import { css } from "@emotion/react/macro";

export const reset = css`
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  p,
  pre {
    margin: 0;
  }

  button {
    padding: 0;
    background: transparent;
    border: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  input:focus,
  textarea:focus,
  input:active,
  textarea:active {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }

  iframe {
    border: 0;
  }

  ol,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

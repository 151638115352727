import styled from "@emotion/styled/macro";
import closeIcon from "../../assets/close_icon.svg";
import { ActionSheet } from "../common/actionSheet";
import { Color, FontSize } from "../styles/enums";
import { Image } from "../ui/image";
import { Text } from "../ui/text";
import { Genre } from "../../models/genre";

interface Props {
  open: boolean;
  selectedGenre: Genre | null;
  onClose(genre: Genre | null): void;
}

const Header = styled.div`
  position: fixed;
  width: 100%;

  border-bottom: 1px solid ${Color.ACCENT_50};
  padding: 12px 16px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Body = styled.div`
  padding: 12px 16px;
  text-align: left;
  position: relative;
  top: 52px;
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 150px;
`;

const Item = styled.div`
  display: block;
  border: none;
  padding: 12px;
  margin: 0 -8px 0 -8px;
  border-bottom: 1px solid ${Color.LIGHT_GREY};
  font-size: ${FontSize.SIZE_14};
  cursor: pointer;

  &:hover {
    background-color: ${Color.LIGHT_GREY};
  }
`;

const Legend = styled.p`
  margin-top: 16px;
  font-size: ${FontSize.SIZE_12};
  color: ${Color.ACCENT_500};
`;

export const NovelGenreSelectionActionSheet: React.VFC<Props> = ({
  open,
  selectedGenre,
  onClose
}) => (
  <>
    <ActionSheet open={open} onClose={() => onClose(selectedGenre)}>
      <Header>
        <Text fz={FontSize.SIZE_16} fw="bold">
          ジャンルの選択
        </Text>
        <CloseButton type="button" onClick={() => onClose(selectedGenre)}>
          <Image src={closeIcon} width={18} height={18} alt="閉じる" />
        </CloseButton>
      </Header>
      <Body>
        {Object.entries(Genre).map(([key, value]) => (
          <Item
            key={key}
            onClick={() => {
              onClose(value);
            }}
          >
            {value}
          </Item>
        ))}
        <Legend>
          読者に読まれやすくなるため「二次創作」や「夢小説」の要素を含む作品は「二次創作・夢小説」のジャンルを選択してください。
          <br />
          <br />
          設定したジャンルと内容が大きく異なる場合、運営の判断でジャンルの変更を行う場合があります。
        </Legend>
      </Body>
    </ActionSheet>
  </>
);

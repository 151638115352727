import styled from "@emotion/styled";
import React, { useRef, useEffect, useState, useCallback } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { keyframes } from "@emotion/react/macro";
import { FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";
import { ActivityModal } from "./activityModal";

const EnterAnimation = keyframes`
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Window = styled.div`
  animation: ${EnterAnimation} 256ms;
  position: relative;
  min-width: 280px;
  max-width: 460px;
  border-radius: 14px;
  background-color: ${Color.DIALOG_BG};
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 4px;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  min-height: 96px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MsgContainer = styled.div`
  word-break: break-all;
  text-align: center;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: ${FontSize.SIZE_17};
`;

const Msg = styled.div`
  margin-top: 10px;
  white-space: pre-wrap;
  font-size: ${FontSize.SIZE_13};
`;

const Separator = styled.div`
  background-color: ${Color.ACCENT_500};
  width: 100%;
  height: 1px;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ConfirmButton = styled.div<{ enabled: boolean }>`
  cursor: ${({ enabled }) => (enabled ? "pointer" : "not-allowed")};
  font-size: 17px;
  font-weight: bold;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 50%;

  color: ${({ enabled }) => (enabled ? Color.RED : Color.GREY_TRANSPARENT)};
`;

const CancelButton = styled.div`
  color: ${Color.TINT};
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  padding-top: 14px;
  padding-bottom: 14px;
  border-right: 1px solid ${Color.ACCENT_500};
  width: 50%;
`;

const TextField = styled.input`
  display: block;
  border: none;
  background-color: ${Color.WHITE};
  padding: 12px 16px;
  margin: 25px auto 5px;
  width: 80%;
  text-align: center;
`;

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmNovelDeleteModal: React.FC<Props> = ({
  onClose,
  onConfirm
}) => {
  const alertRef = useRef<HTMLDivElement>(null);

  const [canDelete, setCanDelete] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showActivity, setShowActivity] = useState(false);

  const checkConfirm = useCallback(() => {
    if (!canDelete) {
      return;
    }
    setShowActivity(true);
    onConfirm();
  }, [canDelete, onConfirm]);

  const handleChange = useCallback(e => {
    if (e.target.value === "作品と中身を全て削除") {
      setCanDelete(true);
      setShowWarning(false);
      return;
    }
    setCanDelete(false);
    setShowWarning(e.target.value.length > 0);
  }, []);

  useEffect(() => {
    if (alertRef.current) {
      disableBodyScroll(alertRef.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [alertRef]);

  return (
    <Wrapper role="dialog" aria-modal ref={alertRef}>
      <Overlay aria-hidden onClick={onClose} />
      <Window>
        <Content>
          {showActivity && <ActivityModal />}
          <MsgContainer>
            <Title>削除した場合、中のエピソードも全て削除されます。</Title>
            <Msg>
              本当に削除したい場合、以下のテキストを入力してください：
              <br />「<b>作品と中身を全て削除</b>」
            </Msg>
          </MsgContainer>
          <TextField id="title" type="text" onChange={handleChange} />
          {showWarning && (
            <Text fz={FontSize.SIZE_12} color={Color.RED}>
              入力が正しくありません。
            </Text>
          )}
        </Content>
        <Separator />
        <Buttons>
          <CancelButton onClick={onClose}>キャンセル</CancelButton>
          <ConfirmButton onClick={checkConfirm} enabled={canDelete}>
            削除する
          </ConfirmButton>
        </Buttons>
      </Window>
    </Wrapper>
  );
};

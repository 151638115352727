import { useCallback } from "react";
import { useLocalStorage } from "react-use";
import { useAuth } from "./useAuth";
import { MeRepository } from "../repositories/meRepository";

export const AUTH_TOKEN_KEY = "nekoThtua";
export const AUTH_TOKEN_EXPIRED_AT_KEY = "tAderipxEnekoThtua";
export const AUTH_TOKEN_UID_KEY = "diUnekoThtua";

interface AuthTokenHandle {
  ensureAuthToken(): Promise<string>;
}

const meRepository = new MeRepository();

export const useAuthToken = (): AuthTokenHandle => {
  const [authToken, setAuthToken] = useLocalStorage<string>(AUTH_TOKEN_KEY);
  const [authTokenExpiredAt, setAuthTokenExpiredAt] = useLocalStorage<number>(
    AUTH_TOKEN_EXPIRED_AT_KEY
  );
  const { user } = useAuth();
  const [authTokenUid, setAuthTokenUid] = useLocalStorage<string>(
    AUTH_TOKEN_UID_KEY
  );

  const ensureAuthToken = useCallback(async () => {
    let token = "";
    if (authTokenExpiredAt && authToken && user?.uid === authTokenUid) {
      const now = new Date();
      // Token will expire in more than 2 minutes
      if (authTokenExpiredAt * 1000 - now.getTime() > 120000) {
        token = authToken;
      }
    }
    if (!token) {
      // token expired or will expire in the next 2 minutes, or not stored yet
      const serverGeneratedToken = await meRepository.issueAuthCustomToken();
      token = serverGeneratedToken.token?.value || "";
      setAuthToken(token);
      setAuthTokenExpiredAt(Number(serverGeneratedToken.expiresAt?.seconds));
      if (user?.uid) {
        setAuthTokenUid(user.uid);
      }
    }
    return token;
  }, [
    authToken,
    authTokenExpiredAt,
    authTokenUid,
    setAuthToken,
    setAuthTokenExpiredAt,
    setAuthTokenUid,
    user?.uid
  ]);

  return {
    ensureAuthToken
  };
};

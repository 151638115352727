import useSWR from "swr";
import { useCallback, useEffect, useState } from "react";
import { useInterval } from "react-use";
import { NovelUseCase } from "../../../usecases/novelUseCase";
import { useAuth } from "../../../hooks/useAuth";
import { useMedia } from "../../../hooks/useMedia";
import { useAppParameters } from "../../../hooks/useAppParameters";
import { Media } from "../../styles/enums";

const novelUseCase = new NovelUseCase();

const TIKTOK_STATUS_POLL_TRIES = 15;
const TIKTOK_STATUS_POLL_INTERVAL = 30000; // 30s

interface Handlers {
  screenLarge: boolean;
  isApp: boolean;
  canOpenTikTok: boolean;
  disableTikTokButton: boolean;
  isShowVideoInProgressAlert: boolean;
  checkTikTokVideo: () => void;
  share: () => void;
  closeVideoInProgressAlert: () => void;
}

export const useLocalHooks = (
  episodeId: string,
  episodeTitle: string,
  isSharedForPublic: boolean,
  onClose: () => void
): Handlers => {
  const { user: authUser, tellerUser } = useAuth();
  const { isApp, canOpenTikTok } = useAppParameters();

  const screenLarge = useMedia(Media.LARGE);

  const share = useCallback(() => {
    window.location.href = `teller-studio://share/${episodeId}/s?title=${episodeTitle}`;
    onClose();
  }, [episodeId, episodeTitle, onClose]);

  const [startPolling, setStartPolling] = useState(false);
  const [videoAvailable, setVideoAvailable] = useState(false);
  const [disableTikTokButton, setDisableTikTokButton] = useState(false);
  const [videoCheckAttempts, setVideoCheckAttempts] = useState(
    TIKTOK_STATUS_POLL_TRIES
  );
  const [isShowVideoInProgressAlert, setShowVideoInProgressAlert] = useState(
    false
  );

  const closeVideoInProgressAlert = useCallback(() => {
    setShowVideoInProgressAlert(false);
  }, []);

  const { data: videoExistsAlready } = useSWR(
    authUser && tellerUser && episodeId && isSharedForPublic
      ? ["/api/videoAvailability", episodeId]
      : null,
    (_, id) => novelUseCase.fetchEpisodeTikTok(id),
    {
      shouldRetryOnError: false
    }
  );

  useEffect(() => {
    if (videoExistsAlready && !videoAvailable) {
      setVideoAvailable(true);
    }
  }, [videoAvailable, videoExistsAlready]);

  const requestVideoCreation = useCallback(async () => {
    await novelUseCase.requestEpisodeVideoCreation(episodeId);
  }, [episodeId]);

  const fetchTikTokVideoStatus = useCallback(async () => {
    if (videoAvailable) {
      return;
    }
    try {
      const isAvailable = await novelUseCase.fetchEpisodeTikTok(episodeId);
      setVideoAvailable(isAvailable);
      setDisableTikTokButton(!isAvailable);
    } catch (err) {
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    } finally {
      setVideoCheckAttempts(videoCheckAttempts - 1);
    }
  }, [episodeId, videoAvailable, videoCheckAttempts]);

  const checkTikTokVideo = useCallback(() => {
    if (videoAvailable) {
      window.location.href = `teller-studio://share-tiktok/${episodeId}?title=${episodeTitle}`;
      return;
    }
    if (!startPolling) {
      setStartPolling(true);
      requestVideoCreation();
      fetchTikTokVideoStatus();
      setShowVideoInProgressAlert(true);
    }
  }, [
    episodeId,
    episodeTitle,
    fetchTikTokVideoStatus,
    requestVideoCreation,
    startPolling,
    videoAvailable
  ]);

  useInterval(
    fetchTikTokVideoStatus,
    !isSharedForPublic ||
      videoAvailable ||
      videoCheckAttempts === 0 ||
      !startPolling
      ? null
      : TIKTOK_STATUS_POLL_INTERVAL
  );

  return {
    screenLarge,
    isApp,
    canOpenTikTok,
    checkTikTokVideo,
    disableTikTokButton,
    share,
    isShowVideoInProgressAlert,
    closeVideoInProgressAlert
  };
};

import React, { useMemo } from "react";
import styled from "@emotion/styled/macro";

// Hooks
import { useMedia } from "../../hooks/useMedia";
import { useAppParameters } from "../../hooks/useAppParameters";

// Components
import { Modal } from "./modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";
import { Image } from "../ui/image";
import { Button, ButtonVariant } from "../ui/button";

// Assets
import publishRules from "../../assets/publish_rules.png";

// Utils
import { COMMUNITY_GUIDELINE_LINK } from "../../utils/constants";

const ContentWrapper = styled.div`
  text-align: center;
  margin: 16px auto 0;

  @media ${Media.LARGE} {
    padding: 8px;
  }
`;

const Title = styled(Text)`
  font-size: ${FontSize.SIZE_17};
  font-weight: 700;
`;

const Legend = styled(Text)`
  font-size: ${FontSize.SIZE_12};
  margin-top: 18px;
  line-height: 18px;
  font-weight: 700;

  > span {
    font-weight: normal;
  }

  > a {
    color: ${Color.TINT};
    font-weight: normal;
  }
`;

const Red = styled.span`
  color: ${Color.RED};
`;

const GuidelinesImage = styled(Image)`
  max-width: 240px;

  @media ${Media.LARGE} {
    margin-top: 20px;
    max-width: 295px;
  }
`;

const ButtonsWrapper = styled.div`
  max-width: 295px;
  margin: auto;
  > button {
    font-size: ${FontSize.SIZE_12};
  }
`;

const DraftButton = styled(Button)`
  border: 1px solid ${Color.ACCENT_1000};
  background-color: ${Color.PRIMARY};
  margin-top: 16px;
`;

interface Props {
  open: boolean;
  onPublishButton(): void;
  onDraftButton(): void;
  onClose(): void;
}

export const GuidelinesModal: React.FC<Props> = ({
  open,
  onClose,
  onPublishButton,
  onDraftButton
}) => {
  const { isApp, canOpenInAppWeb } = useAppParameters();
  const screenLarge = useMedia(Media.LARGE);

  const guidelineURL = useMemo((): string => {
    if (canOpenInAppWeb) {
      return `teller-studio://in-app-webview?url=${COMMUNITY_GUIDELINE_LINK}`;
    }
    return isApp ? "teller-studio://guidelines" : COMMUNITY_GUIDELINE_LINK;
  }, [canOpenInAppWeb, isApp]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width="400px"
      height={screenLarge ? "618px" : "auto"}
      noPadding
      close={onClose}
    >
      <ContentWrapper>
        <Title>公開する前のご確認</Title>
        <Legend>
          以下のようなストーリーはルールで禁止されています。
          <br />
          <span>
            違反すると<Red>アカウント停止</Red>になる可能性があります。
          </span>
        </Legend>
        <GuidelinesImage src={publishRules} />
        <Legend>
          <span>※詳しいルールやお問い合わせは</span>
          <a
            href={guidelineURL}
            target={isApp ? "_self" : "_blank"}
            rel="noreferrer"
          >
            ガイドライン
          </a>
          <span>をご覧ください</span>
        </Legend>
        <ButtonsWrapper>
          <Button
            type="button"
            variant={ButtonVariant.PILL}
            color={Color.TINT}
            block
            big
            mt={20}
            onClick={onPublishButton}
          >
            問題ないことに同意し、公開する
          </Button>
          <DraftButton
            type="button"
            variant={ButtonVariant.PILL}
            block
            big
            mb={38}
            onClick={onDraftButton}
          >
            下書きに保存して見直す
          </DraftButton>
        </ButtonsWrapper>
      </ContentWrapper>
    </Modal>
  );
};

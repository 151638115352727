import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react/macro";
import { Color } from "../styles/enums";

const EnterAnimation = keyframes`
  0% {
    margin-top: 45px;
    opacity: 0;
  }

  100% {
    margin-top: 91px;
    opacity: 1;
  }
`;

const ExitAnimation = keyframes`
  0% {
    margin-top: 91px;
    opacity: 1;
  }

  100% {
    margin-top: 45px;
    opacity: 0;
  }
`;

const EnterAnimationAfterHeader = keyframes`
  0% {
    top: 0;
    opacity: 0;
  }

  100% {
    top: 45px;
    opacity: 1;
  }
`;

const ExitAnimationAfterHeader = keyframes`
  0% {
    top: 45px;
    opacity: 1;
  }

  100% {
    top: 0;
    opacity: 0;
  }
`;

export const FeedbackPanel = styled.div<{
  isError?: boolean;
  afterHeader?: boolean;
}>`
  max-width: 960px;
  position: absolute;
  width: 100%;
  margin-top: ${({ afterHeader }) => (afterHeader ? "0" : "91px")};
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  z-index: 999;
  animation: ${({ afterHeader }) =>
    css`${afterHeader ? EnterAnimationAfterHeader : EnterAnimation} 500ms, ${
      afterHeader ? ExitAnimationAfterHeader : ExitAnimation
    } 700ms 3s forwards`};

  background: ${({ isError }) => (isError ? Color.RED : Color.GREEN)};
`;

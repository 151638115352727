import React, {
  useState,
  useCallback,
  useEffect,
  RefObject,
  CSSProperties,
  useMemo
} from "react";
import { useIntersection } from "react-use";
import { useSWRInfinite } from "swr";
import { useHistory } from "react-router-dom";
import {
  DraggingStyle,
  NotDraggingStyle,
  DropResult
} from "react-beautiful-dnd";
import { useAlertMsg } from "./useAlertMsg";
import { useAuth } from "./useAuth";
import { logError } from "../utils/utils";
import { NovelUseCase } from "../usecases/novelUseCase";
import {
  query_recursive_types as RpcRecursiveTypes,
  story as RpcStory
} from "../infra/api/rpc/api";

interface EpisodeReorderHandle {
  isLoading: boolean;
  isValidating: boolean;
  isEmpty: boolean;
  items: RpcRecursiveTypes.IStoryResponse[];
  publishedExistsNow: boolean;
  onDragEnd(result: DropResult): void;
  getItemStyle(
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): CSSProperties;
  getListStyle(isDraggingOver: boolean): CSSProperties;
  orderChanged(): boolean;
  isDiscardChangesActionSheetOpen: boolean;
  closeDiscardChangesActionSheet(): void;
  checkChangesOnBack(): void;
  toNovelPage(): void;
  checkAndSaveChanges(): void;
  saveChanges(): void;
  isShowActivityModal: boolean;
  isShowAlert: boolean;
  showAlertWithMsg(msg: string): void;
  alertMsg: string | null;
  hideAlert(): void;
}

const novelUseCase = new NovelUseCase();

export const useEpisodeReorder = (
  novelId: string,
  novel: RpcRecursiveTypes.ISeriesResponse | null | undefined,
  paginationRef: RefObject<HTMLDivElement>
): EpisodeReorderHandle => {
  const history = useHistory();

  const { isShowAlert, showAlertWithMsg, alertMsg, hideAlert } = useAlertMsg();

  const [isLoading, setIsLoading] = useState(true);
  const [
    isDiscardChangesActionSheetOpen,
    setDiscardChangesActionSheetOpen
  ] = useState(false);

  const { tellerUser } = useAuth();

  const openDiscardChangesActionSheet = useCallback(() => {
    setDiscardChangesActionSheetOpen(true);
  }, []);

  const closeDiscardChangesActionSheet = useCallback(() => {
    setDiscardChangesActionSheetOpen(false);
  }, []);

  const [isShowActivityModal, setShowActivityModal] = useState(false);
  const showActivityModal = useCallback(() => {
    setShowActivityModal(true);
  }, []);
  const hideActivityModal = useCallback(() => {
    setShowActivityModal(false);
  }, []);

  const getKey = useCallback(
    (pageIndex, prevData) => {
      if (!tellerUser?.id?.value || !novelId) {
        return null;
      }

      if (!prevData) {
        return ["/api/user/novel/reorder", novelId, 0];
      }

      if (prevData?.page?.hasNextPage?.value) {
        return ["/api/user/novel/reorder", novelId, pageIndex];
      }

      return null;
    }, // SWR key
    [novelId, tellerUser?.id?.value]
  );

  const fetcher = useCallback(
    (
      _key,
      nId,
      page
    ): Promise<RpcRecursiveTypes.ISeriesStoryPageResponse | null> =>
      novelUseCase.fetchNovelEpisodes(nId, page),
    []
  );

  // Current items with order edited by user
  const [items, setItems] = useState<RpcRecursiveTypes.IStoryResponse[]>([]);

  const {
    data: novelEpisodesPage,
    error,
    size,
    setSize,
    isValidating,
    mutate
  } = useSWRInfinite(getKey, fetcher, {
    shouldRetryOnError: false,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    onSuccess: data => {
      // As data is cached, if we have existing data from useSWR, load items state with it
      // right away
      if (items.length === 0) {
        const stories: RpcRecursiveTypes.IStoryResponse[] = data
          .map(p => p?.storyList)
          .flat()
          .filter(
            el => el !== null && el !== undefined
          ) as RpcRecursiveTypes.IStoryResponse[];
        setItems(stories);
      } else {
        // As we have infinite loading method, if the user scrolls down, new stories will come from server
        // but we want to preserv changes in order already done, so what we do is just go adding
        // new stories from server at the end of current elements
        const lastLoadedPage = data[size - 1];
        const appendStories: RpcRecursiveTypes.IStoryResponse[] = lastLoadedPage?.storyList?.filter(
          el => el !== null && el !== undefined
        ) as RpcRecursiveTypes.IStoryResponse[];
        setItems(prevItems => prevItems.concat([...appendStories]));
      }
    }
  });

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  const isLoadingFirstPage = !novelEpisodesPage && !error;

  useEffect(() => {
    if (
      isLoadingFirstPage ||
      (size > 0 && novelEpisodesPage && !novelEpisodesPage[size - 1])
    ) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isLoadingFirstPage, novelEpisodesPage, size]);

  const isEmpty = useMemo(
    () =>
      novel === null &&
      (novelEpisodesPage?.[0] === null ||
        novelEpisodesPage?.[0]?.storyList?.length === 0),
    [novel, novelEpisodesPage]
  );

  const isLastPage = useMemo(
    () =>
      novelEpisodesPage?.[size - 1]?.page?.hasNextPage?.value === false ??
      false,
    [novelEpisodesPage, size]
  );

  const intersection = useIntersection(paginationRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1
  });

  useEffect(() => {
    if (
      !isEmpty &&
      novelId !== "undefined" &&
      intersection?.intersectionRatio === 1 &&
      !isLoading &&
      !isValidating &&
      !isLastPage
    ) {
      setSize(size + 1);
    }
  }, [
    error,
    intersection?.intersectionRatio,
    isEmpty,
    isLastPage,
    isLoading,
    isValidating,
    novelId,
    setSize,
    size
  ]);

  const firstEpisodes = useMemo(() => novelEpisodesPage?.[0]?.storyList, [
    novelEpisodesPage
  ]);

  // Episodes coming from server have updated information right away (not from Algolia)
  // But novel data is still being retrieved from Algolia
  // Novel status is shown as published if at least one episode is published,
  // we know this right away using `searchableStoryCount` data from novel, but as this data has some delay
  // let's check if we already have some published episode (data is more fresh from datastore) and do not wait for Algolia
  const publishedExistsNow = useMemo(
    () => !!firstEpisodes?.find(e => e.status === RpcStory.StoryStatus.PUBLISH),
    [firstEpisodes]
  );

  // Store original order, so we can then compare if any changes has been made
  const [originalOrder, setOriginalOrder] = useState<string[]>([]);

  useEffect(() => {
    if (novelEpisodesPage) {
      const order = novelEpisodesPage
        .map(p => p?.storyList?.map(n => n.id?.value))
        .flat()
        .filter(el => el !== null && el !== undefined);
      setOriginalOrder(order as string[]);
    }
  }, [novelEpisodesPage]);

  const orderChanged = useCallback((): boolean => {
    let changed = false;
    originalOrder.forEach((orderedId, idx) => {
      const elId = items[idx]?.id?.value;
      if (elId !== orderedId) {
        changed = true;
      }
    });
    return changed;
  }, [items, originalOrder]);

  const reorder = (
    list: RpcRecursiveTypes.IStoryResponse[],
    startIndex: number,
    endIndex: number
  ): RpcRecursiveTypes.IStoryResponse[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(reorderItems);
  };

  const toNovelPage = useCallback(() => {
    history.push(
      `/novel/${novelId}?notransition=1${orderChanged() ? "&refresh=1" : ""}`
    );
  }, [history, novelId, orderChanged]);

  const checkChangesOnBack = useCallback(() => {
    if (orderChanged()) {
      openDiscardChangesActionSheet();
    } else {
      toNovelPage();
    }
  }, [openDiscardChangesActionSheet, orderChanged, toNovelPage]);

  const saveChanges = useCallback(async () => {
    showActivityModal();
    try {
      let orderedIds = items.map(c => c.id?.value);

      if (!isLastPage) {
        // keep loading all stories
        let nextPageNumber = novelEpisodesPage?.[size - 1]?.page?.hasNextPage
          ?.value
          ? size
          : undefined;
        while (nextPageNumber) {
          /* eslint-disable no-await-in-loop */
          // ref: https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it
          const nextPage = await novelUseCase.fetchNovelEpisodes(
            novelId,
            nextPageNumber
          );
          if (nextPage) {
            const ids = nextPage.storyList?.map(n => n.id?.value);
            nextPageNumber = nextPage?.page?.hasNextPage?.value
              ? nextPageNumber + 1
              : undefined;
            orderedIds = orderedIds.concat(ids);
          } else {
            nextPageNumber = undefined;
          }
        }
      }
      await novelUseCase.reorderEpisodes(novelId, orderedIds as string[]);
      // After data has been stored, mutate cached data for next accesses
      mutate();
      closeDiscardChangesActionSheet();
      toNovelPage();
    } catch (err) {
      showAlertWithMsg("不明なエラーが発生しました。");
      hideActivityModal();
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }, [
    closeDiscardChangesActionSheet,
    hideActivityModal,
    isLastPage,
    items,
    mutate,
    novelEpisodesPage,
    novelId,
    showActivityModal,
    showAlertWithMsg,
    size,
    toNovelPage
  ]);

  const checkAndSaveChanges = useCallback(async () => {
    if (!orderChanged()) {
      toNovelPage();
      return;
    }
    await saveChanges();
  }, [orderChanged, saveChanges, toNovelPage]);

  const grid = 0;

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    boxShadow: isDragging ? "4px 1px 15px 3px rgba(0,0,0,0.1)" : "none",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: "100%"
  });

  return {
    isLoading,
    isValidating,
    isEmpty,
    items,
    publishedExistsNow,
    onDragEnd,
    getItemStyle,
    getListStyle,
    orderChanged,
    isDiscardChangesActionSheetOpen,
    checkChangesOnBack,
    closeDiscardChangesActionSheet,
    toNovelPage,
    checkAndSaveChanges,
    saveChanges,
    isShowActivityModal,
    isShowAlert,
    showAlertWithMsg,
    alertMsg,
    hideAlert
  };
};

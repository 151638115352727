import styled from "@emotion/styled/macro";

import { ActionSheet, OptionsList, Option } from "../common/actionSheet";
import { FontSize, Color } from "../styles/enums";
import { Image } from "../ui/image";
import closeIcon from "../../assets/header/close_icon.svg";

const Wrapper = styled.div`
  min-height: 230px;
`;

const Header = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  border-bottom: 2px solid ${Color.ACCENT_50};
`;

const CloseButton = styled.button`
  min-width: 20px;
  margin-left: 11px;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  flex: 1;
`;

const Opt = styled(Option)`
  font-size: ${FontSize.SIZE_14};
  font-weight: 700;
  line-height: 53px;
  height: 53px;
  cursor: pointer;

  &:hover {
    background: ${Color.STRONG_BG};
  }
`;

const SaveOption = styled(Opt)`
  color: ${Color.TINT};
  margin-top: -10px;
`;

const DiscardOption = styled(Opt)`
  color: ${Color.RED};
`;

const CancelOption = styled(Opt)`
  color: ${Color.ACCENT_500};
`;

interface Props {
  open: boolean;
  isNew: boolean;
  onSave(): void;
  onDiscard(): void;
  onClose(): void;
}

export const EpisodeDiscardActionSheet: React.VFC<Props> = ({
  open,
  isNew,
  onSave,
  onDiscard,
  onClose
}) => (
  <ActionSheet open={open || false} onClose={onClose} bckWhite>
    <Header>
      <CloseButton type="button" onClick={onClose}>
        <Image src={closeIcon} />
      </CloseButton>
      <Title>
        {isNew
          ? "下書きに保存しますか？"
          : "前回から変更があります。保存しますか？"}
      </Title>
    </Header>
    <Wrapper>
      <OptionsList>
        <SaveOption onClick={onSave}>保存</SaveOption>
        <DiscardOption onClick={onDiscard}>
          {isNew ? "破棄" : "変更を破棄"}
        </DiscardOption>
        <CancelOption onClick={onClose}>キャンセル</CancelOption>
      </OptionsList>
    </Wrapper>
  </ActionSheet>
);

import styled from "@emotion/styled/macro";
import { useEffect, useState, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// UseCase, model
import {
  story as RpcStory,
  query_types as RpcQueryTypes
} from "../../infra/api/rpc/api";
import { NovelUseCase } from "../../usecases/novelUseCase";
import { Episode, toMarkdown } from "../../models/episode";

// Hooks
import { useGuidelinesModal } from "../../hooks/useGuidelinesModal";
import { useContestAcknowledge } from "../../hooks/useContestAcknowledge";

// Components
import { ActionSheet } from "../common/actionSheet";
import { Loading } from "../common/loading";
import { Image } from "../ui/image";
import { Button, ButtonVariant } from "../ui/button";
import { Text } from "../ui/text";
import { Color, FontSize } from "../styles/enums";
import closeIcon from "../../assets/close_icon.svg";
import { GuidelinesModal } from "../common/guidelinesModal";
import { EpisodeContestModal } from "./episodeContestModal";

type FormValues = {
  title?: string | null;
  sensitiveFlag?: RpcQueryTypes.SensitiveFlag;
  contestConditionsAcknowledge?: boolean;
};

const Header = styled.div`
  position: relative;
  border-bottom: 1px solid ${Color.ACCENT_50};
  padding: 12px 16px;
  text-align: center;
`;

const Form = styled.form`
  text-align: left;
  padding: 16px;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Label = styled.label`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  margin: 16px 0 8px;
`;

const TextField = styled.input`
  display: block;
  border: none;
  background-color: ${Color.WHITE};
  padding: 12px 16px;
  margin: 0 -16px 8px;
  width: calc(100% + 32px);
`;

const RatingContainer = styled.div`
  padding: 12px 16px;
  margin: 0 -16px 8px;
  width: calc(100% + 32px);
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
`;

const RatingSectionTitle = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  svg {
    margin: 0 0 3px 4px;
  }
`;

const IsSentiveCheckboxInputLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 16px;
  cursor: pointer;

  input[type="checkbox"] {
    margin-right: 4px;
  }

  span {
    margin-top: 2px;
    line-height: 1;
  }
`;

const ActivityWrapper = styled.div`
  margin-top: 20px;
`;

const TITLE_MAX_LENGTH = 50;

const novelUseCase = new NovelUseCase();
interface Props {
  open: boolean;
  episode: Episode;
  content: string;
  isForPublish: boolean; // publish right after saving
  openAboutRatingModal: () => void;
  onClose(
    saved: boolean,
    error: boolean,
    isNew: boolean,
    discardFromGuidelinesModal: boolean,
    novelId?: string | null,
    epId?: string | null,
    epTitle?: string
  ): void;
}

export const EpisodeTitleActionSheet: React.VFC<Props> = ({
  open,
  isForPublish,
  episode,
  content,
  openAboutRatingModal,
  onClose
}) => {
  const {
    shouldShow: shouldShowGuidelines,
    isOpen: isGuidelinesModalOpen,
    open: showGuidelinesModal,
    close: closeGuidelinesModal
  } = useGuidelinesModal();
  const {
    checkContestTags,
    openContestModal,
    selectedContestTag,
    isOpen: contestModalIsOpen,
    close: closeContestModal
  } = useContestAcknowledge();

  const history = useHistory();
  const {
    register,
    setValue,
    watch,
    handleSubmit: createHandleSubmit,
    formState
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      title: episode.title || "",
      sensitiveFlag: episode.sensitiveFlag,
      contestConditionsAcknowledge: false
    }
  });
  const submitRef = useRef<HTMLButtonElement | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const title = watch("title");
  const sensitiveFlag = watch("sensitiveFlag");

  useEffect(() => {
    setValue("title", episode.title);
  }, [episode.title, setValue]);

  useEffect(() => {
    setValue("sensitiveFlag", episode.sensitiveFlag);
  }, [episode.sensitiveFlag, setValue]);

  const storeEpisode = useCallback(
    async (draftFromGuidelinesModal?: boolean) => {
      if (!title) {
        return;
      }
      setIsSaving(true);
      const ep = { ...episode, title, sensitiveFlag: Number(sensitiveFlag) };
      if (!ep.novelScript || ep.novelScript.length === 0) {
        ep.novelScript = " ";
      }
      if (isForPublish && !draftFromGuidelinesModal) {
        ep.status = RpcStory.StoryStatus.PUBLISH;
      }
      let epId = ep.id;
      let novelId = ep?.novelId;
      try {
        if (content) {
          ep.novelScript = toMarkdown(content);
        }
        if (!ep.id) {
          const createdIds = await novelUseCase.createEpisode(ep);
          epId = createdIds.episodeId;
          novelId = createdIds.novelId;
          if (!isForPublish || draftFromGuidelinesModal) {
            // Now we have id, refresh url
            history.push(`/novel/${novelId}/episodes/${epId}?notransition=1`);
          }
        } else {
          await novelUseCase.updateEpisode(ep);
        }
        setValue("contestConditionsAcknowledge", false, {
          shouldValidate: true
        });

        onClose(
          true,
          false,
          !episode.id,
          !!draftFromGuidelinesModal,
          novelId,
          epId,
          title
        );
      } catch (err) {
        onClose(
          false,
          true,
          !episode.id,
          !!draftFromGuidelinesModal,
          epId,
          title
        );
      } finally {
        setIsSaving(false);
      }
    },
    [
      content,
      episode,
      history,
      isForPublish,
      onClose,
      sensitiveFlag,
      setValue,
      title
    ]
  );

  const publishAfterContestConditionsAcknowledged = useCallback(() => {
    closeContestModal();
    setValue("contestConditionsAcknowledge", true, { shouldValidate: true });
    if (submitRef.current) {
      submitRef.current.click();
    }
  }, [closeContestModal, setValue]);

  const handleSubmit = createHandleSubmit(values => {
    const isPublished = episode.status === RpcStory.StoryStatus.PUBLISH;
    if (
      (isPublished || isForPublish) &&
      !values.contestConditionsAcknowledge &&
      episode.tags &&
      checkContestTags(episode.tags)
    ) {
      openContestModal(episode.tags);
      return;
    }
    if ((isForPublish || isPublished) && shouldShowGuidelines) {
      showGuidelinesModal();
    } else {
      closeGuidelinesModal();
      storeEpisode();
    }
  });

  const guidelinesModalPublishCallback = useCallback(() => {
    closeGuidelinesModal();
    storeEpisode();
  }, [closeGuidelinesModal, storeEpisode]);

  const guidelinesModalDraftCallback = useCallback(() => {
    closeGuidelinesModal();
    storeEpisode(true);
  }, [closeGuidelinesModal, storeEpisode]);

  const titleLength = (title && title.length) || 0;

  return (
    <>
      <ActionSheet
        open={open || false}
        onClose={() => onClose(false, false, !episode.id, false)}
      >
        <Header>
          <Text fz={FontSize.SIZE_16} fw="bold">
            {isForPublish ? "投稿する前に" : "作品詳細"}
          </Text>
          <CloseButton
            type="button"
            onClick={() => onClose(false, false, !episode.id, false)}
          >
            <Image src={closeIcon} width={18} height={18} alt="閉じる" />
          </CloseButton>
        </Header>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="title">
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              fw="bold"
              color={Color.ACCENT_500}
            >
              エピソードタイトル
            </Text>
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              color={
                titleLength <= TITLE_MAX_LENGTH ? Color.ACCENT_500 : Color.RED
              }
            >
              {TITLE_MAX_LENGTH - titleLength}
            </Text>
          </Label>
          <TextField
            id="title"
            type="text"
            {...register("title", {
              required: { value: true, message: "タイトルを入力してください" },
              maxLength: {
                value: TITLE_MAX_LENGTH,
                message: "文字数制限を超えています"
              }
            })}
          />
          {formState.errors.title ? (
            <Text fz={FontSize.SIZE_12} color={Color.RED}>
              {formState.errors.title.message}
            </Text>
          ) : null}
          {/* レーティング設定 */}
          <RatingContainer>
            <RatingSectionTitle onClick={openAboutRatingModal}>
              <Text
                as="span"
                fz={FontSize.SIZE_12}
                fw="bold"
                color={Color.ACCENT_500}
              >
                レーティング設定
              </Text>
              <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
                <path
                  d="m7.33301 11.9999h1.33333v-1.3333h-1.33333zm.66666-10.66665c-3.68 0-6.66666 2.98667-6.66666 6.66667 0 3.67998 2.98666 6.66668 6.66666 6.66668 3.68003 0 6.66663-2.9867 6.66663-6.66668 0-3.68-2.9866-6.66667-6.66663-6.66667zm0 12.00005c-2.94 0-5.33333-2.3934-5.33333-5.33338 0-2.94 2.39333-5.33333 5.33333-5.33333 2.94003 0 5.33333 2.39333 5.33333 5.33333 0 2.93998-2.3933 5.33338-5.33333 5.33338zm0-9.33338c-1.47333 0-2.66666 1.19333-2.66666 2.66667h1.33333c0-.73334.6-1.33334 1.33333-1.33334.73334 0 1.33334.6 1.33334 1.33334 0 1.33333-2 1.16666-2 3.33333h1.33333c0-1.5 1.99996-1.66667 1.99996-3.33333 0-1.47334-1.19329-2.66667-2.66663-2.66667z"
                  fill="#1b88ff"
                />
              </svg>
            </RatingSectionTitle>
            <IsSentiveCheckboxInputLabel htmlFor="sensitiveFlagForEpisodeTitleActionSheet">
              <input
                id="sensitiveFlagForEpisodeTitleActionSheet"
                type="checkbox"
                value={
                  episode.sensitiveFlag ===
                  RpcQueryTypes.SensitiveFlag.SENSITIVE_FLAG_ENABLED_BY_ADMIN
                    ? RpcQueryTypes.SensitiveFlag
                        .SENSITIVE_FLAG_ENABLED_BY_ADMIN
                    : RpcQueryTypes.SensitiveFlag.SENSITIVE_FLAG_ENABLED_BY_USER
                }
                disabled={
                  episode.sensitiveFlag ===
                  RpcQueryTypes.SensitiveFlag.SENSITIVE_FLAG_ENABLED_BY_ADMIN
                }
                {...register("sensitiveFlag")}
              />
              <Text as="span" fz={FontSize.SIZE_14} color={Color.ACCENT_1000}>
                センシティブな内容を含む
              </Text>
            </IsSentiveCheckboxInputLabel>
            <Text as="span" fz={FontSize.SIZE_12} color={Color.ACCENT_500}>
              通報やサイトパトロールにより「センシティブな内容を含む」に変更される場合がございます。予めご了承ください。
            </Text>
          </RatingContainer>
          {isSaving ? (
            <ActivityWrapper>
              <Loading small noPadding noColor />
            </ActivityWrapper>
          ) : null}
          <Button
            ref={submitRef}
            type="submit"
            variant={ButtonVariant.PILL}
            color={formState.isValid ? Color.TINT : Color.GREY}
            block
            big
            mt={28}
            mb={48}
            disabled={!formState.isValid}
          >
            {isForPublish ? "投稿" : "完了"}
          </Button>
        </Form>
      </ActionSheet>
      <GuidelinesModal
        open={isGuidelinesModalOpen}
        onClose={closeGuidelinesModal}
        onPublishButton={guidelinesModalPublishCallback}
        onDraftButton={guidelinesModalDraftCallback}
      />
      {selectedContestTag && (
        <EpisodeContestModal
          open={contestModalIsOpen}
          onClose={closeContestModal}
          onCloseWithAcknowledge={publishAfterContestConditionsAcknowledged}
          contestTagName={selectedContestTag}
        />
      )}
    </>
  );
};

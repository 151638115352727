import React from "react";
import styled from "@emotion/styled/macro";
import { Modal } from "./modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";
import { Image } from "../ui/image";
import { Button, ButtonVariant } from "../ui/button";
import { useMedia } from "../../hooks/useMedia";
import warningIcon from "../../assets/warning_icon.svg";

const ContentWrapper = styled.div`
  text-align: center;
  margin: 8px auto 32px;
  padding: 16px 24px;
`;

const Title = styled(Text)`
  font-size: ${FontSize.SIZE_18};
  font-weight: 700;
  margin-top: 22px;
`;

const Legend = styled(Text)`
  font-size: ${FontSize.SIZE_14};
  margin-top: 18px;
  line-height: 18px;
  text-align: left;
  font-weight: normal;
`;

interface Props {
  open: boolean;
  onClose(): void;
}

export const ImageHasBeenReplacedModal: React.FC<Props> = ({
  open,
  onClose
}) => {
  const screenLarge = useMedia(Media.LARGE);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width={screenLarge ? "400px" : "100vw"}
      maxWidth="400px"
      noPadding
      close={onClose}
      hideClose
      overlayClose={false}
    >
      <ContentWrapper>
        <Image src={warningIcon} width={38} />
        <Title>一時的に画像を差し替えました</Title>
        <Legend>
          画像に関してサービス利用規約違反が確認されたため、一時的に差し替えました。詳しくは通知をご確認ください。
          <br />
          <br />
          複数回の違反が確認されると、アップロード機能を一定期間制限されますのでご注意ください。
        </Legend>
        <Button
          type="button"
          variant={ButtonVariant.PILL}
          color={Color.TINT}
          block
          big
          mt={32}
          onClick={onClose}
        >
          次へ
        </Button>
      </ContentWrapper>
    </Modal>
  );
};

import {
  query_recursive_types as RpcRecursiveTypes,
  types as RpcTypes
} from "../infra/api/rpc/api";

import { Genre, rpcGenreToGenre } from "./genre";

export const PublishRange = {
  PUBLIC: "public",
  FOLLOWERS: "followers",
  MUTUAL_FOLLOW: "mutual_follow"
} as const;
export type PublishRange = typeof PublishRange[keyof typeof PublishRange];

export const publishRangeFromRpcSharedWithStatus = (
  sharedWithStatus?: RpcTypes.SharedWithStatus | null
): PublishRange => {
  if (!sharedWithStatus) {
    return PublishRange.PUBLIC;
  }
  switch (sharedWithStatus) {
    case RpcTypes.SharedWithStatus.SHARED_WITH_FOLLOWER:
      return PublishRange.FOLLOWERS;
    case RpcTypes.SharedWithStatus.SHARED_WITH_MUTUAL_FOLLOWER:
      return PublishRange.MUTUAL_FOLLOW;
    default:
      return PublishRange.PUBLIC;
  }
};

export const rpcSharedWithStatusFromPublishRange = (
  publishRange: PublishRange
): RpcTypes.SharedWithStatus => {
  switch (publishRange) {
    case PublishRange.FOLLOWERS:
      return RpcTypes.SharedWithStatus.SHARED_WITH_FOLLOWER;
    case PublishRange.MUTUAL_FOLLOW:
      return RpcTypes.SharedWithStatus.SHARED_WITH_MUTUAL_FOLLOWER;
    default:
      return RpcTypes.SharedWithStatus.SHARED_WITH_PUBLIC;
  }
};

export type Novel = {
  userId: string;
  tags?: string[];
  genre: Genre | null;
  id?: string;
  title: string;
  description?: string;
  thumbnailId?: string;
  thumbnailUrl?: string;
  isCompleted: boolean;
  isOneShot: boolean;
  publishRange: PublishRange;
};

export const novelFromRpcSeries = (
  rpcSeries: RpcRecursiveTypes.ISeriesResponse
): Novel => ({
  userId: rpcSeries.user?.id?.value || "",
  tags: rpcSeries.tags?.map(c => c.value || "") || [],
  id: rpcSeries.id?.value || "",
  title: rpcSeries.title?.value || "",
  description: rpcSeries.description?.value || "",
  thumbnailId: rpcSeries.thumbnail?.id?.value || "",
  thumbnailUrl: rpcSeries.thumbnail?.servingUrl?.value || "",
  isCompleted: rpcSeries.isCompleted?.value || false,
  isOneShot: rpcSeries.isOneshot?.value || false,
  publishRange: publishRangeFromRpcSharedWithStatus(rpcSeries.sharedWithStatus),
  genre: rpcGenreToGenre(rpcSeries.genre || null)
});

import styled from "@emotion/styled/macro";

// Hooks
import { useLocalHooks } from "./useLocalHooks";

// Components
import { Modal } from "../../common/modal";
import { Media, FontSize, Color } from "../../styles/enums";
import { Text } from "../../ui/text";
import { Button, ButtonVariant } from "../../ui/button";
import { Alert } from "../../common/alert";

const ContentWrapper = styled.div`
  text-align: center;
  @media ${Media.LARGE} {
    padding: 8px 80px;
  }
`;

const Title = styled(Text)`
  font-size: ${FontSize.SIZE_20};
  font-weight: 700;
`;

const ShareButton = styled(Button)`
  font-size: ${FontSize.SIZE_14};
  font-weight: 600;
  background-color: ${Color.LIGHT_TINT};
  color: ${Color.TINT};
`;

const ShareTikTokButton = styled(Button)`
  margin-top: 40px;
  background-color: ${Color.ACCENT_1000};
  color: ${Color.PRIMARY};
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  font-size: ${FontSize.SIZE_14};
  font-weight: 600;
  width: 100%;

  &:disabled {
    background-color: ${Color.ACCENT_500};
    color: ${Color.PRIMARY};
    .new-tag {
      display: none;
    }
  }
`;

const TikTokLabel = styled.div`
  vertical-align: middle;
`;

const NewTag = styled.div`
  display: inline-block;
  color: ${Color.PRIMARY};
  background-color: ${Color.PINK};
  padding: 4px 8px;
  border-radius: 40px;
  height: 18px;
  width: 48px;
  font-size: ${FontSize.SIZE_10};
  margin-left: 3px;
`;

interface Props {
  open: boolean;
  episodeTitle: string;
  episodeId: string;
  isSharedForPublic: boolean;
  onClose(): void;
}

export const EpisodePublishedModal: React.FC<Props> = ({
  open,
  episodeTitle,
  episodeId,
  isSharedForPublic,
  onClose
}) => {
  const {
    screenLarge,
    isApp,
    canOpenTikTok,
    checkTikTokVideo,
    disableTikTokButton,
    share,
    isShowVideoInProgressAlert,
    closeVideoInProgressAlert
  } = useLocalHooks(episodeTitle, episodeId, isSharedForPublic, onClose);

  if (!open) {
    return null;
  }

  return (
    <>
      <Modal
        open={open}
        width={screenLarge ? "500px" : "400px"}
        showCloseIconInSmall
        close={onClose}
      >
        <ContentWrapper>
          <Text fz={FontSize.SIZE_38}>🎉</Text>
          <Title>ストーリーを公開しました</Title>
          {isApp ? (
            <>
              {canOpenTikTok && isSharedForPublic ? (
                <ShareTikTokButton
                  variant={ButtonVariant.PILL}
                  onClick={checkTikTokVideo}
                  disabled={disableTikTokButton}
                >
                  <TikTokLabel>TikTokに動画でシェア</TikTokLabel>
                  <NewTag> NEW !</NewTag>
                </ShareTikTokButton>
              ) : null}
              <ShareButton
                type="button"
                variant={ButtonVariant.PILL}
                block
                big
                mt={canOpenTikTok ? 8 : 48}
                mb={8}
                onClick={share}
              >
                {canOpenTikTok && isSharedForPublic ? "その他シェア" : "シェア"}
              </ShareButton>
              <Text fz={FontSize.SIZE_12} color={Color.ACCENT_1000}>
                シェアすることでストーリーが読まれやすくなります
              </Text>
            </>
          ) : null}
          <Button
            type="button"
            variant={ButtonVariant.OUTLINED}
            block
            big
            mt={40}
            mb={38}
            onClick={onClose}
          >
            閉じる
          </Button>
        </ContentWrapper>
      </Modal>
      {isShowVideoInProgressAlert ? (
        <Alert
          close={closeVideoInProgressAlert}
          msg="動画の準備に1分ほどかかります。出来上がり次第、通知画面にてお知らせします。"
          buttonText="確認"
        />
      ) : null}
    </>
  );
};

/* eslint-disable jsx-a11y/control-has-associated-label */
import styled from "@emotion/styled/macro";
import { useRef, useEffect, useCallback, useState, useMemo } from "react";
import { useQuill } from "react-quilljs";
import { subscribe, isSupported } from "on-screen-keyboard-detector";
import { NovelUseCase } from "../../usecases/novelUseCase";
import { fromMarkdown } from "../../models/episode";
import { Color, FontSize, Media } from "../styles/enums";
import { HEADER_HEIGHT_SMALL } from "../common/header/header";
import { ActivityModal } from "../common/activityModal";
import { loadWithOrientation, getFileInfo } from "../../utils/imageUtils";
import { useImageUploadWarningModal } from "../../hooks/useImageUploadWarningModal";
import { ImageUploadWarningModal } from "../common/imageUploadWarningModal";
import "../../assets/editor/editor-teller-theme.css";
import { validateURL } from "../../utils/utils";
import {
  MAX_CHARS_PER_STORY,
  SHOW_REMAINING_CHARS_THRESHOLD
} from "../../utils/constants";

const EditorWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const EditorContainer = styled.div`
  font-size: ${FontSize.SIZE_16};
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  flex: 1;
  margin-top: 91px;
  background-color: ${Color.WHITE};

  > div {
    max-height: calc(100vh - ${HEADER_HEIGHT_SMALL} - 35px);
  }

  @media ${Media.SMALL} {
    > div {
      transition: all 200ms ease-out;
    }

    &.ql-editor {
      overflow-y: visible;
    }

    &.ql-container.ql-snow {
      border: 0;
    }
  }
`;

const CharacterCount = styled.div<{ aboveLimit: boolean }>`
  font-size: ${FontSize.SIZE_10};
  color: ${({ aboveLimit }) => (aboveLimit ? Color.RED : Color.ACCENT_500)};
  position: absolute;
  top: 95px;
  right: 12px;
  background-color: ${Color.PRIMARY_TRANSPARENT};
  padding: 3px 6px;
  border-radius: 8px;
`;

const Toolbar = styled.div`
  position: fixed;
  top: 46px;
  background: ${Color.WHITE};
  padding: 10px;
  width: 100%;
  max-width: 960px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${Color.ACCENT_50};
  border-left: 1px solid ${Color.ACCENT_50};
  border-right: 1px solid ${Color.ACCENT_50};

  @media ${Media.SMALLEST} {
    max-width: 100%;
    overflow-x: scroll;
    justify-content: flex-start;
  }

  @media ${Media.SMALLER} {
    justify-content: center;
  }

  button {
    width: 56px;
    min-width: 45px;
    border-right: 1px solid ${Color.SEPARATOR};
    cursor: pointer;

    &:last-of-type {
      border: none;
    }

    &.ql-active {
      svg path {
        fill: #2eaadc;
      }
    }
  }
`;

const VIRTUAL_KEYBOARD_HEIGHT_IOS = "53vh";
const VIRTUAL_KEYBOARD_HEIGHT_ANDROID = "20vh";

const Wrapper = styled.div<{ isIPhone?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  /* Virtual keyboard is in place, reserve space */
  &.halfHeight {
    @media ${Media.SMALL} {
      ${EditorContainer} > div {
        ${({ isIPhone }) =>
          `height: calc(100vh - ${HEADER_HEIGHT_SMALL} - ${
            isIPhone
              ? VIRTUAL_KEYBOARD_HEIGHT_IOS
              : VIRTUAL_KEYBOARD_HEIGHT_ANDROID
          });
      max-height: calc(100vh - ${HEADER_HEIGHT_SMALL} - ${
            isIPhone
              ? VIRTUAL_KEYBOARD_HEIGHT_IOS
              : VIRTUAL_KEYBOARD_HEIGHT_ANDROID
          });`}
      }
    }
  }
`;

const Debug = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-family: Courier;
  z-index: 9999;
`;

const novelUseCase = new NovelUseCase();

// We will script format in markdown from server, then convert to HTML and paste into the editor
// While editing, content will be in HTML
// When sending back to server, the HTML will be converted to markdown
// Reference: https://github.com/quilljs/quill/issues/2514#:~:text=commented-,on%20Feb%2017%2C%202019,-I%20just%20wanted
interface Props {
  onChange(content: string): void;
  content: string;
  onError(msg: string): void;
  isLoading: boolean;
  isNew: boolean;
  setIsEmptyOrAboveLimit(isNotValid: boolean): void;
}

export const Editor: React.FC<Props> = ({
  onChange,
  content,
  onError,
  isLoading,
  isNew,
  setIsEmptyOrAboveLimit
}) => {
  const [charCount, setCharCount] = useState<number>(0);
  const [hasFocus, setHasFocus] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [isShowActivity, setShowActivity] = useState<boolean>(false);
  const [hasVirtualKeyboard, setHasVirtualKeyboard] = useState(false);
  const [isVirtualKeyboardVisible, setIsVirtualKeyboardVisible] = useState<
    boolean | null
  >(null);
  const [halfHeight, setHalfHeight] = useState(false);

  const { userAgent } = navigator;
  const isTouchable = "ontouchend" in document;
  const isIPhone =
    /\b(\w*iPhone\w*)\b/.test(userAgent) &&
    /\b(\w*Mobile\w*)\b/.test(userAgent) &&
    isTouchable;

  const modules = useMemo(
    () => ({
      toolbar: {
        container: "#toolbar"
      },
      history: {
        // Enable with custom configurations
        delay: 2500,
        maxStack: 500
      }
    }),
    []
  );

  const formats = ["bold", "underline", "italic", "header", "divider", "image"];

  const { Quill, quill, quillRef } = useQuill({ modules, formats });

  // const boldRef = useRef<HTMLButtonElement>(null);
  const undoRef = useRef<HTMLButtonElement>(null);
  const redoRef = useRef<HTMLButtonElement>(null);
  const dividerRef = useRef<HTMLButtonElement>(null);

  const handleUndo = useCallback(() => {
    quill?.getModule("history").undo();
  }, [quill]);

  useEffect(() => {
    let unsubscribe: () => void = () => {
      /* noop */
    };
    if (isSupported()) {
      if (!hasVirtualKeyboard) {
        setHasVirtualKeyboard(true);
      }
      unsubscribe = subscribe(visibility => {
        setIsVirtualKeyboardVisible(visibility === "visible");
      });
    }
    return unsubscribe;
  }, [hasVirtualKeyboard]);

  useEffect(() => {
    const undoElement = undoRef.current;
    if (undoElement) {
      undoElement.addEventListener("click", handleUndo);
    }
    return () => {
      undoElement?.removeEventListener("click", handleUndo);
    };
  }, [handleUndo, quill]);

  const handleRedo = useCallback(() => {
    quill?.getModule("history").redo();
  }, [quill]);

  useEffect(() => {
    const redoElement = redoRef.current;
    if (redoElement) {
      redoElement.addEventListener("click", handleRedo);
    }
    return () => {
      redoElement?.removeEventListener("click", handleRedo);
    };
  }, [handleRedo, quill]);

  const handleDivider = useCallback(() => {
    if (quill) {
      const range = quill.getSelection(true);
      quill.insertEmbed(range.index, "divider", true, Quill.sources.USER);
      quill.setSelection(range.index + 3, Quill.sources.SILENT);
    }
  }, [Quill, quill]);

  useEffect(() => {
    const dividerElement = dividerRef.current;
    if (dividerElement && quill) {
      const toolbar = quill.getModule("toolbar");
      toolbar.addHandler("divider", handleDivider);
    }
  }, [handleDivider, quill]);

  // Quill configuration prior to instantiate our current editor
  if (Quill && !quill) {
    // Set toolbar icons
    const icons = Quill.import("ui/icons");
    icons.bold =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9024 11.79C15.8487 11.12 16.5121 10.02 16.5121 9C16.5121 6.74 14.8048 5 12.6097 5H7.9754C7.43882 5 7 5.45 7 6V18C7 18.55 7.43882 19 7.9754 19H13.1268C15.1463 19 16.9902 17.31 16.9999 15.23C17.0097 13.7 16.1707 12.39 14.9024 11.79ZM9.439 7.5H12.3658C13.1756 7.5 13.8292 8.17 13.8292 9C13.8292 9.83 13.1756 10.5 12.3658 10.5H9.439V7.5ZM12.8536 16.5H9.439V13.5H12.8536C13.6634 13.5 14.317 14.17 14.317 15C14.317 15.83 13.6634 16.5 12.8536 16.5Z" fill="#62646E"/></svg>';
    icons.underline =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.6771 16.4C15.2743 16.0533 17.1429 13.6356 17.1429 10.9244V6.11111C17.1429 5.49778 16.6629 5 16.0714 5C15.48 5 15 5.49778 15 6.11111V11.0222C15 12.5067 14.0314 13.8578 12.6257 14.1511C10.6971 14.5689 9 13.04 9 11.1111V6.11111C9 5.49778 8.52 5 7.92857 5C7.33714 5 6.85714 5.49778 6.85714 6.11111V11.1111C6.85714 14.2844 9.54 16.8178 12.6771 16.4ZM6 19.85C6 20.3389 6.38571 21 6.85714 21H17.1429C17.6143 21 18 20.3389 18 19.85C18 19.3611 17.6143 18.75 17.1429 18.75H6.85714C6.38571 18.75 6 19.3611 6 19.85Z" fill="#62646E"/></svg>';
    icons.italic =
      '<svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.33333 0.5V3H5.175L2.325 11H0V13.5H6.66667V11H4.825L7.675 3H10V0.5H3.33333Z" fill="#62646E"/></svg>';
    icons.header[1] =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.36842 6.4C9.36842 7.17467 9.96789 7.8 10.7105 7.8H13.8421V17.6C13.8421 18.3747 14.4416 19 15.1842 19C15.9268 19 16.5263 18.3747 16.5263 17.6V7.8H19.6579C20.4005 7.8 21 7.17467 21 6.4C21 5.62533 20.4005 5 19.6579 5H10.7105C9.96789 5 9.36842 5.62533 9.36842 6.4ZM5.34211 12.4667H6.68421V17.6C6.68421 18.3747 7.28368 19 8.02632 19C8.76895 19 9.36842 18.3747 9.36842 17.6V12.4667H10.7105C11.4532 12.4667 12.0526 11.8413 12.0526 11.0667C12.0526 10.292 11.4532 9.66667 10.7105 9.66667H5.34211C4.59947 9.66667 4 10.292 4 11.0667C4 11.8413 4.59947 12.4667 5.34211 12.4667Z" fill="#62646E"/></svg>';
    icons.divider =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75 6C6.3375 6 6 5.55 6 5C6 4.45 6.3375 4 6.75 4H17.25C17.6625 4 18 4.45 18 5C18 5.55 17.6625 6 17.25 6H6.75Z" fill="#62646E"/><path d="M6.75 21C6.3375 21 6 20.55 6 20C6 19.45 6.3375 19 6.75 19H17.25C17.6625 19 18 19.45 18 20C18 20.55 17.6625 21 17.25 21H6.75Z" fill="#62646E"/><circle cx="5.5" cy="12.5" r="1.5" fill="#62646E"/><circle cx="18.5" cy="12.5" r="1.5" fill="#62646E"/><circle cx="12" cy="12.5" r="1.5" fill="#62646E"/></svg>';
    icons.image =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.2381 19.0952H3.90476V5.7619H12.4762V3.85714H3.90476C2.85714 3.85714 2 4.71429 2 5.7619V19.0952C2 20.1429 2.85714 21 3.90476 21H17.2381C18.2857 21 19.1429 20.1429 19.1429 19.0952V10.5238H17.2381V19.0952ZM9.81905 16.0762L7.95238 13.8286L5.33333 17.1905H15.8095L12.4381 12.7048L9.81905 16.0762ZM19.1429 3.85714V1H17.2381V3.85714H14.381C14.3905 3.86667 14.381 5.7619 14.381 5.7619H17.2381V8.60952C17.2476 8.61905 19.1429 8.60952 19.1429 8.60952V5.7619H22V3.85714H19.1429Z" fill="#62646E"/></svg>';
    icons.undo =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4181 8C10.0412 8 7.88846 8.99 6.22908 10.6L5.53381 8.71C4.96872 8.08 4 8.52 4 9.41V14C4 14.55 4.40363 15 4.89697 15H8.911C9.7093 15 10.1129 13.92 9.54785 13.29L7.83464 12.38C9.08143 11.22 10.6691 10.5 12.4271 10.5C15.2615 10.5 17.7102 12.34 18.8763 15C19.1185 15.56 19.6925 15.84 20.2217 15.64C20.8586 15.41 21.1815 14.6 20.8945 13.92C19.3517 10.42 16.1405 8 12.4181 8Z" fill="#62646E"/></svg>';
    icons.redo =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5819 8C14.9588 8 17.1115 8.99 18.7709 10.6L19.4662 8.71C20.0313 8.08 21 8.52 21 9.41V14C21 14.55 20.5964 15 20.103 15H16.089C15.2907 15 14.8871 13.92 15.4522 13.29L17.1654 12.38C15.9186 11.22 14.3309 10.5 12.5729 10.5C9.73848 10.5 7.28976 12.34 6.12371 15C5.88153 15.56 5.30747 15.84 4.77826 15.64C4.14141 15.41 3.8185 14.6 4.10553 13.92C5.64831 10.42 8.85945 8 12.5819 8Z" fill="#62646E"/></svg>';

    // Add HR extension
    const BlockEmbed = Quill.import("blots/block/embed");
    class DividerBlot extends BlockEmbed {}
    DividerBlot.blotName = "divider";
    DividerBlot.tagName = "hr";
    Quill.register(DividerBlot);
  }

  const imageFileRef = useRef<HTMLInputElement | null>(null);

  const contentImageClick = useCallback(() => {
    imageFileRef.current?.click();
  }, []);

  const setEditorFocus = useCallback(() => {
    if (quill) {
      quill.focus();
      setHasFocus(true);
    }
  }, [quill]);

  const contentImageHandler = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setShowActivity(true);
      if (quill) {
        quill.disable();
        const file = e.target.files?.[0];
        if (file) {
          const imageData = await loadWithOrientation(file);
          const canvas = imageData.image as HTMLCanvasElement;
          const base64 = canvas.toDataURL(file.type);
          const fileInfo = await getFileInfo(base64);
          if (fileInfo.isQR) {
            onError("この画像はアップできません。");
            setShowActivity(false);
            quill.enable();
            return;
          }
          const extension = file.name.split(".").pop() || "jpeg";
          const rpcImage = await novelUseCase.createImageOnServer(
            base64,
            extension,
            fileInfo
          );
          if (!rpcImage || !validateURL(rpcImage.servingUrl?.value)) {
            onError("画像のアップロードに失敗しました");
          } else {
            const range = quill.getSelection();
            if (range && rpcImage.servingUrl?.value) {
              quill.insertEmbed(range.index, "block", "p", "api");
              quill.insertEmbed(
                range.index + 1,
                "image",
                rpcImage.servingUrl.value,
                "api"
              );
            }
          }
        }
        setShowActivity(false);
        quill.enable();
        setTimeout(() => {
          setHasFocus(false);
        }, 500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quill, setHasFocus]
  );

  const scrollToCursor = useCallback(() => {
    if (quillRef.current) {
      const editor = quillRef.current.querySelector("div.ql-editor");
      // Detect current scroll position
      const top = editor.scrollTop;

      // Detect current cursor position
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const rect = range?.getClientRects()[0];

      // For some weird reason, safari webview scrolls up and the fixed header is lost
      // So we need to scroll to top the first thing on focus
      window.scrollTo(0, 0);
      // If the position is not near the top, scroll there adding a little padding
      if (rect && rect.y > 300) {
        editor.scrollTo({ top: top + 100, left: 0, behavior: "smooth" });
      }
    }
  }, [quillRef]);

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        const count = quill.getText().length - 1;
        setCharCount(count);
        setIsEmptyOrAboveLimit(count === 0 || count > MAX_CHARS_PER_STORY);
        const html = quillRef.current.firstChild.innerHTML;
        onChange(html);
      });

      quill.on("selection-change", (range, oldRange) => {
        if (range === null && oldRange !== null) {
          setHasFocus(false);
        } else if (range !== null && oldRange === null) {
          setHasFocus(true);
        }
      });

      if (content && content.length > 0) {
        quill.disable();
        quill.clipboard.dangerouslyPasteHTML(fromMarkdown(content));
        quill.enable();
        setContentLoaded(true);
      }
      quill.getModule("toolbar").addHandler("image", contentImageClick);

      quill.root.addEventListener("blur", () => {
        setHasFocus(false);
      });

      quill
        .getModule("toolbar")
        .container.addEventListener(
          "mousedown",
          (e: { preventDefault: () => void }) => {
            e.preventDefault();
          }
        );
    }
  }, [
    Quill,
    content,
    contentImageClick,
    onChange,
    quill,
    quillRef,
    scrollToCursor,
    setIsEmptyOrAboveLimit
  ]);

  useEffect(() => {
    // disable paste html with format after contents has been loaded
    if (quill) {
      if (isNew || (!isLoading && content && contentLoaded)) {
        const Delta = Quill.import("delta");
        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (_, delta) => {
          const newDelta = new Delta();
          delta.ops.forEach(op => {
            if (
              op.insert &&
              typeof op.insert === "object" &&
              // eslint-disable-next-line no-prototype-builtins
              op.insert.hasOwnProperty("image")
            ) {
              // 画像が貼り付けられた場合はスキップ
              return;
            }
            newDelta.ops.push(op);
          });
          return newDelta;
        });
      }
    }
  }, [Quill, content, contentLoaded, isLoading, isNew, quill]);

  useEffect(() => {
    const toHalf =
      isVirtualKeyboardVisible !== null && isVirtualKeyboardVisible && hasFocus;
    setHalfHeight(toHalf);
    // If the editor contents are long and the user taps below, for some reason
    // the window scrolls down making the header not viaible in iOS
    // This is a very weird effect, because the webview is marked as not scrollable
    // and even disabling scroll in body doesn't have any effect
    // So we force a scroll to top whenever the virtual keyboard is shown

    if (toHalf) {
      // When the editor height is cut in half for making space for the virtual keyboard
      // Try to scroll the editor div to the place where the user tapped
      // So is always visible around the center of the screen at least
      // This has to be executed using timeout (even if is 0). Quill set focus won't work otherwise
      setTimeout(() => {
        setEditorFocus();
        scrollToCursor();
      }, 0);
    }
  }, [
    hasFocus,
    isVirtualKeyboardVisible,
    quillRef,
    scrollToCursor,
    setEditorFocus
  ]);

  const debug = false;

  const {
    isOpen: isImageWarningModalOpen,
    open: openImageWarningModal,
    close: closeImageWarningModal,
    shouldShowImageWarning
  } = useImageUploadWarningModal();

  const imageUploadClick = useCallback(
    (e?: React.MouseEvent) => {
      if (shouldShowImageWarning) {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        setHasFocus(false);
        openImageWarningModal();
      }
    },
    [openImageWarningModal, shouldShowImageWarning]
  );

  const onCloseWarningModal = useCallback(() => {
    closeImageWarningModal();
    setTimeout(() => {
      setHasFocus(true);
      imageFileRef.current?.click();
    }, 1000);
  }, [closeImageWarningModal]);

  const charCountNotice = useMemo(() => {
    if (charCount > SHOW_REMAINING_CHARS_THRESHOLD) {
      return `${charCount.toLocaleString()} / ${MAX_CHARS_PER_STORY.toLocaleString()}文字`;
    }
    return `${charCount.toLocaleString()}文字`;
  }, [charCount]);

  const isAboveTheLimit = useMemo(() => charCount > MAX_CHARS_PER_STORY, [
    charCount
  ]);

  return (
    <>
      <Wrapper
        className={halfHeight ? "halfHeight" : ""}
        isIPhone={isIPhone}
        onDrop={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {isShowActivity ? <ActivityModal /> : null}
        <Toolbar id="toolbar">
          <button type="button" className="ql-bold" />
          <button type="button" className="ql-underline" />
          <button type="button" className="ql-italic" />
          <button type="button" className="ql-header" value="1" />
          <button type="button" className="ql-divider" ref={dividerRef} />
          <button type="button" className="ql-image" />
          <button type="button" className="ql-undo" ref={undoRef} />
          <button type="button" className="ql-redo" ref={redoRef} />
        </Toolbar>
        <EditorWrapper onClick={setEditorFocus}>
          <input
            ref={imageFileRef}
            type="file"
            accept="image/*"
            onChange={contentImageHandler}
            onClick={imageUploadClick}
            style={{ display: "none" }}
          />

          <EditorContainer ref={quillRef} />
          <CharacterCount aboveLimit={isAboveTheLimit}>
            {charCountNotice}
          </CharacterCount>
        </EditorWrapper>
        {debug ? (
          <Debug>{halfHeight ? "half height" : "full height"}</Debug>
        ) : null}
      </Wrapper>
      {shouldShowImageWarning ? (
        <ImageUploadWarningModal
          open={isImageWarningModalOpen}
          onClose={onCloseWarningModal}
        />
      ) : null}
    </>
  );
};

import useSWR from "swr";
import { useCallback, useState } from "react";
import { useAuth } from "./useAuth";
import { useModal, ModalHandle } from "./useModal";
import { fetchContestTags } from "../usecases/contestUseCase";

type ContestAcknowledgeHandle = {
  checkContestTags(episodeTags: string[]): boolean;
  openContestModal: (episodeTags: string[]) => void;
  selectedContestTag: string | undefined;
} & Omit<ModalHandle, "open">;

export const useContestAcknowledge = (): ContestAcknowledgeHandle => {
  const { user } = useAuth();
  const { data: contestTags } = useSWR(
    user ? "contests" : null,
    fetchContestTags
  );

  const { isOpen, open, close } = useModal();

  const [selectedContestTag, setSelectedContestTag] = useState<
    string | undefined
  >(undefined);

  const checkContestTags = useCallback(
    (episodeTags: string[]) =>
      contestTags?.some(t => episodeTags.includes(t)) || false,
    [contestTags]
  );

  const openContestModal = useCallback(
    (episodeTags: string[]) => {
      // NOTE: 現状複数のコンテストが同時にないという前提で、最初のコンテストタグだけを選択する
      const firstContestTag = contestTags?.find(t => episodeTags.includes(t));
      setSelectedContestTag(firstContestTag);
      open();
    },
    [contestTags, open]
  );

  return {
    isOpen,
    openContestModal,
    selectedContestTag,
    close,
    checkContestTags
  };
};

// eslint-disable-next-line import/no-extraneous-dependencies
import { FirebaseError } from "@firebase/util";
import styled from "@emotion/styled";
import { useEffectOnce } from "react-use";
import { useHistory } from "react-router-dom";
import { useAppParameters } from "../hooks/useAppParameters";
import { AuthUseCase } from "../usecases/authUseCase";
import { Color } from "../components/styles/enums";
import { Image } from "../components/ui/image";
import { Text } from "../components/ui/text";
import spinner from "../assets/spinner.svg";

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  height: 100vh;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = styled.div`
  vertical-align: middle;
`;

const LoadingIndicator = styled(Image)`
  height: 40px;
  vertical-align: middle;
  padding-left: 5px;
`;
const authUseCase = new AuthUseCase();

export const GatewayContainer: React.FC = () => {
  const { storeAppParameters } = useAppParameters();
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffectOnce(() => {
    if (!token) {
      window.location.href = "teller-studio://back";
      return;
    }
    authUseCase
      .loginByToken(token)
      .then(credentials => {
        storeAppParameters();
        if (credentials) {
          history.push("/");
        }
      })
      .catch(err => {
        if (
          err instanceof FirebaseError &&
          err.code === "auth/invalid-custom-token"
        ) {
          history.push("/error/invalid_token");
        } else {
          throw err;
        }
      });
  });

  return (
    <PageWrapper>
      <div>
        <Loading>
          <Text mb={10} fw="bold" color={Color.ACCENT_500}>
            読み込み中
          </Text>
          <LoadingIndicator src={spinner} />
        </Loading>
      </div>
    </PageWrapper>
  );
};

import { PropsWithChildren, useCallback } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { Image } from "../../ui/image";
import { Text } from "../../ui/text";
import { Color, FontSize, Media, ZIndex } from "../../styles/enums";
import { ellipsis } from "../../styles/mixins/ellipsis";

import backIcon from "../../../assets/header/back_icon.svg";
import closeIcon from "../../../assets/header/close_icon.svg";
import moreIcon from "../../../assets/more_icon.png";

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: ${ZIndex.HEADER};
  width: 100%;
  line-height: 0;
  background-color: ${Color.WHITE};
  border-bottom: 1px solid ${Color.SEPARATOR};
`;

const SmallHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${FontSize.SIZE_16};
  background: ${Color.WHITE};
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  @media ${Media.SMALL} {
    max-width: 60.4em;
    width: 100%;
    min-width: 100%;
  }

  @media ${Media.LARGE} {
    max-width: 960px;
    margin: auto;
  }
`;

const SmallHeaderTitle = styled(Text)`
  font-size: ${FontSize.SIZE_16};
  font-weight: bold;
  flex: 1;
  text-align: center;
  text-align: center;
  ${ellipsis("80%")}
`;

const Empty = styled.div`
  width: 30px;
`;

const Left = styled.span`
  height: 21px;
  display: flex;
  justify-content: left;
  width: 30px;
`;

const LeftLink = styled(Link)`
  display: flex;
  width: 30px;

  &:hover {
    opacity: 0.7;
  }
`;

const Right = styled.span`
  justify-content: flex-end;
  text-align: right;
  display: flex;
  height: 21px;
  position: relative;
  width: 30px;
`;

const RightLink = styled.button`
  display: flex;
  justify-content: flex-end;
  min-width: 40px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  width: 30px;

  &:hover {
    opacity: 0.7;
  }

  span {
    color: ${Color.TINT};
    font-weight: 700;
    font-size: ${FontSize.SIZE_15};
  }
`;

const MoreIcon = styled(Image)`
  width: 18px;
  min-width: 18px;
  margin-top: 8px;
`;

const BackButtonPosition = styled.div`
  width: 30px;
`;

type Props = PropsWithChildren<{
  title?: string | null;
  backLink?: string;
  backAction?(): void;
  noBackButton?: boolean;
  moreButton?: boolean;
  moreButtonLabel?: string;
  onMoreButtonClick?(): void;
}>;

export const AppHeader: React.FC<Props> = ({
  title,
  backLink,
  backAction,
  noBackButton,
  moreButton,
  moreButtonLabel,
  onMoreButtonClick,
  children
}) => {
  const closeCallback = useCallback(() => {
    window.location.href = "teller-studio://back";
  }, []);

  const backCallback = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!backLink && backAction) {
        e.preventDefault();
        e.stopPropagation();
        backAction();
      }
    },
    [backAction, backLink]
  );

  return (
    <Wrapper>
      <SmallHeader>
        <BackButtonPosition>
          {!noBackButton ? (
            <Left>
              <LeftLink to={backLink || "/"} onClick={backCallback}>
                <Image src={backIcon} />
              </LeftLink>
            </Left>
          ) : (
            <Left>
              <LeftLink to="#" onClick={closeCallback}>
                <Image src={closeIcon} />
              </LeftLink>
            </Left>
          )}
        </BackButtonPosition>
        <SmallHeaderTitle>{title}</SmallHeaderTitle>
        {moreButton ? (
          <Right>
            <RightLink onClick={onMoreButtonClick}>
              {moreButtonLabel ? (
                <span>{moreButtonLabel}</span>
              ) : (
                <MoreIcon src={moreIcon} />
              )}
            </RightLink>
          </Right>
        ) : (
          <Empty />
        )}
      </SmallHeader>
      {children}
    </Wrapper>
  );
};

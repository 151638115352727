import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import vipBanner from "../../assets/header/header_pulldown_vip_banner.png";
import vipBannerWebp from "../../assets/header/header_pulldown_vip_banner.webp";

// Components
import { Color, FontSize, ZIndex } from "../styles/enums";
import { Image } from "../ui/image";
import { UsernameWithBadges } from "./usernameWithBadges";

const Wrapper = styled.div`
  position: relative;
  z-index: ${ZIndex.SHARE_BUTTON};
`;

const ClickWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 43px;

  &:hover {
    opacity: 0.7;
  }
`;

const Thumbnail = styled(Image)`
  cursor: pointer;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
`;

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ActionSheet = styled.div`
  position: absolute;
  background: ${Color.WHITE};
  right: 0;
  left: calc(50% - 160px);
  margin: 0 auto;
  top: 44px;
  width: 240px;
  color: ${Color.ACCENT_1000};
  padding-top: 12px;
  border-radius: 6px;
  box-shadow: 0 0 48px rgba(0, 0, 0, 0.3);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    right: 0;
    left: 42px;
    margin: 0 auto;
    width: 16px;
    height: 16px;
    border-style: solid;
    border-width: 0 8px 13.9px 8px;
    border-color: transparent transparent ${Color.WHITE} transparent;
  }
`;

const OptionsList = styled.ul`
  display: grid;
  padding-left: 8px;
  padding-right: 8px;
  grid-template-rows: 1fr 1fr 1fr;
  font-size: ${FontSize.SIZE_14};
`;

const Option = styled.li`
  padding-bottom: 5px;

  &:last-child {
    padding-bottom: 0;
  }

  > a,
  > button {
    display: flex;
    justify-content: left;
    border-radius: 6px;
    padding: 5px 15px;
    cursor: pointer;
    line-height: 30px;
    color: ${Color.ACCENT_1000};
    width: 100%;

    &:hover {
      background: ${Color.STRONG_BG};
    }
  }
`;

const VipBanner = styled.a`
  border-top: 1px solid ${Color.SEPARATOR};
  padding-top: 3px;
`;

interface Props {
  thumbnailUrl: string;
  username: string;
  isVip: boolean;
  isOfficialWriter: boolean;
  wasAlreadyVip: boolean;
  openPopup: () => void;
  closePopup: () => void;
  isOpen: boolean;
  onLogout: () => void;
}

export const UserOptionsPopup: React.FC<Props> = ({
  thumbnailUrl,
  username,
  isVip,
  isOfficialWriter,
  wasAlreadyVip,
  openPopup,
  closePopup,
  isOpen,
  onLogout
}) => {
  const vipPageLink = !wasAlreadyVip ? "/vip_offer" : "/profile/plan_selection";

  const curatedUsername =
    username && username.length > 8 ? `${username.substr(0, 8)} ...` : username;

  return (
    <Wrapper>
      <ClickWrapper onClick={openPopup}>
        <Thumbnail
          src={thumbnailUrl}
          alt={curatedUsername}
          width="28"
          height="28"
        />
        <UsernameWithBadges
          header
          username={curatedUsername}
          isVip={isVip}
          isOfficialWriter={isOfficialWriter}
        />
      </ClickWrapper>
      {isOpen ? (
        <div>
          <Scrim onClick={closePopup} />
          <ActionSheet>
            <OptionsList>
              <Option>
                <a href={`//${process.env.REACT_APP_WEB_DOMAIN}/my_page`}>
                  マイページ／設定
                </a>
              </Option>
              <Option>
                <Link to="/" onClick={closePopup}>
                  自分のストーリー
                </Link>
              </Option>
              <Option>
                <button type="button" onClick={onLogout}>
                  ログアウト
                </button>
              </Option>
            </OptionsList>
            {!isVip ? (
              <VipBanner
                href={`//${process.env.REACT_APP_WEB_DOMAIN}${vipPageLink}`}
              >
                <picture>
                  <source type="image/webp" srcSet={vipBannerWebp} />
                  <Image src={vipBanner} lazy width="240" height="99" />
                </picture>
              </VipBanner>
            ) : null}
          </ActionSheet>
        </div>
      ) : null}
    </Wrapper>
  );
};

// https://www.figma.com/file/SjYKsDIXB2uTv4wXURb4K3if/TELLER-DESIGN-GUIDELINE?node-id=1%3A27
export enum Color {
  ACCENT_1000 = "#313238",
  ACCENT_700 = "#62646e",
  ACCENT_500 = "#989ba1",
  ACCENT_100 = "#d7d8da",
  ACCENT_50 = "#edeeef",
  PRIMARY = "#fafafa",
  DIALOG_BG = "#f0f0f0",
  PRIMARY_TRANSPARENT = "rgb(250, 250, 250, 0.8)",
  OVERLAY_BG = "rgba(0,0,0,0.5)",
  TINT = "#1b88ff",
  LIGHT_TINT = "#e0efff",
  STRONG_BG = "#ebedf1",
  SEPARATOR = "#d7d8da",
  HIGHLIGHT = "#e9f0f7",
  WHITE = "#fff",
  YELLOW_BG = "#F9F5E8",
  RED = "#f04a40",
  PINK = "#eb5757",
  GREEN = "#51ad93",
  GOLD = "#efb035",
  YELLOW = "#F2C94C",
  SILVER = "#9fb4bd",
  COPPER = "#be674c",
  GREY = "#313238",
  GREY_TRANSPARENT = "rgb(49, 50, 56, 0.8)",
  LIGHT_GREY = "#EBEBEC",
  LINK = "#1d5084",
  DISABLED = "rgba(118, 118, 128, 0.12);"
}

import styled from "@emotion/styled";
import { css } from "@emotion/react/macro";

import React, { useCallback, useState } from "react";
import { Text } from "../ui/text";
import { Image } from "../ui/image";
import { Color, FontSize } from "../styles/enums";
import { Button, ButtonVariant } from "../ui/button";
import { Modal } from "./modal";

import novelButton from "../../assets/novel_selector.png";
import chatButton from "../../assets/chat_selector.png";
import loadingIcon from "../../assets/spinner.svg";

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  height: 134px;
  position: relative;

  > img {
    width: 47%;
    cursor: pointer;
    border-radius: 10px;
  }
`;

const Wrapper = styled.div<{ selected?: number }>`
  background-color: ${Color.PRIMARY};

  .loading {
    ${({ selected }) => `opacity: ${selected ? 1 : 0};`}
  }

  img,
  button {
    transition: opacity ease-in 200ms;
  }

  button {
    ${({ selected }) => `opacity: ${selected ? 0.2 : 1};`}
  }

  button:focus {
    outline: none;
  }

  img:focus {
    outline: none;
  }

  .wrapper {
    img:nth-of-type(1) {
      ${({ selected }) =>
        selected
          ? css`
              opacity: ${selected === 2 ? 1 : 0.2};
              border: ${selected === 2 ? "4px solid #1b88ff;" : ""};
            `
          : null}
    }

    img:nth-of-type(2) {
      ${({ selected }) =>
        selected
          ? css`
              opacity: ${selected === 1 ? 1 : 0.2};
              border: ${selected === 1 ? "4px solid #1b88ff;" : ""};
            `
          : null}
    }
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: ${FontSize.SIZE_16};
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 24px;
`;

const LoadingIcon = styled.img`
  width: 40px;
  padding: 0;
  margin: -30px auto 0 auto;
  display: block;
  opacity: 0;
`;

interface Props {
  open: boolean;
  onClose(): void;
  onNovelClicked(): void;
  onChatClicked(): void;
}

export const StoryTypeModal: React.FC<Props> = ({
  open,
  onClose,
  onNovelClicked,
  onChatClicked
}) => {
  const [selected, setSelected] = useState<undefined | number>(undefined);

  const toStudio = useCallback(() => {
    setSelected(1);
    onChatClicked();
  }, [onChatClicked]);

  const toNovelStudio = useCallback(() => {
    setSelected(2);
    onNovelClicked();
  }, [onNovelClicked]);

  return (
    <Modal open={open} width="326px" minSmallHeight="200" close={onClose}>
      <Wrapper selected={selected}>
        <Title>どちらのスタジオを開きますか？</Title>
        <LoadingIcon className="loading" src={loadingIcon} />
        <SelectWrapper className="wrapper">
          <Image src={novelButton} onClick={toNovelStudio} />
          <Image src={chatButton} onClick={toStudio} />
        </SelectWrapper>

        <Button
          type="submit"
          variant={ButtonVariant.OUTLINED}
          color={Color.ACCENT_1000}
          block
          big
          mt={24}
          mb={12}
          onClick={onClose}
        >
          閉じる
        </Button>
      </Wrapper>
    </Modal>
  );
};

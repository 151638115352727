import { useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "react-use";
import { query_types as RpcQueryTypes } from "../infra/api/rpc/api";
import {
  APP_PARAMETER_IS_AGREE_POST_RULE,
  useAppParameters
} from "./useAppParameters";
import { useModal } from "./useModal";
import { useAuth } from "./useAuth";
import { Platform } from "../utils/constants";
import { isAvailable } from "../utils/appUtil";

interface GuidelinesModalHandle {
  isOpen: boolean;
  handleOnEnter: () => void;
}

export const useGuidelinesModalForNewUser = (): GuidelinesModalHandle => {
  const { isAgreePostRule, appVersion, isApp } = useAppParameters();
  const [, setIsAgreePostRule] = useLocalStorage<boolean>(
    APP_PARAMETER_IS_AGREE_POST_RULE
  );
  const { tellerUser } = useAuth();
  // ガイドラインを表示するか
  const needShowGuidelines = useMemo(() => {
    if (
      !tellerUser ||
      tellerUser.userRole === RpcQueryTypes.UserRole.USER_ROLE_OFFICIAL_WRITER
    ) {
      return false;
    }

    if (isApp && typeof appVersion === "string") {
      // Androidアプリで開かれていて、アプリのバージョンが8.32.0未満のときはモーダルを表示しない
      if (
        !isAvailable(
          {
            version: "8.32.0",
            platform: Platform.ANDROID
          },
          {
            version: appVersion,
            platform: Platform.ANDROID
          }
        )
      )
        return false;

      // iOSアプリで開かれていて、アプリのバージョンが8.32.0未満のときはモーダルを表示しない
      if (
        !isAvailable(
          {
            version: "8.32.0",
            platform: Platform.IOS
          },
          {
            version: appVersion,
            platform: Platform.IOS
          }
        )
      )
        return false;
    }

    // すでに同意している場合は表示しない
    if (isAgreePostRule) return false;
    return (tellerUser.searchableStoryCount?.value || 0) === 0;
  }, [appVersion, isAgreePostRule, isApp, tellerUser]);
  const { isOpen, open, close } = useModal();

  // モーダルを表示する
  useEffect(() => {
    if (!needShowGuidelines) return;
    open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needShowGuidelines]);

  const handleOnEnter = useCallback(() => {
    setIsAgreePostRule(true);
    close();
  }, [close, setIsAgreePostRule]);

  return {
    isOpen,
    handleOnEnter
  };
};

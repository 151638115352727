// Core
import styled from "@emotion/styled/macro";

// Components
import { FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";

interface Props {
  stories?: number | null;
  followers?: number | null;
  followees?: number | null;
}

const UserStatsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 230px;
`;

const Stats = styled.div`
  text-align: center;
`;

const StatsNumber = styled(Text)`
  font-size: ${FontSize.SIZE_16};
  font-weight: 600;
`;

const StatsCategory = styled(Text)`
  font-size: ${FontSize.SIZE_10};
  color: ${Color.ACCENT_500};
  margin-top: -5px;
`;

export const UserStats: React.FC<Props> = ({
  stories,
  followers,
  followees
}) => (
  <UserStatsWrapper>
    <Stats>
      <StatsNumber>{stories?.toLocaleString() || 0}</StatsNumber>
      <StatsCategory>ストーリー</StatsCategory>
    </Stats>
    <Stats>
      <StatsNumber>{followers?.toLocaleString() || 0}</StatsNumber>
      <StatsCategory>フォロワー</StatsCategory>
    </Stats>
    <Stats>
      <StatsNumber>{followees?.toLocaleString() || 0}</StatsNumber>
      <StatsCategory>フォロー</StatsCategory>
    </Stats>
  </UserStatsWrapper>
);

import { createContext } from "react";
import useSWR, { mutate } from "swr";

export type MoreButtonAction = "showNovelActionSheet" | "link";

export interface UIState {
  title?: string;
  description?: string;
  noLayout?: boolean;
  noScroll?: boolean;
}

export const UI_STATE_KEY = "UI_STATE";

export const INITIAL_STATE: UIState = {
  title: "TELLER Novel Studio",
  description: "",
  noLayout: false,
  noScroll: false
};

export type UIContextType = {
  state: UIState;
  setState: (state: UIState) => void;
};

interface ProviderProps {
  children?: React.ReactNode;
}

export const UIContext = createContext<UIContextType>({
  state: INITIAL_STATE,
  setState: (_state: UIState) => {
    // noop
  }
});

export const UIContextProvider = ({
  children
}: ProviderProps): React.ReactElement => {
  const { data: state = INITIAL_STATE } = useSWR(UI_STATE_KEY, {
    initialData: INITIAL_STATE
  });

  const setState = (newState: UIState): void => {
    mutate(
      UI_STATE_KEY,
      {
        ...state,
        ...newState
      },
      false
    );
  };

  return (
    <UIContext.Provider value={{ state, setState }}>
      {children}
    </UIContext.Provider>
  );
};

UIContextProvider.defaultProps = {
  children: null
};

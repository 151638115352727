import React, { memo, useMemo } from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as Description } from "@material-symbols/svg-400/outlined/description.svg";

// Hooks
import { useAppParameters } from "../../hooks/useAppParameters";

// Components
import { Modal } from "./modal";
import { FontSize, Color } from "../styles/enums";
import { Image } from "../ui/image";
import { Button, ButtonVariant } from "../ui/button";

// Assets
import headerPng from "../../assets/guidelines_modal_for_new_user_header.png";
import headerWebp from "../../assets/guidelines_modal_for_new_user_header.webp";

// Utils
import {
  COMMUNITY_GUIDELINE_COPYRIGHT_PROTECTION_LINK,
  COMMUNITY_GUIDELINE_LINK,
  TERMS_OF_SERVICE_LINK
} from "../../utils/constants";

const ContentWrapper = styled.div`
  text-align: center;
`;

const GuidelinesImage = styled(Image)`
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 24px 24px 32px;
`;

const ButtonsWrapper = styled.div`
  max-width: 295px;
  margin: auto;
  > button {
    font-size: ${FontSize.SIZE_12};
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.a`
  color: ${Color.TINT};

  > p {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 14px;

    > svg {
      margin-right: 0.2em;
    }
  }
`;

interface Props {
  open: boolean;
  onEnterButton(): void;
}

/**
 * 新規ユーザが初めてストーリーを書き始めるときに表示するモーダル
 * @param param0
 * @returns
 */
const GuidelinesModalForNewUserToMemo: React.FC<Props> = ({
  open,
  onEnterButton
}) => {
  const { isApp } = useAppParameters();

  const termOfServiceLink = useMemo(
    (): string =>
      isApp
        ? `teller-studio://in-app-webview?url=${TERMS_OF_SERVICE_LINK}?nolayout=1`
        : TERMS_OF_SERVICE_LINK,
    [isApp]
  );

  const communityGuidelineLink = useMemo(
    (): string =>
      isApp
        ? `teller-studio://in-app-webview?url=${COMMUNITY_GUIDELINE_LINK}?nolayout=1`
        : COMMUNITY_GUIDELINE_LINK,
    [isApp]
  );

  const communityGuidelineCopyyrightProtectionLink = useMemo(
    (): string =>
      isApp
        ? `teller-studio://in-app-webview?url=${COMMUNITY_GUIDELINE_COPYRIGHT_PROTECTION_LINK}?nolayout=1`
        : COMMUNITY_GUIDELINE_COPYRIGHT_PROTECTION_LINK,
    [isApp]
  );

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width="400px"
      height="auto"
      noPadding
      hideClose
      overflowScrolling={false}
      close={() => {
        // nop
      }}
    >
      <ContentWrapper>
        <picture>
          <source srcSet={headerWebp} type="image/webp" />
          <GuidelinesImage src={headerPng} />
        </picture>
        <MainContainer>
          <LinksContainer>
            <Link
              href={termOfServiceLink}
              target={isApp ? "_self" : "_blank"}
              rel="noreferrer"
            >
              <p>
                <Description width="14px" height="14px" fill={Color.TINT} />
                利用規約
              </p>
            </Link>
            <Link
              href={communityGuidelineLink}
              target={isApp ? "_self" : "_blank"}
              rel="noreferrer"
            >
              <p>
                <Description width="14px" height="14px" fill={Color.TINT} />
                コミュニティガイドライン
              </p>
            </Link>
            <Link
              href={communityGuidelineCopyyrightProtectionLink}
              target={isApp ? "_self" : "_blank"}
              rel="noreferrer"
            >
              <p>
                <Description width="14px" height="14px" fill={Color.TINT} />
                画像アップロード時の注意（著作権編）
              </p>
            </Link>
          </LinksContainer>
          <ButtonsWrapper>
            <Button
              type="button"
              variant={ButtonVariant.PILL}
              color={Color.TINT}
              block
              big
              mt={20}
              onClick={onEnterButton}
            >
              はじめる
            </Button>
          </ButtonsWrapper>
        </MainContainer>
      </ContentWrapper>
    </Modal>
  );
};

export const GuidelinesModalForNewUser = memo(GuidelinesModalForNewUserToMemo);

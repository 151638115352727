import { initializeAnalytics } from "firebase/analytics";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "./App";
import { UAContext } from "./hooks/useUA";
import { parseUA } from "./infra/ua";
import { reportWebVitals } from "./reportWebVitals";

const firebaseConfig: Record<string, unknown> = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
      : undefined
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://8c0f189f59e0484687174a68f69ac9d9@o429046.ingest.sentry.io/6388321",
    integrations: [new BrowserTracing()],
    ignoreErrors: [
      // https://sentry.io/share/issue/9f0bcd78d9fe46b1ac07efda7d422547/
      "ネットワークエラーです",
      // https://sentry.io/share/issue/a85fe7cc2bfa466caca8f1e4eb7ba47b/
      "Network Error",
      // https://sentry.io/share/issue/bd617ebb4a74480eba3e55c001b5d94b/
      // HACK: Prefetch is meant to return a `Promise`, however in this instance
      //       it returns `undefined`, meaning that this throws:
      //       https://github.com/GoogleChromeLabs/quicklink/blob/4044de01df18e99629657faf19c529fbeb4496e5/src/index.mjs#L117
      "Cannot read property 'then' of undefined",
      "FirebaseError",
      "api request 404",
      "ChunkLoadError",
      /IDBDatabase/,
      /Indexed Database/,
      /auth\/internal-error/
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    sampleRate: 0.1
  });
}

const app = initializeApp(firebaseConfig);

export const auth = (): Auth => {
  if (!app) {
    initializeApp(firebaseConfig);
  }
  return getAuth(app);
};

// Reference: https://firebase.google.com/docs/reference/js/analytics.md#initializeanalytics
export const analytics = initializeAnalytics(app, {
  config: {
    send_page_view: false
  }
});

export const remoteConfig = getRemoteConfig(app);
// From Firebase setup guide https://firebase.google.com/docs/remote-config/get-started?platform=web
// During development, it's recommended to set a relatively low minimum fetch interval. See Throttling for more information.
if (process.env.NODE_ENV !== "production") {
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
}

fetchAndActivate(remoteConfig);

const ua = parseUA(navigator.userAgent);

ReactDOM.render(
  <StrictMode>
    <UAContext.Provider value={ua}>
      <BrowserRouter basename="/novel-studio">
        <App />
      </BrowserRouter>
    </UAContext.Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  // eslint-disable-next-line import/no-named-default
  default as loadImage,
  LoadImageOptions,
  parseMetaData
} from "blueimp-load-image";
import jsQR from "jsqr";

import { resource as RpcResource } from "../infra/api/rpc/api";

const supportsAutomaticRotation = (): Promise<boolean> => {
  // Black 2x1 JPEG, with the following meta information set:
  // - EXIF Orientation: 6 (Rotated 90° CCW)
  // Source: https://github.com/blueimp/JavaScript-Load-Image
  const testAutoOrientationImageURL =
    "data:image/jpeg;base64,/9j/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAYAAAA" +
    "AAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBA" +
    "QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQE" +
    "BAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAEAAgMBEQACEQEDEQH/x" +
    "ABKAAEAAAAAAAAAAAAAAAAAAAALEAEAAAAAAAAAAAAAAAAAAAAAAQEAAAAAAAAAAAAAAAA" +
    "AAAAAEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwA/8H//2Q==";
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      // Check if browser supports automatic image orientation:
      const supported = img.width === 1 && img.height === 2;
      resolve(supported);
    };
    img.src = testAutoOrientationImageURL;
  });
};

export const loadWithOrientation = (
  file: File
): Promise<loadImage.LoadImageResult> => {
  const MAX_SIZE = 1125;
  const options: LoadImageOptions = {
    maxWidth: MAX_SIZE,
    maxHeight: MAX_SIZE,
    canvas: true,
    pixelRatio: window.devicePixelRatio
  };

  return new Promise<loadImage.LoadImageResult>(resolve => {
    supportsAutomaticRotation().then(result => {
      if (result) {
        resolve(loadImage(file, options));
      } else {
        parseMetaData(file, data => {
          if (data.exif) {
            // options の orientation は小文字。 exif.getの 'Orientation' は先頭大文字
            // ここでcanvasの回転を指定している
            options.orientation = data.exif.get(
              "Orientation"
            ) as loadImage.Orientation;
          }
          // 画像の読み込み。完了時に callback が呼び出される
          resolve(loadImage(file, options));
        });
      }
    });
  });
};

export type ImageInfo = {
  width: number;
  height: number;
  isQR: boolean;
};

const isQR = (image: HTMLImageElement): boolean => {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return false;
  }
  ctx.drawImage(image, 0, 0);
  const imageData = ctx.getImageData(0, 0, image.width, image.height);
  const data = jsQR(imageData.data, imageData.width, imageData.height);
  return data !== null;
};

export const getFileInfo = (dataURL: string): Promise<ImageInfo> =>
  new Promise(resolve => {
    const image = new Image();
    image.src = dataURL;
    image.onload = () => {
      resolve({
        width: image.naturalWidth,
        height: image.naturalHeight,
        isQR: isQR(image)
      });
    };
  });

export const getImageFormatType = (type?: string): RpcResource.ImageFormat => {
  if (!type) {
    return RpcResource.ImageFormat.IMAGE_FORMAT_UNSPECIFIED;
  }
  const t = {
    "image/jpeg": RpcResource.ImageFormat.IMAGE_FORMAT_JPEG,
    "image/png": RpcResource.ImageFormat.IMAGE_FORMAT_PNG,
    "image/gif": RpcResource.ImageFormat.IMAGE_FORMAT_GIF,
    "image/webp": RpcResource.ImageFormat.IMAGE_FORMAT_WEBP
  }[type];
  return t || RpcResource.ImageFormat.IMAGE_FORMAT_UNSPECIFIED;
};

import {
  query_recursive_types as RpcQueryRecursiveTypes,
  mutation_story as RpcMutationStory,
  mutation_series as RpcMutationSeries,
  story as RpcStory,
  query_types as RpcQueryTypes,
  resource as RpcResource,
  types as RpcTypes,
  story_video as RpcStoryVideo
} from "../infra/api/rpc/api";

import {
  QueryRepository,
  QuickQueryParams
} from "../repositories/queryRepository";

import { MutationRepository } from "../repositories/mutationRepository";
import { FirebaseRepository } from "../repositories/firebaseRepository";
import { ResourceRepository } from "../repositories/resourceRepository";
import { rpcGenreToGenre } from "../models/genre";
import {
  Episode,
  copyRpcSeriesDataToEpisode,
  episodeFromRpcStory
} from "../models/episode";
import {
  Novel,
  novelFromRpcSeries,
  PublishRange,
  publishRangeFromRpcSharedWithStatus
} from "../models/novel";
import { ImageInfo, getImageFormatType } from "../utils/imageUtils";

export const STORIES_PER_PAGE = 20;

export type CreateEpisodeResponse = {
  episodeId: string;
  novelId: string;
};

type SeriesIndexStory = {
  [seriesIndex: number]: RpcQueryRecursiveTypes.IStoryResponse;
};

export class NovelUseCase {
  private queryRepository: QueryRepository;

  private mutationRepository: MutationRepository;

  private firebaseRepository: FirebaseRepository;

  private resourceRepository: ResourceRepository;

  constructor() {
    this.queryRepository = new QueryRepository();
    this.mutationRepository = new MutationRepository();
    this.firebaseRepository = new FirebaseRepository();
    this.resourceRepository = new ResourceRepository();
  }

  public fetchUserNovels = (
    cursor?: string,
    page?: number,
    limit?: number
  ): Promise<RpcQueryRecursiveTypes.IMySeriesCursorResponse | null> =>
    new Promise<RpcQueryRecursiveTypes.IMySeriesCursorResponse | null>(
      (resolve, reject) => {
        const params: QuickQueryParams = {
          requestId: `stories-${cursor ? `-c-${cursor.slice(-10)}` : ""}`,
          userStoriesRequest: {
            seriesPagination: {
              cursor,
              limit: limit || STORIES_PER_PAGE
            }
          }
        };
        this.queryRepository.quickQuery([params]).then(response => {
          if (response.responseList[0]?.error) {
            reject(response.responseList[0]?.error?.message?.value);
            return;
          }
          const responseItem = response.responseList[0]?.me?.seriesPage;
          if (!responseItem) {
            resolve(null);
            return;
          }
          resolve(responseItem);
        });
      }
    );

  public fetchNovel = (
    userId: string,
    novelId: string
  ): Promise<RpcQueryRecursiveTypes.ISeriesResponse | null> =>
    new Promise<RpcQueryRecursiveTypes.ISeriesResponse | null>(resolve => {
      const params: QuickQueryParams = {
        requestId: `novel-${novelId}`,
        novelRequest: {
          novelId,
          userId
        }
      };
      this.queryRepository.quickQuery([params]).then(response => {
        const responseItem = response.responseList[0]?.series;
        if (!responseItem) {
          resolve(null);
          return;
        }
        resolve(responseItem);
      });
    });

  public fetchNovelEpisodes = (
    novelId: string,
    page?: number,
    limit?: number
  ): Promise<RpcQueryRecursiveTypes.ISeriesStoryPageResponse | null> =>
    new Promise<RpcQueryRecursiveTypes.ISeriesStoryPageResponse | null>(
      resolve => {
        const params: QuickQueryParams = {
          requestId: `novel-${novelId}-episodes-${page ? `-p-${page}` : ""}`,
          novelEpisodesRequest: {
            novelId,
            episodePagination: {
              page: page || 0,
              limit: limit || STORIES_PER_PAGE
            }
          }
        };
        this.queryRepository.quickQuery([params]).then(response => {
          const responseItem = response.responseList[0].series?.storyPage;
          if (!responseItem) {
            resolve(null);
            return;
          }
          resolve(responseItem);
        });
      }
    );

  public fetchEpisode = (
    episodeId: string,
    withScript = false
  ): Promise<RpcQueryRecursiveTypes.IStoryResponse | null> =>
    new Promise<RpcQueryRecursiveTypes.IStoryResponse | null>(resolve => {
      const params: QuickQueryParams = {
        requestId: `episode-${episodeId}`,
        episodeRequest: {
          episodeId,
          withScript
        }
      };
      this.queryRepository.quickQuery([params]).then(response => {
        const responseItem = response.responseList[0]?.story;
        if (!responseItem) {
          resolve(null);
          return;
        }
        resolve(responseItem);
      });
    });

  public requestEpisodeVideoCreation = (episodeId: string): Promise<void> =>
    this.mutationRepository.requestVideoCreation(episodeId);

  public fetchEpisodeTikTok = (episodeId: string): Promise<boolean> =>
    new Promise<boolean>(resolve => {
      const params: QuickQueryParams = {
        requestId: `episode-${episodeId}-for-tiktok-`,
        episodeForTikTokRequest: {
          episodeId
        }
      };
      this.queryRepository.quickQuery([params]).then(response => {
        const responseItem =
          response.responseList[0]?.story?.video?.forTiktok?.status;
        if (!responseItem) {
          resolve(false);
          return;
        }
        resolve(
          responseItem ===
            RpcStoryVideo.StoryVideoStatus.STORY_VIDEO_STATUS_UP_TO_DATE ||
            responseItem ===
              RpcStoryVideo.StoryVideoStatus.STORY_VIDEO_STATUS_STALE
        );
      });
    });

  public ensureEpisode = (
    userId: string,
    userIsOfficial: boolean,
    defaultTags: string[] = [],
    novelId = "new",
    episodeId = "new",
    count?: number
  ): Promise<Episode | null> =>
    new Promise<Episode | null>(resolve => {
      if (episodeId !== "new") {
        this.fetchEpisode(episodeId, true).then(rpcEpisode => {
          if (!rpcEpisode) {
            resolve(null);
            return;
          }
          resolve({
            userId,
            tags:
              rpcEpisode?.series?.tags
                ?.map(t => t.value)
                .filter((x): x is string => typeof x === "string") || [],
            id: rpcEpisode?.id?.value ?? undefined,
            novelId: rpcEpisode?.series?.id?.value || "new",
            novelTitle: rpcEpisode?.series?.title?.value || "作品名",
            novelIndex: rpcEpisode?.seriesIndex?.value || 1,
            novelPublishFor: rpcEpisode?.series?.sharedWithStatus
              ? publishRangeFromRpcSharedWithStatus(
                  rpcEpisode.series.sharedWithStatus
                )
              : null,
            novelGenre: rpcEpisode?.series?.genre
              ? rpcGenreToGenre(rpcEpisode?.series?.genre)
              : null,
            title: rpcEpisode?.title?.value ?? "",
            description: "",
            thumbnailId: rpcEpisode?.series?.thumbnail?.id?.value ?? undefined,
            thumbnailUrl:
              rpcEpisode?.series?.thumbnail?.servingUrl?.value ?? undefined,
            sensitiveFlag: rpcEpisode?.sensitiveFlag ?? undefined,
            status: rpcEpisode?.status ?? undefined,
            novelScript:
              rpcEpisode?.novelScript?.fullScript?.value ?? undefined,
            scriptType:
              rpcEpisode?.scriptType ??
              RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_UNSPECIFIED,
            isOfficial: rpcEpisode?.isOfficial?.value ?? false,
            isPublished: (rpcEpisode.publishedAt?.seconds ?? 0) > 0
          });
        });
      } else {
        const newEpisode: Episode = {
          userId,
          title: "第1話",
          status: RpcStory.StoryStatus.DRAFT,
          scriptType: RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_NOVEL,
          novelTitle: "作品名",
          novelIndex: 1,
          novelPublishFor: null,
          novelGenre: null,
          tags: defaultTags,
          isOfficial: userIsOfficial,
          isPublished: false
        };
        if (novelId !== "new") {
          this.fetchNovel(userId, novelId).then(rpcNovel => {
            const episodeCount =
              count ||
              rpcNovel?.searchStory?.storyPage?.page?.totalCount?.value ||
              0;
            newEpisode.title = `第${episodeCount + 1}話`;
            newEpisode.novelId = novelId;
            newEpisode.novelTitle = rpcNovel?.title?.value || "作品名";
            newEpisode.novelIndex = episodeCount + 1;
            newEpisode.novelPublishFor = rpcNovel?.sharedWithStatus
              ? publishRangeFromRpcSharedWithStatus(rpcNovel.sharedWithStatus)
              : null;
            newEpisode.novelGenre = rpcNovel?.genre
              ? rpcGenreToGenre(rpcNovel?.genre)
              : null;
            newEpisode.thumbnailId = rpcNovel?.thumbnail?.id?.value || "";
            newEpisode.tags = rpcNovel?.tags?.map(t => t.value || "") || [];
            resolve(newEpisode);
          });
        } else {
          resolve(newEpisode);
        }
      }
    });

  private ensureNovel = (
    episode: Episode,
    completedSeries?: boolean,
    oneShotSeries?: boolean
  ): Promise<RpcQueryRecursiveTypes.ISeriesResponse | null> =>
    new Promise<RpcQueryRecursiveTypes.ISeriesResponse | null>(resolve => {
      if (episode.novelId === "new" || !episode.novelId) {
        this.mutationRepository
          .createSeries({
            userId: episode.userId,
            tags: episode.tags,
            title: episode.novelTitle || "タイトル",
            genre: episode.novelGenre || null,
            description: episode.description,
            thumbnailId: episode.thumbnailId,
            isCompleted: completedSeries || false,
            isOneShot: oneShotSeries || false,
            publishRange: episode.novelPublishFor || PublishRange.PUBLIC
          })
          .then(novelId => {
            if (novelId.id?.value) {
              resolve(this.fetchNovel(episode.userId, novelId.id?.value));
            }
          });
      } else {
        this.fetchNovel(episode.userId, episode.novelId).then(rpcNovel => {
          if (rpcNovel) {
            const novel = novelFromRpcSeries(rpcNovel);
            if (!novel?.thumbnailId) {
              // Novel is still in default draft state (no thumbnail, no tags, default title with timestamp)
              // Assign data from form
              novel.title = episode.novelTitle || novel.title;
              novel.thumbnailId = episode.thumbnailId;
              novel.tags = episode.tags;
              novel.description = episode.description;
              this.updateNovel(novel).then(updatedNovel => {
                if (updatedNovel.id?.value) {
                  resolve(
                    this.fetchNovel(episode.userId, updatedNovel.id.value)
                  );
                }
              });
            } else {
              resolve(rpcNovel);
            }
          }
        });
      }
    });

  public createEpisode = (
    episode: Episode,
    completedSeries?: boolean,
    oneShotSeries?: boolean
  ): Promise<CreateEpisodeResponse> =>
    new Promise<CreateEpisodeResponse>((resolve, reject) => {
      this.ensureNovel(episode, completedSeries, oneShotSeries).then(novel => {
        if (!novel) {
          reject();
        }
        if (novel) {
          const ep = copyRpcSeriesDataToEpisode(novel, episode);
          this.mutationRepository
            .createStory(ep)
            .then(newEpisodeId => {
              resolve({
                episodeId: newEpisodeId.id?.value || "",
                novelId: novel.id?.value || ""
              });
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });

  private updateEpisodeStatus = (
    episodeId: string,
    status: RpcStory.StoryStatus
  ): Promise<RpcMutationStory.UpdateStoryResponse> =>
    new Promise<RpcMutationStory.UpdateStoryResponse>((resolve, reject) => {
      this.fetchEpisode(episodeId, true).then(rpcEpisode => {
        if (!rpcEpisode) {
          reject();
          return;
        }
        const ep = episodeFromRpcStory(rpcEpisode);
        ep.status = status;
        resolve(this.mutationRepository.updateStory(ep));
      });
    });

  public publishEpisode = (
    episodeId: string
  ): Promise<RpcMutationStory.UpdateStoryResponse> =>
    this.updateEpisodeStatus(episodeId, RpcStory.StoryStatus.PUBLISH);

  public episodeToDraft = (
    episodeId: string
  ): Promise<RpcMutationStory.UpdateStoryResponse> =>
    this.updateEpisodeStatus(episodeId, RpcStory.StoryStatus.DRAFT);

  public reassignEpisodeSeriesIndex = (
    episodeId: string,
    seriesIndex: number
  ): Promise<RpcMutationStory.UpdateStoryResponse> =>
    new Promise<RpcMutationStory.UpdateStoryResponse>((resolve, reject) => {
      this.fetchEpisode(episodeId, true).then(rpcEpisode => {
        if (!rpcEpisode || !seriesIndex) {
          reject();
          return;
        }
        const ep = episodeFromRpcStory(rpcEpisode);
        ep.novelIndex = seriesIndex;
        resolve(this.mutationRepository.updateStory(ep));
      });
    });

  public updateEpisode = (
    episode: Episode
  ): Promise<RpcMutationStory.UpdateStoryResponse> =>
    this.mutationRepository.updateStory(episode);

  // Novel was created as automatic draft case (on close with unsaved changed)
  public updateEpisodeAndNovel = (
    episode: Episode,
    completedSeries: boolean,
    isOneShotSeries: boolean
  ): Promise<RpcMutationStory.UpdateStoryResponse> =>
    new Promise<RpcMutationStory.UpdateStoryResponse>((resolve, reject) => {
      this.ensureNovel(episode, completedSeries, isOneShotSeries).then(
        rpcNovel => {
          if (!rpcNovel) {
            reject();
          }
          if (rpcNovel) {
            const novel = novelFromRpcSeries(rpcNovel);
            novel.title = episode.novelTitle || novel.title;
            novel.thumbnailId = episode.thumbnailId;
            novel.tags = episode.tags;
            novel.description = episode.description;
            novel.isCompleted = completedSeries;
            novel.isOneShot = isOneShotSeries;
            this.updateNovel(novel).then(() => {
              resolve(this.updateEpisode(episode));
            });
          }
        }
      );
    });

  public deleteNovel = (novelId: string): Promise<RpcTypes.Empty> =>
    this.mutationRepository.deleteSeries(novelId);

  public setNovelOneShotAsSeries = (
    rpcSeries: RpcQueryRecursiveTypes.ISeriesResponse
  ): Promise<RpcMutationSeries.UpdateSeriesResponse> => {
    const novel = novelFromRpcSeries(rpcSeries);
    novel.isOneShot = false;
    novel.isCompleted = false;
    return this.mutationRepository.updateSeries(novel);
  };

  public updateNovel = (
    novel: Novel
  ): Promise<RpcMutationSeries.UpdateSeriesResponse> =>
    this.mutationRepository.updateSeries(novel);

  // When deleting one episode inside novel, we must reassign the seriesIndex of the other episodes
  // For example, if we have 5 stories in order and we remove number 3
  // Then stories number 4 and 5 become number 3 and 4, we must update seriesIndex to reflect this
  public deleteEpisode = async (
    id: string,
    reorderIndexes?: boolean,
    baseSeriesIndex?: number,
    userId?: string,
    novelId?: string
  ): Promise<RpcTypes.Empty> => {
    if (
      reorderIndexes &&
      baseSeriesIndex &&
      baseSeriesIndex > 0 &&
      userId &&
      novelId
    ) {
      const storiesBySeriesIndex: SeriesIndexStory = {};
      let page: number | null = 0;

      while (page) {
        // eslint-disable-next-line no-await-in-loop
        const resp: RpcQueryRecursiveTypes.ISeriesStoryPageResponse | null = await this.fetchNovelEpisodes(
          novelId,
          page
        );
        page = resp?.page?.hasNextPage?.value ? page + 1 : null;
        const episodes = resp?.storyList;
        episodes?.forEach(story => {
          if (story?.seriesIndex?.value) {
            storiesBySeriesIndex[story?.seriesIndex?.value] = story;
          }
        });
      }
      const updatePromises: Promise<
        RpcMutationStory.UpdateStoryResponse
      >[] = [];
      // Loop through all stories increasing a counter so we can synchronize in order
      // If the story is the same as the one we want to delete, do not increase the counter
      // For the others, reassign the seriesIndex and store in promises object
      // For execute all at the end
      let seriesIndex = 1;
      Object.keys(storiesBySeriesIndex).forEach((k: string) => {
        const st = storiesBySeriesIndex[Number(k)];
        if (st.id?.value === id) {
          return;
        }
        if (Number(k) !== seriesIndex) {
          if (st.id?.value) {
            updatePromises.push(
              this.reassignEpisodeSeriesIndex(st.id?.value, seriesIndex)
            );
          }
        }
        seriesIndex += 1;
      });
      return Promise.all(updatePromises).then(() =>
        this.mutationRepository.deleteStory(id)
      );
    }

    return this.mutationRepository.deleteStory(id);
  };

  public createImageOnServer = (
    base64: string,
    extension: string,
    fileInfo: ImageInfo
  ): Promise<RpcResource.Image> =>
    new Promise<RpcResource.Image>(resolve => {
      this.firebaseRepository
        .uploadImageToStorage(base64, extension)
        .then(result => {
          const imgRequest: RpcResource.IResourceCreateImageRequest = {
            gsPath: {
              value: `gs://${result.metadata.bucket}/${result.metadata.fullPath}`
            },
            format: getImageFormatType(result.metadata.contentType),
            category: RpcResource.ImageCategory.IMAGE_CATEGORY_CONTENT,
            width: { value: fileInfo.width },
            height: { value: fileInfo.height }
          };
          this.resourceRepository.createImage(imgRequest).then(rpcImage => {
            resolve(rpcImage);
          });
        });
    });

  public reorderEpisodes = (
    novelId: string,
    episodeIds: string[]
  ): Promise<RpcTypes.Empty> =>
    this.mutationRepository.updateSeriesStoryOrder(novelId, episodeIds);

  public acceptGuidelines = (novelId: string): Promise<RpcTypes.Empty> =>
    this.mutationRepository.acceptGuidelines(novelId);
}

// eslint-disable-next-line import/no-extraneous-dependencies
import { FirebaseError } from "@firebase/util";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import spinner from "../assets/spinner.svg";
import { Color } from "../components/styles/enums";
import { Image } from "../components/ui/image";
import { Text } from "../components/ui/text";
import { AuthUseCase } from "../usecases/authUseCase";

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  height: 100vh;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = styled.div`
  vertical-align: middle;
`;

const LoadingIndicator = styled(Image)`
  height: 40px;
  vertical-align: middle;
  padding-left: 5px;
`;

const authUseCase = new AuthUseCase();

export const LoginContainer: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const webToken = urlParams.get("webToken");
  const to = urlParams.get("to");
  const tags = urlParams.get("tags");
  const [toWrite] = useState(to === "newStory" || to === "write");
  const [loginDone, setLoginDone] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const f = async (tkn: string): Promise<void> => {
      try {
        const credentials = await authUseCase.loginByToken(tkn);
        if (credentials) {
          setLoginDone(true);
        }
      } catch (err) {
        if (
          err instanceof FirebaseError &&
          err.code === "auth/invalid-custom-token"
        ) {
          history.push("/error/invalid_token");
        } else {
          throw err;
        }
      }
    };
    if (webToken) {
      f(webToken);
    }
  }, [history, webToken]);

  useEffect(() => {
    if (loginDone) {
      if (toWrite) {
        history.push(`/novel/new/episodes/new${tags ? `?tags=${tags}` : ""}`);
      } else {
        history.push("/");
      }
    }
  }, [history, loginDone, tags, toWrite]);

  return (
    <PageWrapper>
      <div>
        <Loading>
          <Text mb={10} fw="bold" color={Color.ACCENT_500}>
            読み込み中
          </Text>
          <LoadingIndicator src={spinner} />
        </Loading>
      </div>
    </PageWrapper>
  );
};

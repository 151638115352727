import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  description?: string;
}

const SITE_NAME = "Teller Novel Studio";
const DEFAULT_TITLE = SITE_NAME;
const TITLE_TEMPLATE = ` - ${SITE_NAME}`;

const stripEscapeSequence = (str: string): string => str.replace("\\", "");

const truncate = (str: string, n = 120): string =>
  str.length > n ? `${str.substring(0, n - 1)} …` : str;

const createTitle = (title?: string): string =>
  title ? `${stripEscapeSequence(title)}${TITLE_TEMPLATE}` : DEFAULT_TITLE;

const normalizeDescription = (description: string): string =>
  truncate(stripEscapeSequence(description));

export const Metadata: React.FC<Props> = ({ title, description }) => {
  const ttl = createTitle(title);
  const desc = description ? normalizeDescription(description) : null;

  return (
    <Helmet>
      <title>{ttl}</title>
      {desc ? <meta name="description" content={desc} /> : null}
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

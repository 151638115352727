import { RPCImpl, RPCImplCallback } from "protobufjs";
import "whatwg-fetch";
import { auth } from "../../index";
import { AppError, ErrorType } from "../../models/appError";
import { handleError } from "./errorHandler";

export class TellerApi {
  private baseURL = `${process.env.REACT_APP_API_ENDPOINT || ""}/twirp`;

  public post = (endpoint: string): RPCImpl => async (
    _,
    requestData: Uint8Array,
    callback: RPCImplCallback
  ): Promise<void> => {
    try {
      const res: Uint8Array = await this.sendRequest(endpoint, requestData);
      callback(null, res);
    } catch (err) {
      callback(err, null);
    }
  };

  private prepareRequest = async (body: Uint8Array): Promise<RequestInit> => {
    const { currentUser } = auth();

    // Should not happen as we are always checking for authUser presence before posting requests
    if (currentUser === null) {
      throw new AppError(
        ErrorType.AUTHENTICATION_ERROR,
        "ネットワークエラーです"
      );
    }

    const idToken = await currentUser.getIdToken(false);

    if (idToken === null) {
      throw new AppError(
        ErrorType.AUTHENTICATION_ERROR,
        "ネットワークエラーです"
      );
    }

    const method = "POST";
    const headers = {
      Accept: "application/protobuf",
      "Content-Type": "application/protobuf",
      Authorization: `Bearer ${idToken}`
    };

    const option: RequestInit = {
      body,
      method,
      mode: "cors",
      headers
    };
    return option;
  };

  private sendRequest = async (
    path: string,
    body: Uint8Array
  ): Promise<Uint8Array> => {
    const requestOptions = await this.prepareRequest(body);

    const resUnit8Array: Uint8Array = await fetch(
      this.baseURL + path,
      requestOptions
    )
      .catch(_err => {
        // ネットワークエラーなどを処理
        throw new AppError(ErrorType.NETWORK_ERROR, "ネットワークエラーです");
      })
      .then((res: Response): Response => handleError(res))
      .then((res: Response): Promise<ArrayBuffer> => res.arrayBuffer())
      .then(
        (buffer: ArrayBuffer): Uint8Array => {
          const arr = new Uint8Array(buffer);
          return arr;
        }
      );
    return resUnit8Array;
  };
}

import styled from "@emotion/styled";
import { Color, Media } from "../styles/enums";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${Media.LARGE} {
    display: block;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  border: 1px solid ${Color.ACCENT_100};
  border-radius: 8px;
  padding: 1px;
  background: ${Color.ACCENT_100};

  @media ${Media.SMALL} {
    width: 100%;
    max-width: 160px;
    margin: auto 0;
  }

  @media ${Media.LARGE} {
    margin-right: auto;
    width: 350px;
  }
`;

export const RadioOption = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  padding: 4px 8px;
  display: inline-block;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: 0.1s all ease-in;
  border-radius: 7px;
  background: ${Color.DISABLED};

  ${({ disabled }) =>
    disabled && `background: ${Color.ACCENT_100}; color: ${Color.ACCENT_500};`}

  ${({ selected }) =>
    selected &&
    `background: ${Color.WHITE}; box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04); color: ${Color.TINT}; font-weight: bold; `}

  &:hover {
    ${({ disabled }) => !disabled && `opacity: 1;`}
  }
`;

import ReactDOMServer from "react-dom/server";
import styled from "@emotion/styled";
import { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import { useAlertMsg } from "../../hooks/useAlertMsg";
import { useOneStoryOnlyWarningModal } from "../../hooks/useOneStoryOnlyWarningModal";

// Models
import {
  story as RpcStory,
  query_recursive_types as RpcRecursiveTypes,
  types as RpcTypes
} from "../../infra/api/rpc/api";

// Usecase
import { NovelUseCase } from "../../usecases/novelUseCase";

// Hooks
import { useAuth } from "../../hooks/useAuth";
import { useAppParameters } from "../../hooks/useAppParameters";
import { useLayoutUI } from "../../hooks/useLayoutUI";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import { useGuidelinesModal } from "../../hooks/useGuidelinesModal";
import { useChatStudioRedirect } from "../../hooks/useChatStudioRedirect";

// Components
import { AppHeader } from "../../components/common/header/appHeader";
import { ActivityModal } from "../../components/common/activityModal";
import { OneStoryOnlyWarningModal } from "../../components/common/oneStoryOnlyWarningModal";
import { NoResults } from "../../components/common/noResults";
import { NovelCell } from "../../components/novel/novelCell";
import { NovelActionSheet } from "../../components/novel/novelActionSheet";
import { NovelInformationFormActionSheet } from "../../components/novel/novelInformationFormActionSheet";
import { EpisodeCell } from "../../components/episode/episodeCell";
import { EpisodeActionSheet } from "../../components/episode/episodeActionSheet";
import { EpisodePublishedModal } from "../../components/episode/episodePublishedModal/episodePublishedModal";
import { GuidelinesModal } from "../../components/common/guidelinesModal";
import { Image } from "../../components/ui/image";
import { Confirm } from "../../components/common/confirm";
import { Alert } from "../../components/common/alert";
import { Button, ButtonVariant } from "../../components/ui/button";
import { Text } from "../../components/ui/text";
import { Color, FontSize, Media } from "../../components/styles/enums";

import { PullToReload } from "../../libs/pullToReload";
import loadingIcon from "../../assets/spinner.svg";
import pullToRefreshArrow from "../../assets/pull_to_refresh_down.png";

import { useModal } from "../../hooks/useModal";
import { useChatPreview } from "../../hooks/useChatPreview";
import { AboutRatingModal } from "../../components/common/aboutRatingModal";
import { useLoadNovelAndEpisodes } from "./useLoadNovelAndEpisodes";

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  min-height: calc(100vh - 46px);
  max-width: 960px;
  padding-bottom: 180px;
  margin: auto;
`;

const LegendWrapper = styled.div`
  display: flex;
  font-size: ${FontSize.SIZE_12};
  align-items: baseline;
  justify-content: space-between;
`;

const Legend = styled(Text)`
  color: ${Color.ACCENT_700};
  font-weight: 700;
  padding-left: 16px;
  margin-top: 16px;
`;

const ReorderLegend = styled(Text)`
  color: ${Color.ACCENT_500};
  font-weight: 400;
  margin-right: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
const WriteEpisode = styled(Button)`
  position: fixed;
  left: 50%;
  margin-left: -172px;
  bottom: 53px;
  height: 40px;
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  width: 344px;

  @media ${Media.SMALL} {
    height: 56px;
  }

  @media ${Media.SMALLEST} {
    width: 300px;
    margin-left: -150px;
  }
`;

const PullToReloadRef = styled.div`
  line-height: 80px;

  &.threshold {
    .loading {
      display: inline-block;
      margin-right: -25px;
      margin-top: -3px;
    }

    .arrow {
      transform: rotate(180deg);
    }
  }
`;

const DownArrow = styled(Image)`
  height: 15px;
  vertical-align: middle;
  margin-right: 14px;
  margin-bottom: 4px;
  transition: all 0.3s;
  transform: rotate(0deg);
`;

const LoadingInd = styled(Image)`
  display: none;
  height: 30px;
  width: 30px;
  vertical-align: middle;
`;

const PullDownSentence = styled.span`
  color: ${Color.ACCENT_500};
`;

type Props = {
  novelId: string;
};

const novelUseCase = new NovelUseCase();

const LoadingSpinner = <Image src={loadingIcon} width={40} height={40} />;
const PullInnerContent = (
  <>
    <DownArrow className="arrow" src={pullToRefreshArrow} />
    <PullDownSentence>引き下げて更新</PullDownSentence>
    <LoadingInd className="loading" src={loadingIcon} />
  </>
);

const Content = styled.div`
  min-height: 90vh;
  height: 90vh;
  padding-bottom: 150px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const NovelContainer: React.FC = () => {
  const {
    user: authUser,
    tellerUser,
    isOfficialWriter,
    requestReloadMe
  } = useAuth();
  const { isApp } = useAppParameters();
  const { goToChatStudio } = useChatStudioRedirect();
  const { previewInWeb } = useChatPreview();
  const { sendEventToFirebase } = useFirebaseAnalytics();
  const { novelId } = useParams<Props>();
  const { setLayout } = useLayoutUI();
  const history = useHistory();

  const screenLarge = useMedia(Media.LARGE);
  const { showAlertWithMsg, isShowAlert, alertMsg, hideAlert } = useAlertMsg();
  const [isSaving, setSaving] = useState(false);
  const [reloadOnCloseAlert, setReloadOnCloseAlert] = useState(false);
  const [isShowDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [layoutSet, setLayoutSet] = useState(false);
  const [isMovingToStudio, setMovingToStudio] = useState(false);

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const publishedParam = urlParams.get("published");
  const publishedTitleParam = urlParams.get("title");
  const publishedIdParam = urlParams.get("id");
  const needRefresh = urlParams.get("refresh") === "1";

  const pullToReloadRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // Novel action sheet handle
  const [novelActionSheetIsOpen, setNovelActionSheetIsOpen] = useState(false);
  const openNovelActionSheet = useCallback(() => {
    setNovelActionSheetIsOpen(true);
  }, []);
  const closeNovelActionSheet = useCallback(() => {
    setNovelActionSheetIsOpen(false);
  }, []);

  // About Rating Modal
  const aboutRatingModal = useModal();

  // Novel information form action sheet handle
  const [
    novelInformationFormActionSheetIsOpen,
    setNovelInformationFormActionSheetIsOpen
  ] = useState(false);

  const openNovelInformationFormActionSheet = useCallback(() => {
    setNovelInformationFormActionSheetIsOpen(true);
  }, []);

  const closeNovelInformationFormActionSheet = useCallback(() => {
    setNovelInformationFormActionSheetIsOpen(false);
  }, []);

  // Episode action sheet handle
  const [isEpisodeActionSheetOpen, setEpisodeActionSheetOpen] = useState(false);
  const [
    selectedEpisode,
    setSelectedEpisode
  ] = useState<RpcRecursiveTypes.IStoryResponse | null>(null);

  const openEpisodeActionSheet = useCallback(
    (ep: RpcRecursiveTypes.IStoryResponse) => {
      setSelectedEpisode(ep);
      setEpisodeActionSheetOpen(true);
    },
    []
  );

  const closeEpisodeActionSheet = useCallback(() => {
    setEpisodeActionSheetOpen(false);
  }, []);

  // Episode published modal handle
  const [isEpisodePublishedModalOpen, setEpisodePublishedModalOpen] = useState(
    false
  );

  const paginationRef = useRef<HTMLDivElement>(null);

  const {
    isValidating,
    isEmpty,
    hideReorderStories,
    novel,
    novelEpisodesPage,
    mutateEpisodes,
    mutateNovel
  } = useLoadNovelAndEpisodes(novelId, paginationRef);

  useEffect(() => {
    if (needRefresh) {
      urlParams.delete("refresh");
      history.replace({ search: urlParams.toString() });
      mutateNovel();
      mutateEpisodes();
    }
  }, [history, mutateEpisodes, mutateNovel, needRefresh, urlParams]);

  const {
    shouldShow: shouldShowGuidelines,
    isOpen: isGuidelinesModalOpen,
    open: showGuidelinesModal,
    close: closeGuidelinesModal
  } = useGuidelinesModal();

  // Reload on data change
  const reload = useCallback(() => {
    mutateNovel();
    mutateEpisodes();
  }, [mutateEpisodes, mutateNovel]);

  const closeEpisodePublishedModal = useCallback(() => {
    setEpisodePublishedModalOpen(false);
    if (reloadOnCloseAlert) {
      reload();
    }
  }, [reload, reloadOnCloseAlert]);

  // Alert handle
  const showAlert = useCallback(
    (msg: string, reloadOnClose?: boolean) => {
      showAlertWithMsg(msg);
      setReloadOnCloseAlert(reloadOnClose || false);
    },
    [showAlertWithMsg]
  );

  const closeAlert = useCallback(() => {
    hideAlert();
    if (reloadOnCloseAlert) {
      reload();
    }
  }, [hideAlert, reload, reloadOnCloseAlert]);

  // Episode operations
  const onPublish = useCallback(async () => {
    if (selectedEpisode?.id?.value) {
      setSaving(true);
      try {
        await novelUseCase.publishEpisode(selectedEpisode.id?.value);
        setReloadOnCloseAlert(true);
        setEpisodePublishedModalOpen(true);
        requestReloadMe();
      } catch (err) {
        showAlert("不明なエラーが発生しました。");
      } finally {
        setSaving(false);
      }
    }
  }, [requestReloadMe, selectedEpisode, showAlert]);

  const onBeforePublish = useCallback(() => {
    if (shouldShowGuidelines) {
      showGuidelinesModal();
    } else {
      onPublish();
    }
  }, [shouldShowGuidelines, showGuidelinesModal, onPublish]);

  const guidelinesModalPublishCallback = useCallback(() => {
    closeGuidelinesModal();
    onPublish();
  }, [closeGuidelinesModal, onPublish]);

  const guidelinesModalDraftCallback = useCallback(() => {
    closeGuidelinesModal();
  }, [closeGuidelinesModal]);

  const onToDraft = useCallback(async () => {
    if (selectedEpisode?.id?.value) {
      setSaving(true);
      try {
        await novelUseCase.episodeToDraft(selectedEpisode.id?.value);
        requestReloadMe();
        setReloadOnCloseAlert(true);
        showAlert("下書きを保存しました。", true);
      } catch (err) {
        showAlert("不明なエラーが発生しました。");
      } finally {
        setSaving(false);
      }
    }
  }, [requestReloadMe, selectedEpisode, showAlert]);

  const onShare = useCallback(() => {
    if (selectedEpisode) {
      window.location.href = `teller-studio://share/${selectedEpisode.id?.value}/s?title=${selectedEpisode.title?.value}`;
    }
  }, [selectedEpisode]);

  const closeDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);

  const onDelete = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const deleteEpisode = useCallback(async () => {
    closeDeleteConfirm();
    if (selectedEpisode?.id?.value) {
      setSaving(true);
      try {
        await novelUseCase.deleteEpisode(
          selectedEpisode.id?.value,
          true,
          selectedEpisode.seriesIndex?.value || 0,
          tellerUser?.id?.value || undefined,
          novelId
        );
        if (isApp) {
          window.location.href = `teller-studio://delete-story/${selectedEpisode?.id?.value}?script_type=novel`;
        } else {
          sendEventToFirebase("story_delete", authUser, tellerUser, {
            story_id: selectedEpisode?.id?.value,
            script_type: "novel"
          });
        }
        requestReloadMe();
        showAlert("削除しました", true);
      } catch (err) {
        showAlert("不明なエラーが発生しました。");
      } finally {
        setSaving(false);
      }
    }
  }, [
    authUser,
    closeDeleteConfirm,
    isApp,
    novelId,
    requestReloadMe,
    selectedEpisode,
    sendEventToFirebase,
    showAlert,
    tellerUser
  ]);

  const onPreview = useCallback(() => {
    if (novel?.hasChatNovelScriptStory?.value && selectedEpisode?.id?.value) {
      previewInWeb(selectedEpisode.id.value, screenLarge);
    } else {
      history.push(
        `/preview/${novelId}/episodes/${selectedEpisode?.id?.value}`
      );
    }
  }, [
    history,
    novel?.hasChatNovelScriptStory?.value,
    novelId,
    previewInWeb,
    screenLarge,
    selectedEpisode?.id?.value
  ]);

  const {
    isOpen: isOneStoryOnlyWarningModalOpen,
    open: showOneStoryOnlyWarningModal,
    close: closeOneStoryOnlyWarningModal
  } = useOneStoryOnlyWarningModal();

  // When writing new episode, first we check number of stories for assigning series index and
  // also an automatic title "第７話"
  // As here we have the most refreshed data in this page coming from datastore instead of Algolia,
  // pass it as parameter to episode write page
  const writeEpisode = useCallback(
    async (changeToSeries?: boolean) => {
      if (changeToSeries && novel) {
        // User tries to add one more story to one shot series and already accepted
        // changing the setting, so update it right away
        await novelUseCase.setNovelOneShotAsSeries(novel);
        mutateNovel();
      }
      setMovingToStudio(true);
      const storyCount = novelEpisodesPage?.[0]?.page?.totalCount?.value || 0;

      // TODO: check this parameter and set isCompleted to false (or the new API for unmarking series as one story only)
      if (novel?.hasChatNovelScriptStory?.value) {
        goToChatStudio(
          "/stories/new",
          `&series_id=${novel?.id?.value}&stories_count=${storyCount}`
        );
      } else {
        history.push(`/novel/${novelId}/episodes/new?c=${storyCount}`);
      }
    },
    [goToChatStudio, history, mutateNovel, novel, novelEpisodesPage, novelId]
  );

  const checkOneStorySetting = useCallback(() => {
    if (novel?.isOneshot?.value) {
      showOneStoryOnlyWarningModal();
    } else {
      writeEpisode();
    }
  }, [novel?.isOneshot?.value, showOneStoryOnlyWarningModal, writeEpisode]);

  const episodes = novelEpisodesPage?.[0]?.storyList;
  const firstEpisodeScriptType = episodes?.[0]?.scriptType;
  // Episodes coming from server have updated information right away (not from Algolia)
  // But novel data is still being retrieved from Algolia
  // Novel status is shown as published if at least one episode is published,
  // we know this right away using `searchableStoryCount` data from novel, but as this data has some delay
  // let's check if we already have some published episode (data is more fresh from datastore) and do not wait for Algolia
  const publishedExistsNow = !!episodes?.find(
    e => e.status === RpcStory.StoryStatus.PUBLISH
  );
  const novelPublishedStatus =
    publishedExistsNow || (novel?.searchableStoryCount?.value || 0) > 0;

  // Novel operations
  const deleteNovel = useCallback(() => {
    history.push("/");
  }, [history]);

  const shareNovel = useCallback(() => {
    window.location.href = `teller-studio://share/${novel?.id?.value}/se?title=${novel?.title?.value}`;
  }, [novel]);

  // If coming from episode publication, show publication modal
  useEffect(() => {
    if (publishedParam === "1" && publishedIdParam && publishedTitleParam) {
      setEpisodePublishedModalOpen(true);
      setReloadOnCloseAlert(true);
    }
  }, [publishedIdParam, publishedParam, publishedTitleParam]);

  // Layout
  useEffect(() => {
    if (!layoutSet && novel?.title?.value) {
      setLayout({
        title: novel.title.value
      });
      setLayoutSet(true);
    }
  }, [layoutSet, novel, setLayout]);

  const ptr = useRef<PullToReload | null>();

  useEffect(() => {
    if (contentRef.current && pullToReloadRef.current && !ptr.current) {
      // eslint-disable-next-line no-new
      ptr.current = new PullToReload({
        "loading-content": ReactDOMServer.renderToString(LoadingSpinner),
        height: 80,
        "default-inner": ReactDOMServer.renderToString(PullInnerContent),
        "default-text": "引き下げて更新",
        "threshold-reached-text": "離して更新",
        "border-height": 0,
        "font-size": "13px",
        "content-element": contentRef.current,
        "refresh-element": pullToReloadRef.current,
        threshold: 5,
        "callback-loading": () => {
          setTimeout(() => {
            reload();
            ptr?.current?.loadingEnd();
          }, 1000);
        }
      });
    }
  }, [contentRef, mutateEpisodes, mutateNovel, pullToReloadRef, reload]);

  // Enable publish from episode context menu (three dots icon on the right)
  // For this to happen, novel must already have some tags and a thumbnail assigned
  // (novel form filled at least once)
  const isReadyForPublish = useMemo(() => {
    if (novel) {
      return (
        (novel.tags?.length ?? 0) > 0 &&
        (novel.thumbnail?.servingUrl ?? null) !== null
      );
    }
    return false;
  }, [novel]);

  const toChatStudioCallback = useCallback(() => {
    setMovingToStudio(true);
  }, []);

  const toReorder = useCallback(() => {
    history.push(`/novel/${novelId}/reorder?notransition=1`);
  }, [history, novelId]);

  return (
    <div>
      <AppHeader
        title={novel?.title?.value || ""}
        noBackButton={false}
        backLink="/"
        moreButton
        onMoreButtonClick={openNovelActionSheet}
      />
      <PageWrapper>
        <PullToReloadRef ref={pullToReloadRef}>
          {PullInnerContent}
        </PullToReloadRef>

        {isShowDeleteConfirm ? (
          <Confirm
            title="削除しますか?"
            msg="削除した場合もとには戻せません"
            onClose={closeDeleteConfirm}
            onConfirm={deleteEpisode}
          />
        ) : null}
        {isShowAlert && alertMsg ? (
          <Alert msg={alertMsg} close={closeAlert} />
        ) : null}
        {!isEmpty && (isSaving || isValidating || isMovingToStudio) ? (
          <ActivityModal />
        ) : null}
        {isEmpty ? <NoResults text="データは見つかりませんでした" /> : null}
        <Content ref={contentRef}>
          {novel ? (
            <>
              <Legend>作品情報</Legend>
              <NovelCell
                novel={novel}
                isEpisodeListing
                novelStatusFromEpisodes={novelPublishedStatus}
                seriesTypeFromEpisodes={firstEpisodeScriptType}
                isOfficialWriter={isOfficialWriter}
              />
              {episodes && episodes.length > 0 ? (
                <>
                  <LegendWrapper>
                    <Legend>エピソード</Legend>
                    <ReorderLegend onClick={toReorder}>並び替え</ReorderLegend>
                  </LegendWrapper>
                  {novelEpisodesPage?.map(novelWithEpisodes =>
                    novelWithEpisodes?.storyList?.map(
                      (ep: RpcRecursiveTypes.IStoryResponse) => (
                        <EpisodeCell
                          key={ep.id?.value}
                          screenLarge={screenLarge}
                          novelId={novelId}
                          episode={ep}
                          isOfficialWriter={isOfficialWriter}
                          isChat={novel.hasChatNovelScriptStory?.value || false}
                          onClickMore={e => {
                            e.stopPropagation();
                            openEpisodeActionSheet(ep);
                          }}
                          onToChatStudio={toChatStudioCallback}
                        />
                      )
                    )
                  )}
                </>
              ) : null}
            </>
          ) : null}
          <div ref={paginationRef} />
        </Content>

        <WriteEpisode
          className="fixedButton"
          block
          variant={ButtonVariant.PILL}
          color={Color.TINT}
          onClick={checkOneStorySetting}
          disabled={!novel}
        >
          新しいエピソードを追加
        </WriteEpisode>
        <NovelActionSheet
          open={novelActionSheetIsOpen}
          novelId={novel?.id?.value}
          isOfficial={novel?.isOfficial?.value || false}
          isPublished={novelPublishedStatus}
          isOfficialWriter={isOfficialWriter}
          hideOrderStories={hideReorderStories}
          onEdit={() => {
            openNovelInformationFormActionSheet();
            closeNovelActionSheet();
          }}
          onShare={shareNovel}
          onDeleteSucceed={deleteNovel}
          onClose={closeNovelActionSheet}
        />
        <NovelInformationFormActionSheet
          open={novelInformationFormActionSheetIsOpen}
          novel={novel}
          openAboutRatingModal={aboutRatingModal.open}
          onClose={closeNovelInformationFormActionSheet}
          onSave={reload}
          isForPublish={false}
        />
        <EpisodeActionSheet
          isApp={isApp}
          open={isEpisodeActionSheetOpen}
          isOfficialWriter={isOfficialWriter}
          isReadyForPublish={isReadyForPublish}
          isPublished={selectedEpisode?.status === RpcStory.StoryStatus.PUBLISH}
          onClose={closeEpisodeActionSheet}
          onPreview={onPreview}
          onToDraft={onToDraft}
          onShare={onShare}
          onDelete={onDelete}
          onPublish={onBeforePublish}
        />
        <GuidelinesModal
          open={isGuidelinesModalOpen}
          onClose={closeGuidelinesModal}
          onPublishButton={guidelinesModalPublishCallback}
          onDraftButton={guidelinesModalDraftCallback}
        />
        <EpisodePublishedModal
          open={isEpisodePublishedModalOpen}
          episodeTitle={
            selectedEpisode?.title?.value || publishedTitleParam || ""
          }
          episodeId={selectedEpisode?.id?.value || publishedIdParam || ""}
          isSharedForPublic={
            novel?.sharedWithStatus ===
            RpcTypes.SharedWithStatus.SHARED_WITH_PUBLIC
          }
          onClose={closeEpisodePublishedModal}
        />
        <AboutRatingModal
          open={aboutRatingModal.isOpen}
          onClose={aboutRatingModal.close}
        />
        <OneStoryOnlyWarningModal
          open={isOneStoryOnlyWarningModalOpen}
          onClose={closeOneStoryOnlyWarningModal}
          onChangeToSeries={() => writeEpisode(true)}
        />
      </PageWrapper>
    </div>
  );
};

import { css } from "@emotion/react/macro";
import { Color, FontSize } from "../../styles/enums";

export const base = css`
  html {
    box-sizing: border-box;
  }

  body {
    color: ${Color.ACCENT_1000};
    font-size: ${FontSize.SIZE_14};
    font-family: "Avenir Next", "Hiragino Kaku Gothic Pro",
      "ヒラギノ角ゴ Pro W3", "Hiragino Sans", Meiryo, メイリオ, Osaka,
      "MS PGothic", arial, helvetica, sans-serif;
    line-height: 1.7;
  }

  /**
   * Prevent padding and border from affecting element width
   * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /**
   * Suppress the focus outline on elements that cannot be accessed via keyboard
   * https://github.com/suitcss/base/blob/master/lib/base.css
   */
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  a {
    text-decoration: none;
    color: #313238;
  }

  a:hover {
    color: #1d5084;
  }

  a:visited,
  a:-webkit-any-link {
    text-decoration: none;
  }
`;

import styled from "@emotion/styled/macro";
import { User } from "firebase/auth";
import { useCallback, useState } from "react";
import useSWR from "swr";
import loginMyPageIcon from "../../../assets/header/login_mypage_icon.svg";
import vipIcon from "../../../assets/header/vip_icon.svg";
import { query_recursive_types as RpcQueryRecursiveTypes } from "../../../infra/api/rpc/api";
import { fetchLatestSubscription } from "../../../usecases/subscriptionUseCase";
import { FontSize } from "../../styles/enums";
import { Image } from "../../ui/image";
import { UserOptionsPopup } from "../../user/userOptionsPopup";

const List = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: right;
  font-size: ${FontSize.SIZE_16};
  white-space: nowrap;
  list-style: none;
  margin-top: -4px;
`;

const VipIcon = styled(Image)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 7px;
`;

interface Props {
  authUser: User | null | undefined;
  tellerUser: RpcQueryRecursiveTypes.IUserResponse | null;
  isVipUser: boolean;
  isOfficialWriter: boolean;
  onLogout: () => void;
}

export const Nav: React.FC<Props> = ({
  authUser,
  tellerUser,
  isVipUser,
  isOfficialWriter,
  onLogout
}) => {
  const [isUserPopupOpen, setUserPopupOpen] = useState(false);

  const openUserPopup = useCallback(() => {
    setUserPopupOpen(true);
  }, []);

  const closeUserPopup = useCallback(() => {
    setUserPopupOpen(false);
  }, []);

  const { data: latestSubscription } = useSWR(
    authUser && tellerUser && isVipUser
      ? ["/api/latestSubscription", tellerUser.id?.value]
      : null,
    fetchLatestSubscription,
    {
      shouldRetryOnError: false
    }
  );

  if (!authUser) {
    return <nav />;
  }

  const vipPageLink = !latestSubscription
    ? "/vip_offer"
    : "/profile/plan_selection";

  return (
    <nav>
      <List>
        {!isVipUser ? (
          <li>
            <a href={`//${process.env.REACT_APP_WEB_DOMAIN}${vipPageLink}`}>
              <VipIcon width="32" height="32" src={vipIcon} />
              VIPプラン
            </a>
          </li>
        ) : null}
        {authUser && !authUser.isAnonymous && tellerUser ? (
          <>
            <li>
              <UserOptionsPopup
                thumbnailUrl={
                  (tellerUser && tellerUser.thumbnail?.servingUrl?.value) ||
                  loginMyPageIcon
                }
                username={tellerUser.name?.value || "ゲスト"}
                isVip={isVipUser}
                isOfficialWriter={isOfficialWriter}
                wasAlreadyVip={!!latestSubscription}
                openPopup={openUserPopup}
                closePopup={closeUserPopup}
                isOpen={isUserPopupOpen}
                onLogout={onLogout}
              />
            </li>
          </>
        ) : null}
      </List>
    </nav>
  );
};

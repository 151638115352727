import styled from "@emotion/styled/macro";

import { ActionSheet, OptionsList, Option } from "../common/actionSheet";
import { Color, FontSize } from "../styles/enums";
import { Image } from "../ui/image";
import closeModalIcon from "../../assets/close_actionsheet_icon.svg";

const Wrapper = styled.div`
  min-height: 230px;
`;

const CenteredOption = styled(Option)<{ color?: string }>`
  > button {
    cursor: pointer;
    display: block;
    font-weight: 700;
    color: ${({ color }) => (color ? `${color}` : Color.ACCENT_1000)};
  }
`;

const TitleWrapper = styled(CenteredOption)`
  display: flex;
  font-weight: 700;
  font-size: ${FontSize.SIZE_16};
  line-height: 45px;
  align-items: center;

  > div {
    position: relative;
    left: -20px;
    margin: auto;
  }

  > img {
    padding-left: 6px;
    justify-content: flex-start;
    cursor: pointer;
  }
`;

interface Props {
  open: boolean;
  onSave(): void;
  onDiscard(): void;
  onClose(): void;
}

export const NovelStoryOrderSaveChangesActionSheet: React.VFC<Props> = ({
  open,
  onSave,
  onDiscard,
  onClose
}) => (
  <ActionSheet open={open || false} onClose={onClose} bckWhite>
    <Wrapper>
      <OptionsList>
        <TitleWrapper>
          <Image height={20} src={closeModalIcon} onClick={onClose} />
          <div>新しい順番を保存しますか？</div>
        </TitleWrapper>
        <CenteredOption color={Color.TINT}>
          <button type="button" onClick={onSave}>
            保存
          </button>
        </CenteredOption>
        <CenteredOption color={Color.RED}>
          <button type="button" onClick={onDiscard}>
            破棄
          </button>
        </CenteredOption>
        <CenteredOption color={Color.ACCENT_500}>
          <button type="button" onClick={onClose}>
            キャンセル
          </button>
        </CenteredOption>
      </OptionsList>
    </Wrapper>
  </ActionSheet>
);

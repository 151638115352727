import { useState, useCallback } from "react";
import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react/macro";
import { useHistory } from "react-router-dom";
import { story as RpcStory } from "../../infra/api/rpc/api";
import { NovelUseCase } from "../../usecases/novelUseCase";
import { Episode, toMarkdown } from "../../models/episode";
import { useContestAcknowledge } from "../../hooks/useContestAcknowledge";
import { EpisodeContestModal } from "./episodeContestModal";
import { Image } from "../ui/image";
import { ellipsis } from "../styles/mixins/ellipsis";
import { Color, FontSize, Media, ZIndex } from "../styles/enums";
import closeIcon from "../../assets/header/close_icon.svg";
import smallEditIcon from "../../assets/small_edit_icon.svg";
import spinnerIcon from "../../assets/spinner.svg";
import doneIcon from "../../assets/done_icon.svg";
import { logError } from "../../utils/utils";
import { PublishRange } from "../../models/novel";

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  z-index: ${ZIndex.HEADER};
  width: 100%;
  line-height: 0;
  background-color: ${Color.WHITE};
  border-bottom: 1px solid ${Color.ACCENT_50};

  button {
    text-align: left;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

const SmallHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${FontSize.SIZE_16};
  background: ${Color.WHITE};
  height: 45px;
  padding-left: 15px;
  align-items: center;

  @media ${Media.SMALL} {
    max-width: 60.4em;
    width: 100%;
    min-width: 100%;
  }

  @media ${Media.LARGE} {
    max-width: 960px;
    margin: auto;
  }
`;

const Left = styled.div`
  height: 21px;
  display: flex;
  justify-content: left;
  max-width: 60%;
`;
const LegendTitle = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  max-width: 70vw;

  @media ${Media.SMALL} {
    max-width: 56vw;
  }

  @media ${Media.SMALLEST} {
    max-width: 40vw;
  }
`;

const NovelTitle = styled.div`
  font-size: ${FontSize.SIZE_10};
  color: ${Color.ACCENT_700};
  padding-top: 5px;
  min-height: 15px;
  ${ellipsis("100%")}
`;

const Title = styled.div`
  font-size: ${FontSize.SIZE_12};
  font-weight: 700;
  color: ${Color.ACCENT_1000};
  display: inline;
  margin-right: 5px;
  ${ellipsis("100%")}
`;

const Right = styled.div`
  justify-content: flex-end;
  text-align: right;
  position: relative;
  right: 15px;
  width: 150px;
  min-width: 150px;

  > button {
    font-size: ${FontSize.SIZE_16};
    font-weight: 700;
  }
`;

const Save = styled.button`
  margin-right: 8px;
  padding-right: 16px;
  border-right: 1px solid ${Color.SEPARATOR};
  font-weight: 700;
  color: ${Color.ACCENT_700};
  &:disabled {
    color: ${Color.ACCENT_500};
  }
`;

const Next = styled.button`
  margin-left: 8px;
  color: ${Color.TINT};
  &:disabled {
    color: ${Color.ACCENT_500};
  }
`;

const EnterAnimation = keyframes`
  0% {
    width: 24px;
    opacity: 0;
  }

  100% {
    width: 24px;
    opacity: 1;
  }
`;

const ExitAnimation = keyframes`
  0% {
    width: 24px;
    opacity: 1;
  }

  100% {
    width: 48px;
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const DoneIcon = styled(Image)`
  animation: ${EnterAnimation} 500ms, ${ExitAnimation} 300ms 2s forwards;
`;

const ActivityIcons = styled.span`
  display: inline-block;
  height: 24px;
  width: 48px;
  vertical-align: middle;
`;

const CloseButton = styled.button`
  min-width: 20px;
`;

const TitleButton = styled.button`
  min-height: 20px;
  max-width: 100%;
  overflow: hidden;
  display: flex;
`;

const novelUseCase = new NovelUseCase();
interface Props {
  episode?: Episode | null;
  content: string;
  isEmptyOrAboveLimit: boolean;
  onClose(): void;
  onNewNovel(): void;
  onSave(
    saved: boolean,
    error: boolean,
    published: boolean,
    publishFor: PublishRange | null,
    isNew: boolean,
    tags?: string[] | null,
    novId?: string | null,
    epId?: string | null
  ): void;
  onNext(): void;
  onEdit(): void;
}

export const EpisodeHeader: React.FC<Props> = ({
  episode,
  content,
  isEmptyOrAboveLimit,
  onClose,
  onNewNovel,
  onSave,
  onNext,
  onEdit
}) => {
  const [isSavingStart, setSavingStart] = useState<boolean>(false);
  const [isSavingEnd, setSavingEnd] = useState<boolean | null>(false);
  const history = useHistory();
  const {
    checkContestTags,
    openContestModal,
    selectedContestTag,
    isOpen: contestModalIsOpen,
    close: closeContestModal
  } = useContestAcknowledge();

  const save = useCallback(async () => {
    closeContestModal();
    if (!content || isEmptyOrAboveLimit) {
      return;
    }
    if (!episode?.novelId) {
      onNewNovel();
      return;
    }
    setSavingStart(true);
    if (episode) {
      const markdown = toMarkdown(content);
      const ep: Episode = { ...episode, novelScript: markdown };
      const isNew = !episode.id;
      let novelId = ep.novelId || null;
      let epId = ep.id || null;
      try {
        if (episode.id) {
          await novelUseCase.updateEpisode(ep);
        } else {
          const newIds = await novelUseCase.createEpisode(ep);
          novelId = newIds.novelId;
          epId = newIds.episodeId;
          // Now we have id, refresh url
          setTimeout(() => {
            // Execute refresh only if user didn't change page before
            onSave(
              true, // saved
              false, // error
              false, // published
              episode.novelPublishFor || null, // publish for
              isNew,
              ep.tags,
              novelId,
              epId
            );
            if (window.location.pathname.indexOf("/episodes/") > -1) {
              const link = `/novel/${newIds.novelId}/episodes/${newIds.episodeId}?notransition=1`;
              history.push(link);
            }
          }, 3000);
        }
        setSavingEnd(true);
        setTimeout(() => {
          setSavingEnd(false);
        }, 2500);
        onSave(
          true, // saved
          false, // error
          false, // published
          episode.novelPublishFor || null, // publish for
          isNew,
          episode.tags,
          novelId,
          epId
        );
      } catch (err) {
        logError(err);
        onSave(
          false, // saved
          true, // error
          false, // published
          episode.novelPublishFor || null, // publish for
          isNew,
          episode.tags,
          novelId,
          epId
        );
      } finally {
        setSavingStart(false);
      }
    }
  }, [
    closeContestModal,
    content,
    episode,
    history,
    isEmptyOrAboveLimit,
    onNewNovel,
    onSave
  ]);

  const checkContest = useCallback(() => {
    if (!episode) {
      return;
    }
    const isPublished = episode.status === RpcStory.StoryStatus.PUBLISH;
    if (isPublished && episode.tags && checkContestTags(episode.tags)) {
      openContestModal(episode.tags);
      return;
    }
    save();
  }, [checkContestTags, episode, openContestModal, save]);

  return (
    <>
      <Wrapper>
        <SmallHeader>
          <Left>
            {episode ? (
              <>
                <CloseButton type="button" onClick={onClose}>
                  <Image src={closeIcon} />
                </CloseButton>
                <LegendTitle>
                  <NovelTitle>{episode?.novelTitle}</NovelTitle>
                  <TitleButton type="button" onClick={onEdit}>
                    <Title>{episode?.title}</Title>
                    {episode ? <Image src={smallEditIcon} /> : null}
                  </TitleButton>
                </LegendTitle>
              </>
            ) : null}
          </Left>
          <Right>
            <ActivityIcons>
              {isSavingStart ? (
                <Image src={spinnerIcon} width="24" mt={-3} />
              ) : null}
              {isSavingEnd ? (
                <DoneIcon src={doneIcon} width="24" mt={-3} />
              ) : null}
            </ActivityIcons>

            <Save onClick={checkContest} disabled={isEmptyOrAboveLimit}>
              保存
            </Save>
            <Next onClick={onNext} disabled={isEmptyOrAboveLimit}>
              次へ
            </Next>
          </Right>
        </SmallHeader>
      </Wrapper>
      {selectedContestTag && (
        <EpisodeContestModal
          open={contestModalIsOpen}
          onClose={closeContestModal}
          onCloseWithAcknowledge={save}
          contestTagName={selectedContestTag}
        />
      )}
    </>
  );
};

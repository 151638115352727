import React, { useState, useRef, useCallback } from "react";
import { useMedia } from "react-use";
import styled from "@emotion/styled/macro";
import { Modal } from "../common/modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";
import { Button, ButtonVariant } from "../ui/button";

const ContentWrapper = styled.div`
  text-align: center;
  height: 590px;
  display: flex;
  flex-direction: column;

  @media ${Media.LARGE} {
    padding: 32px 23px;
    height: 630px;
  }
`;

const Title = styled(Text)`
  margin-top: 20px;
  font-size: ${FontSize.SIZE_18};
  font-weight: 600;
`;

const ConditionsWrapper = styled.div`
  margin-top: 16px;
  background-color: ${Color.WHITE};
  border: 1px solid ${Color.ACCENT_50};
  border-radius: 8px;
  text-align: left;
  flex-grow: 1;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const ApplyToContestButton = styled(Button)`
  font-weight: 700;
  &:disabled {
    color: ${Color.WHITE};
    background-color: ${Color.ACCENT_100};
  }
`;

const ConditionsAcknowledgeCheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 35px 10px 16px;
  cursor: pointer;
  font-size: ${FontSize.SIZE_14};

  input[type="checkbox"] {
    margin-right: 7px;
    &:hover {
      cursor: pointer;
    }
  }

  span {
    margin-top: 2px;
    line-height: 1;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

interface Props {
  open: boolean;
  onClose(): void;
  onCloseWithAcknowledge(): void;
  contestTagName: string;
}

export const EpisodeContestModal: React.FC<Props> = ({
  open,
  onClose,
  onCloseWithAcknowledge,
  contestTagName
}) => {
  const screenLarge = useMedia(Media.LARGE);
  const [isAcknowledged, setAcknowledged] = useState(false);
  const toggleCheckbox = useCallback(() => {
    const enabled = isAcknowledged;
    setAcknowledged(!enabled);
  }, [isAcknowledged]);

  const onReadButtonHandle = useCallback(() => {
    if (isAcknowledged) {
      onCloseWithAcknowledge();
    }
  }, [isAcknowledged, onCloseWithAcknowledge]);

  const allowScrollOn = useRef(null);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width={`${screenLarge ? 600 : 400}px`}
      close={onClose}
      scrollableElement={allowScrollOn}
    >
      <ContentWrapper>
        <Title>コンテスト応募規約</Title>
        <ConditionsWrapper>
          <iframe
            ref={allowScrollOn}
            title="コンテスト応募規約"
            src={`//${process.env.REACT_APP_WEB_DOMAIN}/contest-terms/${contestTagName}`}
            style={{
              border: "0px"
            }}
          />
        </ConditionsWrapper>
        <BottomContainer>
          <ConditionsAcknowledgeCheckboxLabel htmlFor="conditionsAcknowledgeCheckbox">
            <input
              id="conditionsAcknowledgeCheckbox"
              type="checkbox"
              checked={isAcknowledged}
              onChange={toggleCheckbox}
            />
            <Text as="span" fz={FontSize.SIZE_14} color={Color.ACCENT_1000}>
              規約を確認、理解した上で同意します
            </Text>
          </ConditionsAcknowledgeCheckboxLabel>
          <ApplyToContestButton
            type="button"
            variant={ButtonVariant.PILL}
            color={Color.TINT}
            block
            big
            mt={28}
            onClick={onReadButtonHandle}
            disabled={!isAcknowledged}
          >
            コンテストに参加する
          </ApplyToContestButton>
          <Button
            type="submit"
            variant={ButtonVariant.OUTLINED}
            color={Color.ACCENT_1000}
            block
            big
            mt={16}
            onClick={onClose}
          >
            閉じる
          </Button>
        </BottomContainer>
      </ContentWrapper>
    </Modal>
  );
};

import styled from "@emotion/styled";
import { STATUS_CODES } from "http";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLayoutUI } from "../hooks/useLayoutUI";
import { Color } from "../components/styles/enums";

import { AppHeader } from "../components/common/header/appHeader";

type Props = {
  status?: number;
};

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  padding: 30px 20px 180px;
  max-width: 960px;
  margin: auto;

  > pre {
    white-space: pre-wrap;
  }
`;

export const GenericErrorContainer: React.FC<Props> = ({ status }) => {
  const { setLayout } = useLayoutUI();
  const { type } = useParams<{ type?: string }>();

  let title = (status && STATUS_CODES[status]) || "Internal Error";
  let msg = "";
  if (type === "invalid_token") {
    title = "認証エラー";
    msg = "エラーが発生しました。しばらくしてからもう一度お試しください。";
  }

  useEffect(() => {
    setLayout({
      title: "エラー"
    });
  }, [setLayout]);

  return (
    <div>
      <AppHeader title="エラー" noBackButton moreButton={false} />
      <PageWrapper>
        <h1>{title}</h1>
        <pre>{status || msg}</pre>
      </PageWrapper>
    </div>
  );
};

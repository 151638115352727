import {
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
  Unsubscribe,
  User,
  UserCredential
} from "firebase/auth";
import { fetchAndActivate, getString } from "firebase/remote-config";
import { nanoid } from "nanoid";

import { getStorage, ref, uploadString, UploadResult } from "firebase/storage";
import { auth, remoteConfig } from "../index";

export class FirebaseRepository {
  public signInWithCustomToken = (token: string): Promise<UserCredential> =>
    signInWithCustomToken(auth(), token);

  public startObservingAuthState = (
    handler: (a: User | null) => void
  ): Unsubscribe => onAuthStateChanged(auth(), handler);

  public logout = (): Promise<void> => signOut(auth());

  public uploadImageToStorage = (
    dataURL: string,
    extension: string
  ): Promise<UploadResult> =>
    new Promise<UploadResult>((resolve, reject) => {
      const uid = auth()?.currentUser?.uid;
      if (!uid) {
        reject();
        return;
      }
      const uniqueFilename = `${nanoid()}.${extension}`;
      const storage = getStorage();
      const path = `userdata/${uid}/private/${uniqueFilename}`;
      const imageRef = ref(storage, path);
      resolve(uploadString(imageRef, dataURL, "data_url"));
    });

  public getRemoteConfigValue = (key: string): Promise<string> =>
    new Promise<string>(resolve => {
      if (remoteConfig.lastFetchStatus === "no-fetch-yet") {
        fetchAndActivate(remoteConfig).then(() => {
          resolve(getString(remoteConfig, key));
        });
      } else {
        resolve(getString(remoteConfig, key));
      }
    });
}

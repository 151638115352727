import { User } from "firebase/auth";
import useSWR, { mutate } from "swr";
import { query_recursive_types as RpcQueryRecursiveTypes } from "../infra/api/rpc/api";
import { AUTH_INITIAL_STATE, AUTH_KEY } from "./useAuthHandle";

interface AuthState {
  user: User | null | undefined;
  tellerUser: RpcQueryRecursiveTypes.IUserResponse | null;
  isVipUser: boolean;
  isOfficialWriter: boolean;
  requestReloadMe: () => void;
}

export const useAuth = (): AuthState => {
  const { data } = useSWR(AUTH_KEY);

  const toReturn = data || AUTH_INITIAL_STATE;

  const requestReloadMe = (): void => {
    mutate(AUTH_KEY, { ...data, requestFetchMe: true }, false);
  };

  return {
    user: toReturn.user,
    tellerUser: toReturn.tellerUser,
    isVipUser: toReturn.isVipUser,
    isOfficialWriter: toReturn.isOfficialWriter,
    requestReloadMe
  };
};

import { css, SerializedStyles } from "@emotion/react/macro";
import { px } from "../utils";

/**
 * Represent truncated text with an ellipsis
 *
 * @example
 * const Div = styled.div`
 *  ${ellipsis(300)}
 * `;
 */
export const ellipsis = (width: number | string): SerializedStyles => css`
  display: inline-block;
  max-width: ${px(width)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
`;

import React from "react";
import styled from "@emotion/styled/macro";

// Components
import { Modal } from "./modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";
import { Image } from "../ui/image";
import { Button, ButtonVariant } from "../ui/button";

import warningIcon from "../../assets/warning_icon.svg";

const ContentWrapper = styled.div`
  text-align: center;
  margin: 16px auto 0;

  @media ${Media.LARGE} {
    padding: 8px;
  }
`;

const Title = styled(Text)`
  font-size: ${FontSize.SIZE_18};
  font-weight: 600;
  line-height: 1.4;
  padding: 20px 25px 0;
`;

const Message = styled(Text)`
  font-size: ${FontSize.SIZE_14};
  margin-top: 15px;
  padding: 15px;
  text-align: center;

  > span {
    font-weight: normal;
  }

  > a {
    color: ${Color.TINT};
    font-weight: normal;
  }
`;

const GuidelinesImage = styled(Image)`
  max-width: 240px;

  @media ${Media.LARGE} {
    margin-top: 20px;
    max-width: 295px;
  }
`;

const OkButton = styled(Button)`
  height: 40px;
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  max-width: 80%;
  margin: 16px auto 30px;

  @media ${Media.SMALL} {
    height: 46px;
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ImageUploadWarningModal: React.FC<Props> = ({ open, onClose }) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width="326px"
      height="auto"
      hideClose
      noPadding
      close={onClose}
      overlayClose={false}
    >
      <ContentWrapper>
        <GuidelinesImage src={warningIcon} />
        <Title>
          画像のアップロードをする場合、許可のない漫画・芸能人・第三者のイラスト等の利用に注意して下さい。
        </Title>
        <Message>
          第三者が作成した画像をアップロードする際は権利元の利用規約やガイドライン等をご確認下さい。ユーザー様がアップロードする画像に関する全ての責任は、ユーザー様ご自身に帰属しております。詳しいルールは利用規約をご覧ください。
        </Message>
        <OkButton
          block
          variant={ButtonVariant.PILL}
          color={Color.TINT}
          onClick={onClose}
        >
          OK
        </OkButton>
      </ContentWrapper>
    </Modal>
  );
};

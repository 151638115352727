import styled from "@emotion/styled";
import React, { useRef, useEffect } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { keyframes } from "@emotion/react/macro";
import { Color, FontSize } from "../styles/enums";

const EnterAnimation = keyframes`
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Window = styled.div`
  animation: ${EnterAnimation} 256ms;
  position: relative;
  min-width: 280px;
  max-width: 460px;
  border-radius: 14px;
  background-color: ${Color.DIALOG_BG};
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 4px;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  max-height: 120px;
  min-height: 96px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MsgContainer = styled.div`
  word-break: break-all;
  text-align: center;
`;

const Msg = styled.div`
  white-space: pre-wrap;
  line-height: 1.2;
  font-weight: bold;
  font-size: ${FontSize.SIZE_17};
`;

const Separator = styled.div`
  background-color: #989ba1;
  width: 100%;
  height: 1px;
`;

const ConfirmButton = styled.div`
  color: ${Color.TINT};
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  padding-top: 14px;
  padding-bottom: 14px;
`;

interface Props {
  msg: string;
  buttonText?: string;
  close: () => void;
}

export const Alert: React.FC<Props> = ({ msg, close, buttonText }) => {
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (alertRef.current) {
      disableBodyScroll(alertRef.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [alertRef]);

  return (
    <Wrapper role="dialog" aria-modal ref={alertRef}>
      <Overlay aria-hidden onClick={close} />
      <Window>
        <Content>
          <MsgContainer>
            <Msg>{msg}</Msg>
          </MsgContainer>
        </Content>
        <Separator />
        <ConfirmButton onClick={close}>{buttonText || "OK"}</ConfirmButton>
      </Window>
    </Wrapper>
  );
};

// Core
import styled from "@emotion/styled/macro";
import { User } from "firebase/auth";
import logoImage from "../../../assets/header/logo.svg";
import menuImage from "../../../assets/header/menu.svg";
import { query_recursive_types as RpcQueryRecursiveTypes } from "../../../infra/api/rpc/api";
import { Color, Media, ZIndex } from "../../styles/enums";
import { Button } from "../../ui/button";
import { Container } from "../../ui/container";
import { Image } from "../../ui/image";
import { Text } from "../../ui/text";
import { Nav } from "./nav";

export const HEADER_HEIGHT_SMALL = "45px";
export const HEADER_HEIGHT_LARGE = "76px";

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: ${ZIndex.HEADER};
  width: 100%;
  line-height: 0;
  background-color: ${Color.WHITE};
  box-shadow: 0 0.5px 0 #d7d8da;

  @media ${Media.LARGE} {
    height: ${HEADER_HEIGHT_LARGE};
  }

  @media ${Media.SMALL} {
    height: ${HEADER_HEIGHT_SMALL};
  }
`;

const Inner = styled(Container)`
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "nav logo";
  grid-template-columns: auto 1fr;
  align-items: center;
  height: ${HEADER_HEIGHT_SMALL};
  padding: 0 16px;

  @media ${Media.SMALL} {
    max-width: 60.4em;
    width: 100%;
    min-width: 100%;
  }

  @media ${Media.LARGE} {
    grid-template-areas: "logo nav";
    height: 76px;
    max-width: 1216px;
    margin: auto;
  }
`;

const Cell = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
`;

const LogoCell = styled.div`
  grid-area: "logo";
  margin-top: -4px;

  @media ${Media.LARGE} {
    margin-left: -1px;
  }
`;

interface Props {
  authUser: User | null | undefined;
  tellerUser: RpcQueryRecursiveTypes.IUserResponse | null;
  isOfficialWriter: boolean;
  isVipUser: boolean;
  screenLarge: boolean;
  onSidebarOpen(): void;
  onLogout: () => void;
}

export const Header: React.FC<Props> = ({
  authUser,
  tellerUser,
  isOfficialWriter,
  isVipUser,
  screenLarge,
  onSidebarOpen,
  onLogout
}) => (
  <>
    <Wrapper>
      <Inner>
        <LogoCell>
          <Text as="p" ta="center">
            <a href={`//${process.env.REACT_APP_WEB_DOMAIN}/`}>
              <Image
                src={logoImage}
                ml={0}
                mt={-1}
                width={screenLarge ? 145 : 135}
                alt="Teller"
              />
            </a>
          </Text>
        </LogoCell>
        <Cell area="nav">
          {!screenLarge ? (
            <Button aria-label="メニューを開く" onClick={onSidebarOpen}>
              <Image src={menuImage} width={18} height={18} />
            </Button>
          ) : null}
          {screenLarge ? (
            <Nav
              authUser={authUser}
              tellerUser={tellerUser}
              isVipUser={isVipUser}
              isOfficialWriter={isOfficialWriter}
              onLogout={onLogout}
            />
          ) : null}
        </Cell>
      </Inner>
    </Wrapper>
  </>
);

// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from "history";
import { Component, ReactNode } from "react";
import { GenericErrorContainer } from "../../routes";

interface Props {
  history: History;
}

interface State {
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  componentDidCatch(): void {
    const { history } = this.props;

    // Try to recover fatal error when history change
    history.listen(() => {
      window.location.reload();
    });
  }

  render(): ReactNode {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <GenericErrorContainer status={(error as any).status} />;
    }

    return children;
  }
}

import styled from "@emotion/styled/macro";
import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { NestedValue, useForm, DefaultValues } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import {
  resource as RpcResource,
  query_recursive_types as RpcRecursiveTypes,
  query_types as RpcQueryTypes,
  story as RpcStory
} from "../../infra/api/rpc/api";
import { rpcGenreToGenre, Genre } from "../../models/genre";
import { NovelUseCase } from "../../usecases/novelUseCase";
import { Episode, toMarkdown } from "../../models/episode";
import {
  Novel,
  PublishRange,
  publishRangeFromRpcSharedWithStatus
} from "../../models/novel";
import { useAuth } from "../../hooks/useAuth";
import { useGuidelinesModal } from "../../hooks/useGuidelinesModal";
import { useContestAcknowledge } from "../../hooks/useContestAcknowledge";
import { ActionSheet } from "../common/actionSheet";
import { Color, FontSize, Media } from "../styles/enums";
import { Button, ButtonVariant } from "../ui/button";
import { Image } from "../ui/image";
import { Text } from "../ui/text";
import { NovelTagSelectionActionSheet } from "./novelTagSelectionActionSheet";
import { NovelThumbnailSelectionActionSheet } from "./novelThumbnailSelectionActionSheet";
import { NovelGenreSelectionActionSheet } from "./novelGenreSelectionActionSheet";
import { GuidelinesModal } from "../common/guidelinesModal";
import { EpisodeContestModal } from "../episode/episodeContestModal";
import { Alert } from "../common/alert";
import { ActivityModal } from "../common/activityModal";
import closeIcon from "../../assets/close_icon.svg";
import thumbnailEditImage from "../../assets/thumbnail_edit.png";
import dropdownArrow from "../../assets/dropdown_arrow.svg";
import rightArrow from "../../assets/right_arrow.svg";

import { logError } from "../../utils/utils";
import {
  Wrapper as RadioLabelWrapper,
  RadioWrapper,
  RadioOption
} from "../common/twoOptionsSelector";
import "react-slidedown/lib/slidedown.css";
import { useDefaultThumbnail } from "../../hooks/useDefaultThumbnail";
import { useUploadDefaultThumbnail } from "../../hooks/useUploadDefaultThumbnail";

interface Props {
  open: boolean;
  isForPublish: boolean;
  defaultTags?: string[];
  novel?: RpcRecursiveTypes.ISeriesResponse | null;
  episode?: Episode | null;
  content?: string;
  isWritePage?: boolean;
  openAboutRatingModal: () => void;
  onClose(): void;
  onSave(
    saved: boolean,
    error: boolean,
    published: boolean,
    publishedFor: PublishRange | null,
    isNew: boolean,
    tags?: string[] | null,
    novelId?: string | null,
    epId?: string | null,
    epTitle?: string
  ): void;
}

type FormValues = {
  novelTitle?: string | null;
  episodeTitle?: string | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  thumbnailId?: string | null;
  tags?: NestedValue<string[]>;
  sensitiveFlag?: RpcQueryTypes.SensitiveFlag;
  contestConditionsAcknowledge?: boolean;
  publishRange: PublishRange;
  genre: Genre | null;
};

const TITLE_MAX_LENGTH = 50;
const DESCRIPTION_MAX_LENGTH = 1000;
const TAGS_MAX_LENGTH = 6;

const Header = styled.div`
  position: fixed;
  width: 100%;
  border-bottom: 1px solid ${Color.ACCENT_50};
  padding: 12px 16px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Form = styled.form`
  text-align: left;
  padding: 16px;
  position: relative;
  top: 52px;
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 100px;
`;

const SeriesImageWrapper = styled.div`
  text-align: center;
`;

const ThumbnailWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 auto 20px;
  max-width: 341px;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  border-radius: 4px;
  display: block;
  margin: auto;
  max-width: 341px;

  @media ${Media.SMALL} {
    max-height: 30vh;
  }
`;

const ThumbnailButtons = styled.div`
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 341px;
`;

const ThumbnailReloadButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 16px;
  cursor: pointer;

  > svg {
    filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.4));
  }

  :hover {
    opacity: 0.7;
  }
`;

const ThumbnailEditButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 8px 8px 8px 16px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const ThumbnailRemoveButton = styled.button`
  position: absolute;
  bottom: -5px;
  padding: 0 12px;
  cursor: pointer;

  > svg {
    width: 30px;
    filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.4));
  }

  :hover {
    opacity: 0.7;
  }
`;

const Label = styled.label`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  margin: 16px 0 8px;
`;

const TextField = styled.input`
  display: block;
  border: none;
  background-color: ${Color.WHITE};
  padding: 12px 16px;
  margin: 0 -16px 8px;
  width: calc(100% + 32px);
  line-height: 1;
`;

const RatingContainer = styled.div`
  padding: 12px 16px;
  margin: 0 -16px 8px;
  width: calc(100% + 32px);
  display: flex;
  flex-direction: column;
`;

const RatingSectionTitle = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  svg {
    margin: 0 0 3px 4px;
  }
`;

const IsSentiveCheckboxInputLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 16px;
  cursor: pointer;
  input[type="checkbox"] {
    margin-right: 4px;
  }

  span {
    margin-top: 2px;
    line-height: 1;
  }
`;

const TextArea = styled(TextField.withComponent("textarea"))``;

const DropdownContainer = styled.div`
  margin-right: 15px;
  width: 190px;
  height: 37px;
  background: url(${dropdownArrow}) no-repeat right 10px center;
  border-radius: 80px;
  margin-bottom: 10px;
`;

const Dropdown = styled.select`
  border: 1px solid ${Color.ACCENT_100};
  width: 190px;
  border-radius: 80px;
  padding: 10px 20px;
  color: ${Color.ACCENT_1000};
  font-size: ${FontSize.SIZE_14};
  appearance: none;
  background: none;

  &:focus {
    outline: none;
    box-sizing: border-box;
  }
`;

const GenreField = styled.div`
  display: flex;
  margin: 0 -16px 8px;
  background-color: ${Color.WHITE};
  padding: 12px 16px 0;
  width: calc(100% + 48px);
  height: 43px;
  cursor: pointer;

  > input {
    cursor: pointer;
  }
`;

const Tags = styled.div`
  margin: 0 -16px 8px;
  background-color: ${Color.WHITE};
  padding: 12px 16px 0;
  width: calc(100% + 32px);
`;

const RightArrowImage = styled(Image)`
  margin-left: auto;
  width: 10px;
  margin-top: -10px;
`;

const Tag = styled.button`
  display: inline-block;
  margin: 0 12px 12px 0;
  border-radius: 32px;
  background-color: ${Color.ACCENT_50};
  cursor: pointer;
  padding: 8px 12px;
  color: ${Color.ACCENT_700};
  font-size: ${FontSize.SIZE_14};

  :hover {
    opacity: 0.7;
  }
`;

const AddTag = styled(Tag)`
  background-color: #e0efff;
  color: ${Color.TINT};
`;

const ConfirmButtonWrapper = styled.div`
  width: 100%;
  background-color: ${Color.PRIMARY};
  position: fixed;
  bottom: 50px;
  left: 0;
  padding: 16px 32px;
  height: 80px;
`;

const SlideDownFast = styled(SlideDown)`
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  &.transitioning {
    > input {
      width: 100%;
    }
  }
`;

const novelUseCase = new NovelUseCase();

export const NovelInformationFormActionSheet: React.VFC<Props> = ({
  open,
  novel,
  episode,
  content,
  isForPublish,
  isWritePage,
  defaultTags = [],
  openAboutRatingModal,
  onClose,
  onSave
}) => {
  const history = useHistory();
  const { isOfficialWriter } = useAuth();
  const {
    shouldShow: shouldShowGuidelines,
    isOpen: isGuidelinesModalOpen,
    open: showGuidelinesModal,
    close: closeGuidelinesModal
  } = useGuidelinesModal();

  const formValues: DefaultValues<FormValues> = {
    novelTitle: "",
    episodeTitle: "",
    description: "",
    tags: [],
    genre: null,
    sensitiveFlag: episode?.sensitiveFlag,
    publishRange: PublishRange.PUBLIC
  };

  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit: createHandleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: formValues
  });
  const submitRef = useRef<HTMLButtonElement | null>(null);
  const [savedTags, setSavedTags] = useState<string[] | undefined>([]);
  const [isShowAlert, setShowAlert] = useState(false);
  const [alertErrorMsg, setAlertErrorMsg] = useState<string | null>(null);
  const [isTriggerOnSaveOnAlertClose, setTriggerOnSaveOnAlertClose] = useState(
    false
  );

  const [isSaving, setIsSaving] = useState(false);

  const [
    episodePublishOnHold,
    setEpisodePublishOnHold
  ] = useState<Episode | null>(null);

  const {
    checkContestTags,
    openContestModal,
    selectedContestTag,
    isOpen: contestModalIsOpen,
    close: closeContestModal
  } = useContestAcknowledge();

  const publishAfterContestConditionsAcknowledged = useCallback(() => {
    closeContestModal();
    setValue("contestConditionsAcknowledge", true, { shouldValidate: true });
    if (submitRef.current) {
      submitRef.current.click();
    }
  }, [closeContestModal, setValue]);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (novel) {
      const tags = novel.tags?.map(t => t.value || "");
      reset({
        novelTitle: novel.title?.value || "",
        episodeTitle: "",
        description: novel.description?.value || "",
        thumbnailUrl: novel.thumbnail?.servingUrl?.value || "",
        tags,
        publishRange: publishRangeFromRpcSharedWithStatus(
          novel.sharedWithStatus
        ),
        genre: rpcGenreToGenre(novel?.genre || null)
      });
      setSavedTags(tags);
    } else if (episode) {
      const { title } = episode as Episode;
      if (episode.id) {
        const {
          description,
          thumbnailUrl,
          tags,
          novelTitle,
          sensitiveFlag
        } = episode as Episode;
        setSavedTags(tags);
        reset({
          episodeTitle: title,
          description,
          thumbnailUrl,
          tags,
          novelTitle,
          sensitiveFlag
        });
      }
    }
  }, [episode, novel, open, reset]);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (!novel && episode && !episode.id) {
      reset({ episodeTitle: episode.title, tags: defaultTags });
      setSavedTags(defaultTags);
    }
  }, [defaultTags, episode, novel, open, reset]);

  const novelTitle = watch("novelTitle");
  const episodeTitle = watch("episodeTitle");
  const description = watch("description");
  const thumbnailUrl = watch("thumbnailUrl");
  const tags = watch("tags");
  const sensitiveFlag = watch("sensitiveFlag");
  const [isOneShot, setOneShot] = useState(novel?.isOneshot?.value || false);
  const publishRange = watch("publishRange");
  const genre = watch("genre");
  const [isCompleted, setCompleted] = useState(
    (!isOneShot && novel?.isCompleted?.value) || false
  );

  const { defaultThumbnailUrl, refreshBgType } = useDefaultThumbnail(
    !!thumbnailUrl,
    novelTitle ?? undefined
  );
  const { thumbnailRef, uploadDefaultThumbnail } = useUploadDefaultThumbnail();

  const showAlert = useCallback((msg: string) => {
    setAlertErrorMsg(msg);
    setShowAlert(true);
  }, []);

  const closeAlert = useCallback(() => {
    setAlertErrorMsg(null);
    setShowAlert(false);
    if (isTriggerOnSaveOnAlertClose) {
      onSave(
        true, // saved
        false, // error
        false, // published
        publishRange, // publish for
        true // is New
      );
    }
  }, [isTriggerOnSaveOnAlertClose, onSave, publishRange]);

  const [
    novelThumbnailSelectionActionSheetIsOpen,
    setNovelThumbnailSelectionActionSheetIsOpen
  ] = useState(false);
  const [
    novelTagSelectionActionSheetIsOpen,
    setNovelTagSelectionActionSheetIsOpen
  ] = useState(false);

  const openNovelThumbnailSelectionActionSheet = useCallback(() => {
    setNovelThumbnailSelectionActionSheetIsOpen(true);
  }, []);

  const closeNovelThumbnailSelectionActionSheet = useCallback(() => {
    setNovelThumbnailSelectionActionSheetIsOpen(false);
  }, []);

  const [
    novelGenreSelectionActionSheetIsOpen,
    setNovelGenreSelectionActionSheetIsOpen
  ] = useState(false);

  const openNovelGenreSelectionActionSheet = useCallback(() => {
    setNovelGenreSelectionActionSheetIsOpen(true);
  }, []);

  const closeNovelGenreSelectionActionSheet = useCallback(
    (selectedGenre: Genre | null) => {
      setNovelGenreSelectionActionSheetIsOpen(false);
      setValue("genre", selectedGenre);
      clearErrors("genre");
    },
    [clearErrors, setValue]
  );

  const handleThumbnailChange = useCallback(
    (newThumbnail: RpcResource.IImage) => {
      if (newThumbnail.servingUrl?.value) {
        setValue("thumbnailUrl", newThumbnail.servingUrl.value);
      }
      if (newThumbnail.id?.value) {
        setValue("thumbnailId", newThumbnail.id?.value);
      }
    },
    [setValue]
  );

  const openNovelTagsSelectionActionSheet = useCallback(() => {
    setNovelTagSelectionActionSheetIsOpen(true);
  }, []);

  const closeNovelTagsSelectionActionSheet = useCallback(
    (undoChanges: boolean) => {
      setNovelTagSelectionActionSheetIsOpen(false);
      if (undoChanges) {
        setValue("tags", savedTags);
        clearErrors("tags");
      }
    },
    [clearErrors, savedTags, setValue]
  );

  const handleTagsChange = useCallback(
    (newTags: string[]) => {
      setValue("tags", newTags);
      if (newTags.length > TAGS_MAX_LENGTH) {
        setError("tags", {
          type: "maxLength",
          message: `登録できるタグは${TAGS_MAX_LENGTH}つまでです`
        });
      } else {
        clearErrors("tags");
      }
    },
    [clearErrors, setError, setValue]
  );

  const canSelectSingleStorySeries = useMemo(() => {
    if (!novel) {
      return true;
    }

    return novel.canChangeToOneshot?.value || false;
  }, [novel]);

  const validatedNovelFromValues = useCallback(
    async (values): Promise<Novel | null> => {
      if (novel) {
        const formNovel: Novel = {
          id: novel.id?.value || "",
          userId: novel.user?.id?.value || "",
          title: values.novelTitle || "",
          description: values.description || "",
          thumbnailId: values.thumbnailId || novel.thumbnail?.id?.value || "",
          thumbnailUrl:
            values.thumbnailUrl || novel.thumbnail?.servingUrl?.value || "",
          tags: values.tags,
          genre,
          isCompleted,
          isOneShot,
          publishRange
        };
        // デフォルトの画像のとき
        if (!thumbnailUrl) {
          const defaultThumbnail = await uploadDefaultThumbnail();
          if (defaultThumbnail) {
            formNovel.thumbnailId = defaultThumbnail.id;
            formNovel.thumbnailUrl = defaultThumbnail.servingUrl;
          }
        }
        if (formNovel.genre === null) {
          showAlert("ジャンルを選択してください。");
          return null;
        }

        if (formNovel.tags?.length === 0) {
          showAlert("タグを追加してください。");
          return null;
        }

        if (!formNovel.thumbnailUrl && !formNovel.thumbnailId) {
          showAlert("画像を選択してください。");
          return null;
        }

        return formNovel;
      }
      return null;
    },
    [
      genre,
      isCompleted,
      isOneShot,
      novel,
      publishRange,
      showAlert,
      thumbnailUrl,
      uploadDefaultThumbnail
    ]
  );

  const validatedEpisodeFromValues = useCallback(
    async (values): Promise<Episode | null> => {
      if (!episode) return null;

      const formEpisode: Episode = {
        ...episode,
        title:
          (canSelectSingleStorySeries && isOneShot
            ? values.novelTitle
            : values.episodeTitle) || "",
        description: values.description || "",
        thumbnailId: values.thumbnailId || "",
        thumbnailUrl: values.thumbnailUrl || "",
        tags: values.tags,
        isOfficial: isOfficialWriter,
        novelTitle: values.novelTitle || "",
        sensitiveFlag: Number(values.sensitiveFlag),
        novelPublishFor: values.publishRange,
        novelGenre: values.genre || null
      };
      if (content) {
        formEpisode.novelScript = toMarkdown(content);
      }

      // Prevent "script too short" error
      if (!formEpisode.novelScript || formEpisode.novelScript?.length === 0) {
        formEpisode.novelScript = " ";
      }

      // デフォルトの画像のとき
      // デフォルトの画像のとき
      if (!thumbnailUrl) {
        const defaultThumbnail = await uploadDefaultThumbnail();
        if (defaultThumbnail) {
          formEpisode.thumbnailId = defaultThumbnail.id;
          formEpisode.thumbnailUrl = defaultThumbnail.servingUrl;
        }
      }

      if (!formEpisode.thumbnailUrl && !formEpisode.thumbnailId) {
        showAlert("画像を選択してください");
        return null;
      }

      if (!formEpisode.novelGenre) {
        showAlert("ジャンルを選択してください");
        return null;
      }

      if (!formEpisode.tags || formEpisode.tags.length === 0) {
        showAlert("タグを追加してください");
        return null;
      }

      if (isForPublish) {
        formEpisode.status = RpcStory.StoryStatus.PUBLISH;
      }
      return formEpisode;
    },
    [
      canSelectSingleStorySeries,
      content,
      episode,
      isForPublish,
      isOfficialWriter,
      isOneShot,
      showAlert,
      thumbnailUrl,
      uploadDefaultThumbnail
    ]
  );

  const handleUpdateNovel = useCallback(
    async (formNovel: Novel) => {
      try {
        setIsSaving(true);
        await novelUseCase.updateNovel(formNovel);
        showAlert("更新が完了しました。");
        setTriggerOnSaveOnAlertClose(true);
      } catch (err) {
        showAlert("不明なエラーが発生しました。");
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [onClose, showAlert]
  );

  const handleUpdateOrCreateEpisode = useCallback(
    async (formEpisode: Episode, draftFromGuidelinesModal?: boolean) => {
      if (!episode || !formEpisode) return;

      let isNew = true;
      try {
        setIsSaving(true);
        let newId: string | null = null;
        let novelId: string | null = null;

        // Episode and novel exists, need to update both
        if (episode.id && episode.id !== "new" && episode.novelId) {
          isNew = false;

          await novelUseCase.updateEpisodeAndNovel(
            formEpisode,
            isCompleted,
            isOneShot
          );
          newId = episode.id;
          novelId = episode.novelId;
        } else {
          // Novel and episode doesn't exists, create both
          const createdIds = await novelUseCase.createEpisode(
            formEpisode,
            isCompleted,
            isOneShot
          );
          newId = createdIds.episodeId;
          novelId = createdIds.novelId;
        }

        // Now we have id, refresh url
        if ((!isForPublish || draftFromGuidelinesModal) && isWritePage) {
          setTimeout(() => {
            // Execute refresh only if user didn't change page before
            if (window.location.pathname.indexOf("/episodes/") > -1) {
              const link = `/novel/${novelId}/episodes/${newId}?notransition=1`;
              history.push(link);
            }
          }, 1500);
        }
        onSave(
          true, // saved
          false, // error
          isForPublish && !draftFromGuidelinesModal, // published
          publishRange, // published for
          isNew, // is new
          formEpisode.tags,
          novelId,
          newId,
          formEpisode.title || undefined
        );
      } catch (err) {
        onSave(
          false, // saved
          true, // error
          false, // published
          null, // publishRange
          isNew // is new
        );
        logError(err);
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [
      episode,
      history,
      isCompleted,
      isForPublish,
      isOneShot,
      isWritePage,
      onClose,
      onSave,
      publishRange
    ]
  );

  const handleSubmit = createHandleSubmit(async values => {
    setIsSaving(true);
    // Novel already exists case, update information
    if (novel) {
      const formNovel = await validatedNovelFromValues(values);
      if (formNovel) {
        if (
          !episode &&
          formNovel.tags &&
          checkContestTags(formNovel.tags) &&
          !values.contestConditionsAcknowledge
        ) {
          openContestModal(formNovel.tags);
          setIsSaving(false);
          return;
        }
        handleUpdateNovel(formNovel);
      }
    }
    const formEpisode = await validatedEpisodeFromValues(values);
    if (!formEpisode || !episode) {
      setIsSaving(false);
      return;
    }

    if (
      formEpisode.tags &&
      checkContestTags(formEpisode.tags) &&
      !values.contestConditionsAcknowledge
    ) {
      openContestModal(formEpisode.tags);
      setIsSaving(false);
      return;
    }

    // If user has less than 3 published episodes, show guidelines modal
    // And store current episode information in state in case "publish" button from modal is pressed
    // So we can resume publish operation right away (after error validations has been passed)
    if (shouldShowGuidelines) {
      setEpisodePublishOnHold(formEpisode);
      showGuidelinesModal();
      setIsSaving(false);
      return;
    }

    handleUpdateOrCreateEpisode(formEpisode);
  });

  const guidelinesModalPublishCallback = useCallback(() => {
    if (episodePublishOnHold) {
      handleUpdateOrCreateEpisode(episodePublishOnHold);
    }
  }, [episodePublishOnHold, handleUpdateOrCreateEpisode]);

  const guidelinesModalDraftCallback = useCallback(() => {
    if (episodePublishOnHold) {
      episodePublishOnHold.status = RpcStory.StoryStatus.DRAFT;
      handleUpdateOrCreateEpisode(episodePublishOnHold, true);
      closeGuidelinesModal();
    }
  }, [closeGuidelinesModal, episodePublishOnHold, handleUpdateOrCreateEpisode]);

  const removeThumbnailSelection = useCallback(() => {
    setValue("thumbnailUrl", null);
  }, [setValue]);

  const novelTitleLength = novelTitle?.length || 0;
  const episodeTitleLength = episodeTitle?.length || 0;

  return (
    <>
      {isShowAlert && alertErrorMsg ? (
        <Alert msg={alertErrorMsg} close={closeAlert} />
      ) : null}
      {isSaving ? <ActivityModal /> : null}
      <ActionSheet open={open} onClose={onClose}>
        <Header>
          <Text fz={FontSize.SIZE_16} fw="bold">
            {isForPublish ? "投稿する前に" : "作品詳細"}
          </Text>
          <CloseButton type="button" onClick={onClose}>
            <Image src={closeIcon} width={18} height={18} alt="閉じる" />
          </CloseButton>
        </Header>
        <Form onSubmit={handleSubmit}>
          <SeriesImageWrapper>
            <ThumbnailWrapper
              onClick={e => {
                e.preventDefault();
                openNovelThumbnailSelectionActionSheet();
              }}
            >
              <Thumbnail
                src={
                  thumbnailUrl
                    ? `${thumbnailUrl}=s340`
                    : defaultThumbnailUrl ?? ""
                }
                ref={thumbnailRef}
                crossOrigin="anonymous"
              />
              <ThumbnailButtons>
                {thumbnailUrl ? (
                  <ThumbnailRemoveButton
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      removeThumbnailSelection();
                    }}
                  >
                    <svg
                      height="48"
                      viewBox="0 0 48 48"
                      width="48"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                    >
                      <path d="M0 0h48v48h-48z" fill="none" />
                      <path d="M29.17 16l-5.17 5.17-5.17-5.17-2.83 2.83 5.17 5.17-5.17 5.17 2.83 2.83 5.17-5.17 5.17 5.17 2.83-2.83-5.17-5.17 5.17-5.17-2.83-2.83zm-5.17-12c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z" />
                    </svg>
                  </ThumbnailRemoveButton>
                ) : (
                  <ThumbnailReloadButton
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      refreshBgType();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-refresh-ccw"
                    >
                      <polyline points="1 4 1 10 7 10" />
                      <polyline points="23 20 23 14 17 14" />
                      <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                    </svg>
                  </ThumbnailReloadButton>
                )}
                <ThumbnailEditButton
                  onClick={e => {
                    e.preventDefault();
                    openNovelThumbnailSelectionActionSheet();
                  }}
                >
                  <Image
                    src={thumbnailEditImage}
                    width={36}
                    height={34}
                    alt="変更する"
                  />
                </ThumbnailEditButton>
              </ThumbnailButtons>
            </ThumbnailWrapper>
          </SeriesImageWrapper>
          <RadioLabelWrapper>
            <Label htmlFor="novelCompleted">
              <Text
                as="span"
                fz={FontSize.SIZE_12}
                fw="bold"
                color={Color.ACCENT_500}
              >
                連載状況
              </Text>
            </Label>
            <RadioWrapper>
              {canSelectSingleStorySeries ? (
                <>
                  <RadioOption
                    onClick={() => setOneShot(false)}
                    selected={!isOneShot}
                  >
                    連載
                  </RadioOption>
                  <RadioOption
                    onClick={() => setOneShot(true)}
                    selected={isOneShot}
                  >
                    読切
                  </RadioOption>
                </>
              ) : (
                <>
                  <RadioOption
                    onClick={() => setCompleted(false)}
                    selected={!isCompleted}
                  >
                    連載中
                  </RadioOption>
                  <RadioOption
                    onClick={() => setCompleted(true)}
                    selected={isCompleted}
                  >
                    完結
                  </RadioOption>
                </>
              )}
            </RadioWrapper>
          </RadioLabelWrapper>
          <Label htmlFor="novelTitle">
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              fw="bold"
              color={Color.ACCENT_500}
            >
              作品タイトル
            </Text>
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              color={
                novelTitleLength <= TITLE_MAX_LENGTH
                  ? Color.ACCENT_500
                  : Color.RED
              }
            >
              {TITLE_MAX_LENGTH - novelTitleLength}
            </Text>
          </Label>
          <TextField
            id="title"
            type="text"
            placeholder="タイトル"
            maxLength={TITLE_MAX_LENGTH}
            {...register("novelTitle", {
              required: { value: true, message: "タイトルを入力してください" },
              maxLength: {
                value: TITLE_MAX_LENGTH,
                message: "文字数制限を超えています"
              }
            })}
          />
          {errors.novelTitle ? (
            <Text fz={FontSize.SIZE_12} color={Color.RED}>
              {errors.novelTitle.message}
            </Text>
          ) : null}
          <SlideDownFast closed={canSelectSingleStorySeries && isOneShot}>
            {episode && (
              <>
                <Label htmlFor="episodeTitle">
                  <Text
                    as="span"
                    fz={FontSize.SIZE_12}
                    fw="bold"
                    color={Color.ACCENT_500}
                  >
                    エピソードタイトル
                  </Text>
                  <Text
                    as="span"
                    fz={FontSize.SIZE_12}
                    color={
                      episodeTitleLength <= TITLE_MAX_LENGTH
                        ? Color.ACCENT_500
                        : Color.RED
                    }
                  >
                    {TITLE_MAX_LENGTH - episodeTitleLength}
                  </Text>
                </Label>
                <TextField
                  id="title"
                  type="text"
                  {...register("episodeTitle", {
                    required: {
                      value: true,
                      message: "タイトルを入力してください"
                    },
                    maxLength: {
                      value: TITLE_MAX_LENGTH,
                      message: "文字数制限を超えています"
                    }
                  })}
                />
                {errors.episodeTitle ? (
                  <Text fz={FontSize.SIZE_12} color={Color.RED}>
                    {errors.episodeTitle.message}
                  </Text>
                ) : null}
              </>
            )}
          </SlideDownFast>
          <Label as="p">
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              fw="bold"
              color={Color.ACCENT_500}
            >
              ジャンル
            </Text>
            <Text as="span" />
            <GenreField onClick={openNovelGenreSelectionActionSheet}>
              <TextField
                id="genre"
                type="text"
                readOnly
                placeholder="ジャンルを選択してください"
                {...register("genre", {
                  required: {
                    value: true,
                    message: "ジャンルを選択してください"
                  }
                })}
              />

              <RightArrowImage src={rightArrow} />
            </GenreField>
          </Label>
          {errors.genre ? (
            <Text fz={FontSize.SIZE_12} color={Color.RED} mt={-10}>
              {errors.genre?.message}
            </Text>
          ) : null}
          <Label as="p">
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              fw="bold"
              color={Color.ACCENT_500}
            >
              タグ
            </Text>
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              color={
                (tags?.length ?? 0) <= TAGS_MAX_LENGTH
                  ? Color.ACCENT_500
                  : Color.RED
              }
            >
              {TAGS_MAX_LENGTH - (tags?.length ?? 0)}
            </Text>
          </Label>
          <Tags>
            {tags?.map(tag => (
              <Tag
                key={tag}
                type="button"
                onClick={openNovelTagsSelectionActionSheet}
              >
                #{tag}
              </Tag>
            ))}
            <AddTag type="button" onClick={openNovelTagsSelectionActionSheet}>
              +追加
            </AddTag>
          </Tags>
          {errors.tags ? (
            <Text fz={FontSize.SIZE_12} color={Color.RED}>
              {errors.tags.message}
            </Text>
          ) : null}
          <Label htmlFor="description">
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              fw="bold"
              color={Color.ACCENT_500}
            >
              あらすじ
            </Text>
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              color={
                (description?.length ?? 0) <= DESCRIPTION_MAX_LENGTH
                  ? Color.ACCENT_500
                  : Color.RED
              }
            >
              {DESCRIPTION_MAX_LENGTH - (description?.length ?? 0)}
            </Text>
          </Label>
          <TextArea
            id="description"
            rows={4}
            placeholder="あらすじ(任意)"
            {...register("description", {
              maxLength: {
                value: DESCRIPTION_MAX_LENGTH,
                message: "文字数制限を超えています"
              }
            })}
          />
          {errors.description ? (
            <Text fz={FontSize.SIZE_12} color={Color.RED}>
              {errors.description.message}
            </Text>
          ) : null}
          <Label htmlFor="publicationRange">
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              fw="bold"
              color={Color.ACCENT_500}
            >
              公開範囲
            </Text>
          </Label>
          <DropdownContainer>
            <Dropdown id="publicationRange" {...register("publishRange")}>
              <option value={PublishRange.PUBLIC}>全体公開</option>
              <option value={PublishRange.FOLLOWERS}>フォロワー限定公開</option>
              <option value={PublishRange.MUTUAL_FOLLOW}>
                相互フォロー限定公開
              </option>
            </Dropdown>
          </DropdownContainer>
          {/* レーティング設定 */}
          {isForPublish && (
            <RatingContainer>
              <RatingSectionTitle onClick={openAboutRatingModal}>
                <Text
                  as="span"
                  fz={FontSize.SIZE_12}
                  fw="bold"
                  color={Color.ACCENT_500}
                >
                  レーティング設定
                </Text>
                <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
                  <path
                    d="m7.33301 11.9999h1.33333v-1.3333h-1.33333zm.66666-10.66665c-3.68 0-6.66666 2.98667-6.66666 6.66667 0 3.67998 2.98666 6.66668 6.66666 6.66668 3.68003 0 6.66663-2.9867 6.66663-6.66668 0-3.68-2.9866-6.66667-6.66663-6.66667zm0 12.00005c-2.94 0-5.33333-2.3934-5.33333-5.33338 0-2.94 2.39333-5.33333 5.33333-5.33333 2.94003 0 5.33333 2.39333 5.33333 5.33333 0 2.93998-2.3933 5.33338-5.33333 5.33338zm0-9.33338c-1.47333 0-2.66666 1.19333-2.66666 2.66667h1.33333c0-.73334.6-1.33334 1.33333-1.33334.73334 0 1.33334.6 1.33334 1.33334 0 1.33333-2 1.16666-2 3.33333h1.33333c0-1.5 1.99996-1.66667 1.99996-3.33333 0-1.47334-1.19329-2.66667-2.66663-2.66667z"
                    fill="#1b88ff"
                  />
                </svg>
              </RatingSectionTitle>
              <IsSentiveCheckboxInputLabel htmlFor="sensitiveFlagForNovelInformationFormActionSheet">
                <input
                  id="sensitiveFlagForNovelInformationFormActionSheet"
                  type="checkbox"
                  value={
                    sensitiveFlag ===
                    RpcQueryTypes.SensitiveFlag.SENSITIVE_FLAG_ENABLED_BY_ADMIN
                      ? RpcQueryTypes.SensitiveFlag
                          .SENSITIVE_FLAG_ENABLED_BY_ADMIN
                      : RpcQueryTypes.SensitiveFlag
                          .SENSITIVE_FLAG_ENABLED_BY_USER
                  }
                  disabled={
                    sensitiveFlag ===
                    RpcQueryTypes.SensitiveFlag.SENSITIVE_FLAG_ENABLED_BY_ADMIN
                  }
                  {...register("sensitiveFlag")}
                />
                <Text as="span" fz={FontSize.SIZE_14} color={Color.ACCENT_1000}>
                  センシティブな内容を含む
                </Text>
              </IsSentiveCheckboxInputLabel>
              <Text as="span" fz={FontSize.SIZE_12} color={Color.ACCENT_500}>
                通報やサイトパトロールにより「センシティブな内容を含む」に変更される場合がございます。予めご了承ください。
              </Text>
            </RatingContainer>
          )}
          {/* Spacer */}
          <div
            style={{
              height: "100px"
            }}
          />
          <ConfirmButtonWrapper>
            <Button
              ref={submitRef}
              type="submit"
              variant={ButtonVariant.PILL}
              color={Color.TINT}
              block
              big
            >
              投稿
            </Button>
          </ConfirmButtonWrapper>
        </Form>
      </ActionSheet>
      <NovelThumbnailSelectionActionSheet
        open={novelThumbnailSelectionActionSheetIsOpen}
        onChange={handleThumbnailChange}
        onClose={closeNovelThumbnailSelectionActionSheet}
      />
      <NovelTagSelectionActionSheet
        open={novelTagSelectionActionSheetIsOpen}
        selectedTags={tags}
        error={errors.tags}
        onChange={handleTagsChange}
        onClose={closeNovelTagsSelectionActionSheet}
      />
      <NovelGenreSelectionActionSheet
        open={novelGenreSelectionActionSheetIsOpen}
        selectedGenre={genre}
        onClose={closeNovelGenreSelectionActionSheet}
      />
      <GuidelinesModal
        open={isGuidelinesModalOpen}
        onClose={closeGuidelinesModal}
        onPublishButton={guidelinesModalPublishCallback}
        onDraftButton={guidelinesModalDraftCallback}
      />
      {selectedContestTag && (
        <EpisodeContestModal
          open={contestModalIsOpen}
          onClose={closeContestModal}
          onCloseWithAcknowledge={publishAfterContestConditionsAcknowledged}
          contestTagName={selectedContestTag}
        />
      )}
    </>
  );
};

import { css, SerializedStyles } from "@emotion/react/macro";
import { Color, FontSize } from "../enums";

export enum ButtonVariant {
  ROUNDED,
  PILL,
  OUTLINED
}

type AllowedColor =
  | Color.STRONG_BG
  | Color.ACCENT_700
  | Color.PRIMARY
  | Color.GREY
  | Color.TINT;

export interface ButtonProps {
  color?: AllowedColor;
  variant?: ButtonVariant;
  block?: boolean;
  big?: boolean;
  small?: boolean;
  bold?: boolean;
}

const DARK_COLORS: AllowedColor[] = [Color.GREY, Color.ACCENT_700, Color.TINT];

const createRoundedStyles = (
  color: AllowedColor = Color.STRONG_BG
): SerializedStyles => css`
  padding: 8px 12px;
  color: ${DARK_COLORS.includes(color) ? Color.PRIMARY : Color.ACCENT_1000};
  background-color: ${color};
  border-radius: 4px;
`;

const createPillStyles = (
  color: AllowedColor = Color.STRONG_BG
): SerializedStyles => css`
  padding: 8px 16px;
  color: ${DARK_COLORS.includes(color) ? Color.PRIMARY : Color.ACCENT_1000};
  background-color: ${color};
  border-radius: 2em;
`;

const outlinedStyles = css`
  padding: 8px 16px;
  color: ${Color.ACCENT_1000};
  background-color: ${Color.PRIMARY};
  border: 1px solid ${Color.ACCENT_100};
  border-radius: 2em;
`;

const extractAdditionalStyles = (
  color?: AllowedColor,
  variant?: ButtonVariant
): SerializedStyles => {
  if (variant === undefined) {
    return css`
      color: ${color || "currentColor"};
    `;
  }

  switch (variant) {
    case ButtonVariant.ROUNDED:
      return createRoundedStyles(color);
    case ButtonVariant.PILL:
      return createPillStyles(color);
    case ButtonVariant.OUTLINED:
      return outlinedStyles;
    default:
      throw new Error(`Expected "ButtonVariant", got "${variant}"`);
  }
};

export const button = ({
  color,
  variant,
  block,
  big,
  small,
  bold
}: ButtonProps): SerializedStyles => css`
  position: relative;
  display: ${block ? "flex" : "inline-flex"};
  justify-content: center;
  align-items: center;
  line-height: 1.15;
  ${block && "width: 100%"};
  ${bold && `font-weight: 600;`}
  ${big &&
    `
    font-size: ${FontSize.SIZE_16};
    line-height: 2;
  `}
  ${small &&
    `
    font-size: ${FontSize.SIZE_12};
    height: 26px;
  `}
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;


  @media (hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  :disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  ${extractAdditionalStyles(color, variant)}
`;

// 1rem will be equal to the default font-size provided by the browser (usually 16px)
// https://medium.com/@vamptvo/pixels-vs-ems-users-do-change-font-size-5cfb20831773
export enum FontSize {
  SIZE_9 = "0.6rem",
  SIZE_10 = "0.625rem", // = 10px / 16px (1rem)
  SIZE_11 = "0.6875rem",
  SIZE_12 = "0.75rem",
  SIZE_13 = "0.8125rem",
  SIZE_14 = "0.875rem",
  SIZE_15 = "0.95rem",
  SIZE_16 = "1rem",
  SIZE_17 = "1.1rem",
  SIZE_18 = "1.125rem",
  SIZE_20 = "1.25rem",
  SIZE_21 = "1.30rem",
  SIZE_24 = "1.5rem",
  SIZE_28 = "1.775rem",
  SIZE_32 = "2rem",
  SIZE_36 = "2.25rem",
  SIZE_38 = "2.4rem",
  SIZE_44 = "2.7rem",
  SIZE_48 = "3rem"
}

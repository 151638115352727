export const COMMUNITY_GUIDELINE_LINK =
  "https://teller.jp/community_guideline?nolayout=1";

export const COMMUNITY_GUIDELINE_COPYRIGHT_PROTECTION_LINK =
  "https://teller.jp/community-guideline/copyright-protection";

export const TERMS_OF_SERVICE_LINK = "https://teller.jp/tos?nolayout=1";

export const GONZO_CONTEST_LINK = "https://teller.jp/contest/202209-gonzo";

export const Platform = {
  UNKNOWN: "unknown",
  IOS: "ios",
  ANDROID: "android"
} as const;
export type Platform = typeof Platform[keyof typeof Platform];

export const MAX_CHARS_PER_STORY = 250000;
export const SHOW_REMAINING_CHARS_THRESHOLD = 200000;

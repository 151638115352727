import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModal } from "./useModal";
import { NovelUseCase } from "../usecases/novelUseCase";

const novelUseCase = new NovelUseCase();

interface MustAcknowledgeGuidelinesHandle {
  novelId?: string;
  isImageReplacedModalOpen: boolean;
  openImageReplacedModal: (novelId: string) => void;
  closeImageReplacedModal: () => void;
  isGuidelinesAcknowledgeModalOpen: boolean;
  openGuidelinesAcknowledgeModal(): void;
  closeGuidelinesAcknowledgedModal(acknowledged?: boolean): void;
}

export const useMustAcknowledgeGuidelines = (): MustAcknowledgeGuidelinesHandle => {
  const imageReplacedModalHandle = useModal();
  const guidelinesAcknowledgeModalHandle = useModal();
  const [novelId, setNovelId] = useState<string | undefined>(undefined);
  const history = useHistory();

  const closeImageReplacedModal = useCallback(() => {
    imageReplacedModalHandle.close();
    guidelinesAcknowledgeModalHandle.open();
  }, [guidelinesAcknowledgeModalHandle, imageReplacedModalHandle]);

  const closeGuidelinesAcknowledgedModal = useCallback(
    async (acknowledged?: boolean) => {
      if (acknowledged && novelId) {
        try {
          await novelUseCase.acceptGuidelines(novelId);
          history.push(`/novel/${novelId}`);
        } catch (err) {
          if (process.env.NODE_ENV !== "production") {
            // eslint-disable-next-line no-console
            console.log(err);
          }
        }
      }
      setNovelId(undefined);
      guidelinesAcknowledgeModalHandle.close();
    },
    [guidelinesAcknowledgeModalHandle, history, novelId]
  );

  const openImageReplacedModal = useCallback(
    (openForNovelId: string) => {
      setNovelId(openForNovelId);
      imageReplacedModalHandle.open();
    },
    [imageReplacedModalHandle]
  );

  return {
    novelId,
    isImageReplacedModalOpen: imageReplacedModalHandle.isOpen,
    openImageReplacedModal,
    closeImageReplacedModal,
    isGuidelinesAcknowledgeModalOpen: guidelinesAcknowledgeModalHandle.isOpen,
    openGuidelinesAcknowledgeModal: guidelinesAcknowledgeModalHandle.open,
    closeGuidelinesAcknowledgedModal
  };
};

import styled from "@emotion/styled/macro";
import { useCallback, useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import useSWR from "swr";
import closeIcon from "../../assets/close_icon.svg";
import { query_search_v2 as RpcQuerySearchV2 } from "../../infra/api/rpc/api";
import { useAuth } from "../../hooks/useAuth";
import { TagUseCase } from "../../usecases/tagUseCase";
import { ActionSheet } from "../common/actionSheet";
import { Alert } from "../common/alert";
import { Confirm } from "../common/confirm";
import { Color, FontSize } from "../styles/enums";
import { Button, ButtonVariant } from "../ui/button";
import { Image } from "../ui/image";
import { Text } from "../ui/text";

interface Props {
  open: boolean;
  selectedTags?: string[];
  error?: FieldError;
  onChange(tags: string[]): void;
  onClose(undoChanges: boolean): void;
}

interface FormValues {
  additionalTag: string;
}

const TAG_MAX_LENGTH = 40;

const tagUseCase = new TagUseCase();
const numberFormatter = new Intl.NumberFormat(undefined, {
  maximumSignificantDigits: 3
});

const Header = styled.div`
  position: fixed;
  width: 100%;

  border-bottom: 1px solid ${Color.ACCENT_50};
  padding: 12px 16px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Body = styled.div`
  padding: 12px 16px;
  text-align: left;
  position: relative;
  top: 52px;
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 200px;
`;

const Label = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  margin: 16px 0 8px;
`;

const SelectedTags = styled.div`
  margin: 0 -16px 8px;
  background-color: ${Color.WHITE};
  padding: 12px 16px 0;
  width: calc(100% + 32px);
  overflow: auto;
  white-space: nowrap;
`;

const AdditionalTagForm = styled.form`
  display: inline-block;
  margin: 0 12px 12px 0;

  input {
    z-index: 99;
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 8px 0;
  }
`;

const SearchTags = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: ${Color.WHITE};
  min-height: 120%;

  button {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px;
    width: 100%;
    text-align: left;
    white-space: nowrap;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const SearchTag = styled.button`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SearchTagValue = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SearchTagCount = styled.span`
  margin-left: 20px;
  color: #989ba1;
  font-size: 13px;
`;

const Tags = styled.div<{ showingSuggestions: boolean }>`
  margin-bottom: 8px;
  ${({ showingSuggestions }) => `opacity: ${showingSuggestions ? 0 : 1};`}
`;

const Tag = styled.button`
  display: inline-block;
  margin: 0 12px 12px 0;
  border-radius: 32px;
  background-color: ${Color.ACCENT_50};
  cursor: pointer;
  padding: 8px 12px;
  color: ${Color.ACCENT_700};
  font-size: ${FontSize.SIZE_14};

  :hover {
    opacity: 0.7;
  }

  :disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

const ConfirmButtonWrapper = styled.div`
  width: 100%;
  background-color: ${Color.PRIMARY};
  position: fixed;
  bottom: 50px;
  left: 0;
  padding: 16px 32px;
  height: 80px;
`;

export const NovelTagSelectionActionSheet: React.VFC<Props> = ({
  open,
  selectedTags = [],
  error,
  onChange,
  onClose
}) => {
  const {
    register,
    watch,
    reset,
    handleSubmit: createHandleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      additionalTag: ""
    }
  });
  const additionalTag = watch("additionalTag");

  const { user: authUser, tellerUser } = useAuth();

  const [
    searchStoryFacetTag,
    setSearchStoryFacetTag
  ] = useState<RpcQuerySearchV2.ISearchFacetTagResponseV2 | null>(null);
  const [isShowDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [tagToDelete, setTagToDelete] = useState<string | null>(null);

  const { data: previousAndRisingTags } = useSWR(
    authUser && tellerUser
      ? ["/api/previousAndRisingTags", tellerUser.id?.value]
      : null,
    (_, uid) => tagUseCase.fetchPreviousAndRisingTags(uid)
  );

  const previousTags =
    previousAndRisingTags?.responseList[0]?.me?.usedTagListResponse?.tagList;
  const risingTags =
    previousAndRisingTags?.responseList[1]?.searchQuery?.popularSearchQueryList
      ?.queryList;

  const { data: remoteConfigSelectedTags } = useSWR(
    "/api/remoteConfigSelectedTags",
    tagUseCase.fetchSelectedTags
  );

  const showDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const closeDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);

  const [isShowAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState<string | null>(null);

  const showAlert = useCallback((msg: string) => {
    setAlertMsg(msg);
    setShowAlert(true);
  }, []);

  const closeAlert = useCallback(() => {
    setShowAlert(false);
    setAlertMsg(null);
  }, []);

  const addTag = useCallback(
    (tag: string) => {
      const trimmed = tag.trim();

      if (trimmed === "") {
        return;
      }

      if (selectedTags.includes(trimmed)) {
        showAlert("既に同じタグが存在します");
        return;
      }

      onChange([...selectedTags, trimmed]);
    },
    [onChange, selectedTags, showAlert]
  );

  const removeTag = useCallback(
    (tag: string) => {
      setTagToDelete(tag);
      showDeleteConfirm();
    },
    [showDeleteConfirm]
  );

  const deleteTagConfirm = useCallback(() => {
    closeDeleteConfirm();
    onChange(selectedTags.filter(v => v !== tagToDelete));
    setTagToDelete(null);
  }, [closeDeleteConfirm, onChange, selectedTags, tagToDelete]);

  const handleAdditionalTagFormSubmit = createHandleSubmit(values => {
    addTag(values.additionalTag);
    reset();
  });

  // Implementation of the incremental search for additional tag
  useEffect(() => {
    if (additionalTag) {
      (async () => {
        const res = await tagUseCase.searchStoryFacetTag(additionalTag);

        setSearchStoryFacetTag(res);
      })();
    }
  }, [additionalTag]);

  const showingSuggestions =
    additionalTag &&
    searchStoryFacetTag?.facetTags &&
    searchStoryFacetTag?.facetTags?.length > 0;

  return (
    <>
      {isShowDeleteConfirm ? (
        <Confirm
          title={`「#${tagToDelete}」を削除しますか？`}
          msg=""
          onClose={closeDeleteConfirm}
          onConfirm={deleteTagConfirm}
        />
      ) : null}
      {isShowAlert && alertMsg ? (
        <Alert msg={alertMsg} close={closeAlert} />
      ) : null}
      <ActionSheet open={open} onClose={() => onClose(true)}>
        <Header>
          <Text fz={FontSize.SIZE_16} fw="bold">
            タグ
          </Text>
          <CloseButton type="button" onClick={() => onClose(true)}>
            <Image src={closeIcon} width={18} height={18} alt="閉じる" />
          </CloseButton>
        </Header>
        <Body>
          <Label>
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              fw="bold"
              color={Color.ACCENT_500}
            >
              作品タグ (複数追加可能)
            </Text>
            <Text
              as="span"
              fz={FontSize.SIZE_12}
              color={
                additionalTag.length <= TAG_MAX_LENGTH
                  ? Color.ACCENT_500
                  : Color.RED
              }
            >
              {TAG_MAX_LENGTH - additionalTag.length}
            </Text>
          </Label>
          <SelectedTags>
            {selectedTags.map(tag => (
              <Tag
                key={`selected_tags_${tag}`}
                type="button"
                onClick={() => removeTag(tag)}
              >
                #{tag}
              </Tag>
            ))}
            <AdditionalTagForm
              autoComplete="off"
              onSubmit={handleAdditionalTagFormSubmit}
            >
              <input
                type="text"
                placeholder="タグを追加"
                {...register("additionalTag", {
                  maxLength: {
                    value: TAG_MAX_LENGTH,
                    message: "文字数制限を超えています"
                  }
                })}
              />
            </AdditionalTagForm>
          </SelectedTags>

          {showingSuggestions ? (
            <SearchTags>
              {searchStoryFacetTag?.facetTags?.map(({ tag, count }) => (
                <SearchTag
                  key={tag?.value}
                  type="button"
                  onClick={() => {
                    if (tag?.value) {
                      addTag(tag.value);
                      setSearchStoryFacetTag(null);
                      reset();
                    }
                  }}
                >
                  <SearchTagValue>#{tag?.value}</SearchTagValue>
                  {count?.value ? (
                    <SearchTagCount>
                      {numberFormatter.format(Number(count.value))}作品
                    </SearchTagCount>
                  ) : null}
                </SearchTag>
              ))}
            </SearchTags>
          ) : null}

          {errors.additionalTag ? (
            <Text fz={FontSize.SIZE_12} color={Color.RED}>
              {errors.additionalTag.message}
            </Text>
          ) : null}
          {error ? (
            <Text fz={FontSize.SIZE_12} color={Color.RED}>
              {error.message}
            </Text>
          ) : null}

          {previousTags && previousTags.length > 0 ? (
            <>
              <Text
                fz={FontSize.SIZE_12}
                fw="bold"
                color={Color.ACCENT_500}
                mt={16}
                mb={8}
              >
                以前使ったタグ
              </Text>
              <Tags showingSuggestions={showingSuggestions || false}>
                {previousTags.map(tagObject => {
                  const tag = tagObject?.value;
                  if (!tag) {
                    return null;
                  }
                  return (
                    <Tag
                      key={`previous_tags_${tag}`}
                      type="button"
                      disabled={selectedTags.includes(tag)}
                      onClick={() => addTag(tag)}
                    >
                      #{tag}
                    </Tag>
                  );
                })}
              </Tags>
            </>
          ) : null}
          {remoteConfigSelectedTags && remoteConfigSelectedTags.length > 0 ? (
            <>
              <Text
                fz={FontSize.SIZE_12}
                fw="bold"
                color={Color.ACCENT_500}
                mt={16}
                mb={8}
              >
                みんなが調べているタグ
              </Text>
              <Tags showingSuggestions={showingSuggestions || false}>
                {remoteConfigSelectedTags.map(tag => (
                  <Tag
                    key={`popular_tags_${tag}`}
                    type="button"
                    disabled={selectedTags.includes(tag)}
                    onClick={() => addTag(tag)}
                  >
                    #{tag}
                  </Tag>
                ))}
              </Tags>
            </>
          ) : null}

          {risingTags && risingTags.length > 0 ? (
            <>
              <Text
                fz={FontSize.SIZE_12}
                fw="bold"
                color={Color.ACCENT_500}
                mt={16}
                mb={8}
              >
                急上昇タグ
              </Text>
              <Tags showingSuggestions={showingSuggestions || false}>
                {risingTags.map(tagObject => {
                  const tag = tagObject.value;
                  if (!tag) {
                    return null;
                  }
                  return (
                    <Tag
                      key={`suggestions_${tag}`}
                      type="button"
                      disabled={selectedTags.includes(tag)}
                      onClick={() => addTag(tag)}
                    >
                      #{tag}
                    </Tag>
                  );
                })}
              </Tags>
            </>
          ) : null}

          <ConfirmButtonWrapper>
            <Button
              variant={ButtonVariant.PILL}
              color={Color.TINT}
              block
              big
              disabled={!!error}
              onClick={() => onClose(false)}
            >
              完了
            </Button>
          </ConfirmButtonWrapper>
        </Body>
      </ActionSheet>
    </>
  );
};

import { useState, useCallback, useMemo } from "react";
import { useAuth } from "./useAuth";
import { query_types as RpcQueryTypes } from "../infra/api/rpc/api";

interface GuidelinesModalHandle {
  shouldShow: boolean;
  isOpen: boolean;
  open(): void;
  close(): void;
}

export const useGuidelinesModal = (): GuidelinesModalHandle => {
  const { tellerUser } = useAuth();
  const needGuidelinesAcknowledge = useMemo(() => {
    if (
      !tellerUser ||
      tellerUser.userRole === RpcQueryTypes.UserRole.USER_ROLE_OFFICIAL_WRITER
    ) {
      return false;
    }
    return (tellerUser.searchableStoryCount?.value || 0) < 2;
  }, [tellerUser]);

  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => {
    setOpen(true);
  }, []);
  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    shouldShow: needGuidelinesAcknowledge,
    isOpen,
    open,
    close
  };
};

import styled from "@emotion/styled/macro";
import { margins, MarginsProps } from "../../styles/mixins/margins";

interface Props
  extends React.ImgHTMLAttributes<HTMLImageElement>,
    MarginsProps {
  src: string;
  rounded?: boolean;
  circle?: boolean;
}

export const Image = styled.img<Props>`
  max-width: 100%;
  vertical-align: middle;
  ${({ rounded }) => rounded && "border-radius: 3px"};
  ${({ circle }) => circle && "border-radius: 50%"};
  ${margins};
`;

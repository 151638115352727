export enum OS {
  UNKNOWN,
  ANDROID,
  IOS
}

export interface ParsedUA {
  os: OS;
  isMobile: boolean;
  isBot: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle
const MOBILE = !!(window as any)?.__CONFIG__?.mobile;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle
const BOT = !!(window as any)?.__CONFIG__?.bot;
const RE_ANDROID = /android/i;
const RE_IOS = /(ipod|iphone|ipad)/i;

const parseOS = (ua: string): OS => {
  if (RE_ANDROID.test(ua)) {
    return OS.ANDROID;
  }

  if (RE_IOS.test(ua)) {
    return OS.IOS;
  }

  return OS.UNKNOWN;
};

export const parseUA = (ua: string): ParsedUA => ({
  os: parseOS(ua),
  isMobile: MOBILE,
  isBot: BOT
});

import { Unsubscribe, User, UserCredential } from "firebase/auth";
import {
  query_recursive_types as RpcQueryRecursiveTypes,
  me as RpcMe
} from "../infra/api/rpc/api";
import { FirebaseRepository } from "../repositories/firebaseRepository";
import {
  QueryRepository,
  QuickQueryParams
} from "../repositories/queryRepository";
import { MeRepository } from "../repositories/meRepository";

export class AuthUseCase {
  private firebaseRepository: FirebaseRepository;

  private queryRepository: QueryRepository;

  private meRepository: MeRepository;

  constructor() {
    this.firebaseRepository = new FirebaseRepository();
    this.queryRepository = new QueryRepository();
    this.meRepository = new MeRepository();
  }

  public loginByToken = (token: string): Promise<UserCredential> =>
    this.firebaseRepository.signInWithCustomToken(token);

  public detectAuthChange = (handler: (a: User | null) => void): Unsubscribe =>
    this.firebaseRepository.startObservingAuthState(handler);

  public logout = (): Promise<void> => this.firebaseRepository.logout();

  public fetchMe = (): Promise<RpcQueryRecursiveTypes.IUserResponse> =>
    new Promise<RpcQueryRecursiveTypes.IUserResponse>((resolve, reject) => {
      const meParams: QuickQueryParams = {
        requestId: "fetchMe",
        meRequest: {}
      };
      this.queryRepository.quickQuery([meParams]).then(response => {
        const me = response?.responseList[0]?.me?.user;
        if (!me) {
          reject();
          return;
        }
        resolve(me);
      });
    });

  public issueAuthToken = (): Promise<RpcMe.AuthCustomToken> =>
    this.meRepository.issueAuthCustomToken();
}

import { event as RpcEvent } from "../infra/api/rpc/api";
import { QueryRepository } from "../repositories/queryRepository";

export const fetchContestTags = (): Promise<string[]> =>
  new Promise<string[]>(resolve => {
    const queryRepository = new QueryRepository();
    queryRepository
      .quickQuery([
        {
          requestId: "contests",
          eventListRequest: {
            filterLabel: RpcEvent.EventLabel.EVENT_LABEL_CONTEST
          }
        }
      ])
      .then(response => {
        try {
          const tags =
            response.responseList[0]?.eventList?.eventList?.map(
              e => e.tag?.value || ""
            ) || [];
          resolve(tags.filter(t => t !== ""));
        } catch {
          resolve([]);
        }
      });
  });

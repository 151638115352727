import { ComponentProps } from "react";
import { Image } from "./image";

interface Props extends ComponentProps<typeof Image> {
  lazy?: boolean;
}

export const ConnectedImage: React.FC<Props> = ({
  alt = "",
  lazy,
  ...rest
}) => <Image alt={alt} loading={lazy ? "lazy" : undefined} {...rest} />;

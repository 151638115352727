import useSWR, { useSWRInfinite } from "swr";
import { useCallback, useEffect, RefObject, useMemo } from "react";
import { useIntersection } from "react-use";
import { NovelUseCase } from "../../usecases/novelUseCase";
import { useAuth } from "../../hooks/useAuth";
import { query_recursive_types as RpcRecursiveTypes } from "../../infra/api/rpc/api";
import { logError } from "../../utils/utils";

const novelUseCase = new NovelUseCase();

interface LoadNovelAndEpisodesHandle {
  isValidating: boolean;
  isEmpty: boolean;
  hideReorderStories: boolean;
  novel: RpcRecursiveTypes.ISeriesResponse | null | undefined;
  novelEpisodesPage:
    | (RpcRecursiveTypes.ISeriesStoryPageResponse | null)[]
    | null
    | undefined;
  mutateNovel: () => void;
  mutateEpisodes: () => void;
}

export const useLoadNovelAndEpisodes = (
  novelId: string,
  paginationRef: RefObject<HTMLDivElement>
): LoadNovelAndEpisodesHandle => {
  const { tellerUser } = useAuth();

  const { data: novel, mutate: mutateNovel } = useSWR(
    tellerUser?.id?.value && novelId
      ? ["/api/novel", tellerUser.id.value, novelId]
      : null,
    (_, uId, nId) => novelUseCase.fetchNovel(uId, nId)
  );

  const getKey = useCallback(
    (pageIndex, prevData) => {
      if (!tellerUser?.id?.value || !novelId) {
        return null;
      }

      if (!prevData) {
        return ["/api/user/novel", novelId, 0];
      }

      if (prevData?.page?.hasNextPage?.value) {
        return ["/api/user/novel", novelId, pageIndex];
      }
      return null;
    }, // SWR key
    [novelId, tellerUser?.id?.value]
  );

  const fetcher = useCallback(
    (
      _key,
      nId,
      page
    ): Promise<RpcRecursiveTypes.ISeriesStoryPageResponse | null> =>
      novelUseCase.fetchNovelEpisodes(nId, page),
    []
  );

  // Novel and episode data loading (with pagination on scroll)
  const {
    data: novelEpisodesPage,
    error,
    size,
    setSize,
    isValidating,
    mutate
  } = useSWRInfinite(getKey, fetcher, {
    onError: err => {
      logError(err);
    }
  });

  const intersection = useIntersection(paginationRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1
  });

  const isEmpty = useMemo(
    () =>
      novel === null &&
      (novelEpisodesPage?.[0] === null ||
        novelEpisodesPage?.[0]?.storyList?.length === 0),
    [novel, novelEpisodesPage]
  );

  const hideReorderStories = useMemo(() => {
    const episodesLength = novelEpisodesPage?.[0]?.storyList?.length || 0;
    return isEmpty || episodesLength < 2;
  }, [isEmpty, novelEpisodesPage]);

  const isLastPage = useMemo(() => {
    const hasNextPage = novelEpisodesPage?.[size - 1]?.page?.hasNextPage?.value;
    return !hasNextPage;
  }, [novelEpisodesPage, size]);

  useEffect(() => {
    if (
      !isEmpty &&
      novelId !== "undefined" &&
      intersection?.intersectionRatio === 1 &&
      !isValidating &&
      !isLastPage
    ) {
      setSize(size + 1);
    }
  }, [
    error,
    intersection?.intersectionRatio,
    isEmpty,
    isLastPage,
    isValidating,
    novelId,
    setSize,
    size
  ]);

  return {
    novel,
    isValidating,
    isEmpty,
    hideReorderStories,
    novelEpisodesPage,
    mutateNovel,
    mutateEpisodes: mutate
  };
};

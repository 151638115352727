import { useCallback } from "react";
import { useAuthToken } from "./useAuthToken";
import { useAuth } from "./useAuth";
import { useAppParameters } from "./useAppParameters";

interface UseChatPreviewHandle {
  previewInWeb(chatStoryId: string, screenLarge: boolean): void;
}

export const useChatPreview = (): UseChatPreviewHandle => {
  const { ensureAuthToken } = useAuthToken();
  const { user } = useAuth();
  const { isApp } = useAppParameters();

  const previewInWeb = useCallback(
    async (chatStoryId: string, screenLarge: boolean) => {
      const token = await ensureAuthToken();

      if (!token || !user?.uid) {
        return;
      }

      const url = `//${process.env.REACT_APP_WEB_DOMAIN}/preview/${chatStoryId}?webToken=${token}&webUid=${user.uid}`;
      if (isApp || !screenLarge) {
        window.location.href = url;
      } else {
        const win = window.open(
          "",
          "_blank",
          "width=375, height=667, resizable=0"
        ); // window 作成 (SP の横幅と高さにする)
        if (win) {
          win.document.body.innerHTML = "loading...";
          win.location.href = url;
        }
      }
    },
    [ensureAuthToken, isApp, user?.uid]
  );

  return { previewInWeb };
};

import styled from "@emotion/styled/macro";
import { Color, ZIndex, Media, FontSize } from "../styles/enums";

const TRANSITION_DURATION = "200ms";

export const OptionsList = styled.ul`
  display: grid;
  margin-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: ${FontSize.SIZE_14};
  font-weight: 700;
`;

export const Option = styled.li`
  height: 45px;
  border-bottom: 1px solid ${Color.SEPARATOR};

  &:last-of-type {
    border-bottom: none;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 45px;
  }

  img {
    margin-right: 10px;
  }

  > a,
  > button {
    display: flex;
    justify-content: left;
    border-radius: 6px;
    padding: 5px 15px;
    cursor: pointer;
    line-height: 36px;
    align-items: center;
    color: ${Color.ACCENT_1000};
    width: 100%;

    &:hover {
      background: ${Color.STRONG_BG};
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity ${TRANSITION_DURATION};
`;

const ContentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
`;

const Content = styled.div<{ bckWhite?: boolean }>`
  background-color: ${({ bckWhite }) =>
    bckWhite ? Color.WHITE : Color.PRIMARY};
  transform: translateY(50px);
  transition: transform ${TRANSITION_DURATION};
  width: 100%;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media ${Media.LARGE} {
    max-width: 990px;
    margin: auto;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.SIDEBAR};
  transition: visibility ${TRANSITION_DURATION};
  text-align: center;

  &[aria-hidden="true"] {
    visibility: hidden;

    ${Backdrop} {
      opacity: 0;
    }

    ${Content} {
      transform: translateY(100%);
    }
  }
`;

interface Props {
  open: boolean;
  onClose(): void;
  bckWhite?: boolean;
}

export const ActionSheet: React.FC<Props> = ({
  open,
  onClose,
  children,
  bckWhite
}) => (
  <Wrapper aria-hidden={!open}>
    <Backdrop aria-hidden onClick={onClose} />
    <ContentWrapper>
      <Content bckWhite={bckWhite}>{children}</Content>
    </ContentWrapper>
  </Wrapper>
);

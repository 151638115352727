import styled from "@emotion/styled";
import { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import useSWR from "swr";

// Usecase
import { NovelUseCase } from "../usecases/novelUseCase";

// Hooks
import { useAuth } from "../hooks/useAuth";
import { useLayoutUI } from "../hooks/useLayoutUI";

// Components
import { AppHeader } from "../components/common/header/appHeader";

import { ActivityModal } from "../components/common/activityModal";
import { Alert } from "../components/common/alert";
import { Color, FontSize } from "../components/styles/enums";
import { fromMarkdown } from "../models/episode";
import hrDivider from "../assets/editor/horizontal_divider.svg";

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  max-width: 960px;
  margin: auto;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 100px;
`;

const EpisodeContainer = styled.div`
  height: 100%;
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic Pro",
    "ヒラギノ角ゴ Pro W3", Meiryo, メイリオ, Osaka, "MS PGothic", arial,
    helvetica, sans-serif;
  font-size: ${FontSize.SIZE_16};
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding: 15px;
  line-height: 1.5;

  em {
    font-family: "serif";
  }

  h1 {
    font-size: 1.3em;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 12px;
  }

  img {
    max-width: 90%;
    max-height: 500px;
    display: block;
    margin: auto;
    padding: 24px;
  }

  hr {
    height: 0;
    padding: 0 20px;
    margin: 10px 0 40px 0;

    border: 0;
    text-align: center;

    &:before {
      content: url(${hrDivider});
      position: relative;
      top: -20px;
    }
  }
`;

type Props = {
  novelId: string;
  episodeId: string;
};

const novelUseCase = new NovelUseCase();

export const EpisodePreviewContainer: React.FC = () => {
  const { episodeId, novelId } = useParams<Props>();
  const { tellerUser } = useAuth();
  const { setLayout } = useLayoutUI();
  const history = useHistory();
  const [alertErrorMsg, setAlertErrorMsg] = useState<string | null>(null);
  const [isShowAlert, setShowAlert] = useState(false);
  const [isGoBackOnCloseAlert, setGoBackOnCloseAlert] = useState(false);
  const episodeRef = useRef<HTMLDivElement>(null);

  const { data: episode } = useSWR(
    tellerUser?.id?.value
      ? ["/api/preview/episode", tellerUser.id.value, episodeId]
      : null,
    (_, _uId, eId) => novelUseCase.fetchEpisode(eId, true),
    {
      revalidateOnFocus: false
    }
  );

  useEffect(() => {
    if (episode?.novelScript?.fullScript?.value && episodeRef.current) {
      episodeRef.current.innerHTML = fromMarkdown(
        episode?.novelScript?.fullScript?.value
      );
    }
  }, [episode]);

  const goBack = useCallback(() => {
    history.push(`/novel/${novelId}`);
  }, [history, novelId]);

  const showAlert = useCallback((msg: string, goBackOnClose?: boolean) => {
    setAlertErrorMsg(msg);
    setShowAlert(true);
    if (goBackOnClose) {
      setGoBackOnCloseAlert(true);
    }
  }, []);

  const closeAlert = useCallback(() => {
    setAlertErrorMsg(null);
    setShowAlert(false);
    if (isGoBackOnCloseAlert) {
      goBack();
    }
  }, [goBack, isGoBackOnCloseAlert]);

  useEffect(() => {
    setLayout({
      title: episode?.title?.value || ""
    });
  }, [episode, novelId, setLayout]);

  useEffect(() => {
    if (episodeId && episode === null) {
      showAlert("ストーリーが存在しません", true);
    }
  }, [episode, episodeId, showAlert]);

  return (
    <div>
      {episode ? (
        <AppHeader
          title={`${episode?.title?.value} ー プレビュー`}
          noBackButton={false}
          backLink={`/novel/${novelId}`}
          moreButton={false}
        />
      ) : null}
      <PageWrapper>
        {episodeId && episode === undefined ? <ActivityModal /> : null}

        {isShowAlert && alertErrorMsg ? (
          <Alert msg={alertErrorMsg} close={closeAlert} />
        ) : null}
        <EpisodeContainer ref={episodeRef} />
      </PageWrapper>
    </div>
  );
};

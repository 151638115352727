export const formattedDateTime = (): string => {
  const today = new Date();
  const dd: string = String(today.getDate()).padStart(2, "0");
  const mm: string = String(today.getMonth() + 1).padStart(2, "0");
  const hh: string = String(today.getHours()).padStart(2, "0");
  const mins: string = String(today.getMinutes()).padStart(2, "0");

  const yyyy: number = today.getFullYear();

  return `${yyyy}/${mm}/${dd} ${hh}:${mins}`;
};

export const validateURL = (url?: string | null): boolean => {
  if (!url) {
    return false;
  }
  try {
    const validUrl = new URL(url);
    return validUrl.protocol === "http:" || validUrl.protocol === "https:";
  } catch (_) {
    return false;
  }
};

export const logError = (err: unknown): void => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

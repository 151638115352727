import { useState, useCallback } from "react";

interface UseAlertMsgHandler {
  showAlertWithMsg(msg: string): void;
  isShowAlert: boolean;
  alertMsg: string | null;
  hideAlert(): void;
}

export const useAlertMsg = (): UseAlertMsgHandler => {
  const [isShowAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState<string | null>(null);

  const showAlertWithMsg = useCallback((msg: string) => {
    setAlertMsg(msg);
    setShowAlert(true);
  }, []);

  const hideAlert = useCallback(() => {
    setShowAlert(false);
    setAlertMsg(null);
  }, []);

  return {
    showAlertWithMsg,
    isShowAlert,
    alertMsg,
    hideAlert
  };
};

import { RPCImpl } from "protobufjs";
import { payment as RpcPayment } from "../infra/api/rpc/api";
import { TellerApi } from "../infra/api/tellerApi";

const servicePath = "/payment.PaymentService/";

export class PaymentRepository {
  private api: TellerApi;

  constructor() {
    this.api = new TellerApi();
  }

  public getLatestSubscription = (
    platform: RpcPayment.PaymentPlatform
  ): Promise<RpcPayment.PaymentSubscription> => {
    const service = this.getService("GetLatestSubscription");
    return service.getLatestSubscription({
      platform
    });
  };

  private getService = (method: string): RpcPayment.PaymentService => {
    const rpcImpl: RPCImpl = this.api.post(`${servicePath}${method}`);
    return RpcPayment.PaymentService.create(rpcImpl, false, false);
  };
}

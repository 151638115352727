import TurndownService from "turndown";
import MarkdownIt from "markdown-it";
import {
  story as RpcStory,
  query_recursive_types as RpcRecursiveTypes,
  query_types as RpcQueryTypes
} from "../infra/api/rpc/api";
import { validateURL } from "../utils/utils";
import { PublishRange, publishRangeFromRpcSharedWithStatus } from "./novel";
import type { Genre } from "./genre";
import { rpcGenreToGenre } from "./genre";

const RE_MARKDOWN_IMAGE = /!\[(.*?)\]\((.*?)\)/gim;

export type Episode = {
  userId: string;
  tags?: string[];
  id?: string;
  novelId?: string;
  novelTitle?: string;
  novelIndex?: number;
  novelPublishFor: PublishRange | null;
  novelGenre: Genre | null;
  title: string;
  description?: string;
  thumbnailId?: string;
  thumbnailUrl?: string;
  sensitiveFlag?: RpcQueryTypes.SensitiveFlag;
  status?: RpcStory.StoryStatus;
  novelScript?: string;
  chatScript?: RpcRecursiveTypes.IStoryChatNovelScriptResponse | null;
  isOfficial: boolean;
  scriptType: RpcQueryTypes.StoryScriptType;
  // **一度でも**公開したことがあるか
  isPublished: boolean;
};

export const episodeFromRpcStory = (
  rpcStory: RpcRecursiveTypes.IStoryResponse
): Episode => {
  const tags =
    rpcStory.tags && rpcStory.tags.length > 0
      ? rpcStory.tags
      : rpcStory.series?.tags;
  const ep: Episode = {
    userId: rpcStory.user?.id?.value || "",
    tags: tags?.map(c => c.value || "") || [],
    id: rpcStory.id?.value || "",
    novelId: rpcStory.series?.id?.value || "",
    novelTitle: rpcStory.series?.title?.value || "",
    novelIndex: rpcStory.seriesIndex?.value || undefined,
    novelPublishFor: rpcStory.series?.userAvailability
      ? publishRangeFromRpcSharedWithStatus(rpcStory.series?.sharedWithStatus)
      : null,
    novelGenre: rpcGenreToGenre(rpcStory.series?.genre || null),

    title: rpcStory.title?.value || "",
    description: "",
    thumbnailId:
      rpcStory.thumbnail?.id?.value ||
      rpcStory.series?.thumbnail?.id?.value ||
      "",
    thumbnailUrl: rpcStory.thumbnail?.servingUrl?.value || "",
    sensitiveFlag:
      rpcStory.sensitiveFlag ||
      RpcQueryTypes.SensitiveFlag.SENSITIVE_FLAG_UNSPECIFIED,
    status: rpcStory.status || RpcStory.StoryStatus.DRAFT,
    isOfficial: rpcStory.isOfficial?.value || false,
    scriptType:
      rpcStory.scriptType ||
      RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_CHAT_NOVEL,
    isPublished: (rpcStory.publishedAt?.seconds ?? 0) > 0
  };
  if (ep.scriptType === RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_NOVEL) {
    ep.novelScript = rpcStory.novelScript?.fullScript?.value || "";
  } else {
    ep.chatScript = rpcStory.chatNovelScript;
  }
  return ep;
};

export const copyRpcSeriesDataToEpisode = (
  series: RpcRecursiveTypes.ISeriesResponse,
  episode: Episode
): Episode => ({
  ...episode,
  thumbnailId: series.thumbnail?.id?.value || "",
  tags: series.tags?.map(c => c.value || "") || [],
  // NOTE: STU-140 Episode description not set
  description: "",
  novelId: series.id?.value || ""
});

export const toMarkdown = (htmlScript: string): string => {
  const sv = new TurndownService({
    headingStyle: "atx",
    emDelimiter: "*",
    hr: "---"
  });

  // Quill adds always paragraphs to any new line
  // and they get converted as multiple new lines in markdown
  // so before sending to server, just strip all these not needed lines
  // And also add a generic alt attribute to images for now
  const curated = htmlScript
    .replaceAll("<p><br></p>", "$br$") // mark additional empty lines as $br$
    .replaceAll("<p>", "") // remove <p> tags
    .replaceAll("</p>", "$endp$") // mark end of paragraph with $endp$ token
    .replaceAll("<img alt='画像'", "<img")
    .replaceAll("<img", "<img alt='画像'") // add a generic alt attribute for images
    .replaceAll('">', '">\n'); // also ensure there is nothing after image in the same line

  // Turndownservice converts from html to markdown, but it adds some extra blank lines
  // on HT element, just override these here.
  // And also underline for us will be stored as italic in server between `*`
  const md = sv
    .addRule("underline", {
      filter: "u",
      replacement: content => `<u>${content}</u>`
    })
    .turndown(curated);

  // after conversion, put back our special cases:
  const withBr = md
    .replaceAll("$br$", "<br>\n") // each empty line is stored as <br> and actual line break
    .replaceAll("$endp$", "\n\n"); // each paragraph end is stored with two line breaks
  return withBr;
};

export const fromMarkdown = (markdownScript: string): string => {
  const md = MarkdownIt();
  md.set({ html: true, xhtmlOut: true });

  // If we convert directly from server markdown to HTML, the result doesn't look like
  // the original in quill editor, so I add some adjustments so it looks exactly like it was
  // edited
  // For example, blank lines are ignored, but I convert them to <p><br/></p> which is what quill expects
  const sanitized: string[] = [];
  markdownScript.split("\n").forEach(line => {
    const trimmed = line.trim();
    if (trimmed === "") {
      return;
    }

    // Title and image cases, do not add <br/>
    if (trimmed === "<br>") {
      sanitized.push("<p><br></p>");
      return;
    }
    // End of lines are added but only for "normal" texts, no for titles or images
    if (line.startsWith("# ")) {
      sanitized.push(`\n${line}`);
      return;
    }
    // Image, ignore not valid urls
    if (line.startsWith("![")) {
      try {
        const elements = RE_MARKDOWN_IMAGE.exec(line);
        if (elements) {
          const imageUrl = elements[2];
          if (!validateURL(imageUrl)) {
            return;
          }
        }
      } catch (_) {
        // no op
      }
    }

    if (line.startsWith("![") || trimmed === "---") {
      sanitized.push(`\n${line}\n`);
    } else {
      sanitized.push(`\n${trimmed}\n`);
    }
  });
  const withBr = sanitized.join("\n");
  const html = md.render(withBr);

  return html;
};

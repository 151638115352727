// Core
import styled from "@emotion/styled/macro";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { Link } from "react-router-dom";
import { User } from "firebase/auth";
import { useEffect, useRef } from "react";
import useSWR from "swr";
import {
  query_recursive_types as RpcQueryRecursiveTypes,
  query_types as RpcQueryTypes
} from "../../infra/api/rpc/api";
// UseCases
import { fetchLatestSubscription } from "../../usecases/subscriptionUseCase";
// Components, assets
import { Color, FontSize, ZIndex } from "../styles/enums";
import { Button } from "../ui/button";
import { Image } from "../ui/image";
import { Text } from "../ui/text";
import { UserStats } from "../user/userStats";
import logoImage from "../../assets/header/logo.svg";
import officialUserBadgeImage from "../../assets/official_user_badge.svg";
import rightArrowIcon from "../../assets/right_arrow_icon.svg";
import closeImage from "../../assets/sidebar/close.svg";
import romanceAdultIcon from "../../assets/sidebar/romance_adult_icon.png";
import dramaIcon from "../../assets/sidebar/drama_icon.png";
import dramaIconWebp from "../../assets/sidebar/drama_icon.webp";
import fantasyIcon from "../../assets/sidebar/fantasy_icon.png";
import fantasyIconWebp from "../../assets/sidebar/fantasy_icon.webp";
import homeIcon from "../../assets/sidebar/home_icon.svg";
import horrorIcon from "../../assets/sidebar/horror_icon.png";
import horrorIconWebp from "../../assets/sidebar/horror_icon.webp";
import mysteryIcon from "../../assets/sidebar/mystery_icon.png";
import mysteryIconWebp from "../../assets/sidebar/mystery_icon.webp";
import myPageIcon from "../../assets/sidebar/my_page_icon.svg";
import myStoriesIcon from "../../assets/sidebar/my_stories_icon.svg";
import heartIcon from "../../assets/sidebar/romance_icon.png";
import heartIconWebp from "../../assets/sidebar/romance_icon.webp";
import vipBanner from "../../assets/sidebar/sidebar_vip_banner.png";
import vipBannerWebp from "../../assets/sidebar/sidebar_vip_banner.webp";
import userPlaceholder from "../../assets/user_placeholder.png";
import vipUserBadgeImage from "../../assets/vip_badge.png";
import writeIcon from "../../assets/write_icon.svg";

const TRANSITION_DURATION = "200ms";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity ${TRANSITION_DURATION};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 95vh;
  background-color: ${Color.WHITE};
  justify-content: space-around;
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
`;

const Content = styled.div`
  position: fixed;
  right: auto;
  left: 0;
  width: 70vw;
  min-width: 250px;
  max-width: 400px;
  padding-top: 5px;
  padding-bottom: 16vh;
  overflow: hidden;
  background-color: ${Color.WHITE};
  transform: translateX(0);
  transition: transform ${TRANSITION_DURATION};
`;

const Header = styled.div`
  display: flex;
  padding: 0 16px;
  height: 45px;
  align-items: baseline;
`;

const Logo = styled(Image)`
  flex: 1 0 auto;
  max-width: 135px;
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.SIDEBAR};
  transition: visibility ${TRANSITION_DURATION};

  &[aria-hidden="true"] {
    visibility: hidden;

    ${Backdrop} {
      opacity: 0;
    }

    ${Content} {
      transform: translateX(-100%);
    }
  }
`;

const Home = styled.a`
  display: flex;
  align-items: center;
  justify-content: unset;
  height: 48px;
`;

const UserStatsWrapper = styled.div`
  margin-top: 22px;
  margin-left: 13px;
  margin-bottom: 16px;
`;

const Separator = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid ${Color.SEPARATOR};
`;

const SectionIcon = styled(Image)`
  margin-left: 26px;
  margin-right: 18px;
  width: 25px;
`;

const Category = styled.a`
  display: flex;
  align-items: center;
  justify-content: unset;
  height: 48px;
`;

const StickyButton = styled.div`
  width: 35vw;
  min-width: 175px;
  margin: 10px auto 20px;
  height: 50px;
`;

const WriteStoryButton = styled(Link)`
  display: block;
  width: 100%;
  max-width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: ${FontSize.SIZE_14};
  border-radius: 50px;
  background-color: ${Color.TINT};
  color: ${Color.WHITE};
  border: none;

  &:hover {
    opacity: 0.7;
    color: ${Color.WHITE};
  }
`;

const UserLink = styled.a`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 24px;
`;

const ThumbnailWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  min-width: 60px;
`;

const Thumbnail = styled(Image)`
  cursor: pointer;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
`;

const VipBadge = styled(Image)`
  margin: -10px auto;
  width: 25px;
`;

const OfficialBadge = styled(Image)`
  margin: -10px auto;
  width: 24px;
`;

const Username = styled(Text)`
  font-size: ${FontSize.SIZE_18};
  font-weight: 600;
  text-align: left;
`;

const Logout = styled.div`
  cursor: pointer;
  line-height: 48px;
  padding-left: 24px;
  height: 64px;
`;

interface Props {
  authUser: User | undefined | null;
  tellerUser: RpcQueryRecursiveTypes.IUserResponse | null;
  isVipUser?: boolean;
  open: boolean;
  onClose: () => void;
  onLogout: () => void;
}

export const Sidebar: React.FC<Props> = ({
  open,
  onClose,
  authUser,
  tellerUser,
  isVipUser,
  onLogout
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const { data: latestSubscription } = useSWR(
    authUser && tellerUser && !isVipUser
      ? ["/api/latestSubscription", tellerUser.id?.value]
      : null,
    fetchLatestSubscription,
    {
      shouldRetryOnError: false
    }
  );

  const officialBadge =
    tellerUser?.userRole === RpcQueryTypes.UserRole.USER_ROLE_OFFICIAL_WRITER ||
    null;
  const vipBadge = (!officialBadge && isVipUser) || null;
  const vipPageLink = !latestSubscription
    ? "/vip_offer"
    : "/profile/plan_selection";

  useEffect(() => {
    if (open) {
      if (sidebarRef.current) {
        disableBodyScroll(sidebarRef.current);
      }
      if (buttonRef.current) {
        const { bottom } = buttonRef.current.getBoundingClientRect();
        if (Math.floor(bottom) > window.innerHeight) {
          buttonRef.current.style.height = "180px";
        } else {
          buttonRef.current.style.height = "50px";
        }
      }
    } else {
      clearAllBodyScrollLocks();
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open, sidebarRef]);

  return (
    <Wrapper aria-hidden={!open}>
      <Backdrop aria-hidden onClick={onClose} />
      <Content>
        <ContentWrapper>
          <Header>
            <Button aria-label="メニューを閉じる" onClick={onClose}>
              <Image lazy src={closeImage} height="24" width="24" />
            </Button>
            <Logo lazy src={logoImage} width={135} />
          </Header>

          <ScrollableContent ref={sidebarRef}>
            {tellerUser ? (
              <>
                <UserLink
                  href={`//${process.env.REACT_APP_WEB_DOMAIN}/user/${tellerUser.id?.value}`}
                  onClick={onClose}
                >
                  <ThumbnailWrapper>
                    <Thumbnail
                      lazy
                      src={
                        tellerUser.thumbnail?.servingUrl?.value ||
                        userPlaceholder
                      }
                    />
                    {officialBadge ? (
                      <OfficialBadge lazy src={officialUserBadgeImage} />
                    ) : null}
                    {vipBadge ? (
                      <VipBadge lazy src={vipUserBadgeImage} />
                    ) : null}
                  </ThumbnailWrapper>
                  <Username>{tellerUser.name?.value || "ゲスト"}</Username>
                  <Image
                    lazy
                    src={rightArrowIcon}
                    ml={10}
                    mb={3}
                    mr={10}
                    width="8"
                    height="14"
                  />
                </UserLink>
                <UserStatsWrapper>
                  <UserStats
                    stories={tellerUser?.searchableStoryCount?.value}
                    followers={
                      tellerUser?.followerPage?.cursor?.totalCount?.value
                    }
                    followees={
                      tellerUser?.followeePage?.cursor?.totalCount?.value
                    }
                  />
                </UserStatsWrapper>
              </>
            ) : null}

            {!isVipUser ? (
              <a href={`//${process.env.REACT_APP_WEB_DOMAIN}${vipPageLink}`}>
                <picture>
                  <source type="image/webp" srcSet={vipBannerWebp} />
                  <Image lazy src={vipBanner} width="400" />
                </picture>
              </a>
            ) : null}

            <section>
              <Separator />
              <Home href={`//${process.env.REACT_APP_WEB_DOMAIN}`}>
                <SectionIcon lazy src={homeIcon} width="25" height="25" />
                <Text as="div">トップ</Text>
              </Home>
              <Separator />
              <Text
                as="h2"
                color={Color.ACCENT_500}
                fz={FontSize.SIZE_12}
                mt={22}
                ml={16}
              >
                カテゴリー
              </Text>
              <Category
                href={`//${process.env.REACT_APP_WEB_DOMAIN}/tag/ホラー`}
              >
                <picture>
                  <source type="image/webp" srcSet={horrorIconWebp} />
                  <SectionIcon lazy src={horrorIcon} width="25" height="25" />
                </picture>
                <Text as="div">ホラー</Text>
              </Category>
              <Category
                href={`//${process.env.REACT_APP_WEB_DOMAIN}/tag/ミステリー`}
              >
                <picture>
                  <source type="image/webp" srcSet={mysteryIconWebp} />
                  <SectionIcon lazy src={mysteryIcon} width="25" height="25" />
                </picture>
                <Text as="div">ミステリー</Text>
              </Category>
              <Category
                href={`//${process.env.REACT_APP_WEB_DOMAIN}/tag/大人ロマンス`}
              >
                <SectionIcon src={romanceAdultIcon} />
                <Text as="div">大人ロマンス</Text>
              </Category>
              <Category
                href={`//${process.env.REACT_APP_WEB_DOMAIN}/tag/青春恋愛`}
              >
                <picture>
                  <source type="image/webp" srcSet={heartIconWebp} />
                  <SectionIcon lazy src={heartIcon} width="25" height="25" />
                </picture>
                <Text as="div">青春恋愛</Text>
              </Category>
              <Category
                href={`//${process.env.REACT_APP_WEB_DOMAIN}/tag/ドラマ`}
              >
                <picture>
                  <source type="image/webp" srcSet={dramaIconWebp} />
                  <SectionIcon lazy src={dramaIcon} width="25" height="25" />
                </picture>
                <Text as="div">ドラマ</Text>
              </Category>
              <Category
                href={`//${process.env.REACT_APP_WEB_DOMAIN}/tag/ファンタジー`}
              >
                <picture>
                  <source type="image/webp" srcSet={fantasyIconWebp} />
                  <SectionIcon lazy src={fantasyIcon} width="25" height="25" />
                </picture>
                <Text as="div">ファンタジー</Text>
              </Category>
            </section>

            <Separator />
            <Category href={`//${process.env.REACT_APP_WEB_DOMAIN}/my_page`}>
              <SectionIcon lazy src={myPageIcon} width="25" height="21" />
              <Text as="div">マイページ／設定</Text>
            </Category>
            <Category href="/" onClick={onClose}>
              <SectionIcon lazy src={myStoriesIcon} width="25" height="23" />
              <Text as="div">自分のストーリー</Text>
            </Category>
            <Separator />
            <Logout onClick={onLogout}>ログアウト</Logout>
          </ScrollableContent>
          <StickyButton ref={buttonRef}>
            <WriteStoryButton
              to="/novel/new/episodes/new?from=home"
              onClick={onClose}
            >
              <Image lazy mr={10} src={writeIcon} width="16" height="16" />
              ストーリーを書く
            </WriteStoryButton>
          </StickyButton>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
};

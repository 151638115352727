import { useCallback } from "react";
import { useAuth } from "./useAuth";
import { PublishRange } from "../models/novel";
import { useFirebaseAnalytics } from "./useFirebaseAnalytics";
import { useAppParameters } from "./useAppParameters";

interface SendEventsHandle {
  sendStoryCreateDone(
    epId: string,
    originalEpId: string,
    isPublished: boolean,
    publishRange: PublishRange | null,
    isNew: boolean,
    isNewPublished: boolean,
    tags: string[] | null | undefined
  ): void;
  sendStoryCreateStart(
    epId: string,
    originalEpId: string,
    isPublished: boolean,
    isNew: boolean,
    tags: string[] | null | undefined
  ): void;
}

export const useSendEvents = (): SendEventsHandle => {
  const { user: authUser, tellerUser } = useAuth();
  const { isApp } = useAppParameters();
  const { sendEventToFirebase } = useFirebaseAnalytics();
  const urlParams = new URLSearchParams(window.location.search);
  const from = urlParams.get("from");

  const triggerTellerStudioLink = useCallback(
    (
      event: string,
      finalEpisodeId: string,
      episodeIdParam: string,
      isPublished = false,
      isNew = false,
      tags?: string[] | null,
      isNewPublished = false,
      publishRange = null
    ) => {
      let eventName = "";
      let withFrom = "";
      let withStatus = "";
      let withTags = "";
      const isCreate = finalEpisodeId === "new" || !episodeIdParam;
      if (event === "create") {
        eventName = isCreate
          ? "start-create-story"
          : `start-update-story/${finalEpisodeId}`;
        withFrom = from === "home" ? "&from=manage-stories" : "";
      }
      if (event === "finish") {
        eventName = isNew
          ? `finish-create-story/${finalEpisodeId}`
          : `finish-update-story/${finalEpisodeId}`;
        withStatus = `&status=${isPublished ? "publish" : "draft"}`;
      }
      if (tags && tags.length > 0) {
        withTags = `&tags=${tags.join(",")}`;
      }

      const withIsNewPublish = `&is-new-publish=${isNewPublished ? 1 : 0}`;
      const withPublishFor = `&publish-for=${publishRange || "none"}`;

      if (eventName) {
        const url = `teller-studio://${eventName}?script_type=novel${withFrom}${withStatus}${withTags}${withIsNewPublish}${withPublishFor}`;
        window.location.href = url;
      }
    },
    [from]
  );

  const sendStoryCreateDone = useCallback(
    (
      epId: string,
      originalEpId: string,
      isPublished: boolean,
      publishRange: PublishRange | null,
      isNew: boolean,
      isNewPublished: boolean,
      tags: string[] | null | undefined
    ): void => {
      if (isApp) {
        triggerTellerStudioLink(
          "finish",
          epId,
          originalEpId,
          isPublished,
          isNew,
          tags,
          isNewPublished,
          publishRange
        );
      } else {
        sendEventToFirebase("story_create_done", authUser, tellerUser, {
          story_id: epId,
          is_new: isNew,
          script_type: "novel",
          status: isPublished ? "publish" : "draft",
          is_new_publish: isNewPublished ? "1" : "0",
          tags: tags?.join(",") || "",
          publish_for: publishRange || "none"
        });
      }
    },
    [authUser, isApp, sendEventToFirebase, tellerUser, triggerTellerStudioLink]
  );

  const sendStoryCreateStart = useCallback(
    (
      epId: string,
      originalEpId: string,
      isPublished: boolean,
      isNew: boolean,
      tags: string[] | null | undefined
    ): void => {
      if (isApp) {
        triggerTellerStudioLink(
          "create",
          epId,
          originalEpId,
          isPublished,
          isNew,
          tags
        );
      } else {
        sendEventToFirebase("story_create_start", authUser, tellerUser, {
          story_id: epId,
          is_new: isNew,
          from: from === "home" ? "自分の作品" : "連載",
          script_type: "novel",
          tags: tags?.join(",") || ""
        });
      }
    },
    [
      authUser,
      from,
      isApp,
      sendEventToFirebase,
      tellerUser,
      triggerTellerStudioLink
    ]
  );

  return {
    sendStoryCreateDone,
    sendStoryCreateStart
  };
};

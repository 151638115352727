import React from "react";
import styled from "@emotion/styled";
import { Color } from "../styles/enums";

const NoResultsWrapper = styled.div`
  padding: 100px 0;
  background-color: ${Color.PRIMARY};
  font-weight: bold;
  color: ${Color.ACCENT_500};
  text-align: center;
  margin: auto;
`;

interface Props {
  text: string;
}

export const NoResults: React.FC<Props> = ({ text }) => (
  <NoResultsWrapper>{text}</NoResultsWrapper>
);

import { useState, useCallback, useMemo } from "react";
import { useLocalStorage } from "react-use";

interface ImageUploadWarningModalHandle {
  shouldShowImageWarning: boolean;
  isOpen: boolean;
  open(): void;
  close(): void;
}

const IMAGE_UPLOAD_WARNING_KEY = "image-warning-acknowledged";

export const useImageUploadWarningModal = (): ImageUploadWarningModalHandle => {
  const [acknowledged, setAcknowledged] = useLocalStorage(
    IMAGE_UPLOAD_WARNING_KEY
  );

  const needImagesWarningAcknowledge = useMemo(() => !acknowledged, [
    acknowledged
  ]);

  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
    setAcknowledged(true);
  }, [setAcknowledged]);

  return {
    shouldShowImageWarning: needImagesWarningAcknowledge,
    isOpen,
    open,
    close
  };
};

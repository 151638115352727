import styled from "@emotion/styled/macro";
import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { NovelUseCase } from "../../usecases/novelUseCase";
import { useAuth } from "../../hooks/useAuth";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import { useAppParameters } from "../../hooks/useAppParameters";
import { ActionSheet, OptionsList, Option } from "../common/actionSheet";
import { ConfirmNovelDeleteModal } from "../common/confirmNovelDeleteModal";
import { Alert } from "../common/alert";
import { Image } from "../ui/image";
import editIcon from "../../assets/edit_icon.svg";
import reorderIcon from "../../assets/order_icon.svg";
import shareIcon from "../../assets/share_icon.svg";
import deleteIcon from "../../assets/delete_icon.svg";
import { logError } from "../../utils/utils";

const Wrapper = styled.div`
  min-height: 185px;
`;

interface Props {
  open: boolean;
  novelId?: string | null;
  isOfficial: boolean;
  isPublished: boolean;
  isOfficialWriter: boolean;
  hideOrderStories?: boolean;
  onEdit(): void;
  onDeleteSucceed(): void;
  onShare(): void;
  onClose(): void;
}

const novelUseCase = new NovelUseCase();

export const NovelActionSheet: React.VFC<Props> = ({
  open,
  novelId,
  isOfficial,
  isPublished,
  isOfficialWriter,
  hideOrderStories,
  onEdit,
  onDeleteSucceed,
  onShare,
  onClose
}) => {
  const history = useHistory();
  const { sendEventToFirebase } = useFirebaseAnalytics();
  const { user: authUser, tellerUser, requestReloadMe } = useAuth();
  const { isApp } = useAppParameters();
  const [isShowDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState<string | null>(null);
  const [isShowingDeleteSucceedAlert, setShowingDeleteSucceedAlert] = useState(
    false
  );
  const [
    isCloseActionSheetAfterAlert,
    setCloseActionSheetAfterAlert
  ] = useState(false);

  const showDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const closeDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);

  const showAlert = useCallback(
    (msg: string, closeActionSheetAfter: boolean) => {
      setAlertMsg(msg);
      setShowAlert(true);
      setCloseActionSheetAfterAlert(closeActionSheetAfter);
    },
    []
  );

  const closeAlert = useCallback(() => {
    setShowAlert(false);
    setAlertMsg(null);
    if (isShowingDeleteSucceedAlert) {
      onDeleteSucceed();
    }
    if (isCloseActionSheetAfterAlert) {
      onClose();
    }
  }, [
    isCloseActionSheetAfterAlert,
    isShowingDeleteSucceedAlert,
    onClose,
    onDeleteSucceed
  ]);

  const deleteNovel = useCallback(async () => {
    if (novelId) {
      try {
        await novelUseCase.deleteNovel(novelId);
        showAlert("削除しました", true);
        setShowingDeleteSucceedAlert(true);
        if (isApp) {
          window.location.href = `teller-studio://delete-series/${novelId}?script_type=novel`;
        } else {
          sendEventToFirebase("series_delete", authUser, tellerUser, {
            series_id: novelId,
            script_type: "novel"
          });
        }
        requestReloadMe();
      } catch (err) {
        showAlert("不明なエラーが発生しました。", true);
        logError(err);
      } finally {
        closeDeleteConfirm();
      }
    } else {
      closeDeleteConfirm();
    }
  }, [
    authUser,
    closeDeleteConfirm,
    isApp,
    novelId,
    requestReloadMe,
    sendEventToFirebase,
    showAlert,
    tellerUser
  ]);

  const onReorder = useCallback(() => {
    history.push(`/novel/${novelId}/reorder?notransition=1`);
  }, [history, novelId]);

  return (
    <>
      {isShowDeleteConfirm ? (
        <ConfirmNovelDeleteModal
          onClose={closeDeleteConfirm}
          onConfirm={deleteNovel}
        />
      ) : null}
      {isShowAlert && alertMsg ? (
        <Alert msg={alertMsg} close={closeAlert} />
      ) : null}

      <ActionSheet open={open || false} onClose={onClose} bckWhite>
        <Wrapper>
          <OptionsList>
            {isPublished && isOfficialWriter ? null : (
              <Option>
                <button type="button" onClick={onEdit}>
                  <Image src={editIcon} />
                  作品詳細を編集
                </button>
              </Option>
            )}
            {isOfficialWriter || hideOrderStories ? null : (
              <Option>
                <button type="button" onClick={onReorder}>
                  <Image src={reorderIcon} />
                  並び替え
                </button>
              </Option>
            )}
            {isApp ? (
              <Option>
                <button type="button" onClick={onShare}>
                  <Image src={shareIcon} />
                  作品を共有
                </button>
              </Option>
            ) : null}
            {isOfficial || (isPublished && isOfficialWriter) ? null : (
              <Option>
                <button type="button" onClick={showDeleteConfirm}>
                  <Image src={deleteIcon} />
                  作品を削除
                </button>
              </Option>
            )}
          </OptionsList>
        </Wrapper>
      </ActionSheet>
    </>
  );
};

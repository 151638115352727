import React from "react";
import styled from "@emotion/styled/macro";

// Components
import { Modal } from "./modal";
import { FontSize, Color } from "../styles/enums";
import { Button, ButtonVariant } from "../ui/button";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    flex: 1;
  }
`;

const CloseButton = styled(Button)`
  height: 40px;
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  max-width: 80%;
  margin: 16px auto 0;
  border: 1px solid ${Color.ACCENT_700};
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CommunityGuidelineModal: React.FC<Props> = ({ open, onClose }) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width="100vw"
      height="100vh"
      maxWidth="initial"
      borderRadius="initial"
      noPadding
      hideClose
      close={onClose}
      overlayClose={false}
    >
      <ContentWrapper>
        <iframe
          title="コミュニティガイドライン"
          src={`//${process.env.REACT_APP_WEB_DOMAIN}/community_guideline?nolayout=1`}
          frameBorder="no"
        />
        <CloseButton
          block
          variant={ButtonVariant.OUTLINED}
          color={Color.ACCENT_700}
          onClick={onClose}
        >
          閉じる
        </CloseButton>
      </ContentWrapper>
    </Modal>
  );
};

import { RPCImpl } from "protobufjs";

import { me as RpcMe } from "../infra/api/rpc/api";
import { TellerApi } from "../infra/api/tellerApi";

const servicePath = "/me.MeService/";

export class MeRepository {
  private api: TellerApi;

  constructor() {
    this.api = new TellerApi();
  }

  public issueAuthCustomToken = (): Promise<RpcMe.AuthCustomToken> => {
    const service = this.getMeService("IssueAuthCustomToken");
    return service.issueAuthCustomToken({});
  };

  private getMeService = (method: string): RpcMe.MeService => {
    const rpcImpl: RPCImpl = this.api.post(`${servicePath}${method}`);
    return RpcMe.MeService.create(rpcImpl, false, false);
  };
}

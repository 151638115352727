import { payment as RpcPayment } from "../infra/api/rpc/api";
import { PaymentRepository } from "../repositories/paymentRepository";

export enum PAYMENT_PLATFORM {
  CREDIT_CARD = "creditCard",
  DOCOMO = "docomo"
}

const paymentPlatformToRpc = (
  platform: PAYMENT_PLATFORM
): RpcPayment.PaymentPlatform => {
  let rpcPlatform: RpcPayment.PaymentPlatform;
  switch (platform) {
    case PAYMENT_PLATFORM.CREDIT_CARD:
      rpcPlatform = RpcPayment.PaymentPlatform.PAYMENT_PLATFORM_CREDIT_CARD;
      break;
    case PAYMENT_PLATFORM.DOCOMO:
      rpcPlatform = RpcPayment.PaymentPlatform.PAYMENT_PLATFORM_DOCOMO;
      break;
    default:
      rpcPlatform = RpcPayment.PaymentPlatform.PAYMENT_PLATFORM_UNSPECIFIED;
  }
  return rpcPlatform;
};

export const fetchLatestSubscription = (
  platform?: PAYMENT_PLATFORM
): Promise<RpcPayment.PaymentSubscription> => {
  const repo = new PaymentRepository();
  const rpcPlatform = platform
    ? paymentPlatformToRpc(platform)
    : RpcPayment.PaymentPlatform.PAYMENT_PLATFORM_UNSPECIFIED;
  return repo.getLatestSubscription(rpcPlatform);
};

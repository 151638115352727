import { useEffect, useCallback } from "react";
import { useLocalStorage } from "react-use";
import { User as FirebaseUser } from "firebase/auth";
import { logEvent } from "firebase/analytics";
import { nanoid } from "nanoid";
import { query_recursive_types as RpcQueryRecursiveTypes } from "../infra/api/rpc/api";

import { SESSION_KEY } from "../App";
import { analytics } from "../index";

interface FBAnalytics {
  sendEventToFirebase: (
    event: string,
    authUser: FirebaseUser | null | undefined,
    tellerUser: RpcQueryRecursiveTypes.IUserResponse | null,
    params?: Record<string, unknown>
  ) => void;
}

const isProduction =
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const useFirebaseAnalytics = (): FBAnalytics => {
  const [sessionID, setSessionID] = useLocalStorage<string | undefined>(
    SESSION_KEY,
    undefined
  );

  useEffect(() => {
    if (!sessionID) {
      setSessionID(nanoid());
    }
  }, [sessionID, setSessionID]);

  const sendEventToFirebase = useCallback(
    (
      event: string,
      authUser: FirebaseUser | null | undefined,
      tellerUser: RpcQueryRecursiveTypes.IUserResponse | null,
      params?: Record<string, unknown>
    ): void => {
      const paramsToSend = params;

      // eslint-disable camelcase
      if (paramsToSend) {
        if (tellerUser) {
          paramsToSend.login_user_id = tellerUser.id?.value;
        }
        if (authUser?.uid) {
          paramsToSend.user_id = authUser.uid;
        }
        if (sessionID) {
          paramsToSend.browser_session_id = sessionID;
        }
      }
      // eslint-disable enable
      if (!isProduction) {
        // eslint-disable-next-line no-console
        console.log(`**** sending ${event}:`, JSON.stringify(paramsToSend));
      }

      if (isProduction && analytics) {
        logEvent(analytics, event, paramsToSend);
      }
    },
    [sessionID]
  );

  return {
    sendEventToFirebase
  };
};

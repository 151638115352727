/*eslint-disable*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.errors = (function() {

    /**
     * Namespace errors.
     * @exports errors
     * @namespace
     */
    var errors = {};

    /**
     * ErrorMessage enum.
     * @name errors.ErrorMessage
     * @enum {number}
     * @property {number} ERROR_MESSAGE_UNKNOWN=0 ERROR_MESSAGE_UNKNOWN value
     * @property {number} BAD_REQUEST_ERROR_CODE=1 BAD_REQUEST_ERROR_CODE value
     * @property {number} REQUIRED_ERROR_CODE=2 REQUIRED_ERROR_CODE value
     * @property {number} MIN_LENGTH_ERROR_CODE=3 MIN_LENGTH_ERROR_CODE value
     * @property {number} MAX_LENGTH_ERROR_CODE=4 MAX_LENGTH_ERROR_CODE value
     * @property {number} INVALID_FORMAT_ERROR_CODE=5 INVALID_FORMAT_ERROR_CODE value
     * @property {number} UNAUTHORIZED_ERROR_CODE=6 UNAUTHORIZED_ERROR_CODE value
     * @property {number} FORBIDDEN_ERROR_CODE=7 FORBIDDEN_ERROR_CODE value
     * @property {number} NOT_FOUND_ERROR_CODE=8 NOT_FOUND_ERROR_CODE value
     * @property {number} SERVER_ERROR_CODE=9 SERVER_ERROR_CODE value
     * @property {number} MAINTENANCE_ERROR_CODE=10 MAINTENANCE_ERROR_CODE value
     * @property {number} AUTHORIZATION_EXPIRED_ERROR_CODE=11 AUTHORIZATION_EXPIRED_ERROR_CODE value
     * @property {number} CONFLICT_ERROR_CODE=12 CONFLICT_ERROR_CODE value
     */
    errors.ErrorMessage = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ERROR_MESSAGE_UNKNOWN"] = 0;
        values[valuesById[1] = "BAD_REQUEST_ERROR_CODE"] = 1;
        values[valuesById[2] = "REQUIRED_ERROR_CODE"] = 2;
        values[valuesById[3] = "MIN_LENGTH_ERROR_CODE"] = 3;
        values[valuesById[4] = "MAX_LENGTH_ERROR_CODE"] = 4;
        values[valuesById[5] = "INVALID_FORMAT_ERROR_CODE"] = 5;
        values[valuesById[6] = "UNAUTHORIZED_ERROR_CODE"] = 6;
        values[valuesById[7] = "FORBIDDEN_ERROR_CODE"] = 7;
        values[valuesById[8] = "NOT_FOUND_ERROR_CODE"] = 8;
        values[valuesById[9] = "SERVER_ERROR_CODE"] = 9;
        values[valuesById[10] = "MAINTENANCE_ERROR_CODE"] = 10;
        values[valuesById[11] = "AUTHORIZATION_EXPIRED_ERROR_CODE"] = 11;
        values[valuesById[12] = "CONFLICT_ERROR_CODE"] = 12;
        return values;
    })();

    /**
     * ErrorField enum.
     * @name errors.ErrorField
     * @enum {number}
     * @property {number} ERROR_FIELD_UNKNOWN=0 ERROR_FIELD_UNKNOWN value
     * @property {number} TITLE=1 TITLE value
     * @property {number} THUMBNAIL_URL=2 THUMBNAIL_URL value
     * @property {number} TAGS=3 TAGS value
     * @property {number} SCRIPT_BODY=4 SCRIPT_BODY value
     * @property {number} NAME=5 NAME value
     * @property {number} PROFILE=6 PROFILE value
     * @property {number} GS_PATH=7 GS_PATH value
     * @property {number} WIDTH=8 WIDTH value
     * @property {number} HEIGHT=9 HEIGHT value
     * @property {number} CATEGORY=10 CATEGORY value
     * @property {number} FORMAT=11 FORMAT value
     * @property {number} DURATION=12 DURATION value
     * @property {number} CODEC=13 CODEC value
     * @property {number} AMOUNT=14 AMOUNT value
     * @property {number} DESCRIPTION=15 DESCRIPTION value
     */
    errors.ErrorField = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ERROR_FIELD_UNKNOWN"] = 0;
        values[valuesById[1] = "TITLE"] = 1;
        values[valuesById[2] = "THUMBNAIL_URL"] = 2;
        values[valuesById[3] = "TAGS"] = 3;
        values[valuesById[4] = "SCRIPT_BODY"] = 4;
        values[valuesById[5] = "NAME"] = 5;
        values[valuesById[6] = "PROFILE"] = 6;
        values[valuesById[7] = "GS_PATH"] = 7;
        values[valuesById[8] = "WIDTH"] = 8;
        values[valuesById[9] = "HEIGHT"] = 9;
        values[valuesById[10] = "CATEGORY"] = 10;
        values[valuesById[11] = "FORMAT"] = 11;
        values[valuesById[12] = "DURATION"] = 12;
        values[valuesById[13] = "CODEC"] = 13;
        values[valuesById[14] = "AMOUNT"] = 14;
        values[valuesById[15] = "DESCRIPTION"] = 15;
        return values;
    })();

    return errors;
})();

$root.event = (function() {

    /**
     * Namespace event.
     * @exports event
     * @namespace
     */
    var event = {};

    event.EventService = (function() {

        /**
         * Constructs a new EventService service.
         * @memberof event
         * @classdesc Represents an EventService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function EventService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (EventService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EventService;

        /**
         * Creates new EventService service using the specified rpc implementation.
         * @function create
         * @memberof event.EventService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {EventService} RPC service. Useful where requests and/or responses are streamed.
         */
        EventService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link event.EventService#listActive}.
         * @memberof event.EventService
         * @typedef ListActiveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {event.EventList} [response] EventList
         */

        /**
         * Calls ListActive.
         * @function listActive
         * @memberof event.EventService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {event.EventService.ListActiveCallback} callback Node-style callback called with the error, if any, and EventList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EventService.prototype.listActive = function listActive(request, callback) {
            return this.rpcCall(listActive, $root.types.Empty, $root.event.EventList, request, callback);
        }, "name", { value: "ListActive" });

        /**
         * Calls ListActive.
         * @function listActive
         * @memberof event.EventService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<event.EventList>} Promise
         * @variation 2
         */

        return EventService;
    })();

    event.Event = (function() {

        /**
         * Properties of an Event.
         * @memberof event
         * @interface IEvent
         * @property {google.protobuf.IStringValue|null} [id] Event id
         * @property {google.protobuf.IStringValue|null} [name] Event name
         * @property {google.protobuf.IStringValue|null} [description] Event description
         * @property {google.protobuf.IStringValue|null} [tag] Event tag
         * @property {google.protobuf.IStringValue|null} [actionUrl] Event actionUrl
         * @property {resource.IImage|null} [coverImage] Event coverImage
         * @property {google.protobuf.ITimestamp|null} [since] Event since
         * @property {google.protobuf.ITimestamp|null} [until] Event until
         * @property {google.protobuf.ITimestamp|null} [createdAt] Event createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] Event updatedAt
         */

        /**
         * Constructs a new Event.
         * @memberof event
         * @classdesc Represents an Event.
         * @implements IEvent
         * @constructor
         * @param {event.IEvent=} [properties] Properties to set
         */
        function Event(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Event id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof event.Event
         * @instance
         */
        Event.prototype.id = null;

        /**
         * Event name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof event.Event
         * @instance
         */
        Event.prototype.name = null;

        /**
         * Event description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof event.Event
         * @instance
         */
        Event.prototype.description = null;

        /**
         * Event tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof event.Event
         * @instance
         */
        Event.prototype.tag = null;

        /**
         * Event actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof event.Event
         * @instance
         */
        Event.prototype.actionUrl = null;

        /**
         * Event coverImage.
         * @member {resource.IImage|null|undefined} coverImage
         * @memberof event.Event
         * @instance
         */
        Event.prototype.coverImage = null;

        /**
         * Event since.
         * @member {google.protobuf.ITimestamp|null|undefined} since
         * @memberof event.Event
         * @instance
         */
        Event.prototype.since = null;

        /**
         * Event until.
         * @member {google.protobuf.ITimestamp|null|undefined} until
         * @memberof event.Event
         * @instance
         */
        Event.prototype.until = null;

        /**
         * Event createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof event.Event
         * @instance
         */
        Event.prototype.createdAt = null;

        /**
         * Event updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof event.Event
         * @instance
         */
        Event.prototype.updatedAt = null;

        /**
         * Creates a new Event instance using the specified properties.
         * @function create
         * @memberof event.Event
         * @static
         * @param {event.IEvent=} [properties] Properties to set
         * @returns {event.Event} Event instance
         */
        Event.create = function create(properties) {
            return new Event(properties);
        };

        /**
         * Encodes the specified Event message. Does not implicitly {@link event.Event.verify|verify} messages.
         * @function encode
         * @memberof event.Event
         * @static
         * @param {event.IEvent} message Event message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Event.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.coverImage != null && Object.hasOwnProperty.call(message, "coverImage"))
                $root.resource.Image.encode(message.coverImage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.since != null && Object.hasOwnProperty.call(message, "since"))
                $root.google.protobuf.Timestamp.encode(message.since, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.until != null && Object.hasOwnProperty.call(message, "until"))
                $root.google.protobuf.Timestamp.encode(message.until, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Event message from the specified reader or buffer.
         * @function decode
         * @memberof event.Event
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {event.Event} Event
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Event.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.event.Event();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.coverImage = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.since = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.until = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an Event message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof event.Event
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {event.Event} Event
         */
        Event.fromObject = function fromObject(object) {
            if (object instanceof $root.event.Event)
                return object;
            var message = new $root.event.Event();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".event.Event.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".event.Event.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".event.Event.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".event.Event.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".event.Event.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.coverImage != null) {
                if (typeof object.coverImage !== "object")
                    throw TypeError(".event.Event.coverImage: object expected");
                message.coverImage = $root.resource.Image.fromObject(object.coverImage);
            }
            if (object.since != null) {
                if (typeof object.since !== "object")
                    throw TypeError(".event.Event.since: object expected");
                message.since = $root.google.protobuf.Timestamp.fromObject(object.since);
            }
            if (object.until != null) {
                if (typeof object.until !== "object")
                    throw TypeError(".event.Event.until: object expected");
                message.until = $root.google.protobuf.Timestamp.fromObject(object.until);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".event.Event.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".event.Event.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an Event message. Also converts values to other types if specified.
         * @function toObject
         * @memberof event.Event
         * @static
         * @param {event.Event} message Event
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Event.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.name = null;
                object.description = null;
                object.tag = null;
                object.actionUrl = null;
                object.coverImage = null;
                object.since = null;
                object.until = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.coverImage != null && message.hasOwnProperty("coverImage"))
                object.coverImage = $root.resource.Image.toObject(message.coverImage, options);
            if (message.since != null && message.hasOwnProperty("since"))
                object.since = $root.google.protobuf.Timestamp.toObject(message.since, options);
            if (message.until != null && message.hasOwnProperty("until"))
                object.until = $root.google.protobuf.Timestamp.toObject(message.until, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this Event to JSON.
         * @function toJSON
         * @memberof event.Event
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Event.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Event
         * @function getTypeUrl
         * @memberof event.Event
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Event.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/event.Event";
        };

        return Event;
    })();

    event.EventList = (function() {

        /**
         * Properties of an EventList.
         * @memberof event
         * @interface IEventList
         * @property {Array.<event.IEvent>|null} [eventList] EventList eventList
         */

        /**
         * Constructs a new EventList.
         * @memberof event
         * @classdesc Represents an EventList.
         * @implements IEventList
         * @constructor
         * @param {event.IEventList=} [properties] Properties to set
         */
        function EventList(properties) {
            this.eventList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventList eventList.
         * @member {Array.<event.IEvent>} eventList
         * @memberof event.EventList
         * @instance
         */
        EventList.prototype.eventList = $util.emptyArray;

        /**
         * Creates a new EventList instance using the specified properties.
         * @function create
         * @memberof event.EventList
         * @static
         * @param {event.IEventList=} [properties] Properties to set
         * @returns {event.EventList} EventList instance
         */
        EventList.create = function create(properties) {
            return new EventList(properties);
        };

        /**
         * Encodes the specified EventList message. Does not implicitly {@link event.EventList.verify|verify} messages.
         * @function encode
         * @memberof event.EventList
         * @static
         * @param {event.IEventList} message EventList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eventList != null && message.eventList.length)
                for (var i = 0; i < message.eventList.length; ++i)
                    $root.event.Event.encode(message.eventList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an EventList message from the specified reader or buffer.
         * @function decode
         * @memberof event.EventList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {event.EventList} EventList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.event.EventList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.eventList && message.eventList.length))
                        message.eventList = [];
                    message.eventList.push($root.event.Event.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an EventList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof event.EventList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {event.EventList} EventList
         */
        EventList.fromObject = function fromObject(object) {
            if (object instanceof $root.event.EventList)
                return object;
            var message = new $root.event.EventList();
            if (object.eventList) {
                if (!Array.isArray(object.eventList))
                    throw TypeError(".event.EventList.eventList: array expected");
                message.eventList = [];
                for (var i = 0; i < object.eventList.length; ++i) {
                    if (typeof object.eventList[i] !== "object")
                        throw TypeError(".event.EventList.eventList: object expected");
                    message.eventList[i] = $root.event.Event.fromObject(object.eventList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an EventList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof event.EventList
         * @static
         * @param {event.EventList} message EventList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.eventList = [];
            if (message.eventList && message.eventList.length) {
                object.eventList = [];
                for (var j = 0; j < message.eventList.length; ++j)
                    object.eventList[j] = $root.event.Event.toObject(message.eventList[j], options);
            }
            return object;
        };

        /**
         * Converts this EventList to JSON.
         * @function toJSON
         * @memberof event.EventList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventList
         * @function getTypeUrl
         * @memberof event.EventList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/event.EventList";
        };

        return EventList;
    })();

    /**
     * EventLabel enum.
     * @name event.EventLabel
     * @enum {number}
     * @property {number} EVENT_LABEL_UNSPECIFIED=0 EVENT_LABEL_UNSPECIFIED value
     * @property {number} EVENT_LABEL_THEME=1 EVENT_LABEL_THEME value
     * @property {number} EVENT_LABEL_CONTEST=2 EVENT_LABEL_CONTEST value
     */
    event.EventLabel = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EVENT_LABEL_UNSPECIFIED"] = 0;
        values[valuesById[1] = "EVENT_LABEL_THEME"] = 1;
        values[valuesById[2] = "EVENT_LABEL_CONTEST"] = 2;
        return values;
    })();

    return event;
})();

$root.types = (function() {

    /**
     * Namespace types.
     * @exports types
     * @namespace
     */
    var types = {};

    /**
     * SharedWithStatus enum.
     * @name types.SharedWithStatus
     * @enum {number}
     * @property {number} SHARED_WITH_STATUS_UNKNOWN=0 SHARED_WITH_STATUS_UNKNOWN value
     * @property {number} SHARED_WITH_NOBODY=1 SHARED_WITH_NOBODY value
     * @property {number} SHARED_WITH_DIRECT_LINK=2 SHARED_WITH_DIRECT_LINK value
     * @property {number} SHARED_WITH_PUBLIC=3 SHARED_WITH_PUBLIC value
     * @property {number} SHARED_WITH_FOLLOWER=4 SHARED_WITH_FOLLOWER value
     * @property {number} SHARED_WITH_MUTUAL_FOLLOWER=5 SHARED_WITH_MUTUAL_FOLLOWER value
     */
    types.SharedWithStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SHARED_WITH_STATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "SHARED_WITH_NOBODY"] = 1;
        values[valuesById[2] = "SHARED_WITH_DIRECT_LINK"] = 2;
        values[valuesById[3] = "SHARED_WITH_PUBLIC"] = 3;
        values[valuesById[4] = "SHARED_WITH_FOLLOWER"] = 4;
        values[valuesById[5] = "SHARED_WITH_MUTUAL_FOLLOWER"] = 5;
        return values;
    })();

    /**
     * OS enum.
     * @name types.OS
     * @enum {number}
     * @property {number} OS_UNSPECIFIED=0 OS_UNSPECIFIED value
     * @property {number} OS_IOS=1 OS_IOS value
     * @property {number} OS_ANDROID=2 OS_ANDROID value
     */
    types.OS = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "OS_IOS"] = 1;
        values[valuesById[2] = "OS_ANDROID"] = 2;
        return values;
    })();

    types.Empty = (function() {

        /**
         * Properties of an Empty.
         * @memberof types
         * @interface IEmpty
         */

        /**
         * Constructs a new Empty.
         * @memberof types
         * @classdesc Represents an Empty.
         * @implements IEmpty
         * @constructor
         * @param {types.IEmpty=} [properties] Properties to set
         */
        function Empty(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Empty instance using the specified properties.
         * @function create
         * @memberof types.Empty
         * @static
         * @param {types.IEmpty=} [properties] Properties to set
         * @returns {types.Empty} Empty instance
         */
        Empty.create = function create(properties) {
            return new Empty(properties);
        };

        /**
         * Encodes the specified Empty message. Does not implicitly {@link types.Empty.verify|verify} messages.
         * @function encode
         * @memberof types.Empty
         * @static
         * @param {types.IEmpty} message Empty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Empty.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an Empty message from the specified reader or buffer.
         * @function decode
         * @memberof types.Empty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {types.Empty} Empty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Empty.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.types.Empty();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an Empty message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof types.Empty
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {types.Empty} Empty
         */
        Empty.fromObject = function fromObject(object) {
            if (object instanceof $root.types.Empty)
                return object;
            return new $root.types.Empty();
        };

        /**
         * Creates a plain object from an Empty message. Also converts values to other types if specified.
         * @function toObject
         * @memberof types.Empty
         * @static
         * @param {types.Empty} message Empty
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Empty.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Empty to JSON.
         * @function toJSON
         * @memberof types.Empty
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Empty.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Empty
         * @function getTypeUrl
         * @memberof types.Empty
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Empty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/types.Empty";
        };

        return Empty;
    })();

    /**
     * CommentRestrictionType enum.
     * @name types.CommentRestrictionType
     * @enum {number}
     * @property {number} COMMENT_RESTRICTION_TYPE_UNSPECIFIED=0 COMMENT_RESTRICTION_TYPE_UNSPECIFIED value
     * @property {number} COMMENT_RESTRICTION_TYPE_UNRESTRICTED=1 COMMENT_RESTRICTION_TYPE_UNRESTRICTED value
     * @property {number} COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS_AND_FOLLOWINGS=2 COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS_AND_FOLLOWINGS value
     * @property {number} COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWINGS=3 COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWINGS value
     * @property {number} COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS=4 COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS value
     */
    types.CommentRestrictionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COMMENT_RESTRICTION_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "COMMENT_RESTRICTION_TYPE_UNRESTRICTED"] = 1;
        values[valuesById[2] = "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS_AND_FOLLOWINGS"] = 2;
        values[valuesById[3] = "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWINGS"] = 3;
        values[valuesById[4] = "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS"] = 4;
        return values;
    })();

    types.UInt32Range = (function() {

        /**
         * Properties of a UInt32Range.
         * @memberof types
         * @interface IUInt32Range
         * @property {google.protobuf.IUInt32Value|null} [greaterThanEqual] UInt32Range greaterThanEqual
         * @property {google.protobuf.IUInt32Value|null} [lessThan] UInt32Range lessThan
         */

        /**
         * Constructs a new UInt32Range.
         * @memberof types
         * @classdesc Represents a UInt32Range.
         * @implements IUInt32Range
         * @constructor
         * @param {types.IUInt32Range=} [properties] Properties to set
         */
        function UInt32Range(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UInt32Range greaterThanEqual.
         * @member {google.protobuf.IUInt32Value|null|undefined} greaterThanEqual
         * @memberof types.UInt32Range
         * @instance
         */
        UInt32Range.prototype.greaterThanEqual = null;

        /**
         * UInt32Range lessThan.
         * @member {google.protobuf.IUInt32Value|null|undefined} lessThan
         * @memberof types.UInt32Range
         * @instance
         */
        UInt32Range.prototype.lessThan = null;

        /**
         * Creates a new UInt32Range instance using the specified properties.
         * @function create
         * @memberof types.UInt32Range
         * @static
         * @param {types.IUInt32Range=} [properties] Properties to set
         * @returns {types.UInt32Range} UInt32Range instance
         */
        UInt32Range.create = function create(properties) {
            return new UInt32Range(properties);
        };

        /**
         * Encodes the specified UInt32Range message. Does not implicitly {@link types.UInt32Range.verify|verify} messages.
         * @function encode
         * @memberof types.UInt32Range
         * @static
         * @param {types.IUInt32Range} message UInt32Range message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UInt32Range.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.greaterThanEqual != null && Object.hasOwnProperty.call(message, "greaterThanEqual"))
                $root.google.protobuf.UInt32Value.encode(message.greaterThanEqual, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.lessThan != null && Object.hasOwnProperty.call(message, "lessThan"))
                $root.google.protobuf.UInt32Value.encode(message.lessThan, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UInt32Range message from the specified reader or buffer.
         * @function decode
         * @memberof types.UInt32Range
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {types.UInt32Range} UInt32Range
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UInt32Range.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.types.UInt32Range();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.greaterThanEqual = $root.google.protobuf.UInt32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lessThan = $root.google.protobuf.UInt32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UInt32Range message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof types.UInt32Range
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {types.UInt32Range} UInt32Range
         */
        UInt32Range.fromObject = function fromObject(object) {
            if (object instanceof $root.types.UInt32Range)
                return object;
            var message = new $root.types.UInt32Range();
            if (object.greaterThanEqual != null) {
                if (typeof object.greaterThanEqual !== "object")
                    throw TypeError(".types.UInt32Range.greaterThanEqual: object expected");
                message.greaterThanEqual = $root.google.protobuf.UInt32Value.fromObject(object.greaterThanEqual);
            }
            if (object.lessThan != null) {
                if (typeof object.lessThan !== "object")
                    throw TypeError(".types.UInt32Range.lessThan: object expected");
                message.lessThan = $root.google.protobuf.UInt32Value.fromObject(object.lessThan);
            }
            return message;
        };

        /**
         * Creates a plain object from a UInt32Range message. Also converts values to other types if specified.
         * @function toObject
         * @memberof types.UInt32Range
         * @static
         * @param {types.UInt32Range} message UInt32Range
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UInt32Range.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.greaterThanEqual = null;
                object.lessThan = null;
            }
            if (message.greaterThanEqual != null && message.hasOwnProperty("greaterThanEqual"))
                object.greaterThanEqual = $root.google.protobuf.UInt32Value.toObject(message.greaterThanEqual, options);
            if (message.lessThan != null && message.hasOwnProperty("lessThan"))
                object.lessThan = $root.google.protobuf.UInt32Value.toObject(message.lessThan, options);
            return object;
        };

        /**
         * Converts this UInt32Range to JSON.
         * @function toJSON
         * @memberof types.UInt32Range
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UInt32Range.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UInt32Range
         * @function getTypeUrl
         * @memberof types.UInt32Range
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UInt32Range.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/types.UInt32Range";
        };

        return UInt32Range;
    })();

    types.TimestampRange = (function() {

        /**
         * Properties of a TimestampRange.
         * @memberof types
         * @interface ITimestampRange
         * @property {google.protobuf.ITimestamp|null} [greaterThanEqual] TimestampRange greaterThanEqual
         * @property {google.protobuf.ITimestamp|null} [lessThan] TimestampRange lessThan
         */

        /**
         * Constructs a new TimestampRange.
         * @memberof types
         * @classdesc Represents a TimestampRange.
         * @implements ITimestampRange
         * @constructor
         * @param {types.ITimestampRange=} [properties] Properties to set
         */
        function TimestampRange(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimestampRange greaterThanEqual.
         * @member {google.protobuf.ITimestamp|null|undefined} greaterThanEqual
         * @memberof types.TimestampRange
         * @instance
         */
        TimestampRange.prototype.greaterThanEqual = null;

        /**
         * TimestampRange lessThan.
         * @member {google.protobuf.ITimestamp|null|undefined} lessThan
         * @memberof types.TimestampRange
         * @instance
         */
        TimestampRange.prototype.lessThan = null;

        /**
         * Creates a new TimestampRange instance using the specified properties.
         * @function create
         * @memberof types.TimestampRange
         * @static
         * @param {types.ITimestampRange=} [properties] Properties to set
         * @returns {types.TimestampRange} TimestampRange instance
         */
        TimestampRange.create = function create(properties) {
            return new TimestampRange(properties);
        };

        /**
         * Encodes the specified TimestampRange message. Does not implicitly {@link types.TimestampRange.verify|verify} messages.
         * @function encode
         * @memberof types.TimestampRange
         * @static
         * @param {types.ITimestampRange} message TimestampRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimestampRange.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.greaterThanEqual != null && Object.hasOwnProperty.call(message, "greaterThanEqual"))
                $root.google.protobuf.Timestamp.encode(message.greaterThanEqual, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.lessThan != null && Object.hasOwnProperty.call(message, "lessThan"))
                $root.google.protobuf.Timestamp.encode(message.lessThan, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TimestampRange message from the specified reader or buffer.
         * @function decode
         * @memberof types.TimestampRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {types.TimestampRange} TimestampRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimestampRange.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.types.TimestampRange();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.greaterThanEqual = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lessThan = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TimestampRange message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof types.TimestampRange
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {types.TimestampRange} TimestampRange
         */
        TimestampRange.fromObject = function fromObject(object) {
            if (object instanceof $root.types.TimestampRange)
                return object;
            var message = new $root.types.TimestampRange();
            if (object.greaterThanEqual != null) {
                if (typeof object.greaterThanEqual !== "object")
                    throw TypeError(".types.TimestampRange.greaterThanEqual: object expected");
                message.greaterThanEqual = $root.google.protobuf.Timestamp.fromObject(object.greaterThanEqual);
            }
            if (object.lessThan != null) {
                if (typeof object.lessThan !== "object")
                    throw TypeError(".types.TimestampRange.lessThan: object expected");
                message.lessThan = $root.google.protobuf.Timestamp.fromObject(object.lessThan);
            }
            return message;
        };

        /**
         * Creates a plain object from a TimestampRange message. Also converts values to other types if specified.
         * @function toObject
         * @memberof types.TimestampRange
         * @static
         * @param {types.TimestampRange} message TimestampRange
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimestampRange.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.greaterThanEqual = null;
                object.lessThan = null;
            }
            if (message.greaterThanEqual != null && message.hasOwnProperty("greaterThanEqual"))
                object.greaterThanEqual = $root.google.protobuf.Timestamp.toObject(message.greaterThanEqual, options);
            if (message.lessThan != null && message.hasOwnProperty("lessThan"))
                object.lessThan = $root.google.protobuf.Timestamp.toObject(message.lessThan, options);
            return object;
        };

        /**
         * Converts this TimestampRange to JSON.
         * @function toJSON
         * @memberof types.TimestampRange
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimestampRange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TimestampRange
         * @function getTypeUrl
         * @memberof types.TimestampRange
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimestampRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/types.TimestampRange";
        };

        return TimestampRange;
    })();

    /**
     * ViolationType enum.
     * @name types.ViolationType
     * @enum {number}
     * @property {number} VIOLATION_TYPE_UNKNOWN=0 VIOLATION_TYPE_UNKNOWN value
     * @property {number} VIOLATION_TYPE_VIOLENCE=1 VIOLATION_TYPE_VIOLENCE value
     * @property {number} VIOLATION_TYPE_HARASSMENT=2 VIOLATION_TYPE_HARASSMENT value
     * @property {number} VIOLATION_TYPE_PRIVACY=3 VIOLATION_TYPE_PRIVACY value
     * @property {number} VIOLATION_TYPE_RIGHTS=4 VIOLATION_TYPE_RIGHTS value
     * @property {number} VIOLATION_TYPE_ADULT=5 VIOLATION_TYPE_ADULT value
     * @property {number} VIOLATION_TYPE_SPAM=6 VIOLATION_TYPE_SPAM value
     * @property {number} VIOLATION_TYPE_OTHER=7 VIOLATION_TYPE_OTHER value
     */
    types.ViolationType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "VIOLATION_TYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "VIOLATION_TYPE_VIOLENCE"] = 1;
        values[valuesById[2] = "VIOLATION_TYPE_HARASSMENT"] = 2;
        values[valuesById[3] = "VIOLATION_TYPE_PRIVACY"] = 3;
        values[valuesById[4] = "VIOLATION_TYPE_RIGHTS"] = 4;
        values[valuesById[5] = "VIOLATION_TYPE_ADULT"] = 5;
        values[valuesById[6] = "VIOLATION_TYPE_SPAM"] = 6;
        values[valuesById[7] = "VIOLATION_TYPE_OTHER"] = 7;
        return values;
    })();

    /**
     * Platform enum.
     * @name types.Platform
     * @enum {number}
     * @property {number} PLATFORM_UNSPECIFIED=0 PLATFORM_UNSPECIFIED value
     * @property {number} PLATFORM_IOS_APP=1 PLATFORM_IOS_APP value
     * @property {number} PLATFORM_ANDROID_APP=2 PLATFORM_ANDROID_APP value
     * @property {number} PLATFORM_WEB_APP=3 PLATFORM_WEB_APP value
     */
    types.Platform = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PLATFORM_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PLATFORM_IOS_APP"] = 1;
        values[valuesById[2] = "PLATFORM_ANDROID_APP"] = 2;
        values[valuesById[3] = "PLATFORM_WEB_APP"] = 3;
        return values;
    })();

    return types;
})();

$root.resource = (function() {

    /**
     * Namespace resource.
     * @exports resource
     * @namespace
     */
    var resource = {};

    /**
     * ImageFormat enum.
     * @name resource.ImageFormat
     * @enum {number}
     * @property {number} IMAGE_FORMAT_UNSPECIFIED=0 IMAGE_FORMAT_UNSPECIFIED value
     * @property {number} IMAGE_FORMAT_PNG=1 IMAGE_FORMAT_PNG value
     * @property {number} IMAGE_FORMAT_JPEG=2 IMAGE_FORMAT_JPEG value
     * @property {number} IMAGE_FORMAT_GIF=3 IMAGE_FORMAT_GIF value
     * @property {number} IMAGE_FORMAT_WEBP=4 IMAGE_FORMAT_WEBP value
     */
    resource.ImageFormat = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "IMAGE_FORMAT_UNSPECIFIED"] = 0;
        values[valuesById[1] = "IMAGE_FORMAT_PNG"] = 1;
        values[valuesById[2] = "IMAGE_FORMAT_JPEG"] = 2;
        values[valuesById[3] = "IMAGE_FORMAT_GIF"] = 3;
        values[valuesById[4] = "IMAGE_FORMAT_WEBP"] = 4;
        return values;
    })();

    resource.Image = (function() {

        /**
         * Properties of an Image.
         * @memberof resource
         * @interface IImage
         * @property {google.protobuf.IStringValue|null} [id] Image id
         * @property {google.protobuf.IStringValue|null} [userId] Image userId
         * @property {google.protobuf.IStringValue|null} [servingUrl] Image servingUrl
         * @property {resource.ImageFormat|null} [format] Image format
         * @property {google.protobuf.IInt32Value|null} [width] Image width
         * @property {google.protobuf.IInt32Value|null} [height] Image height
         * @property {resource.ImageCategory|null} [category] Image category
         * @property {google.protobuf.ITimestamp|null} [createdAt] Image createdAt
         * @property {google.protobuf.IBoolValue|null} [isAvailable] Image isAvailable
         * @property {google.protobuf.ITimestamp|null} [updatedAt] Image updatedAt
         */

        /**
         * Constructs a new Image.
         * @memberof resource
         * @classdesc Represents an Image.
         * @implements IImage
         * @constructor
         * @param {resource.IImage=} [properties] Properties to set
         */
        function Image(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Image id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.id = null;

        /**
         * Image userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.userId = null;

        /**
         * Image servingUrl.
         * @member {google.protobuf.IStringValue|null|undefined} servingUrl
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.servingUrl = null;

        /**
         * Image format.
         * @member {resource.ImageFormat} format
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.format = 0;

        /**
         * Image width.
         * @member {google.protobuf.IInt32Value|null|undefined} width
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.width = null;

        /**
         * Image height.
         * @member {google.protobuf.IInt32Value|null|undefined} height
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.height = null;

        /**
         * Image category.
         * @member {resource.ImageCategory} category
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.category = 0;

        /**
         * Image createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.createdAt = null;

        /**
         * Image isAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} isAvailable
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.isAvailable = null;

        /**
         * Image updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof resource.Image
         * @instance
         */
        Image.prototype.updatedAt = null;

        /**
         * Creates a new Image instance using the specified properties.
         * @function create
         * @memberof resource.Image
         * @static
         * @param {resource.IImage=} [properties] Properties to set
         * @returns {resource.Image} Image instance
         */
        Image.create = function create(properties) {
            return new Image(properties);
        };

        /**
         * Encodes the specified Image message. Does not implicitly {@link resource.Image.verify|verify} messages.
         * @function encode
         * @memberof resource.Image
         * @static
         * @param {resource.IImage} message Image message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Image.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.servingUrl != null && Object.hasOwnProperty.call(message, "servingUrl"))
                $root.google.protobuf.StringValue.encode(message.servingUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.format);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                $root.google.protobuf.Int32Value.encode(message.width, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                $root.google.protobuf.Int32Value.encode(message.height, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.category);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isAvailable != null && Object.hasOwnProperty.call(message, "isAvailable"))
                $root.google.protobuf.BoolValue.encode(message.isAvailable, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Image message from the specified reader or buffer.
         * @function decode
         * @memberof resource.Image
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.Image} Image
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Image.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.Image();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.servingUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.format = reader.int32();
                    break;
                case 5:
                    message.width = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.height = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.category = reader.int32();
                    break;
                case 8:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.isAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an Image message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.Image
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.Image} Image
         */
        Image.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.Image)
                return object;
            var message = new $root.resource.Image();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".resource.Image.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".resource.Image.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.servingUrl != null) {
                if (typeof object.servingUrl !== "object")
                    throw TypeError(".resource.Image.servingUrl: object expected");
                message.servingUrl = $root.google.protobuf.StringValue.fromObject(object.servingUrl);
            }
            switch (object.format) {
            case "IMAGE_FORMAT_UNSPECIFIED":
            case 0:
                message.format = 0;
                break;
            case "IMAGE_FORMAT_PNG":
            case 1:
                message.format = 1;
                break;
            case "IMAGE_FORMAT_JPEG":
            case 2:
                message.format = 2;
                break;
            case "IMAGE_FORMAT_GIF":
            case 3:
                message.format = 3;
                break;
            case "IMAGE_FORMAT_WEBP":
            case 4:
                message.format = 4;
                break;
            }
            if (object.width != null) {
                if (typeof object.width !== "object")
                    throw TypeError(".resource.Image.width: object expected");
                message.width = $root.google.protobuf.Int32Value.fromObject(object.width);
            }
            if (object.height != null) {
                if (typeof object.height !== "object")
                    throw TypeError(".resource.Image.height: object expected");
                message.height = $root.google.protobuf.Int32Value.fromObject(object.height);
            }
            switch (object.category) {
            case "IMAGE_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "IMAGE_CATEGORY_USER_PROFILE":
            case 1:
                message.category = 1;
                break;
            case "IMAGE_CATEGORY_CHARACTER_ICON":
            case 2:
                message.category = 2;
                break;
            case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
            case 3:
                message.category = 3;
                break;
            case "IMAGE_CATEGORY_CHAT_BACKGROUND":
            case 4:
                message.category = 4;
                break;
            case "IMAGE_CATEGORY_TALK_BACKGROUND":
            case 5:
                message.category = 5;
                break;
            case "IMAGE_CATEGORY_CONTENT":
            case 6:
                message.category = 6;
                break;
            case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
            case 7:
                message.category = 7;
                break;
            case "IMAGE_CATEGORY_STAMP":
            case 8:
                message.category = 8;
                break;
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".resource.Image.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.isAvailable != null) {
                if (typeof object.isAvailable !== "object")
                    throw TypeError(".resource.Image.isAvailable: object expected");
                message.isAvailable = $root.google.protobuf.BoolValue.fromObject(object.isAvailable);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".resource.Image.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an Image message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.Image
         * @static
         * @param {resource.Image} message Image
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Image.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.userId = null;
                object.servingUrl = null;
                object.format = options.enums === String ? "IMAGE_FORMAT_UNSPECIFIED" : 0;
                object.width = null;
                object.height = null;
                object.category = options.enums === String ? "IMAGE_CATEGORY_UNSPECIFIED" : 0;
                object.createdAt = null;
                object.isAvailable = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.servingUrl != null && message.hasOwnProperty("servingUrl"))
                object.servingUrl = $root.google.protobuf.StringValue.toObject(message.servingUrl, options);
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = options.enums === String ? $root.resource.ImageFormat[message.format] : message.format;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = $root.google.protobuf.Int32Value.toObject(message.width, options);
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = $root.google.protobuf.Int32Value.toObject(message.height, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.resource.ImageCategory[message.category] : message.category;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.isAvailable != null && message.hasOwnProperty("isAvailable"))
                object.isAvailable = $root.google.protobuf.BoolValue.toObject(message.isAvailable, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this Image to JSON.
         * @function toJSON
         * @memberof resource.Image
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Image.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Image
         * @function getTypeUrl
         * @memberof resource.Image
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Image.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.Image";
        };

        return Image;
    })();

    /**
     * ImageCategory enum.
     * @name resource.ImageCategory
     * @enum {number}
     * @property {number} IMAGE_CATEGORY_UNSPECIFIED=0 IMAGE_CATEGORY_UNSPECIFIED value
     * @property {number} IMAGE_CATEGORY_USER_PROFILE=1 IMAGE_CATEGORY_USER_PROFILE value
     * @property {number} IMAGE_CATEGORY_CHARACTER_ICON=2 IMAGE_CATEGORY_CHARACTER_ICON value
     * @property {number} IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON=3 IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON value
     * @property {number} IMAGE_CATEGORY_CHAT_BACKGROUND=4 IMAGE_CATEGORY_CHAT_BACKGROUND value
     * @property {number} IMAGE_CATEGORY_TALK_BACKGROUND=5 IMAGE_CATEGORY_TALK_BACKGROUND value
     * @property {number} IMAGE_CATEGORY_CONTENT=6 IMAGE_CATEGORY_CONTENT value
     * @property {number} IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL=7 IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL value
     * @property {number} IMAGE_CATEGORY_STAMP=8 IMAGE_CATEGORY_STAMP value
     */
    resource.ImageCategory = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "IMAGE_CATEGORY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "IMAGE_CATEGORY_USER_PROFILE"] = 1;
        values[valuesById[2] = "IMAGE_CATEGORY_CHARACTER_ICON"] = 2;
        values[valuesById[3] = "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON"] = 3;
        values[valuesById[4] = "IMAGE_CATEGORY_CHAT_BACKGROUND"] = 4;
        values[valuesById[5] = "IMAGE_CATEGORY_TALK_BACKGROUND"] = 5;
        values[valuesById[6] = "IMAGE_CATEGORY_CONTENT"] = 6;
        values[valuesById[7] = "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL"] = 7;
        values[valuesById[8] = "IMAGE_CATEGORY_STAMP"] = 8;
        return values;
    })();

    resource.PresetImage = (function() {

        /**
         * Properties of a PresetImage.
         * @memberof resource
         * @interface IPresetImage
         * @property {google.protobuf.IStringValue|null} [name] PresetImage name
         * @property {resource.IImage|null} [thumbnail] PresetImage thumbnail
         * @property {resource.IImage|null} [image] PresetImage image
         * @property {google.protobuf.IBoolValue|null} [isAvailable] PresetImage isAvailable
         */

        /**
         * Constructs a new PresetImage.
         * @memberof resource
         * @classdesc Represents a PresetImage.
         * @implements IPresetImage
         * @constructor
         * @param {resource.IPresetImage=} [properties] Properties to set
         */
        function PresetImage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresetImage name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof resource.PresetImage
         * @instance
         */
        PresetImage.prototype.name = null;

        /**
         * PresetImage thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof resource.PresetImage
         * @instance
         */
        PresetImage.prototype.thumbnail = null;

        /**
         * PresetImage image.
         * @member {resource.IImage|null|undefined} image
         * @memberof resource.PresetImage
         * @instance
         */
        PresetImage.prototype.image = null;

        /**
         * PresetImage isAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} isAvailable
         * @memberof resource.PresetImage
         * @instance
         */
        PresetImage.prototype.isAvailable = null;

        /**
         * Creates a new PresetImage instance using the specified properties.
         * @function create
         * @memberof resource.PresetImage
         * @static
         * @param {resource.IPresetImage=} [properties] Properties to set
         * @returns {resource.PresetImage} PresetImage instance
         */
        PresetImage.create = function create(properties) {
            return new PresetImage(properties);
        };

        /**
         * Encodes the specified PresetImage message. Does not implicitly {@link resource.PresetImage.verify|verify} messages.
         * @function encode
         * @memberof resource.PresetImage
         * @static
         * @param {resource.IPresetImage} message PresetImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresetImage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                $root.resource.Image.encode(message.image, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.isAvailable != null && Object.hasOwnProperty.call(message, "isAvailable"))
                $root.google.protobuf.BoolValue.encode(message.isAvailable, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PresetImage message from the specified reader or buffer.
         * @function decode
         * @memberof resource.PresetImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.PresetImage} PresetImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresetImage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.PresetImage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.image = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.isAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PresetImage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.PresetImage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.PresetImage} PresetImage
         */
        PresetImage.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.PresetImage)
                return object;
            var message = new $root.resource.PresetImage();
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".resource.PresetImage.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".resource.PresetImage.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.image != null) {
                if (typeof object.image !== "object")
                    throw TypeError(".resource.PresetImage.image: object expected");
                message.image = $root.resource.Image.fromObject(object.image);
            }
            if (object.isAvailable != null) {
                if (typeof object.isAvailable !== "object")
                    throw TypeError(".resource.PresetImage.isAvailable: object expected");
                message.isAvailable = $root.google.protobuf.BoolValue.fromObject(object.isAvailable);
            }
            return message;
        };

        /**
         * Creates a plain object from a PresetImage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.PresetImage
         * @static
         * @param {resource.PresetImage} message PresetImage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresetImage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = null;
                object.thumbnail = null;
                object.image = null;
                object.isAvailable = null;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.image != null && message.hasOwnProperty("image"))
                object.image = $root.resource.Image.toObject(message.image, options);
            if (message.isAvailable != null && message.hasOwnProperty("isAvailable"))
                object.isAvailable = $root.google.protobuf.BoolValue.toObject(message.isAvailable, options);
            return object;
        };

        /**
         * Converts this PresetImage to JSON.
         * @function toJSON
         * @memberof resource.PresetImage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresetImage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresetImage
         * @function getTypeUrl
         * @memberof resource.PresetImage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresetImage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.PresetImage";
        };

        return PresetImage;
    })();

    resource.PresetImageList = (function() {

        /**
         * Properties of a PresetImageList.
         * @memberof resource
         * @interface IPresetImageList
         * @property {Array.<resource.IPresetImage>|null} [presetImageList] PresetImageList presetImageList
         */

        /**
         * Constructs a new PresetImageList.
         * @memberof resource
         * @classdesc Represents a PresetImageList.
         * @implements IPresetImageList
         * @constructor
         * @param {resource.IPresetImageList=} [properties] Properties to set
         */
        function PresetImageList(properties) {
            this.presetImageList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresetImageList presetImageList.
         * @member {Array.<resource.IPresetImage>} presetImageList
         * @memberof resource.PresetImageList
         * @instance
         */
        PresetImageList.prototype.presetImageList = $util.emptyArray;

        /**
         * Creates a new PresetImageList instance using the specified properties.
         * @function create
         * @memberof resource.PresetImageList
         * @static
         * @param {resource.IPresetImageList=} [properties] Properties to set
         * @returns {resource.PresetImageList} PresetImageList instance
         */
        PresetImageList.create = function create(properties) {
            return new PresetImageList(properties);
        };

        /**
         * Encodes the specified PresetImageList message. Does not implicitly {@link resource.PresetImageList.verify|verify} messages.
         * @function encode
         * @memberof resource.PresetImageList
         * @static
         * @param {resource.IPresetImageList} message PresetImageList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresetImageList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.presetImageList != null && message.presetImageList.length)
                for (var i = 0; i < message.presetImageList.length; ++i)
                    $root.resource.PresetImage.encode(message.presetImageList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PresetImageList message from the specified reader or buffer.
         * @function decode
         * @memberof resource.PresetImageList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.PresetImageList} PresetImageList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresetImageList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.PresetImageList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.presetImageList && message.presetImageList.length))
                        message.presetImageList = [];
                    message.presetImageList.push($root.resource.PresetImage.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PresetImageList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.PresetImageList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.PresetImageList} PresetImageList
         */
        PresetImageList.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.PresetImageList)
                return object;
            var message = new $root.resource.PresetImageList();
            if (object.presetImageList) {
                if (!Array.isArray(object.presetImageList))
                    throw TypeError(".resource.PresetImageList.presetImageList: array expected");
                message.presetImageList = [];
                for (var i = 0; i < object.presetImageList.length; ++i) {
                    if (typeof object.presetImageList[i] !== "object")
                        throw TypeError(".resource.PresetImageList.presetImageList: object expected");
                    message.presetImageList[i] = $root.resource.PresetImage.fromObject(object.presetImageList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PresetImageList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.PresetImageList
         * @static
         * @param {resource.PresetImageList} message PresetImageList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresetImageList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.presetImageList = [];
            if (message.presetImageList && message.presetImageList.length) {
                object.presetImageList = [];
                for (var j = 0; j < message.presetImageList.length; ++j)
                    object.presetImageList[j] = $root.resource.PresetImage.toObject(message.presetImageList[j], options);
            }
            return object;
        };

        /**
         * Converts this PresetImageList to JSON.
         * @function toJSON
         * @memberof resource.PresetImageList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresetImageList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresetImageList
         * @function getTypeUrl
         * @memberof resource.PresetImageList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresetImageList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.PresetImageList";
        };

        return PresetImageList;
    })();

    resource.PresetImageSubcategory = (function() {

        /**
         * Properties of a PresetImageSubcategory.
         * @memberof resource
         * @interface IPresetImageSubcategory
         * @property {google.protobuf.IStringValue|null} [name] PresetImageSubcategory name
         * @property {resource.IImage|null} [thumbnail] PresetImageSubcategory thumbnail
         * @property {Array.<resource.IPresetImage>|null} [presetImageList] PresetImageSubcategory presetImageList
         */

        /**
         * Constructs a new PresetImageSubcategory.
         * @memberof resource
         * @classdesc Represents a PresetImageSubcategory.
         * @implements IPresetImageSubcategory
         * @constructor
         * @param {resource.IPresetImageSubcategory=} [properties] Properties to set
         */
        function PresetImageSubcategory(properties) {
            this.presetImageList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresetImageSubcategory name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof resource.PresetImageSubcategory
         * @instance
         */
        PresetImageSubcategory.prototype.name = null;

        /**
         * PresetImageSubcategory thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof resource.PresetImageSubcategory
         * @instance
         */
        PresetImageSubcategory.prototype.thumbnail = null;

        /**
         * PresetImageSubcategory presetImageList.
         * @member {Array.<resource.IPresetImage>} presetImageList
         * @memberof resource.PresetImageSubcategory
         * @instance
         */
        PresetImageSubcategory.prototype.presetImageList = $util.emptyArray;

        /**
         * Creates a new PresetImageSubcategory instance using the specified properties.
         * @function create
         * @memberof resource.PresetImageSubcategory
         * @static
         * @param {resource.IPresetImageSubcategory=} [properties] Properties to set
         * @returns {resource.PresetImageSubcategory} PresetImageSubcategory instance
         */
        PresetImageSubcategory.create = function create(properties) {
            return new PresetImageSubcategory(properties);
        };

        /**
         * Encodes the specified PresetImageSubcategory message. Does not implicitly {@link resource.PresetImageSubcategory.verify|verify} messages.
         * @function encode
         * @memberof resource.PresetImageSubcategory
         * @static
         * @param {resource.IPresetImageSubcategory} message PresetImageSubcategory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresetImageSubcategory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.presetImageList != null && message.presetImageList.length)
                for (var i = 0; i < message.presetImageList.length; ++i)
                    $root.resource.PresetImage.encode(message.presetImageList[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PresetImageSubcategory message from the specified reader or buffer.
         * @function decode
         * @memberof resource.PresetImageSubcategory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.PresetImageSubcategory} PresetImageSubcategory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresetImageSubcategory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.PresetImageSubcategory();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.presetImageList && message.presetImageList.length))
                        message.presetImageList = [];
                    message.presetImageList.push($root.resource.PresetImage.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PresetImageSubcategory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.PresetImageSubcategory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.PresetImageSubcategory} PresetImageSubcategory
         */
        PresetImageSubcategory.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.PresetImageSubcategory)
                return object;
            var message = new $root.resource.PresetImageSubcategory();
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".resource.PresetImageSubcategory.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".resource.PresetImageSubcategory.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.presetImageList) {
                if (!Array.isArray(object.presetImageList))
                    throw TypeError(".resource.PresetImageSubcategory.presetImageList: array expected");
                message.presetImageList = [];
                for (var i = 0; i < object.presetImageList.length; ++i) {
                    if (typeof object.presetImageList[i] !== "object")
                        throw TypeError(".resource.PresetImageSubcategory.presetImageList: object expected");
                    message.presetImageList[i] = $root.resource.PresetImage.fromObject(object.presetImageList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PresetImageSubcategory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.PresetImageSubcategory
         * @static
         * @param {resource.PresetImageSubcategory} message PresetImageSubcategory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresetImageSubcategory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.presetImageList = [];
            if (options.defaults) {
                object.name = null;
                object.thumbnail = null;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.presetImageList && message.presetImageList.length) {
                object.presetImageList = [];
                for (var j = 0; j < message.presetImageList.length; ++j)
                    object.presetImageList[j] = $root.resource.PresetImage.toObject(message.presetImageList[j], options);
            }
            return object;
        };

        /**
         * Converts this PresetImageSubcategory to JSON.
         * @function toJSON
         * @memberof resource.PresetImageSubcategory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresetImageSubcategory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresetImageSubcategory
         * @function getTypeUrl
         * @memberof resource.PresetImageSubcategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresetImageSubcategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.PresetImageSubcategory";
        };

        return PresetImageSubcategory;
    })();

    resource.PresetImageSubcategoryList = (function() {

        /**
         * Properties of a PresetImageSubcategoryList.
         * @memberof resource
         * @interface IPresetImageSubcategoryList
         * @property {Array.<resource.IPresetImageSubcategory>|null} [subcategoryList] PresetImageSubcategoryList subcategoryList
         */

        /**
         * Constructs a new PresetImageSubcategoryList.
         * @memberof resource
         * @classdesc Represents a PresetImageSubcategoryList.
         * @implements IPresetImageSubcategoryList
         * @constructor
         * @param {resource.IPresetImageSubcategoryList=} [properties] Properties to set
         */
        function PresetImageSubcategoryList(properties) {
            this.subcategoryList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresetImageSubcategoryList subcategoryList.
         * @member {Array.<resource.IPresetImageSubcategory>} subcategoryList
         * @memberof resource.PresetImageSubcategoryList
         * @instance
         */
        PresetImageSubcategoryList.prototype.subcategoryList = $util.emptyArray;

        /**
         * Creates a new PresetImageSubcategoryList instance using the specified properties.
         * @function create
         * @memberof resource.PresetImageSubcategoryList
         * @static
         * @param {resource.IPresetImageSubcategoryList=} [properties] Properties to set
         * @returns {resource.PresetImageSubcategoryList} PresetImageSubcategoryList instance
         */
        PresetImageSubcategoryList.create = function create(properties) {
            return new PresetImageSubcategoryList(properties);
        };

        /**
         * Encodes the specified PresetImageSubcategoryList message. Does not implicitly {@link resource.PresetImageSubcategoryList.verify|verify} messages.
         * @function encode
         * @memberof resource.PresetImageSubcategoryList
         * @static
         * @param {resource.IPresetImageSubcategoryList} message PresetImageSubcategoryList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresetImageSubcategoryList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.subcategoryList != null && message.subcategoryList.length)
                for (var i = 0; i < message.subcategoryList.length; ++i)
                    $root.resource.PresetImageSubcategory.encode(message.subcategoryList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PresetImageSubcategoryList message from the specified reader or buffer.
         * @function decode
         * @memberof resource.PresetImageSubcategoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.PresetImageSubcategoryList} PresetImageSubcategoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresetImageSubcategoryList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.PresetImageSubcategoryList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.subcategoryList && message.subcategoryList.length))
                        message.subcategoryList = [];
                    message.subcategoryList.push($root.resource.PresetImageSubcategory.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PresetImageSubcategoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.PresetImageSubcategoryList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.PresetImageSubcategoryList} PresetImageSubcategoryList
         */
        PresetImageSubcategoryList.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.PresetImageSubcategoryList)
                return object;
            var message = new $root.resource.PresetImageSubcategoryList();
            if (object.subcategoryList) {
                if (!Array.isArray(object.subcategoryList))
                    throw TypeError(".resource.PresetImageSubcategoryList.subcategoryList: array expected");
                message.subcategoryList = [];
                for (var i = 0; i < object.subcategoryList.length; ++i) {
                    if (typeof object.subcategoryList[i] !== "object")
                        throw TypeError(".resource.PresetImageSubcategoryList.subcategoryList: object expected");
                    message.subcategoryList[i] = $root.resource.PresetImageSubcategory.fromObject(object.subcategoryList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PresetImageSubcategoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.PresetImageSubcategoryList
         * @static
         * @param {resource.PresetImageSubcategoryList} message PresetImageSubcategoryList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresetImageSubcategoryList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.subcategoryList = [];
            if (message.subcategoryList && message.subcategoryList.length) {
                object.subcategoryList = [];
                for (var j = 0; j < message.subcategoryList.length; ++j)
                    object.subcategoryList[j] = $root.resource.PresetImageSubcategory.toObject(message.subcategoryList[j], options);
            }
            return object;
        };

        /**
         * Converts this PresetImageSubcategoryList to JSON.
         * @function toJSON
         * @memberof resource.PresetImageSubcategoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresetImageSubcategoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresetImageSubcategoryList
         * @function getTypeUrl
         * @memberof resource.PresetImageSubcategoryList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresetImageSubcategoryList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.PresetImageSubcategoryList";
        };

        return PresetImageSubcategoryList;
    })();

    resource.ResourceImageList = (function() {

        /**
         * Properties of a ResourceImageList.
         * @memberof resource
         * @interface IResourceImageList
         * @property {Array.<resource.IImage>|null} [imageList] ResourceImageList imageList
         */

        /**
         * Constructs a new ResourceImageList.
         * @memberof resource
         * @classdesc Represents a ResourceImageList.
         * @implements IResourceImageList
         * @constructor
         * @param {resource.IResourceImageList=} [properties] Properties to set
         */
        function ResourceImageList(properties) {
            this.imageList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceImageList imageList.
         * @member {Array.<resource.IImage>} imageList
         * @memberof resource.ResourceImageList
         * @instance
         */
        ResourceImageList.prototype.imageList = $util.emptyArray;

        /**
         * Creates a new ResourceImageList instance using the specified properties.
         * @function create
         * @memberof resource.ResourceImageList
         * @static
         * @param {resource.IResourceImageList=} [properties] Properties to set
         * @returns {resource.ResourceImageList} ResourceImageList instance
         */
        ResourceImageList.create = function create(properties) {
            return new ResourceImageList(properties);
        };

        /**
         * Encodes the specified ResourceImageList message. Does not implicitly {@link resource.ResourceImageList.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceImageList
         * @static
         * @param {resource.IResourceImageList} message ResourceImageList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceImageList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageList != null && message.imageList.length)
                for (var i = 0; i < message.imageList.length; ++i)
                    $root.resource.Image.encode(message.imageList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ResourceImageList message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceImageList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceImageList} ResourceImageList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceImageList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceImageList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.imageList && message.imageList.length))
                        message.imageList = [];
                    message.imageList.push($root.resource.Image.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceImageList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceImageList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceImageList} ResourceImageList
         */
        ResourceImageList.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceImageList)
                return object;
            var message = new $root.resource.ResourceImageList();
            if (object.imageList) {
                if (!Array.isArray(object.imageList))
                    throw TypeError(".resource.ResourceImageList.imageList: array expected");
                message.imageList = [];
                for (var i = 0; i < object.imageList.length; ++i) {
                    if (typeof object.imageList[i] !== "object")
                        throw TypeError(".resource.ResourceImageList.imageList: object expected");
                    message.imageList[i] = $root.resource.Image.fromObject(object.imageList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceImageList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceImageList
         * @static
         * @param {resource.ResourceImageList} message ResourceImageList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceImageList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.imageList = [];
            if (message.imageList && message.imageList.length) {
                object.imageList = [];
                for (var j = 0; j < message.imageList.length; ++j)
                    object.imageList[j] = $root.resource.Image.toObject(message.imageList[j], options);
            }
            return object;
        };

        /**
         * Converts this ResourceImageList to JSON.
         * @function toJSON
         * @memberof resource.ResourceImageList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceImageList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceImageList
         * @function getTypeUrl
         * @memberof resource.ResourceImageList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceImageList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceImageList";
        };

        return ResourceImageList;
    })();

    /**
     * AudioCodec enum.
     * @name resource.AudioCodec
     * @enum {number}
     * @property {number} AUDIO_CODEC_UNSPECIFIED=0 AUDIO_CODEC_UNSPECIFIED value
     * @property {number} AUDIO_CODEC_AAC=1 AUDIO_CODEC_AAC value
     * @property {number} AUDIO_CODEC_MP3=2 AUDIO_CODEC_MP3 value
     * @property {number} AUDIO_CODEC_VORBIS=3 AUDIO_CODEC_VORBIS value
     */
    resource.AudioCodec = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUDIO_CODEC_UNSPECIFIED"] = 0;
        values[valuesById[1] = "AUDIO_CODEC_AAC"] = 1;
        values[valuesById[2] = "AUDIO_CODEC_MP3"] = 2;
        values[valuesById[3] = "AUDIO_CODEC_VORBIS"] = 3;
        return values;
    })();

    resource.Audio = (function() {

        /**
         * Properties of an Audio.
         * @memberof resource
         * @interface IAudio
         * @property {google.protobuf.IStringValue|null} [id] Audio id
         * @property {google.protobuf.IStringValue|null} [userId] Audio userId
         * @property {google.protobuf.IStringValue|null} [servingUrl] Audio servingUrl
         * @property {google.protobuf.IDuration|null} [duration] Audio duration
         * @property {resource.AudioCodec|null} [codec] Audio codec
         * @property {google.protobuf.ITimestamp|null} [createdAt] Audio createdAt
         */

        /**
         * Constructs a new Audio.
         * @memberof resource
         * @classdesc Represents an Audio.
         * @implements IAudio
         * @constructor
         * @param {resource.IAudio=} [properties] Properties to set
         */
        function Audio(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Audio id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof resource.Audio
         * @instance
         */
        Audio.prototype.id = null;

        /**
         * Audio userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof resource.Audio
         * @instance
         */
        Audio.prototype.userId = null;

        /**
         * Audio servingUrl.
         * @member {google.protobuf.IStringValue|null|undefined} servingUrl
         * @memberof resource.Audio
         * @instance
         */
        Audio.prototype.servingUrl = null;

        /**
         * Audio duration.
         * @member {google.protobuf.IDuration|null|undefined} duration
         * @memberof resource.Audio
         * @instance
         */
        Audio.prototype.duration = null;

        /**
         * Audio codec.
         * @member {resource.AudioCodec} codec
         * @memberof resource.Audio
         * @instance
         */
        Audio.prototype.codec = 0;

        /**
         * Audio createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof resource.Audio
         * @instance
         */
        Audio.prototype.createdAt = null;

        /**
         * Creates a new Audio instance using the specified properties.
         * @function create
         * @memberof resource.Audio
         * @static
         * @param {resource.IAudio=} [properties] Properties to set
         * @returns {resource.Audio} Audio instance
         */
        Audio.create = function create(properties) {
            return new Audio(properties);
        };

        /**
         * Encodes the specified Audio message. Does not implicitly {@link resource.Audio.verify|verify} messages.
         * @function encode
         * @memberof resource.Audio
         * @static
         * @param {resource.IAudio} message Audio message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Audio.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.servingUrl != null && Object.hasOwnProperty.call(message, "servingUrl"))
                $root.google.protobuf.StringValue.encode(message.servingUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.codec != null && Object.hasOwnProperty.call(message, "codec"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.codec);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Audio message from the specified reader or buffer.
         * @function decode
         * @memberof resource.Audio
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.Audio} Audio
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Audio.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.Audio();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.servingUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.codec = reader.int32();
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an Audio message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.Audio
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.Audio} Audio
         */
        Audio.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.Audio)
                return object;
            var message = new $root.resource.Audio();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".resource.Audio.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".resource.Audio.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.servingUrl != null) {
                if (typeof object.servingUrl !== "object")
                    throw TypeError(".resource.Audio.servingUrl: object expected");
                message.servingUrl = $root.google.protobuf.StringValue.fromObject(object.servingUrl);
            }
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".resource.Audio.duration: object expected");
                message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
            }
            switch (object.codec) {
            case "AUDIO_CODEC_UNSPECIFIED":
            case 0:
                message.codec = 0;
                break;
            case "AUDIO_CODEC_AAC":
            case 1:
                message.codec = 1;
                break;
            case "AUDIO_CODEC_MP3":
            case 2:
                message.codec = 2;
                break;
            case "AUDIO_CODEC_VORBIS":
            case 3:
                message.codec = 3;
                break;
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".resource.Audio.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an Audio message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.Audio
         * @static
         * @param {resource.Audio} message Audio
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Audio.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.userId = null;
                object.servingUrl = null;
                object.duration = null;
                object.codec = options.enums === String ? "AUDIO_CODEC_UNSPECIFIED" : 0;
                object.createdAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.servingUrl != null && message.hasOwnProperty("servingUrl"))
                object.servingUrl = $root.google.protobuf.StringValue.toObject(message.servingUrl, options);
            if (message.duration != null && message.hasOwnProperty("duration"))
                object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
            if (message.codec != null && message.hasOwnProperty("codec"))
                object.codec = options.enums === String ? $root.resource.AudioCodec[message.codec] : message.codec;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            return object;
        };

        /**
         * Converts this Audio to JSON.
         * @function toJSON
         * @memberof resource.Audio
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Audio.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Audio
         * @function getTypeUrl
         * @memberof resource.Audio
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Audio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.Audio";
        };

        return Audio;
    })();

    resource.ResourceAudioList = (function() {

        /**
         * Properties of a ResourceAudioList.
         * @memberof resource
         * @interface IResourceAudioList
         * @property {Array.<resource.IAudio>|null} [audioList] ResourceAudioList audioList
         */

        /**
         * Constructs a new ResourceAudioList.
         * @memberof resource
         * @classdesc Represents a ResourceAudioList.
         * @implements IResourceAudioList
         * @constructor
         * @param {resource.IResourceAudioList=} [properties] Properties to set
         */
        function ResourceAudioList(properties) {
            this.audioList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceAudioList audioList.
         * @member {Array.<resource.IAudio>} audioList
         * @memberof resource.ResourceAudioList
         * @instance
         */
        ResourceAudioList.prototype.audioList = $util.emptyArray;

        /**
         * Creates a new ResourceAudioList instance using the specified properties.
         * @function create
         * @memberof resource.ResourceAudioList
         * @static
         * @param {resource.IResourceAudioList=} [properties] Properties to set
         * @returns {resource.ResourceAudioList} ResourceAudioList instance
         */
        ResourceAudioList.create = function create(properties) {
            return new ResourceAudioList(properties);
        };

        /**
         * Encodes the specified ResourceAudioList message. Does not implicitly {@link resource.ResourceAudioList.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceAudioList
         * @static
         * @param {resource.IResourceAudioList} message ResourceAudioList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceAudioList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.audioList != null && message.audioList.length)
                for (var i = 0; i < message.audioList.length; ++i)
                    $root.resource.Audio.encode(message.audioList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ResourceAudioList message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceAudioList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceAudioList} ResourceAudioList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceAudioList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceAudioList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.audioList && message.audioList.length))
                        message.audioList = [];
                    message.audioList.push($root.resource.Audio.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceAudioList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceAudioList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceAudioList} ResourceAudioList
         */
        ResourceAudioList.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceAudioList)
                return object;
            var message = new $root.resource.ResourceAudioList();
            if (object.audioList) {
                if (!Array.isArray(object.audioList))
                    throw TypeError(".resource.ResourceAudioList.audioList: array expected");
                message.audioList = [];
                for (var i = 0; i < object.audioList.length; ++i) {
                    if (typeof object.audioList[i] !== "object")
                        throw TypeError(".resource.ResourceAudioList.audioList: object expected");
                    message.audioList[i] = $root.resource.Audio.fromObject(object.audioList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceAudioList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceAudioList
         * @static
         * @param {resource.ResourceAudioList} message ResourceAudioList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceAudioList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.audioList = [];
            if (message.audioList && message.audioList.length) {
                object.audioList = [];
                for (var j = 0; j < message.audioList.length; ++j)
                    object.audioList[j] = $root.resource.Audio.toObject(message.audioList[j], options);
            }
            return object;
        };

        /**
         * Converts this ResourceAudioList to JSON.
         * @function toJSON
         * @memberof resource.ResourceAudioList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceAudioList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceAudioList
         * @function getTypeUrl
         * @memberof resource.ResourceAudioList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceAudioList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceAudioList";
        };

        return ResourceAudioList;
    })();

    resource.Resources = (function() {

        /**
         * Properties of a Resources.
         * @memberof resource
         * @interface IResources
         * @property {Array.<resource.IImage>|null} [imageList] Resources imageList
         * @property {Array.<resource.IAudio>|null} [audioList] Resources audioList
         */

        /**
         * Constructs a new Resources.
         * @memberof resource
         * @classdesc Represents a Resources.
         * @implements IResources
         * @constructor
         * @param {resource.IResources=} [properties] Properties to set
         */
        function Resources(properties) {
            this.imageList = [];
            this.audioList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Resources imageList.
         * @member {Array.<resource.IImage>} imageList
         * @memberof resource.Resources
         * @instance
         */
        Resources.prototype.imageList = $util.emptyArray;

        /**
         * Resources audioList.
         * @member {Array.<resource.IAudio>} audioList
         * @memberof resource.Resources
         * @instance
         */
        Resources.prototype.audioList = $util.emptyArray;

        /**
         * Creates a new Resources instance using the specified properties.
         * @function create
         * @memberof resource.Resources
         * @static
         * @param {resource.IResources=} [properties] Properties to set
         * @returns {resource.Resources} Resources instance
         */
        Resources.create = function create(properties) {
            return new Resources(properties);
        };

        /**
         * Encodes the specified Resources message. Does not implicitly {@link resource.Resources.verify|verify} messages.
         * @function encode
         * @memberof resource.Resources
         * @static
         * @param {resource.IResources} message Resources message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Resources.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageList != null && message.imageList.length)
                for (var i = 0; i < message.imageList.length; ++i)
                    $root.resource.Image.encode(message.imageList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.audioList != null && message.audioList.length)
                for (var i = 0; i < message.audioList.length; ++i)
                    $root.resource.Audio.encode(message.audioList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Resources message from the specified reader or buffer.
         * @function decode
         * @memberof resource.Resources
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.Resources} Resources
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Resources.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.Resources();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.imageList && message.imageList.length))
                        message.imageList = [];
                    message.imageList.push($root.resource.Image.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.audioList && message.audioList.length))
                        message.audioList = [];
                    message.audioList.push($root.resource.Audio.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Resources message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.Resources
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.Resources} Resources
         */
        Resources.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.Resources)
                return object;
            var message = new $root.resource.Resources();
            if (object.imageList) {
                if (!Array.isArray(object.imageList))
                    throw TypeError(".resource.Resources.imageList: array expected");
                message.imageList = [];
                for (var i = 0; i < object.imageList.length; ++i) {
                    if (typeof object.imageList[i] !== "object")
                        throw TypeError(".resource.Resources.imageList: object expected");
                    message.imageList[i] = $root.resource.Image.fromObject(object.imageList[i]);
                }
            }
            if (object.audioList) {
                if (!Array.isArray(object.audioList))
                    throw TypeError(".resource.Resources.audioList: array expected");
                message.audioList = [];
                for (var i = 0; i < object.audioList.length; ++i) {
                    if (typeof object.audioList[i] !== "object")
                        throw TypeError(".resource.Resources.audioList: object expected");
                    message.audioList[i] = $root.resource.Audio.fromObject(object.audioList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Resources message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.Resources
         * @static
         * @param {resource.Resources} message Resources
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Resources.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.imageList = [];
                object.audioList = [];
            }
            if (message.imageList && message.imageList.length) {
                object.imageList = [];
                for (var j = 0; j < message.imageList.length; ++j)
                    object.imageList[j] = $root.resource.Image.toObject(message.imageList[j], options);
            }
            if (message.audioList && message.audioList.length) {
                object.audioList = [];
                for (var j = 0; j < message.audioList.length; ++j)
                    object.audioList[j] = $root.resource.Audio.toObject(message.audioList[j], options);
            }
            return object;
        };

        /**
         * Converts this Resources to JSON.
         * @function toJSON
         * @memberof resource.Resources
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Resources.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Resources
         * @function getTypeUrl
         * @memberof resource.Resources
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Resources.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.Resources";
        };

        return Resources;
    })();

    resource.ResourceService = (function() {

        /**
         * Constructs a new ResourceService service.
         * @memberof resource
         * @classdesc Represents a ResourceService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ResourceService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ResourceService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ResourceService;

        /**
         * Creates new ResourceService service using the specified rpc implementation.
         * @function create
         * @memberof resource.ResourceService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {ResourceService} RPC service. Useful where requests and/or responses are streamed.
         */
        ResourceService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link resource.ResourceService#createImage}.
         * @memberof resource.ResourceService
         * @typedef CreateImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource.Image} [response] Image
         */

        /**
         * Calls CreateImage.
         * @function createImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceCreateImageRequest} request ResourceCreateImageRequest message or plain object
         * @param {resource.ResourceService.CreateImageCallback} callback Node-style callback called with the error, if any, and Image
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.createImage = function createImage(request, callback) {
            return this.rpcCall(createImage, $root.resource.ResourceCreateImageRequest, $root.resource.Image, request, callback);
        }, "name", { value: "CreateImage" });

        /**
         * Calls CreateImage.
         * @function createImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceCreateImageRequest} request ResourceCreateImageRequest message or plain object
         * @returns {Promise<resource.Image>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource.ResourceService#deleteImage}.
         * @memberof resource.ResourceService
         * @typedef DeleteImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteImage.
         * @function deleteImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceDeleteImageRequest} request ResourceDeleteImageRequest message or plain object
         * @param {resource.ResourceService.DeleteImageCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.deleteImage = function deleteImage(request, callback) {
            return this.rpcCall(deleteImage, $root.resource.ResourceDeleteImageRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteImage" });

        /**
         * Calls DeleteImage.
         * @function deleteImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceDeleteImageRequest} request ResourceDeleteImageRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource.ResourceService#getMultiImage}.
         * @memberof resource.ResourceService
         * @typedef GetMultiImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource.ResourceImageList} [response] ResourceImageList
         */

        /**
         * Calls GetMultiImage.
         * @function getMultiImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceGetMultiImageRequest} request ResourceGetMultiImageRequest message or plain object
         * @param {resource.ResourceService.GetMultiImageCallback} callback Node-style callback called with the error, if any, and ResourceImageList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.getMultiImage = function getMultiImage(request, callback) {
            return this.rpcCall(getMultiImage, $root.resource.ResourceGetMultiImageRequest, $root.resource.ResourceImageList, request, callback);
        }, "name", { value: "GetMultiImage" });

        /**
         * Calls GetMultiImage.
         * @function getMultiImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceGetMultiImageRequest} request ResourceGetMultiImageRequest message or plain object
         * @returns {Promise<resource.ResourceImageList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource.ResourceService#listPresetImageV2}.
         * @memberof resource.ResourceService
         * @typedef ListPresetImageV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource.PresetImageSubcategoryList} [response] PresetImageSubcategoryList
         */

        /**
         * Calls ListPresetImageV2.
         * @function listPresetImageV2
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceListPresetImageRequest} request ResourceListPresetImageRequest message or plain object
         * @param {resource.ResourceService.ListPresetImageV2Callback} callback Node-style callback called with the error, if any, and PresetImageSubcategoryList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.listPresetImageV2 = function listPresetImageV2(request, callback) {
            return this.rpcCall(listPresetImageV2, $root.resource.ResourceListPresetImageRequest, $root.resource.PresetImageSubcategoryList, request, callback);
        }, "name", { value: "ListPresetImageV2" });

        /**
         * Calls ListPresetImageV2.
         * @function listPresetImageV2
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceListPresetImageRequest} request ResourceListPresetImageRequest message or plain object
         * @returns {Promise<resource.PresetImageSubcategoryList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource.ResourceService#listPresetImage}.
         * @memberof resource.ResourceService
         * @typedef ListPresetImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource.PresetImageList} [response] PresetImageList
         */

        /**
         * Calls ListPresetImage.
         * @function listPresetImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceListPresetImageRequest} request ResourceListPresetImageRequest message or plain object
         * @param {resource.ResourceService.ListPresetImageCallback} callback Node-style callback called with the error, if any, and PresetImageList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.listPresetImage = function listPresetImage(request, callback) {
            return this.rpcCall(listPresetImage, $root.resource.ResourceListPresetImageRequest, $root.resource.PresetImageList, request, callback);
        }, "name", { value: "ListPresetImage" });

        /**
         * Calls ListPresetImage.
         * @function listPresetImage
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceListPresetImageRequest} request ResourceListPresetImageRequest message or plain object
         * @returns {Promise<resource.PresetImageList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource.ResourceService#createAudio}.
         * @memberof resource.ResourceService
         * @typedef CreateAudioCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource.Audio} [response] Audio
         */

        /**
         * Calls CreateAudio.
         * @function createAudio
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceCreateAudioRequest} request ResourceCreateAudioRequest message or plain object
         * @param {resource.ResourceService.CreateAudioCallback} callback Node-style callback called with the error, if any, and Audio
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.createAudio = function createAudio(request, callback) {
            return this.rpcCall(createAudio, $root.resource.ResourceCreateAudioRequest, $root.resource.Audio, request, callback);
        }, "name", { value: "CreateAudio" });

        /**
         * Calls CreateAudio.
         * @function createAudio
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceCreateAudioRequest} request ResourceCreateAudioRequest message or plain object
         * @returns {Promise<resource.Audio>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource.ResourceService#deleteAudio}.
         * @memberof resource.ResourceService
         * @typedef DeleteAudioCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteAudio.
         * @function deleteAudio
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceDeleteAudioRequest} request ResourceDeleteAudioRequest message or plain object
         * @param {resource.ResourceService.DeleteAudioCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.deleteAudio = function deleteAudio(request, callback) {
            return this.rpcCall(deleteAudio, $root.resource.ResourceDeleteAudioRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteAudio" });

        /**
         * Calls DeleteAudio.
         * @function deleteAudio
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceDeleteAudioRequest} request ResourceDeleteAudioRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource.ResourceService#getMultiAudio}.
         * @memberof resource.ResourceService
         * @typedef GetMultiAudioCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource.ResourceAudioList} [response] ResourceAudioList
         */

        /**
         * Calls GetMultiAudio.
         * @function getMultiAudio
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceGetMultiAudioRequest} request ResourceGetMultiAudioRequest message or plain object
         * @param {resource.ResourceService.GetMultiAudioCallback} callback Node-style callback called with the error, if any, and ResourceAudioList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceService.prototype.getMultiAudio = function getMultiAudio(request, callback) {
            return this.rpcCall(getMultiAudio, $root.resource.ResourceGetMultiAudioRequest, $root.resource.ResourceAudioList, request, callback);
        }, "name", { value: "GetMultiAudio" });

        /**
         * Calls GetMultiAudio.
         * @function getMultiAudio
         * @memberof resource.ResourceService
         * @instance
         * @param {resource.IResourceGetMultiAudioRequest} request ResourceGetMultiAudioRequest message or plain object
         * @returns {Promise<resource.ResourceAudioList>} Promise
         * @variation 2
         */

        return ResourceService;
    })();

    resource.ResourceCreateImageRequest = (function() {

        /**
         * Properties of a ResourceCreateImageRequest.
         * @memberof resource
         * @interface IResourceCreateImageRequest
         * @property {google.protobuf.IStringValue|null} [gsPath] ResourceCreateImageRequest gsPath
         * @property {resource.ImageFormat|null} [format] ResourceCreateImageRequest format
         * @property {google.protobuf.IInt32Value|null} [width] ResourceCreateImageRequest width
         * @property {google.protobuf.IInt32Value|null} [height] ResourceCreateImageRequest height
         * @property {resource.ImageCategory|null} [category] ResourceCreateImageRequest category
         */

        /**
         * Constructs a new ResourceCreateImageRequest.
         * @memberof resource
         * @classdesc Represents a ResourceCreateImageRequest.
         * @implements IResourceCreateImageRequest
         * @constructor
         * @param {resource.IResourceCreateImageRequest=} [properties] Properties to set
         */
        function ResourceCreateImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceCreateImageRequest gsPath.
         * @member {google.protobuf.IStringValue|null|undefined} gsPath
         * @memberof resource.ResourceCreateImageRequest
         * @instance
         */
        ResourceCreateImageRequest.prototype.gsPath = null;

        /**
         * ResourceCreateImageRequest format.
         * @member {resource.ImageFormat} format
         * @memberof resource.ResourceCreateImageRequest
         * @instance
         */
        ResourceCreateImageRequest.prototype.format = 0;

        /**
         * ResourceCreateImageRequest width.
         * @member {google.protobuf.IInt32Value|null|undefined} width
         * @memberof resource.ResourceCreateImageRequest
         * @instance
         */
        ResourceCreateImageRequest.prototype.width = null;

        /**
         * ResourceCreateImageRequest height.
         * @member {google.protobuf.IInt32Value|null|undefined} height
         * @memberof resource.ResourceCreateImageRequest
         * @instance
         */
        ResourceCreateImageRequest.prototype.height = null;

        /**
         * ResourceCreateImageRequest category.
         * @member {resource.ImageCategory} category
         * @memberof resource.ResourceCreateImageRequest
         * @instance
         */
        ResourceCreateImageRequest.prototype.category = 0;

        /**
         * Creates a new ResourceCreateImageRequest instance using the specified properties.
         * @function create
         * @memberof resource.ResourceCreateImageRequest
         * @static
         * @param {resource.IResourceCreateImageRequest=} [properties] Properties to set
         * @returns {resource.ResourceCreateImageRequest} ResourceCreateImageRequest instance
         */
        ResourceCreateImageRequest.create = function create(properties) {
            return new ResourceCreateImageRequest(properties);
        };

        /**
         * Encodes the specified ResourceCreateImageRequest message. Does not implicitly {@link resource.ResourceCreateImageRequest.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceCreateImageRequest
         * @static
         * @param {resource.IResourceCreateImageRequest} message ResourceCreateImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceCreateImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gsPath != null && Object.hasOwnProperty.call(message, "gsPath"))
                $root.google.protobuf.StringValue.encode(message.gsPath, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.format);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                $root.google.protobuf.Int32Value.encode(message.width, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                $root.google.protobuf.Int32Value.encode(message.height, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.category);
            return writer;
        };

        /**
         * Decodes a ResourceCreateImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceCreateImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceCreateImageRequest} ResourceCreateImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceCreateImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceCreateImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gsPath = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.format = reader.int32();
                    break;
                case 3:
                    message.width = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.height = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.category = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceCreateImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceCreateImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceCreateImageRequest} ResourceCreateImageRequest
         */
        ResourceCreateImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceCreateImageRequest)
                return object;
            var message = new $root.resource.ResourceCreateImageRequest();
            if (object.gsPath != null) {
                if (typeof object.gsPath !== "object")
                    throw TypeError(".resource.ResourceCreateImageRequest.gsPath: object expected");
                message.gsPath = $root.google.protobuf.StringValue.fromObject(object.gsPath);
            }
            switch (object.format) {
            case "IMAGE_FORMAT_UNSPECIFIED":
            case 0:
                message.format = 0;
                break;
            case "IMAGE_FORMAT_PNG":
            case 1:
                message.format = 1;
                break;
            case "IMAGE_FORMAT_JPEG":
            case 2:
                message.format = 2;
                break;
            case "IMAGE_FORMAT_GIF":
            case 3:
                message.format = 3;
                break;
            case "IMAGE_FORMAT_WEBP":
            case 4:
                message.format = 4;
                break;
            }
            if (object.width != null) {
                if (typeof object.width !== "object")
                    throw TypeError(".resource.ResourceCreateImageRequest.width: object expected");
                message.width = $root.google.protobuf.Int32Value.fromObject(object.width);
            }
            if (object.height != null) {
                if (typeof object.height !== "object")
                    throw TypeError(".resource.ResourceCreateImageRequest.height: object expected");
                message.height = $root.google.protobuf.Int32Value.fromObject(object.height);
            }
            switch (object.category) {
            case "IMAGE_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "IMAGE_CATEGORY_USER_PROFILE":
            case 1:
                message.category = 1;
                break;
            case "IMAGE_CATEGORY_CHARACTER_ICON":
            case 2:
                message.category = 2;
                break;
            case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
            case 3:
                message.category = 3;
                break;
            case "IMAGE_CATEGORY_CHAT_BACKGROUND":
            case 4:
                message.category = 4;
                break;
            case "IMAGE_CATEGORY_TALK_BACKGROUND":
            case 5:
                message.category = 5;
                break;
            case "IMAGE_CATEGORY_CONTENT":
            case 6:
                message.category = 6;
                break;
            case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
            case 7:
                message.category = 7;
                break;
            case "IMAGE_CATEGORY_STAMP":
            case 8:
                message.category = 8;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceCreateImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceCreateImageRequest
         * @static
         * @param {resource.ResourceCreateImageRequest} message ResourceCreateImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceCreateImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gsPath = null;
                object.format = options.enums === String ? "IMAGE_FORMAT_UNSPECIFIED" : 0;
                object.width = null;
                object.height = null;
                object.category = options.enums === String ? "IMAGE_CATEGORY_UNSPECIFIED" : 0;
            }
            if (message.gsPath != null && message.hasOwnProperty("gsPath"))
                object.gsPath = $root.google.protobuf.StringValue.toObject(message.gsPath, options);
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = options.enums === String ? $root.resource.ImageFormat[message.format] : message.format;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = $root.google.protobuf.Int32Value.toObject(message.width, options);
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = $root.google.protobuf.Int32Value.toObject(message.height, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.resource.ImageCategory[message.category] : message.category;
            return object;
        };

        /**
         * Converts this ResourceCreateImageRequest to JSON.
         * @function toJSON
         * @memberof resource.ResourceCreateImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceCreateImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceCreateImageRequest
         * @function getTypeUrl
         * @memberof resource.ResourceCreateImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceCreateImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceCreateImageRequest";
        };

        return ResourceCreateImageRequest;
    })();

    resource.ResourceDeleteImageRequest = (function() {

        /**
         * Properties of a ResourceDeleteImageRequest.
         * @memberof resource
         * @interface IResourceDeleteImageRequest
         * @property {google.protobuf.IStringValue|null} [id] ResourceDeleteImageRequest id
         */

        /**
         * Constructs a new ResourceDeleteImageRequest.
         * @memberof resource
         * @classdesc Represents a ResourceDeleteImageRequest.
         * @implements IResourceDeleteImageRequest
         * @constructor
         * @param {resource.IResourceDeleteImageRequest=} [properties] Properties to set
         */
        function ResourceDeleteImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceDeleteImageRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof resource.ResourceDeleteImageRequest
         * @instance
         */
        ResourceDeleteImageRequest.prototype.id = null;

        /**
         * Creates a new ResourceDeleteImageRequest instance using the specified properties.
         * @function create
         * @memberof resource.ResourceDeleteImageRequest
         * @static
         * @param {resource.IResourceDeleteImageRequest=} [properties] Properties to set
         * @returns {resource.ResourceDeleteImageRequest} ResourceDeleteImageRequest instance
         */
        ResourceDeleteImageRequest.create = function create(properties) {
            return new ResourceDeleteImageRequest(properties);
        };

        /**
         * Encodes the specified ResourceDeleteImageRequest message. Does not implicitly {@link resource.ResourceDeleteImageRequest.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceDeleteImageRequest
         * @static
         * @param {resource.IResourceDeleteImageRequest} message ResourceDeleteImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceDeleteImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ResourceDeleteImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceDeleteImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceDeleteImageRequest} ResourceDeleteImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceDeleteImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceDeleteImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceDeleteImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceDeleteImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceDeleteImageRequest} ResourceDeleteImageRequest
         */
        ResourceDeleteImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceDeleteImageRequest)
                return object;
            var message = new $root.resource.ResourceDeleteImageRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".resource.ResourceDeleteImageRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceDeleteImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceDeleteImageRequest
         * @static
         * @param {resource.ResourceDeleteImageRequest} message ResourceDeleteImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceDeleteImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this ResourceDeleteImageRequest to JSON.
         * @function toJSON
         * @memberof resource.ResourceDeleteImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceDeleteImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceDeleteImageRequest
         * @function getTypeUrl
         * @memberof resource.ResourceDeleteImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceDeleteImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceDeleteImageRequest";
        };

        return ResourceDeleteImageRequest;
    })();

    resource.ResourceGetMultiImageRequest = (function() {

        /**
         * Properties of a ResourceGetMultiImageRequest.
         * @memberof resource
         * @interface IResourceGetMultiImageRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [idList] ResourceGetMultiImageRequest idList
         */

        /**
         * Constructs a new ResourceGetMultiImageRequest.
         * @memberof resource
         * @classdesc Represents a ResourceGetMultiImageRequest.
         * @implements IResourceGetMultiImageRequest
         * @constructor
         * @param {resource.IResourceGetMultiImageRequest=} [properties] Properties to set
         */
        function ResourceGetMultiImageRequest(properties) {
            this.idList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceGetMultiImageRequest idList.
         * @member {Array.<google.protobuf.IStringValue>} idList
         * @memberof resource.ResourceGetMultiImageRequest
         * @instance
         */
        ResourceGetMultiImageRequest.prototype.idList = $util.emptyArray;

        /**
         * Creates a new ResourceGetMultiImageRequest instance using the specified properties.
         * @function create
         * @memberof resource.ResourceGetMultiImageRequest
         * @static
         * @param {resource.IResourceGetMultiImageRequest=} [properties] Properties to set
         * @returns {resource.ResourceGetMultiImageRequest} ResourceGetMultiImageRequest instance
         */
        ResourceGetMultiImageRequest.create = function create(properties) {
            return new ResourceGetMultiImageRequest(properties);
        };

        /**
         * Encodes the specified ResourceGetMultiImageRequest message. Does not implicitly {@link resource.ResourceGetMultiImageRequest.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceGetMultiImageRequest
         * @static
         * @param {resource.IResourceGetMultiImageRequest} message ResourceGetMultiImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceGetMultiImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idList != null && message.idList.length)
                for (var i = 0; i < message.idList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.idList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ResourceGetMultiImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceGetMultiImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceGetMultiImageRequest} ResourceGetMultiImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceGetMultiImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceGetMultiImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.idList && message.idList.length))
                        message.idList = [];
                    message.idList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceGetMultiImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceGetMultiImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceGetMultiImageRequest} ResourceGetMultiImageRequest
         */
        ResourceGetMultiImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceGetMultiImageRequest)
                return object;
            var message = new $root.resource.ResourceGetMultiImageRequest();
            if (object.idList) {
                if (!Array.isArray(object.idList))
                    throw TypeError(".resource.ResourceGetMultiImageRequest.idList: array expected");
                message.idList = [];
                for (var i = 0; i < object.idList.length; ++i) {
                    if (typeof object.idList[i] !== "object")
                        throw TypeError(".resource.ResourceGetMultiImageRequest.idList: object expected");
                    message.idList[i] = $root.google.protobuf.StringValue.fromObject(object.idList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceGetMultiImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceGetMultiImageRequest
         * @static
         * @param {resource.ResourceGetMultiImageRequest} message ResourceGetMultiImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceGetMultiImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.idList = [];
            if (message.idList && message.idList.length) {
                object.idList = [];
                for (var j = 0; j < message.idList.length; ++j)
                    object.idList[j] = $root.google.protobuf.StringValue.toObject(message.idList[j], options);
            }
            return object;
        };

        /**
         * Converts this ResourceGetMultiImageRequest to JSON.
         * @function toJSON
         * @memberof resource.ResourceGetMultiImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceGetMultiImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceGetMultiImageRequest
         * @function getTypeUrl
         * @memberof resource.ResourceGetMultiImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceGetMultiImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceGetMultiImageRequest";
        };

        return ResourceGetMultiImageRequest;
    })();

    resource.ResourceGetMultiImageResponse = (function() {

        /**
         * Properties of a ResourceGetMultiImageResponse.
         * @memberof resource
         * @interface IResourceGetMultiImageResponse
         * @property {Array.<resource.IImage>|null} [imageList] ResourceGetMultiImageResponse imageList
         */

        /**
         * Constructs a new ResourceGetMultiImageResponse.
         * @memberof resource
         * @classdesc Represents a ResourceGetMultiImageResponse.
         * @implements IResourceGetMultiImageResponse
         * @constructor
         * @param {resource.IResourceGetMultiImageResponse=} [properties] Properties to set
         */
        function ResourceGetMultiImageResponse(properties) {
            this.imageList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceGetMultiImageResponse imageList.
         * @member {Array.<resource.IImage>} imageList
         * @memberof resource.ResourceGetMultiImageResponse
         * @instance
         */
        ResourceGetMultiImageResponse.prototype.imageList = $util.emptyArray;

        /**
         * Creates a new ResourceGetMultiImageResponse instance using the specified properties.
         * @function create
         * @memberof resource.ResourceGetMultiImageResponse
         * @static
         * @param {resource.IResourceGetMultiImageResponse=} [properties] Properties to set
         * @returns {resource.ResourceGetMultiImageResponse} ResourceGetMultiImageResponse instance
         */
        ResourceGetMultiImageResponse.create = function create(properties) {
            return new ResourceGetMultiImageResponse(properties);
        };

        /**
         * Encodes the specified ResourceGetMultiImageResponse message. Does not implicitly {@link resource.ResourceGetMultiImageResponse.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceGetMultiImageResponse
         * @static
         * @param {resource.IResourceGetMultiImageResponse} message ResourceGetMultiImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceGetMultiImageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageList != null && message.imageList.length)
                for (var i = 0; i < message.imageList.length; ++i)
                    $root.resource.Image.encode(message.imageList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ResourceGetMultiImageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceGetMultiImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceGetMultiImageResponse} ResourceGetMultiImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceGetMultiImageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceGetMultiImageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.imageList && message.imageList.length))
                        message.imageList = [];
                    message.imageList.push($root.resource.Image.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceGetMultiImageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceGetMultiImageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceGetMultiImageResponse} ResourceGetMultiImageResponse
         */
        ResourceGetMultiImageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceGetMultiImageResponse)
                return object;
            var message = new $root.resource.ResourceGetMultiImageResponse();
            if (object.imageList) {
                if (!Array.isArray(object.imageList))
                    throw TypeError(".resource.ResourceGetMultiImageResponse.imageList: array expected");
                message.imageList = [];
                for (var i = 0; i < object.imageList.length; ++i) {
                    if (typeof object.imageList[i] !== "object")
                        throw TypeError(".resource.ResourceGetMultiImageResponse.imageList: object expected");
                    message.imageList[i] = $root.resource.Image.fromObject(object.imageList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceGetMultiImageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceGetMultiImageResponse
         * @static
         * @param {resource.ResourceGetMultiImageResponse} message ResourceGetMultiImageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceGetMultiImageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.imageList = [];
            if (message.imageList && message.imageList.length) {
                object.imageList = [];
                for (var j = 0; j < message.imageList.length; ++j)
                    object.imageList[j] = $root.resource.Image.toObject(message.imageList[j], options);
            }
            return object;
        };

        /**
         * Converts this ResourceGetMultiImageResponse to JSON.
         * @function toJSON
         * @memberof resource.ResourceGetMultiImageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceGetMultiImageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceGetMultiImageResponse
         * @function getTypeUrl
         * @memberof resource.ResourceGetMultiImageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceGetMultiImageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceGetMultiImageResponse";
        };

        return ResourceGetMultiImageResponse;
    })();

    resource.ResourceListPresetImageRequest = (function() {

        /**
         * Properties of a ResourceListPresetImageRequest.
         * @memberof resource
         * @interface IResourceListPresetImageRequest
         * @property {resource.ImageCategory|null} [category] ResourceListPresetImageRequest category
         */

        /**
         * Constructs a new ResourceListPresetImageRequest.
         * @memberof resource
         * @classdesc Represents a ResourceListPresetImageRequest.
         * @implements IResourceListPresetImageRequest
         * @constructor
         * @param {resource.IResourceListPresetImageRequest=} [properties] Properties to set
         */
        function ResourceListPresetImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceListPresetImageRequest category.
         * @member {resource.ImageCategory} category
         * @memberof resource.ResourceListPresetImageRequest
         * @instance
         */
        ResourceListPresetImageRequest.prototype.category = 0;

        /**
         * Creates a new ResourceListPresetImageRequest instance using the specified properties.
         * @function create
         * @memberof resource.ResourceListPresetImageRequest
         * @static
         * @param {resource.IResourceListPresetImageRequest=} [properties] Properties to set
         * @returns {resource.ResourceListPresetImageRequest} ResourceListPresetImageRequest instance
         */
        ResourceListPresetImageRequest.create = function create(properties) {
            return new ResourceListPresetImageRequest(properties);
        };

        /**
         * Encodes the specified ResourceListPresetImageRequest message. Does not implicitly {@link resource.ResourceListPresetImageRequest.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceListPresetImageRequest
         * @static
         * @param {resource.IResourceListPresetImageRequest} message ResourceListPresetImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceListPresetImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.category);
            return writer;
        };

        /**
         * Decodes a ResourceListPresetImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceListPresetImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceListPresetImageRequest} ResourceListPresetImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceListPresetImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceListPresetImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.category = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceListPresetImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceListPresetImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceListPresetImageRequest} ResourceListPresetImageRequest
         */
        ResourceListPresetImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceListPresetImageRequest)
                return object;
            var message = new $root.resource.ResourceListPresetImageRequest();
            switch (object.category) {
            case "IMAGE_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "IMAGE_CATEGORY_USER_PROFILE":
            case 1:
                message.category = 1;
                break;
            case "IMAGE_CATEGORY_CHARACTER_ICON":
            case 2:
                message.category = 2;
                break;
            case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
            case 3:
                message.category = 3;
                break;
            case "IMAGE_CATEGORY_CHAT_BACKGROUND":
            case 4:
                message.category = 4;
                break;
            case "IMAGE_CATEGORY_TALK_BACKGROUND":
            case 5:
                message.category = 5;
                break;
            case "IMAGE_CATEGORY_CONTENT":
            case 6:
                message.category = 6;
                break;
            case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
            case 7:
                message.category = 7;
                break;
            case "IMAGE_CATEGORY_STAMP":
            case 8:
                message.category = 8;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceListPresetImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceListPresetImageRequest
         * @static
         * @param {resource.ResourceListPresetImageRequest} message ResourceListPresetImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceListPresetImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.category = options.enums === String ? "IMAGE_CATEGORY_UNSPECIFIED" : 0;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.resource.ImageCategory[message.category] : message.category;
            return object;
        };

        /**
         * Converts this ResourceListPresetImageRequest to JSON.
         * @function toJSON
         * @memberof resource.ResourceListPresetImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceListPresetImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceListPresetImageRequest
         * @function getTypeUrl
         * @memberof resource.ResourceListPresetImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceListPresetImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceListPresetImageRequest";
        };

        return ResourceListPresetImageRequest;
    })();

    resource.ResourceCreateAudioRequest = (function() {

        /**
         * Properties of a ResourceCreateAudioRequest.
         * @memberof resource
         * @interface IResourceCreateAudioRequest
         * @property {google.protobuf.IStringValue|null} [gsPath] ResourceCreateAudioRequest gsPath
         * @property {google.protobuf.IDuration|null} [duration] ResourceCreateAudioRequest duration
         * @property {resource.AudioCodec|null} [codec] ResourceCreateAudioRequest codec
         */

        /**
         * Constructs a new ResourceCreateAudioRequest.
         * @memberof resource
         * @classdesc Represents a ResourceCreateAudioRequest.
         * @implements IResourceCreateAudioRequest
         * @constructor
         * @param {resource.IResourceCreateAudioRequest=} [properties] Properties to set
         */
        function ResourceCreateAudioRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceCreateAudioRequest gsPath.
         * @member {google.protobuf.IStringValue|null|undefined} gsPath
         * @memberof resource.ResourceCreateAudioRequest
         * @instance
         */
        ResourceCreateAudioRequest.prototype.gsPath = null;

        /**
         * ResourceCreateAudioRequest duration.
         * @member {google.protobuf.IDuration|null|undefined} duration
         * @memberof resource.ResourceCreateAudioRequest
         * @instance
         */
        ResourceCreateAudioRequest.prototype.duration = null;

        /**
         * ResourceCreateAudioRequest codec.
         * @member {resource.AudioCodec} codec
         * @memberof resource.ResourceCreateAudioRequest
         * @instance
         */
        ResourceCreateAudioRequest.prototype.codec = 0;

        /**
         * Creates a new ResourceCreateAudioRequest instance using the specified properties.
         * @function create
         * @memberof resource.ResourceCreateAudioRequest
         * @static
         * @param {resource.IResourceCreateAudioRequest=} [properties] Properties to set
         * @returns {resource.ResourceCreateAudioRequest} ResourceCreateAudioRequest instance
         */
        ResourceCreateAudioRequest.create = function create(properties) {
            return new ResourceCreateAudioRequest(properties);
        };

        /**
         * Encodes the specified ResourceCreateAudioRequest message. Does not implicitly {@link resource.ResourceCreateAudioRequest.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceCreateAudioRequest
         * @static
         * @param {resource.IResourceCreateAudioRequest} message ResourceCreateAudioRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceCreateAudioRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gsPath != null && Object.hasOwnProperty.call(message, "gsPath"))
                $root.google.protobuf.StringValue.encode(message.gsPath, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.codec != null && Object.hasOwnProperty.call(message, "codec"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.codec);
            return writer;
        };

        /**
         * Decodes a ResourceCreateAudioRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceCreateAudioRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceCreateAudioRequest} ResourceCreateAudioRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceCreateAudioRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceCreateAudioRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gsPath = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.codec = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceCreateAudioRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceCreateAudioRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceCreateAudioRequest} ResourceCreateAudioRequest
         */
        ResourceCreateAudioRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceCreateAudioRequest)
                return object;
            var message = new $root.resource.ResourceCreateAudioRequest();
            if (object.gsPath != null) {
                if (typeof object.gsPath !== "object")
                    throw TypeError(".resource.ResourceCreateAudioRequest.gsPath: object expected");
                message.gsPath = $root.google.protobuf.StringValue.fromObject(object.gsPath);
            }
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".resource.ResourceCreateAudioRequest.duration: object expected");
                message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
            }
            switch (object.codec) {
            case "AUDIO_CODEC_UNSPECIFIED":
            case 0:
                message.codec = 0;
                break;
            case "AUDIO_CODEC_AAC":
            case 1:
                message.codec = 1;
                break;
            case "AUDIO_CODEC_MP3":
            case 2:
                message.codec = 2;
                break;
            case "AUDIO_CODEC_VORBIS":
            case 3:
                message.codec = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceCreateAudioRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceCreateAudioRequest
         * @static
         * @param {resource.ResourceCreateAudioRequest} message ResourceCreateAudioRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceCreateAudioRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gsPath = null;
                object.duration = null;
                object.codec = options.enums === String ? "AUDIO_CODEC_UNSPECIFIED" : 0;
            }
            if (message.gsPath != null && message.hasOwnProperty("gsPath"))
                object.gsPath = $root.google.protobuf.StringValue.toObject(message.gsPath, options);
            if (message.duration != null && message.hasOwnProperty("duration"))
                object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
            if (message.codec != null && message.hasOwnProperty("codec"))
                object.codec = options.enums === String ? $root.resource.AudioCodec[message.codec] : message.codec;
            return object;
        };

        /**
         * Converts this ResourceCreateAudioRequest to JSON.
         * @function toJSON
         * @memberof resource.ResourceCreateAudioRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceCreateAudioRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceCreateAudioRequest
         * @function getTypeUrl
         * @memberof resource.ResourceCreateAudioRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceCreateAudioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceCreateAudioRequest";
        };

        return ResourceCreateAudioRequest;
    })();

    resource.ResourceDeleteAudioRequest = (function() {

        /**
         * Properties of a ResourceDeleteAudioRequest.
         * @memberof resource
         * @interface IResourceDeleteAudioRequest
         * @property {google.protobuf.IStringValue|null} [id] ResourceDeleteAudioRequest id
         */

        /**
         * Constructs a new ResourceDeleteAudioRequest.
         * @memberof resource
         * @classdesc Represents a ResourceDeleteAudioRequest.
         * @implements IResourceDeleteAudioRequest
         * @constructor
         * @param {resource.IResourceDeleteAudioRequest=} [properties] Properties to set
         */
        function ResourceDeleteAudioRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceDeleteAudioRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof resource.ResourceDeleteAudioRequest
         * @instance
         */
        ResourceDeleteAudioRequest.prototype.id = null;

        /**
         * Creates a new ResourceDeleteAudioRequest instance using the specified properties.
         * @function create
         * @memberof resource.ResourceDeleteAudioRequest
         * @static
         * @param {resource.IResourceDeleteAudioRequest=} [properties] Properties to set
         * @returns {resource.ResourceDeleteAudioRequest} ResourceDeleteAudioRequest instance
         */
        ResourceDeleteAudioRequest.create = function create(properties) {
            return new ResourceDeleteAudioRequest(properties);
        };

        /**
         * Encodes the specified ResourceDeleteAudioRequest message. Does not implicitly {@link resource.ResourceDeleteAudioRequest.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceDeleteAudioRequest
         * @static
         * @param {resource.IResourceDeleteAudioRequest} message ResourceDeleteAudioRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceDeleteAudioRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ResourceDeleteAudioRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceDeleteAudioRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceDeleteAudioRequest} ResourceDeleteAudioRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceDeleteAudioRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceDeleteAudioRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceDeleteAudioRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceDeleteAudioRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceDeleteAudioRequest} ResourceDeleteAudioRequest
         */
        ResourceDeleteAudioRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceDeleteAudioRequest)
                return object;
            var message = new $root.resource.ResourceDeleteAudioRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".resource.ResourceDeleteAudioRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceDeleteAudioRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceDeleteAudioRequest
         * @static
         * @param {resource.ResourceDeleteAudioRequest} message ResourceDeleteAudioRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceDeleteAudioRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this ResourceDeleteAudioRequest to JSON.
         * @function toJSON
         * @memberof resource.ResourceDeleteAudioRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceDeleteAudioRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceDeleteAudioRequest
         * @function getTypeUrl
         * @memberof resource.ResourceDeleteAudioRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceDeleteAudioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceDeleteAudioRequest";
        };

        return ResourceDeleteAudioRequest;
    })();

    resource.ResourceGetMultiAudioRequest = (function() {

        /**
         * Properties of a ResourceGetMultiAudioRequest.
         * @memberof resource
         * @interface IResourceGetMultiAudioRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [idList] ResourceGetMultiAudioRequest idList
         */

        /**
         * Constructs a new ResourceGetMultiAudioRequest.
         * @memberof resource
         * @classdesc Represents a ResourceGetMultiAudioRequest.
         * @implements IResourceGetMultiAudioRequest
         * @constructor
         * @param {resource.IResourceGetMultiAudioRequest=} [properties] Properties to set
         */
        function ResourceGetMultiAudioRequest(properties) {
            this.idList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceGetMultiAudioRequest idList.
         * @member {Array.<google.protobuf.IStringValue>} idList
         * @memberof resource.ResourceGetMultiAudioRequest
         * @instance
         */
        ResourceGetMultiAudioRequest.prototype.idList = $util.emptyArray;

        /**
         * Creates a new ResourceGetMultiAudioRequest instance using the specified properties.
         * @function create
         * @memberof resource.ResourceGetMultiAudioRequest
         * @static
         * @param {resource.IResourceGetMultiAudioRequest=} [properties] Properties to set
         * @returns {resource.ResourceGetMultiAudioRequest} ResourceGetMultiAudioRequest instance
         */
        ResourceGetMultiAudioRequest.create = function create(properties) {
            return new ResourceGetMultiAudioRequest(properties);
        };

        /**
         * Encodes the specified ResourceGetMultiAudioRequest message. Does not implicitly {@link resource.ResourceGetMultiAudioRequest.verify|verify} messages.
         * @function encode
         * @memberof resource.ResourceGetMultiAudioRequest
         * @static
         * @param {resource.IResourceGetMultiAudioRequest} message ResourceGetMultiAudioRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceGetMultiAudioRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idList != null && message.idList.length)
                for (var i = 0; i < message.idList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.idList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ResourceGetMultiAudioRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource.ResourceGetMultiAudioRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource.ResourceGetMultiAudioRequest} ResourceGetMultiAudioRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceGetMultiAudioRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource.ResourceGetMultiAudioRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.idList && message.idList.length))
                        message.idList = [];
                    message.idList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ResourceGetMultiAudioRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource.ResourceGetMultiAudioRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource.ResourceGetMultiAudioRequest} ResourceGetMultiAudioRequest
         */
        ResourceGetMultiAudioRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource.ResourceGetMultiAudioRequest)
                return object;
            var message = new $root.resource.ResourceGetMultiAudioRequest();
            if (object.idList) {
                if (!Array.isArray(object.idList))
                    throw TypeError(".resource.ResourceGetMultiAudioRequest.idList: array expected");
                message.idList = [];
                for (var i = 0; i < object.idList.length; ++i) {
                    if (typeof object.idList[i] !== "object")
                        throw TypeError(".resource.ResourceGetMultiAudioRequest.idList: object expected");
                    message.idList[i] = $root.google.protobuf.StringValue.fromObject(object.idList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceGetMultiAudioRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource.ResourceGetMultiAudioRequest
         * @static
         * @param {resource.ResourceGetMultiAudioRequest} message ResourceGetMultiAudioRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceGetMultiAudioRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.idList = [];
            if (message.idList && message.idList.length) {
                object.idList = [];
                for (var j = 0; j < message.idList.length; ++j)
                    object.idList[j] = $root.google.protobuf.StringValue.toObject(message.idList[j], options);
            }
            return object;
        };

        /**
         * Converts this ResourceGetMultiAudioRequest to JSON.
         * @function toJSON
         * @memberof resource.ResourceGetMultiAudioRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceGetMultiAudioRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceGetMultiAudioRequest
         * @function getTypeUrl
         * @memberof resource.ResourceGetMultiAudioRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceGetMultiAudioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource.ResourceGetMultiAudioRequest";
        };

        return ResourceGetMultiAudioRequest;
    })();

    return resource;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.DoubleValue = (function() {

            /**
             * Properties of a DoubleValue.
             * @memberof google.protobuf
             * @interface IDoubleValue
             * @property {number|null} [value] DoubleValue value
             */

            /**
             * Constructs a new DoubleValue.
             * @memberof google.protobuf
             * @classdesc Represents a DoubleValue.
             * @implements IDoubleValue
             * @constructor
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             */
            function DoubleValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DoubleValue value.
             * @member {number} value
             * @memberof google.protobuf.DoubleValue
             * @instance
             */
            DoubleValue.prototype.value = 0;

            /**
             * Creates a new DoubleValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             * @returns {google.protobuf.DoubleValue} DoubleValue instance
             */
            DoubleValue.create = function create(properties) {
                return new DoubleValue(properties);
            };

            /**
             * Encodes the specified DoubleValue message. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DoubleValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
                return writer;
            };

            /**
             * Decodes a DoubleValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DoubleValue} DoubleValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DoubleValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DoubleValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DoubleValue} DoubleValue
             */
            DoubleValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DoubleValue)
                    return object;
                var message = new $root.google.protobuf.DoubleValue();
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };

            /**
             * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.DoubleValue} message DoubleValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DoubleValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };

            /**
             * Converts this DoubleValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.DoubleValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DoubleValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DoubleValue
             * @function getTypeUrl
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DoubleValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DoubleValue";
            };

            return DoubleValue;
        })();

        protobuf.FloatValue = (function() {

            /**
             * Properties of a FloatValue.
             * @memberof google.protobuf
             * @interface IFloatValue
             * @property {number|null} [value] FloatValue value
             */

            /**
             * Constructs a new FloatValue.
             * @memberof google.protobuf
             * @classdesc Represents a FloatValue.
             * @implements IFloatValue
             * @constructor
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             */
            function FloatValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FloatValue value.
             * @member {number} value
             * @memberof google.protobuf.FloatValue
             * @instance
             */
            FloatValue.prototype.value = 0;

            /**
             * Creates a new FloatValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             * @returns {google.protobuf.FloatValue} FloatValue instance
             */
            FloatValue.create = function create(properties) {
                return new FloatValue(properties);
            };

            /**
             * Encodes the specified FloatValue message. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.value);
                return writer;
            };

            /**
             * Decodes a FloatValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FloatValue} FloatValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FloatValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.float();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FloatValue} FloatValue
             */
            FloatValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FloatValue)
                    return object;
                var message = new $root.google.protobuf.FloatValue();
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };

            /**
             * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.FloatValue} message FloatValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FloatValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };

            /**
             * Converts this FloatValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.FloatValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FloatValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FloatValue
             * @function getTypeUrl
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FloatValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FloatValue";
            };

            return FloatValue;
        })();

        protobuf.Int64Value = (function() {

            /**
             * Properties of an Int64Value.
             * @memberof google.protobuf
             * @interface IInt64Value
             * @property {number|Long|null} [value] Int64Value value
             */

            /**
             * Constructs a new Int64Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int64Value.
             * @implements IInt64Value
             * @constructor
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             */
            function Int64Value(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int64Value value.
             * @member {number|Long} value
             * @memberof google.protobuf.Int64Value
             * @instance
             */
            Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Int64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.Int64Value} Int64Value instance
             */
            Int64Value.create = function create(properties) {
                return new Int64Value(properties);
            };

            /**
             * Encodes the specified Int64Value message. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int64Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
                return writer;
            };

            /**
             * Decodes an Int64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Int64Value} Int64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int64Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int64Value();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Int64Value} Int64Value
             */
            Int64Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Int64Value)
                    return object;
                var message = new $root.google.protobuf.Int64Value();
                if (object.value != null)
                    if ($util.Long)
                        (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                    else if (typeof object.value === "string")
                        message.value = parseInt(object.value, 10);
                    else if (typeof object.value === "number")
                        message.value = object.value;
                    else if (typeof object.value === "object")
                        message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.Int64Value} message Int64Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int64Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.value = options.longs === String ? "0" : 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value === "number")
                        object.value = options.longs === String ? String(message.value) : message.value;
                    else
                        object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                return object;
            };

            /**
             * Converts this Int64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int64Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int64Value";
            };

            return Int64Value;
        })();

        protobuf.UInt64Value = (function() {

            /**
             * Properties of a UInt64Value.
             * @memberof google.protobuf
             * @interface IUInt64Value
             * @property {number|Long|null} [value] UInt64Value value
             */

            /**
             * Constructs a new UInt64Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt64Value.
             * @implements IUInt64Value
             * @constructor
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             */
            function UInt64Value(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt64Value value.
             * @member {number|Long} value
             * @memberof google.protobuf.UInt64Value
             * @instance
             */
            UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new UInt64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt64Value} UInt64Value instance
             */
            UInt64Value.create = function create(properties) {
                return new UInt64Value(properties);
            };

            /**
             * Encodes the specified UInt64Value message. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt64Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.value);
                return writer;
            };

            /**
             * Decodes a UInt64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UInt64Value} UInt64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt64Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt64Value();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UInt64Value} UInt64Value
             */
            UInt64Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UInt64Value)
                    return object;
                var message = new $root.google.protobuf.UInt64Value();
                if (object.value != null)
                    if ($util.Long)
                        (message.value = $util.Long.fromValue(object.value)).unsigned = true;
                    else if (typeof object.value === "string")
                        message.value = parseInt(object.value, 10);
                    else if (typeof object.value === "number")
                        message.value = object.value;
                    else if (typeof object.value === "object")
                        message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.UInt64Value} message UInt64Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt64Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.value = options.longs === String ? "0" : 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value === "number")
                        object.value = options.longs === String ? String(message.value) : message.value;
                    else
                        object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber(true) : message.value;
                return object;
            };

            /**
             * Converts this UInt64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt64Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt64Value";
            };

            return UInt64Value;
        })();

        protobuf.Int32Value = (function() {

            /**
             * Properties of an Int32Value.
             * @memberof google.protobuf
             * @interface IInt32Value
             * @property {number|null} [value] Int32Value value
             */

            /**
             * Constructs a new Int32Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int32Value.
             * @implements IInt32Value
             * @constructor
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             */
            function Int32Value(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int32Value value.
             * @member {number} value
             * @memberof google.protobuf.Int32Value
             * @instance
             */
            Int32Value.prototype.value = 0;

            /**
             * Creates a new Int32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.Int32Value} Int32Value instance
             */
            Int32Value.create = function create(properties) {
                return new Int32Value(properties);
            };

            /**
             * Encodes the specified Int32Value message. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int32Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                return writer;
            };

            /**
             * Decodes an Int32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Int32Value} Int32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int32Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int32Value();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Int32Value} Int32Value
             */
            Int32Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Int32Value)
                    return object;
                var message = new $root.google.protobuf.Int32Value();
                if (object.value != null)
                    message.value = object.value | 0;
                return message;
            };

            /**
             * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.Int32Value} message Int32Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int32Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this Int32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int32Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int32Value";
            };

            return Int32Value;
        })();

        protobuf.UInt32Value = (function() {

            /**
             * Properties of a UInt32Value.
             * @memberof google.protobuf
             * @interface IUInt32Value
             * @property {number|null} [value] UInt32Value value
             */

            /**
             * Constructs a new UInt32Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt32Value.
             * @implements IUInt32Value
             * @constructor
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             */
            function UInt32Value(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt32Value value.
             * @member {number} value
             * @memberof google.protobuf.UInt32Value
             * @instance
             */
            UInt32Value.prototype.value = 0;

            /**
             * Creates a new UInt32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt32Value} UInt32Value instance
             */
            UInt32Value.create = function create(properties) {
                return new UInt32Value(properties);
            };

            /**
             * Encodes the specified UInt32Value message. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt32Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.value);
                return writer;
            };

            /**
             * Decodes a UInt32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UInt32Value} UInt32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt32Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt32Value();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UInt32Value} UInt32Value
             */
            UInt32Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UInt32Value)
                    return object;
                var message = new $root.google.protobuf.UInt32Value();
                if (object.value != null)
                    message.value = object.value >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.UInt32Value} message UInt32Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt32Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this UInt32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt32Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt32Value";
            };

            return UInt32Value;
        })();

        protobuf.BoolValue = (function() {

            /**
             * Properties of a BoolValue.
             * @memberof google.protobuf
             * @interface IBoolValue
             * @property {boolean|null} [value] BoolValue value
             */

            /**
             * Constructs a new BoolValue.
             * @memberof google.protobuf
             * @classdesc Represents a BoolValue.
             * @implements IBoolValue
             * @constructor
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             */
            function BoolValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BoolValue value.
             * @member {boolean} value
             * @memberof google.protobuf.BoolValue
             * @instance
             */
            BoolValue.prototype.value = false;

            /**
             * Creates a new BoolValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             * @returns {google.protobuf.BoolValue} BoolValue instance
             */
            BoolValue.create = function create(properties) {
                return new BoolValue(properties);
            };

            /**
             * Encodes the specified BoolValue message. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoolValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                return writer;
            };

            /**
             * Decodes a BoolValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.BoolValue} BoolValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoolValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BoolValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.BoolValue} BoolValue
             */
            BoolValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.BoolValue)
                    return object;
                var message = new $root.google.protobuf.BoolValue();
                if (object.value != null)
                    message.value = Boolean(object.value);
                return message;
            };

            /**
             * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.BoolValue} message BoolValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BoolValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.value = false;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this BoolValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BoolValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BoolValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BoolValue
             * @function getTypeUrl
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BoolValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BoolValue";
            };

            return BoolValue;
        })();

        protobuf.StringValue = (function() {

            /**
             * Properties of a StringValue.
             * @memberof google.protobuf
             * @interface IStringValue
             * @property {string|null} [value] StringValue value
             */

            /**
             * Constructs a new StringValue.
             * @memberof google.protobuf
             * @classdesc Represents a StringValue.
             * @implements IStringValue
             * @constructor
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             */
            function StringValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StringValue value.
             * @member {string} value
             * @memberof google.protobuf.StringValue
             * @instance
             */
            StringValue.prototype.value = "";

            /**
             * Creates a new StringValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             * @returns {google.protobuf.StringValue} StringValue instance
             */
            StringValue.create = function create(properties) {
                return new StringValue(properties);
            };

            /**
             * Encodes the specified StringValue message. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StringValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                return writer;
            };

            /**
             * Decodes a StringValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.StringValue} StringValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StringValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.StringValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.StringValue} StringValue
             */
            StringValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.StringValue)
                    return object;
                var message = new $root.google.protobuf.StringValue();
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from a StringValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.StringValue} message StringValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StringValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.value = "";
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this StringValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.StringValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StringValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StringValue
             * @function getTypeUrl
             * @memberof google.protobuf.StringValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StringValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.StringValue";
            };

            return StringValue;
        })();

        protobuf.BytesValue = (function() {

            /**
             * Properties of a BytesValue.
             * @memberof google.protobuf
             * @interface IBytesValue
             * @property {Uint8Array|null} [value] BytesValue value
             */

            /**
             * Constructs a new BytesValue.
             * @memberof google.protobuf
             * @classdesc Represents a BytesValue.
             * @implements IBytesValue
             * @constructor
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             */
            function BytesValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BytesValue value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.BytesValue
             * @instance
             */
            BytesValue.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new BytesValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             * @returns {google.protobuf.BytesValue} BytesValue instance
             */
            BytesValue.create = function create(properties) {
                return new BytesValue(properties);
            };

            /**
             * Encodes the specified BytesValue message. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BytesValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.value);
                return writer;
            };

            /**
             * Decodes a BytesValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.BytesValue} BytesValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BytesValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BytesValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.BytesValue} BytesValue
             */
            BytesValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.BytesValue)
                    return object;
                var message = new $root.google.protobuf.BytesValue();
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.BytesValue} message BytesValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BytesValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this BytesValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BytesValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BytesValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BytesValue
             * @function getTypeUrl
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BytesValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BytesValue";
            };

            return BytesValue;
        })();

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        protobuf.Duration = (function() {

            /**
             * Properties of a Duration.
             * @memberof google.protobuf
             * @interface IDuration
             * @property {number|Long|null} [seconds] Duration seconds
             * @property {number|null} [nanos] Duration nanos
             */

            /**
             * Constructs a new Duration.
             * @memberof google.protobuf
             * @classdesc Represents a Duration.
             * @implements IDuration
             * @constructor
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             */
            function Duration(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Duration seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Duration nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.nanos = 0;

            /**
             * Creates a new Duration instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             * @returns {google.protobuf.Duration} Duration instance
             */
            Duration.create = function create(properties) {
                return new Duration(properties);
            };

            /**
             * Encodes the specified Duration message. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Decodes a Duration message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Duration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a Duration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Duration} Duration
             */
            Duration.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Duration)
                    return object;
                var message = new $root.google.protobuf.Duration();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Duration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.Duration} message Duration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Duration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Duration to JSON.
             * @function toJSON
             * @memberof google.protobuf.Duration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Duration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Duration
             * @function getTypeUrl
             * @memberof google.protobuf.Duration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Duration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Duration";
            };

            return Duration;
        })();

        return protobuf;
    })();

    return google;
})();

$root.following = (function() {

    /**
     * Namespace following.
     * @exports following
     * @namespace
     */
    var following = {};

    following.FollowingService = (function() {

        /**
         * Constructs a new FollowingService service.
         * @memberof following
         * @classdesc Represents a FollowingService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function FollowingService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (FollowingService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = FollowingService;

        /**
         * Creates new FollowingService service using the specified rpc implementation.
         * @function create
         * @memberof following.FollowingService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {FollowingService} RPC service. Useful where requests and/or responses are streamed.
         */
        FollowingService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link following.FollowingService#getStats}.
         * @memberof following.FollowingService
         * @typedef GetStatsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {following.FollowingStats} [response] FollowingStats
         */

        /**
         * Calls GetStats.
         * @function getStats
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @param {following.FollowingService.GetStatsCallback} callback Node-style callback called with the error, if any, and FollowingStats
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FollowingService.prototype.getStats = function getStats(request, callback) {
            return this.rpcCall(getStats, $root.user.UserIdRequest, $root.following.FollowingStats, request, callback);
        }, "name", { value: "GetStats" });

        /**
         * Calls GetStats.
         * @function getStats
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @returns {Promise<following.FollowingStats>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link following.FollowingService#listFollowerV2}.
         * @memberof following.FollowingService
         * @typedef ListFollowerV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_selection.UserSelectionWithCursor} [response] UserSelectionWithCursor
         */

        /**
         * Calls ListFollowerV2.
         * @function listFollowerV2
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @param {following.FollowingService.ListFollowerV2Callback} callback Node-style callback called with the error, if any, and UserSelectionWithCursor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FollowingService.prototype.listFollowerV2 = function listFollowerV2(request, callback) {
            return this.rpcCall(listFollowerV2, $root.user.UserIdWithCursorRequest, $root.user_selection.UserSelectionWithCursor, request, callback);
        }, "name", { value: "ListFollowerV2" });

        /**
         * Calls ListFollowerV2.
         * @function listFollowerV2
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @returns {Promise<user_selection.UserSelectionWithCursor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link following.FollowingService#listFolloweeV2}.
         * @memberof following.FollowingService
         * @typedef ListFolloweeV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_selection.UserSelectionWithCursor} [response] UserSelectionWithCursor
         */

        /**
         * Calls ListFolloweeV2.
         * @function listFolloweeV2
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @param {following.FollowingService.ListFolloweeV2Callback} callback Node-style callback called with the error, if any, and UserSelectionWithCursor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FollowingService.prototype.listFolloweeV2 = function listFolloweeV2(request, callback) {
            return this.rpcCall(listFolloweeV2, $root.user.UserIdWithCursorRequest, $root.user_selection.UserSelectionWithCursor, request, callback);
        }, "name", { value: "ListFolloweeV2" });

        /**
         * Calls ListFolloweeV2.
         * @function listFolloweeV2
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @returns {Promise<user_selection.UserSelectionWithCursor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link following.FollowingService#follow}.
         * @memberof following.FollowingService
         * @typedef FollowCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls Follow.
         * @function follow
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @param {following.FollowingService.FollowCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FollowingService.prototype.follow = function follow(request, callback) {
            return this.rpcCall(follow, $root.user.UserIdRequest, $root.types.Empty, request, callback);
        }, "name", { value: "Follow" });

        /**
         * Calls Follow.
         * @function follow
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link following.FollowingService#unfollow}.
         * @memberof following.FollowingService
         * @typedef UnfollowCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls Unfollow.
         * @function unfollow
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @param {following.FollowingService.UnfollowCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FollowingService.prototype.unfollow = function unfollow(request, callback) {
            return this.rpcCall(unfollow, $root.user.UserIdRequest, $root.types.Empty, request, callback);
        }, "name", { value: "Unfollow" });

        /**
         * Calls Unfollow.
         * @function unfollow
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link following.FollowingService#listFollower}.
         * @memberof following.FollowingService
         * @typedef ListFollowerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_selection.UserSelectionGetResponse} [response] UserSelectionGetResponse
         */

        /**
         * Calls ListFollower.
         * @function listFollower
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @param {following.FollowingService.ListFollowerCallback} callback Node-style callback called with the error, if any, and UserSelectionGetResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FollowingService.prototype.listFollower = function listFollower(request, callback) {
            return this.rpcCall(listFollower, $root.user.UserIdWithCursorRequest, $root.user_selection.UserSelectionGetResponse, request, callback);
        }, "name", { value: "ListFollower" });

        /**
         * Calls ListFollower.
         * @function listFollower
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @returns {Promise<user_selection.UserSelectionGetResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link following.FollowingService#listFollowee}.
         * @memberof following.FollowingService
         * @typedef ListFolloweeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_selection.UserSelectionGetResponse} [response] UserSelectionGetResponse
         */

        /**
         * Calls ListFollowee.
         * @function listFollowee
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @param {following.FollowingService.ListFolloweeCallback} callback Node-style callback called with the error, if any, and UserSelectionGetResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FollowingService.prototype.listFollowee = function listFollowee(request, callback) {
            return this.rpcCall(listFollowee, $root.user.UserIdWithCursorRequest, $root.user_selection.UserSelectionGetResponse, request, callback);
        }, "name", { value: "ListFollowee" });

        /**
         * Calls ListFollowee.
         * @function listFollowee
         * @memberof following.FollowingService
         * @instance
         * @param {user.IUserIdWithCursorRequest} request UserIdWithCursorRequest message or plain object
         * @returns {Promise<user_selection.UserSelectionGetResponse>} Promise
         * @variation 2
         */

        return FollowingService;
    })();

    following.FollowingStats = (function() {

        /**
         * Properties of a FollowingStats.
         * @memberof following
         * @interface IFollowingStats
         * @property {google.protobuf.IStringValue|null} [userId] FollowingStats userId
         * @property {google.protobuf.IInt32Value|null} [followerCount] FollowingStats followerCount
         * @property {google.protobuf.IInt32Value|null} [followeeCount] FollowingStats followeeCount
         */

        /**
         * Constructs a new FollowingStats.
         * @memberof following
         * @classdesc Represents a FollowingStats.
         * @implements IFollowingStats
         * @constructor
         * @param {following.IFollowingStats=} [properties] Properties to set
         */
        function FollowingStats(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FollowingStats userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof following.FollowingStats
         * @instance
         */
        FollowingStats.prototype.userId = null;

        /**
         * FollowingStats followerCount.
         * @member {google.protobuf.IInt32Value|null|undefined} followerCount
         * @memberof following.FollowingStats
         * @instance
         */
        FollowingStats.prototype.followerCount = null;

        /**
         * FollowingStats followeeCount.
         * @member {google.protobuf.IInt32Value|null|undefined} followeeCount
         * @memberof following.FollowingStats
         * @instance
         */
        FollowingStats.prototype.followeeCount = null;

        /**
         * Creates a new FollowingStats instance using the specified properties.
         * @function create
         * @memberof following.FollowingStats
         * @static
         * @param {following.IFollowingStats=} [properties] Properties to set
         * @returns {following.FollowingStats} FollowingStats instance
         */
        FollowingStats.create = function create(properties) {
            return new FollowingStats(properties);
        };

        /**
         * Encodes the specified FollowingStats message. Does not implicitly {@link following.FollowingStats.verify|verify} messages.
         * @function encode
         * @memberof following.FollowingStats
         * @static
         * @param {following.IFollowingStats} message FollowingStats message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FollowingStats.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.followerCount != null && Object.hasOwnProperty.call(message, "followerCount"))
                $root.google.protobuf.Int32Value.encode(message.followerCount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.followeeCount != null && Object.hasOwnProperty.call(message, "followeeCount"))
                $root.google.protobuf.Int32Value.encode(message.followeeCount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FollowingStats message from the specified reader or buffer.
         * @function decode
         * @memberof following.FollowingStats
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {following.FollowingStats} FollowingStats
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FollowingStats.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.following.FollowingStats();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.followerCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.followeeCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a FollowingStats message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof following.FollowingStats
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {following.FollowingStats} FollowingStats
         */
        FollowingStats.fromObject = function fromObject(object) {
            if (object instanceof $root.following.FollowingStats)
                return object;
            var message = new $root.following.FollowingStats();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".following.FollowingStats.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.followerCount != null) {
                if (typeof object.followerCount !== "object")
                    throw TypeError(".following.FollowingStats.followerCount: object expected");
                message.followerCount = $root.google.protobuf.Int32Value.fromObject(object.followerCount);
            }
            if (object.followeeCount != null) {
                if (typeof object.followeeCount !== "object")
                    throw TypeError(".following.FollowingStats.followeeCount: object expected");
                message.followeeCount = $root.google.protobuf.Int32Value.fromObject(object.followeeCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a FollowingStats message. Also converts values to other types if specified.
         * @function toObject
         * @memberof following.FollowingStats
         * @static
         * @param {following.FollowingStats} message FollowingStats
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FollowingStats.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = null;
                object.followerCount = null;
                object.followeeCount = null;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.followerCount != null && message.hasOwnProperty("followerCount"))
                object.followerCount = $root.google.protobuf.Int32Value.toObject(message.followerCount, options);
            if (message.followeeCount != null && message.hasOwnProperty("followeeCount"))
                object.followeeCount = $root.google.protobuf.Int32Value.toObject(message.followeeCount, options);
            return object;
        };

        /**
         * Converts this FollowingStats to JSON.
         * @function toJSON
         * @memberof following.FollowingStats
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FollowingStats.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FollowingStats
         * @function getTypeUrl
         * @memberof following.FollowingStats
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FollowingStats.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/following.FollowingStats";
        };

        return FollowingStats;
    })();

    return following;
})();

$root.user = (function() {

    /**
     * Namespace user.
     * @exports user
     * @namespace
     */
    var user = {};

    user.UserService = (function() {

        /**
         * Constructs a new UserService service.
         * @memberof user
         * @classdesc Represents a UserService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function UserService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (UserService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UserService;

        /**
         * Creates new UserService service using the specified rpc implementation.
         * @function create
         * @memberof user.UserService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {UserService} RPC service. Useful where requests and/or responses are streamed.
         */
        UserService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link user.UserService#getV2}.
         * @memberof user.UserService
         * @typedef GetV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof user.UserService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @param {user.UserService.GetV2Callback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getV2 = function getV2(request, callback) {
            return this.rpcCall(getV2, $root.user.UserIdRequest, $root.user.User, request, callback);
        }, "name", { value: "GetV2" });

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof user.UserService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getMultiV2}.
         * @memberof user.UserService
         * @typedef GetMultiV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.UserList} [response] UserList
         */

        /**
         * Calls GetMultiV2.
         * @function getMultiV2
         * @memberof user.UserService
         * @instance
         * @param {user.IUserGetMultiRequest} request UserGetMultiRequest message or plain object
         * @param {user.UserService.GetMultiV2Callback} callback Node-style callback called with the error, if any, and UserList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getMultiV2 = function getMultiV2(request, callback) {
            return this.rpcCall(getMultiV2, $root.user.UserGetMultiRequest, $root.user.UserList, request, callback);
        }, "name", { value: "GetMultiV2" });

        /**
         * Calls GetMultiV2.
         * @function getMultiV2
         * @memberof user.UserService
         * @instance
         * @param {user.IUserGetMultiRequest} request UserGetMultiRequest message or plain object
         * @returns {Promise<user.UserList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#get}.
         * @memberof user.UserService
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.UserGetResponseV1} [response] UserGetResponseV1
         */

        /**
         * Calls Get.
         * @function get
         * @memberof user.UserService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @param {user.UserService.GetCallback} callback Node-style callback called with the error, if any, and UserGetResponseV1
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.user.UserIdRequest, $root.user.UserGetResponseV1, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof user.UserService
         * @instance
         * @param {user.IUserIdRequest} request UserIdRequest message or plain object
         * @returns {Promise<user.UserGetResponseV1>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getMulti}.
         * @memberof user.UserService
         * @typedef GetMultiCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.UserGetMultiResponseV1} [response] UserGetMultiResponseV1
         */

        /**
         * Calls GetMulti.
         * @function getMulti
         * @memberof user.UserService
         * @instance
         * @param {user.IUserGetMultiRequest} request UserGetMultiRequest message or plain object
         * @param {user.UserService.GetMultiCallback} callback Node-style callback called with the error, if any, and UserGetMultiResponseV1
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getMulti = function getMulti(request, callback) {
            return this.rpcCall(getMulti, $root.user.UserGetMultiRequest, $root.user.UserGetMultiResponseV1, request, callback);
        }, "name", { value: "GetMulti" });

        /**
         * Calls GetMulti.
         * @function getMulti
         * @memberof user.UserService
         * @instance
         * @param {user.IUserGetMultiRequest} request UserGetMultiRequest message or plain object
         * @returns {Promise<user.UserGetMultiResponseV1>} Promise
         * @variation 2
         */

        return UserService;
    })();

    user.UserIdRequest = (function() {

        /**
         * Properties of a UserIdRequest.
         * @memberof user
         * @interface IUserIdRequest
         * @property {google.protobuf.IStringValue|null} [id] UserIdRequest id
         */

        /**
         * Constructs a new UserIdRequest.
         * @memberof user
         * @classdesc Represents a UserIdRequest.
         * @implements IUserIdRequest
         * @constructor
         * @param {user.IUserIdRequest=} [properties] Properties to set
         */
        function UserIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserIdRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof user.UserIdRequest
         * @instance
         */
        UserIdRequest.prototype.id = null;

        /**
         * Creates a new UserIdRequest instance using the specified properties.
         * @function create
         * @memberof user.UserIdRequest
         * @static
         * @param {user.IUserIdRequest=} [properties] Properties to set
         * @returns {user.UserIdRequest} UserIdRequest instance
         */
        UserIdRequest.create = function create(properties) {
            return new UserIdRequest(properties);
        };

        /**
         * Encodes the specified UserIdRequest message. Does not implicitly {@link user.UserIdRequest.verify|verify} messages.
         * @function encode
         * @memberof user.UserIdRequest
         * @static
         * @param {user.IUserIdRequest} message UserIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserIdRequest} UserIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserIdRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserIdRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserIdRequest} UserIdRequest
         */
        UserIdRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserIdRequest)
                return object;
            var message = new $root.user.UserIdRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".user.UserIdRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserIdRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserIdRequest
         * @static
         * @param {user.UserIdRequest} message UserIdRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserIdRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this UserIdRequest to JSON.
         * @function toJSON
         * @memberof user.UserIdRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserIdRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserIdRequest
         * @function getTypeUrl
         * @memberof user.UserIdRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserIdRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserIdRequest";
        };

        return UserIdRequest;
    })();

    user.UserGetMultiRequest = (function() {

        /**
         * Properties of a UserGetMultiRequest.
         * @memberof user
         * @interface IUserGetMultiRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [idList] UserGetMultiRequest idList
         */

        /**
         * Constructs a new UserGetMultiRequest.
         * @memberof user
         * @classdesc Represents a UserGetMultiRequest.
         * @implements IUserGetMultiRequest
         * @constructor
         * @param {user.IUserGetMultiRequest=} [properties] Properties to set
         */
        function UserGetMultiRequest(properties) {
            this.idList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserGetMultiRequest idList.
         * @member {Array.<google.protobuf.IStringValue>} idList
         * @memberof user.UserGetMultiRequest
         * @instance
         */
        UserGetMultiRequest.prototype.idList = $util.emptyArray;

        /**
         * Creates a new UserGetMultiRequest instance using the specified properties.
         * @function create
         * @memberof user.UserGetMultiRequest
         * @static
         * @param {user.IUserGetMultiRequest=} [properties] Properties to set
         * @returns {user.UserGetMultiRequest} UserGetMultiRequest instance
         */
        UserGetMultiRequest.create = function create(properties) {
            return new UserGetMultiRequest(properties);
        };

        /**
         * Encodes the specified UserGetMultiRequest message. Does not implicitly {@link user.UserGetMultiRequest.verify|verify} messages.
         * @function encode
         * @memberof user.UserGetMultiRequest
         * @static
         * @param {user.IUserGetMultiRequest} message UserGetMultiRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserGetMultiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idList != null && message.idList.length)
                for (var i = 0; i < message.idList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.idList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserGetMultiRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserGetMultiRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserGetMultiRequest} UserGetMultiRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserGetMultiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserGetMultiRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.idList && message.idList.length))
                        message.idList = [];
                    message.idList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserGetMultiRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserGetMultiRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserGetMultiRequest} UserGetMultiRequest
         */
        UserGetMultiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserGetMultiRequest)
                return object;
            var message = new $root.user.UserGetMultiRequest();
            if (object.idList) {
                if (!Array.isArray(object.idList))
                    throw TypeError(".user.UserGetMultiRequest.idList: array expected");
                message.idList = [];
                for (var i = 0; i < object.idList.length; ++i) {
                    if (typeof object.idList[i] !== "object")
                        throw TypeError(".user.UserGetMultiRequest.idList: object expected");
                    message.idList[i] = $root.google.protobuf.StringValue.fromObject(object.idList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UserGetMultiRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserGetMultiRequest
         * @static
         * @param {user.UserGetMultiRequest} message UserGetMultiRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserGetMultiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.idList = [];
            if (message.idList && message.idList.length) {
                object.idList = [];
                for (var j = 0; j < message.idList.length; ++j)
                    object.idList[j] = $root.google.protobuf.StringValue.toObject(message.idList[j], options);
            }
            return object;
        };

        /**
         * Converts this UserGetMultiRequest to JSON.
         * @function toJSON
         * @memberof user.UserGetMultiRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserGetMultiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserGetMultiRequest
         * @function getTypeUrl
         * @memberof user.UserGetMultiRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserGetMultiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserGetMultiRequest";
        };

        return UserGetMultiRequest;
    })();

    user.UserIdWithCursorRequest = (function() {

        /**
         * Properties of a UserIdWithCursorRequest.
         * @memberof user
         * @interface IUserIdWithCursorRequest
         * @property {google.protobuf.IStringValue|null} [id] UserIdWithCursorRequest id
         * @property {google.protobuf.IInt32Value|null} [limit] UserIdWithCursorRequest limit
         * @property {google.protobuf.IStringValue|null} [cursor] UserIdWithCursorRequest cursor
         */

        /**
         * Constructs a new UserIdWithCursorRequest.
         * @memberof user
         * @classdesc Represents a UserIdWithCursorRequest.
         * @implements IUserIdWithCursorRequest
         * @constructor
         * @param {user.IUserIdWithCursorRequest=} [properties] Properties to set
         */
        function UserIdWithCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserIdWithCursorRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof user.UserIdWithCursorRequest
         * @instance
         */
        UserIdWithCursorRequest.prototype.id = null;

        /**
         * UserIdWithCursorRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof user.UserIdWithCursorRequest
         * @instance
         */
        UserIdWithCursorRequest.prototype.limit = null;

        /**
         * UserIdWithCursorRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof user.UserIdWithCursorRequest
         * @instance
         */
        UserIdWithCursorRequest.prototype.cursor = null;

        /**
         * Creates a new UserIdWithCursorRequest instance using the specified properties.
         * @function create
         * @memberof user.UserIdWithCursorRequest
         * @static
         * @param {user.IUserIdWithCursorRequest=} [properties] Properties to set
         * @returns {user.UserIdWithCursorRequest} UserIdWithCursorRequest instance
         */
        UserIdWithCursorRequest.create = function create(properties) {
            return new UserIdWithCursorRequest(properties);
        };

        /**
         * Encodes the specified UserIdWithCursorRequest message. Does not implicitly {@link user.UserIdWithCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof user.UserIdWithCursorRequest
         * @static
         * @param {user.IUserIdWithCursorRequest} message UserIdWithCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserIdWithCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserIdWithCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserIdWithCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserIdWithCursorRequest} UserIdWithCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserIdWithCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserIdWithCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserIdWithCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserIdWithCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserIdWithCursorRequest} UserIdWithCursorRequest
         */
        UserIdWithCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserIdWithCursorRequest)
                return object;
            var message = new $root.user.UserIdWithCursorRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".user.UserIdWithCursorRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".user.UserIdWithCursorRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".user.UserIdWithCursorRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserIdWithCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserIdWithCursorRequest
         * @static
         * @param {user.UserIdWithCursorRequest} message UserIdWithCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserIdWithCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.limit = null;
                object.cursor = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this UserIdWithCursorRequest to JSON.
         * @function toJSON
         * @memberof user.UserIdWithCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserIdWithCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserIdWithCursorRequest
         * @function getTypeUrl
         * @memberof user.UserIdWithCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserIdWithCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserIdWithCursorRequest";
        };

        return UserIdWithCursorRequest;
    })();

    user.User = (function() {

        /**
         * Properties of a User.
         * @memberof user
         * @interface IUser
         * @property {google.protobuf.IStringValue|null} [id] User id
         * @property {google.protobuf.IStringValue|null} [name] User name
         * @property {google.protobuf.IStringValue|null} [profile] User profile
         * @property {resource.IImage|null} [thumbnail] User thumbnail
         * @property {resource.IImage|null} [coverImage] User coverImage
         * @property {google.protobuf.ITimestamp|null} [createdAt] User createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] User updatedAt
         * @property {google.protobuf.IBoolValue|null} [isVip] User isVip
         * @property {google.protobuf.IBoolValue|null} [isOfficialWriter] User isOfficialWriter
         * @property {google.protobuf.IBoolValue|null} [isInTrial] User isInTrial
         */

        /**
         * Constructs a new User.
         * @memberof user
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {user.IUser=} [properties] Properties to set
         */
        function User(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof user.User
         * @instance
         */
        User.prototype.id = null;

        /**
         * User name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof user.User
         * @instance
         */
        User.prototype.name = null;

        /**
         * User profile.
         * @member {google.protobuf.IStringValue|null|undefined} profile
         * @memberof user.User
         * @instance
         */
        User.prototype.profile = null;

        /**
         * User thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof user.User
         * @instance
         */
        User.prototype.thumbnail = null;

        /**
         * User coverImage.
         * @member {resource.IImage|null|undefined} coverImage
         * @memberof user.User
         * @instance
         */
        User.prototype.coverImage = null;

        /**
         * User createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof user.User
         * @instance
         */
        User.prototype.createdAt = null;

        /**
         * User updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof user.User
         * @instance
         */
        User.prototype.updatedAt = null;

        /**
         * User isVip.
         * @member {google.protobuf.IBoolValue|null|undefined} isVip
         * @memberof user.User
         * @instance
         */
        User.prototype.isVip = null;

        /**
         * User isOfficialWriter.
         * @member {google.protobuf.IBoolValue|null|undefined} isOfficialWriter
         * @memberof user.User
         * @instance
         */
        User.prototype.isOfficialWriter = null;

        /**
         * User isInTrial.
         * @member {google.protobuf.IBoolValue|null|undefined} isInTrial
         * @memberof user.User
         * @instance
         */
        User.prototype.isInTrial = null;

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof user.User
         * @static
         * @param {user.IUser=} [properties] Properties to set
         * @returns {user.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link user.User.verify|verify} messages.
         * @function encode
         * @memberof user.User
         * @static
         * @param {user.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.google.protobuf.StringValue.encode(message.profile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.coverImage != null && Object.hasOwnProperty.call(message, "coverImage"))
                $root.resource.Image.encode(message.coverImage, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.isVip != null && Object.hasOwnProperty.call(message, "isVip"))
                $root.google.protobuf.BoolValue.encode(message.isVip, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isOfficialWriter != null && Object.hasOwnProperty.call(message, "isOfficialWriter"))
                $root.google.protobuf.BoolValue.encode(message.isOfficialWriter, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.isInTrial != null && Object.hasOwnProperty.call(message, "isInTrial"))
                $root.google.protobuf.BoolValue.encode(message.isInTrial, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof user.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.User();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.profile = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.coverImage = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.isVip = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.isOfficialWriter = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.isInTrial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.User
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.User} User
         */
        User.fromObject = function fromObject(object) {
            if (object instanceof $root.user.User)
                return object;
            var message = new $root.user.User();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".user.User.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".user.User.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.profile != null) {
                if (typeof object.profile !== "object")
                    throw TypeError(".user.User.profile: object expected");
                message.profile = $root.google.protobuf.StringValue.fromObject(object.profile);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".user.User.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.coverImage != null) {
                if (typeof object.coverImage !== "object")
                    throw TypeError(".user.User.coverImage: object expected");
                message.coverImage = $root.resource.Image.fromObject(object.coverImage);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".user.User.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".user.User.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.isVip != null) {
                if (typeof object.isVip !== "object")
                    throw TypeError(".user.User.isVip: object expected");
                message.isVip = $root.google.protobuf.BoolValue.fromObject(object.isVip);
            }
            if (object.isOfficialWriter != null) {
                if (typeof object.isOfficialWriter !== "object")
                    throw TypeError(".user.User.isOfficialWriter: object expected");
                message.isOfficialWriter = $root.google.protobuf.BoolValue.fromObject(object.isOfficialWriter);
            }
            if (object.isInTrial != null) {
                if (typeof object.isInTrial !== "object")
                    throw TypeError(".user.User.isInTrial: object expected");
                message.isInTrial = $root.google.protobuf.BoolValue.fromObject(object.isInTrial);
            }
            return message;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.User
         * @static
         * @param {user.User} message User
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.name = null;
                object.profile = null;
                object.thumbnail = null;
                object.coverImage = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.isVip = null;
                object.isOfficialWriter = null;
                object.isInTrial = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.profile != null && message.hasOwnProperty("profile"))
                object.profile = $root.google.protobuf.StringValue.toObject(message.profile, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.coverImage != null && message.hasOwnProperty("coverImage"))
                object.coverImage = $root.resource.Image.toObject(message.coverImage, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.isVip != null && message.hasOwnProperty("isVip"))
                object.isVip = $root.google.protobuf.BoolValue.toObject(message.isVip, options);
            if (message.isOfficialWriter != null && message.hasOwnProperty("isOfficialWriter"))
                object.isOfficialWriter = $root.google.protobuf.BoolValue.toObject(message.isOfficialWriter, options);
            if (message.isInTrial != null && message.hasOwnProperty("isInTrial"))
                object.isInTrial = $root.google.protobuf.BoolValue.toObject(message.isInTrial, options);
            return object;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof user.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for User
         * @function getTypeUrl
         * @memberof user.User
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.User";
        };

        return User;
    })();

    user.UserList = (function() {

        /**
         * Properties of a UserList.
         * @memberof user
         * @interface IUserList
         * @property {Array.<user.IUser>|null} [userList] UserList userList
         */

        /**
         * Constructs a new UserList.
         * @memberof user
         * @classdesc Represents a UserList.
         * @implements IUserList
         * @constructor
         * @param {user.IUserList=} [properties] Properties to set
         */
        function UserList(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserList userList.
         * @member {Array.<user.IUser>} userList
         * @memberof user.UserList
         * @instance
         */
        UserList.prototype.userList = $util.emptyArray;

        /**
         * Creates a new UserList instance using the specified properties.
         * @function create
         * @memberof user.UserList
         * @static
         * @param {user.IUserList=} [properties] Properties to set
         * @returns {user.UserList} UserList instance
         */
        UserList.create = function create(properties) {
            return new UserList(properties);
        };

        /**
         * Encodes the specified UserList message. Does not implicitly {@link user.UserList.verify|verify} messages.
         * @function encode
         * @memberof user.UserList
         * @static
         * @param {user.IUserList} message UserList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.user.User.encode(message.userList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserList message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserList} UserList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.user.User.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserList} UserList
         */
        UserList.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserList)
                return object;
            var message = new $root.user.UserList();
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".user.UserList.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".user.UserList.userList: object expected");
                    message.userList[i] = $root.user.User.fromObject(object.userList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UserList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserList
         * @static
         * @param {user.UserList} message UserList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.user.User.toObject(message.userList[j], options);
            }
            return object;
        };

        /**
         * Converts this UserList to JSON.
         * @function toJSON
         * @memberof user.UserList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserList
         * @function getTypeUrl
         * @memberof user.UserList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserList";
        };

        return UserList;
    })();

    user.UserListWithCursor = (function() {

        /**
         * Properties of a UserListWithCursor.
         * @memberof user
         * @interface IUserListWithCursor
         * @property {Array.<user.IUser>|null} [userList] UserListWithCursor userList
         * @property {google.protobuf.IStringValue|null} [cursor] UserListWithCursor cursor
         */

        /**
         * Constructs a new UserListWithCursor.
         * @memberof user
         * @classdesc Represents a UserListWithCursor.
         * @implements IUserListWithCursor
         * @constructor
         * @param {user.IUserListWithCursor=} [properties] Properties to set
         */
        function UserListWithCursor(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserListWithCursor userList.
         * @member {Array.<user.IUser>} userList
         * @memberof user.UserListWithCursor
         * @instance
         */
        UserListWithCursor.prototype.userList = $util.emptyArray;

        /**
         * UserListWithCursor cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof user.UserListWithCursor
         * @instance
         */
        UserListWithCursor.prototype.cursor = null;

        /**
         * Creates a new UserListWithCursor instance using the specified properties.
         * @function create
         * @memberof user.UserListWithCursor
         * @static
         * @param {user.IUserListWithCursor=} [properties] Properties to set
         * @returns {user.UserListWithCursor} UserListWithCursor instance
         */
        UserListWithCursor.create = function create(properties) {
            return new UserListWithCursor(properties);
        };

        /**
         * Encodes the specified UserListWithCursor message. Does not implicitly {@link user.UserListWithCursor.verify|verify} messages.
         * @function encode
         * @memberof user.UserListWithCursor
         * @static
         * @param {user.IUserListWithCursor} message UserListWithCursor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserListWithCursor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.user.User.encode(message.userList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserListWithCursor message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserListWithCursor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserListWithCursor} UserListWithCursor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserListWithCursor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserListWithCursor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.user.User.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserListWithCursor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserListWithCursor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserListWithCursor} UserListWithCursor
         */
        UserListWithCursor.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserListWithCursor)
                return object;
            var message = new $root.user.UserListWithCursor();
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".user.UserListWithCursor.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".user.UserListWithCursor.userList: object expected");
                    message.userList[i] = $root.user.User.fromObject(object.userList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".user.UserListWithCursor.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserListWithCursor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserListWithCursor
         * @static
         * @param {user.UserListWithCursor} message UserListWithCursor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserListWithCursor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.user.User.toObject(message.userList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this UserListWithCursor to JSON.
         * @function toJSON
         * @memberof user.UserListWithCursor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserListWithCursor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserListWithCursor
         * @function getTypeUrl
         * @memberof user.UserListWithCursor
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserListWithCursor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserListWithCursor";
        };

        return UserListWithCursor;
    })();

    user.UserV1 = (function() {

        /**
         * Properties of a UserV1.
         * @memberof user
         * @interface IUserV1
         * @property {google.protobuf.IStringValue|null} [id] UserV1 id
         * @property {google.protobuf.IStringValue|null} [name] UserV1 name
         * @property {google.protobuf.IStringValue|null} [profile] UserV1 profile
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] UserV1 thumbnailUrl
         * @property {google.protobuf.IStringValue|null} [coverImageUrl] UserV1 coverImageUrl
         */

        /**
         * Constructs a new UserV1.
         * @memberof user
         * @classdesc Represents a UserV1.
         * @implements IUserV1
         * @constructor
         * @param {user.IUserV1=} [properties] Properties to set
         */
        function UserV1(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserV1 id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof user.UserV1
         * @instance
         */
        UserV1.prototype.id = null;

        /**
         * UserV1 name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof user.UserV1
         * @instance
         */
        UserV1.prototype.name = null;

        /**
         * UserV1 profile.
         * @member {google.protobuf.IStringValue|null|undefined} profile
         * @memberof user.UserV1
         * @instance
         */
        UserV1.prototype.profile = null;

        /**
         * UserV1 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof user.UserV1
         * @instance
         */
        UserV1.prototype.thumbnailUrl = null;

        /**
         * UserV1 coverImageUrl.
         * @member {google.protobuf.IStringValue|null|undefined} coverImageUrl
         * @memberof user.UserV1
         * @instance
         */
        UserV1.prototype.coverImageUrl = null;

        /**
         * Creates a new UserV1 instance using the specified properties.
         * @function create
         * @memberof user.UserV1
         * @static
         * @param {user.IUserV1=} [properties] Properties to set
         * @returns {user.UserV1} UserV1 instance
         */
        UserV1.create = function create(properties) {
            return new UserV1(properties);
        };

        /**
         * Encodes the specified UserV1 message. Does not implicitly {@link user.UserV1.verify|verify} messages.
         * @function encode
         * @memberof user.UserV1
         * @static
         * @param {user.IUserV1} message UserV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.google.protobuf.StringValue.encode(message.profile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.coverImageUrl != null && Object.hasOwnProperty.call(message, "coverImageUrl"))
                $root.google.protobuf.StringValue.encode(message.coverImageUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserV1 message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserV1} UserV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.profile = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.coverImageUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserV1} UserV1
         */
        UserV1.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserV1)
                return object;
            var message = new $root.user.UserV1();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".user.UserV1.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".user.UserV1.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.profile != null) {
                if (typeof object.profile !== "object")
                    throw TypeError(".user.UserV1.profile: object expected");
                message.profile = $root.google.protobuf.StringValue.fromObject(object.profile);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".user.UserV1.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.coverImageUrl != null) {
                if (typeof object.coverImageUrl !== "object")
                    throw TypeError(".user.UserV1.coverImageUrl: object expected");
                message.coverImageUrl = $root.google.protobuf.StringValue.fromObject(object.coverImageUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserV1
         * @static
         * @param {user.UserV1} message UserV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.name = null;
                object.profile = null;
                object.thumbnailUrl = null;
                object.coverImageUrl = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.profile != null && message.hasOwnProperty("profile"))
                object.profile = $root.google.protobuf.StringValue.toObject(message.profile, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.coverImageUrl != null && message.hasOwnProperty("coverImageUrl"))
                object.coverImageUrl = $root.google.protobuf.StringValue.toObject(message.coverImageUrl, options);
            return object;
        };

        /**
         * Converts this UserV1 to JSON.
         * @function toJSON
         * @memberof user.UserV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserV1
         * @function getTypeUrl
         * @memberof user.UserV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserV1";
        };

        return UserV1;
    })();

    user.UserGetResponseV1 = (function() {

        /**
         * Properties of a UserGetResponseV1.
         * @memberof user
         * @interface IUserGetResponseV1
         * @property {user.IUserV1|null} [user] UserGetResponseV1 user
         */

        /**
         * Constructs a new UserGetResponseV1.
         * @memberof user
         * @classdesc Represents a UserGetResponseV1.
         * @implements IUserGetResponseV1
         * @constructor
         * @param {user.IUserGetResponseV1=} [properties] Properties to set
         */
        function UserGetResponseV1(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserGetResponseV1 user.
         * @member {user.IUserV1|null|undefined} user
         * @memberof user.UserGetResponseV1
         * @instance
         */
        UserGetResponseV1.prototype.user = null;

        /**
         * Creates a new UserGetResponseV1 instance using the specified properties.
         * @function create
         * @memberof user.UserGetResponseV1
         * @static
         * @param {user.IUserGetResponseV1=} [properties] Properties to set
         * @returns {user.UserGetResponseV1} UserGetResponseV1 instance
         */
        UserGetResponseV1.create = function create(properties) {
            return new UserGetResponseV1(properties);
        };

        /**
         * Encodes the specified UserGetResponseV1 message. Does not implicitly {@link user.UserGetResponseV1.verify|verify} messages.
         * @function encode
         * @memberof user.UserGetResponseV1
         * @static
         * @param {user.IUserGetResponseV1} message UserGetResponseV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserGetResponseV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.user.UserV1.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserGetResponseV1 message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserGetResponseV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserGetResponseV1} UserGetResponseV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserGetResponseV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserGetResponseV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.user.UserV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserGetResponseV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserGetResponseV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserGetResponseV1} UserGetResponseV1
         */
        UserGetResponseV1.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserGetResponseV1)
                return object;
            var message = new $root.user.UserGetResponseV1();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".user.UserGetResponseV1.user: object expected");
                message.user = $root.user.UserV1.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserGetResponseV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserGetResponseV1
         * @static
         * @param {user.UserGetResponseV1} message UserGetResponseV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserGetResponseV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.user.UserV1.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this UserGetResponseV1 to JSON.
         * @function toJSON
         * @memberof user.UserGetResponseV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserGetResponseV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserGetResponseV1
         * @function getTypeUrl
         * @memberof user.UserGetResponseV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserGetResponseV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserGetResponseV1";
        };

        return UserGetResponseV1;
    })();

    user.UserGetMultiResponseV1 = (function() {

        /**
         * Properties of a UserGetMultiResponseV1.
         * @memberof user
         * @interface IUserGetMultiResponseV1
         * @property {Array.<user.IUserV1>|null} [userList] UserGetMultiResponseV1 userList
         */

        /**
         * Constructs a new UserGetMultiResponseV1.
         * @memberof user
         * @classdesc Represents a UserGetMultiResponseV1.
         * @implements IUserGetMultiResponseV1
         * @constructor
         * @param {user.IUserGetMultiResponseV1=} [properties] Properties to set
         */
        function UserGetMultiResponseV1(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserGetMultiResponseV1 userList.
         * @member {Array.<user.IUserV1>} userList
         * @memberof user.UserGetMultiResponseV1
         * @instance
         */
        UserGetMultiResponseV1.prototype.userList = $util.emptyArray;

        /**
         * Creates a new UserGetMultiResponseV1 instance using the specified properties.
         * @function create
         * @memberof user.UserGetMultiResponseV1
         * @static
         * @param {user.IUserGetMultiResponseV1=} [properties] Properties to set
         * @returns {user.UserGetMultiResponseV1} UserGetMultiResponseV1 instance
         */
        UserGetMultiResponseV1.create = function create(properties) {
            return new UserGetMultiResponseV1(properties);
        };

        /**
         * Encodes the specified UserGetMultiResponseV1 message. Does not implicitly {@link user.UserGetMultiResponseV1.verify|verify} messages.
         * @function encode
         * @memberof user.UserGetMultiResponseV1
         * @static
         * @param {user.IUserGetMultiResponseV1} message UserGetMultiResponseV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserGetMultiResponseV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.user.UserV1.encode(message.userList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserGetMultiResponseV1 message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserGetMultiResponseV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserGetMultiResponseV1} UserGetMultiResponseV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserGetMultiResponseV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserGetMultiResponseV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.user.UserV1.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserGetMultiResponseV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserGetMultiResponseV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserGetMultiResponseV1} UserGetMultiResponseV1
         */
        UserGetMultiResponseV1.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserGetMultiResponseV1)
                return object;
            var message = new $root.user.UserGetMultiResponseV1();
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".user.UserGetMultiResponseV1.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".user.UserGetMultiResponseV1.userList: object expected");
                    message.userList[i] = $root.user.UserV1.fromObject(object.userList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UserGetMultiResponseV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserGetMultiResponseV1
         * @static
         * @param {user.UserGetMultiResponseV1} message UserGetMultiResponseV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserGetMultiResponseV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.user.UserV1.toObject(message.userList[j], options);
            }
            return object;
        };

        /**
         * Converts this UserGetMultiResponseV1 to JSON.
         * @function toJSON
         * @memberof user.UserGetMultiResponseV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserGetMultiResponseV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserGetMultiResponseV1
         * @function getTypeUrl
         * @memberof user.UserGetMultiResponseV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserGetMultiResponseV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserGetMultiResponseV1";
        };

        return UserGetMultiResponseV1;
    })();

    return user;
})();

$root.user_selection = (function() {

    /**
     * Namespace user_selection.
     * @exports user_selection
     * @namespace
     */
    var user_selection = {};

    user_selection.UserSelectionV3 = (function() {

        /**
         * Properties of a UserSelectionV3.
         * @memberof user_selection
         * @interface IUserSelectionV3
         * @property {google.protobuf.IStringValue|null} [id] UserSelectionV3 id
         * @property {google.protobuf.IStringValue|null} [title] UserSelectionV3 title
         * @property {google.protobuf.IStringValue|null} [description] UserSelectionV3 description
         * @property {resource.IImage|null} [thumbnail] UserSelectionV3 thumbnail
         * @property {Array.<google.protobuf.IStringValue>|null} [userIdList] UserSelectionV3 userIdList
         * @property {google.protobuf.ITimestamp|null} [createdAt] UserSelectionV3 createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] UserSelectionV3 updatedAt
         */

        /**
         * Constructs a new UserSelectionV3.
         * @memberof user_selection
         * @classdesc Represents a UserSelectionV3.
         * @implements IUserSelectionV3
         * @constructor
         * @param {user_selection.IUserSelectionV3=} [properties] Properties to set
         */
        function UserSelectionV3(properties) {
            this.userIdList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserSelectionV3 id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof user_selection.UserSelectionV3
         * @instance
         */
        UserSelectionV3.prototype.id = null;

        /**
         * UserSelectionV3 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof user_selection.UserSelectionV3
         * @instance
         */
        UserSelectionV3.prototype.title = null;

        /**
         * UserSelectionV3 description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof user_selection.UserSelectionV3
         * @instance
         */
        UserSelectionV3.prototype.description = null;

        /**
         * UserSelectionV3 thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof user_selection.UserSelectionV3
         * @instance
         */
        UserSelectionV3.prototype.thumbnail = null;

        /**
         * UserSelectionV3 userIdList.
         * @member {Array.<google.protobuf.IStringValue>} userIdList
         * @memberof user_selection.UserSelectionV3
         * @instance
         */
        UserSelectionV3.prototype.userIdList = $util.emptyArray;

        /**
         * UserSelectionV3 createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof user_selection.UserSelectionV3
         * @instance
         */
        UserSelectionV3.prototype.createdAt = null;

        /**
         * UserSelectionV3 updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof user_selection.UserSelectionV3
         * @instance
         */
        UserSelectionV3.prototype.updatedAt = null;

        /**
         * Creates a new UserSelectionV3 instance using the specified properties.
         * @function create
         * @memberof user_selection.UserSelectionV3
         * @static
         * @param {user_selection.IUserSelectionV3=} [properties] Properties to set
         * @returns {user_selection.UserSelectionV3} UserSelectionV3 instance
         */
        UserSelectionV3.create = function create(properties) {
            return new UserSelectionV3(properties);
        };

        /**
         * Encodes the specified UserSelectionV3 message. Does not implicitly {@link user_selection.UserSelectionV3.verify|verify} messages.
         * @function encode
         * @memberof user_selection.UserSelectionV3
         * @static
         * @param {user_selection.IUserSelectionV3} message UserSelectionV3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSelectionV3.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userIdList != null && message.userIdList.length)
                for (var i = 0; i < message.userIdList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.userIdList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserSelectionV3 message from the specified reader or buffer.
         * @function decode
         * @memberof user_selection.UserSelectionV3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_selection.UserSelectionV3} UserSelectionV3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSelectionV3.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_selection.UserSelectionV3();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.userIdList && message.userIdList.length))
                        message.userIdList = [];
                    message.userIdList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserSelectionV3 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_selection.UserSelectionV3
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_selection.UserSelectionV3} UserSelectionV3
         */
        UserSelectionV3.fromObject = function fromObject(object) {
            if (object instanceof $root.user_selection.UserSelectionV3)
                return object;
            var message = new $root.user_selection.UserSelectionV3();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".user_selection.UserSelectionV3.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".user_selection.UserSelectionV3.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".user_selection.UserSelectionV3.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".user_selection.UserSelectionV3.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.userIdList) {
                if (!Array.isArray(object.userIdList))
                    throw TypeError(".user_selection.UserSelectionV3.userIdList: array expected");
                message.userIdList = [];
                for (var i = 0; i < object.userIdList.length; ++i) {
                    if (typeof object.userIdList[i] !== "object")
                        throw TypeError(".user_selection.UserSelectionV3.userIdList: object expected");
                    message.userIdList[i] = $root.google.protobuf.StringValue.fromObject(object.userIdList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".user_selection.UserSelectionV3.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".user_selection.UserSelectionV3.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserSelectionV3 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_selection.UserSelectionV3
         * @static
         * @param {user_selection.UserSelectionV3} message UserSelectionV3
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSelectionV3.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userIdList = [];
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.userIdList && message.userIdList.length) {
                object.userIdList = [];
                for (var j = 0; j < message.userIdList.length; ++j)
                    object.userIdList[j] = $root.google.protobuf.StringValue.toObject(message.userIdList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this UserSelectionV3 to JSON.
         * @function toJSON
         * @memberof user_selection.UserSelectionV3
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSelectionV3.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSelectionV3
         * @function getTypeUrl
         * @memberof user_selection.UserSelectionV3
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSelectionV3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_selection.UserSelectionV3";
        };

        return UserSelectionV3;
    })();

    user_selection.UserSelection = (function() {

        /**
         * Properties of a UserSelection.
         * @memberof user_selection
         * @interface IUserSelection
         * @property {google.protobuf.IStringValue|null} [id] UserSelection id
         * @property {google.protobuf.IStringValue|null} [title] UserSelection title
         * @property {google.protobuf.IStringValue|null} [description] UserSelection description
         * @property {resource.IImage|null} [thumbnail] UserSelection thumbnail
         * @property {Array.<user.IUser>|null} [userList] UserSelection userList
         * @property {google.protobuf.ITimestamp|null} [createdAt] UserSelection createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] UserSelection updatedAt
         */

        /**
         * Constructs a new UserSelection.
         * @memberof user_selection
         * @classdesc Represents a UserSelection.
         * @implements IUserSelection
         * @constructor
         * @param {user_selection.IUserSelection=} [properties] Properties to set
         */
        function UserSelection(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserSelection id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof user_selection.UserSelection
         * @instance
         */
        UserSelection.prototype.id = null;

        /**
         * UserSelection title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof user_selection.UserSelection
         * @instance
         */
        UserSelection.prototype.title = null;

        /**
         * UserSelection description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof user_selection.UserSelection
         * @instance
         */
        UserSelection.prototype.description = null;

        /**
         * UserSelection thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof user_selection.UserSelection
         * @instance
         */
        UserSelection.prototype.thumbnail = null;

        /**
         * UserSelection userList.
         * @member {Array.<user.IUser>} userList
         * @memberof user_selection.UserSelection
         * @instance
         */
        UserSelection.prototype.userList = $util.emptyArray;

        /**
         * UserSelection createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof user_selection.UserSelection
         * @instance
         */
        UserSelection.prototype.createdAt = null;

        /**
         * UserSelection updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof user_selection.UserSelection
         * @instance
         */
        UserSelection.prototype.updatedAt = null;

        /**
         * Creates a new UserSelection instance using the specified properties.
         * @function create
         * @memberof user_selection.UserSelection
         * @static
         * @param {user_selection.IUserSelection=} [properties] Properties to set
         * @returns {user_selection.UserSelection} UserSelection instance
         */
        UserSelection.create = function create(properties) {
            return new UserSelection(properties);
        };

        /**
         * Encodes the specified UserSelection message. Does not implicitly {@link user_selection.UserSelection.verify|verify} messages.
         * @function encode
         * @memberof user_selection.UserSelection
         * @static
         * @param {user_selection.IUserSelection} message UserSelection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSelection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.user.User.encode(message.userList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserSelection message from the specified reader or buffer.
         * @function decode
         * @memberof user_selection.UserSelection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_selection.UserSelection} UserSelection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSelection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_selection.UserSelection();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.user.User.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserSelection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_selection.UserSelection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_selection.UserSelection} UserSelection
         */
        UserSelection.fromObject = function fromObject(object) {
            if (object instanceof $root.user_selection.UserSelection)
                return object;
            var message = new $root.user_selection.UserSelection();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".user_selection.UserSelection.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".user_selection.UserSelection.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".user_selection.UserSelection.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".user_selection.UserSelection.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".user_selection.UserSelection.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".user_selection.UserSelection.userList: object expected");
                    message.userList[i] = $root.user.User.fromObject(object.userList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".user_selection.UserSelection.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".user_selection.UserSelection.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserSelection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_selection.UserSelection
         * @static
         * @param {user_selection.UserSelection} message UserSelection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSelection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.user.User.toObject(message.userList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this UserSelection to JSON.
         * @function toJSON
         * @memberof user_selection.UserSelection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSelection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSelection
         * @function getTypeUrl
         * @memberof user_selection.UserSelection
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSelection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_selection.UserSelection";
        };

        return UserSelection;
    })();

    user_selection.UserSelectionWithCursor = (function() {

        /**
         * Properties of a UserSelectionWithCursor.
         * @memberof user_selection
         * @interface IUserSelectionWithCursor
         * @property {user_selection.IUserSelection|null} [userSelection] UserSelectionWithCursor userSelection
         * @property {google.protobuf.IStringValue|null} [cursor] UserSelectionWithCursor cursor
         */

        /**
         * Constructs a new UserSelectionWithCursor.
         * @memberof user_selection
         * @classdesc Represents a UserSelectionWithCursor.
         * @implements IUserSelectionWithCursor
         * @constructor
         * @param {user_selection.IUserSelectionWithCursor=} [properties] Properties to set
         */
        function UserSelectionWithCursor(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserSelectionWithCursor userSelection.
         * @member {user_selection.IUserSelection|null|undefined} userSelection
         * @memberof user_selection.UserSelectionWithCursor
         * @instance
         */
        UserSelectionWithCursor.prototype.userSelection = null;

        /**
         * UserSelectionWithCursor cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof user_selection.UserSelectionWithCursor
         * @instance
         */
        UserSelectionWithCursor.prototype.cursor = null;

        /**
         * Creates a new UserSelectionWithCursor instance using the specified properties.
         * @function create
         * @memberof user_selection.UserSelectionWithCursor
         * @static
         * @param {user_selection.IUserSelectionWithCursor=} [properties] Properties to set
         * @returns {user_selection.UserSelectionWithCursor} UserSelectionWithCursor instance
         */
        UserSelectionWithCursor.create = function create(properties) {
            return new UserSelectionWithCursor(properties);
        };

        /**
         * Encodes the specified UserSelectionWithCursor message. Does not implicitly {@link user_selection.UserSelectionWithCursor.verify|verify} messages.
         * @function encode
         * @memberof user_selection.UserSelectionWithCursor
         * @static
         * @param {user_selection.IUserSelectionWithCursor} message UserSelectionWithCursor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSelectionWithCursor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userSelection != null && Object.hasOwnProperty.call(message, "userSelection"))
                $root.user_selection.UserSelection.encode(message.userSelection, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserSelectionWithCursor message from the specified reader or buffer.
         * @function decode
         * @memberof user_selection.UserSelectionWithCursor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_selection.UserSelectionWithCursor} UserSelectionWithCursor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSelectionWithCursor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_selection.UserSelectionWithCursor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userSelection = $root.user_selection.UserSelection.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserSelectionWithCursor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_selection.UserSelectionWithCursor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_selection.UserSelectionWithCursor} UserSelectionWithCursor
         */
        UserSelectionWithCursor.fromObject = function fromObject(object) {
            if (object instanceof $root.user_selection.UserSelectionWithCursor)
                return object;
            var message = new $root.user_selection.UserSelectionWithCursor();
            if (object.userSelection != null) {
                if (typeof object.userSelection !== "object")
                    throw TypeError(".user_selection.UserSelectionWithCursor.userSelection: object expected");
                message.userSelection = $root.user_selection.UserSelection.fromObject(object.userSelection);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".user_selection.UserSelectionWithCursor.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserSelectionWithCursor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_selection.UserSelectionWithCursor
         * @static
         * @param {user_selection.UserSelectionWithCursor} message UserSelectionWithCursor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSelectionWithCursor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userSelection = null;
                object.cursor = null;
            }
            if (message.userSelection != null && message.hasOwnProperty("userSelection"))
                object.userSelection = $root.user_selection.UserSelection.toObject(message.userSelection, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this UserSelectionWithCursor to JSON.
         * @function toJSON
         * @memberof user_selection.UserSelectionWithCursor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSelectionWithCursor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSelectionWithCursor
         * @function getTypeUrl
         * @memberof user_selection.UserSelectionWithCursor
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSelectionWithCursor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_selection.UserSelectionWithCursor";
        };

        return UserSelectionWithCursor;
    })();

    user_selection.UserSelectionGetResponse = (function() {

        /**
         * Properties of a UserSelectionGetResponse.
         * @memberof user_selection
         * @interface IUserSelectionGetResponse
         * @property {user_selection.IUserSelectionV1|null} [userSelection] UserSelectionGetResponse userSelection
         * @property {google.protobuf.IStringValue|null} [cursor] UserSelectionGetResponse cursor
         */

        /**
         * Constructs a new UserSelectionGetResponse.
         * @memberof user_selection
         * @classdesc Represents a UserSelectionGetResponse.
         * @implements IUserSelectionGetResponse
         * @constructor
         * @param {user_selection.IUserSelectionGetResponse=} [properties] Properties to set
         */
        function UserSelectionGetResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserSelectionGetResponse userSelection.
         * @member {user_selection.IUserSelectionV1|null|undefined} userSelection
         * @memberof user_selection.UserSelectionGetResponse
         * @instance
         */
        UserSelectionGetResponse.prototype.userSelection = null;

        /**
         * UserSelectionGetResponse cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof user_selection.UserSelectionGetResponse
         * @instance
         */
        UserSelectionGetResponse.prototype.cursor = null;

        /**
         * Creates a new UserSelectionGetResponse instance using the specified properties.
         * @function create
         * @memberof user_selection.UserSelectionGetResponse
         * @static
         * @param {user_selection.IUserSelectionGetResponse=} [properties] Properties to set
         * @returns {user_selection.UserSelectionGetResponse} UserSelectionGetResponse instance
         */
        UserSelectionGetResponse.create = function create(properties) {
            return new UserSelectionGetResponse(properties);
        };

        /**
         * Encodes the specified UserSelectionGetResponse message. Does not implicitly {@link user_selection.UserSelectionGetResponse.verify|verify} messages.
         * @function encode
         * @memberof user_selection.UserSelectionGetResponse
         * @static
         * @param {user_selection.IUserSelectionGetResponse} message UserSelectionGetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSelectionGetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userSelection != null && Object.hasOwnProperty.call(message, "userSelection"))
                $root.user_selection.UserSelectionV1.encode(message.userSelection, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserSelectionGetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user_selection.UserSelectionGetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_selection.UserSelectionGetResponse} UserSelectionGetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSelectionGetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_selection.UserSelectionGetResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userSelection = $root.user_selection.UserSelectionV1.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserSelectionGetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_selection.UserSelectionGetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_selection.UserSelectionGetResponse} UserSelectionGetResponse
         */
        UserSelectionGetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user_selection.UserSelectionGetResponse)
                return object;
            var message = new $root.user_selection.UserSelectionGetResponse();
            if (object.userSelection != null) {
                if (typeof object.userSelection !== "object")
                    throw TypeError(".user_selection.UserSelectionGetResponse.userSelection: object expected");
                message.userSelection = $root.user_selection.UserSelectionV1.fromObject(object.userSelection);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".user_selection.UserSelectionGetResponse.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserSelectionGetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_selection.UserSelectionGetResponse
         * @static
         * @param {user_selection.UserSelectionGetResponse} message UserSelectionGetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSelectionGetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userSelection = null;
                object.cursor = null;
            }
            if (message.userSelection != null && message.hasOwnProperty("userSelection"))
                object.userSelection = $root.user_selection.UserSelectionV1.toObject(message.userSelection, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this UserSelectionGetResponse to JSON.
         * @function toJSON
         * @memberof user_selection.UserSelectionGetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSelectionGetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSelectionGetResponse
         * @function getTypeUrl
         * @memberof user_selection.UserSelectionGetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSelectionGetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_selection.UserSelectionGetResponse";
        };

        return UserSelectionGetResponse;
    })();

    user_selection.UserSelectionV1 = (function() {

        /**
         * Properties of a UserSelectionV1.
         * @memberof user_selection
         * @interface IUserSelectionV1
         * @property {google.protobuf.IStringValue|null} [id] UserSelectionV1 id
         * @property {google.protobuf.IStringValue|null} [title] UserSelectionV1 title
         * @property {google.protobuf.IStringValue|null} [description] UserSelectionV1 description
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] UserSelectionV1 thumbnailUrl
         * @property {Array.<user.IUserV1>|null} [userList] UserSelectionV1 userList
         * @property {google.protobuf.ITimestamp|null} [createdAt] UserSelectionV1 createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] UserSelectionV1 updatedAt
         */

        /**
         * Constructs a new UserSelectionV1.
         * @memberof user_selection
         * @classdesc Represents a UserSelectionV1.
         * @implements IUserSelectionV1
         * @constructor
         * @param {user_selection.IUserSelectionV1=} [properties] Properties to set
         */
        function UserSelectionV1(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserSelectionV1 id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof user_selection.UserSelectionV1
         * @instance
         */
        UserSelectionV1.prototype.id = null;

        /**
         * UserSelectionV1 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof user_selection.UserSelectionV1
         * @instance
         */
        UserSelectionV1.prototype.title = null;

        /**
         * UserSelectionV1 description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof user_selection.UserSelectionV1
         * @instance
         */
        UserSelectionV1.prototype.description = null;

        /**
         * UserSelectionV1 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof user_selection.UserSelectionV1
         * @instance
         */
        UserSelectionV1.prototype.thumbnailUrl = null;

        /**
         * UserSelectionV1 userList.
         * @member {Array.<user.IUserV1>} userList
         * @memberof user_selection.UserSelectionV1
         * @instance
         */
        UserSelectionV1.prototype.userList = $util.emptyArray;

        /**
         * UserSelectionV1 createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof user_selection.UserSelectionV1
         * @instance
         */
        UserSelectionV1.prototype.createdAt = null;

        /**
         * UserSelectionV1 updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof user_selection.UserSelectionV1
         * @instance
         */
        UserSelectionV1.prototype.updatedAt = null;

        /**
         * Creates a new UserSelectionV1 instance using the specified properties.
         * @function create
         * @memberof user_selection.UserSelectionV1
         * @static
         * @param {user_selection.IUserSelectionV1=} [properties] Properties to set
         * @returns {user_selection.UserSelectionV1} UserSelectionV1 instance
         */
        UserSelectionV1.create = function create(properties) {
            return new UserSelectionV1(properties);
        };

        /**
         * Encodes the specified UserSelectionV1 message. Does not implicitly {@link user_selection.UserSelectionV1.verify|verify} messages.
         * @function encode
         * @memberof user_selection.UserSelectionV1
         * @static
         * @param {user_selection.IUserSelectionV1} message UserSelectionV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSelectionV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.user.UserV1.encode(message.userList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserSelectionV1 message from the specified reader or buffer.
         * @function decode
         * @memberof user_selection.UserSelectionV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_selection.UserSelectionV1} UserSelectionV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSelectionV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_selection.UserSelectionV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.user.UserV1.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserSelectionV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_selection.UserSelectionV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_selection.UserSelectionV1} UserSelectionV1
         */
        UserSelectionV1.fromObject = function fromObject(object) {
            if (object instanceof $root.user_selection.UserSelectionV1)
                return object;
            var message = new $root.user_selection.UserSelectionV1();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".user_selection.UserSelectionV1.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".user_selection.UserSelectionV1.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".user_selection.UserSelectionV1.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".user_selection.UserSelectionV1.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".user_selection.UserSelectionV1.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".user_selection.UserSelectionV1.userList: object expected");
                    message.userList[i] = $root.user.UserV1.fromObject(object.userList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".user_selection.UserSelectionV1.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".user_selection.UserSelectionV1.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserSelectionV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_selection.UserSelectionV1
         * @static
         * @param {user_selection.UserSelectionV1} message UserSelectionV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSelectionV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnailUrl = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.user.UserV1.toObject(message.userList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this UserSelectionV1 to JSON.
         * @function toJSON
         * @memberof user_selection.UserSelectionV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSelectionV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSelectionV1
         * @function getTypeUrl
         * @memberof user_selection.UserSelectionV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSelectionV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_selection.UserSelectionV1";
        };

        return UserSelectionV1;
    })();

    return user_selection;
})();

$root.like = (function() {

    /**
     * Namespace like.
     * @exports like
     * @namespace
     */
    var like = {};

    like.LikeService = (function() {

        /**
         * Constructs a new LikeService service.
         * @memberof like
         * @classdesc Represents a LikeService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function LikeService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (LikeService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LikeService;

        /**
         * Creates new LikeService service using the specified rpc implementation.
         * @function create
         * @memberof like.LikeService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {LikeService} RPC service. Useful where requests and/or responses are streamed.
         */
        LikeService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link like.LikeService#get}.
         * @memberof like.LikeService
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {like.LikeGetResponse} [response] LikeGetResponse
         */

        /**
         * Calls Get.
         * @function get
         * @memberof like.LikeService
         * @instance
         * @param {like.ILikeGetRequest} request LikeGetRequest message or plain object
         * @param {like.LikeService.GetCallback} callback Node-style callback called with the error, if any, and LikeGetResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LikeService.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.like.LikeGetRequest, $root.like.LikeGetResponse, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof like.LikeService
         * @instance
         * @param {like.ILikeGetRequest} request LikeGetRequest message or plain object
         * @returns {Promise<like.LikeGetResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link like.LikeService#getMulti}.
         * @memberof like.LikeService
         * @typedef GetMultiCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {like.LikeGetMultiResponse} [response] LikeGetMultiResponse
         */

        /**
         * Calls GetMulti.
         * @function getMulti
         * @memberof like.LikeService
         * @instance
         * @param {like.ILikeGetMultiRequest} request LikeGetMultiRequest message or plain object
         * @param {like.LikeService.GetMultiCallback} callback Node-style callback called with the error, if any, and LikeGetMultiResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LikeService.prototype.getMulti = function getMulti(request, callback) {
            return this.rpcCall(getMulti, $root.like.LikeGetMultiRequest, $root.like.LikeGetMultiResponse, request, callback);
        }, "name", { value: "GetMulti" });

        /**
         * Calls GetMulti.
         * @function getMulti
         * @memberof like.LikeService
         * @instance
         * @param {like.ILikeGetMultiRequest} request LikeGetMultiRequest message or plain object
         * @returns {Promise<like.LikeGetMultiResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link like.LikeService#like}.
         * @memberof like.LikeService
         * @typedef LikeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {like.LikeResponse} [response] LikeResponse
         */

        /**
         * Calls Like.
         * @function like
         * @memberof like.LikeService
         * @instance
         * @param {like.ILikeRequest} request LikeRequest message or plain object
         * @param {like.LikeService.LikeCallback} callback Node-style callback called with the error, if any, and LikeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LikeService.prototype.like = function like(request, callback) {
            return this.rpcCall(like, $root.like.LikeRequest, $root.like.LikeResponse, request, callback);
        }, "name", { value: "Like" });

        /**
         * Calls Like.
         * @function like
         * @memberof like.LikeService
         * @instance
         * @param {like.ILikeRequest} request LikeRequest message or plain object
         * @returns {Promise<like.LikeResponse>} Promise
         * @variation 2
         */

        return LikeService;
    })();

    like.Like = (function() {

        /**
         * Properties of a Like.
         * @memberof like
         * @interface ILike
         * @property {google.protobuf.IStringValue|null} [storyId] Like storyId
         * @property {google.protobuf.IInt32Value|null} [count] Like count
         */

        /**
         * Constructs a new Like.
         * @memberof like
         * @classdesc Represents a Like.
         * @implements ILike
         * @constructor
         * @param {like.ILike=} [properties] Properties to set
         */
        function Like(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Like storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof like.Like
         * @instance
         */
        Like.prototype.storyId = null;

        /**
         * Like count.
         * @member {google.protobuf.IInt32Value|null|undefined} count
         * @memberof like.Like
         * @instance
         */
        Like.prototype.count = null;

        /**
         * Creates a new Like instance using the specified properties.
         * @function create
         * @memberof like.Like
         * @static
         * @param {like.ILike=} [properties] Properties to set
         * @returns {like.Like} Like instance
         */
        Like.create = function create(properties) {
            return new Like(properties);
        };

        /**
         * Encodes the specified Like message. Does not implicitly {@link like.Like.verify|verify} messages.
         * @function encode
         * @memberof like.Like
         * @static
         * @param {like.ILike} message Like message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Like.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                $root.google.protobuf.Int32Value.encode(message.count, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Like message from the specified reader or buffer.
         * @function decode
         * @memberof like.Like
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {like.Like} Like
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Like.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.like.Like();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.count = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Like message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof like.Like
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {like.Like} Like
         */
        Like.fromObject = function fromObject(object) {
            if (object instanceof $root.like.Like)
                return object;
            var message = new $root.like.Like();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".like.Like.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.count != null) {
                if (typeof object.count !== "object")
                    throw TypeError(".like.Like.count: object expected");
                message.count = $root.google.protobuf.Int32Value.fromObject(object.count);
            }
            return message;
        };

        /**
         * Creates a plain object from a Like message. Also converts values to other types if specified.
         * @function toObject
         * @memberof like.Like
         * @static
         * @param {like.Like} message Like
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Like.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.count = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = $root.google.protobuf.Int32Value.toObject(message.count, options);
            return object;
        };

        /**
         * Converts this Like to JSON.
         * @function toJSON
         * @memberof like.Like
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Like.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Like
         * @function getTypeUrl
         * @memberof like.Like
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Like.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/like.Like";
        };

        return Like;
    })();

    like.LikeGetRequest = (function() {

        /**
         * Properties of a LikeGetRequest.
         * @memberof like
         * @interface ILikeGetRequest
         * @property {google.protobuf.IStringValue|null} [storyId] LikeGetRequest storyId
         */

        /**
         * Constructs a new LikeGetRequest.
         * @memberof like
         * @classdesc Represents a LikeGetRequest.
         * @implements ILikeGetRequest
         * @constructor
         * @param {like.ILikeGetRequest=} [properties] Properties to set
         */
        function LikeGetRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LikeGetRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof like.LikeGetRequest
         * @instance
         */
        LikeGetRequest.prototype.storyId = null;

        /**
         * Creates a new LikeGetRequest instance using the specified properties.
         * @function create
         * @memberof like.LikeGetRequest
         * @static
         * @param {like.ILikeGetRequest=} [properties] Properties to set
         * @returns {like.LikeGetRequest} LikeGetRequest instance
         */
        LikeGetRequest.create = function create(properties) {
            return new LikeGetRequest(properties);
        };

        /**
         * Encodes the specified LikeGetRequest message. Does not implicitly {@link like.LikeGetRequest.verify|verify} messages.
         * @function encode
         * @memberof like.LikeGetRequest
         * @static
         * @param {like.ILikeGetRequest} message LikeGetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LikeGetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a LikeGetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof like.LikeGetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {like.LikeGetRequest} LikeGetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LikeGetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.like.LikeGetRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a LikeGetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof like.LikeGetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {like.LikeGetRequest} LikeGetRequest
         */
        LikeGetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.like.LikeGetRequest)
                return object;
            var message = new $root.like.LikeGetRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".like.LikeGetRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            return message;
        };

        /**
         * Creates a plain object from a LikeGetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof like.LikeGetRequest
         * @static
         * @param {like.LikeGetRequest} message LikeGetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LikeGetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.storyId = null;
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            return object;
        };

        /**
         * Converts this LikeGetRequest to JSON.
         * @function toJSON
         * @memberof like.LikeGetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LikeGetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LikeGetRequest
         * @function getTypeUrl
         * @memberof like.LikeGetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LikeGetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/like.LikeGetRequest";
        };

        return LikeGetRequest;
    })();

    like.LikeGetResponse = (function() {

        /**
         * Properties of a LikeGetResponse.
         * @memberof like
         * @interface ILikeGetResponse
         * @property {like.ILike|null} [like] LikeGetResponse like
         */

        /**
         * Constructs a new LikeGetResponse.
         * @memberof like
         * @classdesc Represents a LikeGetResponse.
         * @implements ILikeGetResponse
         * @constructor
         * @param {like.ILikeGetResponse=} [properties] Properties to set
         */
        function LikeGetResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LikeGetResponse like.
         * @member {like.ILike|null|undefined} like
         * @memberof like.LikeGetResponse
         * @instance
         */
        LikeGetResponse.prototype.like = null;

        /**
         * Creates a new LikeGetResponse instance using the specified properties.
         * @function create
         * @memberof like.LikeGetResponse
         * @static
         * @param {like.ILikeGetResponse=} [properties] Properties to set
         * @returns {like.LikeGetResponse} LikeGetResponse instance
         */
        LikeGetResponse.create = function create(properties) {
            return new LikeGetResponse(properties);
        };

        /**
         * Encodes the specified LikeGetResponse message. Does not implicitly {@link like.LikeGetResponse.verify|verify} messages.
         * @function encode
         * @memberof like.LikeGetResponse
         * @static
         * @param {like.ILikeGetResponse} message LikeGetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LikeGetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.like != null && Object.hasOwnProperty.call(message, "like"))
                $root.like.Like.encode(message.like, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a LikeGetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof like.LikeGetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {like.LikeGetResponse} LikeGetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LikeGetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.like.LikeGetResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.like = $root.like.Like.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a LikeGetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof like.LikeGetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {like.LikeGetResponse} LikeGetResponse
         */
        LikeGetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.like.LikeGetResponse)
                return object;
            var message = new $root.like.LikeGetResponse();
            if (object.like != null) {
                if (typeof object.like !== "object")
                    throw TypeError(".like.LikeGetResponse.like: object expected");
                message.like = $root.like.Like.fromObject(object.like);
            }
            return message;
        };

        /**
         * Creates a plain object from a LikeGetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof like.LikeGetResponse
         * @static
         * @param {like.LikeGetResponse} message LikeGetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LikeGetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.like = null;
            if (message.like != null && message.hasOwnProperty("like"))
                object.like = $root.like.Like.toObject(message.like, options);
            return object;
        };

        /**
         * Converts this LikeGetResponse to JSON.
         * @function toJSON
         * @memberof like.LikeGetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LikeGetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LikeGetResponse
         * @function getTypeUrl
         * @memberof like.LikeGetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LikeGetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/like.LikeGetResponse";
        };

        return LikeGetResponse;
    })();

    like.LikeGetMultiRequest = (function() {

        /**
         * Properties of a LikeGetMultiRequest.
         * @memberof like
         * @interface ILikeGetMultiRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [storyIdList] LikeGetMultiRequest storyIdList
         */

        /**
         * Constructs a new LikeGetMultiRequest.
         * @memberof like
         * @classdesc Represents a LikeGetMultiRequest.
         * @implements ILikeGetMultiRequest
         * @constructor
         * @param {like.ILikeGetMultiRequest=} [properties] Properties to set
         */
        function LikeGetMultiRequest(properties) {
            this.storyIdList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LikeGetMultiRequest storyIdList.
         * @member {Array.<google.protobuf.IStringValue>} storyIdList
         * @memberof like.LikeGetMultiRequest
         * @instance
         */
        LikeGetMultiRequest.prototype.storyIdList = $util.emptyArray;

        /**
         * Creates a new LikeGetMultiRequest instance using the specified properties.
         * @function create
         * @memberof like.LikeGetMultiRequest
         * @static
         * @param {like.ILikeGetMultiRequest=} [properties] Properties to set
         * @returns {like.LikeGetMultiRequest} LikeGetMultiRequest instance
         */
        LikeGetMultiRequest.create = function create(properties) {
            return new LikeGetMultiRequest(properties);
        };

        /**
         * Encodes the specified LikeGetMultiRequest message. Does not implicitly {@link like.LikeGetMultiRequest.verify|verify} messages.
         * @function encode
         * @memberof like.LikeGetMultiRequest
         * @static
         * @param {like.ILikeGetMultiRequest} message LikeGetMultiRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LikeGetMultiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyIdList != null && message.storyIdList.length)
                for (var i = 0; i < message.storyIdList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.storyIdList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a LikeGetMultiRequest message from the specified reader or buffer.
         * @function decode
         * @memberof like.LikeGetMultiRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {like.LikeGetMultiRequest} LikeGetMultiRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LikeGetMultiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.like.LikeGetMultiRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyIdList && message.storyIdList.length))
                        message.storyIdList = [];
                    message.storyIdList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a LikeGetMultiRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof like.LikeGetMultiRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {like.LikeGetMultiRequest} LikeGetMultiRequest
         */
        LikeGetMultiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.like.LikeGetMultiRequest)
                return object;
            var message = new $root.like.LikeGetMultiRequest();
            if (object.storyIdList) {
                if (!Array.isArray(object.storyIdList))
                    throw TypeError(".like.LikeGetMultiRequest.storyIdList: array expected");
                message.storyIdList = [];
                for (var i = 0; i < object.storyIdList.length; ++i) {
                    if (typeof object.storyIdList[i] !== "object")
                        throw TypeError(".like.LikeGetMultiRequest.storyIdList: object expected");
                    message.storyIdList[i] = $root.google.protobuf.StringValue.fromObject(object.storyIdList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a LikeGetMultiRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof like.LikeGetMultiRequest
         * @static
         * @param {like.LikeGetMultiRequest} message LikeGetMultiRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LikeGetMultiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyIdList = [];
            if (message.storyIdList && message.storyIdList.length) {
                object.storyIdList = [];
                for (var j = 0; j < message.storyIdList.length; ++j)
                    object.storyIdList[j] = $root.google.protobuf.StringValue.toObject(message.storyIdList[j], options);
            }
            return object;
        };

        /**
         * Converts this LikeGetMultiRequest to JSON.
         * @function toJSON
         * @memberof like.LikeGetMultiRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LikeGetMultiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LikeGetMultiRequest
         * @function getTypeUrl
         * @memberof like.LikeGetMultiRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LikeGetMultiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/like.LikeGetMultiRequest";
        };

        return LikeGetMultiRequest;
    })();

    like.LikeGetMultiResponse = (function() {

        /**
         * Properties of a LikeGetMultiResponse.
         * @memberof like
         * @interface ILikeGetMultiResponse
         * @property {Array.<like.ILike>|null} [likeList] LikeGetMultiResponse likeList
         */

        /**
         * Constructs a new LikeGetMultiResponse.
         * @memberof like
         * @classdesc Represents a LikeGetMultiResponse.
         * @implements ILikeGetMultiResponse
         * @constructor
         * @param {like.ILikeGetMultiResponse=} [properties] Properties to set
         */
        function LikeGetMultiResponse(properties) {
            this.likeList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LikeGetMultiResponse likeList.
         * @member {Array.<like.ILike>} likeList
         * @memberof like.LikeGetMultiResponse
         * @instance
         */
        LikeGetMultiResponse.prototype.likeList = $util.emptyArray;

        /**
         * Creates a new LikeGetMultiResponse instance using the specified properties.
         * @function create
         * @memberof like.LikeGetMultiResponse
         * @static
         * @param {like.ILikeGetMultiResponse=} [properties] Properties to set
         * @returns {like.LikeGetMultiResponse} LikeGetMultiResponse instance
         */
        LikeGetMultiResponse.create = function create(properties) {
            return new LikeGetMultiResponse(properties);
        };

        /**
         * Encodes the specified LikeGetMultiResponse message. Does not implicitly {@link like.LikeGetMultiResponse.verify|verify} messages.
         * @function encode
         * @memberof like.LikeGetMultiResponse
         * @static
         * @param {like.ILikeGetMultiResponse} message LikeGetMultiResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LikeGetMultiResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.likeList != null && message.likeList.length)
                for (var i = 0; i < message.likeList.length; ++i)
                    $root.like.Like.encode(message.likeList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a LikeGetMultiResponse message from the specified reader or buffer.
         * @function decode
         * @memberof like.LikeGetMultiResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {like.LikeGetMultiResponse} LikeGetMultiResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LikeGetMultiResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.like.LikeGetMultiResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.likeList && message.likeList.length))
                        message.likeList = [];
                    message.likeList.push($root.like.Like.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a LikeGetMultiResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof like.LikeGetMultiResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {like.LikeGetMultiResponse} LikeGetMultiResponse
         */
        LikeGetMultiResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.like.LikeGetMultiResponse)
                return object;
            var message = new $root.like.LikeGetMultiResponse();
            if (object.likeList) {
                if (!Array.isArray(object.likeList))
                    throw TypeError(".like.LikeGetMultiResponse.likeList: array expected");
                message.likeList = [];
                for (var i = 0; i < object.likeList.length; ++i) {
                    if (typeof object.likeList[i] !== "object")
                        throw TypeError(".like.LikeGetMultiResponse.likeList: object expected");
                    message.likeList[i] = $root.like.Like.fromObject(object.likeList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a LikeGetMultiResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof like.LikeGetMultiResponse
         * @static
         * @param {like.LikeGetMultiResponse} message LikeGetMultiResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LikeGetMultiResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.likeList = [];
            if (message.likeList && message.likeList.length) {
                object.likeList = [];
                for (var j = 0; j < message.likeList.length; ++j)
                    object.likeList[j] = $root.like.Like.toObject(message.likeList[j], options);
            }
            return object;
        };

        /**
         * Converts this LikeGetMultiResponse to JSON.
         * @function toJSON
         * @memberof like.LikeGetMultiResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LikeGetMultiResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LikeGetMultiResponse
         * @function getTypeUrl
         * @memberof like.LikeGetMultiResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LikeGetMultiResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/like.LikeGetMultiResponse";
        };

        return LikeGetMultiResponse;
    })();

    like.LikeRequest = (function() {

        /**
         * Properties of a LikeRequest.
         * @memberof like
         * @interface ILikeRequest
         * @property {google.protobuf.IStringValue|null} [storyId] LikeRequest storyId
         * @property {google.protobuf.IInt32Value|null} [count] LikeRequest count
         */

        /**
         * Constructs a new LikeRequest.
         * @memberof like
         * @classdesc Represents a LikeRequest.
         * @implements ILikeRequest
         * @constructor
         * @param {like.ILikeRequest=} [properties] Properties to set
         */
        function LikeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LikeRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof like.LikeRequest
         * @instance
         */
        LikeRequest.prototype.storyId = null;

        /**
         * LikeRequest count.
         * @member {google.protobuf.IInt32Value|null|undefined} count
         * @memberof like.LikeRequest
         * @instance
         */
        LikeRequest.prototype.count = null;

        /**
         * Creates a new LikeRequest instance using the specified properties.
         * @function create
         * @memberof like.LikeRequest
         * @static
         * @param {like.ILikeRequest=} [properties] Properties to set
         * @returns {like.LikeRequest} LikeRequest instance
         */
        LikeRequest.create = function create(properties) {
            return new LikeRequest(properties);
        };

        /**
         * Encodes the specified LikeRequest message. Does not implicitly {@link like.LikeRequest.verify|verify} messages.
         * @function encode
         * @memberof like.LikeRequest
         * @static
         * @param {like.ILikeRequest} message LikeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LikeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                $root.google.protobuf.Int32Value.encode(message.count, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a LikeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof like.LikeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {like.LikeRequest} LikeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LikeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.like.LikeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.count = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a LikeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof like.LikeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {like.LikeRequest} LikeRequest
         */
        LikeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.like.LikeRequest)
                return object;
            var message = new $root.like.LikeRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".like.LikeRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.count != null) {
                if (typeof object.count !== "object")
                    throw TypeError(".like.LikeRequest.count: object expected");
                message.count = $root.google.protobuf.Int32Value.fromObject(object.count);
            }
            return message;
        };

        /**
         * Creates a plain object from a LikeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof like.LikeRequest
         * @static
         * @param {like.LikeRequest} message LikeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LikeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.count = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = $root.google.protobuf.Int32Value.toObject(message.count, options);
            return object;
        };

        /**
         * Converts this LikeRequest to JSON.
         * @function toJSON
         * @memberof like.LikeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LikeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LikeRequest
         * @function getTypeUrl
         * @memberof like.LikeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LikeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/like.LikeRequest";
        };

        return LikeRequest;
    })();

    like.LikeResponse = (function() {

        /**
         * Properties of a LikeResponse.
         * @memberof like
         * @interface ILikeResponse
         */

        /**
         * Constructs a new LikeResponse.
         * @memberof like
         * @classdesc Represents a LikeResponse.
         * @implements ILikeResponse
         * @constructor
         * @param {like.ILikeResponse=} [properties] Properties to set
         */
        function LikeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LikeResponse instance using the specified properties.
         * @function create
         * @memberof like.LikeResponse
         * @static
         * @param {like.ILikeResponse=} [properties] Properties to set
         * @returns {like.LikeResponse} LikeResponse instance
         */
        LikeResponse.create = function create(properties) {
            return new LikeResponse(properties);
        };

        /**
         * Encodes the specified LikeResponse message. Does not implicitly {@link like.LikeResponse.verify|verify} messages.
         * @function encode
         * @memberof like.LikeResponse
         * @static
         * @param {like.ILikeResponse} message LikeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LikeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a LikeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof like.LikeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {like.LikeResponse} LikeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LikeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.like.LikeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a LikeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof like.LikeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {like.LikeResponse} LikeResponse
         */
        LikeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.like.LikeResponse)
                return object;
            return new $root.like.LikeResponse();
        };

        /**
         * Creates a plain object from a LikeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof like.LikeResponse
         * @static
         * @param {like.LikeResponse} message LikeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LikeResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LikeResponse to JSON.
         * @function toJSON
         * @memberof like.LikeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LikeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LikeResponse
         * @function getTypeUrl
         * @memberof like.LikeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LikeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/like.LikeResponse";
        };

        return LikeResponse;
    })();

    return like;
})();

$root.me = (function() {

    /**
     * Namespace me.
     * @exports me
     * @namespace
     */
    var me = {};

    me.MeService = (function() {

        /**
         * Constructs a new MeService service.
         * @memberof me
         * @classdesc Represents a MeService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MeService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MeService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MeService;

        /**
         * Creates new MeService service using the specified rpc implementation.
         * @function create
         * @memberof me.MeService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {MeService} RPC service. Useful where requests and/or responses are streamed.
         */
        MeService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link me.MeService#getV2}.
         * @memberof me.MeService
         * @typedef GetV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.GetV2Callback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getV2 = function getV2(request, callback) {
            return this.rpcCall(getV2, $root.types.Empty, $root.user.User, request, callback);
        }, "name", { value: "GetV2" });

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#updateV2}.
         * @memberof me.MeService
         * @typedef UpdateV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls UpdateV2.
         * @function updateV2
         * @memberof me.MeService
         * @instance
         * @param {user.IUser} request User message or plain object
         * @param {me.MeService.UpdateV2Callback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.updateV2 = function updateV2(request, callback) {
            return this.rpcCall(updateV2, $root.user.User, $root.user.User, request, callback);
        }, "name", { value: "UpdateV2" });

        /**
         * Calls UpdateV2.
         * @function updateV2
         * @memberof me.MeService
         * @instance
         * @param {user.IUser} request User message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#updateNotificationTokenV2}.
         * @memberof me.MeService
         * @typedef UpdateNotificationTokenV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateNotificationTokenV2.
         * @function updateNotificationTokenV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateNotificationTokenRequestV2} request MeUpdateNotificationTokenRequestV2 message or plain object
         * @param {me.MeService.UpdateNotificationTokenV2Callback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.updateNotificationTokenV2 = function updateNotificationTokenV2(request, callback) {
            return this.rpcCall(updateNotificationTokenV2, $root.me.MeUpdateNotificationTokenRequestV2, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateNotificationTokenV2" });

        /**
         * Calls UpdateNotificationTokenV2.
         * @function updateNotificationTokenV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateNotificationTokenRequestV2} request MeUpdateNotificationTokenRequestV2 message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getNotificationPreference}.
         * @memberof me.MeService
         * @typedef GetNotificationPreferenceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {notification.NotificationPreference} [response] NotificationPreference
         */

        /**
         * Calls GetNotificationPreference.
         * @function getNotificationPreference
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.GetNotificationPreferenceCallback} callback Node-style callback called with the error, if any, and NotificationPreference
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getNotificationPreference = function getNotificationPreference(request, callback) {
            return this.rpcCall(getNotificationPreference, $root.types.Empty, $root.notification.NotificationPreference, request, callback);
        }, "name", { value: "GetNotificationPreference" });

        /**
         * Calls GetNotificationPreference.
         * @function getNotificationPreference
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<notification.NotificationPreference>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#updateNotificationPreference}.
         * @memberof me.MeService
         * @typedef UpdateNotificationPreferenceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateNotificationPreference.
         * @function updateNotificationPreference
         * @memberof me.MeService
         * @instance
         * @param {notification.INotificationPreference} request NotificationPreference message or plain object
         * @param {me.MeService.UpdateNotificationPreferenceCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.updateNotificationPreference = function updateNotificationPreference(request, callback) {
            return this.rpcCall(updateNotificationPreference, $root.notification.NotificationPreference, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateNotificationPreference" });

        /**
         * Calls UpdateNotificationPreference.
         * @function updateNotificationPreference
         * @memberof me.MeService
         * @instance
         * @param {notification.INotificationPreference} request NotificationPreference message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#issueAuthCustomToken}.
         * @memberof me.MeService
         * @typedef IssueAuthCustomTokenCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.AuthCustomToken} [response] AuthCustomToken
         */

        /**
         * Calls IssueAuthCustomToken.
         * @function issueAuthCustomToken
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.IssueAuthCustomTokenCallback} callback Node-style callback called with the error, if any, and AuthCustomToken
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.issueAuthCustomToken = function issueAuthCustomToken(request, callback) {
            return this.rpcCall(issueAuthCustomToken, $root.types.Empty, $root.me.AuthCustomToken, request, callback);
        }, "name", { value: "IssueAuthCustomToken" });

        /**
         * Calls IssueAuthCustomToken.
         * @function issueAuthCustomToken
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<me.AuthCustomToken>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getReadingProgress}.
         * @memberof me.MeService
         * @typedef GetReadingProgressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeGetReadingProgressResponse} [response] MeGetReadingProgressResponse
         */

        /**
         * Calls GetReadingProgress.
         * @function getReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeGetReadingProgressRequest} request MeGetReadingProgressRequest message or plain object
         * @param {me.MeService.GetReadingProgressCallback} callback Node-style callback called with the error, if any, and MeGetReadingProgressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getReadingProgress = function getReadingProgress(request, callback) {
            return this.rpcCall(getReadingProgress, $root.me.MeGetReadingProgressRequest, $root.me.MeGetReadingProgressResponse, request, callback);
        }, "name", { value: "GetReadingProgress" });

        /**
         * Calls GetReadingProgress.
         * @function getReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeGetReadingProgressRequest} request MeGetReadingProgressRequest message or plain object
         * @returns {Promise<me.MeGetReadingProgressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getMultiReadingProgress}.
         * @memberof me.MeService
         * @typedef GetMultiReadingProgressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeGetMultiReadingProgressResponse} [response] MeGetMultiReadingProgressResponse
         */

        /**
         * Calls GetMultiReadingProgress.
         * @function getMultiReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeGetMultiReadingProgressRequest} request MeGetMultiReadingProgressRequest message or plain object
         * @param {me.MeService.GetMultiReadingProgressCallback} callback Node-style callback called with the error, if any, and MeGetMultiReadingProgressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getMultiReadingProgress = function getMultiReadingProgress(request, callback) {
            return this.rpcCall(getMultiReadingProgress, $root.me.MeGetMultiReadingProgressRequest, $root.me.MeGetMultiReadingProgressResponse, request, callback);
        }, "name", { value: "GetMultiReadingProgress" });

        /**
         * Calls GetMultiReadingProgress.
         * @function getMultiReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeGetMultiReadingProgressRequest} request MeGetMultiReadingProgressRequest message or plain object
         * @returns {Promise<me.MeGetMultiReadingProgressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#updateReadingProgress}.
         * @memberof me.MeService
         * @typedef UpdateReadingProgressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeUpdateReadingProgressResponse} [response] MeUpdateReadingProgressResponse
         */

        /**
         * Calls UpdateReadingProgress.
         * @function updateReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateReadingProgressRequest} request MeUpdateReadingProgressRequest message or plain object
         * @param {me.MeService.UpdateReadingProgressCallback} callback Node-style callback called with the error, if any, and MeUpdateReadingProgressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.updateReadingProgress = function updateReadingProgress(request, callback) {
            return this.rpcCall(updateReadingProgress, $root.me.MeUpdateReadingProgressRequest, $root.me.MeUpdateReadingProgressResponse, request, callback);
        }, "name", { value: "UpdateReadingProgress" });

        /**
         * Calls UpdateReadingProgress.
         * @function updateReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateReadingProgressRequest} request MeUpdateReadingProgressRequest message or plain object
         * @returns {Promise<me.MeUpdateReadingProgressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#listReadingProgress}.
         * @memberof me.MeService
         * @typedef ListReadingProgressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeListReadingProgressResponse} [response] MeListReadingProgressResponse
         */

        /**
         * Calls ListReadingProgress.
         * @function listReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListReadingProgressRequest} request MeListReadingProgressRequest message or plain object
         * @param {me.MeService.ListReadingProgressCallback} callback Node-style callback called with the error, if any, and MeListReadingProgressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.listReadingProgress = function listReadingProgress(request, callback) {
            return this.rpcCall(listReadingProgress, $root.me.MeListReadingProgressRequest, $root.me.MeListReadingProgressResponse, request, callback);
        }, "name", { value: "ListReadingProgress" });

        /**
         * Calls ListReadingProgress.
         * @function listReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListReadingProgressRequest} request MeListReadingProgressRequest message or plain object
         * @returns {Promise<me.MeListReadingProgressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#block}.
         * @memberof me.MeService
         * @typedef BlockCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls Block.
         * @function block
         * @memberof me.MeService
         * @instance
         * @param {me.IMeBlockRequest} request MeBlockRequest message or plain object
         * @param {me.MeService.BlockCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.block = function block(request, callback) {
            return this.rpcCall(block, $root.me.MeBlockRequest, $root.types.Empty, request, callback);
        }, "name", { value: "Block" });

        /**
         * Calls Block.
         * @function block
         * @memberof me.MeService
         * @instance
         * @param {me.IMeBlockRequest} request MeBlockRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#unblock}.
         * @memberof me.MeService
         * @typedef UnblockCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls Unblock.
         * @function unblock
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUnblockRequest} request MeUnblockRequest message or plain object
         * @param {me.MeService.UnblockCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.unblock = function unblock(request, callback) {
            return this.rpcCall(unblock, $root.me.MeUnblockRequest, $root.types.Empty, request, callback);
        }, "name", { value: "Unblock" });

        /**
         * Calls Unblock.
         * @function unblock
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUnblockRequest} request MeUnblockRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#listBlockingV2}.
         * @memberof me.MeService
         * @typedef ListBlockingV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_selection.UserSelectionWithCursor} [response] UserSelectionWithCursor
         */

        /**
         * Calls ListBlockingV2.
         * @function listBlockingV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListBlockingRequest} request MeListBlockingRequest message or plain object
         * @param {me.MeService.ListBlockingV2Callback} callback Node-style callback called with the error, if any, and UserSelectionWithCursor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.listBlockingV2 = function listBlockingV2(request, callback) {
            return this.rpcCall(listBlockingV2, $root.me.MeListBlockingRequest, $root.user_selection.UserSelectionWithCursor, request, callback);
        }, "name", { value: "ListBlockingV2" });

        /**
         * Calls ListBlockingV2.
         * @function listBlockingV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListBlockingRequest} request MeListBlockingRequest message or plain object
         * @returns {Promise<user_selection.UserSelectionWithCursor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#isBlockedBy}.
         * @memberof me.MeService
         * @typedef IsBlockedByCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeIsBlockedByResponse} [response] MeIsBlockedByResponse
         */

        /**
         * Calls IsBlockedBy.
         * @function isBlockedBy
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsBlockedByRequest} request MeIsBlockedByRequest message or plain object
         * @param {me.MeService.IsBlockedByCallback} callback Node-style callback called with the error, if any, and MeIsBlockedByResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.isBlockedBy = function isBlockedBy(request, callback) {
            return this.rpcCall(isBlockedBy, $root.me.MeIsBlockedByRequest, $root.me.MeIsBlockedByResponse, request, callback);
        }, "name", { value: "IsBlockedBy" });

        /**
         * Calls IsBlockedBy.
         * @function isBlockedBy
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsBlockedByRequest} request MeIsBlockedByRequest message or plain object
         * @returns {Promise<me.MeIsBlockedByResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getNotificationCountSinceV2}.
         * @memberof me.MeService
         * @typedef GetNotificationCountSinceV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeGetNotificationCountSinceResponse} [response] MeGetNotificationCountSinceResponse
         */

        /**
         * Calls GetNotificationCountSinceV2.
         * @function getNotificationCountSinceV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeGetNotificationCountSinceRequestV2} request MeGetNotificationCountSinceRequestV2 message or plain object
         * @param {me.MeService.GetNotificationCountSinceV2Callback} callback Node-style callback called with the error, if any, and MeGetNotificationCountSinceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getNotificationCountSinceV2 = function getNotificationCountSinceV2(request, callback) {
            return this.rpcCall(getNotificationCountSinceV2, $root.me.MeGetNotificationCountSinceRequestV2, $root.me.MeGetNotificationCountSinceResponse, request, callback);
        }, "name", { value: "GetNotificationCountSinceV2" });

        /**
         * Calls GetNotificationCountSinceV2.
         * @function getNotificationCountSinceV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeGetNotificationCountSinceRequestV2} request MeGetNotificationCountSinceRequestV2 message or plain object
         * @returns {Promise<me.MeGetNotificationCountSinceResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#listAudio}.
         * @memberof me.MeService
         * @typedef ListAudioCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeListAudioResponse} [response] MeListAudioResponse
         */

        /**
         * Calls ListAudio.
         * @function listAudio
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListAudioRequest} request MeListAudioRequest message or plain object
         * @param {me.MeService.ListAudioCallback} callback Node-style callback called with the error, if any, and MeListAudioResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.listAudio = function listAudio(request, callback) {
            return this.rpcCall(listAudio, $root.me.MeListAudioRequest, $root.me.MeListAudioResponse, request, callback);
        }, "name", { value: "ListAudio" });

        /**
         * Calls ListAudio.
         * @function listAudio
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListAudioRequest} request MeListAudioRequest message or plain object
         * @returns {Promise<me.MeListAudioResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getPermission}.
         * @memberof me.MeService
         * @typedef GetPermissionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {permission.Permission} [response] Permission
         */

        /**
         * Calls GetPermission.
         * @function getPermission
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.GetPermissionCallback} callback Node-style callback called with the error, if any, and Permission
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getPermission = function getPermission(request, callback) {
            return this.rpcCall(getPermission, $root.types.Empty, $root.permission.Permission, request, callback);
        }, "name", { value: "GetPermission" });

        /**
         * Calls GetPermission.
         * @function getPermission
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<permission.Permission>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getPaw}.
         * @memberof me.MeService
         * @typedef GetPawCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {paw.Paw} [response] Paw
         */

        /**
         * Calls GetPaw.
         * @function getPaw
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.GetPawCallback} callback Node-style callback called with the error, if any, and Paw
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getPaw = function getPaw(request, callback) {
            return this.rpcCall(getPaw, $root.types.Empty, $root.paw.Paw, request, callback);
        }, "name", { value: "GetPaw" });

        /**
         * Calls GetPaw.
         * @function getPaw
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<paw.Paw>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#usePaw}.
         * @memberof me.MeService
         * @typedef UsePawCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {paw.Paw} [response] Paw
         */

        /**
         * Calls UsePaw.
         * @function usePaw
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUsePawRequest} request MeUsePawRequest message or plain object
         * @param {me.MeService.UsePawCallback} callback Node-style callback called with the error, if any, and Paw
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.usePaw = function usePaw(request, callback) {
            return this.rpcCall(usePaw, $root.me.MeUsePawRequest, $root.paw.Paw, request, callback);
        }, "name", { value: "UsePaw" });

        /**
         * Calls UsePaw.
         * @function usePaw
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUsePawRequest} request MeUsePawRequest message or plain object
         * @returns {Promise<paw.Paw>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getHalfReadingStorySelectionV2}.
         * @memberof me.MeService
         * @typedef GetHalfReadingStorySelectionV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithCursorV3} [response] StorySelectionWithCursorV3
         */

        /**
         * Calls GetHalfReadingStorySelectionV2.
         * @function getHalfReadingStorySelectionV2
         * @memberof me.MeService
         * @instance
         * @param {me.IGetHalfReadingStorySelectionRequest} request GetHalfReadingStorySelectionRequest message or plain object
         * @param {me.MeService.GetHalfReadingStorySelectionV2Callback} callback Node-style callback called with the error, if any, and StorySelectionWithCursorV3
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getHalfReadingStorySelectionV2 = function getHalfReadingStorySelectionV2(request, callback) {
            return this.rpcCall(getHalfReadingStorySelectionV2, $root.me.GetHalfReadingStorySelectionRequest, $root.story_selection.StorySelectionWithCursorV3, request, callback);
        }, "name", { value: "GetHalfReadingStorySelectionV2" });

        /**
         * Calls GetHalfReadingStorySelectionV2.
         * @function getHalfReadingStorySelectionV2
         * @memberof me.MeService
         * @instance
         * @param {me.IGetHalfReadingStorySelectionRequest} request GetHalfReadingStorySelectionRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionWithCursorV3>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getNextStoryByRecentReadingProgress}.
         * @memberof me.MeService
         * @typedef GetNextStoryByRecentReadingProgressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeGetNextStoryByRecentReadingProgressResponse} [response] MeGetNextStoryByRecentReadingProgressResponse
         */

        /**
         * Calls GetNextStoryByRecentReadingProgress.
         * @function getNextStoryByRecentReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.GetNextStoryByRecentReadingProgressCallback} callback Node-style callback called with the error, if any, and MeGetNextStoryByRecentReadingProgressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getNextStoryByRecentReadingProgress = function getNextStoryByRecentReadingProgress(request, callback) {
            return this.rpcCall(getNextStoryByRecentReadingProgress, $root.types.Empty, $root.me.MeGetNextStoryByRecentReadingProgressResponse, request, callback);
        }, "name", { value: "GetNextStoryByRecentReadingProgress" });

        /**
         * Calls GetNextStoryByRecentReadingProgress.
         * @function getNextStoryByRecentReadingProgress
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<me.MeGetNextStoryByRecentReadingProgressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getCommentRestrictionPreference}.
         * @memberof me.MeService
         * @typedef GetCommentRestrictionPreferenceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeGetCommentRestrictionPreferenceResponse} [response] MeGetCommentRestrictionPreferenceResponse
         */

        /**
         * Calls GetCommentRestrictionPreference.
         * @function getCommentRestrictionPreference
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.GetCommentRestrictionPreferenceCallback} callback Node-style callback called with the error, if any, and MeGetCommentRestrictionPreferenceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getCommentRestrictionPreference = function getCommentRestrictionPreference(request, callback) {
            return this.rpcCall(getCommentRestrictionPreference, $root.types.Empty, $root.me.MeGetCommentRestrictionPreferenceResponse, request, callback);
        }, "name", { value: "GetCommentRestrictionPreference" });

        /**
         * Calls GetCommentRestrictionPreference.
         * @function getCommentRestrictionPreference
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<me.MeGetCommentRestrictionPreferenceResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#updateCommentRestrictionPreference}.
         * @memberof me.MeService
         * @typedef UpdateCommentRestrictionPreferenceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateCommentRestrictionPreference.
         * @function updateCommentRestrictionPreference
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateCommentRestrictionPreferenceRequest} request MeUpdateCommentRestrictionPreferenceRequest message or plain object
         * @param {me.MeService.UpdateCommentRestrictionPreferenceCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.updateCommentRestrictionPreference = function updateCommentRestrictionPreference(request, callback) {
            return this.rpcCall(updateCommentRestrictionPreference, $root.me.MeUpdateCommentRestrictionPreferenceRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateCommentRestrictionPreference" });

        /**
         * Calls UpdateCommentRestrictionPreference.
         * @function updateCommentRestrictionPreference
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateCommentRestrictionPreferenceRequest} request MeUpdateCommentRestrictionPreferenceRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#isFollowing}.
         * @memberof me.MeService
         * @typedef IsFollowingCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.FollowingStatus} [response] FollowingStatus
         */

        /**
         * Calls IsFollowing.
         * @function isFollowing
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsFollowingRequest} request MeIsFollowingRequest message or plain object
         * @param {me.MeService.IsFollowingCallback} callback Node-style callback called with the error, if any, and FollowingStatus
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.isFollowing = function isFollowing(request, callback) {
            return this.rpcCall(isFollowing, $root.me.MeIsFollowingRequest, $root.me.FollowingStatus, request, callback);
        }, "name", { value: "IsFollowing" });

        /**
         * Calls IsFollowing.
         * @function isFollowing
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsFollowingRequest} request MeIsFollowingRequest message or plain object
         * @returns {Promise<me.FollowingStatus>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#isFollowingMulti}.
         * @memberof me.MeService
         * @typedef IsFollowingMultiCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeIsFollowingMultiResponse} [response] MeIsFollowingMultiResponse
         */

        /**
         * Calls IsFollowingMulti.
         * @function isFollowingMulti
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsFollowingMultiRequest} request MeIsFollowingMultiRequest message or plain object
         * @param {me.MeService.IsFollowingMultiCallback} callback Node-style callback called with the error, if any, and MeIsFollowingMultiResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.isFollowingMulti = function isFollowingMulti(request, callback) {
            return this.rpcCall(isFollowingMulti, $root.me.MeIsFollowingMultiRequest, $root.me.MeIsFollowingMultiResponse, request, callback);
        }, "name", { value: "IsFollowingMulti" });

        /**
         * Calls IsFollowingMulti.
         * @function isFollowingMulti
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsFollowingMultiRequest} request MeIsFollowingMultiRequest message or plain object
         * @returns {Promise<me.MeIsFollowingMultiResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#isBlocking}.
         * @memberof me.MeService
         * @typedef IsBlockingCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.BlockingStatus} [response] BlockingStatus
         */

        /**
         * Calls IsBlocking.
         * @function isBlocking
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsBlockingRequest} request MeIsBlockingRequest message or plain object
         * @param {me.MeService.IsBlockingCallback} callback Node-style callback called with the error, if any, and BlockingStatus
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.isBlocking = function isBlocking(request, callback) {
            return this.rpcCall(isBlocking, $root.me.MeIsBlockingRequest, $root.me.BlockingStatus, request, callback);
        }, "name", { value: "IsBlocking" });

        /**
         * Calls IsBlocking.
         * @function isBlocking
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsBlockingRequest} request MeIsBlockingRequest message or plain object
         * @returns {Promise<me.BlockingStatus>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#isBlockingMulti}.
         * @memberof me.MeService
         * @typedef IsBlockingMultiCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeIsBlockingMultiResponse} [response] MeIsBlockingMultiResponse
         */

        /**
         * Calls IsBlockingMulti.
         * @function isBlockingMulti
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsBlockingMultiRequest} request MeIsBlockingMultiRequest message or plain object
         * @param {me.MeService.IsBlockingMultiCallback} callback Node-style callback called with the error, if any, and MeIsBlockingMultiResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.isBlockingMulti = function isBlockingMulti(request, callback) {
            return this.rpcCall(isBlockingMulti, $root.me.MeIsBlockingMultiRequest, $root.me.MeIsBlockingMultiResponse, request, callback);
        }, "name", { value: "IsBlockingMulti" });

        /**
         * Calls IsBlockingMulti.
         * @function isBlockingMulti
         * @memberof me.MeService
         * @instance
         * @param {me.IMeIsBlockingMultiRequest} request MeIsBlockingMultiRequest message or plain object
         * @returns {Promise<me.MeIsBlockingMultiResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#getHalfReadingStorySelection}.
         * @memberof me.MeService
         * @typedef GetHalfReadingStorySelectionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithCursor} [response] StorySelectionWithCursor
         */

        /**
         * Calls GetHalfReadingStorySelection.
         * @function getHalfReadingStorySelection
         * @memberof me.MeService
         * @instance
         * @param {me.IGetHalfReadingStorySelectionRequest} request GetHalfReadingStorySelectionRequest message or plain object
         * @param {me.MeService.GetHalfReadingStorySelectionCallback} callback Node-style callback called with the error, if any, and StorySelectionWithCursor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.getHalfReadingStorySelection = function getHalfReadingStorySelection(request, callback) {
            return this.rpcCall(getHalfReadingStorySelection, $root.me.GetHalfReadingStorySelectionRequest, $root.story_selection.StorySelectionWithCursor, request, callback);
        }, "name", { value: "GetHalfReadingStorySelection" });

        /**
         * Calls GetHalfReadingStorySelection.
         * @function getHalfReadingStorySelection
         * @memberof me.MeService
         * @instance
         * @param {me.IGetHalfReadingStorySelectionRequest} request GetHalfReadingStorySelectionRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionWithCursor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#get}.
         * @memberof me.MeService
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeGetResponse} [response] MeGetResponse
         */

        /**
         * Calls Get.
         * @function get
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {me.MeService.GetCallback} callback Node-style callback called with the error, if any, and MeGetResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.types.Empty, $root.me.MeGetResponse, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof me.MeService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<me.MeGetResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#update}.
         * @memberof me.MeService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeUpdateResponse} [response] MeUpdateResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateRequest} request MeUpdateRequest message or plain object
         * @param {me.MeService.UpdateCallback} callback Node-style callback called with the error, if any, and MeUpdateResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.me.MeUpdateRequest, $root.me.MeUpdateResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateRequest} request MeUpdateRequest message or plain object
         * @returns {Promise<me.MeUpdateResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#listBlocking}.
         * @memberof me.MeService
         * @typedef ListBlockingCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeListBlockingResponse} [response] MeListBlockingResponse
         */

        /**
         * Calls ListBlocking.
         * @function listBlocking
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListBlockingRequest} request MeListBlockingRequest message or plain object
         * @param {me.MeService.ListBlockingCallback} callback Node-style callback called with the error, if any, and MeListBlockingResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.listBlocking = function listBlocking(request, callback) {
            return this.rpcCall(listBlocking, $root.me.MeListBlockingRequest, $root.me.MeListBlockingResponse, request, callback);
        }, "name", { value: "ListBlocking" });

        /**
         * Calls ListBlocking.
         * @function listBlocking
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListBlockingRequest} request MeListBlockingRequest message or plain object
         * @returns {Promise<me.MeListBlockingResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#updateNotificationToken}.
         * @memberof me.MeService
         * @typedef UpdateNotificationTokenCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateNotificationToken.
         * @function updateNotificationToken
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateNotificationTokenRequest} request MeUpdateNotificationTokenRequest message or plain object
         * @param {me.MeService.UpdateNotificationTokenCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.updateNotificationToken = function updateNotificationToken(request, callback) {
            return this.rpcCall(updateNotificationToken, $root.me.MeUpdateNotificationTokenRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateNotificationToken" });

        /**
         * Calls UpdateNotificationToken.
         * @function updateNotificationToken
         * @memberof me.MeService
         * @instance
         * @param {me.IMeUpdateNotificationTokenRequest} request MeUpdateNotificationTokenRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#listImage}.
         * @memberof me.MeService
         * @typedef ListImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeListImageResponse} [response] MeListImageResponse
         */

        /**
         * Calls ListImage.
         * @function listImage
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListImageRequest} request MeListImageRequest message or plain object
         * @param {me.MeService.ListImageCallback} callback Node-style callback called with the error, if any, and MeListImageResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.listImage = function listImage(request, callback) {
            return this.rpcCall(listImage, $root.me.MeListImageRequest, $root.me.MeListImageResponse, request, callback);
        }, "name", { value: "ListImage" });

        /**
         * Calls ListImage.
         * @function listImage
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListImageRequest} request MeListImageRequest message or plain object
         * @returns {Promise<me.MeListImageResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link me.MeService#listNotificationV2}.
         * @memberof me.MeService
         * @typedef ListNotificationV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {me.MeListNotificationResponseV2} [response] MeListNotificationResponseV2
         */

        /**
         * Calls ListNotificationV2.
         * @function listNotificationV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListNotificationRequestV2} request MeListNotificationRequestV2 message or plain object
         * @param {me.MeService.ListNotificationV2Callback} callback Node-style callback called with the error, if any, and MeListNotificationResponseV2
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeService.prototype.listNotificationV2 = function listNotificationV2(request, callback) {
            return this.rpcCall(listNotificationV2, $root.me.MeListNotificationRequestV2, $root.me.MeListNotificationResponseV2, request, callback);
        }, "name", { value: "ListNotificationV2" });

        /**
         * Calls ListNotificationV2.
         * @function listNotificationV2
         * @memberof me.MeService
         * @instance
         * @param {me.IMeListNotificationRequestV2} request MeListNotificationRequestV2 message or plain object
         * @returns {Promise<me.MeListNotificationResponseV2>} Promise
         * @variation 2
         */

        return MeService;
    })();

    me.MeGetResponse = (function() {

        /**
         * Properties of a MeGetResponse.
         * @memberof me
         * @interface IMeGetResponse
         * @property {user.IUserV1|null} [user] MeGetResponse user
         */

        /**
         * Constructs a new MeGetResponse.
         * @memberof me
         * @classdesc Represents a MeGetResponse.
         * @implements IMeGetResponse
         * @constructor
         * @param {me.IMeGetResponse=} [properties] Properties to set
         */
        function MeGetResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetResponse user.
         * @member {user.IUserV1|null|undefined} user
         * @memberof me.MeGetResponse
         * @instance
         */
        MeGetResponse.prototype.user = null;

        /**
         * Creates a new MeGetResponse instance using the specified properties.
         * @function create
         * @memberof me.MeGetResponse
         * @static
         * @param {me.IMeGetResponse=} [properties] Properties to set
         * @returns {me.MeGetResponse} MeGetResponse instance
         */
        MeGetResponse.create = function create(properties) {
            return new MeGetResponse(properties);
        };

        /**
         * Encodes the specified MeGetResponse message. Does not implicitly {@link me.MeGetResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetResponse
         * @static
         * @param {me.IMeGetResponse} message MeGetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.user.UserV1.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetResponse} MeGetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.user.UserV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetResponse} MeGetResponse
         */
        MeGetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetResponse)
                return object;
            var message = new $root.me.MeGetResponse();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".me.MeGetResponse.user: object expected");
                message.user = $root.user.UserV1.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetResponse
         * @static
         * @param {me.MeGetResponse} message MeGetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.user.UserV1.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this MeGetResponse to JSON.
         * @function toJSON
         * @memberof me.MeGetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetResponse
         * @function getTypeUrl
         * @memberof me.MeGetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetResponse";
        };

        return MeGetResponse;
    })();

    me.MeUpdateRequest = (function() {

        /**
         * Properties of a MeUpdateRequest.
         * @memberof me
         * @interface IMeUpdateRequest
         * @property {user.IUserV1|null} [user] MeUpdateRequest user
         */

        /**
         * Constructs a new MeUpdateRequest.
         * @memberof me
         * @classdesc Represents a MeUpdateRequest.
         * @implements IMeUpdateRequest
         * @constructor
         * @param {me.IMeUpdateRequest=} [properties] Properties to set
         */
        function MeUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUpdateRequest user.
         * @member {user.IUserV1|null|undefined} user
         * @memberof me.MeUpdateRequest
         * @instance
         */
        MeUpdateRequest.prototype.user = null;

        /**
         * Creates a new MeUpdateRequest instance using the specified properties.
         * @function create
         * @memberof me.MeUpdateRequest
         * @static
         * @param {me.IMeUpdateRequest=} [properties] Properties to set
         * @returns {me.MeUpdateRequest} MeUpdateRequest instance
         */
        MeUpdateRequest.create = function create(properties) {
            return new MeUpdateRequest(properties);
        };

        /**
         * Encodes the specified MeUpdateRequest message. Does not implicitly {@link me.MeUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeUpdateRequest
         * @static
         * @param {me.IMeUpdateRequest} message MeUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.user.UserV1.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUpdateRequest} MeUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.user.UserV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUpdateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUpdateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUpdateRequest} MeUpdateRequest
         */
        MeUpdateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUpdateRequest)
                return object;
            var message = new $root.me.MeUpdateRequest();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".me.MeUpdateRequest.user: object expected");
                message.user = $root.user.UserV1.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUpdateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUpdateRequest
         * @static
         * @param {me.MeUpdateRequest} message MeUpdateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUpdateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.user.UserV1.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this MeUpdateRequest to JSON.
         * @function toJSON
         * @memberof me.MeUpdateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUpdateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUpdateRequest
         * @function getTypeUrl
         * @memberof me.MeUpdateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUpdateRequest";
        };

        return MeUpdateRequest;
    })();

    me.MeUpdateResponse = (function() {

        /**
         * Properties of a MeUpdateResponse.
         * @memberof me
         * @interface IMeUpdateResponse
         * @property {user.IUserV1|null} [user] MeUpdateResponse user
         */

        /**
         * Constructs a new MeUpdateResponse.
         * @memberof me
         * @classdesc Represents a MeUpdateResponse.
         * @implements IMeUpdateResponse
         * @constructor
         * @param {me.IMeUpdateResponse=} [properties] Properties to set
         */
        function MeUpdateResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUpdateResponse user.
         * @member {user.IUserV1|null|undefined} user
         * @memberof me.MeUpdateResponse
         * @instance
         */
        MeUpdateResponse.prototype.user = null;

        /**
         * Creates a new MeUpdateResponse instance using the specified properties.
         * @function create
         * @memberof me.MeUpdateResponse
         * @static
         * @param {me.IMeUpdateResponse=} [properties] Properties to set
         * @returns {me.MeUpdateResponse} MeUpdateResponse instance
         */
        MeUpdateResponse.create = function create(properties) {
            return new MeUpdateResponse(properties);
        };

        /**
         * Encodes the specified MeUpdateResponse message. Does not implicitly {@link me.MeUpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeUpdateResponse
         * @static
         * @param {me.IMeUpdateResponse} message MeUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.user.UserV1.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeUpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUpdateResponse} MeUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUpdateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.user.UserV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUpdateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUpdateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUpdateResponse} MeUpdateResponse
         */
        MeUpdateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUpdateResponse)
                return object;
            var message = new $root.me.MeUpdateResponse();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".me.MeUpdateResponse.user: object expected");
                message.user = $root.user.UserV1.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUpdateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUpdateResponse
         * @static
         * @param {me.MeUpdateResponse} message MeUpdateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUpdateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.user.UserV1.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this MeUpdateResponse to JSON.
         * @function toJSON
         * @memberof me.MeUpdateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUpdateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUpdateResponse
         * @function getTypeUrl
         * @memberof me.MeUpdateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUpdateResponse";
        };

        return MeUpdateResponse;
    })();

    me.MeUpdateNotificationTokenRequestV2 = (function() {

        /**
         * Properties of a MeUpdateNotificationTokenRequestV2.
         * @memberof me
         * @interface IMeUpdateNotificationTokenRequestV2
         * @property {google.protobuf.IStringValue|null} [firebaseInstanceId] MeUpdateNotificationTokenRequestV2 firebaseInstanceId
         * @property {types.OS|null} [os] MeUpdateNotificationTokenRequestV2 os
         */

        /**
         * Constructs a new MeUpdateNotificationTokenRequestV2.
         * @memberof me
         * @classdesc Represents a MeUpdateNotificationTokenRequestV2.
         * @implements IMeUpdateNotificationTokenRequestV2
         * @constructor
         * @param {me.IMeUpdateNotificationTokenRequestV2=} [properties] Properties to set
         */
        function MeUpdateNotificationTokenRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUpdateNotificationTokenRequestV2 firebaseInstanceId.
         * @member {google.protobuf.IStringValue|null|undefined} firebaseInstanceId
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @instance
         */
        MeUpdateNotificationTokenRequestV2.prototype.firebaseInstanceId = null;

        /**
         * MeUpdateNotificationTokenRequestV2 os.
         * @member {types.OS} os
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @instance
         */
        MeUpdateNotificationTokenRequestV2.prototype.os = 0;

        /**
         * Creates a new MeUpdateNotificationTokenRequestV2 instance using the specified properties.
         * @function create
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @static
         * @param {me.IMeUpdateNotificationTokenRequestV2=} [properties] Properties to set
         * @returns {me.MeUpdateNotificationTokenRequestV2} MeUpdateNotificationTokenRequestV2 instance
         */
        MeUpdateNotificationTokenRequestV2.create = function create(properties) {
            return new MeUpdateNotificationTokenRequestV2(properties);
        };

        /**
         * Encodes the specified MeUpdateNotificationTokenRequestV2 message. Does not implicitly {@link me.MeUpdateNotificationTokenRequestV2.verify|verify} messages.
         * @function encode
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @static
         * @param {me.IMeUpdateNotificationTokenRequestV2} message MeUpdateNotificationTokenRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUpdateNotificationTokenRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.firebaseInstanceId != null && Object.hasOwnProperty.call(message, "firebaseInstanceId"))
                $root.google.protobuf.StringValue.encode(message.firebaseInstanceId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.os != null && Object.hasOwnProperty.call(message, "os"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.os);
            return writer;
        };

        /**
         * Decodes a MeUpdateNotificationTokenRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUpdateNotificationTokenRequestV2} MeUpdateNotificationTokenRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUpdateNotificationTokenRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUpdateNotificationTokenRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.firebaseInstanceId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.os = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUpdateNotificationTokenRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUpdateNotificationTokenRequestV2} MeUpdateNotificationTokenRequestV2
         */
        MeUpdateNotificationTokenRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUpdateNotificationTokenRequestV2)
                return object;
            var message = new $root.me.MeUpdateNotificationTokenRequestV2();
            if (object.firebaseInstanceId != null) {
                if (typeof object.firebaseInstanceId !== "object")
                    throw TypeError(".me.MeUpdateNotificationTokenRequestV2.firebaseInstanceId: object expected");
                message.firebaseInstanceId = $root.google.protobuf.StringValue.fromObject(object.firebaseInstanceId);
            }
            switch (object.os) {
            case "OS_UNSPECIFIED":
            case 0:
                message.os = 0;
                break;
            case "OS_IOS":
            case 1:
                message.os = 1;
                break;
            case "OS_ANDROID":
            case 2:
                message.os = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUpdateNotificationTokenRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @static
         * @param {me.MeUpdateNotificationTokenRequestV2} message MeUpdateNotificationTokenRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUpdateNotificationTokenRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.firebaseInstanceId = null;
                object.os = options.enums === String ? "OS_UNSPECIFIED" : 0;
            }
            if (message.firebaseInstanceId != null && message.hasOwnProperty("firebaseInstanceId"))
                object.firebaseInstanceId = $root.google.protobuf.StringValue.toObject(message.firebaseInstanceId, options);
            if (message.os != null && message.hasOwnProperty("os"))
                object.os = options.enums === String ? $root.types.OS[message.os] : message.os;
            return object;
        };

        /**
         * Converts this MeUpdateNotificationTokenRequestV2 to JSON.
         * @function toJSON
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUpdateNotificationTokenRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUpdateNotificationTokenRequestV2
         * @function getTypeUrl
         * @memberof me.MeUpdateNotificationTokenRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUpdateNotificationTokenRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUpdateNotificationTokenRequestV2";
        };

        return MeUpdateNotificationTokenRequestV2;
    })();

    me.MeMigrationToken = (function() {

        /**
         * Properties of a MeMigrationToken.
         * @memberof me
         * @interface IMeMigrationToken
         * @property {google.protobuf.IStringValue|null} [userId] MeMigrationToken userId
         * @property {google.protobuf.IStringValue|null} [token] MeMigrationToken token
         * @property {google.protobuf.ITimestamp|null} [expiresAt] MeMigrationToken expiresAt
         */

        /**
         * Constructs a new MeMigrationToken.
         * @memberof me
         * @classdesc Represents a MeMigrationToken.
         * @implements IMeMigrationToken
         * @constructor
         * @param {me.IMeMigrationToken=} [properties] Properties to set
         */
        function MeMigrationToken(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeMigrationToken userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.MeMigrationToken
         * @instance
         */
        MeMigrationToken.prototype.userId = null;

        /**
         * MeMigrationToken token.
         * @member {google.protobuf.IStringValue|null|undefined} token
         * @memberof me.MeMigrationToken
         * @instance
         */
        MeMigrationToken.prototype.token = null;

        /**
         * MeMigrationToken expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof me.MeMigrationToken
         * @instance
         */
        MeMigrationToken.prototype.expiresAt = null;

        /**
         * Creates a new MeMigrationToken instance using the specified properties.
         * @function create
         * @memberof me.MeMigrationToken
         * @static
         * @param {me.IMeMigrationToken=} [properties] Properties to set
         * @returns {me.MeMigrationToken} MeMigrationToken instance
         */
        MeMigrationToken.create = function create(properties) {
            return new MeMigrationToken(properties);
        };

        /**
         * Encodes the specified MeMigrationToken message. Does not implicitly {@link me.MeMigrationToken.verify|verify} messages.
         * @function encode
         * @memberof me.MeMigrationToken
         * @static
         * @param {me.IMeMigrationToken} message MeMigrationToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeMigrationToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                $root.google.protobuf.StringValue.encode(message.token, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeMigrationToken message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeMigrationToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeMigrationToken} MeMigrationToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeMigrationToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeMigrationToken();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.token = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeMigrationToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeMigrationToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeMigrationToken} MeMigrationToken
         */
        MeMigrationToken.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeMigrationToken)
                return object;
            var message = new $root.me.MeMigrationToken();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.MeMigrationToken.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.token != null) {
                if (typeof object.token !== "object")
                    throw TypeError(".me.MeMigrationToken.token: object expected");
                message.token = $root.google.protobuf.StringValue.fromObject(object.token);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".me.MeMigrationToken.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeMigrationToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeMigrationToken
         * @static
         * @param {me.MeMigrationToken} message MeMigrationToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeMigrationToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = null;
                object.token = null;
                object.expiresAt = null;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = $root.google.protobuf.StringValue.toObject(message.token, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            return object;
        };

        /**
         * Converts this MeMigrationToken to JSON.
         * @function toJSON
         * @memberof me.MeMigrationToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeMigrationToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeMigrationToken
         * @function getTypeUrl
         * @memberof me.MeMigrationToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeMigrationToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeMigrationToken";
        };

        return MeMigrationToken;
    })();

    me.MeMigrateRequest = (function() {

        /**
         * Properties of a MeMigrateRequest.
         * @memberof me
         * @interface IMeMigrateRequest
         * @property {google.protobuf.IStringValue|null} [token] MeMigrateRequest token
         */

        /**
         * Constructs a new MeMigrateRequest.
         * @memberof me
         * @classdesc Represents a MeMigrateRequest.
         * @implements IMeMigrateRequest
         * @constructor
         * @param {me.IMeMigrateRequest=} [properties] Properties to set
         */
        function MeMigrateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeMigrateRequest token.
         * @member {google.protobuf.IStringValue|null|undefined} token
         * @memberof me.MeMigrateRequest
         * @instance
         */
        MeMigrateRequest.prototype.token = null;

        /**
         * Creates a new MeMigrateRequest instance using the specified properties.
         * @function create
         * @memberof me.MeMigrateRequest
         * @static
         * @param {me.IMeMigrateRequest=} [properties] Properties to set
         * @returns {me.MeMigrateRequest} MeMigrateRequest instance
         */
        MeMigrateRequest.create = function create(properties) {
            return new MeMigrateRequest(properties);
        };

        /**
         * Encodes the specified MeMigrateRequest message. Does not implicitly {@link me.MeMigrateRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeMigrateRequest
         * @static
         * @param {me.IMeMigrateRequest} message MeMigrateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeMigrateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                $root.google.protobuf.StringValue.encode(message.token, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeMigrateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeMigrateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeMigrateRequest} MeMigrateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeMigrateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeMigrateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeMigrateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeMigrateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeMigrateRequest} MeMigrateRequest
         */
        MeMigrateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeMigrateRequest)
                return object;
            var message = new $root.me.MeMigrateRequest();
            if (object.token != null) {
                if (typeof object.token !== "object")
                    throw TypeError(".me.MeMigrateRequest.token: object expected");
                message.token = $root.google.protobuf.StringValue.fromObject(object.token);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeMigrateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeMigrateRequest
         * @static
         * @param {me.MeMigrateRequest} message MeMigrateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeMigrateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.token = null;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = $root.google.protobuf.StringValue.toObject(message.token, options);
            return object;
        };

        /**
         * Converts this MeMigrateRequest to JSON.
         * @function toJSON
         * @memberof me.MeMigrateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeMigrateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeMigrateRequest
         * @function getTypeUrl
         * @memberof me.MeMigrateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeMigrateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeMigrateRequest";
        };

        return MeMigrateRequest;
    })();

    me.MeGetReadingProgressRequest = (function() {

        /**
         * Properties of a MeGetReadingProgressRequest.
         * @memberof me
         * @interface IMeGetReadingProgressRequest
         * @property {google.protobuf.IStringValue|null} [storyId] MeGetReadingProgressRequest storyId
         */

        /**
         * Constructs a new MeGetReadingProgressRequest.
         * @memberof me
         * @classdesc Represents a MeGetReadingProgressRequest.
         * @implements IMeGetReadingProgressRequest
         * @constructor
         * @param {me.IMeGetReadingProgressRequest=} [properties] Properties to set
         */
        function MeGetReadingProgressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetReadingProgressRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof me.MeGetReadingProgressRequest
         * @instance
         */
        MeGetReadingProgressRequest.prototype.storyId = null;

        /**
         * Creates a new MeGetReadingProgressRequest instance using the specified properties.
         * @function create
         * @memberof me.MeGetReadingProgressRequest
         * @static
         * @param {me.IMeGetReadingProgressRequest=} [properties] Properties to set
         * @returns {me.MeGetReadingProgressRequest} MeGetReadingProgressRequest instance
         */
        MeGetReadingProgressRequest.create = function create(properties) {
            return new MeGetReadingProgressRequest(properties);
        };

        /**
         * Encodes the specified MeGetReadingProgressRequest message. Does not implicitly {@link me.MeGetReadingProgressRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetReadingProgressRequest
         * @static
         * @param {me.IMeGetReadingProgressRequest} message MeGetReadingProgressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetReadingProgressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetReadingProgressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetReadingProgressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetReadingProgressRequest} MeGetReadingProgressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetReadingProgressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetReadingProgressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetReadingProgressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetReadingProgressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetReadingProgressRequest} MeGetReadingProgressRequest
         */
        MeGetReadingProgressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetReadingProgressRequest)
                return object;
            var message = new $root.me.MeGetReadingProgressRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".me.MeGetReadingProgressRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetReadingProgressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetReadingProgressRequest
         * @static
         * @param {me.MeGetReadingProgressRequest} message MeGetReadingProgressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetReadingProgressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.storyId = null;
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            return object;
        };

        /**
         * Converts this MeGetReadingProgressRequest to JSON.
         * @function toJSON
         * @memberof me.MeGetReadingProgressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetReadingProgressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetReadingProgressRequest
         * @function getTypeUrl
         * @memberof me.MeGetReadingProgressRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetReadingProgressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetReadingProgressRequest";
        };

        return MeGetReadingProgressRequest;
    })();

    me.MeGetReadingProgressResponse = (function() {

        /**
         * Properties of a MeGetReadingProgressResponse.
         * @memberof me
         * @interface IMeGetReadingProgressResponse
         * @property {reading_progress.IReadingProgress|null} [readingProgress] MeGetReadingProgressResponse readingProgress
         */

        /**
         * Constructs a new MeGetReadingProgressResponse.
         * @memberof me
         * @classdesc Represents a MeGetReadingProgressResponse.
         * @implements IMeGetReadingProgressResponse
         * @constructor
         * @param {me.IMeGetReadingProgressResponse=} [properties] Properties to set
         */
        function MeGetReadingProgressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetReadingProgressResponse readingProgress.
         * @member {reading_progress.IReadingProgress|null|undefined} readingProgress
         * @memberof me.MeGetReadingProgressResponse
         * @instance
         */
        MeGetReadingProgressResponse.prototype.readingProgress = null;

        /**
         * Creates a new MeGetReadingProgressResponse instance using the specified properties.
         * @function create
         * @memberof me.MeGetReadingProgressResponse
         * @static
         * @param {me.IMeGetReadingProgressResponse=} [properties] Properties to set
         * @returns {me.MeGetReadingProgressResponse} MeGetReadingProgressResponse instance
         */
        MeGetReadingProgressResponse.create = function create(properties) {
            return new MeGetReadingProgressResponse(properties);
        };

        /**
         * Encodes the specified MeGetReadingProgressResponse message. Does not implicitly {@link me.MeGetReadingProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetReadingProgressResponse
         * @static
         * @param {me.IMeGetReadingProgressResponse} message MeGetReadingProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetReadingProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.readingProgress != null && Object.hasOwnProperty.call(message, "readingProgress"))
                $root.reading_progress.ReadingProgress.encode(message.readingProgress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetReadingProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetReadingProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetReadingProgressResponse} MeGetReadingProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetReadingProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetReadingProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.readingProgress = $root.reading_progress.ReadingProgress.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetReadingProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetReadingProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetReadingProgressResponse} MeGetReadingProgressResponse
         */
        MeGetReadingProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetReadingProgressResponse)
                return object;
            var message = new $root.me.MeGetReadingProgressResponse();
            if (object.readingProgress != null) {
                if (typeof object.readingProgress !== "object")
                    throw TypeError(".me.MeGetReadingProgressResponse.readingProgress: object expected");
                message.readingProgress = $root.reading_progress.ReadingProgress.fromObject(object.readingProgress);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetReadingProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetReadingProgressResponse
         * @static
         * @param {me.MeGetReadingProgressResponse} message MeGetReadingProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetReadingProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.readingProgress = null;
            if (message.readingProgress != null && message.hasOwnProperty("readingProgress"))
                object.readingProgress = $root.reading_progress.ReadingProgress.toObject(message.readingProgress, options);
            return object;
        };

        /**
         * Converts this MeGetReadingProgressResponse to JSON.
         * @function toJSON
         * @memberof me.MeGetReadingProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetReadingProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetReadingProgressResponse
         * @function getTypeUrl
         * @memberof me.MeGetReadingProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetReadingProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetReadingProgressResponse";
        };

        return MeGetReadingProgressResponse;
    })();

    me.MeGetMultiReadingProgressRequest = (function() {

        /**
         * Properties of a MeGetMultiReadingProgressRequest.
         * @memberof me
         * @interface IMeGetMultiReadingProgressRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [storyIdList] MeGetMultiReadingProgressRequest storyIdList
         */

        /**
         * Constructs a new MeGetMultiReadingProgressRequest.
         * @memberof me
         * @classdesc Represents a MeGetMultiReadingProgressRequest.
         * @implements IMeGetMultiReadingProgressRequest
         * @constructor
         * @param {me.IMeGetMultiReadingProgressRequest=} [properties] Properties to set
         */
        function MeGetMultiReadingProgressRequest(properties) {
            this.storyIdList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetMultiReadingProgressRequest storyIdList.
         * @member {Array.<google.protobuf.IStringValue>} storyIdList
         * @memberof me.MeGetMultiReadingProgressRequest
         * @instance
         */
        MeGetMultiReadingProgressRequest.prototype.storyIdList = $util.emptyArray;

        /**
         * Creates a new MeGetMultiReadingProgressRequest instance using the specified properties.
         * @function create
         * @memberof me.MeGetMultiReadingProgressRequest
         * @static
         * @param {me.IMeGetMultiReadingProgressRequest=} [properties] Properties to set
         * @returns {me.MeGetMultiReadingProgressRequest} MeGetMultiReadingProgressRequest instance
         */
        MeGetMultiReadingProgressRequest.create = function create(properties) {
            return new MeGetMultiReadingProgressRequest(properties);
        };

        /**
         * Encodes the specified MeGetMultiReadingProgressRequest message. Does not implicitly {@link me.MeGetMultiReadingProgressRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetMultiReadingProgressRequest
         * @static
         * @param {me.IMeGetMultiReadingProgressRequest} message MeGetMultiReadingProgressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetMultiReadingProgressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyIdList != null && message.storyIdList.length)
                for (var i = 0; i < message.storyIdList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.storyIdList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetMultiReadingProgressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetMultiReadingProgressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetMultiReadingProgressRequest} MeGetMultiReadingProgressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetMultiReadingProgressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetMultiReadingProgressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyIdList && message.storyIdList.length))
                        message.storyIdList = [];
                    message.storyIdList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetMultiReadingProgressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetMultiReadingProgressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetMultiReadingProgressRequest} MeGetMultiReadingProgressRequest
         */
        MeGetMultiReadingProgressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetMultiReadingProgressRequest)
                return object;
            var message = new $root.me.MeGetMultiReadingProgressRequest();
            if (object.storyIdList) {
                if (!Array.isArray(object.storyIdList))
                    throw TypeError(".me.MeGetMultiReadingProgressRequest.storyIdList: array expected");
                message.storyIdList = [];
                for (var i = 0; i < object.storyIdList.length; ++i) {
                    if (typeof object.storyIdList[i] !== "object")
                        throw TypeError(".me.MeGetMultiReadingProgressRequest.storyIdList: object expected");
                    message.storyIdList[i] = $root.google.protobuf.StringValue.fromObject(object.storyIdList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetMultiReadingProgressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetMultiReadingProgressRequest
         * @static
         * @param {me.MeGetMultiReadingProgressRequest} message MeGetMultiReadingProgressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetMultiReadingProgressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyIdList = [];
            if (message.storyIdList && message.storyIdList.length) {
                object.storyIdList = [];
                for (var j = 0; j < message.storyIdList.length; ++j)
                    object.storyIdList[j] = $root.google.protobuf.StringValue.toObject(message.storyIdList[j], options);
            }
            return object;
        };

        /**
         * Converts this MeGetMultiReadingProgressRequest to JSON.
         * @function toJSON
         * @memberof me.MeGetMultiReadingProgressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetMultiReadingProgressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetMultiReadingProgressRequest
         * @function getTypeUrl
         * @memberof me.MeGetMultiReadingProgressRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetMultiReadingProgressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetMultiReadingProgressRequest";
        };

        return MeGetMultiReadingProgressRequest;
    })();

    me.MeGetMultiReadingProgressResponse = (function() {

        /**
         * Properties of a MeGetMultiReadingProgressResponse.
         * @memberof me
         * @interface IMeGetMultiReadingProgressResponse
         * @property {Array.<reading_progress.IReadingProgress>|null} [readingProgressList] MeGetMultiReadingProgressResponse readingProgressList
         */

        /**
         * Constructs a new MeGetMultiReadingProgressResponse.
         * @memberof me
         * @classdesc Represents a MeGetMultiReadingProgressResponse.
         * @implements IMeGetMultiReadingProgressResponse
         * @constructor
         * @param {me.IMeGetMultiReadingProgressResponse=} [properties] Properties to set
         */
        function MeGetMultiReadingProgressResponse(properties) {
            this.readingProgressList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetMultiReadingProgressResponse readingProgressList.
         * @member {Array.<reading_progress.IReadingProgress>} readingProgressList
         * @memberof me.MeGetMultiReadingProgressResponse
         * @instance
         */
        MeGetMultiReadingProgressResponse.prototype.readingProgressList = $util.emptyArray;

        /**
         * Creates a new MeGetMultiReadingProgressResponse instance using the specified properties.
         * @function create
         * @memberof me.MeGetMultiReadingProgressResponse
         * @static
         * @param {me.IMeGetMultiReadingProgressResponse=} [properties] Properties to set
         * @returns {me.MeGetMultiReadingProgressResponse} MeGetMultiReadingProgressResponse instance
         */
        MeGetMultiReadingProgressResponse.create = function create(properties) {
            return new MeGetMultiReadingProgressResponse(properties);
        };

        /**
         * Encodes the specified MeGetMultiReadingProgressResponse message. Does not implicitly {@link me.MeGetMultiReadingProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetMultiReadingProgressResponse
         * @static
         * @param {me.IMeGetMultiReadingProgressResponse} message MeGetMultiReadingProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetMultiReadingProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.readingProgressList != null && message.readingProgressList.length)
                for (var i = 0; i < message.readingProgressList.length; ++i)
                    $root.reading_progress.ReadingProgress.encode(message.readingProgressList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetMultiReadingProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetMultiReadingProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetMultiReadingProgressResponse} MeGetMultiReadingProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetMultiReadingProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetMultiReadingProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.readingProgressList && message.readingProgressList.length))
                        message.readingProgressList = [];
                    message.readingProgressList.push($root.reading_progress.ReadingProgress.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetMultiReadingProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetMultiReadingProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetMultiReadingProgressResponse} MeGetMultiReadingProgressResponse
         */
        MeGetMultiReadingProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetMultiReadingProgressResponse)
                return object;
            var message = new $root.me.MeGetMultiReadingProgressResponse();
            if (object.readingProgressList) {
                if (!Array.isArray(object.readingProgressList))
                    throw TypeError(".me.MeGetMultiReadingProgressResponse.readingProgressList: array expected");
                message.readingProgressList = [];
                for (var i = 0; i < object.readingProgressList.length; ++i) {
                    if (typeof object.readingProgressList[i] !== "object")
                        throw TypeError(".me.MeGetMultiReadingProgressResponse.readingProgressList: object expected");
                    message.readingProgressList[i] = $root.reading_progress.ReadingProgress.fromObject(object.readingProgressList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetMultiReadingProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetMultiReadingProgressResponse
         * @static
         * @param {me.MeGetMultiReadingProgressResponse} message MeGetMultiReadingProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetMultiReadingProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.readingProgressList = [];
            if (message.readingProgressList && message.readingProgressList.length) {
                object.readingProgressList = [];
                for (var j = 0; j < message.readingProgressList.length; ++j)
                    object.readingProgressList[j] = $root.reading_progress.ReadingProgress.toObject(message.readingProgressList[j], options);
            }
            return object;
        };

        /**
         * Converts this MeGetMultiReadingProgressResponse to JSON.
         * @function toJSON
         * @memberof me.MeGetMultiReadingProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetMultiReadingProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetMultiReadingProgressResponse
         * @function getTypeUrl
         * @memberof me.MeGetMultiReadingProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetMultiReadingProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetMultiReadingProgressResponse";
        };

        return MeGetMultiReadingProgressResponse;
    })();

    me.MeUpdateReadingProgressRequest = (function() {

        /**
         * Properties of a MeUpdateReadingProgressRequest.
         * @memberof me
         * @interface IMeUpdateReadingProgressRequest
         * @property {google.protobuf.IStringValue|null} [storyId] MeUpdateReadingProgressRequest storyId
         * @property {google.protobuf.IInt32Value|null} [index] MeUpdateReadingProgressRequest index
         */

        /**
         * Constructs a new MeUpdateReadingProgressRequest.
         * @memberof me
         * @classdesc Represents a MeUpdateReadingProgressRequest.
         * @implements IMeUpdateReadingProgressRequest
         * @constructor
         * @param {me.IMeUpdateReadingProgressRequest=} [properties] Properties to set
         */
        function MeUpdateReadingProgressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUpdateReadingProgressRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof me.MeUpdateReadingProgressRequest
         * @instance
         */
        MeUpdateReadingProgressRequest.prototype.storyId = null;

        /**
         * MeUpdateReadingProgressRequest index.
         * @member {google.protobuf.IInt32Value|null|undefined} index
         * @memberof me.MeUpdateReadingProgressRequest
         * @instance
         */
        MeUpdateReadingProgressRequest.prototype.index = null;

        /**
         * Creates a new MeUpdateReadingProgressRequest instance using the specified properties.
         * @function create
         * @memberof me.MeUpdateReadingProgressRequest
         * @static
         * @param {me.IMeUpdateReadingProgressRequest=} [properties] Properties to set
         * @returns {me.MeUpdateReadingProgressRequest} MeUpdateReadingProgressRequest instance
         */
        MeUpdateReadingProgressRequest.create = function create(properties) {
            return new MeUpdateReadingProgressRequest(properties);
        };

        /**
         * Encodes the specified MeUpdateReadingProgressRequest message. Does not implicitly {@link me.MeUpdateReadingProgressRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeUpdateReadingProgressRequest
         * @static
         * @param {me.IMeUpdateReadingProgressRequest} message MeUpdateReadingProgressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUpdateReadingProgressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                $root.google.protobuf.Int32Value.encode(message.index, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeUpdateReadingProgressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUpdateReadingProgressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUpdateReadingProgressRequest} MeUpdateReadingProgressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUpdateReadingProgressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUpdateReadingProgressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.index = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUpdateReadingProgressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUpdateReadingProgressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUpdateReadingProgressRequest} MeUpdateReadingProgressRequest
         */
        MeUpdateReadingProgressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUpdateReadingProgressRequest)
                return object;
            var message = new $root.me.MeUpdateReadingProgressRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".me.MeUpdateReadingProgressRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.index != null) {
                if (typeof object.index !== "object")
                    throw TypeError(".me.MeUpdateReadingProgressRequest.index: object expected");
                message.index = $root.google.protobuf.Int32Value.fromObject(object.index);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUpdateReadingProgressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUpdateReadingProgressRequest
         * @static
         * @param {me.MeUpdateReadingProgressRequest} message MeUpdateReadingProgressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUpdateReadingProgressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.index = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = $root.google.protobuf.Int32Value.toObject(message.index, options);
            return object;
        };

        /**
         * Converts this MeUpdateReadingProgressRequest to JSON.
         * @function toJSON
         * @memberof me.MeUpdateReadingProgressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUpdateReadingProgressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUpdateReadingProgressRequest
         * @function getTypeUrl
         * @memberof me.MeUpdateReadingProgressRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUpdateReadingProgressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUpdateReadingProgressRequest";
        };

        return MeUpdateReadingProgressRequest;
    })();

    me.MeUpdateReadingProgressResponse = (function() {

        /**
         * Properties of a MeUpdateReadingProgressResponse.
         * @memberof me
         * @interface IMeUpdateReadingProgressResponse
         * @property {reading_progress.IReadingProgress|null} [readingProgress] MeUpdateReadingProgressResponse readingProgress
         */

        /**
         * Constructs a new MeUpdateReadingProgressResponse.
         * @memberof me
         * @classdesc Represents a MeUpdateReadingProgressResponse.
         * @implements IMeUpdateReadingProgressResponse
         * @constructor
         * @param {me.IMeUpdateReadingProgressResponse=} [properties] Properties to set
         */
        function MeUpdateReadingProgressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUpdateReadingProgressResponse readingProgress.
         * @member {reading_progress.IReadingProgress|null|undefined} readingProgress
         * @memberof me.MeUpdateReadingProgressResponse
         * @instance
         */
        MeUpdateReadingProgressResponse.prototype.readingProgress = null;

        /**
         * Creates a new MeUpdateReadingProgressResponse instance using the specified properties.
         * @function create
         * @memberof me.MeUpdateReadingProgressResponse
         * @static
         * @param {me.IMeUpdateReadingProgressResponse=} [properties] Properties to set
         * @returns {me.MeUpdateReadingProgressResponse} MeUpdateReadingProgressResponse instance
         */
        MeUpdateReadingProgressResponse.create = function create(properties) {
            return new MeUpdateReadingProgressResponse(properties);
        };

        /**
         * Encodes the specified MeUpdateReadingProgressResponse message. Does not implicitly {@link me.MeUpdateReadingProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeUpdateReadingProgressResponse
         * @static
         * @param {me.IMeUpdateReadingProgressResponse} message MeUpdateReadingProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUpdateReadingProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.readingProgress != null && Object.hasOwnProperty.call(message, "readingProgress"))
                $root.reading_progress.ReadingProgress.encode(message.readingProgress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeUpdateReadingProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUpdateReadingProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUpdateReadingProgressResponse} MeUpdateReadingProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUpdateReadingProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUpdateReadingProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.readingProgress = $root.reading_progress.ReadingProgress.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUpdateReadingProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUpdateReadingProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUpdateReadingProgressResponse} MeUpdateReadingProgressResponse
         */
        MeUpdateReadingProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUpdateReadingProgressResponse)
                return object;
            var message = new $root.me.MeUpdateReadingProgressResponse();
            if (object.readingProgress != null) {
                if (typeof object.readingProgress !== "object")
                    throw TypeError(".me.MeUpdateReadingProgressResponse.readingProgress: object expected");
                message.readingProgress = $root.reading_progress.ReadingProgress.fromObject(object.readingProgress);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUpdateReadingProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUpdateReadingProgressResponse
         * @static
         * @param {me.MeUpdateReadingProgressResponse} message MeUpdateReadingProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUpdateReadingProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.readingProgress = null;
            if (message.readingProgress != null && message.hasOwnProperty("readingProgress"))
                object.readingProgress = $root.reading_progress.ReadingProgress.toObject(message.readingProgress, options);
            return object;
        };

        /**
         * Converts this MeUpdateReadingProgressResponse to JSON.
         * @function toJSON
         * @memberof me.MeUpdateReadingProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUpdateReadingProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUpdateReadingProgressResponse
         * @function getTypeUrl
         * @memberof me.MeUpdateReadingProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUpdateReadingProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUpdateReadingProgressResponse";
        };

        return MeUpdateReadingProgressResponse;
    })();

    me.MeListReadingProgressRequest = (function() {

        /**
         * Properties of a MeListReadingProgressRequest.
         * @memberof me
         * @interface IMeListReadingProgressRequest
         * @property {google.protobuf.IStringValue|null} [cursor] MeListReadingProgressRequest cursor
         * @property {google.protobuf.IInt32Value|null} [limit] MeListReadingProgressRequest limit
         */

        /**
         * Constructs a new MeListReadingProgressRequest.
         * @memberof me
         * @classdesc Represents a MeListReadingProgressRequest.
         * @implements IMeListReadingProgressRequest
         * @constructor
         * @param {me.IMeListReadingProgressRequest=} [properties] Properties to set
         */
        function MeListReadingProgressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListReadingProgressRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListReadingProgressRequest
         * @instance
         */
        MeListReadingProgressRequest.prototype.cursor = null;

        /**
         * MeListReadingProgressRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof me.MeListReadingProgressRequest
         * @instance
         */
        MeListReadingProgressRequest.prototype.limit = null;

        /**
         * Creates a new MeListReadingProgressRequest instance using the specified properties.
         * @function create
         * @memberof me.MeListReadingProgressRequest
         * @static
         * @param {me.IMeListReadingProgressRequest=} [properties] Properties to set
         * @returns {me.MeListReadingProgressRequest} MeListReadingProgressRequest instance
         */
        MeListReadingProgressRequest.create = function create(properties) {
            return new MeListReadingProgressRequest(properties);
        };

        /**
         * Encodes the specified MeListReadingProgressRequest message. Does not implicitly {@link me.MeListReadingProgressRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeListReadingProgressRequest
         * @static
         * @param {me.IMeListReadingProgressRequest} message MeListReadingProgressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListReadingProgressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListReadingProgressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListReadingProgressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListReadingProgressRequest} MeListReadingProgressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListReadingProgressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListReadingProgressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListReadingProgressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListReadingProgressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListReadingProgressRequest} MeListReadingProgressRequest
         */
        MeListReadingProgressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListReadingProgressRequest)
                return object;
            var message = new $root.me.MeListReadingProgressRequest();
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListReadingProgressRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".me.MeListReadingProgressRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListReadingProgressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListReadingProgressRequest
         * @static
         * @param {me.MeListReadingProgressRequest} message MeListReadingProgressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListReadingProgressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.cursor = null;
                object.limit = null;
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            return object;
        };

        /**
         * Converts this MeListReadingProgressRequest to JSON.
         * @function toJSON
         * @memberof me.MeListReadingProgressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListReadingProgressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListReadingProgressRequest
         * @function getTypeUrl
         * @memberof me.MeListReadingProgressRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListReadingProgressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListReadingProgressRequest";
        };

        return MeListReadingProgressRequest;
    })();

    me.MeListReadingProgressResponse = (function() {

        /**
         * Properties of a MeListReadingProgressResponse.
         * @memberof me
         * @interface IMeListReadingProgressResponse
         * @property {Array.<reading_progress.IReadingProgress>|null} [readingProgressList] MeListReadingProgressResponse readingProgressList
         * @property {google.protobuf.IStringValue|null} [cursor] MeListReadingProgressResponse cursor
         */

        /**
         * Constructs a new MeListReadingProgressResponse.
         * @memberof me
         * @classdesc Represents a MeListReadingProgressResponse.
         * @implements IMeListReadingProgressResponse
         * @constructor
         * @param {me.IMeListReadingProgressResponse=} [properties] Properties to set
         */
        function MeListReadingProgressResponse(properties) {
            this.readingProgressList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListReadingProgressResponse readingProgressList.
         * @member {Array.<reading_progress.IReadingProgress>} readingProgressList
         * @memberof me.MeListReadingProgressResponse
         * @instance
         */
        MeListReadingProgressResponse.prototype.readingProgressList = $util.emptyArray;

        /**
         * MeListReadingProgressResponse cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListReadingProgressResponse
         * @instance
         */
        MeListReadingProgressResponse.prototype.cursor = null;

        /**
         * Creates a new MeListReadingProgressResponse instance using the specified properties.
         * @function create
         * @memberof me.MeListReadingProgressResponse
         * @static
         * @param {me.IMeListReadingProgressResponse=} [properties] Properties to set
         * @returns {me.MeListReadingProgressResponse} MeListReadingProgressResponse instance
         */
        MeListReadingProgressResponse.create = function create(properties) {
            return new MeListReadingProgressResponse(properties);
        };

        /**
         * Encodes the specified MeListReadingProgressResponse message. Does not implicitly {@link me.MeListReadingProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeListReadingProgressResponse
         * @static
         * @param {me.IMeListReadingProgressResponse} message MeListReadingProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListReadingProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.readingProgressList != null && message.readingProgressList.length)
                for (var i = 0; i < message.readingProgressList.length; ++i)
                    $root.reading_progress.ReadingProgress.encode(message.readingProgressList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListReadingProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListReadingProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListReadingProgressResponse} MeListReadingProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListReadingProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListReadingProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.readingProgressList && message.readingProgressList.length))
                        message.readingProgressList = [];
                    message.readingProgressList.push($root.reading_progress.ReadingProgress.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListReadingProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListReadingProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListReadingProgressResponse} MeListReadingProgressResponse
         */
        MeListReadingProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListReadingProgressResponse)
                return object;
            var message = new $root.me.MeListReadingProgressResponse();
            if (object.readingProgressList) {
                if (!Array.isArray(object.readingProgressList))
                    throw TypeError(".me.MeListReadingProgressResponse.readingProgressList: array expected");
                message.readingProgressList = [];
                for (var i = 0; i < object.readingProgressList.length; ++i) {
                    if (typeof object.readingProgressList[i] !== "object")
                        throw TypeError(".me.MeListReadingProgressResponse.readingProgressList: object expected");
                    message.readingProgressList[i] = $root.reading_progress.ReadingProgress.fromObject(object.readingProgressList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListReadingProgressResponse.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListReadingProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListReadingProgressResponse
         * @static
         * @param {me.MeListReadingProgressResponse} message MeListReadingProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListReadingProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.readingProgressList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.readingProgressList && message.readingProgressList.length) {
                object.readingProgressList = [];
                for (var j = 0; j < message.readingProgressList.length; ++j)
                    object.readingProgressList[j] = $root.reading_progress.ReadingProgress.toObject(message.readingProgressList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MeListReadingProgressResponse to JSON.
         * @function toJSON
         * @memberof me.MeListReadingProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListReadingProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListReadingProgressResponse
         * @function getTypeUrl
         * @memberof me.MeListReadingProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListReadingProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListReadingProgressResponse";
        };

        return MeListReadingProgressResponse;
    })();

    me.MeBlockRequest = (function() {

        /**
         * Properties of a MeBlockRequest.
         * @memberof me
         * @interface IMeBlockRequest
         * @property {google.protobuf.IStringValue|null} [userId] MeBlockRequest userId
         */

        /**
         * Constructs a new MeBlockRequest.
         * @memberof me
         * @classdesc Represents a MeBlockRequest.
         * @implements IMeBlockRequest
         * @constructor
         * @param {me.IMeBlockRequest=} [properties] Properties to set
         */
        function MeBlockRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeBlockRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.MeBlockRequest
         * @instance
         */
        MeBlockRequest.prototype.userId = null;

        /**
         * Creates a new MeBlockRequest instance using the specified properties.
         * @function create
         * @memberof me.MeBlockRequest
         * @static
         * @param {me.IMeBlockRequest=} [properties] Properties to set
         * @returns {me.MeBlockRequest} MeBlockRequest instance
         */
        MeBlockRequest.create = function create(properties) {
            return new MeBlockRequest(properties);
        };

        /**
         * Encodes the specified MeBlockRequest message. Does not implicitly {@link me.MeBlockRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeBlockRequest
         * @static
         * @param {me.IMeBlockRequest} message MeBlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeBlockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeBlockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeBlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeBlockRequest} MeBlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeBlockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeBlockRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeBlockRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeBlockRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeBlockRequest} MeBlockRequest
         */
        MeBlockRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeBlockRequest)
                return object;
            var message = new $root.me.MeBlockRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.MeBlockRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeBlockRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeBlockRequest
         * @static
         * @param {me.MeBlockRequest} message MeBlockRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeBlockRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this MeBlockRequest to JSON.
         * @function toJSON
         * @memberof me.MeBlockRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeBlockRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeBlockRequest
         * @function getTypeUrl
         * @memberof me.MeBlockRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeBlockRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeBlockRequest";
        };

        return MeBlockRequest;
    })();

    me.MeUnblockRequest = (function() {

        /**
         * Properties of a MeUnblockRequest.
         * @memberof me
         * @interface IMeUnblockRequest
         * @property {google.protobuf.IStringValue|null} [userId] MeUnblockRequest userId
         */

        /**
         * Constructs a new MeUnblockRequest.
         * @memberof me
         * @classdesc Represents a MeUnblockRequest.
         * @implements IMeUnblockRequest
         * @constructor
         * @param {me.IMeUnblockRequest=} [properties] Properties to set
         */
        function MeUnblockRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUnblockRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.MeUnblockRequest
         * @instance
         */
        MeUnblockRequest.prototype.userId = null;

        /**
         * Creates a new MeUnblockRequest instance using the specified properties.
         * @function create
         * @memberof me.MeUnblockRequest
         * @static
         * @param {me.IMeUnblockRequest=} [properties] Properties to set
         * @returns {me.MeUnblockRequest} MeUnblockRequest instance
         */
        MeUnblockRequest.create = function create(properties) {
            return new MeUnblockRequest(properties);
        };

        /**
         * Encodes the specified MeUnblockRequest message. Does not implicitly {@link me.MeUnblockRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeUnblockRequest
         * @static
         * @param {me.IMeUnblockRequest} message MeUnblockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUnblockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeUnblockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUnblockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUnblockRequest} MeUnblockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUnblockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUnblockRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUnblockRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUnblockRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUnblockRequest} MeUnblockRequest
         */
        MeUnblockRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUnblockRequest)
                return object;
            var message = new $root.me.MeUnblockRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.MeUnblockRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUnblockRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUnblockRequest
         * @static
         * @param {me.MeUnblockRequest} message MeUnblockRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUnblockRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this MeUnblockRequest to JSON.
         * @function toJSON
         * @memberof me.MeUnblockRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUnblockRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUnblockRequest
         * @function getTypeUrl
         * @memberof me.MeUnblockRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUnblockRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUnblockRequest";
        };

        return MeUnblockRequest;
    })();

    me.MeListBlockingRequest = (function() {

        /**
         * Properties of a MeListBlockingRequest.
         * @memberof me
         * @interface IMeListBlockingRequest
         * @property {google.protobuf.IStringValue|null} [cursor] MeListBlockingRequest cursor
         */

        /**
         * Constructs a new MeListBlockingRequest.
         * @memberof me
         * @classdesc Represents a MeListBlockingRequest.
         * @implements IMeListBlockingRequest
         * @constructor
         * @param {me.IMeListBlockingRequest=} [properties] Properties to set
         */
        function MeListBlockingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListBlockingRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListBlockingRequest
         * @instance
         */
        MeListBlockingRequest.prototype.cursor = null;

        /**
         * Creates a new MeListBlockingRequest instance using the specified properties.
         * @function create
         * @memberof me.MeListBlockingRequest
         * @static
         * @param {me.IMeListBlockingRequest=} [properties] Properties to set
         * @returns {me.MeListBlockingRequest} MeListBlockingRequest instance
         */
        MeListBlockingRequest.create = function create(properties) {
            return new MeListBlockingRequest(properties);
        };

        /**
         * Encodes the specified MeListBlockingRequest message. Does not implicitly {@link me.MeListBlockingRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeListBlockingRequest
         * @static
         * @param {me.IMeListBlockingRequest} message MeListBlockingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListBlockingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListBlockingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListBlockingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListBlockingRequest} MeListBlockingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListBlockingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListBlockingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListBlockingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListBlockingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListBlockingRequest} MeListBlockingRequest
         */
        MeListBlockingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListBlockingRequest)
                return object;
            var message = new $root.me.MeListBlockingRequest();
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListBlockingRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListBlockingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListBlockingRequest
         * @static
         * @param {me.MeListBlockingRequest} message MeListBlockingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListBlockingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.cursor = null;
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MeListBlockingRequest to JSON.
         * @function toJSON
         * @memberof me.MeListBlockingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListBlockingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListBlockingRequest
         * @function getTypeUrl
         * @memberof me.MeListBlockingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListBlockingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListBlockingRequest";
        };

        return MeListBlockingRequest;
    })();

    me.MeListBlockingResponse = (function() {

        /**
         * Properties of a MeListBlockingResponse.
         * @memberof me
         * @interface IMeListBlockingResponse
         * @property {Array.<user.IUserV1>|null} [userList] MeListBlockingResponse userList
         * @property {google.protobuf.IStringValue|null} [cursor] MeListBlockingResponse cursor
         */

        /**
         * Constructs a new MeListBlockingResponse.
         * @memberof me
         * @classdesc Represents a MeListBlockingResponse.
         * @implements IMeListBlockingResponse
         * @constructor
         * @param {me.IMeListBlockingResponse=} [properties] Properties to set
         */
        function MeListBlockingResponse(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListBlockingResponse userList.
         * @member {Array.<user.IUserV1>} userList
         * @memberof me.MeListBlockingResponse
         * @instance
         */
        MeListBlockingResponse.prototype.userList = $util.emptyArray;

        /**
         * MeListBlockingResponse cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListBlockingResponse
         * @instance
         */
        MeListBlockingResponse.prototype.cursor = null;

        /**
         * Creates a new MeListBlockingResponse instance using the specified properties.
         * @function create
         * @memberof me.MeListBlockingResponse
         * @static
         * @param {me.IMeListBlockingResponse=} [properties] Properties to set
         * @returns {me.MeListBlockingResponse} MeListBlockingResponse instance
         */
        MeListBlockingResponse.create = function create(properties) {
            return new MeListBlockingResponse(properties);
        };

        /**
         * Encodes the specified MeListBlockingResponse message. Does not implicitly {@link me.MeListBlockingResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeListBlockingResponse
         * @static
         * @param {me.IMeListBlockingResponse} message MeListBlockingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListBlockingResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.user.UserV1.encode(message.userList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListBlockingResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListBlockingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListBlockingResponse} MeListBlockingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListBlockingResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListBlockingResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.user.UserV1.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListBlockingResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListBlockingResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListBlockingResponse} MeListBlockingResponse
         */
        MeListBlockingResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListBlockingResponse)
                return object;
            var message = new $root.me.MeListBlockingResponse();
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".me.MeListBlockingResponse.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".me.MeListBlockingResponse.userList: object expected");
                    message.userList[i] = $root.user.UserV1.fromObject(object.userList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListBlockingResponse.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListBlockingResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListBlockingResponse
         * @static
         * @param {me.MeListBlockingResponse} message MeListBlockingResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListBlockingResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.user.UserV1.toObject(message.userList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MeListBlockingResponse to JSON.
         * @function toJSON
         * @memberof me.MeListBlockingResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListBlockingResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListBlockingResponse
         * @function getTypeUrl
         * @memberof me.MeListBlockingResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListBlockingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListBlockingResponse";
        };

        return MeListBlockingResponse;
    })();

    me.MeIsBlockedByRequest = (function() {

        /**
         * Properties of a MeIsBlockedByRequest.
         * @memberof me
         * @interface IMeIsBlockedByRequest
         * @property {google.protobuf.IStringValue|null} [userId] MeIsBlockedByRequest userId
         */

        /**
         * Constructs a new MeIsBlockedByRequest.
         * @memberof me
         * @classdesc Represents a MeIsBlockedByRequest.
         * @implements IMeIsBlockedByRequest
         * @constructor
         * @param {me.IMeIsBlockedByRequest=} [properties] Properties to set
         */
        function MeIsBlockedByRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsBlockedByRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.MeIsBlockedByRequest
         * @instance
         */
        MeIsBlockedByRequest.prototype.userId = null;

        /**
         * Creates a new MeIsBlockedByRequest instance using the specified properties.
         * @function create
         * @memberof me.MeIsBlockedByRequest
         * @static
         * @param {me.IMeIsBlockedByRequest=} [properties] Properties to set
         * @returns {me.MeIsBlockedByRequest} MeIsBlockedByRequest instance
         */
        MeIsBlockedByRequest.create = function create(properties) {
            return new MeIsBlockedByRequest(properties);
        };

        /**
         * Encodes the specified MeIsBlockedByRequest message. Does not implicitly {@link me.MeIsBlockedByRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsBlockedByRequest
         * @static
         * @param {me.IMeIsBlockedByRequest} message MeIsBlockedByRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsBlockedByRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsBlockedByRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsBlockedByRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsBlockedByRequest} MeIsBlockedByRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsBlockedByRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsBlockedByRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsBlockedByRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsBlockedByRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsBlockedByRequest} MeIsBlockedByRequest
         */
        MeIsBlockedByRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsBlockedByRequest)
                return object;
            var message = new $root.me.MeIsBlockedByRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.MeIsBlockedByRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsBlockedByRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsBlockedByRequest
         * @static
         * @param {me.MeIsBlockedByRequest} message MeIsBlockedByRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsBlockedByRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this MeIsBlockedByRequest to JSON.
         * @function toJSON
         * @memberof me.MeIsBlockedByRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsBlockedByRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsBlockedByRequest
         * @function getTypeUrl
         * @memberof me.MeIsBlockedByRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsBlockedByRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsBlockedByRequest";
        };

        return MeIsBlockedByRequest;
    })();

    me.MeIsBlockedByResponse = (function() {

        /**
         * Properties of a MeIsBlockedByResponse.
         * @memberof me
         * @interface IMeIsBlockedByResponse
         * @property {google.protobuf.IBoolValue|null} [isBlocked] MeIsBlockedByResponse isBlocked
         */

        /**
         * Constructs a new MeIsBlockedByResponse.
         * @memberof me
         * @classdesc Represents a MeIsBlockedByResponse.
         * @implements IMeIsBlockedByResponse
         * @constructor
         * @param {me.IMeIsBlockedByResponse=} [properties] Properties to set
         */
        function MeIsBlockedByResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsBlockedByResponse isBlocked.
         * @member {google.protobuf.IBoolValue|null|undefined} isBlocked
         * @memberof me.MeIsBlockedByResponse
         * @instance
         */
        MeIsBlockedByResponse.prototype.isBlocked = null;

        /**
         * Creates a new MeIsBlockedByResponse instance using the specified properties.
         * @function create
         * @memberof me.MeIsBlockedByResponse
         * @static
         * @param {me.IMeIsBlockedByResponse=} [properties] Properties to set
         * @returns {me.MeIsBlockedByResponse} MeIsBlockedByResponse instance
         */
        MeIsBlockedByResponse.create = function create(properties) {
            return new MeIsBlockedByResponse(properties);
        };

        /**
         * Encodes the specified MeIsBlockedByResponse message. Does not implicitly {@link me.MeIsBlockedByResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsBlockedByResponse
         * @static
         * @param {me.IMeIsBlockedByResponse} message MeIsBlockedByResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsBlockedByResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                $root.google.protobuf.BoolValue.encode(message.isBlocked, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsBlockedByResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsBlockedByResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsBlockedByResponse} MeIsBlockedByResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsBlockedByResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsBlockedByResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.isBlocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsBlockedByResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsBlockedByResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsBlockedByResponse} MeIsBlockedByResponse
         */
        MeIsBlockedByResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsBlockedByResponse)
                return object;
            var message = new $root.me.MeIsBlockedByResponse();
            if (object.isBlocked != null) {
                if (typeof object.isBlocked !== "object")
                    throw TypeError(".me.MeIsBlockedByResponse.isBlocked: object expected");
                message.isBlocked = $root.google.protobuf.BoolValue.fromObject(object.isBlocked);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsBlockedByResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsBlockedByResponse
         * @static
         * @param {me.MeIsBlockedByResponse} message MeIsBlockedByResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsBlockedByResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.isBlocked = null;
            if (message.isBlocked != null && message.hasOwnProperty("isBlocked"))
                object.isBlocked = $root.google.protobuf.BoolValue.toObject(message.isBlocked, options);
            return object;
        };

        /**
         * Converts this MeIsBlockedByResponse to JSON.
         * @function toJSON
         * @memberof me.MeIsBlockedByResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsBlockedByResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsBlockedByResponse
         * @function getTypeUrl
         * @memberof me.MeIsBlockedByResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsBlockedByResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsBlockedByResponse";
        };

        return MeIsBlockedByResponse;
    })();

    me.MeGetNotificationCountSinceRequestV2 = (function() {

        /**
         * Properties of a MeGetNotificationCountSinceRequestV2.
         * @memberof me
         * @interface IMeGetNotificationCountSinceRequestV2
         * @property {google.protobuf.ITimestamp|null} [since] MeGetNotificationCountSinceRequestV2 since
         * @property {types.OS|null} [os] MeGetNotificationCountSinceRequestV2 os
         * @property {notification.NotificationCategory|null} [category] MeGetNotificationCountSinceRequestV2 category
         * @property {Array.<notification.NotificationCategory>|null} [categoryList] MeGetNotificationCountSinceRequestV2 categoryList
         */

        /**
         * Constructs a new MeGetNotificationCountSinceRequestV2.
         * @memberof me
         * @classdesc Represents a MeGetNotificationCountSinceRequestV2.
         * @implements IMeGetNotificationCountSinceRequestV2
         * @constructor
         * @param {me.IMeGetNotificationCountSinceRequestV2=} [properties] Properties to set
         */
        function MeGetNotificationCountSinceRequestV2(properties) {
            this.categoryList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetNotificationCountSinceRequestV2 since.
         * @member {google.protobuf.ITimestamp|null|undefined} since
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @instance
         */
        MeGetNotificationCountSinceRequestV2.prototype.since = null;

        /**
         * MeGetNotificationCountSinceRequestV2 os.
         * @member {types.OS} os
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @instance
         */
        MeGetNotificationCountSinceRequestV2.prototype.os = 0;

        /**
         * MeGetNotificationCountSinceRequestV2 category.
         * @member {notification.NotificationCategory} category
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @instance
         */
        MeGetNotificationCountSinceRequestV2.prototype.category = 0;

        /**
         * MeGetNotificationCountSinceRequestV2 categoryList.
         * @member {Array.<notification.NotificationCategory>} categoryList
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @instance
         */
        MeGetNotificationCountSinceRequestV2.prototype.categoryList = $util.emptyArray;

        /**
         * Creates a new MeGetNotificationCountSinceRequestV2 instance using the specified properties.
         * @function create
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @static
         * @param {me.IMeGetNotificationCountSinceRequestV2=} [properties] Properties to set
         * @returns {me.MeGetNotificationCountSinceRequestV2} MeGetNotificationCountSinceRequestV2 instance
         */
        MeGetNotificationCountSinceRequestV2.create = function create(properties) {
            return new MeGetNotificationCountSinceRequestV2(properties);
        };

        /**
         * Encodes the specified MeGetNotificationCountSinceRequestV2 message. Does not implicitly {@link me.MeGetNotificationCountSinceRequestV2.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @static
         * @param {me.IMeGetNotificationCountSinceRequestV2} message MeGetNotificationCountSinceRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetNotificationCountSinceRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.since != null && Object.hasOwnProperty.call(message, "since"))
                $root.google.protobuf.Timestamp.encode(message.since, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.os != null && Object.hasOwnProperty.call(message, "os"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.os);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.category);
            if (message.categoryList != null && message.categoryList.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.categoryList.length; ++i)
                    writer.int32(message.categoryList[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a MeGetNotificationCountSinceRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetNotificationCountSinceRequestV2} MeGetNotificationCountSinceRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetNotificationCountSinceRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetNotificationCountSinceRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.since = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.os = reader.int32();
                    break;
                case 3:
                    message.category = reader.int32();
                    break;
                case 4:
                    if (!(message.categoryList && message.categoryList.length))
                        message.categoryList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.categoryList.push(reader.int32());
                    } else
                        message.categoryList.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetNotificationCountSinceRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetNotificationCountSinceRequestV2} MeGetNotificationCountSinceRequestV2
         */
        MeGetNotificationCountSinceRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetNotificationCountSinceRequestV2)
                return object;
            var message = new $root.me.MeGetNotificationCountSinceRequestV2();
            if (object.since != null) {
                if (typeof object.since !== "object")
                    throw TypeError(".me.MeGetNotificationCountSinceRequestV2.since: object expected");
                message.since = $root.google.protobuf.Timestamp.fromObject(object.since);
            }
            switch (object.os) {
            case "OS_UNSPECIFIED":
            case 0:
                message.os = 0;
                break;
            case "OS_IOS":
            case 1:
                message.os = 1;
                break;
            case "OS_ANDROID":
            case 2:
                message.os = 2;
                break;
            }
            switch (object.category) {
            case "NOTIFICATION_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "NOTIFICATION_CATEGORY_OPERATION":
            case 1:
                message.category = 1;
                break;
            case "NOTIFICATION_CATEGORY_USER_RELATED":
            case 2:
                message.category = 2;
                break;
            case "NOTIFICATION_CATEGORY_FOLLOWING_RELATED":
            case 3:
                message.category = 3;
                break;
            case "NOTIFICATION_CATEGORY_WAKEUP_DIALOG":
            case 4:
                message.category = 4;
                break;
            }
            if (object.categoryList) {
                if (!Array.isArray(object.categoryList))
                    throw TypeError(".me.MeGetNotificationCountSinceRequestV2.categoryList: array expected");
                message.categoryList = [];
                for (var i = 0; i < object.categoryList.length; ++i)
                    switch (object.categoryList[i]) {
                    default:
                    case "NOTIFICATION_CATEGORY_UNSPECIFIED":
                    case 0:
                        message.categoryList[i] = 0;
                        break;
                    case "NOTIFICATION_CATEGORY_OPERATION":
                    case 1:
                        message.categoryList[i] = 1;
                        break;
                    case "NOTIFICATION_CATEGORY_USER_RELATED":
                    case 2:
                        message.categoryList[i] = 2;
                        break;
                    case "NOTIFICATION_CATEGORY_FOLLOWING_RELATED":
                    case 3:
                        message.categoryList[i] = 3;
                        break;
                    case "NOTIFICATION_CATEGORY_WAKEUP_DIALOG":
                    case 4:
                        message.categoryList[i] = 4;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetNotificationCountSinceRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @static
         * @param {me.MeGetNotificationCountSinceRequestV2} message MeGetNotificationCountSinceRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetNotificationCountSinceRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.categoryList = [];
            if (options.defaults) {
                object.since = null;
                object.os = options.enums === String ? "OS_UNSPECIFIED" : 0;
                object.category = options.enums === String ? "NOTIFICATION_CATEGORY_UNSPECIFIED" : 0;
            }
            if (message.since != null && message.hasOwnProperty("since"))
                object.since = $root.google.protobuf.Timestamp.toObject(message.since, options);
            if (message.os != null && message.hasOwnProperty("os"))
                object.os = options.enums === String ? $root.types.OS[message.os] : message.os;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.notification.NotificationCategory[message.category] : message.category;
            if (message.categoryList && message.categoryList.length) {
                object.categoryList = [];
                for (var j = 0; j < message.categoryList.length; ++j)
                    object.categoryList[j] = options.enums === String ? $root.notification.NotificationCategory[message.categoryList[j]] : message.categoryList[j];
            }
            return object;
        };

        /**
         * Converts this MeGetNotificationCountSinceRequestV2 to JSON.
         * @function toJSON
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetNotificationCountSinceRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetNotificationCountSinceRequestV2
         * @function getTypeUrl
         * @memberof me.MeGetNotificationCountSinceRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetNotificationCountSinceRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetNotificationCountSinceRequestV2";
        };

        return MeGetNotificationCountSinceRequestV2;
    })();

    me.MeGetNotificationCountSinceResponse = (function() {

        /**
         * Properties of a MeGetNotificationCountSinceResponse.
         * @memberof me
         * @interface IMeGetNotificationCountSinceResponse
         * @property {google.protobuf.IInt32Value|null} [count] MeGetNotificationCountSinceResponse count
         */

        /**
         * Constructs a new MeGetNotificationCountSinceResponse.
         * @memberof me
         * @classdesc Represents a MeGetNotificationCountSinceResponse.
         * @implements IMeGetNotificationCountSinceResponse
         * @constructor
         * @param {me.IMeGetNotificationCountSinceResponse=} [properties] Properties to set
         */
        function MeGetNotificationCountSinceResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetNotificationCountSinceResponse count.
         * @member {google.protobuf.IInt32Value|null|undefined} count
         * @memberof me.MeGetNotificationCountSinceResponse
         * @instance
         */
        MeGetNotificationCountSinceResponse.prototype.count = null;

        /**
         * Creates a new MeGetNotificationCountSinceResponse instance using the specified properties.
         * @function create
         * @memberof me.MeGetNotificationCountSinceResponse
         * @static
         * @param {me.IMeGetNotificationCountSinceResponse=} [properties] Properties to set
         * @returns {me.MeGetNotificationCountSinceResponse} MeGetNotificationCountSinceResponse instance
         */
        MeGetNotificationCountSinceResponse.create = function create(properties) {
            return new MeGetNotificationCountSinceResponse(properties);
        };

        /**
         * Encodes the specified MeGetNotificationCountSinceResponse message. Does not implicitly {@link me.MeGetNotificationCountSinceResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetNotificationCountSinceResponse
         * @static
         * @param {me.IMeGetNotificationCountSinceResponse} message MeGetNotificationCountSinceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetNotificationCountSinceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                $root.google.protobuf.Int32Value.encode(message.count, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetNotificationCountSinceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetNotificationCountSinceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetNotificationCountSinceResponse} MeGetNotificationCountSinceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetNotificationCountSinceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetNotificationCountSinceResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.count = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetNotificationCountSinceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetNotificationCountSinceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetNotificationCountSinceResponse} MeGetNotificationCountSinceResponse
         */
        MeGetNotificationCountSinceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetNotificationCountSinceResponse)
                return object;
            var message = new $root.me.MeGetNotificationCountSinceResponse();
            if (object.count != null) {
                if (typeof object.count !== "object")
                    throw TypeError(".me.MeGetNotificationCountSinceResponse.count: object expected");
                message.count = $root.google.protobuf.Int32Value.fromObject(object.count);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetNotificationCountSinceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetNotificationCountSinceResponse
         * @static
         * @param {me.MeGetNotificationCountSinceResponse} message MeGetNotificationCountSinceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetNotificationCountSinceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.count = null;
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = $root.google.protobuf.Int32Value.toObject(message.count, options);
            return object;
        };

        /**
         * Converts this MeGetNotificationCountSinceResponse to JSON.
         * @function toJSON
         * @memberof me.MeGetNotificationCountSinceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetNotificationCountSinceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetNotificationCountSinceResponse
         * @function getTypeUrl
         * @memberof me.MeGetNotificationCountSinceResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetNotificationCountSinceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetNotificationCountSinceResponse";
        };

        return MeGetNotificationCountSinceResponse;
    })();

    me.MeListNotificationRequestV2 = (function() {

        /**
         * Properties of a MeListNotificationRequestV2.
         * @memberof me
         * @interface IMeListNotificationRequestV2
         * @property {google.protobuf.IStringValue|null} [cursor] MeListNotificationRequestV2 cursor
         * @property {types.OS|null} [os] MeListNotificationRequestV2 os
         * @property {notification.NotificationCategory|null} [category] MeListNotificationRequestV2 category
         * @property {Array.<notification.NotificationCategory>|null} [categoryList] MeListNotificationRequestV2 categoryList
         */

        /**
         * Constructs a new MeListNotificationRequestV2.
         * @memberof me
         * @classdesc Represents a MeListNotificationRequestV2.
         * @implements IMeListNotificationRequestV2
         * @constructor
         * @param {me.IMeListNotificationRequestV2=} [properties] Properties to set
         */
        function MeListNotificationRequestV2(properties) {
            this.categoryList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListNotificationRequestV2 cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListNotificationRequestV2
         * @instance
         */
        MeListNotificationRequestV2.prototype.cursor = null;

        /**
         * MeListNotificationRequestV2 os.
         * @member {types.OS} os
         * @memberof me.MeListNotificationRequestV2
         * @instance
         */
        MeListNotificationRequestV2.prototype.os = 0;

        /**
         * MeListNotificationRequestV2 category.
         * @member {notification.NotificationCategory} category
         * @memberof me.MeListNotificationRequestV2
         * @instance
         */
        MeListNotificationRequestV2.prototype.category = 0;

        /**
         * MeListNotificationRequestV2 categoryList.
         * @member {Array.<notification.NotificationCategory>} categoryList
         * @memberof me.MeListNotificationRequestV2
         * @instance
         */
        MeListNotificationRequestV2.prototype.categoryList = $util.emptyArray;

        /**
         * Creates a new MeListNotificationRequestV2 instance using the specified properties.
         * @function create
         * @memberof me.MeListNotificationRequestV2
         * @static
         * @param {me.IMeListNotificationRequestV2=} [properties] Properties to set
         * @returns {me.MeListNotificationRequestV2} MeListNotificationRequestV2 instance
         */
        MeListNotificationRequestV2.create = function create(properties) {
            return new MeListNotificationRequestV2(properties);
        };

        /**
         * Encodes the specified MeListNotificationRequestV2 message. Does not implicitly {@link me.MeListNotificationRequestV2.verify|verify} messages.
         * @function encode
         * @memberof me.MeListNotificationRequestV2
         * @static
         * @param {me.IMeListNotificationRequestV2} message MeListNotificationRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListNotificationRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.os != null && Object.hasOwnProperty.call(message, "os"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.os);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.category);
            if (message.categoryList != null && message.categoryList.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.categoryList.length; ++i)
                    writer.int32(message.categoryList[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a MeListNotificationRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListNotificationRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListNotificationRequestV2} MeListNotificationRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListNotificationRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListNotificationRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.os = reader.int32();
                    break;
                case 3:
                    message.category = reader.int32();
                    break;
                case 4:
                    if (!(message.categoryList && message.categoryList.length))
                        message.categoryList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.categoryList.push(reader.int32());
                    } else
                        message.categoryList.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListNotificationRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListNotificationRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListNotificationRequestV2} MeListNotificationRequestV2
         */
        MeListNotificationRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListNotificationRequestV2)
                return object;
            var message = new $root.me.MeListNotificationRequestV2();
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListNotificationRequestV2.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            switch (object.os) {
            case "OS_UNSPECIFIED":
            case 0:
                message.os = 0;
                break;
            case "OS_IOS":
            case 1:
                message.os = 1;
                break;
            case "OS_ANDROID":
            case 2:
                message.os = 2;
                break;
            }
            switch (object.category) {
            case "NOTIFICATION_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "NOTIFICATION_CATEGORY_OPERATION":
            case 1:
                message.category = 1;
                break;
            case "NOTIFICATION_CATEGORY_USER_RELATED":
            case 2:
                message.category = 2;
                break;
            case "NOTIFICATION_CATEGORY_FOLLOWING_RELATED":
            case 3:
                message.category = 3;
                break;
            case "NOTIFICATION_CATEGORY_WAKEUP_DIALOG":
            case 4:
                message.category = 4;
                break;
            }
            if (object.categoryList) {
                if (!Array.isArray(object.categoryList))
                    throw TypeError(".me.MeListNotificationRequestV2.categoryList: array expected");
                message.categoryList = [];
                for (var i = 0; i < object.categoryList.length; ++i)
                    switch (object.categoryList[i]) {
                    default:
                    case "NOTIFICATION_CATEGORY_UNSPECIFIED":
                    case 0:
                        message.categoryList[i] = 0;
                        break;
                    case "NOTIFICATION_CATEGORY_OPERATION":
                    case 1:
                        message.categoryList[i] = 1;
                        break;
                    case "NOTIFICATION_CATEGORY_USER_RELATED":
                    case 2:
                        message.categoryList[i] = 2;
                        break;
                    case "NOTIFICATION_CATEGORY_FOLLOWING_RELATED":
                    case 3:
                        message.categoryList[i] = 3;
                        break;
                    case "NOTIFICATION_CATEGORY_WAKEUP_DIALOG":
                    case 4:
                        message.categoryList[i] = 4;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListNotificationRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListNotificationRequestV2
         * @static
         * @param {me.MeListNotificationRequestV2} message MeListNotificationRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListNotificationRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.categoryList = [];
            if (options.defaults) {
                object.cursor = null;
                object.os = options.enums === String ? "OS_UNSPECIFIED" : 0;
                object.category = options.enums === String ? "NOTIFICATION_CATEGORY_UNSPECIFIED" : 0;
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            if (message.os != null && message.hasOwnProperty("os"))
                object.os = options.enums === String ? $root.types.OS[message.os] : message.os;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.notification.NotificationCategory[message.category] : message.category;
            if (message.categoryList && message.categoryList.length) {
                object.categoryList = [];
                for (var j = 0; j < message.categoryList.length; ++j)
                    object.categoryList[j] = options.enums === String ? $root.notification.NotificationCategory[message.categoryList[j]] : message.categoryList[j];
            }
            return object;
        };

        /**
         * Converts this MeListNotificationRequestV2 to JSON.
         * @function toJSON
         * @memberof me.MeListNotificationRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListNotificationRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListNotificationRequestV2
         * @function getTypeUrl
         * @memberof me.MeListNotificationRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListNotificationRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListNotificationRequestV2";
        };

        return MeListNotificationRequestV2;
    })();

    me.MeListNotificationResponseV2 = (function() {

        /**
         * Properties of a MeListNotificationResponseV2.
         * @memberof me
         * @interface IMeListNotificationResponseV2
         * @property {Array.<notification.INotificationV2>|null} [notificationList] MeListNotificationResponseV2 notificationList
         * @property {google.protobuf.IStringValue|null} [cursor] MeListNotificationResponseV2 cursor
         */

        /**
         * Constructs a new MeListNotificationResponseV2.
         * @memberof me
         * @classdesc Represents a MeListNotificationResponseV2.
         * @implements IMeListNotificationResponseV2
         * @constructor
         * @param {me.IMeListNotificationResponseV2=} [properties] Properties to set
         */
        function MeListNotificationResponseV2(properties) {
            this.notificationList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListNotificationResponseV2 notificationList.
         * @member {Array.<notification.INotificationV2>} notificationList
         * @memberof me.MeListNotificationResponseV2
         * @instance
         */
        MeListNotificationResponseV2.prototype.notificationList = $util.emptyArray;

        /**
         * MeListNotificationResponseV2 cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListNotificationResponseV2
         * @instance
         */
        MeListNotificationResponseV2.prototype.cursor = null;

        /**
         * Creates a new MeListNotificationResponseV2 instance using the specified properties.
         * @function create
         * @memberof me.MeListNotificationResponseV2
         * @static
         * @param {me.IMeListNotificationResponseV2=} [properties] Properties to set
         * @returns {me.MeListNotificationResponseV2} MeListNotificationResponseV2 instance
         */
        MeListNotificationResponseV2.create = function create(properties) {
            return new MeListNotificationResponseV2(properties);
        };

        /**
         * Encodes the specified MeListNotificationResponseV2 message. Does not implicitly {@link me.MeListNotificationResponseV2.verify|verify} messages.
         * @function encode
         * @memberof me.MeListNotificationResponseV2
         * @static
         * @param {me.IMeListNotificationResponseV2} message MeListNotificationResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListNotificationResponseV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.notificationList != null && message.notificationList.length)
                for (var i = 0; i < message.notificationList.length; ++i)
                    $root.notification.NotificationV2.encode(message.notificationList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListNotificationResponseV2 message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListNotificationResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListNotificationResponseV2} MeListNotificationResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListNotificationResponseV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListNotificationResponseV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.notificationList && message.notificationList.length))
                        message.notificationList = [];
                    message.notificationList.push($root.notification.NotificationV2.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListNotificationResponseV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListNotificationResponseV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListNotificationResponseV2} MeListNotificationResponseV2
         */
        MeListNotificationResponseV2.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListNotificationResponseV2)
                return object;
            var message = new $root.me.MeListNotificationResponseV2();
            if (object.notificationList) {
                if (!Array.isArray(object.notificationList))
                    throw TypeError(".me.MeListNotificationResponseV2.notificationList: array expected");
                message.notificationList = [];
                for (var i = 0; i < object.notificationList.length; ++i) {
                    if (typeof object.notificationList[i] !== "object")
                        throw TypeError(".me.MeListNotificationResponseV2.notificationList: object expected");
                    message.notificationList[i] = $root.notification.NotificationV2.fromObject(object.notificationList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListNotificationResponseV2.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListNotificationResponseV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListNotificationResponseV2
         * @static
         * @param {me.MeListNotificationResponseV2} message MeListNotificationResponseV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListNotificationResponseV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.notificationList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.notificationList && message.notificationList.length) {
                object.notificationList = [];
                for (var j = 0; j < message.notificationList.length; ++j)
                    object.notificationList[j] = $root.notification.NotificationV2.toObject(message.notificationList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MeListNotificationResponseV2 to JSON.
         * @function toJSON
         * @memberof me.MeListNotificationResponseV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListNotificationResponseV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListNotificationResponseV2
         * @function getTypeUrl
         * @memberof me.MeListNotificationResponseV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListNotificationResponseV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListNotificationResponseV2";
        };

        return MeListNotificationResponseV2;
    })();

    me.MeUpdateNotificationTokenRequest = (function() {

        /**
         * Properties of a MeUpdateNotificationTokenRequest.
         * @memberof me
         * @interface IMeUpdateNotificationTokenRequest
         * @property {google.protobuf.IStringValue|null} [fcmToken] MeUpdateNotificationTokenRequest fcmToken
         * @property {google.protobuf.IStringValue|null} [apnsToken] MeUpdateNotificationTokenRequest apnsToken
         */

        /**
         * Constructs a new MeUpdateNotificationTokenRequest.
         * @memberof me
         * @classdesc Represents a MeUpdateNotificationTokenRequest.
         * @implements IMeUpdateNotificationTokenRequest
         * @constructor
         * @param {me.IMeUpdateNotificationTokenRequest=} [properties] Properties to set
         */
        function MeUpdateNotificationTokenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUpdateNotificationTokenRequest fcmToken.
         * @member {google.protobuf.IStringValue|null|undefined} fcmToken
         * @memberof me.MeUpdateNotificationTokenRequest
         * @instance
         */
        MeUpdateNotificationTokenRequest.prototype.fcmToken = null;

        /**
         * MeUpdateNotificationTokenRequest apnsToken.
         * @member {google.protobuf.IStringValue|null|undefined} apnsToken
         * @memberof me.MeUpdateNotificationTokenRequest
         * @instance
         */
        MeUpdateNotificationTokenRequest.prototype.apnsToken = null;

        /**
         * Creates a new MeUpdateNotificationTokenRequest instance using the specified properties.
         * @function create
         * @memberof me.MeUpdateNotificationTokenRequest
         * @static
         * @param {me.IMeUpdateNotificationTokenRequest=} [properties] Properties to set
         * @returns {me.MeUpdateNotificationTokenRequest} MeUpdateNotificationTokenRequest instance
         */
        MeUpdateNotificationTokenRequest.create = function create(properties) {
            return new MeUpdateNotificationTokenRequest(properties);
        };

        /**
         * Encodes the specified MeUpdateNotificationTokenRequest message. Does not implicitly {@link me.MeUpdateNotificationTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeUpdateNotificationTokenRequest
         * @static
         * @param {me.IMeUpdateNotificationTokenRequest} message MeUpdateNotificationTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUpdateNotificationTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fcmToken != null && Object.hasOwnProperty.call(message, "fcmToken"))
                $root.google.protobuf.StringValue.encode(message.fcmToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.apnsToken != null && Object.hasOwnProperty.call(message, "apnsToken"))
                $root.google.protobuf.StringValue.encode(message.apnsToken, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeUpdateNotificationTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUpdateNotificationTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUpdateNotificationTokenRequest} MeUpdateNotificationTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUpdateNotificationTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUpdateNotificationTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fcmToken = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.apnsToken = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUpdateNotificationTokenRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUpdateNotificationTokenRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUpdateNotificationTokenRequest} MeUpdateNotificationTokenRequest
         */
        MeUpdateNotificationTokenRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUpdateNotificationTokenRequest)
                return object;
            var message = new $root.me.MeUpdateNotificationTokenRequest();
            if (object.fcmToken != null) {
                if (typeof object.fcmToken !== "object")
                    throw TypeError(".me.MeUpdateNotificationTokenRequest.fcmToken: object expected");
                message.fcmToken = $root.google.protobuf.StringValue.fromObject(object.fcmToken);
            }
            if (object.apnsToken != null) {
                if (typeof object.apnsToken !== "object")
                    throw TypeError(".me.MeUpdateNotificationTokenRequest.apnsToken: object expected");
                message.apnsToken = $root.google.protobuf.StringValue.fromObject(object.apnsToken);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUpdateNotificationTokenRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUpdateNotificationTokenRequest
         * @static
         * @param {me.MeUpdateNotificationTokenRequest} message MeUpdateNotificationTokenRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUpdateNotificationTokenRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.fcmToken = null;
                object.apnsToken = null;
            }
            if (message.fcmToken != null && message.hasOwnProperty("fcmToken"))
                object.fcmToken = $root.google.protobuf.StringValue.toObject(message.fcmToken, options);
            if (message.apnsToken != null && message.hasOwnProperty("apnsToken"))
                object.apnsToken = $root.google.protobuf.StringValue.toObject(message.apnsToken, options);
            return object;
        };

        /**
         * Converts this MeUpdateNotificationTokenRequest to JSON.
         * @function toJSON
         * @memberof me.MeUpdateNotificationTokenRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUpdateNotificationTokenRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUpdateNotificationTokenRequest
         * @function getTypeUrl
         * @memberof me.MeUpdateNotificationTokenRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUpdateNotificationTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUpdateNotificationTokenRequest";
        };

        return MeUpdateNotificationTokenRequest;
    })();

    me.Notification = (function() {

        /**
         * Properties of a Notification.
         * @memberof me
         * @interface INotification
         * @property {google.protobuf.IStringValue|null} [title] Notification title
         * @property {google.protobuf.IStringValue|null} [message] Notification message
         * @property {google.protobuf.IStringValue|null} [actionUrl] Notification actionUrl
         * @property {google.protobuf.ITimestamp|null} [createdAt] Notification createdAt
         */

        /**
         * Constructs a new Notification.
         * @memberof me
         * @classdesc Represents a Notification.
         * @implements INotification
         * @constructor
         * @param {me.INotification=} [properties] Properties to set
         */
        function Notification(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Notification title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof me.Notification
         * @instance
         */
        Notification.prototype.title = null;

        /**
         * Notification message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof me.Notification
         * @instance
         */
        Notification.prototype.message = null;

        /**
         * Notification actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof me.Notification
         * @instance
         */
        Notification.prototype.actionUrl = null;

        /**
         * Notification createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof me.Notification
         * @instance
         */
        Notification.prototype.createdAt = null;

        /**
         * Creates a new Notification instance using the specified properties.
         * @function create
         * @memberof me.Notification
         * @static
         * @param {me.INotification=} [properties] Properties to set
         * @returns {me.Notification} Notification instance
         */
        Notification.create = function create(properties) {
            return new Notification(properties);
        };

        /**
         * Encodes the specified Notification message. Does not implicitly {@link me.Notification.verify|verify} messages.
         * @function encode
         * @memberof me.Notification
         * @static
         * @param {me.INotification} message Notification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Notification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Notification message from the specified reader or buffer.
         * @function decode
         * @memberof me.Notification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.Notification} Notification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Notification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.Notification();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Notification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.Notification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.Notification} Notification
         */
        Notification.fromObject = function fromObject(object) {
            if (object instanceof $root.me.Notification)
                return object;
            var message = new $root.me.Notification();
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".me.Notification.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".me.Notification.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".me.Notification.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".me.Notification.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a Notification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.Notification
         * @static
         * @param {me.Notification} message Notification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Notification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.title = null;
                object.message = null;
                object.actionUrl = null;
                object.createdAt = null;
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            return object;
        };

        /**
         * Converts this Notification to JSON.
         * @function toJSON
         * @memberof me.Notification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Notification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Notification
         * @function getTypeUrl
         * @memberof me.Notification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Notification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.Notification";
        };

        return Notification;
    })();

    me.MeListImageRequest = (function() {

        /**
         * Properties of a MeListImageRequest.
         * @memberof me
         * @interface IMeListImageRequest
         * @property {resource.ImageCategory|null} [category] MeListImageRequest category
         * @property {google.protobuf.IStringValue|null} [cursor] MeListImageRequest cursor
         * @property {google.protobuf.IInt32Value|null} [limit] MeListImageRequest limit
         */

        /**
         * Constructs a new MeListImageRequest.
         * @memberof me
         * @classdesc Represents a MeListImageRequest.
         * @implements IMeListImageRequest
         * @constructor
         * @param {me.IMeListImageRequest=} [properties] Properties to set
         */
        function MeListImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListImageRequest category.
         * @member {resource.ImageCategory} category
         * @memberof me.MeListImageRequest
         * @instance
         */
        MeListImageRequest.prototype.category = 0;

        /**
         * MeListImageRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListImageRequest
         * @instance
         */
        MeListImageRequest.prototype.cursor = null;

        /**
         * MeListImageRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof me.MeListImageRequest
         * @instance
         */
        MeListImageRequest.prototype.limit = null;

        /**
         * Creates a new MeListImageRequest instance using the specified properties.
         * @function create
         * @memberof me.MeListImageRequest
         * @static
         * @param {me.IMeListImageRequest=} [properties] Properties to set
         * @returns {me.MeListImageRequest} MeListImageRequest instance
         */
        MeListImageRequest.create = function create(properties) {
            return new MeListImageRequest(properties);
        };

        /**
         * Encodes the specified MeListImageRequest message. Does not implicitly {@link me.MeListImageRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeListImageRequest
         * @static
         * @param {me.IMeListImageRequest} message MeListImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.category);
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListImageRequest} MeListImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.category = reader.int32();
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListImageRequest} MeListImageRequest
         */
        MeListImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListImageRequest)
                return object;
            var message = new $root.me.MeListImageRequest();
            switch (object.category) {
            case "IMAGE_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "IMAGE_CATEGORY_USER_PROFILE":
            case 1:
                message.category = 1;
                break;
            case "IMAGE_CATEGORY_CHARACTER_ICON":
            case 2:
                message.category = 2;
                break;
            case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
            case 3:
                message.category = 3;
                break;
            case "IMAGE_CATEGORY_CHAT_BACKGROUND":
            case 4:
                message.category = 4;
                break;
            case "IMAGE_CATEGORY_TALK_BACKGROUND":
            case 5:
                message.category = 5;
                break;
            case "IMAGE_CATEGORY_CONTENT":
            case 6:
                message.category = 6;
                break;
            case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
            case 7:
                message.category = 7;
                break;
            case "IMAGE_CATEGORY_STAMP":
            case 8:
                message.category = 8;
                break;
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListImageRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".me.MeListImageRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListImageRequest
         * @static
         * @param {me.MeListImageRequest} message MeListImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.category = options.enums === String ? "IMAGE_CATEGORY_UNSPECIFIED" : 0;
                object.cursor = null;
                object.limit = null;
            }
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.resource.ImageCategory[message.category] : message.category;
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            return object;
        };

        /**
         * Converts this MeListImageRequest to JSON.
         * @function toJSON
         * @memberof me.MeListImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListImageRequest
         * @function getTypeUrl
         * @memberof me.MeListImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListImageRequest";
        };

        return MeListImageRequest;
    })();

    me.MeListImageResponse = (function() {

        /**
         * Properties of a MeListImageResponse.
         * @memberof me
         * @interface IMeListImageResponse
         * @property {Array.<resource.IImage>|null} [imageList] MeListImageResponse imageList
         * @property {google.protobuf.IStringValue|null} [cursor] MeListImageResponse cursor
         */

        /**
         * Constructs a new MeListImageResponse.
         * @memberof me
         * @classdesc Represents a MeListImageResponse.
         * @implements IMeListImageResponse
         * @constructor
         * @param {me.IMeListImageResponse=} [properties] Properties to set
         */
        function MeListImageResponse(properties) {
            this.imageList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListImageResponse imageList.
         * @member {Array.<resource.IImage>} imageList
         * @memberof me.MeListImageResponse
         * @instance
         */
        MeListImageResponse.prototype.imageList = $util.emptyArray;

        /**
         * MeListImageResponse cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListImageResponse
         * @instance
         */
        MeListImageResponse.prototype.cursor = null;

        /**
         * Creates a new MeListImageResponse instance using the specified properties.
         * @function create
         * @memberof me.MeListImageResponse
         * @static
         * @param {me.IMeListImageResponse=} [properties] Properties to set
         * @returns {me.MeListImageResponse} MeListImageResponse instance
         */
        MeListImageResponse.create = function create(properties) {
            return new MeListImageResponse(properties);
        };

        /**
         * Encodes the specified MeListImageResponse message. Does not implicitly {@link me.MeListImageResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeListImageResponse
         * @static
         * @param {me.IMeListImageResponse} message MeListImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListImageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageList != null && message.imageList.length)
                for (var i = 0; i < message.imageList.length; ++i)
                    $root.resource.Image.encode(message.imageList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListImageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListImageResponse} MeListImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListImageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListImageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.imageList && message.imageList.length))
                        message.imageList = [];
                    message.imageList.push($root.resource.Image.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListImageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListImageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListImageResponse} MeListImageResponse
         */
        MeListImageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListImageResponse)
                return object;
            var message = new $root.me.MeListImageResponse();
            if (object.imageList) {
                if (!Array.isArray(object.imageList))
                    throw TypeError(".me.MeListImageResponse.imageList: array expected");
                message.imageList = [];
                for (var i = 0; i < object.imageList.length; ++i) {
                    if (typeof object.imageList[i] !== "object")
                        throw TypeError(".me.MeListImageResponse.imageList: object expected");
                    message.imageList[i] = $root.resource.Image.fromObject(object.imageList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListImageResponse.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListImageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListImageResponse
         * @static
         * @param {me.MeListImageResponse} message MeListImageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListImageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.imageList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.imageList && message.imageList.length) {
                object.imageList = [];
                for (var j = 0; j < message.imageList.length; ++j)
                    object.imageList[j] = $root.resource.Image.toObject(message.imageList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MeListImageResponse to JSON.
         * @function toJSON
         * @memberof me.MeListImageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListImageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListImageResponse
         * @function getTypeUrl
         * @memberof me.MeListImageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListImageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListImageResponse";
        };

        return MeListImageResponse;
    })();

    me.MeListAudioRequest = (function() {

        /**
         * Properties of a MeListAudioRequest.
         * @memberof me
         * @interface IMeListAudioRequest
         * @property {google.protobuf.IStringValue|null} [cursor] MeListAudioRequest cursor
         * @property {google.protobuf.IInt32Value|null} [limit] MeListAudioRequest limit
         */

        /**
         * Constructs a new MeListAudioRequest.
         * @memberof me
         * @classdesc Represents a MeListAudioRequest.
         * @implements IMeListAudioRequest
         * @constructor
         * @param {me.IMeListAudioRequest=} [properties] Properties to set
         */
        function MeListAudioRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListAudioRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListAudioRequest
         * @instance
         */
        MeListAudioRequest.prototype.cursor = null;

        /**
         * MeListAudioRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof me.MeListAudioRequest
         * @instance
         */
        MeListAudioRequest.prototype.limit = null;

        /**
         * Creates a new MeListAudioRequest instance using the specified properties.
         * @function create
         * @memberof me.MeListAudioRequest
         * @static
         * @param {me.IMeListAudioRequest=} [properties] Properties to set
         * @returns {me.MeListAudioRequest} MeListAudioRequest instance
         */
        MeListAudioRequest.create = function create(properties) {
            return new MeListAudioRequest(properties);
        };

        /**
         * Encodes the specified MeListAudioRequest message. Does not implicitly {@link me.MeListAudioRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeListAudioRequest
         * @static
         * @param {me.IMeListAudioRequest} message MeListAudioRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListAudioRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListAudioRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListAudioRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListAudioRequest} MeListAudioRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListAudioRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListAudioRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListAudioRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListAudioRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListAudioRequest} MeListAudioRequest
         */
        MeListAudioRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListAudioRequest)
                return object;
            var message = new $root.me.MeListAudioRequest();
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListAudioRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".me.MeListAudioRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListAudioRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListAudioRequest
         * @static
         * @param {me.MeListAudioRequest} message MeListAudioRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListAudioRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.cursor = null;
                object.limit = null;
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            return object;
        };

        /**
         * Converts this MeListAudioRequest to JSON.
         * @function toJSON
         * @memberof me.MeListAudioRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListAudioRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListAudioRequest
         * @function getTypeUrl
         * @memberof me.MeListAudioRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListAudioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListAudioRequest";
        };

        return MeListAudioRequest;
    })();

    me.MeListAudioResponse = (function() {

        /**
         * Properties of a MeListAudioResponse.
         * @memberof me
         * @interface IMeListAudioResponse
         * @property {Array.<resource.IAudio>|null} [audioList] MeListAudioResponse audioList
         * @property {google.protobuf.IStringValue|null} [cursor] MeListAudioResponse cursor
         */

        /**
         * Constructs a new MeListAudioResponse.
         * @memberof me
         * @classdesc Represents a MeListAudioResponse.
         * @implements IMeListAudioResponse
         * @constructor
         * @param {me.IMeListAudioResponse=} [properties] Properties to set
         */
        function MeListAudioResponse(properties) {
            this.audioList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeListAudioResponse audioList.
         * @member {Array.<resource.IAudio>} audioList
         * @memberof me.MeListAudioResponse
         * @instance
         */
        MeListAudioResponse.prototype.audioList = $util.emptyArray;

        /**
         * MeListAudioResponse cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.MeListAudioResponse
         * @instance
         */
        MeListAudioResponse.prototype.cursor = null;

        /**
         * Creates a new MeListAudioResponse instance using the specified properties.
         * @function create
         * @memberof me.MeListAudioResponse
         * @static
         * @param {me.IMeListAudioResponse=} [properties] Properties to set
         * @returns {me.MeListAudioResponse} MeListAudioResponse instance
         */
        MeListAudioResponse.create = function create(properties) {
            return new MeListAudioResponse(properties);
        };

        /**
         * Encodes the specified MeListAudioResponse message. Does not implicitly {@link me.MeListAudioResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeListAudioResponse
         * @static
         * @param {me.IMeListAudioResponse} message MeListAudioResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeListAudioResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.audioList != null && message.audioList.length)
                for (var i = 0; i < message.audioList.length; ++i)
                    $root.resource.Audio.encode(message.audioList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeListAudioResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeListAudioResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeListAudioResponse} MeListAudioResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeListAudioResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeListAudioResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.audioList && message.audioList.length))
                        message.audioList = [];
                    message.audioList.push($root.resource.Audio.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeListAudioResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeListAudioResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeListAudioResponse} MeListAudioResponse
         */
        MeListAudioResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeListAudioResponse)
                return object;
            var message = new $root.me.MeListAudioResponse();
            if (object.audioList) {
                if (!Array.isArray(object.audioList))
                    throw TypeError(".me.MeListAudioResponse.audioList: array expected");
                message.audioList = [];
                for (var i = 0; i < object.audioList.length; ++i) {
                    if (typeof object.audioList[i] !== "object")
                        throw TypeError(".me.MeListAudioResponse.audioList: object expected");
                    message.audioList[i] = $root.resource.Audio.fromObject(object.audioList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.MeListAudioResponse.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeListAudioResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeListAudioResponse
         * @static
         * @param {me.MeListAudioResponse} message MeListAudioResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeListAudioResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.audioList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.audioList && message.audioList.length) {
                object.audioList = [];
                for (var j = 0; j < message.audioList.length; ++j)
                    object.audioList[j] = $root.resource.Audio.toObject(message.audioList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MeListAudioResponse to JSON.
         * @function toJSON
         * @memberof me.MeListAudioResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeListAudioResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeListAudioResponse
         * @function getTypeUrl
         * @memberof me.MeListAudioResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeListAudioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeListAudioResponse";
        };

        return MeListAudioResponse;
    })();

    me.MeUsePawRequest = (function() {

        /**
         * Properties of a MeUsePawRequest.
         * @memberof me
         * @interface IMeUsePawRequest
         * @property {google.protobuf.IInt32Value|null} [amount] MeUsePawRequest amount
         */

        /**
         * Constructs a new MeUsePawRequest.
         * @memberof me
         * @classdesc Represents a MeUsePawRequest.
         * @implements IMeUsePawRequest
         * @constructor
         * @param {me.IMeUsePawRequest=} [properties] Properties to set
         */
        function MeUsePawRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUsePawRequest amount.
         * @member {google.protobuf.IInt32Value|null|undefined} amount
         * @memberof me.MeUsePawRequest
         * @instance
         */
        MeUsePawRequest.prototype.amount = null;

        /**
         * Creates a new MeUsePawRequest instance using the specified properties.
         * @function create
         * @memberof me.MeUsePawRequest
         * @static
         * @param {me.IMeUsePawRequest=} [properties] Properties to set
         * @returns {me.MeUsePawRequest} MeUsePawRequest instance
         */
        MeUsePawRequest.create = function create(properties) {
            return new MeUsePawRequest(properties);
        };

        /**
         * Encodes the specified MeUsePawRequest message. Does not implicitly {@link me.MeUsePawRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeUsePawRequest
         * @static
         * @param {me.IMeUsePawRequest} message MeUsePawRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUsePawRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                $root.google.protobuf.Int32Value.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeUsePawRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUsePawRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUsePawRequest} MeUsePawRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUsePawRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUsePawRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.amount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUsePawRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUsePawRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUsePawRequest} MeUsePawRequest
         */
        MeUsePawRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUsePawRequest)
                return object;
            var message = new $root.me.MeUsePawRequest();
            if (object.amount != null) {
                if (typeof object.amount !== "object")
                    throw TypeError(".me.MeUsePawRequest.amount: object expected");
                message.amount = $root.google.protobuf.Int32Value.fromObject(object.amount);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUsePawRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUsePawRequest
         * @static
         * @param {me.MeUsePawRequest} message MeUsePawRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUsePawRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.amount = null;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = $root.google.protobuf.Int32Value.toObject(message.amount, options);
            return object;
        };

        /**
         * Converts this MeUsePawRequest to JSON.
         * @function toJSON
         * @memberof me.MeUsePawRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUsePawRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUsePawRequest
         * @function getTypeUrl
         * @memberof me.MeUsePawRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUsePawRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUsePawRequest";
        };

        return MeUsePawRequest;
    })();

    me.MeGetNotificationCountSinceRequest = (function() {

        /**
         * Properties of a MeGetNotificationCountSinceRequest.
         * @memberof me
         * @interface IMeGetNotificationCountSinceRequest
         * @property {google.protobuf.ITimestamp|null} [since] MeGetNotificationCountSinceRequest since
         */

        /**
         * Constructs a new MeGetNotificationCountSinceRequest.
         * @memberof me
         * @classdesc Represents a MeGetNotificationCountSinceRequest.
         * @implements IMeGetNotificationCountSinceRequest
         * @constructor
         * @param {me.IMeGetNotificationCountSinceRequest=} [properties] Properties to set
         */
        function MeGetNotificationCountSinceRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetNotificationCountSinceRequest since.
         * @member {google.protobuf.ITimestamp|null|undefined} since
         * @memberof me.MeGetNotificationCountSinceRequest
         * @instance
         */
        MeGetNotificationCountSinceRequest.prototype.since = null;

        /**
         * Creates a new MeGetNotificationCountSinceRequest instance using the specified properties.
         * @function create
         * @memberof me.MeGetNotificationCountSinceRequest
         * @static
         * @param {me.IMeGetNotificationCountSinceRequest=} [properties] Properties to set
         * @returns {me.MeGetNotificationCountSinceRequest} MeGetNotificationCountSinceRequest instance
         */
        MeGetNotificationCountSinceRequest.create = function create(properties) {
            return new MeGetNotificationCountSinceRequest(properties);
        };

        /**
         * Encodes the specified MeGetNotificationCountSinceRequest message. Does not implicitly {@link me.MeGetNotificationCountSinceRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetNotificationCountSinceRequest
         * @static
         * @param {me.IMeGetNotificationCountSinceRequest} message MeGetNotificationCountSinceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetNotificationCountSinceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.since != null && Object.hasOwnProperty.call(message, "since"))
                $root.google.protobuf.Timestamp.encode(message.since, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetNotificationCountSinceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetNotificationCountSinceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetNotificationCountSinceRequest} MeGetNotificationCountSinceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetNotificationCountSinceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetNotificationCountSinceRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.since = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetNotificationCountSinceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetNotificationCountSinceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetNotificationCountSinceRequest} MeGetNotificationCountSinceRequest
         */
        MeGetNotificationCountSinceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetNotificationCountSinceRequest)
                return object;
            var message = new $root.me.MeGetNotificationCountSinceRequest();
            if (object.since != null) {
                if (typeof object.since !== "object")
                    throw TypeError(".me.MeGetNotificationCountSinceRequest.since: object expected");
                message.since = $root.google.protobuf.Timestamp.fromObject(object.since);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetNotificationCountSinceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetNotificationCountSinceRequest
         * @static
         * @param {me.MeGetNotificationCountSinceRequest} message MeGetNotificationCountSinceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetNotificationCountSinceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.since = null;
            if (message.since != null && message.hasOwnProperty("since"))
                object.since = $root.google.protobuf.Timestamp.toObject(message.since, options);
            return object;
        };

        /**
         * Converts this MeGetNotificationCountSinceRequest to JSON.
         * @function toJSON
         * @memberof me.MeGetNotificationCountSinceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetNotificationCountSinceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetNotificationCountSinceRequest
         * @function getTypeUrl
         * @memberof me.MeGetNotificationCountSinceRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetNotificationCountSinceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetNotificationCountSinceRequest";
        };

        return MeGetNotificationCountSinceRequest;
    })();

    me.GetHalfReadingStorySelectionRequest = (function() {

        /**
         * Properties of a GetHalfReadingStorySelectionRequest.
         * @memberof me
         * @interface IGetHalfReadingStorySelectionRequest
         * @property {google.protobuf.IInt32Value|null} [limit] GetHalfReadingStorySelectionRequest limit
         * @property {google.protobuf.IStringValue|null} [cursor] GetHalfReadingStorySelectionRequest cursor
         */

        /**
         * Constructs a new GetHalfReadingStorySelectionRequest.
         * @memberof me
         * @classdesc Represents a GetHalfReadingStorySelectionRequest.
         * @implements IGetHalfReadingStorySelectionRequest
         * @constructor
         * @param {me.IGetHalfReadingStorySelectionRequest=} [properties] Properties to set
         */
        function GetHalfReadingStorySelectionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetHalfReadingStorySelectionRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @instance
         */
        GetHalfReadingStorySelectionRequest.prototype.limit = null;

        /**
         * GetHalfReadingStorySelectionRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @instance
         */
        GetHalfReadingStorySelectionRequest.prototype.cursor = null;

        /**
         * Creates a new GetHalfReadingStorySelectionRequest instance using the specified properties.
         * @function create
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @static
         * @param {me.IGetHalfReadingStorySelectionRequest=} [properties] Properties to set
         * @returns {me.GetHalfReadingStorySelectionRequest} GetHalfReadingStorySelectionRequest instance
         */
        GetHalfReadingStorySelectionRequest.create = function create(properties) {
            return new GetHalfReadingStorySelectionRequest(properties);
        };

        /**
         * Encodes the specified GetHalfReadingStorySelectionRequest message. Does not implicitly {@link me.GetHalfReadingStorySelectionRequest.verify|verify} messages.
         * @function encode
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @static
         * @param {me.IGetHalfReadingStorySelectionRequest} message GetHalfReadingStorySelectionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetHalfReadingStorySelectionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetHalfReadingStorySelectionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.GetHalfReadingStorySelectionRequest} GetHalfReadingStorySelectionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetHalfReadingStorySelectionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.GetHalfReadingStorySelectionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a GetHalfReadingStorySelectionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.GetHalfReadingStorySelectionRequest} GetHalfReadingStorySelectionRequest
         */
        GetHalfReadingStorySelectionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.GetHalfReadingStorySelectionRequest)
                return object;
            var message = new $root.me.GetHalfReadingStorySelectionRequest();
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".me.GetHalfReadingStorySelectionRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".me.GetHalfReadingStorySelectionRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetHalfReadingStorySelectionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @static
         * @param {me.GetHalfReadingStorySelectionRequest} message GetHalfReadingStorySelectionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetHalfReadingStorySelectionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.limit = null;
                object.cursor = null;
            }
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this GetHalfReadingStorySelectionRequest to JSON.
         * @function toJSON
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetHalfReadingStorySelectionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetHalfReadingStorySelectionRequest
         * @function getTypeUrl
         * @memberof me.GetHalfReadingStorySelectionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetHalfReadingStorySelectionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.GetHalfReadingStorySelectionRequest";
        };

        return GetHalfReadingStorySelectionRequest;
    })();

    me.AuthCustomToken = (function() {

        /**
         * Properties of an AuthCustomToken.
         * @memberof me
         * @interface IAuthCustomToken
         * @property {google.protobuf.IStringValue|null} [token] AuthCustomToken token
         * @property {google.protobuf.ITimestamp|null} [expiresAt] AuthCustomToken expiresAt
         */

        /**
         * Constructs a new AuthCustomToken.
         * @memberof me
         * @classdesc Represents an AuthCustomToken.
         * @implements IAuthCustomToken
         * @constructor
         * @param {me.IAuthCustomToken=} [properties] Properties to set
         */
        function AuthCustomToken(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthCustomToken token.
         * @member {google.protobuf.IStringValue|null|undefined} token
         * @memberof me.AuthCustomToken
         * @instance
         */
        AuthCustomToken.prototype.token = null;

        /**
         * AuthCustomToken expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof me.AuthCustomToken
         * @instance
         */
        AuthCustomToken.prototype.expiresAt = null;

        /**
         * Creates a new AuthCustomToken instance using the specified properties.
         * @function create
         * @memberof me.AuthCustomToken
         * @static
         * @param {me.IAuthCustomToken=} [properties] Properties to set
         * @returns {me.AuthCustomToken} AuthCustomToken instance
         */
        AuthCustomToken.create = function create(properties) {
            return new AuthCustomToken(properties);
        };

        /**
         * Encodes the specified AuthCustomToken message. Does not implicitly {@link me.AuthCustomToken.verify|verify} messages.
         * @function encode
         * @memberof me.AuthCustomToken
         * @static
         * @param {me.IAuthCustomToken} message AuthCustomToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthCustomToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                $root.google.protobuf.StringValue.encode(message.token, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AuthCustomToken message from the specified reader or buffer.
         * @function decode
         * @memberof me.AuthCustomToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.AuthCustomToken} AuthCustomToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthCustomToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.AuthCustomToken();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AuthCustomToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.AuthCustomToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.AuthCustomToken} AuthCustomToken
         */
        AuthCustomToken.fromObject = function fromObject(object) {
            if (object instanceof $root.me.AuthCustomToken)
                return object;
            var message = new $root.me.AuthCustomToken();
            if (object.token != null) {
                if (typeof object.token !== "object")
                    throw TypeError(".me.AuthCustomToken.token: object expected");
                message.token = $root.google.protobuf.StringValue.fromObject(object.token);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".me.AuthCustomToken.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthCustomToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.AuthCustomToken
         * @static
         * @param {me.AuthCustomToken} message AuthCustomToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthCustomToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.token = null;
                object.expiresAt = null;
            }
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = $root.google.protobuf.StringValue.toObject(message.token, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            return object;
        };

        /**
         * Converts this AuthCustomToken to JSON.
         * @function toJSON
         * @memberof me.AuthCustomToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthCustomToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthCustomToken
         * @function getTypeUrl
         * @memberof me.AuthCustomToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthCustomToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.AuthCustomToken";
        };

        return AuthCustomToken;
    })();

    me.MeUpdateCommentRestrictionPreferenceRequest = (function() {

        /**
         * Properties of a MeUpdateCommentRestrictionPreferenceRequest.
         * @memberof me
         * @interface IMeUpdateCommentRestrictionPreferenceRequest
         * @property {types.CommentRestrictionType|null} [commentRestrictionType] MeUpdateCommentRestrictionPreferenceRequest commentRestrictionType
         */

        /**
         * Constructs a new MeUpdateCommentRestrictionPreferenceRequest.
         * @memberof me
         * @classdesc Represents a MeUpdateCommentRestrictionPreferenceRequest.
         * @implements IMeUpdateCommentRestrictionPreferenceRequest
         * @constructor
         * @param {me.IMeUpdateCommentRestrictionPreferenceRequest=} [properties] Properties to set
         */
        function MeUpdateCommentRestrictionPreferenceRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeUpdateCommentRestrictionPreferenceRequest commentRestrictionType.
         * @member {types.CommentRestrictionType} commentRestrictionType
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @instance
         */
        MeUpdateCommentRestrictionPreferenceRequest.prototype.commentRestrictionType = 0;

        /**
         * Creates a new MeUpdateCommentRestrictionPreferenceRequest instance using the specified properties.
         * @function create
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @static
         * @param {me.IMeUpdateCommentRestrictionPreferenceRequest=} [properties] Properties to set
         * @returns {me.MeUpdateCommentRestrictionPreferenceRequest} MeUpdateCommentRestrictionPreferenceRequest instance
         */
        MeUpdateCommentRestrictionPreferenceRequest.create = function create(properties) {
            return new MeUpdateCommentRestrictionPreferenceRequest(properties);
        };

        /**
         * Encodes the specified MeUpdateCommentRestrictionPreferenceRequest message. Does not implicitly {@link me.MeUpdateCommentRestrictionPreferenceRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @static
         * @param {me.IMeUpdateCommentRestrictionPreferenceRequest} message MeUpdateCommentRestrictionPreferenceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeUpdateCommentRestrictionPreferenceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentRestrictionType != null && Object.hasOwnProperty.call(message, "commentRestrictionType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.commentRestrictionType);
            return writer;
        };

        /**
         * Decodes a MeUpdateCommentRestrictionPreferenceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeUpdateCommentRestrictionPreferenceRequest} MeUpdateCommentRestrictionPreferenceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeUpdateCommentRestrictionPreferenceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeUpdateCommentRestrictionPreferenceRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commentRestrictionType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeUpdateCommentRestrictionPreferenceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeUpdateCommentRestrictionPreferenceRequest} MeUpdateCommentRestrictionPreferenceRequest
         */
        MeUpdateCommentRestrictionPreferenceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeUpdateCommentRestrictionPreferenceRequest)
                return object;
            var message = new $root.me.MeUpdateCommentRestrictionPreferenceRequest();
            switch (object.commentRestrictionType) {
            case "COMMENT_RESTRICTION_TYPE_UNSPECIFIED":
            case 0:
                message.commentRestrictionType = 0;
                break;
            case "COMMENT_RESTRICTION_TYPE_UNRESTRICTED":
            case 1:
                message.commentRestrictionType = 1;
                break;
            case "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS_AND_FOLLOWINGS":
            case 2:
                message.commentRestrictionType = 2;
                break;
            case "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWINGS":
            case 3:
                message.commentRestrictionType = 3;
                break;
            case "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS":
            case 4:
                message.commentRestrictionType = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a MeUpdateCommentRestrictionPreferenceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @static
         * @param {me.MeUpdateCommentRestrictionPreferenceRequest} message MeUpdateCommentRestrictionPreferenceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeUpdateCommentRestrictionPreferenceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.commentRestrictionType = options.enums === String ? "COMMENT_RESTRICTION_TYPE_UNSPECIFIED" : 0;
            if (message.commentRestrictionType != null && message.hasOwnProperty("commentRestrictionType"))
                object.commentRestrictionType = options.enums === String ? $root.types.CommentRestrictionType[message.commentRestrictionType] : message.commentRestrictionType;
            return object;
        };

        /**
         * Converts this MeUpdateCommentRestrictionPreferenceRequest to JSON.
         * @function toJSON
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeUpdateCommentRestrictionPreferenceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeUpdateCommentRestrictionPreferenceRequest
         * @function getTypeUrl
         * @memberof me.MeUpdateCommentRestrictionPreferenceRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeUpdateCommentRestrictionPreferenceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeUpdateCommentRestrictionPreferenceRequest";
        };

        return MeUpdateCommentRestrictionPreferenceRequest;
    })();

    me.MeGetCommentRestrictionPreferenceResponse = (function() {

        /**
         * Properties of a MeGetCommentRestrictionPreferenceResponse.
         * @memberof me
         * @interface IMeGetCommentRestrictionPreferenceResponse
         * @property {types.CommentRestrictionType|null} [commentRestrictionType] MeGetCommentRestrictionPreferenceResponse commentRestrictionType
         * @property {google.protobuf.ITimestamp|null} [updatedAt] MeGetCommentRestrictionPreferenceResponse updatedAt
         */

        /**
         * Constructs a new MeGetCommentRestrictionPreferenceResponse.
         * @memberof me
         * @classdesc Represents a MeGetCommentRestrictionPreferenceResponse.
         * @implements IMeGetCommentRestrictionPreferenceResponse
         * @constructor
         * @param {me.IMeGetCommentRestrictionPreferenceResponse=} [properties] Properties to set
         */
        function MeGetCommentRestrictionPreferenceResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetCommentRestrictionPreferenceResponse commentRestrictionType.
         * @member {types.CommentRestrictionType} commentRestrictionType
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @instance
         */
        MeGetCommentRestrictionPreferenceResponse.prototype.commentRestrictionType = 0;

        /**
         * MeGetCommentRestrictionPreferenceResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @instance
         */
        MeGetCommentRestrictionPreferenceResponse.prototype.updatedAt = null;

        /**
         * Creates a new MeGetCommentRestrictionPreferenceResponse instance using the specified properties.
         * @function create
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @static
         * @param {me.IMeGetCommentRestrictionPreferenceResponse=} [properties] Properties to set
         * @returns {me.MeGetCommentRestrictionPreferenceResponse} MeGetCommentRestrictionPreferenceResponse instance
         */
        MeGetCommentRestrictionPreferenceResponse.create = function create(properties) {
            return new MeGetCommentRestrictionPreferenceResponse(properties);
        };

        /**
         * Encodes the specified MeGetCommentRestrictionPreferenceResponse message. Does not implicitly {@link me.MeGetCommentRestrictionPreferenceResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @static
         * @param {me.IMeGetCommentRestrictionPreferenceResponse} message MeGetCommentRestrictionPreferenceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetCommentRestrictionPreferenceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentRestrictionType != null && Object.hasOwnProperty.call(message, "commentRestrictionType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.commentRestrictionType);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeGetCommentRestrictionPreferenceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetCommentRestrictionPreferenceResponse} MeGetCommentRestrictionPreferenceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetCommentRestrictionPreferenceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetCommentRestrictionPreferenceResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commentRestrictionType = reader.int32();
                    break;
                case 2:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetCommentRestrictionPreferenceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetCommentRestrictionPreferenceResponse} MeGetCommentRestrictionPreferenceResponse
         */
        MeGetCommentRestrictionPreferenceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetCommentRestrictionPreferenceResponse)
                return object;
            var message = new $root.me.MeGetCommentRestrictionPreferenceResponse();
            switch (object.commentRestrictionType) {
            case "COMMENT_RESTRICTION_TYPE_UNSPECIFIED":
            case 0:
                message.commentRestrictionType = 0;
                break;
            case "COMMENT_RESTRICTION_TYPE_UNRESTRICTED":
            case 1:
                message.commentRestrictionType = 1;
                break;
            case "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS_AND_FOLLOWINGS":
            case 2:
                message.commentRestrictionType = 2;
                break;
            case "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWINGS":
            case 3:
                message.commentRestrictionType = 3;
                break;
            case "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS":
            case 4:
                message.commentRestrictionType = 4;
                break;
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".me.MeGetCommentRestrictionPreferenceResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetCommentRestrictionPreferenceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @static
         * @param {me.MeGetCommentRestrictionPreferenceResponse} message MeGetCommentRestrictionPreferenceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetCommentRestrictionPreferenceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.commentRestrictionType = options.enums === String ? "COMMENT_RESTRICTION_TYPE_UNSPECIFIED" : 0;
                object.updatedAt = null;
            }
            if (message.commentRestrictionType != null && message.hasOwnProperty("commentRestrictionType"))
                object.commentRestrictionType = options.enums === String ? $root.types.CommentRestrictionType[message.commentRestrictionType] : message.commentRestrictionType;
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this MeGetCommentRestrictionPreferenceResponse to JSON.
         * @function toJSON
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetCommentRestrictionPreferenceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetCommentRestrictionPreferenceResponse
         * @function getTypeUrl
         * @memberof me.MeGetCommentRestrictionPreferenceResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetCommentRestrictionPreferenceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetCommentRestrictionPreferenceResponse";
        };

        return MeGetCommentRestrictionPreferenceResponse;
    })();

    me.FollowingStatus = (function() {

        /**
         * Properties of a FollowingStatus.
         * @memberof me
         * @interface IFollowingStatus
         * @property {google.protobuf.IStringValue|null} [userId] FollowingStatus userId
         * @property {google.protobuf.IBoolValue|null} [isFollowing] FollowingStatus isFollowing
         */

        /**
         * Constructs a new FollowingStatus.
         * @memberof me
         * @classdesc Represents a FollowingStatus.
         * @implements IFollowingStatus
         * @constructor
         * @param {me.IFollowingStatus=} [properties] Properties to set
         */
        function FollowingStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FollowingStatus userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.FollowingStatus
         * @instance
         */
        FollowingStatus.prototype.userId = null;

        /**
         * FollowingStatus isFollowing.
         * @member {google.protobuf.IBoolValue|null|undefined} isFollowing
         * @memberof me.FollowingStatus
         * @instance
         */
        FollowingStatus.prototype.isFollowing = null;

        /**
         * Creates a new FollowingStatus instance using the specified properties.
         * @function create
         * @memberof me.FollowingStatus
         * @static
         * @param {me.IFollowingStatus=} [properties] Properties to set
         * @returns {me.FollowingStatus} FollowingStatus instance
         */
        FollowingStatus.create = function create(properties) {
            return new FollowingStatus(properties);
        };

        /**
         * Encodes the specified FollowingStatus message. Does not implicitly {@link me.FollowingStatus.verify|verify} messages.
         * @function encode
         * @memberof me.FollowingStatus
         * @static
         * @param {me.IFollowingStatus} message FollowingStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FollowingStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.isFollowing != null && Object.hasOwnProperty.call(message, "isFollowing"))
                $root.google.protobuf.BoolValue.encode(message.isFollowing, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FollowingStatus message from the specified reader or buffer.
         * @function decode
         * @memberof me.FollowingStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.FollowingStatus} FollowingStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FollowingStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.FollowingStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.isFollowing = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a FollowingStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.FollowingStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.FollowingStatus} FollowingStatus
         */
        FollowingStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.me.FollowingStatus)
                return object;
            var message = new $root.me.FollowingStatus();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.FollowingStatus.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.isFollowing != null) {
                if (typeof object.isFollowing !== "object")
                    throw TypeError(".me.FollowingStatus.isFollowing: object expected");
                message.isFollowing = $root.google.protobuf.BoolValue.fromObject(object.isFollowing);
            }
            return message;
        };

        /**
         * Creates a plain object from a FollowingStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.FollowingStatus
         * @static
         * @param {me.FollowingStatus} message FollowingStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FollowingStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = null;
                object.isFollowing = null;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.isFollowing != null && message.hasOwnProperty("isFollowing"))
                object.isFollowing = $root.google.protobuf.BoolValue.toObject(message.isFollowing, options);
            return object;
        };

        /**
         * Converts this FollowingStatus to JSON.
         * @function toJSON
         * @memberof me.FollowingStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FollowingStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FollowingStatus
         * @function getTypeUrl
         * @memberof me.FollowingStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FollowingStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.FollowingStatus";
        };

        return FollowingStatus;
    })();

    me.MeIsFollowingRequest = (function() {

        /**
         * Properties of a MeIsFollowingRequest.
         * @memberof me
         * @interface IMeIsFollowingRequest
         * @property {google.protobuf.IStringValue|null} [userId] MeIsFollowingRequest userId
         */

        /**
         * Constructs a new MeIsFollowingRequest.
         * @memberof me
         * @classdesc Represents a MeIsFollowingRequest.
         * @implements IMeIsFollowingRequest
         * @constructor
         * @param {me.IMeIsFollowingRequest=} [properties] Properties to set
         */
        function MeIsFollowingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsFollowingRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.MeIsFollowingRequest
         * @instance
         */
        MeIsFollowingRequest.prototype.userId = null;

        /**
         * Creates a new MeIsFollowingRequest instance using the specified properties.
         * @function create
         * @memberof me.MeIsFollowingRequest
         * @static
         * @param {me.IMeIsFollowingRequest=} [properties] Properties to set
         * @returns {me.MeIsFollowingRequest} MeIsFollowingRequest instance
         */
        MeIsFollowingRequest.create = function create(properties) {
            return new MeIsFollowingRequest(properties);
        };

        /**
         * Encodes the specified MeIsFollowingRequest message. Does not implicitly {@link me.MeIsFollowingRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsFollowingRequest
         * @static
         * @param {me.IMeIsFollowingRequest} message MeIsFollowingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsFollowingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsFollowingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsFollowingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsFollowingRequest} MeIsFollowingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsFollowingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsFollowingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsFollowingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsFollowingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsFollowingRequest} MeIsFollowingRequest
         */
        MeIsFollowingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsFollowingRequest)
                return object;
            var message = new $root.me.MeIsFollowingRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.MeIsFollowingRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsFollowingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsFollowingRequest
         * @static
         * @param {me.MeIsFollowingRequest} message MeIsFollowingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsFollowingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this MeIsFollowingRequest to JSON.
         * @function toJSON
         * @memberof me.MeIsFollowingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsFollowingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsFollowingRequest
         * @function getTypeUrl
         * @memberof me.MeIsFollowingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsFollowingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsFollowingRequest";
        };

        return MeIsFollowingRequest;
    })();

    me.MeIsFollowingMultiRequest = (function() {

        /**
         * Properties of a MeIsFollowingMultiRequest.
         * @memberof me
         * @interface IMeIsFollowingMultiRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [userIdList] MeIsFollowingMultiRequest userIdList
         */

        /**
         * Constructs a new MeIsFollowingMultiRequest.
         * @memberof me
         * @classdesc Represents a MeIsFollowingMultiRequest.
         * @implements IMeIsFollowingMultiRequest
         * @constructor
         * @param {me.IMeIsFollowingMultiRequest=} [properties] Properties to set
         */
        function MeIsFollowingMultiRequest(properties) {
            this.userIdList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsFollowingMultiRequest userIdList.
         * @member {Array.<google.protobuf.IStringValue>} userIdList
         * @memberof me.MeIsFollowingMultiRequest
         * @instance
         */
        MeIsFollowingMultiRequest.prototype.userIdList = $util.emptyArray;

        /**
         * Creates a new MeIsFollowingMultiRequest instance using the specified properties.
         * @function create
         * @memberof me.MeIsFollowingMultiRequest
         * @static
         * @param {me.IMeIsFollowingMultiRequest=} [properties] Properties to set
         * @returns {me.MeIsFollowingMultiRequest} MeIsFollowingMultiRequest instance
         */
        MeIsFollowingMultiRequest.create = function create(properties) {
            return new MeIsFollowingMultiRequest(properties);
        };

        /**
         * Encodes the specified MeIsFollowingMultiRequest message. Does not implicitly {@link me.MeIsFollowingMultiRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsFollowingMultiRequest
         * @static
         * @param {me.IMeIsFollowingMultiRequest} message MeIsFollowingMultiRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsFollowingMultiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userIdList != null && message.userIdList.length)
                for (var i = 0; i < message.userIdList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.userIdList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsFollowingMultiRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsFollowingMultiRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsFollowingMultiRequest} MeIsFollowingMultiRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsFollowingMultiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsFollowingMultiRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userIdList && message.userIdList.length))
                        message.userIdList = [];
                    message.userIdList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsFollowingMultiRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsFollowingMultiRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsFollowingMultiRequest} MeIsFollowingMultiRequest
         */
        MeIsFollowingMultiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsFollowingMultiRequest)
                return object;
            var message = new $root.me.MeIsFollowingMultiRequest();
            if (object.userIdList) {
                if (!Array.isArray(object.userIdList))
                    throw TypeError(".me.MeIsFollowingMultiRequest.userIdList: array expected");
                message.userIdList = [];
                for (var i = 0; i < object.userIdList.length; ++i) {
                    if (typeof object.userIdList[i] !== "object")
                        throw TypeError(".me.MeIsFollowingMultiRequest.userIdList: object expected");
                    message.userIdList[i] = $root.google.protobuf.StringValue.fromObject(object.userIdList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsFollowingMultiRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsFollowingMultiRequest
         * @static
         * @param {me.MeIsFollowingMultiRequest} message MeIsFollowingMultiRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsFollowingMultiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userIdList = [];
            if (message.userIdList && message.userIdList.length) {
                object.userIdList = [];
                for (var j = 0; j < message.userIdList.length; ++j)
                    object.userIdList[j] = $root.google.protobuf.StringValue.toObject(message.userIdList[j], options);
            }
            return object;
        };

        /**
         * Converts this MeIsFollowingMultiRequest to JSON.
         * @function toJSON
         * @memberof me.MeIsFollowingMultiRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsFollowingMultiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsFollowingMultiRequest
         * @function getTypeUrl
         * @memberof me.MeIsFollowingMultiRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsFollowingMultiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsFollowingMultiRequest";
        };

        return MeIsFollowingMultiRequest;
    })();

    me.MeIsFollowingMultiResponse = (function() {

        /**
         * Properties of a MeIsFollowingMultiResponse.
         * @memberof me
         * @interface IMeIsFollowingMultiResponse
         * @property {Array.<me.IFollowingStatus>|null} [followingStatusList] MeIsFollowingMultiResponse followingStatusList
         */

        /**
         * Constructs a new MeIsFollowingMultiResponse.
         * @memberof me
         * @classdesc Represents a MeIsFollowingMultiResponse.
         * @implements IMeIsFollowingMultiResponse
         * @constructor
         * @param {me.IMeIsFollowingMultiResponse=} [properties] Properties to set
         */
        function MeIsFollowingMultiResponse(properties) {
            this.followingStatusList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsFollowingMultiResponse followingStatusList.
         * @member {Array.<me.IFollowingStatus>} followingStatusList
         * @memberof me.MeIsFollowingMultiResponse
         * @instance
         */
        MeIsFollowingMultiResponse.prototype.followingStatusList = $util.emptyArray;

        /**
         * Creates a new MeIsFollowingMultiResponse instance using the specified properties.
         * @function create
         * @memberof me.MeIsFollowingMultiResponse
         * @static
         * @param {me.IMeIsFollowingMultiResponse=} [properties] Properties to set
         * @returns {me.MeIsFollowingMultiResponse} MeIsFollowingMultiResponse instance
         */
        MeIsFollowingMultiResponse.create = function create(properties) {
            return new MeIsFollowingMultiResponse(properties);
        };

        /**
         * Encodes the specified MeIsFollowingMultiResponse message. Does not implicitly {@link me.MeIsFollowingMultiResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsFollowingMultiResponse
         * @static
         * @param {me.IMeIsFollowingMultiResponse} message MeIsFollowingMultiResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsFollowingMultiResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.followingStatusList != null && message.followingStatusList.length)
                for (var i = 0; i < message.followingStatusList.length; ++i)
                    $root.me.FollowingStatus.encode(message.followingStatusList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsFollowingMultiResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsFollowingMultiResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsFollowingMultiResponse} MeIsFollowingMultiResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsFollowingMultiResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsFollowingMultiResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.followingStatusList && message.followingStatusList.length))
                        message.followingStatusList = [];
                    message.followingStatusList.push($root.me.FollowingStatus.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsFollowingMultiResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsFollowingMultiResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsFollowingMultiResponse} MeIsFollowingMultiResponse
         */
        MeIsFollowingMultiResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsFollowingMultiResponse)
                return object;
            var message = new $root.me.MeIsFollowingMultiResponse();
            if (object.followingStatusList) {
                if (!Array.isArray(object.followingStatusList))
                    throw TypeError(".me.MeIsFollowingMultiResponse.followingStatusList: array expected");
                message.followingStatusList = [];
                for (var i = 0; i < object.followingStatusList.length; ++i) {
                    if (typeof object.followingStatusList[i] !== "object")
                        throw TypeError(".me.MeIsFollowingMultiResponse.followingStatusList: object expected");
                    message.followingStatusList[i] = $root.me.FollowingStatus.fromObject(object.followingStatusList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsFollowingMultiResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsFollowingMultiResponse
         * @static
         * @param {me.MeIsFollowingMultiResponse} message MeIsFollowingMultiResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsFollowingMultiResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.followingStatusList = [];
            if (message.followingStatusList && message.followingStatusList.length) {
                object.followingStatusList = [];
                for (var j = 0; j < message.followingStatusList.length; ++j)
                    object.followingStatusList[j] = $root.me.FollowingStatus.toObject(message.followingStatusList[j], options);
            }
            return object;
        };

        /**
         * Converts this MeIsFollowingMultiResponse to JSON.
         * @function toJSON
         * @memberof me.MeIsFollowingMultiResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsFollowingMultiResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsFollowingMultiResponse
         * @function getTypeUrl
         * @memberof me.MeIsFollowingMultiResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsFollowingMultiResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsFollowingMultiResponse";
        };

        return MeIsFollowingMultiResponse;
    })();

    me.BlockingStatus = (function() {

        /**
         * Properties of a BlockingStatus.
         * @memberof me
         * @interface IBlockingStatus
         * @property {google.protobuf.IStringValue|null} [userId] BlockingStatus userId
         * @property {google.protobuf.IBoolValue|null} [isBlocking] BlockingStatus isBlocking
         */

        /**
         * Constructs a new BlockingStatus.
         * @memberof me
         * @classdesc Represents a BlockingStatus.
         * @implements IBlockingStatus
         * @constructor
         * @param {me.IBlockingStatus=} [properties] Properties to set
         */
        function BlockingStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingStatus userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.BlockingStatus
         * @instance
         */
        BlockingStatus.prototype.userId = null;

        /**
         * BlockingStatus isBlocking.
         * @member {google.protobuf.IBoolValue|null|undefined} isBlocking
         * @memberof me.BlockingStatus
         * @instance
         */
        BlockingStatus.prototype.isBlocking = null;

        /**
         * Creates a new BlockingStatus instance using the specified properties.
         * @function create
         * @memberof me.BlockingStatus
         * @static
         * @param {me.IBlockingStatus=} [properties] Properties to set
         * @returns {me.BlockingStatus} BlockingStatus instance
         */
        BlockingStatus.create = function create(properties) {
            return new BlockingStatus(properties);
        };

        /**
         * Encodes the specified BlockingStatus message. Does not implicitly {@link me.BlockingStatus.verify|verify} messages.
         * @function encode
         * @memberof me.BlockingStatus
         * @static
         * @param {me.IBlockingStatus} message BlockingStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.isBlocking != null && Object.hasOwnProperty.call(message, "isBlocking"))
                $root.google.protobuf.BoolValue.encode(message.isBlocking, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingStatus message from the specified reader or buffer.
         * @function decode
         * @memberof me.BlockingStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.BlockingStatus} BlockingStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.BlockingStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.isBlocking = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a BlockingStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.BlockingStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.BlockingStatus} BlockingStatus
         */
        BlockingStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.me.BlockingStatus)
                return object;
            var message = new $root.me.BlockingStatus();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.BlockingStatus.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.isBlocking != null) {
                if (typeof object.isBlocking !== "object")
                    throw TypeError(".me.BlockingStatus.isBlocking: object expected");
                message.isBlocking = $root.google.protobuf.BoolValue.fromObject(object.isBlocking);
            }
            return message;
        };

        /**
         * Creates a plain object from a BlockingStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.BlockingStatus
         * @static
         * @param {me.BlockingStatus} message BlockingStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BlockingStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = null;
                object.isBlocking = null;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.isBlocking != null && message.hasOwnProperty("isBlocking"))
                object.isBlocking = $root.google.protobuf.BoolValue.toObject(message.isBlocking, options);
            return object;
        };

        /**
         * Converts this BlockingStatus to JSON.
         * @function toJSON
         * @memberof me.BlockingStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BlockingStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BlockingStatus
         * @function getTypeUrl
         * @memberof me.BlockingStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BlockingStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.BlockingStatus";
        };

        return BlockingStatus;
    })();

    me.MeIsBlockingRequest = (function() {

        /**
         * Properties of a MeIsBlockingRequest.
         * @memberof me
         * @interface IMeIsBlockingRequest
         * @property {google.protobuf.IStringValue|null} [userId] MeIsBlockingRequest userId
         */

        /**
         * Constructs a new MeIsBlockingRequest.
         * @memberof me
         * @classdesc Represents a MeIsBlockingRequest.
         * @implements IMeIsBlockingRequest
         * @constructor
         * @param {me.IMeIsBlockingRequest=} [properties] Properties to set
         */
        function MeIsBlockingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsBlockingRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof me.MeIsBlockingRequest
         * @instance
         */
        MeIsBlockingRequest.prototype.userId = null;

        /**
         * Creates a new MeIsBlockingRequest instance using the specified properties.
         * @function create
         * @memberof me.MeIsBlockingRequest
         * @static
         * @param {me.IMeIsBlockingRequest=} [properties] Properties to set
         * @returns {me.MeIsBlockingRequest} MeIsBlockingRequest instance
         */
        MeIsBlockingRequest.create = function create(properties) {
            return new MeIsBlockingRequest(properties);
        };

        /**
         * Encodes the specified MeIsBlockingRequest message. Does not implicitly {@link me.MeIsBlockingRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsBlockingRequest
         * @static
         * @param {me.IMeIsBlockingRequest} message MeIsBlockingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsBlockingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsBlockingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsBlockingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsBlockingRequest} MeIsBlockingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsBlockingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsBlockingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsBlockingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsBlockingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsBlockingRequest} MeIsBlockingRequest
         */
        MeIsBlockingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsBlockingRequest)
                return object;
            var message = new $root.me.MeIsBlockingRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".me.MeIsBlockingRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsBlockingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsBlockingRequest
         * @static
         * @param {me.MeIsBlockingRequest} message MeIsBlockingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsBlockingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this MeIsBlockingRequest to JSON.
         * @function toJSON
         * @memberof me.MeIsBlockingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsBlockingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsBlockingRequest
         * @function getTypeUrl
         * @memberof me.MeIsBlockingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsBlockingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsBlockingRequest";
        };

        return MeIsBlockingRequest;
    })();

    me.MeIsBlockingMultiRequest = (function() {

        /**
         * Properties of a MeIsBlockingMultiRequest.
         * @memberof me
         * @interface IMeIsBlockingMultiRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [userIdList] MeIsBlockingMultiRequest userIdList
         */

        /**
         * Constructs a new MeIsBlockingMultiRequest.
         * @memberof me
         * @classdesc Represents a MeIsBlockingMultiRequest.
         * @implements IMeIsBlockingMultiRequest
         * @constructor
         * @param {me.IMeIsBlockingMultiRequest=} [properties] Properties to set
         */
        function MeIsBlockingMultiRequest(properties) {
            this.userIdList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsBlockingMultiRequest userIdList.
         * @member {Array.<google.protobuf.IStringValue>} userIdList
         * @memberof me.MeIsBlockingMultiRequest
         * @instance
         */
        MeIsBlockingMultiRequest.prototype.userIdList = $util.emptyArray;

        /**
         * Creates a new MeIsBlockingMultiRequest instance using the specified properties.
         * @function create
         * @memberof me.MeIsBlockingMultiRequest
         * @static
         * @param {me.IMeIsBlockingMultiRequest=} [properties] Properties to set
         * @returns {me.MeIsBlockingMultiRequest} MeIsBlockingMultiRequest instance
         */
        MeIsBlockingMultiRequest.create = function create(properties) {
            return new MeIsBlockingMultiRequest(properties);
        };

        /**
         * Encodes the specified MeIsBlockingMultiRequest message. Does not implicitly {@link me.MeIsBlockingMultiRequest.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsBlockingMultiRequest
         * @static
         * @param {me.IMeIsBlockingMultiRequest} message MeIsBlockingMultiRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsBlockingMultiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userIdList != null && message.userIdList.length)
                for (var i = 0; i < message.userIdList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.userIdList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsBlockingMultiRequest message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsBlockingMultiRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsBlockingMultiRequest} MeIsBlockingMultiRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsBlockingMultiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsBlockingMultiRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userIdList && message.userIdList.length))
                        message.userIdList = [];
                    message.userIdList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsBlockingMultiRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsBlockingMultiRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsBlockingMultiRequest} MeIsBlockingMultiRequest
         */
        MeIsBlockingMultiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsBlockingMultiRequest)
                return object;
            var message = new $root.me.MeIsBlockingMultiRequest();
            if (object.userIdList) {
                if (!Array.isArray(object.userIdList))
                    throw TypeError(".me.MeIsBlockingMultiRequest.userIdList: array expected");
                message.userIdList = [];
                for (var i = 0; i < object.userIdList.length; ++i) {
                    if (typeof object.userIdList[i] !== "object")
                        throw TypeError(".me.MeIsBlockingMultiRequest.userIdList: object expected");
                    message.userIdList[i] = $root.google.protobuf.StringValue.fromObject(object.userIdList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsBlockingMultiRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsBlockingMultiRequest
         * @static
         * @param {me.MeIsBlockingMultiRequest} message MeIsBlockingMultiRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsBlockingMultiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userIdList = [];
            if (message.userIdList && message.userIdList.length) {
                object.userIdList = [];
                for (var j = 0; j < message.userIdList.length; ++j)
                    object.userIdList[j] = $root.google.protobuf.StringValue.toObject(message.userIdList[j], options);
            }
            return object;
        };

        /**
         * Converts this MeIsBlockingMultiRequest to JSON.
         * @function toJSON
         * @memberof me.MeIsBlockingMultiRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsBlockingMultiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsBlockingMultiRequest
         * @function getTypeUrl
         * @memberof me.MeIsBlockingMultiRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsBlockingMultiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsBlockingMultiRequest";
        };

        return MeIsBlockingMultiRequest;
    })();

    me.MeIsBlockingMultiResponse = (function() {

        /**
         * Properties of a MeIsBlockingMultiResponse.
         * @memberof me
         * @interface IMeIsBlockingMultiResponse
         * @property {Array.<me.IBlockingStatus>|null} [blockingStatusList] MeIsBlockingMultiResponse blockingStatusList
         */

        /**
         * Constructs a new MeIsBlockingMultiResponse.
         * @memberof me
         * @classdesc Represents a MeIsBlockingMultiResponse.
         * @implements IMeIsBlockingMultiResponse
         * @constructor
         * @param {me.IMeIsBlockingMultiResponse=} [properties] Properties to set
         */
        function MeIsBlockingMultiResponse(properties) {
            this.blockingStatusList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeIsBlockingMultiResponse blockingStatusList.
         * @member {Array.<me.IBlockingStatus>} blockingStatusList
         * @memberof me.MeIsBlockingMultiResponse
         * @instance
         */
        MeIsBlockingMultiResponse.prototype.blockingStatusList = $util.emptyArray;

        /**
         * Creates a new MeIsBlockingMultiResponse instance using the specified properties.
         * @function create
         * @memberof me.MeIsBlockingMultiResponse
         * @static
         * @param {me.IMeIsBlockingMultiResponse=} [properties] Properties to set
         * @returns {me.MeIsBlockingMultiResponse} MeIsBlockingMultiResponse instance
         */
        MeIsBlockingMultiResponse.create = function create(properties) {
            return new MeIsBlockingMultiResponse(properties);
        };

        /**
         * Encodes the specified MeIsBlockingMultiResponse message. Does not implicitly {@link me.MeIsBlockingMultiResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeIsBlockingMultiResponse
         * @static
         * @param {me.IMeIsBlockingMultiResponse} message MeIsBlockingMultiResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeIsBlockingMultiResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blockingStatusList != null && message.blockingStatusList.length)
                for (var i = 0; i < message.blockingStatusList.length; ++i)
                    $root.me.BlockingStatus.encode(message.blockingStatusList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeIsBlockingMultiResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeIsBlockingMultiResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeIsBlockingMultiResponse} MeIsBlockingMultiResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeIsBlockingMultiResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeIsBlockingMultiResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.blockingStatusList && message.blockingStatusList.length))
                        message.blockingStatusList = [];
                    message.blockingStatusList.push($root.me.BlockingStatus.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeIsBlockingMultiResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeIsBlockingMultiResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeIsBlockingMultiResponse} MeIsBlockingMultiResponse
         */
        MeIsBlockingMultiResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeIsBlockingMultiResponse)
                return object;
            var message = new $root.me.MeIsBlockingMultiResponse();
            if (object.blockingStatusList) {
                if (!Array.isArray(object.blockingStatusList))
                    throw TypeError(".me.MeIsBlockingMultiResponse.blockingStatusList: array expected");
                message.blockingStatusList = [];
                for (var i = 0; i < object.blockingStatusList.length; ++i) {
                    if (typeof object.blockingStatusList[i] !== "object")
                        throw TypeError(".me.MeIsBlockingMultiResponse.blockingStatusList: object expected");
                    message.blockingStatusList[i] = $root.me.BlockingStatus.fromObject(object.blockingStatusList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MeIsBlockingMultiResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeIsBlockingMultiResponse
         * @static
         * @param {me.MeIsBlockingMultiResponse} message MeIsBlockingMultiResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeIsBlockingMultiResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.blockingStatusList = [];
            if (message.blockingStatusList && message.blockingStatusList.length) {
                object.blockingStatusList = [];
                for (var j = 0; j < message.blockingStatusList.length; ++j)
                    object.blockingStatusList[j] = $root.me.BlockingStatus.toObject(message.blockingStatusList[j], options);
            }
            return object;
        };

        /**
         * Converts this MeIsBlockingMultiResponse to JSON.
         * @function toJSON
         * @memberof me.MeIsBlockingMultiResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeIsBlockingMultiResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeIsBlockingMultiResponse
         * @function getTypeUrl
         * @memberof me.MeIsBlockingMultiResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeIsBlockingMultiResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeIsBlockingMultiResponse";
        };

        return MeIsBlockingMultiResponse;
    })();

    me.MeGetNextStoryByRecentReadingProgressResponse = (function() {

        /**
         * Properties of a MeGetNextStoryByRecentReadingProgressResponse.
         * @memberof me
         * @interface IMeGetNextStoryByRecentReadingProgressResponse
         * @property {story.IStoryIDWithMetadata|null} [storyIdWithMetadata] MeGetNextStoryByRecentReadingProgressResponse storyIdWithMetadata
         * @property {me.MeGetNextStoryByRecentReadingProgressResponse.RecentReadingProgressType|null} [type] MeGetNextStoryByRecentReadingProgressResponse type
         */

        /**
         * Constructs a new MeGetNextStoryByRecentReadingProgressResponse.
         * @memberof me
         * @classdesc Represents a MeGetNextStoryByRecentReadingProgressResponse.
         * @implements IMeGetNextStoryByRecentReadingProgressResponse
         * @constructor
         * @param {me.IMeGetNextStoryByRecentReadingProgressResponse=} [properties] Properties to set
         */
        function MeGetNextStoryByRecentReadingProgressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeGetNextStoryByRecentReadingProgressResponse storyIdWithMetadata.
         * @member {story.IStoryIDWithMetadata|null|undefined} storyIdWithMetadata
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @instance
         */
        MeGetNextStoryByRecentReadingProgressResponse.prototype.storyIdWithMetadata = null;

        /**
         * MeGetNextStoryByRecentReadingProgressResponse type.
         * @member {me.MeGetNextStoryByRecentReadingProgressResponse.RecentReadingProgressType} type
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @instance
         */
        MeGetNextStoryByRecentReadingProgressResponse.prototype.type = 0;

        /**
         * Creates a new MeGetNextStoryByRecentReadingProgressResponse instance using the specified properties.
         * @function create
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @static
         * @param {me.IMeGetNextStoryByRecentReadingProgressResponse=} [properties] Properties to set
         * @returns {me.MeGetNextStoryByRecentReadingProgressResponse} MeGetNextStoryByRecentReadingProgressResponse instance
         */
        MeGetNextStoryByRecentReadingProgressResponse.create = function create(properties) {
            return new MeGetNextStoryByRecentReadingProgressResponse(properties);
        };

        /**
         * Encodes the specified MeGetNextStoryByRecentReadingProgressResponse message. Does not implicitly {@link me.MeGetNextStoryByRecentReadingProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @static
         * @param {me.IMeGetNextStoryByRecentReadingProgressResponse} message MeGetNextStoryByRecentReadingProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeGetNextStoryByRecentReadingProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyIdWithMetadata != null && Object.hasOwnProperty.call(message, "storyIdWithMetadata"))
                $root.story.StoryIDWithMetadata.encode(message.storyIdWithMetadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            return writer;
        };

        /**
         * Decodes a MeGetNextStoryByRecentReadingProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {me.MeGetNextStoryByRecentReadingProgressResponse} MeGetNextStoryByRecentReadingProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeGetNextStoryByRecentReadingProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.me.MeGetNextStoryByRecentReadingProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyIdWithMetadata = $root.story.StoryIDWithMetadata.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeGetNextStoryByRecentReadingProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {me.MeGetNextStoryByRecentReadingProgressResponse} MeGetNextStoryByRecentReadingProgressResponse
         */
        MeGetNextStoryByRecentReadingProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.me.MeGetNextStoryByRecentReadingProgressResponse)
                return object;
            var message = new $root.me.MeGetNextStoryByRecentReadingProgressResponse();
            if (object.storyIdWithMetadata != null) {
                if (typeof object.storyIdWithMetadata !== "object")
                    throw TypeError(".me.MeGetNextStoryByRecentReadingProgressResponse.storyIdWithMetadata: object expected");
                message.storyIdWithMetadata = $root.story.StoryIDWithMetadata.fromObject(object.storyIdWithMetadata);
            }
            switch (object.type) {
            case "UNSPECIFIED":
            case 0:
                message.type = 0;
                break;
            case "HALF_READING_STORY":
            case 1:
                message.type = 1;
                break;
            case "NEXT_SERIES_STORY":
            case 2:
                message.type = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a MeGetNextStoryByRecentReadingProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @static
         * @param {me.MeGetNextStoryByRecentReadingProgressResponse} message MeGetNextStoryByRecentReadingProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeGetNextStoryByRecentReadingProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyIdWithMetadata = null;
                object.type = options.enums === String ? "UNSPECIFIED" : 0;
            }
            if (message.storyIdWithMetadata != null && message.hasOwnProperty("storyIdWithMetadata"))
                object.storyIdWithMetadata = $root.story.StoryIDWithMetadata.toObject(message.storyIdWithMetadata, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.me.MeGetNextStoryByRecentReadingProgressResponse.RecentReadingProgressType[message.type] : message.type;
            return object;
        };

        /**
         * Converts this MeGetNextStoryByRecentReadingProgressResponse to JSON.
         * @function toJSON
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeGetNextStoryByRecentReadingProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeGetNextStoryByRecentReadingProgressResponse
         * @function getTypeUrl
         * @memberof me.MeGetNextStoryByRecentReadingProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeGetNextStoryByRecentReadingProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/me.MeGetNextStoryByRecentReadingProgressResponse";
        };

        /**
         * RecentReadingProgressType enum.
         * @name me.MeGetNextStoryByRecentReadingProgressResponse.RecentReadingProgressType
         * @enum {number}
         * @property {number} UNSPECIFIED=0 UNSPECIFIED value
         * @property {number} HALF_READING_STORY=1 HALF_READING_STORY value
         * @property {number} NEXT_SERIES_STORY=2 NEXT_SERIES_STORY value
         */
        MeGetNextStoryByRecentReadingProgressResponse.RecentReadingProgressType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSPECIFIED"] = 0;
            values[valuesById[1] = "HALF_READING_STORY"] = 1;
            values[valuesById[2] = "NEXT_SERIES_STORY"] = 2;
            return values;
        })();

        return MeGetNextStoryByRecentReadingProgressResponse;
    })();

    return me;
})();

$root.permission = (function() {

    /**
     * Namespace permission.
     * @exports permission
     * @namespace
     */
    var permission = {};

    permission.Permission = (function() {

        /**
         * Properties of a Permission.
         * @memberof permission
         * @interface IPermission
         * @property {boolean|null} [canReadPublicUserContent] Permission canReadPublicUserContent
         * @property {boolean|null} [canReadEveryUserContent] Permission canReadEveryUserContent
         * @property {boolean|null} [canWriteOwnContent] Permission canWriteOwnContent
         * @property {boolean|null} [canWriteEveryUserContent] Permission canWriteEveryUserContent
         * @property {boolean|null} [canWriteOfficialContent] Permission canWriteOfficialContent
         * @property {boolean|null} [canPublishOwnContent] Permission canPublishOwnContent
         * @property {Array.<resource.ImageCategory>|null} [listUploadableImageCategory] Permission listUploadableImageCategory
         * @property {boolean|null} [canImportSimpleFormatScript] Permission canImportSimpleFormatScript
         * @property {boolean|null} [canWriteSeriesGroup] Permission canWriteSeriesGroup
         */

        /**
         * Constructs a new Permission.
         * @memberof permission
         * @classdesc Represents a Permission.
         * @implements IPermission
         * @constructor
         * @param {permission.IPermission=} [properties] Properties to set
         */
        function Permission(properties) {
            this.listUploadableImageCategory = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Permission canReadPublicUserContent.
         * @member {boolean} canReadPublicUserContent
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canReadPublicUserContent = false;

        /**
         * Permission canReadEveryUserContent.
         * @member {boolean} canReadEveryUserContent
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canReadEveryUserContent = false;

        /**
         * Permission canWriteOwnContent.
         * @member {boolean} canWriteOwnContent
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canWriteOwnContent = false;

        /**
         * Permission canWriteEveryUserContent.
         * @member {boolean} canWriteEveryUserContent
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canWriteEveryUserContent = false;

        /**
         * Permission canWriteOfficialContent.
         * @member {boolean} canWriteOfficialContent
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canWriteOfficialContent = false;

        /**
         * Permission canPublishOwnContent.
         * @member {boolean} canPublishOwnContent
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canPublishOwnContent = false;

        /**
         * Permission listUploadableImageCategory.
         * @member {Array.<resource.ImageCategory>} listUploadableImageCategory
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.listUploadableImageCategory = $util.emptyArray;

        /**
         * Permission canImportSimpleFormatScript.
         * @member {boolean} canImportSimpleFormatScript
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canImportSimpleFormatScript = false;

        /**
         * Permission canWriteSeriesGroup.
         * @member {boolean} canWriteSeriesGroup
         * @memberof permission.Permission
         * @instance
         */
        Permission.prototype.canWriteSeriesGroup = false;

        /**
         * Creates a new Permission instance using the specified properties.
         * @function create
         * @memberof permission.Permission
         * @static
         * @param {permission.IPermission=} [properties] Properties to set
         * @returns {permission.Permission} Permission instance
         */
        Permission.create = function create(properties) {
            return new Permission(properties);
        };

        /**
         * Encodes the specified Permission message. Does not implicitly {@link permission.Permission.verify|verify} messages.
         * @function encode
         * @memberof permission.Permission
         * @static
         * @param {permission.IPermission} message Permission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Permission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canReadPublicUserContent != null && Object.hasOwnProperty.call(message, "canReadPublicUserContent"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.canReadPublicUserContent);
            if (message.canReadEveryUserContent != null && Object.hasOwnProperty.call(message, "canReadEveryUserContent"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canReadEveryUserContent);
            if (message.canWriteOwnContent != null && Object.hasOwnProperty.call(message, "canWriteOwnContent"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.canWriteOwnContent);
            if (message.canWriteEveryUserContent != null && Object.hasOwnProperty.call(message, "canWriteEveryUserContent"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.canWriteEveryUserContent);
            if (message.canWriteOfficialContent != null && Object.hasOwnProperty.call(message, "canWriteOfficialContent"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.canWriteOfficialContent);
            if (message.canPublishOwnContent != null && Object.hasOwnProperty.call(message, "canPublishOwnContent"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.canPublishOwnContent);
            if (message.listUploadableImageCategory != null && message.listUploadableImageCategory.length) {
                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                for (var i = 0; i < message.listUploadableImageCategory.length; ++i)
                    writer.int32(message.listUploadableImageCategory[i]);
                writer.ldelim();
            }
            if (message.canImportSimpleFormatScript != null && Object.hasOwnProperty.call(message, "canImportSimpleFormatScript"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.canImportSimpleFormatScript);
            if (message.canWriteSeriesGroup != null && Object.hasOwnProperty.call(message, "canWriteSeriesGroup"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.canWriteSeriesGroup);
            return writer;
        };

        /**
         * Decodes a Permission message from the specified reader or buffer.
         * @function decode
         * @memberof permission.Permission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permission.Permission} Permission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Permission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.permission.Permission();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.canReadPublicUserContent = reader.bool();
                    break;
                case 2:
                    message.canReadEveryUserContent = reader.bool();
                    break;
                case 3:
                    message.canWriteOwnContent = reader.bool();
                    break;
                case 4:
                    message.canWriteEveryUserContent = reader.bool();
                    break;
                case 5:
                    message.canWriteOfficialContent = reader.bool();
                    break;
                case 6:
                    message.canPublishOwnContent = reader.bool();
                    break;
                case 7:
                    if (!(message.listUploadableImageCategory && message.listUploadableImageCategory.length))
                        message.listUploadableImageCategory = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.listUploadableImageCategory.push(reader.int32());
                    } else
                        message.listUploadableImageCategory.push(reader.int32());
                    break;
                case 8:
                    message.canImportSimpleFormatScript = reader.bool();
                    break;
                case 9:
                    message.canWriteSeriesGroup = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Permission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permission.Permission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permission.Permission} Permission
         */
        Permission.fromObject = function fromObject(object) {
            if (object instanceof $root.permission.Permission)
                return object;
            var message = new $root.permission.Permission();
            if (object.canReadPublicUserContent != null)
                message.canReadPublicUserContent = Boolean(object.canReadPublicUserContent);
            if (object.canReadEveryUserContent != null)
                message.canReadEveryUserContent = Boolean(object.canReadEveryUserContent);
            if (object.canWriteOwnContent != null)
                message.canWriteOwnContent = Boolean(object.canWriteOwnContent);
            if (object.canWriteEveryUserContent != null)
                message.canWriteEveryUserContent = Boolean(object.canWriteEveryUserContent);
            if (object.canWriteOfficialContent != null)
                message.canWriteOfficialContent = Boolean(object.canWriteOfficialContent);
            if (object.canPublishOwnContent != null)
                message.canPublishOwnContent = Boolean(object.canPublishOwnContent);
            if (object.listUploadableImageCategory) {
                if (!Array.isArray(object.listUploadableImageCategory))
                    throw TypeError(".permission.Permission.listUploadableImageCategory: array expected");
                message.listUploadableImageCategory = [];
                for (var i = 0; i < object.listUploadableImageCategory.length; ++i)
                    switch (object.listUploadableImageCategory[i]) {
                    default:
                    case "IMAGE_CATEGORY_UNSPECIFIED":
                    case 0:
                        message.listUploadableImageCategory[i] = 0;
                        break;
                    case "IMAGE_CATEGORY_USER_PROFILE":
                    case 1:
                        message.listUploadableImageCategory[i] = 1;
                        break;
                    case "IMAGE_CATEGORY_CHARACTER_ICON":
                    case 2:
                        message.listUploadableImageCategory[i] = 2;
                        break;
                    case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
                    case 3:
                        message.listUploadableImageCategory[i] = 3;
                        break;
                    case "IMAGE_CATEGORY_CHAT_BACKGROUND":
                    case 4:
                        message.listUploadableImageCategory[i] = 4;
                        break;
                    case "IMAGE_CATEGORY_TALK_BACKGROUND":
                    case 5:
                        message.listUploadableImageCategory[i] = 5;
                        break;
                    case "IMAGE_CATEGORY_CONTENT":
                    case 6:
                        message.listUploadableImageCategory[i] = 6;
                        break;
                    case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
                    case 7:
                        message.listUploadableImageCategory[i] = 7;
                        break;
                    case "IMAGE_CATEGORY_STAMP":
                    case 8:
                        message.listUploadableImageCategory[i] = 8;
                        break;
                    }
            }
            if (object.canImportSimpleFormatScript != null)
                message.canImportSimpleFormatScript = Boolean(object.canImportSimpleFormatScript);
            if (object.canWriteSeriesGroup != null)
                message.canWriteSeriesGroup = Boolean(object.canWriteSeriesGroup);
            return message;
        };

        /**
         * Creates a plain object from a Permission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permission.Permission
         * @static
         * @param {permission.Permission} message Permission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Permission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.listUploadableImageCategory = [];
            if (options.defaults) {
                object.canReadPublicUserContent = false;
                object.canReadEveryUserContent = false;
                object.canWriteOwnContent = false;
                object.canWriteEveryUserContent = false;
                object.canWriteOfficialContent = false;
                object.canPublishOwnContent = false;
                object.canImportSimpleFormatScript = false;
                object.canWriteSeriesGroup = false;
            }
            if (message.canReadPublicUserContent != null && message.hasOwnProperty("canReadPublicUserContent"))
                object.canReadPublicUserContent = message.canReadPublicUserContent;
            if (message.canReadEveryUserContent != null && message.hasOwnProperty("canReadEveryUserContent"))
                object.canReadEveryUserContent = message.canReadEveryUserContent;
            if (message.canWriteOwnContent != null && message.hasOwnProperty("canWriteOwnContent"))
                object.canWriteOwnContent = message.canWriteOwnContent;
            if (message.canWriteEveryUserContent != null && message.hasOwnProperty("canWriteEveryUserContent"))
                object.canWriteEveryUserContent = message.canWriteEveryUserContent;
            if (message.canWriteOfficialContent != null && message.hasOwnProperty("canWriteOfficialContent"))
                object.canWriteOfficialContent = message.canWriteOfficialContent;
            if (message.canPublishOwnContent != null && message.hasOwnProperty("canPublishOwnContent"))
                object.canPublishOwnContent = message.canPublishOwnContent;
            if (message.listUploadableImageCategory && message.listUploadableImageCategory.length) {
                object.listUploadableImageCategory = [];
                for (var j = 0; j < message.listUploadableImageCategory.length; ++j)
                    object.listUploadableImageCategory[j] = options.enums === String ? $root.resource.ImageCategory[message.listUploadableImageCategory[j]] : message.listUploadableImageCategory[j];
            }
            if (message.canImportSimpleFormatScript != null && message.hasOwnProperty("canImportSimpleFormatScript"))
                object.canImportSimpleFormatScript = message.canImportSimpleFormatScript;
            if (message.canWriteSeriesGroup != null && message.hasOwnProperty("canWriteSeriesGroup"))
                object.canWriteSeriesGroup = message.canWriteSeriesGroup;
            return object;
        };

        /**
         * Converts this Permission to JSON.
         * @function toJSON
         * @memberof permission.Permission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Permission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Permission
         * @function getTypeUrl
         * @memberof permission.Permission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Permission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permission.Permission";
        };

        return Permission;
    })();

    return permission;
})();

$root.paw = (function() {

    /**
     * Namespace paw.
     * @exports paw
     * @namespace
     */
    var paw = {};

    paw.Paw = (function() {

        /**
         * Properties of a Paw.
         * @memberof paw
         * @interface IPaw
         * @property {google.protobuf.IStringValue|null} [userId] Paw userId
         * @property {google.protobuf.IInt32Value|null} [amount] Paw amount
         * @property {google.protobuf.ITimestamp|null} [updatedAt] Paw updatedAt
         * @property {google.protobuf.ITimestamp|null} [nextGainAt] Paw nextGainAt
         * @property {google.protobuf.IInt32Value|null} [upperLimit] Paw upperLimit
         * @property {google.protobuf.IInt32Value|null} [emptyCount] Paw emptyCount
         */

        /**
         * Constructs a new Paw.
         * @memberof paw
         * @classdesc Represents a Paw.
         * @implements IPaw
         * @constructor
         * @param {paw.IPaw=} [properties] Properties to set
         */
        function Paw(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Paw userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof paw.Paw
         * @instance
         */
        Paw.prototype.userId = null;

        /**
         * Paw amount.
         * @member {google.protobuf.IInt32Value|null|undefined} amount
         * @memberof paw.Paw
         * @instance
         */
        Paw.prototype.amount = null;

        /**
         * Paw updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof paw.Paw
         * @instance
         */
        Paw.prototype.updatedAt = null;

        /**
         * Paw nextGainAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextGainAt
         * @memberof paw.Paw
         * @instance
         */
        Paw.prototype.nextGainAt = null;

        /**
         * Paw upperLimit.
         * @member {google.protobuf.IInt32Value|null|undefined} upperLimit
         * @memberof paw.Paw
         * @instance
         */
        Paw.prototype.upperLimit = null;

        /**
         * Paw emptyCount.
         * @member {google.protobuf.IInt32Value|null|undefined} emptyCount
         * @memberof paw.Paw
         * @instance
         */
        Paw.prototype.emptyCount = null;

        /**
         * Creates a new Paw instance using the specified properties.
         * @function create
         * @memberof paw.Paw
         * @static
         * @param {paw.IPaw=} [properties] Properties to set
         * @returns {paw.Paw} Paw instance
         */
        Paw.create = function create(properties) {
            return new Paw(properties);
        };

        /**
         * Encodes the specified Paw message. Does not implicitly {@link paw.Paw.verify|verify} messages.
         * @function encode
         * @memberof paw.Paw
         * @static
         * @param {paw.IPaw} message Paw message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Paw.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                $root.google.protobuf.Int32Value.encode(message.amount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.nextGainAt != null && Object.hasOwnProperty.call(message, "nextGainAt"))
                $root.google.protobuf.Timestamp.encode(message.nextGainAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.upperLimit != null && Object.hasOwnProperty.call(message, "upperLimit"))
                $root.google.protobuf.Int32Value.encode(message.upperLimit, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.emptyCount != null && Object.hasOwnProperty.call(message, "emptyCount"))
                $root.google.protobuf.Int32Value.encode(message.emptyCount, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Paw message from the specified reader or buffer.
         * @function decode
         * @memberof paw.Paw
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {paw.Paw} Paw
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Paw.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.paw.Paw();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.amount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.nextGainAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.upperLimit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.emptyCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Paw message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof paw.Paw
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {paw.Paw} Paw
         */
        Paw.fromObject = function fromObject(object) {
            if (object instanceof $root.paw.Paw)
                return object;
            var message = new $root.paw.Paw();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".paw.Paw.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.amount != null) {
                if (typeof object.amount !== "object")
                    throw TypeError(".paw.Paw.amount: object expected");
                message.amount = $root.google.protobuf.Int32Value.fromObject(object.amount);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".paw.Paw.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.nextGainAt != null) {
                if (typeof object.nextGainAt !== "object")
                    throw TypeError(".paw.Paw.nextGainAt: object expected");
                message.nextGainAt = $root.google.protobuf.Timestamp.fromObject(object.nextGainAt);
            }
            if (object.upperLimit != null) {
                if (typeof object.upperLimit !== "object")
                    throw TypeError(".paw.Paw.upperLimit: object expected");
                message.upperLimit = $root.google.protobuf.Int32Value.fromObject(object.upperLimit);
            }
            if (object.emptyCount != null) {
                if (typeof object.emptyCount !== "object")
                    throw TypeError(".paw.Paw.emptyCount: object expected");
                message.emptyCount = $root.google.protobuf.Int32Value.fromObject(object.emptyCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a Paw message. Also converts values to other types if specified.
         * @function toObject
         * @memberof paw.Paw
         * @static
         * @param {paw.Paw} message Paw
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Paw.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = null;
                object.amount = null;
                object.updatedAt = null;
                object.nextGainAt = null;
                object.upperLimit = null;
                object.emptyCount = null;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = $root.google.protobuf.Int32Value.toObject(message.amount, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.nextGainAt != null && message.hasOwnProperty("nextGainAt"))
                object.nextGainAt = $root.google.protobuf.Timestamp.toObject(message.nextGainAt, options);
            if (message.upperLimit != null && message.hasOwnProperty("upperLimit"))
                object.upperLimit = $root.google.protobuf.Int32Value.toObject(message.upperLimit, options);
            if (message.emptyCount != null && message.hasOwnProperty("emptyCount"))
                object.emptyCount = $root.google.protobuf.Int32Value.toObject(message.emptyCount, options);
            return object;
        };

        /**
         * Converts this Paw to JSON.
         * @function toJSON
         * @memberof paw.Paw
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Paw.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Paw
         * @function getTypeUrl
         * @memberof paw.Paw
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Paw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/paw.Paw";
        };

        return Paw;
    })();

    return paw;
})();

$root.story_selection = (function() {

    /**
     * Namespace story_selection.
     * @exports story_selection
     * @namespace
     */
    var story_selection = {};

    story_selection.StorySelectionService = (function() {

        /**
         * Constructs a new StorySelectionService service.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function StorySelectionService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (StorySelectionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StorySelectionService;

        /**
         * Creates new StorySelectionService service using the specified rpc implementation.
         * @function create
         * @memberof story_selection.StorySelectionService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {StorySelectionService} RPC service. Useful where requests and/or responses are streamed.
         */
        StorySelectionService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link story_selection.StorySelectionService#getV3}.
         * @memberof story_selection.StorySelectionService
         * @typedef GetV3Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithCursorV3} [response] StorySelectionWithCursorV3
         */

        /**
         * Calls GetV3.
         * @function getV3
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetRequest} request StorySelectionGetRequest message or plain object
         * @param {story_selection.StorySelectionService.GetV3Callback} callback Node-style callback called with the error, if any, and StorySelectionWithCursorV3
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StorySelectionService.prototype.getV3 = function getV3(request, callback) {
            return this.rpcCall(getV3, $root.story_selection.StorySelectionGetRequest, $root.story_selection.StorySelectionWithCursorV3, request, callback);
        }, "name", { value: "GetV3" });

        /**
         * Calls GetV3.
         * @function getV3
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetRequest} request StorySelectionGetRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionWithCursorV3>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story_selection.StorySelectionService#getMultiV3}.
         * @memberof story_selection.StorySelectionService
         * @typedef GetMultiV3Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionListV3} [response] StorySelectionListV3
         */

        /**
         * Calls GetMultiV3.
         * @function getMultiV3
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetMultiRequest} request StorySelectionGetMultiRequest message or plain object
         * @param {story_selection.StorySelectionService.GetMultiV3Callback} callback Node-style callback called with the error, if any, and StorySelectionListV3
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StorySelectionService.prototype.getMultiV3 = function getMultiV3(request, callback) {
            return this.rpcCall(getMultiV3, $root.story_selection.StorySelectionGetMultiRequest, $root.story_selection.StorySelectionListV3, request, callback);
        }, "name", { value: "GetMultiV3" });

        /**
         * Calls GetMultiV3.
         * @function getMultiV3
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetMultiRequest} request StorySelectionGetMultiRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionListV3>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story_selection.StorySelectionService#getV2}.
         * @memberof story_selection.StorySelectionService
         * @typedef GetV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithCursor} [response] StorySelectionWithCursor
         */

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetRequest} request StorySelectionGetRequest message or plain object
         * @param {story_selection.StorySelectionService.GetV2Callback} callback Node-style callback called with the error, if any, and StorySelectionWithCursor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StorySelectionService.prototype.getV2 = function getV2(request, callback) {
            return this.rpcCall(getV2, $root.story_selection.StorySelectionGetRequest, $root.story_selection.StorySelectionWithCursor, request, callback);
        }, "name", { value: "GetV2" });

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetRequest} request StorySelectionGetRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionWithCursor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story_selection.StorySelectionService#getMulti}.
         * @memberof story_selection.StorySelectionService
         * @typedef GetMultiCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionList} [response] StorySelectionList
         */

        /**
         * Calls GetMulti.
         * @function getMulti
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetMultiRequest} request StorySelectionGetMultiRequest message or plain object
         * @param {story_selection.StorySelectionService.GetMultiCallback} callback Node-style callback called with the error, if any, and StorySelectionList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StorySelectionService.prototype.getMulti = function getMulti(request, callback) {
            return this.rpcCall(getMulti, $root.story_selection.StorySelectionGetMultiRequest, $root.story_selection.StorySelectionList, request, callback);
        }, "name", { value: "GetMulti" });

        /**
         * Calls GetMulti.
         * @function getMulti
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetMultiRequest} request StorySelectionGetMultiRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story_selection.StorySelectionService#get}.
         * @memberof story_selection.StorySelectionService
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionGetResponse} [response] StorySelectionGetResponse
         */

        /**
         * Calls Get.
         * @function get
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetRequest} request StorySelectionGetRequest message or plain object
         * @param {story_selection.StorySelectionService.GetCallback} callback Node-style callback called with the error, if any, and StorySelectionGetResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StorySelectionService.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.story_selection.StorySelectionGetRequest, $root.story_selection.StorySelectionGetResponse, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof story_selection.StorySelectionService
         * @instance
         * @param {story_selection.IStorySelectionGetRequest} request StorySelectionGetRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionGetResponse>} Promise
         * @variation 2
         */

        return StorySelectionService;
    })();

    story_selection.StorySelectionV3 = (function() {

        /**
         * Properties of a StorySelectionV3.
         * @memberof story_selection
         * @interface IStorySelectionV3
         * @property {google.protobuf.IStringValue|null} [id] StorySelectionV3 id
         * @property {google.protobuf.IStringValue|null} [title] StorySelectionV3 title
         * @property {google.protobuf.IStringValue|null} [description] StorySelectionV3 description
         * @property {resource.IImage|null} [thumbnail] StorySelectionV3 thumbnail
         * @property {Array.<story.IStoryIDWithMetadata>|null} [storyList] StorySelectionV3 storyList
         * @property {google.protobuf.ITimestamp|null} [createdAt] StorySelectionV3 createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StorySelectionV3 updatedAt
         */

        /**
         * Constructs a new StorySelectionV3.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionV3.
         * @implements IStorySelectionV3
         * @constructor
         * @param {story_selection.IStorySelectionV3=} [properties] Properties to set
         */
        function StorySelectionV3(properties) {
            this.storyList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionV3 id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_selection.StorySelectionV3
         * @instance
         */
        StorySelectionV3.prototype.id = null;

        /**
         * StorySelectionV3 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story_selection.StorySelectionV3
         * @instance
         */
        StorySelectionV3.prototype.title = null;

        /**
         * StorySelectionV3 description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story_selection.StorySelectionV3
         * @instance
         */
        StorySelectionV3.prototype.description = null;

        /**
         * StorySelectionV3 thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof story_selection.StorySelectionV3
         * @instance
         */
        StorySelectionV3.prototype.thumbnail = null;

        /**
         * StorySelectionV3 storyList.
         * @member {Array.<story.IStoryIDWithMetadata>} storyList
         * @memberof story_selection.StorySelectionV3
         * @instance
         */
        StorySelectionV3.prototype.storyList = $util.emptyArray;

        /**
         * StorySelectionV3 createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof story_selection.StorySelectionV3
         * @instance
         */
        StorySelectionV3.prototype.createdAt = null;

        /**
         * StorySelectionV3 updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof story_selection.StorySelectionV3
         * @instance
         */
        StorySelectionV3.prototype.updatedAt = null;

        /**
         * Creates a new StorySelectionV3 instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionV3
         * @static
         * @param {story_selection.IStorySelectionV3=} [properties] Properties to set
         * @returns {story_selection.StorySelectionV3} StorySelectionV3 instance
         */
        StorySelectionV3.create = function create(properties) {
            return new StorySelectionV3(properties);
        };

        /**
         * Encodes the specified StorySelectionV3 message. Does not implicitly {@link story_selection.StorySelectionV3.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionV3
         * @static
         * @param {story_selection.IStorySelectionV3} message StorySelectionV3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionV3.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.storyList != null && message.storyList.length)
                for (var i = 0; i < message.storyList.length; ++i)
                    $root.story.StoryIDWithMetadata.encode(message.storyList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionV3 message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionV3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionV3} StorySelectionV3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionV3.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionV3();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.storyList && message.storyList.length))
                        message.storyList = [];
                    message.storyList.push($root.story.StoryIDWithMetadata.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionV3 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionV3
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionV3} StorySelectionV3
         */
        StorySelectionV3.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionV3)
                return object;
            var message = new $root.story_selection.StorySelectionV3();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_selection.StorySelectionV3.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story_selection.StorySelectionV3.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story_selection.StorySelectionV3.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".story_selection.StorySelectionV3.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.storyList) {
                if (!Array.isArray(object.storyList))
                    throw TypeError(".story_selection.StorySelectionV3.storyList: array expected");
                message.storyList = [];
                for (var i = 0; i < object.storyList.length; ++i) {
                    if (typeof object.storyList[i] !== "object")
                        throw TypeError(".story_selection.StorySelectionV3.storyList: object expected");
                    message.storyList[i] = $root.story.StoryIDWithMetadata.fromObject(object.storyList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".story_selection.StorySelectionV3.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".story_selection.StorySelectionV3.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionV3 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionV3
         * @static
         * @param {story_selection.StorySelectionV3} message StorySelectionV3
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionV3.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyList = [];
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.storyList && message.storyList.length) {
                object.storyList = [];
                for (var j = 0; j < message.storyList.length; ++j)
                    object.storyList[j] = $root.story.StoryIDWithMetadata.toObject(message.storyList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this StorySelectionV3 to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionV3
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionV3.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionV3
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionV3
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionV3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionV3";
        };

        return StorySelectionV3;
    })();

    story_selection.StorySelectionWithCursorV3 = (function() {

        /**
         * Properties of a StorySelectionWithCursorV3.
         * @memberof story_selection
         * @interface IStorySelectionWithCursorV3
         * @property {story_selection.IStorySelectionV3|null} [storySelection] StorySelectionWithCursorV3 storySelection
         * @property {google.protobuf.IStringValue|null} [cursor] StorySelectionWithCursorV3 cursor
         */

        /**
         * Constructs a new StorySelectionWithCursorV3.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionWithCursorV3.
         * @implements IStorySelectionWithCursorV3
         * @constructor
         * @param {story_selection.IStorySelectionWithCursorV3=} [properties] Properties to set
         */
        function StorySelectionWithCursorV3(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionWithCursorV3 storySelection.
         * @member {story_selection.IStorySelectionV3|null|undefined} storySelection
         * @memberof story_selection.StorySelectionWithCursorV3
         * @instance
         */
        StorySelectionWithCursorV3.prototype.storySelection = null;

        /**
         * StorySelectionWithCursorV3 cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof story_selection.StorySelectionWithCursorV3
         * @instance
         */
        StorySelectionWithCursorV3.prototype.cursor = null;

        /**
         * Creates a new StorySelectionWithCursorV3 instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionWithCursorV3
         * @static
         * @param {story_selection.IStorySelectionWithCursorV3=} [properties] Properties to set
         * @returns {story_selection.StorySelectionWithCursorV3} StorySelectionWithCursorV3 instance
         */
        StorySelectionWithCursorV3.create = function create(properties) {
            return new StorySelectionWithCursorV3(properties);
        };

        /**
         * Encodes the specified StorySelectionWithCursorV3 message. Does not implicitly {@link story_selection.StorySelectionWithCursorV3.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionWithCursorV3
         * @static
         * @param {story_selection.IStorySelectionWithCursorV3} message StorySelectionWithCursorV3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionWithCursorV3.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storySelection != null && Object.hasOwnProperty.call(message, "storySelection"))
                $root.story_selection.StorySelectionV3.encode(message.storySelection, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionWithCursorV3 message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionWithCursorV3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionWithCursorV3} StorySelectionWithCursorV3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionWithCursorV3.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionWithCursorV3();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storySelection = $root.story_selection.StorySelectionV3.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionWithCursorV3 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionWithCursorV3
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionWithCursorV3} StorySelectionWithCursorV3
         */
        StorySelectionWithCursorV3.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionWithCursorV3)
                return object;
            var message = new $root.story_selection.StorySelectionWithCursorV3();
            if (object.storySelection != null) {
                if (typeof object.storySelection !== "object")
                    throw TypeError(".story_selection.StorySelectionWithCursorV3.storySelection: object expected");
                message.storySelection = $root.story_selection.StorySelectionV3.fromObject(object.storySelection);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".story_selection.StorySelectionWithCursorV3.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionWithCursorV3 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionWithCursorV3
         * @static
         * @param {story_selection.StorySelectionWithCursorV3} message StorySelectionWithCursorV3
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionWithCursorV3.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storySelection = null;
                object.cursor = null;
            }
            if (message.storySelection != null && message.hasOwnProperty("storySelection"))
                object.storySelection = $root.story_selection.StorySelectionV3.toObject(message.storySelection, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StorySelectionWithCursorV3 to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionWithCursorV3
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionWithCursorV3.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionWithCursorV3
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionWithCursorV3
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionWithCursorV3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionWithCursorV3";
        };

        return StorySelectionWithCursorV3;
    })();

    story_selection.StorySelectionListV3 = (function() {

        /**
         * Properties of a StorySelectionListV3.
         * @memberof story_selection
         * @interface IStorySelectionListV3
         * @property {Array.<story_selection.IStorySelectionV3>|null} [storySelectionList] StorySelectionListV3 storySelectionList
         */

        /**
         * Constructs a new StorySelectionListV3.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionListV3.
         * @implements IStorySelectionListV3
         * @constructor
         * @param {story_selection.IStorySelectionListV3=} [properties] Properties to set
         */
        function StorySelectionListV3(properties) {
            this.storySelectionList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionListV3 storySelectionList.
         * @member {Array.<story_selection.IStorySelectionV3>} storySelectionList
         * @memberof story_selection.StorySelectionListV3
         * @instance
         */
        StorySelectionListV3.prototype.storySelectionList = $util.emptyArray;

        /**
         * Creates a new StorySelectionListV3 instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionListV3
         * @static
         * @param {story_selection.IStorySelectionListV3=} [properties] Properties to set
         * @returns {story_selection.StorySelectionListV3} StorySelectionListV3 instance
         */
        StorySelectionListV3.create = function create(properties) {
            return new StorySelectionListV3(properties);
        };

        /**
         * Encodes the specified StorySelectionListV3 message. Does not implicitly {@link story_selection.StorySelectionListV3.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionListV3
         * @static
         * @param {story_selection.IStorySelectionListV3} message StorySelectionListV3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionListV3.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storySelectionList != null && message.storySelectionList.length)
                for (var i = 0; i < message.storySelectionList.length; ++i)
                    $root.story_selection.StorySelectionV3.encode(message.storySelectionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionListV3 message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionListV3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionListV3} StorySelectionListV3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionListV3.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionListV3();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storySelectionList && message.storySelectionList.length))
                        message.storySelectionList = [];
                    message.storySelectionList.push($root.story_selection.StorySelectionV3.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionListV3 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionListV3
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionListV3} StorySelectionListV3
         */
        StorySelectionListV3.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionListV3)
                return object;
            var message = new $root.story_selection.StorySelectionListV3();
            if (object.storySelectionList) {
                if (!Array.isArray(object.storySelectionList))
                    throw TypeError(".story_selection.StorySelectionListV3.storySelectionList: array expected");
                message.storySelectionList = [];
                for (var i = 0; i < object.storySelectionList.length; ++i) {
                    if (typeof object.storySelectionList[i] !== "object")
                        throw TypeError(".story_selection.StorySelectionListV3.storySelectionList: object expected");
                    message.storySelectionList[i] = $root.story_selection.StorySelectionV3.fromObject(object.storySelectionList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionListV3 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionListV3
         * @static
         * @param {story_selection.StorySelectionListV3} message StorySelectionListV3
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionListV3.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storySelectionList = [];
            if (message.storySelectionList && message.storySelectionList.length) {
                object.storySelectionList = [];
                for (var j = 0; j < message.storySelectionList.length; ++j)
                    object.storySelectionList[j] = $root.story_selection.StorySelectionV3.toObject(message.storySelectionList[j], options);
            }
            return object;
        };

        /**
         * Converts this StorySelectionListV3 to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionListV3
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionListV3.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionListV3
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionListV3
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionListV3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionListV3";
        };

        return StorySelectionListV3;
    })();

    story_selection.StorySelectionGetRequest = (function() {

        /**
         * Properties of a StorySelectionGetRequest.
         * @memberof story_selection
         * @interface IStorySelectionGetRequest
         * @property {google.protobuf.IStringValue|null} [id] StorySelectionGetRequest id
         * @property {google.protobuf.IInt32Value|null} [limit] StorySelectionGetRequest limit
         * @property {google.protobuf.IStringValue|null} [cursor] StorySelectionGetRequest cursor
         */

        /**
         * Constructs a new StorySelectionGetRequest.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionGetRequest.
         * @implements IStorySelectionGetRequest
         * @constructor
         * @param {story_selection.IStorySelectionGetRequest=} [properties] Properties to set
         */
        function StorySelectionGetRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionGetRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_selection.StorySelectionGetRequest
         * @instance
         */
        StorySelectionGetRequest.prototype.id = null;

        /**
         * StorySelectionGetRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof story_selection.StorySelectionGetRequest
         * @instance
         */
        StorySelectionGetRequest.prototype.limit = null;

        /**
         * StorySelectionGetRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof story_selection.StorySelectionGetRequest
         * @instance
         */
        StorySelectionGetRequest.prototype.cursor = null;

        /**
         * Creates a new StorySelectionGetRequest instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionGetRequest
         * @static
         * @param {story_selection.IStorySelectionGetRequest=} [properties] Properties to set
         * @returns {story_selection.StorySelectionGetRequest} StorySelectionGetRequest instance
         */
        StorySelectionGetRequest.create = function create(properties) {
            return new StorySelectionGetRequest(properties);
        };

        /**
         * Encodes the specified StorySelectionGetRequest message. Does not implicitly {@link story_selection.StorySelectionGetRequest.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionGetRequest
         * @static
         * @param {story_selection.IStorySelectionGetRequest} message StorySelectionGetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionGetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionGetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionGetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionGetRequest} StorySelectionGetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionGetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionGetRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionGetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionGetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionGetRequest} StorySelectionGetRequest
         */
        StorySelectionGetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionGetRequest)
                return object;
            var message = new $root.story_selection.StorySelectionGetRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_selection.StorySelectionGetRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".story_selection.StorySelectionGetRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".story_selection.StorySelectionGetRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionGetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionGetRequest
         * @static
         * @param {story_selection.StorySelectionGetRequest} message StorySelectionGetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionGetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.limit = null;
                object.cursor = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StorySelectionGetRequest to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionGetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionGetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionGetRequest
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionGetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionGetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionGetRequest";
        };

        return StorySelectionGetRequest;
    })();

    story_selection.StorySelectionGetMultiRequest = (function() {

        /**
         * Properties of a StorySelectionGetMultiRequest.
         * @memberof story_selection
         * @interface IStorySelectionGetMultiRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [idList] StorySelectionGetMultiRequest idList
         * @property {google.protobuf.IInt32Value|null} [limitPerSelection] StorySelectionGetMultiRequest limitPerSelection
         */

        /**
         * Constructs a new StorySelectionGetMultiRequest.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionGetMultiRequest.
         * @implements IStorySelectionGetMultiRequest
         * @constructor
         * @param {story_selection.IStorySelectionGetMultiRequest=} [properties] Properties to set
         */
        function StorySelectionGetMultiRequest(properties) {
            this.idList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionGetMultiRequest idList.
         * @member {Array.<google.protobuf.IStringValue>} idList
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @instance
         */
        StorySelectionGetMultiRequest.prototype.idList = $util.emptyArray;

        /**
         * StorySelectionGetMultiRequest limitPerSelection.
         * @member {google.protobuf.IInt32Value|null|undefined} limitPerSelection
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @instance
         */
        StorySelectionGetMultiRequest.prototype.limitPerSelection = null;

        /**
         * Creates a new StorySelectionGetMultiRequest instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @static
         * @param {story_selection.IStorySelectionGetMultiRequest=} [properties] Properties to set
         * @returns {story_selection.StorySelectionGetMultiRequest} StorySelectionGetMultiRequest instance
         */
        StorySelectionGetMultiRequest.create = function create(properties) {
            return new StorySelectionGetMultiRequest(properties);
        };

        /**
         * Encodes the specified StorySelectionGetMultiRequest message. Does not implicitly {@link story_selection.StorySelectionGetMultiRequest.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @static
         * @param {story_selection.IStorySelectionGetMultiRequest} message StorySelectionGetMultiRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionGetMultiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idList != null && message.idList.length)
                for (var i = 0; i < message.idList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.idList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limitPerSelection != null && Object.hasOwnProperty.call(message, "limitPerSelection"))
                $root.google.protobuf.Int32Value.encode(message.limitPerSelection, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionGetMultiRequest message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionGetMultiRequest} StorySelectionGetMultiRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionGetMultiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionGetMultiRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.idList && message.idList.length))
                        message.idList = [];
                    message.idList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.limitPerSelection = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionGetMultiRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionGetMultiRequest} StorySelectionGetMultiRequest
         */
        StorySelectionGetMultiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionGetMultiRequest)
                return object;
            var message = new $root.story_selection.StorySelectionGetMultiRequest();
            if (object.idList) {
                if (!Array.isArray(object.idList))
                    throw TypeError(".story_selection.StorySelectionGetMultiRequest.idList: array expected");
                message.idList = [];
                for (var i = 0; i < object.idList.length; ++i) {
                    if (typeof object.idList[i] !== "object")
                        throw TypeError(".story_selection.StorySelectionGetMultiRequest.idList: object expected");
                    message.idList[i] = $root.google.protobuf.StringValue.fromObject(object.idList[i]);
                }
            }
            if (object.limitPerSelection != null) {
                if (typeof object.limitPerSelection !== "object")
                    throw TypeError(".story_selection.StorySelectionGetMultiRequest.limitPerSelection: object expected");
                message.limitPerSelection = $root.google.protobuf.Int32Value.fromObject(object.limitPerSelection);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionGetMultiRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @static
         * @param {story_selection.StorySelectionGetMultiRequest} message StorySelectionGetMultiRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionGetMultiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.idList = [];
            if (options.defaults)
                object.limitPerSelection = null;
            if (message.idList && message.idList.length) {
                object.idList = [];
                for (var j = 0; j < message.idList.length; ++j)
                    object.idList[j] = $root.google.protobuf.StringValue.toObject(message.idList[j], options);
            }
            if (message.limitPerSelection != null && message.hasOwnProperty("limitPerSelection"))
                object.limitPerSelection = $root.google.protobuf.Int32Value.toObject(message.limitPerSelection, options);
            return object;
        };

        /**
         * Converts this StorySelectionGetMultiRequest to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionGetMultiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionGetMultiRequest
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionGetMultiRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionGetMultiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionGetMultiRequest";
        };

        return StorySelectionGetMultiRequest;
    })();

    story_selection.StorySelectionList = (function() {

        /**
         * Properties of a StorySelectionList.
         * @memberof story_selection
         * @interface IStorySelectionList
         * @property {Array.<story_selection.IStorySelectionWithCursor>|null} [storySelectionList] StorySelectionList storySelectionList
         */

        /**
         * Constructs a new StorySelectionList.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionList.
         * @implements IStorySelectionList
         * @constructor
         * @param {story_selection.IStorySelectionList=} [properties] Properties to set
         */
        function StorySelectionList(properties) {
            this.storySelectionList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionList storySelectionList.
         * @member {Array.<story_selection.IStorySelectionWithCursor>} storySelectionList
         * @memberof story_selection.StorySelectionList
         * @instance
         */
        StorySelectionList.prototype.storySelectionList = $util.emptyArray;

        /**
         * Creates a new StorySelectionList instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionList
         * @static
         * @param {story_selection.IStorySelectionList=} [properties] Properties to set
         * @returns {story_selection.StorySelectionList} StorySelectionList instance
         */
        StorySelectionList.create = function create(properties) {
            return new StorySelectionList(properties);
        };

        /**
         * Encodes the specified StorySelectionList message. Does not implicitly {@link story_selection.StorySelectionList.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionList
         * @static
         * @param {story_selection.IStorySelectionList} message StorySelectionList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storySelectionList != null && message.storySelectionList.length)
                for (var i = 0; i < message.storySelectionList.length; ++i)
                    $root.story_selection.StorySelectionWithCursor.encode(message.storySelectionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionList message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionList} StorySelectionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storySelectionList && message.storySelectionList.length))
                        message.storySelectionList = [];
                    message.storySelectionList.push($root.story_selection.StorySelectionWithCursor.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionList} StorySelectionList
         */
        StorySelectionList.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionList)
                return object;
            var message = new $root.story_selection.StorySelectionList();
            if (object.storySelectionList) {
                if (!Array.isArray(object.storySelectionList))
                    throw TypeError(".story_selection.StorySelectionList.storySelectionList: array expected");
                message.storySelectionList = [];
                for (var i = 0; i < object.storySelectionList.length; ++i) {
                    if (typeof object.storySelectionList[i] !== "object")
                        throw TypeError(".story_selection.StorySelectionList.storySelectionList: object expected");
                    message.storySelectionList[i] = $root.story_selection.StorySelectionWithCursor.fromObject(object.storySelectionList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionList
         * @static
         * @param {story_selection.StorySelectionList} message StorySelectionList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storySelectionList = [];
            if (message.storySelectionList && message.storySelectionList.length) {
                object.storySelectionList = [];
                for (var j = 0; j < message.storySelectionList.length; ++j)
                    object.storySelectionList[j] = $root.story_selection.StorySelectionWithCursor.toObject(message.storySelectionList[j], options);
            }
            return object;
        };

        /**
         * Converts this StorySelectionList to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionList
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionList";
        };

        return StorySelectionList;
    })();

    story_selection.StorySelectionWithCursor = (function() {

        /**
         * Properties of a StorySelectionWithCursor.
         * @memberof story_selection
         * @interface IStorySelectionWithCursor
         * @property {story_selection.IStorySelection|null} [storySelection] StorySelectionWithCursor storySelection
         * @property {google.protobuf.IStringValue|null} [cursor] StorySelectionWithCursor cursor
         */

        /**
         * Constructs a new StorySelectionWithCursor.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionWithCursor.
         * @implements IStorySelectionWithCursor
         * @constructor
         * @param {story_selection.IStorySelectionWithCursor=} [properties] Properties to set
         */
        function StorySelectionWithCursor(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionWithCursor storySelection.
         * @member {story_selection.IStorySelection|null|undefined} storySelection
         * @memberof story_selection.StorySelectionWithCursor
         * @instance
         */
        StorySelectionWithCursor.prototype.storySelection = null;

        /**
         * StorySelectionWithCursor cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof story_selection.StorySelectionWithCursor
         * @instance
         */
        StorySelectionWithCursor.prototype.cursor = null;

        /**
         * Creates a new StorySelectionWithCursor instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionWithCursor
         * @static
         * @param {story_selection.IStorySelectionWithCursor=} [properties] Properties to set
         * @returns {story_selection.StorySelectionWithCursor} StorySelectionWithCursor instance
         */
        StorySelectionWithCursor.create = function create(properties) {
            return new StorySelectionWithCursor(properties);
        };

        /**
         * Encodes the specified StorySelectionWithCursor message. Does not implicitly {@link story_selection.StorySelectionWithCursor.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionWithCursor
         * @static
         * @param {story_selection.IStorySelectionWithCursor} message StorySelectionWithCursor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionWithCursor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storySelection != null && Object.hasOwnProperty.call(message, "storySelection"))
                $root.story_selection.StorySelection.encode(message.storySelection, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionWithCursor message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionWithCursor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionWithCursor} StorySelectionWithCursor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionWithCursor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionWithCursor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storySelection = $root.story_selection.StorySelection.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionWithCursor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionWithCursor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionWithCursor} StorySelectionWithCursor
         */
        StorySelectionWithCursor.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionWithCursor)
                return object;
            var message = new $root.story_selection.StorySelectionWithCursor();
            if (object.storySelection != null) {
                if (typeof object.storySelection !== "object")
                    throw TypeError(".story_selection.StorySelectionWithCursor.storySelection: object expected");
                message.storySelection = $root.story_selection.StorySelection.fromObject(object.storySelection);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".story_selection.StorySelectionWithCursor.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionWithCursor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionWithCursor
         * @static
         * @param {story_selection.StorySelectionWithCursor} message StorySelectionWithCursor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionWithCursor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storySelection = null;
                object.cursor = null;
            }
            if (message.storySelection != null && message.hasOwnProperty("storySelection"))
                object.storySelection = $root.story_selection.StorySelection.toObject(message.storySelection, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StorySelectionWithCursor to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionWithCursor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionWithCursor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionWithCursor
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionWithCursor
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionWithCursor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionWithCursor";
        };

        return StorySelectionWithCursor;
    })();

    story_selection.StorySelection = (function() {

        /**
         * Properties of a StorySelection.
         * @memberof story_selection
         * @interface IStorySelection
         * @property {google.protobuf.IStringValue|null} [id] StorySelection id
         * @property {google.protobuf.IStringValue|null} [title] StorySelection title
         * @property {google.protobuf.IStringValue|null} [description] StorySelection description
         * @property {resource.IImage|null} [thumbnail] StorySelection thumbnail
         * @property {Array.<story.ISeriesStoryHeader>|null} [headerList] StorySelection headerList
         * @property {google.protobuf.ITimestamp|null} [createdAt] StorySelection createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StorySelection updatedAt
         */

        /**
         * Constructs a new StorySelection.
         * @memberof story_selection
         * @classdesc Represents a StorySelection.
         * @implements IStorySelection
         * @constructor
         * @param {story_selection.IStorySelection=} [properties] Properties to set
         */
        function StorySelection(properties) {
            this.headerList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelection id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_selection.StorySelection
         * @instance
         */
        StorySelection.prototype.id = null;

        /**
         * StorySelection title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story_selection.StorySelection
         * @instance
         */
        StorySelection.prototype.title = null;

        /**
         * StorySelection description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story_selection.StorySelection
         * @instance
         */
        StorySelection.prototype.description = null;

        /**
         * StorySelection thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof story_selection.StorySelection
         * @instance
         */
        StorySelection.prototype.thumbnail = null;

        /**
         * StorySelection headerList.
         * @member {Array.<story.ISeriesStoryHeader>} headerList
         * @memberof story_selection.StorySelection
         * @instance
         */
        StorySelection.prototype.headerList = $util.emptyArray;

        /**
         * StorySelection createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof story_selection.StorySelection
         * @instance
         */
        StorySelection.prototype.createdAt = null;

        /**
         * StorySelection updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof story_selection.StorySelection
         * @instance
         */
        StorySelection.prototype.updatedAt = null;

        /**
         * Creates a new StorySelection instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelection
         * @static
         * @param {story_selection.IStorySelection=} [properties] Properties to set
         * @returns {story_selection.StorySelection} StorySelection instance
         */
        StorySelection.create = function create(properties) {
            return new StorySelection(properties);
        };

        /**
         * Encodes the specified StorySelection message. Does not implicitly {@link story_selection.StorySelection.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelection
         * @static
         * @param {story_selection.IStorySelection} message StorySelection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.headerList != null && message.headerList.length)
                for (var i = 0; i < message.headerList.length; ++i)
                    $root.story.SeriesStoryHeader.encode(message.headerList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelection message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelection} StorySelection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelection();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.headerList && message.headerList.length))
                        message.headerList = [];
                    message.headerList.push($root.story.SeriesStoryHeader.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelection} StorySelection
         */
        StorySelection.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelection)
                return object;
            var message = new $root.story_selection.StorySelection();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_selection.StorySelection.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story_selection.StorySelection.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story_selection.StorySelection.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".story_selection.StorySelection.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.headerList) {
                if (!Array.isArray(object.headerList))
                    throw TypeError(".story_selection.StorySelection.headerList: array expected");
                message.headerList = [];
                for (var i = 0; i < object.headerList.length; ++i) {
                    if (typeof object.headerList[i] !== "object")
                        throw TypeError(".story_selection.StorySelection.headerList: object expected");
                    message.headerList[i] = $root.story.SeriesStoryHeader.fromObject(object.headerList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".story_selection.StorySelection.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".story_selection.StorySelection.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelection
         * @static
         * @param {story_selection.StorySelection} message StorySelection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.headerList = [];
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.headerList && message.headerList.length) {
                object.headerList = [];
                for (var j = 0; j < message.headerList.length; ++j)
                    object.headerList[j] = $root.story.SeriesStoryHeader.toObject(message.headerList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this StorySelection to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelection
         * @function getTypeUrl
         * @memberof story_selection.StorySelection
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelection";
        };

        return StorySelection;
    })();

    story_selection.StorySelectionWithMatchScore = (function() {

        /**
         * Properties of a StorySelectionWithMatchScore.
         * @memberof story_selection
         * @interface IStorySelectionWithMatchScore
         * @property {google.protobuf.IStringValue|null} [id] StorySelectionWithMatchScore id
         * @property {google.protobuf.IStringValue|null} [title] StorySelectionWithMatchScore title
         * @property {google.protobuf.IStringValue|null} [description] StorySelectionWithMatchScore description
         * @property {resource.IImage|null} [thumbnail] StorySelectionWithMatchScore thumbnail
         * @property {Array.<story.ISeriesStoryHeaderWithMatchScore>|null} [headerList] StorySelectionWithMatchScore headerList
         * @property {google.protobuf.ITimestamp|null} [createdAt] StorySelectionWithMatchScore createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StorySelectionWithMatchScore updatedAt
         */

        /**
         * Constructs a new StorySelectionWithMatchScore.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionWithMatchScore.
         * @implements IStorySelectionWithMatchScore
         * @constructor
         * @param {story_selection.IStorySelectionWithMatchScore=} [properties] Properties to set
         */
        function StorySelectionWithMatchScore(properties) {
            this.headerList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionWithMatchScore id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         */
        StorySelectionWithMatchScore.prototype.id = null;

        /**
         * StorySelectionWithMatchScore title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         */
        StorySelectionWithMatchScore.prototype.title = null;

        /**
         * StorySelectionWithMatchScore description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         */
        StorySelectionWithMatchScore.prototype.description = null;

        /**
         * StorySelectionWithMatchScore thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         */
        StorySelectionWithMatchScore.prototype.thumbnail = null;

        /**
         * StorySelectionWithMatchScore headerList.
         * @member {Array.<story.ISeriesStoryHeaderWithMatchScore>} headerList
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         */
        StorySelectionWithMatchScore.prototype.headerList = $util.emptyArray;

        /**
         * StorySelectionWithMatchScore createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         */
        StorySelectionWithMatchScore.prototype.createdAt = null;

        /**
         * StorySelectionWithMatchScore updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         */
        StorySelectionWithMatchScore.prototype.updatedAt = null;

        /**
         * Creates a new StorySelectionWithMatchScore instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionWithMatchScore
         * @static
         * @param {story_selection.IStorySelectionWithMatchScore=} [properties] Properties to set
         * @returns {story_selection.StorySelectionWithMatchScore} StorySelectionWithMatchScore instance
         */
        StorySelectionWithMatchScore.create = function create(properties) {
            return new StorySelectionWithMatchScore(properties);
        };

        /**
         * Encodes the specified StorySelectionWithMatchScore message. Does not implicitly {@link story_selection.StorySelectionWithMatchScore.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionWithMatchScore
         * @static
         * @param {story_selection.IStorySelectionWithMatchScore} message StorySelectionWithMatchScore message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionWithMatchScore.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.headerList != null && message.headerList.length)
                for (var i = 0; i < message.headerList.length; ++i)
                    $root.story.SeriesStoryHeaderWithMatchScore.encode(message.headerList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionWithMatchScore message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionWithMatchScore
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionWithMatchScore} StorySelectionWithMatchScore
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionWithMatchScore.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionWithMatchScore();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.headerList && message.headerList.length))
                        message.headerList = [];
                    message.headerList.push($root.story.SeriesStoryHeaderWithMatchScore.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionWithMatchScore message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionWithMatchScore
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionWithMatchScore} StorySelectionWithMatchScore
         */
        StorySelectionWithMatchScore.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionWithMatchScore)
                return object;
            var message = new $root.story_selection.StorySelectionWithMatchScore();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_selection.StorySelectionWithMatchScore.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story_selection.StorySelectionWithMatchScore.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story_selection.StorySelectionWithMatchScore.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".story_selection.StorySelectionWithMatchScore.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.headerList) {
                if (!Array.isArray(object.headerList))
                    throw TypeError(".story_selection.StorySelectionWithMatchScore.headerList: array expected");
                message.headerList = [];
                for (var i = 0; i < object.headerList.length; ++i) {
                    if (typeof object.headerList[i] !== "object")
                        throw TypeError(".story_selection.StorySelectionWithMatchScore.headerList: object expected");
                    message.headerList[i] = $root.story.SeriesStoryHeaderWithMatchScore.fromObject(object.headerList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".story_selection.StorySelectionWithMatchScore.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".story_selection.StorySelectionWithMatchScore.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionWithMatchScore message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionWithMatchScore
         * @static
         * @param {story_selection.StorySelectionWithMatchScore} message StorySelectionWithMatchScore
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionWithMatchScore.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.headerList = [];
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.headerList && message.headerList.length) {
                object.headerList = [];
                for (var j = 0; j < message.headerList.length; ++j)
                    object.headerList[j] = $root.story.SeriesStoryHeaderWithMatchScore.toObject(message.headerList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this StorySelectionWithMatchScore to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionWithMatchScore
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionWithMatchScore.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionWithMatchScore
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionWithMatchScore
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionWithMatchScore.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionWithMatchScore";
        };

        return StorySelectionWithMatchScore;
    })();

    story_selection.StorySelectionGetResponse = (function() {

        /**
         * Properties of a StorySelectionGetResponse.
         * @memberof story_selection
         * @interface IStorySelectionGetResponse
         * @property {story_selection.IStorySelectionV1|null} [storySelection] StorySelectionGetResponse storySelection
         * @property {google.protobuf.IStringValue|null} [cursor] StorySelectionGetResponse cursor
         */

        /**
         * Constructs a new StorySelectionGetResponse.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionGetResponse.
         * @implements IStorySelectionGetResponse
         * @constructor
         * @param {story_selection.IStorySelectionGetResponse=} [properties] Properties to set
         */
        function StorySelectionGetResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionGetResponse storySelection.
         * @member {story_selection.IStorySelectionV1|null|undefined} storySelection
         * @memberof story_selection.StorySelectionGetResponse
         * @instance
         */
        StorySelectionGetResponse.prototype.storySelection = null;

        /**
         * StorySelectionGetResponse cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof story_selection.StorySelectionGetResponse
         * @instance
         */
        StorySelectionGetResponse.prototype.cursor = null;

        /**
         * Creates a new StorySelectionGetResponse instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionGetResponse
         * @static
         * @param {story_selection.IStorySelectionGetResponse=} [properties] Properties to set
         * @returns {story_selection.StorySelectionGetResponse} StorySelectionGetResponse instance
         */
        StorySelectionGetResponse.create = function create(properties) {
            return new StorySelectionGetResponse(properties);
        };

        /**
         * Encodes the specified StorySelectionGetResponse message. Does not implicitly {@link story_selection.StorySelectionGetResponse.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionGetResponse
         * @static
         * @param {story_selection.IStorySelectionGetResponse} message StorySelectionGetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionGetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storySelection != null && Object.hasOwnProperty.call(message, "storySelection"))
                $root.story_selection.StorySelectionV1.encode(message.storySelection, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionGetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionGetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionGetResponse} StorySelectionGetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionGetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionGetResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storySelection = $root.story_selection.StorySelectionV1.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionGetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionGetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionGetResponse} StorySelectionGetResponse
         */
        StorySelectionGetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionGetResponse)
                return object;
            var message = new $root.story_selection.StorySelectionGetResponse();
            if (object.storySelection != null) {
                if (typeof object.storySelection !== "object")
                    throw TypeError(".story_selection.StorySelectionGetResponse.storySelection: object expected");
                message.storySelection = $root.story_selection.StorySelectionV1.fromObject(object.storySelection);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".story_selection.StorySelectionGetResponse.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionGetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionGetResponse
         * @static
         * @param {story_selection.StorySelectionGetResponse} message StorySelectionGetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionGetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storySelection = null;
                object.cursor = null;
            }
            if (message.storySelection != null && message.hasOwnProperty("storySelection"))
                object.storySelection = $root.story_selection.StorySelectionV1.toObject(message.storySelection, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StorySelectionGetResponse to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionGetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionGetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionGetResponse
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionGetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionGetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionGetResponse";
        };

        return StorySelectionGetResponse;
    })();

    story_selection.StorySelectionV1 = (function() {

        /**
         * Properties of a StorySelectionV1.
         * @memberof story_selection
         * @interface IStorySelectionV1
         * @property {google.protobuf.IStringValue|null} [id] StorySelectionV1 id
         * @property {google.protobuf.IStringValue|null} [title] StorySelectionV1 title
         * @property {google.protobuf.IStringValue|null} [description] StorySelectionV1 description
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] StorySelectionV1 thumbnailUrl
         * @property {Array.<story.ISeriesStoryHeaderV1>|null} [headerList] StorySelectionV1 headerList
         * @property {google.protobuf.ITimestamp|null} [createdAt] StorySelectionV1 createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StorySelectionV1 updatedAt
         */

        /**
         * Constructs a new StorySelectionV1.
         * @memberof story_selection
         * @classdesc Represents a StorySelectionV1.
         * @implements IStorySelectionV1
         * @constructor
         * @param {story_selection.IStorySelectionV1=} [properties] Properties to set
         */
        function StorySelectionV1(properties) {
            this.headerList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionV1 id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_selection.StorySelectionV1
         * @instance
         */
        StorySelectionV1.prototype.id = null;

        /**
         * StorySelectionV1 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story_selection.StorySelectionV1
         * @instance
         */
        StorySelectionV1.prototype.title = null;

        /**
         * StorySelectionV1 description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story_selection.StorySelectionV1
         * @instance
         */
        StorySelectionV1.prototype.description = null;

        /**
         * StorySelectionV1 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof story_selection.StorySelectionV1
         * @instance
         */
        StorySelectionV1.prototype.thumbnailUrl = null;

        /**
         * StorySelectionV1 headerList.
         * @member {Array.<story.ISeriesStoryHeaderV1>} headerList
         * @memberof story_selection.StorySelectionV1
         * @instance
         */
        StorySelectionV1.prototype.headerList = $util.emptyArray;

        /**
         * StorySelectionV1 createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof story_selection.StorySelectionV1
         * @instance
         */
        StorySelectionV1.prototype.createdAt = null;

        /**
         * StorySelectionV1 updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof story_selection.StorySelectionV1
         * @instance
         */
        StorySelectionV1.prototype.updatedAt = null;

        /**
         * Creates a new StorySelectionV1 instance using the specified properties.
         * @function create
         * @memberof story_selection.StorySelectionV1
         * @static
         * @param {story_selection.IStorySelectionV1=} [properties] Properties to set
         * @returns {story_selection.StorySelectionV1} StorySelectionV1 instance
         */
        StorySelectionV1.create = function create(properties) {
            return new StorySelectionV1(properties);
        };

        /**
         * Encodes the specified StorySelectionV1 message. Does not implicitly {@link story_selection.StorySelectionV1.verify|verify} messages.
         * @function encode
         * @memberof story_selection.StorySelectionV1
         * @static
         * @param {story_selection.IStorySelectionV1} message StorySelectionV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.headerList != null && message.headerList.length)
                for (var i = 0; i < message.headerList.length; ++i)
                    $root.story.SeriesStoryHeaderV1.encode(message.headerList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionV1 message from the specified reader or buffer.
         * @function decode
         * @memberof story_selection.StorySelectionV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_selection.StorySelectionV1} StorySelectionV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_selection.StorySelectionV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.headerList && message.headerList.length))
                        message.headerList = [];
                    message.headerList.push($root.story.SeriesStoryHeaderV1.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_selection.StorySelectionV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_selection.StorySelectionV1} StorySelectionV1
         */
        StorySelectionV1.fromObject = function fromObject(object) {
            if (object instanceof $root.story_selection.StorySelectionV1)
                return object;
            var message = new $root.story_selection.StorySelectionV1();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_selection.StorySelectionV1.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story_selection.StorySelectionV1.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story_selection.StorySelectionV1.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".story_selection.StorySelectionV1.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.headerList) {
                if (!Array.isArray(object.headerList))
                    throw TypeError(".story_selection.StorySelectionV1.headerList: array expected");
                message.headerList = [];
                for (var i = 0; i < object.headerList.length; ++i) {
                    if (typeof object.headerList[i] !== "object")
                        throw TypeError(".story_selection.StorySelectionV1.headerList: object expected");
                    message.headerList[i] = $root.story.SeriesStoryHeaderV1.fromObject(object.headerList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".story_selection.StorySelectionV1.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".story_selection.StorySelectionV1.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_selection.StorySelectionV1
         * @static
         * @param {story_selection.StorySelectionV1} message StorySelectionV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.headerList = [];
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnailUrl = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.headerList && message.headerList.length) {
                object.headerList = [];
                for (var j = 0; j < message.headerList.length; ++j)
                    object.headerList[j] = $root.story.SeriesStoryHeaderV1.toObject(message.headerList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this StorySelectionV1 to JSON.
         * @function toJSON
         * @memberof story_selection.StorySelectionV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionV1
         * @function getTypeUrl
         * @memberof story_selection.StorySelectionV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_selection.StorySelectionV1";
        };

        return StorySelectionV1;
    })();

    return story_selection;
})();

$root.story = (function() {

    /**
     * Namespace story.
     * @exports story
     * @namespace
     */
    var story = {};

    story.StoryService = (function() {

        /**
         * Constructs a new StoryService service.
         * @memberof story
         * @classdesc Represents a StoryService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function StoryService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (StoryService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoryService;

        /**
         * Creates new StoryService service using the specified rpc implementation.
         * @function create
         * @memberof story.StoryService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {StoryService} RPC service. Useful where requests and/or responses are streamed.
         */
        StoryService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link story.StoryService#getV2}.
         * @memberof story.StoryService
         * @typedef GetV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story.Story} [response] Story
         */

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryIdRequest} request StoryIdRequest message or plain object
         * @param {story.StoryService.GetV2Callback} callback Node-style callback called with the error, if any, and Story
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryService.prototype.getV2 = function getV2(request, callback) {
            return this.rpcCall(getV2, $root.story.StoryIdRequest, $root.story.Story, request, callback);
        }, "name", { value: "GetV2" });

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryIdRequest} request StoryIdRequest message or plain object
         * @returns {Promise<story.Story>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story.StoryService#createV2}.
         * @memberof story.StoryService
         * @typedef CreateV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story.StoryHeader} [response] StoryHeader
         */

        /**
         * Calls CreateV2.
         * @function createV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStory} request Story message or plain object
         * @param {story.StoryService.CreateV2Callback} callback Node-style callback called with the error, if any, and StoryHeader
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryService.prototype.createV2 = function createV2(request, callback) {
            return this.rpcCall(createV2, $root.story.Story, $root.story.StoryHeader, request, callback);
        }, "name", { value: "CreateV2" });

        /**
         * Calls CreateV2.
         * @function createV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStory} request Story message or plain object
         * @returns {Promise<story.StoryHeader>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story.StoryService#updateV2}.
         * @memberof story.StoryService
         * @typedef UpdateV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story.StoryHeader} [response] StoryHeader
         */

        /**
         * Calls UpdateV2.
         * @function updateV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStory} request Story message or plain object
         * @param {story.StoryService.UpdateV2Callback} callback Node-style callback called with the error, if any, and StoryHeader
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryService.prototype.updateV2 = function updateV2(request, callback) {
            return this.rpcCall(updateV2, $root.story.Story, $root.story.StoryHeader, request, callback);
        }, "name", { value: "UpdateV2" });

        /**
         * Calls UpdateV2.
         * @function updateV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStory} request Story message or plain object
         * @returns {Promise<story.StoryHeader>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story.StoryService#deleteV2}.
         * @memberof story.StoryService
         * @typedef DeleteV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteV2.
         * @function deleteV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryIdRequest} request StoryIdRequest message or plain object
         * @param {story.StoryService.DeleteV2Callback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryService.prototype.deleteV2 = function deleteV2(request, callback) {
            return this.rpcCall(deleteV2, $root.story.StoryIdRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteV2" });

        /**
         * Calls DeleteV2.
         * @function deleteV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryIdRequest} request StoryIdRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story.StoryService#reportViolationV2}.
         * @memberof story.StoryService
         * @typedef ReportViolationV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls ReportViolationV2.
         * @function reportViolationV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryReportViolationRequestV2} request StoryReportViolationRequestV2 message or plain object
         * @param {story.StoryService.ReportViolationV2Callback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryService.prototype.reportViolationV2 = function reportViolationV2(request, callback) {
            return this.rpcCall(reportViolationV2, $root.story.StoryReportViolationRequestV2, $root.types.Empty, request, callback);
        }, "name", { value: "ReportViolationV2" });

        /**
         * Calls ReportViolationV2.
         * @function reportViolationV2
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryReportViolationRequestV2} request StoryReportViolationRequestV2 message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story.StoryService#reportViolation}.
         * @memberof story.StoryService
         * @typedef ReportViolationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls ReportViolation.
         * @function reportViolation
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryReportViolationRequest} request StoryReportViolationRequest message or plain object
         * @param {story.StoryService.ReportViolationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryService.prototype.reportViolation = function reportViolation(request, callback) {
            return this.rpcCall(reportViolation, $root.story.StoryReportViolationRequest, $root.types.Empty, request, callback);
        }, "name", { value: "ReportViolation" });

        /**
         * Calls ReportViolation.
         * @function reportViolation
         * @memberof story.StoryService
         * @instance
         * @param {story.IStoryReportViolationRequest} request StoryReportViolationRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        return StoryService;
    })();

    story.StoryIdRequest = (function() {

        /**
         * Properties of a StoryIdRequest.
         * @memberof story
         * @interface IStoryIdRequest
         * @property {google.protobuf.IStringValue|null} [id] StoryIdRequest id
         */

        /**
         * Constructs a new StoryIdRequest.
         * @memberof story
         * @classdesc Represents a StoryIdRequest.
         * @implements IStoryIdRequest
         * @constructor
         * @param {story.IStoryIdRequest=} [properties] Properties to set
         */
        function StoryIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryIdRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story.StoryIdRequest
         * @instance
         */
        StoryIdRequest.prototype.id = null;

        /**
         * Creates a new StoryIdRequest instance using the specified properties.
         * @function create
         * @memberof story.StoryIdRequest
         * @static
         * @param {story.IStoryIdRequest=} [properties] Properties to set
         * @returns {story.StoryIdRequest} StoryIdRequest instance
         */
        StoryIdRequest.create = function create(properties) {
            return new StoryIdRequest(properties);
        };

        /**
         * Encodes the specified StoryIdRequest message. Does not implicitly {@link story.StoryIdRequest.verify|verify} messages.
         * @function encode
         * @memberof story.StoryIdRequest
         * @static
         * @param {story.IStoryIdRequest} message StoryIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryIdRequest} StoryIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryIdRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryIdRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryIdRequest} StoryIdRequest
         */
        StoryIdRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryIdRequest)
                return object;
            var message = new $root.story.StoryIdRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story.StoryIdRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryIdRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryIdRequest
         * @static
         * @param {story.StoryIdRequest} message StoryIdRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryIdRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this StoryIdRequest to JSON.
         * @function toJSON
         * @memberof story.StoryIdRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryIdRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryIdRequest
         * @function getTypeUrl
         * @memberof story.StoryIdRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryIdRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryIdRequest";
        };

        return StoryIdRequest;
    })();

    story.StoryIdListRequest = (function() {

        /**
         * Properties of a StoryIdListRequest.
         * @memberof story
         * @interface IStoryIdListRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [storyIdList] StoryIdListRequest storyIdList
         */

        /**
         * Constructs a new StoryIdListRequest.
         * @memberof story
         * @classdesc Represents a StoryIdListRequest.
         * @implements IStoryIdListRequest
         * @constructor
         * @param {story.IStoryIdListRequest=} [properties] Properties to set
         */
        function StoryIdListRequest(properties) {
            this.storyIdList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryIdListRequest storyIdList.
         * @member {Array.<google.protobuf.IStringValue>} storyIdList
         * @memberof story.StoryIdListRequest
         * @instance
         */
        StoryIdListRequest.prototype.storyIdList = $util.emptyArray;

        /**
         * Creates a new StoryIdListRequest instance using the specified properties.
         * @function create
         * @memberof story.StoryIdListRequest
         * @static
         * @param {story.IStoryIdListRequest=} [properties] Properties to set
         * @returns {story.StoryIdListRequest} StoryIdListRequest instance
         */
        StoryIdListRequest.create = function create(properties) {
            return new StoryIdListRequest(properties);
        };

        /**
         * Encodes the specified StoryIdListRequest message. Does not implicitly {@link story.StoryIdListRequest.verify|verify} messages.
         * @function encode
         * @memberof story.StoryIdListRequest
         * @static
         * @param {story.IStoryIdListRequest} message StoryIdListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryIdListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyIdList != null && message.storyIdList.length)
                for (var i = 0; i < message.storyIdList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.storyIdList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryIdListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryIdListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryIdListRequest} StoryIdListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryIdListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryIdListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyIdList && message.storyIdList.length))
                        message.storyIdList = [];
                    message.storyIdList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryIdListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryIdListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryIdListRequest} StoryIdListRequest
         */
        StoryIdListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryIdListRequest)
                return object;
            var message = new $root.story.StoryIdListRequest();
            if (object.storyIdList) {
                if (!Array.isArray(object.storyIdList))
                    throw TypeError(".story.StoryIdListRequest.storyIdList: array expected");
                message.storyIdList = [];
                for (var i = 0; i < object.storyIdList.length; ++i) {
                    if (typeof object.storyIdList[i] !== "object")
                        throw TypeError(".story.StoryIdListRequest.storyIdList: object expected");
                    message.storyIdList[i] = $root.google.protobuf.StringValue.fromObject(object.storyIdList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryIdListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryIdListRequest
         * @static
         * @param {story.StoryIdListRequest} message StoryIdListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryIdListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyIdList = [];
            if (message.storyIdList && message.storyIdList.length) {
                object.storyIdList = [];
                for (var j = 0; j < message.storyIdList.length; ++j)
                    object.storyIdList[j] = $root.google.protobuf.StringValue.toObject(message.storyIdList[j], options);
            }
            return object;
        };

        /**
         * Converts this StoryIdListRequest to JSON.
         * @function toJSON
         * @memberof story.StoryIdListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryIdListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryIdListRequest
         * @function getTypeUrl
         * @memberof story.StoryIdListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryIdListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryIdListRequest";
        };

        return StoryIdListRequest;
    })();

    story.StoryHeader = (function() {

        /**
         * Properties of a StoryHeader.
         * @memberof story
         * @interface IStoryHeader
         * @property {google.protobuf.IStringValue|null} [id] StoryHeader id
         * @property {google.protobuf.IStringValue|null} [userId] StoryHeader userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] StoryHeader tags
         * @property {google.protobuf.IStringValue|null} [seriesId] StoryHeader seriesId
         * @property {google.protobuf.IInt32Value|null} [seriesIndex] StoryHeader seriesIndex
         * @property {google.protobuf.IStringValue|null} [title] StoryHeader title
         * @property {google.protobuf.IStringValue|null} [description] StoryHeader description
         * @property {google.protobuf.IStringValue|null} [opening] StoryHeader opening
         * @property {resource.IImage|null} [thumbnail] StoryHeader thumbnail
         * @property {google.protobuf.IInt32Value|null} [length] StoryHeader length
         * @property {story.StoryStatus|null} [status] StoryHeader status
         * @property {google.protobuf.IBoolValue|null} [isOfficial] StoryHeader isOfficial
         * @property {google.protobuf.ITimestamp|null} [createdAt] StoryHeader createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StoryHeader updatedAt
         * @property {google.protobuf.ITimestamp|null} [publishedAt] StoryHeader publishedAt
         * @property {google.protobuf.IBoolValue|null} [isPawRequired] StoryHeader isPawRequired
         * @property {google.protobuf.IBoolValue|null} [isVipOnly] StoryHeader isVipOnly
         * @property {query_types.SensitiveFlag|null} [sensitiveFlag] StoryHeader sensitiveFlag
         */

        /**
         * Constructs a new StoryHeader.
         * @memberof story
         * @classdesc Represents a StoryHeader.
         * @implements IStoryHeader
         * @constructor
         * @param {story.IStoryHeader=} [properties] Properties to set
         */
        function StoryHeader(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryHeader id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.id = null;

        /**
         * StoryHeader userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.userId = null;

        /**
         * StoryHeader tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.tags = $util.emptyArray;

        /**
         * StoryHeader seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.seriesId = null;

        /**
         * StoryHeader seriesIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} seriesIndex
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.seriesIndex = null;

        /**
         * StoryHeader title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.title = null;

        /**
         * StoryHeader description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.description = null;

        /**
         * StoryHeader opening.
         * @member {google.protobuf.IStringValue|null|undefined} opening
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.opening = null;

        /**
         * StoryHeader thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.thumbnail = null;

        /**
         * StoryHeader length.
         * @member {google.protobuf.IInt32Value|null|undefined} length
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.length = null;

        /**
         * StoryHeader status.
         * @member {story.StoryStatus} status
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.status = 0;

        /**
         * StoryHeader isOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} isOfficial
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.isOfficial = null;

        /**
         * StoryHeader createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.createdAt = null;

        /**
         * StoryHeader updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.updatedAt = null;

        /**
         * StoryHeader publishedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} publishedAt
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.publishedAt = null;

        /**
         * StoryHeader isPawRequired.
         * @member {google.protobuf.IBoolValue|null|undefined} isPawRequired
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.isPawRequired = null;

        /**
         * StoryHeader isVipOnly.
         * @member {google.protobuf.IBoolValue|null|undefined} isVipOnly
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.isVipOnly = null;

        /**
         * StoryHeader sensitiveFlag.
         * @member {query_types.SensitiveFlag} sensitiveFlag
         * @memberof story.StoryHeader
         * @instance
         */
        StoryHeader.prototype.sensitiveFlag = 0;

        /**
         * Creates a new StoryHeader instance using the specified properties.
         * @function create
         * @memberof story.StoryHeader
         * @static
         * @param {story.IStoryHeader=} [properties] Properties to set
         * @returns {story.StoryHeader} StoryHeader instance
         */
        StoryHeader.create = function create(properties) {
            return new StoryHeader(properties);
        };

        /**
         * Encodes the specified StoryHeader message. Does not implicitly {@link story.StoryHeader.verify|verify} messages.
         * @function encode
         * @memberof story.StoryHeader
         * @static
         * @param {story.IStoryHeader} message StoryHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryHeader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.seriesIndex != null && Object.hasOwnProperty.call(message, "seriesIndex"))
                $root.google.protobuf.Int32Value.encode(message.seriesIndex, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.opening != null && Object.hasOwnProperty.call(message, "opening"))
                $root.google.protobuf.StringValue.encode(message.opening, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                $root.google.protobuf.Int32Value.encode(message.length, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.status);
            if (message.isOfficial != null && Object.hasOwnProperty.call(message, "isOfficial"))
                $root.google.protobuf.BoolValue.encode(message.isOfficial, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.publishedAt != null && Object.hasOwnProperty.call(message, "publishedAt"))
                $root.google.protobuf.Timestamp.encode(message.publishedAt, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.isPawRequired != null && Object.hasOwnProperty.call(message, "isPawRequired"))
                $root.google.protobuf.BoolValue.encode(message.isPawRequired, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.isVipOnly != null && Object.hasOwnProperty.call(message, "isVipOnly"))
                $root.google.protobuf.BoolValue.encode(message.isVipOnly, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.sensitiveFlag != null && Object.hasOwnProperty.call(message, "sensitiveFlag"))
                writer.uint32(/* id 18, wireType 0 =*/144).int32(message.sensitiveFlag);
            return writer;
        };

        /**
         * Decodes a StoryHeader message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryHeader} StoryHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryHeader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryHeader();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.seriesIndex = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.opening = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.length = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.status = reader.int32();
                    break;
                case 12:
                    message.isOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.publishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.isPawRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.isVipOnly = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.sensitiveFlag = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryHeader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryHeader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryHeader} StoryHeader
         */
        StoryHeader.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryHeader)
                return object;
            var message = new $root.story.StoryHeader();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story.StoryHeader.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".story.StoryHeader.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".story.StoryHeader.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".story.StoryHeader.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".story.StoryHeader.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            if (object.seriesIndex != null) {
                if (typeof object.seriesIndex !== "object")
                    throw TypeError(".story.StoryHeader.seriesIndex: object expected");
                message.seriesIndex = $root.google.protobuf.Int32Value.fromObject(object.seriesIndex);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story.StoryHeader.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story.StoryHeader.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.opening != null) {
                if (typeof object.opening !== "object")
                    throw TypeError(".story.StoryHeader.opening: object expected");
                message.opening = $root.google.protobuf.StringValue.fromObject(object.opening);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".story.StoryHeader.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.length != null) {
                if (typeof object.length !== "object")
                    throw TypeError(".story.StoryHeader.length: object expected");
                message.length = $root.google.protobuf.Int32Value.fromObject(object.length);
            }
            switch (object.status) {
            case "STORY_STATUS_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "DRAFT":
            case 1:
                message.status = 1;
                break;
            case "RESERVE":
            case 2:
                message.status = 2;
                break;
            case "PUBLISH":
            case 3:
                message.status = 3;
                break;
            case "REJECT":
            case 4:
                message.status = 4;
                break;
            }
            if (object.isOfficial != null) {
                if (typeof object.isOfficial !== "object")
                    throw TypeError(".story.StoryHeader.isOfficial: object expected");
                message.isOfficial = $root.google.protobuf.BoolValue.fromObject(object.isOfficial);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".story.StoryHeader.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".story.StoryHeader.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.publishedAt != null) {
                if (typeof object.publishedAt !== "object")
                    throw TypeError(".story.StoryHeader.publishedAt: object expected");
                message.publishedAt = $root.google.protobuf.Timestamp.fromObject(object.publishedAt);
            }
            if (object.isPawRequired != null) {
                if (typeof object.isPawRequired !== "object")
                    throw TypeError(".story.StoryHeader.isPawRequired: object expected");
                message.isPawRequired = $root.google.protobuf.BoolValue.fromObject(object.isPawRequired);
            }
            if (object.isVipOnly != null) {
                if (typeof object.isVipOnly !== "object")
                    throw TypeError(".story.StoryHeader.isVipOnly: object expected");
                message.isVipOnly = $root.google.protobuf.BoolValue.fromObject(object.isVipOnly);
            }
            switch (object.sensitiveFlag) {
            case "SENSITIVE_FLAG_UNSPECIFIED":
            case 0:
                message.sensitiveFlag = 0;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_USER":
            case 1:
                message.sensitiveFlag = 1;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_ADMIN":
            case 2:
                message.sensitiveFlag = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryHeader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryHeader
         * @static
         * @param {story.StoryHeader} message StoryHeader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryHeader.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.id = null;
                object.userId = null;
                object.seriesId = null;
                object.seriesIndex = null;
                object.title = null;
                object.description = null;
                object.opening = null;
                object.thumbnail = null;
                object.length = null;
                object.status = options.enums === String ? "STORY_STATUS_UNKNOWN" : 0;
                object.isOfficial = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.publishedAt = null;
                object.isPawRequired = null;
                object.isVipOnly = null;
                object.sensitiveFlag = options.enums === String ? "SENSITIVE_FLAG_UNSPECIFIED" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            if (message.seriesIndex != null && message.hasOwnProperty("seriesIndex"))
                object.seriesIndex = $root.google.protobuf.Int32Value.toObject(message.seriesIndex, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.opening != null && message.hasOwnProperty("opening"))
                object.opening = $root.google.protobuf.StringValue.toObject(message.opening, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.length != null && message.hasOwnProperty("length"))
                object.length = $root.google.protobuf.Int32Value.toObject(message.length, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.story.StoryStatus[message.status] : message.status;
            if (message.isOfficial != null && message.hasOwnProperty("isOfficial"))
                object.isOfficial = $root.google.protobuf.BoolValue.toObject(message.isOfficial, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.publishedAt != null && message.hasOwnProperty("publishedAt"))
                object.publishedAt = $root.google.protobuf.Timestamp.toObject(message.publishedAt, options);
            if (message.isPawRequired != null && message.hasOwnProperty("isPawRequired"))
                object.isPawRequired = $root.google.protobuf.BoolValue.toObject(message.isPawRequired, options);
            if (message.isVipOnly != null && message.hasOwnProperty("isVipOnly"))
                object.isVipOnly = $root.google.protobuf.BoolValue.toObject(message.isVipOnly, options);
            if (message.sensitiveFlag != null && message.hasOwnProperty("sensitiveFlag"))
                object.sensitiveFlag = options.enums === String ? $root.query_types.SensitiveFlag[message.sensitiveFlag] : message.sensitiveFlag;
            return object;
        };

        /**
         * Converts this StoryHeader to JSON.
         * @function toJSON
         * @memberof story.StoryHeader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryHeader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryHeader
         * @function getTypeUrl
         * @memberof story.StoryHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryHeader";
        };

        return StoryHeader;
    })();

    story.SeriesStoryHeader = (function() {

        /**
         * Properties of a SeriesStoryHeader.
         * @memberof story
         * @interface ISeriesStoryHeader
         * @property {story.IStoryHeader|null} [header] SeriesStoryHeader header
         * @property {series.ISeries|null} [series] SeriesStoryHeader series
         */

        /**
         * Constructs a new SeriesStoryHeader.
         * @memberof story
         * @classdesc Represents a SeriesStoryHeader.
         * @implements ISeriesStoryHeader
         * @constructor
         * @param {story.ISeriesStoryHeader=} [properties] Properties to set
         */
        function SeriesStoryHeader(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesStoryHeader header.
         * @member {story.IStoryHeader|null|undefined} header
         * @memberof story.SeriesStoryHeader
         * @instance
         */
        SeriesStoryHeader.prototype.header = null;

        /**
         * SeriesStoryHeader series.
         * @member {series.ISeries|null|undefined} series
         * @memberof story.SeriesStoryHeader
         * @instance
         */
        SeriesStoryHeader.prototype.series = null;

        /**
         * Creates a new SeriesStoryHeader instance using the specified properties.
         * @function create
         * @memberof story.SeriesStoryHeader
         * @static
         * @param {story.ISeriesStoryHeader=} [properties] Properties to set
         * @returns {story.SeriesStoryHeader} SeriesStoryHeader instance
         */
        SeriesStoryHeader.create = function create(properties) {
            return new SeriesStoryHeader(properties);
        };

        /**
         * Encodes the specified SeriesStoryHeader message. Does not implicitly {@link story.SeriesStoryHeader.verify|verify} messages.
         * @function encode
         * @memberof story.SeriesStoryHeader
         * @static
         * @param {story.ISeriesStoryHeader} message SeriesStoryHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesStoryHeader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.story.StoryHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.series.Series.encode(message.series, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesStoryHeader message from the specified reader or buffer.
         * @function decode
         * @memberof story.SeriesStoryHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.SeriesStoryHeader} SeriesStoryHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesStoryHeader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.SeriesStoryHeader();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.header = $root.story.StoryHeader.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.series = $root.series.Series.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesStoryHeader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.SeriesStoryHeader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.SeriesStoryHeader} SeriesStoryHeader
         */
        SeriesStoryHeader.fromObject = function fromObject(object) {
            if (object instanceof $root.story.SeriesStoryHeader)
                return object;
            var message = new $root.story.SeriesStoryHeader();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".story.SeriesStoryHeader.header: object expected");
                message.header = $root.story.StoryHeader.fromObject(object.header);
            }
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".story.SeriesStoryHeader.series: object expected");
                message.series = $root.series.Series.fromObject(object.series);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesStoryHeader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.SeriesStoryHeader
         * @static
         * @param {story.SeriesStoryHeader} message SeriesStoryHeader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesStoryHeader.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.header = null;
                object.series = null;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.story.StoryHeader.toObject(message.header, options);
            if (message.series != null && message.hasOwnProperty("series"))
                object.series = $root.series.Series.toObject(message.series, options);
            return object;
        };

        /**
         * Converts this SeriesStoryHeader to JSON.
         * @function toJSON
         * @memberof story.SeriesStoryHeader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesStoryHeader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesStoryHeader
         * @function getTypeUrl
         * @memberof story.SeriesStoryHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesStoryHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.SeriesStoryHeader";
        };

        return SeriesStoryHeader;
    })();

    story.SeriesStoryHeaderWithMatchScore = (function() {

        /**
         * Properties of a SeriesStoryHeaderWithMatchScore.
         * @memberof story
         * @interface ISeriesStoryHeaderWithMatchScore
         * @property {story.ISeriesStoryHeader|null} [header] SeriesStoryHeaderWithMatchScore header
         * @property {google.protobuf.IFloatValue|null} [matchScore] SeriesStoryHeaderWithMatchScore matchScore
         */

        /**
         * Constructs a new SeriesStoryHeaderWithMatchScore.
         * @memberof story
         * @classdesc Represents a SeriesStoryHeaderWithMatchScore.
         * @implements ISeriesStoryHeaderWithMatchScore
         * @constructor
         * @param {story.ISeriesStoryHeaderWithMatchScore=} [properties] Properties to set
         */
        function SeriesStoryHeaderWithMatchScore(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesStoryHeaderWithMatchScore header.
         * @member {story.ISeriesStoryHeader|null|undefined} header
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @instance
         */
        SeriesStoryHeaderWithMatchScore.prototype.header = null;

        /**
         * SeriesStoryHeaderWithMatchScore matchScore.
         * @member {google.protobuf.IFloatValue|null|undefined} matchScore
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @instance
         */
        SeriesStoryHeaderWithMatchScore.prototype.matchScore = null;

        /**
         * Creates a new SeriesStoryHeaderWithMatchScore instance using the specified properties.
         * @function create
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @static
         * @param {story.ISeriesStoryHeaderWithMatchScore=} [properties] Properties to set
         * @returns {story.SeriesStoryHeaderWithMatchScore} SeriesStoryHeaderWithMatchScore instance
         */
        SeriesStoryHeaderWithMatchScore.create = function create(properties) {
            return new SeriesStoryHeaderWithMatchScore(properties);
        };

        /**
         * Encodes the specified SeriesStoryHeaderWithMatchScore message. Does not implicitly {@link story.SeriesStoryHeaderWithMatchScore.verify|verify} messages.
         * @function encode
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @static
         * @param {story.ISeriesStoryHeaderWithMatchScore} message SeriesStoryHeaderWithMatchScore message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesStoryHeaderWithMatchScore.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.story.SeriesStoryHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.matchScore != null && Object.hasOwnProperty.call(message, "matchScore"))
                $root.google.protobuf.FloatValue.encode(message.matchScore, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesStoryHeaderWithMatchScore message from the specified reader or buffer.
         * @function decode
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.SeriesStoryHeaderWithMatchScore} SeriesStoryHeaderWithMatchScore
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesStoryHeaderWithMatchScore.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.SeriesStoryHeaderWithMatchScore();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.header = $root.story.SeriesStoryHeader.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.matchScore = $root.google.protobuf.FloatValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesStoryHeaderWithMatchScore message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.SeriesStoryHeaderWithMatchScore} SeriesStoryHeaderWithMatchScore
         */
        SeriesStoryHeaderWithMatchScore.fromObject = function fromObject(object) {
            if (object instanceof $root.story.SeriesStoryHeaderWithMatchScore)
                return object;
            var message = new $root.story.SeriesStoryHeaderWithMatchScore();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".story.SeriesStoryHeaderWithMatchScore.header: object expected");
                message.header = $root.story.SeriesStoryHeader.fromObject(object.header);
            }
            if (object.matchScore != null) {
                if (typeof object.matchScore !== "object")
                    throw TypeError(".story.SeriesStoryHeaderWithMatchScore.matchScore: object expected");
                message.matchScore = $root.google.protobuf.FloatValue.fromObject(object.matchScore);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesStoryHeaderWithMatchScore message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @static
         * @param {story.SeriesStoryHeaderWithMatchScore} message SeriesStoryHeaderWithMatchScore
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesStoryHeaderWithMatchScore.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.header = null;
                object.matchScore = null;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.story.SeriesStoryHeader.toObject(message.header, options);
            if (message.matchScore != null && message.hasOwnProperty("matchScore"))
                object.matchScore = $root.google.protobuf.FloatValue.toObject(message.matchScore, options);
            return object;
        };

        /**
         * Converts this SeriesStoryHeaderWithMatchScore to JSON.
         * @function toJSON
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesStoryHeaderWithMatchScore.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesStoryHeaderWithMatchScore
         * @function getTypeUrl
         * @memberof story.SeriesStoryHeaderWithMatchScore
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesStoryHeaderWithMatchScore.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.SeriesStoryHeaderWithMatchScore";
        };

        return SeriesStoryHeaderWithMatchScore;
    })();

    story.Story = (function() {

        /**
         * Properties of a Story.
         * @memberof story
         * @interface IStory
         * @property {story.IStoryHeader|null} [header] Story header
         * @property {tellerscript.ITellerScript|null} [script] Story script
         * @property {resource.IResources|null} [resources] Story resources
         */

        /**
         * Constructs a new Story.
         * @memberof story
         * @classdesc Represents a Story.
         * @implements IStory
         * @constructor
         * @param {story.IStory=} [properties] Properties to set
         */
        function Story(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Story header.
         * @member {story.IStoryHeader|null|undefined} header
         * @memberof story.Story
         * @instance
         */
        Story.prototype.header = null;

        /**
         * Story script.
         * @member {tellerscript.ITellerScript|null|undefined} script
         * @memberof story.Story
         * @instance
         */
        Story.prototype.script = null;

        /**
         * Story resources.
         * @member {resource.IResources|null|undefined} resources
         * @memberof story.Story
         * @instance
         */
        Story.prototype.resources = null;

        /**
         * Creates a new Story instance using the specified properties.
         * @function create
         * @memberof story.Story
         * @static
         * @param {story.IStory=} [properties] Properties to set
         * @returns {story.Story} Story instance
         */
        Story.create = function create(properties) {
            return new Story(properties);
        };

        /**
         * Encodes the specified Story message. Does not implicitly {@link story.Story.verify|verify} messages.
         * @function encode
         * @memberof story.Story
         * @static
         * @param {story.IStory} message Story message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Story.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.story.StoryHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.script != null && Object.hasOwnProperty.call(message, "script"))
                $root.tellerscript.TellerScript.encode(message.script, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.resources != null && Object.hasOwnProperty.call(message, "resources"))
                $root.resource.Resources.encode(message.resources, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Story message from the specified reader or buffer.
         * @function decode
         * @memberof story.Story
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.Story} Story
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Story.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.Story();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.header = $root.story.StoryHeader.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.script = $root.tellerscript.TellerScript.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.resources = $root.resource.Resources.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Story message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.Story
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.Story} Story
         */
        Story.fromObject = function fromObject(object) {
            if (object instanceof $root.story.Story)
                return object;
            var message = new $root.story.Story();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".story.Story.header: object expected");
                message.header = $root.story.StoryHeader.fromObject(object.header);
            }
            if (object.script != null) {
                if (typeof object.script !== "object")
                    throw TypeError(".story.Story.script: object expected");
                message.script = $root.tellerscript.TellerScript.fromObject(object.script);
            }
            if (object.resources != null) {
                if (typeof object.resources !== "object")
                    throw TypeError(".story.Story.resources: object expected");
                message.resources = $root.resource.Resources.fromObject(object.resources);
            }
            return message;
        };

        /**
         * Creates a plain object from a Story message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.Story
         * @static
         * @param {story.Story} message Story
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Story.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.header = null;
                object.script = null;
                object.resources = null;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.story.StoryHeader.toObject(message.header, options);
            if (message.script != null && message.hasOwnProperty("script"))
                object.script = $root.tellerscript.TellerScript.toObject(message.script, options);
            if (message.resources != null && message.hasOwnProperty("resources"))
                object.resources = $root.resource.Resources.toObject(message.resources, options);
            return object;
        };

        /**
         * Converts this Story to JSON.
         * @function toJSON
         * @memberof story.Story
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Story.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Story
         * @function getTypeUrl
         * @memberof story.Story
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Story.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.Story";
        };

        return Story;
    })();

    story.UserSpecificStoryMetadata = (function() {

        /**
         * Properties of a UserSpecificStoryMetadata.
         * @memberof story
         * @interface IUserSpecificStoryMetadata
         * @property {google.protobuf.IInt32Value|null} [readingProgress] UserSpecificStoryMetadata readingProgress
         * @property {google.protobuf.IFloatValue|null} [matchScore] UserSpecificStoryMetadata matchScore
         */

        /**
         * Constructs a new UserSpecificStoryMetadata.
         * @memberof story
         * @classdesc Represents a UserSpecificStoryMetadata.
         * @implements IUserSpecificStoryMetadata
         * @constructor
         * @param {story.IUserSpecificStoryMetadata=} [properties] Properties to set
         */
        function UserSpecificStoryMetadata(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserSpecificStoryMetadata readingProgress.
         * @member {google.protobuf.IInt32Value|null|undefined} readingProgress
         * @memberof story.UserSpecificStoryMetadata
         * @instance
         */
        UserSpecificStoryMetadata.prototype.readingProgress = null;

        /**
         * UserSpecificStoryMetadata matchScore.
         * @member {google.protobuf.IFloatValue|null|undefined} matchScore
         * @memberof story.UserSpecificStoryMetadata
         * @instance
         */
        UserSpecificStoryMetadata.prototype.matchScore = null;

        /**
         * Creates a new UserSpecificStoryMetadata instance using the specified properties.
         * @function create
         * @memberof story.UserSpecificStoryMetadata
         * @static
         * @param {story.IUserSpecificStoryMetadata=} [properties] Properties to set
         * @returns {story.UserSpecificStoryMetadata} UserSpecificStoryMetadata instance
         */
        UserSpecificStoryMetadata.create = function create(properties) {
            return new UserSpecificStoryMetadata(properties);
        };

        /**
         * Encodes the specified UserSpecificStoryMetadata message. Does not implicitly {@link story.UserSpecificStoryMetadata.verify|verify} messages.
         * @function encode
         * @memberof story.UserSpecificStoryMetadata
         * @static
         * @param {story.IUserSpecificStoryMetadata} message UserSpecificStoryMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSpecificStoryMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.readingProgress != null && Object.hasOwnProperty.call(message, "readingProgress"))
                $root.google.protobuf.Int32Value.encode(message.readingProgress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.matchScore != null && Object.hasOwnProperty.call(message, "matchScore"))
                $root.google.protobuf.FloatValue.encode(message.matchScore, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserSpecificStoryMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof story.UserSpecificStoryMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.UserSpecificStoryMetadata} UserSpecificStoryMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSpecificStoryMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.UserSpecificStoryMetadata();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.readingProgress = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.matchScore = $root.google.protobuf.FloatValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserSpecificStoryMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.UserSpecificStoryMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.UserSpecificStoryMetadata} UserSpecificStoryMetadata
         */
        UserSpecificStoryMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.story.UserSpecificStoryMetadata)
                return object;
            var message = new $root.story.UserSpecificStoryMetadata();
            if (object.readingProgress != null) {
                if (typeof object.readingProgress !== "object")
                    throw TypeError(".story.UserSpecificStoryMetadata.readingProgress: object expected");
                message.readingProgress = $root.google.protobuf.Int32Value.fromObject(object.readingProgress);
            }
            if (object.matchScore != null) {
                if (typeof object.matchScore !== "object")
                    throw TypeError(".story.UserSpecificStoryMetadata.matchScore: object expected");
                message.matchScore = $root.google.protobuf.FloatValue.fromObject(object.matchScore);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserSpecificStoryMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.UserSpecificStoryMetadata
         * @static
         * @param {story.UserSpecificStoryMetadata} message UserSpecificStoryMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSpecificStoryMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.readingProgress = null;
                object.matchScore = null;
            }
            if (message.readingProgress != null && message.hasOwnProperty("readingProgress"))
                object.readingProgress = $root.google.protobuf.Int32Value.toObject(message.readingProgress, options);
            if (message.matchScore != null && message.hasOwnProperty("matchScore"))
                object.matchScore = $root.google.protobuf.FloatValue.toObject(message.matchScore, options);
            return object;
        };

        /**
         * Converts this UserSpecificStoryMetadata to JSON.
         * @function toJSON
         * @memberof story.UserSpecificStoryMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSpecificStoryMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSpecificStoryMetadata
         * @function getTypeUrl
         * @memberof story.UserSpecificStoryMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSpecificStoryMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.UserSpecificStoryMetadata";
        };

        return UserSpecificStoryMetadata;
    })();

    story.StoryIDWithMetadata = (function() {

        /**
         * Properties of a StoryIDWithMetadata.
         * @memberof story
         * @interface IStoryIDWithMetadata
         * @property {google.protobuf.IStringValue|null} [id] StoryIDWithMetadata id
         * @property {story.IUserSpecificStoryMetadata|null} [metadata] StoryIDWithMetadata metadata
         */

        /**
         * Constructs a new StoryIDWithMetadata.
         * @memberof story
         * @classdesc Represents a StoryIDWithMetadata.
         * @implements IStoryIDWithMetadata
         * @constructor
         * @param {story.IStoryIDWithMetadata=} [properties] Properties to set
         */
        function StoryIDWithMetadata(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryIDWithMetadata id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story.StoryIDWithMetadata
         * @instance
         */
        StoryIDWithMetadata.prototype.id = null;

        /**
         * StoryIDWithMetadata metadata.
         * @member {story.IUserSpecificStoryMetadata|null|undefined} metadata
         * @memberof story.StoryIDWithMetadata
         * @instance
         */
        StoryIDWithMetadata.prototype.metadata = null;

        /**
         * Creates a new StoryIDWithMetadata instance using the specified properties.
         * @function create
         * @memberof story.StoryIDWithMetadata
         * @static
         * @param {story.IStoryIDWithMetadata=} [properties] Properties to set
         * @returns {story.StoryIDWithMetadata} StoryIDWithMetadata instance
         */
        StoryIDWithMetadata.create = function create(properties) {
            return new StoryIDWithMetadata(properties);
        };

        /**
         * Encodes the specified StoryIDWithMetadata message. Does not implicitly {@link story.StoryIDWithMetadata.verify|verify} messages.
         * @function encode
         * @memberof story.StoryIDWithMetadata
         * @static
         * @param {story.IStoryIDWithMetadata} message StoryIDWithMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryIDWithMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                $root.story.UserSpecificStoryMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryIDWithMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryIDWithMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryIDWithMetadata} StoryIDWithMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryIDWithMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryIDWithMetadata();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.metadata = $root.story.UserSpecificStoryMetadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryIDWithMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryIDWithMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryIDWithMetadata} StoryIDWithMetadata
         */
        StoryIDWithMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryIDWithMetadata)
                return object;
            var message = new $root.story.StoryIDWithMetadata();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story.StoryIDWithMetadata.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.metadata != null) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".story.StoryIDWithMetadata.metadata: object expected");
                message.metadata = $root.story.UserSpecificStoryMetadata.fromObject(object.metadata);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryIDWithMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryIDWithMetadata
         * @static
         * @param {story.StoryIDWithMetadata} message StoryIDWithMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryIDWithMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.metadata = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                object.metadata = $root.story.UserSpecificStoryMetadata.toObject(message.metadata, options);
            return object;
        };

        /**
         * Converts this StoryIDWithMetadata to JSON.
         * @function toJSON
         * @memberof story.StoryIDWithMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryIDWithMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryIDWithMetadata
         * @function getTypeUrl
         * @memberof story.StoryIDWithMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryIDWithMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryIDWithMetadata";
        };

        return StoryIDWithMetadata;
    })();

    /**
     * StoryStatus enum.
     * @name story.StoryStatus
     * @enum {number}
     * @property {number} STORY_STATUS_UNKNOWN=0 STORY_STATUS_UNKNOWN value
     * @property {number} DRAFT=1 DRAFT value
     * @property {number} RESERVE=2 RESERVE value
     * @property {number} PUBLISH=3 PUBLISH value
     * @property {number} REJECT=4 REJECT value
     */
    story.StoryStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_STATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "DRAFT"] = 1;
        values[valuesById[2] = "RESERVE"] = 2;
        values[valuesById[3] = "PUBLISH"] = 3;
        values[valuesById[4] = "REJECT"] = 4;
        return values;
    })();

    story.StoryHeaderV1 = (function() {

        /**
         * Properties of a StoryHeaderV1.
         * @memberof story
         * @interface IStoryHeaderV1
         * @property {google.protobuf.IStringValue|null} [storyId] StoryHeaderV1 storyId
         * @property {google.protobuf.IStringValue|null} [userId] StoryHeaderV1 userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] StoryHeaderV1 tags
         * @property {google.protobuf.IStringValue|null} [seriesId] StoryHeaderV1 seriesId
         * @property {google.protobuf.IInt32Value|null} [seriesIndex] StoryHeaderV1 seriesIndex
         * @property {google.protobuf.IStringValue|null} [title] StoryHeaderV1 title
         * @property {google.protobuf.IStringValue|null} [description] StoryHeaderV1 description
         * @property {google.protobuf.IStringValue|null} [opening] StoryHeaderV1 opening
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] StoryHeaderV1 thumbnailUrl
         * @property {google.protobuf.IInt32Value|null} [length] StoryHeaderV1 length
         * @property {story.StoryStatus|null} [status] StoryHeaderV1 status
         * @property {google.protobuf.IBoolValue|null} [isOfficial] StoryHeaderV1 isOfficial
         * @property {google.protobuf.ITimestamp|null} [createdAt] StoryHeaderV1 createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StoryHeaderV1 updatedAt
         * @property {google.protobuf.ITimestamp|null} [publishedAt] StoryHeaderV1 publishedAt
         */

        /**
         * Constructs a new StoryHeaderV1.
         * @memberof story
         * @classdesc Represents a StoryHeaderV1.
         * @implements IStoryHeaderV1
         * @constructor
         * @param {story.IStoryHeaderV1=} [properties] Properties to set
         */
        function StoryHeaderV1(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryHeaderV1 storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.storyId = null;

        /**
         * StoryHeaderV1 userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.userId = null;

        /**
         * StoryHeaderV1 tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.tags = $util.emptyArray;

        /**
         * StoryHeaderV1 seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.seriesId = null;

        /**
         * StoryHeaderV1 seriesIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} seriesIndex
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.seriesIndex = null;

        /**
         * StoryHeaderV1 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.title = null;

        /**
         * StoryHeaderV1 description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.description = null;

        /**
         * StoryHeaderV1 opening.
         * @member {google.protobuf.IStringValue|null|undefined} opening
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.opening = null;

        /**
         * StoryHeaderV1 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.thumbnailUrl = null;

        /**
         * StoryHeaderV1 length.
         * @member {google.protobuf.IInt32Value|null|undefined} length
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.length = null;

        /**
         * StoryHeaderV1 status.
         * @member {story.StoryStatus} status
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.status = 0;

        /**
         * StoryHeaderV1 isOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} isOfficial
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.isOfficial = null;

        /**
         * StoryHeaderV1 createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.createdAt = null;

        /**
         * StoryHeaderV1 updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.updatedAt = null;

        /**
         * StoryHeaderV1 publishedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} publishedAt
         * @memberof story.StoryHeaderV1
         * @instance
         */
        StoryHeaderV1.prototype.publishedAt = null;

        /**
         * Creates a new StoryHeaderV1 instance using the specified properties.
         * @function create
         * @memberof story.StoryHeaderV1
         * @static
         * @param {story.IStoryHeaderV1=} [properties] Properties to set
         * @returns {story.StoryHeaderV1} StoryHeaderV1 instance
         */
        StoryHeaderV1.create = function create(properties) {
            return new StoryHeaderV1(properties);
        };

        /**
         * Encodes the specified StoryHeaderV1 message. Does not implicitly {@link story.StoryHeaderV1.verify|verify} messages.
         * @function encode
         * @memberof story.StoryHeaderV1
         * @static
         * @param {story.IStoryHeaderV1} message StoryHeaderV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryHeaderV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.seriesIndex != null && Object.hasOwnProperty.call(message, "seriesIndex"))
                $root.google.protobuf.Int32Value.encode(message.seriesIndex, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.opening != null && Object.hasOwnProperty.call(message, "opening"))
                $root.google.protobuf.StringValue.encode(message.opening, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                $root.google.protobuf.Int32Value.encode(message.length, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.status);
            if (message.isOfficial != null && Object.hasOwnProperty.call(message, "isOfficial"))
                $root.google.protobuf.BoolValue.encode(message.isOfficial, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.publishedAt != null && Object.hasOwnProperty.call(message, "publishedAt"))
                $root.google.protobuf.Timestamp.encode(message.publishedAt, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryHeaderV1 message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryHeaderV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryHeaderV1} StoryHeaderV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryHeaderV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryHeaderV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.seriesIndex = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.opening = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.length = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.status = reader.int32();
                    break;
                case 12:
                    message.isOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.publishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryHeaderV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryHeaderV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryHeaderV1} StoryHeaderV1
         */
        StoryHeaderV1.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryHeaderV1)
                return object;
            var message = new $root.story.StoryHeaderV1();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".story.StoryHeaderV1.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".story.StoryHeaderV1.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".story.StoryHeaderV1.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".story.StoryHeaderV1.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".story.StoryHeaderV1.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            if (object.seriesIndex != null) {
                if (typeof object.seriesIndex !== "object")
                    throw TypeError(".story.StoryHeaderV1.seriesIndex: object expected");
                message.seriesIndex = $root.google.protobuf.Int32Value.fromObject(object.seriesIndex);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story.StoryHeaderV1.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story.StoryHeaderV1.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.opening != null) {
                if (typeof object.opening !== "object")
                    throw TypeError(".story.StoryHeaderV1.opening: object expected");
                message.opening = $root.google.protobuf.StringValue.fromObject(object.opening);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".story.StoryHeaderV1.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.length != null) {
                if (typeof object.length !== "object")
                    throw TypeError(".story.StoryHeaderV1.length: object expected");
                message.length = $root.google.protobuf.Int32Value.fromObject(object.length);
            }
            switch (object.status) {
            case "STORY_STATUS_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "DRAFT":
            case 1:
                message.status = 1;
                break;
            case "RESERVE":
            case 2:
                message.status = 2;
                break;
            case "PUBLISH":
            case 3:
                message.status = 3;
                break;
            case "REJECT":
            case 4:
                message.status = 4;
                break;
            }
            if (object.isOfficial != null) {
                if (typeof object.isOfficial !== "object")
                    throw TypeError(".story.StoryHeaderV1.isOfficial: object expected");
                message.isOfficial = $root.google.protobuf.BoolValue.fromObject(object.isOfficial);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".story.StoryHeaderV1.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".story.StoryHeaderV1.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.publishedAt != null) {
                if (typeof object.publishedAt !== "object")
                    throw TypeError(".story.StoryHeaderV1.publishedAt: object expected");
                message.publishedAt = $root.google.protobuf.Timestamp.fromObject(object.publishedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryHeaderV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryHeaderV1
         * @static
         * @param {story.StoryHeaderV1} message StoryHeaderV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryHeaderV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.storyId = null;
                object.userId = null;
                object.seriesId = null;
                object.seriesIndex = null;
                object.title = null;
                object.description = null;
                object.opening = null;
                object.thumbnailUrl = null;
                object.length = null;
                object.status = options.enums === String ? "STORY_STATUS_UNKNOWN" : 0;
                object.isOfficial = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.publishedAt = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            if (message.seriesIndex != null && message.hasOwnProperty("seriesIndex"))
                object.seriesIndex = $root.google.protobuf.Int32Value.toObject(message.seriesIndex, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.opening != null && message.hasOwnProperty("opening"))
                object.opening = $root.google.protobuf.StringValue.toObject(message.opening, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.length != null && message.hasOwnProperty("length"))
                object.length = $root.google.protobuf.Int32Value.toObject(message.length, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.story.StoryStatus[message.status] : message.status;
            if (message.isOfficial != null && message.hasOwnProperty("isOfficial"))
                object.isOfficial = $root.google.protobuf.BoolValue.toObject(message.isOfficial, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.publishedAt != null && message.hasOwnProperty("publishedAt"))
                object.publishedAt = $root.google.protobuf.Timestamp.toObject(message.publishedAt, options);
            return object;
        };

        /**
         * Converts this StoryHeaderV1 to JSON.
         * @function toJSON
         * @memberof story.StoryHeaderV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryHeaderV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryHeaderV1
         * @function getTypeUrl
         * @memberof story.StoryHeaderV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryHeaderV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryHeaderV1";
        };

        return StoryHeaderV1;
    })();

    story.SeriesStoryHeaderV1 = (function() {

        /**
         * Properties of a SeriesStoryHeaderV1.
         * @memberof story
         * @interface ISeriesStoryHeaderV1
         * @property {story.IStoryHeaderV1|null} [header] SeriesStoryHeaderV1 header
         * @property {series.ISeriesV1|null} [series] SeriesStoryHeaderV1 series
         */

        /**
         * Constructs a new SeriesStoryHeaderV1.
         * @memberof story
         * @classdesc Represents a SeriesStoryHeaderV1.
         * @implements ISeriesStoryHeaderV1
         * @constructor
         * @param {story.ISeriesStoryHeaderV1=} [properties] Properties to set
         */
        function SeriesStoryHeaderV1(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesStoryHeaderV1 header.
         * @member {story.IStoryHeaderV1|null|undefined} header
         * @memberof story.SeriesStoryHeaderV1
         * @instance
         */
        SeriesStoryHeaderV1.prototype.header = null;

        /**
         * SeriesStoryHeaderV1 series.
         * @member {series.ISeriesV1|null|undefined} series
         * @memberof story.SeriesStoryHeaderV1
         * @instance
         */
        SeriesStoryHeaderV1.prototype.series = null;

        /**
         * Creates a new SeriesStoryHeaderV1 instance using the specified properties.
         * @function create
         * @memberof story.SeriesStoryHeaderV1
         * @static
         * @param {story.ISeriesStoryHeaderV1=} [properties] Properties to set
         * @returns {story.SeriesStoryHeaderV1} SeriesStoryHeaderV1 instance
         */
        SeriesStoryHeaderV1.create = function create(properties) {
            return new SeriesStoryHeaderV1(properties);
        };

        /**
         * Encodes the specified SeriesStoryHeaderV1 message. Does not implicitly {@link story.SeriesStoryHeaderV1.verify|verify} messages.
         * @function encode
         * @memberof story.SeriesStoryHeaderV1
         * @static
         * @param {story.ISeriesStoryHeaderV1} message SeriesStoryHeaderV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesStoryHeaderV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.story.StoryHeaderV1.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.series.SeriesV1.encode(message.series, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesStoryHeaderV1 message from the specified reader or buffer.
         * @function decode
         * @memberof story.SeriesStoryHeaderV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.SeriesStoryHeaderV1} SeriesStoryHeaderV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesStoryHeaderV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.SeriesStoryHeaderV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.header = $root.story.StoryHeaderV1.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.series = $root.series.SeriesV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesStoryHeaderV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.SeriesStoryHeaderV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.SeriesStoryHeaderV1} SeriesStoryHeaderV1
         */
        SeriesStoryHeaderV1.fromObject = function fromObject(object) {
            if (object instanceof $root.story.SeriesStoryHeaderV1)
                return object;
            var message = new $root.story.SeriesStoryHeaderV1();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".story.SeriesStoryHeaderV1.header: object expected");
                message.header = $root.story.StoryHeaderV1.fromObject(object.header);
            }
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".story.SeriesStoryHeaderV1.series: object expected");
                message.series = $root.series.SeriesV1.fromObject(object.series);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesStoryHeaderV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.SeriesStoryHeaderV1
         * @static
         * @param {story.SeriesStoryHeaderV1} message SeriesStoryHeaderV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesStoryHeaderV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.header = null;
                object.series = null;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.story.StoryHeaderV1.toObject(message.header, options);
            if (message.series != null && message.hasOwnProperty("series"))
                object.series = $root.series.SeriesV1.toObject(message.series, options);
            return object;
        };

        /**
         * Converts this SeriesStoryHeaderV1 to JSON.
         * @function toJSON
         * @memberof story.SeriesStoryHeaderV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesStoryHeaderV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesStoryHeaderV1
         * @function getTypeUrl
         * @memberof story.SeriesStoryHeaderV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesStoryHeaderV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.SeriesStoryHeaderV1";
        };

        return SeriesStoryHeaderV1;
    })();

    story.StoryV1 = (function() {

        /**
         * Properties of a StoryV1.
         * @memberof story
         * @interface IStoryV1
         * @property {story.IStoryHeaderV1|null} [header] StoryV1 header
         * @property {google.protobuf.IStringValue|null} [body] StoryV1 body
         */

        /**
         * Constructs a new StoryV1.
         * @memberof story
         * @classdesc Represents a StoryV1.
         * @implements IStoryV1
         * @constructor
         * @param {story.IStoryV1=} [properties] Properties to set
         */
        function StoryV1(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryV1 header.
         * @member {story.IStoryHeaderV1|null|undefined} header
         * @memberof story.StoryV1
         * @instance
         */
        StoryV1.prototype.header = null;

        /**
         * StoryV1 body.
         * @member {google.protobuf.IStringValue|null|undefined} body
         * @memberof story.StoryV1
         * @instance
         */
        StoryV1.prototype.body = null;

        /**
         * Creates a new StoryV1 instance using the specified properties.
         * @function create
         * @memberof story.StoryV1
         * @static
         * @param {story.IStoryV1=} [properties] Properties to set
         * @returns {story.StoryV1} StoryV1 instance
         */
        StoryV1.create = function create(properties) {
            return new StoryV1(properties);
        };

        /**
         * Encodes the specified StoryV1 message. Does not implicitly {@link story.StoryV1.verify|verify} messages.
         * @function encode
         * @memberof story.StoryV1
         * @static
         * @param {story.IStoryV1} message StoryV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.story.StoryHeaderV1.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                $root.google.protobuf.StringValue.encode(message.body, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryV1 message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryV1} StoryV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.header = $root.story.StoryHeaderV1.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.body = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryV1} StoryV1
         */
        StoryV1.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryV1)
                return object;
            var message = new $root.story.StoryV1();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".story.StoryV1.header: object expected");
                message.header = $root.story.StoryHeaderV1.fromObject(object.header);
            }
            if (object.body != null) {
                if (typeof object.body !== "object")
                    throw TypeError(".story.StoryV1.body: object expected");
                message.body = $root.google.protobuf.StringValue.fromObject(object.body);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryV1
         * @static
         * @param {story.StoryV1} message StoryV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.header = null;
                object.body = null;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.story.StoryHeaderV1.toObject(message.header, options);
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = $root.google.protobuf.StringValue.toObject(message.body, options);
            return object;
        };

        /**
         * Converts this StoryV1 to JSON.
         * @function toJSON
         * @memberof story.StoryV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryV1
         * @function getTypeUrl
         * @memberof story.StoryV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryV1";
        };

        return StoryV1;
    })();

    /**
     * StoryViolationIssue enum.
     * @name story.StoryViolationIssue
     * @enum {number}
     * @property {number} STORY_VIOLATION_ISSUE_UNKNOWN=0 STORY_VIOLATION_ISSUE_UNKNOWN value
     * @property {number} COPYRIGHT=1 COPYRIGHT value
     * @property {number} SLANDER=2 SLANDER value
     * @property {number} EXCESSIVE=3 EXCESSIVE value
     * @property {number} PORTRAIT=4 PORTRAIT value
     */
    story.StoryViolationIssue = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_VIOLATION_ISSUE_UNKNOWN"] = 0;
        values[valuesById[1] = "COPYRIGHT"] = 1;
        values[valuesById[2] = "SLANDER"] = 2;
        values[valuesById[3] = "EXCESSIVE"] = 3;
        values[valuesById[4] = "PORTRAIT"] = 4;
        return values;
    })();

    story.StoryReportViolationRequestV2 = (function() {

        /**
         * Properties of a StoryReportViolationRequestV2.
         * @memberof story
         * @interface IStoryReportViolationRequestV2
         * @property {google.protobuf.IStringValue|null} [storyId] StoryReportViolationRequestV2 storyId
         * @property {types.ViolationType|null} [type] StoryReportViolationRequestV2 type
         * @property {google.protobuf.IStringValue|null} [message] StoryReportViolationRequestV2 message
         */

        /**
         * Constructs a new StoryReportViolationRequestV2.
         * @memberof story
         * @classdesc Represents a StoryReportViolationRequestV2.
         * @implements IStoryReportViolationRequestV2
         * @constructor
         * @param {story.IStoryReportViolationRequestV2=} [properties] Properties to set
         */
        function StoryReportViolationRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReportViolationRequestV2 storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof story.StoryReportViolationRequestV2
         * @instance
         */
        StoryReportViolationRequestV2.prototype.storyId = null;

        /**
         * StoryReportViolationRequestV2 type.
         * @member {types.ViolationType} type
         * @memberof story.StoryReportViolationRequestV2
         * @instance
         */
        StoryReportViolationRequestV2.prototype.type = 0;

        /**
         * StoryReportViolationRequestV2 message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof story.StoryReportViolationRequestV2
         * @instance
         */
        StoryReportViolationRequestV2.prototype.message = null;

        /**
         * Creates a new StoryReportViolationRequestV2 instance using the specified properties.
         * @function create
         * @memberof story.StoryReportViolationRequestV2
         * @static
         * @param {story.IStoryReportViolationRequestV2=} [properties] Properties to set
         * @returns {story.StoryReportViolationRequestV2} StoryReportViolationRequestV2 instance
         */
        StoryReportViolationRequestV2.create = function create(properties) {
            return new StoryReportViolationRequestV2(properties);
        };

        /**
         * Encodes the specified StoryReportViolationRequestV2 message. Does not implicitly {@link story.StoryReportViolationRequestV2.verify|verify} messages.
         * @function encode
         * @memberof story.StoryReportViolationRequestV2
         * @static
         * @param {story.IStoryReportViolationRequestV2} message StoryReportViolationRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReportViolationRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReportViolationRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryReportViolationRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryReportViolationRequestV2} StoryReportViolationRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReportViolationRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryReportViolationRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReportViolationRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryReportViolationRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryReportViolationRequestV2} StoryReportViolationRequestV2
         */
        StoryReportViolationRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryReportViolationRequestV2)
                return object;
            var message = new $root.story.StoryReportViolationRequestV2();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".story.StoryReportViolationRequestV2.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            switch (object.type) {
            case "VIOLATION_TYPE_UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "VIOLATION_TYPE_VIOLENCE":
            case 1:
                message.type = 1;
                break;
            case "VIOLATION_TYPE_HARASSMENT":
            case 2:
                message.type = 2;
                break;
            case "VIOLATION_TYPE_PRIVACY":
            case 3:
                message.type = 3;
                break;
            case "VIOLATION_TYPE_RIGHTS":
            case 4:
                message.type = 4;
                break;
            case "VIOLATION_TYPE_ADULT":
            case 5:
                message.type = 5;
                break;
            case "VIOLATION_TYPE_SPAM":
            case 6:
                message.type = 6;
                break;
            case "VIOLATION_TYPE_OTHER":
            case 7:
                message.type = 7;
                break;
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".story.StoryReportViolationRequestV2.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReportViolationRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryReportViolationRequestV2
         * @static
         * @param {story.StoryReportViolationRequestV2} message StoryReportViolationRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReportViolationRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.type = options.enums === String ? "VIOLATION_TYPE_UNKNOWN" : 0;
                object.message = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.types.ViolationType[message.type] : message.type;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this StoryReportViolationRequestV2 to JSON.
         * @function toJSON
         * @memberof story.StoryReportViolationRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReportViolationRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReportViolationRequestV2
         * @function getTypeUrl
         * @memberof story.StoryReportViolationRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReportViolationRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryReportViolationRequestV2";
        };

        return StoryReportViolationRequestV2;
    })();

    story.StoryReportViolationRequest = (function() {

        /**
         * Properties of a StoryReportViolationRequest.
         * @memberof story
         * @interface IStoryReportViolationRequest
         * @property {google.protobuf.IStringValue|null} [storyId] StoryReportViolationRequest storyId
         * @property {story.StoryViolationIssue|null} [issue] StoryReportViolationRequest issue
         * @property {google.protobuf.IStringValue|null} [message] StoryReportViolationRequest message
         */

        /**
         * Constructs a new StoryReportViolationRequest.
         * @memberof story
         * @classdesc Represents a StoryReportViolationRequest.
         * @implements IStoryReportViolationRequest
         * @constructor
         * @param {story.IStoryReportViolationRequest=} [properties] Properties to set
         */
        function StoryReportViolationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReportViolationRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof story.StoryReportViolationRequest
         * @instance
         */
        StoryReportViolationRequest.prototype.storyId = null;

        /**
         * StoryReportViolationRequest issue.
         * @member {story.StoryViolationIssue} issue
         * @memberof story.StoryReportViolationRequest
         * @instance
         */
        StoryReportViolationRequest.prototype.issue = 0;

        /**
         * StoryReportViolationRequest message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof story.StoryReportViolationRequest
         * @instance
         */
        StoryReportViolationRequest.prototype.message = null;

        /**
         * Creates a new StoryReportViolationRequest instance using the specified properties.
         * @function create
         * @memberof story.StoryReportViolationRequest
         * @static
         * @param {story.IStoryReportViolationRequest=} [properties] Properties to set
         * @returns {story.StoryReportViolationRequest} StoryReportViolationRequest instance
         */
        StoryReportViolationRequest.create = function create(properties) {
            return new StoryReportViolationRequest(properties);
        };

        /**
         * Encodes the specified StoryReportViolationRequest message. Does not implicitly {@link story.StoryReportViolationRequest.verify|verify} messages.
         * @function encode
         * @memberof story.StoryReportViolationRequest
         * @static
         * @param {story.IStoryReportViolationRequest} message StoryReportViolationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReportViolationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.issue != null && Object.hasOwnProperty.call(message, "issue"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.issue);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReportViolationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof story.StoryReportViolationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story.StoryReportViolationRequest} StoryReportViolationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReportViolationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story.StoryReportViolationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.issue = reader.int32();
                    break;
                case 3:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReportViolationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story.StoryReportViolationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story.StoryReportViolationRequest} StoryReportViolationRequest
         */
        StoryReportViolationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.story.StoryReportViolationRequest)
                return object;
            var message = new $root.story.StoryReportViolationRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".story.StoryReportViolationRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            switch (object.issue) {
            case "STORY_VIOLATION_ISSUE_UNKNOWN":
            case 0:
                message.issue = 0;
                break;
            case "COPYRIGHT":
            case 1:
                message.issue = 1;
                break;
            case "SLANDER":
            case 2:
                message.issue = 2;
                break;
            case "EXCESSIVE":
            case 3:
                message.issue = 3;
                break;
            case "PORTRAIT":
            case 4:
                message.issue = 4;
                break;
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".story.StoryReportViolationRequest.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReportViolationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story.StoryReportViolationRequest
         * @static
         * @param {story.StoryReportViolationRequest} message StoryReportViolationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReportViolationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.issue = options.enums === String ? "STORY_VIOLATION_ISSUE_UNKNOWN" : 0;
                object.message = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.issue != null && message.hasOwnProperty("issue"))
                object.issue = options.enums === String ? $root.story.StoryViolationIssue[message.issue] : message.issue;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this StoryReportViolationRequest to JSON.
         * @function toJSON
         * @memberof story.StoryReportViolationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReportViolationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReportViolationRequest
         * @function getTypeUrl
         * @memberof story.StoryReportViolationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReportViolationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story.StoryReportViolationRequest";
        };

        return StoryReportViolationRequest;
    })();

    return story;
})();

$root.series = (function() {

    /**
     * Namespace series.
     * @exports series
     * @namespace
     */
    var series = {};

    series.SeriesService = (function() {

        /**
         * Constructs a new SeriesService service.
         * @memberof series
         * @classdesc Represents a SeriesService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SeriesService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SeriesService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SeriesService;

        /**
         * Creates new SeriesService service using the specified rpc implementation.
         * @function create
         * @memberof series.SeriesService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {SeriesService} RPC service. Useful where requests and/or responses are streamed.
         */
        SeriesService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link series.SeriesService#getV2}.
         * @memberof series.SeriesService
         * @typedef GetV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {series.Series} [response] Series
         */

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequest} request SeriesIdRequest message or plain object
         * @param {series.SeriesService.GetV2Callback} callback Node-style callback called with the error, if any, and Series
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype.getV2 = function getV2(request, callback) {
            return this.rpcCall(getV2, $root.series.SeriesIdRequest, $root.series.Series, request, callback);
        }, "name", { value: "GetV2" });

        /**
         * Calls GetV2.
         * @function getV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequest} request SeriesIdRequest message or plain object
         * @returns {Promise<series.Series>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link series.SeriesService#createV2}.
         * @memberof series.SeriesService
         * @typedef CreateV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {series.Series} [response] Series
         */

        /**
         * Calls CreateV2.
         * @function createV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeries} request Series message or plain object
         * @param {series.SeriesService.CreateV2Callback} callback Node-style callback called with the error, if any, and Series
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype.createV2 = function createV2(request, callback) {
            return this.rpcCall(createV2, $root.series.Series, $root.series.Series, request, callback);
        }, "name", { value: "CreateV2" });

        /**
         * Calls CreateV2.
         * @function createV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeries} request Series message or plain object
         * @returns {Promise<series.Series>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link series.SeriesService#updateV2}.
         * @memberof series.SeriesService
         * @typedef UpdateV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {series.Series} [response] Series
         */

        /**
         * Calls UpdateV2.
         * @function updateV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeries} request Series message or plain object
         * @param {series.SeriesService.UpdateV2Callback} callback Node-style callback called with the error, if any, and Series
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype.updateV2 = function updateV2(request, callback) {
            return this.rpcCall(updateV2, $root.series.Series, $root.series.Series, request, callback);
        }, "name", { value: "UpdateV2" });

        /**
         * Calls UpdateV2.
         * @function updateV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeries} request Series message or plain object
         * @returns {Promise<series.Series>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link series.SeriesService#deleteV2}.
         * @memberof series.SeriesService
         * @typedef DeleteV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteV2.
         * @function deleteV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequest} request SeriesIdRequest message or plain object
         * @param {series.SeriesService.DeleteV2Callback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype.deleteV2 = function deleteV2(request, callback) {
            return this.rpcCall(deleteV2, $root.series.SeriesIdRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteV2" });

        /**
         * Calls DeleteV2.
         * @function deleteV2
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequest} request SeriesIdRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link series.SeriesService#get}.
         * @memberof series.SeriesService
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {series.SeriesResponseV1} [response] SeriesResponseV1
         */

        /**
         * Calls Get.
         * @function get
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequestV1} request SeriesIdRequestV1 message or plain object
         * @param {series.SeriesService.GetCallback} callback Node-style callback called with the error, if any, and SeriesResponseV1
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.series.SeriesIdRequestV1, $root.series.SeriesResponseV1, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequestV1} request SeriesIdRequestV1 message or plain object
         * @returns {Promise<series.SeriesResponseV1>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link series.SeriesService#create}.
         * @memberof series.SeriesService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {series.SeriesResponseV1} [response] SeriesResponseV1
         */

        /**
         * Calls Create.
         * @function create
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesV1} request SeriesV1 message or plain object
         * @param {series.SeriesService.CreateCallback} callback Node-style callback called with the error, if any, and SeriesResponseV1
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.series.SeriesV1, $root.series.SeriesResponseV1, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesV1} request SeriesV1 message or plain object
         * @returns {Promise<series.SeriesResponseV1>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link series.SeriesService#update}.
         * @memberof series.SeriesService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {series.SeriesResponseV1} [response] SeriesResponseV1
         */

        /**
         * Calls Update.
         * @function update
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesV1} request SeriesV1 message or plain object
         * @param {series.SeriesService.UpdateCallback} callback Node-style callback called with the error, if any, and SeriesResponseV1
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.series.SeriesV1, $root.series.SeriesResponseV1, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesV1} request SeriesV1 message or plain object
         * @returns {Promise<series.SeriesResponseV1>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link series.SeriesService#delete_}.
         * @memberof series.SeriesService
         * @typedef DeleteCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls Delete.
         * @function delete
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequestV1} request SeriesIdRequestV1 message or plain object
         * @param {series.SeriesService.DeleteCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SeriesService.prototype["delete"] = function delete_(request, callback) {
            return this.rpcCall(delete_, $root.series.SeriesIdRequestV1, $root.types.Empty, request, callback);
        }, "name", { value: "Delete" });

        /**
         * Calls Delete.
         * @function delete
         * @memberof series.SeriesService
         * @instance
         * @param {series.ISeriesIdRequestV1} request SeriesIdRequestV1 message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        return SeriesService;
    })();

    series.SeriesIdRequest = (function() {

        /**
         * Properties of a SeriesIdRequest.
         * @memberof series
         * @interface ISeriesIdRequest
         * @property {google.protobuf.IStringValue|null} [id] SeriesIdRequest id
         */

        /**
         * Constructs a new SeriesIdRequest.
         * @memberof series
         * @classdesc Represents a SeriesIdRequest.
         * @implements ISeriesIdRequest
         * @constructor
         * @param {series.ISeriesIdRequest=} [properties] Properties to set
         */
        function SeriesIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesIdRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof series.SeriesIdRequest
         * @instance
         */
        SeriesIdRequest.prototype.id = null;

        /**
         * Creates a new SeriesIdRequest instance using the specified properties.
         * @function create
         * @memberof series.SeriesIdRequest
         * @static
         * @param {series.ISeriesIdRequest=} [properties] Properties to set
         * @returns {series.SeriesIdRequest} SeriesIdRequest instance
         */
        SeriesIdRequest.create = function create(properties) {
            return new SeriesIdRequest(properties);
        };

        /**
         * Encodes the specified SeriesIdRequest message. Does not implicitly {@link series.SeriesIdRequest.verify|verify} messages.
         * @function encode
         * @memberof series.SeriesIdRequest
         * @static
         * @param {series.ISeriesIdRequest} message SeriesIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof series.SeriesIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {series.SeriesIdRequest} SeriesIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.series.SeriesIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesIdRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof series.SeriesIdRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {series.SeriesIdRequest} SeriesIdRequest
         */
        SeriesIdRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.series.SeriesIdRequest)
                return object;
            var message = new $root.series.SeriesIdRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".series.SeriesIdRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesIdRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof series.SeriesIdRequest
         * @static
         * @param {series.SeriesIdRequest} message SeriesIdRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesIdRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this SeriesIdRequest to JSON.
         * @function toJSON
         * @memberof series.SeriesIdRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesIdRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesIdRequest
         * @function getTypeUrl
         * @memberof series.SeriesIdRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesIdRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/series.SeriesIdRequest";
        };

        return SeriesIdRequest;
    })();

    series.Series = (function() {

        /**
         * Properties of a Series.
         * @memberof series
         * @interface ISeries
         * @property {google.protobuf.IStringValue|null} [id] Series id
         * @property {google.protobuf.IStringValue|null} [userId] Series userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] Series tags
         * @property {google.protobuf.IStringValue|null} [title] Series title
         * @property {google.protobuf.IStringValue|null} [description] Series description
         * @property {resource.IImage|null} [thumbnail] Series thumbnail
         * @property {google.protobuf.IBoolValue|null} [isOfficial] Series isOfficial
         * @property {google.protobuf.IBoolValue|null} [isCompleted] Series isCompleted
         * @property {google.protobuf.ITimestamp|null} [nextPublishAt] Series nextPublishAt
         * @property {google.protobuf.ITimestamp|null} [createdAt] Series createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] Series updatedAt
         * @property {types.SharedWithStatus|null} [sharedWithStatus] Series sharedWithStatus
         * @property {google.protobuf.IBoolValue|null} [hasVipOnlyStory] Series hasVipOnlyStory
         * @property {google.protobuf.IBoolValue|null} [isPawRequired] Series isPawRequired
         */

        /**
         * Constructs a new Series.
         * @memberof series
         * @classdesc Represents a Series.
         * @implements ISeries
         * @constructor
         * @param {series.ISeries=} [properties] Properties to set
         */
        function Series(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Series id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof series.Series
         * @instance
         */
        Series.prototype.id = null;

        /**
         * Series userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof series.Series
         * @instance
         */
        Series.prototype.userId = null;

        /**
         * Series tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof series.Series
         * @instance
         */
        Series.prototype.tags = $util.emptyArray;

        /**
         * Series title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof series.Series
         * @instance
         */
        Series.prototype.title = null;

        /**
         * Series description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof series.Series
         * @instance
         */
        Series.prototype.description = null;

        /**
         * Series thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof series.Series
         * @instance
         */
        Series.prototype.thumbnail = null;

        /**
         * Series isOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} isOfficial
         * @memberof series.Series
         * @instance
         */
        Series.prototype.isOfficial = null;

        /**
         * Series isCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} isCompleted
         * @memberof series.Series
         * @instance
         */
        Series.prototype.isCompleted = null;

        /**
         * Series nextPublishAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextPublishAt
         * @memberof series.Series
         * @instance
         */
        Series.prototype.nextPublishAt = null;

        /**
         * Series createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof series.Series
         * @instance
         */
        Series.prototype.createdAt = null;

        /**
         * Series updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof series.Series
         * @instance
         */
        Series.prototype.updatedAt = null;

        /**
         * Series sharedWithStatus.
         * @member {types.SharedWithStatus} sharedWithStatus
         * @memberof series.Series
         * @instance
         */
        Series.prototype.sharedWithStatus = 0;

        /**
         * Series hasVipOnlyStory.
         * @member {google.protobuf.IBoolValue|null|undefined} hasVipOnlyStory
         * @memberof series.Series
         * @instance
         */
        Series.prototype.hasVipOnlyStory = null;

        /**
         * Series isPawRequired.
         * @member {google.protobuf.IBoolValue|null|undefined} isPawRequired
         * @memberof series.Series
         * @instance
         */
        Series.prototype.isPawRequired = null;

        /**
         * Creates a new Series instance using the specified properties.
         * @function create
         * @memberof series.Series
         * @static
         * @param {series.ISeries=} [properties] Properties to set
         * @returns {series.Series} Series instance
         */
        Series.create = function create(properties) {
            return new Series(properties);
        };

        /**
         * Encodes the specified Series message. Does not implicitly {@link series.Series.verify|verify} messages.
         * @function encode
         * @memberof series.Series
         * @static
         * @param {series.ISeries} message Series message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Series.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isOfficial != null && Object.hasOwnProperty.call(message, "isOfficial"))
                $root.google.protobuf.BoolValue.encode(message.isOfficial, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.isCompleted != null && Object.hasOwnProperty.call(message, "isCompleted"))
                $root.google.protobuf.BoolValue.encode(message.isCompleted, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.nextPublishAt != null && Object.hasOwnProperty.call(message, "nextPublishAt"))
                $root.google.protobuf.Timestamp.encode(message.nextPublishAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.sharedWithStatus != null && Object.hasOwnProperty.call(message, "sharedWithStatus"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.sharedWithStatus);
            if (message.hasVipOnlyStory != null && Object.hasOwnProperty.call(message, "hasVipOnlyStory"))
                $root.google.protobuf.BoolValue.encode(message.hasVipOnlyStory, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.isPawRequired != null && Object.hasOwnProperty.call(message, "isPawRequired"))
                $root.google.protobuf.BoolValue.encode(message.isPawRequired, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Series message from the specified reader or buffer.
         * @function decode
         * @memberof series.Series
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {series.Series} Series
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Series.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.series.Series();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.isCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.nextPublishAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.sharedWithStatus = reader.int32();
                    break;
                case 13:
                    message.hasVipOnlyStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.isPawRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Series message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof series.Series
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {series.Series} Series
         */
        Series.fromObject = function fromObject(object) {
            if (object instanceof $root.series.Series)
                return object;
            var message = new $root.series.Series();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".series.Series.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".series.Series.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".series.Series.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".series.Series.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".series.Series.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".series.Series.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".series.Series.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.isOfficial != null) {
                if (typeof object.isOfficial !== "object")
                    throw TypeError(".series.Series.isOfficial: object expected");
                message.isOfficial = $root.google.protobuf.BoolValue.fromObject(object.isOfficial);
            }
            if (object.isCompleted != null) {
                if (typeof object.isCompleted !== "object")
                    throw TypeError(".series.Series.isCompleted: object expected");
                message.isCompleted = $root.google.protobuf.BoolValue.fromObject(object.isCompleted);
            }
            if (object.nextPublishAt != null) {
                if (typeof object.nextPublishAt !== "object")
                    throw TypeError(".series.Series.nextPublishAt: object expected");
                message.nextPublishAt = $root.google.protobuf.Timestamp.fromObject(object.nextPublishAt);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".series.Series.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".series.Series.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            switch (object.sharedWithStatus) {
            case "SHARED_WITH_STATUS_UNKNOWN":
            case 0:
                message.sharedWithStatus = 0;
                break;
            case "SHARED_WITH_NOBODY":
            case 1:
                message.sharedWithStatus = 1;
                break;
            case "SHARED_WITH_DIRECT_LINK":
            case 2:
                message.sharedWithStatus = 2;
                break;
            case "SHARED_WITH_PUBLIC":
            case 3:
                message.sharedWithStatus = 3;
                break;
            case "SHARED_WITH_FOLLOWER":
            case 4:
                message.sharedWithStatus = 4;
                break;
            case "SHARED_WITH_MUTUAL_FOLLOWER":
            case 5:
                message.sharedWithStatus = 5;
                break;
            }
            if (object.hasVipOnlyStory != null) {
                if (typeof object.hasVipOnlyStory !== "object")
                    throw TypeError(".series.Series.hasVipOnlyStory: object expected");
                message.hasVipOnlyStory = $root.google.protobuf.BoolValue.fromObject(object.hasVipOnlyStory);
            }
            if (object.isPawRequired != null) {
                if (typeof object.isPawRequired !== "object")
                    throw TypeError(".series.Series.isPawRequired: object expected");
                message.isPawRequired = $root.google.protobuf.BoolValue.fromObject(object.isPawRequired);
            }
            return message;
        };

        /**
         * Creates a plain object from a Series message. Also converts values to other types if specified.
         * @function toObject
         * @memberof series.Series
         * @static
         * @param {series.Series} message Series
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Series.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.id = null;
                object.userId = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.isOfficial = null;
                object.isCompleted = null;
                object.nextPublishAt = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.sharedWithStatus = options.enums === String ? "SHARED_WITH_STATUS_UNKNOWN" : 0;
                object.hasVipOnlyStory = null;
                object.isPawRequired = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.isOfficial != null && message.hasOwnProperty("isOfficial"))
                object.isOfficial = $root.google.protobuf.BoolValue.toObject(message.isOfficial, options);
            if (message.isCompleted != null && message.hasOwnProperty("isCompleted"))
                object.isCompleted = $root.google.protobuf.BoolValue.toObject(message.isCompleted, options);
            if (message.nextPublishAt != null && message.hasOwnProperty("nextPublishAt"))
                object.nextPublishAt = $root.google.protobuf.Timestamp.toObject(message.nextPublishAt, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.sharedWithStatus != null && message.hasOwnProperty("sharedWithStatus"))
                object.sharedWithStatus = options.enums === String ? $root.types.SharedWithStatus[message.sharedWithStatus] : message.sharedWithStatus;
            if (message.hasVipOnlyStory != null && message.hasOwnProperty("hasVipOnlyStory"))
                object.hasVipOnlyStory = $root.google.protobuf.BoolValue.toObject(message.hasVipOnlyStory, options);
            if (message.isPawRequired != null && message.hasOwnProperty("isPawRequired"))
                object.isPawRequired = $root.google.protobuf.BoolValue.toObject(message.isPawRequired, options);
            return object;
        };

        /**
         * Converts this Series to JSON.
         * @function toJSON
         * @memberof series.Series
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Series.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Series
         * @function getTypeUrl
         * @memberof series.Series
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Series.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/series.Series";
        };

        return Series;
    })();

    series.SeriesV1 = (function() {

        /**
         * Properties of a SeriesV1.
         * @memberof series
         * @interface ISeriesV1
         * @property {google.protobuf.IStringValue|null} [seriesId] SeriesV1 seriesId
         * @property {google.protobuf.IStringValue|null} [userId] SeriesV1 userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] SeriesV1 tags
         * @property {google.protobuf.IStringValue|null} [title] SeriesV1 title
         * @property {google.protobuf.IStringValue|null} [description] SeriesV1 description
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] SeriesV1 thumbnailUrl
         * @property {google.protobuf.IBoolValue|null} [isOfficial] SeriesV1 isOfficial
         * @property {google.protobuf.IBoolValue|null} [isCompleted] SeriesV1 isCompleted
         * @property {google.protobuf.ITimestamp|null} [nextPublishAt] SeriesV1 nextPublishAt
         * @property {google.protobuf.ITimestamp|null} [createdAt] SeriesV1 createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] SeriesV1 updatedAt
         * @property {types.SharedWithStatus|null} [sharedWithStatus] SeriesV1 sharedWithStatus
         */

        /**
         * Constructs a new SeriesV1.
         * @memberof series
         * @classdesc Represents a SeriesV1.
         * @implements ISeriesV1
         * @constructor
         * @param {series.ISeriesV1=} [properties] Properties to set
         */
        function SeriesV1(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesV1 seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.seriesId = null;

        /**
         * SeriesV1 userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.userId = null;

        /**
         * SeriesV1 tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.tags = $util.emptyArray;

        /**
         * SeriesV1 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.title = null;

        /**
         * SeriesV1 description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.description = null;

        /**
         * SeriesV1 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.thumbnailUrl = null;

        /**
         * SeriesV1 isOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} isOfficial
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.isOfficial = null;

        /**
         * SeriesV1 isCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} isCompleted
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.isCompleted = null;

        /**
         * SeriesV1 nextPublishAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextPublishAt
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.nextPublishAt = null;

        /**
         * SeriesV1 createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.createdAt = null;

        /**
         * SeriesV1 updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.updatedAt = null;

        /**
         * SeriesV1 sharedWithStatus.
         * @member {types.SharedWithStatus} sharedWithStatus
         * @memberof series.SeriesV1
         * @instance
         */
        SeriesV1.prototype.sharedWithStatus = 0;

        /**
         * Creates a new SeriesV1 instance using the specified properties.
         * @function create
         * @memberof series.SeriesV1
         * @static
         * @param {series.ISeriesV1=} [properties] Properties to set
         * @returns {series.SeriesV1} SeriesV1 instance
         */
        SeriesV1.create = function create(properties) {
            return new SeriesV1(properties);
        };

        /**
         * Encodes the specified SeriesV1 message. Does not implicitly {@link series.SeriesV1.verify|verify} messages.
         * @function encode
         * @memberof series.SeriesV1
         * @static
         * @param {series.ISeriesV1} message SeriesV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isOfficial != null && Object.hasOwnProperty.call(message, "isOfficial"))
                $root.google.protobuf.BoolValue.encode(message.isOfficial, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.isCompleted != null && Object.hasOwnProperty.call(message, "isCompleted"))
                $root.google.protobuf.BoolValue.encode(message.isCompleted, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.nextPublishAt != null && Object.hasOwnProperty.call(message, "nextPublishAt"))
                $root.google.protobuf.Timestamp.encode(message.nextPublishAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.sharedWithStatus != null && Object.hasOwnProperty.call(message, "sharedWithStatus"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.sharedWithStatus);
            return writer;
        };

        /**
         * Decodes a SeriesV1 message from the specified reader or buffer.
         * @function decode
         * @memberof series.SeriesV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {series.SeriesV1} SeriesV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.series.SeriesV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.isCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.nextPublishAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.sharedWithStatus = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof series.SeriesV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {series.SeriesV1} SeriesV1
         */
        SeriesV1.fromObject = function fromObject(object) {
            if (object instanceof $root.series.SeriesV1)
                return object;
            var message = new $root.series.SeriesV1();
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".series.SeriesV1.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".series.SeriesV1.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".series.SeriesV1.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".series.SeriesV1.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".series.SeriesV1.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".series.SeriesV1.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".series.SeriesV1.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.isOfficial != null) {
                if (typeof object.isOfficial !== "object")
                    throw TypeError(".series.SeriesV1.isOfficial: object expected");
                message.isOfficial = $root.google.protobuf.BoolValue.fromObject(object.isOfficial);
            }
            if (object.isCompleted != null) {
                if (typeof object.isCompleted !== "object")
                    throw TypeError(".series.SeriesV1.isCompleted: object expected");
                message.isCompleted = $root.google.protobuf.BoolValue.fromObject(object.isCompleted);
            }
            if (object.nextPublishAt != null) {
                if (typeof object.nextPublishAt !== "object")
                    throw TypeError(".series.SeriesV1.nextPublishAt: object expected");
                message.nextPublishAt = $root.google.protobuf.Timestamp.fromObject(object.nextPublishAt);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".series.SeriesV1.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".series.SeriesV1.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            switch (object.sharedWithStatus) {
            case "SHARED_WITH_STATUS_UNKNOWN":
            case 0:
                message.sharedWithStatus = 0;
                break;
            case "SHARED_WITH_NOBODY":
            case 1:
                message.sharedWithStatus = 1;
                break;
            case "SHARED_WITH_DIRECT_LINK":
            case 2:
                message.sharedWithStatus = 2;
                break;
            case "SHARED_WITH_PUBLIC":
            case 3:
                message.sharedWithStatus = 3;
                break;
            case "SHARED_WITH_FOLLOWER":
            case 4:
                message.sharedWithStatus = 4;
                break;
            case "SHARED_WITH_MUTUAL_FOLLOWER":
            case 5:
                message.sharedWithStatus = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof series.SeriesV1
         * @static
         * @param {series.SeriesV1} message SeriesV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.seriesId = null;
                object.userId = null;
                object.title = null;
                object.description = null;
                object.thumbnailUrl = null;
                object.isOfficial = null;
                object.isCompleted = null;
                object.nextPublishAt = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.sharedWithStatus = options.enums === String ? "SHARED_WITH_STATUS_UNKNOWN" : 0;
            }
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.isOfficial != null && message.hasOwnProperty("isOfficial"))
                object.isOfficial = $root.google.protobuf.BoolValue.toObject(message.isOfficial, options);
            if (message.isCompleted != null && message.hasOwnProperty("isCompleted"))
                object.isCompleted = $root.google.protobuf.BoolValue.toObject(message.isCompleted, options);
            if (message.nextPublishAt != null && message.hasOwnProperty("nextPublishAt"))
                object.nextPublishAt = $root.google.protobuf.Timestamp.toObject(message.nextPublishAt, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.sharedWithStatus != null && message.hasOwnProperty("sharedWithStatus"))
                object.sharedWithStatus = options.enums === String ? $root.types.SharedWithStatus[message.sharedWithStatus] : message.sharedWithStatus;
            return object;
        };

        /**
         * Converts this SeriesV1 to JSON.
         * @function toJSON
         * @memberof series.SeriesV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesV1
         * @function getTypeUrl
         * @memberof series.SeriesV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/series.SeriesV1";
        };

        return SeriesV1;
    })();

    series.SeriesIdRequestV1 = (function() {

        /**
         * Properties of a SeriesIdRequestV1.
         * @memberof series
         * @interface ISeriesIdRequestV1
         * @property {google.protobuf.IStringValue|null} [seriesId] SeriesIdRequestV1 seriesId
         */

        /**
         * Constructs a new SeriesIdRequestV1.
         * @memberof series
         * @classdesc Represents a SeriesIdRequestV1.
         * @implements ISeriesIdRequestV1
         * @constructor
         * @param {series.ISeriesIdRequestV1=} [properties] Properties to set
         */
        function SeriesIdRequestV1(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesIdRequestV1 seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof series.SeriesIdRequestV1
         * @instance
         */
        SeriesIdRequestV1.prototype.seriesId = null;

        /**
         * Creates a new SeriesIdRequestV1 instance using the specified properties.
         * @function create
         * @memberof series.SeriesIdRequestV1
         * @static
         * @param {series.ISeriesIdRequestV1=} [properties] Properties to set
         * @returns {series.SeriesIdRequestV1} SeriesIdRequestV1 instance
         */
        SeriesIdRequestV1.create = function create(properties) {
            return new SeriesIdRequestV1(properties);
        };

        /**
         * Encodes the specified SeriesIdRequestV1 message. Does not implicitly {@link series.SeriesIdRequestV1.verify|verify} messages.
         * @function encode
         * @memberof series.SeriesIdRequestV1
         * @static
         * @param {series.ISeriesIdRequestV1} message SeriesIdRequestV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesIdRequestV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesIdRequestV1 message from the specified reader or buffer.
         * @function decode
         * @memberof series.SeriesIdRequestV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {series.SeriesIdRequestV1} SeriesIdRequestV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesIdRequestV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.series.SeriesIdRequestV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesIdRequestV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof series.SeriesIdRequestV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {series.SeriesIdRequestV1} SeriesIdRequestV1
         */
        SeriesIdRequestV1.fromObject = function fromObject(object) {
            if (object instanceof $root.series.SeriesIdRequestV1)
                return object;
            var message = new $root.series.SeriesIdRequestV1();
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".series.SeriesIdRequestV1.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesIdRequestV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof series.SeriesIdRequestV1
         * @static
         * @param {series.SeriesIdRequestV1} message SeriesIdRequestV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesIdRequestV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seriesId = null;
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            return object;
        };

        /**
         * Converts this SeriesIdRequestV1 to JSON.
         * @function toJSON
         * @memberof series.SeriesIdRequestV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesIdRequestV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesIdRequestV1
         * @function getTypeUrl
         * @memberof series.SeriesIdRequestV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesIdRequestV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/series.SeriesIdRequestV1";
        };

        return SeriesIdRequestV1;
    })();

    series.SeriesResponseV1 = (function() {

        /**
         * Properties of a SeriesResponseV1.
         * @memberof series
         * @interface ISeriesResponseV1
         * @property {series.ISeriesV1|null} [series] SeriesResponseV1 series
         */

        /**
         * Constructs a new SeriesResponseV1.
         * @memberof series
         * @classdesc Represents a SeriesResponseV1.
         * @implements ISeriesResponseV1
         * @constructor
         * @param {series.ISeriesResponseV1=} [properties] Properties to set
         */
        function SeriesResponseV1(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesResponseV1 series.
         * @member {series.ISeriesV1|null|undefined} series
         * @memberof series.SeriesResponseV1
         * @instance
         */
        SeriesResponseV1.prototype.series = null;

        /**
         * Creates a new SeriesResponseV1 instance using the specified properties.
         * @function create
         * @memberof series.SeriesResponseV1
         * @static
         * @param {series.ISeriesResponseV1=} [properties] Properties to set
         * @returns {series.SeriesResponseV1} SeriesResponseV1 instance
         */
        SeriesResponseV1.create = function create(properties) {
            return new SeriesResponseV1(properties);
        };

        /**
         * Encodes the specified SeriesResponseV1 message. Does not implicitly {@link series.SeriesResponseV1.verify|verify} messages.
         * @function encode
         * @memberof series.SeriesResponseV1
         * @static
         * @param {series.ISeriesResponseV1} message SeriesResponseV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesResponseV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.series.SeriesV1.encode(message.series, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesResponseV1 message from the specified reader or buffer.
         * @function decode
         * @memberof series.SeriesResponseV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {series.SeriesResponseV1} SeriesResponseV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesResponseV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.series.SeriesResponseV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.series = $root.series.SeriesV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesResponseV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof series.SeriesResponseV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {series.SeriesResponseV1} SeriesResponseV1
         */
        SeriesResponseV1.fromObject = function fromObject(object) {
            if (object instanceof $root.series.SeriesResponseV1)
                return object;
            var message = new $root.series.SeriesResponseV1();
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".series.SeriesResponseV1.series: object expected");
                message.series = $root.series.SeriesV1.fromObject(object.series);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesResponseV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof series.SeriesResponseV1
         * @static
         * @param {series.SeriesResponseV1} message SeriesResponseV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesResponseV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.series = null;
            if (message.series != null && message.hasOwnProperty("series"))
                object.series = $root.series.SeriesV1.toObject(message.series, options);
            return object;
        };

        /**
         * Converts this SeriesResponseV1 to JSON.
         * @function toJSON
         * @memberof series.SeriesResponseV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesResponseV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesResponseV1
         * @function getTypeUrl
         * @memberof series.SeriesResponseV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesResponseV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/series.SeriesResponseV1";
        };

        return SeriesResponseV1;
    })();

    return series;
})();

$root.tellerscript = (function() {

    /**
     * Namespace tellerscript.
     * @exports tellerscript
     * @namespace
     */
    var tellerscript = {};

    /**
     * FormatVersion enum.
     * @name tellerscript.FormatVersion
     * @enum {number}
     * @property {number} FORMAT_VERSION_UNSPECIFIED=0 FORMAT_VERSION_UNSPECIFIED value
     * @property {number} VERSION_1=1 VERSION_1 value
     */
    tellerscript.FormatVersion = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FORMAT_VERSION_UNSPECIFIED"] = 0;
        values[valuesById[1] = "VERSION_1"] = 1;
        return values;
    })();

    tellerscript.TellerScript = (function() {

        /**
         * Properties of a TellerScript.
         * @memberof tellerscript
         * @interface ITellerScript
         * @property {tellerscript.TellerScript.IHeader|null} [header] TellerScript header
         * @property {Array.<tellerscript.IScene>|null} [sceneList] TellerScript sceneList
         */

        /**
         * Constructs a new TellerScript.
         * @memberof tellerscript
         * @classdesc Represents a TellerScript.
         * @implements ITellerScript
         * @constructor
         * @param {tellerscript.ITellerScript=} [properties] Properties to set
         */
        function TellerScript(properties) {
            this.sceneList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TellerScript header.
         * @member {tellerscript.TellerScript.IHeader|null|undefined} header
         * @memberof tellerscript.TellerScript
         * @instance
         */
        TellerScript.prototype.header = null;

        /**
         * TellerScript sceneList.
         * @member {Array.<tellerscript.IScene>} sceneList
         * @memberof tellerscript.TellerScript
         * @instance
         */
        TellerScript.prototype.sceneList = $util.emptyArray;

        /**
         * Creates a new TellerScript instance using the specified properties.
         * @function create
         * @memberof tellerscript.TellerScript
         * @static
         * @param {tellerscript.ITellerScript=} [properties] Properties to set
         * @returns {tellerscript.TellerScript} TellerScript instance
         */
        TellerScript.create = function create(properties) {
            return new TellerScript(properties);
        };

        /**
         * Encodes the specified TellerScript message. Does not implicitly {@link tellerscript.TellerScript.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.TellerScript
         * @static
         * @param {tellerscript.ITellerScript} message TellerScript message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TellerScript.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.tellerscript.TellerScript.Header.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sceneList != null && message.sceneList.length)
                for (var i = 0; i < message.sceneList.length; ++i)
                    $root.tellerscript.Scene.encode(message.sceneList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TellerScript message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.TellerScript
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.TellerScript} TellerScript
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TellerScript.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.TellerScript();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.header = $root.tellerscript.TellerScript.Header.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.sceneList && message.sceneList.length))
                        message.sceneList = [];
                    message.sceneList.push($root.tellerscript.Scene.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TellerScript message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.TellerScript
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.TellerScript} TellerScript
         */
        TellerScript.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.TellerScript)
                return object;
            var message = new $root.tellerscript.TellerScript();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".tellerscript.TellerScript.header: object expected");
                message.header = $root.tellerscript.TellerScript.Header.fromObject(object.header);
            }
            if (object.sceneList) {
                if (!Array.isArray(object.sceneList))
                    throw TypeError(".tellerscript.TellerScript.sceneList: array expected");
                message.sceneList = [];
                for (var i = 0; i < object.sceneList.length; ++i) {
                    if (typeof object.sceneList[i] !== "object")
                        throw TypeError(".tellerscript.TellerScript.sceneList: object expected");
                    message.sceneList[i] = $root.tellerscript.Scene.fromObject(object.sceneList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TellerScript message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.TellerScript
         * @static
         * @param {tellerscript.TellerScript} message TellerScript
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TellerScript.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.sceneList = [];
            if (options.defaults)
                object.header = null;
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.tellerscript.TellerScript.Header.toObject(message.header, options);
            if (message.sceneList && message.sceneList.length) {
                object.sceneList = [];
                for (var j = 0; j < message.sceneList.length; ++j)
                    object.sceneList[j] = $root.tellerscript.Scene.toObject(message.sceneList[j], options);
            }
            return object;
        };

        /**
         * Converts this TellerScript to JSON.
         * @function toJSON
         * @memberof tellerscript.TellerScript
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TellerScript.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TellerScript
         * @function getTypeUrl
         * @memberof tellerscript.TellerScript
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TellerScript.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.TellerScript";
        };

        TellerScript.Header = (function() {

            /**
             * Properties of a Header.
             * @memberof tellerscript.TellerScript
             * @interface IHeader
             * @property {tellerscript.FormatVersion|null} [version] Header version
             * @property {Array.<tellerscript.ICharacter>|null} [characterList] Header characterList
             */

            /**
             * Constructs a new Header.
             * @memberof tellerscript.TellerScript
             * @classdesc Represents a Header.
             * @implements IHeader
             * @constructor
             * @param {tellerscript.TellerScript.IHeader=} [properties] Properties to set
             */
            function Header(properties) {
                this.characterList = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Header version.
             * @member {tellerscript.FormatVersion} version
             * @memberof tellerscript.TellerScript.Header
             * @instance
             */
            Header.prototype.version = 0;

            /**
             * Header characterList.
             * @member {Array.<tellerscript.ICharacter>} characterList
             * @memberof tellerscript.TellerScript.Header
             * @instance
             */
            Header.prototype.characterList = $util.emptyArray;

            /**
             * Creates a new Header instance using the specified properties.
             * @function create
             * @memberof tellerscript.TellerScript.Header
             * @static
             * @param {tellerscript.TellerScript.IHeader=} [properties] Properties to set
             * @returns {tellerscript.TellerScript.Header} Header instance
             */
            Header.create = function create(properties) {
                return new Header(properties);
            };

            /**
             * Encodes the specified Header message. Does not implicitly {@link tellerscript.TellerScript.Header.verify|verify} messages.
             * @function encode
             * @memberof tellerscript.TellerScript.Header
             * @static
             * @param {tellerscript.TellerScript.IHeader} message Header message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Header.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.version);
                if (message.characterList != null && message.characterList.length)
                    for (var i = 0; i < message.characterList.length; ++i)
                        $root.tellerscript.Character.encode(message.characterList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Header message from the specified reader or buffer.
             * @function decode
             * @memberof tellerscript.TellerScript.Header
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {tellerscript.TellerScript.Header} Header
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Header.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.TellerScript.Header();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.version = reader.int32();
                        break;
                    case 2:
                        if (!(message.characterList && message.characterList.length))
                            message.characterList = [];
                        message.characterList.push($root.tellerscript.Character.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a Header message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof tellerscript.TellerScript.Header
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {tellerscript.TellerScript.Header} Header
             */
            Header.fromObject = function fromObject(object) {
                if (object instanceof $root.tellerscript.TellerScript.Header)
                    return object;
                var message = new $root.tellerscript.TellerScript.Header();
                switch (object.version) {
                case "FORMAT_VERSION_UNSPECIFIED":
                case 0:
                    message.version = 0;
                    break;
                case "VERSION_1":
                case 1:
                    message.version = 1;
                    break;
                }
                if (object.characterList) {
                    if (!Array.isArray(object.characterList))
                        throw TypeError(".tellerscript.TellerScript.Header.characterList: array expected");
                    message.characterList = [];
                    for (var i = 0; i < object.characterList.length; ++i) {
                        if (typeof object.characterList[i] !== "object")
                            throw TypeError(".tellerscript.TellerScript.Header.characterList: object expected");
                        message.characterList[i] = $root.tellerscript.Character.fromObject(object.characterList[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Header message. Also converts values to other types if specified.
             * @function toObject
             * @memberof tellerscript.TellerScript.Header
             * @static
             * @param {tellerscript.TellerScript.Header} message Header
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Header.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.characterList = [];
                if (options.defaults)
                    object.version = options.enums === String ? "FORMAT_VERSION_UNSPECIFIED" : 0;
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = options.enums === String ? $root.tellerscript.FormatVersion[message.version] : message.version;
                if (message.characterList && message.characterList.length) {
                    object.characterList = [];
                    for (var j = 0; j < message.characterList.length; ++j)
                        object.characterList[j] = $root.tellerscript.Character.toObject(message.characterList[j], options);
                }
                return object;
            };

            /**
             * Converts this Header to JSON.
             * @function toJSON
             * @memberof tellerscript.TellerScript.Header
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Header.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Header
             * @function getTypeUrl
             * @memberof tellerscript.TellerScript.Header
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Header.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/tellerscript.TellerScript.Header";
            };

            return Header;
        })();

        return TellerScript;
    })();

    tellerscript.Scene = (function() {

        /**
         * Properties of a Scene.
         * @memberof tellerscript
         * @interface IScene
         * @property {Array.<string>|null} [mainCharacterIdList] Scene mainCharacterIdList
         * @property {tellerscript.Scene.Type|null} [type] Scene type
         * @property {string|null} [imageId] Scene imageId
         * @property {tellerscript.IRGBAColor|null} [color] Scene color
         * @property {Array.<tellerscript.IOp>|null} [ops] Scene ops
         * @property {string|null} [name] Scene name
         */

        /**
         * Constructs a new Scene.
         * @memberof tellerscript
         * @classdesc Represents a Scene.
         * @implements IScene
         * @constructor
         * @param {tellerscript.IScene=} [properties] Properties to set
         */
        function Scene(properties) {
            this.mainCharacterIdList = [];
            this.ops = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Scene mainCharacterIdList.
         * @member {Array.<string>} mainCharacterIdList
         * @memberof tellerscript.Scene
         * @instance
         */
        Scene.prototype.mainCharacterIdList = $util.emptyArray;

        /**
         * Scene type.
         * @member {tellerscript.Scene.Type} type
         * @memberof tellerscript.Scene
         * @instance
         */
        Scene.prototype.type = 0;

        /**
         * Scene imageId.
         * @member {string|null|undefined} imageId
         * @memberof tellerscript.Scene
         * @instance
         */
        Scene.prototype.imageId = null;

        /**
         * Scene color.
         * @member {tellerscript.IRGBAColor|null|undefined} color
         * @memberof tellerscript.Scene
         * @instance
         */
        Scene.prototype.color = null;

        /**
         * Scene ops.
         * @member {Array.<tellerscript.IOp>} ops
         * @memberof tellerscript.Scene
         * @instance
         */
        Scene.prototype.ops = $util.emptyArray;

        /**
         * Scene name.
         * @member {string} name
         * @memberof tellerscript.Scene
         * @instance
         */
        Scene.prototype.name = "";

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Scene background.
         * @member {"imageId"|"color"|undefined} background
         * @memberof tellerscript.Scene
         * @instance
         */
        Object.defineProperty(Scene.prototype, "background", {
            get: $util.oneOfGetter($oneOfFields = ["imageId", "color"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Scene instance using the specified properties.
         * @function create
         * @memberof tellerscript.Scene
         * @static
         * @param {tellerscript.IScene=} [properties] Properties to set
         * @returns {tellerscript.Scene} Scene instance
         */
        Scene.create = function create(properties) {
            return new Scene(properties);
        };

        /**
         * Encodes the specified Scene message. Does not implicitly {@link tellerscript.Scene.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.Scene
         * @static
         * @param {tellerscript.IScene} message Scene message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Scene.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mainCharacterIdList != null && message.mainCharacterIdList.length)
                for (var i = 0; i < message.mainCharacterIdList.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.mainCharacterIdList[i]);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageId);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                $root.tellerscript.RGBAColor.encode(message.color, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.ops != null && message.ops.length)
                for (var i = 0; i < message.ops.length; ++i)
                    $root.tellerscript.Op.encode(message.ops[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            return writer;
        };

        /**
         * Decodes a Scene message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.Scene
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.Scene} Scene
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Scene.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.Scene();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.mainCharacterIdList && message.mainCharacterIdList.length))
                        message.mainCharacterIdList = [];
                    message.mainCharacterIdList.push(reader.string());
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.imageId = reader.string();
                    break;
                case 4:
                    message.color = $root.tellerscript.RGBAColor.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.ops && message.ops.length))
                        message.ops = [];
                    message.ops.push($root.tellerscript.Op.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Scene message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.Scene
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.Scene} Scene
         */
        Scene.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.Scene)
                return object;
            var message = new $root.tellerscript.Scene();
            if (object.mainCharacterIdList) {
                if (!Array.isArray(object.mainCharacterIdList))
                    throw TypeError(".tellerscript.Scene.mainCharacterIdList: array expected");
                message.mainCharacterIdList = [];
                for (var i = 0; i < object.mainCharacterIdList.length; ++i)
                    message.mainCharacterIdList[i] = String(object.mainCharacterIdList[i]);
            }
            switch (object.type) {
            case "TYPE_UNSPECIFIED":
            case 0:
                message.type = 0;
                break;
            case "TYPE_CHAT":
            case 1:
                message.type = 1;
                break;
            case "TYPE_TALK":
            case 2:
                message.type = 2;
                break;
            }
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            if (object.color != null) {
                if (typeof object.color !== "object")
                    throw TypeError(".tellerscript.Scene.color: object expected");
                message.color = $root.tellerscript.RGBAColor.fromObject(object.color);
            }
            if (object.ops) {
                if (!Array.isArray(object.ops))
                    throw TypeError(".tellerscript.Scene.ops: array expected");
                message.ops = [];
                for (var i = 0; i < object.ops.length; ++i) {
                    if (typeof object.ops[i] !== "object")
                        throw TypeError(".tellerscript.Scene.ops: object expected");
                    message.ops[i] = $root.tellerscript.Op.fromObject(object.ops[i]);
                }
            }
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a Scene message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.Scene
         * @static
         * @param {tellerscript.Scene} message Scene
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Scene.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.mainCharacterIdList = [];
                object.ops = [];
            }
            if (options.defaults) {
                object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                object.name = "";
            }
            if (message.mainCharacterIdList && message.mainCharacterIdList.length) {
                object.mainCharacterIdList = [];
                for (var j = 0; j < message.mainCharacterIdList.length; ++j)
                    object.mainCharacterIdList[j] = message.mainCharacterIdList[j];
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.tellerscript.Scene.Type[message.type] : message.type;
            if (message.imageId != null && message.hasOwnProperty("imageId")) {
                object.imageId = message.imageId;
                if (options.oneofs)
                    object.background = "imageId";
            }
            if (message.color != null && message.hasOwnProperty("color")) {
                object.color = $root.tellerscript.RGBAColor.toObject(message.color, options);
                if (options.oneofs)
                    object.background = "color";
            }
            if (message.ops && message.ops.length) {
                object.ops = [];
                for (var j = 0; j < message.ops.length; ++j)
                    object.ops[j] = $root.tellerscript.Op.toObject(message.ops[j], options);
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this Scene to JSON.
         * @function toJSON
         * @memberof tellerscript.Scene
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Scene.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Scene
         * @function getTypeUrl
         * @memberof tellerscript.Scene
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Scene.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.Scene";
        };

        /**
         * Type enum.
         * @name tellerscript.Scene.Type
         * @enum {number}
         * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
         * @property {number} TYPE_CHAT=1 TYPE_CHAT value
         * @property {number} TYPE_TALK=2 TYPE_TALK value
         */
        Scene.Type = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "TYPE_CHAT"] = 1;
            values[valuesById[2] = "TYPE_TALK"] = 2;
            return values;
        })();

        return Scene;
    })();

    tellerscript.Op = (function() {

        /**
         * Properties of an Op.
         * @memberof tellerscript
         * @interface IOp
         * @property {tellerscript.IShowImageOp|null} [showImageOp] Op showImageOp
         * @property {tellerscript.IShowTextOp|null} [showTextOp] Op showTextOp
         * @property {tellerscript.IVibrateOp|null} [vibrateOp] Op vibrateOp
         * @property {tellerscript.IShowNotificationOp|null} [showNotificationOp] Op showNotificationOp
         * @property {tellerscript.IPostMessageOp|null} [postMessageOp] Op postMessageOp
         * @property {tellerscript.IPostImageOp|null} [postImageOp] Op postImageOp
         * @property {tellerscript.IReceiveCallOp|null} [receiveCallOp] Op receiveCallOp
         * @property {tellerscript.IEndCallOp|null} [endCallOp] Op endCallOp
         * @property {tellerscript.IMissCallOp|null} [missedCallOp] Op missedCallOp
         * @property {tellerscript.INoResponseCallOp|null} [noResponseCallOp] Op noResponseCallOp
         * @property {tellerscript.ITypingNowOp|null} [typingNowOp] Op typingNowOp
         */

        /**
         * Constructs a new Op.
         * @memberof tellerscript
         * @classdesc Represents an Op.
         * @implements IOp
         * @constructor
         * @param {tellerscript.IOp=} [properties] Properties to set
         */
        function Op(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Op showImageOp.
         * @member {tellerscript.IShowImageOp|null|undefined} showImageOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.showImageOp = null;

        /**
         * Op showTextOp.
         * @member {tellerscript.IShowTextOp|null|undefined} showTextOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.showTextOp = null;

        /**
         * Op vibrateOp.
         * @member {tellerscript.IVibrateOp|null|undefined} vibrateOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.vibrateOp = null;

        /**
         * Op showNotificationOp.
         * @member {tellerscript.IShowNotificationOp|null|undefined} showNotificationOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.showNotificationOp = null;

        /**
         * Op postMessageOp.
         * @member {tellerscript.IPostMessageOp|null|undefined} postMessageOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.postMessageOp = null;

        /**
         * Op postImageOp.
         * @member {tellerscript.IPostImageOp|null|undefined} postImageOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.postImageOp = null;

        /**
         * Op receiveCallOp.
         * @member {tellerscript.IReceiveCallOp|null|undefined} receiveCallOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.receiveCallOp = null;

        /**
         * Op endCallOp.
         * @member {tellerscript.IEndCallOp|null|undefined} endCallOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.endCallOp = null;

        /**
         * Op missedCallOp.
         * @member {tellerscript.IMissCallOp|null|undefined} missedCallOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.missedCallOp = null;

        /**
         * Op noResponseCallOp.
         * @member {tellerscript.INoResponseCallOp|null|undefined} noResponseCallOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.noResponseCallOp = null;

        /**
         * Op typingNowOp.
         * @member {tellerscript.ITypingNowOp|null|undefined} typingNowOp
         * @memberof tellerscript.Op
         * @instance
         */
        Op.prototype.typingNowOp = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Op opOneof.
         * @member {"showImageOp"|"showTextOp"|"vibrateOp"|"showNotificationOp"|"postMessageOp"|"postImageOp"|"receiveCallOp"|"endCallOp"|"missedCallOp"|"noResponseCallOp"|"typingNowOp"|undefined} opOneof
         * @memberof tellerscript.Op
         * @instance
         */
        Object.defineProperty(Op.prototype, "opOneof", {
            get: $util.oneOfGetter($oneOfFields = ["showImageOp", "showTextOp", "vibrateOp", "showNotificationOp", "postMessageOp", "postImageOp", "receiveCallOp", "endCallOp", "missedCallOp", "noResponseCallOp", "typingNowOp"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Op instance using the specified properties.
         * @function create
         * @memberof tellerscript.Op
         * @static
         * @param {tellerscript.IOp=} [properties] Properties to set
         * @returns {tellerscript.Op} Op instance
         */
        Op.create = function create(properties) {
            return new Op(properties);
        };

        /**
         * Encodes the specified Op message. Does not implicitly {@link tellerscript.Op.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.Op
         * @static
         * @param {tellerscript.IOp} message Op message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Op.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.showImageOp != null && Object.hasOwnProperty.call(message, "showImageOp"))
                $root.tellerscript.ShowImageOp.encode(message.showImageOp, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.showTextOp != null && Object.hasOwnProperty.call(message, "showTextOp"))
                $root.tellerscript.ShowTextOp.encode(message.showTextOp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.vibrateOp != null && Object.hasOwnProperty.call(message, "vibrateOp"))
                $root.tellerscript.VibrateOp.encode(message.vibrateOp, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.showNotificationOp != null && Object.hasOwnProperty.call(message, "showNotificationOp"))
                $root.tellerscript.ShowNotificationOp.encode(message.showNotificationOp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.postMessageOp != null && Object.hasOwnProperty.call(message, "postMessageOp"))
                $root.tellerscript.PostMessageOp.encode(message.postMessageOp, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.postImageOp != null && Object.hasOwnProperty.call(message, "postImageOp"))
                $root.tellerscript.PostImageOp.encode(message.postImageOp, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.receiveCallOp != null && Object.hasOwnProperty.call(message, "receiveCallOp"))
                $root.tellerscript.ReceiveCallOp.encode(message.receiveCallOp, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.endCallOp != null && Object.hasOwnProperty.call(message, "endCallOp"))
                $root.tellerscript.EndCallOp.encode(message.endCallOp, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.missedCallOp != null && Object.hasOwnProperty.call(message, "missedCallOp"))
                $root.tellerscript.MissCallOp.encode(message.missedCallOp, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.noResponseCallOp != null && Object.hasOwnProperty.call(message, "noResponseCallOp"))
                $root.tellerscript.NoResponseCallOp.encode(message.noResponseCallOp, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.typingNowOp != null && Object.hasOwnProperty.call(message, "typingNowOp"))
                $root.tellerscript.TypingNowOp.encode(message.typingNowOp, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Op message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.Op
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.Op} Op
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Op.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.Op();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.showImageOp = $root.tellerscript.ShowImageOp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.showTextOp = $root.tellerscript.ShowTextOp.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.vibrateOp = $root.tellerscript.VibrateOp.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.showNotificationOp = $root.tellerscript.ShowNotificationOp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.postMessageOp = $root.tellerscript.PostMessageOp.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.postImageOp = $root.tellerscript.PostImageOp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.receiveCallOp = $root.tellerscript.ReceiveCallOp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.endCallOp = $root.tellerscript.EndCallOp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.missedCallOp = $root.tellerscript.MissCallOp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.noResponseCallOp = $root.tellerscript.NoResponseCallOp.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.typingNowOp = $root.tellerscript.TypingNowOp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an Op message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.Op
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.Op} Op
         */
        Op.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.Op)
                return object;
            var message = new $root.tellerscript.Op();
            if (object.showImageOp != null) {
                if (typeof object.showImageOp !== "object")
                    throw TypeError(".tellerscript.Op.showImageOp: object expected");
                message.showImageOp = $root.tellerscript.ShowImageOp.fromObject(object.showImageOp);
            }
            if (object.showTextOp != null) {
                if (typeof object.showTextOp !== "object")
                    throw TypeError(".tellerscript.Op.showTextOp: object expected");
                message.showTextOp = $root.tellerscript.ShowTextOp.fromObject(object.showTextOp);
            }
            if (object.vibrateOp != null) {
                if (typeof object.vibrateOp !== "object")
                    throw TypeError(".tellerscript.Op.vibrateOp: object expected");
                message.vibrateOp = $root.tellerscript.VibrateOp.fromObject(object.vibrateOp);
            }
            if (object.showNotificationOp != null) {
                if (typeof object.showNotificationOp !== "object")
                    throw TypeError(".tellerscript.Op.showNotificationOp: object expected");
                message.showNotificationOp = $root.tellerscript.ShowNotificationOp.fromObject(object.showNotificationOp);
            }
            if (object.postMessageOp != null) {
                if (typeof object.postMessageOp !== "object")
                    throw TypeError(".tellerscript.Op.postMessageOp: object expected");
                message.postMessageOp = $root.tellerscript.PostMessageOp.fromObject(object.postMessageOp);
            }
            if (object.postImageOp != null) {
                if (typeof object.postImageOp !== "object")
                    throw TypeError(".tellerscript.Op.postImageOp: object expected");
                message.postImageOp = $root.tellerscript.PostImageOp.fromObject(object.postImageOp);
            }
            if (object.receiveCallOp != null) {
                if (typeof object.receiveCallOp !== "object")
                    throw TypeError(".tellerscript.Op.receiveCallOp: object expected");
                message.receiveCallOp = $root.tellerscript.ReceiveCallOp.fromObject(object.receiveCallOp);
            }
            if (object.endCallOp != null) {
                if (typeof object.endCallOp !== "object")
                    throw TypeError(".tellerscript.Op.endCallOp: object expected");
                message.endCallOp = $root.tellerscript.EndCallOp.fromObject(object.endCallOp);
            }
            if (object.missedCallOp != null) {
                if (typeof object.missedCallOp !== "object")
                    throw TypeError(".tellerscript.Op.missedCallOp: object expected");
                message.missedCallOp = $root.tellerscript.MissCallOp.fromObject(object.missedCallOp);
            }
            if (object.noResponseCallOp != null) {
                if (typeof object.noResponseCallOp !== "object")
                    throw TypeError(".tellerscript.Op.noResponseCallOp: object expected");
                message.noResponseCallOp = $root.tellerscript.NoResponseCallOp.fromObject(object.noResponseCallOp);
            }
            if (object.typingNowOp != null) {
                if (typeof object.typingNowOp !== "object")
                    throw TypeError(".tellerscript.Op.typingNowOp: object expected");
                message.typingNowOp = $root.tellerscript.TypingNowOp.fromObject(object.typingNowOp);
            }
            return message;
        };

        /**
         * Creates a plain object from an Op message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.Op
         * @static
         * @param {tellerscript.Op} message Op
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Op.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.showImageOp != null && message.hasOwnProperty("showImageOp")) {
                object.showImageOp = $root.tellerscript.ShowImageOp.toObject(message.showImageOp, options);
                if (options.oneofs)
                    object.opOneof = "showImageOp";
            }
            if (message.showTextOp != null && message.hasOwnProperty("showTextOp")) {
                object.showTextOp = $root.tellerscript.ShowTextOp.toObject(message.showTextOp, options);
                if (options.oneofs)
                    object.opOneof = "showTextOp";
            }
            if (message.vibrateOp != null && message.hasOwnProperty("vibrateOp")) {
                object.vibrateOp = $root.tellerscript.VibrateOp.toObject(message.vibrateOp, options);
                if (options.oneofs)
                    object.opOneof = "vibrateOp";
            }
            if (message.showNotificationOp != null && message.hasOwnProperty("showNotificationOp")) {
                object.showNotificationOp = $root.tellerscript.ShowNotificationOp.toObject(message.showNotificationOp, options);
                if (options.oneofs)
                    object.opOneof = "showNotificationOp";
            }
            if (message.postMessageOp != null && message.hasOwnProperty("postMessageOp")) {
                object.postMessageOp = $root.tellerscript.PostMessageOp.toObject(message.postMessageOp, options);
                if (options.oneofs)
                    object.opOneof = "postMessageOp";
            }
            if (message.postImageOp != null && message.hasOwnProperty("postImageOp")) {
                object.postImageOp = $root.tellerscript.PostImageOp.toObject(message.postImageOp, options);
                if (options.oneofs)
                    object.opOneof = "postImageOp";
            }
            if (message.receiveCallOp != null && message.hasOwnProperty("receiveCallOp")) {
                object.receiveCallOp = $root.tellerscript.ReceiveCallOp.toObject(message.receiveCallOp, options);
                if (options.oneofs)
                    object.opOneof = "receiveCallOp";
            }
            if (message.endCallOp != null && message.hasOwnProperty("endCallOp")) {
                object.endCallOp = $root.tellerscript.EndCallOp.toObject(message.endCallOp, options);
                if (options.oneofs)
                    object.opOneof = "endCallOp";
            }
            if (message.missedCallOp != null && message.hasOwnProperty("missedCallOp")) {
                object.missedCallOp = $root.tellerscript.MissCallOp.toObject(message.missedCallOp, options);
                if (options.oneofs)
                    object.opOneof = "missedCallOp";
            }
            if (message.noResponseCallOp != null && message.hasOwnProperty("noResponseCallOp")) {
                object.noResponseCallOp = $root.tellerscript.NoResponseCallOp.toObject(message.noResponseCallOp, options);
                if (options.oneofs)
                    object.opOneof = "noResponseCallOp";
            }
            if (message.typingNowOp != null && message.hasOwnProperty("typingNowOp")) {
                object.typingNowOp = $root.tellerscript.TypingNowOp.toObject(message.typingNowOp, options);
                if (options.oneofs)
                    object.opOneof = "typingNowOp";
            }
            return object;
        };

        /**
         * Converts this Op to JSON.
         * @function toJSON
         * @memberof tellerscript.Op
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Op.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Op
         * @function getTypeUrl
         * @memberof tellerscript.Op
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Op.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.Op";
        };

        return Op;
    })();

    tellerscript.Character = (function() {

        /**
         * Properties of a Character.
         * @memberof tellerscript
         * @interface ICharacter
         * @property {string|null} [id] Character id
         * @property {string|null} [name] Character name
         * @property {string|null} [imageId] Character imageId
         */

        /**
         * Constructs a new Character.
         * @memberof tellerscript
         * @classdesc Represents a Character.
         * @implements ICharacter
         * @constructor
         * @param {tellerscript.ICharacter=} [properties] Properties to set
         */
        function Character(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Character id.
         * @member {string} id
         * @memberof tellerscript.Character
         * @instance
         */
        Character.prototype.id = "";

        /**
         * Character name.
         * @member {string} name
         * @memberof tellerscript.Character
         * @instance
         */
        Character.prototype.name = "";

        /**
         * Character imageId.
         * @member {string} imageId
         * @memberof tellerscript.Character
         * @instance
         */
        Character.prototype.imageId = "";

        /**
         * Creates a new Character instance using the specified properties.
         * @function create
         * @memberof tellerscript.Character
         * @static
         * @param {tellerscript.ICharacter=} [properties] Properties to set
         * @returns {tellerscript.Character} Character instance
         */
        Character.create = function create(properties) {
            return new Character(properties);
        };

        /**
         * Encodes the specified Character message. Does not implicitly {@link tellerscript.Character.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.Character
         * @static
         * @param {tellerscript.ICharacter} message Character message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Character.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageId);
            return writer;
        };

        /**
         * Decodes a Character message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.Character
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.Character} Character
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Character.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.Character();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.imageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Character message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.Character
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.Character} Character
         */
        Character.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.Character)
                return object;
            var message = new $root.tellerscript.Character();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            return message;
        };

        /**
         * Creates a plain object from a Character message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.Character
         * @static
         * @param {tellerscript.Character} message Character
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Character.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.imageId = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            return object;
        };

        /**
         * Converts this Character to JSON.
         * @function toJSON
         * @memberof tellerscript.Character
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Character.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Character
         * @function getTypeUrl
         * @memberof tellerscript.Character
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Character.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.Character";
        };

        return Character;
    })();

    /**
     * TextAlign enum.
     * @name tellerscript.TextAlign
     * @enum {number}
     * @property {number} TEXT_ALIGN_UNSPECIFIED=0 TEXT_ALIGN_UNSPECIFIED value
     * @property {number} TEXT_ALIGN_LEFT=1 TEXT_ALIGN_LEFT value
     * @property {number} TEXT_ALIGN_RIGHT=2 TEXT_ALIGN_RIGHT value
     * @property {number} TEXT_ALIGN_CENTER=3 TEXT_ALIGN_CENTER value
     */
    tellerscript.TextAlign = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TEXT_ALIGN_UNSPECIFIED"] = 0;
        values[valuesById[1] = "TEXT_ALIGN_LEFT"] = 1;
        values[valuesById[2] = "TEXT_ALIGN_RIGHT"] = 2;
        values[valuesById[3] = "TEXT_ALIGN_CENTER"] = 3;
        return values;
    })();

    tellerscript.RGBAColor = (function() {

        /**
         * Properties of a RGBAColor.
         * @memberof tellerscript
         * @interface IRGBAColor
         * @property {number|null} [red] RGBAColor red
         * @property {number|null} [green] RGBAColor green
         * @property {number|null} [blue] RGBAColor blue
         * @property {number|null} [alpha] RGBAColor alpha
         */

        /**
         * Constructs a new RGBAColor.
         * @memberof tellerscript
         * @classdesc Represents a RGBAColor.
         * @implements IRGBAColor
         * @constructor
         * @param {tellerscript.IRGBAColor=} [properties] Properties to set
         */
        function RGBAColor(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RGBAColor red.
         * @member {number} red
         * @memberof tellerscript.RGBAColor
         * @instance
         */
        RGBAColor.prototype.red = 0;

        /**
         * RGBAColor green.
         * @member {number} green
         * @memberof tellerscript.RGBAColor
         * @instance
         */
        RGBAColor.prototype.green = 0;

        /**
         * RGBAColor blue.
         * @member {number} blue
         * @memberof tellerscript.RGBAColor
         * @instance
         */
        RGBAColor.prototype.blue = 0;

        /**
         * RGBAColor alpha.
         * @member {number} alpha
         * @memberof tellerscript.RGBAColor
         * @instance
         */
        RGBAColor.prototype.alpha = 0;

        /**
         * Creates a new RGBAColor instance using the specified properties.
         * @function create
         * @memberof tellerscript.RGBAColor
         * @static
         * @param {tellerscript.IRGBAColor=} [properties] Properties to set
         * @returns {tellerscript.RGBAColor} RGBAColor instance
         */
        RGBAColor.create = function create(properties) {
            return new RGBAColor(properties);
        };

        /**
         * Encodes the specified RGBAColor message. Does not implicitly {@link tellerscript.RGBAColor.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.RGBAColor
         * @static
         * @param {tellerscript.IRGBAColor} message RGBAColor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RGBAColor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.red != null && Object.hasOwnProperty.call(message, "red"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.red);
            if (message.green != null && Object.hasOwnProperty.call(message, "green"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.green);
            if (message.blue != null && Object.hasOwnProperty.call(message, "blue"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.blue);
            if (message.alpha != null && Object.hasOwnProperty.call(message, "alpha"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.alpha);
            return writer;
        };

        /**
         * Decodes a RGBAColor message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.RGBAColor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.RGBAColor} RGBAColor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RGBAColor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.RGBAColor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.red = reader.int32();
                    break;
                case 2:
                    message.green = reader.int32();
                    break;
                case 3:
                    message.blue = reader.int32();
                    break;
                case 4:
                    message.alpha = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a RGBAColor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.RGBAColor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.RGBAColor} RGBAColor
         */
        RGBAColor.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.RGBAColor)
                return object;
            var message = new $root.tellerscript.RGBAColor();
            if (object.red != null)
                message.red = object.red | 0;
            if (object.green != null)
                message.green = object.green | 0;
            if (object.blue != null)
                message.blue = object.blue | 0;
            if (object.alpha != null)
                message.alpha = object.alpha | 0;
            return message;
        };

        /**
         * Creates a plain object from a RGBAColor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.RGBAColor
         * @static
         * @param {tellerscript.RGBAColor} message RGBAColor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RGBAColor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.red = 0;
                object.green = 0;
                object.blue = 0;
                object.alpha = 0;
            }
            if (message.red != null && message.hasOwnProperty("red"))
                object.red = message.red;
            if (message.green != null && message.hasOwnProperty("green"))
                object.green = message.green;
            if (message.blue != null && message.hasOwnProperty("blue"))
                object.blue = message.blue;
            if (message.alpha != null && message.hasOwnProperty("alpha"))
                object.alpha = message.alpha;
            return object;
        };

        /**
         * Converts this RGBAColor to JSON.
         * @function toJSON
         * @memberof tellerscript.RGBAColor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RGBAColor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RGBAColor
         * @function getTypeUrl
         * @memberof tellerscript.RGBAColor
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RGBAColor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.RGBAColor";
        };

        return RGBAColor;
    })();

    /**
     * FontSize enum.
     * @name tellerscript.FontSize
     * @enum {number}
     * @property {number} FONT_SIZE_MEDIUM=0 FONT_SIZE_MEDIUM value
     * @property {number} FONT_SIZE_SMALL=1 FONT_SIZE_SMALL value
     * @property {number} FONT_SIZE_LARGE=2 FONT_SIZE_LARGE value
     * @property {number} FONT_SIZE_X_SMALL=3 FONT_SIZE_X_SMALL value
     * @property {number} FONT_SIZE_X_LARGE=4 FONT_SIZE_X_LARGE value
     */
    tellerscript.FontSize = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FONT_SIZE_MEDIUM"] = 0;
        values[valuesById[1] = "FONT_SIZE_SMALL"] = 1;
        values[valuesById[2] = "FONT_SIZE_LARGE"] = 2;
        values[valuesById[3] = "FONT_SIZE_X_SMALL"] = 3;
        values[valuesById[4] = "FONT_SIZE_X_LARGE"] = 4;
        return values;
    })();

    tellerscript.ShowImageOp = (function() {

        /**
         * Properties of a ShowImageOp.
         * @memberof tellerscript
         * @interface IShowImageOp
         * @property {string|null} [imageId] ShowImageOp imageId
         */

        /**
         * Constructs a new ShowImageOp.
         * @memberof tellerscript
         * @classdesc Represents a ShowImageOp.
         * @implements IShowImageOp
         * @constructor
         * @param {tellerscript.IShowImageOp=} [properties] Properties to set
         */
        function ShowImageOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ShowImageOp imageId.
         * @member {string} imageId
         * @memberof tellerscript.ShowImageOp
         * @instance
         */
        ShowImageOp.prototype.imageId = "";

        /**
         * Creates a new ShowImageOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.ShowImageOp
         * @static
         * @param {tellerscript.IShowImageOp=} [properties] Properties to set
         * @returns {tellerscript.ShowImageOp} ShowImageOp instance
         */
        ShowImageOp.create = function create(properties) {
            return new ShowImageOp(properties);
        };

        /**
         * Encodes the specified ShowImageOp message. Does not implicitly {@link tellerscript.ShowImageOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.ShowImageOp
         * @static
         * @param {tellerscript.IShowImageOp} message ShowImageOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ShowImageOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageId);
            return writer;
        };

        /**
         * Decodes a ShowImageOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.ShowImageOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.ShowImageOp} ShowImageOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ShowImageOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.ShowImageOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.imageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ShowImageOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.ShowImageOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.ShowImageOp} ShowImageOp
         */
        ShowImageOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.ShowImageOp)
                return object;
            var message = new $root.tellerscript.ShowImageOp();
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            return message;
        };

        /**
         * Creates a plain object from a ShowImageOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.ShowImageOp
         * @static
         * @param {tellerscript.ShowImageOp} message ShowImageOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ShowImageOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.imageId = "";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            return object;
        };

        /**
         * Converts this ShowImageOp to JSON.
         * @function toJSON
         * @memberof tellerscript.ShowImageOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ShowImageOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ShowImageOp
         * @function getTypeUrl
         * @memberof tellerscript.ShowImageOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ShowImageOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.ShowImageOp";
        };

        return ShowImageOp;
    })();

    tellerscript.ShowTextOp = (function() {

        /**
         * Properties of a ShowTextOp.
         * @memberof tellerscript
         * @interface IShowTextOp
         * @property {tellerscript.TextAlign|null} [textAlign] ShowTextOp textAlign
         * @property {string|null} [text] ShowTextOp text
         * @property {tellerscript.IRGBAColor|null} [fontColor] ShowTextOp fontColor
         * @property {tellerscript.IRGBAColor|null} [backgroundColor] ShowTextOp backgroundColor
         * @property {tellerscript.FontSize|null} [fontSize] ShowTextOp fontSize
         */

        /**
         * Constructs a new ShowTextOp.
         * @memberof tellerscript
         * @classdesc Represents a ShowTextOp.
         * @implements IShowTextOp
         * @constructor
         * @param {tellerscript.IShowTextOp=} [properties] Properties to set
         */
        function ShowTextOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ShowTextOp textAlign.
         * @member {tellerscript.TextAlign} textAlign
         * @memberof tellerscript.ShowTextOp
         * @instance
         */
        ShowTextOp.prototype.textAlign = 0;

        /**
         * ShowTextOp text.
         * @member {string} text
         * @memberof tellerscript.ShowTextOp
         * @instance
         */
        ShowTextOp.prototype.text = "";

        /**
         * ShowTextOp fontColor.
         * @member {tellerscript.IRGBAColor|null|undefined} fontColor
         * @memberof tellerscript.ShowTextOp
         * @instance
         */
        ShowTextOp.prototype.fontColor = null;

        /**
         * ShowTextOp backgroundColor.
         * @member {tellerscript.IRGBAColor|null|undefined} backgroundColor
         * @memberof tellerscript.ShowTextOp
         * @instance
         */
        ShowTextOp.prototype.backgroundColor = null;

        /**
         * ShowTextOp fontSize.
         * @member {tellerscript.FontSize} fontSize
         * @memberof tellerscript.ShowTextOp
         * @instance
         */
        ShowTextOp.prototype.fontSize = 0;

        /**
         * Creates a new ShowTextOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.ShowTextOp
         * @static
         * @param {tellerscript.IShowTextOp=} [properties] Properties to set
         * @returns {tellerscript.ShowTextOp} ShowTextOp instance
         */
        ShowTextOp.create = function create(properties) {
            return new ShowTextOp(properties);
        };

        /**
         * Encodes the specified ShowTextOp message. Does not implicitly {@link tellerscript.ShowTextOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.ShowTextOp
         * @static
         * @param {tellerscript.IShowTextOp} message ShowTextOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ShowTextOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.textAlign != null && Object.hasOwnProperty.call(message, "textAlign"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.textAlign);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            if (message.fontColor != null && Object.hasOwnProperty.call(message, "fontColor"))
                $root.tellerscript.RGBAColor.encode(message.fontColor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
                $root.tellerscript.RGBAColor.encode(message.backgroundColor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.fontSize != null && Object.hasOwnProperty.call(message, "fontSize"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.fontSize);
            return writer;
        };

        /**
         * Decodes a ShowTextOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.ShowTextOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.ShowTextOp} ShowTextOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ShowTextOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.ShowTextOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.textAlign = reader.int32();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.fontColor = $root.tellerscript.RGBAColor.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.backgroundColor = $root.tellerscript.RGBAColor.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.fontSize = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ShowTextOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.ShowTextOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.ShowTextOp} ShowTextOp
         */
        ShowTextOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.ShowTextOp)
                return object;
            var message = new $root.tellerscript.ShowTextOp();
            switch (object.textAlign) {
            case "TEXT_ALIGN_UNSPECIFIED":
            case 0:
                message.textAlign = 0;
                break;
            case "TEXT_ALIGN_LEFT":
            case 1:
                message.textAlign = 1;
                break;
            case "TEXT_ALIGN_RIGHT":
            case 2:
                message.textAlign = 2;
                break;
            case "TEXT_ALIGN_CENTER":
            case 3:
                message.textAlign = 3;
                break;
            }
            if (object.text != null)
                message.text = String(object.text);
            if (object.fontColor != null) {
                if (typeof object.fontColor !== "object")
                    throw TypeError(".tellerscript.ShowTextOp.fontColor: object expected");
                message.fontColor = $root.tellerscript.RGBAColor.fromObject(object.fontColor);
            }
            if (object.backgroundColor != null) {
                if (typeof object.backgroundColor !== "object")
                    throw TypeError(".tellerscript.ShowTextOp.backgroundColor: object expected");
                message.backgroundColor = $root.tellerscript.RGBAColor.fromObject(object.backgroundColor);
            }
            switch (object.fontSize) {
            case "FONT_SIZE_MEDIUM":
            case 0:
                message.fontSize = 0;
                break;
            case "FONT_SIZE_SMALL":
            case 1:
                message.fontSize = 1;
                break;
            case "FONT_SIZE_LARGE":
            case 2:
                message.fontSize = 2;
                break;
            case "FONT_SIZE_X_SMALL":
            case 3:
                message.fontSize = 3;
                break;
            case "FONT_SIZE_X_LARGE":
            case 4:
                message.fontSize = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ShowTextOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.ShowTextOp
         * @static
         * @param {tellerscript.ShowTextOp} message ShowTextOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ShowTextOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.textAlign = options.enums === String ? "TEXT_ALIGN_UNSPECIFIED" : 0;
                object.text = "";
                object.fontColor = null;
                object.backgroundColor = null;
                object.fontSize = options.enums === String ? "FONT_SIZE_MEDIUM" : 0;
            }
            if (message.textAlign != null && message.hasOwnProperty("textAlign"))
                object.textAlign = options.enums === String ? $root.tellerscript.TextAlign[message.textAlign] : message.textAlign;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.fontColor != null && message.hasOwnProperty("fontColor"))
                object.fontColor = $root.tellerscript.RGBAColor.toObject(message.fontColor, options);
            if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
                object.backgroundColor = $root.tellerscript.RGBAColor.toObject(message.backgroundColor, options);
            if (message.fontSize != null && message.hasOwnProperty("fontSize"))
                object.fontSize = options.enums === String ? $root.tellerscript.FontSize[message.fontSize] : message.fontSize;
            return object;
        };

        /**
         * Converts this ShowTextOp to JSON.
         * @function toJSON
         * @memberof tellerscript.ShowTextOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ShowTextOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ShowTextOp
         * @function getTypeUrl
         * @memberof tellerscript.ShowTextOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ShowTextOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.ShowTextOp";
        };

        return ShowTextOp;
    })();

    tellerscript.ShowNotificationOp = (function() {

        /**
         * Properties of a ShowNotificationOp.
         * @memberof tellerscript
         * @interface IShowNotificationOp
         * @property {string|null} [imageId] ShowNotificationOp imageId
         * @property {string|null} [title] ShowNotificationOp title
         * @property {string|null} [text] ShowNotificationOp text
         */

        /**
         * Constructs a new ShowNotificationOp.
         * @memberof tellerscript
         * @classdesc Represents a ShowNotificationOp.
         * @implements IShowNotificationOp
         * @constructor
         * @param {tellerscript.IShowNotificationOp=} [properties] Properties to set
         */
        function ShowNotificationOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ShowNotificationOp imageId.
         * @member {string} imageId
         * @memberof tellerscript.ShowNotificationOp
         * @instance
         */
        ShowNotificationOp.prototype.imageId = "";

        /**
         * ShowNotificationOp title.
         * @member {string} title
         * @memberof tellerscript.ShowNotificationOp
         * @instance
         */
        ShowNotificationOp.prototype.title = "";

        /**
         * ShowNotificationOp text.
         * @member {string} text
         * @memberof tellerscript.ShowNotificationOp
         * @instance
         */
        ShowNotificationOp.prototype.text = "";

        /**
         * Creates a new ShowNotificationOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.ShowNotificationOp
         * @static
         * @param {tellerscript.IShowNotificationOp=} [properties] Properties to set
         * @returns {tellerscript.ShowNotificationOp} ShowNotificationOp instance
         */
        ShowNotificationOp.create = function create(properties) {
            return new ShowNotificationOp(properties);
        };

        /**
         * Encodes the specified ShowNotificationOp message. Does not implicitly {@link tellerscript.ShowNotificationOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.ShowNotificationOp
         * @static
         * @param {tellerscript.IShowNotificationOp} message ShowNotificationOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ShowNotificationOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageId);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
            return writer;
        };

        /**
         * Decodes a ShowNotificationOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.ShowNotificationOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.ShowNotificationOp} ShowNotificationOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ShowNotificationOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.ShowNotificationOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.imageId = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ShowNotificationOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.ShowNotificationOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.ShowNotificationOp} ShowNotificationOp
         */
        ShowNotificationOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.ShowNotificationOp)
                return object;
            var message = new $root.tellerscript.ShowNotificationOp();
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            if (object.title != null)
                message.title = String(object.title);
            if (object.text != null)
                message.text = String(object.text);
            return message;
        };

        /**
         * Creates a plain object from a ShowNotificationOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.ShowNotificationOp
         * @static
         * @param {tellerscript.ShowNotificationOp} message ShowNotificationOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ShowNotificationOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.imageId = "";
                object.title = "";
                object.text = "";
            }
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            return object;
        };

        /**
         * Converts this ShowNotificationOp to JSON.
         * @function toJSON
         * @memberof tellerscript.ShowNotificationOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ShowNotificationOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ShowNotificationOp
         * @function getTypeUrl
         * @memberof tellerscript.ShowNotificationOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ShowNotificationOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.ShowNotificationOp";
        };

        return ShowNotificationOp;
    })();

    tellerscript.VibrateOp = (function() {

        /**
         * Properties of a VibrateOp.
         * @memberof tellerscript
         * @interface IVibrateOp
         * @property {google.protobuf.IDuration|null} [duration] VibrateOp duration
         * @property {tellerscript.VibrateOp.VibrationStrength|null} [strength] VibrateOp strength
         */

        /**
         * Constructs a new VibrateOp.
         * @memberof tellerscript
         * @classdesc Represents a VibrateOp.
         * @implements IVibrateOp
         * @constructor
         * @param {tellerscript.IVibrateOp=} [properties] Properties to set
         */
        function VibrateOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VibrateOp duration.
         * @member {google.protobuf.IDuration|null|undefined} duration
         * @memberof tellerscript.VibrateOp
         * @instance
         */
        VibrateOp.prototype.duration = null;

        /**
         * VibrateOp strength.
         * @member {tellerscript.VibrateOp.VibrationStrength} strength
         * @memberof tellerscript.VibrateOp
         * @instance
         */
        VibrateOp.prototype.strength = 0;

        /**
         * Creates a new VibrateOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.VibrateOp
         * @static
         * @param {tellerscript.IVibrateOp=} [properties] Properties to set
         * @returns {tellerscript.VibrateOp} VibrateOp instance
         */
        VibrateOp.create = function create(properties) {
            return new VibrateOp(properties);
        };

        /**
         * Encodes the specified VibrateOp message. Does not implicitly {@link tellerscript.VibrateOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.VibrateOp
         * @static
         * @param {tellerscript.IVibrateOp} message VibrateOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VibrateOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.strength != null && Object.hasOwnProperty.call(message, "strength"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.strength);
            return writer;
        };

        /**
         * Decodes a VibrateOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.VibrateOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.VibrateOp} VibrateOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VibrateOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.VibrateOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.strength = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a VibrateOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.VibrateOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.VibrateOp} VibrateOp
         */
        VibrateOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.VibrateOp)
                return object;
            var message = new $root.tellerscript.VibrateOp();
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".tellerscript.VibrateOp.duration: object expected");
                message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
            }
            switch (object.strength) {
            case "VIBRATION_STRENGTH_NORMAL":
            case 0:
                message.strength = 0;
                break;
            case "VIBRATION_STRENGTH_WEAK":
            case 1:
                message.strength = 1;
                break;
            case "VIBRATION_STRENGTH_STRONG":
            case 2:
                message.strength = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a VibrateOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.VibrateOp
         * @static
         * @param {tellerscript.VibrateOp} message VibrateOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VibrateOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.duration = null;
                object.strength = options.enums === String ? "VIBRATION_STRENGTH_NORMAL" : 0;
            }
            if (message.duration != null && message.hasOwnProperty("duration"))
                object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
            if (message.strength != null && message.hasOwnProperty("strength"))
                object.strength = options.enums === String ? $root.tellerscript.VibrateOp.VibrationStrength[message.strength] : message.strength;
            return object;
        };

        /**
         * Converts this VibrateOp to JSON.
         * @function toJSON
         * @memberof tellerscript.VibrateOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VibrateOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VibrateOp
         * @function getTypeUrl
         * @memberof tellerscript.VibrateOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VibrateOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.VibrateOp";
        };

        /**
         * VibrationStrength enum.
         * @name tellerscript.VibrateOp.VibrationStrength
         * @enum {number}
         * @property {number} VIBRATION_STRENGTH_NORMAL=0 VIBRATION_STRENGTH_NORMAL value
         * @property {number} VIBRATION_STRENGTH_WEAK=1 VIBRATION_STRENGTH_WEAK value
         * @property {number} VIBRATION_STRENGTH_STRONG=2 VIBRATION_STRENGTH_STRONG value
         */
        VibrateOp.VibrationStrength = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "VIBRATION_STRENGTH_NORMAL"] = 0;
            values[valuesById[1] = "VIBRATION_STRENGTH_WEAK"] = 1;
            values[valuesById[2] = "VIBRATION_STRENGTH_STRONG"] = 2;
            return values;
        })();

        return VibrateOp;
    })();

    tellerscript.PostMessageOp = (function() {

        /**
         * Properties of a PostMessageOp.
         * @memberof tellerscript
         * @interface IPostMessageOp
         * @property {string|null} [characterId] PostMessageOp characterId
         * @property {string|null} [text] PostMessageOp text
         */

        /**
         * Constructs a new PostMessageOp.
         * @memberof tellerscript
         * @classdesc Represents a PostMessageOp.
         * @implements IPostMessageOp
         * @constructor
         * @param {tellerscript.IPostMessageOp=} [properties] Properties to set
         */
        function PostMessageOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PostMessageOp characterId.
         * @member {string} characterId
         * @memberof tellerscript.PostMessageOp
         * @instance
         */
        PostMessageOp.prototype.characterId = "";

        /**
         * PostMessageOp text.
         * @member {string} text
         * @memberof tellerscript.PostMessageOp
         * @instance
         */
        PostMessageOp.prototype.text = "";

        /**
         * Creates a new PostMessageOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.PostMessageOp
         * @static
         * @param {tellerscript.IPostMessageOp=} [properties] Properties to set
         * @returns {tellerscript.PostMessageOp} PostMessageOp instance
         */
        PostMessageOp.create = function create(properties) {
            return new PostMessageOp(properties);
        };

        /**
         * Encodes the specified PostMessageOp message. Does not implicitly {@link tellerscript.PostMessageOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.PostMessageOp
         * @static
         * @param {tellerscript.IPostMessageOp} message PostMessageOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostMessageOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.characterId != null && Object.hasOwnProperty.call(message, "characterId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.characterId);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            return writer;
        };

        /**
         * Decodes a PostMessageOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.PostMessageOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.PostMessageOp} PostMessageOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostMessageOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.PostMessageOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.characterId = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PostMessageOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.PostMessageOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.PostMessageOp} PostMessageOp
         */
        PostMessageOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.PostMessageOp)
                return object;
            var message = new $root.tellerscript.PostMessageOp();
            if (object.characterId != null)
                message.characterId = String(object.characterId);
            if (object.text != null)
                message.text = String(object.text);
            return message;
        };

        /**
         * Creates a plain object from a PostMessageOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.PostMessageOp
         * @static
         * @param {tellerscript.PostMessageOp} message PostMessageOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PostMessageOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.characterId = "";
                object.text = "";
            }
            if (message.characterId != null && message.hasOwnProperty("characterId"))
                object.characterId = message.characterId;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            return object;
        };

        /**
         * Converts this PostMessageOp to JSON.
         * @function toJSON
         * @memberof tellerscript.PostMessageOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PostMessageOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PostMessageOp
         * @function getTypeUrl
         * @memberof tellerscript.PostMessageOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PostMessageOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.PostMessageOp";
        };

        return PostMessageOp;
    })();

    tellerscript.PostImageOp = (function() {

        /**
         * Properties of a PostImageOp.
         * @memberof tellerscript
         * @interface IPostImageOp
         * @property {string|null} [characterId] PostImageOp characterId
         * @property {string|null} [imageId] PostImageOp imageId
         */

        /**
         * Constructs a new PostImageOp.
         * @memberof tellerscript
         * @classdesc Represents a PostImageOp.
         * @implements IPostImageOp
         * @constructor
         * @param {tellerscript.IPostImageOp=} [properties] Properties to set
         */
        function PostImageOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PostImageOp characterId.
         * @member {string} characterId
         * @memberof tellerscript.PostImageOp
         * @instance
         */
        PostImageOp.prototype.characterId = "";

        /**
         * PostImageOp imageId.
         * @member {string} imageId
         * @memberof tellerscript.PostImageOp
         * @instance
         */
        PostImageOp.prototype.imageId = "";

        /**
         * Creates a new PostImageOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.PostImageOp
         * @static
         * @param {tellerscript.IPostImageOp=} [properties] Properties to set
         * @returns {tellerscript.PostImageOp} PostImageOp instance
         */
        PostImageOp.create = function create(properties) {
            return new PostImageOp(properties);
        };

        /**
         * Encodes the specified PostImageOp message. Does not implicitly {@link tellerscript.PostImageOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.PostImageOp
         * @static
         * @param {tellerscript.IPostImageOp} message PostImageOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostImageOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.characterId != null && Object.hasOwnProperty.call(message, "characterId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.characterId);
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageId);
            return writer;
        };

        /**
         * Decodes a PostImageOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.PostImageOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.PostImageOp} PostImageOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostImageOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.PostImageOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.characterId = reader.string();
                    break;
                case 2:
                    message.imageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PostImageOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.PostImageOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.PostImageOp} PostImageOp
         */
        PostImageOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.PostImageOp)
                return object;
            var message = new $root.tellerscript.PostImageOp();
            if (object.characterId != null)
                message.characterId = String(object.characterId);
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            return message;
        };

        /**
         * Creates a plain object from a PostImageOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.PostImageOp
         * @static
         * @param {tellerscript.PostImageOp} message PostImageOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PostImageOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.characterId = "";
                object.imageId = "";
            }
            if (message.characterId != null && message.hasOwnProperty("characterId"))
                object.characterId = message.characterId;
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            return object;
        };

        /**
         * Converts this PostImageOp to JSON.
         * @function toJSON
         * @memberof tellerscript.PostImageOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PostImageOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PostImageOp
         * @function getTypeUrl
         * @memberof tellerscript.PostImageOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PostImageOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.PostImageOp";
        };

        return PostImageOp;
    })();

    tellerscript.ReceiveCallOp = (function() {

        /**
         * Properties of a ReceiveCallOp.
         * @memberof tellerscript
         * @interface IReceiveCallOp
         * @property {string|null} [characterId] ReceiveCallOp characterId
         * @property {boolean|null} [vibrate] ReceiveCallOp vibrate
         * @property {google.protobuf.IDuration|null} [ringingDuration] ReceiveCallOp ringingDuration
         * @property {string|null} [audioId] ReceiveCallOp audioId
         * @property {google.protobuf.IDuration|null} [talkingDuration] ReceiveCallOp talkingDuration
         */

        /**
         * Constructs a new ReceiveCallOp.
         * @memberof tellerscript
         * @classdesc Represents a ReceiveCallOp.
         * @implements IReceiveCallOp
         * @constructor
         * @param {tellerscript.IReceiveCallOp=} [properties] Properties to set
         */
        function ReceiveCallOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReceiveCallOp characterId.
         * @member {string} characterId
         * @memberof tellerscript.ReceiveCallOp
         * @instance
         */
        ReceiveCallOp.prototype.characterId = "";

        /**
         * ReceiveCallOp vibrate.
         * @member {boolean} vibrate
         * @memberof tellerscript.ReceiveCallOp
         * @instance
         */
        ReceiveCallOp.prototype.vibrate = false;

        /**
         * ReceiveCallOp ringingDuration.
         * @member {google.protobuf.IDuration|null|undefined} ringingDuration
         * @memberof tellerscript.ReceiveCallOp
         * @instance
         */
        ReceiveCallOp.prototype.ringingDuration = null;

        /**
         * ReceiveCallOp audioId.
         * @member {string} audioId
         * @memberof tellerscript.ReceiveCallOp
         * @instance
         */
        ReceiveCallOp.prototype.audioId = "";

        /**
         * ReceiveCallOp talkingDuration.
         * @member {google.protobuf.IDuration|null|undefined} talkingDuration
         * @memberof tellerscript.ReceiveCallOp
         * @instance
         */
        ReceiveCallOp.prototype.talkingDuration = null;

        /**
         * Creates a new ReceiveCallOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.ReceiveCallOp
         * @static
         * @param {tellerscript.IReceiveCallOp=} [properties] Properties to set
         * @returns {tellerscript.ReceiveCallOp} ReceiveCallOp instance
         */
        ReceiveCallOp.create = function create(properties) {
            return new ReceiveCallOp(properties);
        };

        /**
         * Encodes the specified ReceiveCallOp message. Does not implicitly {@link tellerscript.ReceiveCallOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.ReceiveCallOp
         * @static
         * @param {tellerscript.IReceiveCallOp} message ReceiveCallOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.characterId != null && Object.hasOwnProperty.call(message, "characterId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.characterId);
            if (message.vibrate != null && Object.hasOwnProperty.call(message, "vibrate"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.vibrate);
            if (message.ringingDuration != null && Object.hasOwnProperty.call(message, "ringingDuration"))
                $root.google.protobuf.Duration.encode(message.ringingDuration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.audioId != null && Object.hasOwnProperty.call(message, "audioId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.audioId);
            if (message.talkingDuration != null && Object.hasOwnProperty.call(message, "talkingDuration"))
                $root.google.protobuf.Duration.encode(message.talkingDuration, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ReceiveCallOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.ReceiveCallOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.ReceiveCallOp} ReceiveCallOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.ReceiveCallOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.characterId = reader.string();
                    break;
                case 2:
                    message.vibrate = reader.bool();
                    break;
                case 3:
                    message.ringingDuration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.audioId = reader.string();
                    break;
                case 5:
                    message.talkingDuration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ReceiveCallOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.ReceiveCallOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.ReceiveCallOp} ReceiveCallOp
         */
        ReceiveCallOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.ReceiveCallOp)
                return object;
            var message = new $root.tellerscript.ReceiveCallOp();
            if (object.characterId != null)
                message.characterId = String(object.characterId);
            if (object.vibrate != null)
                message.vibrate = Boolean(object.vibrate);
            if (object.ringingDuration != null) {
                if (typeof object.ringingDuration !== "object")
                    throw TypeError(".tellerscript.ReceiveCallOp.ringingDuration: object expected");
                message.ringingDuration = $root.google.protobuf.Duration.fromObject(object.ringingDuration);
            }
            if (object.audioId != null)
                message.audioId = String(object.audioId);
            if (object.talkingDuration != null) {
                if (typeof object.talkingDuration !== "object")
                    throw TypeError(".tellerscript.ReceiveCallOp.talkingDuration: object expected");
                message.talkingDuration = $root.google.protobuf.Duration.fromObject(object.talkingDuration);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReceiveCallOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.ReceiveCallOp
         * @static
         * @param {tellerscript.ReceiveCallOp} message ReceiveCallOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiveCallOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.characterId = "";
                object.vibrate = false;
                object.ringingDuration = null;
                object.audioId = "";
                object.talkingDuration = null;
            }
            if (message.characterId != null && message.hasOwnProperty("characterId"))
                object.characterId = message.characterId;
            if (message.vibrate != null && message.hasOwnProperty("vibrate"))
                object.vibrate = message.vibrate;
            if (message.ringingDuration != null && message.hasOwnProperty("ringingDuration"))
                object.ringingDuration = $root.google.protobuf.Duration.toObject(message.ringingDuration, options);
            if (message.audioId != null && message.hasOwnProperty("audioId"))
                object.audioId = message.audioId;
            if (message.talkingDuration != null && message.hasOwnProperty("talkingDuration"))
                object.talkingDuration = $root.google.protobuf.Duration.toObject(message.talkingDuration, options);
            return object;
        };

        /**
         * Converts this ReceiveCallOp to JSON.
         * @function toJSON
         * @memberof tellerscript.ReceiveCallOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiveCallOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiveCallOp
         * @function getTypeUrl
         * @memberof tellerscript.ReceiveCallOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiveCallOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.ReceiveCallOp";
        };

        return ReceiveCallOp;
    })();

    tellerscript.EndCallOp = (function() {

        /**
         * Properties of an EndCallOp.
         * @memberof tellerscript
         * @interface IEndCallOp
         * @property {string|null} [characterId] EndCallOp characterId
         * @property {google.protobuf.IDuration|null} [duration] EndCallOp duration
         */

        /**
         * Constructs a new EndCallOp.
         * @memberof tellerscript
         * @classdesc Represents an EndCallOp.
         * @implements IEndCallOp
         * @constructor
         * @param {tellerscript.IEndCallOp=} [properties] Properties to set
         */
        function EndCallOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EndCallOp characterId.
         * @member {string} characterId
         * @memberof tellerscript.EndCallOp
         * @instance
         */
        EndCallOp.prototype.characterId = "";

        /**
         * EndCallOp duration.
         * @member {google.protobuf.IDuration|null|undefined} duration
         * @memberof tellerscript.EndCallOp
         * @instance
         */
        EndCallOp.prototype.duration = null;

        /**
         * Creates a new EndCallOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.EndCallOp
         * @static
         * @param {tellerscript.IEndCallOp=} [properties] Properties to set
         * @returns {tellerscript.EndCallOp} EndCallOp instance
         */
        EndCallOp.create = function create(properties) {
            return new EndCallOp(properties);
        };

        /**
         * Encodes the specified EndCallOp message. Does not implicitly {@link tellerscript.EndCallOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.EndCallOp
         * @static
         * @param {tellerscript.IEndCallOp} message EndCallOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EndCallOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.characterId != null && Object.hasOwnProperty.call(message, "characterId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.characterId);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an EndCallOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.EndCallOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.EndCallOp} EndCallOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EndCallOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.EndCallOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.characterId = reader.string();
                    break;
                case 2:
                    message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an EndCallOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.EndCallOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.EndCallOp} EndCallOp
         */
        EndCallOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.EndCallOp)
                return object;
            var message = new $root.tellerscript.EndCallOp();
            if (object.characterId != null)
                message.characterId = String(object.characterId);
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".tellerscript.EndCallOp.duration: object expected");
                message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
            }
            return message;
        };

        /**
         * Creates a plain object from an EndCallOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.EndCallOp
         * @static
         * @param {tellerscript.EndCallOp} message EndCallOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EndCallOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.characterId = "";
                object.duration = null;
            }
            if (message.characterId != null && message.hasOwnProperty("characterId"))
                object.characterId = message.characterId;
            if (message.duration != null && message.hasOwnProperty("duration"))
                object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
            return object;
        };

        /**
         * Converts this EndCallOp to JSON.
         * @function toJSON
         * @memberof tellerscript.EndCallOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EndCallOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EndCallOp
         * @function getTypeUrl
         * @memberof tellerscript.EndCallOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EndCallOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.EndCallOp";
        };

        return EndCallOp;
    })();

    tellerscript.MissCallOp = (function() {

        /**
         * Properties of a MissCallOp.
         * @memberof tellerscript
         * @interface IMissCallOp
         * @property {string|null} [characterId] MissCallOp characterId
         */

        /**
         * Constructs a new MissCallOp.
         * @memberof tellerscript
         * @classdesc Represents a MissCallOp.
         * @implements IMissCallOp
         * @constructor
         * @param {tellerscript.IMissCallOp=} [properties] Properties to set
         */
        function MissCallOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MissCallOp characterId.
         * @member {string} characterId
         * @memberof tellerscript.MissCallOp
         * @instance
         */
        MissCallOp.prototype.characterId = "";

        /**
         * Creates a new MissCallOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.MissCallOp
         * @static
         * @param {tellerscript.IMissCallOp=} [properties] Properties to set
         * @returns {tellerscript.MissCallOp} MissCallOp instance
         */
        MissCallOp.create = function create(properties) {
            return new MissCallOp(properties);
        };

        /**
         * Encodes the specified MissCallOp message. Does not implicitly {@link tellerscript.MissCallOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.MissCallOp
         * @static
         * @param {tellerscript.IMissCallOp} message MissCallOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MissCallOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.characterId != null && Object.hasOwnProperty.call(message, "characterId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.characterId);
            return writer;
        };

        /**
         * Decodes a MissCallOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.MissCallOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.MissCallOp} MissCallOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MissCallOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.MissCallOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.characterId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MissCallOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.MissCallOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.MissCallOp} MissCallOp
         */
        MissCallOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.MissCallOp)
                return object;
            var message = new $root.tellerscript.MissCallOp();
            if (object.characterId != null)
                message.characterId = String(object.characterId);
            return message;
        };

        /**
         * Creates a plain object from a MissCallOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.MissCallOp
         * @static
         * @param {tellerscript.MissCallOp} message MissCallOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MissCallOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.characterId = "";
            if (message.characterId != null && message.hasOwnProperty("characterId"))
                object.characterId = message.characterId;
            return object;
        };

        /**
         * Converts this MissCallOp to JSON.
         * @function toJSON
         * @memberof tellerscript.MissCallOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MissCallOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MissCallOp
         * @function getTypeUrl
         * @memberof tellerscript.MissCallOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MissCallOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.MissCallOp";
        };

        return MissCallOp;
    })();

    tellerscript.NoResponseCallOp = (function() {

        /**
         * Properties of a NoResponseCallOp.
         * @memberof tellerscript
         * @interface INoResponseCallOp
         * @property {string|null} [characterId] NoResponseCallOp characterId
         */

        /**
         * Constructs a new NoResponseCallOp.
         * @memberof tellerscript
         * @classdesc Represents a NoResponseCallOp.
         * @implements INoResponseCallOp
         * @constructor
         * @param {tellerscript.INoResponseCallOp=} [properties] Properties to set
         */
        function NoResponseCallOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NoResponseCallOp characterId.
         * @member {string} characterId
         * @memberof tellerscript.NoResponseCallOp
         * @instance
         */
        NoResponseCallOp.prototype.characterId = "";

        /**
         * Creates a new NoResponseCallOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.NoResponseCallOp
         * @static
         * @param {tellerscript.INoResponseCallOp=} [properties] Properties to set
         * @returns {tellerscript.NoResponseCallOp} NoResponseCallOp instance
         */
        NoResponseCallOp.create = function create(properties) {
            return new NoResponseCallOp(properties);
        };

        /**
         * Encodes the specified NoResponseCallOp message. Does not implicitly {@link tellerscript.NoResponseCallOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.NoResponseCallOp
         * @static
         * @param {tellerscript.INoResponseCallOp} message NoResponseCallOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NoResponseCallOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.characterId != null && Object.hasOwnProperty.call(message, "characterId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.characterId);
            return writer;
        };

        /**
         * Decodes a NoResponseCallOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.NoResponseCallOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.NoResponseCallOp} NoResponseCallOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NoResponseCallOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.NoResponseCallOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.characterId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NoResponseCallOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.NoResponseCallOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.NoResponseCallOp} NoResponseCallOp
         */
        NoResponseCallOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.NoResponseCallOp)
                return object;
            var message = new $root.tellerscript.NoResponseCallOp();
            if (object.characterId != null)
                message.characterId = String(object.characterId);
            return message;
        };

        /**
         * Creates a plain object from a NoResponseCallOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.NoResponseCallOp
         * @static
         * @param {tellerscript.NoResponseCallOp} message NoResponseCallOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NoResponseCallOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.characterId = "";
            if (message.characterId != null && message.hasOwnProperty("characterId"))
                object.characterId = message.characterId;
            return object;
        };

        /**
         * Converts this NoResponseCallOp to JSON.
         * @function toJSON
         * @memberof tellerscript.NoResponseCallOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NoResponseCallOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NoResponseCallOp
         * @function getTypeUrl
         * @memberof tellerscript.NoResponseCallOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NoResponseCallOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.NoResponseCallOp";
        };

        return NoResponseCallOp;
    })();

    tellerscript.TypingNowOp = (function() {

        /**
         * Properties of a TypingNowOp.
         * @memberof tellerscript
         * @interface ITypingNowOp
         * @property {string|null} [characterId] TypingNowOp characterId
         * @property {google.protobuf.IDuration|null} [duration] TypingNowOp duration
         */

        /**
         * Constructs a new TypingNowOp.
         * @memberof tellerscript
         * @classdesc Represents a TypingNowOp.
         * @implements ITypingNowOp
         * @constructor
         * @param {tellerscript.ITypingNowOp=} [properties] Properties to set
         */
        function TypingNowOp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TypingNowOp characterId.
         * @member {string} characterId
         * @memberof tellerscript.TypingNowOp
         * @instance
         */
        TypingNowOp.prototype.characterId = "";

        /**
         * TypingNowOp duration.
         * @member {google.protobuf.IDuration|null|undefined} duration
         * @memberof tellerscript.TypingNowOp
         * @instance
         */
        TypingNowOp.prototype.duration = null;

        /**
         * Creates a new TypingNowOp instance using the specified properties.
         * @function create
         * @memberof tellerscript.TypingNowOp
         * @static
         * @param {tellerscript.ITypingNowOp=} [properties] Properties to set
         * @returns {tellerscript.TypingNowOp} TypingNowOp instance
         */
        TypingNowOp.create = function create(properties) {
            return new TypingNowOp(properties);
        };

        /**
         * Encodes the specified TypingNowOp message. Does not implicitly {@link tellerscript.TypingNowOp.verify|verify} messages.
         * @function encode
         * @memberof tellerscript.TypingNowOp
         * @static
         * @param {tellerscript.ITypingNowOp} message TypingNowOp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TypingNowOp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.characterId != null && Object.hasOwnProperty.call(message, "characterId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.characterId);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TypingNowOp message from the specified reader or buffer.
         * @function decode
         * @memberof tellerscript.TypingNowOp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tellerscript.TypingNowOp} TypingNowOp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TypingNowOp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tellerscript.TypingNowOp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.characterId = reader.string();
                    break;
                case 2:
                    message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TypingNowOp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tellerscript.TypingNowOp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tellerscript.TypingNowOp} TypingNowOp
         */
        TypingNowOp.fromObject = function fromObject(object) {
            if (object instanceof $root.tellerscript.TypingNowOp)
                return object;
            var message = new $root.tellerscript.TypingNowOp();
            if (object.characterId != null)
                message.characterId = String(object.characterId);
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".tellerscript.TypingNowOp.duration: object expected");
                message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
            }
            return message;
        };

        /**
         * Creates a plain object from a TypingNowOp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tellerscript.TypingNowOp
         * @static
         * @param {tellerscript.TypingNowOp} message TypingNowOp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TypingNowOp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.characterId = "";
                object.duration = null;
            }
            if (message.characterId != null && message.hasOwnProperty("characterId"))
                object.characterId = message.characterId;
            if (message.duration != null && message.hasOwnProperty("duration"))
                object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
            return object;
        };

        /**
         * Converts this TypingNowOp to JSON.
         * @function toJSON
         * @memberof tellerscript.TypingNowOp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TypingNowOp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TypingNowOp
         * @function getTypeUrl
         * @memberof tellerscript.TypingNowOp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TypingNowOp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tellerscript.TypingNowOp";
        };

        return TypingNowOp;
    })();

    return tellerscript;
})();

$root.query_types = (function() {

    /**
     * Namespace query_types.
     * @exports query_types
     * @namespace
     */
    var query_types = {};

    query_types.StoryFilter = (function() {

        /**
         * Properties of a StoryFilter.
         * @memberof query_types
         * @interface IStoryFilter
         * @property {google.protobuf.IBoolValue|null} [excludeAlreadyRead] StoryFilter excludeAlreadyRead
         * @property {google.protobuf.IBoolValue|null} [includeBlocked] StoryFilter includeBlocked
         * @property {google.protobuf.IBoolValue|null} [excludeCompletedSeries] StoryFilter excludeCompletedSeries
         * @property {google.protobuf.IBoolValue|null} [excludeNotCompletedSeries] StoryFilter excludeNotCompletedSeries
         * @property {google.protobuf.IBoolValue|null} [excludeSeries] StoryFilter excludeSeries
         * @property {google.protobuf.IBoolValue|null} [excludeNotSeries] StoryFilter excludeNotSeries
         * @property {google.protobuf.IBoolValue|null} [includeHidden] StoryFilter includeHidden
         */

        /**
         * Constructs a new StoryFilter.
         * @memberof query_types
         * @classdesc Represents a StoryFilter.
         * @implements IStoryFilter
         * @constructor
         * @param {query_types.IStoryFilter=} [properties] Properties to set
         */
        function StoryFilter(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryFilter excludeAlreadyRead.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeAlreadyRead
         * @memberof query_types.StoryFilter
         * @instance
         */
        StoryFilter.prototype.excludeAlreadyRead = null;

        /**
         * StoryFilter includeBlocked.
         * @member {google.protobuf.IBoolValue|null|undefined} includeBlocked
         * @memberof query_types.StoryFilter
         * @instance
         */
        StoryFilter.prototype.includeBlocked = null;

        /**
         * StoryFilter excludeCompletedSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeCompletedSeries
         * @memberof query_types.StoryFilter
         * @instance
         */
        StoryFilter.prototype.excludeCompletedSeries = null;

        /**
         * StoryFilter excludeNotCompletedSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotCompletedSeries
         * @memberof query_types.StoryFilter
         * @instance
         */
        StoryFilter.prototype.excludeNotCompletedSeries = null;

        /**
         * StoryFilter excludeSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeSeries
         * @memberof query_types.StoryFilter
         * @instance
         */
        StoryFilter.prototype.excludeSeries = null;

        /**
         * StoryFilter excludeNotSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotSeries
         * @memberof query_types.StoryFilter
         * @instance
         */
        StoryFilter.prototype.excludeNotSeries = null;

        /**
         * StoryFilter includeHidden.
         * @member {google.protobuf.IBoolValue|null|undefined} includeHidden
         * @memberof query_types.StoryFilter
         * @instance
         */
        StoryFilter.prototype.includeHidden = null;

        /**
         * Creates a new StoryFilter instance using the specified properties.
         * @function create
         * @memberof query_types.StoryFilter
         * @static
         * @param {query_types.IStoryFilter=} [properties] Properties to set
         * @returns {query_types.StoryFilter} StoryFilter instance
         */
        StoryFilter.create = function create(properties) {
            return new StoryFilter(properties);
        };

        /**
         * Encodes the specified StoryFilter message. Does not implicitly {@link query_types.StoryFilter.verify|verify} messages.
         * @function encode
         * @memberof query_types.StoryFilter
         * @static
         * @param {query_types.IStoryFilter} message StoryFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.excludeAlreadyRead != null && Object.hasOwnProperty.call(message, "excludeAlreadyRead"))
                $root.google.protobuf.BoolValue.encode(message.excludeAlreadyRead, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.includeBlocked != null && Object.hasOwnProperty.call(message, "includeBlocked"))
                $root.google.protobuf.BoolValue.encode(message.includeBlocked, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.excludeCompletedSeries != null && Object.hasOwnProperty.call(message, "excludeCompletedSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeCompletedSeries, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.excludeNotCompletedSeries != null && Object.hasOwnProperty.call(message, "excludeNotCompletedSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotCompletedSeries, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.includeHidden != null && Object.hasOwnProperty.call(message, "includeHidden"))
                $root.google.protobuf.BoolValue.encode(message.includeHidden, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.excludeSeries != null && Object.hasOwnProperty.call(message, "excludeSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeSeries, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.excludeNotSeries != null && Object.hasOwnProperty.call(message, "excludeNotSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotSeries, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryFilter message from the specified reader or buffer.
         * @function decode
         * @memberof query_types.StoryFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_types.StoryFilter} StoryFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_types.StoryFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.excludeAlreadyRead = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.includeBlocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.excludeCompletedSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.excludeNotCompletedSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.excludeSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.excludeNotSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.includeHidden = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_types.StoryFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_types.StoryFilter} StoryFilter
         */
        StoryFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.query_types.StoryFilter)
                return object;
            var message = new $root.query_types.StoryFilter();
            if (object.excludeAlreadyRead != null) {
                if (typeof object.excludeAlreadyRead !== "object")
                    throw TypeError(".query_types.StoryFilter.excludeAlreadyRead: object expected");
                message.excludeAlreadyRead = $root.google.protobuf.BoolValue.fromObject(object.excludeAlreadyRead);
            }
            if (object.includeBlocked != null) {
                if (typeof object.includeBlocked !== "object")
                    throw TypeError(".query_types.StoryFilter.includeBlocked: object expected");
                message.includeBlocked = $root.google.protobuf.BoolValue.fromObject(object.includeBlocked);
            }
            if (object.excludeCompletedSeries != null) {
                if (typeof object.excludeCompletedSeries !== "object")
                    throw TypeError(".query_types.StoryFilter.excludeCompletedSeries: object expected");
                message.excludeCompletedSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeCompletedSeries);
            }
            if (object.excludeNotCompletedSeries != null) {
                if (typeof object.excludeNotCompletedSeries !== "object")
                    throw TypeError(".query_types.StoryFilter.excludeNotCompletedSeries: object expected");
                message.excludeNotCompletedSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeNotCompletedSeries);
            }
            if (object.excludeSeries != null) {
                if (typeof object.excludeSeries !== "object")
                    throw TypeError(".query_types.StoryFilter.excludeSeries: object expected");
                message.excludeSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeSeries);
            }
            if (object.excludeNotSeries != null) {
                if (typeof object.excludeNotSeries !== "object")
                    throw TypeError(".query_types.StoryFilter.excludeNotSeries: object expected");
                message.excludeNotSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeNotSeries);
            }
            if (object.includeHidden != null) {
                if (typeof object.includeHidden !== "object")
                    throw TypeError(".query_types.StoryFilter.includeHidden: object expected");
                message.includeHidden = $root.google.protobuf.BoolValue.fromObject(object.includeHidden);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_types.StoryFilter
         * @static
         * @param {query_types.StoryFilter} message StoryFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.excludeAlreadyRead = null;
                object.includeBlocked = null;
                object.excludeCompletedSeries = null;
                object.excludeNotCompletedSeries = null;
                object.includeHidden = null;
                object.excludeSeries = null;
                object.excludeNotSeries = null;
            }
            if (message.excludeAlreadyRead != null && message.hasOwnProperty("excludeAlreadyRead"))
                object.excludeAlreadyRead = $root.google.protobuf.BoolValue.toObject(message.excludeAlreadyRead, options);
            if (message.includeBlocked != null && message.hasOwnProperty("includeBlocked"))
                object.includeBlocked = $root.google.protobuf.BoolValue.toObject(message.includeBlocked, options);
            if (message.excludeCompletedSeries != null && message.hasOwnProperty("excludeCompletedSeries"))
                object.excludeCompletedSeries = $root.google.protobuf.BoolValue.toObject(message.excludeCompletedSeries, options);
            if (message.excludeNotCompletedSeries != null && message.hasOwnProperty("excludeNotCompletedSeries"))
                object.excludeNotCompletedSeries = $root.google.protobuf.BoolValue.toObject(message.excludeNotCompletedSeries, options);
            if (message.includeHidden != null && message.hasOwnProperty("includeHidden"))
                object.includeHidden = $root.google.protobuf.BoolValue.toObject(message.includeHidden, options);
            if (message.excludeSeries != null && message.hasOwnProperty("excludeSeries"))
                object.excludeSeries = $root.google.protobuf.BoolValue.toObject(message.excludeSeries, options);
            if (message.excludeNotSeries != null && message.hasOwnProperty("excludeNotSeries"))
                object.excludeNotSeries = $root.google.protobuf.BoolValue.toObject(message.excludeNotSeries, options);
            return object;
        };

        /**
         * Converts this StoryFilter to JSON.
         * @function toJSON
         * @memberof query_types.StoryFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryFilter
         * @function getTypeUrl
         * @memberof query_types.StoryFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_types.StoryFilter";
        };

        return StoryFilter;
    })();

    /**
     * StoryScriptType enum.
     * @name query_types.StoryScriptType
     * @enum {number}
     * @property {number} STORY_SCRIPT_TYPE_UNSPECIFIED=0 STORY_SCRIPT_TYPE_UNSPECIFIED value
     * @property {number} STORY_SCRIPT_TYPE_CHAT_NOVEL=1 STORY_SCRIPT_TYPE_CHAT_NOVEL value
     * @property {number} STORY_SCRIPT_TYPE_NOVEL=2 STORY_SCRIPT_TYPE_NOVEL value
     * @property {number} STORY_SCRIPT_TYPE_VIDEO=3 STORY_SCRIPT_TYPE_VIDEO value
     * @property {number} STORY_SCRIPT_TYPE_AUDIO=4 STORY_SCRIPT_TYPE_AUDIO value
     */
    query_types.StoryScriptType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_SCRIPT_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STORY_SCRIPT_TYPE_CHAT_NOVEL"] = 1;
        values[valuesById[2] = "STORY_SCRIPT_TYPE_NOVEL"] = 2;
        values[valuesById[3] = "STORY_SCRIPT_TYPE_VIDEO"] = 3;
        values[valuesById[4] = "STORY_SCRIPT_TYPE_AUDIO"] = 4;
        return values;
    })();

    /**
     * SensitiveFlag enum.
     * @name query_types.SensitiveFlag
     * @enum {number}
     * @property {number} SENSITIVE_FLAG_UNSPECIFIED=0 SENSITIVE_FLAG_UNSPECIFIED value
     * @property {number} SENSITIVE_FLAG_ENABLED_BY_USER=1 SENSITIVE_FLAG_ENABLED_BY_USER value
     * @property {number} SENSITIVE_FLAG_ENABLED_BY_ADMIN=2 SENSITIVE_FLAG_ENABLED_BY_ADMIN value
     */
    query_types.SensitiveFlag = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SENSITIVE_FLAG_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SENSITIVE_FLAG_ENABLED_BY_USER"] = 1;
        values[valuesById[2] = "SENSITIVE_FLAG_ENABLED_BY_ADMIN"] = 2;
        return values;
    })();

    /**
     * UserRole enum.
     * @name query_types.UserRole
     * @enum {number}
     * @property {number} USER_ROLE_UNSPECIFIED=0 USER_ROLE_UNSPECIFIED value
     * @property {number} USER_ROLE_PLAIN=1 USER_ROLE_PLAIN value
     * @property {number} USER_ROLE_OFFICIAL_WRITER=2 USER_ROLE_OFFICIAL_WRITER value
     * @property {number} USER_ROLE_BANNED=3 USER_ROLE_BANNED value
     * @property {number} USER_ROLE_ADMIN=4 USER_ROLE_ADMIN value
     */
    query_types.UserRole = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USER_ROLE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "USER_ROLE_PLAIN"] = 1;
        values[valuesById[2] = "USER_ROLE_OFFICIAL_WRITER"] = 2;
        values[valuesById[3] = "USER_ROLE_BANNED"] = 3;
        values[valuesById[4] = "USER_ROLE_ADMIN"] = 4;
        return values;
    })();

    /**
     * VipStatus enum.
     * @name query_types.VipStatus
     * @enum {number}
     * @property {number} VIP_STATUS_UNSPECIFIED=0 VIP_STATUS_UNSPECIFIED value
     * @property {number} VIP_STATUS_NONE=1 VIP_STATUS_NONE value
     * @property {number} VIP_STATUS_IN_TRIAL=2 VIP_STATUS_IN_TRIAL value
     * @property {number} VIP_STATUS_VIP=3 VIP_STATUS_VIP value
     */
    query_types.VipStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "VIP_STATUS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "VIP_STATUS_NONE"] = 1;
        values[valuesById[2] = "VIP_STATUS_IN_TRIAL"] = 2;
        values[valuesById[3] = "VIP_STATUS_VIP"] = 3;
        return values;
    })();

    /**
     * VipPlan enum.
     * @name query_types.VipPlan
     * @enum {number}
     * @property {number} VIP_PLAN_UNSPECIFIED=0 VIP_PLAN_UNSPECIFIED value
     * @property {number} VIP_PLAN_LITE=1 VIP_PLAN_LITE value
     * @property {number} VIP_PLAN_STANDARD=2 VIP_PLAN_STANDARD value
     */
    query_types.VipPlan = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "VIP_PLAN_UNSPECIFIED"] = 0;
        values[valuesById[1] = "VIP_PLAN_LITE"] = 1;
        values[valuesById[2] = "VIP_PLAN_STANDARD"] = 2;
        return values;
    })();

    query_types.VipMembership = (function() {

        /**
         * Properties of a VipMembership.
         * @memberof query_types
         * @interface IVipMembership
         * @property {query_types.VipPlan|null} [plan] VipMembership plan
         * @property {google.protobuf.IBoolValue|null} [inTrial] VipMembership inTrial
         */

        /**
         * Constructs a new VipMembership.
         * @memberof query_types
         * @classdesc Represents a VipMembership.
         * @implements IVipMembership
         * @constructor
         * @param {query_types.IVipMembership=} [properties] Properties to set
         */
        function VipMembership(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VipMembership plan.
         * @member {query_types.VipPlan} plan
         * @memberof query_types.VipMembership
         * @instance
         */
        VipMembership.prototype.plan = 0;

        /**
         * VipMembership inTrial.
         * @member {google.protobuf.IBoolValue|null|undefined} inTrial
         * @memberof query_types.VipMembership
         * @instance
         */
        VipMembership.prototype.inTrial = null;

        /**
         * Creates a new VipMembership instance using the specified properties.
         * @function create
         * @memberof query_types.VipMembership
         * @static
         * @param {query_types.IVipMembership=} [properties] Properties to set
         * @returns {query_types.VipMembership} VipMembership instance
         */
        VipMembership.create = function create(properties) {
            return new VipMembership(properties);
        };

        /**
         * Encodes the specified VipMembership message. Does not implicitly {@link query_types.VipMembership.verify|verify} messages.
         * @function encode
         * @memberof query_types.VipMembership
         * @static
         * @param {query_types.IVipMembership} message VipMembership message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VipMembership.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.plan != null && Object.hasOwnProperty.call(message, "plan"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.plan);
            if (message.inTrial != null && Object.hasOwnProperty.call(message, "inTrial"))
                $root.google.protobuf.BoolValue.encode(message.inTrial, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a VipMembership message from the specified reader or buffer.
         * @function decode
         * @memberof query_types.VipMembership
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_types.VipMembership} VipMembership
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VipMembership.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_types.VipMembership();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.plan = reader.int32();
                    break;
                case 2:
                    message.inTrial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a VipMembership message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_types.VipMembership
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_types.VipMembership} VipMembership
         */
        VipMembership.fromObject = function fromObject(object) {
            if (object instanceof $root.query_types.VipMembership)
                return object;
            var message = new $root.query_types.VipMembership();
            switch (object.plan) {
            case "VIP_PLAN_UNSPECIFIED":
            case 0:
                message.plan = 0;
                break;
            case "VIP_PLAN_LITE":
            case 1:
                message.plan = 1;
                break;
            case "VIP_PLAN_STANDARD":
            case 2:
                message.plan = 2;
                break;
            }
            if (object.inTrial != null) {
                if (typeof object.inTrial !== "object")
                    throw TypeError(".query_types.VipMembership.inTrial: object expected");
                message.inTrial = $root.google.protobuf.BoolValue.fromObject(object.inTrial);
            }
            return message;
        };

        /**
         * Creates a plain object from a VipMembership message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_types.VipMembership
         * @static
         * @param {query_types.VipMembership} message VipMembership
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VipMembership.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.plan = options.enums === String ? "VIP_PLAN_UNSPECIFIED" : 0;
                object.inTrial = null;
            }
            if (message.plan != null && message.hasOwnProperty("plan"))
                object.plan = options.enums === String ? $root.query_types.VipPlan[message.plan] : message.plan;
            if (message.inTrial != null && message.hasOwnProperty("inTrial"))
                object.inTrial = $root.google.protobuf.BoolValue.toObject(message.inTrial, options);
            return object;
        };

        /**
         * Converts this VipMembership to JSON.
         * @function toJSON
         * @memberof query_types.VipMembership
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VipMembership.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VipMembership
         * @function getTypeUrl
         * @memberof query_types.VipMembership
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VipMembership.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_types.VipMembership";
        };

        return VipMembership;
    })();

    /**
     * StoryAvailability enum.
     * @name query_types.StoryAvailability
     * @enum {number}
     * @property {number} STORY_AVAILABILITY_UNSPECIFIED=0 STORY_AVAILABILITY_UNSPECIFIED value
     * @property {number} STORY_AVAILABILITY_REQUIRE_VIP=1 STORY_AVAILABILITY_REQUIRE_VIP value
     * @property {number} STORY_AVAILABILITY_REQUIRE_WAIT=2 STORY_AVAILABILITY_REQUIRE_WAIT value
     * @property {number} STORY_AVAILABILITY_REQUIRE_NONE=3 STORY_AVAILABILITY_REQUIRE_NONE value
     * @property {number} STORY_AVAILABILITY_REQUIRE_FOLLOWING=4 STORY_AVAILABILITY_REQUIRE_FOLLOWING value
     * @property {number} STORY_AVAILABILITY_REQUIRE_FOLLOWED=5 STORY_AVAILABILITY_REQUIRE_FOLLOWED value
     * @property {number} STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING=6 STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING value
     */
    query_types.StoryAvailability = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_AVAILABILITY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STORY_AVAILABILITY_REQUIRE_VIP"] = 1;
        values[valuesById[2] = "STORY_AVAILABILITY_REQUIRE_WAIT"] = 2;
        values[valuesById[3] = "STORY_AVAILABILITY_REQUIRE_NONE"] = 3;
        values[valuesById[4] = "STORY_AVAILABILITY_REQUIRE_FOLLOWING"] = 4;
        values[valuesById[5] = "STORY_AVAILABILITY_REQUIRE_FOLLOWED"] = 5;
        values[valuesById[6] = "STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING"] = 6;
        return values;
    })();

    /**
     * PublishSchedule enum.
     * @name query_types.PublishSchedule
     * @enum {number}
     * @property {number} PUBLISH_SCHEDULE_UNSPECIFIED=0 PUBLISH_SCHEDULE_UNSPECIFIED value
     * @property {number} PUBLISH_SCHEDULE_EVARY_DAY=1 PUBLISH_SCHEDULE_EVARY_DAY value
     * @property {number} PUBLISH_SCHEDULE_EVERY_SUNDAY=2 PUBLISH_SCHEDULE_EVERY_SUNDAY value
     * @property {number} PUBLISH_SCHEDULE_EVERY_MONDAY=3 PUBLISH_SCHEDULE_EVERY_MONDAY value
     * @property {number} PUBLISH_SCHEDULE_EVERY_TUESDAY=4 PUBLISH_SCHEDULE_EVERY_TUESDAY value
     * @property {number} PUBLISH_SCHEDULE_EVERY_WEDNESDAY=5 PUBLISH_SCHEDULE_EVERY_WEDNESDAY value
     * @property {number} PUBLISH_SCHEDULE_EVERY_THURSDAY=6 PUBLISH_SCHEDULE_EVERY_THURSDAY value
     * @property {number} PUBLISH_SCHEDULE_EVERY_FRIDAY=7 PUBLISH_SCHEDULE_EVERY_FRIDAY value
     * @property {number} PUBLISH_SCHEDULE_EVERY_SATURDAY=8 PUBLISH_SCHEDULE_EVERY_SATURDAY value
     */
    query_types.PublishSchedule = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PUBLISH_SCHEDULE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PUBLISH_SCHEDULE_EVARY_DAY"] = 1;
        values[valuesById[2] = "PUBLISH_SCHEDULE_EVERY_SUNDAY"] = 2;
        values[valuesById[3] = "PUBLISH_SCHEDULE_EVERY_MONDAY"] = 3;
        values[valuesById[4] = "PUBLISH_SCHEDULE_EVERY_TUESDAY"] = 4;
        values[valuesById[5] = "PUBLISH_SCHEDULE_EVERY_WEDNESDAY"] = 5;
        values[valuesById[6] = "PUBLISH_SCHEDULE_EVERY_THURSDAY"] = 6;
        values[valuesById[7] = "PUBLISH_SCHEDULE_EVERY_FRIDAY"] = 7;
        values[valuesById[8] = "PUBLISH_SCHEDULE_EVERY_SATURDAY"] = 8;
        return values;
    })();

    /**
     * SeriesAvailability enum.
     * @name query_types.SeriesAvailability
     * @enum {number}
     * @property {number} SERIES_AVAILABILITY_UNSPECIFIED=0 SERIES_AVAILABILITY_UNSPECIFIED value
     * @property {number} SERIES_AVAILABILITY_REQUIRE_FOLLOWING=1 SERIES_AVAILABILITY_REQUIRE_FOLLOWING value
     * @property {number} SERIES_AVAILABILITY_REQUIRE_FOLLOWED=2 SERIES_AVAILABILITY_REQUIRE_FOLLOWED value
     * @property {number} SERIES_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING=3 SERIES_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING value
     * @property {number} SERIES_AVAILABILITY_REQUIRE_NONE=4 SERIES_AVAILABILITY_REQUIRE_NONE value
     */
    query_types.SeriesAvailability = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERIES_AVAILABILITY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SERIES_AVAILABILITY_REQUIRE_FOLLOWING"] = 1;
        values[valuesById[2] = "SERIES_AVAILABILITY_REQUIRE_FOLLOWED"] = 2;
        values[valuesById[3] = "SERIES_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING"] = 3;
        values[valuesById[4] = "SERIES_AVAILABILITY_REQUIRE_NONE"] = 4;
        return values;
    })();

    /**
     * OriginalFlag enum.
     * @name query_types.OriginalFlag
     * @enum {number}
     * @property {number} ORIGINAL_FLAG_UNSPECIFIED=0 ORIGINAL_FLAG_UNSPECIFIED value
     * @property {number} ORIGINAL_FLAG_ORIGINAL=1 ORIGINAL_FLAG_ORIGINAL value
     * @property {number} ORIGINAL_FLAG_FANFIC=2 ORIGINAL_FLAG_FANFIC value
     * @property {number} ORIGINAL_FLAG_OTHER=3 ORIGINAL_FLAG_OTHER value
     */
    query_types.OriginalFlag = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ORIGINAL_FLAG_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ORIGINAL_FLAG_ORIGINAL"] = 1;
        values[valuesById[2] = "ORIGINAL_FLAG_FANFIC"] = 2;
        values[valuesById[3] = "ORIGINAL_FLAG_OTHER"] = 3;
        return values;
    })();

    /**
     * Genre enum.
     * @name query_types.Genre
     * @enum {number}
     * @property {number} GENRE_UNSPECIFIED=0 GENRE_UNSPECIFIED value
     * @property {number} GENRE_FANFIC=1 GENRE_FANFIC value
     * @property {number} GENRE_OTHER=2 GENRE_OTHER value
     * @property {number} GENRE_HORROR_THRILLER=3 GENRE_HORROR_THRILLER value
     * @property {number} GENRE_ROMANCE_FANTASY=4 GENRE_ROMANCE_FANTASY value
     * @property {number} GENRE_BL=5 GENRE_BL value
     * @property {number} GENRE_DRAMA=6 GENRE_DRAMA value
     * @property {number} GENRE_ROMANCE=7 GENRE_ROMANCE value
     * @property {number} GENRE_FANTASY=8 GENRE_FANTASY value
     * @property {number} GENRE_COMEDY=9 GENRE_COMEDY value
     */
    query_types.Genre = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "GENRE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "GENRE_FANFIC"] = 1;
        values[valuesById[2] = "GENRE_OTHER"] = 2;
        values[valuesById[3] = "GENRE_HORROR_THRILLER"] = 3;
        values[valuesById[4] = "GENRE_ROMANCE_FANTASY"] = 4;
        values[valuesById[5] = "GENRE_BL"] = 5;
        values[valuesById[6] = "GENRE_DRAMA"] = 6;
        values[valuesById[7] = "GENRE_ROMANCE"] = 7;
        values[valuesById[8] = "GENRE_FANTASY"] = 8;
        values[valuesById[9] = "GENRE_COMEDY"] = 9;
        return values;
    })();

    /**
     * Trend enum.
     * @name query_types.Trend
     * @enum {number}
     * @property {number} TREND_UNSPECIFIED=0 TREND_UNSPECIFIED value
     * @property {number} TREND_UP=1 TREND_UP value
     * @property {number} TREND_DOWN=2 TREND_DOWN value
     * @property {number} TREND_STAY=3 TREND_STAY value
     */
    query_types.Trend = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TREND_UNSPECIFIED"] = 0;
        values[valuesById[1] = "TREND_UP"] = 1;
        values[valuesById[2] = "TREND_DOWN"] = 2;
        values[valuesById[3] = "TREND_STAY"] = 3;
        return values;
    })();

    return query_types;
})();

$root.notification = (function() {

    /**
     * Namespace notification.
     * @exports notification
     * @namespace
     */
    var notification = {};

    notification.NotificationV2 = (function() {

        /**
         * Properties of a NotificationV2.
         * @memberof notification
         * @interface INotificationV2
         * @property {google.protobuf.IStringValue|null} [message] NotificationV2 message
         * @property {google.protobuf.IStringValue|null} [content] NotificationV2 content
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationV2 actionUrl
         * @property {google.protobuf.IStringValue|null} [imageUrl] NotificationV2 imageUrl
         * @property {notification.NotificationType|null} [notificationType] NotificationV2 notificationType
         * @property {Array.<google.protobuf.IStringValue>|null} [iconUrls] NotificationV2 iconUrls
         * @property {google.protobuf.ITimestamp|null} [createdAt] NotificationV2 createdAt
         * @property {google.protobuf.IStringValue|null} [id] NotificationV2 id
         * @property {google.protobuf.IStringValue|null} [title] NotificationV2 title
         * @property {notification.INotificationMetadata|null} [metadata] NotificationV2 metadata
         */

        /**
         * Constructs a new NotificationV2.
         * @memberof notification
         * @classdesc Represents a NotificationV2.
         * @implements INotificationV2
         * @constructor
         * @param {notification.INotificationV2=} [properties] Properties to set
         */
        function NotificationV2(properties) {
            this.iconUrls = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationV2 message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.message = null;

        /**
         * NotificationV2 content.
         * @member {google.protobuf.IStringValue|null|undefined} content
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.content = null;

        /**
         * NotificationV2 actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.actionUrl = null;

        /**
         * NotificationV2 imageUrl.
         * @member {google.protobuf.IStringValue|null|undefined} imageUrl
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.imageUrl = null;

        /**
         * NotificationV2 notificationType.
         * @member {notification.NotificationType} notificationType
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.notificationType = 0;

        /**
         * NotificationV2 iconUrls.
         * @member {Array.<google.protobuf.IStringValue>} iconUrls
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.iconUrls = $util.emptyArray;

        /**
         * NotificationV2 createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.createdAt = null;

        /**
         * NotificationV2 id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.id = null;

        /**
         * NotificationV2 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.title = null;

        /**
         * NotificationV2 metadata.
         * @member {notification.INotificationMetadata|null|undefined} metadata
         * @memberof notification.NotificationV2
         * @instance
         */
        NotificationV2.prototype.metadata = null;

        /**
         * Creates a new NotificationV2 instance using the specified properties.
         * @function create
         * @memberof notification.NotificationV2
         * @static
         * @param {notification.INotificationV2=} [properties] Properties to set
         * @returns {notification.NotificationV2} NotificationV2 instance
         */
        NotificationV2.create = function create(properties) {
            return new NotificationV2(properties);
        };

        /**
         * Encodes the specified NotificationV2 message. Does not implicitly {@link notification.NotificationV2.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationV2
         * @static
         * @param {notification.INotificationV2} message NotificationV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                $root.google.protobuf.StringValue.encode(message.content, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                $root.google.protobuf.StringValue.encode(message.imageUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.notificationType != null && Object.hasOwnProperty.call(message, "notificationType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.notificationType);
            if (message.iconUrls != null && message.iconUrls.length)
                for (var i = 0; i < message.iconUrls.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.iconUrls[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                $root.notification.NotificationMetadata.encode(message.metadata, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationV2 message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationV2} NotificationV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.content = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.imageUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.notificationType = reader.int32();
                    break;
                case 6:
                    if (!(message.iconUrls && message.iconUrls.length))
                        message.iconUrls = [];
                    message.iconUrls.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.metadata = $root.notification.NotificationMetadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationV2} NotificationV2
         */
        NotificationV2.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationV2)
                return object;
            var message = new $root.notification.NotificationV2();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationV2.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.content != null) {
                if (typeof object.content !== "object")
                    throw TypeError(".notification.NotificationV2.content: object expected");
                message.content = $root.google.protobuf.StringValue.fromObject(object.content);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationV2.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.imageUrl != null) {
                if (typeof object.imageUrl !== "object")
                    throw TypeError(".notification.NotificationV2.imageUrl: object expected");
                message.imageUrl = $root.google.protobuf.StringValue.fromObject(object.imageUrl);
            }
            switch (object.notificationType) {
            case "NOTIFICATION_TYPE_UNSPECIFIED":
            case 0:
                message.notificationType = 0;
                break;
            case "NOTIFICATION_TYPE_OPERATION":
            case 1:
                message.notificationType = 1;
                break;
            case "NOTIFICATION_TYPE_STORY_REJECTED":
            case 2:
                message.notificationType = 2;
                break;
            case "NOTIFICATION_TYPE_USER_LIKE_BREAK":
            case 3:
                message.notificationType = 3;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK":
            case 4:
                message.notificationType = 4;
                break;
            case "NOTIFICATION_TYPE_USER_BANNED":
            case 5:
                message.notificationType = 5;
                break;
            case "NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS":
            case 8:
                message.notificationType = 8;
                break;
            case "NOTIFICATION_TYPE_NEW_FOLLOWER":
            case 10:
                message.notificationType = 10;
                break;
            case "NOTIFICATION_TYPE_NEW_STORY_COMMENT":
            case 11:
                message.notificationType = 11;
                break;
            case "NOTIFICATION_TYPE_NEW_COMMENT_LIKE":
            case 12:
                message.notificationType = 12;
                break;
            case "NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE":
            case 13:
                message.notificationType = 13;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY":
            case 15:
                message.notificationType = 15;
                break;
            case "NOTIFICATION_TYPE_NEW_MEDAL":
            case 21:
                message.notificationType = 21;
                break;
            case "NOTIFICATION_TYPE_PROMOTION_OFFER":
            case 22:
                message.notificationType = 22;
                break;
            case "NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY":
            case 24:
                message.notificationType = 24;
                break;
            case "NOTIFICATION_TYPE_STORY_REMINDER":
            case 26:
                message.notificationType = 26;
                break;
            case "NOTIFICATION_TYPE_PAW_INTRODUCTION":
            case 27:
                message.notificationType = 27;
                break;
            case "NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE":
            case 29:
                message.notificationType = 29;
                break;
            case "NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED":
            case 30:
                message.notificationType = 30;
                break;
            case "NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER":
            case 31:
                message.notificationType = 31;
                break;
            case "NOTIFICATION_TYPE_NEW_SEASON":
            case 32:
                message.notificationType = 32;
                break;
            case "NOTIFICATION_TYPE_NEXT_SEASON_REMINDER":
            case 33:
                message.notificationType = 33;
                break;
            case "NOTIFICATION_TYPE_STORY_VIDEO_CREATED":
            case 34:
                message.notificationType = 34;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_NEW_LIKES":
            case 35:
                message.notificationType = 35;
                break;
            case "NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST":
            case 36:
                message.notificationType = 36;
                break;
            case "NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES":
            case 6:
                message.notificationType = 6;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS":
            case 7:
                message.notificationType = 7;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES":
            case 9:
                message.notificationType = 9;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS":
            case 14:
                message.notificationType = 14;
                break;
            case "NOTIFICATION_TYPE_STORY_IN_TAG_RANKING":
            case 16:
                message.notificationType = 16;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK":
            case 17:
                message.notificationType = 17;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES":
            case 18:
                message.notificationType = 18;
                break;
            case "NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK":
            case 19:
                message.notificationType = 19;
                break;
            case "NOTIFICATION_TYPE_PAW_RECOVERED":
            case 20:
                message.notificationType = 20;
                break;
            case "NOTIFICATION_TYPE_NEW_BOOKMARK":
            case 23:
                message.notificationType = 23;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK":
            case 25:
                message.notificationType = 25;
                break;
            case "NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER":
            case 28:
                message.notificationType = 28;
                break;
            }
            if (object.iconUrls) {
                if (!Array.isArray(object.iconUrls))
                    throw TypeError(".notification.NotificationV2.iconUrls: array expected");
                message.iconUrls = [];
                for (var i = 0; i < object.iconUrls.length; ++i) {
                    if (typeof object.iconUrls[i] !== "object")
                        throw TypeError(".notification.NotificationV2.iconUrls: object expected");
                    message.iconUrls[i] = $root.google.protobuf.StringValue.fromObject(object.iconUrls[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".notification.NotificationV2.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".notification.NotificationV2.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".notification.NotificationV2.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.metadata != null) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".notification.NotificationV2.metadata: object expected");
                message.metadata = $root.notification.NotificationMetadata.fromObject(object.metadata);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationV2
         * @static
         * @param {notification.NotificationV2} message NotificationV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.iconUrls = [];
            if (options.defaults) {
                object.message = null;
                object.content = null;
                object.actionUrl = null;
                object.imageUrl = null;
                object.notificationType = options.enums === String ? "NOTIFICATION_TYPE_UNSPECIFIED" : 0;
                object.createdAt = null;
                object.id = null;
                object.title = null;
                object.metadata = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = $root.google.protobuf.StringValue.toObject(message.content, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                object.imageUrl = $root.google.protobuf.StringValue.toObject(message.imageUrl, options);
            if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                object.notificationType = options.enums === String ? $root.notification.NotificationType[message.notificationType] : message.notificationType;
            if (message.iconUrls && message.iconUrls.length) {
                object.iconUrls = [];
                for (var j = 0; j < message.iconUrls.length; ++j)
                    object.iconUrls[j] = $root.google.protobuf.StringValue.toObject(message.iconUrls[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                object.metadata = $root.notification.NotificationMetadata.toObject(message.metadata, options);
            return object;
        };

        /**
         * Converts this NotificationV2 to JSON.
         * @function toJSON
         * @memberof notification.NotificationV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationV2
         * @function getTypeUrl
         * @memberof notification.NotificationV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationV2";
        };

        return NotificationV2;
    })();

    notification.NotificationMetadata = (function() {

        /**
         * Properties of a NotificationMetadata.
         * @memberof notification
         * @interface INotificationMetadata
         * @property {google.protobuf.IStringValue|null} [storyId] NotificationMetadata storyId
         * @property {google.protobuf.IStringValue|null} [seriesId] NotificationMetadata seriesId
         */

        /**
         * Constructs a new NotificationMetadata.
         * @memberof notification
         * @classdesc Represents a NotificationMetadata.
         * @implements INotificationMetadata
         * @constructor
         * @param {notification.INotificationMetadata=} [properties] Properties to set
         */
        function NotificationMetadata(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationMetadata storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof notification.NotificationMetadata
         * @instance
         */
        NotificationMetadata.prototype.storyId = null;

        /**
         * NotificationMetadata seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof notification.NotificationMetadata
         * @instance
         */
        NotificationMetadata.prototype.seriesId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * NotificationMetadata content.
         * @member {"storyId"|"seriesId"|undefined} content
         * @memberof notification.NotificationMetadata
         * @instance
         */
        Object.defineProperty(NotificationMetadata.prototype, "content", {
            get: $util.oneOfGetter($oneOfFields = ["storyId", "seriesId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new NotificationMetadata instance using the specified properties.
         * @function create
         * @memberof notification.NotificationMetadata
         * @static
         * @param {notification.INotificationMetadata=} [properties] Properties to set
         * @returns {notification.NotificationMetadata} NotificationMetadata instance
         */
        NotificationMetadata.create = function create(properties) {
            return new NotificationMetadata(properties);
        };

        /**
         * Encodes the specified NotificationMetadata message. Does not implicitly {@link notification.NotificationMetadata.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationMetadata
         * @static
         * @param {notification.INotificationMetadata} message NotificationMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationMetadata} NotificationMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationMetadata();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationMetadata} NotificationMetadata
         */
        NotificationMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationMetadata)
                return object;
            var message = new $root.notification.NotificationMetadata();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".notification.NotificationMetadata.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".notification.NotificationMetadata.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationMetadata
         * @static
         * @param {notification.NotificationMetadata} message NotificationMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.storyId != null && message.hasOwnProperty("storyId")) {
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
                if (options.oneofs)
                    object.content = "storyId";
            }
            if (message.seriesId != null && message.hasOwnProperty("seriesId")) {
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
                if (options.oneofs)
                    object.content = "seriesId";
            }
            return object;
        };

        /**
         * Converts this NotificationMetadata to JSON.
         * @function toJSON
         * @memberof notification.NotificationMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationMetadata
         * @function getTypeUrl
         * @memberof notification.NotificationMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationMetadata";
        };

        return NotificationMetadata;
    })();

    notification.NotificationPreference = (function() {

        /**
         * Properties of a NotificationPreference.
         * @memberof notification
         * @interface INotificationPreference
         * @property {Array.<notification.NotificationType>|null} [refusedPushNotificationTypeList] NotificationPreference refusedPushNotificationTypeList
         * @property {Array.<notification.NotificationType>|null} [acceptedPushNotificationTypeList] NotificationPreference acceptedPushNotificationTypeList
         * @property {google.protobuf.ITimestamp|null} [updatedAt] NotificationPreference updatedAt
         */

        /**
         * Constructs a new NotificationPreference.
         * @memberof notification
         * @classdesc Represents a NotificationPreference.
         * @implements INotificationPreference
         * @constructor
         * @param {notification.INotificationPreference=} [properties] Properties to set
         */
        function NotificationPreference(properties) {
            this.refusedPushNotificationTypeList = [];
            this.acceptedPushNotificationTypeList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationPreference refusedPushNotificationTypeList.
         * @member {Array.<notification.NotificationType>} refusedPushNotificationTypeList
         * @memberof notification.NotificationPreference
         * @instance
         */
        NotificationPreference.prototype.refusedPushNotificationTypeList = $util.emptyArray;

        /**
         * NotificationPreference acceptedPushNotificationTypeList.
         * @member {Array.<notification.NotificationType>} acceptedPushNotificationTypeList
         * @memberof notification.NotificationPreference
         * @instance
         */
        NotificationPreference.prototype.acceptedPushNotificationTypeList = $util.emptyArray;

        /**
         * NotificationPreference updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof notification.NotificationPreference
         * @instance
         */
        NotificationPreference.prototype.updatedAt = null;

        /**
         * Creates a new NotificationPreference instance using the specified properties.
         * @function create
         * @memberof notification.NotificationPreference
         * @static
         * @param {notification.INotificationPreference=} [properties] Properties to set
         * @returns {notification.NotificationPreference} NotificationPreference instance
         */
        NotificationPreference.create = function create(properties) {
            return new NotificationPreference(properties);
        };

        /**
         * Encodes the specified NotificationPreference message. Does not implicitly {@link notification.NotificationPreference.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationPreference
         * @static
         * @param {notification.INotificationPreference} message NotificationPreference message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationPreference.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refusedPushNotificationTypeList != null && message.refusedPushNotificationTypeList.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.refusedPushNotificationTypeList.length; ++i)
                    writer.int32(message.refusedPushNotificationTypeList[i]);
                writer.ldelim();
            }
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.acceptedPushNotificationTypeList != null && message.acceptedPushNotificationTypeList.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.acceptedPushNotificationTypeList.length; ++i)
                    writer.int32(message.acceptedPushNotificationTypeList[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a NotificationPreference message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationPreference
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationPreference} NotificationPreference
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationPreference.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationPreference();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.refusedPushNotificationTypeList && message.refusedPushNotificationTypeList.length))
                        message.refusedPushNotificationTypeList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.refusedPushNotificationTypeList.push(reader.int32());
                    } else
                        message.refusedPushNotificationTypeList.push(reader.int32());
                    break;
                case 3:
                    if (!(message.acceptedPushNotificationTypeList && message.acceptedPushNotificationTypeList.length))
                        message.acceptedPushNotificationTypeList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.acceptedPushNotificationTypeList.push(reader.int32());
                    } else
                        message.acceptedPushNotificationTypeList.push(reader.int32());
                    break;
                case 2:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationPreference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationPreference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationPreference} NotificationPreference
         */
        NotificationPreference.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationPreference)
                return object;
            var message = new $root.notification.NotificationPreference();
            if (object.refusedPushNotificationTypeList) {
                if (!Array.isArray(object.refusedPushNotificationTypeList))
                    throw TypeError(".notification.NotificationPreference.refusedPushNotificationTypeList: array expected");
                message.refusedPushNotificationTypeList = [];
                for (var i = 0; i < object.refusedPushNotificationTypeList.length; ++i)
                    switch (object.refusedPushNotificationTypeList[i]) {
                    default:
                    case "NOTIFICATION_TYPE_UNSPECIFIED":
                    case 0:
                        message.refusedPushNotificationTypeList[i] = 0;
                        break;
                    case "NOTIFICATION_TYPE_OPERATION":
                    case 1:
                        message.refusedPushNotificationTypeList[i] = 1;
                        break;
                    case "NOTIFICATION_TYPE_STORY_REJECTED":
                    case 2:
                        message.refusedPushNotificationTypeList[i] = 2;
                        break;
                    case "NOTIFICATION_TYPE_USER_LIKE_BREAK":
                    case 3:
                        message.refusedPushNotificationTypeList[i] = 3;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK":
                    case 4:
                        message.refusedPushNotificationTypeList[i] = 4;
                        break;
                    case "NOTIFICATION_TYPE_USER_BANNED":
                    case 5:
                        message.refusedPushNotificationTypeList[i] = 5;
                        break;
                    case "NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS":
                    case 8:
                        message.refusedPushNotificationTypeList[i] = 8;
                        break;
                    case "NOTIFICATION_TYPE_NEW_FOLLOWER":
                    case 10:
                        message.refusedPushNotificationTypeList[i] = 10;
                        break;
                    case "NOTIFICATION_TYPE_NEW_STORY_COMMENT":
                    case 11:
                        message.refusedPushNotificationTypeList[i] = 11;
                        break;
                    case "NOTIFICATION_TYPE_NEW_COMMENT_LIKE":
                    case 12:
                        message.refusedPushNotificationTypeList[i] = 12;
                        break;
                    case "NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE":
                    case 13:
                        message.refusedPushNotificationTypeList[i] = 13;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY":
                    case 15:
                        message.refusedPushNotificationTypeList[i] = 15;
                        break;
                    case "NOTIFICATION_TYPE_NEW_MEDAL":
                    case 21:
                        message.refusedPushNotificationTypeList[i] = 21;
                        break;
                    case "NOTIFICATION_TYPE_PROMOTION_OFFER":
                    case 22:
                        message.refusedPushNotificationTypeList[i] = 22;
                        break;
                    case "NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY":
                    case 24:
                        message.refusedPushNotificationTypeList[i] = 24;
                        break;
                    case "NOTIFICATION_TYPE_STORY_REMINDER":
                    case 26:
                        message.refusedPushNotificationTypeList[i] = 26;
                        break;
                    case "NOTIFICATION_TYPE_PAW_INTRODUCTION":
                    case 27:
                        message.refusedPushNotificationTypeList[i] = 27;
                        break;
                    case "NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE":
                    case 29:
                        message.refusedPushNotificationTypeList[i] = 29;
                        break;
                    case "NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED":
                    case 30:
                        message.refusedPushNotificationTypeList[i] = 30;
                        break;
                    case "NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER":
                    case 31:
                        message.refusedPushNotificationTypeList[i] = 31;
                        break;
                    case "NOTIFICATION_TYPE_NEW_SEASON":
                    case 32:
                        message.refusedPushNotificationTypeList[i] = 32;
                        break;
                    case "NOTIFICATION_TYPE_NEXT_SEASON_REMINDER":
                    case 33:
                        message.refusedPushNotificationTypeList[i] = 33;
                        break;
                    case "NOTIFICATION_TYPE_STORY_VIDEO_CREATED":
                    case 34:
                        message.refusedPushNotificationTypeList[i] = 34;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_NEW_LIKES":
                    case 35:
                        message.refusedPushNotificationTypeList[i] = 35;
                        break;
                    case "NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST":
                    case 36:
                        message.refusedPushNotificationTypeList[i] = 36;
                        break;
                    case "NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES":
                    case 6:
                        message.refusedPushNotificationTypeList[i] = 6;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS":
                    case 7:
                        message.refusedPushNotificationTypeList[i] = 7;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES":
                    case 9:
                        message.refusedPushNotificationTypeList[i] = 9;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS":
                    case 14:
                        message.refusedPushNotificationTypeList[i] = 14;
                        break;
                    case "NOTIFICATION_TYPE_STORY_IN_TAG_RANKING":
                    case 16:
                        message.refusedPushNotificationTypeList[i] = 16;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK":
                    case 17:
                        message.refusedPushNotificationTypeList[i] = 17;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES":
                    case 18:
                        message.refusedPushNotificationTypeList[i] = 18;
                        break;
                    case "NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK":
                    case 19:
                        message.refusedPushNotificationTypeList[i] = 19;
                        break;
                    case "NOTIFICATION_TYPE_PAW_RECOVERED":
                    case 20:
                        message.refusedPushNotificationTypeList[i] = 20;
                        break;
                    case "NOTIFICATION_TYPE_NEW_BOOKMARK":
                    case 23:
                        message.refusedPushNotificationTypeList[i] = 23;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK":
                    case 25:
                        message.refusedPushNotificationTypeList[i] = 25;
                        break;
                    case "NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER":
                    case 28:
                        message.refusedPushNotificationTypeList[i] = 28;
                        break;
                    }
            }
            if (object.acceptedPushNotificationTypeList) {
                if (!Array.isArray(object.acceptedPushNotificationTypeList))
                    throw TypeError(".notification.NotificationPreference.acceptedPushNotificationTypeList: array expected");
                message.acceptedPushNotificationTypeList = [];
                for (var i = 0; i < object.acceptedPushNotificationTypeList.length; ++i)
                    switch (object.acceptedPushNotificationTypeList[i]) {
                    default:
                    case "NOTIFICATION_TYPE_UNSPECIFIED":
                    case 0:
                        message.acceptedPushNotificationTypeList[i] = 0;
                        break;
                    case "NOTIFICATION_TYPE_OPERATION":
                    case 1:
                        message.acceptedPushNotificationTypeList[i] = 1;
                        break;
                    case "NOTIFICATION_TYPE_STORY_REJECTED":
                    case 2:
                        message.acceptedPushNotificationTypeList[i] = 2;
                        break;
                    case "NOTIFICATION_TYPE_USER_LIKE_BREAK":
                    case 3:
                        message.acceptedPushNotificationTypeList[i] = 3;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK":
                    case 4:
                        message.acceptedPushNotificationTypeList[i] = 4;
                        break;
                    case "NOTIFICATION_TYPE_USER_BANNED":
                    case 5:
                        message.acceptedPushNotificationTypeList[i] = 5;
                        break;
                    case "NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS":
                    case 8:
                        message.acceptedPushNotificationTypeList[i] = 8;
                        break;
                    case "NOTIFICATION_TYPE_NEW_FOLLOWER":
                    case 10:
                        message.acceptedPushNotificationTypeList[i] = 10;
                        break;
                    case "NOTIFICATION_TYPE_NEW_STORY_COMMENT":
                    case 11:
                        message.acceptedPushNotificationTypeList[i] = 11;
                        break;
                    case "NOTIFICATION_TYPE_NEW_COMMENT_LIKE":
                    case 12:
                        message.acceptedPushNotificationTypeList[i] = 12;
                        break;
                    case "NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE":
                    case 13:
                        message.acceptedPushNotificationTypeList[i] = 13;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY":
                    case 15:
                        message.acceptedPushNotificationTypeList[i] = 15;
                        break;
                    case "NOTIFICATION_TYPE_NEW_MEDAL":
                    case 21:
                        message.acceptedPushNotificationTypeList[i] = 21;
                        break;
                    case "NOTIFICATION_TYPE_PROMOTION_OFFER":
                    case 22:
                        message.acceptedPushNotificationTypeList[i] = 22;
                        break;
                    case "NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY":
                    case 24:
                        message.acceptedPushNotificationTypeList[i] = 24;
                        break;
                    case "NOTIFICATION_TYPE_STORY_REMINDER":
                    case 26:
                        message.acceptedPushNotificationTypeList[i] = 26;
                        break;
                    case "NOTIFICATION_TYPE_PAW_INTRODUCTION":
                    case 27:
                        message.acceptedPushNotificationTypeList[i] = 27;
                        break;
                    case "NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE":
                    case 29:
                        message.acceptedPushNotificationTypeList[i] = 29;
                        break;
                    case "NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED":
                    case 30:
                        message.acceptedPushNotificationTypeList[i] = 30;
                        break;
                    case "NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER":
                    case 31:
                        message.acceptedPushNotificationTypeList[i] = 31;
                        break;
                    case "NOTIFICATION_TYPE_NEW_SEASON":
                    case 32:
                        message.acceptedPushNotificationTypeList[i] = 32;
                        break;
                    case "NOTIFICATION_TYPE_NEXT_SEASON_REMINDER":
                    case 33:
                        message.acceptedPushNotificationTypeList[i] = 33;
                        break;
                    case "NOTIFICATION_TYPE_STORY_VIDEO_CREATED":
                    case 34:
                        message.acceptedPushNotificationTypeList[i] = 34;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_NEW_LIKES":
                    case 35:
                        message.acceptedPushNotificationTypeList[i] = 35;
                        break;
                    case "NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST":
                    case 36:
                        message.acceptedPushNotificationTypeList[i] = 36;
                        break;
                    case "NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES":
                    case 6:
                        message.acceptedPushNotificationTypeList[i] = 6;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS":
                    case 7:
                        message.acceptedPushNotificationTypeList[i] = 7;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES":
                    case 9:
                        message.acceptedPushNotificationTypeList[i] = 9;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS":
                    case 14:
                        message.acceptedPushNotificationTypeList[i] = 14;
                        break;
                    case "NOTIFICATION_TYPE_STORY_IN_TAG_RANKING":
                    case 16:
                        message.acceptedPushNotificationTypeList[i] = 16;
                        break;
                    case "NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK":
                    case 17:
                        message.acceptedPushNotificationTypeList[i] = 17;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES":
                    case 18:
                        message.acceptedPushNotificationTypeList[i] = 18;
                        break;
                    case "NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK":
                    case 19:
                        message.acceptedPushNotificationTypeList[i] = 19;
                        break;
                    case "NOTIFICATION_TYPE_PAW_RECOVERED":
                    case 20:
                        message.acceptedPushNotificationTypeList[i] = 20;
                        break;
                    case "NOTIFICATION_TYPE_NEW_BOOKMARK":
                    case 23:
                        message.acceptedPushNotificationTypeList[i] = 23;
                        break;
                    case "NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK":
                    case 25:
                        message.acceptedPushNotificationTypeList[i] = 25;
                        break;
                    case "NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER":
                    case 28:
                        message.acceptedPushNotificationTypeList[i] = 28;
                        break;
                    }
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".notification.NotificationPreference.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationPreference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationPreference
         * @static
         * @param {notification.NotificationPreference} message NotificationPreference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationPreference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.refusedPushNotificationTypeList = [];
                object.acceptedPushNotificationTypeList = [];
            }
            if (options.defaults)
                object.updatedAt = null;
            if (message.refusedPushNotificationTypeList && message.refusedPushNotificationTypeList.length) {
                object.refusedPushNotificationTypeList = [];
                for (var j = 0; j < message.refusedPushNotificationTypeList.length; ++j)
                    object.refusedPushNotificationTypeList[j] = options.enums === String ? $root.notification.NotificationType[message.refusedPushNotificationTypeList[j]] : message.refusedPushNotificationTypeList[j];
            }
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.acceptedPushNotificationTypeList && message.acceptedPushNotificationTypeList.length) {
                object.acceptedPushNotificationTypeList = [];
                for (var j = 0; j < message.acceptedPushNotificationTypeList.length; ++j)
                    object.acceptedPushNotificationTypeList[j] = options.enums === String ? $root.notification.NotificationType[message.acceptedPushNotificationTypeList[j]] : message.acceptedPushNotificationTypeList[j];
            }
            return object;
        };

        /**
         * Converts this NotificationPreference to JSON.
         * @function toJSON
         * @memberof notification.NotificationPreference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationPreference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationPreference
         * @function getTypeUrl
         * @memberof notification.NotificationPreference
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationPreference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationPreference";
        };

        return NotificationPreference;
    })();

    notification.ImageWithLink = (function() {

        /**
         * Properties of an ImageWithLink.
         * @memberof notification
         * @interface IImageWithLink
         * @property {Array.<google.protobuf.IStringValue>|null} [imageUrlList] ImageWithLink imageUrlList
         * @property {google.protobuf.IStringValue|null} [actionUrl] ImageWithLink actionUrl
         */

        /**
         * Constructs a new ImageWithLink.
         * @memberof notification
         * @classdesc Represents an ImageWithLink.
         * @implements IImageWithLink
         * @constructor
         * @param {notification.IImageWithLink=} [properties] Properties to set
         */
        function ImageWithLink(properties) {
            this.imageUrlList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageWithLink imageUrlList.
         * @member {Array.<google.protobuf.IStringValue>} imageUrlList
         * @memberof notification.ImageWithLink
         * @instance
         */
        ImageWithLink.prototype.imageUrlList = $util.emptyArray;

        /**
         * ImageWithLink actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.ImageWithLink
         * @instance
         */
        ImageWithLink.prototype.actionUrl = null;

        /**
         * Creates a new ImageWithLink instance using the specified properties.
         * @function create
         * @memberof notification.ImageWithLink
         * @static
         * @param {notification.IImageWithLink=} [properties] Properties to set
         * @returns {notification.ImageWithLink} ImageWithLink instance
         */
        ImageWithLink.create = function create(properties) {
            return new ImageWithLink(properties);
        };

        /**
         * Encodes the specified ImageWithLink message. Does not implicitly {@link notification.ImageWithLink.verify|verify} messages.
         * @function encode
         * @memberof notification.ImageWithLink
         * @static
         * @param {notification.IImageWithLink} message ImageWithLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageWithLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageUrlList != null && message.imageUrlList.length)
                for (var i = 0; i < message.imageUrlList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.imageUrlList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ImageWithLink message from the specified reader or buffer.
         * @function decode
         * @memberof notification.ImageWithLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.ImageWithLink} ImageWithLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageWithLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.ImageWithLink();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.imageUrlList && message.imageUrlList.length))
                        message.imageUrlList = [];
                    message.imageUrlList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an ImageWithLink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.ImageWithLink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.ImageWithLink} ImageWithLink
         */
        ImageWithLink.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.ImageWithLink)
                return object;
            var message = new $root.notification.ImageWithLink();
            if (object.imageUrlList) {
                if (!Array.isArray(object.imageUrlList))
                    throw TypeError(".notification.ImageWithLink.imageUrlList: array expected");
                message.imageUrlList = [];
                for (var i = 0; i < object.imageUrlList.length; ++i) {
                    if (typeof object.imageUrlList[i] !== "object")
                        throw TypeError(".notification.ImageWithLink.imageUrlList: object expected");
                    message.imageUrlList[i] = $root.google.protobuf.StringValue.fromObject(object.imageUrlList[i]);
                }
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.ImageWithLink.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageWithLink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.ImageWithLink
         * @static
         * @param {notification.ImageWithLink} message ImageWithLink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageWithLink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.imageUrlList = [];
            if (options.defaults)
                object.actionUrl = null;
            if (message.imageUrlList && message.imageUrlList.length) {
                object.imageUrlList = [];
                for (var j = 0; j < message.imageUrlList.length; ++j)
                    object.imageUrlList[j] = $root.google.protobuf.StringValue.toObject(message.imageUrlList[j], options);
            }
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            return object;
        };

        /**
         * Converts this ImageWithLink to JSON.
         * @function toJSON
         * @memberof notification.ImageWithLink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageWithLink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImageWithLink
         * @function getTypeUrl
         * @memberof notification.ImageWithLink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImageWithLink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.ImageWithLink";
        };

        return ImageWithLink;
    })();

    /**
     * NotificationType enum.
     * @name notification.NotificationType
     * @enum {number}
     * @property {number} NOTIFICATION_TYPE_UNSPECIFIED=0 NOTIFICATION_TYPE_UNSPECIFIED value
     * @property {number} NOTIFICATION_TYPE_OPERATION=1 NOTIFICATION_TYPE_OPERATION value
     * @property {number} NOTIFICATION_TYPE_STORY_REJECTED=2 NOTIFICATION_TYPE_STORY_REJECTED value
     * @property {number} NOTIFICATION_TYPE_USER_LIKE_BREAK=3 NOTIFICATION_TYPE_USER_LIKE_BREAK value
     * @property {number} NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK=4 NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK value
     * @property {number} NOTIFICATION_TYPE_USER_BANNED=5 NOTIFICATION_TYPE_USER_BANNED value
     * @property {number} NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS=8 NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS value
     * @property {number} NOTIFICATION_TYPE_NEW_FOLLOWER=10 NOTIFICATION_TYPE_NEW_FOLLOWER value
     * @property {number} NOTIFICATION_TYPE_NEW_STORY_COMMENT=11 NOTIFICATION_TYPE_NEW_STORY_COMMENT value
     * @property {number} NOTIFICATION_TYPE_NEW_COMMENT_LIKE=12 NOTIFICATION_TYPE_NEW_COMMENT_LIKE value
     * @property {number} NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE=13 NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE value
     * @property {number} NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY=15 NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY value
     * @property {number} NOTIFICATION_TYPE_NEW_MEDAL=21 NOTIFICATION_TYPE_NEW_MEDAL value
     * @property {number} NOTIFICATION_TYPE_PROMOTION_OFFER=22 NOTIFICATION_TYPE_PROMOTION_OFFER value
     * @property {number} NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY=24 NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY value
     * @property {number} NOTIFICATION_TYPE_STORY_REMINDER=26 NOTIFICATION_TYPE_STORY_REMINDER value
     * @property {number} NOTIFICATION_TYPE_PAW_INTRODUCTION=27 NOTIFICATION_TYPE_PAW_INTRODUCTION value
     * @property {number} NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE=29 NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE value
     * @property {number} NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED=30 NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED value
     * @property {number} NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER=31 NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER value
     * @property {number} NOTIFICATION_TYPE_NEW_SEASON=32 NOTIFICATION_TYPE_NEW_SEASON value
     * @property {number} NOTIFICATION_TYPE_NEXT_SEASON_REMINDER=33 NOTIFICATION_TYPE_NEXT_SEASON_REMINDER value
     * @property {number} NOTIFICATION_TYPE_STORY_VIDEO_CREATED=34 NOTIFICATION_TYPE_STORY_VIDEO_CREATED value
     * @property {number} NOTIFICATION_TYPE_USER_STORY_NEW_LIKES=35 NOTIFICATION_TYPE_USER_STORY_NEW_LIKES value
     * @property {number} NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST=36 NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST value
     * @property {number} NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES=6 NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES value
     * @property {number} NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS=7 NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS value
     * @property {number} NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES=9 NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES value
     * @property {number} NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS=14 NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS value
     * @property {number} NOTIFICATION_TYPE_STORY_IN_TAG_RANKING=16 NOTIFICATION_TYPE_STORY_IN_TAG_RANKING value
     * @property {number} NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK=17 NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK value
     * @property {number} NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES=18 NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES value
     * @property {number} NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK=19 NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK value
     * @property {number} NOTIFICATION_TYPE_PAW_RECOVERED=20 NOTIFICATION_TYPE_PAW_RECOVERED value
     * @property {number} NOTIFICATION_TYPE_NEW_BOOKMARK=23 NOTIFICATION_TYPE_NEW_BOOKMARK value
     * @property {number} NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK=25 NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK value
     * @property {number} NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER=28 NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER value
     */
    notification.NotificationType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NOTIFICATION_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "NOTIFICATION_TYPE_OPERATION"] = 1;
        values[valuesById[2] = "NOTIFICATION_TYPE_STORY_REJECTED"] = 2;
        values[valuesById[3] = "NOTIFICATION_TYPE_USER_LIKE_BREAK"] = 3;
        values[valuesById[4] = "NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK"] = 4;
        values[valuesById[5] = "NOTIFICATION_TYPE_USER_BANNED"] = 5;
        values[valuesById[8] = "NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS"] = 8;
        values[valuesById[10] = "NOTIFICATION_TYPE_NEW_FOLLOWER"] = 10;
        values[valuesById[11] = "NOTIFICATION_TYPE_NEW_STORY_COMMENT"] = 11;
        values[valuesById[12] = "NOTIFICATION_TYPE_NEW_COMMENT_LIKE"] = 12;
        values[valuesById[13] = "NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE"] = 13;
        values[valuesById[15] = "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY"] = 15;
        values[valuesById[21] = "NOTIFICATION_TYPE_NEW_MEDAL"] = 21;
        values[valuesById[22] = "NOTIFICATION_TYPE_PROMOTION_OFFER"] = 22;
        values[valuesById[24] = "NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY"] = 24;
        values[valuesById[26] = "NOTIFICATION_TYPE_STORY_REMINDER"] = 26;
        values[valuesById[27] = "NOTIFICATION_TYPE_PAW_INTRODUCTION"] = 27;
        values[valuesById[29] = "NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE"] = 29;
        values[valuesById[30] = "NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED"] = 30;
        values[valuesById[31] = "NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER"] = 31;
        values[valuesById[32] = "NOTIFICATION_TYPE_NEW_SEASON"] = 32;
        values[valuesById[33] = "NOTIFICATION_TYPE_NEXT_SEASON_REMINDER"] = 33;
        values[valuesById[34] = "NOTIFICATION_TYPE_STORY_VIDEO_CREATED"] = 34;
        values[valuesById[35] = "NOTIFICATION_TYPE_USER_STORY_NEW_LIKES"] = 35;
        values[valuesById[36] = "NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST"] = 36;
        values[valuesById[6] = "NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES"] = 6;
        values[valuesById[7] = "NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS"] = 7;
        values[valuesById[9] = "NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES"] = 9;
        values[valuesById[14] = "NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS"] = 14;
        values[valuesById[16] = "NOTIFICATION_TYPE_STORY_IN_TAG_RANKING"] = 16;
        values[valuesById[17] = "NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK"] = 17;
        values[valuesById[18] = "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES"] = 18;
        values[valuesById[19] = "NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK"] = 19;
        values[valuesById[20] = "NOTIFICATION_TYPE_PAW_RECOVERED"] = 20;
        values[valuesById[23] = "NOTIFICATION_TYPE_NEW_BOOKMARK"] = 23;
        values[valuesById[25] = "NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK"] = 25;
        values[valuesById[28] = "NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER"] = 28;
        return values;
    })();

    /**
     * NotificationCategory enum.
     * @name notification.NotificationCategory
     * @enum {number}
     * @property {number} NOTIFICATION_CATEGORY_UNSPECIFIED=0 NOTIFICATION_CATEGORY_UNSPECIFIED value
     * @property {number} NOTIFICATION_CATEGORY_OPERATION=1 NOTIFICATION_CATEGORY_OPERATION value
     * @property {number} NOTIFICATION_CATEGORY_USER_RELATED=2 NOTIFICATION_CATEGORY_USER_RELATED value
     * @property {number} NOTIFICATION_CATEGORY_FOLLOWING_RELATED=3 NOTIFICATION_CATEGORY_FOLLOWING_RELATED value
     * @property {number} NOTIFICATION_CATEGORY_WAKEUP_DIALOG=4 NOTIFICATION_CATEGORY_WAKEUP_DIALOG value
     */
    notification.NotificationCategory = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NOTIFICATION_CATEGORY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "NOTIFICATION_CATEGORY_OPERATION"] = 1;
        values[valuesById[2] = "NOTIFICATION_CATEGORY_USER_RELATED"] = 2;
        values[valuesById[3] = "NOTIFICATION_CATEGORY_FOLLOWING_RELATED"] = 3;
        values[valuesById[4] = "NOTIFICATION_CATEGORY_WAKEUP_DIALOG"] = 4;
        return values;
    })();

    notification.NotificationContents = (function() {

        /**
         * Properties of a NotificationContents.
         * @memberof notification
         * @interface INotificationContents
         * @property {notification.INotificationContentsOperation|null} [operation] NotificationContents operation
         * @property {notification.INotificationContentsStoryRejected|null} [storyRejected] NotificationContents storyRejected
         * @property {notification.INotificationContentsUserBanned|null} [userBanned] NotificationContents userBanned
         * @property {notification.INotificationContentsPaymentFailure|null} [paymentFailure] NotificationContents paymentFailure
         * @property {notification.INotificationContentsVipGracePeriodExpired|null} [vipGracePeriodExpired] NotificationContents vipGracePeriodExpired
         * @property {notification.INotificationContentsNewFollower|null} [newFollower] NotificationContents newFollower
         * @property {notification.INotificationContentsNewStoryComment|null} [newStoryComment] NotificationContents newStoryComment
         * @property {notification.INotificationContentsNewCommentLike|null} [newCommentLike] NotificationContents newCommentLike
         * @property {notification.INotificationContentsNewCommentResponse|null} [newCommentResponse] NotificationContents newCommentResponse
         * @property {notification.INotificationContentsFollowingUserNewStory|null} [followingUserNewStory] NotificationContents followingUserNewStory
         * @property {notification.INotificationContentsUserLikeBreak|null} [userLikeBreak] NotificationContents userLikeBreak
         * @property {notification.INotificationContentsBookmarkNewSeriesStory|null} [bookmarkNewSeriesStory] NotificationContents bookmarkNewSeriesStory
         * @property {notification.INotificationContentsUserStoryLikeBreak|null} [userStoryLikeBreak] NotificationContents userStoryLikeBreak
         * @property {notification.INotificationContentsNewMedal|null} [newMedal] NotificationContents newMedal
         * @property {notification.INotificationContentsUserStoryNewLikes|null} [userStoryNewLikes] NotificationContents userStoryNewLikes
         * @property {notification.INotificationContentsStoryVideoCreated|null} [storyVideoCreated] NotificationContents storyVideoCreated
         * @property {notification.INotificationContentsMutualFollowReadRequest|null} [mutualFollowReadRequest] NotificationContents mutualFollowReadRequest
         */

        /**
         * Constructs a new NotificationContents.
         * @memberof notification
         * @classdesc Represents a NotificationContents.
         * @implements INotificationContents
         * @constructor
         * @param {notification.INotificationContents=} [properties] Properties to set
         */
        function NotificationContents(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContents operation.
         * @member {notification.INotificationContentsOperation|null|undefined} operation
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.operation = null;

        /**
         * NotificationContents storyRejected.
         * @member {notification.INotificationContentsStoryRejected|null|undefined} storyRejected
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.storyRejected = null;

        /**
         * NotificationContents userBanned.
         * @member {notification.INotificationContentsUserBanned|null|undefined} userBanned
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.userBanned = null;

        /**
         * NotificationContents paymentFailure.
         * @member {notification.INotificationContentsPaymentFailure|null|undefined} paymentFailure
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.paymentFailure = null;

        /**
         * NotificationContents vipGracePeriodExpired.
         * @member {notification.INotificationContentsVipGracePeriodExpired|null|undefined} vipGracePeriodExpired
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.vipGracePeriodExpired = null;

        /**
         * NotificationContents newFollower.
         * @member {notification.INotificationContentsNewFollower|null|undefined} newFollower
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.newFollower = null;

        /**
         * NotificationContents newStoryComment.
         * @member {notification.INotificationContentsNewStoryComment|null|undefined} newStoryComment
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.newStoryComment = null;

        /**
         * NotificationContents newCommentLike.
         * @member {notification.INotificationContentsNewCommentLike|null|undefined} newCommentLike
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.newCommentLike = null;

        /**
         * NotificationContents newCommentResponse.
         * @member {notification.INotificationContentsNewCommentResponse|null|undefined} newCommentResponse
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.newCommentResponse = null;

        /**
         * NotificationContents followingUserNewStory.
         * @member {notification.INotificationContentsFollowingUserNewStory|null|undefined} followingUserNewStory
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.followingUserNewStory = null;

        /**
         * NotificationContents userLikeBreak.
         * @member {notification.INotificationContentsUserLikeBreak|null|undefined} userLikeBreak
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.userLikeBreak = null;

        /**
         * NotificationContents bookmarkNewSeriesStory.
         * @member {notification.INotificationContentsBookmarkNewSeriesStory|null|undefined} bookmarkNewSeriesStory
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.bookmarkNewSeriesStory = null;

        /**
         * NotificationContents userStoryLikeBreak.
         * @member {notification.INotificationContentsUserStoryLikeBreak|null|undefined} userStoryLikeBreak
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.userStoryLikeBreak = null;

        /**
         * NotificationContents newMedal.
         * @member {notification.INotificationContentsNewMedal|null|undefined} newMedal
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.newMedal = null;

        /**
         * NotificationContents userStoryNewLikes.
         * @member {notification.INotificationContentsUserStoryNewLikes|null|undefined} userStoryNewLikes
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.userStoryNewLikes = null;

        /**
         * NotificationContents storyVideoCreated.
         * @member {notification.INotificationContentsStoryVideoCreated|null|undefined} storyVideoCreated
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.storyVideoCreated = null;

        /**
         * NotificationContents mutualFollowReadRequest.
         * @member {notification.INotificationContentsMutualFollowReadRequest|null|undefined} mutualFollowReadRequest
         * @memberof notification.NotificationContents
         * @instance
         */
        NotificationContents.prototype.mutualFollowReadRequest = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * NotificationContents contents.
         * @member {"operation"|"storyRejected"|"userBanned"|"paymentFailure"|"vipGracePeriodExpired"|"newFollower"|"newStoryComment"|"newCommentLike"|"newCommentResponse"|"followingUserNewStory"|"userLikeBreak"|"bookmarkNewSeriesStory"|"userStoryLikeBreak"|"newMedal"|"userStoryNewLikes"|"storyVideoCreated"|"mutualFollowReadRequest"|undefined} contents
         * @memberof notification.NotificationContents
         * @instance
         */
        Object.defineProperty(NotificationContents.prototype, "contents", {
            get: $util.oneOfGetter($oneOfFields = ["operation", "storyRejected", "userBanned", "paymentFailure", "vipGracePeriodExpired", "newFollower", "newStoryComment", "newCommentLike", "newCommentResponse", "followingUserNewStory", "userLikeBreak", "bookmarkNewSeriesStory", "userStoryLikeBreak", "newMedal", "userStoryNewLikes", "storyVideoCreated", "mutualFollowReadRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new NotificationContents instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContents
         * @static
         * @param {notification.INotificationContents=} [properties] Properties to set
         * @returns {notification.NotificationContents} NotificationContents instance
         */
        NotificationContents.create = function create(properties) {
            return new NotificationContents(properties);
        };

        /**
         * Encodes the specified NotificationContents message. Does not implicitly {@link notification.NotificationContents.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContents
         * @static
         * @param {notification.INotificationContents} message NotificationContents message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContents.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
                $root.notification.NotificationContentsOperation.encode(message.operation, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyRejected != null && Object.hasOwnProperty.call(message, "storyRejected"))
                $root.notification.NotificationContentsStoryRejected.encode(message.storyRejected, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.userBanned != null && Object.hasOwnProperty.call(message, "userBanned"))
                $root.notification.NotificationContentsUserBanned.encode(message.userBanned, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.paymentFailure != null && Object.hasOwnProperty.call(message, "paymentFailure"))
                $root.notification.NotificationContentsPaymentFailure.encode(message.paymentFailure, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.vipGracePeriodExpired != null && Object.hasOwnProperty.call(message, "vipGracePeriodExpired"))
                $root.notification.NotificationContentsVipGracePeriodExpired.encode(message.vipGracePeriodExpired, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.newFollower != null && Object.hasOwnProperty.call(message, "newFollower"))
                $root.notification.NotificationContentsNewFollower.encode(message.newFollower, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.newStoryComment != null && Object.hasOwnProperty.call(message, "newStoryComment"))
                $root.notification.NotificationContentsNewStoryComment.encode(message.newStoryComment, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.newCommentLike != null && Object.hasOwnProperty.call(message, "newCommentLike"))
                $root.notification.NotificationContentsNewCommentLike.encode(message.newCommentLike, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.newCommentResponse != null && Object.hasOwnProperty.call(message, "newCommentResponse"))
                $root.notification.NotificationContentsNewCommentResponse.encode(message.newCommentResponse, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.followingUserNewStory != null && Object.hasOwnProperty.call(message, "followingUserNewStory"))
                $root.notification.NotificationContentsFollowingUserNewStory.encode(message.followingUserNewStory, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.userLikeBreak != null && Object.hasOwnProperty.call(message, "userLikeBreak"))
                $root.notification.NotificationContentsUserLikeBreak.encode(message.userLikeBreak, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.bookmarkNewSeriesStory != null && Object.hasOwnProperty.call(message, "bookmarkNewSeriesStory"))
                $root.notification.NotificationContentsBookmarkNewSeriesStory.encode(message.bookmarkNewSeriesStory, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.userStoryLikeBreak != null && Object.hasOwnProperty.call(message, "userStoryLikeBreak"))
                $root.notification.NotificationContentsUserStoryLikeBreak.encode(message.userStoryLikeBreak, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.newMedal != null && Object.hasOwnProperty.call(message, "newMedal"))
                $root.notification.NotificationContentsNewMedal.encode(message.newMedal, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.userStoryNewLikes != null && Object.hasOwnProperty.call(message, "userStoryNewLikes"))
                $root.notification.NotificationContentsUserStoryNewLikes.encode(message.userStoryNewLikes, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.storyVideoCreated != null && Object.hasOwnProperty.call(message, "storyVideoCreated"))
                $root.notification.NotificationContentsStoryVideoCreated.encode(message.storyVideoCreated, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.mutualFollowReadRequest != null && Object.hasOwnProperty.call(message, "mutualFollowReadRequest"))
                $root.notification.NotificationContentsMutualFollowReadRequest.encode(message.mutualFollowReadRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContents message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContents
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContents} NotificationContents
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContents.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContents();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.operation = $root.notification.NotificationContentsOperation.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyRejected = $root.notification.NotificationContentsStoryRejected.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userBanned = $root.notification.NotificationContentsUserBanned.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.paymentFailure = $root.notification.NotificationContentsPaymentFailure.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.vipGracePeriodExpired = $root.notification.NotificationContentsVipGracePeriodExpired.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.newFollower = $root.notification.NotificationContentsNewFollower.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.newStoryComment = $root.notification.NotificationContentsNewStoryComment.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.newCommentLike = $root.notification.NotificationContentsNewCommentLike.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.newCommentResponse = $root.notification.NotificationContentsNewCommentResponse.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.followingUserNewStory = $root.notification.NotificationContentsFollowingUserNewStory.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.userLikeBreak = $root.notification.NotificationContentsUserLikeBreak.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.bookmarkNewSeriesStory = $root.notification.NotificationContentsBookmarkNewSeriesStory.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.userStoryLikeBreak = $root.notification.NotificationContentsUserStoryLikeBreak.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.newMedal = $root.notification.NotificationContentsNewMedal.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.userStoryNewLikes = $root.notification.NotificationContentsUserStoryNewLikes.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.storyVideoCreated = $root.notification.NotificationContentsStoryVideoCreated.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.mutualFollowReadRequest = $root.notification.NotificationContentsMutualFollowReadRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContents message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContents
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContents} NotificationContents
         */
        NotificationContents.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContents)
                return object;
            var message = new $root.notification.NotificationContents();
            if (object.operation != null) {
                if (typeof object.operation !== "object")
                    throw TypeError(".notification.NotificationContents.operation: object expected");
                message.operation = $root.notification.NotificationContentsOperation.fromObject(object.operation);
            }
            if (object.storyRejected != null) {
                if (typeof object.storyRejected !== "object")
                    throw TypeError(".notification.NotificationContents.storyRejected: object expected");
                message.storyRejected = $root.notification.NotificationContentsStoryRejected.fromObject(object.storyRejected);
            }
            if (object.userBanned != null) {
                if (typeof object.userBanned !== "object")
                    throw TypeError(".notification.NotificationContents.userBanned: object expected");
                message.userBanned = $root.notification.NotificationContentsUserBanned.fromObject(object.userBanned);
            }
            if (object.paymentFailure != null) {
                if (typeof object.paymentFailure !== "object")
                    throw TypeError(".notification.NotificationContents.paymentFailure: object expected");
                message.paymentFailure = $root.notification.NotificationContentsPaymentFailure.fromObject(object.paymentFailure);
            }
            if (object.vipGracePeriodExpired != null) {
                if (typeof object.vipGracePeriodExpired !== "object")
                    throw TypeError(".notification.NotificationContents.vipGracePeriodExpired: object expected");
                message.vipGracePeriodExpired = $root.notification.NotificationContentsVipGracePeriodExpired.fromObject(object.vipGracePeriodExpired);
            }
            if (object.newFollower != null) {
                if (typeof object.newFollower !== "object")
                    throw TypeError(".notification.NotificationContents.newFollower: object expected");
                message.newFollower = $root.notification.NotificationContentsNewFollower.fromObject(object.newFollower);
            }
            if (object.newStoryComment != null) {
                if (typeof object.newStoryComment !== "object")
                    throw TypeError(".notification.NotificationContents.newStoryComment: object expected");
                message.newStoryComment = $root.notification.NotificationContentsNewStoryComment.fromObject(object.newStoryComment);
            }
            if (object.newCommentLike != null) {
                if (typeof object.newCommentLike !== "object")
                    throw TypeError(".notification.NotificationContents.newCommentLike: object expected");
                message.newCommentLike = $root.notification.NotificationContentsNewCommentLike.fromObject(object.newCommentLike);
            }
            if (object.newCommentResponse != null) {
                if (typeof object.newCommentResponse !== "object")
                    throw TypeError(".notification.NotificationContents.newCommentResponse: object expected");
                message.newCommentResponse = $root.notification.NotificationContentsNewCommentResponse.fromObject(object.newCommentResponse);
            }
            if (object.followingUserNewStory != null) {
                if (typeof object.followingUserNewStory !== "object")
                    throw TypeError(".notification.NotificationContents.followingUserNewStory: object expected");
                message.followingUserNewStory = $root.notification.NotificationContentsFollowingUserNewStory.fromObject(object.followingUserNewStory);
            }
            if (object.userLikeBreak != null) {
                if (typeof object.userLikeBreak !== "object")
                    throw TypeError(".notification.NotificationContents.userLikeBreak: object expected");
                message.userLikeBreak = $root.notification.NotificationContentsUserLikeBreak.fromObject(object.userLikeBreak);
            }
            if (object.bookmarkNewSeriesStory != null) {
                if (typeof object.bookmarkNewSeriesStory !== "object")
                    throw TypeError(".notification.NotificationContents.bookmarkNewSeriesStory: object expected");
                message.bookmarkNewSeriesStory = $root.notification.NotificationContentsBookmarkNewSeriesStory.fromObject(object.bookmarkNewSeriesStory);
            }
            if (object.userStoryLikeBreak != null) {
                if (typeof object.userStoryLikeBreak !== "object")
                    throw TypeError(".notification.NotificationContents.userStoryLikeBreak: object expected");
                message.userStoryLikeBreak = $root.notification.NotificationContentsUserStoryLikeBreak.fromObject(object.userStoryLikeBreak);
            }
            if (object.newMedal != null) {
                if (typeof object.newMedal !== "object")
                    throw TypeError(".notification.NotificationContents.newMedal: object expected");
                message.newMedal = $root.notification.NotificationContentsNewMedal.fromObject(object.newMedal);
            }
            if (object.userStoryNewLikes != null) {
                if (typeof object.userStoryNewLikes !== "object")
                    throw TypeError(".notification.NotificationContents.userStoryNewLikes: object expected");
                message.userStoryNewLikes = $root.notification.NotificationContentsUserStoryNewLikes.fromObject(object.userStoryNewLikes);
            }
            if (object.storyVideoCreated != null) {
                if (typeof object.storyVideoCreated !== "object")
                    throw TypeError(".notification.NotificationContents.storyVideoCreated: object expected");
                message.storyVideoCreated = $root.notification.NotificationContentsStoryVideoCreated.fromObject(object.storyVideoCreated);
            }
            if (object.mutualFollowReadRequest != null) {
                if (typeof object.mutualFollowReadRequest !== "object")
                    throw TypeError(".notification.NotificationContents.mutualFollowReadRequest: object expected");
                message.mutualFollowReadRequest = $root.notification.NotificationContentsMutualFollowReadRequest.fromObject(object.mutualFollowReadRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContents message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContents
         * @static
         * @param {notification.NotificationContents} message NotificationContents
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContents.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.operation != null && message.hasOwnProperty("operation")) {
                object.operation = $root.notification.NotificationContentsOperation.toObject(message.operation, options);
                if (options.oneofs)
                    object.contents = "operation";
            }
            if (message.storyRejected != null && message.hasOwnProperty("storyRejected")) {
                object.storyRejected = $root.notification.NotificationContentsStoryRejected.toObject(message.storyRejected, options);
                if (options.oneofs)
                    object.contents = "storyRejected";
            }
            if (message.userBanned != null && message.hasOwnProperty("userBanned")) {
                object.userBanned = $root.notification.NotificationContentsUserBanned.toObject(message.userBanned, options);
                if (options.oneofs)
                    object.contents = "userBanned";
            }
            if (message.paymentFailure != null && message.hasOwnProperty("paymentFailure")) {
                object.paymentFailure = $root.notification.NotificationContentsPaymentFailure.toObject(message.paymentFailure, options);
                if (options.oneofs)
                    object.contents = "paymentFailure";
            }
            if (message.vipGracePeriodExpired != null && message.hasOwnProperty("vipGracePeriodExpired")) {
                object.vipGracePeriodExpired = $root.notification.NotificationContentsVipGracePeriodExpired.toObject(message.vipGracePeriodExpired, options);
                if (options.oneofs)
                    object.contents = "vipGracePeriodExpired";
            }
            if (message.newFollower != null && message.hasOwnProperty("newFollower")) {
                object.newFollower = $root.notification.NotificationContentsNewFollower.toObject(message.newFollower, options);
                if (options.oneofs)
                    object.contents = "newFollower";
            }
            if (message.newStoryComment != null && message.hasOwnProperty("newStoryComment")) {
                object.newStoryComment = $root.notification.NotificationContentsNewStoryComment.toObject(message.newStoryComment, options);
                if (options.oneofs)
                    object.contents = "newStoryComment";
            }
            if (message.newCommentLike != null && message.hasOwnProperty("newCommentLike")) {
                object.newCommentLike = $root.notification.NotificationContentsNewCommentLike.toObject(message.newCommentLike, options);
                if (options.oneofs)
                    object.contents = "newCommentLike";
            }
            if (message.newCommentResponse != null && message.hasOwnProperty("newCommentResponse")) {
                object.newCommentResponse = $root.notification.NotificationContentsNewCommentResponse.toObject(message.newCommentResponse, options);
                if (options.oneofs)
                    object.contents = "newCommentResponse";
            }
            if (message.followingUserNewStory != null && message.hasOwnProperty("followingUserNewStory")) {
                object.followingUserNewStory = $root.notification.NotificationContentsFollowingUserNewStory.toObject(message.followingUserNewStory, options);
                if (options.oneofs)
                    object.contents = "followingUserNewStory";
            }
            if (message.userLikeBreak != null && message.hasOwnProperty("userLikeBreak")) {
                object.userLikeBreak = $root.notification.NotificationContentsUserLikeBreak.toObject(message.userLikeBreak, options);
                if (options.oneofs)
                    object.contents = "userLikeBreak";
            }
            if (message.bookmarkNewSeriesStory != null && message.hasOwnProperty("bookmarkNewSeriesStory")) {
                object.bookmarkNewSeriesStory = $root.notification.NotificationContentsBookmarkNewSeriesStory.toObject(message.bookmarkNewSeriesStory, options);
                if (options.oneofs)
                    object.contents = "bookmarkNewSeriesStory";
            }
            if (message.userStoryLikeBreak != null && message.hasOwnProperty("userStoryLikeBreak")) {
                object.userStoryLikeBreak = $root.notification.NotificationContentsUserStoryLikeBreak.toObject(message.userStoryLikeBreak, options);
                if (options.oneofs)
                    object.contents = "userStoryLikeBreak";
            }
            if (message.newMedal != null && message.hasOwnProperty("newMedal")) {
                object.newMedal = $root.notification.NotificationContentsNewMedal.toObject(message.newMedal, options);
                if (options.oneofs)
                    object.contents = "newMedal";
            }
            if (message.userStoryNewLikes != null && message.hasOwnProperty("userStoryNewLikes")) {
                object.userStoryNewLikes = $root.notification.NotificationContentsUserStoryNewLikes.toObject(message.userStoryNewLikes, options);
                if (options.oneofs)
                    object.contents = "userStoryNewLikes";
            }
            if (message.storyVideoCreated != null && message.hasOwnProperty("storyVideoCreated")) {
                object.storyVideoCreated = $root.notification.NotificationContentsStoryVideoCreated.toObject(message.storyVideoCreated, options);
                if (options.oneofs)
                    object.contents = "storyVideoCreated";
            }
            if (message.mutualFollowReadRequest != null && message.hasOwnProperty("mutualFollowReadRequest")) {
                object.mutualFollowReadRequest = $root.notification.NotificationContentsMutualFollowReadRequest.toObject(message.mutualFollowReadRequest, options);
                if (options.oneofs)
                    object.contents = "mutualFollowReadRequest";
            }
            return object;
        };

        /**
         * Converts this NotificationContents to JSON.
         * @function toJSON
         * @memberof notification.NotificationContents
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContents.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContents
         * @function getTypeUrl
         * @memberof notification.NotificationContents
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContents.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContents";
        };

        return NotificationContents;
    })();

    notification.NotificationContentsOperation = (function() {

        /**
         * Properties of a NotificationContentsOperation.
         * @memberof notification
         * @interface INotificationContentsOperation
         * @property {google.protobuf.IStringValue|null} [title] NotificationContentsOperation title
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsOperation message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsOperation actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsOperation thumbnailUrl
         * @property {notification.INotificationMetadata|null} [metadata] NotificationContentsOperation metadata
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsOperation iconUrl
         */

        /**
         * Constructs a new NotificationContentsOperation.
         * @memberof notification
         * @classdesc Represents a NotificationContentsOperation.
         * @implements INotificationContentsOperation
         * @constructor
         * @param {notification.INotificationContentsOperation=} [properties] Properties to set
         */
        function NotificationContentsOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsOperation title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof notification.NotificationContentsOperation
         * @instance
         */
        NotificationContentsOperation.prototype.title = null;

        /**
         * NotificationContentsOperation message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsOperation
         * @instance
         */
        NotificationContentsOperation.prototype.message = null;

        /**
         * NotificationContentsOperation actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsOperation
         * @instance
         */
        NotificationContentsOperation.prototype.actionUrl = null;

        /**
         * NotificationContentsOperation thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsOperation
         * @instance
         */
        NotificationContentsOperation.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsOperation metadata.
         * @member {notification.INotificationMetadata|null|undefined} metadata
         * @memberof notification.NotificationContentsOperation
         * @instance
         */
        NotificationContentsOperation.prototype.metadata = null;

        /**
         * NotificationContentsOperation iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsOperation
         * @instance
         */
        NotificationContentsOperation.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsOperation instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsOperation
         * @static
         * @param {notification.INotificationContentsOperation=} [properties] Properties to set
         * @returns {notification.NotificationContentsOperation} NotificationContentsOperation instance
         */
        NotificationContentsOperation.create = function create(properties) {
            return new NotificationContentsOperation(properties);
        };

        /**
         * Encodes the specified NotificationContentsOperation message. Does not implicitly {@link notification.NotificationContentsOperation.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsOperation
         * @static
         * @param {notification.INotificationContentsOperation} message NotificationContentsOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                $root.notification.NotificationMetadata.encode(message.metadata, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsOperation message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsOperation} NotificationContentsOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.metadata = $root.notification.NotificationMetadata.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsOperation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsOperation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsOperation} NotificationContentsOperation
         */
        NotificationContentsOperation.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsOperation)
                return object;
            var message = new $root.notification.NotificationContentsOperation();
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".notification.NotificationContentsOperation.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsOperation.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsOperation.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsOperation.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.metadata != null) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".notification.NotificationContentsOperation.metadata: object expected");
                message.metadata = $root.notification.NotificationMetadata.fromObject(object.metadata);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsOperation.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsOperation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsOperation
         * @static
         * @param {notification.NotificationContentsOperation} message NotificationContentsOperation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsOperation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.title = null;
                object.message = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.metadata = null;
                object.iconUrl = null;
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                object.metadata = $root.notification.NotificationMetadata.toObject(message.metadata, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            return object;
        };

        /**
         * Converts this NotificationContentsOperation to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsOperation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsOperation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsOperation
         * @function getTypeUrl
         * @memberof notification.NotificationContentsOperation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsOperation";
        };

        return NotificationContentsOperation;
    })();

    notification.NotificationContentsStoryRejected = (function() {

        /**
         * Properties of a NotificationContentsStoryRejected.
         * @memberof notification
         * @interface INotificationContentsStoryRejected
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsStoryRejected message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsStoryRejected actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsStoryRejected thumbnailUrl
         * @property {google.protobuf.IStringValue|null} [storyTitle] NotificationContentsStoryRejected storyTitle
         */

        /**
         * Constructs a new NotificationContentsStoryRejected.
         * @memberof notification
         * @classdesc Represents a NotificationContentsStoryRejected.
         * @implements INotificationContentsStoryRejected
         * @constructor
         * @param {notification.INotificationContentsStoryRejected=} [properties] Properties to set
         */
        function NotificationContentsStoryRejected(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsStoryRejected message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsStoryRejected
         * @instance
         */
        NotificationContentsStoryRejected.prototype.message = null;

        /**
         * NotificationContentsStoryRejected actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsStoryRejected
         * @instance
         */
        NotificationContentsStoryRejected.prototype.actionUrl = null;

        /**
         * NotificationContentsStoryRejected thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsStoryRejected
         * @instance
         */
        NotificationContentsStoryRejected.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsStoryRejected storyTitle.
         * @member {google.protobuf.IStringValue|null|undefined} storyTitle
         * @memberof notification.NotificationContentsStoryRejected
         * @instance
         */
        NotificationContentsStoryRejected.prototype.storyTitle = null;

        /**
         * Creates a new NotificationContentsStoryRejected instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsStoryRejected
         * @static
         * @param {notification.INotificationContentsStoryRejected=} [properties] Properties to set
         * @returns {notification.NotificationContentsStoryRejected} NotificationContentsStoryRejected instance
         */
        NotificationContentsStoryRejected.create = function create(properties) {
            return new NotificationContentsStoryRejected(properties);
        };

        /**
         * Encodes the specified NotificationContentsStoryRejected message. Does not implicitly {@link notification.NotificationContentsStoryRejected.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsStoryRejected
         * @static
         * @param {notification.INotificationContentsStoryRejected} message NotificationContentsStoryRejected message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsStoryRejected.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.storyTitle != null && Object.hasOwnProperty.call(message, "storyTitle"))
                $root.google.protobuf.StringValue.encode(message.storyTitle, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsStoryRejected message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsStoryRejected
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsStoryRejected} NotificationContentsStoryRejected
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsStoryRejected.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsStoryRejected();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.storyTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsStoryRejected message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsStoryRejected
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsStoryRejected} NotificationContentsStoryRejected
         */
        NotificationContentsStoryRejected.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsStoryRejected)
                return object;
            var message = new $root.notification.NotificationContentsStoryRejected();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsStoryRejected.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsStoryRejected.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsStoryRejected.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.storyTitle != null) {
                if (typeof object.storyTitle !== "object")
                    throw TypeError(".notification.NotificationContentsStoryRejected.storyTitle: object expected");
                message.storyTitle = $root.google.protobuf.StringValue.fromObject(object.storyTitle);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsStoryRejected message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsStoryRejected
         * @static
         * @param {notification.NotificationContentsStoryRejected} message NotificationContentsStoryRejected
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsStoryRejected.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.storyTitle = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.storyTitle != null && message.hasOwnProperty("storyTitle"))
                object.storyTitle = $root.google.protobuf.StringValue.toObject(message.storyTitle, options);
            return object;
        };

        /**
         * Converts this NotificationContentsStoryRejected to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsStoryRejected
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsStoryRejected.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsStoryRejected
         * @function getTypeUrl
         * @memberof notification.NotificationContentsStoryRejected
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsStoryRejected.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsStoryRejected";
        };

        return NotificationContentsStoryRejected;
    })();

    notification.NotificationContentsUserBanned = (function() {

        /**
         * Properties of a NotificationContentsUserBanned.
         * @memberof notification
         * @interface INotificationContentsUserBanned
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsUserBanned message
         */

        /**
         * Constructs a new NotificationContentsUserBanned.
         * @memberof notification
         * @classdesc Represents a NotificationContentsUserBanned.
         * @implements INotificationContentsUserBanned
         * @constructor
         * @param {notification.INotificationContentsUserBanned=} [properties] Properties to set
         */
        function NotificationContentsUserBanned(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsUserBanned message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsUserBanned
         * @instance
         */
        NotificationContentsUserBanned.prototype.message = null;

        /**
         * Creates a new NotificationContentsUserBanned instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsUserBanned
         * @static
         * @param {notification.INotificationContentsUserBanned=} [properties] Properties to set
         * @returns {notification.NotificationContentsUserBanned} NotificationContentsUserBanned instance
         */
        NotificationContentsUserBanned.create = function create(properties) {
            return new NotificationContentsUserBanned(properties);
        };

        /**
         * Encodes the specified NotificationContentsUserBanned message. Does not implicitly {@link notification.NotificationContentsUserBanned.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsUserBanned
         * @static
         * @param {notification.INotificationContentsUserBanned} message NotificationContentsUserBanned message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsUserBanned.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsUserBanned message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsUserBanned
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsUserBanned} NotificationContentsUserBanned
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsUserBanned.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsUserBanned();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsUserBanned message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsUserBanned
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsUserBanned} NotificationContentsUserBanned
         */
        NotificationContentsUserBanned.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsUserBanned)
                return object;
            var message = new $root.notification.NotificationContentsUserBanned();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsUserBanned.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsUserBanned message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsUserBanned
         * @static
         * @param {notification.NotificationContentsUserBanned} message NotificationContentsUserBanned
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsUserBanned.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.message = null;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this NotificationContentsUserBanned to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsUserBanned
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsUserBanned.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsUserBanned
         * @function getTypeUrl
         * @memberof notification.NotificationContentsUserBanned
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsUserBanned.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsUserBanned";
        };

        return NotificationContentsUserBanned;
    })();

    notification.NotificationContentsPaymentFailure = (function() {

        /**
         * Properties of a NotificationContentsPaymentFailure.
         * @memberof notification
         * @interface INotificationContentsPaymentFailure
         * @property {google.protobuf.IStringValue|null} [title] NotificationContentsPaymentFailure title
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsPaymentFailure message
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsPaymentFailure iconUrl
         */

        /**
         * Constructs a new NotificationContentsPaymentFailure.
         * @memberof notification
         * @classdesc Represents a NotificationContentsPaymentFailure.
         * @implements INotificationContentsPaymentFailure
         * @constructor
         * @param {notification.INotificationContentsPaymentFailure=} [properties] Properties to set
         */
        function NotificationContentsPaymentFailure(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsPaymentFailure title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof notification.NotificationContentsPaymentFailure
         * @instance
         */
        NotificationContentsPaymentFailure.prototype.title = null;

        /**
         * NotificationContentsPaymentFailure message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsPaymentFailure
         * @instance
         */
        NotificationContentsPaymentFailure.prototype.message = null;

        /**
         * NotificationContentsPaymentFailure iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsPaymentFailure
         * @instance
         */
        NotificationContentsPaymentFailure.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsPaymentFailure instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsPaymentFailure
         * @static
         * @param {notification.INotificationContentsPaymentFailure=} [properties] Properties to set
         * @returns {notification.NotificationContentsPaymentFailure} NotificationContentsPaymentFailure instance
         */
        NotificationContentsPaymentFailure.create = function create(properties) {
            return new NotificationContentsPaymentFailure(properties);
        };

        /**
         * Encodes the specified NotificationContentsPaymentFailure message. Does not implicitly {@link notification.NotificationContentsPaymentFailure.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsPaymentFailure
         * @static
         * @param {notification.INotificationContentsPaymentFailure} message NotificationContentsPaymentFailure message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsPaymentFailure.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsPaymentFailure message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsPaymentFailure
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsPaymentFailure} NotificationContentsPaymentFailure
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsPaymentFailure.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsPaymentFailure();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsPaymentFailure message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsPaymentFailure
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsPaymentFailure} NotificationContentsPaymentFailure
         */
        NotificationContentsPaymentFailure.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsPaymentFailure)
                return object;
            var message = new $root.notification.NotificationContentsPaymentFailure();
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".notification.NotificationContentsPaymentFailure.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsPaymentFailure.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsPaymentFailure.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsPaymentFailure message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsPaymentFailure
         * @static
         * @param {notification.NotificationContentsPaymentFailure} message NotificationContentsPaymentFailure
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsPaymentFailure.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.title = null;
                object.message = null;
                object.iconUrl = null;
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            return object;
        };

        /**
         * Converts this NotificationContentsPaymentFailure to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsPaymentFailure
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsPaymentFailure.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsPaymentFailure
         * @function getTypeUrl
         * @memberof notification.NotificationContentsPaymentFailure
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsPaymentFailure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsPaymentFailure";
        };

        return NotificationContentsPaymentFailure;
    })();

    notification.NotificationContentsVipGracePeriodExpired = (function() {

        /**
         * Properties of a NotificationContentsVipGracePeriodExpired.
         * @memberof notification
         * @interface INotificationContentsVipGracePeriodExpired
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsVipGracePeriodExpired message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsVipGracePeriodExpired actionUrl
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsVipGracePeriodExpired iconUrl
         */

        /**
         * Constructs a new NotificationContentsVipGracePeriodExpired.
         * @memberof notification
         * @classdesc Represents a NotificationContentsVipGracePeriodExpired.
         * @implements INotificationContentsVipGracePeriodExpired
         * @constructor
         * @param {notification.INotificationContentsVipGracePeriodExpired=} [properties] Properties to set
         */
        function NotificationContentsVipGracePeriodExpired(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsVipGracePeriodExpired message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @instance
         */
        NotificationContentsVipGracePeriodExpired.prototype.message = null;

        /**
         * NotificationContentsVipGracePeriodExpired actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @instance
         */
        NotificationContentsVipGracePeriodExpired.prototype.actionUrl = null;

        /**
         * NotificationContentsVipGracePeriodExpired iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @instance
         */
        NotificationContentsVipGracePeriodExpired.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsVipGracePeriodExpired instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @static
         * @param {notification.INotificationContentsVipGracePeriodExpired=} [properties] Properties to set
         * @returns {notification.NotificationContentsVipGracePeriodExpired} NotificationContentsVipGracePeriodExpired instance
         */
        NotificationContentsVipGracePeriodExpired.create = function create(properties) {
            return new NotificationContentsVipGracePeriodExpired(properties);
        };

        /**
         * Encodes the specified NotificationContentsVipGracePeriodExpired message. Does not implicitly {@link notification.NotificationContentsVipGracePeriodExpired.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @static
         * @param {notification.INotificationContentsVipGracePeriodExpired} message NotificationContentsVipGracePeriodExpired message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsVipGracePeriodExpired.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsVipGracePeriodExpired message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsVipGracePeriodExpired} NotificationContentsVipGracePeriodExpired
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsVipGracePeriodExpired.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsVipGracePeriodExpired();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsVipGracePeriodExpired message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsVipGracePeriodExpired} NotificationContentsVipGracePeriodExpired
         */
        NotificationContentsVipGracePeriodExpired.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsVipGracePeriodExpired)
                return object;
            var message = new $root.notification.NotificationContentsVipGracePeriodExpired();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsVipGracePeriodExpired.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsVipGracePeriodExpired.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsVipGracePeriodExpired.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsVipGracePeriodExpired message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @static
         * @param {notification.NotificationContentsVipGracePeriodExpired} message NotificationContentsVipGracePeriodExpired
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsVipGracePeriodExpired.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.iconUrl = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            return object;
        };

        /**
         * Converts this NotificationContentsVipGracePeriodExpired to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsVipGracePeriodExpired.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsVipGracePeriodExpired
         * @function getTypeUrl
         * @memberof notification.NotificationContentsVipGracePeriodExpired
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsVipGracePeriodExpired.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsVipGracePeriodExpired";
        };

        return NotificationContentsVipGracePeriodExpired;
    })();

    notification.NotificationContentsNewFollower = (function() {

        /**
         * Properties of a NotificationContentsNewFollower.
         * @memberof notification
         * @interface INotificationContentsNewFollower
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsNewFollower message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsNewFollower actionUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsNewFollower userIcon
         */

        /**
         * Constructs a new NotificationContentsNewFollower.
         * @memberof notification
         * @classdesc Represents a NotificationContentsNewFollower.
         * @implements INotificationContentsNewFollower
         * @constructor
         * @param {notification.INotificationContentsNewFollower=} [properties] Properties to set
         */
        function NotificationContentsNewFollower(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsNewFollower message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsNewFollower
         * @instance
         */
        NotificationContentsNewFollower.prototype.message = null;

        /**
         * NotificationContentsNewFollower actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsNewFollower
         * @instance
         */
        NotificationContentsNewFollower.prototype.actionUrl = null;

        /**
         * NotificationContentsNewFollower userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsNewFollower
         * @instance
         */
        NotificationContentsNewFollower.prototype.userIcon = null;

        /**
         * Creates a new NotificationContentsNewFollower instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsNewFollower
         * @static
         * @param {notification.INotificationContentsNewFollower=} [properties] Properties to set
         * @returns {notification.NotificationContentsNewFollower} NotificationContentsNewFollower instance
         */
        NotificationContentsNewFollower.create = function create(properties) {
            return new NotificationContentsNewFollower(properties);
        };

        /**
         * Encodes the specified NotificationContentsNewFollower message. Does not implicitly {@link notification.NotificationContentsNewFollower.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsNewFollower
         * @static
         * @param {notification.INotificationContentsNewFollower} message NotificationContentsNewFollower message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsNewFollower.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsNewFollower message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsNewFollower
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsNewFollower} NotificationContentsNewFollower
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsNewFollower.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsNewFollower();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsNewFollower message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsNewFollower
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsNewFollower} NotificationContentsNewFollower
         */
        NotificationContentsNewFollower.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsNewFollower)
                return object;
            var message = new $root.notification.NotificationContentsNewFollower();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsNewFollower.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewFollower.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsNewFollower.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsNewFollower message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsNewFollower
         * @static
         * @param {notification.NotificationContentsNewFollower} message NotificationContentsNewFollower
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsNewFollower.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.userIcon = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            return object;
        };

        /**
         * Converts this NotificationContentsNewFollower to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsNewFollower
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsNewFollower.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsNewFollower
         * @function getTypeUrl
         * @memberof notification.NotificationContentsNewFollower
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsNewFollower.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsNewFollower";
        };

        return NotificationContentsNewFollower;
    })();

    notification.NotificationContentsNewStoryComment = (function() {

        /**
         * Properties of a NotificationContentsNewStoryComment.
         * @memberof notification
         * @interface INotificationContentsNewStoryComment
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsNewStoryComment message
         * @property {google.protobuf.IStringValue|null} [storyTitle] NotificationContentsNewStoryComment storyTitle
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsNewStoryComment actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsNewStoryComment thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsNewStoryComment userIcon
         */

        /**
         * Constructs a new NotificationContentsNewStoryComment.
         * @memberof notification
         * @classdesc Represents a NotificationContentsNewStoryComment.
         * @implements INotificationContentsNewStoryComment
         * @constructor
         * @param {notification.INotificationContentsNewStoryComment=} [properties] Properties to set
         */
        function NotificationContentsNewStoryComment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsNewStoryComment message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsNewStoryComment
         * @instance
         */
        NotificationContentsNewStoryComment.prototype.message = null;

        /**
         * NotificationContentsNewStoryComment storyTitle.
         * @member {google.protobuf.IStringValue|null|undefined} storyTitle
         * @memberof notification.NotificationContentsNewStoryComment
         * @instance
         */
        NotificationContentsNewStoryComment.prototype.storyTitle = null;

        /**
         * NotificationContentsNewStoryComment actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsNewStoryComment
         * @instance
         */
        NotificationContentsNewStoryComment.prototype.actionUrl = null;

        /**
         * NotificationContentsNewStoryComment thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsNewStoryComment
         * @instance
         */
        NotificationContentsNewStoryComment.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsNewStoryComment userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsNewStoryComment
         * @instance
         */
        NotificationContentsNewStoryComment.prototype.userIcon = null;

        /**
         * Creates a new NotificationContentsNewStoryComment instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsNewStoryComment
         * @static
         * @param {notification.INotificationContentsNewStoryComment=} [properties] Properties to set
         * @returns {notification.NotificationContentsNewStoryComment} NotificationContentsNewStoryComment instance
         */
        NotificationContentsNewStoryComment.create = function create(properties) {
            return new NotificationContentsNewStoryComment(properties);
        };

        /**
         * Encodes the specified NotificationContentsNewStoryComment message. Does not implicitly {@link notification.NotificationContentsNewStoryComment.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsNewStoryComment
         * @static
         * @param {notification.INotificationContentsNewStoryComment} message NotificationContentsNewStoryComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsNewStoryComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyTitle != null && Object.hasOwnProperty.call(message, "storyTitle"))
                $root.google.protobuf.StringValue.encode(message.storyTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsNewStoryComment message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsNewStoryComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsNewStoryComment} NotificationContentsNewStoryComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsNewStoryComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsNewStoryComment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsNewStoryComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsNewStoryComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsNewStoryComment} NotificationContentsNewStoryComment
         */
        NotificationContentsNewStoryComment.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsNewStoryComment)
                return object;
            var message = new $root.notification.NotificationContentsNewStoryComment();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsNewStoryComment.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.storyTitle != null) {
                if (typeof object.storyTitle !== "object")
                    throw TypeError(".notification.NotificationContentsNewStoryComment.storyTitle: object expected");
                message.storyTitle = $root.google.protobuf.StringValue.fromObject(object.storyTitle);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewStoryComment.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewStoryComment.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsNewStoryComment.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsNewStoryComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsNewStoryComment
         * @static
         * @param {notification.NotificationContentsNewStoryComment} message NotificationContentsNewStoryComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsNewStoryComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.storyTitle = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.userIcon = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.storyTitle != null && message.hasOwnProperty("storyTitle"))
                object.storyTitle = $root.google.protobuf.StringValue.toObject(message.storyTitle, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            return object;
        };

        /**
         * Converts this NotificationContentsNewStoryComment to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsNewStoryComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsNewStoryComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsNewStoryComment
         * @function getTypeUrl
         * @memberof notification.NotificationContentsNewStoryComment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsNewStoryComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsNewStoryComment";
        };

        return NotificationContentsNewStoryComment;
    })();

    notification.NotificationContentsNewCommentLike = (function() {

        /**
         * Properties of a NotificationContentsNewCommentLike.
         * @memberof notification
         * @interface INotificationContentsNewCommentLike
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsNewCommentLike message
         * @property {google.protobuf.IStringValue|null} [storyTitle] NotificationContentsNewCommentLike storyTitle
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsNewCommentLike actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsNewCommentLike thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsNewCommentLike userIcon
         */

        /**
         * Constructs a new NotificationContentsNewCommentLike.
         * @memberof notification
         * @classdesc Represents a NotificationContentsNewCommentLike.
         * @implements INotificationContentsNewCommentLike
         * @constructor
         * @param {notification.INotificationContentsNewCommentLike=} [properties] Properties to set
         */
        function NotificationContentsNewCommentLike(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsNewCommentLike message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsNewCommentLike
         * @instance
         */
        NotificationContentsNewCommentLike.prototype.message = null;

        /**
         * NotificationContentsNewCommentLike storyTitle.
         * @member {google.protobuf.IStringValue|null|undefined} storyTitle
         * @memberof notification.NotificationContentsNewCommentLike
         * @instance
         */
        NotificationContentsNewCommentLike.prototype.storyTitle = null;

        /**
         * NotificationContentsNewCommentLike actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsNewCommentLike
         * @instance
         */
        NotificationContentsNewCommentLike.prototype.actionUrl = null;

        /**
         * NotificationContentsNewCommentLike thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsNewCommentLike
         * @instance
         */
        NotificationContentsNewCommentLike.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsNewCommentLike userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsNewCommentLike
         * @instance
         */
        NotificationContentsNewCommentLike.prototype.userIcon = null;

        /**
         * Creates a new NotificationContentsNewCommentLike instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsNewCommentLike
         * @static
         * @param {notification.INotificationContentsNewCommentLike=} [properties] Properties to set
         * @returns {notification.NotificationContentsNewCommentLike} NotificationContentsNewCommentLike instance
         */
        NotificationContentsNewCommentLike.create = function create(properties) {
            return new NotificationContentsNewCommentLike(properties);
        };

        /**
         * Encodes the specified NotificationContentsNewCommentLike message. Does not implicitly {@link notification.NotificationContentsNewCommentLike.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsNewCommentLike
         * @static
         * @param {notification.INotificationContentsNewCommentLike} message NotificationContentsNewCommentLike message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsNewCommentLike.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyTitle != null && Object.hasOwnProperty.call(message, "storyTitle"))
                $root.google.protobuf.StringValue.encode(message.storyTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsNewCommentLike message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsNewCommentLike
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsNewCommentLike} NotificationContentsNewCommentLike
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsNewCommentLike.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsNewCommentLike();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsNewCommentLike message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsNewCommentLike
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsNewCommentLike} NotificationContentsNewCommentLike
         */
        NotificationContentsNewCommentLike.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsNewCommentLike)
                return object;
            var message = new $root.notification.NotificationContentsNewCommentLike();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentLike.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.storyTitle != null) {
                if (typeof object.storyTitle !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentLike.storyTitle: object expected");
                message.storyTitle = $root.google.protobuf.StringValue.fromObject(object.storyTitle);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentLike.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentLike.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentLike.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsNewCommentLike message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsNewCommentLike
         * @static
         * @param {notification.NotificationContentsNewCommentLike} message NotificationContentsNewCommentLike
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsNewCommentLike.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.storyTitle = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.userIcon = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.storyTitle != null && message.hasOwnProperty("storyTitle"))
                object.storyTitle = $root.google.protobuf.StringValue.toObject(message.storyTitle, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            return object;
        };

        /**
         * Converts this NotificationContentsNewCommentLike to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsNewCommentLike
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsNewCommentLike.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsNewCommentLike
         * @function getTypeUrl
         * @memberof notification.NotificationContentsNewCommentLike
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsNewCommentLike.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsNewCommentLike";
        };

        return NotificationContentsNewCommentLike;
    })();

    notification.NotificationContentsNewCommentResponse = (function() {

        /**
         * Properties of a NotificationContentsNewCommentResponse.
         * @memberof notification
         * @interface INotificationContentsNewCommentResponse
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsNewCommentResponse message
         * @property {google.protobuf.IStringValue|null} [storyTitle] NotificationContentsNewCommentResponse storyTitle
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsNewCommentResponse actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsNewCommentResponse thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsNewCommentResponse userIcon
         */

        /**
         * Constructs a new NotificationContentsNewCommentResponse.
         * @memberof notification
         * @classdesc Represents a NotificationContentsNewCommentResponse.
         * @implements INotificationContentsNewCommentResponse
         * @constructor
         * @param {notification.INotificationContentsNewCommentResponse=} [properties] Properties to set
         */
        function NotificationContentsNewCommentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsNewCommentResponse message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsNewCommentResponse
         * @instance
         */
        NotificationContentsNewCommentResponse.prototype.message = null;

        /**
         * NotificationContentsNewCommentResponse storyTitle.
         * @member {google.protobuf.IStringValue|null|undefined} storyTitle
         * @memberof notification.NotificationContentsNewCommentResponse
         * @instance
         */
        NotificationContentsNewCommentResponse.prototype.storyTitle = null;

        /**
         * NotificationContentsNewCommentResponse actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsNewCommentResponse
         * @instance
         */
        NotificationContentsNewCommentResponse.prototype.actionUrl = null;

        /**
         * NotificationContentsNewCommentResponse thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsNewCommentResponse
         * @instance
         */
        NotificationContentsNewCommentResponse.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsNewCommentResponse userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsNewCommentResponse
         * @instance
         */
        NotificationContentsNewCommentResponse.prototype.userIcon = null;

        /**
         * Creates a new NotificationContentsNewCommentResponse instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsNewCommentResponse
         * @static
         * @param {notification.INotificationContentsNewCommentResponse=} [properties] Properties to set
         * @returns {notification.NotificationContentsNewCommentResponse} NotificationContentsNewCommentResponse instance
         */
        NotificationContentsNewCommentResponse.create = function create(properties) {
            return new NotificationContentsNewCommentResponse(properties);
        };

        /**
         * Encodes the specified NotificationContentsNewCommentResponse message. Does not implicitly {@link notification.NotificationContentsNewCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsNewCommentResponse
         * @static
         * @param {notification.INotificationContentsNewCommentResponse} message NotificationContentsNewCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsNewCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyTitle != null && Object.hasOwnProperty.call(message, "storyTitle"))
                $root.google.protobuf.StringValue.encode(message.storyTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsNewCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsNewCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsNewCommentResponse} NotificationContentsNewCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsNewCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsNewCommentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsNewCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsNewCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsNewCommentResponse} NotificationContentsNewCommentResponse
         */
        NotificationContentsNewCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsNewCommentResponse)
                return object;
            var message = new $root.notification.NotificationContentsNewCommentResponse();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentResponse.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.storyTitle != null) {
                if (typeof object.storyTitle !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentResponse.storyTitle: object expected");
                message.storyTitle = $root.google.protobuf.StringValue.fromObject(object.storyTitle);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentResponse.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentResponse.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsNewCommentResponse.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsNewCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsNewCommentResponse
         * @static
         * @param {notification.NotificationContentsNewCommentResponse} message NotificationContentsNewCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsNewCommentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.storyTitle = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.userIcon = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.storyTitle != null && message.hasOwnProperty("storyTitle"))
                object.storyTitle = $root.google.protobuf.StringValue.toObject(message.storyTitle, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            return object;
        };

        /**
         * Converts this NotificationContentsNewCommentResponse to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsNewCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsNewCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsNewCommentResponse
         * @function getTypeUrl
         * @memberof notification.NotificationContentsNewCommentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsNewCommentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsNewCommentResponse";
        };

        return NotificationContentsNewCommentResponse;
    })();

    notification.NotificationContentsFollowingUserNewStory = (function() {

        /**
         * Properties of a NotificationContentsFollowingUserNewStory.
         * @memberof notification
         * @interface INotificationContentsFollowingUserNewStory
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsFollowingUserNewStory message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsFollowingUserNewStory actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsFollowingUserNewStory thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsFollowingUserNewStory userIcon
         */

        /**
         * Constructs a new NotificationContentsFollowingUserNewStory.
         * @memberof notification
         * @classdesc Represents a NotificationContentsFollowingUserNewStory.
         * @implements INotificationContentsFollowingUserNewStory
         * @constructor
         * @param {notification.INotificationContentsFollowingUserNewStory=} [properties] Properties to set
         */
        function NotificationContentsFollowingUserNewStory(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsFollowingUserNewStory message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @instance
         */
        NotificationContentsFollowingUserNewStory.prototype.message = null;

        /**
         * NotificationContentsFollowingUserNewStory actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @instance
         */
        NotificationContentsFollowingUserNewStory.prototype.actionUrl = null;

        /**
         * NotificationContentsFollowingUserNewStory thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @instance
         */
        NotificationContentsFollowingUserNewStory.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsFollowingUserNewStory userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @instance
         */
        NotificationContentsFollowingUserNewStory.prototype.userIcon = null;

        /**
         * Creates a new NotificationContentsFollowingUserNewStory instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @static
         * @param {notification.INotificationContentsFollowingUserNewStory=} [properties] Properties to set
         * @returns {notification.NotificationContentsFollowingUserNewStory} NotificationContentsFollowingUserNewStory instance
         */
        NotificationContentsFollowingUserNewStory.create = function create(properties) {
            return new NotificationContentsFollowingUserNewStory(properties);
        };

        /**
         * Encodes the specified NotificationContentsFollowingUserNewStory message. Does not implicitly {@link notification.NotificationContentsFollowingUserNewStory.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @static
         * @param {notification.INotificationContentsFollowingUserNewStory} message NotificationContentsFollowingUserNewStory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsFollowingUserNewStory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsFollowingUserNewStory message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsFollowingUserNewStory} NotificationContentsFollowingUserNewStory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsFollowingUserNewStory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsFollowingUserNewStory();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsFollowingUserNewStory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsFollowingUserNewStory} NotificationContentsFollowingUserNewStory
         */
        NotificationContentsFollowingUserNewStory.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsFollowingUserNewStory)
                return object;
            var message = new $root.notification.NotificationContentsFollowingUserNewStory();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsFollowingUserNewStory.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsFollowingUserNewStory.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsFollowingUserNewStory.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsFollowingUserNewStory.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsFollowingUserNewStory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @static
         * @param {notification.NotificationContentsFollowingUserNewStory} message NotificationContentsFollowingUserNewStory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsFollowingUserNewStory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.userIcon = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            return object;
        };

        /**
         * Converts this NotificationContentsFollowingUserNewStory to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsFollowingUserNewStory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsFollowingUserNewStory
         * @function getTypeUrl
         * @memberof notification.NotificationContentsFollowingUserNewStory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsFollowingUserNewStory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsFollowingUserNewStory";
        };

        return NotificationContentsFollowingUserNewStory;
    })();

    notification.NotificationContentsUserLikeBreak = (function() {

        /**
         * Properties of a NotificationContentsUserLikeBreak.
         * @memberof notification
         * @interface INotificationContentsUserLikeBreak
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsUserLikeBreak message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsUserLikeBreak actionUrl
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsUserLikeBreak iconUrl
         */

        /**
         * Constructs a new NotificationContentsUserLikeBreak.
         * @memberof notification
         * @classdesc Represents a NotificationContentsUserLikeBreak.
         * @implements INotificationContentsUserLikeBreak
         * @constructor
         * @param {notification.INotificationContentsUserLikeBreak=} [properties] Properties to set
         */
        function NotificationContentsUserLikeBreak(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsUserLikeBreak message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsUserLikeBreak
         * @instance
         */
        NotificationContentsUserLikeBreak.prototype.message = null;

        /**
         * NotificationContentsUserLikeBreak actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsUserLikeBreak
         * @instance
         */
        NotificationContentsUserLikeBreak.prototype.actionUrl = null;

        /**
         * NotificationContentsUserLikeBreak iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsUserLikeBreak
         * @instance
         */
        NotificationContentsUserLikeBreak.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsUserLikeBreak instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsUserLikeBreak
         * @static
         * @param {notification.INotificationContentsUserLikeBreak=} [properties] Properties to set
         * @returns {notification.NotificationContentsUserLikeBreak} NotificationContentsUserLikeBreak instance
         */
        NotificationContentsUserLikeBreak.create = function create(properties) {
            return new NotificationContentsUserLikeBreak(properties);
        };

        /**
         * Encodes the specified NotificationContentsUserLikeBreak message. Does not implicitly {@link notification.NotificationContentsUserLikeBreak.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsUserLikeBreak
         * @static
         * @param {notification.INotificationContentsUserLikeBreak} message NotificationContentsUserLikeBreak message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsUserLikeBreak.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsUserLikeBreak message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsUserLikeBreak
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsUserLikeBreak} NotificationContentsUserLikeBreak
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsUserLikeBreak.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsUserLikeBreak();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsUserLikeBreak message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsUserLikeBreak
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsUserLikeBreak} NotificationContentsUserLikeBreak
         */
        NotificationContentsUserLikeBreak.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsUserLikeBreak)
                return object;
            var message = new $root.notification.NotificationContentsUserLikeBreak();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsUserLikeBreak.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsUserLikeBreak.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsUserLikeBreak.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsUserLikeBreak message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsUserLikeBreak
         * @static
         * @param {notification.NotificationContentsUserLikeBreak} message NotificationContentsUserLikeBreak
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsUserLikeBreak.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.iconUrl = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            return object;
        };

        /**
         * Converts this NotificationContentsUserLikeBreak to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsUserLikeBreak
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsUserLikeBreak.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsUserLikeBreak
         * @function getTypeUrl
         * @memberof notification.NotificationContentsUserLikeBreak
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsUserLikeBreak.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsUserLikeBreak";
        };

        return NotificationContentsUserLikeBreak;
    })();

    notification.NotificationContentsBookmarkNewSeriesStory = (function() {

        /**
         * Properties of a NotificationContentsBookmarkNewSeriesStory.
         * @memberof notification
         * @interface INotificationContentsBookmarkNewSeriesStory
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsBookmarkNewSeriesStory message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsBookmarkNewSeriesStory actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsBookmarkNewSeriesStory thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsBookmarkNewSeriesStory userIcon
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsBookmarkNewSeriesStory iconUrl
         */

        /**
         * Constructs a new NotificationContentsBookmarkNewSeriesStory.
         * @memberof notification
         * @classdesc Represents a NotificationContentsBookmarkNewSeriesStory.
         * @implements INotificationContentsBookmarkNewSeriesStory
         * @constructor
         * @param {notification.INotificationContentsBookmarkNewSeriesStory=} [properties] Properties to set
         */
        function NotificationContentsBookmarkNewSeriesStory(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsBookmarkNewSeriesStory message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStory.prototype.message = null;

        /**
         * NotificationContentsBookmarkNewSeriesStory actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStory.prototype.actionUrl = null;

        /**
         * NotificationContentsBookmarkNewSeriesStory thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStory.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsBookmarkNewSeriesStory userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStory.prototype.userIcon = null;

        /**
         * NotificationContentsBookmarkNewSeriesStory iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStory.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsBookmarkNewSeriesStory instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @static
         * @param {notification.INotificationContentsBookmarkNewSeriesStory=} [properties] Properties to set
         * @returns {notification.NotificationContentsBookmarkNewSeriesStory} NotificationContentsBookmarkNewSeriesStory instance
         */
        NotificationContentsBookmarkNewSeriesStory.create = function create(properties) {
            return new NotificationContentsBookmarkNewSeriesStory(properties);
        };

        /**
         * Encodes the specified NotificationContentsBookmarkNewSeriesStory message. Does not implicitly {@link notification.NotificationContentsBookmarkNewSeriesStory.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @static
         * @param {notification.INotificationContentsBookmarkNewSeriesStory} message NotificationContentsBookmarkNewSeriesStory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsBookmarkNewSeriesStory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsBookmarkNewSeriesStory message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsBookmarkNewSeriesStory} NotificationContentsBookmarkNewSeriesStory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsBookmarkNewSeriesStory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsBookmarkNewSeriesStory();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsBookmarkNewSeriesStory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsBookmarkNewSeriesStory} NotificationContentsBookmarkNewSeriesStory
         */
        NotificationContentsBookmarkNewSeriesStory.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsBookmarkNewSeriesStory)
                return object;
            var message = new $root.notification.NotificationContentsBookmarkNewSeriesStory();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsBookmarkNewSeriesStory.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsBookmarkNewSeriesStory.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsBookmarkNewSeriesStory.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsBookmarkNewSeriesStory.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsBookmarkNewSeriesStory.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsBookmarkNewSeriesStory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @static
         * @param {notification.NotificationContentsBookmarkNewSeriesStory} message NotificationContentsBookmarkNewSeriesStory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsBookmarkNewSeriesStory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.iconUrl = null;
                object.userIcon = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            return object;
        };

        /**
         * Converts this NotificationContentsBookmarkNewSeriesStory to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsBookmarkNewSeriesStory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsBookmarkNewSeriesStory
         * @function getTypeUrl
         * @memberof notification.NotificationContentsBookmarkNewSeriesStory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsBookmarkNewSeriesStory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsBookmarkNewSeriesStory";
        };

        return NotificationContentsBookmarkNewSeriesStory;
    })();

    notification.NotificationContentsUserStoryLikeBreak = (function() {

        /**
         * Properties of a NotificationContentsUserStoryLikeBreak.
         * @memberof notification
         * @interface INotificationContentsUserStoryLikeBreak
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsUserStoryLikeBreak message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsUserStoryLikeBreak actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsUserStoryLikeBreak thumbnailUrl
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsUserStoryLikeBreak iconUrl
         */

        /**
         * Constructs a new NotificationContentsUserStoryLikeBreak.
         * @memberof notification
         * @classdesc Represents a NotificationContentsUserStoryLikeBreak.
         * @implements INotificationContentsUserStoryLikeBreak
         * @constructor
         * @param {notification.INotificationContentsUserStoryLikeBreak=} [properties] Properties to set
         */
        function NotificationContentsUserStoryLikeBreak(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsUserStoryLikeBreak message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @instance
         */
        NotificationContentsUserStoryLikeBreak.prototype.message = null;

        /**
         * NotificationContentsUserStoryLikeBreak actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @instance
         */
        NotificationContentsUserStoryLikeBreak.prototype.actionUrl = null;

        /**
         * NotificationContentsUserStoryLikeBreak thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @instance
         */
        NotificationContentsUserStoryLikeBreak.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsUserStoryLikeBreak iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @instance
         */
        NotificationContentsUserStoryLikeBreak.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsUserStoryLikeBreak instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @static
         * @param {notification.INotificationContentsUserStoryLikeBreak=} [properties] Properties to set
         * @returns {notification.NotificationContentsUserStoryLikeBreak} NotificationContentsUserStoryLikeBreak instance
         */
        NotificationContentsUserStoryLikeBreak.create = function create(properties) {
            return new NotificationContentsUserStoryLikeBreak(properties);
        };

        /**
         * Encodes the specified NotificationContentsUserStoryLikeBreak message. Does not implicitly {@link notification.NotificationContentsUserStoryLikeBreak.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @static
         * @param {notification.INotificationContentsUserStoryLikeBreak} message NotificationContentsUserStoryLikeBreak message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsUserStoryLikeBreak.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsUserStoryLikeBreak message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsUserStoryLikeBreak} NotificationContentsUserStoryLikeBreak
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsUserStoryLikeBreak.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsUserStoryLikeBreak();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsUserStoryLikeBreak message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsUserStoryLikeBreak} NotificationContentsUserStoryLikeBreak
         */
        NotificationContentsUserStoryLikeBreak.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsUserStoryLikeBreak)
                return object;
            var message = new $root.notification.NotificationContentsUserStoryLikeBreak();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryLikeBreak.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryLikeBreak.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryLikeBreak.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryLikeBreak.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsUserStoryLikeBreak message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @static
         * @param {notification.NotificationContentsUserStoryLikeBreak} message NotificationContentsUserStoryLikeBreak
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsUserStoryLikeBreak.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.iconUrl = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            return object;
        };

        /**
         * Converts this NotificationContentsUserStoryLikeBreak to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsUserStoryLikeBreak.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsUserStoryLikeBreak
         * @function getTypeUrl
         * @memberof notification.NotificationContentsUserStoryLikeBreak
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsUserStoryLikeBreak.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsUserStoryLikeBreak";
        };

        return NotificationContentsUserStoryLikeBreak;
    })();

    notification.NotificationContentsNewMedal = (function() {

        /**
         * Properties of a NotificationContentsNewMedal.
         * @memberof notification
         * @interface INotificationContentsNewMedal
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsNewMedal message
         * @property {google.protobuf.IStringValue|null} [storyTitle] NotificationContentsNewMedal storyTitle
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsNewMedal actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsNewMedal thumbnailUrl
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsNewMedal iconUrl
         */

        /**
         * Constructs a new NotificationContentsNewMedal.
         * @memberof notification
         * @classdesc Represents a NotificationContentsNewMedal.
         * @implements INotificationContentsNewMedal
         * @constructor
         * @param {notification.INotificationContentsNewMedal=} [properties] Properties to set
         */
        function NotificationContentsNewMedal(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsNewMedal message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsNewMedal
         * @instance
         */
        NotificationContentsNewMedal.prototype.message = null;

        /**
         * NotificationContentsNewMedal storyTitle.
         * @member {google.protobuf.IStringValue|null|undefined} storyTitle
         * @memberof notification.NotificationContentsNewMedal
         * @instance
         */
        NotificationContentsNewMedal.prototype.storyTitle = null;

        /**
         * NotificationContentsNewMedal actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsNewMedal
         * @instance
         */
        NotificationContentsNewMedal.prototype.actionUrl = null;

        /**
         * NotificationContentsNewMedal thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsNewMedal
         * @instance
         */
        NotificationContentsNewMedal.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsNewMedal iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsNewMedal
         * @instance
         */
        NotificationContentsNewMedal.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsNewMedal instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsNewMedal
         * @static
         * @param {notification.INotificationContentsNewMedal=} [properties] Properties to set
         * @returns {notification.NotificationContentsNewMedal} NotificationContentsNewMedal instance
         */
        NotificationContentsNewMedal.create = function create(properties) {
            return new NotificationContentsNewMedal(properties);
        };

        /**
         * Encodes the specified NotificationContentsNewMedal message. Does not implicitly {@link notification.NotificationContentsNewMedal.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsNewMedal
         * @static
         * @param {notification.INotificationContentsNewMedal} message NotificationContentsNewMedal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsNewMedal.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyTitle != null && Object.hasOwnProperty.call(message, "storyTitle"))
                $root.google.protobuf.StringValue.encode(message.storyTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsNewMedal message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsNewMedal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsNewMedal} NotificationContentsNewMedal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsNewMedal.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsNewMedal();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsNewMedal message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsNewMedal
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsNewMedal} NotificationContentsNewMedal
         */
        NotificationContentsNewMedal.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsNewMedal)
                return object;
            var message = new $root.notification.NotificationContentsNewMedal();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsNewMedal.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.storyTitle != null) {
                if (typeof object.storyTitle !== "object")
                    throw TypeError(".notification.NotificationContentsNewMedal.storyTitle: object expected");
                message.storyTitle = $root.google.protobuf.StringValue.fromObject(object.storyTitle);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewMedal.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewMedal.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsNewMedal.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsNewMedal message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsNewMedal
         * @static
         * @param {notification.NotificationContentsNewMedal} message NotificationContentsNewMedal
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsNewMedal.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.storyTitle = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.iconUrl = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.storyTitle != null && message.hasOwnProperty("storyTitle"))
                object.storyTitle = $root.google.protobuf.StringValue.toObject(message.storyTitle, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            return object;
        };

        /**
         * Converts this NotificationContentsNewMedal to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsNewMedal
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsNewMedal.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsNewMedal
         * @function getTypeUrl
         * @memberof notification.NotificationContentsNewMedal
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsNewMedal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsNewMedal";
        };

        return NotificationContentsNewMedal;
    })();

    notification.NotificationContentsUserStoryNewLikes = (function() {

        /**
         * Properties of a NotificationContentsUserStoryNewLikes.
         * @memberof notification
         * @interface INotificationContentsUserStoryNewLikes
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsUserStoryNewLikes message
         * @property {google.protobuf.IStringValue|null} [storyTitle] NotificationContentsUserStoryNewLikes storyTitle
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsUserStoryNewLikes actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsUserStoryNewLikes thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsUserStoryNewLikes userIcon
         */

        /**
         * Constructs a new NotificationContentsUserStoryNewLikes.
         * @memberof notification
         * @classdesc Represents a NotificationContentsUserStoryNewLikes.
         * @implements INotificationContentsUserStoryNewLikes
         * @constructor
         * @param {notification.INotificationContentsUserStoryNewLikes=} [properties] Properties to set
         */
        function NotificationContentsUserStoryNewLikes(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsUserStoryNewLikes message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @instance
         */
        NotificationContentsUserStoryNewLikes.prototype.message = null;

        /**
         * NotificationContentsUserStoryNewLikes storyTitle.
         * @member {google.protobuf.IStringValue|null|undefined} storyTitle
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @instance
         */
        NotificationContentsUserStoryNewLikes.prototype.storyTitle = null;

        /**
         * NotificationContentsUserStoryNewLikes actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @instance
         */
        NotificationContentsUserStoryNewLikes.prototype.actionUrl = null;

        /**
         * NotificationContentsUserStoryNewLikes thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @instance
         */
        NotificationContentsUserStoryNewLikes.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsUserStoryNewLikes userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @instance
         */
        NotificationContentsUserStoryNewLikes.prototype.userIcon = null;

        /**
         * Creates a new NotificationContentsUserStoryNewLikes instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @static
         * @param {notification.INotificationContentsUserStoryNewLikes=} [properties] Properties to set
         * @returns {notification.NotificationContentsUserStoryNewLikes} NotificationContentsUserStoryNewLikes instance
         */
        NotificationContentsUserStoryNewLikes.create = function create(properties) {
            return new NotificationContentsUserStoryNewLikes(properties);
        };

        /**
         * Encodes the specified NotificationContentsUserStoryNewLikes message. Does not implicitly {@link notification.NotificationContentsUserStoryNewLikes.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @static
         * @param {notification.INotificationContentsUserStoryNewLikes} message NotificationContentsUserStoryNewLikes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsUserStoryNewLikes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyTitle != null && Object.hasOwnProperty.call(message, "storyTitle"))
                $root.google.protobuf.StringValue.encode(message.storyTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsUserStoryNewLikes message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsUserStoryNewLikes} NotificationContentsUserStoryNewLikes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsUserStoryNewLikes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsUserStoryNewLikes();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsUserStoryNewLikes message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsUserStoryNewLikes} NotificationContentsUserStoryNewLikes
         */
        NotificationContentsUserStoryNewLikes.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsUserStoryNewLikes)
                return object;
            var message = new $root.notification.NotificationContentsUserStoryNewLikes();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryNewLikes.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.storyTitle != null) {
                if (typeof object.storyTitle !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryNewLikes.storyTitle: object expected");
                message.storyTitle = $root.google.protobuf.StringValue.fromObject(object.storyTitle);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryNewLikes.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryNewLikes.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsUserStoryNewLikes.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsUserStoryNewLikes message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @static
         * @param {notification.NotificationContentsUserStoryNewLikes} message NotificationContentsUserStoryNewLikes
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsUserStoryNewLikes.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.storyTitle = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.userIcon = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.storyTitle != null && message.hasOwnProperty("storyTitle"))
                object.storyTitle = $root.google.protobuf.StringValue.toObject(message.storyTitle, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            return object;
        };

        /**
         * Converts this NotificationContentsUserStoryNewLikes to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsUserStoryNewLikes.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsUserStoryNewLikes
         * @function getTypeUrl
         * @memberof notification.NotificationContentsUserStoryNewLikes
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsUserStoryNewLikes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsUserStoryNewLikes";
        };

        return NotificationContentsUserStoryNewLikes;
    })();

    notification.NotificationContentsStoryVideoCreated = (function() {

        /**
         * Properties of a NotificationContentsStoryVideoCreated.
         * @memberof notification
         * @interface INotificationContentsStoryVideoCreated
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsStoryVideoCreated message
         * @property {google.protobuf.IStringValue|null} [storyTitle] NotificationContentsStoryVideoCreated storyTitle
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsStoryVideoCreated actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsStoryVideoCreated thumbnailUrl
         * @property {google.protobuf.IStringValue|null} [iconUrl] NotificationContentsStoryVideoCreated iconUrl
         */

        /**
         * Constructs a new NotificationContentsStoryVideoCreated.
         * @memberof notification
         * @classdesc Represents a NotificationContentsStoryVideoCreated.
         * @implements INotificationContentsStoryVideoCreated
         * @constructor
         * @param {notification.INotificationContentsStoryVideoCreated=} [properties] Properties to set
         */
        function NotificationContentsStoryVideoCreated(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsStoryVideoCreated message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @instance
         */
        NotificationContentsStoryVideoCreated.prototype.message = null;

        /**
         * NotificationContentsStoryVideoCreated storyTitle.
         * @member {google.protobuf.IStringValue|null|undefined} storyTitle
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @instance
         */
        NotificationContentsStoryVideoCreated.prototype.storyTitle = null;

        /**
         * NotificationContentsStoryVideoCreated actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @instance
         */
        NotificationContentsStoryVideoCreated.prototype.actionUrl = null;

        /**
         * NotificationContentsStoryVideoCreated thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @instance
         */
        NotificationContentsStoryVideoCreated.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsStoryVideoCreated iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @instance
         */
        NotificationContentsStoryVideoCreated.prototype.iconUrl = null;

        /**
         * Creates a new NotificationContentsStoryVideoCreated instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @static
         * @param {notification.INotificationContentsStoryVideoCreated=} [properties] Properties to set
         * @returns {notification.NotificationContentsStoryVideoCreated} NotificationContentsStoryVideoCreated instance
         */
        NotificationContentsStoryVideoCreated.create = function create(properties) {
            return new NotificationContentsStoryVideoCreated(properties);
        };

        /**
         * Encodes the specified NotificationContentsStoryVideoCreated message. Does not implicitly {@link notification.NotificationContentsStoryVideoCreated.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @static
         * @param {notification.INotificationContentsStoryVideoCreated} message NotificationContentsStoryVideoCreated message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsStoryVideoCreated.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyTitle != null && Object.hasOwnProperty.call(message, "storyTitle"))
                $root.google.protobuf.StringValue.encode(message.storyTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsStoryVideoCreated message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsStoryVideoCreated} NotificationContentsStoryVideoCreated
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsStoryVideoCreated.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsStoryVideoCreated();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsStoryVideoCreated message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsStoryVideoCreated} NotificationContentsStoryVideoCreated
         */
        NotificationContentsStoryVideoCreated.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsStoryVideoCreated)
                return object;
            var message = new $root.notification.NotificationContentsStoryVideoCreated();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsStoryVideoCreated.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.storyTitle != null) {
                if (typeof object.storyTitle !== "object")
                    throw TypeError(".notification.NotificationContentsStoryVideoCreated.storyTitle: object expected");
                message.storyTitle = $root.google.protobuf.StringValue.fromObject(object.storyTitle);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsStoryVideoCreated.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".notification.NotificationContentsStoryVideoCreated.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".notification.NotificationContentsStoryVideoCreated.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsStoryVideoCreated message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @static
         * @param {notification.NotificationContentsStoryVideoCreated} message NotificationContentsStoryVideoCreated
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsStoryVideoCreated.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.storyTitle = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.iconUrl = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.storyTitle != null && message.hasOwnProperty("storyTitle"))
                object.storyTitle = $root.google.protobuf.StringValue.toObject(message.storyTitle, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            return object;
        };

        /**
         * Converts this NotificationContentsStoryVideoCreated to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsStoryVideoCreated.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsStoryVideoCreated
         * @function getTypeUrl
         * @memberof notification.NotificationContentsStoryVideoCreated
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsStoryVideoCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsStoryVideoCreated";
        };

        return NotificationContentsStoryVideoCreated;
    })();

    notification.NotificationContentsMutualFollowReadRequest = (function() {

        /**
         * Properties of a NotificationContentsMutualFollowReadRequest.
         * @memberof notification
         * @interface INotificationContentsMutualFollowReadRequest
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsMutualFollowReadRequest message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsMutualFollowReadRequest actionUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsMutualFollowReadRequest userIcon
         * @property {google.protobuf.IStringValue|null} [seriesTitle] NotificationContentsMutualFollowReadRequest seriesTitle
         */

        /**
         * Constructs a new NotificationContentsMutualFollowReadRequest.
         * @memberof notification
         * @classdesc Represents a NotificationContentsMutualFollowReadRequest.
         * @implements INotificationContentsMutualFollowReadRequest
         * @constructor
         * @param {notification.INotificationContentsMutualFollowReadRequest=} [properties] Properties to set
         */
        function NotificationContentsMutualFollowReadRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsMutualFollowReadRequest message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @instance
         */
        NotificationContentsMutualFollowReadRequest.prototype.message = null;

        /**
         * NotificationContentsMutualFollowReadRequest actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @instance
         */
        NotificationContentsMutualFollowReadRequest.prototype.actionUrl = null;

        /**
         * NotificationContentsMutualFollowReadRequest userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @instance
         */
        NotificationContentsMutualFollowReadRequest.prototype.userIcon = null;

        /**
         * NotificationContentsMutualFollowReadRequest seriesTitle.
         * @member {google.protobuf.IStringValue|null|undefined} seriesTitle
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @instance
         */
        NotificationContentsMutualFollowReadRequest.prototype.seriesTitle = null;

        /**
         * Creates a new NotificationContentsMutualFollowReadRequest instance using the specified properties.
         * @function create
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @static
         * @param {notification.INotificationContentsMutualFollowReadRequest=} [properties] Properties to set
         * @returns {notification.NotificationContentsMutualFollowReadRequest} NotificationContentsMutualFollowReadRequest instance
         */
        NotificationContentsMutualFollowReadRequest.create = function create(properties) {
            return new NotificationContentsMutualFollowReadRequest(properties);
        };

        /**
         * Encodes the specified NotificationContentsMutualFollowReadRequest message. Does not implicitly {@link notification.NotificationContentsMutualFollowReadRequest.verify|verify} messages.
         * @function encode
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @static
         * @param {notification.INotificationContentsMutualFollowReadRequest} message NotificationContentsMutualFollowReadRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsMutualFollowReadRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.seriesTitle != null && Object.hasOwnProperty.call(message, "seriesTitle"))
                $root.google.protobuf.StringValue.encode(message.seriesTitle, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsMutualFollowReadRequest message from the specified reader or buffer.
         * @function decode
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {notification.NotificationContentsMutualFollowReadRequest} NotificationContentsMutualFollowReadRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsMutualFollowReadRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.notification.NotificationContentsMutualFollowReadRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.seriesTitle = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsMutualFollowReadRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {notification.NotificationContentsMutualFollowReadRequest} NotificationContentsMutualFollowReadRequest
         */
        NotificationContentsMutualFollowReadRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.notification.NotificationContentsMutualFollowReadRequest)
                return object;
            var message = new $root.notification.NotificationContentsMutualFollowReadRequest();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".notification.NotificationContentsMutualFollowReadRequest.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".notification.NotificationContentsMutualFollowReadRequest.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".notification.NotificationContentsMutualFollowReadRequest.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            if (object.seriesTitle != null) {
                if (typeof object.seriesTitle !== "object")
                    throw TypeError(".notification.NotificationContentsMutualFollowReadRequest.seriesTitle: object expected");
                message.seriesTitle = $root.google.protobuf.StringValue.fromObject(object.seriesTitle);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsMutualFollowReadRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @static
         * @param {notification.NotificationContentsMutualFollowReadRequest} message NotificationContentsMutualFollowReadRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsMutualFollowReadRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.userIcon = null;
                object.seriesTitle = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            if (message.seriesTitle != null && message.hasOwnProperty("seriesTitle"))
                object.seriesTitle = $root.google.protobuf.StringValue.toObject(message.seriesTitle, options);
            return object;
        };

        /**
         * Converts this NotificationContentsMutualFollowReadRequest to JSON.
         * @function toJSON
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsMutualFollowReadRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsMutualFollowReadRequest
         * @function getTypeUrl
         * @memberof notification.NotificationContentsMutualFollowReadRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsMutualFollowReadRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/notification.NotificationContentsMutualFollowReadRequest";
        };

        return NotificationContentsMutualFollowReadRequest;
    })();

    return notification;
})();

$root.reading_progress = (function() {

    /**
     * Namespace reading_progress.
     * @exports reading_progress
     * @namespace
     */
    var reading_progress = {};

    reading_progress.ReadingProgress = (function() {

        /**
         * Properties of a ReadingProgress.
         * @memberof reading_progress
         * @interface IReadingProgress
         * @property {google.protobuf.IStringValue|null} [storyId] ReadingProgress storyId
         * @property {google.protobuf.IInt32Value|null} [index] ReadingProgress index
         * @property {google.protobuf.IBoolValue|null} [isFinished] ReadingProgress isFinished
         * @property {google.protobuf.ITimestamp|null} [createdAt] ReadingProgress createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] ReadingProgress updatedAt
         */

        /**
         * Constructs a new ReadingProgress.
         * @memberof reading_progress
         * @classdesc Represents a ReadingProgress.
         * @implements IReadingProgress
         * @constructor
         * @param {reading_progress.IReadingProgress=} [properties] Properties to set
         */
        function ReadingProgress(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadingProgress storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof reading_progress.ReadingProgress
         * @instance
         */
        ReadingProgress.prototype.storyId = null;

        /**
         * ReadingProgress index.
         * @member {google.protobuf.IInt32Value|null|undefined} index
         * @memberof reading_progress.ReadingProgress
         * @instance
         */
        ReadingProgress.prototype.index = null;

        /**
         * ReadingProgress isFinished.
         * @member {google.protobuf.IBoolValue|null|undefined} isFinished
         * @memberof reading_progress.ReadingProgress
         * @instance
         */
        ReadingProgress.prototype.isFinished = null;

        /**
         * ReadingProgress createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof reading_progress.ReadingProgress
         * @instance
         */
        ReadingProgress.prototype.createdAt = null;

        /**
         * ReadingProgress updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof reading_progress.ReadingProgress
         * @instance
         */
        ReadingProgress.prototype.updatedAt = null;

        /**
         * Creates a new ReadingProgress instance using the specified properties.
         * @function create
         * @memberof reading_progress.ReadingProgress
         * @static
         * @param {reading_progress.IReadingProgress=} [properties] Properties to set
         * @returns {reading_progress.ReadingProgress} ReadingProgress instance
         */
        ReadingProgress.create = function create(properties) {
            return new ReadingProgress(properties);
        };

        /**
         * Encodes the specified ReadingProgress message. Does not implicitly {@link reading_progress.ReadingProgress.verify|verify} messages.
         * @function encode
         * @memberof reading_progress.ReadingProgress
         * @static
         * @param {reading_progress.IReadingProgress} message ReadingProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadingProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                $root.google.protobuf.Int32Value.encode(message.index, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.isFinished != null && Object.hasOwnProperty.call(message, "isFinished"))
                $root.google.protobuf.BoolValue.encode(message.isFinished, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ReadingProgress message from the specified reader or buffer.
         * @function decode
         * @memberof reading_progress.ReadingProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {reading_progress.ReadingProgress} ReadingProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadingProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.reading_progress.ReadingProgress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.index = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.isFinished = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ReadingProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof reading_progress.ReadingProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {reading_progress.ReadingProgress} ReadingProgress
         */
        ReadingProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.reading_progress.ReadingProgress)
                return object;
            var message = new $root.reading_progress.ReadingProgress();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".reading_progress.ReadingProgress.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.index != null) {
                if (typeof object.index !== "object")
                    throw TypeError(".reading_progress.ReadingProgress.index: object expected");
                message.index = $root.google.protobuf.Int32Value.fromObject(object.index);
            }
            if (object.isFinished != null) {
                if (typeof object.isFinished !== "object")
                    throw TypeError(".reading_progress.ReadingProgress.isFinished: object expected");
                message.isFinished = $root.google.protobuf.BoolValue.fromObject(object.isFinished);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".reading_progress.ReadingProgress.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".reading_progress.ReadingProgress.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReadingProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof reading_progress.ReadingProgress
         * @static
         * @param {reading_progress.ReadingProgress} message ReadingProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadingProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.index = null;
                object.isFinished = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = $root.google.protobuf.Int32Value.toObject(message.index, options);
            if (message.isFinished != null && message.hasOwnProperty("isFinished"))
                object.isFinished = $root.google.protobuf.BoolValue.toObject(message.isFinished, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this ReadingProgress to JSON.
         * @function toJSON
         * @memberof reading_progress.ReadingProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadingProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadingProgress
         * @function getTypeUrl
         * @memberof reading_progress.ReadingProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadingProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/reading_progress.ReadingProgress";
        };

        return ReadingProgress;
    })();

    return reading_progress;
})();

$root.media = (function() {

    /**
     * Namespace media.
     * @exports media
     * @namespace
     */
    var media = {};

    media.MediaService = (function() {

        /**
         * Constructs a new MediaService service.
         * @memberof media
         * @classdesc Represents a MediaService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MediaService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MediaService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MediaService;

        /**
         * Creates new MediaService service using the specified rpc implementation.
         * @function create
         * @memberof media.MediaService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {MediaService} RPC service. Useful where requests and/or responses are streamed.
         */
        MediaService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link media.MediaService#upload}.
         * @memberof media.MediaService
         * @typedef UploadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {media.MediaUploadResponse} [response] MediaUploadResponse
         */

        /**
         * Calls Upload.
         * @function upload
         * @memberof media.MediaService
         * @instance
         * @param {media.IMediaUploadRequest} request MediaUploadRequest message or plain object
         * @param {media.MediaService.UploadCallback} callback Node-style callback called with the error, if any, and MediaUploadResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MediaService.prototype.upload = function upload(request, callback) {
            return this.rpcCall(upload, $root.media.MediaUploadRequest, $root.media.MediaUploadResponse, request, callback);
        }, "name", { value: "Upload" });

        /**
         * Calls Upload.
         * @function upload
         * @memberof media.MediaService
         * @instance
         * @param {media.IMediaUploadRequest} request MediaUploadRequest message or plain object
         * @returns {Promise<media.MediaUploadResponse>} Promise
         * @variation 2
         */

        return MediaService;
    })();

    media.MediaUploadRequest = (function() {

        /**
         * Properties of a MediaUploadRequest.
         * @memberof media
         * @interface IMediaUploadRequest
         * @property {google.protobuf.IBytesValue|null} [blob] MediaUploadRequest blob
         */

        /**
         * Constructs a new MediaUploadRequest.
         * @memberof media
         * @classdesc Represents a MediaUploadRequest.
         * @implements IMediaUploadRequest
         * @constructor
         * @param {media.IMediaUploadRequest=} [properties] Properties to set
         */
        function MediaUploadRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MediaUploadRequest blob.
         * @member {google.protobuf.IBytesValue|null|undefined} blob
         * @memberof media.MediaUploadRequest
         * @instance
         */
        MediaUploadRequest.prototype.blob = null;

        /**
         * Creates a new MediaUploadRequest instance using the specified properties.
         * @function create
         * @memberof media.MediaUploadRequest
         * @static
         * @param {media.IMediaUploadRequest=} [properties] Properties to set
         * @returns {media.MediaUploadRequest} MediaUploadRequest instance
         */
        MediaUploadRequest.create = function create(properties) {
            return new MediaUploadRequest(properties);
        };

        /**
         * Encodes the specified MediaUploadRequest message. Does not implicitly {@link media.MediaUploadRequest.verify|verify} messages.
         * @function encode
         * @memberof media.MediaUploadRequest
         * @static
         * @param {media.IMediaUploadRequest} message MediaUploadRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MediaUploadRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blob != null && Object.hasOwnProperty.call(message, "blob"))
                $root.google.protobuf.BytesValue.encode(message.blob, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MediaUploadRequest message from the specified reader or buffer.
         * @function decode
         * @memberof media.MediaUploadRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {media.MediaUploadRequest} MediaUploadRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MediaUploadRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.media.MediaUploadRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.blob = $root.google.protobuf.BytesValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MediaUploadRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof media.MediaUploadRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {media.MediaUploadRequest} MediaUploadRequest
         */
        MediaUploadRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.media.MediaUploadRequest)
                return object;
            var message = new $root.media.MediaUploadRequest();
            if (object.blob != null) {
                if (typeof object.blob !== "object")
                    throw TypeError(".media.MediaUploadRequest.blob: object expected");
                message.blob = $root.google.protobuf.BytesValue.fromObject(object.blob);
            }
            return message;
        };

        /**
         * Creates a plain object from a MediaUploadRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof media.MediaUploadRequest
         * @static
         * @param {media.MediaUploadRequest} message MediaUploadRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MediaUploadRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.blob = null;
            if (message.blob != null && message.hasOwnProperty("blob"))
                object.blob = $root.google.protobuf.BytesValue.toObject(message.blob, options);
            return object;
        };

        /**
         * Converts this MediaUploadRequest to JSON.
         * @function toJSON
         * @memberof media.MediaUploadRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MediaUploadRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MediaUploadRequest
         * @function getTypeUrl
         * @memberof media.MediaUploadRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MediaUploadRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/media.MediaUploadRequest";
        };

        return MediaUploadRequest;
    })();

    media.MediaUploadResponse = (function() {

        /**
         * Properties of a MediaUploadResponse.
         * @memberof media
         * @interface IMediaUploadResponse
         * @property {google.protobuf.IStringValue|null} [url] MediaUploadResponse url
         */

        /**
         * Constructs a new MediaUploadResponse.
         * @memberof media
         * @classdesc Represents a MediaUploadResponse.
         * @implements IMediaUploadResponse
         * @constructor
         * @param {media.IMediaUploadResponse=} [properties] Properties to set
         */
        function MediaUploadResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MediaUploadResponse url.
         * @member {google.protobuf.IStringValue|null|undefined} url
         * @memberof media.MediaUploadResponse
         * @instance
         */
        MediaUploadResponse.prototype.url = null;

        /**
         * Creates a new MediaUploadResponse instance using the specified properties.
         * @function create
         * @memberof media.MediaUploadResponse
         * @static
         * @param {media.IMediaUploadResponse=} [properties] Properties to set
         * @returns {media.MediaUploadResponse} MediaUploadResponse instance
         */
        MediaUploadResponse.create = function create(properties) {
            return new MediaUploadResponse(properties);
        };

        /**
         * Encodes the specified MediaUploadResponse message. Does not implicitly {@link media.MediaUploadResponse.verify|verify} messages.
         * @function encode
         * @memberof media.MediaUploadResponse
         * @static
         * @param {media.IMediaUploadResponse} message MediaUploadResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MediaUploadResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                $root.google.protobuf.StringValue.encode(message.url, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MediaUploadResponse message from the specified reader or buffer.
         * @function decode
         * @memberof media.MediaUploadResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {media.MediaUploadResponse} MediaUploadResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MediaUploadResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.media.MediaUploadResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MediaUploadResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof media.MediaUploadResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {media.MediaUploadResponse} MediaUploadResponse
         */
        MediaUploadResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.media.MediaUploadResponse)
                return object;
            var message = new $root.media.MediaUploadResponse();
            if (object.url != null) {
                if (typeof object.url !== "object")
                    throw TypeError(".media.MediaUploadResponse.url: object expected");
                message.url = $root.google.protobuf.StringValue.fromObject(object.url);
            }
            return message;
        };

        /**
         * Creates a plain object from a MediaUploadResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof media.MediaUploadResponse
         * @static
         * @param {media.MediaUploadResponse} message MediaUploadResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MediaUploadResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.url = null;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = $root.google.protobuf.StringValue.toObject(message.url, options);
            return object;
        };

        /**
         * Converts this MediaUploadResponse to JSON.
         * @function toJSON
         * @memberof media.MediaUploadResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MediaUploadResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MediaUploadResponse
         * @function getTypeUrl
         * @memberof media.MediaUploadResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MediaUploadResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/media.MediaUploadResponse";
        };

        return MediaUploadResponse;
    })();

    return media;
})();

$root.mutation_apple_auth = (function() {

    /**
     * Namespace mutation_apple_auth.
     * @exports mutation_apple_auth
     * @namespace
     */
    var mutation_apple_auth = {};

    mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest = (function() {

        /**
         * Properties of an UpdateAppleAuthAuthorizationCodeRequest.
         * @memberof mutation_apple_auth
         * @interface IUpdateAppleAuthAuthorizationCodeRequest
         * @property {google.protobuf.IStringValue|null} [authorizationCode] UpdateAppleAuthAuthorizationCodeRequest authorizationCode
         */

        /**
         * Constructs a new UpdateAppleAuthAuthorizationCodeRequest.
         * @memberof mutation_apple_auth
         * @classdesc Represents an UpdateAppleAuthAuthorizationCodeRequest.
         * @implements IUpdateAppleAuthAuthorizationCodeRequest
         * @constructor
         * @param {mutation_apple_auth.IUpdateAppleAuthAuthorizationCodeRequest=} [properties] Properties to set
         */
        function UpdateAppleAuthAuthorizationCodeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateAppleAuthAuthorizationCodeRequest authorizationCode.
         * @member {google.protobuf.IStringValue|null|undefined} authorizationCode
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @instance
         */
        UpdateAppleAuthAuthorizationCodeRequest.prototype.authorizationCode = null;

        /**
         * Creates a new UpdateAppleAuthAuthorizationCodeRequest instance using the specified properties.
         * @function create
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @static
         * @param {mutation_apple_auth.IUpdateAppleAuthAuthorizationCodeRequest=} [properties] Properties to set
         * @returns {mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest} UpdateAppleAuthAuthorizationCodeRequest instance
         */
        UpdateAppleAuthAuthorizationCodeRequest.create = function create(properties) {
            return new UpdateAppleAuthAuthorizationCodeRequest(properties);
        };

        /**
         * Encodes the specified UpdateAppleAuthAuthorizationCodeRequest message. Does not implicitly {@link mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @static
         * @param {mutation_apple_auth.IUpdateAppleAuthAuthorizationCodeRequest} message UpdateAppleAuthAuthorizationCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateAppleAuthAuthorizationCodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.authorizationCode != null && Object.hasOwnProperty.call(message, "authorizationCode"))
                $root.google.protobuf.StringValue.encode(message.authorizationCode, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateAppleAuthAuthorizationCodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest} UpdateAppleAuthAuthorizationCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateAppleAuthAuthorizationCodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.authorizationCode = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateAppleAuthAuthorizationCodeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest} UpdateAppleAuthAuthorizationCodeRequest
         */
        UpdateAppleAuthAuthorizationCodeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest)
                return object;
            var message = new $root.mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest();
            if (object.authorizationCode != null) {
                if (typeof object.authorizationCode !== "object")
                    throw TypeError(".mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest.authorizationCode: object expected");
                message.authorizationCode = $root.google.protobuf.StringValue.fromObject(object.authorizationCode);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateAppleAuthAuthorizationCodeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @static
         * @param {mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest} message UpdateAppleAuthAuthorizationCodeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateAppleAuthAuthorizationCodeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.authorizationCode = null;
            if (message.authorizationCode != null && message.hasOwnProperty("authorizationCode"))
                object.authorizationCode = $root.google.protobuf.StringValue.toObject(message.authorizationCode, options);
            return object;
        };

        /**
         * Converts this UpdateAppleAuthAuthorizationCodeRequest to JSON.
         * @function toJSON
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateAppleAuthAuthorizationCodeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateAppleAuthAuthorizationCodeRequest
         * @function getTypeUrl
         * @memberof mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateAppleAuthAuthorizationCodeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest";
        };

        return UpdateAppleAuthAuthorizationCodeRequest;
    })();

    return mutation_apple_auth;
})();

$root.mutation_authorize_device = (function() {

    /**
     * Namespace mutation_authorize_device.
     * @exports mutation_authorize_device
     * @namespace
     */
    var mutation_authorize_device = {};

    mutation_authorize_device.AuthorizeDeviceRequest = (function() {

        /**
         * Properties of an AuthorizeDeviceRequest.
         * @memberof mutation_authorize_device
         * @interface IAuthorizeDeviceRequest
         * @property {google.protobuf.IStringValue|null} [deviceId] AuthorizeDeviceRequest deviceId
         * @property {types.Platform|null} [platform] AuthorizeDeviceRequest platform
         * @property {google.protobuf.IStringValue|null} [property] AuthorizeDeviceRequest property
         */

        /**
         * Constructs a new AuthorizeDeviceRequest.
         * @memberof mutation_authorize_device
         * @classdesc Represents an AuthorizeDeviceRequest.
         * @implements IAuthorizeDeviceRequest
         * @constructor
         * @param {mutation_authorize_device.IAuthorizeDeviceRequest=} [properties] Properties to set
         */
        function AuthorizeDeviceRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthorizeDeviceRequest deviceId.
         * @member {google.protobuf.IStringValue|null|undefined} deviceId
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @instance
         */
        AuthorizeDeviceRequest.prototype.deviceId = null;

        /**
         * AuthorizeDeviceRequest platform.
         * @member {types.Platform} platform
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @instance
         */
        AuthorizeDeviceRequest.prototype.platform = 0;

        /**
         * AuthorizeDeviceRequest property.
         * @member {google.protobuf.IStringValue|null|undefined} property
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @instance
         */
        AuthorizeDeviceRequest.prototype.property = null;

        /**
         * Creates a new AuthorizeDeviceRequest instance using the specified properties.
         * @function create
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @static
         * @param {mutation_authorize_device.IAuthorizeDeviceRequest=} [properties] Properties to set
         * @returns {mutation_authorize_device.AuthorizeDeviceRequest} AuthorizeDeviceRequest instance
         */
        AuthorizeDeviceRequest.create = function create(properties) {
            return new AuthorizeDeviceRequest(properties);
        };

        /**
         * Encodes the specified AuthorizeDeviceRequest message. Does not implicitly {@link mutation_authorize_device.AuthorizeDeviceRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @static
         * @param {mutation_authorize_device.IAuthorizeDeviceRequest} message AuthorizeDeviceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthorizeDeviceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                $root.google.protobuf.StringValue.encode(message.deviceId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.platform);
            if (message.property != null && Object.hasOwnProperty.call(message, "property"))
                $root.google.protobuf.StringValue.encode(message.property, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AuthorizeDeviceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_authorize_device.AuthorizeDeviceRequest} AuthorizeDeviceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthorizeDeviceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_authorize_device.AuthorizeDeviceRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.deviceId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.platform = reader.int32();
                    break;
                case 3:
                    message.property = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AuthorizeDeviceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_authorize_device.AuthorizeDeviceRequest} AuthorizeDeviceRequest
         */
        AuthorizeDeviceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_authorize_device.AuthorizeDeviceRequest)
                return object;
            var message = new $root.mutation_authorize_device.AuthorizeDeviceRequest();
            if (object.deviceId != null) {
                if (typeof object.deviceId !== "object")
                    throw TypeError(".mutation_authorize_device.AuthorizeDeviceRequest.deviceId: object expected");
                message.deviceId = $root.google.protobuf.StringValue.fromObject(object.deviceId);
            }
            switch (object.platform) {
            case "PLATFORM_UNSPECIFIED":
            case 0:
                message.platform = 0;
                break;
            case "PLATFORM_IOS_APP":
            case 1:
                message.platform = 1;
                break;
            case "PLATFORM_ANDROID_APP":
            case 2:
                message.platform = 2;
                break;
            case "PLATFORM_WEB_APP":
            case 3:
                message.platform = 3;
                break;
            }
            if (object.property != null) {
                if (typeof object.property !== "object")
                    throw TypeError(".mutation_authorize_device.AuthorizeDeviceRequest.property: object expected");
                message.property = $root.google.protobuf.StringValue.fromObject(object.property);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthorizeDeviceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @static
         * @param {mutation_authorize_device.AuthorizeDeviceRequest} message AuthorizeDeviceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthorizeDeviceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.deviceId = null;
                object.platform = options.enums === String ? "PLATFORM_UNSPECIFIED" : 0;
                object.property = null;
            }
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = $root.google.protobuf.StringValue.toObject(message.deviceId, options);
            if (message.platform != null && message.hasOwnProperty("platform"))
                object.platform = options.enums === String ? $root.types.Platform[message.platform] : message.platform;
            if (message.property != null && message.hasOwnProperty("property"))
                object.property = $root.google.protobuf.StringValue.toObject(message.property, options);
            return object;
        };

        /**
         * Converts this AuthorizeDeviceRequest to JSON.
         * @function toJSON
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthorizeDeviceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthorizeDeviceRequest
         * @function getTypeUrl
         * @memberof mutation_authorize_device.AuthorizeDeviceRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthorizeDeviceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_authorize_device.AuthorizeDeviceRequest";
        };

        return AuthorizeDeviceRequest;
    })();

    mutation_authorize_device.AuthorizeDeviceResponse = (function() {

        /**
         * Properties of an AuthorizeDeviceResponse.
         * @memberof mutation_authorize_device
         * @interface IAuthorizeDeviceResponse
         * @property {google.protobuf.IBoolValue|null} [succeeded] AuthorizeDeviceResponse succeeded
         */

        /**
         * Constructs a new AuthorizeDeviceResponse.
         * @memberof mutation_authorize_device
         * @classdesc Represents an AuthorizeDeviceResponse.
         * @implements IAuthorizeDeviceResponse
         * @constructor
         * @param {mutation_authorize_device.IAuthorizeDeviceResponse=} [properties] Properties to set
         */
        function AuthorizeDeviceResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthorizeDeviceResponse succeeded.
         * @member {google.protobuf.IBoolValue|null|undefined} succeeded
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @instance
         */
        AuthorizeDeviceResponse.prototype.succeeded = null;

        /**
         * Creates a new AuthorizeDeviceResponse instance using the specified properties.
         * @function create
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @static
         * @param {mutation_authorize_device.IAuthorizeDeviceResponse=} [properties] Properties to set
         * @returns {mutation_authorize_device.AuthorizeDeviceResponse} AuthorizeDeviceResponse instance
         */
        AuthorizeDeviceResponse.create = function create(properties) {
            return new AuthorizeDeviceResponse(properties);
        };

        /**
         * Encodes the specified AuthorizeDeviceResponse message. Does not implicitly {@link mutation_authorize_device.AuthorizeDeviceResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @static
         * @param {mutation_authorize_device.IAuthorizeDeviceResponse} message AuthorizeDeviceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthorizeDeviceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.succeeded != null && Object.hasOwnProperty.call(message, "succeeded"))
                $root.google.protobuf.BoolValue.encode(message.succeeded, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AuthorizeDeviceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_authorize_device.AuthorizeDeviceResponse} AuthorizeDeviceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthorizeDeviceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_authorize_device.AuthorizeDeviceResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.succeeded = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AuthorizeDeviceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_authorize_device.AuthorizeDeviceResponse} AuthorizeDeviceResponse
         */
        AuthorizeDeviceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_authorize_device.AuthorizeDeviceResponse)
                return object;
            var message = new $root.mutation_authorize_device.AuthorizeDeviceResponse();
            if (object.succeeded != null) {
                if (typeof object.succeeded !== "object")
                    throw TypeError(".mutation_authorize_device.AuthorizeDeviceResponse.succeeded: object expected");
                message.succeeded = $root.google.protobuf.BoolValue.fromObject(object.succeeded);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthorizeDeviceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @static
         * @param {mutation_authorize_device.AuthorizeDeviceResponse} message AuthorizeDeviceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthorizeDeviceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.succeeded = null;
            if (message.succeeded != null && message.hasOwnProperty("succeeded"))
                object.succeeded = $root.google.protobuf.BoolValue.toObject(message.succeeded, options);
            return object;
        };

        /**
         * Converts this AuthorizeDeviceResponse to JSON.
         * @function toJSON
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthorizeDeviceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthorizeDeviceResponse
         * @function getTypeUrl
         * @memberof mutation_authorize_device.AuthorizeDeviceResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthorizeDeviceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_authorize_device.AuthorizeDeviceResponse";
        };

        return AuthorizeDeviceResponse;
    })();

    return mutation_authorize_device;
})();

$root.mutation_follow_user = (function() {

    /**
     * Namespace mutation_follow_user.
     * @exports mutation_follow_user
     * @namespace
     */
    var mutation_follow_user = {};

    mutation_follow_user.FollowUserRequest = (function() {

        /**
         * Properties of a FollowUserRequest.
         * @memberof mutation_follow_user
         * @interface IFollowUserRequest
         * @property {google.protobuf.IStringValue|null} [userId] FollowUserRequest userId
         * @property {google.protobuf.IStringValue|null} [mutualFollowSeriesId] FollowUserRequest mutualFollowSeriesId
         */

        /**
         * Constructs a new FollowUserRequest.
         * @memberof mutation_follow_user
         * @classdesc Represents a FollowUserRequest.
         * @implements IFollowUserRequest
         * @constructor
         * @param {mutation_follow_user.IFollowUserRequest=} [properties] Properties to set
         */
        function FollowUserRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FollowUserRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof mutation_follow_user.FollowUserRequest
         * @instance
         */
        FollowUserRequest.prototype.userId = null;

        /**
         * FollowUserRequest mutualFollowSeriesId.
         * @member {google.protobuf.IStringValue|null|undefined} mutualFollowSeriesId
         * @memberof mutation_follow_user.FollowUserRequest
         * @instance
         */
        FollowUserRequest.prototype.mutualFollowSeriesId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FollowUserRequest reason.
         * @member {"mutualFollowSeriesId"|undefined} reason
         * @memberof mutation_follow_user.FollowUserRequest
         * @instance
         */
        Object.defineProperty(FollowUserRequest.prototype, "reason", {
            get: $util.oneOfGetter($oneOfFields = ["mutualFollowSeriesId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FollowUserRequest instance using the specified properties.
         * @function create
         * @memberof mutation_follow_user.FollowUserRequest
         * @static
         * @param {mutation_follow_user.IFollowUserRequest=} [properties] Properties to set
         * @returns {mutation_follow_user.FollowUserRequest} FollowUserRequest instance
         */
        FollowUserRequest.create = function create(properties) {
            return new FollowUserRequest(properties);
        };

        /**
         * Encodes the specified FollowUserRequest message. Does not implicitly {@link mutation_follow_user.FollowUserRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_follow_user.FollowUserRequest
         * @static
         * @param {mutation_follow_user.IFollowUserRequest} message FollowUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FollowUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.mutualFollowSeriesId != null && Object.hasOwnProperty.call(message, "mutualFollowSeriesId"))
                $root.google.protobuf.StringValue.encode(message.mutualFollowSeriesId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FollowUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_follow_user.FollowUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_follow_user.FollowUserRequest} FollowUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FollowUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_follow_user.FollowUserRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.mutualFollowSeriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a FollowUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_follow_user.FollowUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_follow_user.FollowUserRequest} FollowUserRequest
         */
        FollowUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_follow_user.FollowUserRequest)
                return object;
            var message = new $root.mutation_follow_user.FollowUserRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".mutation_follow_user.FollowUserRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.mutualFollowSeriesId != null) {
                if (typeof object.mutualFollowSeriesId !== "object")
                    throw TypeError(".mutation_follow_user.FollowUserRequest.mutualFollowSeriesId: object expected");
                message.mutualFollowSeriesId = $root.google.protobuf.StringValue.fromObject(object.mutualFollowSeriesId);
            }
            return message;
        };

        /**
         * Creates a plain object from a FollowUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_follow_user.FollowUserRequest
         * @static
         * @param {mutation_follow_user.FollowUserRequest} message FollowUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FollowUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.mutualFollowSeriesId != null && message.hasOwnProperty("mutualFollowSeriesId")) {
                object.mutualFollowSeriesId = $root.google.protobuf.StringValue.toObject(message.mutualFollowSeriesId, options);
                if (options.oneofs)
                    object.reason = "mutualFollowSeriesId";
            }
            return object;
        };

        /**
         * Converts this FollowUserRequest to JSON.
         * @function toJSON
         * @memberof mutation_follow_user.FollowUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FollowUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FollowUserRequest
         * @function getTypeUrl
         * @memberof mutation_follow_user.FollowUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FollowUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_follow_user.FollowUserRequest";
        };

        return FollowUserRequest;
    })();

    mutation_follow_user.UnfollowUserRequest = (function() {

        /**
         * Properties of an UnfollowUserRequest.
         * @memberof mutation_follow_user
         * @interface IUnfollowUserRequest
         * @property {google.protobuf.IStringValue|null} [userId] UnfollowUserRequest userId
         */

        /**
         * Constructs a new UnfollowUserRequest.
         * @memberof mutation_follow_user
         * @classdesc Represents an UnfollowUserRequest.
         * @implements IUnfollowUserRequest
         * @constructor
         * @param {mutation_follow_user.IUnfollowUserRequest=} [properties] Properties to set
         */
        function UnfollowUserRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnfollowUserRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @instance
         */
        UnfollowUserRequest.prototype.userId = null;

        /**
         * Creates a new UnfollowUserRequest instance using the specified properties.
         * @function create
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @static
         * @param {mutation_follow_user.IUnfollowUserRequest=} [properties] Properties to set
         * @returns {mutation_follow_user.UnfollowUserRequest} UnfollowUserRequest instance
         */
        UnfollowUserRequest.create = function create(properties) {
            return new UnfollowUserRequest(properties);
        };

        /**
         * Encodes the specified UnfollowUserRequest message. Does not implicitly {@link mutation_follow_user.UnfollowUserRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @static
         * @param {mutation_follow_user.IUnfollowUserRequest} message UnfollowUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnfollowUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UnfollowUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_follow_user.UnfollowUserRequest} UnfollowUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnfollowUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_follow_user.UnfollowUserRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UnfollowUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_follow_user.UnfollowUserRequest} UnfollowUserRequest
         */
        UnfollowUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_follow_user.UnfollowUserRequest)
                return object;
            var message = new $root.mutation_follow_user.UnfollowUserRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".mutation_follow_user.UnfollowUserRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from an UnfollowUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @static
         * @param {mutation_follow_user.UnfollowUserRequest} message UnfollowUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnfollowUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this UnfollowUserRequest to JSON.
         * @function toJSON
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnfollowUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnfollowUserRequest
         * @function getTypeUrl
         * @memberof mutation_follow_user.UnfollowUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnfollowUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_follow_user.UnfollowUserRequest";
        };

        return UnfollowUserRequest;
    })();

    return mutation_follow_user;
})();

$root.mutation_inspection = (function() {

    /**
     * Namespace mutation_inspection.
     * @exports mutation_inspection
     * @namespace
     */
    var mutation_inspection = {};

    mutation_inspection.ReportStoryRightViolationRequest = (function() {

        /**
         * Properties of a ReportStoryRightViolationRequest.
         * @memberof mutation_inspection
         * @interface IReportStoryRightViolationRequest
         * @property {google.protobuf.IStringValue|null} [storyId] ReportStoryRightViolationRequest storyId
         * @property {mutation_inspection.ReportContentType|null} [contentType] ReportStoryRightViolationRequest contentType
         * @property {mutation_inspection.ReportRightType|null} [rightType] ReportStoryRightViolationRequest rightType
         * @property {google.protobuf.IBoolValue|null} [isReporterRightViolated] ReportStoryRightViolationRequest isReporterRightViolated
         * @property {google.protobuf.IStringValue|null} [evidenceText] ReportStoryRightViolationRequest evidenceText
         * @property {Array.<google.protobuf.IStringValue>|null} [evidenceFileList] ReportStoryRightViolationRequest evidenceFileList
         * @property {google.protobuf.IStringValue|null} [name] ReportStoryRightViolationRequest name
         * @property {google.protobuf.IBoolValue|null} [allowNameDisclosure] ReportStoryRightViolationRequest allowNameDisclosure
         * @property {google.protobuf.IStringValue|null} [emailAddress] ReportStoryRightViolationRequest emailAddress
         * @property {google.protobuf.IStringValue|null} [phoneNumber] ReportStoryRightViolationRequest phoneNumber
         * @property {Array.<google.protobuf.IStringValue>|null} [rightCertificationFileList] ReportStoryRightViolationRequest rightCertificationFileList
         * @property {google.protobuf.IStringValue|null} [evidenceTextByThirdParty] ReportStoryRightViolationRequest evidenceTextByThirdParty
         * @property {Array.<google.protobuf.IStringValue>|null} [evidenceFileListByThirdParty] ReportStoryRightViolationRequest evidenceFileListByThirdParty
         */

        /**
         * Constructs a new ReportStoryRightViolationRequest.
         * @memberof mutation_inspection
         * @classdesc Represents a ReportStoryRightViolationRequest.
         * @implements IReportStoryRightViolationRequest
         * @constructor
         * @param {mutation_inspection.IReportStoryRightViolationRequest=} [properties] Properties to set
         */
        function ReportStoryRightViolationRequest(properties) {
            this.evidenceFileList = [];
            this.rightCertificationFileList = [];
            this.evidenceFileListByThirdParty = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportStoryRightViolationRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.storyId = null;

        /**
         * ReportStoryRightViolationRequest contentType.
         * @member {mutation_inspection.ReportContentType} contentType
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.contentType = 0;

        /**
         * ReportStoryRightViolationRequest rightType.
         * @member {mutation_inspection.ReportRightType} rightType
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.rightType = 0;

        /**
         * ReportStoryRightViolationRequest isReporterRightViolated.
         * @member {google.protobuf.IBoolValue|null|undefined} isReporterRightViolated
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.isReporterRightViolated = null;

        /**
         * ReportStoryRightViolationRequest evidenceText.
         * @member {google.protobuf.IStringValue|null|undefined} evidenceText
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.evidenceText = null;

        /**
         * ReportStoryRightViolationRequest evidenceFileList.
         * @member {Array.<google.protobuf.IStringValue>} evidenceFileList
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.evidenceFileList = $util.emptyArray;

        /**
         * ReportStoryRightViolationRequest name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.name = null;

        /**
         * ReportStoryRightViolationRequest allowNameDisclosure.
         * @member {google.protobuf.IBoolValue|null|undefined} allowNameDisclosure
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.allowNameDisclosure = null;

        /**
         * ReportStoryRightViolationRequest emailAddress.
         * @member {google.protobuf.IStringValue|null|undefined} emailAddress
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.emailAddress = null;

        /**
         * ReportStoryRightViolationRequest phoneNumber.
         * @member {google.protobuf.IStringValue|null|undefined} phoneNumber
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.phoneNumber = null;

        /**
         * ReportStoryRightViolationRequest rightCertificationFileList.
         * @member {Array.<google.protobuf.IStringValue>} rightCertificationFileList
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.rightCertificationFileList = $util.emptyArray;

        /**
         * ReportStoryRightViolationRequest evidenceTextByThirdParty.
         * @member {google.protobuf.IStringValue|null|undefined} evidenceTextByThirdParty
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.evidenceTextByThirdParty = null;

        /**
         * ReportStoryRightViolationRequest evidenceFileListByThirdParty.
         * @member {Array.<google.protobuf.IStringValue>} evidenceFileListByThirdParty
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         */
        ReportStoryRightViolationRequest.prototype.evidenceFileListByThirdParty = $util.emptyArray;

        /**
         * Creates a new ReportStoryRightViolationRequest instance using the specified properties.
         * @function create
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @static
         * @param {mutation_inspection.IReportStoryRightViolationRequest=} [properties] Properties to set
         * @returns {mutation_inspection.ReportStoryRightViolationRequest} ReportStoryRightViolationRequest instance
         */
        ReportStoryRightViolationRequest.create = function create(properties) {
            return new ReportStoryRightViolationRequest(properties);
        };

        /**
         * Encodes the specified ReportStoryRightViolationRequest message. Does not implicitly {@link mutation_inspection.ReportStoryRightViolationRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @static
         * @param {mutation_inspection.IReportStoryRightViolationRequest} message ReportStoryRightViolationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportStoryRightViolationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.contentType);
            if (message.rightType != null && Object.hasOwnProperty.call(message, "rightType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rightType);
            if (message.isReporterRightViolated != null && Object.hasOwnProperty.call(message, "isReporterRightViolated"))
                $root.google.protobuf.BoolValue.encode(message.isReporterRightViolated, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.evidenceText != null && Object.hasOwnProperty.call(message, "evidenceText"))
                $root.google.protobuf.StringValue.encode(message.evidenceText, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.evidenceFileList != null && message.evidenceFileList.length)
                for (var i = 0; i < message.evidenceFileList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.evidenceFileList[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.allowNameDisclosure != null && Object.hasOwnProperty.call(message, "allowNameDisclosure"))
                $root.google.protobuf.BoolValue.encode(message.allowNameDisclosure, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                $root.google.protobuf.StringValue.encode(message.emailAddress, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                $root.google.protobuf.StringValue.encode(message.phoneNumber, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.rightCertificationFileList != null && message.rightCertificationFileList.length)
                for (var i = 0; i < message.rightCertificationFileList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.rightCertificationFileList[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.evidenceTextByThirdParty != null && Object.hasOwnProperty.call(message, "evidenceTextByThirdParty"))
                $root.google.protobuf.StringValue.encode(message.evidenceTextByThirdParty, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.evidenceFileListByThirdParty != null && message.evidenceFileListByThirdParty.length)
                for (var i = 0; i < message.evidenceFileListByThirdParty.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.evidenceFileListByThirdParty[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ReportStoryRightViolationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_inspection.ReportStoryRightViolationRequest} ReportStoryRightViolationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportStoryRightViolationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_inspection.ReportStoryRightViolationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.contentType = reader.int32();
                    break;
                case 3:
                    message.rightType = reader.int32();
                    break;
                case 4:
                    message.isReporterRightViolated = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.evidenceText = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.evidenceFileList && message.evidenceFileList.length))
                        message.evidenceFileList = [];
                    message.evidenceFileList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.allowNameDisclosure = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.emailAddress = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.phoneNumber = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 11:
                    if (!(message.rightCertificationFileList && message.rightCertificationFileList.length))
                        message.rightCertificationFileList = [];
                    message.rightCertificationFileList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.evidenceTextByThirdParty = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    if (!(message.evidenceFileListByThirdParty && message.evidenceFileListByThirdParty.length))
                        message.evidenceFileListByThirdParty = [];
                    message.evidenceFileListByThirdParty.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ReportStoryRightViolationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_inspection.ReportStoryRightViolationRequest} ReportStoryRightViolationRequest
         */
        ReportStoryRightViolationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_inspection.ReportStoryRightViolationRequest)
                return object;
            var message = new $root.mutation_inspection.ReportStoryRightViolationRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            switch (object.contentType) {
            case "CONTENT_TYPE_UNSPECIFIED":
            case 0:
                message.contentType = 0;
                break;
            case "IMAGE":
            case 1:
                message.contentType = 1;
                break;
            case "TEXT":
            case 2:
                message.contentType = 2;
                break;
            }
            switch (object.rightType) {
            case "RIGHT_TYPE_UNSPECIFIED":
            case 0:
                message.rightType = 0;
                break;
            case "COPYRIGHT":
            case 1:
                message.rightType = 1;
                break;
            case "PUBLICITY":
            case 2:
                message.rightType = 2;
                break;
            case "OTHER":
            case 3:
                message.rightType = 3;
                break;
            }
            if (object.isReporterRightViolated != null) {
                if (typeof object.isReporterRightViolated !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.isReporterRightViolated: object expected");
                message.isReporterRightViolated = $root.google.protobuf.BoolValue.fromObject(object.isReporterRightViolated);
            }
            if (object.evidenceText != null) {
                if (typeof object.evidenceText !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.evidenceText: object expected");
                message.evidenceText = $root.google.protobuf.StringValue.fromObject(object.evidenceText);
            }
            if (object.evidenceFileList) {
                if (!Array.isArray(object.evidenceFileList))
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.evidenceFileList: array expected");
                message.evidenceFileList = [];
                for (var i = 0; i < object.evidenceFileList.length; ++i) {
                    if (typeof object.evidenceFileList[i] !== "object")
                        throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.evidenceFileList: object expected");
                    message.evidenceFileList[i] = $root.google.protobuf.StringValue.fromObject(object.evidenceFileList[i]);
                }
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.allowNameDisclosure != null) {
                if (typeof object.allowNameDisclosure !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.allowNameDisclosure: object expected");
                message.allowNameDisclosure = $root.google.protobuf.BoolValue.fromObject(object.allowNameDisclosure);
            }
            if (object.emailAddress != null) {
                if (typeof object.emailAddress !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.emailAddress: object expected");
                message.emailAddress = $root.google.protobuf.StringValue.fromObject(object.emailAddress);
            }
            if (object.phoneNumber != null) {
                if (typeof object.phoneNumber !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.phoneNumber: object expected");
                message.phoneNumber = $root.google.protobuf.StringValue.fromObject(object.phoneNumber);
            }
            if (object.rightCertificationFileList) {
                if (!Array.isArray(object.rightCertificationFileList))
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.rightCertificationFileList: array expected");
                message.rightCertificationFileList = [];
                for (var i = 0; i < object.rightCertificationFileList.length; ++i) {
                    if (typeof object.rightCertificationFileList[i] !== "object")
                        throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.rightCertificationFileList: object expected");
                    message.rightCertificationFileList[i] = $root.google.protobuf.StringValue.fromObject(object.rightCertificationFileList[i]);
                }
            }
            if (object.evidenceTextByThirdParty != null) {
                if (typeof object.evidenceTextByThirdParty !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.evidenceTextByThirdParty: object expected");
                message.evidenceTextByThirdParty = $root.google.protobuf.StringValue.fromObject(object.evidenceTextByThirdParty);
            }
            if (object.evidenceFileListByThirdParty) {
                if (!Array.isArray(object.evidenceFileListByThirdParty))
                    throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.evidenceFileListByThirdParty: array expected");
                message.evidenceFileListByThirdParty = [];
                for (var i = 0; i < object.evidenceFileListByThirdParty.length; ++i) {
                    if (typeof object.evidenceFileListByThirdParty[i] !== "object")
                        throw TypeError(".mutation_inspection.ReportStoryRightViolationRequest.evidenceFileListByThirdParty: object expected");
                    message.evidenceFileListByThirdParty[i] = $root.google.protobuf.StringValue.fromObject(object.evidenceFileListByThirdParty[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ReportStoryRightViolationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @static
         * @param {mutation_inspection.ReportStoryRightViolationRequest} message ReportStoryRightViolationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportStoryRightViolationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.evidenceFileList = [];
                object.rightCertificationFileList = [];
                object.evidenceFileListByThirdParty = [];
            }
            if (options.defaults) {
                object.storyId = null;
                object.contentType = options.enums === String ? "CONTENT_TYPE_UNSPECIFIED" : 0;
                object.rightType = options.enums === String ? "RIGHT_TYPE_UNSPECIFIED" : 0;
                object.isReporterRightViolated = null;
                object.evidenceText = null;
                object.name = null;
                object.allowNameDisclosure = null;
                object.emailAddress = null;
                object.phoneNumber = null;
                object.evidenceTextByThirdParty = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                object.contentType = options.enums === String ? $root.mutation_inspection.ReportContentType[message.contentType] : message.contentType;
            if (message.rightType != null && message.hasOwnProperty("rightType"))
                object.rightType = options.enums === String ? $root.mutation_inspection.ReportRightType[message.rightType] : message.rightType;
            if (message.isReporterRightViolated != null && message.hasOwnProperty("isReporterRightViolated"))
                object.isReporterRightViolated = $root.google.protobuf.BoolValue.toObject(message.isReporterRightViolated, options);
            if (message.evidenceText != null && message.hasOwnProperty("evidenceText"))
                object.evidenceText = $root.google.protobuf.StringValue.toObject(message.evidenceText, options);
            if (message.evidenceFileList && message.evidenceFileList.length) {
                object.evidenceFileList = [];
                for (var j = 0; j < message.evidenceFileList.length; ++j)
                    object.evidenceFileList[j] = $root.google.protobuf.StringValue.toObject(message.evidenceFileList[j], options);
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.allowNameDisclosure != null && message.hasOwnProperty("allowNameDisclosure"))
                object.allowNameDisclosure = $root.google.protobuf.BoolValue.toObject(message.allowNameDisclosure, options);
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = $root.google.protobuf.StringValue.toObject(message.emailAddress, options);
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                object.phoneNumber = $root.google.protobuf.StringValue.toObject(message.phoneNumber, options);
            if (message.rightCertificationFileList && message.rightCertificationFileList.length) {
                object.rightCertificationFileList = [];
                for (var j = 0; j < message.rightCertificationFileList.length; ++j)
                    object.rightCertificationFileList[j] = $root.google.protobuf.StringValue.toObject(message.rightCertificationFileList[j], options);
            }
            if (message.evidenceTextByThirdParty != null && message.hasOwnProperty("evidenceTextByThirdParty"))
                object.evidenceTextByThirdParty = $root.google.protobuf.StringValue.toObject(message.evidenceTextByThirdParty, options);
            if (message.evidenceFileListByThirdParty && message.evidenceFileListByThirdParty.length) {
                object.evidenceFileListByThirdParty = [];
                for (var j = 0; j < message.evidenceFileListByThirdParty.length; ++j)
                    object.evidenceFileListByThirdParty[j] = $root.google.protobuf.StringValue.toObject(message.evidenceFileListByThirdParty[j], options);
            }
            return object;
        };

        /**
         * Converts this ReportStoryRightViolationRequest to JSON.
         * @function toJSON
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportStoryRightViolationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportStoryRightViolationRequest
         * @function getTypeUrl
         * @memberof mutation_inspection.ReportStoryRightViolationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportStoryRightViolationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_inspection.ReportStoryRightViolationRequest";
        };

        return ReportStoryRightViolationRequest;
    })();

    mutation_inspection.ReportStoryViolationRequest = (function() {

        /**
         * Properties of a ReportStoryViolationRequest.
         * @memberof mutation_inspection
         * @interface IReportStoryViolationRequest
         * @property {google.protobuf.IStringValue|null} [storyId] ReportStoryViolationRequest storyId
         * @property {types.ViolationType|null} [type] ReportStoryViolationRequest type
         * @property {google.protobuf.IStringValue|null} [message] ReportStoryViolationRequest message
         */

        /**
         * Constructs a new ReportStoryViolationRequest.
         * @memberof mutation_inspection
         * @classdesc Represents a ReportStoryViolationRequest.
         * @implements IReportStoryViolationRequest
         * @constructor
         * @param {mutation_inspection.IReportStoryViolationRequest=} [properties] Properties to set
         */
        function ReportStoryViolationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportStoryViolationRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @instance
         */
        ReportStoryViolationRequest.prototype.storyId = null;

        /**
         * ReportStoryViolationRequest type.
         * @member {types.ViolationType} type
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @instance
         */
        ReportStoryViolationRequest.prototype.type = 0;

        /**
         * ReportStoryViolationRequest message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @instance
         */
        ReportStoryViolationRequest.prototype.message = null;

        /**
         * Creates a new ReportStoryViolationRequest instance using the specified properties.
         * @function create
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @static
         * @param {mutation_inspection.IReportStoryViolationRequest=} [properties] Properties to set
         * @returns {mutation_inspection.ReportStoryViolationRequest} ReportStoryViolationRequest instance
         */
        ReportStoryViolationRequest.create = function create(properties) {
            return new ReportStoryViolationRequest(properties);
        };

        /**
         * Encodes the specified ReportStoryViolationRequest message. Does not implicitly {@link mutation_inspection.ReportStoryViolationRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @static
         * @param {mutation_inspection.IReportStoryViolationRequest} message ReportStoryViolationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportStoryViolationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ReportStoryViolationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_inspection.ReportStoryViolationRequest} ReportStoryViolationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportStoryViolationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_inspection.ReportStoryViolationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ReportStoryViolationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_inspection.ReportStoryViolationRequest} ReportStoryViolationRequest
         */
        ReportStoryViolationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_inspection.ReportStoryViolationRequest)
                return object;
            var message = new $root.mutation_inspection.ReportStoryViolationRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryViolationRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            switch (object.type) {
            case "VIOLATION_TYPE_UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "VIOLATION_TYPE_VIOLENCE":
            case 1:
                message.type = 1;
                break;
            case "VIOLATION_TYPE_HARASSMENT":
            case 2:
                message.type = 2;
                break;
            case "VIOLATION_TYPE_PRIVACY":
            case 3:
                message.type = 3;
                break;
            case "VIOLATION_TYPE_RIGHTS":
            case 4:
                message.type = 4;
                break;
            case "VIOLATION_TYPE_ADULT":
            case 5:
                message.type = 5;
                break;
            case "VIOLATION_TYPE_SPAM":
            case 6:
                message.type = 6;
                break;
            case "VIOLATION_TYPE_OTHER":
            case 7:
                message.type = 7;
                break;
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".mutation_inspection.ReportStoryViolationRequest.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReportStoryViolationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @static
         * @param {mutation_inspection.ReportStoryViolationRequest} message ReportStoryViolationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportStoryViolationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.type = options.enums === String ? "VIOLATION_TYPE_UNKNOWN" : 0;
                object.message = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.types.ViolationType[message.type] : message.type;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this ReportStoryViolationRequest to JSON.
         * @function toJSON
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportStoryViolationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportStoryViolationRequest
         * @function getTypeUrl
         * @memberof mutation_inspection.ReportStoryViolationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportStoryViolationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_inspection.ReportStoryViolationRequest";
        };

        return ReportStoryViolationRequest;
    })();

    mutation_inspection.ReportCommentViolationRequest = (function() {

        /**
         * Properties of a ReportCommentViolationRequest.
         * @memberof mutation_inspection
         * @interface IReportCommentViolationRequest
         * @property {google.protobuf.IStringValue|null} [commentId] ReportCommentViolationRequest commentId
         * @property {types.ViolationType|null} [type] ReportCommentViolationRequest type
         * @property {google.protobuf.IStringValue|null} [message] ReportCommentViolationRequest message
         */

        /**
         * Constructs a new ReportCommentViolationRequest.
         * @memberof mutation_inspection
         * @classdesc Represents a ReportCommentViolationRequest.
         * @implements IReportCommentViolationRequest
         * @constructor
         * @param {mutation_inspection.IReportCommentViolationRequest=} [properties] Properties to set
         */
        function ReportCommentViolationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportCommentViolationRequest commentId.
         * @member {google.protobuf.IStringValue|null|undefined} commentId
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @instance
         */
        ReportCommentViolationRequest.prototype.commentId = null;

        /**
         * ReportCommentViolationRequest type.
         * @member {types.ViolationType} type
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @instance
         */
        ReportCommentViolationRequest.prototype.type = 0;

        /**
         * ReportCommentViolationRequest message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @instance
         */
        ReportCommentViolationRequest.prototype.message = null;

        /**
         * Creates a new ReportCommentViolationRequest instance using the specified properties.
         * @function create
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @static
         * @param {mutation_inspection.IReportCommentViolationRequest=} [properties] Properties to set
         * @returns {mutation_inspection.ReportCommentViolationRequest} ReportCommentViolationRequest instance
         */
        ReportCommentViolationRequest.create = function create(properties) {
            return new ReportCommentViolationRequest(properties);
        };

        /**
         * Encodes the specified ReportCommentViolationRequest message. Does not implicitly {@link mutation_inspection.ReportCommentViolationRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @static
         * @param {mutation_inspection.IReportCommentViolationRequest} message ReportCommentViolationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportCommentViolationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentId != null && Object.hasOwnProperty.call(message, "commentId"))
                $root.google.protobuf.StringValue.encode(message.commentId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ReportCommentViolationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_inspection.ReportCommentViolationRequest} ReportCommentViolationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportCommentViolationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_inspection.ReportCommentViolationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commentId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ReportCommentViolationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_inspection.ReportCommentViolationRequest} ReportCommentViolationRequest
         */
        ReportCommentViolationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_inspection.ReportCommentViolationRequest)
                return object;
            var message = new $root.mutation_inspection.ReportCommentViolationRequest();
            if (object.commentId != null) {
                if (typeof object.commentId !== "object")
                    throw TypeError(".mutation_inspection.ReportCommentViolationRequest.commentId: object expected");
                message.commentId = $root.google.protobuf.StringValue.fromObject(object.commentId);
            }
            switch (object.type) {
            case "VIOLATION_TYPE_UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "VIOLATION_TYPE_VIOLENCE":
            case 1:
                message.type = 1;
                break;
            case "VIOLATION_TYPE_HARASSMENT":
            case 2:
                message.type = 2;
                break;
            case "VIOLATION_TYPE_PRIVACY":
            case 3:
                message.type = 3;
                break;
            case "VIOLATION_TYPE_RIGHTS":
            case 4:
                message.type = 4;
                break;
            case "VIOLATION_TYPE_ADULT":
            case 5:
                message.type = 5;
                break;
            case "VIOLATION_TYPE_SPAM":
            case 6:
                message.type = 6;
                break;
            case "VIOLATION_TYPE_OTHER":
            case 7:
                message.type = 7;
                break;
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".mutation_inspection.ReportCommentViolationRequest.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReportCommentViolationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @static
         * @param {mutation_inspection.ReportCommentViolationRequest} message ReportCommentViolationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportCommentViolationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.commentId = null;
                object.type = options.enums === String ? "VIOLATION_TYPE_UNKNOWN" : 0;
                object.message = null;
            }
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                object.commentId = $root.google.protobuf.StringValue.toObject(message.commentId, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.types.ViolationType[message.type] : message.type;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this ReportCommentViolationRequest to JSON.
         * @function toJSON
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportCommentViolationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportCommentViolationRequest
         * @function getTypeUrl
         * @memberof mutation_inspection.ReportCommentViolationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportCommentViolationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_inspection.ReportCommentViolationRequest";
        };

        return ReportCommentViolationRequest;
    })();

    mutation_inspection.ReportUserViolationRequest = (function() {

        /**
         * Properties of a ReportUserViolationRequest.
         * @memberof mutation_inspection
         * @interface IReportUserViolationRequest
         * @property {google.protobuf.IStringValue|null} [userId] ReportUserViolationRequest userId
         * @property {types.ViolationType|null} [type] ReportUserViolationRequest type
         * @property {google.protobuf.IStringValue|null} [message] ReportUserViolationRequest message
         */

        /**
         * Constructs a new ReportUserViolationRequest.
         * @memberof mutation_inspection
         * @classdesc Represents a ReportUserViolationRequest.
         * @implements IReportUserViolationRequest
         * @constructor
         * @param {mutation_inspection.IReportUserViolationRequest=} [properties] Properties to set
         */
        function ReportUserViolationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportUserViolationRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @instance
         */
        ReportUserViolationRequest.prototype.userId = null;

        /**
         * ReportUserViolationRequest type.
         * @member {types.ViolationType} type
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @instance
         */
        ReportUserViolationRequest.prototype.type = 0;

        /**
         * ReportUserViolationRequest message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @instance
         */
        ReportUserViolationRequest.prototype.message = null;

        /**
         * Creates a new ReportUserViolationRequest instance using the specified properties.
         * @function create
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @static
         * @param {mutation_inspection.IReportUserViolationRequest=} [properties] Properties to set
         * @returns {mutation_inspection.ReportUserViolationRequest} ReportUserViolationRequest instance
         */
        ReportUserViolationRequest.create = function create(properties) {
            return new ReportUserViolationRequest(properties);
        };

        /**
         * Encodes the specified ReportUserViolationRequest message. Does not implicitly {@link mutation_inspection.ReportUserViolationRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @static
         * @param {mutation_inspection.IReportUserViolationRequest} message ReportUserViolationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportUserViolationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ReportUserViolationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_inspection.ReportUserViolationRequest} ReportUserViolationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportUserViolationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_inspection.ReportUserViolationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ReportUserViolationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_inspection.ReportUserViolationRequest} ReportUserViolationRequest
         */
        ReportUserViolationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_inspection.ReportUserViolationRequest)
                return object;
            var message = new $root.mutation_inspection.ReportUserViolationRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".mutation_inspection.ReportUserViolationRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            switch (object.type) {
            case "VIOLATION_TYPE_UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "VIOLATION_TYPE_VIOLENCE":
            case 1:
                message.type = 1;
                break;
            case "VIOLATION_TYPE_HARASSMENT":
            case 2:
                message.type = 2;
                break;
            case "VIOLATION_TYPE_PRIVACY":
            case 3:
                message.type = 3;
                break;
            case "VIOLATION_TYPE_RIGHTS":
            case 4:
                message.type = 4;
                break;
            case "VIOLATION_TYPE_ADULT":
            case 5:
                message.type = 5;
                break;
            case "VIOLATION_TYPE_SPAM":
            case 6:
                message.type = 6;
                break;
            case "VIOLATION_TYPE_OTHER":
            case 7:
                message.type = 7;
                break;
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".mutation_inspection.ReportUserViolationRequest.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReportUserViolationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @static
         * @param {mutation_inspection.ReportUserViolationRequest} message ReportUserViolationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportUserViolationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = null;
                object.type = options.enums === String ? "VIOLATION_TYPE_UNKNOWN" : 0;
                object.message = null;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.types.ViolationType[message.type] : message.type;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this ReportUserViolationRequest to JSON.
         * @function toJSON
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportUserViolationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportUserViolationRequest
         * @function getTypeUrl
         * @memberof mutation_inspection.ReportUserViolationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportUserViolationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_inspection.ReportUserViolationRequest";
        };

        return ReportUserViolationRequest;
    })();

    mutation_inspection.ReportSeriesRightViolationRequest = (function() {

        /**
         * Properties of a ReportSeriesRightViolationRequest.
         * @memberof mutation_inspection
         * @interface IReportSeriesRightViolationRequest
         * @property {google.protobuf.IStringValue|null} [seriesId] ReportSeriesRightViolationRequest seriesId
         * @property {mutation_inspection.ReportContentType|null} [contentType] ReportSeriesRightViolationRequest contentType
         * @property {mutation_inspection.ReportRightType|null} [rightType] ReportSeriesRightViolationRequest rightType
         * @property {google.protobuf.IBoolValue|null} [isReporterRightViolated] ReportSeriesRightViolationRequest isReporterRightViolated
         * @property {google.protobuf.IStringValue|null} [evidenceText] ReportSeriesRightViolationRequest evidenceText
         * @property {Array.<google.protobuf.IStringValue>|null} [evidenceFileList] ReportSeriesRightViolationRequest evidenceFileList
         * @property {google.protobuf.IStringValue|null} [name] ReportSeriesRightViolationRequest name
         * @property {google.protobuf.IBoolValue|null} [allowNameDisclosure] ReportSeriesRightViolationRequest allowNameDisclosure
         * @property {google.protobuf.IStringValue|null} [emailAddress] ReportSeriesRightViolationRequest emailAddress
         * @property {google.protobuf.IStringValue|null} [phoneNumber] ReportSeriesRightViolationRequest phoneNumber
         * @property {Array.<google.protobuf.IStringValue>|null} [rightCertificationFileList] ReportSeriesRightViolationRequest rightCertificationFileList
         * @property {google.protobuf.IStringValue|null} [evidenceTextByThirdParty] ReportSeriesRightViolationRequest evidenceTextByThirdParty
         * @property {Array.<google.protobuf.IStringValue>|null} [evidenceFileListByThirdParty] ReportSeriesRightViolationRequest evidenceFileListByThirdParty
         */

        /**
         * Constructs a new ReportSeriesRightViolationRequest.
         * @memberof mutation_inspection
         * @classdesc Represents a ReportSeriesRightViolationRequest.
         * @implements IReportSeriesRightViolationRequest
         * @constructor
         * @param {mutation_inspection.IReportSeriesRightViolationRequest=} [properties] Properties to set
         */
        function ReportSeriesRightViolationRequest(properties) {
            this.evidenceFileList = [];
            this.rightCertificationFileList = [];
            this.evidenceFileListByThirdParty = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportSeriesRightViolationRequest seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.seriesId = null;

        /**
         * ReportSeriesRightViolationRequest contentType.
         * @member {mutation_inspection.ReportContentType} contentType
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.contentType = 0;

        /**
         * ReportSeriesRightViolationRequest rightType.
         * @member {mutation_inspection.ReportRightType} rightType
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.rightType = 0;

        /**
         * ReportSeriesRightViolationRequest isReporterRightViolated.
         * @member {google.protobuf.IBoolValue|null|undefined} isReporterRightViolated
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.isReporterRightViolated = null;

        /**
         * ReportSeriesRightViolationRequest evidenceText.
         * @member {google.protobuf.IStringValue|null|undefined} evidenceText
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.evidenceText = null;

        /**
         * ReportSeriesRightViolationRequest evidenceFileList.
         * @member {Array.<google.protobuf.IStringValue>} evidenceFileList
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.evidenceFileList = $util.emptyArray;

        /**
         * ReportSeriesRightViolationRequest name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.name = null;

        /**
         * ReportSeriesRightViolationRequest allowNameDisclosure.
         * @member {google.protobuf.IBoolValue|null|undefined} allowNameDisclosure
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.allowNameDisclosure = null;

        /**
         * ReportSeriesRightViolationRequest emailAddress.
         * @member {google.protobuf.IStringValue|null|undefined} emailAddress
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.emailAddress = null;

        /**
         * ReportSeriesRightViolationRequest phoneNumber.
         * @member {google.protobuf.IStringValue|null|undefined} phoneNumber
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.phoneNumber = null;

        /**
         * ReportSeriesRightViolationRequest rightCertificationFileList.
         * @member {Array.<google.protobuf.IStringValue>} rightCertificationFileList
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.rightCertificationFileList = $util.emptyArray;

        /**
         * ReportSeriesRightViolationRequest evidenceTextByThirdParty.
         * @member {google.protobuf.IStringValue|null|undefined} evidenceTextByThirdParty
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.evidenceTextByThirdParty = null;

        /**
         * ReportSeriesRightViolationRequest evidenceFileListByThirdParty.
         * @member {Array.<google.protobuf.IStringValue>} evidenceFileListByThirdParty
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         */
        ReportSeriesRightViolationRequest.prototype.evidenceFileListByThirdParty = $util.emptyArray;

        /**
         * Creates a new ReportSeriesRightViolationRequest instance using the specified properties.
         * @function create
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @static
         * @param {mutation_inspection.IReportSeriesRightViolationRequest=} [properties] Properties to set
         * @returns {mutation_inspection.ReportSeriesRightViolationRequest} ReportSeriesRightViolationRequest instance
         */
        ReportSeriesRightViolationRequest.create = function create(properties) {
            return new ReportSeriesRightViolationRequest(properties);
        };

        /**
         * Encodes the specified ReportSeriesRightViolationRequest message. Does not implicitly {@link mutation_inspection.ReportSeriesRightViolationRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @static
         * @param {mutation_inspection.IReportSeriesRightViolationRequest} message ReportSeriesRightViolationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportSeriesRightViolationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.contentType);
            if (message.rightType != null && Object.hasOwnProperty.call(message, "rightType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rightType);
            if (message.isReporterRightViolated != null && Object.hasOwnProperty.call(message, "isReporterRightViolated"))
                $root.google.protobuf.BoolValue.encode(message.isReporterRightViolated, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.evidenceText != null && Object.hasOwnProperty.call(message, "evidenceText"))
                $root.google.protobuf.StringValue.encode(message.evidenceText, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.evidenceFileList != null && message.evidenceFileList.length)
                for (var i = 0; i < message.evidenceFileList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.evidenceFileList[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.allowNameDisclosure != null && Object.hasOwnProperty.call(message, "allowNameDisclosure"))
                $root.google.protobuf.BoolValue.encode(message.allowNameDisclosure, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                $root.google.protobuf.StringValue.encode(message.emailAddress, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                $root.google.protobuf.StringValue.encode(message.phoneNumber, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.rightCertificationFileList != null && message.rightCertificationFileList.length)
                for (var i = 0; i < message.rightCertificationFileList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.rightCertificationFileList[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.evidenceTextByThirdParty != null && Object.hasOwnProperty.call(message, "evidenceTextByThirdParty"))
                $root.google.protobuf.StringValue.encode(message.evidenceTextByThirdParty, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.evidenceFileListByThirdParty != null && message.evidenceFileListByThirdParty.length)
                for (var i = 0; i < message.evidenceFileListByThirdParty.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.evidenceFileListByThirdParty[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ReportSeriesRightViolationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_inspection.ReportSeriesRightViolationRequest} ReportSeriesRightViolationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportSeriesRightViolationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_inspection.ReportSeriesRightViolationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.contentType = reader.int32();
                    break;
                case 3:
                    message.rightType = reader.int32();
                    break;
                case 4:
                    message.isReporterRightViolated = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.evidenceText = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.evidenceFileList && message.evidenceFileList.length))
                        message.evidenceFileList = [];
                    message.evidenceFileList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.allowNameDisclosure = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.emailAddress = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.phoneNumber = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 11:
                    if (!(message.rightCertificationFileList && message.rightCertificationFileList.length))
                        message.rightCertificationFileList = [];
                    message.rightCertificationFileList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.evidenceTextByThirdParty = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    if (!(message.evidenceFileListByThirdParty && message.evidenceFileListByThirdParty.length))
                        message.evidenceFileListByThirdParty = [];
                    message.evidenceFileListByThirdParty.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ReportSeriesRightViolationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_inspection.ReportSeriesRightViolationRequest} ReportSeriesRightViolationRequest
         */
        ReportSeriesRightViolationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_inspection.ReportSeriesRightViolationRequest)
                return object;
            var message = new $root.mutation_inspection.ReportSeriesRightViolationRequest();
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            switch (object.contentType) {
            case "CONTENT_TYPE_UNSPECIFIED":
            case 0:
                message.contentType = 0;
                break;
            case "IMAGE":
            case 1:
                message.contentType = 1;
                break;
            case "TEXT":
            case 2:
                message.contentType = 2;
                break;
            }
            switch (object.rightType) {
            case "RIGHT_TYPE_UNSPECIFIED":
            case 0:
                message.rightType = 0;
                break;
            case "COPYRIGHT":
            case 1:
                message.rightType = 1;
                break;
            case "PUBLICITY":
            case 2:
                message.rightType = 2;
                break;
            case "OTHER":
            case 3:
                message.rightType = 3;
                break;
            }
            if (object.isReporterRightViolated != null) {
                if (typeof object.isReporterRightViolated !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.isReporterRightViolated: object expected");
                message.isReporterRightViolated = $root.google.protobuf.BoolValue.fromObject(object.isReporterRightViolated);
            }
            if (object.evidenceText != null) {
                if (typeof object.evidenceText !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.evidenceText: object expected");
                message.evidenceText = $root.google.protobuf.StringValue.fromObject(object.evidenceText);
            }
            if (object.evidenceFileList) {
                if (!Array.isArray(object.evidenceFileList))
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.evidenceFileList: array expected");
                message.evidenceFileList = [];
                for (var i = 0; i < object.evidenceFileList.length; ++i) {
                    if (typeof object.evidenceFileList[i] !== "object")
                        throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.evidenceFileList: object expected");
                    message.evidenceFileList[i] = $root.google.protobuf.StringValue.fromObject(object.evidenceFileList[i]);
                }
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.allowNameDisclosure != null) {
                if (typeof object.allowNameDisclosure !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.allowNameDisclosure: object expected");
                message.allowNameDisclosure = $root.google.protobuf.BoolValue.fromObject(object.allowNameDisclosure);
            }
            if (object.emailAddress != null) {
                if (typeof object.emailAddress !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.emailAddress: object expected");
                message.emailAddress = $root.google.protobuf.StringValue.fromObject(object.emailAddress);
            }
            if (object.phoneNumber != null) {
                if (typeof object.phoneNumber !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.phoneNumber: object expected");
                message.phoneNumber = $root.google.protobuf.StringValue.fromObject(object.phoneNumber);
            }
            if (object.rightCertificationFileList) {
                if (!Array.isArray(object.rightCertificationFileList))
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.rightCertificationFileList: array expected");
                message.rightCertificationFileList = [];
                for (var i = 0; i < object.rightCertificationFileList.length; ++i) {
                    if (typeof object.rightCertificationFileList[i] !== "object")
                        throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.rightCertificationFileList: object expected");
                    message.rightCertificationFileList[i] = $root.google.protobuf.StringValue.fromObject(object.rightCertificationFileList[i]);
                }
            }
            if (object.evidenceTextByThirdParty != null) {
                if (typeof object.evidenceTextByThirdParty !== "object")
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.evidenceTextByThirdParty: object expected");
                message.evidenceTextByThirdParty = $root.google.protobuf.StringValue.fromObject(object.evidenceTextByThirdParty);
            }
            if (object.evidenceFileListByThirdParty) {
                if (!Array.isArray(object.evidenceFileListByThirdParty))
                    throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.evidenceFileListByThirdParty: array expected");
                message.evidenceFileListByThirdParty = [];
                for (var i = 0; i < object.evidenceFileListByThirdParty.length; ++i) {
                    if (typeof object.evidenceFileListByThirdParty[i] !== "object")
                        throw TypeError(".mutation_inspection.ReportSeriesRightViolationRequest.evidenceFileListByThirdParty: object expected");
                    message.evidenceFileListByThirdParty[i] = $root.google.protobuf.StringValue.fromObject(object.evidenceFileListByThirdParty[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ReportSeriesRightViolationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @static
         * @param {mutation_inspection.ReportSeriesRightViolationRequest} message ReportSeriesRightViolationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportSeriesRightViolationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.evidenceFileList = [];
                object.rightCertificationFileList = [];
                object.evidenceFileListByThirdParty = [];
            }
            if (options.defaults) {
                object.seriesId = null;
                object.contentType = options.enums === String ? "CONTENT_TYPE_UNSPECIFIED" : 0;
                object.rightType = options.enums === String ? "RIGHT_TYPE_UNSPECIFIED" : 0;
                object.isReporterRightViolated = null;
                object.evidenceText = null;
                object.name = null;
                object.allowNameDisclosure = null;
                object.emailAddress = null;
                object.phoneNumber = null;
                object.evidenceTextByThirdParty = null;
            }
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                object.contentType = options.enums === String ? $root.mutation_inspection.ReportContentType[message.contentType] : message.contentType;
            if (message.rightType != null && message.hasOwnProperty("rightType"))
                object.rightType = options.enums === String ? $root.mutation_inspection.ReportRightType[message.rightType] : message.rightType;
            if (message.isReporterRightViolated != null && message.hasOwnProperty("isReporterRightViolated"))
                object.isReporterRightViolated = $root.google.protobuf.BoolValue.toObject(message.isReporterRightViolated, options);
            if (message.evidenceText != null && message.hasOwnProperty("evidenceText"))
                object.evidenceText = $root.google.protobuf.StringValue.toObject(message.evidenceText, options);
            if (message.evidenceFileList && message.evidenceFileList.length) {
                object.evidenceFileList = [];
                for (var j = 0; j < message.evidenceFileList.length; ++j)
                    object.evidenceFileList[j] = $root.google.protobuf.StringValue.toObject(message.evidenceFileList[j], options);
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.allowNameDisclosure != null && message.hasOwnProperty("allowNameDisclosure"))
                object.allowNameDisclosure = $root.google.protobuf.BoolValue.toObject(message.allowNameDisclosure, options);
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = $root.google.protobuf.StringValue.toObject(message.emailAddress, options);
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                object.phoneNumber = $root.google.protobuf.StringValue.toObject(message.phoneNumber, options);
            if (message.rightCertificationFileList && message.rightCertificationFileList.length) {
                object.rightCertificationFileList = [];
                for (var j = 0; j < message.rightCertificationFileList.length; ++j)
                    object.rightCertificationFileList[j] = $root.google.protobuf.StringValue.toObject(message.rightCertificationFileList[j], options);
            }
            if (message.evidenceTextByThirdParty != null && message.hasOwnProperty("evidenceTextByThirdParty"))
                object.evidenceTextByThirdParty = $root.google.protobuf.StringValue.toObject(message.evidenceTextByThirdParty, options);
            if (message.evidenceFileListByThirdParty && message.evidenceFileListByThirdParty.length) {
                object.evidenceFileListByThirdParty = [];
                for (var j = 0; j < message.evidenceFileListByThirdParty.length; ++j)
                    object.evidenceFileListByThirdParty[j] = $root.google.protobuf.StringValue.toObject(message.evidenceFileListByThirdParty[j], options);
            }
            return object;
        };

        /**
         * Converts this ReportSeriesRightViolationRequest to JSON.
         * @function toJSON
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportSeriesRightViolationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportSeriesRightViolationRequest
         * @function getTypeUrl
         * @memberof mutation_inspection.ReportSeriesRightViolationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportSeriesRightViolationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_inspection.ReportSeriesRightViolationRequest";
        };

        return ReportSeriesRightViolationRequest;
    })();

    mutation_inspection.AcceptGuidelineRequest = (function() {

        /**
         * Properties of an AcceptGuidelineRequest.
         * @memberof mutation_inspection
         * @interface IAcceptGuidelineRequest
         * @property {google.protobuf.IStringValue|null} [seriesId] AcceptGuidelineRequest seriesId
         */

        /**
         * Constructs a new AcceptGuidelineRequest.
         * @memberof mutation_inspection
         * @classdesc Represents an AcceptGuidelineRequest.
         * @implements IAcceptGuidelineRequest
         * @constructor
         * @param {mutation_inspection.IAcceptGuidelineRequest=} [properties] Properties to set
         */
        function AcceptGuidelineRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AcceptGuidelineRequest seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @instance
         */
        AcceptGuidelineRequest.prototype.seriesId = null;

        /**
         * Creates a new AcceptGuidelineRequest instance using the specified properties.
         * @function create
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @static
         * @param {mutation_inspection.IAcceptGuidelineRequest=} [properties] Properties to set
         * @returns {mutation_inspection.AcceptGuidelineRequest} AcceptGuidelineRequest instance
         */
        AcceptGuidelineRequest.create = function create(properties) {
            return new AcceptGuidelineRequest(properties);
        };

        /**
         * Encodes the specified AcceptGuidelineRequest message. Does not implicitly {@link mutation_inspection.AcceptGuidelineRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @static
         * @param {mutation_inspection.IAcceptGuidelineRequest} message AcceptGuidelineRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AcceptGuidelineRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AcceptGuidelineRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_inspection.AcceptGuidelineRequest} AcceptGuidelineRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AcceptGuidelineRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_inspection.AcceptGuidelineRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AcceptGuidelineRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_inspection.AcceptGuidelineRequest} AcceptGuidelineRequest
         */
        AcceptGuidelineRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_inspection.AcceptGuidelineRequest)
                return object;
            var message = new $root.mutation_inspection.AcceptGuidelineRequest();
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".mutation_inspection.AcceptGuidelineRequest.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            return message;
        };

        /**
         * Creates a plain object from an AcceptGuidelineRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @static
         * @param {mutation_inspection.AcceptGuidelineRequest} message AcceptGuidelineRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AcceptGuidelineRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seriesId = null;
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            return object;
        };

        /**
         * Converts this AcceptGuidelineRequest to JSON.
         * @function toJSON
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AcceptGuidelineRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AcceptGuidelineRequest
         * @function getTypeUrl
         * @memberof mutation_inspection.AcceptGuidelineRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AcceptGuidelineRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_inspection.AcceptGuidelineRequest";
        };

        return AcceptGuidelineRequest;
    })();

    /**
     * ReportContentType enum.
     * @name mutation_inspection.ReportContentType
     * @enum {number}
     * @property {number} CONTENT_TYPE_UNSPECIFIED=0 CONTENT_TYPE_UNSPECIFIED value
     * @property {number} IMAGE=1 IMAGE value
     * @property {number} TEXT=2 TEXT value
     */
    mutation_inspection.ReportContentType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONTENT_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "IMAGE"] = 1;
        values[valuesById[2] = "TEXT"] = 2;
        return values;
    })();

    /**
     * ReportRightType enum.
     * @name mutation_inspection.ReportRightType
     * @enum {number}
     * @property {number} RIGHT_TYPE_UNSPECIFIED=0 RIGHT_TYPE_UNSPECIFIED value
     * @property {number} COPYRIGHT=1 COPYRIGHT value
     * @property {number} PUBLICITY=2 PUBLICITY value
     * @property {number} OTHER=3 OTHER value
     */
    mutation_inspection.ReportRightType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RIGHT_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "COPYRIGHT"] = 1;
        values[valuesById[2] = "PUBLICITY"] = 2;
        values[valuesById[3] = "OTHER"] = 3;
        return values;
    })();

    return mutation_inspection;
})();

$root.mutation_reading_progress = (function() {

    /**
     * Namespace mutation_reading_progress.
     * @exports mutation_reading_progress
     * @namespace
     */
    var mutation_reading_progress = {};

    mutation_reading_progress.DeleteStoryReadingProgressRequest = (function() {

        /**
         * Properties of a DeleteStoryReadingProgressRequest.
         * @memberof mutation_reading_progress
         * @interface IDeleteStoryReadingProgressRequest
         * @property {google.protobuf.IStringValue|null} [id] DeleteStoryReadingProgressRequest id
         */

        /**
         * Constructs a new DeleteStoryReadingProgressRequest.
         * @memberof mutation_reading_progress
         * @classdesc Represents a DeleteStoryReadingProgressRequest.
         * @implements IDeleteStoryReadingProgressRequest
         * @constructor
         * @param {mutation_reading_progress.IDeleteStoryReadingProgressRequest=} [properties] Properties to set
         */
        function DeleteStoryReadingProgressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteStoryReadingProgressRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @instance
         */
        DeleteStoryReadingProgressRequest.prototype.id = null;

        /**
         * Creates a new DeleteStoryReadingProgressRequest instance using the specified properties.
         * @function create
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @static
         * @param {mutation_reading_progress.IDeleteStoryReadingProgressRequest=} [properties] Properties to set
         * @returns {mutation_reading_progress.DeleteStoryReadingProgressRequest} DeleteStoryReadingProgressRequest instance
         */
        DeleteStoryReadingProgressRequest.create = function create(properties) {
            return new DeleteStoryReadingProgressRequest(properties);
        };

        /**
         * Encodes the specified DeleteStoryReadingProgressRequest message. Does not implicitly {@link mutation_reading_progress.DeleteStoryReadingProgressRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @static
         * @param {mutation_reading_progress.IDeleteStoryReadingProgressRequest} message DeleteStoryReadingProgressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteStoryReadingProgressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DeleteStoryReadingProgressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_reading_progress.DeleteStoryReadingProgressRequest} DeleteStoryReadingProgressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteStoryReadingProgressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_reading_progress.DeleteStoryReadingProgressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a DeleteStoryReadingProgressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_reading_progress.DeleteStoryReadingProgressRequest} DeleteStoryReadingProgressRequest
         */
        DeleteStoryReadingProgressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_reading_progress.DeleteStoryReadingProgressRequest)
                return object;
            var message = new $root.mutation_reading_progress.DeleteStoryReadingProgressRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_reading_progress.DeleteStoryReadingProgressRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteStoryReadingProgressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @static
         * @param {mutation_reading_progress.DeleteStoryReadingProgressRequest} message DeleteStoryReadingProgressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteStoryReadingProgressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this DeleteStoryReadingProgressRequest to JSON.
         * @function toJSON
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteStoryReadingProgressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteStoryReadingProgressRequest
         * @function getTypeUrl
         * @memberof mutation_reading_progress.DeleteStoryReadingProgressRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteStoryReadingProgressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_reading_progress.DeleteStoryReadingProgressRequest";
        };

        return DeleteStoryReadingProgressRequest;
    })();

    mutation_reading_progress.DeleteSeriesReadingProgressRequest = (function() {

        /**
         * Properties of a DeleteSeriesReadingProgressRequest.
         * @memberof mutation_reading_progress
         * @interface IDeleteSeriesReadingProgressRequest
         * @property {google.protobuf.IStringValue|null} [id] DeleteSeriesReadingProgressRequest id
         */

        /**
         * Constructs a new DeleteSeriesReadingProgressRequest.
         * @memberof mutation_reading_progress
         * @classdesc Represents a DeleteSeriesReadingProgressRequest.
         * @implements IDeleteSeriesReadingProgressRequest
         * @constructor
         * @param {mutation_reading_progress.IDeleteSeriesReadingProgressRequest=} [properties] Properties to set
         */
        function DeleteSeriesReadingProgressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteSeriesReadingProgressRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @instance
         */
        DeleteSeriesReadingProgressRequest.prototype.id = null;

        /**
         * Creates a new DeleteSeriesReadingProgressRequest instance using the specified properties.
         * @function create
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @static
         * @param {mutation_reading_progress.IDeleteSeriesReadingProgressRequest=} [properties] Properties to set
         * @returns {mutation_reading_progress.DeleteSeriesReadingProgressRequest} DeleteSeriesReadingProgressRequest instance
         */
        DeleteSeriesReadingProgressRequest.create = function create(properties) {
            return new DeleteSeriesReadingProgressRequest(properties);
        };

        /**
         * Encodes the specified DeleteSeriesReadingProgressRequest message. Does not implicitly {@link mutation_reading_progress.DeleteSeriesReadingProgressRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @static
         * @param {mutation_reading_progress.IDeleteSeriesReadingProgressRequest} message DeleteSeriesReadingProgressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteSeriesReadingProgressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DeleteSeriesReadingProgressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_reading_progress.DeleteSeriesReadingProgressRequest} DeleteSeriesReadingProgressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteSeriesReadingProgressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_reading_progress.DeleteSeriesReadingProgressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a DeleteSeriesReadingProgressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_reading_progress.DeleteSeriesReadingProgressRequest} DeleteSeriesReadingProgressRequest
         */
        DeleteSeriesReadingProgressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_reading_progress.DeleteSeriesReadingProgressRequest)
                return object;
            var message = new $root.mutation_reading_progress.DeleteSeriesReadingProgressRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_reading_progress.DeleteSeriesReadingProgressRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteSeriesReadingProgressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @static
         * @param {mutation_reading_progress.DeleteSeriesReadingProgressRequest} message DeleteSeriesReadingProgressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteSeriesReadingProgressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this DeleteSeriesReadingProgressRequest to JSON.
         * @function toJSON
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteSeriesReadingProgressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteSeriesReadingProgressRequest
         * @function getTypeUrl
         * @memberof mutation_reading_progress.DeleteSeriesReadingProgressRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteSeriesReadingProgressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_reading_progress.DeleteSeriesReadingProgressRequest";
        };

        return DeleteSeriesReadingProgressRequest;
    })();

    return mutation_reading_progress;
})();

$root.mutation_resource = (function() {

    /**
     * Namespace mutation_resource.
     * @exports mutation_resource
     * @namespace
     */
    var mutation_resource = {};

    mutation_resource.CreateImageRequest = (function() {

        /**
         * Properties of a CreateImageRequest.
         * @memberof mutation_resource
         * @interface ICreateImageRequest
         * @property {google.protobuf.IStringValue|null} [gsPath] CreateImageRequest gsPath
         * @property {resource.ImageFormat|null} [format] CreateImageRequest format
         * @property {google.protobuf.IInt32Value|null} [width] CreateImageRequest width
         * @property {google.protobuf.IInt32Value|null} [height] CreateImageRequest height
         * @property {resource.ImageCategory|null} [category] CreateImageRequest category
         */

        /**
         * Constructs a new CreateImageRequest.
         * @memberof mutation_resource
         * @classdesc Represents a CreateImageRequest.
         * @implements ICreateImageRequest
         * @constructor
         * @param {mutation_resource.ICreateImageRequest=} [properties] Properties to set
         */
        function CreateImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateImageRequest gsPath.
         * @member {google.protobuf.IStringValue|null|undefined} gsPath
         * @memberof mutation_resource.CreateImageRequest
         * @instance
         */
        CreateImageRequest.prototype.gsPath = null;

        /**
         * CreateImageRequest format.
         * @member {resource.ImageFormat} format
         * @memberof mutation_resource.CreateImageRequest
         * @instance
         */
        CreateImageRequest.prototype.format = 0;

        /**
         * CreateImageRequest width.
         * @member {google.protobuf.IInt32Value|null|undefined} width
         * @memberof mutation_resource.CreateImageRequest
         * @instance
         */
        CreateImageRequest.prototype.width = null;

        /**
         * CreateImageRequest height.
         * @member {google.protobuf.IInt32Value|null|undefined} height
         * @memberof mutation_resource.CreateImageRequest
         * @instance
         */
        CreateImageRequest.prototype.height = null;

        /**
         * CreateImageRequest category.
         * @member {resource.ImageCategory} category
         * @memberof mutation_resource.CreateImageRequest
         * @instance
         */
        CreateImageRequest.prototype.category = 0;

        /**
         * Creates a new CreateImageRequest instance using the specified properties.
         * @function create
         * @memberof mutation_resource.CreateImageRequest
         * @static
         * @param {mutation_resource.ICreateImageRequest=} [properties] Properties to set
         * @returns {mutation_resource.CreateImageRequest} CreateImageRequest instance
         */
        CreateImageRequest.create = function create(properties) {
            return new CreateImageRequest(properties);
        };

        /**
         * Encodes the specified CreateImageRequest message. Does not implicitly {@link mutation_resource.CreateImageRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_resource.CreateImageRequest
         * @static
         * @param {mutation_resource.ICreateImageRequest} message CreateImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gsPath != null && Object.hasOwnProperty.call(message, "gsPath"))
                $root.google.protobuf.StringValue.encode(message.gsPath, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.format);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                $root.google.protobuf.Int32Value.encode(message.width, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                $root.google.protobuf.Int32Value.encode(message.height, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.category);
            return writer;
        };

        /**
         * Decodes a CreateImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_resource.CreateImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_resource.CreateImageRequest} CreateImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_resource.CreateImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gsPath = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.format = reader.int32();
                    break;
                case 3:
                    message.width = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.height = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.category = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_resource.CreateImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_resource.CreateImageRequest} CreateImageRequest
         */
        CreateImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_resource.CreateImageRequest)
                return object;
            var message = new $root.mutation_resource.CreateImageRequest();
            if (object.gsPath != null) {
                if (typeof object.gsPath !== "object")
                    throw TypeError(".mutation_resource.CreateImageRequest.gsPath: object expected");
                message.gsPath = $root.google.protobuf.StringValue.fromObject(object.gsPath);
            }
            switch (object.format) {
            case "IMAGE_FORMAT_UNSPECIFIED":
            case 0:
                message.format = 0;
                break;
            case "IMAGE_FORMAT_PNG":
            case 1:
                message.format = 1;
                break;
            case "IMAGE_FORMAT_JPEG":
            case 2:
                message.format = 2;
                break;
            case "IMAGE_FORMAT_GIF":
            case 3:
                message.format = 3;
                break;
            case "IMAGE_FORMAT_WEBP":
            case 4:
                message.format = 4;
                break;
            }
            if (object.width != null) {
                if (typeof object.width !== "object")
                    throw TypeError(".mutation_resource.CreateImageRequest.width: object expected");
                message.width = $root.google.protobuf.Int32Value.fromObject(object.width);
            }
            if (object.height != null) {
                if (typeof object.height !== "object")
                    throw TypeError(".mutation_resource.CreateImageRequest.height: object expected");
                message.height = $root.google.protobuf.Int32Value.fromObject(object.height);
            }
            switch (object.category) {
            case "IMAGE_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "IMAGE_CATEGORY_USER_PROFILE":
            case 1:
                message.category = 1;
                break;
            case "IMAGE_CATEGORY_CHARACTER_ICON":
            case 2:
                message.category = 2;
                break;
            case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
            case 3:
                message.category = 3;
                break;
            case "IMAGE_CATEGORY_CHAT_BACKGROUND":
            case 4:
                message.category = 4;
                break;
            case "IMAGE_CATEGORY_TALK_BACKGROUND":
            case 5:
                message.category = 5;
                break;
            case "IMAGE_CATEGORY_CONTENT":
            case 6:
                message.category = 6;
                break;
            case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
            case 7:
                message.category = 7;
                break;
            case "IMAGE_CATEGORY_STAMP":
            case 8:
                message.category = 8;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_resource.CreateImageRequest
         * @static
         * @param {mutation_resource.CreateImageRequest} message CreateImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gsPath = null;
                object.format = options.enums === String ? "IMAGE_FORMAT_UNSPECIFIED" : 0;
                object.width = null;
                object.height = null;
                object.category = options.enums === String ? "IMAGE_CATEGORY_UNSPECIFIED" : 0;
            }
            if (message.gsPath != null && message.hasOwnProperty("gsPath"))
                object.gsPath = $root.google.protobuf.StringValue.toObject(message.gsPath, options);
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = options.enums === String ? $root.resource.ImageFormat[message.format] : message.format;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = $root.google.protobuf.Int32Value.toObject(message.width, options);
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = $root.google.protobuf.Int32Value.toObject(message.height, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.resource.ImageCategory[message.category] : message.category;
            return object;
        };

        /**
         * Converts this CreateImageRequest to JSON.
         * @function toJSON
         * @memberof mutation_resource.CreateImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateImageRequest
         * @function getTypeUrl
         * @memberof mutation_resource.CreateImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_resource.CreateImageRequest";
        };

        return CreateImageRequest;
    })();

    mutation_resource.CreateImageResponse = (function() {

        /**
         * Properties of a CreateImageResponse.
         * @memberof mutation_resource
         * @interface ICreateImageResponse
         * @property {resource.IImage|null} [image] CreateImageResponse image
         */

        /**
         * Constructs a new CreateImageResponse.
         * @memberof mutation_resource
         * @classdesc Represents a CreateImageResponse.
         * @implements ICreateImageResponse
         * @constructor
         * @param {mutation_resource.ICreateImageResponse=} [properties] Properties to set
         */
        function CreateImageResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateImageResponse image.
         * @member {resource.IImage|null|undefined} image
         * @memberof mutation_resource.CreateImageResponse
         * @instance
         */
        CreateImageResponse.prototype.image = null;

        /**
         * Creates a new CreateImageResponse instance using the specified properties.
         * @function create
         * @memberof mutation_resource.CreateImageResponse
         * @static
         * @param {mutation_resource.ICreateImageResponse=} [properties] Properties to set
         * @returns {mutation_resource.CreateImageResponse} CreateImageResponse instance
         */
        CreateImageResponse.create = function create(properties) {
            return new CreateImageResponse(properties);
        };

        /**
         * Encodes the specified CreateImageResponse message. Does not implicitly {@link mutation_resource.CreateImageResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_resource.CreateImageResponse
         * @static
         * @param {mutation_resource.ICreateImageResponse} message CreateImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateImageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                $root.resource.Image.encode(message.image, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreateImageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_resource.CreateImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_resource.CreateImageResponse} CreateImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateImageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_resource.CreateImageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.image = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateImageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_resource.CreateImageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_resource.CreateImageResponse} CreateImageResponse
         */
        CreateImageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_resource.CreateImageResponse)
                return object;
            var message = new $root.mutation_resource.CreateImageResponse();
            if (object.image != null) {
                if (typeof object.image !== "object")
                    throw TypeError(".mutation_resource.CreateImageResponse.image: object expected");
                message.image = $root.resource.Image.fromObject(object.image);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateImageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_resource.CreateImageResponse
         * @static
         * @param {mutation_resource.CreateImageResponse} message CreateImageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateImageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.image = null;
            if (message.image != null && message.hasOwnProperty("image"))
                object.image = $root.resource.Image.toObject(message.image, options);
            return object;
        };

        /**
         * Converts this CreateImageResponse to JSON.
         * @function toJSON
         * @memberof mutation_resource.CreateImageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateImageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateImageResponse
         * @function getTypeUrl
         * @memberof mutation_resource.CreateImageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateImageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_resource.CreateImageResponse";
        };

        return CreateImageResponse;
    })();

    mutation_resource.DeleteImageRequest = (function() {

        /**
         * Properties of a DeleteImageRequest.
         * @memberof mutation_resource
         * @interface IDeleteImageRequest
         * @property {google.protobuf.IStringValue|null} [id] DeleteImageRequest id
         */

        /**
         * Constructs a new DeleteImageRequest.
         * @memberof mutation_resource
         * @classdesc Represents a DeleteImageRequest.
         * @implements IDeleteImageRequest
         * @constructor
         * @param {mutation_resource.IDeleteImageRequest=} [properties] Properties to set
         */
        function DeleteImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteImageRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_resource.DeleteImageRequest
         * @instance
         */
        DeleteImageRequest.prototype.id = null;

        /**
         * Creates a new DeleteImageRequest instance using the specified properties.
         * @function create
         * @memberof mutation_resource.DeleteImageRequest
         * @static
         * @param {mutation_resource.IDeleteImageRequest=} [properties] Properties to set
         * @returns {mutation_resource.DeleteImageRequest} DeleteImageRequest instance
         */
        DeleteImageRequest.create = function create(properties) {
            return new DeleteImageRequest(properties);
        };

        /**
         * Encodes the specified DeleteImageRequest message. Does not implicitly {@link mutation_resource.DeleteImageRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_resource.DeleteImageRequest
         * @static
         * @param {mutation_resource.IDeleteImageRequest} message DeleteImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DeleteImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_resource.DeleteImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_resource.DeleteImageRequest} DeleteImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_resource.DeleteImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a DeleteImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_resource.DeleteImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_resource.DeleteImageRequest} DeleteImageRequest
         */
        DeleteImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_resource.DeleteImageRequest)
                return object;
            var message = new $root.mutation_resource.DeleteImageRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_resource.DeleteImageRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_resource.DeleteImageRequest
         * @static
         * @param {mutation_resource.DeleteImageRequest} message DeleteImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this DeleteImageRequest to JSON.
         * @function toJSON
         * @memberof mutation_resource.DeleteImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteImageRequest
         * @function getTypeUrl
         * @memberof mutation_resource.DeleteImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_resource.DeleteImageRequest";
        };

        return DeleteImageRequest;
    })();

    mutation_resource.HideImageRequest = (function() {

        /**
         * Properties of a HideImageRequest.
         * @memberof mutation_resource
         * @interface IHideImageRequest
         * @property {google.protobuf.IStringValue|null} [id] HideImageRequest id
         */

        /**
         * Constructs a new HideImageRequest.
         * @memberof mutation_resource
         * @classdesc Represents a HideImageRequest.
         * @implements IHideImageRequest
         * @constructor
         * @param {mutation_resource.IHideImageRequest=} [properties] Properties to set
         */
        function HideImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HideImageRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_resource.HideImageRequest
         * @instance
         */
        HideImageRequest.prototype.id = null;

        /**
         * Creates a new HideImageRequest instance using the specified properties.
         * @function create
         * @memberof mutation_resource.HideImageRequest
         * @static
         * @param {mutation_resource.IHideImageRequest=} [properties] Properties to set
         * @returns {mutation_resource.HideImageRequest} HideImageRequest instance
         */
        HideImageRequest.create = function create(properties) {
            return new HideImageRequest(properties);
        };

        /**
         * Encodes the specified HideImageRequest message. Does not implicitly {@link mutation_resource.HideImageRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_resource.HideImageRequest
         * @static
         * @param {mutation_resource.IHideImageRequest} message HideImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HideImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a HideImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_resource.HideImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_resource.HideImageRequest} HideImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HideImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_resource.HideImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a HideImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_resource.HideImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_resource.HideImageRequest} HideImageRequest
         */
        HideImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_resource.HideImageRequest)
                return object;
            var message = new $root.mutation_resource.HideImageRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_resource.HideImageRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a HideImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_resource.HideImageRequest
         * @static
         * @param {mutation_resource.HideImageRequest} message HideImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HideImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this HideImageRequest to JSON.
         * @function toJSON
         * @memberof mutation_resource.HideImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HideImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HideImageRequest
         * @function getTypeUrl
         * @memberof mutation_resource.HideImageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HideImageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_resource.HideImageRequest";
        };

        return HideImageRequest;
    })();

    return mutation_resource;
})();

$root.mutation_series = (function() {

    /**
     * Namespace mutation_series.
     * @exports mutation_series
     * @namespace
     */
    var mutation_series = {};

    mutation_series.CreateSeriesRequest = (function() {

        /**
         * Properties of a CreateSeriesRequest.
         * @memberof mutation_series
         * @interface ICreateSeriesRequest
         * @property {google.protobuf.IStringValue|null} [userId] CreateSeriesRequest userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] CreateSeriesRequest tags
         * @property {google.protobuf.IStringValue|null} [title] CreateSeriesRequest title
         * @property {google.protobuf.IStringValue|null} [description] CreateSeriesRequest description
         * @property {google.protobuf.IStringValue|null} [thumbnailId] CreateSeriesRequest thumbnailId
         * @property {google.protobuf.IBoolValue|null} [isCompleted] CreateSeriesRequest isCompleted
         * @property {google.protobuf.ITimestamp|null} [nextPublishAt] CreateSeriesRequest nextPublishAt
         * @property {types.SharedWithStatus|null} [sharedWithStatus] CreateSeriesRequest sharedWithStatus
         * @property {google.protobuf.IBoolValue|null} [isOneshot] CreateSeriesRequest isOneshot
         * @property {query_types.Genre|null} [genre] CreateSeriesRequest genre
         */

        /**
         * Constructs a new CreateSeriesRequest.
         * @memberof mutation_series
         * @classdesc Represents a CreateSeriesRequest.
         * @implements ICreateSeriesRequest
         * @constructor
         * @param {mutation_series.ICreateSeriesRequest=} [properties] Properties to set
         */
        function CreateSeriesRequest(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateSeriesRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.userId = null;

        /**
         * CreateSeriesRequest tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.tags = $util.emptyArray;

        /**
         * CreateSeriesRequest title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.title = null;

        /**
         * CreateSeriesRequest description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.description = null;

        /**
         * CreateSeriesRequest thumbnailId.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailId
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.thumbnailId = null;

        /**
         * CreateSeriesRequest isCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} isCompleted
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.isCompleted = null;

        /**
         * CreateSeriesRequest nextPublishAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextPublishAt
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.nextPublishAt = null;

        /**
         * CreateSeriesRequest sharedWithStatus.
         * @member {types.SharedWithStatus} sharedWithStatus
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.sharedWithStatus = 0;

        /**
         * CreateSeriesRequest isOneshot.
         * @member {google.protobuf.IBoolValue|null|undefined} isOneshot
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.isOneshot = null;

        /**
         * CreateSeriesRequest genre.
         * @member {query_types.Genre} genre
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         */
        CreateSeriesRequest.prototype.genre = 0;

        /**
         * Creates a new CreateSeriesRequest instance using the specified properties.
         * @function create
         * @memberof mutation_series.CreateSeriesRequest
         * @static
         * @param {mutation_series.ICreateSeriesRequest=} [properties] Properties to set
         * @returns {mutation_series.CreateSeriesRequest} CreateSeriesRequest instance
         */
        CreateSeriesRequest.create = function create(properties) {
            return new CreateSeriesRequest(properties);
        };

        /**
         * Encodes the specified CreateSeriesRequest message. Does not implicitly {@link mutation_series.CreateSeriesRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_series.CreateSeriesRequest
         * @static
         * @param {mutation_series.ICreateSeriesRequest} message CreateSeriesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSeriesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.thumbnailId != null && Object.hasOwnProperty.call(message, "thumbnailId"))
                $root.google.protobuf.StringValue.encode(message.thumbnailId, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.isCompleted != null && Object.hasOwnProperty.call(message, "isCompleted"))
                $root.google.protobuf.BoolValue.encode(message.isCompleted, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.nextPublishAt != null && Object.hasOwnProperty.call(message, "nextPublishAt"))
                $root.google.protobuf.Timestamp.encode(message.nextPublishAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.sharedWithStatus != null && Object.hasOwnProperty.call(message, "sharedWithStatus"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sharedWithStatus);
            if (message.isOneshot != null && Object.hasOwnProperty.call(message, "isOneshot"))
                $root.google.protobuf.BoolValue.encode(message.isOneshot, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.genre != null && Object.hasOwnProperty.call(message, "genre"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.genre);
            return writer;
        };

        /**
         * Decodes a CreateSeriesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series.CreateSeriesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series.CreateSeriesRequest} CreateSeriesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSeriesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series.CreateSeriesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.thumbnailId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.isCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.nextPublishAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.sharedWithStatus = reader.int32();
                    break;
                case 9:
                    message.isOneshot = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.genre = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateSeriesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series.CreateSeriesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series.CreateSeriesRequest} CreateSeriesRequest
         */
        CreateSeriesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series.CreateSeriesRequest)
                return object;
            var message = new $root.mutation_series.CreateSeriesRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".mutation_series.CreateSeriesRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".mutation_series.CreateSeriesRequest.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".mutation_series.CreateSeriesRequest.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".mutation_series.CreateSeriesRequest.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".mutation_series.CreateSeriesRequest.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailId != null) {
                if (typeof object.thumbnailId !== "object")
                    throw TypeError(".mutation_series.CreateSeriesRequest.thumbnailId: object expected");
                message.thumbnailId = $root.google.protobuf.StringValue.fromObject(object.thumbnailId);
            }
            if (object.isCompleted != null) {
                if (typeof object.isCompleted !== "object")
                    throw TypeError(".mutation_series.CreateSeriesRequest.isCompleted: object expected");
                message.isCompleted = $root.google.protobuf.BoolValue.fromObject(object.isCompleted);
            }
            if (object.nextPublishAt != null) {
                if (typeof object.nextPublishAt !== "object")
                    throw TypeError(".mutation_series.CreateSeriesRequest.nextPublishAt: object expected");
                message.nextPublishAt = $root.google.protobuf.Timestamp.fromObject(object.nextPublishAt);
            }
            switch (object.sharedWithStatus) {
            case "SHARED_WITH_STATUS_UNKNOWN":
            case 0:
                message.sharedWithStatus = 0;
                break;
            case "SHARED_WITH_NOBODY":
            case 1:
                message.sharedWithStatus = 1;
                break;
            case "SHARED_WITH_DIRECT_LINK":
            case 2:
                message.sharedWithStatus = 2;
                break;
            case "SHARED_WITH_PUBLIC":
            case 3:
                message.sharedWithStatus = 3;
                break;
            case "SHARED_WITH_FOLLOWER":
            case 4:
                message.sharedWithStatus = 4;
                break;
            case "SHARED_WITH_MUTUAL_FOLLOWER":
            case 5:
                message.sharedWithStatus = 5;
                break;
            }
            if (object.isOneshot != null) {
                if (typeof object.isOneshot !== "object")
                    throw TypeError(".mutation_series.CreateSeriesRequest.isOneshot: object expected");
                message.isOneshot = $root.google.protobuf.BoolValue.fromObject(object.isOneshot);
            }
            switch (object.genre) {
            case "GENRE_UNSPECIFIED":
            case 0:
                message.genre = 0;
                break;
            case "GENRE_FANFIC":
            case 1:
                message.genre = 1;
                break;
            case "GENRE_OTHER":
            case 2:
                message.genre = 2;
                break;
            case "GENRE_HORROR_THRILLER":
            case 3:
                message.genre = 3;
                break;
            case "GENRE_ROMANCE_FANTASY":
            case 4:
                message.genre = 4;
                break;
            case "GENRE_BL":
            case 5:
                message.genre = 5;
                break;
            case "GENRE_DRAMA":
            case 6:
                message.genre = 6;
                break;
            case "GENRE_ROMANCE":
            case 7:
                message.genre = 7;
                break;
            case "GENRE_FANTASY":
            case 8:
                message.genre = 8;
                break;
            case "GENRE_COMEDY":
            case 9:
                message.genre = 9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateSeriesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series.CreateSeriesRequest
         * @static
         * @param {mutation_series.CreateSeriesRequest} message CreateSeriesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateSeriesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.userId = null;
                object.title = null;
                object.description = null;
                object.thumbnailId = null;
                object.isCompleted = null;
                object.nextPublishAt = null;
                object.sharedWithStatus = options.enums === String ? "SHARED_WITH_STATUS_UNKNOWN" : 0;
                object.isOneshot = null;
                object.genre = options.enums === String ? "GENRE_UNSPECIFIED" : 0;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailId != null && message.hasOwnProperty("thumbnailId"))
                object.thumbnailId = $root.google.protobuf.StringValue.toObject(message.thumbnailId, options);
            if (message.isCompleted != null && message.hasOwnProperty("isCompleted"))
                object.isCompleted = $root.google.protobuf.BoolValue.toObject(message.isCompleted, options);
            if (message.nextPublishAt != null && message.hasOwnProperty("nextPublishAt"))
                object.nextPublishAt = $root.google.protobuf.Timestamp.toObject(message.nextPublishAt, options);
            if (message.sharedWithStatus != null && message.hasOwnProperty("sharedWithStatus"))
                object.sharedWithStatus = options.enums === String ? $root.types.SharedWithStatus[message.sharedWithStatus] : message.sharedWithStatus;
            if (message.isOneshot != null && message.hasOwnProperty("isOneshot"))
                object.isOneshot = $root.google.protobuf.BoolValue.toObject(message.isOneshot, options);
            if (message.genre != null && message.hasOwnProperty("genre"))
                object.genre = options.enums === String ? $root.query_types.Genre[message.genre] : message.genre;
            return object;
        };

        /**
         * Converts this CreateSeriesRequest to JSON.
         * @function toJSON
         * @memberof mutation_series.CreateSeriesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateSeriesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateSeriesRequest
         * @function getTypeUrl
         * @memberof mutation_series.CreateSeriesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateSeriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series.CreateSeriesRequest";
        };

        return CreateSeriesRequest;
    })();

    mutation_series.CreateSeriesResponse = (function() {

        /**
         * Properties of a CreateSeriesResponse.
         * @memberof mutation_series
         * @interface ICreateSeriesResponse
         * @property {google.protobuf.IStringValue|null} [id] CreateSeriesResponse id
         */

        /**
         * Constructs a new CreateSeriesResponse.
         * @memberof mutation_series
         * @classdesc Represents a CreateSeriesResponse.
         * @implements ICreateSeriesResponse
         * @constructor
         * @param {mutation_series.ICreateSeriesResponse=} [properties] Properties to set
         */
        function CreateSeriesResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateSeriesResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_series.CreateSeriesResponse
         * @instance
         */
        CreateSeriesResponse.prototype.id = null;

        /**
         * Creates a new CreateSeriesResponse instance using the specified properties.
         * @function create
         * @memberof mutation_series.CreateSeriesResponse
         * @static
         * @param {mutation_series.ICreateSeriesResponse=} [properties] Properties to set
         * @returns {mutation_series.CreateSeriesResponse} CreateSeriesResponse instance
         */
        CreateSeriesResponse.create = function create(properties) {
            return new CreateSeriesResponse(properties);
        };

        /**
         * Encodes the specified CreateSeriesResponse message. Does not implicitly {@link mutation_series.CreateSeriesResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_series.CreateSeriesResponse
         * @static
         * @param {mutation_series.ICreateSeriesResponse} message CreateSeriesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSeriesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreateSeriesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series.CreateSeriesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series.CreateSeriesResponse} CreateSeriesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSeriesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series.CreateSeriesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateSeriesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series.CreateSeriesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series.CreateSeriesResponse} CreateSeriesResponse
         */
        CreateSeriesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series.CreateSeriesResponse)
                return object;
            var message = new $root.mutation_series.CreateSeriesResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_series.CreateSeriesResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateSeriesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series.CreateSeriesResponse
         * @static
         * @param {mutation_series.CreateSeriesResponse} message CreateSeriesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateSeriesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this CreateSeriesResponse to JSON.
         * @function toJSON
         * @memberof mutation_series.CreateSeriesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateSeriesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateSeriesResponse
         * @function getTypeUrl
         * @memberof mutation_series.CreateSeriesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateSeriesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series.CreateSeriesResponse";
        };

        return CreateSeriesResponse;
    })();

    mutation_series.UpdateSeriesRequest = (function() {

        /**
         * Properties of an UpdateSeriesRequest.
         * @memberof mutation_series
         * @interface IUpdateSeriesRequest
         * @property {google.protobuf.IStringValue|null} [id] UpdateSeriesRequest id
         * @property {google.protobuf.IStringValue|null} [userId] UpdateSeriesRequest userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] UpdateSeriesRequest tags
         * @property {google.protobuf.IStringValue|null} [title] UpdateSeriesRequest title
         * @property {google.protobuf.IStringValue|null} [description] UpdateSeriesRequest description
         * @property {google.protobuf.IStringValue|null} [thumbnailId] UpdateSeriesRequest thumbnailId
         * @property {google.protobuf.IBoolValue|null} [isCompleted] UpdateSeriesRequest isCompleted
         * @property {google.protobuf.ITimestamp|null} [nextPublishAt] UpdateSeriesRequest nextPublishAt
         * @property {types.SharedWithStatus|null} [sharedWithStatus] UpdateSeriesRequest sharedWithStatus
         * @property {google.protobuf.IBoolValue|null} [isOneshot] UpdateSeriesRequest isOneshot
         * @property {query_types.Genre|null} [genre] UpdateSeriesRequest genre
         */

        /**
         * Constructs a new UpdateSeriesRequest.
         * @memberof mutation_series
         * @classdesc Represents an UpdateSeriesRequest.
         * @implements IUpdateSeriesRequest
         * @constructor
         * @param {mutation_series.IUpdateSeriesRequest=} [properties] Properties to set
         */
        function UpdateSeriesRequest(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateSeriesRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.id = null;

        /**
         * UpdateSeriesRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.userId = null;

        /**
         * UpdateSeriesRequest tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.tags = $util.emptyArray;

        /**
         * UpdateSeriesRequest title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.title = null;

        /**
         * UpdateSeriesRequest description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.description = null;

        /**
         * UpdateSeriesRequest thumbnailId.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailId
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.thumbnailId = null;

        /**
         * UpdateSeriesRequest isCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} isCompleted
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.isCompleted = null;

        /**
         * UpdateSeriesRequest nextPublishAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextPublishAt
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.nextPublishAt = null;

        /**
         * UpdateSeriesRequest sharedWithStatus.
         * @member {types.SharedWithStatus} sharedWithStatus
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.sharedWithStatus = 0;

        /**
         * UpdateSeriesRequest isOneshot.
         * @member {google.protobuf.IBoolValue|null|undefined} isOneshot
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.isOneshot = null;

        /**
         * UpdateSeriesRequest genre.
         * @member {query_types.Genre} genre
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         */
        UpdateSeriesRequest.prototype.genre = 0;

        /**
         * Creates a new UpdateSeriesRequest instance using the specified properties.
         * @function create
         * @memberof mutation_series.UpdateSeriesRequest
         * @static
         * @param {mutation_series.IUpdateSeriesRequest=} [properties] Properties to set
         * @returns {mutation_series.UpdateSeriesRequest} UpdateSeriesRequest instance
         */
        UpdateSeriesRequest.create = function create(properties) {
            return new UpdateSeriesRequest(properties);
        };

        /**
         * Encodes the specified UpdateSeriesRequest message. Does not implicitly {@link mutation_series.UpdateSeriesRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_series.UpdateSeriesRequest
         * @static
         * @param {mutation_series.IUpdateSeriesRequest} message UpdateSeriesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSeriesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.thumbnailId != null && Object.hasOwnProperty.call(message, "thumbnailId"))
                $root.google.protobuf.StringValue.encode(message.thumbnailId, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isCompleted != null && Object.hasOwnProperty.call(message, "isCompleted"))
                $root.google.protobuf.BoolValue.encode(message.isCompleted, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.nextPublishAt != null && Object.hasOwnProperty.call(message, "nextPublishAt"))
                $root.google.protobuf.Timestamp.encode(message.nextPublishAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.sharedWithStatus != null && Object.hasOwnProperty.call(message, "sharedWithStatus"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.sharedWithStatus);
            if (message.isOneshot != null && Object.hasOwnProperty.call(message, "isOneshot"))
                $root.google.protobuf.BoolValue.encode(message.isOneshot, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.genre != null && Object.hasOwnProperty.call(message, "genre"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.genre);
            return writer;
        };

        /**
         * Decodes an UpdateSeriesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series.UpdateSeriesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series.UpdateSeriesRequest} UpdateSeriesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSeriesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series.UpdateSeriesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.thumbnailId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.nextPublishAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.sharedWithStatus = reader.int32();
                    break;
                case 10:
                    message.isOneshot = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.genre = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateSeriesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series.UpdateSeriesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series.UpdateSeriesRequest} UpdateSeriesRequest
         */
        UpdateSeriesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series.UpdateSeriesRequest)
                return object;
            var message = new $root.mutation_series.UpdateSeriesRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".mutation_series.UpdateSeriesRequest.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".mutation_series.UpdateSeriesRequest.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailId != null) {
                if (typeof object.thumbnailId !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.thumbnailId: object expected");
                message.thumbnailId = $root.google.protobuf.StringValue.fromObject(object.thumbnailId);
            }
            if (object.isCompleted != null) {
                if (typeof object.isCompleted !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.isCompleted: object expected");
                message.isCompleted = $root.google.protobuf.BoolValue.fromObject(object.isCompleted);
            }
            if (object.nextPublishAt != null) {
                if (typeof object.nextPublishAt !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.nextPublishAt: object expected");
                message.nextPublishAt = $root.google.protobuf.Timestamp.fromObject(object.nextPublishAt);
            }
            switch (object.sharedWithStatus) {
            case "SHARED_WITH_STATUS_UNKNOWN":
            case 0:
                message.sharedWithStatus = 0;
                break;
            case "SHARED_WITH_NOBODY":
            case 1:
                message.sharedWithStatus = 1;
                break;
            case "SHARED_WITH_DIRECT_LINK":
            case 2:
                message.sharedWithStatus = 2;
                break;
            case "SHARED_WITH_PUBLIC":
            case 3:
                message.sharedWithStatus = 3;
                break;
            case "SHARED_WITH_FOLLOWER":
            case 4:
                message.sharedWithStatus = 4;
                break;
            case "SHARED_WITH_MUTUAL_FOLLOWER":
            case 5:
                message.sharedWithStatus = 5;
                break;
            }
            if (object.isOneshot != null) {
                if (typeof object.isOneshot !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesRequest.isOneshot: object expected");
                message.isOneshot = $root.google.protobuf.BoolValue.fromObject(object.isOneshot);
            }
            switch (object.genre) {
            case "GENRE_UNSPECIFIED":
            case 0:
                message.genre = 0;
                break;
            case "GENRE_FANFIC":
            case 1:
                message.genre = 1;
                break;
            case "GENRE_OTHER":
            case 2:
                message.genre = 2;
                break;
            case "GENRE_HORROR_THRILLER":
            case 3:
                message.genre = 3;
                break;
            case "GENRE_ROMANCE_FANTASY":
            case 4:
                message.genre = 4;
                break;
            case "GENRE_BL":
            case 5:
                message.genre = 5;
                break;
            case "GENRE_DRAMA":
            case 6:
                message.genre = 6;
                break;
            case "GENRE_ROMANCE":
            case 7:
                message.genre = 7;
                break;
            case "GENRE_FANTASY":
            case 8:
                message.genre = 8;
                break;
            case "GENRE_COMEDY":
            case 9:
                message.genre = 9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateSeriesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series.UpdateSeriesRequest
         * @static
         * @param {mutation_series.UpdateSeriesRequest} message UpdateSeriesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSeriesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.id = null;
                object.userId = null;
                object.title = null;
                object.description = null;
                object.thumbnailId = null;
                object.isCompleted = null;
                object.nextPublishAt = null;
                object.sharedWithStatus = options.enums === String ? "SHARED_WITH_STATUS_UNKNOWN" : 0;
                object.isOneshot = null;
                object.genre = options.enums === String ? "GENRE_UNSPECIFIED" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailId != null && message.hasOwnProperty("thumbnailId"))
                object.thumbnailId = $root.google.protobuf.StringValue.toObject(message.thumbnailId, options);
            if (message.isCompleted != null && message.hasOwnProperty("isCompleted"))
                object.isCompleted = $root.google.protobuf.BoolValue.toObject(message.isCompleted, options);
            if (message.nextPublishAt != null && message.hasOwnProperty("nextPublishAt"))
                object.nextPublishAt = $root.google.protobuf.Timestamp.toObject(message.nextPublishAt, options);
            if (message.sharedWithStatus != null && message.hasOwnProperty("sharedWithStatus"))
                object.sharedWithStatus = options.enums === String ? $root.types.SharedWithStatus[message.sharedWithStatus] : message.sharedWithStatus;
            if (message.isOneshot != null && message.hasOwnProperty("isOneshot"))
                object.isOneshot = $root.google.protobuf.BoolValue.toObject(message.isOneshot, options);
            if (message.genre != null && message.hasOwnProperty("genre"))
                object.genre = options.enums === String ? $root.query_types.Genre[message.genre] : message.genre;
            return object;
        };

        /**
         * Converts this UpdateSeriesRequest to JSON.
         * @function toJSON
         * @memberof mutation_series.UpdateSeriesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSeriesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateSeriesRequest
         * @function getTypeUrl
         * @memberof mutation_series.UpdateSeriesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateSeriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series.UpdateSeriesRequest";
        };

        return UpdateSeriesRequest;
    })();

    mutation_series.UpdateSeriesResponse = (function() {

        /**
         * Properties of an UpdateSeriesResponse.
         * @memberof mutation_series
         * @interface IUpdateSeriesResponse
         * @property {google.protobuf.IStringValue|null} [id] UpdateSeriesResponse id
         */

        /**
         * Constructs a new UpdateSeriesResponse.
         * @memberof mutation_series
         * @classdesc Represents an UpdateSeriesResponse.
         * @implements IUpdateSeriesResponse
         * @constructor
         * @param {mutation_series.IUpdateSeriesResponse=} [properties] Properties to set
         */
        function UpdateSeriesResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateSeriesResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_series.UpdateSeriesResponse
         * @instance
         */
        UpdateSeriesResponse.prototype.id = null;

        /**
         * Creates a new UpdateSeriesResponse instance using the specified properties.
         * @function create
         * @memberof mutation_series.UpdateSeriesResponse
         * @static
         * @param {mutation_series.IUpdateSeriesResponse=} [properties] Properties to set
         * @returns {mutation_series.UpdateSeriesResponse} UpdateSeriesResponse instance
         */
        UpdateSeriesResponse.create = function create(properties) {
            return new UpdateSeriesResponse(properties);
        };

        /**
         * Encodes the specified UpdateSeriesResponse message. Does not implicitly {@link mutation_series.UpdateSeriesResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_series.UpdateSeriesResponse
         * @static
         * @param {mutation_series.IUpdateSeriesResponse} message UpdateSeriesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSeriesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateSeriesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series.UpdateSeriesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series.UpdateSeriesResponse} UpdateSeriesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSeriesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series.UpdateSeriesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateSeriesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series.UpdateSeriesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series.UpdateSeriesResponse} UpdateSeriesResponse
         */
        UpdateSeriesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series.UpdateSeriesResponse)
                return object;
            var message = new $root.mutation_series.UpdateSeriesResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateSeriesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series.UpdateSeriesResponse
         * @static
         * @param {mutation_series.UpdateSeriesResponse} message UpdateSeriesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSeriesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this UpdateSeriesResponse to JSON.
         * @function toJSON
         * @memberof mutation_series.UpdateSeriesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSeriesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateSeriesResponse
         * @function getTypeUrl
         * @memberof mutation_series.UpdateSeriesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateSeriesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series.UpdateSeriesResponse";
        };

        return UpdateSeriesResponse;
    })();

    mutation_series.DeleteSeriesRequest = (function() {

        /**
         * Properties of a DeleteSeriesRequest.
         * @memberof mutation_series
         * @interface IDeleteSeriesRequest
         * @property {google.protobuf.IStringValue|null} [id] DeleteSeriesRequest id
         * @property {google.protobuf.IBoolValue|null} [deleteStory] DeleteSeriesRequest deleteStory
         */

        /**
         * Constructs a new DeleteSeriesRequest.
         * @memberof mutation_series
         * @classdesc Represents a DeleteSeriesRequest.
         * @implements IDeleteSeriesRequest
         * @constructor
         * @param {mutation_series.IDeleteSeriesRequest=} [properties] Properties to set
         */
        function DeleteSeriesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteSeriesRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_series.DeleteSeriesRequest
         * @instance
         */
        DeleteSeriesRequest.prototype.id = null;

        /**
         * DeleteSeriesRequest deleteStory.
         * @member {google.protobuf.IBoolValue|null|undefined} deleteStory
         * @memberof mutation_series.DeleteSeriesRequest
         * @instance
         */
        DeleteSeriesRequest.prototype.deleteStory = null;

        /**
         * Creates a new DeleteSeriesRequest instance using the specified properties.
         * @function create
         * @memberof mutation_series.DeleteSeriesRequest
         * @static
         * @param {mutation_series.IDeleteSeriesRequest=} [properties] Properties to set
         * @returns {mutation_series.DeleteSeriesRequest} DeleteSeriesRequest instance
         */
        DeleteSeriesRequest.create = function create(properties) {
            return new DeleteSeriesRequest(properties);
        };

        /**
         * Encodes the specified DeleteSeriesRequest message. Does not implicitly {@link mutation_series.DeleteSeriesRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_series.DeleteSeriesRequest
         * @static
         * @param {mutation_series.IDeleteSeriesRequest} message DeleteSeriesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteSeriesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.deleteStory != null && Object.hasOwnProperty.call(message, "deleteStory"))
                $root.google.protobuf.BoolValue.encode(message.deleteStory, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DeleteSeriesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series.DeleteSeriesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series.DeleteSeriesRequest} DeleteSeriesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteSeriesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series.DeleteSeriesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.deleteStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a DeleteSeriesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series.DeleteSeriesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series.DeleteSeriesRequest} DeleteSeriesRequest
         */
        DeleteSeriesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series.DeleteSeriesRequest)
                return object;
            var message = new $root.mutation_series.DeleteSeriesRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_series.DeleteSeriesRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.deleteStory != null) {
                if (typeof object.deleteStory !== "object")
                    throw TypeError(".mutation_series.DeleteSeriesRequest.deleteStory: object expected");
                message.deleteStory = $root.google.protobuf.BoolValue.fromObject(object.deleteStory);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteSeriesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series.DeleteSeriesRequest
         * @static
         * @param {mutation_series.DeleteSeriesRequest} message DeleteSeriesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteSeriesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.deleteStory = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.deleteStory != null && message.hasOwnProperty("deleteStory"))
                object.deleteStory = $root.google.protobuf.BoolValue.toObject(message.deleteStory, options);
            return object;
        };

        /**
         * Converts this DeleteSeriesRequest to JSON.
         * @function toJSON
         * @memberof mutation_series.DeleteSeriesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteSeriesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteSeriesRequest
         * @function getTypeUrl
         * @memberof mutation_series.DeleteSeriesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteSeriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series.DeleteSeriesRequest";
        };

        return DeleteSeriesRequest;
    })();

    mutation_series.UpdateSeriesStoryOrderRequest = (function() {

        /**
         * Properties of an UpdateSeriesStoryOrderRequest.
         * @memberof mutation_series
         * @interface IUpdateSeriesStoryOrderRequest
         * @property {google.protobuf.IStringValue|null} [id] UpdateSeriesStoryOrderRequest id
         * @property {Array.<google.protobuf.IStringValue>|null} [orderedStoryIdList] UpdateSeriesStoryOrderRequest orderedStoryIdList
         */

        /**
         * Constructs a new UpdateSeriesStoryOrderRequest.
         * @memberof mutation_series
         * @classdesc Represents an UpdateSeriesStoryOrderRequest.
         * @implements IUpdateSeriesStoryOrderRequest
         * @constructor
         * @param {mutation_series.IUpdateSeriesStoryOrderRequest=} [properties] Properties to set
         */
        function UpdateSeriesStoryOrderRequest(properties) {
            this.orderedStoryIdList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateSeriesStoryOrderRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @instance
         */
        UpdateSeriesStoryOrderRequest.prototype.id = null;

        /**
         * UpdateSeriesStoryOrderRequest orderedStoryIdList.
         * @member {Array.<google.protobuf.IStringValue>} orderedStoryIdList
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @instance
         */
        UpdateSeriesStoryOrderRequest.prototype.orderedStoryIdList = $util.emptyArray;

        /**
         * Creates a new UpdateSeriesStoryOrderRequest instance using the specified properties.
         * @function create
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @static
         * @param {mutation_series.IUpdateSeriesStoryOrderRequest=} [properties] Properties to set
         * @returns {mutation_series.UpdateSeriesStoryOrderRequest} UpdateSeriesStoryOrderRequest instance
         */
        UpdateSeriesStoryOrderRequest.create = function create(properties) {
            return new UpdateSeriesStoryOrderRequest(properties);
        };

        /**
         * Encodes the specified UpdateSeriesStoryOrderRequest message. Does not implicitly {@link mutation_series.UpdateSeriesStoryOrderRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @static
         * @param {mutation_series.IUpdateSeriesStoryOrderRequest} message UpdateSeriesStoryOrderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSeriesStoryOrderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.orderedStoryIdList != null && message.orderedStoryIdList.length)
                for (var i = 0; i < message.orderedStoryIdList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.orderedStoryIdList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateSeriesStoryOrderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series.UpdateSeriesStoryOrderRequest} UpdateSeriesStoryOrderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSeriesStoryOrderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series.UpdateSeriesStoryOrderRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.orderedStoryIdList && message.orderedStoryIdList.length))
                        message.orderedStoryIdList = [];
                    message.orderedStoryIdList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateSeriesStoryOrderRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series.UpdateSeriesStoryOrderRequest} UpdateSeriesStoryOrderRequest
         */
        UpdateSeriesStoryOrderRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series.UpdateSeriesStoryOrderRequest)
                return object;
            var message = new $root.mutation_series.UpdateSeriesStoryOrderRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_series.UpdateSeriesStoryOrderRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.orderedStoryIdList) {
                if (!Array.isArray(object.orderedStoryIdList))
                    throw TypeError(".mutation_series.UpdateSeriesStoryOrderRequest.orderedStoryIdList: array expected");
                message.orderedStoryIdList = [];
                for (var i = 0; i < object.orderedStoryIdList.length; ++i) {
                    if (typeof object.orderedStoryIdList[i] !== "object")
                        throw TypeError(".mutation_series.UpdateSeriesStoryOrderRequest.orderedStoryIdList: object expected");
                    message.orderedStoryIdList[i] = $root.google.protobuf.StringValue.fromObject(object.orderedStoryIdList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateSeriesStoryOrderRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @static
         * @param {mutation_series.UpdateSeriesStoryOrderRequest} message UpdateSeriesStoryOrderRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSeriesStoryOrderRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.orderedStoryIdList = [];
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.orderedStoryIdList && message.orderedStoryIdList.length) {
                object.orderedStoryIdList = [];
                for (var j = 0; j < message.orderedStoryIdList.length; ++j)
                    object.orderedStoryIdList[j] = $root.google.protobuf.StringValue.toObject(message.orderedStoryIdList[j], options);
            }
            return object;
        };

        /**
         * Converts this UpdateSeriesStoryOrderRequest to JSON.
         * @function toJSON
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSeriesStoryOrderRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateSeriesStoryOrderRequest
         * @function getTypeUrl
         * @memberof mutation_series.UpdateSeriesStoryOrderRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateSeriesStoryOrderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series.UpdateSeriesStoryOrderRequest";
        };

        return UpdateSeriesStoryOrderRequest;
    })();

    return mutation_series;
})();

$root.mutation_series_charge = (function() {

    /**
     * Namespace mutation_series_charge.
     * @exports mutation_series_charge
     * @namespace
     */
    var mutation_series_charge = {};

    mutation_series_charge.UseSeriesChargeRequest = (function() {

        /**
         * Properties of a UseSeriesChargeRequest.
         * @memberof mutation_series_charge
         * @interface IUseSeriesChargeRequest
         * @property {google.protobuf.IStringValue|null} [storyId] UseSeriesChargeRequest storyId
         * @property {query_series_charge.ISeriesChargeRequireField|null} [requireSeriesCharge] UseSeriesChargeRequest requireSeriesCharge
         */

        /**
         * Constructs a new UseSeriesChargeRequest.
         * @memberof mutation_series_charge
         * @classdesc Represents a UseSeriesChargeRequest.
         * @implements IUseSeriesChargeRequest
         * @constructor
         * @param {mutation_series_charge.IUseSeriesChargeRequest=} [properties] Properties to set
         */
        function UseSeriesChargeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UseSeriesChargeRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @instance
         */
        UseSeriesChargeRequest.prototype.storyId = null;

        /**
         * UseSeriesChargeRequest requireSeriesCharge.
         * @member {query_series_charge.ISeriesChargeRequireField|null|undefined} requireSeriesCharge
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @instance
         */
        UseSeriesChargeRequest.prototype.requireSeriesCharge = null;

        /**
         * Creates a new UseSeriesChargeRequest instance using the specified properties.
         * @function create
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @static
         * @param {mutation_series_charge.IUseSeriesChargeRequest=} [properties] Properties to set
         * @returns {mutation_series_charge.UseSeriesChargeRequest} UseSeriesChargeRequest instance
         */
        UseSeriesChargeRequest.create = function create(properties) {
            return new UseSeriesChargeRequest(properties);
        };

        /**
         * Encodes the specified UseSeriesChargeRequest message. Does not implicitly {@link mutation_series_charge.UseSeriesChargeRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @static
         * @param {mutation_series_charge.IUseSeriesChargeRequest} message UseSeriesChargeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UseSeriesChargeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireSeriesCharge != null && Object.hasOwnProperty.call(message, "requireSeriesCharge"))
                $root.query_series_charge.SeriesChargeRequireField.encode(message.requireSeriesCharge, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UseSeriesChargeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series_charge.UseSeriesChargeRequest} UseSeriesChargeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UseSeriesChargeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series_charge.UseSeriesChargeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UseSeriesChargeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series_charge.UseSeriesChargeRequest} UseSeriesChargeRequest
         */
        UseSeriesChargeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series_charge.UseSeriesChargeRequest)
                return object;
            var message = new $root.mutation_series_charge.UseSeriesChargeRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".mutation_series_charge.UseSeriesChargeRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.requireSeriesCharge != null) {
                if (typeof object.requireSeriesCharge !== "object")
                    throw TypeError(".mutation_series_charge.UseSeriesChargeRequest.requireSeriesCharge: object expected");
                message.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.fromObject(object.requireSeriesCharge);
            }
            return message;
        };

        /**
         * Creates a plain object from a UseSeriesChargeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @static
         * @param {mutation_series_charge.UseSeriesChargeRequest} message UseSeriesChargeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UseSeriesChargeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.requireSeriesCharge = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.requireSeriesCharge != null && message.hasOwnProperty("requireSeriesCharge"))
                object.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.toObject(message.requireSeriesCharge, options);
            return object;
        };

        /**
         * Converts this UseSeriesChargeRequest to JSON.
         * @function toJSON
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UseSeriesChargeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UseSeriesChargeRequest
         * @function getTypeUrl
         * @memberof mutation_series_charge.UseSeriesChargeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UseSeriesChargeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series_charge.UseSeriesChargeRequest";
        };

        return UseSeriesChargeRequest;
    })();

    mutation_series_charge.UseSeriesChargeResponse = (function() {

        /**
         * Properties of a UseSeriesChargeResponse.
         * @memberof mutation_series_charge
         * @interface IUseSeriesChargeResponse
         * @property {query_series_charge.ISeriesChargeResponse|null} [seriesCharge] UseSeriesChargeResponse seriesCharge
         */

        /**
         * Constructs a new UseSeriesChargeResponse.
         * @memberof mutation_series_charge
         * @classdesc Represents a UseSeriesChargeResponse.
         * @implements IUseSeriesChargeResponse
         * @constructor
         * @param {mutation_series_charge.IUseSeriesChargeResponse=} [properties] Properties to set
         */
        function UseSeriesChargeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UseSeriesChargeResponse seriesCharge.
         * @member {query_series_charge.ISeriesChargeResponse|null|undefined} seriesCharge
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @instance
         */
        UseSeriesChargeResponse.prototype.seriesCharge = null;

        /**
         * Creates a new UseSeriesChargeResponse instance using the specified properties.
         * @function create
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @static
         * @param {mutation_series_charge.IUseSeriesChargeResponse=} [properties] Properties to set
         * @returns {mutation_series_charge.UseSeriesChargeResponse} UseSeriesChargeResponse instance
         */
        UseSeriesChargeResponse.create = function create(properties) {
            return new UseSeriesChargeResponse(properties);
        };

        /**
         * Encodes the specified UseSeriesChargeResponse message. Does not implicitly {@link mutation_series_charge.UseSeriesChargeResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @static
         * @param {mutation_series_charge.IUseSeriesChargeResponse} message UseSeriesChargeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UseSeriesChargeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesCharge != null && Object.hasOwnProperty.call(message, "seriesCharge"))
                $root.query_series_charge.SeriesChargeResponse.encode(message.seriesCharge, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UseSeriesChargeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_series_charge.UseSeriesChargeResponse} UseSeriesChargeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UseSeriesChargeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_series_charge.UseSeriesChargeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesCharge = $root.query_series_charge.SeriesChargeResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UseSeriesChargeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_series_charge.UseSeriesChargeResponse} UseSeriesChargeResponse
         */
        UseSeriesChargeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_series_charge.UseSeriesChargeResponse)
                return object;
            var message = new $root.mutation_series_charge.UseSeriesChargeResponse();
            if (object.seriesCharge != null) {
                if (typeof object.seriesCharge !== "object")
                    throw TypeError(".mutation_series_charge.UseSeriesChargeResponse.seriesCharge: object expected");
                message.seriesCharge = $root.query_series_charge.SeriesChargeResponse.fromObject(object.seriesCharge);
            }
            return message;
        };

        /**
         * Creates a plain object from a UseSeriesChargeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @static
         * @param {mutation_series_charge.UseSeriesChargeResponse} message UseSeriesChargeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UseSeriesChargeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seriesCharge = null;
            if (message.seriesCharge != null && message.hasOwnProperty("seriesCharge"))
                object.seriesCharge = $root.query_series_charge.SeriesChargeResponse.toObject(message.seriesCharge, options);
            return object;
        };

        /**
         * Converts this UseSeriesChargeResponse to JSON.
         * @function toJSON
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UseSeriesChargeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UseSeriesChargeResponse
         * @function getTypeUrl
         * @memberof mutation_series_charge.UseSeriesChargeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UseSeriesChargeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_series_charge.UseSeriesChargeResponse";
        };

        return UseSeriesChargeResponse;
    })();

    return mutation_series_charge;
})();

$root.query_series_charge = (function() {

    /**
     * Namespace query_series_charge.
     * @exports query_series_charge
     * @namespace
     */
    var query_series_charge = {};

    query_series_charge.SeriesChargeRequireField = (function() {

        /**
         * Properties of a SeriesChargeRequireField.
         * @memberof query_series_charge
         * @interface ISeriesChargeRequireField
         * @property {google.protobuf.IBoolValue|null} [requireIsAvailable] SeriesChargeRequireField requireIsAvailable
         * @property {google.protobuf.IBoolValue|null} [requireNextAvailableAt] SeriesChargeRequireField requireNextAvailableAt
         */

        /**
         * Constructs a new SeriesChargeRequireField.
         * @memberof query_series_charge
         * @classdesc Represents a SeriesChargeRequireField.
         * @implements ISeriesChargeRequireField
         * @constructor
         * @param {query_series_charge.ISeriesChargeRequireField=} [properties] Properties to set
         */
        function SeriesChargeRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesChargeRequireField requireIsAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsAvailable
         * @memberof query_series_charge.SeriesChargeRequireField
         * @instance
         */
        SeriesChargeRequireField.prototype.requireIsAvailable = null;

        /**
         * SeriesChargeRequireField requireNextAvailableAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireNextAvailableAt
         * @memberof query_series_charge.SeriesChargeRequireField
         * @instance
         */
        SeriesChargeRequireField.prototype.requireNextAvailableAt = null;

        /**
         * Creates a new SeriesChargeRequireField instance using the specified properties.
         * @function create
         * @memberof query_series_charge.SeriesChargeRequireField
         * @static
         * @param {query_series_charge.ISeriesChargeRequireField=} [properties] Properties to set
         * @returns {query_series_charge.SeriesChargeRequireField} SeriesChargeRequireField instance
         */
        SeriesChargeRequireField.create = function create(properties) {
            return new SeriesChargeRequireField(properties);
        };

        /**
         * Encodes the specified SeriesChargeRequireField message. Does not implicitly {@link query_series_charge.SeriesChargeRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_series_charge.SeriesChargeRequireField
         * @static
         * @param {query_series_charge.ISeriesChargeRequireField} message SeriesChargeRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesChargeRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireIsAvailable != null && Object.hasOwnProperty.call(message, "requireIsAvailable"))
                $root.google.protobuf.BoolValue.encode(message.requireIsAvailable, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireNextAvailableAt != null && Object.hasOwnProperty.call(message, "requireNextAvailableAt"))
                $root.google.protobuf.BoolValue.encode(message.requireNextAvailableAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesChargeRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_series_charge.SeriesChargeRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_series_charge.SeriesChargeRequireField} SeriesChargeRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesChargeRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_series_charge.SeriesChargeRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireIsAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireNextAvailableAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesChargeRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_series_charge.SeriesChargeRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_series_charge.SeriesChargeRequireField} SeriesChargeRequireField
         */
        SeriesChargeRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_series_charge.SeriesChargeRequireField)
                return object;
            var message = new $root.query_series_charge.SeriesChargeRequireField();
            if (object.requireIsAvailable != null) {
                if (typeof object.requireIsAvailable !== "object")
                    throw TypeError(".query_series_charge.SeriesChargeRequireField.requireIsAvailable: object expected");
                message.requireIsAvailable = $root.google.protobuf.BoolValue.fromObject(object.requireIsAvailable);
            }
            if (object.requireNextAvailableAt != null) {
                if (typeof object.requireNextAvailableAt !== "object")
                    throw TypeError(".query_series_charge.SeriesChargeRequireField.requireNextAvailableAt: object expected");
                message.requireNextAvailableAt = $root.google.protobuf.BoolValue.fromObject(object.requireNextAvailableAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesChargeRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_series_charge.SeriesChargeRequireField
         * @static
         * @param {query_series_charge.SeriesChargeRequireField} message SeriesChargeRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesChargeRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireIsAvailable = null;
                object.requireNextAvailableAt = null;
            }
            if (message.requireIsAvailable != null && message.hasOwnProperty("requireIsAvailable"))
                object.requireIsAvailable = $root.google.protobuf.BoolValue.toObject(message.requireIsAvailable, options);
            if (message.requireNextAvailableAt != null && message.hasOwnProperty("requireNextAvailableAt"))
                object.requireNextAvailableAt = $root.google.protobuf.BoolValue.toObject(message.requireNextAvailableAt, options);
            return object;
        };

        /**
         * Converts this SeriesChargeRequireField to JSON.
         * @function toJSON
         * @memberof query_series_charge.SeriesChargeRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesChargeRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesChargeRequireField
         * @function getTypeUrl
         * @memberof query_series_charge.SeriesChargeRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesChargeRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_series_charge.SeriesChargeRequireField";
        };

        return SeriesChargeRequireField;
    })();

    query_series_charge.SeriesChargeResponse = (function() {

        /**
         * Properties of a SeriesChargeResponse.
         * @memberof query_series_charge
         * @interface ISeriesChargeResponse
         * @property {google.protobuf.IBoolValue|null} [isAvailable] SeriesChargeResponse isAvailable
         * @property {google.protobuf.ITimestamp|null} [nextAvailableAt] SeriesChargeResponse nextAvailableAt
         */

        /**
         * Constructs a new SeriesChargeResponse.
         * @memberof query_series_charge
         * @classdesc Represents a SeriesChargeResponse.
         * @implements ISeriesChargeResponse
         * @constructor
         * @param {query_series_charge.ISeriesChargeResponse=} [properties] Properties to set
         */
        function SeriesChargeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesChargeResponse isAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} isAvailable
         * @memberof query_series_charge.SeriesChargeResponse
         * @instance
         */
        SeriesChargeResponse.prototype.isAvailable = null;

        /**
         * SeriesChargeResponse nextAvailableAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextAvailableAt
         * @memberof query_series_charge.SeriesChargeResponse
         * @instance
         */
        SeriesChargeResponse.prototype.nextAvailableAt = null;

        /**
         * Creates a new SeriesChargeResponse instance using the specified properties.
         * @function create
         * @memberof query_series_charge.SeriesChargeResponse
         * @static
         * @param {query_series_charge.ISeriesChargeResponse=} [properties] Properties to set
         * @returns {query_series_charge.SeriesChargeResponse} SeriesChargeResponse instance
         */
        SeriesChargeResponse.create = function create(properties) {
            return new SeriesChargeResponse(properties);
        };

        /**
         * Encodes the specified SeriesChargeResponse message. Does not implicitly {@link query_series_charge.SeriesChargeResponse.verify|verify} messages.
         * @function encode
         * @memberof query_series_charge.SeriesChargeResponse
         * @static
         * @param {query_series_charge.ISeriesChargeResponse} message SeriesChargeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesChargeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isAvailable != null && Object.hasOwnProperty.call(message, "isAvailable"))
                $root.google.protobuf.BoolValue.encode(message.isAvailable, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.nextAvailableAt != null && Object.hasOwnProperty.call(message, "nextAvailableAt"))
                $root.google.protobuf.Timestamp.encode(message.nextAvailableAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesChargeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_series_charge.SeriesChargeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_series_charge.SeriesChargeResponse} SeriesChargeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesChargeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_series_charge.SeriesChargeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.isAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.nextAvailableAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesChargeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_series_charge.SeriesChargeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_series_charge.SeriesChargeResponse} SeriesChargeResponse
         */
        SeriesChargeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_series_charge.SeriesChargeResponse)
                return object;
            var message = new $root.query_series_charge.SeriesChargeResponse();
            if (object.isAvailable != null) {
                if (typeof object.isAvailable !== "object")
                    throw TypeError(".query_series_charge.SeriesChargeResponse.isAvailable: object expected");
                message.isAvailable = $root.google.protobuf.BoolValue.fromObject(object.isAvailable);
            }
            if (object.nextAvailableAt != null) {
                if (typeof object.nextAvailableAt !== "object")
                    throw TypeError(".query_series_charge.SeriesChargeResponse.nextAvailableAt: object expected");
                message.nextAvailableAt = $root.google.protobuf.Timestamp.fromObject(object.nextAvailableAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesChargeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_series_charge.SeriesChargeResponse
         * @static
         * @param {query_series_charge.SeriesChargeResponse} message SeriesChargeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesChargeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.isAvailable = null;
                object.nextAvailableAt = null;
            }
            if (message.isAvailable != null && message.hasOwnProperty("isAvailable"))
                object.isAvailable = $root.google.protobuf.BoolValue.toObject(message.isAvailable, options);
            if (message.nextAvailableAt != null && message.hasOwnProperty("nextAvailableAt"))
                object.nextAvailableAt = $root.google.protobuf.Timestamp.toObject(message.nextAvailableAt, options);
            return object;
        };

        /**
         * Converts this SeriesChargeResponse to JSON.
         * @function toJSON
         * @memberof query_series_charge.SeriesChargeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesChargeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesChargeResponse
         * @function getTypeUrl
         * @memberof query_series_charge.SeriesChargeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesChargeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_series_charge.SeriesChargeResponse";
        };

        return SeriesChargeResponse;
    })();

    return query_series_charge;
})();

$root.mutation = (function() {

    /**
     * Namespace mutation.
     * @exports mutation
     * @namespace
     */
    var mutation = {};

    mutation.MutationService = (function() {

        /**
         * Constructs a new MutationService service.
         * @memberof mutation
         * @classdesc Represents a MutationService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MutationService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MutationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MutationService;

        /**
         * Creates new MutationService service using the specified rpc implementation.
         * @function create
         * @memberof mutation.MutationService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {MutationService} RPC service. Useful where requests and/or responses are streamed.
         */
        MutationService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link mutation.MutationService#gainBonusTicket}.
         * @memberof mutation.MutationService
         * @typedef GainBonusTicketCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_bonus_ticket.GainBonusTicketResponse} [response] GainBonusTicketResponse
         */

        /**
         * Calls GainBonusTicket.
         * @function gainBonusTicket
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_bonus_ticket.IGainBonusTicketRequest} request GainBonusTicketRequest message or plain object
         * @param {mutation.MutationService.GainBonusTicketCallback} callback Node-style callback called with the error, if any, and GainBonusTicketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.gainBonusTicket = function gainBonusTicket(request, callback) {
            return this.rpcCall(gainBonusTicket, $root.mutation_bonus_ticket.GainBonusTicketRequest, $root.mutation_bonus_ticket.GainBonusTicketResponse, request, callback);
        }, "name", { value: "GainBonusTicket" });

        /**
         * Calls GainBonusTicket.
         * @function gainBonusTicket
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_bonus_ticket.IGainBonusTicketRequest} request GainBonusTicketRequest message or plain object
         * @returns {Promise<mutation_bonus_ticket.GainBonusTicketResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#useTicket}.
         * @memberof mutation.MutationService
         * @typedef UseTicketCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_ticket.UseTicketResponse} [response] UseTicketResponse
         */

        /**
         * Calls UseTicket.
         * @function useTicket
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_ticket.IUseTicketRequest} request UseTicketRequest message or plain object
         * @param {mutation.MutationService.UseTicketCallback} callback Node-style callback called with the error, if any, and UseTicketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.useTicket = function useTicket(request, callback) {
            return this.rpcCall(useTicket, $root.mutation_ticket.UseTicketRequest, $root.mutation_ticket.UseTicketResponse, request, callback);
        }, "name", { value: "UseTicket" });

        /**
         * Calls UseTicket.
         * @function useTicket
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_ticket.IUseTicketRequest} request UseTicketRequest message or plain object
         * @returns {Promise<mutation_ticket.UseTicketResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#useSeriesCharge}.
         * @memberof mutation.MutationService
         * @typedef UseSeriesChargeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_series_charge.UseSeriesChargeResponse} [response] UseSeriesChargeResponse
         */

        /**
         * Calls UseSeriesCharge.
         * @function useSeriesCharge
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series_charge.IUseSeriesChargeRequest} request UseSeriesChargeRequest message or plain object
         * @param {mutation.MutationService.UseSeriesChargeCallback} callback Node-style callback called with the error, if any, and UseSeriesChargeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.useSeriesCharge = function useSeriesCharge(request, callback) {
            return this.rpcCall(useSeriesCharge, $root.mutation_series_charge.UseSeriesChargeRequest, $root.mutation_series_charge.UseSeriesChargeResponse, request, callback);
        }, "name", { value: "UseSeriesCharge" });

        /**
         * Calls UseSeriesCharge.
         * @function useSeriesCharge
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series_charge.IUseSeriesChargeRequest} request UseSeriesChargeRequest message or plain object
         * @returns {Promise<mutation_series_charge.UseSeriesChargeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#acquireFullStory}.
         * @memberof mutation.MutationService
         * @typedef AcquireFullStoryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_story_license.AcquireFullStoryResponse} [response] AcquireFullStoryResponse
         */

        /**
         * Calls AcquireFullStory.
         * @function acquireFullStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story_license.IAcquireFullStoryRequest} request AcquireFullStoryRequest message or plain object
         * @param {mutation.MutationService.AcquireFullStoryCallback} callback Node-style callback called with the error, if any, and AcquireFullStoryResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.acquireFullStory = function acquireFullStory(request, callback) {
            return this.rpcCall(acquireFullStory, $root.mutation_story_license.AcquireFullStoryRequest, $root.mutation_story_license.AcquireFullStoryResponse, request, callback);
        }, "name", { value: "AcquireFullStory" });

        /**
         * Calls AcquireFullStory.
         * @function acquireFullStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story_license.IAcquireFullStoryRequest} request AcquireFullStoryRequest message or plain object
         * @returns {Promise<mutation_story_license.AcquireFullStoryResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#updateProfiling}.
         * @memberof mutation.MutationService
         * @typedef UpdateProfilingCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateProfiling.
         * @function updateProfiling
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_profiling.IUpdateProfilingRequest} request UpdateProfilingRequest message or plain object
         * @param {mutation.MutationService.UpdateProfilingCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.updateProfiling = function updateProfiling(request, callback) {
            return this.rpcCall(updateProfiling, $root.mutation_profiling.UpdateProfilingRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateProfiling" });

        /**
         * Calls UpdateProfiling.
         * @function updateProfiling
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_profiling.IUpdateProfilingRequest} request UpdateProfilingRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#updateUserPreferenceSensitive}.
         * @memberof mutation.MutationService
         * @typedef UpdateUserPreferenceSensitiveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateUserPreferenceSensitive.
         * @function updateUserPreferenceSensitive
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_profiling.IUpdateUserPreferenceSensitiveRequest} request UpdateUserPreferenceSensitiveRequest message or plain object
         * @param {mutation.MutationService.UpdateUserPreferenceSensitiveCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.updateUserPreferenceSensitive = function updateUserPreferenceSensitive(request, callback) {
            return this.rpcCall(updateUserPreferenceSensitive, $root.mutation_profiling.UpdateUserPreferenceSensitiveRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateUserPreferenceSensitive" });

        /**
         * Calls UpdateUserPreferenceSensitive.
         * @function updateUserPreferenceSensitive
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_profiling.IUpdateUserPreferenceSensitiveRequest} request UpdateUserPreferenceSensitiveRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#updateNotOfficialFollowingTag}.
         * @memberof mutation.MutationService
         * @typedef UpdateNotOfficialFollowingTagCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateNotOfficialFollowingTag.
         * @function updateNotOfficialFollowingTag
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_following_tag.IUpdateNotOfficialFollowingTagRequest} request UpdateNotOfficialFollowingTagRequest message or plain object
         * @param {mutation.MutationService.UpdateNotOfficialFollowingTagCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.updateNotOfficialFollowingTag = function updateNotOfficialFollowingTag(request, callback) {
            return this.rpcCall(updateNotOfficialFollowingTag, $root.mutation_following_tag.UpdateNotOfficialFollowingTagRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateNotOfficialFollowingTag" });

        /**
         * Calls UpdateNotOfficialFollowingTag.
         * @function updateNotOfficialFollowingTag
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_following_tag.IUpdateNotOfficialFollowingTagRequest} request UpdateNotOfficialFollowingTagRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#createStory}.
         * @memberof mutation.MutationService
         * @typedef CreateStoryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_story.CreateStoryResponse} [response] CreateStoryResponse
         */

        /**
         * Calls CreateStory.
         * @function createStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story.ICreateStoryRequest} request CreateStoryRequest message or plain object
         * @param {mutation.MutationService.CreateStoryCallback} callback Node-style callback called with the error, if any, and CreateStoryResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.createStory = function createStory(request, callback) {
            return this.rpcCall(createStory, $root.mutation_story.CreateStoryRequest, $root.mutation_story.CreateStoryResponse, request, callback);
        }, "name", { value: "CreateStory" });

        /**
         * Calls CreateStory.
         * @function createStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story.ICreateStoryRequest} request CreateStoryRequest message or plain object
         * @returns {Promise<mutation_story.CreateStoryResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#updateStory}.
         * @memberof mutation.MutationService
         * @typedef UpdateStoryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_story.UpdateStoryResponse} [response] UpdateStoryResponse
         */

        /**
         * Calls UpdateStory.
         * @function updateStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story.IUpdateStoryRequest} request UpdateStoryRequest message or plain object
         * @param {mutation.MutationService.UpdateStoryCallback} callback Node-style callback called with the error, if any, and UpdateStoryResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.updateStory = function updateStory(request, callback) {
            return this.rpcCall(updateStory, $root.mutation_story.UpdateStoryRequest, $root.mutation_story.UpdateStoryResponse, request, callback);
        }, "name", { value: "UpdateStory" });

        /**
         * Calls UpdateStory.
         * @function updateStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story.IUpdateStoryRequest} request UpdateStoryRequest message or plain object
         * @returns {Promise<mutation_story.UpdateStoryResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#deleteStory}.
         * @memberof mutation.MutationService
         * @typedef DeleteStoryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteStory.
         * @function deleteStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story.IDeleteStoryRequest} request DeleteStoryRequest message or plain object
         * @param {mutation.MutationService.DeleteStoryCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.deleteStory = function deleteStory(request, callback) {
            return this.rpcCall(deleteStory, $root.mutation_story.DeleteStoryRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteStory" });

        /**
         * Calls DeleteStory.
         * @function deleteStory
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story.IDeleteStoryRequest} request DeleteStoryRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#createSeries}.
         * @memberof mutation.MutationService
         * @typedef CreateSeriesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_series.CreateSeriesResponse} [response] CreateSeriesResponse
         */

        /**
         * Calls CreateSeries.
         * @function createSeries
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.ICreateSeriesRequest} request CreateSeriesRequest message or plain object
         * @param {mutation.MutationService.CreateSeriesCallback} callback Node-style callback called with the error, if any, and CreateSeriesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.createSeries = function createSeries(request, callback) {
            return this.rpcCall(createSeries, $root.mutation_series.CreateSeriesRequest, $root.mutation_series.CreateSeriesResponse, request, callback);
        }, "name", { value: "CreateSeries" });

        /**
         * Calls CreateSeries.
         * @function createSeries
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.ICreateSeriesRequest} request CreateSeriesRequest message or plain object
         * @returns {Promise<mutation_series.CreateSeriesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#updateSeries}.
         * @memberof mutation.MutationService
         * @typedef UpdateSeriesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_series.UpdateSeriesResponse} [response] UpdateSeriesResponse
         */

        /**
         * Calls UpdateSeries.
         * @function updateSeries
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.IUpdateSeriesRequest} request UpdateSeriesRequest message or plain object
         * @param {mutation.MutationService.UpdateSeriesCallback} callback Node-style callback called with the error, if any, and UpdateSeriesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.updateSeries = function updateSeries(request, callback) {
            return this.rpcCall(updateSeries, $root.mutation_series.UpdateSeriesRequest, $root.mutation_series.UpdateSeriesResponse, request, callback);
        }, "name", { value: "UpdateSeries" });

        /**
         * Calls UpdateSeries.
         * @function updateSeries
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.IUpdateSeriesRequest} request UpdateSeriesRequest message or plain object
         * @returns {Promise<mutation_series.UpdateSeriesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#deleteSeries}.
         * @memberof mutation.MutationService
         * @typedef DeleteSeriesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteSeries.
         * @function deleteSeries
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.IDeleteSeriesRequest} request DeleteSeriesRequest message or plain object
         * @param {mutation.MutationService.DeleteSeriesCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.deleteSeries = function deleteSeries(request, callback) {
            return this.rpcCall(deleteSeries, $root.mutation_series.DeleteSeriesRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteSeries" });

        /**
         * Calls DeleteSeries.
         * @function deleteSeries
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.IDeleteSeriesRequest} request DeleteSeriesRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#updateSeriesStoryOrder}.
         * @memberof mutation.MutationService
         * @typedef UpdateSeriesStoryOrderCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateSeriesStoryOrder.
         * @function updateSeriesStoryOrder
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.IUpdateSeriesStoryOrderRequest} request UpdateSeriesStoryOrderRequest message or plain object
         * @param {mutation.MutationService.UpdateSeriesStoryOrderCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.updateSeriesStoryOrder = function updateSeriesStoryOrder(request, callback) {
            return this.rpcCall(updateSeriesStoryOrder, $root.mutation_series.UpdateSeriesStoryOrderRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateSeriesStoryOrder" });

        /**
         * Calls UpdateSeriesStoryOrder.
         * @function updateSeriesStoryOrder
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_series.IUpdateSeriesStoryOrderRequest} request UpdateSeriesStoryOrderRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#createStoryVideoAsync}.
         * @memberof mutation.MutationService
         * @typedef CreateStoryVideoAsyncCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_story_video.CreateStoryVideoAsyncResponse} [response] CreateStoryVideoAsyncResponse
         */

        /**
         * Calls CreateStoryVideoAsync.
         * @function createStoryVideoAsync
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story_video.ICreateStoryVideoAsyncRequest} request CreateStoryVideoAsyncRequest message or plain object
         * @param {mutation.MutationService.CreateStoryVideoAsyncCallback} callback Node-style callback called with the error, if any, and CreateStoryVideoAsyncResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.createStoryVideoAsync = function createStoryVideoAsync(request, callback) {
            return this.rpcCall(createStoryVideoAsync, $root.mutation_story_video.CreateStoryVideoAsyncRequest, $root.mutation_story_video.CreateStoryVideoAsyncResponse, request, callback);
        }, "name", { value: "CreateStoryVideoAsync" });

        /**
         * Calls CreateStoryVideoAsync.
         * @function createStoryVideoAsync
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_story_video.ICreateStoryVideoAsyncRequest} request CreateStoryVideoAsyncRequest message or plain object
         * @returns {Promise<mutation_story_video.CreateStoryVideoAsyncResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#createImage}.
         * @memberof mutation.MutationService
         * @typedef CreateImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_resource.CreateImageResponse} [response] CreateImageResponse
         */

        /**
         * Calls CreateImage.
         * @function createImage
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_resource.ICreateImageRequest} request CreateImageRequest message or plain object
         * @param {mutation.MutationService.CreateImageCallback} callback Node-style callback called with the error, if any, and CreateImageResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.createImage = function createImage(request, callback) {
            return this.rpcCall(createImage, $root.mutation_resource.CreateImageRequest, $root.mutation_resource.CreateImageResponse, request, callback);
        }, "name", { value: "CreateImage" });

        /**
         * Calls CreateImage.
         * @function createImage
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_resource.ICreateImageRequest} request CreateImageRequest message or plain object
         * @returns {Promise<mutation_resource.CreateImageResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#deleteImage}.
         * @memberof mutation.MutationService
         * @typedef DeleteImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteImage.
         * @function deleteImage
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_resource.IDeleteImageRequest} request DeleteImageRequest message or plain object
         * @param {mutation.MutationService.DeleteImageCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.deleteImage = function deleteImage(request, callback) {
            return this.rpcCall(deleteImage, $root.mutation_resource.DeleteImageRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteImage" });

        /**
         * Calls DeleteImage.
         * @function deleteImage
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_resource.IDeleteImageRequest} request DeleteImageRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#hideImage}.
         * @memberof mutation.MutationService
         * @typedef HideImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls HideImage.
         * @function hideImage
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_resource.IHideImageRequest} request HideImageRequest message or plain object
         * @param {mutation.MutationService.HideImageCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.hideImage = function hideImage(request, callback) {
            return this.rpcCall(hideImage, $root.mutation_resource.HideImageRequest, $root.types.Empty, request, callback);
        }, "name", { value: "HideImage" });

        /**
         * Calls HideImage.
         * @function hideImage
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_resource.IHideImageRequest} request HideImageRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#deleteStoryReadingProgress}.
         * @memberof mutation.MutationService
         * @typedef DeleteStoryReadingProgressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteStoryReadingProgress.
         * @function deleteStoryReadingProgress
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_reading_progress.IDeleteStoryReadingProgressRequest} request DeleteStoryReadingProgressRequest message or plain object
         * @param {mutation.MutationService.DeleteStoryReadingProgressCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.deleteStoryReadingProgress = function deleteStoryReadingProgress(request, callback) {
            return this.rpcCall(deleteStoryReadingProgress, $root.mutation_reading_progress.DeleteStoryReadingProgressRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteStoryReadingProgress" });

        /**
         * Calls DeleteStoryReadingProgress.
         * @function deleteStoryReadingProgress
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_reading_progress.IDeleteStoryReadingProgressRequest} request DeleteStoryReadingProgressRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#deleteSeriesReadingProgress}.
         * @memberof mutation.MutationService
         * @typedef DeleteSeriesReadingProgressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteSeriesReadingProgress.
         * @function deleteSeriesReadingProgress
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_reading_progress.IDeleteSeriesReadingProgressRequest} request DeleteSeriesReadingProgressRequest message or plain object
         * @param {mutation.MutationService.DeleteSeriesReadingProgressCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.deleteSeriesReadingProgress = function deleteSeriesReadingProgress(request, callback) {
            return this.rpcCall(deleteSeriesReadingProgress, $root.mutation_reading_progress.DeleteSeriesReadingProgressRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteSeriesReadingProgress" });

        /**
         * Calls DeleteSeriesReadingProgress.
         * @function deleteSeriesReadingProgress
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_reading_progress.IDeleteSeriesReadingProgressRequest} request DeleteSeriesReadingProgressRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#validateComment}.
         * @memberof mutation.MutationService
         * @typedef ValidateCommentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_comment.ValidateCommentResponse} [response] ValidateCommentResponse
         */

        /**
         * Calls ValidateComment.
         * @function validateComment
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_comment.IValidateCommentRequest} request ValidateCommentRequest message or plain object
         * @param {mutation.MutationService.ValidateCommentCallback} callback Node-style callback called with the error, if any, and ValidateCommentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.validateComment = function validateComment(request, callback) {
            return this.rpcCall(validateComment, $root.mutation_comment.ValidateCommentRequest, $root.mutation_comment.ValidateCommentResponse, request, callback);
        }, "name", { value: "ValidateComment" });

        /**
         * Calls ValidateComment.
         * @function validateComment
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_comment.IValidateCommentRequest} request ValidateCommentRequest message or plain object
         * @returns {Promise<mutation_comment.ValidateCommentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#reportStoryViolation}.
         * @memberof mutation.MutationService
         * @typedef ReportStoryViolationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls ReportStoryViolation.
         * @function reportStoryViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportStoryViolationRequest} request ReportStoryViolationRequest message or plain object
         * @param {mutation.MutationService.ReportStoryViolationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.reportStoryViolation = function reportStoryViolation(request, callback) {
            return this.rpcCall(reportStoryViolation, $root.mutation_inspection.ReportStoryViolationRequest, $root.types.Empty, request, callback);
        }, "name", { value: "ReportStoryViolation" });

        /**
         * Calls ReportStoryViolation.
         * @function reportStoryViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportStoryViolationRequest} request ReportStoryViolationRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#reportStoryRightViolation}.
         * @memberof mutation.MutationService
         * @typedef ReportStoryRightViolationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls ReportStoryRightViolation.
         * @function reportStoryRightViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportStoryRightViolationRequest} request ReportStoryRightViolationRequest message or plain object
         * @param {mutation.MutationService.ReportStoryRightViolationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.reportStoryRightViolation = function reportStoryRightViolation(request, callback) {
            return this.rpcCall(reportStoryRightViolation, $root.mutation_inspection.ReportStoryRightViolationRequest, $root.types.Empty, request, callback);
        }, "name", { value: "ReportStoryRightViolation" });

        /**
         * Calls ReportStoryRightViolation.
         * @function reportStoryRightViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportStoryRightViolationRequest} request ReportStoryRightViolationRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#reportCommentViolation}.
         * @memberof mutation.MutationService
         * @typedef ReportCommentViolationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls ReportCommentViolation.
         * @function reportCommentViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportCommentViolationRequest} request ReportCommentViolationRequest message or plain object
         * @param {mutation.MutationService.ReportCommentViolationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.reportCommentViolation = function reportCommentViolation(request, callback) {
            return this.rpcCall(reportCommentViolation, $root.mutation_inspection.ReportCommentViolationRequest, $root.types.Empty, request, callback);
        }, "name", { value: "ReportCommentViolation" });

        /**
         * Calls ReportCommentViolation.
         * @function reportCommentViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportCommentViolationRequest} request ReportCommentViolationRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#reportUserViolation}.
         * @memberof mutation.MutationService
         * @typedef ReportUserViolationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls ReportUserViolation.
         * @function reportUserViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportUserViolationRequest} request ReportUserViolationRequest message or plain object
         * @param {mutation.MutationService.ReportUserViolationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.reportUserViolation = function reportUserViolation(request, callback) {
            return this.rpcCall(reportUserViolation, $root.mutation_inspection.ReportUserViolationRequest, $root.types.Empty, request, callback);
        }, "name", { value: "ReportUserViolation" });

        /**
         * Calls ReportUserViolation.
         * @function reportUserViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportUserViolationRequest} request ReportUserViolationRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#reportSeriesRightViolation}.
         * @memberof mutation.MutationService
         * @typedef ReportSeriesRightViolationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls ReportSeriesRightViolation.
         * @function reportSeriesRightViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportSeriesRightViolationRequest} request ReportSeriesRightViolationRequest message or plain object
         * @param {mutation.MutationService.ReportSeriesRightViolationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.reportSeriesRightViolation = function reportSeriesRightViolation(request, callback) {
            return this.rpcCall(reportSeriesRightViolation, $root.mutation_inspection.ReportSeriesRightViolationRequest, $root.types.Empty, request, callback);
        }, "name", { value: "ReportSeriesRightViolation" });

        /**
         * Calls ReportSeriesRightViolation.
         * @function reportSeriesRightViolation
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IReportSeriesRightViolationRequest} request ReportSeriesRightViolationRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#acceptGuideline}.
         * @memberof mutation.MutationService
         * @typedef AcceptGuidelineCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls AcceptGuideline.
         * @function acceptGuideline
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IAcceptGuidelineRequest} request AcceptGuidelineRequest message or plain object
         * @param {mutation.MutationService.AcceptGuidelineCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.acceptGuideline = function acceptGuideline(request, callback) {
            return this.rpcCall(acceptGuideline, $root.mutation_inspection.AcceptGuidelineRequest, $root.types.Empty, request, callback);
        }, "name", { value: "AcceptGuideline" });

        /**
         * Calls AcceptGuideline.
         * @function acceptGuideline
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_inspection.IAcceptGuidelineRequest} request AcceptGuidelineRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#deleteMe}.
         * @memberof mutation.MutationService
         * @typedef DeleteMeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteMe.
         * @function deleteMe
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_user.IDeleteMeRequest} request DeleteMeRequest message or plain object
         * @param {mutation.MutationService.DeleteMeCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.deleteMe = function deleteMe(request, callback) {
            return this.rpcCall(deleteMe, $root.mutation_user.DeleteMeRequest, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteMe" });

        /**
         * Calls DeleteMe.
         * @function deleteMe
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_user.IDeleteMeRequest} request DeleteMeRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#updateAppleAuthAuthorizationCode}.
         * @memberof mutation.MutationService
         * @typedef UpdateAppleAuthAuthorizationCodeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UpdateAppleAuthAuthorizationCode.
         * @function updateAppleAuthAuthorizationCode
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_apple_auth.IUpdateAppleAuthAuthorizationCodeRequest} request UpdateAppleAuthAuthorizationCodeRequest message or plain object
         * @param {mutation.MutationService.UpdateAppleAuthAuthorizationCodeCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.updateAppleAuthAuthorizationCode = function updateAppleAuthAuthorizationCode(request, callback) {
            return this.rpcCall(updateAppleAuthAuthorizationCode, $root.mutation_apple_auth.UpdateAppleAuthAuthorizationCodeRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UpdateAppleAuthAuthorizationCode" });

        /**
         * Calls UpdateAppleAuthAuthorizationCode.
         * @function updateAppleAuthAuthorizationCode
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_apple_auth.IUpdateAppleAuthAuthorizationCodeRequest} request UpdateAppleAuthAuthorizationCodeRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#followUser}.
         * @memberof mutation.MutationService
         * @typedef FollowUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls FollowUser.
         * @function followUser
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_follow_user.IFollowUserRequest} request FollowUserRequest message or plain object
         * @param {mutation.MutationService.FollowUserCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.followUser = function followUser(request, callback) {
            return this.rpcCall(followUser, $root.mutation_follow_user.FollowUserRequest, $root.types.Empty, request, callback);
        }, "name", { value: "FollowUser" });

        /**
         * Calls FollowUser.
         * @function followUser
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_follow_user.IFollowUserRequest} request FollowUserRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#unfollowUser}.
         * @memberof mutation.MutationService
         * @typedef UnfollowUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls UnfollowUser.
         * @function unfollowUser
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_follow_user.IUnfollowUserRequest} request UnfollowUserRequest message or plain object
         * @param {mutation.MutationService.UnfollowUserCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.unfollowUser = function unfollowUser(request, callback) {
            return this.rpcCall(unfollowUser, $root.mutation_follow_user.UnfollowUserRequest, $root.types.Empty, request, callback);
        }, "name", { value: "UnfollowUser" });

        /**
         * Calls UnfollowUser.
         * @function unfollowUser
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_follow_user.IUnfollowUserRequest} request UnfollowUserRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link mutation.MutationService#authorizeDevice}.
         * @memberof mutation.MutationService
         * @typedef AuthorizeDeviceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {mutation_authorize_device.AuthorizeDeviceResponse} [response] AuthorizeDeviceResponse
         */

        /**
         * Calls AuthorizeDevice.
         * @function authorizeDevice
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_authorize_device.IAuthorizeDeviceRequest} request AuthorizeDeviceRequest message or plain object
         * @param {mutation.MutationService.AuthorizeDeviceCallback} callback Node-style callback called with the error, if any, and AuthorizeDeviceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MutationService.prototype.authorizeDevice = function authorizeDevice(request, callback) {
            return this.rpcCall(authorizeDevice, $root.mutation_authorize_device.AuthorizeDeviceRequest, $root.mutation_authorize_device.AuthorizeDeviceResponse, request, callback);
        }, "name", { value: "AuthorizeDevice" });

        /**
         * Calls AuthorizeDevice.
         * @function authorizeDevice
         * @memberof mutation.MutationService
         * @instance
         * @param {mutation_authorize_device.IAuthorizeDeviceRequest} request AuthorizeDeviceRequest message or plain object
         * @returns {Promise<mutation_authorize_device.AuthorizeDeviceResponse>} Promise
         * @variation 2
         */

        return MutationService;
    })();

    return mutation;
})();

$root.mutation_bonus_ticket = (function() {

    /**
     * Namespace mutation_bonus_ticket.
     * @exports mutation_bonus_ticket
     * @namespace
     */
    var mutation_bonus_ticket = {};

    mutation_bonus_ticket.GainBonusTicketRequest = (function() {

        /**
         * Properties of a GainBonusTicketRequest.
         * @memberof mutation_bonus_ticket
         * @interface IGainBonusTicketRequest
         * @property {mutation_bonus_ticket.GainBonusTicketRequest.BonusTicketType|null} [bonusTicketType] GainBonusTicketRequest bonusTicketType
         */

        /**
         * Constructs a new GainBonusTicketRequest.
         * @memberof mutation_bonus_ticket
         * @classdesc Represents a GainBonusTicketRequest.
         * @implements IGainBonusTicketRequest
         * @constructor
         * @param {mutation_bonus_ticket.IGainBonusTicketRequest=} [properties] Properties to set
         */
        function GainBonusTicketRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GainBonusTicketRequest bonusTicketType.
         * @member {mutation_bonus_ticket.GainBonusTicketRequest.BonusTicketType} bonusTicketType
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @instance
         */
        GainBonusTicketRequest.prototype.bonusTicketType = 0;

        /**
         * Creates a new GainBonusTicketRequest instance using the specified properties.
         * @function create
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @static
         * @param {mutation_bonus_ticket.IGainBonusTicketRequest=} [properties] Properties to set
         * @returns {mutation_bonus_ticket.GainBonusTicketRequest} GainBonusTicketRequest instance
         */
        GainBonusTicketRequest.create = function create(properties) {
            return new GainBonusTicketRequest(properties);
        };

        /**
         * Encodes the specified GainBonusTicketRequest message. Does not implicitly {@link mutation_bonus_ticket.GainBonusTicketRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @static
         * @param {mutation_bonus_ticket.IGainBonusTicketRequest} message GainBonusTicketRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GainBonusTicketRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bonusTicketType != null && Object.hasOwnProperty.call(message, "bonusTicketType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.bonusTicketType);
            return writer;
        };

        /**
         * Decodes a GainBonusTicketRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_bonus_ticket.GainBonusTicketRequest} GainBonusTicketRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GainBonusTicketRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_bonus_ticket.GainBonusTicketRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.bonusTicketType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a GainBonusTicketRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_bonus_ticket.GainBonusTicketRequest} GainBonusTicketRequest
         */
        GainBonusTicketRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_bonus_ticket.GainBonusTicketRequest)
                return object;
            var message = new $root.mutation_bonus_ticket.GainBonusTicketRequest();
            switch (object.bonusTicketType) {
            case "BONUS_TICKET_TYPE_UNSPECIFIED":
            case 0:
                message.bonusTicketType = 0;
                break;
            case "BONUS_TICKET_SHARING":
            case 1:
                message.bonusTicketType = 1;
                break;
            case "BONUS_TICKET_ADVERTISEMENT":
            case 2:
                message.bonusTicketType = 2;
                break;
            case "BONUS_TICKET_LOGIN":
            case 3:
                message.bonusTicketType = 3;
                break;
            case "BONUS_TICKET_REVIEW":
            case 4:
                message.bonusTicketType = 4;
                break;
            case "BONUS_TICKET_TIME_LIMITED":
            case 5:
                message.bonusTicketType = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GainBonusTicketRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @static
         * @param {mutation_bonus_ticket.GainBonusTicketRequest} message GainBonusTicketRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GainBonusTicketRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.bonusTicketType = options.enums === String ? "BONUS_TICKET_TYPE_UNSPECIFIED" : 0;
            if (message.bonusTicketType != null && message.hasOwnProperty("bonusTicketType"))
                object.bonusTicketType = options.enums === String ? $root.mutation_bonus_ticket.GainBonusTicketRequest.BonusTicketType[message.bonusTicketType] : message.bonusTicketType;
            return object;
        };

        /**
         * Converts this GainBonusTicketRequest to JSON.
         * @function toJSON
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GainBonusTicketRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GainBonusTicketRequest
         * @function getTypeUrl
         * @memberof mutation_bonus_ticket.GainBonusTicketRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GainBonusTicketRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_bonus_ticket.GainBonusTicketRequest";
        };

        /**
         * BonusTicketType enum.
         * @name mutation_bonus_ticket.GainBonusTicketRequest.BonusTicketType
         * @enum {number}
         * @property {number} BONUS_TICKET_TYPE_UNSPECIFIED=0 BONUS_TICKET_TYPE_UNSPECIFIED value
         * @property {number} BONUS_TICKET_SHARING=1 BONUS_TICKET_SHARING value
         * @property {number} BONUS_TICKET_ADVERTISEMENT=2 BONUS_TICKET_ADVERTISEMENT value
         * @property {number} BONUS_TICKET_LOGIN=3 BONUS_TICKET_LOGIN value
         * @property {number} BONUS_TICKET_REVIEW=4 BONUS_TICKET_REVIEW value
         * @property {number} BONUS_TICKET_TIME_LIMITED=5 BONUS_TICKET_TIME_LIMITED value
         */
        GainBonusTicketRequest.BonusTicketType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BONUS_TICKET_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "BONUS_TICKET_SHARING"] = 1;
            values[valuesById[2] = "BONUS_TICKET_ADVERTISEMENT"] = 2;
            values[valuesById[3] = "BONUS_TICKET_LOGIN"] = 3;
            values[valuesById[4] = "BONUS_TICKET_REVIEW"] = 4;
            values[valuesById[5] = "BONUS_TICKET_TIME_LIMITED"] = 5;
            return values;
        })();

        return GainBonusTicketRequest;
    })();

    mutation_bonus_ticket.GainBonusTicketResponse = (function() {

        /**
         * Properties of a GainBonusTicketResponse.
         * @memberof mutation_bonus_ticket
         * @interface IGainBonusTicketResponse
         * @property {google.protobuf.IInt32Value|null} [gainedAmount] GainBonusTicketResponse gainedAmount
         */

        /**
         * Constructs a new GainBonusTicketResponse.
         * @memberof mutation_bonus_ticket
         * @classdesc Represents a GainBonusTicketResponse.
         * @implements IGainBonusTicketResponse
         * @constructor
         * @param {mutation_bonus_ticket.IGainBonusTicketResponse=} [properties] Properties to set
         */
        function GainBonusTicketResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GainBonusTicketResponse gainedAmount.
         * @member {google.protobuf.IInt32Value|null|undefined} gainedAmount
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @instance
         */
        GainBonusTicketResponse.prototype.gainedAmount = null;

        /**
         * Creates a new GainBonusTicketResponse instance using the specified properties.
         * @function create
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @static
         * @param {mutation_bonus_ticket.IGainBonusTicketResponse=} [properties] Properties to set
         * @returns {mutation_bonus_ticket.GainBonusTicketResponse} GainBonusTicketResponse instance
         */
        GainBonusTicketResponse.create = function create(properties) {
            return new GainBonusTicketResponse(properties);
        };

        /**
         * Encodes the specified GainBonusTicketResponse message. Does not implicitly {@link mutation_bonus_ticket.GainBonusTicketResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @static
         * @param {mutation_bonus_ticket.IGainBonusTicketResponse} message GainBonusTicketResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GainBonusTicketResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gainedAmount != null && Object.hasOwnProperty.call(message, "gainedAmount"))
                $root.google.protobuf.Int32Value.encode(message.gainedAmount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GainBonusTicketResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_bonus_ticket.GainBonusTicketResponse} GainBonusTicketResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GainBonusTicketResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_bonus_ticket.GainBonusTicketResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gainedAmount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a GainBonusTicketResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_bonus_ticket.GainBonusTicketResponse} GainBonusTicketResponse
         */
        GainBonusTicketResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_bonus_ticket.GainBonusTicketResponse)
                return object;
            var message = new $root.mutation_bonus_ticket.GainBonusTicketResponse();
            if (object.gainedAmount != null) {
                if (typeof object.gainedAmount !== "object")
                    throw TypeError(".mutation_bonus_ticket.GainBonusTicketResponse.gainedAmount: object expected");
                message.gainedAmount = $root.google.protobuf.Int32Value.fromObject(object.gainedAmount);
            }
            return message;
        };

        /**
         * Creates a plain object from a GainBonusTicketResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @static
         * @param {mutation_bonus_ticket.GainBonusTicketResponse} message GainBonusTicketResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GainBonusTicketResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.gainedAmount = null;
            if (message.gainedAmount != null && message.hasOwnProperty("gainedAmount"))
                object.gainedAmount = $root.google.protobuf.Int32Value.toObject(message.gainedAmount, options);
            return object;
        };

        /**
         * Converts this GainBonusTicketResponse to JSON.
         * @function toJSON
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GainBonusTicketResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GainBonusTicketResponse
         * @function getTypeUrl
         * @memberof mutation_bonus_ticket.GainBonusTicketResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GainBonusTicketResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_bonus_ticket.GainBonusTicketResponse";
        };

        return GainBonusTicketResponse;
    })();

    return mutation_bonus_ticket;
})();

$root.mutation_comment = (function() {

    /**
     * Namespace mutation_comment.
     * @exports mutation_comment
     * @namespace
     */
    var mutation_comment = {};

    mutation_comment.ValidateCommentRequest = (function() {

        /**
         * Properties of a ValidateCommentRequest.
         * @memberof mutation_comment
         * @interface IValidateCommentRequest
         * @property {google.protobuf.IStringValue|null} [body] ValidateCommentRequest body
         */

        /**
         * Constructs a new ValidateCommentRequest.
         * @memberof mutation_comment
         * @classdesc Represents a ValidateCommentRequest.
         * @implements IValidateCommentRequest
         * @constructor
         * @param {mutation_comment.IValidateCommentRequest=} [properties] Properties to set
         */
        function ValidateCommentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidateCommentRequest body.
         * @member {google.protobuf.IStringValue|null|undefined} body
         * @memberof mutation_comment.ValidateCommentRequest
         * @instance
         */
        ValidateCommentRequest.prototype.body = null;

        /**
         * Creates a new ValidateCommentRequest instance using the specified properties.
         * @function create
         * @memberof mutation_comment.ValidateCommentRequest
         * @static
         * @param {mutation_comment.IValidateCommentRequest=} [properties] Properties to set
         * @returns {mutation_comment.ValidateCommentRequest} ValidateCommentRequest instance
         */
        ValidateCommentRequest.create = function create(properties) {
            return new ValidateCommentRequest(properties);
        };

        /**
         * Encodes the specified ValidateCommentRequest message. Does not implicitly {@link mutation_comment.ValidateCommentRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_comment.ValidateCommentRequest
         * @static
         * @param {mutation_comment.IValidateCommentRequest} message ValidateCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateCommentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                $root.google.protobuf.StringValue.encode(message.body, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ValidateCommentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_comment.ValidateCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_comment.ValidateCommentRequest} ValidateCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateCommentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_comment.ValidateCommentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.body = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ValidateCommentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_comment.ValidateCommentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_comment.ValidateCommentRequest} ValidateCommentRequest
         */
        ValidateCommentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_comment.ValidateCommentRequest)
                return object;
            var message = new $root.mutation_comment.ValidateCommentRequest();
            if (object.body != null) {
                if (typeof object.body !== "object")
                    throw TypeError(".mutation_comment.ValidateCommentRequest.body: object expected");
                message.body = $root.google.protobuf.StringValue.fromObject(object.body);
            }
            return message;
        };

        /**
         * Creates a plain object from a ValidateCommentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_comment.ValidateCommentRequest
         * @static
         * @param {mutation_comment.ValidateCommentRequest} message ValidateCommentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidateCommentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.body = null;
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = $root.google.protobuf.StringValue.toObject(message.body, options);
            return object;
        };

        /**
         * Converts this ValidateCommentRequest to JSON.
         * @function toJSON
         * @memberof mutation_comment.ValidateCommentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidateCommentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ValidateCommentRequest
         * @function getTypeUrl
         * @memberof mutation_comment.ValidateCommentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ValidateCommentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_comment.ValidateCommentRequest";
        };

        return ValidateCommentRequest;
    })();

    mutation_comment.ValidateCommentResponse = (function() {

        /**
         * Properties of a ValidateCommentResponse.
         * @memberof mutation_comment
         * @interface IValidateCommentResponse
         * @property {google.protobuf.IBoolValue|null} [succeeded] ValidateCommentResponse succeeded
         */

        /**
         * Constructs a new ValidateCommentResponse.
         * @memberof mutation_comment
         * @classdesc Represents a ValidateCommentResponse.
         * @implements IValidateCommentResponse
         * @constructor
         * @param {mutation_comment.IValidateCommentResponse=} [properties] Properties to set
         */
        function ValidateCommentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidateCommentResponse succeeded.
         * @member {google.protobuf.IBoolValue|null|undefined} succeeded
         * @memberof mutation_comment.ValidateCommentResponse
         * @instance
         */
        ValidateCommentResponse.prototype.succeeded = null;

        /**
         * Creates a new ValidateCommentResponse instance using the specified properties.
         * @function create
         * @memberof mutation_comment.ValidateCommentResponse
         * @static
         * @param {mutation_comment.IValidateCommentResponse=} [properties] Properties to set
         * @returns {mutation_comment.ValidateCommentResponse} ValidateCommentResponse instance
         */
        ValidateCommentResponse.create = function create(properties) {
            return new ValidateCommentResponse(properties);
        };

        /**
         * Encodes the specified ValidateCommentResponse message. Does not implicitly {@link mutation_comment.ValidateCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_comment.ValidateCommentResponse
         * @static
         * @param {mutation_comment.IValidateCommentResponse} message ValidateCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.succeeded != null && Object.hasOwnProperty.call(message, "succeeded"))
                $root.google.protobuf.BoolValue.encode(message.succeeded, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ValidateCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_comment.ValidateCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_comment.ValidateCommentResponse} ValidateCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_comment.ValidateCommentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.succeeded = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ValidateCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_comment.ValidateCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_comment.ValidateCommentResponse} ValidateCommentResponse
         */
        ValidateCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_comment.ValidateCommentResponse)
                return object;
            var message = new $root.mutation_comment.ValidateCommentResponse();
            if (object.succeeded != null) {
                if (typeof object.succeeded !== "object")
                    throw TypeError(".mutation_comment.ValidateCommentResponse.succeeded: object expected");
                message.succeeded = $root.google.protobuf.BoolValue.fromObject(object.succeeded);
            }
            return message;
        };

        /**
         * Creates a plain object from a ValidateCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_comment.ValidateCommentResponse
         * @static
         * @param {mutation_comment.ValidateCommentResponse} message ValidateCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidateCommentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.succeeded = null;
            if (message.succeeded != null && message.hasOwnProperty("succeeded"))
                object.succeeded = $root.google.protobuf.BoolValue.toObject(message.succeeded, options);
            return object;
        };

        /**
         * Converts this ValidateCommentResponse to JSON.
         * @function toJSON
         * @memberof mutation_comment.ValidateCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidateCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ValidateCommentResponse
         * @function getTypeUrl
         * @memberof mutation_comment.ValidateCommentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ValidateCommentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_comment.ValidateCommentResponse";
        };

        return ValidateCommentResponse;
    })();

    return mutation_comment;
})();

$root.mutation_ticket = (function() {

    /**
     * Namespace mutation_ticket.
     * @exports mutation_ticket
     * @namespace
     */
    var mutation_ticket = {};

    mutation_ticket.UseTicketRequest = (function() {

        /**
         * Properties of a UseTicketRequest.
         * @memberof mutation_ticket
         * @interface IUseTicketRequest
         * @property {google.protobuf.IStringValue|null} [storyId] UseTicketRequest storyId
         * @property {google.protobuf.IStringValue|null} [ticketId] UseTicketRequest ticketId
         * @property {google.protobuf.IBoolValue|null} [requireTicketCount] UseTicketRequest requireTicketCount
         * @property {query_series_charge.ISeriesChargeRequireField|null} [requireSeriesCharge] UseTicketRequest requireSeriesCharge
         */

        /**
         * Constructs a new UseTicketRequest.
         * @memberof mutation_ticket
         * @classdesc Represents a UseTicketRequest.
         * @implements IUseTicketRequest
         * @constructor
         * @param {mutation_ticket.IUseTicketRequest=} [properties] Properties to set
         */
        function UseTicketRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UseTicketRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof mutation_ticket.UseTicketRequest
         * @instance
         */
        UseTicketRequest.prototype.storyId = null;

        /**
         * UseTicketRequest ticketId.
         * @member {google.protobuf.IStringValue|null|undefined} ticketId
         * @memberof mutation_ticket.UseTicketRequest
         * @instance
         */
        UseTicketRequest.prototype.ticketId = null;

        /**
         * UseTicketRequest requireTicketCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTicketCount
         * @memberof mutation_ticket.UseTicketRequest
         * @instance
         */
        UseTicketRequest.prototype.requireTicketCount = null;

        /**
         * UseTicketRequest requireSeriesCharge.
         * @member {query_series_charge.ISeriesChargeRequireField|null|undefined} requireSeriesCharge
         * @memberof mutation_ticket.UseTicketRequest
         * @instance
         */
        UseTicketRequest.prototype.requireSeriesCharge = null;

        /**
         * Creates a new UseTicketRequest instance using the specified properties.
         * @function create
         * @memberof mutation_ticket.UseTicketRequest
         * @static
         * @param {mutation_ticket.IUseTicketRequest=} [properties] Properties to set
         * @returns {mutation_ticket.UseTicketRequest} UseTicketRequest instance
         */
        UseTicketRequest.create = function create(properties) {
            return new UseTicketRequest(properties);
        };

        /**
         * Encodes the specified UseTicketRequest message. Does not implicitly {@link mutation_ticket.UseTicketRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_ticket.UseTicketRequest
         * @static
         * @param {mutation_ticket.IUseTicketRequest} message UseTicketRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UseTicketRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.ticketId != null && Object.hasOwnProperty.call(message, "ticketId"))
                $root.google.protobuf.StringValue.encode(message.ticketId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTicketCount != null && Object.hasOwnProperty.call(message, "requireTicketCount"))
                $root.google.protobuf.BoolValue.encode(message.requireTicketCount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireSeriesCharge != null && Object.hasOwnProperty.call(message, "requireSeriesCharge"))
                $root.query_series_charge.SeriesChargeRequireField.encode(message.requireSeriesCharge, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UseTicketRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_ticket.UseTicketRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_ticket.UseTicketRequest} UseTicketRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UseTicketRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_ticket.UseTicketRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.ticketId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTicketCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UseTicketRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_ticket.UseTicketRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_ticket.UseTicketRequest} UseTicketRequest
         */
        UseTicketRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_ticket.UseTicketRequest)
                return object;
            var message = new $root.mutation_ticket.UseTicketRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".mutation_ticket.UseTicketRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.ticketId != null) {
                if (typeof object.ticketId !== "object")
                    throw TypeError(".mutation_ticket.UseTicketRequest.ticketId: object expected");
                message.ticketId = $root.google.protobuf.StringValue.fromObject(object.ticketId);
            }
            if (object.requireTicketCount != null) {
                if (typeof object.requireTicketCount !== "object")
                    throw TypeError(".mutation_ticket.UseTicketRequest.requireTicketCount: object expected");
                message.requireTicketCount = $root.google.protobuf.BoolValue.fromObject(object.requireTicketCount);
            }
            if (object.requireSeriesCharge != null) {
                if (typeof object.requireSeriesCharge !== "object")
                    throw TypeError(".mutation_ticket.UseTicketRequest.requireSeriesCharge: object expected");
                message.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.fromObject(object.requireSeriesCharge);
            }
            return message;
        };

        /**
         * Creates a plain object from a UseTicketRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_ticket.UseTicketRequest
         * @static
         * @param {mutation_ticket.UseTicketRequest} message UseTicketRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UseTicketRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.ticketId = null;
                object.requireTicketCount = null;
                object.requireSeriesCharge = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.ticketId != null && message.hasOwnProperty("ticketId"))
                object.ticketId = $root.google.protobuf.StringValue.toObject(message.ticketId, options);
            if (message.requireTicketCount != null && message.hasOwnProperty("requireTicketCount"))
                object.requireTicketCount = $root.google.protobuf.BoolValue.toObject(message.requireTicketCount, options);
            if (message.requireSeriesCharge != null && message.hasOwnProperty("requireSeriesCharge"))
                object.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.toObject(message.requireSeriesCharge, options);
            return object;
        };

        /**
         * Converts this UseTicketRequest to JSON.
         * @function toJSON
         * @memberof mutation_ticket.UseTicketRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UseTicketRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UseTicketRequest
         * @function getTypeUrl
         * @memberof mutation_ticket.UseTicketRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UseTicketRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_ticket.UseTicketRequest";
        };

        return UseTicketRequest;
    })();

    mutation_ticket.UseTicketResponse = (function() {

        /**
         * Properties of a UseTicketResponse.
         * @memberof mutation_ticket
         * @interface IUseTicketResponse
         * @property {google.protobuf.IInt32Value|null} [ticketCount] UseTicketResponse ticketCount
         * @property {query_series_charge.ISeriesChargeResponse|null} [seriesCharge] UseTicketResponse seriesCharge
         */

        /**
         * Constructs a new UseTicketResponse.
         * @memberof mutation_ticket
         * @classdesc Represents a UseTicketResponse.
         * @implements IUseTicketResponse
         * @constructor
         * @param {mutation_ticket.IUseTicketResponse=} [properties] Properties to set
         */
        function UseTicketResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UseTicketResponse ticketCount.
         * @member {google.protobuf.IInt32Value|null|undefined} ticketCount
         * @memberof mutation_ticket.UseTicketResponse
         * @instance
         */
        UseTicketResponse.prototype.ticketCount = null;

        /**
         * UseTicketResponse seriesCharge.
         * @member {query_series_charge.ISeriesChargeResponse|null|undefined} seriesCharge
         * @memberof mutation_ticket.UseTicketResponse
         * @instance
         */
        UseTicketResponse.prototype.seriesCharge = null;

        /**
         * Creates a new UseTicketResponse instance using the specified properties.
         * @function create
         * @memberof mutation_ticket.UseTicketResponse
         * @static
         * @param {mutation_ticket.IUseTicketResponse=} [properties] Properties to set
         * @returns {mutation_ticket.UseTicketResponse} UseTicketResponse instance
         */
        UseTicketResponse.create = function create(properties) {
            return new UseTicketResponse(properties);
        };

        /**
         * Encodes the specified UseTicketResponse message. Does not implicitly {@link mutation_ticket.UseTicketResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_ticket.UseTicketResponse
         * @static
         * @param {mutation_ticket.IUseTicketResponse} message UseTicketResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UseTicketResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ticketCount != null && Object.hasOwnProperty.call(message, "ticketCount"))
                $root.google.protobuf.Int32Value.encode(message.ticketCount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.seriesCharge != null && Object.hasOwnProperty.call(message, "seriesCharge"))
                $root.query_series_charge.SeriesChargeResponse.encode(message.seriesCharge, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UseTicketResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_ticket.UseTicketResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_ticket.UseTicketResponse} UseTicketResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UseTicketResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_ticket.UseTicketResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ticketCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.seriesCharge = $root.query_series_charge.SeriesChargeResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UseTicketResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_ticket.UseTicketResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_ticket.UseTicketResponse} UseTicketResponse
         */
        UseTicketResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_ticket.UseTicketResponse)
                return object;
            var message = new $root.mutation_ticket.UseTicketResponse();
            if (object.ticketCount != null) {
                if (typeof object.ticketCount !== "object")
                    throw TypeError(".mutation_ticket.UseTicketResponse.ticketCount: object expected");
                message.ticketCount = $root.google.protobuf.Int32Value.fromObject(object.ticketCount);
            }
            if (object.seriesCharge != null) {
                if (typeof object.seriesCharge !== "object")
                    throw TypeError(".mutation_ticket.UseTicketResponse.seriesCharge: object expected");
                message.seriesCharge = $root.query_series_charge.SeriesChargeResponse.fromObject(object.seriesCharge);
            }
            return message;
        };

        /**
         * Creates a plain object from a UseTicketResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_ticket.UseTicketResponse
         * @static
         * @param {mutation_ticket.UseTicketResponse} message UseTicketResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UseTicketResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.ticketCount = null;
                object.seriesCharge = null;
            }
            if (message.ticketCount != null && message.hasOwnProperty("ticketCount"))
                object.ticketCount = $root.google.protobuf.Int32Value.toObject(message.ticketCount, options);
            if (message.seriesCharge != null && message.hasOwnProperty("seriesCharge"))
                object.seriesCharge = $root.query_series_charge.SeriesChargeResponse.toObject(message.seriesCharge, options);
            return object;
        };

        /**
         * Converts this UseTicketResponse to JSON.
         * @function toJSON
         * @memberof mutation_ticket.UseTicketResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UseTicketResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UseTicketResponse
         * @function getTypeUrl
         * @memberof mutation_ticket.UseTicketResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UseTicketResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_ticket.UseTicketResponse";
        };

        return UseTicketResponse;
    })();

    return mutation_ticket;
})();

$root.mutation_profiling = (function() {

    /**
     * Namespace mutation_profiling.
     * @exports mutation_profiling
     * @namespace
     */
    var mutation_profiling = {};

    mutation_profiling.UpdateProfilingRequest = (function() {

        /**
         * Properties of an UpdateProfilingRequest.
         * @memberof mutation_profiling
         * @interface IUpdateProfilingRequest
         * @property {query_profiling.Gender|null} [gender] UpdateProfilingRequest gender
         * @property {google.protobuf.ITimestamp|null} [birthday] UpdateProfilingRequest birthday
         * @property {mutation_profiling.UpdateProfilingRequest.IStoryPreference|null} [story] UpdateProfilingRequest story
         * @property {mutation_profiling.UpdateProfilingRequest.ITagPreference|null} [tag] UpdateProfilingRequest tag
         */

        /**
         * Constructs a new UpdateProfilingRequest.
         * @memberof mutation_profiling
         * @classdesc Represents an UpdateProfilingRequest.
         * @implements IUpdateProfilingRequest
         * @constructor
         * @param {mutation_profiling.IUpdateProfilingRequest=} [properties] Properties to set
         */
        function UpdateProfilingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateProfilingRequest gender.
         * @member {query_profiling.Gender} gender
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @instance
         */
        UpdateProfilingRequest.prototype.gender = 0;

        /**
         * UpdateProfilingRequest birthday.
         * @member {google.protobuf.ITimestamp|null|undefined} birthday
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @instance
         */
        UpdateProfilingRequest.prototype.birthday = null;

        /**
         * UpdateProfilingRequest story.
         * @member {mutation_profiling.UpdateProfilingRequest.IStoryPreference|null|undefined} story
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @instance
         */
        UpdateProfilingRequest.prototype.story = null;

        /**
         * UpdateProfilingRequest tag.
         * @member {mutation_profiling.UpdateProfilingRequest.ITagPreference|null|undefined} tag
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @instance
         */
        UpdateProfilingRequest.prototype.tag = null;

        /**
         * Creates a new UpdateProfilingRequest instance using the specified properties.
         * @function create
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @static
         * @param {mutation_profiling.IUpdateProfilingRequest=} [properties] Properties to set
         * @returns {mutation_profiling.UpdateProfilingRequest} UpdateProfilingRequest instance
         */
        UpdateProfilingRequest.create = function create(properties) {
            return new UpdateProfilingRequest(properties);
        };

        /**
         * Encodes the specified UpdateProfilingRequest message. Does not implicitly {@link mutation_profiling.UpdateProfilingRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @static
         * @param {mutation_profiling.IUpdateProfilingRequest} message UpdateProfilingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProfilingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                $root.google.protobuf.Timestamp.encode(message.birthday, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.mutation_profiling.UpdateProfilingRequest.StoryPreference.encode(message.story, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.mutation_profiling.UpdateProfilingRequest.TagPreference.encode(message.tag, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateProfilingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_profiling.UpdateProfilingRequest} UpdateProfilingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProfilingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_profiling.UpdateProfilingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gender = reader.int32();
                    break;
                case 2:
                    message.birthday = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.story = $root.mutation_profiling.UpdateProfilingRequest.StoryPreference.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.tag = $root.mutation_profiling.UpdateProfilingRequest.TagPreference.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateProfilingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_profiling.UpdateProfilingRequest} UpdateProfilingRequest
         */
        UpdateProfilingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_profiling.UpdateProfilingRequest)
                return object;
            var message = new $root.mutation_profiling.UpdateProfilingRequest();
            switch (object.gender) {
            case "GENDER_UNSPECIFIED":
            case 0:
                message.gender = 0;
                break;
            case "GENDER_MALE":
            case 1:
                message.gender = 1;
                break;
            case "GENDER_FEMALE":
            case 2:
                message.gender = 2;
                break;
            case "GENDER_OTHER":
            case 3:
                message.gender = 3;
                break;
            }
            if (object.birthday != null) {
                if (typeof object.birthday !== "object")
                    throw TypeError(".mutation_profiling.UpdateProfilingRequest.birthday: object expected");
                message.birthday = $root.google.protobuf.Timestamp.fromObject(object.birthday);
            }
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".mutation_profiling.UpdateProfilingRequest.story: object expected");
                message.story = $root.mutation_profiling.UpdateProfilingRequest.StoryPreference.fromObject(object.story);
            }
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".mutation_profiling.UpdateProfilingRequest.tag: object expected");
                message.tag = $root.mutation_profiling.UpdateProfilingRequest.TagPreference.fromObject(object.tag);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateProfilingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @static
         * @param {mutation_profiling.UpdateProfilingRequest} message UpdateProfilingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateProfilingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gender = options.enums === String ? "GENDER_UNSPECIFIED" : 0;
                object.birthday = null;
                object.story = null;
                object.tag = null;
            }
            if (message.gender != null && message.hasOwnProperty("gender"))
                object.gender = options.enums === String ? $root.query_profiling.Gender[message.gender] : message.gender;
            if (message.birthday != null && message.hasOwnProperty("birthday"))
                object.birthday = $root.google.protobuf.Timestamp.toObject(message.birthday, options);
            if (message.story != null && message.hasOwnProperty("story"))
                object.story = $root.mutation_profiling.UpdateProfilingRequest.StoryPreference.toObject(message.story, options);
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.mutation_profiling.UpdateProfilingRequest.TagPreference.toObject(message.tag, options);
            return object;
        };

        /**
         * Converts this UpdateProfilingRequest to JSON.
         * @function toJSON
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateProfilingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateProfilingRequest
         * @function getTypeUrl
         * @memberof mutation_profiling.UpdateProfilingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateProfilingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_profiling.UpdateProfilingRequest";
        };

        UpdateProfilingRequest.StoryPreference = (function() {

            /**
             * Properties of a StoryPreference.
             * @memberof mutation_profiling.UpdateProfilingRequest
             * @interface IStoryPreference
             * @property {Array.<google.protobuf.IStringValue>|null} [questionList] StoryPreference questionList
             * @property {Array.<google.protobuf.IStringValue>|null} [answerPreferredList] StoryPreference answerPreferredList
             */

            /**
             * Constructs a new StoryPreference.
             * @memberof mutation_profiling.UpdateProfilingRequest
             * @classdesc Represents a StoryPreference.
             * @implements IStoryPreference
             * @constructor
             * @param {mutation_profiling.UpdateProfilingRequest.IStoryPreference=} [properties] Properties to set
             */
            function StoryPreference(properties) {
                this.questionList = [];
                this.answerPreferredList = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StoryPreference questionList.
             * @member {Array.<google.protobuf.IStringValue>} questionList
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @instance
             */
            StoryPreference.prototype.questionList = $util.emptyArray;

            /**
             * StoryPreference answerPreferredList.
             * @member {Array.<google.protobuf.IStringValue>} answerPreferredList
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @instance
             */
            StoryPreference.prototype.answerPreferredList = $util.emptyArray;

            /**
             * Creates a new StoryPreference instance using the specified properties.
             * @function create
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @static
             * @param {mutation_profiling.UpdateProfilingRequest.IStoryPreference=} [properties] Properties to set
             * @returns {mutation_profiling.UpdateProfilingRequest.StoryPreference} StoryPreference instance
             */
            StoryPreference.create = function create(properties) {
                return new StoryPreference(properties);
            };

            /**
             * Encodes the specified StoryPreference message. Does not implicitly {@link mutation_profiling.UpdateProfilingRequest.StoryPreference.verify|verify} messages.
             * @function encode
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @static
             * @param {mutation_profiling.UpdateProfilingRequest.IStoryPreference} message StoryPreference message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StoryPreference.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.questionList != null && message.questionList.length)
                    for (var i = 0; i < message.questionList.length; ++i)
                        $root.google.protobuf.StringValue.encode(message.questionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.answerPreferredList != null && message.answerPreferredList.length)
                    for (var i = 0; i < message.answerPreferredList.length; ++i)
                        $root.google.protobuf.StringValue.encode(message.answerPreferredList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a StoryPreference message from the specified reader or buffer.
             * @function decode
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mutation_profiling.UpdateProfilingRequest.StoryPreference} StoryPreference
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StoryPreference.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_profiling.UpdateProfilingRequest.StoryPreference();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.questionList && message.questionList.length))
                            message.questionList = [];
                        message.questionList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        if (!(message.answerPreferredList && message.answerPreferredList.length))
                            message.answerPreferredList = [];
                        message.answerPreferredList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a StoryPreference message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mutation_profiling.UpdateProfilingRequest.StoryPreference} StoryPreference
             */
            StoryPreference.fromObject = function fromObject(object) {
                if (object instanceof $root.mutation_profiling.UpdateProfilingRequest.StoryPreference)
                    return object;
                var message = new $root.mutation_profiling.UpdateProfilingRequest.StoryPreference();
                if (object.questionList) {
                    if (!Array.isArray(object.questionList))
                        throw TypeError(".mutation_profiling.UpdateProfilingRequest.StoryPreference.questionList: array expected");
                    message.questionList = [];
                    for (var i = 0; i < object.questionList.length; ++i) {
                        if (typeof object.questionList[i] !== "object")
                            throw TypeError(".mutation_profiling.UpdateProfilingRequest.StoryPreference.questionList: object expected");
                        message.questionList[i] = $root.google.protobuf.StringValue.fromObject(object.questionList[i]);
                    }
                }
                if (object.answerPreferredList) {
                    if (!Array.isArray(object.answerPreferredList))
                        throw TypeError(".mutation_profiling.UpdateProfilingRequest.StoryPreference.answerPreferredList: array expected");
                    message.answerPreferredList = [];
                    for (var i = 0; i < object.answerPreferredList.length; ++i) {
                        if (typeof object.answerPreferredList[i] !== "object")
                            throw TypeError(".mutation_profiling.UpdateProfilingRequest.StoryPreference.answerPreferredList: object expected");
                        message.answerPreferredList[i] = $root.google.protobuf.StringValue.fromObject(object.answerPreferredList[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a StoryPreference message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @static
             * @param {mutation_profiling.UpdateProfilingRequest.StoryPreference} message StoryPreference
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StoryPreference.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.questionList = [];
                    object.answerPreferredList = [];
                }
                if (message.questionList && message.questionList.length) {
                    object.questionList = [];
                    for (var j = 0; j < message.questionList.length; ++j)
                        object.questionList[j] = $root.google.protobuf.StringValue.toObject(message.questionList[j], options);
                }
                if (message.answerPreferredList && message.answerPreferredList.length) {
                    object.answerPreferredList = [];
                    for (var j = 0; j < message.answerPreferredList.length; ++j)
                        object.answerPreferredList[j] = $root.google.protobuf.StringValue.toObject(message.answerPreferredList[j], options);
                }
                return object;
            };

            /**
             * Converts this StoryPreference to JSON.
             * @function toJSON
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StoryPreference.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StoryPreference
             * @function getTypeUrl
             * @memberof mutation_profiling.UpdateProfilingRequest.StoryPreference
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StoryPreference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mutation_profiling.UpdateProfilingRequest.StoryPreference";
            };

            return StoryPreference;
        })();

        UpdateProfilingRequest.TagPreference = (function() {

            /**
             * Properties of a TagPreference.
             * @memberof mutation_profiling.UpdateProfilingRequest
             * @interface ITagPreference
             * @property {Array.<query_profiling.IProfilingTagPair>|null} [questionList] TagPreference questionList
             * @property {Array.<query_profiling.IProfilingTagPair>|null} [answerPreferredList] TagPreference answerPreferredList
             */

            /**
             * Constructs a new TagPreference.
             * @memberof mutation_profiling.UpdateProfilingRequest
             * @classdesc Represents a TagPreference.
             * @implements ITagPreference
             * @constructor
             * @param {mutation_profiling.UpdateProfilingRequest.ITagPreference=} [properties] Properties to set
             */
            function TagPreference(properties) {
                this.questionList = [];
                this.answerPreferredList = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TagPreference questionList.
             * @member {Array.<query_profiling.IProfilingTagPair>} questionList
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @instance
             */
            TagPreference.prototype.questionList = $util.emptyArray;

            /**
             * TagPreference answerPreferredList.
             * @member {Array.<query_profiling.IProfilingTagPair>} answerPreferredList
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @instance
             */
            TagPreference.prototype.answerPreferredList = $util.emptyArray;

            /**
             * Creates a new TagPreference instance using the specified properties.
             * @function create
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @static
             * @param {mutation_profiling.UpdateProfilingRequest.ITagPreference=} [properties] Properties to set
             * @returns {mutation_profiling.UpdateProfilingRequest.TagPreference} TagPreference instance
             */
            TagPreference.create = function create(properties) {
                return new TagPreference(properties);
            };

            /**
             * Encodes the specified TagPreference message. Does not implicitly {@link mutation_profiling.UpdateProfilingRequest.TagPreference.verify|verify} messages.
             * @function encode
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @static
             * @param {mutation_profiling.UpdateProfilingRequest.ITagPreference} message TagPreference message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TagPreference.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.questionList != null && message.questionList.length)
                    for (var i = 0; i < message.questionList.length; ++i)
                        $root.query_profiling.ProfilingTagPair.encode(message.questionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.answerPreferredList != null && message.answerPreferredList.length)
                    for (var i = 0; i < message.answerPreferredList.length; ++i)
                        $root.query_profiling.ProfilingTagPair.encode(message.answerPreferredList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a TagPreference message from the specified reader or buffer.
             * @function decode
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {mutation_profiling.UpdateProfilingRequest.TagPreference} TagPreference
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TagPreference.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_profiling.UpdateProfilingRequest.TagPreference();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.questionList && message.questionList.length))
                            message.questionList = [];
                        message.questionList.push($root.query_profiling.ProfilingTagPair.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        if (!(message.answerPreferredList && message.answerPreferredList.length))
                            message.answerPreferredList = [];
                        message.answerPreferredList.push($root.query_profiling.ProfilingTagPair.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a TagPreference message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {mutation_profiling.UpdateProfilingRequest.TagPreference} TagPreference
             */
            TagPreference.fromObject = function fromObject(object) {
                if (object instanceof $root.mutation_profiling.UpdateProfilingRequest.TagPreference)
                    return object;
                var message = new $root.mutation_profiling.UpdateProfilingRequest.TagPreference();
                if (object.questionList) {
                    if (!Array.isArray(object.questionList))
                        throw TypeError(".mutation_profiling.UpdateProfilingRequest.TagPreference.questionList: array expected");
                    message.questionList = [];
                    for (var i = 0; i < object.questionList.length; ++i) {
                        if (typeof object.questionList[i] !== "object")
                            throw TypeError(".mutation_profiling.UpdateProfilingRequest.TagPreference.questionList: object expected");
                        message.questionList[i] = $root.query_profiling.ProfilingTagPair.fromObject(object.questionList[i]);
                    }
                }
                if (object.answerPreferredList) {
                    if (!Array.isArray(object.answerPreferredList))
                        throw TypeError(".mutation_profiling.UpdateProfilingRequest.TagPreference.answerPreferredList: array expected");
                    message.answerPreferredList = [];
                    for (var i = 0; i < object.answerPreferredList.length; ++i) {
                        if (typeof object.answerPreferredList[i] !== "object")
                            throw TypeError(".mutation_profiling.UpdateProfilingRequest.TagPreference.answerPreferredList: object expected");
                        message.answerPreferredList[i] = $root.query_profiling.ProfilingTagPair.fromObject(object.answerPreferredList[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a TagPreference message. Also converts values to other types if specified.
             * @function toObject
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @static
             * @param {mutation_profiling.UpdateProfilingRequest.TagPreference} message TagPreference
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TagPreference.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.questionList = [];
                    object.answerPreferredList = [];
                }
                if (message.questionList && message.questionList.length) {
                    object.questionList = [];
                    for (var j = 0; j < message.questionList.length; ++j)
                        object.questionList[j] = $root.query_profiling.ProfilingTagPair.toObject(message.questionList[j], options);
                }
                if (message.answerPreferredList && message.answerPreferredList.length) {
                    object.answerPreferredList = [];
                    for (var j = 0; j < message.answerPreferredList.length; ++j)
                        object.answerPreferredList[j] = $root.query_profiling.ProfilingTagPair.toObject(message.answerPreferredList[j], options);
                }
                return object;
            };

            /**
             * Converts this TagPreference to JSON.
             * @function toJSON
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TagPreference.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TagPreference
             * @function getTypeUrl
             * @memberof mutation_profiling.UpdateProfilingRequest.TagPreference
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TagPreference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/mutation_profiling.UpdateProfilingRequest.TagPreference";
            };

            return TagPreference;
        })();

        return UpdateProfilingRequest;
    })();

    mutation_profiling.UpdateUserPreferenceSensitiveRequest = (function() {

        /**
         * Properties of an UpdateUserPreferenceSensitiveRequest.
         * @memberof mutation_profiling
         * @interface IUpdateUserPreferenceSensitiveRequest
         * @property {user_preference_sensitive.ShowSensitive|null} [showSensitive] UpdateUserPreferenceSensitiveRequest showSensitive
         */

        /**
         * Constructs a new UpdateUserPreferenceSensitiveRequest.
         * @memberof mutation_profiling
         * @classdesc Represents an UpdateUserPreferenceSensitiveRequest.
         * @implements IUpdateUserPreferenceSensitiveRequest
         * @constructor
         * @param {mutation_profiling.IUpdateUserPreferenceSensitiveRequest=} [properties] Properties to set
         */
        function UpdateUserPreferenceSensitiveRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUserPreferenceSensitiveRequest showSensitive.
         * @member {user_preference_sensitive.ShowSensitive} showSensitive
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @instance
         */
        UpdateUserPreferenceSensitiveRequest.prototype.showSensitive = 0;

        /**
         * Creates a new UpdateUserPreferenceSensitiveRequest instance using the specified properties.
         * @function create
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @static
         * @param {mutation_profiling.IUpdateUserPreferenceSensitiveRequest=} [properties] Properties to set
         * @returns {mutation_profiling.UpdateUserPreferenceSensitiveRequest} UpdateUserPreferenceSensitiveRequest instance
         */
        UpdateUserPreferenceSensitiveRequest.create = function create(properties) {
            return new UpdateUserPreferenceSensitiveRequest(properties);
        };

        /**
         * Encodes the specified UpdateUserPreferenceSensitiveRequest message. Does not implicitly {@link mutation_profiling.UpdateUserPreferenceSensitiveRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @static
         * @param {mutation_profiling.IUpdateUserPreferenceSensitiveRequest} message UpdateUserPreferenceSensitiveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserPreferenceSensitiveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.showSensitive != null && Object.hasOwnProperty.call(message, "showSensitive"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.showSensitive);
            return writer;
        };

        /**
         * Decodes an UpdateUserPreferenceSensitiveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_profiling.UpdateUserPreferenceSensitiveRequest} UpdateUserPreferenceSensitiveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserPreferenceSensitiveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_profiling.UpdateUserPreferenceSensitiveRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.showSensitive = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateUserPreferenceSensitiveRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_profiling.UpdateUserPreferenceSensitiveRequest} UpdateUserPreferenceSensitiveRequest
         */
        UpdateUserPreferenceSensitiveRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_profiling.UpdateUserPreferenceSensitiveRequest)
                return object;
            var message = new $root.mutation_profiling.UpdateUserPreferenceSensitiveRequest();
            switch (object.showSensitive) {
            case "SHOW_SENSITIVE_UNSPECIFIED":
            case 0:
                message.showSensitive = 0;
                break;
            case "SHOW_SENSITIVE_NOT_BLOCK_AND_LIST":
            case 3:
                message.showSensitive = 3;
                break;
            case "SHOW_SENSITIVE_BLOCK_AND_NOT_LIST":
            case 4:
                message.showSensitive = 4;
                break;
            case "SHOW_SENSITIVE_BLOCK_AND_LIST":
            case 5:
                message.showSensitive = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateUserPreferenceSensitiveRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @static
         * @param {mutation_profiling.UpdateUserPreferenceSensitiveRequest} message UpdateUserPreferenceSensitiveRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateUserPreferenceSensitiveRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.showSensitive = options.enums === String ? "SHOW_SENSITIVE_UNSPECIFIED" : 0;
            if (message.showSensitive != null && message.hasOwnProperty("showSensitive"))
                object.showSensitive = options.enums === String ? $root.user_preference_sensitive.ShowSensitive[message.showSensitive] : message.showSensitive;
            return object;
        };

        /**
         * Converts this UpdateUserPreferenceSensitiveRequest to JSON.
         * @function toJSON
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateUserPreferenceSensitiveRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateUserPreferenceSensitiveRequest
         * @function getTypeUrl
         * @memberof mutation_profiling.UpdateUserPreferenceSensitiveRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateUserPreferenceSensitiveRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_profiling.UpdateUserPreferenceSensitiveRequest";
        };

        return UpdateUserPreferenceSensitiveRequest;
    })();

    return mutation_profiling;
})();

$root.query_profiling = (function() {

    /**
     * Namespace query_profiling.
     * @exports query_profiling
     * @namespace
     */
    var query_profiling = {};

    query_profiling.ProfilingTagPair = (function() {

        /**
         * Properties of a ProfilingTagPair.
         * @memberof query_profiling
         * @interface IProfilingTagPair
         * @property {google.protobuf.IStringValue|null} [parent] ProfilingTagPair parent
         * @property {google.protobuf.IStringValue|null} [child] ProfilingTagPair child
         */

        /**
         * Constructs a new ProfilingTagPair.
         * @memberof query_profiling
         * @classdesc Represents a ProfilingTagPair.
         * @implements IProfilingTagPair
         * @constructor
         * @param {query_profiling.IProfilingTagPair=} [properties] Properties to set
         */
        function ProfilingTagPair(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilingTagPair parent.
         * @member {google.protobuf.IStringValue|null|undefined} parent
         * @memberof query_profiling.ProfilingTagPair
         * @instance
         */
        ProfilingTagPair.prototype.parent = null;

        /**
         * ProfilingTagPair child.
         * @member {google.protobuf.IStringValue|null|undefined} child
         * @memberof query_profiling.ProfilingTagPair
         * @instance
         */
        ProfilingTagPair.prototype.child = null;

        /**
         * Creates a new ProfilingTagPair instance using the specified properties.
         * @function create
         * @memberof query_profiling.ProfilingTagPair
         * @static
         * @param {query_profiling.IProfilingTagPair=} [properties] Properties to set
         * @returns {query_profiling.ProfilingTagPair} ProfilingTagPair instance
         */
        ProfilingTagPair.create = function create(properties) {
            return new ProfilingTagPair(properties);
        };

        /**
         * Encodes the specified ProfilingTagPair message. Does not implicitly {@link query_profiling.ProfilingTagPair.verify|verify} messages.
         * @function encode
         * @memberof query_profiling.ProfilingTagPair
         * @static
         * @param {query_profiling.IProfilingTagPair} message ProfilingTagPair message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilingTagPair.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                $root.google.protobuf.StringValue.encode(message.parent, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.child != null && Object.hasOwnProperty.call(message, "child"))
                $root.google.protobuf.StringValue.encode(message.child, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfilingTagPair message from the specified reader or buffer.
         * @function decode
         * @memberof query_profiling.ProfilingTagPair
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_profiling.ProfilingTagPair} ProfilingTagPair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilingTagPair.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_profiling.ProfilingTagPair();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parent = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.child = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ProfilingTagPair message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_profiling.ProfilingTagPair
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_profiling.ProfilingTagPair} ProfilingTagPair
         */
        ProfilingTagPair.fromObject = function fromObject(object) {
            if (object instanceof $root.query_profiling.ProfilingTagPair)
                return object;
            var message = new $root.query_profiling.ProfilingTagPair();
            if (object.parent != null) {
                if (typeof object.parent !== "object")
                    throw TypeError(".query_profiling.ProfilingTagPair.parent: object expected");
                message.parent = $root.google.protobuf.StringValue.fromObject(object.parent);
            }
            if (object.child != null) {
                if (typeof object.child !== "object")
                    throw TypeError(".query_profiling.ProfilingTagPair.child: object expected");
                message.child = $root.google.protobuf.StringValue.fromObject(object.child);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilingTagPair message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_profiling.ProfilingTagPair
         * @static
         * @param {query_profiling.ProfilingTagPair} message ProfilingTagPair
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilingTagPair.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.parent = null;
                object.child = null;
            }
            if (message.parent != null && message.hasOwnProperty("parent"))
                object.parent = $root.google.protobuf.StringValue.toObject(message.parent, options);
            if (message.child != null && message.hasOwnProperty("child"))
                object.child = $root.google.protobuf.StringValue.toObject(message.child, options);
            return object;
        };

        /**
         * Converts this ProfilingTagPair to JSON.
         * @function toJSON
         * @memberof query_profiling.ProfilingTagPair
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilingTagPair.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProfilingTagPair
         * @function getTypeUrl
         * @memberof query_profiling.ProfilingTagPair
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfilingTagPair.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_profiling.ProfilingTagPair";
        };

        return ProfilingTagPair;
    })();

    /**
     * Gender enum.
     * @name query_profiling.Gender
     * @enum {number}
     * @property {number} GENDER_UNSPECIFIED=0 GENDER_UNSPECIFIED value
     * @property {number} GENDER_MALE=1 GENDER_MALE value
     * @property {number} GENDER_FEMALE=2 GENDER_FEMALE value
     * @property {number} GENDER_OTHER=3 GENDER_OTHER value
     */
    query_profiling.Gender = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "GENDER_UNSPECIFIED"] = 0;
        values[valuesById[1] = "GENDER_MALE"] = 1;
        values[valuesById[2] = "GENDER_FEMALE"] = 2;
        values[valuesById[3] = "GENDER_OTHER"] = 3;
        return values;
    })();

    return query_profiling;
})();

$root.user_preference_sensitive = (function() {

    /**
     * Namespace user_preference_sensitive.
     * @exports user_preference_sensitive
     * @namespace
     */
    var user_preference_sensitive = {};

    /**
     * ShowSensitive enum.
     * @name user_preference_sensitive.ShowSensitive
     * @enum {number}
     * @property {number} SHOW_SENSITIVE_UNSPECIFIED=0 SHOW_SENSITIVE_UNSPECIFIED value
     * @property {number} SHOW_SENSITIVE_NOT_BLOCK_AND_LIST=3 SHOW_SENSITIVE_NOT_BLOCK_AND_LIST value
     * @property {number} SHOW_SENSITIVE_BLOCK_AND_NOT_LIST=4 SHOW_SENSITIVE_BLOCK_AND_NOT_LIST value
     * @property {number} SHOW_SENSITIVE_BLOCK_AND_LIST=5 SHOW_SENSITIVE_BLOCK_AND_LIST value
     */
    user_preference_sensitive.ShowSensitive = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SHOW_SENSITIVE_UNSPECIFIED"] = 0;
        values[valuesById[3] = "SHOW_SENSITIVE_NOT_BLOCK_AND_LIST"] = 3;
        values[valuesById[4] = "SHOW_SENSITIVE_BLOCK_AND_NOT_LIST"] = 4;
        values[valuesById[5] = "SHOW_SENSITIVE_BLOCK_AND_LIST"] = 5;
        return values;
    })();

    return user_preference_sensitive;
})();

$root.mutation_following_tag = (function() {

    /**
     * Namespace mutation_following_tag.
     * @exports mutation_following_tag
     * @namespace
     */
    var mutation_following_tag = {};

    mutation_following_tag.UpdateNotOfficialFollowingTagRequest = (function() {

        /**
         * Properties of an UpdateNotOfficialFollowingTagRequest.
         * @memberof mutation_following_tag
         * @interface IUpdateNotOfficialFollowingTagRequest
         * @property {Array.<google.protobuf.IStringValue>|null} [tagList] UpdateNotOfficialFollowingTagRequest tagList
         */

        /**
         * Constructs a new UpdateNotOfficialFollowingTagRequest.
         * @memberof mutation_following_tag
         * @classdesc Represents an UpdateNotOfficialFollowingTagRequest.
         * @implements IUpdateNotOfficialFollowingTagRequest
         * @constructor
         * @param {mutation_following_tag.IUpdateNotOfficialFollowingTagRequest=} [properties] Properties to set
         */
        function UpdateNotOfficialFollowingTagRequest(properties) {
            this.tagList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateNotOfficialFollowingTagRequest tagList.
         * @member {Array.<google.protobuf.IStringValue>} tagList
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @instance
         */
        UpdateNotOfficialFollowingTagRequest.prototype.tagList = $util.emptyArray;

        /**
         * Creates a new UpdateNotOfficialFollowingTagRequest instance using the specified properties.
         * @function create
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @static
         * @param {mutation_following_tag.IUpdateNotOfficialFollowingTagRequest=} [properties] Properties to set
         * @returns {mutation_following_tag.UpdateNotOfficialFollowingTagRequest} UpdateNotOfficialFollowingTagRequest instance
         */
        UpdateNotOfficialFollowingTagRequest.create = function create(properties) {
            return new UpdateNotOfficialFollowingTagRequest(properties);
        };

        /**
         * Encodes the specified UpdateNotOfficialFollowingTagRequest message. Does not implicitly {@link mutation_following_tag.UpdateNotOfficialFollowingTagRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @static
         * @param {mutation_following_tag.IUpdateNotOfficialFollowingTagRequest} message UpdateNotOfficialFollowingTagRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateNotOfficialFollowingTagRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tagList != null && message.tagList.length)
                for (var i = 0; i < message.tagList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tagList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateNotOfficialFollowingTagRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_following_tag.UpdateNotOfficialFollowingTagRequest} UpdateNotOfficialFollowingTagRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateNotOfficialFollowingTagRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_following_tag.UpdateNotOfficialFollowingTagRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.tagList && message.tagList.length))
                        message.tagList = [];
                    message.tagList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateNotOfficialFollowingTagRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_following_tag.UpdateNotOfficialFollowingTagRequest} UpdateNotOfficialFollowingTagRequest
         */
        UpdateNotOfficialFollowingTagRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_following_tag.UpdateNotOfficialFollowingTagRequest)
                return object;
            var message = new $root.mutation_following_tag.UpdateNotOfficialFollowingTagRequest();
            if (object.tagList) {
                if (!Array.isArray(object.tagList))
                    throw TypeError(".mutation_following_tag.UpdateNotOfficialFollowingTagRequest.tagList: array expected");
                message.tagList = [];
                for (var i = 0; i < object.tagList.length; ++i) {
                    if (typeof object.tagList[i] !== "object")
                        throw TypeError(".mutation_following_tag.UpdateNotOfficialFollowingTagRequest.tagList: object expected");
                    message.tagList[i] = $root.google.protobuf.StringValue.fromObject(object.tagList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateNotOfficialFollowingTagRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @static
         * @param {mutation_following_tag.UpdateNotOfficialFollowingTagRequest} message UpdateNotOfficialFollowingTagRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateNotOfficialFollowingTagRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tagList = [];
            if (message.tagList && message.tagList.length) {
                object.tagList = [];
                for (var j = 0; j < message.tagList.length; ++j)
                    object.tagList[j] = $root.google.protobuf.StringValue.toObject(message.tagList[j], options);
            }
            return object;
        };

        /**
         * Converts this UpdateNotOfficialFollowingTagRequest to JSON.
         * @function toJSON
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateNotOfficialFollowingTagRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateNotOfficialFollowingTagRequest
         * @function getTypeUrl
         * @memberof mutation_following_tag.UpdateNotOfficialFollowingTagRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateNotOfficialFollowingTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_following_tag.UpdateNotOfficialFollowingTagRequest";
        };

        return UpdateNotOfficialFollowingTagRequest;
    })();

    return mutation_following_tag;
})();

$root.mutation_story = (function() {

    /**
     * Namespace mutation_story.
     * @exports mutation_story
     * @namespace
     */
    var mutation_story = {};

    mutation_story.CreateStoryRequest = (function() {

        /**
         * Properties of a CreateStoryRequest.
         * @memberof mutation_story
         * @interface ICreateStoryRequest
         * @property {google.protobuf.IStringValue|null} [userId] CreateStoryRequest userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] CreateStoryRequest tags
         * @property {google.protobuf.IStringValue|null} [seriesId] CreateStoryRequest seriesId
         * @property {google.protobuf.IInt32Value|null} [seriesIndex] CreateStoryRequest seriesIndex
         * @property {google.protobuf.IStringValue|null} [title] CreateStoryRequest title
         * @property {google.protobuf.IStringValue|null} [description] CreateStoryRequest description
         * @property {google.protobuf.IStringValue|null} [thumbnailId] CreateStoryRequest thumbnailId
         * @property {story.StoryStatus|null} [status] CreateStoryRequest status
         * @property {query_types.StoryScriptType|null} [scriptType] CreateStoryRequest scriptType
         * @property {tellerscript.ITellerScript|null} [chatNovelScript] CreateStoryRequest chatNovelScript
         * @property {google.protobuf.IStringValue|null} [novelScript] CreateStoryRequest novelScript
         * @property {query_types.SensitiveFlag|null} [sensitiveFlag] CreateStoryRequest sensitiveFlag
         */

        /**
         * Constructs a new CreateStoryRequest.
         * @memberof mutation_story
         * @classdesc Represents a CreateStoryRequest.
         * @implements ICreateStoryRequest
         * @constructor
         * @param {mutation_story.ICreateStoryRequest=} [properties] Properties to set
         */
        function CreateStoryRequest(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateStoryRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.userId = null;

        /**
         * CreateStoryRequest tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.tags = $util.emptyArray;

        /**
         * CreateStoryRequest seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.seriesId = null;

        /**
         * CreateStoryRequest seriesIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} seriesIndex
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.seriesIndex = null;

        /**
         * CreateStoryRequest title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.title = null;

        /**
         * CreateStoryRequest description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.description = null;

        /**
         * CreateStoryRequest thumbnailId.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailId
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.thumbnailId = null;

        /**
         * CreateStoryRequest status.
         * @member {story.StoryStatus} status
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.status = 0;

        /**
         * CreateStoryRequest scriptType.
         * @member {query_types.StoryScriptType} scriptType
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.scriptType = 0;

        /**
         * CreateStoryRequest chatNovelScript.
         * @member {tellerscript.ITellerScript|null|undefined} chatNovelScript
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.chatNovelScript = null;

        /**
         * CreateStoryRequest novelScript.
         * @member {google.protobuf.IStringValue|null|undefined} novelScript
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.novelScript = null;

        /**
         * CreateStoryRequest sensitiveFlag.
         * @member {query_types.SensitiveFlag} sensitiveFlag
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        CreateStoryRequest.prototype.sensitiveFlag = 0;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CreateStoryRequest script.
         * @member {"chatNovelScript"|"novelScript"|undefined} script
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         */
        Object.defineProperty(CreateStoryRequest.prototype, "script", {
            get: $util.oneOfGetter($oneOfFields = ["chatNovelScript", "novelScript"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateStoryRequest instance using the specified properties.
         * @function create
         * @memberof mutation_story.CreateStoryRequest
         * @static
         * @param {mutation_story.ICreateStoryRequest=} [properties] Properties to set
         * @returns {mutation_story.CreateStoryRequest} CreateStoryRequest instance
         */
        CreateStoryRequest.create = function create(properties) {
            return new CreateStoryRequest(properties);
        };

        /**
         * Encodes the specified CreateStoryRequest message. Does not implicitly {@link mutation_story.CreateStoryRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_story.CreateStoryRequest
         * @static
         * @param {mutation_story.ICreateStoryRequest} message CreateStoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateStoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.seriesIndex != null && Object.hasOwnProperty.call(message, "seriesIndex"))
                $root.google.protobuf.Int32Value.encode(message.seriesIndex, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.thumbnailId != null && Object.hasOwnProperty.call(message, "thumbnailId"))
                $root.google.protobuf.StringValue.encode(message.thumbnailId, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.status);
            if (message.scriptType != null && Object.hasOwnProperty.call(message, "scriptType"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.scriptType);
            if (message.chatNovelScript != null && Object.hasOwnProperty.call(message, "chatNovelScript"))
                $root.tellerscript.TellerScript.encode(message.chatNovelScript, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.novelScript != null && Object.hasOwnProperty.call(message, "novelScript"))
                $root.google.protobuf.StringValue.encode(message.novelScript, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.sensitiveFlag != null && Object.hasOwnProperty.call(message, "sensitiveFlag"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.sensitiveFlag);
            return writer;
        };

        /**
         * Decodes a CreateStoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story.CreateStoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story.CreateStoryRequest} CreateStoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateStoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story.CreateStoryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.seriesIndex = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.thumbnailId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.status = reader.int32();
                    break;
                case 9:
                    message.scriptType = reader.int32();
                    break;
                case 10:
                    message.chatNovelScript = $root.tellerscript.TellerScript.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.novelScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.sensitiveFlag = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateStoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story.CreateStoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story.CreateStoryRequest} CreateStoryRequest
         */
        CreateStoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story.CreateStoryRequest)
                return object;
            var message = new $root.mutation_story.CreateStoryRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".mutation_story.CreateStoryRequest.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".mutation_story.CreateStoryRequest.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            if (object.seriesIndex != null) {
                if (typeof object.seriesIndex !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.seriesIndex: object expected");
                message.seriesIndex = $root.google.protobuf.Int32Value.fromObject(object.seriesIndex);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailId != null) {
                if (typeof object.thumbnailId !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.thumbnailId: object expected");
                message.thumbnailId = $root.google.protobuf.StringValue.fromObject(object.thumbnailId);
            }
            switch (object.status) {
            case "STORY_STATUS_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "DRAFT":
            case 1:
                message.status = 1;
                break;
            case "RESERVE":
            case 2:
                message.status = 2;
                break;
            case "PUBLISH":
            case 3:
                message.status = 3;
                break;
            case "REJECT":
            case 4:
                message.status = 4;
                break;
            }
            switch (object.scriptType) {
            case "STORY_SCRIPT_TYPE_UNSPECIFIED":
            case 0:
                message.scriptType = 0;
                break;
            case "STORY_SCRIPT_TYPE_CHAT_NOVEL":
            case 1:
                message.scriptType = 1;
                break;
            case "STORY_SCRIPT_TYPE_NOVEL":
            case 2:
                message.scriptType = 2;
                break;
            case "STORY_SCRIPT_TYPE_VIDEO":
            case 3:
                message.scriptType = 3;
                break;
            case "STORY_SCRIPT_TYPE_AUDIO":
            case 4:
                message.scriptType = 4;
                break;
            }
            if (object.chatNovelScript != null) {
                if (typeof object.chatNovelScript !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.chatNovelScript: object expected");
                message.chatNovelScript = $root.tellerscript.TellerScript.fromObject(object.chatNovelScript);
            }
            if (object.novelScript != null) {
                if (typeof object.novelScript !== "object")
                    throw TypeError(".mutation_story.CreateStoryRequest.novelScript: object expected");
                message.novelScript = $root.google.protobuf.StringValue.fromObject(object.novelScript);
            }
            switch (object.sensitiveFlag) {
            case "SENSITIVE_FLAG_UNSPECIFIED":
            case 0:
                message.sensitiveFlag = 0;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_USER":
            case 1:
                message.sensitiveFlag = 1;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_ADMIN":
            case 2:
                message.sensitiveFlag = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateStoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story.CreateStoryRequest
         * @static
         * @param {mutation_story.CreateStoryRequest} message CreateStoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateStoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.userId = null;
                object.seriesId = null;
                object.seriesIndex = null;
                object.title = null;
                object.description = null;
                object.thumbnailId = null;
                object.status = options.enums === String ? "STORY_STATUS_UNKNOWN" : 0;
                object.scriptType = options.enums === String ? "STORY_SCRIPT_TYPE_UNSPECIFIED" : 0;
                object.sensitiveFlag = options.enums === String ? "SENSITIVE_FLAG_UNSPECIFIED" : 0;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            if (message.seriesIndex != null && message.hasOwnProperty("seriesIndex"))
                object.seriesIndex = $root.google.protobuf.Int32Value.toObject(message.seriesIndex, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailId != null && message.hasOwnProperty("thumbnailId"))
                object.thumbnailId = $root.google.protobuf.StringValue.toObject(message.thumbnailId, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.story.StoryStatus[message.status] : message.status;
            if (message.scriptType != null && message.hasOwnProperty("scriptType"))
                object.scriptType = options.enums === String ? $root.query_types.StoryScriptType[message.scriptType] : message.scriptType;
            if (message.chatNovelScript != null && message.hasOwnProperty("chatNovelScript")) {
                object.chatNovelScript = $root.tellerscript.TellerScript.toObject(message.chatNovelScript, options);
                if (options.oneofs)
                    object.script = "chatNovelScript";
            }
            if (message.novelScript != null && message.hasOwnProperty("novelScript")) {
                object.novelScript = $root.google.protobuf.StringValue.toObject(message.novelScript, options);
                if (options.oneofs)
                    object.script = "novelScript";
            }
            if (message.sensitiveFlag != null && message.hasOwnProperty("sensitiveFlag"))
                object.sensitiveFlag = options.enums === String ? $root.query_types.SensitiveFlag[message.sensitiveFlag] : message.sensitiveFlag;
            return object;
        };

        /**
         * Converts this CreateStoryRequest to JSON.
         * @function toJSON
         * @memberof mutation_story.CreateStoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateStoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateStoryRequest
         * @function getTypeUrl
         * @memberof mutation_story.CreateStoryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateStoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story.CreateStoryRequest";
        };

        return CreateStoryRequest;
    })();

    mutation_story.CreateStoryResponse = (function() {

        /**
         * Properties of a CreateStoryResponse.
         * @memberof mutation_story
         * @interface ICreateStoryResponse
         * @property {google.protobuf.IStringValue|null} [id] CreateStoryResponse id
         */

        /**
         * Constructs a new CreateStoryResponse.
         * @memberof mutation_story
         * @classdesc Represents a CreateStoryResponse.
         * @implements ICreateStoryResponse
         * @constructor
         * @param {mutation_story.ICreateStoryResponse=} [properties] Properties to set
         */
        function CreateStoryResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateStoryResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_story.CreateStoryResponse
         * @instance
         */
        CreateStoryResponse.prototype.id = null;

        /**
         * Creates a new CreateStoryResponse instance using the specified properties.
         * @function create
         * @memberof mutation_story.CreateStoryResponse
         * @static
         * @param {mutation_story.ICreateStoryResponse=} [properties] Properties to set
         * @returns {mutation_story.CreateStoryResponse} CreateStoryResponse instance
         */
        CreateStoryResponse.create = function create(properties) {
            return new CreateStoryResponse(properties);
        };

        /**
         * Encodes the specified CreateStoryResponse message. Does not implicitly {@link mutation_story.CreateStoryResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_story.CreateStoryResponse
         * @static
         * @param {mutation_story.ICreateStoryResponse} message CreateStoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateStoryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreateStoryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story.CreateStoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story.CreateStoryResponse} CreateStoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateStoryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story.CreateStoryResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateStoryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story.CreateStoryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story.CreateStoryResponse} CreateStoryResponse
         */
        CreateStoryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story.CreateStoryResponse)
                return object;
            var message = new $root.mutation_story.CreateStoryResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_story.CreateStoryResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateStoryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story.CreateStoryResponse
         * @static
         * @param {mutation_story.CreateStoryResponse} message CreateStoryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateStoryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this CreateStoryResponse to JSON.
         * @function toJSON
         * @memberof mutation_story.CreateStoryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateStoryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateStoryResponse
         * @function getTypeUrl
         * @memberof mutation_story.CreateStoryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateStoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story.CreateStoryResponse";
        };

        return CreateStoryResponse;
    })();

    mutation_story.UpdateStoryRequest = (function() {

        /**
         * Properties of an UpdateStoryRequest.
         * @memberof mutation_story
         * @interface IUpdateStoryRequest
         * @property {google.protobuf.IStringValue|null} [id] UpdateStoryRequest id
         * @property {google.protobuf.IStringValue|null} [userId] UpdateStoryRequest userId
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] UpdateStoryRequest tags
         * @property {google.protobuf.IStringValue|null} [seriesId] UpdateStoryRequest seriesId
         * @property {google.protobuf.IInt32Value|null} [seriesIndex] UpdateStoryRequest seriesIndex
         * @property {google.protobuf.IStringValue|null} [title] UpdateStoryRequest title
         * @property {google.protobuf.IStringValue|null} [description] UpdateStoryRequest description
         * @property {google.protobuf.IStringValue|null} [thumbnailId] UpdateStoryRequest thumbnailId
         * @property {story.StoryStatus|null} [status] UpdateStoryRequest status
         * @property {query_types.StoryScriptType|null} [scriptType] UpdateStoryRequest scriptType
         * @property {tellerscript.ITellerScript|null} [chatNovelScript] UpdateStoryRequest chatNovelScript
         * @property {google.protobuf.IStringValue|null} [novelScript] UpdateStoryRequest novelScript
         * @property {query_types.SensitiveFlag|null} [sensitiveFlag] UpdateStoryRequest sensitiveFlag
         */

        /**
         * Constructs a new UpdateStoryRequest.
         * @memberof mutation_story
         * @classdesc Represents an UpdateStoryRequest.
         * @implements IUpdateStoryRequest
         * @constructor
         * @param {mutation_story.IUpdateStoryRequest=} [properties] Properties to set
         */
        function UpdateStoryRequest(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateStoryRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.id = null;

        /**
         * UpdateStoryRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.userId = null;

        /**
         * UpdateStoryRequest tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.tags = $util.emptyArray;

        /**
         * UpdateStoryRequest seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.seriesId = null;

        /**
         * UpdateStoryRequest seriesIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} seriesIndex
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.seriesIndex = null;

        /**
         * UpdateStoryRequest title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.title = null;

        /**
         * UpdateStoryRequest description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.description = null;

        /**
         * UpdateStoryRequest thumbnailId.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailId
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.thumbnailId = null;

        /**
         * UpdateStoryRequest status.
         * @member {story.StoryStatus} status
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.status = 0;

        /**
         * UpdateStoryRequest scriptType.
         * @member {query_types.StoryScriptType} scriptType
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.scriptType = 0;

        /**
         * UpdateStoryRequest chatNovelScript.
         * @member {tellerscript.ITellerScript|null|undefined} chatNovelScript
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.chatNovelScript = null;

        /**
         * UpdateStoryRequest novelScript.
         * @member {google.protobuf.IStringValue|null|undefined} novelScript
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.novelScript = null;

        /**
         * UpdateStoryRequest sensitiveFlag.
         * @member {query_types.SensitiveFlag} sensitiveFlag
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        UpdateStoryRequest.prototype.sensitiveFlag = 0;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpdateStoryRequest script.
         * @member {"chatNovelScript"|"novelScript"|undefined} script
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         */
        Object.defineProperty(UpdateStoryRequest.prototype, "script", {
            get: $util.oneOfGetter($oneOfFields = ["chatNovelScript", "novelScript"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpdateStoryRequest instance using the specified properties.
         * @function create
         * @memberof mutation_story.UpdateStoryRequest
         * @static
         * @param {mutation_story.IUpdateStoryRequest=} [properties] Properties to set
         * @returns {mutation_story.UpdateStoryRequest} UpdateStoryRequest instance
         */
        UpdateStoryRequest.create = function create(properties) {
            return new UpdateStoryRequest(properties);
        };

        /**
         * Encodes the specified UpdateStoryRequest message. Does not implicitly {@link mutation_story.UpdateStoryRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_story.UpdateStoryRequest
         * @static
         * @param {mutation_story.IUpdateStoryRequest} message UpdateStoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateStoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.seriesIndex != null && Object.hasOwnProperty.call(message, "seriesIndex"))
                $root.google.protobuf.Int32Value.encode(message.seriesIndex, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.thumbnailId != null && Object.hasOwnProperty.call(message, "thumbnailId"))
                $root.google.protobuf.StringValue.encode(message.thumbnailId, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
            if (message.scriptType != null && Object.hasOwnProperty.call(message, "scriptType"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.scriptType);
            if (message.chatNovelScript != null && Object.hasOwnProperty.call(message, "chatNovelScript"))
                $root.tellerscript.TellerScript.encode(message.chatNovelScript, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.novelScript != null && Object.hasOwnProperty.call(message, "novelScript"))
                $root.google.protobuf.StringValue.encode(message.novelScript, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.sensitiveFlag != null && Object.hasOwnProperty.call(message, "sensitiveFlag"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.sensitiveFlag);
            return writer;
        };

        /**
         * Decodes an UpdateStoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story.UpdateStoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story.UpdateStoryRequest} UpdateStoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateStoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story.UpdateStoryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.seriesIndex = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.thumbnailId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.status = reader.int32();
                    break;
                case 10:
                    message.scriptType = reader.int32();
                    break;
                case 11:
                    message.chatNovelScript = $root.tellerscript.TellerScript.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.novelScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.sensitiveFlag = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateStoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story.UpdateStoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story.UpdateStoryRequest} UpdateStoryRequest
         */
        UpdateStoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story.UpdateStoryRequest)
                return object;
            var message = new $root.mutation_story.UpdateStoryRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".mutation_story.UpdateStoryRequest.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".mutation_story.UpdateStoryRequest.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            if (object.seriesIndex != null) {
                if (typeof object.seriesIndex !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.seriesIndex: object expected");
                message.seriesIndex = $root.google.protobuf.Int32Value.fromObject(object.seriesIndex);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailId != null) {
                if (typeof object.thumbnailId !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.thumbnailId: object expected");
                message.thumbnailId = $root.google.protobuf.StringValue.fromObject(object.thumbnailId);
            }
            switch (object.status) {
            case "STORY_STATUS_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "DRAFT":
            case 1:
                message.status = 1;
                break;
            case "RESERVE":
            case 2:
                message.status = 2;
                break;
            case "PUBLISH":
            case 3:
                message.status = 3;
                break;
            case "REJECT":
            case 4:
                message.status = 4;
                break;
            }
            switch (object.scriptType) {
            case "STORY_SCRIPT_TYPE_UNSPECIFIED":
            case 0:
                message.scriptType = 0;
                break;
            case "STORY_SCRIPT_TYPE_CHAT_NOVEL":
            case 1:
                message.scriptType = 1;
                break;
            case "STORY_SCRIPT_TYPE_NOVEL":
            case 2:
                message.scriptType = 2;
                break;
            case "STORY_SCRIPT_TYPE_VIDEO":
            case 3:
                message.scriptType = 3;
                break;
            case "STORY_SCRIPT_TYPE_AUDIO":
            case 4:
                message.scriptType = 4;
                break;
            }
            if (object.chatNovelScript != null) {
                if (typeof object.chatNovelScript !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.chatNovelScript: object expected");
                message.chatNovelScript = $root.tellerscript.TellerScript.fromObject(object.chatNovelScript);
            }
            if (object.novelScript != null) {
                if (typeof object.novelScript !== "object")
                    throw TypeError(".mutation_story.UpdateStoryRequest.novelScript: object expected");
                message.novelScript = $root.google.protobuf.StringValue.fromObject(object.novelScript);
            }
            switch (object.sensitiveFlag) {
            case "SENSITIVE_FLAG_UNSPECIFIED":
            case 0:
                message.sensitiveFlag = 0;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_USER":
            case 1:
                message.sensitiveFlag = 1;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_ADMIN":
            case 2:
                message.sensitiveFlag = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateStoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story.UpdateStoryRequest
         * @static
         * @param {mutation_story.UpdateStoryRequest} message UpdateStoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateStoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.id = null;
                object.userId = null;
                object.seriesId = null;
                object.seriesIndex = null;
                object.title = null;
                object.description = null;
                object.thumbnailId = null;
                object.status = options.enums === String ? "STORY_STATUS_UNKNOWN" : 0;
                object.scriptType = options.enums === String ? "STORY_SCRIPT_TYPE_UNSPECIFIED" : 0;
                object.sensitiveFlag = options.enums === String ? "SENSITIVE_FLAG_UNSPECIFIED" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            if (message.seriesIndex != null && message.hasOwnProperty("seriesIndex"))
                object.seriesIndex = $root.google.protobuf.Int32Value.toObject(message.seriesIndex, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailId != null && message.hasOwnProperty("thumbnailId"))
                object.thumbnailId = $root.google.protobuf.StringValue.toObject(message.thumbnailId, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.story.StoryStatus[message.status] : message.status;
            if (message.scriptType != null && message.hasOwnProperty("scriptType"))
                object.scriptType = options.enums === String ? $root.query_types.StoryScriptType[message.scriptType] : message.scriptType;
            if (message.chatNovelScript != null && message.hasOwnProperty("chatNovelScript")) {
                object.chatNovelScript = $root.tellerscript.TellerScript.toObject(message.chatNovelScript, options);
                if (options.oneofs)
                    object.script = "chatNovelScript";
            }
            if (message.novelScript != null && message.hasOwnProperty("novelScript")) {
                object.novelScript = $root.google.protobuf.StringValue.toObject(message.novelScript, options);
                if (options.oneofs)
                    object.script = "novelScript";
            }
            if (message.sensitiveFlag != null && message.hasOwnProperty("sensitiveFlag"))
                object.sensitiveFlag = options.enums === String ? $root.query_types.SensitiveFlag[message.sensitiveFlag] : message.sensitiveFlag;
            return object;
        };

        /**
         * Converts this UpdateStoryRequest to JSON.
         * @function toJSON
         * @memberof mutation_story.UpdateStoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateStoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateStoryRequest
         * @function getTypeUrl
         * @memberof mutation_story.UpdateStoryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateStoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story.UpdateStoryRequest";
        };

        return UpdateStoryRequest;
    })();

    mutation_story.UpdateStoryResponse = (function() {

        /**
         * Properties of an UpdateStoryResponse.
         * @memberof mutation_story
         * @interface IUpdateStoryResponse
         * @property {google.protobuf.IStringValue|null} [id] UpdateStoryResponse id
         */

        /**
         * Constructs a new UpdateStoryResponse.
         * @memberof mutation_story
         * @classdesc Represents an UpdateStoryResponse.
         * @implements IUpdateStoryResponse
         * @constructor
         * @param {mutation_story.IUpdateStoryResponse=} [properties] Properties to set
         */
        function UpdateStoryResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateStoryResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_story.UpdateStoryResponse
         * @instance
         */
        UpdateStoryResponse.prototype.id = null;

        /**
         * Creates a new UpdateStoryResponse instance using the specified properties.
         * @function create
         * @memberof mutation_story.UpdateStoryResponse
         * @static
         * @param {mutation_story.IUpdateStoryResponse=} [properties] Properties to set
         * @returns {mutation_story.UpdateStoryResponse} UpdateStoryResponse instance
         */
        UpdateStoryResponse.create = function create(properties) {
            return new UpdateStoryResponse(properties);
        };

        /**
         * Encodes the specified UpdateStoryResponse message. Does not implicitly {@link mutation_story.UpdateStoryResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_story.UpdateStoryResponse
         * @static
         * @param {mutation_story.IUpdateStoryResponse} message UpdateStoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateStoryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateStoryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story.UpdateStoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story.UpdateStoryResponse} UpdateStoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateStoryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story.UpdateStoryResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an UpdateStoryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story.UpdateStoryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story.UpdateStoryResponse} UpdateStoryResponse
         */
        UpdateStoryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story.UpdateStoryResponse)
                return object;
            var message = new $root.mutation_story.UpdateStoryResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_story.UpdateStoryResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateStoryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story.UpdateStoryResponse
         * @static
         * @param {mutation_story.UpdateStoryResponse} message UpdateStoryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateStoryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this UpdateStoryResponse to JSON.
         * @function toJSON
         * @memberof mutation_story.UpdateStoryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateStoryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateStoryResponse
         * @function getTypeUrl
         * @memberof mutation_story.UpdateStoryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateStoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story.UpdateStoryResponse";
        };

        return UpdateStoryResponse;
    })();

    mutation_story.DeleteStoryRequest = (function() {

        /**
         * Properties of a DeleteStoryRequest.
         * @memberof mutation_story
         * @interface IDeleteStoryRequest
         * @property {google.protobuf.IStringValue|null} [id] DeleteStoryRequest id
         */

        /**
         * Constructs a new DeleteStoryRequest.
         * @memberof mutation_story
         * @classdesc Represents a DeleteStoryRequest.
         * @implements IDeleteStoryRequest
         * @constructor
         * @param {mutation_story.IDeleteStoryRequest=} [properties] Properties to set
         */
        function DeleteStoryRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteStoryRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof mutation_story.DeleteStoryRequest
         * @instance
         */
        DeleteStoryRequest.prototype.id = null;

        /**
         * Creates a new DeleteStoryRequest instance using the specified properties.
         * @function create
         * @memberof mutation_story.DeleteStoryRequest
         * @static
         * @param {mutation_story.IDeleteStoryRequest=} [properties] Properties to set
         * @returns {mutation_story.DeleteStoryRequest} DeleteStoryRequest instance
         */
        DeleteStoryRequest.create = function create(properties) {
            return new DeleteStoryRequest(properties);
        };

        /**
         * Encodes the specified DeleteStoryRequest message. Does not implicitly {@link mutation_story.DeleteStoryRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_story.DeleteStoryRequest
         * @static
         * @param {mutation_story.IDeleteStoryRequest} message DeleteStoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteStoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DeleteStoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story.DeleteStoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story.DeleteStoryRequest} DeleteStoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteStoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story.DeleteStoryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a DeleteStoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story.DeleteStoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story.DeleteStoryRequest} DeleteStoryRequest
         */
        DeleteStoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story.DeleteStoryRequest)
                return object;
            var message = new $root.mutation_story.DeleteStoryRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".mutation_story.DeleteStoryRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteStoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story.DeleteStoryRequest
         * @static
         * @param {mutation_story.DeleteStoryRequest} message DeleteStoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteStoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = null;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            return object;
        };

        /**
         * Converts this DeleteStoryRequest to JSON.
         * @function toJSON
         * @memberof mutation_story.DeleteStoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteStoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteStoryRequest
         * @function getTypeUrl
         * @memberof mutation_story.DeleteStoryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteStoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story.DeleteStoryRequest";
        };

        return DeleteStoryRequest;
    })();

    return mutation_story;
})();

$root.mutation_story_video = (function() {

    /**
     * Namespace mutation_story_video.
     * @exports mutation_story_video
     * @namespace
     */
    var mutation_story_video = {};

    mutation_story_video.CreateStoryVideoAsyncRequest = (function() {

        /**
         * Properties of a CreateStoryVideoAsyncRequest.
         * @memberof mutation_story_video
         * @interface ICreateStoryVideoAsyncRequest
         * @property {google.protobuf.IStringValue|null} [storyId] CreateStoryVideoAsyncRequest storyId
         */

        /**
         * Constructs a new CreateStoryVideoAsyncRequest.
         * @memberof mutation_story_video
         * @classdesc Represents a CreateStoryVideoAsyncRequest.
         * @implements ICreateStoryVideoAsyncRequest
         * @constructor
         * @param {mutation_story_video.ICreateStoryVideoAsyncRequest=} [properties] Properties to set
         */
        function CreateStoryVideoAsyncRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateStoryVideoAsyncRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @instance
         */
        CreateStoryVideoAsyncRequest.prototype.storyId = null;

        /**
         * Creates a new CreateStoryVideoAsyncRequest instance using the specified properties.
         * @function create
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @static
         * @param {mutation_story_video.ICreateStoryVideoAsyncRequest=} [properties] Properties to set
         * @returns {mutation_story_video.CreateStoryVideoAsyncRequest} CreateStoryVideoAsyncRequest instance
         */
        CreateStoryVideoAsyncRequest.create = function create(properties) {
            return new CreateStoryVideoAsyncRequest(properties);
        };

        /**
         * Encodes the specified CreateStoryVideoAsyncRequest message. Does not implicitly {@link mutation_story_video.CreateStoryVideoAsyncRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @static
         * @param {mutation_story_video.ICreateStoryVideoAsyncRequest} message CreateStoryVideoAsyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateStoryVideoAsyncRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreateStoryVideoAsyncRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story_video.CreateStoryVideoAsyncRequest} CreateStoryVideoAsyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateStoryVideoAsyncRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story_video.CreateStoryVideoAsyncRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateStoryVideoAsyncRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story_video.CreateStoryVideoAsyncRequest} CreateStoryVideoAsyncRequest
         */
        CreateStoryVideoAsyncRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story_video.CreateStoryVideoAsyncRequest)
                return object;
            var message = new $root.mutation_story_video.CreateStoryVideoAsyncRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".mutation_story_video.CreateStoryVideoAsyncRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateStoryVideoAsyncRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @static
         * @param {mutation_story_video.CreateStoryVideoAsyncRequest} message CreateStoryVideoAsyncRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateStoryVideoAsyncRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.storyId = null;
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            return object;
        };

        /**
         * Converts this CreateStoryVideoAsyncRequest to JSON.
         * @function toJSON
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateStoryVideoAsyncRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateStoryVideoAsyncRequest
         * @function getTypeUrl
         * @memberof mutation_story_video.CreateStoryVideoAsyncRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateStoryVideoAsyncRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story_video.CreateStoryVideoAsyncRequest";
        };

        return CreateStoryVideoAsyncRequest;
    })();

    mutation_story_video.CreateStoryVideoAsyncResponse = (function() {

        /**
         * Properties of a CreateStoryVideoAsyncResponse.
         * @memberof mutation_story_video
         * @interface ICreateStoryVideoAsyncResponse
         */

        /**
         * Constructs a new CreateStoryVideoAsyncResponse.
         * @memberof mutation_story_video
         * @classdesc Represents a CreateStoryVideoAsyncResponse.
         * @implements ICreateStoryVideoAsyncResponse
         * @constructor
         * @param {mutation_story_video.ICreateStoryVideoAsyncResponse=} [properties] Properties to set
         */
        function CreateStoryVideoAsyncResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CreateStoryVideoAsyncResponse instance using the specified properties.
         * @function create
         * @memberof mutation_story_video.CreateStoryVideoAsyncResponse
         * @static
         * @param {mutation_story_video.ICreateStoryVideoAsyncResponse=} [properties] Properties to set
         * @returns {mutation_story_video.CreateStoryVideoAsyncResponse} CreateStoryVideoAsyncResponse instance
         */
        CreateStoryVideoAsyncResponse.create = function create(properties) {
            return new CreateStoryVideoAsyncResponse(properties);
        };

        /**
         * Encodes the specified CreateStoryVideoAsyncResponse message. Does not implicitly {@link mutation_story_video.CreateStoryVideoAsyncResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_story_video.CreateStoryVideoAsyncResponse
         * @static
         * @param {mutation_story_video.ICreateStoryVideoAsyncResponse} message CreateStoryVideoAsyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateStoryVideoAsyncResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a CreateStoryVideoAsyncResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story_video.CreateStoryVideoAsyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story_video.CreateStoryVideoAsyncResponse} CreateStoryVideoAsyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateStoryVideoAsyncResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story_video.CreateStoryVideoAsyncResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreateStoryVideoAsyncResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story_video.CreateStoryVideoAsyncResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story_video.CreateStoryVideoAsyncResponse} CreateStoryVideoAsyncResponse
         */
        CreateStoryVideoAsyncResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story_video.CreateStoryVideoAsyncResponse)
                return object;
            return new $root.mutation_story_video.CreateStoryVideoAsyncResponse();
        };

        /**
         * Creates a plain object from a CreateStoryVideoAsyncResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story_video.CreateStoryVideoAsyncResponse
         * @static
         * @param {mutation_story_video.CreateStoryVideoAsyncResponse} message CreateStoryVideoAsyncResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateStoryVideoAsyncResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CreateStoryVideoAsyncResponse to JSON.
         * @function toJSON
         * @memberof mutation_story_video.CreateStoryVideoAsyncResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateStoryVideoAsyncResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateStoryVideoAsyncResponse
         * @function getTypeUrl
         * @memberof mutation_story_video.CreateStoryVideoAsyncResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateStoryVideoAsyncResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story_video.CreateStoryVideoAsyncResponse";
        };

        return CreateStoryVideoAsyncResponse;
    })();

    return mutation_story_video;
})();

$root.mutation_story_license = (function() {

    /**
     * Namespace mutation_story_license.
     * @exports mutation_story_license
     * @namespace
     */
    var mutation_story_license = {};

    mutation_story_license.AcquireFullStoryRequest = (function() {

        /**
         * Properties of an AcquireFullStoryRequest.
         * @memberof mutation_story_license
         * @interface IAcquireFullStoryRequest
         * @property {google.protobuf.IStringValue|null} [storyId] AcquireFullStoryRequest storyId
         */

        /**
         * Constructs a new AcquireFullStoryRequest.
         * @memberof mutation_story_license
         * @classdesc Represents an AcquireFullStoryRequest.
         * @implements IAcquireFullStoryRequest
         * @constructor
         * @param {mutation_story_license.IAcquireFullStoryRequest=} [properties] Properties to set
         */
        function AcquireFullStoryRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AcquireFullStoryRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @instance
         */
        AcquireFullStoryRequest.prototype.storyId = null;

        /**
         * Creates a new AcquireFullStoryRequest instance using the specified properties.
         * @function create
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @static
         * @param {mutation_story_license.IAcquireFullStoryRequest=} [properties] Properties to set
         * @returns {mutation_story_license.AcquireFullStoryRequest} AcquireFullStoryRequest instance
         */
        AcquireFullStoryRequest.create = function create(properties) {
            return new AcquireFullStoryRequest(properties);
        };

        /**
         * Encodes the specified AcquireFullStoryRequest message. Does not implicitly {@link mutation_story_license.AcquireFullStoryRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @static
         * @param {mutation_story_license.IAcquireFullStoryRequest} message AcquireFullStoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AcquireFullStoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AcquireFullStoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story_license.AcquireFullStoryRequest} AcquireFullStoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AcquireFullStoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story_license.AcquireFullStoryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AcquireFullStoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story_license.AcquireFullStoryRequest} AcquireFullStoryRequest
         */
        AcquireFullStoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story_license.AcquireFullStoryRequest)
                return object;
            var message = new $root.mutation_story_license.AcquireFullStoryRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".mutation_story_license.AcquireFullStoryRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            return message;
        };

        /**
         * Creates a plain object from an AcquireFullStoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @static
         * @param {mutation_story_license.AcquireFullStoryRequest} message AcquireFullStoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AcquireFullStoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.storyId = null;
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            return object;
        };

        /**
         * Converts this AcquireFullStoryRequest to JSON.
         * @function toJSON
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AcquireFullStoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AcquireFullStoryRequest
         * @function getTypeUrl
         * @memberof mutation_story_license.AcquireFullStoryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AcquireFullStoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story_license.AcquireFullStoryRequest";
        };

        return AcquireFullStoryRequest;
    })();

    mutation_story_license.AcquireFullStoryResponse = (function() {

        /**
         * Properties of an AcquireFullStoryResponse.
         * @memberof mutation_story_license
         * @interface IAcquireFullStoryResponse
         * @property {query_types.StoryAvailability|null} [availability] AcquireFullStoryResponse availability
         * @property {mutation_story_license.AcquireFullStoryResponse.Result|null} [result] AcquireFullStoryResponse result
         */

        /**
         * Constructs a new AcquireFullStoryResponse.
         * @memberof mutation_story_license
         * @classdesc Represents an AcquireFullStoryResponse.
         * @implements IAcquireFullStoryResponse
         * @constructor
         * @param {mutation_story_license.IAcquireFullStoryResponse=} [properties] Properties to set
         */
        function AcquireFullStoryResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AcquireFullStoryResponse availability.
         * @member {query_types.StoryAvailability} availability
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @instance
         */
        AcquireFullStoryResponse.prototype.availability = 0;

        /**
         * AcquireFullStoryResponse result.
         * @member {mutation_story_license.AcquireFullStoryResponse.Result} result
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @instance
         */
        AcquireFullStoryResponse.prototype.result = 0;

        /**
         * Creates a new AcquireFullStoryResponse instance using the specified properties.
         * @function create
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @static
         * @param {mutation_story_license.IAcquireFullStoryResponse=} [properties] Properties to set
         * @returns {mutation_story_license.AcquireFullStoryResponse} AcquireFullStoryResponse instance
         */
        AcquireFullStoryResponse.create = function create(properties) {
            return new AcquireFullStoryResponse(properties);
        };

        /**
         * Encodes the specified AcquireFullStoryResponse message. Does not implicitly {@link mutation_story_license.AcquireFullStoryResponse.verify|verify} messages.
         * @function encode
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @static
         * @param {mutation_story_license.IAcquireFullStoryResponse} message AcquireFullStoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AcquireFullStoryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.availability != null && Object.hasOwnProperty.call(message, "availability"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.availability);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Decodes an AcquireFullStoryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_story_license.AcquireFullStoryResponse} AcquireFullStoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AcquireFullStoryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_story_license.AcquireFullStoryResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.availability = reader.int32();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AcquireFullStoryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_story_license.AcquireFullStoryResponse} AcquireFullStoryResponse
         */
        AcquireFullStoryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_story_license.AcquireFullStoryResponse)
                return object;
            var message = new $root.mutation_story_license.AcquireFullStoryResponse();
            switch (object.availability) {
            case "STORY_AVAILABILITY_UNSPECIFIED":
            case 0:
                message.availability = 0;
                break;
            case "STORY_AVAILABILITY_REQUIRE_VIP":
            case 1:
                message.availability = 1;
                break;
            case "STORY_AVAILABILITY_REQUIRE_WAIT":
            case 2:
                message.availability = 2;
                break;
            case "STORY_AVAILABILITY_REQUIRE_NONE":
            case 3:
                message.availability = 3;
                break;
            case "STORY_AVAILABILITY_REQUIRE_FOLLOWING":
            case 4:
                message.availability = 4;
                break;
            case "STORY_AVAILABILITY_REQUIRE_FOLLOWED":
            case 5:
                message.availability = 5;
                break;
            case "STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING":
            case 6:
                message.availability = 6;
                break;
            }
            switch (object.result) {
            case "RESULT_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "RESULT_REQUIRE_NONE":
            case 1:
                message.result = 1;
                break;
            case "RESULT_REQUIRE_VIP":
            case 2:
                message.result = 2;
                break;
            case "RESULT_SHORTAGE":
            case 3:
                message.result = 3;
                break;
            case "RESULT_SERIES_CHARGE_USED":
            case 4:
                message.result = 4;
                break;
            case "RESULT_TICKET_USED":
            case 5:
                message.result = 5;
                break;
            case "RESULT_REQUIRE_FOLLOWING":
            case 6:
                message.result = 6;
                break;
            case "RESULT_REQUIRE_MUTUAL_FOLLOWING":
            case 7:
                message.result = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AcquireFullStoryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @static
         * @param {mutation_story_license.AcquireFullStoryResponse} message AcquireFullStoryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AcquireFullStoryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.availability = options.enums === String ? "STORY_AVAILABILITY_UNSPECIFIED" : 0;
                object.result = options.enums === String ? "RESULT_UNSPECIFIED" : 0;
            }
            if (message.availability != null && message.hasOwnProperty("availability"))
                object.availability = options.enums === String ? $root.query_types.StoryAvailability[message.availability] : message.availability;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.mutation_story_license.AcquireFullStoryResponse.Result[message.result] : message.result;
            return object;
        };

        /**
         * Converts this AcquireFullStoryResponse to JSON.
         * @function toJSON
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AcquireFullStoryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AcquireFullStoryResponse
         * @function getTypeUrl
         * @memberof mutation_story_license.AcquireFullStoryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AcquireFullStoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_story_license.AcquireFullStoryResponse";
        };

        /**
         * Result enum.
         * @name mutation_story_license.AcquireFullStoryResponse.Result
         * @enum {number}
         * @property {number} RESULT_UNSPECIFIED=0 RESULT_UNSPECIFIED value
         * @property {number} RESULT_REQUIRE_NONE=1 RESULT_REQUIRE_NONE value
         * @property {number} RESULT_REQUIRE_VIP=2 RESULT_REQUIRE_VIP value
         * @property {number} RESULT_SHORTAGE=3 RESULT_SHORTAGE value
         * @property {number} RESULT_SERIES_CHARGE_USED=4 RESULT_SERIES_CHARGE_USED value
         * @property {number} RESULT_TICKET_USED=5 RESULT_TICKET_USED value
         * @property {number} RESULT_REQUIRE_FOLLOWING=6 RESULT_REQUIRE_FOLLOWING value
         * @property {number} RESULT_REQUIRE_MUTUAL_FOLLOWING=7 RESULT_REQUIRE_MUTUAL_FOLLOWING value
         */
        AcquireFullStoryResponse.Result = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RESULT_UNSPECIFIED"] = 0;
            values[valuesById[1] = "RESULT_REQUIRE_NONE"] = 1;
            values[valuesById[2] = "RESULT_REQUIRE_VIP"] = 2;
            values[valuesById[3] = "RESULT_SHORTAGE"] = 3;
            values[valuesById[4] = "RESULT_SERIES_CHARGE_USED"] = 4;
            values[valuesById[5] = "RESULT_TICKET_USED"] = 5;
            values[valuesById[6] = "RESULT_REQUIRE_FOLLOWING"] = 6;
            values[valuesById[7] = "RESULT_REQUIRE_MUTUAL_FOLLOWING"] = 7;
            return values;
        })();

        return AcquireFullStoryResponse;
    })();

    return mutation_story_license;
})();

$root.mutation_user = (function() {

    /**
     * Namespace mutation_user.
     * @exports mutation_user
     * @namespace
     */
    var mutation_user = {};

    mutation_user.DeleteMeRequest = (function() {

        /**
         * Properties of a DeleteMeRequest.
         * @memberof mutation_user
         * @interface IDeleteMeRequest
         */

        /**
         * Constructs a new DeleteMeRequest.
         * @memberof mutation_user
         * @classdesc Represents a DeleteMeRequest.
         * @implements IDeleteMeRequest
         * @constructor
         * @param {mutation_user.IDeleteMeRequest=} [properties] Properties to set
         */
        function DeleteMeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteMeRequest instance using the specified properties.
         * @function create
         * @memberof mutation_user.DeleteMeRequest
         * @static
         * @param {mutation_user.IDeleteMeRequest=} [properties] Properties to set
         * @returns {mutation_user.DeleteMeRequest} DeleteMeRequest instance
         */
        DeleteMeRequest.create = function create(properties) {
            return new DeleteMeRequest(properties);
        };

        /**
         * Encodes the specified DeleteMeRequest message. Does not implicitly {@link mutation_user.DeleteMeRequest.verify|verify} messages.
         * @function encode
         * @memberof mutation_user.DeleteMeRequest
         * @static
         * @param {mutation_user.IDeleteMeRequest} message DeleteMeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a DeleteMeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mutation_user.DeleteMeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mutation_user.DeleteMeRequest} DeleteMeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mutation_user.DeleteMeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a DeleteMeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mutation_user.DeleteMeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mutation_user.DeleteMeRequest} DeleteMeRequest
         */
        DeleteMeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mutation_user.DeleteMeRequest)
                return object;
            return new $root.mutation_user.DeleteMeRequest();
        };

        /**
         * Creates a plain object from a DeleteMeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mutation_user.DeleteMeRequest
         * @static
         * @param {mutation_user.DeleteMeRequest} message DeleteMeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteMeRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteMeRequest to JSON.
         * @function toJSON
         * @memberof mutation_user.DeleteMeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteMeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteMeRequest
         * @function getTypeUrl
         * @memberof mutation_user.DeleteMeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteMeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mutation_user.DeleteMeRequest";
        };

        return DeleteMeRequest;
    })();

    return mutation_user;
})();

$root.payment = (function() {

    /**
     * Namespace payment.
     * @exports payment
     * @namespace
     */
    var payment = {};

    payment.PaymentSubscription = (function() {

        /**
         * Properties of a PaymentSubscription.
         * @memberof payment
         * @interface IPaymentSubscription
         * @property {google.protobuf.IStringValue|null} [id] PaymentSubscription id
         * @property {google.protobuf.IStringValue|null} [userId] PaymentSubscription userId
         * @property {payment.PaymentPlatform|null} [paymentPlatform] PaymentSubscription paymentPlatform
         * @property {payment.IPaymentProduct|null} [product] PaymentSubscription product
         * @property {google.protobuf.ITimestamp|null} [startedAt] PaymentSubscription startedAt
         * @property {google.protobuf.ITimestamp|null} [expiresAt] PaymentSubscription expiresAt
         * @property {google.protobuf.ITimestamp|null} [resumeAt] PaymentSubscription resumeAt
         * @property {google.protobuf.IBoolValue|null} [isAutoRenewable] PaymentSubscription isAutoRenewable
         * @property {google.protobuf.IBoolValue|null} [isTrial] PaymentSubscription isTrial
         * @property {google.protobuf.IBoolValue|null} [isPending] PaymentSubscription isPending
         * @property {google.protobuf.ITimestamp|null} [chargeFailedAt] PaymentSubscription chargeFailedAt
         * @property {google.protobuf.ITimestamp|null} [nextChargeAt] PaymentSubscription nextChargeAt
         * @property {payment.PaymentPromotionOffer|null} [promotionOffer] PaymentSubscription promotionOffer
         * @property {google.protobuf.ITimestamp|null} [updatedAt] PaymentSubscription updatedAt
         */

        /**
         * Constructs a new PaymentSubscription.
         * @memberof payment
         * @classdesc Represents a PaymentSubscription.
         * @implements IPaymentSubscription
         * @constructor
         * @param {payment.IPaymentSubscription=} [properties] Properties to set
         */
        function PaymentSubscription(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentSubscription id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.id = null;

        /**
         * PaymentSubscription userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.userId = null;

        /**
         * PaymentSubscription paymentPlatform.
         * @member {payment.PaymentPlatform} paymentPlatform
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.paymentPlatform = 0;

        /**
         * PaymentSubscription product.
         * @member {payment.IPaymentProduct|null|undefined} product
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.product = null;

        /**
         * PaymentSubscription startedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.startedAt = null;

        /**
         * PaymentSubscription expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.expiresAt = null;

        /**
         * PaymentSubscription resumeAt.
         * @member {google.protobuf.ITimestamp|null|undefined} resumeAt
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.resumeAt = null;

        /**
         * PaymentSubscription isAutoRenewable.
         * @member {google.protobuf.IBoolValue|null|undefined} isAutoRenewable
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.isAutoRenewable = null;

        /**
         * PaymentSubscription isTrial.
         * @member {google.protobuf.IBoolValue|null|undefined} isTrial
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.isTrial = null;

        /**
         * PaymentSubscription isPending.
         * @member {google.protobuf.IBoolValue|null|undefined} isPending
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.isPending = null;

        /**
         * PaymentSubscription chargeFailedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} chargeFailedAt
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.chargeFailedAt = null;

        /**
         * PaymentSubscription nextChargeAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextChargeAt
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.nextChargeAt = null;

        /**
         * PaymentSubscription promotionOffer.
         * @member {payment.PaymentPromotionOffer} promotionOffer
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.promotionOffer = 0;

        /**
         * PaymentSubscription updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof payment.PaymentSubscription
         * @instance
         */
        PaymentSubscription.prototype.updatedAt = null;

        /**
         * Creates a new PaymentSubscription instance using the specified properties.
         * @function create
         * @memberof payment.PaymentSubscription
         * @static
         * @param {payment.IPaymentSubscription=} [properties] Properties to set
         * @returns {payment.PaymentSubscription} PaymentSubscription instance
         */
        PaymentSubscription.create = function create(properties) {
            return new PaymentSubscription(properties);
        };

        /**
         * Encodes the specified PaymentSubscription message. Does not implicitly {@link payment.PaymentSubscription.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentSubscription
         * @static
         * @param {payment.IPaymentSubscription} message PaymentSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentSubscription.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.payment.PaymentProduct.encode(message.product, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.startedAt != null && Object.hasOwnProperty.call(message, "startedAt"))
                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.resumeAt != null && Object.hasOwnProperty.call(message, "resumeAt"))
                $root.google.protobuf.Timestamp.encode(message.resumeAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isAutoRenewable != null && Object.hasOwnProperty.call(message, "isAutoRenewable"))
                $root.google.protobuf.BoolValue.encode(message.isAutoRenewable, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.isTrial != null && Object.hasOwnProperty.call(message, "isTrial"))
                $root.google.protobuf.BoolValue.encode(message.isTrial, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isPending != null && Object.hasOwnProperty.call(message, "isPending"))
                $root.google.protobuf.BoolValue.encode(message.isPending, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.chargeFailedAt != null && Object.hasOwnProperty.call(message, "chargeFailedAt"))
                $root.google.protobuf.Timestamp.encode(message.chargeFailedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.nextChargeAt != null && Object.hasOwnProperty.call(message, "nextChargeAt"))
                $root.google.protobuf.Timestamp.encode(message.nextChargeAt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.promotionOffer != null && Object.hasOwnProperty.call(message, "promotionOffer"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.promotionOffer);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.paymentPlatform != null && Object.hasOwnProperty.call(message, "paymentPlatform"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.paymentPlatform);
            return writer;
        };

        /**
         * Decodes a PaymentSubscription message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentSubscription} PaymentSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentSubscription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentSubscription();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.paymentPlatform = reader.int32();
                    break;
                case 3:
                    message.product = $root.payment.PaymentProduct.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.resumeAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isAutoRenewable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.isTrial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.isPending = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.chargeFailedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.nextChargeAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.promotionOffer = reader.int32();
                    break;
                case 13:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentSubscription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentSubscription
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentSubscription} PaymentSubscription
         */
        PaymentSubscription.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentSubscription)
                return object;
            var message = new $root.payment.PaymentSubscription();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".payment.PaymentSubscription.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".payment.PaymentSubscription.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            switch (object.paymentPlatform) {
            case "PAYMENT_PLATFORM_UNSPECIFIED":
            case 0:
                message.paymentPlatform = 0;
                break;
            case "PAYMENT_PLATFORM_TELLER":
            case 1:
                message.paymentPlatform = 1;
                break;
            case "PAYMENT_PLATFORM_IOS":
            case 2:
                message.paymentPlatform = 2;
                break;
            case "PAYMENT_PLATFORM_ANDROID":
            case 3:
                message.paymentPlatform = 3;
                break;
            case "PAYMENT_PLATFORM_CREDIT_CARD":
            case 4:
                message.paymentPlatform = 4;
                break;
            case "PAYMENT_PLATFORM_DOCOMO":
            case 5:
                message.paymentPlatform = 5;
                break;
            case "PAYMENT_PLATFORM_AU":
            case 6:
                message.paymentPlatform = 6;
                break;
            case "PAYMENT_PLATFORM_SOFTBANK":
            case 7:
                message.paymentPlatform = 7;
                break;
            case "PAYMENT_PLATFORM_STRIPE":
            case 8:
                message.paymentPlatform = 8;
                break;
            }
            if (object.product != null) {
                if (typeof object.product !== "object")
                    throw TypeError(".payment.PaymentSubscription.product: object expected");
                message.product = $root.payment.PaymentProduct.fromObject(object.product);
            }
            if (object.startedAt != null) {
                if (typeof object.startedAt !== "object")
                    throw TypeError(".payment.PaymentSubscription.startedAt: object expected");
                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".payment.PaymentSubscription.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            if (object.resumeAt != null) {
                if (typeof object.resumeAt !== "object")
                    throw TypeError(".payment.PaymentSubscription.resumeAt: object expected");
                message.resumeAt = $root.google.protobuf.Timestamp.fromObject(object.resumeAt);
            }
            if (object.isAutoRenewable != null) {
                if (typeof object.isAutoRenewable !== "object")
                    throw TypeError(".payment.PaymentSubscription.isAutoRenewable: object expected");
                message.isAutoRenewable = $root.google.protobuf.BoolValue.fromObject(object.isAutoRenewable);
            }
            if (object.isTrial != null) {
                if (typeof object.isTrial !== "object")
                    throw TypeError(".payment.PaymentSubscription.isTrial: object expected");
                message.isTrial = $root.google.protobuf.BoolValue.fromObject(object.isTrial);
            }
            if (object.isPending != null) {
                if (typeof object.isPending !== "object")
                    throw TypeError(".payment.PaymentSubscription.isPending: object expected");
                message.isPending = $root.google.protobuf.BoolValue.fromObject(object.isPending);
            }
            if (object.chargeFailedAt != null) {
                if (typeof object.chargeFailedAt !== "object")
                    throw TypeError(".payment.PaymentSubscription.chargeFailedAt: object expected");
                message.chargeFailedAt = $root.google.protobuf.Timestamp.fromObject(object.chargeFailedAt);
            }
            if (object.nextChargeAt != null) {
                if (typeof object.nextChargeAt !== "object")
                    throw TypeError(".payment.PaymentSubscription.nextChargeAt: object expected");
                message.nextChargeAt = $root.google.protobuf.Timestamp.fromObject(object.nextChargeAt);
            }
            switch (object.promotionOffer) {
            case "PAYMENT_PROMOTION_OFFER_UNSPECIFIED":
            case 0:
                message.promotionOffer = 0;
                break;
            case "PAYMENT_PROMOTION_OFFER_NONE":
            case 1:
                message.promotionOffer = 1;
                break;
            case "PAYMENT_PROMOTION_OFFER_6_MONTH_DISCOUNT":
            case 2:
                message.promotionOffer = 2;
                break;
            case "PAYMENT_PROMOTION_OFFER_2_MONTH_FREE":
            case 3:
                message.promotionOffer = 3;
                break;
            case "PAYMENT_PROMOTION_OFFER_NEW_YEAR_CAMPAIGN":
            case 4:
                message.promotionOffer = 4;
                break;
            case "PAYMENT_PROMOTION_OFFER_FREE_THREE_DAY_FOR_A_YEAR":
            case 5:
                message.promotionOffer = 5;
                break;
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".payment.PaymentSubscription.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentSubscription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentSubscription
         * @static
         * @param {payment.PaymentSubscription} message PaymentSubscription
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentSubscription.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.userId = null;
                object.product = null;
                object.startedAt = null;
                object.expiresAt = null;
                object.resumeAt = null;
                object.isAutoRenewable = null;
                object.isTrial = null;
                object.isPending = null;
                object.chargeFailedAt = null;
                object.nextChargeAt = null;
                object.promotionOffer = options.enums === String ? "PAYMENT_PROMOTION_OFFER_UNSPECIFIED" : 0;
                object.updatedAt = null;
                object.paymentPlatform = options.enums === String ? "PAYMENT_PLATFORM_UNSPECIFIED" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.product != null && message.hasOwnProperty("product"))
                object.product = $root.payment.PaymentProduct.toObject(message.product, options);
            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            if (message.resumeAt != null && message.hasOwnProperty("resumeAt"))
                object.resumeAt = $root.google.protobuf.Timestamp.toObject(message.resumeAt, options);
            if (message.isAutoRenewable != null && message.hasOwnProperty("isAutoRenewable"))
                object.isAutoRenewable = $root.google.protobuf.BoolValue.toObject(message.isAutoRenewable, options);
            if (message.isTrial != null && message.hasOwnProperty("isTrial"))
                object.isTrial = $root.google.protobuf.BoolValue.toObject(message.isTrial, options);
            if (message.isPending != null && message.hasOwnProperty("isPending"))
                object.isPending = $root.google.protobuf.BoolValue.toObject(message.isPending, options);
            if (message.chargeFailedAt != null && message.hasOwnProperty("chargeFailedAt"))
                object.chargeFailedAt = $root.google.protobuf.Timestamp.toObject(message.chargeFailedAt, options);
            if (message.nextChargeAt != null && message.hasOwnProperty("nextChargeAt"))
                object.nextChargeAt = $root.google.protobuf.Timestamp.toObject(message.nextChargeAt, options);
            if (message.promotionOffer != null && message.hasOwnProperty("promotionOffer"))
                object.promotionOffer = options.enums === String ? $root.payment.PaymentPromotionOffer[message.promotionOffer] : message.promotionOffer;
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.paymentPlatform != null && message.hasOwnProperty("paymentPlatform"))
                object.paymentPlatform = options.enums === String ? $root.payment.PaymentPlatform[message.paymentPlatform] : message.paymentPlatform;
            return object;
        };

        /**
         * Converts this PaymentSubscription to JSON.
         * @function toJSON
         * @memberof payment.PaymentSubscription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentSubscription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentSubscription
         * @function getTypeUrl
         * @memberof payment.PaymentSubscription
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentSubscription.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentSubscription";
        };

        return PaymentSubscription;
    })();

    /**
     * PaymentPromotionOffer enum.
     * @name payment.PaymentPromotionOffer
     * @enum {number}
     * @property {number} PAYMENT_PROMOTION_OFFER_UNSPECIFIED=0 PAYMENT_PROMOTION_OFFER_UNSPECIFIED value
     * @property {number} PAYMENT_PROMOTION_OFFER_NONE=1 PAYMENT_PROMOTION_OFFER_NONE value
     * @property {number} PAYMENT_PROMOTION_OFFER_6_MONTH_DISCOUNT=2 PAYMENT_PROMOTION_OFFER_6_MONTH_DISCOUNT value
     * @property {number} PAYMENT_PROMOTION_OFFER_2_MONTH_FREE=3 PAYMENT_PROMOTION_OFFER_2_MONTH_FREE value
     * @property {number} PAYMENT_PROMOTION_OFFER_NEW_YEAR_CAMPAIGN=4 PAYMENT_PROMOTION_OFFER_NEW_YEAR_CAMPAIGN value
     * @property {number} PAYMENT_PROMOTION_OFFER_FREE_THREE_DAY_FOR_A_YEAR=5 PAYMENT_PROMOTION_OFFER_FREE_THREE_DAY_FOR_A_YEAR value
     */
    payment.PaymentPromotionOffer = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PAYMENT_PROMOTION_OFFER_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PAYMENT_PROMOTION_OFFER_NONE"] = 1;
        values[valuesById[2] = "PAYMENT_PROMOTION_OFFER_6_MONTH_DISCOUNT"] = 2;
        values[valuesById[3] = "PAYMENT_PROMOTION_OFFER_2_MONTH_FREE"] = 3;
        values[valuesById[4] = "PAYMENT_PROMOTION_OFFER_NEW_YEAR_CAMPAIGN"] = 4;
        values[valuesById[5] = "PAYMENT_PROMOTION_OFFER_FREE_THREE_DAY_FOR_A_YEAR"] = 5;
        return values;
    })();

    /**
     * PaymentPlatform enum.
     * @name payment.PaymentPlatform
     * @enum {number}
     * @property {number} PAYMENT_PLATFORM_UNSPECIFIED=0 PAYMENT_PLATFORM_UNSPECIFIED value
     * @property {number} PAYMENT_PLATFORM_TELLER=1 PAYMENT_PLATFORM_TELLER value
     * @property {number} PAYMENT_PLATFORM_IOS=2 PAYMENT_PLATFORM_IOS value
     * @property {number} PAYMENT_PLATFORM_ANDROID=3 PAYMENT_PLATFORM_ANDROID value
     * @property {number} PAYMENT_PLATFORM_CREDIT_CARD=4 PAYMENT_PLATFORM_CREDIT_CARD value
     * @property {number} PAYMENT_PLATFORM_DOCOMO=5 PAYMENT_PLATFORM_DOCOMO value
     * @property {number} PAYMENT_PLATFORM_AU=6 PAYMENT_PLATFORM_AU value
     * @property {number} PAYMENT_PLATFORM_SOFTBANK=7 PAYMENT_PLATFORM_SOFTBANK value
     * @property {number} PAYMENT_PLATFORM_STRIPE=8 PAYMENT_PLATFORM_STRIPE value
     */
    payment.PaymentPlatform = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PAYMENT_PLATFORM_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PAYMENT_PLATFORM_TELLER"] = 1;
        values[valuesById[2] = "PAYMENT_PLATFORM_IOS"] = 2;
        values[valuesById[3] = "PAYMENT_PLATFORM_ANDROID"] = 3;
        values[valuesById[4] = "PAYMENT_PLATFORM_CREDIT_CARD"] = 4;
        values[valuesById[5] = "PAYMENT_PLATFORM_DOCOMO"] = 5;
        values[valuesById[6] = "PAYMENT_PLATFORM_AU"] = 6;
        values[valuesById[7] = "PAYMENT_PLATFORM_SOFTBANK"] = 7;
        values[valuesById[8] = "PAYMENT_PLATFORM_STRIPE"] = 8;
        return values;
    })();

    payment.PaymentProduct = (function() {

        /**
         * Properties of a PaymentProduct.
         * @memberof payment
         * @interface IPaymentProduct
         * @property {google.protobuf.IStringValue|null} [id] PaymentProduct id
         * @property {google.protobuf.IStringValue|null} [name] PaymentProduct name
         * @property {payment.PaymentPlatform|null} [platform] PaymentProduct platform
         * @property {google.protobuf.IInt32Value|null} [trialDays] PaymentProduct trialDays
         * @property {google.protobuf.IInt32Value|null} [price] PaymentProduct price
         * @property {google.protobuf.IInt32Value|null} [termByMonth] PaymentProduct termByMonth
         */

        /**
         * Constructs a new PaymentProduct.
         * @memberof payment
         * @classdesc Represents a PaymentProduct.
         * @implements IPaymentProduct
         * @constructor
         * @param {payment.IPaymentProduct=} [properties] Properties to set
         */
        function PaymentProduct(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentProduct id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof payment.PaymentProduct
         * @instance
         */
        PaymentProduct.prototype.id = null;

        /**
         * PaymentProduct name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof payment.PaymentProduct
         * @instance
         */
        PaymentProduct.prototype.name = null;

        /**
         * PaymentProduct platform.
         * @member {payment.PaymentPlatform} platform
         * @memberof payment.PaymentProduct
         * @instance
         */
        PaymentProduct.prototype.platform = 0;

        /**
         * PaymentProduct trialDays.
         * @member {google.protobuf.IInt32Value|null|undefined} trialDays
         * @memberof payment.PaymentProduct
         * @instance
         */
        PaymentProduct.prototype.trialDays = null;

        /**
         * PaymentProduct price.
         * @member {google.protobuf.IInt32Value|null|undefined} price
         * @memberof payment.PaymentProduct
         * @instance
         */
        PaymentProduct.prototype.price = null;

        /**
         * PaymentProduct termByMonth.
         * @member {google.protobuf.IInt32Value|null|undefined} termByMonth
         * @memberof payment.PaymentProduct
         * @instance
         */
        PaymentProduct.prototype.termByMonth = null;

        /**
         * Creates a new PaymentProduct instance using the specified properties.
         * @function create
         * @memberof payment.PaymentProduct
         * @static
         * @param {payment.IPaymentProduct=} [properties] Properties to set
         * @returns {payment.PaymentProduct} PaymentProduct instance
         */
        PaymentProduct.create = function create(properties) {
            return new PaymentProduct(properties);
        };

        /**
         * Encodes the specified PaymentProduct message. Does not implicitly {@link payment.PaymentProduct.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentProduct
         * @static
         * @param {payment.IPaymentProduct} message PaymentProduct message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentProduct.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.platform);
            if (message.trialDays != null && Object.hasOwnProperty.call(message, "trialDays"))
                $root.google.protobuf.Int32Value.encode(message.trialDays, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.google.protobuf.Int32Value.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.termByMonth != null && Object.hasOwnProperty.call(message, "termByMonth"))
                $root.google.protobuf.Int32Value.encode(message.termByMonth, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PaymentProduct message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentProduct} PaymentProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentProduct.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentProduct();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.platform = reader.int32();
                    break;
                case 4:
                    message.trialDays = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.price = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.termByMonth = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentProduct message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentProduct
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentProduct} PaymentProduct
         */
        PaymentProduct.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentProduct)
                return object;
            var message = new $root.payment.PaymentProduct();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".payment.PaymentProduct.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".payment.PaymentProduct.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            switch (object.platform) {
            case "PAYMENT_PLATFORM_UNSPECIFIED":
            case 0:
                message.platform = 0;
                break;
            case "PAYMENT_PLATFORM_TELLER":
            case 1:
                message.platform = 1;
                break;
            case "PAYMENT_PLATFORM_IOS":
            case 2:
                message.platform = 2;
                break;
            case "PAYMENT_PLATFORM_ANDROID":
            case 3:
                message.platform = 3;
                break;
            case "PAYMENT_PLATFORM_CREDIT_CARD":
            case 4:
                message.platform = 4;
                break;
            case "PAYMENT_PLATFORM_DOCOMO":
            case 5:
                message.platform = 5;
                break;
            case "PAYMENT_PLATFORM_AU":
            case 6:
                message.platform = 6;
                break;
            case "PAYMENT_PLATFORM_SOFTBANK":
            case 7:
                message.platform = 7;
                break;
            case "PAYMENT_PLATFORM_STRIPE":
            case 8:
                message.platform = 8;
                break;
            }
            if (object.trialDays != null) {
                if (typeof object.trialDays !== "object")
                    throw TypeError(".payment.PaymentProduct.trialDays: object expected");
                message.trialDays = $root.google.protobuf.Int32Value.fromObject(object.trialDays);
            }
            if (object.price != null) {
                if (typeof object.price !== "object")
                    throw TypeError(".payment.PaymentProduct.price: object expected");
                message.price = $root.google.protobuf.Int32Value.fromObject(object.price);
            }
            if (object.termByMonth != null) {
                if (typeof object.termByMonth !== "object")
                    throw TypeError(".payment.PaymentProduct.termByMonth: object expected");
                message.termByMonth = $root.google.protobuf.Int32Value.fromObject(object.termByMonth);
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentProduct message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentProduct
         * @static
         * @param {payment.PaymentProduct} message PaymentProduct
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentProduct.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.name = null;
                object.platform = options.enums === String ? "PAYMENT_PLATFORM_UNSPECIFIED" : 0;
                object.trialDays = null;
                object.price = null;
                object.termByMonth = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.platform != null && message.hasOwnProperty("platform"))
                object.platform = options.enums === String ? $root.payment.PaymentPlatform[message.platform] : message.platform;
            if (message.trialDays != null && message.hasOwnProperty("trialDays"))
                object.trialDays = $root.google.protobuf.Int32Value.toObject(message.trialDays, options);
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = $root.google.protobuf.Int32Value.toObject(message.price, options);
            if (message.termByMonth != null && message.hasOwnProperty("termByMonth"))
                object.termByMonth = $root.google.protobuf.Int32Value.toObject(message.termByMonth, options);
            return object;
        };

        /**
         * Converts this PaymentProduct to JSON.
         * @function toJSON
         * @memberof payment.PaymentProduct
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentProduct.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentProduct
         * @function getTypeUrl
         * @memberof payment.PaymentProduct
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentProduct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentProduct";
        };

        return PaymentProduct;
    })();

    payment.CreditCard = (function() {

        /**
         * Properties of a CreditCard.
         * @memberof payment
         * @interface ICreditCard
         * @property {google.protobuf.IStringValue|null} [id] CreditCard id
         * @property {google.protobuf.IStringValue|null} [number] CreditCard number
         * @property {payment.ICreditCardExpiration|null} [expiration] CreditCard expiration
         */

        /**
         * Constructs a new CreditCard.
         * @memberof payment
         * @classdesc Represents a CreditCard.
         * @implements ICreditCard
         * @constructor
         * @param {payment.ICreditCard=} [properties] Properties to set
         */
        function CreditCard(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreditCard id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof payment.CreditCard
         * @instance
         */
        CreditCard.prototype.id = null;

        /**
         * CreditCard number.
         * @member {google.protobuf.IStringValue|null|undefined} number
         * @memberof payment.CreditCard
         * @instance
         */
        CreditCard.prototype.number = null;

        /**
         * CreditCard expiration.
         * @member {payment.ICreditCardExpiration|null|undefined} expiration
         * @memberof payment.CreditCard
         * @instance
         */
        CreditCard.prototype.expiration = null;

        /**
         * Creates a new CreditCard instance using the specified properties.
         * @function create
         * @memberof payment.CreditCard
         * @static
         * @param {payment.ICreditCard=} [properties] Properties to set
         * @returns {payment.CreditCard} CreditCard instance
         */
        CreditCard.create = function create(properties) {
            return new CreditCard(properties);
        };

        /**
         * Encodes the specified CreditCard message. Does not implicitly {@link payment.CreditCard.verify|verify} messages.
         * @function encode
         * @memberof payment.CreditCard
         * @static
         * @param {payment.ICreditCard} message CreditCard message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCard.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                $root.google.protobuf.StringValue.encode(message.number, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                $root.payment.CreditCardExpiration.encode(message.expiration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreditCard message from the specified reader or buffer.
         * @function decode
         * @memberof payment.CreditCard
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.CreditCard} CreditCard
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCard.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.CreditCard();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.number = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.expiration = $root.payment.CreditCardExpiration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreditCard message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.CreditCard
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.CreditCard} CreditCard
         */
        CreditCard.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.CreditCard)
                return object;
            var message = new $root.payment.CreditCard();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".payment.CreditCard.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.number != null) {
                if (typeof object.number !== "object")
                    throw TypeError(".payment.CreditCard.number: object expected");
                message.number = $root.google.protobuf.StringValue.fromObject(object.number);
            }
            if (object.expiration != null) {
                if (typeof object.expiration !== "object")
                    throw TypeError(".payment.CreditCard.expiration: object expected");
                message.expiration = $root.payment.CreditCardExpiration.fromObject(object.expiration);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreditCard message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.CreditCard
         * @static
         * @param {payment.CreditCard} message CreditCard
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreditCard.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.number = null;
                object.expiration = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.number != null && message.hasOwnProperty("number"))
                object.number = $root.google.protobuf.StringValue.toObject(message.number, options);
            if (message.expiration != null && message.hasOwnProperty("expiration"))
                object.expiration = $root.payment.CreditCardExpiration.toObject(message.expiration, options);
            return object;
        };

        /**
         * Converts this CreditCard to JSON.
         * @function toJSON
         * @memberof payment.CreditCard
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreditCard.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreditCard
         * @function getTypeUrl
         * @memberof payment.CreditCard
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreditCard.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.CreditCard";
        };

        return CreditCard;
    })();

    payment.CreditCardExpiration = (function() {

        /**
         * Properties of a CreditCardExpiration.
         * @memberof payment
         * @interface ICreditCardExpiration
         * @property {google.protobuf.IStringValue|null} [month] CreditCardExpiration month
         * @property {google.protobuf.IStringValue|null} [year] CreditCardExpiration year
         */

        /**
         * Constructs a new CreditCardExpiration.
         * @memberof payment
         * @classdesc Represents a CreditCardExpiration.
         * @implements ICreditCardExpiration
         * @constructor
         * @param {payment.ICreditCardExpiration=} [properties] Properties to set
         */
        function CreditCardExpiration(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreditCardExpiration month.
         * @member {google.protobuf.IStringValue|null|undefined} month
         * @memberof payment.CreditCardExpiration
         * @instance
         */
        CreditCardExpiration.prototype.month = null;

        /**
         * CreditCardExpiration year.
         * @member {google.protobuf.IStringValue|null|undefined} year
         * @memberof payment.CreditCardExpiration
         * @instance
         */
        CreditCardExpiration.prototype.year = null;

        /**
         * Creates a new CreditCardExpiration instance using the specified properties.
         * @function create
         * @memberof payment.CreditCardExpiration
         * @static
         * @param {payment.ICreditCardExpiration=} [properties] Properties to set
         * @returns {payment.CreditCardExpiration} CreditCardExpiration instance
         */
        CreditCardExpiration.create = function create(properties) {
            return new CreditCardExpiration(properties);
        };

        /**
         * Encodes the specified CreditCardExpiration message. Does not implicitly {@link payment.CreditCardExpiration.verify|verify} messages.
         * @function encode
         * @memberof payment.CreditCardExpiration
         * @static
         * @param {payment.ICreditCardExpiration} message CreditCardExpiration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCardExpiration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                $root.google.protobuf.StringValue.encode(message.month, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                $root.google.protobuf.StringValue.encode(message.year, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreditCardExpiration message from the specified reader or buffer.
         * @function decode
         * @memberof payment.CreditCardExpiration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.CreditCardExpiration} CreditCardExpiration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCardExpiration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.CreditCardExpiration();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.month = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.year = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreditCardExpiration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.CreditCardExpiration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.CreditCardExpiration} CreditCardExpiration
         */
        CreditCardExpiration.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.CreditCardExpiration)
                return object;
            var message = new $root.payment.CreditCardExpiration();
            if (object.month != null) {
                if (typeof object.month !== "object")
                    throw TypeError(".payment.CreditCardExpiration.month: object expected");
                message.month = $root.google.protobuf.StringValue.fromObject(object.month);
            }
            if (object.year != null) {
                if (typeof object.year !== "object")
                    throw TypeError(".payment.CreditCardExpiration.year: object expected");
                message.year = $root.google.protobuf.StringValue.fromObject(object.year);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreditCardExpiration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.CreditCardExpiration
         * @static
         * @param {payment.CreditCardExpiration} message CreditCardExpiration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreditCardExpiration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.month = null;
                object.year = null;
            }
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = $root.google.protobuf.StringValue.toObject(message.month, options);
            if (message.year != null && message.hasOwnProperty("year"))
                object.year = $root.google.protobuf.StringValue.toObject(message.year, options);
            return object;
        };

        /**
         * Converts this CreditCardExpiration to JSON.
         * @function toJSON
         * @memberof payment.CreditCardExpiration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreditCardExpiration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreditCardExpiration
         * @function getTypeUrl
         * @memberof payment.CreditCardExpiration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreditCardExpiration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.CreditCardExpiration";
        };

        return CreditCardExpiration;
    })();

    /**
     * PauseTerm enum.
     * @name payment.PauseTerm
     * @enum {number}
     * @property {number} CREDIT_CARD_PAYMENT_PAUSE_ONE_MONTH=0 CREDIT_CARD_PAYMENT_PAUSE_ONE_MONTH value
     * @property {number} CREDIT_CARD_PAYMENT_PAUSE_TWO_MONTH=1 CREDIT_CARD_PAYMENT_PAUSE_TWO_MONTH value
     * @property {number} CREDIT_CARD_PAYMENT_PAUSE_THREE_MONTH=2 CREDIT_CARD_PAYMENT_PAUSE_THREE_MONTH value
     * @property {number} CREDIT_CARD_PAYMENT_PAUSE_FOUR_MONTH=3 CREDIT_CARD_PAYMENT_PAUSE_FOUR_MONTH value
     * @property {number} CREDIT_CARD_PAYMENT_PAUSE_FIVE_MONTH=4 CREDIT_CARD_PAYMENT_PAUSE_FIVE_MONTH value
     * @property {number} CREDIT_CARD_PAYMENT_PAUSE_SIX_MONTH=5 CREDIT_CARD_PAYMENT_PAUSE_SIX_MONTH value
     */
    payment.PauseTerm = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CREDIT_CARD_PAYMENT_PAUSE_ONE_MONTH"] = 0;
        values[valuesById[1] = "CREDIT_CARD_PAYMENT_PAUSE_TWO_MONTH"] = 1;
        values[valuesById[2] = "CREDIT_CARD_PAYMENT_PAUSE_THREE_MONTH"] = 2;
        values[valuesById[3] = "CREDIT_CARD_PAYMENT_PAUSE_FOUR_MONTH"] = 3;
        values[valuesById[4] = "CREDIT_CARD_PAYMENT_PAUSE_FIVE_MONTH"] = 4;
        values[valuesById[5] = "CREDIT_CARD_PAYMENT_PAUSE_SIX_MONTH"] = 5;
        return values;
    })();

    payment.CreditCardPaymentService = (function() {

        /**
         * Constructs a new CreditCardPaymentService service.
         * @memberof payment
         * @classdesc Represents a CreditCardPaymentService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CreditCardPaymentService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CreditCardPaymentService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CreditCardPaymentService;

        /**
         * Creates new CreditCardPaymentService service using the specified rpc implementation.
         * @function create
         * @memberof payment.CreditCardPaymentService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {CreditCardPaymentService} RPC service. Useful where requests and/or responses are streamed.
         */
        CreditCardPaymentService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link payment.CreditCardPaymentService#activateSubscription}.
         * @memberof payment.CreditCardPaymentService
         * @typedef ActivateSubscriptionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.PaymentSubscription} [response] PaymentSubscription
         */

        /**
         * Calls ActivateSubscription.
         * @function activateSubscription
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentActivateSubscriptionRequest} request CreditCardPaymentActivateSubscriptionRequest message or plain object
         * @param {payment.CreditCardPaymentService.ActivateSubscriptionCallback} callback Node-style callback called with the error, if any, and PaymentSubscription
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreditCardPaymentService.prototype.activateSubscription = function activateSubscription(request, callback) {
            return this.rpcCall(activateSubscription, $root.payment.CreditCardPaymentActivateSubscriptionRequest, $root.payment.PaymentSubscription, request, callback);
        }, "name", { value: "ActivateSubscription" });

        /**
         * Calls ActivateSubscription.
         * @function activateSubscription
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentActivateSubscriptionRequest} request CreditCardPaymentActivateSubscriptionRequest message or plain object
         * @returns {Promise<payment.PaymentSubscription>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.CreditCardPaymentService#cancelSubscription}.
         * @memberof payment.CreditCardPaymentService
         * @typedef CancelSubscriptionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.PaymentSubscription} [response] PaymentSubscription
         */

        /**
         * Calls CancelSubscription.
         * @function cancelSubscription
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentCancelSubscriptionRequest} request CreditCardPaymentCancelSubscriptionRequest message or plain object
         * @param {payment.CreditCardPaymentService.CancelSubscriptionCallback} callback Node-style callback called with the error, if any, and PaymentSubscription
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreditCardPaymentService.prototype.cancelSubscription = function cancelSubscription(request, callback) {
            return this.rpcCall(cancelSubscription, $root.payment.CreditCardPaymentCancelSubscriptionRequest, $root.payment.PaymentSubscription, request, callback);
        }, "name", { value: "CancelSubscription" });

        /**
         * Calls CancelSubscription.
         * @function cancelSubscription
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentCancelSubscriptionRequest} request CreditCardPaymentCancelSubscriptionRequest message or plain object
         * @returns {Promise<payment.PaymentSubscription>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.CreditCardPaymentService#pauseSubscription}.
         * @memberof payment.CreditCardPaymentService
         * @typedef PauseSubscriptionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.PaymentSubscription} [response] PaymentSubscription
         */

        /**
         * Calls PauseSubscription.
         * @function pauseSubscription
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentPauseSubscriptionRequest} request CreditCardPaymentPauseSubscriptionRequest message or plain object
         * @param {payment.CreditCardPaymentService.PauseSubscriptionCallback} callback Node-style callback called with the error, if any, and PaymentSubscription
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreditCardPaymentService.prototype.pauseSubscription = function pauseSubscription(request, callback) {
            return this.rpcCall(pauseSubscription, $root.payment.CreditCardPaymentPauseSubscriptionRequest, $root.payment.PaymentSubscription, request, callback);
        }, "name", { value: "PauseSubscription" });

        /**
         * Calls PauseSubscription.
         * @function pauseSubscription
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentPauseSubscriptionRequest} request CreditCardPaymentPauseSubscriptionRequest message or plain object
         * @returns {Promise<payment.PaymentSubscription>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.CreditCardPaymentService#getCreditCard}.
         * @memberof payment.CreditCardPaymentService
         * @typedef GetCreditCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.CreditCard} [response] CreditCard
         */

        /**
         * Calls GetCreditCard.
         * @function getCreditCard
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {payment.CreditCardPaymentService.GetCreditCardCallback} callback Node-style callback called with the error, if any, and CreditCard
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreditCardPaymentService.prototype.getCreditCard = function getCreditCard(request, callback) {
            return this.rpcCall(getCreditCard, $root.types.Empty, $root.payment.CreditCard, request, callback);
        }, "name", { value: "GetCreditCard" });

        /**
         * Calls GetCreditCard.
         * @function getCreditCard
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<payment.CreditCard>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.CreditCardPaymentService#updateCreditCard}.
         * @memberof payment.CreditCardPaymentService
         * @typedef UpdateCreditCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.CreditCard} [response] CreditCard
         */

        /**
         * Calls UpdateCreditCard.
         * @function updateCreditCard
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentUpdateCreditCardRequest} request CreditCardPaymentUpdateCreditCardRequest message or plain object
         * @param {payment.CreditCardPaymentService.UpdateCreditCardCallback} callback Node-style callback called with the error, if any, and CreditCard
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreditCardPaymentService.prototype.updateCreditCard = function updateCreditCard(request, callback) {
            return this.rpcCall(updateCreditCard, $root.payment.CreditCardPaymentUpdateCreditCardRequest, $root.payment.CreditCard, request, callback);
        }, "name", { value: "UpdateCreditCard" });

        /**
         * Calls UpdateCreditCard.
         * @function updateCreditCard
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {payment.ICreditCardPaymentUpdateCreditCardRequest} request CreditCardPaymentUpdateCreditCardRequest message or plain object
         * @returns {Promise<payment.CreditCard>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.CreditCardPaymentService#deleteCreditCard}.
         * @memberof payment.CreditCardPaymentService
         * @typedef DeleteCreditCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls DeleteCreditCard.
         * @function deleteCreditCard
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {payment.CreditCardPaymentService.DeleteCreditCardCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CreditCardPaymentService.prototype.deleteCreditCard = function deleteCreditCard(request, callback) {
            return this.rpcCall(deleteCreditCard, $root.types.Empty, $root.types.Empty, request, callback);
        }, "name", { value: "DeleteCreditCard" });

        /**
         * Calls DeleteCreditCard.
         * @function deleteCreditCard
         * @memberof payment.CreditCardPaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        return CreditCardPaymentService;
    })();

    payment.CreditCardPaymentActivateSubscriptionRequest = (function() {

        /**
         * Properties of a CreditCardPaymentActivateSubscriptionRequest.
         * @memberof payment
         * @interface ICreditCardPaymentActivateSubscriptionRequest
         * @property {google.protobuf.IStringValue|null} [productId] CreditCardPaymentActivateSubscriptionRequest productId
         * @property {google.protobuf.IStringValue|null} [cardToken] CreditCardPaymentActivateSubscriptionRequest cardToken
         */

        /**
         * Constructs a new CreditCardPaymentActivateSubscriptionRequest.
         * @memberof payment
         * @classdesc Represents a CreditCardPaymentActivateSubscriptionRequest.
         * @implements ICreditCardPaymentActivateSubscriptionRequest
         * @constructor
         * @param {payment.ICreditCardPaymentActivateSubscriptionRequest=} [properties] Properties to set
         */
        function CreditCardPaymentActivateSubscriptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreditCardPaymentActivateSubscriptionRequest productId.
         * @member {google.protobuf.IStringValue|null|undefined} productId
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @instance
         */
        CreditCardPaymentActivateSubscriptionRequest.prototype.productId = null;

        /**
         * CreditCardPaymentActivateSubscriptionRequest cardToken.
         * @member {google.protobuf.IStringValue|null|undefined} cardToken
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @instance
         */
        CreditCardPaymentActivateSubscriptionRequest.prototype.cardToken = null;

        /**
         * Creates a new CreditCardPaymentActivateSubscriptionRequest instance using the specified properties.
         * @function create
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @static
         * @param {payment.ICreditCardPaymentActivateSubscriptionRequest=} [properties] Properties to set
         * @returns {payment.CreditCardPaymentActivateSubscriptionRequest} CreditCardPaymentActivateSubscriptionRequest instance
         */
        CreditCardPaymentActivateSubscriptionRequest.create = function create(properties) {
            return new CreditCardPaymentActivateSubscriptionRequest(properties);
        };

        /**
         * Encodes the specified CreditCardPaymentActivateSubscriptionRequest message. Does not implicitly {@link payment.CreditCardPaymentActivateSubscriptionRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @static
         * @param {payment.ICreditCardPaymentActivateSubscriptionRequest} message CreditCardPaymentActivateSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCardPaymentActivateSubscriptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                $root.google.protobuf.StringValue.encode(message.productId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cardToken != null && Object.hasOwnProperty.call(message, "cardToken"))
                $root.google.protobuf.StringValue.encode(message.cardToken, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreditCardPaymentActivateSubscriptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.CreditCardPaymentActivateSubscriptionRequest} CreditCardPaymentActivateSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCardPaymentActivateSubscriptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.CreditCardPaymentActivateSubscriptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cardToken = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreditCardPaymentActivateSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.CreditCardPaymentActivateSubscriptionRequest} CreditCardPaymentActivateSubscriptionRequest
         */
        CreditCardPaymentActivateSubscriptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.CreditCardPaymentActivateSubscriptionRequest)
                return object;
            var message = new $root.payment.CreditCardPaymentActivateSubscriptionRequest();
            if (object.productId != null) {
                if (typeof object.productId !== "object")
                    throw TypeError(".payment.CreditCardPaymentActivateSubscriptionRequest.productId: object expected");
                message.productId = $root.google.protobuf.StringValue.fromObject(object.productId);
            }
            if (object.cardToken != null) {
                if (typeof object.cardToken !== "object")
                    throw TypeError(".payment.CreditCardPaymentActivateSubscriptionRequest.cardToken: object expected");
                message.cardToken = $root.google.protobuf.StringValue.fromObject(object.cardToken);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreditCardPaymentActivateSubscriptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @static
         * @param {payment.CreditCardPaymentActivateSubscriptionRequest} message CreditCardPaymentActivateSubscriptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreditCardPaymentActivateSubscriptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.productId = null;
                object.cardToken = null;
            }
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = $root.google.protobuf.StringValue.toObject(message.productId, options);
            if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                object.cardToken = $root.google.protobuf.StringValue.toObject(message.cardToken, options);
            return object;
        };

        /**
         * Converts this CreditCardPaymentActivateSubscriptionRequest to JSON.
         * @function toJSON
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreditCardPaymentActivateSubscriptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreditCardPaymentActivateSubscriptionRequest
         * @function getTypeUrl
         * @memberof payment.CreditCardPaymentActivateSubscriptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreditCardPaymentActivateSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.CreditCardPaymentActivateSubscriptionRequest";
        };

        return CreditCardPaymentActivateSubscriptionRequest;
    })();

    payment.CreditCardPaymentCancelSubscriptionRequest = (function() {

        /**
         * Properties of a CreditCardPaymentCancelSubscriptionRequest.
         * @memberof payment
         * @interface ICreditCardPaymentCancelSubscriptionRequest
         * @property {google.protobuf.IStringValue|null} [productId] CreditCardPaymentCancelSubscriptionRequest productId
         * @property {google.protobuf.IBoolValue|null} [deleteCreditCard] CreditCardPaymentCancelSubscriptionRequest deleteCreditCard
         */

        /**
         * Constructs a new CreditCardPaymentCancelSubscriptionRequest.
         * @memberof payment
         * @classdesc Represents a CreditCardPaymentCancelSubscriptionRequest.
         * @implements ICreditCardPaymentCancelSubscriptionRequest
         * @constructor
         * @param {payment.ICreditCardPaymentCancelSubscriptionRequest=} [properties] Properties to set
         */
        function CreditCardPaymentCancelSubscriptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreditCardPaymentCancelSubscriptionRequest productId.
         * @member {google.protobuf.IStringValue|null|undefined} productId
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @instance
         */
        CreditCardPaymentCancelSubscriptionRequest.prototype.productId = null;

        /**
         * CreditCardPaymentCancelSubscriptionRequest deleteCreditCard.
         * @member {google.protobuf.IBoolValue|null|undefined} deleteCreditCard
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @instance
         */
        CreditCardPaymentCancelSubscriptionRequest.prototype.deleteCreditCard = null;

        /**
         * Creates a new CreditCardPaymentCancelSubscriptionRequest instance using the specified properties.
         * @function create
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @static
         * @param {payment.ICreditCardPaymentCancelSubscriptionRequest=} [properties] Properties to set
         * @returns {payment.CreditCardPaymentCancelSubscriptionRequest} CreditCardPaymentCancelSubscriptionRequest instance
         */
        CreditCardPaymentCancelSubscriptionRequest.create = function create(properties) {
            return new CreditCardPaymentCancelSubscriptionRequest(properties);
        };

        /**
         * Encodes the specified CreditCardPaymentCancelSubscriptionRequest message. Does not implicitly {@link payment.CreditCardPaymentCancelSubscriptionRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @static
         * @param {payment.ICreditCardPaymentCancelSubscriptionRequest} message CreditCardPaymentCancelSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCardPaymentCancelSubscriptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                $root.google.protobuf.StringValue.encode(message.productId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.deleteCreditCard != null && Object.hasOwnProperty.call(message, "deleteCreditCard"))
                $root.google.protobuf.BoolValue.encode(message.deleteCreditCard, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreditCardPaymentCancelSubscriptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.CreditCardPaymentCancelSubscriptionRequest} CreditCardPaymentCancelSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCardPaymentCancelSubscriptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.CreditCardPaymentCancelSubscriptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.deleteCreditCard = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreditCardPaymentCancelSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.CreditCardPaymentCancelSubscriptionRequest} CreditCardPaymentCancelSubscriptionRequest
         */
        CreditCardPaymentCancelSubscriptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.CreditCardPaymentCancelSubscriptionRequest)
                return object;
            var message = new $root.payment.CreditCardPaymentCancelSubscriptionRequest();
            if (object.productId != null) {
                if (typeof object.productId !== "object")
                    throw TypeError(".payment.CreditCardPaymentCancelSubscriptionRequest.productId: object expected");
                message.productId = $root.google.protobuf.StringValue.fromObject(object.productId);
            }
            if (object.deleteCreditCard != null) {
                if (typeof object.deleteCreditCard !== "object")
                    throw TypeError(".payment.CreditCardPaymentCancelSubscriptionRequest.deleteCreditCard: object expected");
                message.deleteCreditCard = $root.google.protobuf.BoolValue.fromObject(object.deleteCreditCard);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreditCardPaymentCancelSubscriptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @static
         * @param {payment.CreditCardPaymentCancelSubscriptionRequest} message CreditCardPaymentCancelSubscriptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreditCardPaymentCancelSubscriptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.productId = null;
                object.deleteCreditCard = null;
            }
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = $root.google.protobuf.StringValue.toObject(message.productId, options);
            if (message.deleteCreditCard != null && message.hasOwnProperty("deleteCreditCard"))
                object.deleteCreditCard = $root.google.protobuf.BoolValue.toObject(message.deleteCreditCard, options);
            return object;
        };

        /**
         * Converts this CreditCardPaymentCancelSubscriptionRequest to JSON.
         * @function toJSON
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreditCardPaymentCancelSubscriptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreditCardPaymentCancelSubscriptionRequest
         * @function getTypeUrl
         * @memberof payment.CreditCardPaymentCancelSubscriptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreditCardPaymentCancelSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.CreditCardPaymentCancelSubscriptionRequest";
        };

        return CreditCardPaymentCancelSubscriptionRequest;
    })();

    payment.CreditCardPaymentPauseSubscriptionRequest = (function() {

        /**
         * Properties of a CreditCardPaymentPauseSubscriptionRequest.
         * @memberof payment
         * @interface ICreditCardPaymentPauseSubscriptionRequest
         * @property {google.protobuf.IStringValue|null} [productId] CreditCardPaymentPauseSubscriptionRequest productId
         * @property {payment.PauseTerm|null} [pauseTerm] CreditCardPaymentPauseSubscriptionRequest pauseTerm
         */

        /**
         * Constructs a new CreditCardPaymentPauseSubscriptionRequest.
         * @memberof payment
         * @classdesc Represents a CreditCardPaymentPauseSubscriptionRequest.
         * @implements ICreditCardPaymentPauseSubscriptionRequest
         * @constructor
         * @param {payment.ICreditCardPaymentPauseSubscriptionRequest=} [properties] Properties to set
         */
        function CreditCardPaymentPauseSubscriptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreditCardPaymentPauseSubscriptionRequest productId.
         * @member {google.protobuf.IStringValue|null|undefined} productId
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @instance
         */
        CreditCardPaymentPauseSubscriptionRequest.prototype.productId = null;

        /**
         * CreditCardPaymentPauseSubscriptionRequest pauseTerm.
         * @member {payment.PauseTerm} pauseTerm
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @instance
         */
        CreditCardPaymentPauseSubscriptionRequest.prototype.pauseTerm = 0;

        /**
         * Creates a new CreditCardPaymentPauseSubscriptionRequest instance using the specified properties.
         * @function create
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @static
         * @param {payment.ICreditCardPaymentPauseSubscriptionRequest=} [properties] Properties to set
         * @returns {payment.CreditCardPaymentPauseSubscriptionRequest} CreditCardPaymentPauseSubscriptionRequest instance
         */
        CreditCardPaymentPauseSubscriptionRequest.create = function create(properties) {
            return new CreditCardPaymentPauseSubscriptionRequest(properties);
        };

        /**
         * Encodes the specified CreditCardPaymentPauseSubscriptionRequest message. Does not implicitly {@link payment.CreditCardPaymentPauseSubscriptionRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @static
         * @param {payment.ICreditCardPaymentPauseSubscriptionRequest} message CreditCardPaymentPauseSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCardPaymentPauseSubscriptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                $root.google.protobuf.StringValue.encode(message.productId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pauseTerm != null && Object.hasOwnProperty.call(message, "pauseTerm"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pauseTerm);
            return writer;
        };

        /**
         * Decodes a CreditCardPaymentPauseSubscriptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.CreditCardPaymentPauseSubscriptionRequest} CreditCardPaymentPauseSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCardPaymentPauseSubscriptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.CreditCardPaymentPauseSubscriptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.pauseTerm = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreditCardPaymentPauseSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.CreditCardPaymentPauseSubscriptionRequest} CreditCardPaymentPauseSubscriptionRequest
         */
        CreditCardPaymentPauseSubscriptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.CreditCardPaymentPauseSubscriptionRequest)
                return object;
            var message = new $root.payment.CreditCardPaymentPauseSubscriptionRequest();
            if (object.productId != null) {
                if (typeof object.productId !== "object")
                    throw TypeError(".payment.CreditCardPaymentPauseSubscriptionRequest.productId: object expected");
                message.productId = $root.google.protobuf.StringValue.fromObject(object.productId);
            }
            switch (object.pauseTerm) {
            case "CREDIT_CARD_PAYMENT_PAUSE_ONE_MONTH":
            case 0:
                message.pauseTerm = 0;
                break;
            case "CREDIT_CARD_PAYMENT_PAUSE_TWO_MONTH":
            case 1:
                message.pauseTerm = 1;
                break;
            case "CREDIT_CARD_PAYMENT_PAUSE_THREE_MONTH":
            case 2:
                message.pauseTerm = 2;
                break;
            case "CREDIT_CARD_PAYMENT_PAUSE_FOUR_MONTH":
            case 3:
                message.pauseTerm = 3;
                break;
            case "CREDIT_CARD_PAYMENT_PAUSE_FIVE_MONTH":
            case 4:
                message.pauseTerm = 4;
                break;
            case "CREDIT_CARD_PAYMENT_PAUSE_SIX_MONTH":
            case 5:
                message.pauseTerm = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a CreditCardPaymentPauseSubscriptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @static
         * @param {payment.CreditCardPaymentPauseSubscriptionRequest} message CreditCardPaymentPauseSubscriptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreditCardPaymentPauseSubscriptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.productId = null;
                object.pauseTerm = options.enums === String ? "CREDIT_CARD_PAYMENT_PAUSE_ONE_MONTH" : 0;
            }
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = $root.google.protobuf.StringValue.toObject(message.productId, options);
            if (message.pauseTerm != null && message.hasOwnProperty("pauseTerm"))
                object.pauseTerm = options.enums === String ? $root.payment.PauseTerm[message.pauseTerm] : message.pauseTerm;
            return object;
        };

        /**
         * Converts this CreditCardPaymentPauseSubscriptionRequest to JSON.
         * @function toJSON
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreditCardPaymentPauseSubscriptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreditCardPaymentPauseSubscriptionRequest
         * @function getTypeUrl
         * @memberof payment.CreditCardPaymentPauseSubscriptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreditCardPaymentPauseSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.CreditCardPaymentPauseSubscriptionRequest";
        };

        return CreditCardPaymentPauseSubscriptionRequest;
    })();

    payment.CreditCardPaymentUpdateCreditCardRequest = (function() {

        /**
         * Properties of a CreditCardPaymentUpdateCreditCardRequest.
         * @memberof payment
         * @interface ICreditCardPaymentUpdateCreditCardRequest
         * @property {google.protobuf.IStringValue|null} [cardToken] CreditCardPaymentUpdateCreditCardRequest cardToken
         */

        /**
         * Constructs a new CreditCardPaymentUpdateCreditCardRequest.
         * @memberof payment
         * @classdesc Represents a CreditCardPaymentUpdateCreditCardRequest.
         * @implements ICreditCardPaymentUpdateCreditCardRequest
         * @constructor
         * @param {payment.ICreditCardPaymentUpdateCreditCardRequest=} [properties] Properties to set
         */
        function CreditCardPaymentUpdateCreditCardRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreditCardPaymentUpdateCreditCardRequest cardToken.
         * @member {google.protobuf.IStringValue|null|undefined} cardToken
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @instance
         */
        CreditCardPaymentUpdateCreditCardRequest.prototype.cardToken = null;

        /**
         * Creates a new CreditCardPaymentUpdateCreditCardRequest instance using the specified properties.
         * @function create
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @static
         * @param {payment.ICreditCardPaymentUpdateCreditCardRequest=} [properties] Properties to set
         * @returns {payment.CreditCardPaymentUpdateCreditCardRequest} CreditCardPaymentUpdateCreditCardRequest instance
         */
        CreditCardPaymentUpdateCreditCardRequest.create = function create(properties) {
            return new CreditCardPaymentUpdateCreditCardRequest(properties);
        };

        /**
         * Encodes the specified CreditCardPaymentUpdateCreditCardRequest message. Does not implicitly {@link payment.CreditCardPaymentUpdateCreditCardRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @static
         * @param {payment.ICreditCardPaymentUpdateCreditCardRequest} message CreditCardPaymentUpdateCreditCardRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditCardPaymentUpdateCreditCardRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cardToken != null && Object.hasOwnProperty.call(message, "cardToken"))
                $root.google.protobuf.StringValue.encode(message.cardToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreditCardPaymentUpdateCreditCardRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.CreditCardPaymentUpdateCreditCardRequest} CreditCardPaymentUpdateCreditCardRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditCardPaymentUpdateCreditCardRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.CreditCardPaymentUpdateCreditCardRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cardToken = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CreditCardPaymentUpdateCreditCardRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.CreditCardPaymentUpdateCreditCardRequest} CreditCardPaymentUpdateCreditCardRequest
         */
        CreditCardPaymentUpdateCreditCardRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.CreditCardPaymentUpdateCreditCardRequest)
                return object;
            var message = new $root.payment.CreditCardPaymentUpdateCreditCardRequest();
            if (object.cardToken != null) {
                if (typeof object.cardToken !== "object")
                    throw TypeError(".payment.CreditCardPaymentUpdateCreditCardRequest.cardToken: object expected");
                message.cardToken = $root.google.protobuf.StringValue.fromObject(object.cardToken);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreditCardPaymentUpdateCreditCardRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @static
         * @param {payment.CreditCardPaymentUpdateCreditCardRequest} message CreditCardPaymentUpdateCreditCardRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreditCardPaymentUpdateCreditCardRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.cardToken = null;
            if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                object.cardToken = $root.google.protobuf.StringValue.toObject(message.cardToken, options);
            return object;
        };

        /**
         * Converts this CreditCardPaymentUpdateCreditCardRequest to JSON.
         * @function toJSON
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreditCardPaymentUpdateCreditCardRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreditCardPaymentUpdateCreditCardRequest
         * @function getTypeUrl
         * @memberof payment.CreditCardPaymentUpdateCreditCardRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreditCardPaymentUpdateCreditCardRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.CreditCardPaymentUpdateCreditCardRequest";
        };

        return CreditCardPaymentUpdateCreditCardRequest;
    })();

    payment.PaymentService = (function() {

        /**
         * Constructs a new PaymentService service.
         * @memberof payment
         * @classdesc Represents a PaymentService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PaymentService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PaymentService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PaymentService;

        /**
         * Creates new PaymentService service using the specified rpc implementation.
         * @function create
         * @memberof payment.PaymentService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {PaymentService} RPC service. Useful where requests and/or responses are streamed.
         */
        PaymentService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link payment.PaymentService#getLatestSubscription}.
         * @memberof payment.PaymentService
         * @typedef GetLatestSubscriptionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.PaymentSubscription} [response] PaymentSubscription
         */

        /**
         * Calls GetLatestSubscription.
         * @function getLatestSubscription
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentGetLatestSubscriptionRequest} request PaymentGetLatestSubscriptionRequest message or plain object
         * @param {payment.PaymentService.GetLatestSubscriptionCallback} callback Node-style callback called with the error, if any, and PaymentSubscription
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PaymentService.prototype.getLatestSubscription = function getLatestSubscription(request, callback) {
            return this.rpcCall(getLatestSubscription, $root.payment.PaymentGetLatestSubscriptionRequest, $root.payment.PaymentSubscription, request, callback);
        }, "name", { value: "GetLatestSubscription" });

        /**
         * Calls GetLatestSubscription.
         * @function getLatestSubscription
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentGetLatestSubscriptionRequest} request PaymentGetLatestSubscriptionRequest message or plain object
         * @returns {Promise<payment.PaymentSubscription>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.PaymentService#listActiveSubscription}.
         * @memberof payment.PaymentService
         * @typedef ListActiveSubscriptionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.PaymentListActiveSubscriptionResponse} [response] PaymentListActiveSubscriptionResponse
         */

        /**
         * Calls ListActiveSubscription.
         * @function listActiveSubscription
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentListActiveSubscriptionRequest} request PaymentListActiveSubscriptionRequest message or plain object
         * @param {payment.PaymentService.ListActiveSubscriptionCallback} callback Node-style callback called with the error, if any, and PaymentListActiveSubscriptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PaymentService.prototype.listActiveSubscription = function listActiveSubscription(request, callback) {
            return this.rpcCall(listActiveSubscription, $root.payment.PaymentListActiveSubscriptionRequest, $root.payment.PaymentListActiveSubscriptionResponse, request, callback);
        }, "name", { value: "ListActiveSubscription" });

        /**
         * Calls ListActiveSubscription.
         * @function listActiveSubscription
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentListActiveSubscriptionRequest} request PaymentListActiveSubscriptionRequest message or plain object
         * @returns {Promise<payment.PaymentListActiveSubscriptionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.PaymentService#listProduct}.
         * @memberof payment.PaymentService
         * @typedef ListProductCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.PaymentListProductResponse} [response] PaymentListProductResponse
         */

        /**
         * Calls ListProduct.
         * @function listProduct
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentListProductRequest} request PaymentListProductRequest message or plain object
         * @param {payment.PaymentService.ListProductCallback} callback Node-style callback called with the error, if any, and PaymentListProductResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PaymentService.prototype.listProduct = function listProduct(request, callback) {
            return this.rpcCall(listProduct, $root.payment.PaymentListProductRequest, $root.payment.PaymentListProductResponse, request, callback);
        }, "name", { value: "ListProduct" });

        /**
         * Calls ListProduct.
         * @function listProduct
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentListProductRequest} request PaymentListProductRequest message or plain object
         * @returns {Promise<payment.PaymentListProductResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.PaymentService#requestSubscriptionCancel}.
         * @memberof payment.PaymentService
         * @typedef RequestSubscriptionCancelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls RequestSubscriptionCancel.
         * @function requestSubscriptionCancel
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentSubscriptionCancelRequest} request PaymentSubscriptionCancelRequest message or plain object
         * @param {payment.PaymentService.RequestSubscriptionCancelCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PaymentService.prototype.requestSubscriptionCancel = function requestSubscriptionCancel(request, callback) {
            return this.rpcCall(requestSubscriptionCancel, $root.payment.PaymentSubscriptionCancelRequest, $root.types.Empty, request, callback);
        }, "name", { value: "RequestSubscriptionCancel" });

        /**
         * Calls RequestSubscriptionCancel.
         * @function requestSubscriptionCancel
         * @memberof payment.PaymentService
         * @instance
         * @param {payment.IPaymentSubscriptionCancelRequest} request PaymentSubscriptionCancelRequest message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        return PaymentService;
    })();

    payment.PaymentGetLatestSubscriptionRequest = (function() {

        /**
         * Properties of a PaymentGetLatestSubscriptionRequest.
         * @memberof payment
         * @interface IPaymentGetLatestSubscriptionRequest
         * @property {payment.PaymentPlatform|null} [platform] PaymentGetLatestSubscriptionRequest platform
         */

        /**
         * Constructs a new PaymentGetLatestSubscriptionRequest.
         * @memberof payment
         * @classdesc Represents a PaymentGetLatestSubscriptionRequest.
         * @implements IPaymentGetLatestSubscriptionRequest
         * @constructor
         * @param {payment.IPaymentGetLatestSubscriptionRequest=} [properties] Properties to set
         */
        function PaymentGetLatestSubscriptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentGetLatestSubscriptionRequest platform.
         * @member {payment.PaymentPlatform} platform
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @instance
         */
        PaymentGetLatestSubscriptionRequest.prototype.platform = 0;

        /**
         * Creates a new PaymentGetLatestSubscriptionRequest instance using the specified properties.
         * @function create
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @static
         * @param {payment.IPaymentGetLatestSubscriptionRequest=} [properties] Properties to set
         * @returns {payment.PaymentGetLatestSubscriptionRequest} PaymentGetLatestSubscriptionRequest instance
         */
        PaymentGetLatestSubscriptionRequest.create = function create(properties) {
            return new PaymentGetLatestSubscriptionRequest(properties);
        };

        /**
         * Encodes the specified PaymentGetLatestSubscriptionRequest message. Does not implicitly {@link payment.PaymentGetLatestSubscriptionRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @static
         * @param {payment.IPaymentGetLatestSubscriptionRequest} message PaymentGetLatestSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentGetLatestSubscriptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.platform);
            return writer;
        };

        /**
         * Decodes a PaymentGetLatestSubscriptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentGetLatestSubscriptionRequest} PaymentGetLatestSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentGetLatestSubscriptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentGetLatestSubscriptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.platform = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentGetLatestSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentGetLatestSubscriptionRequest} PaymentGetLatestSubscriptionRequest
         */
        PaymentGetLatestSubscriptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentGetLatestSubscriptionRequest)
                return object;
            var message = new $root.payment.PaymentGetLatestSubscriptionRequest();
            switch (object.platform) {
            case "PAYMENT_PLATFORM_UNSPECIFIED":
            case 0:
                message.platform = 0;
                break;
            case "PAYMENT_PLATFORM_TELLER":
            case 1:
                message.platform = 1;
                break;
            case "PAYMENT_PLATFORM_IOS":
            case 2:
                message.platform = 2;
                break;
            case "PAYMENT_PLATFORM_ANDROID":
            case 3:
                message.platform = 3;
                break;
            case "PAYMENT_PLATFORM_CREDIT_CARD":
            case 4:
                message.platform = 4;
                break;
            case "PAYMENT_PLATFORM_DOCOMO":
            case 5:
                message.platform = 5;
                break;
            case "PAYMENT_PLATFORM_AU":
            case 6:
                message.platform = 6;
                break;
            case "PAYMENT_PLATFORM_SOFTBANK":
            case 7:
                message.platform = 7;
                break;
            case "PAYMENT_PLATFORM_STRIPE":
            case 8:
                message.platform = 8;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentGetLatestSubscriptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @static
         * @param {payment.PaymentGetLatestSubscriptionRequest} message PaymentGetLatestSubscriptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentGetLatestSubscriptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.platform = options.enums === String ? "PAYMENT_PLATFORM_UNSPECIFIED" : 0;
            if (message.platform != null && message.hasOwnProperty("platform"))
                object.platform = options.enums === String ? $root.payment.PaymentPlatform[message.platform] : message.platform;
            return object;
        };

        /**
         * Converts this PaymentGetLatestSubscriptionRequest to JSON.
         * @function toJSON
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentGetLatestSubscriptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentGetLatestSubscriptionRequest
         * @function getTypeUrl
         * @memberof payment.PaymentGetLatestSubscriptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentGetLatestSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentGetLatestSubscriptionRequest";
        };

        return PaymentGetLatestSubscriptionRequest;
    })();

    payment.PaymentListActiveSubscriptionRequest = (function() {

        /**
         * Properties of a PaymentListActiveSubscriptionRequest.
         * @memberof payment
         * @interface IPaymentListActiveSubscriptionRequest
         * @property {payment.PaymentPlatform|null} [platform] PaymentListActiveSubscriptionRequest platform
         */

        /**
         * Constructs a new PaymentListActiveSubscriptionRequest.
         * @memberof payment
         * @classdesc Represents a PaymentListActiveSubscriptionRequest.
         * @implements IPaymentListActiveSubscriptionRequest
         * @constructor
         * @param {payment.IPaymentListActiveSubscriptionRequest=} [properties] Properties to set
         */
        function PaymentListActiveSubscriptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentListActiveSubscriptionRequest platform.
         * @member {payment.PaymentPlatform} platform
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @instance
         */
        PaymentListActiveSubscriptionRequest.prototype.platform = 0;

        /**
         * Creates a new PaymentListActiveSubscriptionRequest instance using the specified properties.
         * @function create
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @static
         * @param {payment.IPaymentListActiveSubscriptionRequest=} [properties] Properties to set
         * @returns {payment.PaymentListActiveSubscriptionRequest} PaymentListActiveSubscriptionRequest instance
         */
        PaymentListActiveSubscriptionRequest.create = function create(properties) {
            return new PaymentListActiveSubscriptionRequest(properties);
        };

        /**
         * Encodes the specified PaymentListActiveSubscriptionRequest message. Does not implicitly {@link payment.PaymentListActiveSubscriptionRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @static
         * @param {payment.IPaymentListActiveSubscriptionRequest} message PaymentListActiveSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentListActiveSubscriptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.platform);
            return writer;
        };

        /**
         * Decodes a PaymentListActiveSubscriptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentListActiveSubscriptionRequest} PaymentListActiveSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentListActiveSubscriptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentListActiveSubscriptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.platform = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentListActiveSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentListActiveSubscriptionRequest} PaymentListActiveSubscriptionRequest
         */
        PaymentListActiveSubscriptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentListActiveSubscriptionRequest)
                return object;
            var message = new $root.payment.PaymentListActiveSubscriptionRequest();
            switch (object.platform) {
            case "PAYMENT_PLATFORM_UNSPECIFIED":
            case 0:
                message.platform = 0;
                break;
            case "PAYMENT_PLATFORM_TELLER":
            case 1:
                message.platform = 1;
                break;
            case "PAYMENT_PLATFORM_IOS":
            case 2:
                message.platform = 2;
                break;
            case "PAYMENT_PLATFORM_ANDROID":
            case 3:
                message.platform = 3;
                break;
            case "PAYMENT_PLATFORM_CREDIT_CARD":
            case 4:
                message.platform = 4;
                break;
            case "PAYMENT_PLATFORM_DOCOMO":
            case 5:
                message.platform = 5;
                break;
            case "PAYMENT_PLATFORM_AU":
            case 6:
                message.platform = 6;
                break;
            case "PAYMENT_PLATFORM_SOFTBANK":
            case 7:
                message.platform = 7;
                break;
            case "PAYMENT_PLATFORM_STRIPE":
            case 8:
                message.platform = 8;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentListActiveSubscriptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @static
         * @param {payment.PaymentListActiveSubscriptionRequest} message PaymentListActiveSubscriptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentListActiveSubscriptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.platform = options.enums === String ? "PAYMENT_PLATFORM_UNSPECIFIED" : 0;
            if (message.platform != null && message.hasOwnProperty("platform"))
                object.platform = options.enums === String ? $root.payment.PaymentPlatform[message.platform] : message.platform;
            return object;
        };

        /**
         * Converts this PaymentListActiveSubscriptionRequest to JSON.
         * @function toJSON
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentListActiveSubscriptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentListActiveSubscriptionRequest
         * @function getTypeUrl
         * @memberof payment.PaymentListActiveSubscriptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentListActiveSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentListActiveSubscriptionRequest";
        };

        return PaymentListActiveSubscriptionRequest;
    })();

    payment.PaymentListActiveSubscriptionResponse = (function() {

        /**
         * Properties of a PaymentListActiveSubscriptionResponse.
         * @memberof payment
         * @interface IPaymentListActiveSubscriptionResponse
         * @property {Array.<payment.IPaymentSubscription>|null} [subscriptionList] PaymentListActiveSubscriptionResponse subscriptionList
         */

        /**
         * Constructs a new PaymentListActiveSubscriptionResponse.
         * @memberof payment
         * @classdesc Represents a PaymentListActiveSubscriptionResponse.
         * @implements IPaymentListActiveSubscriptionResponse
         * @constructor
         * @param {payment.IPaymentListActiveSubscriptionResponse=} [properties] Properties to set
         */
        function PaymentListActiveSubscriptionResponse(properties) {
            this.subscriptionList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentListActiveSubscriptionResponse subscriptionList.
         * @member {Array.<payment.IPaymentSubscription>} subscriptionList
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @instance
         */
        PaymentListActiveSubscriptionResponse.prototype.subscriptionList = $util.emptyArray;

        /**
         * Creates a new PaymentListActiveSubscriptionResponse instance using the specified properties.
         * @function create
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @static
         * @param {payment.IPaymentListActiveSubscriptionResponse=} [properties] Properties to set
         * @returns {payment.PaymentListActiveSubscriptionResponse} PaymentListActiveSubscriptionResponse instance
         */
        PaymentListActiveSubscriptionResponse.create = function create(properties) {
            return new PaymentListActiveSubscriptionResponse(properties);
        };

        /**
         * Encodes the specified PaymentListActiveSubscriptionResponse message. Does not implicitly {@link payment.PaymentListActiveSubscriptionResponse.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @static
         * @param {payment.IPaymentListActiveSubscriptionResponse} message PaymentListActiveSubscriptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentListActiveSubscriptionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.subscriptionList != null && message.subscriptionList.length)
                for (var i = 0; i < message.subscriptionList.length; ++i)
                    $root.payment.PaymentSubscription.encode(message.subscriptionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PaymentListActiveSubscriptionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentListActiveSubscriptionResponse} PaymentListActiveSubscriptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentListActiveSubscriptionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentListActiveSubscriptionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.subscriptionList && message.subscriptionList.length))
                        message.subscriptionList = [];
                    message.subscriptionList.push($root.payment.PaymentSubscription.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentListActiveSubscriptionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentListActiveSubscriptionResponse} PaymentListActiveSubscriptionResponse
         */
        PaymentListActiveSubscriptionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentListActiveSubscriptionResponse)
                return object;
            var message = new $root.payment.PaymentListActiveSubscriptionResponse();
            if (object.subscriptionList) {
                if (!Array.isArray(object.subscriptionList))
                    throw TypeError(".payment.PaymentListActiveSubscriptionResponse.subscriptionList: array expected");
                message.subscriptionList = [];
                for (var i = 0; i < object.subscriptionList.length; ++i) {
                    if (typeof object.subscriptionList[i] !== "object")
                        throw TypeError(".payment.PaymentListActiveSubscriptionResponse.subscriptionList: object expected");
                    message.subscriptionList[i] = $root.payment.PaymentSubscription.fromObject(object.subscriptionList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentListActiveSubscriptionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @static
         * @param {payment.PaymentListActiveSubscriptionResponse} message PaymentListActiveSubscriptionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentListActiveSubscriptionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.subscriptionList = [];
            if (message.subscriptionList && message.subscriptionList.length) {
                object.subscriptionList = [];
                for (var j = 0; j < message.subscriptionList.length; ++j)
                    object.subscriptionList[j] = $root.payment.PaymentSubscription.toObject(message.subscriptionList[j], options);
            }
            return object;
        };

        /**
         * Converts this PaymentListActiveSubscriptionResponse to JSON.
         * @function toJSON
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentListActiveSubscriptionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentListActiveSubscriptionResponse
         * @function getTypeUrl
         * @memberof payment.PaymentListActiveSubscriptionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentListActiveSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentListActiveSubscriptionResponse";
        };

        return PaymentListActiveSubscriptionResponse;
    })();

    payment.PaymentSubscriptionCancelRequest = (function() {

        /**
         * Properties of a PaymentSubscriptionCancelRequest.
         * @memberof payment
         * @interface IPaymentSubscriptionCancelRequest
         * @property {google.protobuf.IStringValue|null} [emailAddress] PaymentSubscriptionCancelRequest emailAddress
         */

        /**
         * Constructs a new PaymentSubscriptionCancelRequest.
         * @memberof payment
         * @classdesc Represents a PaymentSubscriptionCancelRequest.
         * @implements IPaymentSubscriptionCancelRequest
         * @constructor
         * @param {payment.IPaymentSubscriptionCancelRequest=} [properties] Properties to set
         */
        function PaymentSubscriptionCancelRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentSubscriptionCancelRequest emailAddress.
         * @member {google.protobuf.IStringValue|null|undefined} emailAddress
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @instance
         */
        PaymentSubscriptionCancelRequest.prototype.emailAddress = null;

        /**
         * Creates a new PaymentSubscriptionCancelRequest instance using the specified properties.
         * @function create
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @static
         * @param {payment.IPaymentSubscriptionCancelRequest=} [properties] Properties to set
         * @returns {payment.PaymentSubscriptionCancelRequest} PaymentSubscriptionCancelRequest instance
         */
        PaymentSubscriptionCancelRequest.create = function create(properties) {
            return new PaymentSubscriptionCancelRequest(properties);
        };

        /**
         * Encodes the specified PaymentSubscriptionCancelRequest message. Does not implicitly {@link payment.PaymentSubscriptionCancelRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @static
         * @param {payment.IPaymentSubscriptionCancelRequest} message PaymentSubscriptionCancelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentSubscriptionCancelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                $root.google.protobuf.StringValue.encode(message.emailAddress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PaymentSubscriptionCancelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentSubscriptionCancelRequest} PaymentSubscriptionCancelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentSubscriptionCancelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentSubscriptionCancelRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.emailAddress = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentSubscriptionCancelRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentSubscriptionCancelRequest} PaymentSubscriptionCancelRequest
         */
        PaymentSubscriptionCancelRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentSubscriptionCancelRequest)
                return object;
            var message = new $root.payment.PaymentSubscriptionCancelRequest();
            if (object.emailAddress != null) {
                if (typeof object.emailAddress !== "object")
                    throw TypeError(".payment.PaymentSubscriptionCancelRequest.emailAddress: object expected");
                message.emailAddress = $root.google.protobuf.StringValue.fromObject(object.emailAddress);
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentSubscriptionCancelRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @static
         * @param {payment.PaymentSubscriptionCancelRequest} message PaymentSubscriptionCancelRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentSubscriptionCancelRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.emailAddress = null;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = $root.google.protobuf.StringValue.toObject(message.emailAddress, options);
            return object;
        };

        /**
         * Converts this PaymentSubscriptionCancelRequest to JSON.
         * @function toJSON
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentSubscriptionCancelRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentSubscriptionCancelRequest
         * @function getTypeUrl
         * @memberof payment.PaymentSubscriptionCancelRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentSubscriptionCancelRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentSubscriptionCancelRequest";
        };

        return PaymentSubscriptionCancelRequest;
    })();

    payment.PaymentListProductRequest = (function() {

        /**
         * Properties of a PaymentListProductRequest.
         * @memberof payment
         * @interface IPaymentListProductRequest
         * @property {payment.PaymentPlatform|null} [platform] PaymentListProductRequest platform
         */

        /**
         * Constructs a new PaymentListProductRequest.
         * @memberof payment
         * @classdesc Represents a PaymentListProductRequest.
         * @implements IPaymentListProductRequest
         * @constructor
         * @param {payment.IPaymentListProductRequest=} [properties] Properties to set
         */
        function PaymentListProductRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentListProductRequest platform.
         * @member {payment.PaymentPlatform} platform
         * @memberof payment.PaymentListProductRequest
         * @instance
         */
        PaymentListProductRequest.prototype.platform = 0;

        /**
         * Creates a new PaymentListProductRequest instance using the specified properties.
         * @function create
         * @memberof payment.PaymentListProductRequest
         * @static
         * @param {payment.IPaymentListProductRequest=} [properties] Properties to set
         * @returns {payment.PaymentListProductRequest} PaymentListProductRequest instance
         */
        PaymentListProductRequest.create = function create(properties) {
            return new PaymentListProductRequest(properties);
        };

        /**
         * Encodes the specified PaymentListProductRequest message. Does not implicitly {@link payment.PaymentListProductRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentListProductRequest
         * @static
         * @param {payment.IPaymentListProductRequest} message PaymentListProductRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentListProductRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.platform);
            return writer;
        };

        /**
         * Decodes a PaymentListProductRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentListProductRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentListProductRequest} PaymentListProductRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentListProductRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentListProductRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.platform = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentListProductRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentListProductRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentListProductRequest} PaymentListProductRequest
         */
        PaymentListProductRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentListProductRequest)
                return object;
            var message = new $root.payment.PaymentListProductRequest();
            switch (object.platform) {
            case "PAYMENT_PLATFORM_UNSPECIFIED":
            case 0:
                message.platform = 0;
                break;
            case "PAYMENT_PLATFORM_TELLER":
            case 1:
                message.platform = 1;
                break;
            case "PAYMENT_PLATFORM_IOS":
            case 2:
                message.platform = 2;
                break;
            case "PAYMENT_PLATFORM_ANDROID":
            case 3:
                message.platform = 3;
                break;
            case "PAYMENT_PLATFORM_CREDIT_CARD":
            case 4:
                message.platform = 4;
                break;
            case "PAYMENT_PLATFORM_DOCOMO":
            case 5:
                message.platform = 5;
                break;
            case "PAYMENT_PLATFORM_AU":
            case 6:
                message.platform = 6;
                break;
            case "PAYMENT_PLATFORM_SOFTBANK":
            case 7:
                message.platform = 7;
                break;
            case "PAYMENT_PLATFORM_STRIPE":
            case 8:
                message.platform = 8;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentListProductRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentListProductRequest
         * @static
         * @param {payment.PaymentListProductRequest} message PaymentListProductRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentListProductRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.platform = options.enums === String ? "PAYMENT_PLATFORM_UNSPECIFIED" : 0;
            if (message.platform != null && message.hasOwnProperty("platform"))
                object.platform = options.enums === String ? $root.payment.PaymentPlatform[message.platform] : message.platform;
            return object;
        };

        /**
         * Converts this PaymentListProductRequest to JSON.
         * @function toJSON
         * @memberof payment.PaymentListProductRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentListProductRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentListProductRequest
         * @function getTypeUrl
         * @memberof payment.PaymentListProductRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentListProductRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentListProductRequest";
        };

        return PaymentListProductRequest;
    })();

    payment.PaymentListProductResponse = (function() {

        /**
         * Properties of a PaymentListProductResponse.
         * @memberof payment
         * @interface IPaymentListProductResponse
         * @property {Array.<payment.IPaymentProduct>|null} [paymentProductList] PaymentListProductResponse paymentProductList
         */

        /**
         * Constructs a new PaymentListProductResponse.
         * @memberof payment
         * @classdesc Represents a PaymentListProductResponse.
         * @implements IPaymentListProductResponse
         * @constructor
         * @param {payment.IPaymentListProductResponse=} [properties] Properties to set
         */
        function PaymentListProductResponse(properties) {
            this.paymentProductList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentListProductResponse paymentProductList.
         * @member {Array.<payment.IPaymentProduct>} paymentProductList
         * @memberof payment.PaymentListProductResponse
         * @instance
         */
        PaymentListProductResponse.prototype.paymentProductList = $util.emptyArray;

        /**
         * Creates a new PaymentListProductResponse instance using the specified properties.
         * @function create
         * @memberof payment.PaymentListProductResponse
         * @static
         * @param {payment.IPaymentListProductResponse=} [properties] Properties to set
         * @returns {payment.PaymentListProductResponse} PaymentListProductResponse instance
         */
        PaymentListProductResponse.create = function create(properties) {
            return new PaymentListProductResponse(properties);
        };

        /**
         * Encodes the specified PaymentListProductResponse message. Does not implicitly {@link payment.PaymentListProductResponse.verify|verify} messages.
         * @function encode
         * @memberof payment.PaymentListProductResponse
         * @static
         * @param {payment.IPaymentListProductResponse} message PaymentListProductResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentListProductResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentProductList != null && message.paymentProductList.length)
                for (var i = 0; i < message.paymentProductList.length; ++i)
                    $root.payment.PaymentProduct.encode(message.paymentProductList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PaymentListProductResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment.PaymentListProductResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.PaymentListProductResponse} PaymentListProductResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentListProductResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.PaymentListProductResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.paymentProductList && message.paymentProductList.length))
                        message.paymentProductList = [];
                    message.paymentProductList.push($root.payment.PaymentProduct.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PaymentListProductResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.PaymentListProductResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.PaymentListProductResponse} PaymentListProductResponse
         */
        PaymentListProductResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.PaymentListProductResponse)
                return object;
            var message = new $root.payment.PaymentListProductResponse();
            if (object.paymentProductList) {
                if (!Array.isArray(object.paymentProductList))
                    throw TypeError(".payment.PaymentListProductResponse.paymentProductList: array expected");
                message.paymentProductList = [];
                for (var i = 0; i < object.paymentProductList.length; ++i) {
                    if (typeof object.paymentProductList[i] !== "object")
                        throw TypeError(".payment.PaymentListProductResponse.paymentProductList: object expected");
                    message.paymentProductList[i] = $root.payment.PaymentProduct.fromObject(object.paymentProductList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentListProductResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.PaymentListProductResponse
         * @static
         * @param {payment.PaymentListProductResponse} message PaymentListProductResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentListProductResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.paymentProductList = [];
            if (message.paymentProductList && message.paymentProductList.length) {
                object.paymentProductList = [];
                for (var j = 0; j < message.paymentProductList.length; ++j)
                    object.paymentProductList[j] = $root.payment.PaymentProduct.toObject(message.paymentProductList[j], options);
            }
            return object;
        };

        /**
         * Converts this PaymentListProductResponse to JSON.
         * @function toJSON
         * @memberof payment.PaymentListProductResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentListProductResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentListProductResponse
         * @function getTypeUrl
         * @memberof payment.PaymentListProductResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentListProductResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.PaymentListProductResponse";
        };

        return PaymentListProductResponse;
    })();

    payment.StripePaymentService = (function() {

        /**
         * Constructs a new StripePaymentService service.
         * @memberof payment
         * @classdesc Represents a StripePaymentService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function StripePaymentService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (StripePaymentService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StripePaymentService;

        /**
         * Creates new StripePaymentService service using the specified rpc implementation.
         * @function create
         * @memberof payment.StripePaymentService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {StripePaymentService} RPC service. Useful where requests and/or responses are streamed.
         */
        StripePaymentService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link payment.StripePaymentService#getDefaultPaymentMethod}.
         * @memberof payment.StripePaymentService
         * @typedef GetDefaultPaymentMethodCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.StripePaymentMethod} [response] StripePaymentMethod
         */

        /**
         * Calls GetDefaultPaymentMethod.
         * @function getDefaultPaymentMethod
         * @memberof payment.StripePaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {payment.StripePaymentService.GetDefaultPaymentMethodCallback} callback Node-style callback called with the error, if any, and StripePaymentMethod
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StripePaymentService.prototype.getDefaultPaymentMethod = function getDefaultPaymentMethod(request, callback) {
            return this.rpcCall(getDefaultPaymentMethod, $root.types.Empty, $root.payment.StripePaymentMethod, request, callback);
        }, "name", { value: "GetDefaultPaymentMethod" });

        /**
         * Calls GetDefaultPaymentMethod.
         * @function getDefaultPaymentMethod
         * @memberof payment.StripePaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<payment.StripePaymentMethod>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.StripePaymentService#getTrialAvailability}.
         * @memberof payment.StripePaymentService
         * @typedef GetTrialAvailabilityCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.StripePaymentGetTrialAvailabilityResponse} [response] StripePaymentGetTrialAvailabilityResponse
         */

        /**
         * Calls GetTrialAvailability.
         * @function getTrialAvailability
         * @memberof payment.StripePaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {payment.StripePaymentService.GetTrialAvailabilityCallback} callback Node-style callback called with the error, if any, and StripePaymentGetTrialAvailabilityResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StripePaymentService.prototype.getTrialAvailability = function getTrialAvailability(request, callback) {
            return this.rpcCall(getTrialAvailability, $root.types.Empty, $root.payment.StripePaymentGetTrialAvailabilityResponse, request, callback);
        }, "name", { value: "GetTrialAvailability" });

        /**
         * Calls GetTrialAvailability.
         * @function getTrialAvailability
         * @memberof payment.StripePaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<payment.StripePaymentGetTrialAvailabilityResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.StripePaymentService#startSetupPaymentMethod}.
         * @memberof payment.StripePaymentService
         * @typedef StartSetupPaymentMethodCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.StripePaymentStartSetupPaymentMethodResponse} [response] StripePaymentStartSetupPaymentMethodResponse
         */

        /**
         * Calls StartSetupPaymentMethod.
         * @function startSetupPaymentMethod
         * @memberof payment.StripePaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {payment.StripePaymentService.StartSetupPaymentMethodCallback} callback Node-style callback called with the error, if any, and StripePaymentStartSetupPaymentMethodResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StripePaymentService.prototype.startSetupPaymentMethod = function startSetupPaymentMethod(request, callback) {
            return this.rpcCall(startSetupPaymentMethod, $root.types.Empty, $root.payment.StripePaymentStartSetupPaymentMethodResponse, request, callback);
        }, "name", { value: "StartSetupPaymentMethod" });

        /**
         * Calls StartSetupPaymentMethod.
         * @function startSetupPaymentMethod
         * @memberof payment.StripePaymentService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<payment.StripePaymentStartSetupPaymentMethodResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.StripePaymentService#setDefaultPaymentMethod}.
         * @memberof payment.StripePaymentService
         * @typedef SetDefaultPaymentMethodCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {types.Empty} [response] Empty
         */

        /**
         * Calls SetDefaultPaymentMethod.
         * @function setDefaultPaymentMethod
         * @memberof payment.StripePaymentService
         * @instance
         * @param {payment.IStripePaymentSetDefaultPaymentMethod} request StripePaymentSetDefaultPaymentMethod message or plain object
         * @param {payment.StripePaymentService.SetDefaultPaymentMethodCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StripePaymentService.prototype.setDefaultPaymentMethod = function setDefaultPaymentMethod(request, callback) {
            return this.rpcCall(setDefaultPaymentMethod, $root.payment.StripePaymentSetDefaultPaymentMethod, $root.types.Empty, request, callback);
        }, "name", { value: "SetDefaultPaymentMethod" });

        /**
         * Calls SetDefaultPaymentMethod.
         * @function setDefaultPaymentMethod
         * @memberof payment.StripePaymentService
         * @instance
         * @param {payment.IStripePaymentSetDefaultPaymentMethod} request StripePaymentSetDefaultPaymentMethod message or plain object
         * @returns {Promise<types.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.StripePaymentService#activateSubscription}.
         * @memberof payment.StripePaymentService
         * @typedef ActivateSubscriptionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.StripePaymentActivateSubscriptionResponse} [response] StripePaymentActivateSubscriptionResponse
         */

        /**
         * Calls ActivateSubscription.
         * @function activateSubscription
         * @memberof payment.StripePaymentService
         * @instance
         * @param {payment.IStripePaymentActivateSubscriptionRequest} request StripePaymentActivateSubscriptionRequest message or plain object
         * @param {payment.StripePaymentService.ActivateSubscriptionCallback} callback Node-style callback called with the error, if any, and StripePaymentActivateSubscriptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StripePaymentService.prototype.activateSubscription = function activateSubscription(request, callback) {
            return this.rpcCall(activateSubscription, $root.payment.StripePaymentActivateSubscriptionRequest, $root.payment.StripePaymentActivateSubscriptionResponse, request, callback);
        }, "name", { value: "ActivateSubscription" });

        /**
         * Calls ActivateSubscription.
         * @function activateSubscription
         * @memberof payment.StripePaymentService
         * @instance
         * @param {payment.IStripePaymentActivateSubscriptionRequest} request StripePaymentActivateSubscriptionRequest message or plain object
         * @returns {Promise<payment.StripePaymentActivateSubscriptionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link payment.StripePaymentService#issueCustomerPortalLink}.
         * @memberof payment.StripePaymentService
         * @typedef IssueCustomerPortalLinkCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {payment.StripePaymentIssueCustomerPortalLinkResponse} [response] StripePaymentIssueCustomerPortalLinkResponse
         */

        /**
         * Calls IssueCustomerPortalLink.
         * @function issueCustomerPortalLink
         * @memberof payment.StripePaymentService
         * @instance
         * @param {payment.IStripePaymentIssueCustomerPortalLinkRequest} request StripePaymentIssueCustomerPortalLinkRequest message or plain object
         * @param {payment.StripePaymentService.IssueCustomerPortalLinkCallback} callback Node-style callback called with the error, if any, and StripePaymentIssueCustomerPortalLinkResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StripePaymentService.prototype.issueCustomerPortalLink = function issueCustomerPortalLink(request, callback) {
            return this.rpcCall(issueCustomerPortalLink, $root.payment.StripePaymentIssueCustomerPortalLinkRequest, $root.payment.StripePaymentIssueCustomerPortalLinkResponse, request, callback);
        }, "name", { value: "IssueCustomerPortalLink" });

        /**
         * Calls IssueCustomerPortalLink.
         * @function issueCustomerPortalLink
         * @memberof payment.StripePaymentService
         * @instance
         * @param {payment.IStripePaymentIssueCustomerPortalLinkRequest} request StripePaymentIssueCustomerPortalLinkRequest message or plain object
         * @returns {Promise<payment.StripePaymentIssueCustomerPortalLinkResponse>} Promise
         * @variation 2
         */

        return StripePaymentService;
    })();

    payment.StripePaymentSetDefaultPaymentMethod = (function() {

        /**
         * Properties of a StripePaymentSetDefaultPaymentMethod.
         * @memberof payment
         * @interface IStripePaymentSetDefaultPaymentMethod
         * @property {google.protobuf.IStringValue|null} [paymentMethodId] StripePaymentSetDefaultPaymentMethod paymentMethodId
         */

        /**
         * Constructs a new StripePaymentSetDefaultPaymentMethod.
         * @memberof payment
         * @classdesc Represents a StripePaymentSetDefaultPaymentMethod.
         * @implements IStripePaymentSetDefaultPaymentMethod
         * @constructor
         * @param {payment.IStripePaymentSetDefaultPaymentMethod=} [properties] Properties to set
         */
        function StripePaymentSetDefaultPaymentMethod(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentSetDefaultPaymentMethod paymentMethodId.
         * @member {google.protobuf.IStringValue|null|undefined} paymentMethodId
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @instance
         */
        StripePaymentSetDefaultPaymentMethod.prototype.paymentMethodId = null;

        /**
         * Creates a new StripePaymentSetDefaultPaymentMethod instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @static
         * @param {payment.IStripePaymentSetDefaultPaymentMethod=} [properties] Properties to set
         * @returns {payment.StripePaymentSetDefaultPaymentMethod} StripePaymentSetDefaultPaymentMethod instance
         */
        StripePaymentSetDefaultPaymentMethod.create = function create(properties) {
            return new StripePaymentSetDefaultPaymentMethod(properties);
        };

        /**
         * Encodes the specified StripePaymentSetDefaultPaymentMethod message. Does not implicitly {@link payment.StripePaymentSetDefaultPaymentMethod.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @static
         * @param {payment.IStripePaymentSetDefaultPaymentMethod} message StripePaymentSetDefaultPaymentMethod message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentSetDefaultPaymentMethod.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentMethodId != null && Object.hasOwnProperty.call(message, "paymentMethodId"))
                $root.google.protobuf.StringValue.encode(message.paymentMethodId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentSetDefaultPaymentMethod message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentSetDefaultPaymentMethod} StripePaymentSetDefaultPaymentMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentSetDefaultPaymentMethod.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentSetDefaultPaymentMethod();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.paymentMethodId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentSetDefaultPaymentMethod message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentSetDefaultPaymentMethod} StripePaymentSetDefaultPaymentMethod
         */
        StripePaymentSetDefaultPaymentMethod.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentSetDefaultPaymentMethod)
                return object;
            var message = new $root.payment.StripePaymentSetDefaultPaymentMethod();
            if (object.paymentMethodId != null) {
                if (typeof object.paymentMethodId !== "object")
                    throw TypeError(".payment.StripePaymentSetDefaultPaymentMethod.paymentMethodId: object expected");
                message.paymentMethodId = $root.google.protobuf.StringValue.fromObject(object.paymentMethodId);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentSetDefaultPaymentMethod message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @static
         * @param {payment.StripePaymentSetDefaultPaymentMethod} message StripePaymentSetDefaultPaymentMethod
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentSetDefaultPaymentMethod.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.paymentMethodId = null;
            if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                object.paymentMethodId = $root.google.protobuf.StringValue.toObject(message.paymentMethodId, options);
            return object;
        };

        /**
         * Converts this StripePaymentSetDefaultPaymentMethod to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentSetDefaultPaymentMethod.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentSetDefaultPaymentMethod
         * @function getTypeUrl
         * @memberof payment.StripePaymentSetDefaultPaymentMethod
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentSetDefaultPaymentMethod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentSetDefaultPaymentMethod";
        };

        return StripePaymentSetDefaultPaymentMethod;
    })();

    payment.StripePaymentMethod = (function() {

        /**
         * Properties of a StripePaymentMethod.
         * @memberof payment
         * @interface IStripePaymentMethod
         * @property {payment.StripePaymentMethodType|null} [paymentMethod] StripePaymentMethod paymentMethod
         * @property {payment.IStripePaymentMethodCard|null} [card] StripePaymentMethod card
         */

        /**
         * Constructs a new StripePaymentMethod.
         * @memberof payment
         * @classdesc Represents a StripePaymentMethod.
         * @implements IStripePaymentMethod
         * @constructor
         * @param {payment.IStripePaymentMethod=} [properties] Properties to set
         */
        function StripePaymentMethod(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentMethod paymentMethod.
         * @member {payment.StripePaymentMethodType} paymentMethod
         * @memberof payment.StripePaymentMethod
         * @instance
         */
        StripePaymentMethod.prototype.paymentMethod = 0;

        /**
         * StripePaymentMethod card.
         * @member {payment.IStripePaymentMethodCard|null|undefined} card
         * @memberof payment.StripePaymentMethod
         * @instance
         */
        StripePaymentMethod.prototype.card = null;

        /**
         * Creates a new StripePaymentMethod instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentMethod
         * @static
         * @param {payment.IStripePaymentMethod=} [properties] Properties to set
         * @returns {payment.StripePaymentMethod} StripePaymentMethod instance
         */
        StripePaymentMethod.create = function create(properties) {
            return new StripePaymentMethod(properties);
        };

        /**
         * Encodes the specified StripePaymentMethod message. Does not implicitly {@link payment.StripePaymentMethod.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentMethod
         * @static
         * @param {payment.IStripePaymentMethod} message StripePaymentMethod message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentMethod.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentMethod != null && Object.hasOwnProperty.call(message, "paymentMethod"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.paymentMethod);
            if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                $root.payment.StripePaymentMethodCard.encode(message.card, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentMethod message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentMethod} StripePaymentMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentMethod.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentMethod();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.paymentMethod = reader.int32();
                    break;
                case 2:
                    message.card = $root.payment.StripePaymentMethodCard.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentMethod message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentMethod
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentMethod} StripePaymentMethod
         */
        StripePaymentMethod.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentMethod)
                return object;
            var message = new $root.payment.StripePaymentMethod();
            switch (object.paymentMethod) {
            case "STRIPE_PAYMENT_METHOD_TYPE_UNSPECIFIED":
            case 0:
                message.paymentMethod = 0;
                break;
            case "STRIPE_PAYMENT_METHOD_TYPE_CARD":
            case 1:
                message.paymentMethod = 1;
                break;
            }
            if (object.card != null) {
                if (typeof object.card !== "object")
                    throw TypeError(".payment.StripePaymentMethod.card: object expected");
                message.card = $root.payment.StripePaymentMethodCard.fromObject(object.card);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentMethod message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentMethod
         * @static
         * @param {payment.StripePaymentMethod} message StripePaymentMethod
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentMethod.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.paymentMethod = options.enums === String ? "STRIPE_PAYMENT_METHOD_TYPE_UNSPECIFIED" : 0;
                object.card = null;
            }
            if (message.paymentMethod != null && message.hasOwnProperty("paymentMethod"))
                object.paymentMethod = options.enums === String ? $root.payment.StripePaymentMethodType[message.paymentMethod] : message.paymentMethod;
            if (message.card != null && message.hasOwnProperty("card"))
                object.card = $root.payment.StripePaymentMethodCard.toObject(message.card, options);
            return object;
        };

        /**
         * Converts this StripePaymentMethod to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentMethod
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentMethod.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentMethod
         * @function getTypeUrl
         * @memberof payment.StripePaymentMethod
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentMethod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentMethod";
        };

        return StripePaymentMethod;
    })();

    payment.StripePaymentGetTrialAvailabilityResponse = (function() {

        /**
         * Properties of a StripePaymentGetTrialAvailabilityResponse.
         * @memberof payment
         * @interface IStripePaymentGetTrialAvailabilityResponse
         * @property {google.protobuf.IBoolValue|null} [isTrialAvailable] StripePaymentGetTrialAvailabilityResponse isTrialAvailable
         */

        /**
         * Constructs a new StripePaymentGetTrialAvailabilityResponse.
         * @memberof payment
         * @classdesc Represents a StripePaymentGetTrialAvailabilityResponse.
         * @implements IStripePaymentGetTrialAvailabilityResponse
         * @constructor
         * @param {payment.IStripePaymentGetTrialAvailabilityResponse=} [properties] Properties to set
         */
        function StripePaymentGetTrialAvailabilityResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentGetTrialAvailabilityResponse isTrialAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} isTrialAvailable
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @instance
         */
        StripePaymentGetTrialAvailabilityResponse.prototype.isTrialAvailable = null;

        /**
         * Creates a new StripePaymentGetTrialAvailabilityResponse instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @static
         * @param {payment.IStripePaymentGetTrialAvailabilityResponse=} [properties] Properties to set
         * @returns {payment.StripePaymentGetTrialAvailabilityResponse} StripePaymentGetTrialAvailabilityResponse instance
         */
        StripePaymentGetTrialAvailabilityResponse.create = function create(properties) {
            return new StripePaymentGetTrialAvailabilityResponse(properties);
        };

        /**
         * Encodes the specified StripePaymentGetTrialAvailabilityResponse message. Does not implicitly {@link payment.StripePaymentGetTrialAvailabilityResponse.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @static
         * @param {payment.IStripePaymentGetTrialAvailabilityResponse} message StripePaymentGetTrialAvailabilityResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentGetTrialAvailabilityResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isTrialAvailable != null && Object.hasOwnProperty.call(message, "isTrialAvailable"))
                $root.google.protobuf.BoolValue.encode(message.isTrialAvailable, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentGetTrialAvailabilityResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentGetTrialAvailabilityResponse} StripePaymentGetTrialAvailabilityResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentGetTrialAvailabilityResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentGetTrialAvailabilityResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.isTrialAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentGetTrialAvailabilityResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentGetTrialAvailabilityResponse} StripePaymentGetTrialAvailabilityResponse
         */
        StripePaymentGetTrialAvailabilityResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentGetTrialAvailabilityResponse)
                return object;
            var message = new $root.payment.StripePaymentGetTrialAvailabilityResponse();
            if (object.isTrialAvailable != null) {
                if (typeof object.isTrialAvailable !== "object")
                    throw TypeError(".payment.StripePaymentGetTrialAvailabilityResponse.isTrialAvailable: object expected");
                message.isTrialAvailable = $root.google.protobuf.BoolValue.fromObject(object.isTrialAvailable);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentGetTrialAvailabilityResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @static
         * @param {payment.StripePaymentGetTrialAvailabilityResponse} message StripePaymentGetTrialAvailabilityResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentGetTrialAvailabilityResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.isTrialAvailable = null;
            if (message.isTrialAvailable != null && message.hasOwnProperty("isTrialAvailable"))
                object.isTrialAvailable = $root.google.protobuf.BoolValue.toObject(message.isTrialAvailable, options);
            return object;
        };

        /**
         * Converts this StripePaymentGetTrialAvailabilityResponse to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentGetTrialAvailabilityResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentGetTrialAvailabilityResponse
         * @function getTypeUrl
         * @memberof payment.StripePaymentGetTrialAvailabilityResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentGetTrialAvailabilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentGetTrialAvailabilityResponse";
        };

        return StripePaymentGetTrialAvailabilityResponse;
    })();

    /**
     * StripePaymentMethodType enum.
     * @name payment.StripePaymentMethodType
     * @enum {number}
     * @property {number} STRIPE_PAYMENT_METHOD_TYPE_UNSPECIFIED=0 STRIPE_PAYMENT_METHOD_TYPE_UNSPECIFIED value
     * @property {number} STRIPE_PAYMENT_METHOD_TYPE_CARD=1 STRIPE_PAYMENT_METHOD_TYPE_CARD value
     */
    payment.StripePaymentMethodType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STRIPE_PAYMENT_METHOD_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STRIPE_PAYMENT_METHOD_TYPE_CARD"] = 1;
        return values;
    })();

    payment.StripePaymentMethodCard = (function() {

        /**
         * Properties of a StripePaymentMethodCard.
         * @memberof payment
         * @interface IStripePaymentMethodCard
         * @property {google.protobuf.IStringValue|null} [brand] StripePaymentMethodCard brand
         * @property {google.protobuf.IStringValue|null} [last_4] StripePaymentMethodCard last_4
         * @property {payment.StripePaymentMethodCardWallet|null} [wallet] StripePaymentMethodCard wallet
         */

        /**
         * Constructs a new StripePaymentMethodCard.
         * @memberof payment
         * @classdesc Represents a StripePaymentMethodCard.
         * @implements IStripePaymentMethodCard
         * @constructor
         * @param {payment.IStripePaymentMethodCard=} [properties] Properties to set
         */
        function StripePaymentMethodCard(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentMethodCard brand.
         * @member {google.protobuf.IStringValue|null|undefined} brand
         * @memberof payment.StripePaymentMethodCard
         * @instance
         */
        StripePaymentMethodCard.prototype.brand = null;

        /**
         * StripePaymentMethodCard last_4.
         * @member {google.protobuf.IStringValue|null|undefined} last_4
         * @memberof payment.StripePaymentMethodCard
         * @instance
         */
        StripePaymentMethodCard.prototype.last_4 = null;

        /**
         * StripePaymentMethodCard wallet.
         * @member {payment.StripePaymentMethodCardWallet} wallet
         * @memberof payment.StripePaymentMethodCard
         * @instance
         */
        StripePaymentMethodCard.prototype.wallet = 0;

        /**
         * Creates a new StripePaymentMethodCard instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentMethodCard
         * @static
         * @param {payment.IStripePaymentMethodCard=} [properties] Properties to set
         * @returns {payment.StripePaymentMethodCard} StripePaymentMethodCard instance
         */
        StripePaymentMethodCard.create = function create(properties) {
            return new StripePaymentMethodCard(properties);
        };

        /**
         * Encodes the specified StripePaymentMethodCard message. Does not implicitly {@link payment.StripePaymentMethodCard.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentMethodCard
         * @static
         * @param {payment.IStripePaymentMethodCard} message StripePaymentMethodCard message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentMethodCard.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.brand != null && Object.hasOwnProperty.call(message, "brand"))
                $root.google.protobuf.StringValue.encode(message.brand, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.last_4 != null && Object.hasOwnProperty.call(message, "last_4"))
                $root.google.protobuf.StringValue.encode(message.last_4, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.wallet != null && Object.hasOwnProperty.call(message, "wallet"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.wallet);
            return writer;
        };

        /**
         * Decodes a StripePaymentMethodCard message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentMethodCard
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentMethodCard} StripePaymentMethodCard
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentMethodCard.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentMethodCard();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.brand = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.last_4 = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.wallet = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentMethodCard message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentMethodCard
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentMethodCard} StripePaymentMethodCard
         */
        StripePaymentMethodCard.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentMethodCard)
                return object;
            var message = new $root.payment.StripePaymentMethodCard();
            if (object.brand != null) {
                if (typeof object.brand !== "object")
                    throw TypeError(".payment.StripePaymentMethodCard.brand: object expected");
                message.brand = $root.google.protobuf.StringValue.fromObject(object.brand);
            }
            if (object.last_4 != null) {
                if (typeof object.last_4 !== "object")
                    throw TypeError(".payment.StripePaymentMethodCard.last_4: object expected");
                message.last_4 = $root.google.protobuf.StringValue.fromObject(object.last_4);
            }
            switch (object.wallet) {
            case "STRIPE_PAYMENT_METHOD_CARD_WALLET_UNSPECIFIED":
            case 0:
                message.wallet = 0;
                break;
            case "STRIPE_PAYMENT_METHOD_CARD_WALLET_APPLE_PAY":
            case 1:
                message.wallet = 1;
                break;
            case "STRIPE_PAYMENT_METHOD_CARD_WALLET_GOOGLE_PAY":
            case 2:
                message.wallet = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentMethodCard message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentMethodCard
         * @static
         * @param {payment.StripePaymentMethodCard} message StripePaymentMethodCard
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentMethodCard.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.brand = null;
                object.last_4 = null;
                object.wallet = options.enums === String ? "STRIPE_PAYMENT_METHOD_CARD_WALLET_UNSPECIFIED" : 0;
            }
            if (message.brand != null && message.hasOwnProperty("brand"))
                object.brand = $root.google.protobuf.StringValue.toObject(message.brand, options);
            if (message.last_4 != null && message.hasOwnProperty("last_4"))
                object.last_4 = $root.google.protobuf.StringValue.toObject(message.last_4, options);
            if (message.wallet != null && message.hasOwnProperty("wallet"))
                object.wallet = options.enums === String ? $root.payment.StripePaymentMethodCardWallet[message.wallet] : message.wallet;
            return object;
        };

        /**
         * Converts this StripePaymentMethodCard to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentMethodCard
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentMethodCard.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentMethodCard
         * @function getTypeUrl
         * @memberof payment.StripePaymentMethodCard
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentMethodCard.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentMethodCard";
        };

        return StripePaymentMethodCard;
    })();

    /**
     * StripePaymentMethodCardWallet enum.
     * @name payment.StripePaymentMethodCardWallet
     * @enum {number}
     * @property {number} STRIPE_PAYMENT_METHOD_CARD_WALLET_UNSPECIFIED=0 STRIPE_PAYMENT_METHOD_CARD_WALLET_UNSPECIFIED value
     * @property {number} STRIPE_PAYMENT_METHOD_CARD_WALLET_APPLE_PAY=1 STRIPE_PAYMENT_METHOD_CARD_WALLET_APPLE_PAY value
     * @property {number} STRIPE_PAYMENT_METHOD_CARD_WALLET_GOOGLE_PAY=2 STRIPE_PAYMENT_METHOD_CARD_WALLET_GOOGLE_PAY value
     */
    payment.StripePaymentMethodCardWallet = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STRIPE_PAYMENT_METHOD_CARD_WALLET_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STRIPE_PAYMENT_METHOD_CARD_WALLET_APPLE_PAY"] = 1;
        values[valuesById[2] = "STRIPE_PAYMENT_METHOD_CARD_WALLET_GOOGLE_PAY"] = 2;
        return values;
    })();

    /**
     * StripePaymentClientAction enum.
     * @name payment.StripePaymentClientAction
     * @enum {number}
     * @property {number} STRIPE_PAYMENT_CLIENT_ACTION_UNSPECIFIED=0 STRIPE_PAYMENT_CLIENT_ACTION_UNSPECIFIED value
     * @property {number} STRIPE_PAYMENT_CLIENT_ACTION_ALREADY_SUBSCRIBED=1 STRIPE_PAYMENT_CLIENT_ACTION_ALREADY_SUBSCRIBED value
     * @property {number} STRIPE_PAYMENT_CLIENT_ACTION_NONE=2 STRIPE_PAYMENT_CLIENT_ACTION_NONE value
     * @property {number} STRIPE_PAYMENT_CLIENT_ACTION_HANDLE_NEXT_ACTION=3 STRIPE_PAYMENT_CLIENT_ACTION_HANDLE_NEXT_ACTION value
     * @property {number} STRIPE_PAYMENT_CLIENT_ACTION_CHANGE_PAYMENT_METHOD=4 STRIPE_PAYMENT_CLIENT_ACTION_CHANGE_PAYMENT_METHOD value
     */
    payment.StripePaymentClientAction = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STRIPE_PAYMENT_CLIENT_ACTION_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STRIPE_PAYMENT_CLIENT_ACTION_ALREADY_SUBSCRIBED"] = 1;
        values[valuesById[2] = "STRIPE_PAYMENT_CLIENT_ACTION_NONE"] = 2;
        values[valuesById[3] = "STRIPE_PAYMENT_CLIENT_ACTION_HANDLE_NEXT_ACTION"] = 3;
        values[valuesById[4] = "STRIPE_PAYMENT_CLIENT_ACTION_CHANGE_PAYMENT_METHOD"] = 4;
        return values;
    })();

    payment.StripePaymentStartSetupPaymentMethodResponse = (function() {

        /**
         * Properties of a StripePaymentStartSetupPaymentMethodResponse.
         * @memberof payment
         * @interface IStripePaymentStartSetupPaymentMethodResponse
         * @property {google.protobuf.IStringValue|null} [clientSecret] StripePaymentStartSetupPaymentMethodResponse clientSecret
         */

        /**
         * Constructs a new StripePaymentStartSetupPaymentMethodResponse.
         * @memberof payment
         * @classdesc Represents a StripePaymentStartSetupPaymentMethodResponse.
         * @implements IStripePaymentStartSetupPaymentMethodResponse
         * @constructor
         * @param {payment.IStripePaymentStartSetupPaymentMethodResponse=} [properties] Properties to set
         */
        function StripePaymentStartSetupPaymentMethodResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentStartSetupPaymentMethodResponse clientSecret.
         * @member {google.protobuf.IStringValue|null|undefined} clientSecret
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @instance
         */
        StripePaymentStartSetupPaymentMethodResponse.prototype.clientSecret = null;

        /**
         * Creates a new StripePaymentStartSetupPaymentMethodResponse instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @static
         * @param {payment.IStripePaymentStartSetupPaymentMethodResponse=} [properties] Properties to set
         * @returns {payment.StripePaymentStartSetupPaymentMethodResponse} StripePaymentStartSetupPaymentMethodResponse instance
         */
        StripePaymentStartSetupPaymentMethodResponse.create = function create(properties) {
            return new StripePaymentStartSetupPaymentMethodResponse(properties);
        };

        /**
         * Encodes the specified StripePaymentStartSetupPaymentMethodResponse message. Does not implicitly {@link payment.StripePaymentStartSetupPaymentMethodResponse.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @static
         * @param {payment.IStripePaymentStartSetupPaymentMethodResponse} message StripePaymentStartSetupPaymentMethodResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentStartSetupPaymentMethodResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientSecret != null && Object.hasOwnProperty.call(message, "clientSecret"))
                $root.google.protobuf.StringValue.encode(message.clientSecret, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentStartSetupPaymentMethodResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentStartSetupPaymentMethodResponse} StripePaymentStartSetupPaymentMethodResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentStartSetupPaymentMethodResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentStartSetupPaymentMethodResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.clientSecret = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentStartSetupPaymentMethodResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentStartSetupPaymentMethodResponse} StripePaymentStartSetupPaymentMethodResponse
         */
        StripePaymentStartSetupPaymentMethodResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentStartSetupPaymentMethodResponse)
                return object;
            var message = new $root.payment.StripePaymentStartSetupPaymentMethodResponse();
            if (object.clientSecret != null) {
                if (typeof object.clientSecret !== "object")
                    throw TypeError(".payment.StripePaymentStartSetupPaymentMethodResponse.clientSecret: object expected");
                message.clientSecret = $root.google.protobuf.StringValue.fromObject(object.clientSecret);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentStartSetupPaymentMethodResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @static
         * @param {payment.StripePaymentStartSetupPaymentMethodResponse} message StripePaymentStartSetupPaymentMethodResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentStartSetupPaymentMethodResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.clientSecret = null;
            if (message.clientSecret != null && message.hasOwnProperty("clientSecret"))
                object.clientSecret = $root.google.protobuf.StringValue.toObject(message.clientSecret, options);
            return object;
        };

        /**
         * Converts this StripePaymentStartSetupPaymentMethodResponse to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentStartSetupPaymentMethodResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentStartSetupPaymentMethodResponse
         * @function getTypeUrl
         * @memberof payment.StripePaymentStartSetupPaymentMethodResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentStartSetupPaymentMethodResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentStartSetupPaymentMethodResponse";
        };

        return StripePaymentStartSetupPaymentMethodResponse;
    })();

    payment.StripePaymentActivateSubscriptionResponse = (function() {

        /**
         * Properties of a StripePaymentActivateSubscriptionResponse.
         * @memberof payment
         * @interface IStripePaymentActivateSubscriptionResponse
         * @property {payment.StripePaymentClientAction|null} [clientAction] StripePaymentActivateSubscriptionResponse clientAction
         * @property {google.protobuf.IStringValue|null} [clientSecret] StripePaymentActivateSubscriptionResponse clientSecret
         * @property {google.protobuf.IStringValue|null} [paymentMethodId] StripePaymentActivateSubscriptionResponse paymentMethodId
         */

        /**
         * Constructs a new StripePaymentActivateSubscriptionResponse.
         * @memberof payment
         * @classdesc Represents a StripePaymentActivateSubscriptionResponse.
         * @implements IStripePaymentActivateSubscriptionResponse
         * @constructor
         * @param {payment.IStripePaymentActivateSubscriptionResponse=} [properties] Properties to set
         */
        function StripePaymentActivateSubscriptionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentActivateSubscriptionResponse clientAction.
         * @member {payment.StripePaymentClientAction} clientAction
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @instance
         */
        StripePaymentActivateSubscriptionResponse.prototype.clientAction = 0;

        /**
         * StripePaymentActivateSubscriptionResponse clientSecret.
         * @member {google.protobuf.IStringValue|null|undefined} clientSecret
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @instance
         */
        StripePaymentActivateSubscriptionResponse.prototype.clientSecret = null;

        /**
         * StripePaymentActivateSubscriptionResponse paymentMethodId.
         * @member {google.protobuf.IStringValue|null|undefined} paymentMethodId
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @instance
         */
        StripePaymentActivateSubscriptionResponse.prototype.paymentMethodId = null;

        /**
         * Creates a new StripePaymentActivateSubscriptionResponse instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @static
         * @param {payment.IStripePaymentActivateSubscriptionResponse=} [properties] Properties to set
         * @returns {payment.StripePaymentActivateSubscriptionResponse} StripePaymentActivateSubscriptionResponse instance
         */
        StripePaymentActivateSubscriptionResponse.create = function create(properties) {
            return new StripePaymentActivateSubscriptionResponse(properties);
        };

        /**
         * Encodes the specified StripePaymentActivateSubscriptionResponse message. Does not implicitly {@link payment.StripePaymentActivateSubscriptionResponse.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @static
         * @param {payment.IStripePaymentActivateSubscriptionResponse} message StripePaymentActivateSubscriptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentActivateSubscriptionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientAction != null && Object.hasOwnProperty.call(message, "clientAction"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.clientAction);
            if (message.clientSecret != null && Object.hasOwnProperty.call(message, "clientSecret"))
                $root.google.protobuf.StringValue.encode(message.clientSecret, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.paymentMethodId != null && Object.hasOwnProperty.call(message, "paymentMethodId"))
                $root.google.protobuf.StringValue.encode(message.paymentMethodId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentActivateSubscriptionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentActivateSubscriptionResponse} StripePaymentActivateSubscriptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentActivateSubscriptionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentActivateSubscriptionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.clientAction = reader.int32();
                    break;
                case 2:
                    message.clientSecret = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.paymentMethodId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentActivateSubscriptionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentActivateSubscriptionResponse} StripePaymentActivateSubscriptionResponse
         */
        StripePaymentActivateSubscriptionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentActivateSubscriptionResponse)
                return object;
            var message = new $root.payment.StripePaymentActivateSubscriptionResponse();
            switch (object.clientAction) {
            case "STRIPE_PAYMENT_CLIENT_ACTION_UNSPECIFIED":
            case 0:
                message.clientAction = 0;
                break;
            case "STRIPE_PAYMENT_CLIENT_ACTION_ALREADY_SUBSCRIBED":
            case 1:
                message.clientAction = 1;
                break;
            case "STRIPE_PAYMENT_CLIENT_ACTION_NONE":
            case 2:
                message.clientAction = 2;
                break;
            case "STRIPE_PAYMENT_CLIENT_ACTION_HANDLE_NEXT_ACTION":
            case 3:
                message.clientAction = 3;
                break;
            case "STRIPE_PAYMENT_CLIENT_ACTION_CHANGE_PAYMENT_METHOD":
            case 4:
                message.clientAction = 4;
                break;
            }
            if (object.clientSecret != null) {
                if (typeof object.clientSecret !== "object")
                    throw TypeError(".payment.StripePaymentActivateSubscriptionResponse.clientSecret: object expected");
                message.clientSecret = $root.google.protobuf.StringValue.fromObject(object.clientSecret);
            }
            if (object.paymentMethodId != null) {
                if (typeof object.paymentMethodId !== "object")
                    throw TypeError(".payment.StripePaymentActivateSubscriptionResponse.paymentMethodId: object expected");
                message.paymentMethodId = $root.google.protobuf.StringValue.fromObject(object.paymentMethodId);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentActivateSubscriptionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @static
         * @param {payment.StripePaymentActivateSubscriptionResponse} message StripePaymentActivateSubscriptionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentActivateSubscriptionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.clientAction = options.enums === String ? "STRIPE_PAYMENT_CLIENT_ACTION_UNSPECIFIED" : 0;
                object.clientSecret = null;
                object.paymentMethodId = null;
            }
            if (message.clientAction != null && message.hasOwnProperty("clientAction"))
                object.clientAction = options.enums === String ? $root.payment.StripePaymentClientAction[message.clientAction] : message.clientAction;
            if (message.clientSecret != null && message.hasOwnProperty("clientSecret"))
                object.clientSecret = $root.google.protobuf.StringValue.toObject(message.clientSecret, options);
            if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                object.paymentMethodId = $root.google.protobuf.StringValue.toObject(message.paymentMethodId, options);
            return object;
        };

        /**
         * Converts this StripePaymentActivateSubscriptionResponse to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentActivateSubscriptionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentActivateSubscriptionResponse
         * @function getTypeUrl
         * @memberof payment.StripePaymentActivateSubscriptionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentActivateSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentActivateSubscriptionResponse";
        };

        return StripePaymentActivateSubscriptionResponse;
    })();

    payment.StripePaymentActivateSubscriptionRequest = (function() {

        /**
         * Properties of a StripePaymentActivateSubscriptionRequest.
         * @memberof payment
         * @interface IStripePaymentActivateSubscriptionRequest
         * @property {google.protobuf.IStringValue|null} [productId] StripePaymentActivateSubscriptionRequest productId
         */

        /**
         * Constructs a new StripePaymentActivateSubscriptionRequest.
         * @memberof payment
         * @classdesc Represents a StripePaymentActivateSubscriptionRequest.
         * @implements IStripePaymentActivateSubscriptionRequest
         * @constructor
         * @param {payment.IStripePaymentActivateSubscriptionRequest=} [properties] Properties to set
         */
        function StripePaymentActivateSubscriptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentActivateSubscriptionRequest productId.
         * @member {google.protobuf.IStringValue|null|undefined} productId
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @instance
         */
        StripePaymentActivateSubscriptionRequest.prototype.productId = null;

        /**
         * Creates a new StripePaymentActivateSubscriptionRequest instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @static
         * @param {payment.IStripePaymentActivateSubscriptionRequest=} [properties] Properties to set
         * @returns {payment.StripePaymentActivateSubscriptionRequest} StripePaymentActivateSubscriptionRequest instance
         */
        StripePaymentActivateSubscriptionRequest.create = function create(properties) {
            return new StripePaymentActivateSubscriptionRequest(properties);
        };

        /**
         * Encodes the specified StripePaymentActivateSubscriptionRequest message. Does not implicitly {@link payment.StripePaymentActivateSubscriptionRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @static
         * @param {payment.IStripePaymentActivateSubscriptionRequest} message StripePaymentActivateSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentActivateSubscriptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                $root.google.protobuf.StringValue.encode(message.productId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentActivateSubscriptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentActivateSubscriptionRequest} StripePaymentActivateSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentActivateSubscriptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentActivateSubscriptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentActivateSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentActivateSubscriptionRequest} StripePaymentActivateSubscriptionRequest
         */
        StripePaymentActivateSubscriptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentActivateSubscriptionRequest)
                return object;
            var message = new $root.payment.StripePaymentActivateSubscriptionRequest();
            if (object.productId != null) {
                if (typeof object.productId !== "object")
                    throw TypeError(".payment.StripePaymentActivateSubscriptionRequest.productId: object expected");
                message.productId = $root.google.protobuf.StringValue.fromObject(object.productId);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentActivateSubscriptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @static
         * @param {payment.StripePaymentActivateSubscriptionRequest} message StripePaymentActivateSubscriptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentActivateSubscriptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.productId = null;
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = $root.google.protobuf.StringValue.toObject(message.productId, options);
            return object;
        };

        /**
         * Converts this StripePaymentActivateSubscriptionRequest to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentActivateSubscriptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentActivateSubscriptionRequest
         * @function getTypeUrl
         * @memberof payment.StripePaymentActivateSubscriptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentActivateSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentActivateSubscriptionRequest";
        };

        return StripePaymentActivateSubscriptionRequest;
    })();

    payment.StripePaymentIssueCustomerPortalLinkRequest = (function() {

        /**
         * Properties of a StripePaymentIssueCustomerPortalLinkRequest.
         * @memberof payment
         * @interface IStripePaymentIssueCustomerPortalLinkRequest
         * @property {google.protobuf.IStringValue|null} [returnUrl] StripePaymentIssueCustomerPortalLinkRequest returnUrl
         */

        /**
         * Constructs a new StripePaymentIssueCustomerPortalLinkRequest.
         * @memberof payment
         * @classdesc Represents a StripePaymentIssueCustomerPortalLinkRequest.
         * @implements IStripePaymentIssueCustomerPortalLinkRequest
         * @constructor
         * @param {payment.IStripePaymentIssueCustomerPortalLinkRequest=} [properties] Properties to set
         */
        function StripePaymentIssueCustomerPortalLinkRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentIssueCustomerPortalLinkRequest returnUrl.
         * @member {google.protobuf.IStringValue|null|undefined} returnUrl
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @instance
         */
        StripePaymentIssueCustomerPortalLinkRequest.prototype.returnUrl = null;

        /**
         * Creates a new StripePaymentIssueCustomerPortalLinkRequest instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @static
         * @param {payment.IStripePaymentIssueCustomerPortalLinkRequest=} [properties] Properties to set
         * @returns {payment.StripePaymentIssueCustomerPortalLinkRequest} StripePaymentIssueCustomerPortalLinkRequest instance
         */
        StripePaymentIssueCustomerPortalLinkRequest.create = function create(properties) {
            return new StripePaymentIssueCustomerPortalLinkRequest(properties);
        };

        /**
         * Encodes the specified StripePaymentIssueCustomerPortalLinkRequest message. Does not implicitly {@link payment.StripePaymentIssueCustomerPortalLinkRequest.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @static
         * @param {payment.IStripePaymentIssueCustomerPortalLinkRequest} message StripePaymentIssueCustomerPortalLinkRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentIssueCustomerPortalLinkRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.returnUrl != null && Object.hasOwnProperty.call(message, "returnUrl"))
                $root.google.protobuf.StringValue.encode(message.returnUrl, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentIssueCustomerPortalLinkRequest message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentIssueCustomerPortalLinkRequest} StripePaymentIssueCustomerPortalLinkRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentIssueCustomerPortalLinkRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentIssueCustomerPortalLinkRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.returnUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentIssueCustomerPortalLinkRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentIssueCustomerPortalLinkRequest} StripePaymentIssueCustomerPortalLinkRequest
         */
        StripePaymentIssueCustomerPortalLinkRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentIssueCustomerPortalLinkRequest)
                return object;
            var message = new $root.payment.StripePaymentIssueCustomerPortalLinkRequest();
            if (object.returnUrl != null) {
                if (typeof object.returnUrl !== "object")
                    throw TypeError(".payment.StripePaymentIssueCustomerPortalLinkRequest.returnUrl: object expected");
                message.returnUrl = $root.google.protobuf.StringValue.fromObject(object.returnUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentIssueCustomerPortalLinkRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @static
         * @param {payment.StripePaymentIssueCustomerPortalLinkRequest} message StripePaymentIssueCustomerPortalLinkRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentIssueCustomerPortalLinkRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.returnUrl = null;
            if (message.returnUrl != null && message.hasOwnProperty("returnUrl"))
                object.returnUrl = $root.google.protobuf.StringValue.toObject(message.returnUrl, options);
            return object;
        };

        /**
         * Converts this StripePaymentIssueCustomerPortalLinkRequest to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentIssueCustomerPortalLinkRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentIssueCustomerPortalLinkRequest
         * @function getTypeUrl
         * @memberof payment.StripePaymentIssueCustomerPortalLinkRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentIssueCustomerPortalLinkRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentIssueCustomerPortalLinkRequest";
        };

        return StripePaymentIssueCustomerPortalLinkRequest;
    })();

    payment.StripePaymentIssueCustomerPortalLinkResponse = (function() {

        /**
         * Properties of a StripePaymentIssueCustomerPortalLinkResponse.
         * @memberof payment
         * @interface IStripePaymentIssueCustomerPortalLinkResponse
         * @property {google.protobuf.IStringValue|null} [url] StripePaymentIssueCustomerPortalLinkResponse url
         */

        /**
         * Constructs a new StripePaymentIssueCustomerPortalLinkResponse.
         * @memberof payment
         * @classdesc Represents a StripePaymentIssueCustomerPortalLinkResponse.
         * @implements IStripePaymentIssueCustomerPortalLinkResponse
         * @constructor
         * @param {payment.IStripePaymentIssueCustomerPortalLinkResponse=} [properties] Properties to set
         */
        function StripePaymentIssueCustomerPortalLinkResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentIssueCustomerPortalLinkResponse url.
         * @member {google.protobuf.IStringValue|null|undefined} url
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @instance
         */
        StripePaymentIssueCustomerPortalLinkResponse.prototype.url = null;

        /**
         * Creates a new StripePaymentIssueCustomerPortalLinkResponse instance using the specified properties.
         * @function create
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @static
         * @param {payment.IStripePaymentIssueCustomerPortalLinkResponse=} [properties] Properties to set
         * @returns {payment.StripePaymentIssueCustomerPortalLinkResponse} StripePaymentIssueCustomerPortalLinkResponse instance
         */
        StripePaymentIssueCustomerPortalLinkResponse.create = function create(properties) {
            return new StripePaymentIssueCustomerPortalLinkResponse(properties);
        };

        /**
         * Encodes the specified StripePaymentIssueCustomerPortalLinkResponse message. Does not implicitly {@link payment.StripePaymentIssueCustomerPortalLinkResponse.verify|verify} messages.
         * @function encode
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @static
         * @param {payment.IStripePaymentIssueCustomerPortalLinkResponse} message StripePaymentIssueCustomerPortalLinkResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentIssueCustomerPortalLinkResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                $root.google.protobuf.StringValue.encode(message.url, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StripePaymentIssueCustomerPortalLinkResponse message from the specified reader or buffer.
         * @function decode
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {payment.StripePaymentIssueCustomerPortalLinkResponse} StripePaymentIssueCustomerPortalLinkResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentIssueCustomerPortalLinkResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.payment.StripePaymentIssueCustomerPortalLinkResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StripePaymentIssueCustomerPortalLinkResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {payment.StripePaymentIssueCustomerPortalLinkResponse} StripePaymentIssueCustomerPortalLinkResponse
         */
        StripePaymentIssueCustomerPortalLinkResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.payment.StripePaymentIssueCustomerPortalLinkResponse)
                return object;
            var message = new $root.payment.StripePaymentIssueCustomerPortalLinkResponse();
            if (object.url != null) {
                if (typeof object.url !== "object")
                    throw TypeError(".payment.StripePaymentIssueCustomerPortalLinkResponse.url: object expected");
                message.url = $root.google.protobuf.StringValue.fromObject(object.url);
            }
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentIssueCustomerPortalLinkResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @static
         * @param {payment.StripePaymentIssueCustomerPortalLinkResponse} message StripePaymentIssueCustomerPortalLinkResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentIssueCustomerPortalLinkResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.url = null;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = $root.google.protobuf.StringValue.toObject(message.url, options);
            return object;
        };

        /**
         * Converts this StripePaymentIssueCustomerPortalLinkResponse to JSON.
         * @function toJSON
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentIssueCustomerPortalLinkResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentIssueCustomerPortalLinkResponse
         * @function getTypeUrl
         * @memberof payment.StripePaymentIssueCustomerPortalLinkResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentIssueCustomerPortalLinkResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/payment.StripePaymentIssueCustomerPortalLinkResponse";
        };

        return StripePaymentIssueCustomerPortalLinkResponse;
    })();

    return payment;
})();

$root.query_inspection = (function() {

    /**
     * Namespace query_inspection.
     * @exports query_inspection
     * @namespace
     */
    var query_inspection = {};

    query_inspection.UserPenaltyRequest = (function() {

        /**
         * Properties of a UserPenaltyRequest.
         * @memberof query_inspection
         * @interface IUserPenaltyRequest
         * @property {google.protobuf.IBoolValue|null} [requireIsActivated] UserPenaltyRequest requireIsActivated
         * @property {google.protobuf.IBoolValue|null} [requireDuration] UserPenaltyRequest requireDuration
         * @property {google.protobuf.IBoolValue|null} [requireActivatedAt] UserPenaltyRequest requireActivatedAt
         * @property {google.protobuf.IBoolValue|null} [requireExpiresAt] UserPenaltyRequest requireExpiresAt
         */

        /**
         * Constructs a new UserPenaltyRequest.
         * @memberof query_inspection
         * @classdesc Represents a UserPenaltyRequest.
         * @implements IUserPenaltyRequest
         * @constructor
         * @param {query_inspection.IUserPenaltyRequest=} [properties] Properties to set
         */
        function UserPenaltyRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPenaltyRequest requireIsActivated.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsActivated
         * @memberof query_inspection.UserPenaltyRequest
         * @instance
         */
        UserPenaltyRequest.prototype.requireIsActivated = null;

        /**
         * UserPenaltyRequest requireDuration.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDuration
         * @memberof query_inspection.UserPenaltyRequest
         * @instance
         */
        UserPenaltyRequest.prototype.requireDuration = null;

        /**
         * UserPenaltyRequest requireActivatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireActivatedAt
         * @memberof query_inspection.UserPenaltyRequest
         * @instance
         */
        UserPenaltyRequest.prototype.requireActivatedAt = null;

        /**
         * UserPenaltyRequest requireExpiresAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireExpiresAt
         * @memberof query_inspection.UserPenaltyRequest
         * @instance
         */
        UserPenaltyRequest.prototype.requireExpiresAt = null;

        /**
         * Creates a new UserPenaltyRequest instance using the specified properties.
         * @function create
         * @memberof query_inspection.UserPenaltyRequest
         * @static
         * @param {query_inspection.IUserPenaltyRequest=} [properties] Properties to set
         * @returns {query_inspection.UserPenaltyRequest} UserPenaltyRequest instance
         */
        UserPenaltyRequest.create = function create(properties) {
            return new UserPenaltyRequest(properties);
        };

        /**
         * Encodes the specified UserPenaltyRequest message. Does not implicitly {@link query_inspection.UserPenaltyRequest.verify|verify} messages.
         * @function encode
         * @memberof query_inspection.UserPenaltyRequest
         * @static
         * @param {query_inspection.IUserPenaltyRequest} message UserPenaltyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPenaltyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireIsActivated != null && Object.hasOwnProperty.call(message, "requireIsActivated"))
                $root.google.protobuf.BoolValue.encode(message.requireIsActivated, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireDuration != null && Object.hasOwnProperty.call(message, "requireDuration"))
                $root.google.protobuf.BoolValue.encode(message.requireDuration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireActivatedAt != null && Object.hasOwnProperty.call(message, "requireActivatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireActivatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireExpiresAt != null && Object.hasOwnProperty.call(message, "requireExpiresAt"))
                $root.google.protobuf.BoolValue.encode(message.requireExpiresAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserPenaltyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_inspection.UserPenaltyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_inspection.UserPenaltyRequest} UserPenaltyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPenaltyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_inspection.UserPenaltyRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireIsActivated = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireDuration = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireActivatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireExpiresAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserPenaltyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_inspection.UserPenaltyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_inspection.UserPenaltyRequest} UserPenaltyRequest
         */
        UserPenaltyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_inspection.UserPenaltyRequest)
                return object;
            var message = new $root.query_inspection.UserPenaltyRequest();
            if (object.requireIsActivated != null) {
                if (typeof object.requireIsActivated !== "object")
                    throw TypeError(".query_inspection.UserPenaltyRequest.requireIsActivated: object expected");
                message.requireIsActivated = $root.google.protobuf.BoolValue.fromObject(object.requireIsActivated);
            }
            if (object.requireDuration != null) {
                if (typeof object.requireDuration !== "object")
                    throw TypeError(".query_inspection.UserPenaltyRequest.requireDuration: object expected");
                message.requireDuration = $root.google.protobuf.BoolValue.fromObject(object.requireDuration);
            }
            if (object.requireActivatedAt != null) {
                if (typeof object.requireActivatedAt !== "object")
                    throw TypeError(".query_inspection.UserPenaltyRequest.requireActivatedAt: object expected");
                message.requireActivatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireActivatedAt);
            }
            if (object.requireExpiresAt != null) {
                if (typeof object.requireExpiresAt !== "object")
                    throw TypeError(".query_inspection.UserPenaltyRequest.requireExpiresAt: object expected");
                message.requireExpiresAt = $root.google.protobuf.BoolValue.fromObject(object.requireExpiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserPenaltyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_inspection.UserPenaltyRequest
         * @static
         * @param {query_inspection.UserPenaltyRequest} message UserPenaltyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPenaltyRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireIsActivated = null;
                object.requireDuration = null;
                object.requireActivatedAt = null;
                object.requireExpiresAt = null;
            }
            if (message.requireIsActivated != null && message.hasOwnProperty("requireIsActivated"))
                object.requireIsActivated = $root.google.protobuf.BoolValue.toObject(message.requireIsActivated, options);
            if (message.requireDuration != null && message.hasOwnProperty("requireDuration"))
                object.requireDuration = $root.google.protobuf.BoolValue.toObject(message.requireDuration, options);
            if (message.requireActivatedAt != null && message.hasOwnProperty("requireActivatedAt"))
                object.requireActivatedAt = $root.google.protobuf.BoolValue.toObject(message.requireActivatedAt, options);
            if (message.requireExpiresAt != null && message.hasOwnProperty("requireExpiresAt"))
                object.requireExpiresAt = $root.google.protobuf.BoolValue.toObject(message.requireExpiresAt, options);
            return object;
        };

        /**
         * Converts this UserPenaltyRequest to JSON.
         * @function toJSON
         * @memberof query_inspection.UserPenaltyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPenaltyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserPenaltyRequest
         * @function getTypeUrl
         * @memberof query_inspection.UserPenaltyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPenaltyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_inspection.UserPenaltyRequest";
        };

        return UserPenaltyRequest;
    })();

    query_inspection.UserPenaltyResponse = (function() {

        /**
         * Properties of a UserPenaltyResponse.
         * @memberof query_inspection
         * @interface IUserPenaltyResponse
         * @property {google.protobuf.IBoolValue|null} [isActivated] UserPenaltyResponse isActivated
         * @property {google.protobuf.IDuration|null} [duration] UserPenaltyResponse duration
         * @property {google.protobuf.ITimestamp|null} [activatedAt] UserPenaltyResponse activatedAt
         * @property {google.protobuf.ITimestamp|null} [expiresAt] UserPenaltyResponse expiresAt
         */

        /**
         * Constructs a new UserPenaltyResponse.
         * @memberof query_inspection
         * @classdesc Represents a UserPenaltyResponse.
         * @implements IUserPenaltyResponse
         * @constructor
         * @param {query_inspection.IUserPenaltyResponse=} [properties] Properties to set
         */
        function UserPenaltyResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPenaltyResponse isActivated.
         * @member {google.protobuf.IBoolValue|null|undefined} isActivated
         * @memberof query_inspection.UserPenaltyResponse
         * @instance
         */
        UserPenaltyResponse.prototype.isActivated = null;

        /**
         * UserPenaltyResponse duration.
         * @member {google.protobuf.IDuration|null|undefined} duration
         * @memberof query_inspection.UserPenaltyResponse
         * @instance
         */
        UserPenaltyResponse.prototype.duration = null;

        /**
         * UserPenaltyResponse activatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} activatedAt
         * @memberof query_inspection.UserPenaltyResponse
         * @instance
         */
        UserPenaltyResponse.prototype.activatedAt = null;

        /**
         * UserPenaltyResponse expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof query_inspection.UserPenaltyResponse
         * @instance
         */
        UserPenaltyResponse.prototype.expiresAt = null;

        /**
         * Creates a new UserPenaltyResponse instance using the specified properties.
         * @function create
         * @memberof query_inspection.UserPenaltyResponse
         * @static
         * @param {query_inspection.IUserPenaltyResponse=} [properties] Properties to set
         * @returns {query_inspection.UserPenaltyResponse} UserPenaltyResponse instance
         */
        UserPenaltyResponse.create = function create(properties) {
            return new UserPenaltyResponse(properties);
        };

        /**
         * Encodes the specified UserPenaltyResponse message. Does not implicitly {@link query_inspection.UserPenaltyResponse.verify|verify} messages.
         * @function encode
         * @memberof query_inspection.UserPenaltyResponse
         * @static
         * @param {query_inspection.IUserPenaltyResponse} message UserPenaltyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPenaltyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isActivated != null && Object.hasOwnProperty.call(message, "isActivated"))
                $root.google.protobuf.BoolValue.encode(message.isActivated, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.activatedAt != null && Object.hasOwnProperty.call(message, "activatedAt"))
                $root.google.protobuf.Timestamp.encode(message.activatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserPenaltyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_inspection.UserPenaltyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_inspection.UserPenaltyResponse} UserPenaltyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPenaltyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_inspection.UserPenaltyResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.isActivated = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.activatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserPenaltyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_inspection.UserPenaltyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_inspection.UserPenaltyResponse} UserPenaltyResponse
         */
        UserPenaltyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_inspection.UserPenaltyResponse)
                return object;
            var message = new $root.query_inspection.UserPenaltyResponse();
            if (object.isActivated != null) {
                if (typeof object.isActivated !== "object")
                    throw TypeError(".query_inspection.UserPenaltyResponse.isActivated: object expected");
                message.isActivated = $root.google.protobuf.BoolValue.fromObject(object.isActivated);
            }
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".query_inspection.UserPenaltyResponse.duration: object expected");
                message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
            }
            if (object.activatedAt != null) {
                if (typeof object.activatedAt !== "object")
                    throw TypeError(".query_inspection.UserPenaltyResponse.activatedAt: object expected");
                message.activatedAt = $root.google.protobuf.Timestamp.fromObject(object.activatedAt);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".query_inspection.UserPenaltyResponse.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserPenaltyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_inspection.UserPenaltyResponse
         * @static
         * @param {query_inspection.UserPenaltyResponse} message UserPenaltyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPenaltyResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.isActivated = null;
                object.duration = null;
                object.activatedAt = null;
                object.expiresAt = null;
            }
            if (message.isActivated != null && message.hasOwnProperty("isActivated"))
                object.isActivated = $root.google.protobuf.BoolValue.toObject(message.isActivated, options);
            if (message.duration != null && message.hasOwnProperty("duration"))
                object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
            if (message.activatedAt != null && message.hasOwnProperty("activatedAt"))
                object.activatedAt = $root.google.protobuf.Timestamp.toObject(message.activatedAt, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            return object;
        };

        /**
         * Converts this UserPenaltyResponse to JSON.
         * @function toJSON
         * @memberof query_inspection.UserPenaltyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPenaltyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserPenaltyResponse
         * @function getTypeUrl
         * @memberof query_inspection.UserPenaltyResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPenaltyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_inspection.UserPenaltyResponse";
        };

        return UserPenaltyResponse;
    })();

    return query_inspection;
})();

$root.query_pagination = (function() {

    /**
     * Namespace query_pagination.
     * @exports query_pagination
     * @namespace
     */
    var query_pagination = {};

    query_pagination.PageRequest = (function() {

        /**
         * Properties of a PageRequest.
         * @memberof query_pagination
         * @interface IPageRequest
         * @property {google.protobuf.IInt32Value|null} [pageNumber] PageRequest pageNumber
         * @property {google.protobuf.IInt32Value|null} [limit] PageRequest limit
         * @property {query_pagination.IPageInfoRequireField|null} [requirePageInfo] PageRequest requirePageInfo
         */

        /**
         * Constructs a new PageRequest.
         * @memberof query_pagination
         * @classdesc Represents a PageRequest.
         * @implements IPageRequest
         * @constructor
         * @param {query_pagination.IPageRequest=} [properties] Properties to set
         */
        function PageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PageRequest pageNumber.
         * @member {google.protobuf.IInt32Value|null|undefined} pageNumber
         * @memberof query_pagination.PageRequest
         * @instance
         */
        PageRequest.prototype.pageNumber = null;

        /**
         * PageRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof query_pagination.PageRequest
         * @instance
         */
        PageRequest.prototype.limit = null;

        /**
         * PageRequest requirePageInfo.
         * @member {query_pagination.IPageInfoRequireField|null|undefined} requirePageInfo
         * @memberof query_pagination.PageRequest
         * @instance
         */
        PageRequest.prototype.requirePageInfo = null;

        /**
         * Creates a new PageRequest instance using the specified properties.
         * @function create
         * @memberof query_pagination.PageRequest
         * @static
         * @param {query_pagination.IPageRequest=} [properties] Properties to set
         * @returns {query_pagination.PageRequest} PageRequest instance
         */
        PageRequest.create = function create(properties) {
            return new PageRequest(properties);
        };

        /**
         * Encodes the specified PageRequest message. Does not implicitly {@link query_pagination.PageRequest.verify|verify} messages.
         * @function encode
         * @memberof query_pagination.PageRequest
         * @static
         * @param {query_pagination.IPageRequest} message PageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pageNumber != null && Object.hasOwnProperty.call(message, "pageNumber"))
                $root.google.protobuf.Int32Value.encode(message.pageNumber, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requirePageInfo != null && Object.hasOwnProperty.call(message, "requirePageInfo"))
                $root.query_pagination.PageInfoRequireField.encode(message.requirePageInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_pagination.PageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_pagination.PageRequest} PageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_pagination.PageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.pageNumber = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requirePageInfo = $root.query_pagination.PageInfoRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_pagination.PageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_pagination.PageRequest} PageRequest
         */
        PageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_pagination.PageRequest)
                return object;
            var message = new $root.query_pagination.PageRequest();
            if (object.pageNumber != null) {
                if (typeof object.pageNumber !== "object")
                    throw TypeError(".query_pagination.PageRequest.pageNumber: object expected");
                message.pageNumber = $root.google.protobuf.Int32Value.fromObject(object.pageNumber);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".query_pagination.PageRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.requirePageInfo != null) {
                if (typeof object.requirePageInfo !== "object")
                    throw TypeError(".query_pagination.PageRequest.requirePageInfo: object expected");
                message.requirePageInfo = $root.query_pagination.PageInfoRequireField.fromObject(object.requirePageInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a PageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_pagination.PageRequest
         * @static
         * @param {query_pagination.PageRequest} message PageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.pageNumber = null;
                object.limit = null;
                object.requirePageInfo = null;
            }
            if (message.pageNumber != null && message.hasOwnProperty("pageNumber"))
                object.pageNumber = $root.google.protobuf.Int32Value.toObject(message.pageNumber, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.requirePageInfo != null && message.hasOwnProperty("requirePageInfo"))
                object.requirePageInfo = $root.query_pagination.PageInfoRequireField.toObject(message.requirePageInfo, options);
            return object;
        };

        /**
         * Converts this PageRequest to JSON.
         * @function toJSON
         * @memberof query_pagination.PageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PageRequest
         * @function getTypeUrl
         * @memberof query_pagination.PageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_pagination.PageRequest";
        };

        return PageRequest;
    })();

    query_pagination.PageInfoRequireField = (function() {

        /**
         * Properties of a PageInfoRequireField.
         * @memberof query_pagination
         * @interface IPageInfoRequireField
         * @property {google.protobuf.IBoolValue|null} [requireHasNextPage] PageInfoRequireField requireHasNextPage
         * @property {google.protobuf.IBoolValue|null} [requireTotalCount] PageInfoRequireField requireTotalCount
         */

        /**
         * Constructs a new PageInfoRequireField.
         * @memberof query_pagination
         * @classdesc Represents a PageInfoRequireField.
         * @implements IPageInfoRequireField
         * @constructor
         * @param {query_pagination.IPageInfoRequireField=} [properties] Properties to set
         */
        function PageInfoRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PageInfoRequireField requireHasNextPage.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHasNextPage
         * @memberof query_pagination.PageInfoRequireField
         * @instance
         */
        PageInfoRequireField.prototype.requireHasNextPage = null;

        /**
         * PageInfoRequireField requireTotalCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTotalCount
         * @memberof query_pagination.PageInfoRequireField
         * @instance
         */
        PageInfoRequireField.prototype.requireTotalCount = null;

        /**
         * Creates a new PageInfoRequireField instance using the specified properties.
         * @function create
         * @memberof query_pagination.PageInfoRequireField
         * @static
         * @param {query_pagination.IPageInfoRequireField=} [properties] Properties to set
         * @returns {query_pagination.PageInfoRequireField} PageInfoRequireField instance
         */
        PageInfoRequireField.create = function create(properties) {
            return new PageInfoRequireField(properties);
        };

        /**
         * Encodes the specified PageInfoRequireField message. Does not implicitly {@link query_pagination.PageInfoRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_pagination.PageInfoRequireField
         * @static
         * @param {query_pagination.IPageInfoRequireField} message PageInfoRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PageInfoRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireHasNextPage != null && Object.hasOwnProperty.call(message, "requireHasNextPage"))
                $root.google.protobuf.BoolValue.encode(message.requireHasNextPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTotalCount != null && Object.hasOwnProperty.call(message, "requireTotalCount"))
                $root.google.protobuf.BoolValue.encode(message.requireTotalCount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PageInfoRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_pagination.PageInfoRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_pagination.PageInfoRequireField} PageInfoRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PageInfoRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_pagination.PageInfoRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireHasNextPage = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTotalCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PageInfoRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_pagination.PageInfoRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_pagination.PageInfoRequireField} PageInfoRequireField
         */
        PageInfoRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_pagination.PageInfoRequireField)
                return object;
            var message = new $root.query_pagination.PageInfoRequireField();
            if (object.requireHasNextPage != null) {
                if (typeof object.requireHasNextPage !== "object")
                    throw TypeError(".query_pagination.PageInfoRequireField.requireHasNextPage: object expected");
                message.requireHasNextPage = $root.google.protobuf.BoolValue.fromObject(object.requireHasNextPage);
            }
            if (object.requireTotalCount != null) {
                if (typeof object.requireTotalCount !== "object")
                    throw TypeError(".query_pagination.PageInfoRequireField.requireTotalCount: object expected");
                message.requireTotalCount = $root.google.protobuf.BoolValue.fromObject(object.requireTotalCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a PageInfoRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_pagination.PageInfoRequireField
         * @static
         * @param {query_pagination.PageInfoRequireField} message PageInfoRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PageInfoRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireHasNextPage = null;
                object.requireTotalCount = null;
            }
            if (message.requireHasNextPage != null && message.hasOwnProperty("requireHasNextPage"))
                object.requireHasNextPage = $root.google.protobuf.BoolValue.toObject(message.requireHasNextPage, options);
            if (message.requireTotalCount != null && message.hasOwnProperty("requireTotalCount"))
                object.requireTotalCount = $root.google.protobuf.BoolValue.toObject(message.requireTotalCount, options);
            return object;
        };

        /**
         * Converts this PageInfoRequireField to JSON.
         * @function toJSON
         * @memberof query_pagination.PageInfoRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PageInfoRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PageInfoRequireField
         * @function getTypeUrl
         * @memberof query_pagination.PageInfoRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PageInfoRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_pagination.PageInfoRequireField";
        };

        return PageInfoRequireField;
    })();

    query_pagination.PageInfo = (function() {

        /**
         * Properties of a PageInfo.
         * @memberof query_pagination
         * @interface IPageInfo
         * @property {google.protobuf.IBoolValue|null} [hasNextPage] PageInfo hasNextPage
         * @property {google.protobuf.IInt32Value|null} [totalCount] PageInfo totalCount
         */

        /**
         * Constructs a new PageInfo.
         * @memberof query_pagination
         * @classdesc Represents a PageInfo.
         * @implements IPageInfo
         * @constructor
         * @param {query_pagination.IPageInfo=} [properties] Properties to set
         */
        function PageInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PageInfo hasNextPage.
         * @member {google.protobuf.IBoolValue|null|undefined} hasNextPage
         * @memberof query_pagination.PageInfo
         * @instance
         */
        PageInfo.prototype.hasNextPage = null;

        /**
         * PageInfo totalCount.
         * @member {google.protobuf.IInt32Value|null|undefined} totalCount
         * @memberof query_pagination.PageInfo
         * @instance
         */
        PageInfo.prototype.totalCount = null;

        /**
         * Creates a new PageInfo instance using the specified properties.
         * @function create
         * @memberof query_pagination.PageInfo
         * @static
         * @param {query_pagination.IPageInfo=} [properties] Properties to set
         * @returns {query_pagination.PageInfo} PageInfo instance
         */
        PageInfo.create = function create(properties) {
            return new PageInfo(properties);
        };

        /**
         * Encodes the specified PageInfo message. Does not implicitly {@link query_pagination.PageInfo.verify|verify} messages.
         * @function encode
         * @memberof query_pagination.PageInfo
         * @static
         * @param {query_pagination.IPageInfo} message PageInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PageInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hasNextPage != null && Object.hasOwnProperty.call(message, "hasNextPage"))
                $root.google.protobuf.BoolValue.encode(message.hasNextPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                $root.google.protobuf.Int32Value.encode(message.totalCount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PageInfo message from the specified reader or buffer.
         * @function decode
         * @memberof query_pagination.PageInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_pagination.PageInfo} PageInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PageInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_pagination.PageInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.hasNextPage = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.totalCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PageInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_pagination.PageInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_pagination.PageInfo} PageInfo
         */
        PageInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.query_pagination.PageInfo)
                return object;
            var message = new $root.query_pagination.PageInfo();
            if (object.hasNextPage != null) {
                if (typeof object.hasNextPage !== "object")
                    throw TypeError(".query_pagination.PageInfo.hasNextPage: object expected");
                message.hasNextPage = $root.google.protobuf.BoolValue.fromObject(object.hasNextPage);
            }
            if (object.totalCount != null) {
                if (typeof object.totalCount !== "object")
                    throw TypeError(".query_pagination.PageInfo.totalCount: object expected");
                message.totalCount = $root.google.protobuf.Int32Value.fromObject(object.totalCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a PageInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_pagination.PageInfo
         * @static
         * @param {query_pagination.PageInfo} message PageInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PageInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.hasNextPage = null;
                object.totalCount = null;
            }
            if (message.hasNextPage != null && message.hasOwnProperty("hasNextPage"))
                object.hasNextPage = $root.google.protobuf.BoolValue.toObject(message.hasNextPage, options);
            if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                object.totalCount = $root.google.protobuf.Int32Value.toObject(message.totalCount, options);
            return object;
        };

        /**
         * Converts this PageInfo to JSON.
         * @function toJSON
         * @memberof query_pagination.PageInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PageInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PageInfo
         * @function getTypeUrl
         * @memberof query_pagination.PageInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PageInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_pagination.PageInfo";
        };

        return PageInfo;
    })();

    query_pagination.CursorRequest = (function() {

        /**
         * Properties of a CursorRequest.
         * @memberof query_pagination
         * @interface ICursorRequest
         * @property {google.protobuf.IStringValue|null} [cursor] CursorRequest cursor
         * @property {google.protobuf.IInt32Value|null} [limit] CursorRequest limit
         * @property {query_pagination.ICursorInfoRequireField|null} [requireCursorInfo] CursorRequest requireCursorInfo
         */

        /**
         * Constructs a new CursorRequest.
         * @memberof query_pagination
         * @classdesc Represents a CursorRequest.
         * @implements ICursorRequest
         * @constructor
         * @param {query_pagination.ICursorRequest=} [properties] Properties to set
         */
        function CursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CursorRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof query_pagination.CursorRequest
         * @instance
         */
        CursorRequest.prototype.cursor = null;

        /**
         * CursorRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof query_pagination.CursorRequest
         * @instance
         */
        CursorRequest.prototype.limit = null;

        /**
         * CursorRequest requireCursorInfo.
         * @member {query_pagination.ICursorInfoRequireField|null|undefined} requireCursorInfo
         * @memberof query_pagination.CursorRequest
         * @instance
         */
        CursorRequest.prototype.requireCursorInfo = null;

        /**
         * Creates a new CursorRequest instance using the specified properties.
         * @function create
         * @memberof query_pagination.CursorRequest
         * @static
         * @param {query_pagination.ICursorRequest=} [properties] Properties to set
         * @returns {query_pagination.CursorRequest} CursorRequest instance
         */
        CursorRequest.create = function create(properties) {
            return new CursorRequest(properties);
        };

        /**
         * Encodes the specified CursorRequest message. Does not implicitly {@link query_pagination.CursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_pagination.CursorRequest
         * @static
         * @param {query_pagination.ICursorRequest} message CursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireCursorInfo != null && Object.hasOwnProperty.call(message, "requireCursorInfo"))
                $root.query_pagination.CursorInfoRequireField.encode(message.requireCursorInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_pagination.CursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_pagination.CursorRequest} CursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_pagination.CursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireCursorInfo = $root.query_pagination.CursorInfoRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_pagination.CursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_pagination.CursorRequest} CursorRequest
         */
        CursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_pagination.CursorRequest)
                return object;
            var message = new $root.query_pagination.CursorRequest();
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_pagination.CursorRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".query_pagination.CursorRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.requireCursorInfo != null) {
                if (typeof object.requireCursorInfo !== "object")
                    throw TypeError(".query_pagination.CursorRequest.requireCursorInfo: object expected");
                message.requireCursorInfo = $root.query_pagination.CursorInfoRequireField.fromObject(object.requireCursorInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a CursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_pagination.CursorRequest
         * @static
         * @param {query_pagination.CursorRequest} message CursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.cursor = null;
                object.limit = null;
                object.requireCursorInfo = null;
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.requireCursorInfo != null && message.hasOwnProperty("requireCursorInfo"))
                object.requireCursorInfo = $root.query_pagination.CursorInfoRequireField.toObject(message.requireCursorInfo, options);
            return object;
        };

        /**
         * Converts this CursorRequest to JSON.
         * @function toJSON
         * @memberof query_pagination.CursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CursorRequest
         * @function getTypeUrl
         * @memberof query_pagination.CursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_pagination.CursorRequest";
        };

        return CursorRequest;
    })();

    query_pagination.CursorInfoRequireField = (function() {

        /**
         * Properties of a CursorInfoRequireField.
         * @memberof query_pagination
         * @interface ICursorInfoRequireField
         * @property {google.protobuf.IBoolValue|null} [requireEndCursor] CursorInfoRequireField requireEndCursor
         * @property {google.protobuf.IBoolValue|null} [requireHasNextPage] CursorInfoRequireField requireHasNextPage
         * @property {google.protobuf.IBoolValue|null} [requireTotalCount] CursorInfoRequireField requireTotalCount
         */

        /**
         * Constructs a new CursorInfoRequireField.
         * @memberof query_pagination
         * @classdesc Represents a CursorInfoRequireField.
         * @implements ICursorInfoRequireField
         * @constructor
         * @param {query_pagination.ICursorInfoRequireField=} [properties] Properties to set
         */
        function CursorInfoRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CursorInfoRequireField requireEndCursor.
         * @member {google.protobuf.IBoolValue|null|undefined} requireEndCursor
         * @memberof query_pagination.CursorInfoRequireField
         * @instance
         */
        CursorInfoRequireField.prototype.requireEndCursor = null;

        /**
         * CursorInfoRequireField requireHasNextPage.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHasNextPage
         * @memberof query_pagination.CursorInfoRequireField
         * @instance
         */
        CursorInfoRequireField.prototype.requireHasNextPage = null;

        /**
         * CursorInfoRequireField requireTotalCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTotalCount
         * @memberof query_pagination.CursorInfoRequireField
         * @instance
         */
        CursorInfoRequireField.prototype.requireTotalCount = null;

        /**
         * Creates a new CursorInfoRequireField instance using the specified properties.
         * @function create
         * @memberof query_pagination.CursorInfoRequireField
         * @static
         * @param {query_pagination.ICursorInfoRequireField=} [properties] Properties to set
         * @returns {query_pagination.CursorInfoRequireField} CursorInfoRequireField instance
         */
        CursorInfoRequireField.create = function create(properties) {
            return new CursorInfoRequireField(properties);
        };

        /**
         * Encodes the specified CursorInfoRequireField message. Does not implicitly {@link query_pagination.CursorInfoRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_pagination.CursorInfoRequireField
         * @static
         * @param {query_pagination.ICursorInfoRequireField} message CursorInfoRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CursorInfoRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireEndCursor != null && Object.hasOwnProperty.call(message, "requireEndCursor"))
                $root.google.protobuf.BoolValue.encode(message.requireEndCursor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireHasNextPage != null && Object.hasOwnProperty.call(message, "requireHasNextPage"))
                $root.google.protobuf.BoolValue.encode(message.requireHasNextPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTotalCount != null && Object.hasOwnProperty.call(message, "requireTotalCount"))
                $root.google.protobuf.BoolValue.encode(message.requireTotalCount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CursorInfoRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_pagination.CursorInfoRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_pagination.CursorInfoRequireField} CursorInfoRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CursorInfoRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_pagination.CursorInfoRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireEndCursor = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireHasNextPage = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTotalCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CursorInfoRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_pagination.CursorInfoRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_pagination.CursorInfoRequireField} CursorInfoRequireField
         */
        CursorInfoRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_pagination.CursorInfoRequireField)
                return object;
            var message = new $root.query_pagination.CursorInfoRequireField();
            if (object.requireEndCursor != null) {
                if (typeof object.requireEndCursor !== "object")
                    throw TypeError(".query_pagination.CursorInfoRequireField.requireEndCursor: object expected");
                message.requireEndCursor = $root.google.protobuf.BoolValue.fromObject(object.requireEndCursor);
            }
            if (object.requireHasNextPage != null) {
                if (typeof object.requireHasNextPage !== "object")
                    throw TypeError(".query_pagination.CursorInfoRequireField.requireHasNextPage: object expected");
                message.requireHasNextPage = $root.google.protobuf.BoolValue.fromObject(object.requireHasNextPage);
            }
            if (object.requireTotalCount != null) {
                if (typeof object.requireTotalCount !== "object")
                    throw TypeError(".query_pagination.CursorInfoRequireField.requireTotalCount: object expected");
                message.requireTotalCount = $root.google.protobuf.BoolValue.fromObject(object.requireTotalCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a CursorInfoRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_pagination.CursorInfoRequireField
         * @static
         * @param {query_pagination.CursorInfoRequireField} message CursorInfoRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CursorInfoRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireEndCursor = null;
                object.requireHasNextPage = null;
                object.requireTotalCount = null;
            }
            if (message.requireEndCursor != null && message.hasOwnProperty("requireEndCursor"))
                object.requireEndCursor = $root.google.protobuf.BoolValue.toObject(message.requireEndCursor, options);
            if (message.requireHasNextPage != null && message.hasOwnProperty("requireHasNextPage"))
                object.requireHasNextPage = $root.google.protobuf.BoolValue.toObject(message.requireHasNextPage, options);
            if (message.requireTotalCount != null && message.hasOwnProperty("requireTotalCount"))
                object.requireTotalCount = $root.google.protobuf.BoolValue.toObject(message.requireTotalCount, options);
            return object;
        };

        /**
         * Converts this CursorInfoRequireField to JSON.
         * @function toJSON
         * @memberof query_pagination.CursorInfoRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CursorInfoRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CursorInfoRequireField
         * @function getTypeUrl
         * @memberof query_pagination.CursorInfoRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CursorInfoRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_pagination.CursorInfoRequireField";
        };

        return CursorInfoRequireField;
    })();

    query_pagination.CursorInfo = (function() {

        /**
         * Properties of a CursorInfo.
         * @memberof query_pagination
         * @interface ICursorInfo
         * @property {google.protobuf.IStringValue|null} [endCursor] CursorInfo endCursor
         * @property {google.protobuf.IBoolValue|null} [hasNextPage] CursorInfo hasNextPage
         * @property {google.protobuf.IInt32Value|null} [totalCount] CursorInfo totalCount
         */

        /**
         * Constructs a new CursorInfo.
         * @memberof query_pagination
         * @classdesc Represents a CursorInfo.
         * @implements ICursorInfo
         * @constructor
         * @param {query_pagination.ICursorInfo=} [properties] Properties to set
         */
        function CursorInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CursorInfo endCursor.
         * @member {google.protobuf.IStringValue|null|undefined} endCursor
         * @memberof query_pagination.CursorInfo
         * @instance
         */
        CursorInfo.prototype.endCursor = null;

        /**
         * CursorInfo hasNextPage.
         * @member {google.protobuf.IBoolValue|null|undefined} hasNextPage
         * @memberof query_pagination.CursorInfo
         * @instance
         */
        CursorInfo.prototype.hasNextPage = null;

        /**
         * CursorInfo totalCount.
         * @member {google.protobuf.IInt32Value|null|undefined} totalCount
         * @memberof query_pagination.CursorInfo
         * @instance
         */
        CursorInfo.prototype.totalCount = null;

        /**
         * Creates a new CursorInfo instance using the specified properties.
         * @function create
         * @memberof query_pagination.CursorInfo
         * @static
         * @param {query_pagination.ICursorInfo=} [properties] Properties to set
         * @returns {query_pagination.CursorInfo} CursorInfo instance
         */
        CursorInfo.create = function create(properties) {
            return new CursorInfo(properties);
        };

        /**
         * Encodes the specified CursorInfo message. Does not implicitly {@link query_pagination.CursorInfo.verify|verify} messages.
         * @function encode
         * @memberof query_pagination.CursorInfo
         * @static
         * @param {query_pagination.ICursorInfo} message CursorInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CursorInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.endCursor != null && Object.hasOwnProperty.call(message, "endCursor"))
                $root.google.protobuf.StringValue.encode(message.endCursor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.hasNextPage != null && Object.hasOwnProperty.call(message, "hasNextPage"))
                $root.google.protobuf.BoolValue.encode(message.hasNextPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                $root.google.protobuf.Int32Value.encode(message.totalCount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CursorInfo message from the specified reader or buffer.
         * @function decode
         * @memberof query_pagination.CursorInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_pagination.CursorInfo} CursorInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CursorInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_pagination.CursorInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.endCursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.hasNextPage = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.totalCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CursorInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_pagination.CursorInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_pagination.CursorInfo} CursorInfo
         */
        CursorInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.query_pagination.CursorInfo)
                return object;
            var message = new $root.query_pagination.CursorInfo();
            if (object.endCursor != null) {
                if (typeof object.endCursor !== "object")
                    throw TypeError(".query_pagination.CursorInfo.endCursor: object expected");
                message.endCursor = $root.google.protobuf.StringValue.fromObject(object.endCursor);
            }
            if (object.hasNextPage != null) {
                if (typeof object.hasNextPage !== "object")
                    throw TypeError(".query_pagination.CursorInfo.hasNextPage: object expected");
                message.hasNextPage = $root.google.protobuf.BoolValue.fromObject(object.hasNextPage);
            }
            if (object.totalCount != null) {
                if (typeof object.totalCount !== "object")
                    throw TypeError(".query_pagination.CursorInfo.totalCount: object expected");
                message.totalCount = $root.google.protobuf.Int32Value.fromObject(object.totalCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a CursorInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_pagination.CursorInfo
         * @static
         * @param {query_pagination.CursorInfo} message CursorInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CursorInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.endCursor = null;
                object.hasNextPage = null;
                object.totalCount = null;
            }
            if (message.endCursor != null && message.hasOwnProperty("endCursor"))
                object.endCursor = $root.google.protobuf.StringValue.toObject(message.endCursor, options);
            if (message.hasNextPage != null && message.hasOwnProperty("hasNextPage"))
                object.hasNextPage = $root.google.protobuf.BoolValue.toObject(message.hasNextPage, options);
            if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                object.totalCount = $root.google.protobuf.Int32Value.toObject(message.totalCount, options);
            return object;
        };

        /**
         * Converts this CursorInfo to JSON.
         * @function toJSON
         * @memberof query_pagination.CursorInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CursorInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CursorInfo
         * @function getTypeUrl
         * @memberof query_pagination.CursorInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CursorInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_pagination.CursorInfo";
        };

        return CursorInfo;
    })();

    return query_pagination;
})();

$root.query_recursive_types = (function() {

    /**
     * Namespace query_recursive_types.
     * @exports query_recursive_types
     * @namespace
     */
    var query_recursive_types = {};

    query_recursive_types.StoryRequest = (function() {

        /**
         * Properties of a StoryRequest.
         * @memberof query_recursive_types
         * @interface IStoryRequest
         * @property {google.protobuf.IStringValue|null} [id] StoryRequest id
         * @property {query_recursive_types.IStoryRequireField|null} [requireStory] StoryRequest requireStory
         */

        /**
         * Constructs a new StoryRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryRequest.
         * @implements IStoryRequest
         * @constructor
         * @param {query_recursive_types.IStoryRequest=} [properties] Properties to set
         */
        function StoryRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.StoryRequest
         * @instance
         */
        StoryRequest.prototype.id = null;

        /**
         * StoryRequest requireStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStory
         * @memberof query_recursive_types.StoryRequest
         * @instance
         */
        StoryRequest.prototype.requireStory = null;

        /**
         * Creates a new StoryRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryRequest
         * @static
         * @param {query_recursive_types.IStoryRequest=} [properties] Properties to set
         * @returns {query_recursive_types.StoryRequest} StoryRequest instance
         */
        StoryRequest.create = function create(properties) {
            return new StoryRequest(properties);
        };

        /**
         * Encodes the specified StoryRequest message. Does not implicitly {@link query_recursive_types.StoryRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryRequest
         * @static
         * @param {query_recursive_types.IStoryRequest} message StoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireStory != null && Object.hasOwnProperty.call(message, "requireStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStory, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryRequest} StoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryRequest} StoryRequest
         */
        StoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryRequest)
                return object;
            var message = new $root.query_recursive_types.StoryRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.StoryRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireStory != null) {
                if (typeof object.requireStory !== "object")
                    throw TypeError(".query_recursive_types.StoryRequest.requireStory: object expected");
                message.requireStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStory);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryRequest
         * @static
         * @param {query_recursive_types.StoryRequest} message StoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireStory = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireStory != null && message.hasOwnProperty("requireStory"))
                object.requireStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireStory, options);
            return object;
        };

        /**
         * Converts this StoryRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryRequest";
        };

        return StoryRequest;
    })();

    query_recursive_types.StoryChatNovelScriptRequireField = (function() {

        /**
         * Properties of a StoryChatNovelScriptRequireField.
         * @memberof query_recursive_types
         * @interface IStoryChatNovelScriptRequireField
         * @property {google.protobuf.IBoolValue|null} [requireCharLength] StoryChatNovelScriptRequireField requireCharLength
         * @property {google.protobuf.IBoolValue|null} [requireOpLength] StoryChatNovelScriptRequireField requireOpLength
         * @property {google.protobuf.IBoolValue|null} [requireTrialScript] StoryChatNovelScriptRequireField requireTrialScript
         * @property {google.protobuf.IBoolValue|null} [requireFullScript] StoryChatNovelScriptRequireField requireFullScript
         * @property {query_recursive_types.IImageRequireField|null} [requireScriptImageList] StoryChatNovelScriptRequireField requireScriptImageList
         * @property {query_recursive_types.IAudioRequireField|null} [requireScriptAudioList] StoryChatNovelScriptRequireField requireScriptAudioList
         */

        /**
         * Constructs a new StoryChatNovelScriptRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryChatNovelScriptRequireField.
         * @implements IStoryChatNovelScriptRequireField
         * @constructor
         * @param {query_recursive_types.IStoryChatNovelScriptRequireField=} [properties] Properties to set
         */
        function StoryChatNovelScriptRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryChatNovelScriptRequireField requireCharLength.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCharLength
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @instance
         */
        StoryChatNovelScriptRequireField.prototype.requireCharLength = null;

        /**
         * StoryChatNovelScriptRequireField requireOpLength.
         * @member {google.protobuf.IBoolValue|null|undefined} requireOpLength
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @instance
         */
        StoryChatNovelScriptRequireField.prototype.requireOpLength = null;

        /**
         * StoryChatNovelScriptRequireField requireTrialScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTrialScript
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @instance
         */
        StoryChatNovelScriptRequireField.prototype.requireTrialScript = null;

        /**
         * StoryChatNovelScriptRequireField requireFullScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireFullScript
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @instance
         */
        StoryChatNovelScriptRequireField.prototype.requireFullScript = null;

        /**
         * StoryChatNovelScriptRequireField requireScriptImageList.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireScriptImageList
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @instance
         */
        StoryChatNovelScriptRequireField.prototype.requireScriptImageList = null;

        /**
         * StoryChatNovelScriptRequireField requireScriptAudioList.
         * @member {query_recursive_types.IAudioRequireField|null|undefined} requireScriptAudioList
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @instance
         */
        StoryChatNovelScriptRequireField.prototype.requireScriptAudioList = null;

        /**
         * Creates a new StoryChatNovelScriptRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @static
         * @param {query_recursive_types.IStoryChatNovelScriptRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.StoryChatNovelScriptRequireField} StoryChatNovelScriptRequireField instance
         */
        StoryChatNovelScriptRequireField.create = function create(properties) {
            return new StoryChatNovelScriptRequireField(properties);
        };

        /**
         * Encodes the specified StoryChatNovelScriptRequireField message. Does not implicitly {@link query_recursive_types.StoryChatNovelScriptRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @static
         * @param {query_recursive_types.IStoryChatNovelScriptRequireField} message StoryChatNovelScriptRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryChatNovelScriptRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireCharLength != null && Object.hasOwnProperty.call(message, "requireCharLength"))
                $root.google.protobuf.BoolValue.encode(message.requireCharLength, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireOpLength != null && Object.hasOwnProperty.call(message, "requireOpLength"))
                $root.google.protobuf.BoolValue.encode(message.requireOpLength, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTrialScript != null && Object.hasOwnProperty.call(message, "requireTrialScript"))
                $root.google.protobuf.BoolValue.encode(message.requireTrialScript, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireFullScript != null && Object.hasOwnProperty.call(message, "requireFullScript"))
                $root.google.protobuf.BoolValue.encode(message.requireFullScript, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireScriptImageList != null && Object.hasOwnProperty.call(message, "requireScriptImageList"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireScriptImageList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireScriptAudioList != null && Object.hasOwnProperty.call(message, "requireScriptAudioList"))
                $root.query_recursive_types.AudioRequireField.encode(message.requireScriptAudioList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryChatNovelScriptRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryChatNovelScriptRequireField} StoryChatNovelScriptRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryChatNovelScriptRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryChatNovelScriptRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireCharLength = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireOpLength = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTrialScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireFullScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireScriptImageList = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireScriptAudioList = $root.query_recursive_types.AudioRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryChatNovelScriptRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryChatNovelScriptRequireField} StoryChatNovelScriptRequireField
         */
        StoryChatNovelScriptRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryChatNovelScriptRequireField)
                return object;
            var message = new $root.query_recursive_types.StoryChatNovelScriptRequireField();
            if (object.requireCharLength != null) {
                if (typeof object.requireCharLength !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptRequireField.requireCharLength: object expected");
                message.requireCharLength = $root.google.protobuf.BoolValue.fromObject(object.requireCharLength);
            }
            if (object.requireOpLength != null) {
                if (typeof object.requireOpLength !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptRequireField.requireOpLength: object expected");
                message.requireOpLength = $root.google.protobuf.BoolValue.fromObject(object.requireOpLength);
            }
            if (object.requireTrialScript != null) {
                if (typeof object.requireTrialScript !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptRequireField.requireTrialScript: object expected");
                message.requireTrialScript = $root.google.protobuf.BoolValue.fromObject(object.requireTrialScript);
            }
            if (object.requireFullScript != null) {
                if (typeof object.requireFullScript !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptRequireField.requireFullScript: object expected");
                message.requireFullScript = $root.google.protobuf.BoolValue.fromObject(object.requireFullScript);
            }
            if (object.requireScriptImageList != null) {
                if (typeof object.requireScriptImageList !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptRequireField.requireScriptImageList: object expected");
                message.requireScriptImageList = $root.query_recursive_types.ImageRequireField.fromObject(object.requireScriptImageList);
            }
            if (object.requireScriptAudioList != null) {
                if (typeof object.requireScriptAudioList !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptRequireField.requireScriptAudioList: object expected");
                message.requireScriptAudioList = $root.query_recursive_types.AudioRequireField.fromObject(object.requireScriptAudioList);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryChatNovelScriptRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @static
         * @param {query_recursive_types.StoryChatNovelScriptRequireField} message StoryChatNovelScriptRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryChatNovelScriptRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireCharLength = null;
                object.requireOpLength = null;
                object.requireTrialScript = null;
                object.requireFullScript = null;
                object.requireScriptImageList = null;
                object.requireScriptAudioList = null;
            }
            if (message.requireCharLength != null && message.hasOwnProperty("requireCharLength"))
                object.requireCharLength = $root.google.protobuf.BoolValue.toObject(message.requireCharLength, options);
            if (message.requireOpLength != null && message.hasOwnProperty("requireOpLength"))
                object.requireOpLength = $root.google.protobuf.BoolValue.toObject(message.requireOpLength, options);
            if (message.requireTrialScript != null && message.hasOwnProperty("requireTrialScript"))
                object.requireTrialScript = $root.google.protobuf.BoolValue.toObject(message.requireTrialScript, options);
            if (message.requireFullScript != null && message.hasOwnProperty("requireFullScript"))
                object.requireFullScript = $root.google.protobuf.BoolValue.toObject(message.requireFullScript, options);
            if (message.requireScriptImageList != null && message.hasOwnProperty("requireScriptImageList"))
                object.requireScriptImageList = $root.query_recursive_types.ImageRequireField.toObject(message.requireScriptImageList, options);
            if (message.requireScriptAudioList != null && message.hasOwnProperty("requireScriptAudioList"))
                object.requireScriptAudioList = $root.query_recursive_types.AudioRequireField.toObject(message.requireScriptAudioList, options);
            return object;
        };

        /**
         * Converts this StoryChatNovelScriptRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryChatNovelScriptRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryChatNovelScriptRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryChatNovelScriptRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryChatNovelScriptRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryChatNovelScriptRequireField";
        };

        return StoryChatNovelScriptRequireField;
    })();

    query_recursive_types.StoryChatNovelScriptResponse = (function() {

        /**
         * Properties of a StoryChatNovelScriptResponse.
         * @memberof query_recursive_types
         * @interface IStoryChatNovelScriptResponse
         * @property {google.protobuf.IInt32Value|null} [charLength] StoryChatNovelScriptResponse charLength
         * @property {google.protobuf.IInt32Value|null} [opLength] StoryChatNovelScriptResponse opLength
         * @property {tellerscript.ITellerScript|null} [trialScript] StoryChatNovelScriptResponse trialScript
         * @property {tellerscript.ITellerScript|null} [fullScript] StoryChatNovelScriptResponse fullScript
         * @property {Array.<query_recursive_types.IImageResponse>|null} [scriptImageList] StoryChatNovelScriptResponse scriptImageList
         * @property {Array.<query_recursive_types.IAudioResponse>|null} [scriptAudioList] StoryChatNovelScriptResponse scriptAudioList
         */

        /**
         * Constructs a new StoryChatNovelScriptResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryChatNovelScriptResponse.
         * @implements IStoryChatNovelScriptResponse
         * @constructor
         * @param {query_recursive_types.IStoryChatNovelScriptResponse=} [properties] Properties to set
         */
        function StoryChatNovelScriptResponse(properties) {
            this.scriptImageList = [];
            this.scriptAudioList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryChatNovelScriptResponse charLength.
         * @member {google.protobuf.IInt32Value|null|undefined} charLength
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @instance
         */
        StoryChatNovelScriptResponse.prototype.charLength = null;

        /**
         * StoryChatNovelScriptResponse opLength.
         * @member {google.protobuf.IInt32Value|null|undefined} opLength
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @instance
         */
        StoryChatNovelScriptResponse.prototype.opLength = null;

        /**
         * StoryChatNovelScriptResponse trialScript.
         * @member {tellerscript.ITellerScript|null|undefined} trialScript
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @instance
         */
        StoryChatNovelScriptResponse.prototype.trialScript = null;

        /**
         * StoryChatNovelScriptResponse fullScript.
         * @member {tellerscript.ITellerScript|null|undefined} fullScript
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @instance
         */
        StoryChatNovelScriptResponse.prototype.fullScript = null;

        /**
         * StoryChatNovelScriptResponse scriptImageList.
         * @member {Array.<query_recursive_types.IImageResponse>} scriptImageList
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @instance
         */
        StoryChatNovelScriptResponse.prototype.scriptImageList = $util.emptyArray;

        /**
         * StoryChatNovelScriptResponse scriptAudioList.
         * @member {Array.<query_recursive_types.IAudioResponse>} scriptAudioList
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @instance
         */
        StoryChatNovelScriptResponse.prototype.scriptAudioList = $util.emptyArray;

        /**
         * Creates a new StoryChatNovelScriptResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @static
         * @param {query_recursive_types.IStoryChatNovelScriptResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryChatNovelScriptResponse} StoryChatNovelScriptResponse instance
         */
        StoryChatNovelScriptResponse.create = function create(properties) {
            return new StoryChatNovelScriptResponse(properties);
        };

        /**
         * Encodes the specified StoryChatNovelScriptResponse message. Does not implicitly {@link query_recursive_types.StoryChatNovelScriptResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @static
         * @param {query_recursive_types.IStoryChatNovelScriptResponse} message StoryChatNovelScriptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryChatNovelScriptResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.charLength != null && Object.hasOwnProperty.call(message, "charLength"))
                $root.google.protobuf.Int32Value.encode(message.charLength, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.opLength != null && Object.hasOwnProperty.call(message, "opLength"))
                $root.google.protobuf.Int32Value.encode(message.opLength, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.trialScript != null && Object.hasOwnProperty.call(message, "trialScript"))
                $root.tellerscript.TellerScript.encode(message.trialScript, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.fullScript != null && Object.hasOwnProperty.call(message, "fullScript"))
                $root.tellerscript.TellerScript.encode(message.fullScript, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.scriptImageList != null && message.scriptImageList.length)
                for (var i = 0; i < message.scriptImageList.length; ++i)
                    $root.query_recursive_types.ImageResponse.encode(message.scriptImageList[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.scriptAudioList != null && message.scriptAudioList.length)
                for (var i = 0; i < message.scriptAudioList.length; ++i)
                    $root.query_recursive_types.AudioResponse.encode(message.scriptAudioList[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryChatNovelScriptResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryChatNovelScriptResponse} StoryChatNovelScriptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryChatNovelScriptResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryChatNovelScriptResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.charLength = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.opLength = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.trialScript = $root.tellerscript.TellerScript.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.fullScript = $root.tellerscript.TellerScript.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.scriptImageList && message.scriptImageList.length))
                        message.scriptImageList = [];
                    message.scriptImageList.push($root.query_recursive_types.ImageResponse.decode(reader, reader.uint32()));
                    break;
                case 6:
                    if (!(message.scriptAudioList && message.scriptAudioList.length))
                        message.scriptAudioList = [];
                    message.scriptAudioList.push($root.query_recursive_types.AudioResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryChatNovelScriptResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryChatNovelScriptResponse} StoryChatNovelScriptResponse
         */
        StoryChatNovelScriptResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryChatNovelScriptResponse)
                return object;
            var message = new $root.query_recursive_types.StoryChatNovelScriptResponse();
            if (object.charLength != null) {
                if (typeof object.charLength !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.charLength: object expected");
                message.charLength = $root.google.protobuf.Int32Value.fromObject(object.charLength);
            }
            if (object.opLength != null) {
                if (typeof object.opLength !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.opLength: object expected");
                message.opLength = $root.google.protobuf.Int32Value.fromObject(object.opLength);
            }
            if (object.trialScript != null) {
                if (typeof object.trialScript !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.trialScript: object expected");
                message.trialScript = $root.tellerscript.TellerScript.fromObject(object.trialScript);
            }
            if (object.fullScript != null) {
                if (typeof object.fullScript !== "object")
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.fullScript: object expected");
                message.fullScript = $root.tellerscript.TellerScript.fromObject(object.fullScript);
            }
            if (object.scriptImageList) {
                if (!Array.isArray(object.scriptImageList))
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.scriptImageList: array expected");
                message.scriptImageList = [];
                for (var i = 0; i < object.scriptImageList.length; ++i) {
                    if (typeof object.scriptImageList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.scriptImageList: object expected");
                    message.scriptImageList[i] = $root.query_recursive_types.ImageResponse.fromObject(object.scriptImageList[i]);
                }
            }
            if (object.scriptAudioList) {
                if (!Array.isArray(object.scriptAudioList))
                    throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.scriptAudioList: array expected");
                message.scriptAudioList = [];
                for (var i = 0; i < object.scriptAudioList.length; ++i) {
                    if (typeof object.scriptAudioList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryChatNovelScriptResponse.scriptAudioList: object expected");
                    message.scriptAudioList[i] = $root.query_recursive_types.AudioResponse.fromObject(object.scriptAudioList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryChatNovelScriptResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @static
         * @param {query_recursive_types.StoryChatNovelScriptResponse} message StoryChatNovelScriptResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryChatNovelScriptResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.scriptImageList = [];
                object.scriptAudioList = [];
            }
            if (options.defaults) {
                object.charLength = null;
                object.opLength = null;
                object.trialScript = null;
                object.fullScript = null;
            }
            if (message.charLength != null && message.hasOwnProperty("charLength"))
                object.charLength = $root.google.protobuf.Int32Value.toObject(message.charLength, options);
            if (message.opLength != null && message.hasOwnProperty("opLength"))
                object.opLength = $root.google.protobuf.Int32Value.toObject(message.opLength, options);
            if (message.trialScript != null && message.hasOwnProperty("trialScript"))
                object.trialScript = $root.tellerscript.TellerScript.toObject(message.trialScript, options);
            if (message.fullScript != null && message.hasOwnProperty("fullScript"))
                object.fullScript = $root.tellerscript.TellerScript.toObject(message.fullScript, options);
            if (message.scriptImageList && message.scriptImageList.length) {
                object.scriptImageList = [];
                for (var j = 0; j < message.scriptImageList.length; ++j)
                    object.scriptImageList[j] = $root.query_recursive_types.ImageResponse.toObject(message.scriptImageList[j], options);
            }
            if (message.scriptAudioList && message.scriptAudioList.length) {
                object.scriptAudioList = [];
                for (var j = 0; j < message.scriptAudioList.length; ++j)
                    object.scriptAudioList[j] = $root.query_recursive_types.AudioResponse.toObject(message.scriptAudioList[j], options);
            }
            return object;
        };

        /**
         * Converts this StoryChatNovelScriptResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryChatNovelScriptResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryChatNovelScriptResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryChatNovelScriptResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryChatNovelScriptResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryChatNovelScriptResponse";
        };

        return StoryChatNovelScriptResponse;
    })();

    query_recursive_types.StoryNovelScriptRequireField = (function() {

        /**
         * Properties of a StoryNovelScriptRequireField.
         * @memberof query_recursive_types
         * @interface IStoryNovelScriptRequireField
         * @property {google.protobuf.IBoolValue|null} [requireCharLength] StoryNovelScriptRequireField requireCharLength
         * @property {google.protobuf.IBoolValue|null} [requireTrialScript] StoryNovelScriptRequireField requireTrialScript
         * @property {google.protobuf.IBoolValue|null} [requireFullScript] StoryNovelScriptRequireField requireFullScript
         * @property {google.protobuf.IBoolValue|null} [requireTrialHtmlScript] StoryNovelScriptRequireField requireTrialHtmlScript
         * @property {google.protobuf.IBoolValue|null} [requireFullHtmlScript] StoryNovelScriptRequireField requireFullHtmlScript
         * @property {google.protobuf.IBoolValue|null} [requireTrialHtmlBodyScript] StoryNovelScriptRequireField requireTrialHtmlBodyScript
         * @property {google.protobuf.IBoolValue|null} [requireFullHtmlBodyScript] StoryNovelScriptRequireField requireFullHtmlBodyScript
         */

        /**
         * Constructs a new StoryNovelScriptRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryNovelScriptRequireField.
         * @implements IStoryNovelScriptRequireField
         * @constructor
         * @param {query_recursive_types.IStoryNovelScriptRequireField=} [properties] Properties to set
         */
        function StoryNovelScriptRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryNovelScriptRequireField requireCharLength.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCharLength
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         */
        StoryNovelScriptRequireField.prototype.requireCharLength = null;

        /**
         * StoryNovelScriptRequireField requireTrialScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTrialScript
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         */
        StoryNovelScriptRequireField.prototype.requireTrialScript = null;

        /**
         * StoryNovelScriptRequireField requireFullScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireFullScript
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         */
        StoryNovelScriptRequireField.prototype.requireFullScript = null;

        /**
         * StoryNovelScriptRequireField requireTrialHtmlScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTrialHtmlScript
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         */
        StoryNovelScriptRequireField.prototype.requireTrialHtmlScript = null;

        /**
         * StoryNovelScriptRequireField requireFullHtmlScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireFullHtmlScript
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         */
        StoryNovelScriptRequireField.prototype.requireFullHtmlScript = null;

        /**
         * StoryNovelScriptRequireField requireTrialHtmlBodyScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTrialHtmlBodyScript
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         */
        StoryNovelScriptRequireField.prototype.requireTrialHtmlBodyScript = null;

        /**
         * StoryNovelScriptRequireField requireFullHtmlBodyScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireFullHtmlBodyScript
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         */
        StoryNovelScriptRequireField.prototype.requireFullHtmlBodyScript = null;

        /**
         * Creates a new StoryNovelScriptRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @static
         * @param {query_recursive_types.IStoryNovelScriptRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.StoryNovelScriptRequireField} StoryNovelScriptRequireField instance
         */
        StoryNovelScriptRequireField.create = function create(properties) {
            return new StoryNovelScriptRequireField(properties);
        };

        /**
         * Encodes the specified StoryNovelScriptRequireField message. Does not implicitly {@link query_recursive_types.StoryNovelScriptRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @static
         * @param {query_recursive_types.IStoryNovelScriptRequireField} message StoryNovelScriptRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryNovelScriptRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireCharLength != null && Object.hasOwnProperty.call(message, "requireCharLength"))
                $root.google.protobuf.BoolValue.encode(message.requireCharLength, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTrialScript != null && Object.hasOwnProperty.call(message, "requireTrialScript"))
                $root.google.protobuf.BoolValue.encode(message.requireTrialScript, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireFullScript != null && Object.hasOwnProperty.call(message, "requireFullScript"))
                $root.google.protobuf.BoolValue.encode(message.requireFullScript, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireTrialHtmlScript != null && Object.hasOwnProperty.call(message, "requireTrialHtmlScript"))
                $root.google.protobuf.BoolValue.encode(message.requireTrialHtmlScript, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireFullHtmlScript != null && Object.hasOwnProperty.call(message, "requireFullHtmlScript"))
                $root.google.protobuf.BoolValue.encode(message.requireFullHtmlScript, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireTrialHtmlBodyScript != null && Object.hasOwnProperty.call(message, "requireTrialHtmlBodyScript"))
                $root.google.protobuf.BoolValue.encode(message.requireTrialHtmlBodyScript, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireFullHtmlBodyScript != null && Object.hasOwnProperty.call(message, "requireFullHtmlBodyScript"))
                $root.google.protobuf.BoolValue.encode(message.requireFullHtmlBodyScript, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryNovelScriptRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryNovelScriptRequireField} StoryNovelScriptRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryNovelScriptRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryNovelScriptRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireCharLength = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTrialScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireFullScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireTrialHtmlScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireFullHtmlScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireTrialHtmlBodyScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireFullHtmlBodyScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryNovelScriptRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryNovelScriptRequireField} StoryNovelScriptRequireField
         */
        StoryNovelScriptRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryNovelScriptRequireField)
                return object;
            var message = new $root.query_recursive_types.StoryNovelScriptRequireField();
            if (object.requireCharLength != null) {
                if (typeof object.requireCharLength !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptRequireField.requireCharLength: object expected");
                message.requireCharLength = $root.google.protobuf.BoolValue.fromObject(object.requireCharLength);
            }
            if (object.requireTrialScript != null) {
                if (typeof object.requireTrialScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptRequireField.requireTrialScript: object expected");
                message.requireTrialScript = $root.google.protobuf.BoolValue.fromObject(object.requireTrialScript);
            }
            if (object.requireFullScript != null) {
                if (typeof object.requireFullScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptRequireField.requireFullScript: object expected");
                message.requireFullScript = $root.google.protobuf.BoolValue.fromObject(object.requireFullScript);
            }
            if (object.requireTrialHtmlScript != null) {
                if (typeof object.requireTrialHtmlScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptRequireField.requireTrialHtmlScript: object expected");
                message.requireTrialHtmlScript = $root.google.protobuf.BoolValue.fromObject(object.requireTrialHtmlScript);
            }
            if (object.requireFullHtmlScript != null) {
                if (typeof object.requireFullHtmlScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptRequireField.requireFullHtmlScript: object expected");
                message.requireFullHtmlScript = $root.google.protobuf.BoolValue.fromObject(object.requireFullHtmlScript);
            }
            if (object.requireTrialHtmlBodyScript != null) {
                if (typeof object.requireTrialHtmlBodyScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptRequireField.requireTrialHtmlBodyScript: object expected");
                message.requireTrialHtmlBodyScript = $root.google.protobuf.BoolValue.fromObject(object.requireTrialHtmlBodyScript);
            }
            if (object.requireFullHtmlBodyScript != null) {
                if (typeof object.requireFullHtmlBodyScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptRequireField.requireFullHtmlBodyScript: object expected");
                message.requireFullHtmlBodyScript = $root.google.protobuf.BoolValue.fromObject(object.requireFullHtmlBodyScript);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryNovelScriptRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @static
         * @param {query_recursive_types.StoryNovelScriptRequireField} message StoryNovelScriptRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryNovelScriptRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireCharLength = null;
                object.requireTrialScript = null;
                object.requireFullScript = null;
                object.requireTrialHtmlScript = null;
                object.requireFullHtmlScript = null;
                object.requireTrialHtmlBodyScript = null;
                object.requireFullHtmlBodyScript = null;
            }
            if (message.requireCharLength != null && message.hasOwnProperty("requireCharLength"))
                object.requireCharLength = $root.google.protobuf.BoolValue.toObject(message.requireCharLength, options);
            if (message.requireTrialScript != null && message.hasOwnProperty("requireTrialScript"))
                object.requireTrialScript = $root.google.protobuf.BoolValue.toObject(message.requireTrialScript, options);
            if (message.requireFullScript != null && message.hasOwnProperty("requireFullScript"))
                object.requireFullScript = $root.google.protobuf.BoolValue.toObject(message.requireFullScript, options);
            if (message.requireTrialHtmlScript != null && message.hasOwnProperty("requireTrialHtmlScript"))
                object.requireTrialHtmlScript = $root.google.protobuf.BoolValue.toObject(message.requireTrialHtmlScript, options);
            if (message.requireFullHtmlScript != null && message.hasOwnProperty("requireFullHtmlScript"))
                object.requireFullHtmlScript = $root.google.protobuf.BoolValue.toObject(message.requireFullHtmlScript, options);
            if (message.requireTrialHtmlBodyScript != null && message.hasOwnProperty("requireTrialHtmlBodyScript"))
                object.requireTrialHtmlBodyScript = $root.google.protobuf.BoolValue.toObject(message.requireTrialHtmlBodyScript, options);
            if (message.requireFullHtmlBodyScript != null && message.hasOwnProperty("requireFullHtmlBodyScript"))
                object.requireFullHtmlBodyScript = $root.google.protobuf.BoolValue.toObject(message.requireFullHtmlBodyScript, options);
            return object;
        };

        /**
         * Converts this StoryNovelScriptRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryNovelScriptRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryNovelScriptRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryNovelScriptRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryNovelScriptRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryNovelScriptRequireField";
        };

        return StoryNovelScriptRequireField;
    })();

    query_recursive_types.StoryNovelScriptResponse = (function() {

        /**
         * Properties of a StoryNovelScriptResponse.
         * @memberof query_recursive_types
         * @interface IStoryNovelScriptResponse
         * @property {google.protobuf.IInt32Value|null} [charLength] StoryNovelScriptResponse charLength
         * @property {google.protobuf.IStringValue|null} [trialScript] StoryNovelScriptResponse trialScript
         * @property {google.protobuf.IStringValue|null} [fullScript] StoryNovelScriptResponse fullScript
         * @property {google.protobuf.IStringValue|null} [trialHtmlScript] StoryNovelScriptResponse trialHtmlScript
         * @property {google.protobuf.IStringValue|null} [fullHtmlScript] StoryNovelScriptResponse fullHtmlScript
         * @property {google.protobuf.IStringValue|null} [trialHtmlBodyScript] StoryNovelScriptResponse trialHtmlBodyScript
         * @property {google.protobuf.IStringValue|null} [fullHtmlBodyScript] StoryNovelScriptResponse fullHtmlBodyScript
         */

        /**
         * Constructs a new StoryNovelScriptResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryNovelScriptResponse.
         * @implements IStoryNovelScriptResponse
         * @constructor
         * @param {query_recursive_types.IStoryNovelScriptResponse=} [properties] Properties to set
         */
        function StoryNovelScriptResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryNovelScriptResponse charLength.
         * @member {google.protobuf.IInt32Value|null|undefined} charLength
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         */
        StoryNovelScriptResponse.prototype.charLength = null;

        /**
         * StoryNovelScriptResponse trialScript.
         * @member {google.protobuf.IStringValue|null|undefined} trialScript
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         */
        StoryNovelScriptResponse.prototype.trialScript = null;

        /**
         * StoryNovelScriptResponse fullScript.
         * @member {google.protobuf.IStringValue|null|undefined} fullScript
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         */
        StoryNovelScriptResponse.prototype.fullScript = null;

        /**
         * StoryNovelScriptResponse trialHtmlScript.
         * @member {google.protobuf.IStringValue|null|undefined} trialHtmlScript
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         */
        StoryNovelScriptResponse.prototype.trialHtmlScript = null;

        /**
         * StoryNovelScriptResponse fullHtmlScript.
         * @member {google.protobuf.IStringValue|null|undefined} fullHtmlScript
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         */
        StoryNovelScriptResponse.prototype.fullHtmlScript = null;

        /**
         * StoryNovelScriptResponse trialHtmlBodyScript.
         * @member {google.protobuf.IStringValue|null|undefined} trialHtmlBodyScript
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         */
        StoryNovelScriptResponse.prototype.trialHtmlBodyScript = null;

        /**
         * StoryNovelScriptResponse fullHtmlBodyScript.
         * @member {google.protobuf.IStringValue|null|undefined} fullHtmlBodyScript
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         */
        StoryNovelScriptResponse.prototype.fullHtmlBodyScript = null;

        /**
         * Creates a new StoryNovelScriptResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @static
         * @param {query_recursive_types.IStoryNovelScriptResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryNovelScriptResponse} StoryNovelScriptResponse instance
         */
        StoryNovelScriptResponse.create = function create(properties) {
            return new StoryNovelScriptResponse(properties);
        };

        /**
         * Encodes the specified StoryNovelScriptResponse message. Does not implicitly {@link query_recursive_types.StoryNovelScriptResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @static
         * @param {query_recursive_types.IStoryNovelScriptResponse} message StoryNovelScriptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryNovelScriptResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.charLength != null && Object.hasOwnProperty.call(message, "charLength"))
                $root.google.protobuf.Int32Value.encode(message.charLength, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.trialScript != null && Object.hasOwnProperty.call(message, "trialScript"))
                $root.google.protobuf.StringValue.encode(message.trialScript, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.fullScript != null && Object.hasOwnProperty.call(message, "fullScript"))
                $root.google.protobuf.StringValue.encode(message.fullScript, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.trialHtmlScript != null && Object.hasOwnProperty.call(message, "trialHtmlScript"))
                $root.google.protobuf.StringValue.encode(message.trialHtmlScript, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.fullHtmlScript != null && Object.hasOwnProperty.call(message, "fullHtmlScript"))
                $root.google.protobuf.StringValue.encode(message.fullHtmlScript, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.trialHtmlBodyScript != null && Object.hasOwnProperty.call(message, "trialHtmlBodyScript"))
                $root.google.protobuf.StringValue.encode(message.trialHtmlBodyScript, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.fullHtmlBodyScript != null && Object.hasOwnProperty.call(message, "fullHtmlBodyScript"))
                $root.google.protobuf.StringValue.encode(message.fullHtmlBodyScript, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryNovelScriptResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryNovelScriptResponse} StoryNovelScriptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryNovelScriptResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryNovelScriptResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.charLength = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.trialScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.fullScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.trialHtmlScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.fullHtmlScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.trialHtmlBodyScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.fullHtmlBodyScript = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryNovelScriptResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryNovelScriptResponse} StoryNovelScriptResponse
         */
        StoryNovelScriptResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryNovelScriptResponse)
                return object;
            var message = new $root.query_recursive_types.StoryNovelScriptResponse();
            if (object.charLength != null) {
                if (typeof object.charLength !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptResponse.charLength: object expected");
                message.charLength = $root.google.protobuf.Int32Value.fromObject(object.charLength);
            }
            if (object.trialScript != null) {
                if (typeof object.trialScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptResponse.trialScript: object expected");
                message.trialScript = $root.google.protobuf.StringValue.fromObject(object.trialScript);
            }
            if (object.fullScript != null) {
                if (typeof object.fullScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptResponse.fullScript: object expected");
                message.fullScript = $root.google.protobuf.StringValue.fromObject(object.fullScript);
            }
            if (object.trialHtmlScript != null) {
                if (typeof object.trialHtmlScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptResponse.trialHtmlScript: object expected");
                message.trialHtmlScript = $root.google.protobuf.StringValue.fromObject(object.trialHtmlScript);
            }
            if (object.fullHtmlScript != null) {
                if (typeof object.fullHtmlScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptResponse.fullHtmlScript: object expected");
                message.fullHtmlScript = $root.google.protobuf.StringValue.fromObject(object.fullHtmlScript);
            }
            if (object.trialHtmlBodyScript != null) {
                if (typeof object.trialHtmlBodyScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptResponse.trialHtmlBodyScript: object expected");
                message.trialHtmlBodyScript = $root.google.protobuf.StringValue.fromObject(object.trialHtmlBodyScript);
            }
            if (object.fullHtmlBodyScript != null) {
                if (typeof object.fullHtmlBodyScript !== "object")
                    throw TypeError(".query_recursive_types.StoryNovelScriptResponse.fullHtmlBodyScript: object expected");
                message.fullHtmlBodyScript = $root.google.protobuf.StringValue.fromObject(object.fullHtmlBodyScript);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryNovelScriptResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @static
         * @param {query_recursive_types.StoryNovelScriptResponse} message StoryNovelScriptResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryNovelScriptResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.charLength = null;
                object.trialScript = null;
                object.fullScript = null;
                object.trialHtmlScript = null;
                object.fullHtmlScript = null;
                object.trialHtmlBodyScript = null;
                object.fullHtmlBodyScript = null;
            }
            if (message.charLength != null && message.hasOwnProperty("charLength"))
                object.charLength = $root.google.protobuf.Int32Value.toObject(message.charLength, options);
            if (message.trialScript != null && message.hasOwnProperty("trialScript"))
                object.trialScript = $root.google.protobuf.StringValue.toObject(message.trialScript, options);
            if (message.fullScript != null && message.hasOwnProperty("fullScript"))
                object.fullScript = $root.google.protobuf.StringValue.toObject(message.fullScript, options);
            if (message.trialHtmlScript != null && message.hasOwnProperty("trialHtmlScript"))
                object.trialHtmlScript = $root.google.protobuf.StringValue.toObject(message.trialHtmlScript, options);
            if (message.fullHtmlScript != null && message.hasOwnProperty("fullHtmlScript"))
                object.fullHtmlScript = $root.google.protobuf.StringValue.toObject(message.fullHtmlScript, options);
            if (message.trialHtmlBodyScript != null && message.hasOwnProperty("trialHtmlBodyScript"))
                object.trialHtmlBodyScript = $root.google.protobuf.StringValue.toObject(message.trialHtmlBodyScript, options);
            if (message.fullHtmlBodyScript != null && message.hasOwnProperty("fullHtmlBodyScript"))
                object.fullHtmlBodyScript = $root.google.protobuf.StringValue.toObject(message.fullHtmlBodyScript, options);
            return object;
        };

        /**
         * Converts this StoryNovelScriptResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryNovelScriptResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryNovelScriptResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryNovelScriptResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryNovelScriptResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryNovelScriptResponse";
        };

        return StoryNovelScriptResponse;
    })();

    query_recursive_types.StoryRequireField = (function() {

        /**
         * Properties of a StoryRequireField.
         * @memberof query_recursive_types
         * @interface IStoryRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] StoryRequireField requireId
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] StoryRequireField requireUser
         * @property {google.protobuf.IBoolValue|null} [requireTags] StoryRequireField requireTags
         * @property {query_recursive_types.ISeriesRequireField|null} [requireSeries] StoryRequireField requireSeries
         * @property {google.protobuf.IBoolValue|null} [requireSeriesIndex] StoryRequireField requireSeriesIndex
         * @property {google.protobuf.IBoolValue|null} [requireTitle] StoryRequireField requireTitle
         * @property {google.protobuf.IBoolValue|null} [requireDescription] StoryRequireField requireDescription
         * @property {google.protobuf.IBoolValue|null} [requireOpening] StoryRequireField requireOpening
         * @property {query_recursive_types.IImageRequireField|null} [requireThumbnail] StoryRequireField requireThumbnail
         * @property {google.protobuf.IBoolValue|null} [requireStatus] StoryRequireField requireStatus
         * @property {google.protobuf.IBoolValue|null} [requireIsOfficial] StoryRequireField requireIsOfficial
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] StoryRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] StoryRequireField requireUpdatedAt
         * @property {google.protobuf.IBoolValue|null} [requirePublishedAt] StoryRequireField requirePublishedAt
         * @property {google.protobuf.IBoolValue|null} [requireAvailability] StoryRequireField requireAvailability
         * @property {google.protobuf.IBoolValue|null} [requireLikeCount] StoryRequireField requireLikeCount
         * @property {query_recursive_types.ICommentPermissionRequireField|null} [requireCommentPermission] StoryRequireField requireCommentPermission
         * @property {query_recursive_types.ICommentSectionRequireField|null} [requireCommentSection] StoryRequireField requireCommentSection
         * @property {query_recursive_types.IStoryAdvertisementCursorRequest|null} [requireAdvertisementPage] StoryRequireField requireAdvertisementPage
         * @property {google.protobuf.IBoolValue|null} [requireUserBookmarked] StoryRequireField requireUserBookmarked
         * @property {query_recursive_types.IStoryReadingProgressRequireField|null} [requireUserReadingProgress] StoryRequireField requireUserReadingProgress
         * @property {google.protobuf.IBoolValue|null} [requireUserAvailability] StoryRequireField requireUserAvailability
         * @property {query_recursive_types.IStoryRequireField|null} [requireNextStory] StoryRequireField requireNextStory
         * @property {query_recursive_types.IStoryRequireField|null} [requireNextSibling] StoryRequireField requireNextSibling
         * @property {query_recursive_types.IStoryRequireField|null} [requirePreviousSibling] StoryRequireField requirePreviousSibling
         * @property {query_recursive_types.IStoryReviewCommentRequireField|null} [requireReviewComment] StoryRequireField requireReviewComment
         * @property {google.protobuf.IBoolValue|null} [requireScriptType] StoryRequireField requireScriptType
         * @property {query_recursive_types.IStoryChatNovelScriptRequireField|null} [requireChatNovelScript] StoryRequireField requireChatNovelScript
         * @property {query_recursive_types.IStoryNovelScriptRequireField|null} [requireNovelScript] StoryRequireField requireNovelScript
         * @property {story_video.IStoryVideoRequest|null} [requireVideo] StoryRequireField requireVideo
         * @property {google.protobuf.IBoolValue|null} [requireViewCount] StoryRequireField requireViewCount
         * @property {google.protobuf.IBoolValue|null} [requireCanShowGoogleAd] StoryRequireField requireCanShowGoogleAd
         * @property {google.protobuf.IBoolValue|null} [requireOriginalFlag] StoryRequireField requireOriginalFlag
         * @property {google.protobuf.IBoolValue|null} [requireIsAdminSensitive] StoryRequireField requireIsAdminSensitive
         * @property {google.protobuf.IBoolValue|null} [requireIsUserSensitive] StoryRequireField requireIsUserSensitive
         * @property {story_video.IStoryVideoRequest|null} [requireVideoList] StoryRequireField requireVideoList
         * @property {google.protobuf.IBoolValue|null} [requireLength] StoryRequireField requireLength
         * @property {query_recursive_types.IStoryCursorRequest|null} [requireRelatedStoryPage] StoryRequireField requireRelatedStoryPage
         * @property {google.protobuf.IBoolValue|null} [requireTrialScript] StoryRequireField requireTrialScript
         * @property {google.protobuf.IBoolValue|null} [requireFullScript] StoryRequireField requireFullScript
         * @property {query_recursive_types.IImageRequireField|null} [requireScriptImageList] StoryRequireField requireScriptImageList
         * @property {query_recursive_types.IAudioRequireField|null} [requireScriptAudioList] StoryRequireField requireScriptAudioList
         * @property {query_recursive_types.ISearchStoryRequest|null} [requireSearchRelatedStory] StoryRequireField requireSearchRelatedStory
         * @property {google.protobuf.IBoolValue|null} [requireSensitiveFlag] StoryRequireField requireSensitiveFlag
         */

        /**
         * Constructs a new StoryRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryRequireField.
         * @implements IStoryRequireField
         * @constructor
         * @param {query_recursive_types.IStoryRequireField=} [properties] Properties to set
         */
        function StoryRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireId = null;

        /**
         * StoryRequireField requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireUser = null;

        /**
         * StoryRequireField requireTags.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTags
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireTags = null;

        /**
         * StoryRequireField requireSeries.
         * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeries
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireSeries = null;

        /**
         * StoryRequireField requireSeriesIndex.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSeriesIndex
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireSeriesIndex = null;

        /**
         * StoryRequireField requireTitle.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTitle
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireTitle = null;

        /**
         * StoryRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireDescription = null;

        /**
         * StoryRequireField requireOpening.
         * @member {google.protobuf.IBoolValue|null|undefined} requireOpening
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireOpening = null;

        /**
         * StoryRequireField requireThumbnail.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireThumbnail
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireThumbnail = null;

        /**
         * StoryRequireField requireStatus.
         * @member {google.protobuf.IBoolValue|null|undefined} requireStatus
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireStatus = null;

        /**
         * StoryRequireField requireIsOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsOfficial
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireIsOfficial = null;

        /**
         * StoryRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireCreatedAt = null;

        /**
         * StoryRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireUpdatedAt = null;

        /**
         * StoryRequireField requirePublishedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requirePublishedAt
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requirePublishedAt = null;

        /**
         * StoryRequireField requireAvailability.
         * @member {google.protobuf.IBoolValue|null|undefined} requireAvailability
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireAvailability = null;

        /**
         * StoryRequireField requireLikeCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLikeCount
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireLikeCount = null;

        /**
         * StoryRequireField requireCommentPermission.
         * @member {query_recursive_types.ICommentPermissionRequireField|null|undefined} requireCommentPermission
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireCommentPermission = null;

        /**
         * StoryRequireField requireCommentSection.
         * @member {query_recursive_types.ICommentSectionRequireField|null|undefined} requireCommentSection
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireCommentSection = null;

        /**
         * StoryRequireField requireAdvertisementPage.
         * @member {query_recursive_types.IStoryAdvertisementCursorRequest|null|undefined} requireAdvertisementPage
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireAdvertisementPage = null;

        /**
         * StoryRequireField requireUserBookmarked.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUserBookmarked
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireUserBookmarked = null;

        /**
         * StoryRequireField requireUserReadingProgress.
         * @member {query_recursive_types.IStoryReadingProgressRequireField|null|undefined} requireUserReadingProgress
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireUserReadingProgress = null;

        /**
         * StoryRequireField requireUserAvailability.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUserAvailability
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireUserAvailability = null;

        /**
         * StoryRequireField requireNextStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireNextStory
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireNextStory = null;

        /**
         * StoryRequireField requireNextSibling.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireNextSibling
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireNextSibling = null;

        /**
         * StoryRequireField requirePreviousSibling.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requirePreviousSibling
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requirePreviousSibling = null;

        /**
         * StoryRequireField requireReviewComment.
         * @member {query_recursive_types.IStoryReviewCommentRequireField|null|undefined} requireReviewComment
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireReviewComment = null;

        /**
         * StoryRequireField requireScriptType.
         * @member {google.protobuf.IBoolValue|null|undefined} requireScriptType
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireScriptType = null;

        /**
         * StoryRequireField requireChatNovelScript.
         * @member {query_recursive_types.IStoryChatNovelScriptRequireField|null|undefined} requireChatNovelScript
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireChatNovelScript = null;

        /**
         * StoryRequireField requireNovelScript.
         * @member {query_recursive_types.IStoryNovelScriptRequireField|null|undefined} requireNovelScript
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireNovelScript = null;

        /**
         * StoryRequireField requireVideo.
         * @member {story_video.IStoryVideoRequest|null|undefined} requireVideo
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireVideo = null;

        /**
         * StoryRequireField requireViewCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireViewCount
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireViewCount = null;

        /**
         * StoryRequireField requireCanShowGoogleAd.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCanShowGoogleAd
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireCanShowGoogleAd = null;

        /**
         * StoryRequireField requireOriginalFlag.
         * @member {google.protobuf.IBoolValue|null|undefined} requireOriginalFlag
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireOriginalFlag = null;

        /**
         * StoryRequireField requireIsAdminSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsAdminSensitive
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireIsAdminSensitive = null;

        /**
         * StoryRequireField requireIsUserSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsUserSensitive
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireIsUserSensitive = null;

        /**
         * StoryRequireField requireVideoList.
         * @member {story_video.IStoryVideoRequest|null|undefined} requireVideoList
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireVideoList = null;

        /**
         * StoryRequireField requireLength.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLength
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireLength = null;

        /**
         * StoryRequireField requireRelatedStoryPage.
         * @member {query_recursive_types.IStoryCursorRequest|null|undefined} requireRelatedStoryPage
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireRelatedStoryPage = null;

        /**
         * StoryRequireField requireTrialScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTrialScript
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireTrialScript = null;

        /**
         * StoryRequireField requireFullScript.
         * @member {google.protobuf.IBoolValue|null|undefined} requireFullScript
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireFullScript = null;

        /**
         * StoryRequireField requireScriptImageList.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireScriptImageList
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireScriptImageList = null;

        /**
         * StoryRequireField requireScriptAudioList.
         * @member {query_recursive_types.IAudioRequireField|null|undefined} requireScriptAudioList
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireScriptAudioList = null;

        /**
         * StoryRequireField requireSearchRelatedStory.
         * @member {query_recursive_types.ISearchStoryRequest|null|undefined} requireSearchRelatedStory
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireSearchRelatedStory = null;

        /**
         * StoryRequireField requireSensitiveFlag.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSensitiveFlag
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         */
        StoryRequireField.prototype.requireSensitiveFlag = null;

        /**
         * Creates a new StoryRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryRequireField
         * @static
         * @param {query_recursive_types.IStoryRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.StoryRequireField} StoryRequireField instance
         */
        StoryRequireField.create = function create(properties) {
            return new StoryRequireField(properties);
        };

        /**
         * Encodes the specified StoryRequireField message. Does not implicitly {@link query_recursive_types.StoryRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryRequireField
         * @static
         * @param {query_recursive_types.IStoryRequireField} message StoryRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTags != null && Object.hasOwnProperty.call(message, "requireTags"))
                $root.google.protobuf.BoolValue.encode(message.requireTags, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireSeries != null && Object.hasOwnProperty.call(message, "requireSeries"))
                $root.query_recursive_types.SeriesRequireField.encode(message.requireSeries, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireSeriesIndex != null && Object.hasOwnProperty.call(message, "requireSeriesIndex"))
                $root.google.protobuf.BoolValue.encode(message.requireSeriesIndex, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireTitle != null && Object.hasOwnProperty.call(message, "requireTitle"))
                $root.google.protobuf.BoolValue.encode(message.requireTitle, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireOpening != null && Object.hasOwnProperty.call(message, "requireOpening"))
                $root.google.protobuf.BoolValue.encode(message.requireOpening, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.requireThumbnail != null && Object.hasOwnProperty.call(message, "requireThumbnail"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireThumbnail, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.requireLength != null && Object.hasOwnProperty.call(message, "requireLength"))
                $root.google.protobuf.BoolValue.encode(message.requireLength, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.requireStatus != null && Object.hasOwnProperty.call(message, "requireStatus"))
                $root.google.protobuf.BoolValue.encode(message.requireStatus, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.requireIsOfficial != null && Object.hasOwnProperty.call(message, "requireIsOfficial"))
                $root.google.protobuf.BoolValue.encode(message.requireIsOfficial, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.requirePublishedAt != null && Object.hasOwnProperty.call(message, "requirePublishedAt"))
                $root.google.protobuf.BoolValue.encode(message.requirePublishedAt, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.requireAvailability != null && Object.hasOwnProperty.call(message, "requireAvailability"))
                $root.google.protobuf.BoolValue.encode(message.requireAvailability, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.requireTrialScript != null && Object.hasOwnProperty.call(message, "requireTrialScript"))
                $root.google.protobuf.BoolValue.encode(message.requireTrialScript, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.requireFullScript != null && Object.hasOwnProperty.call(message, "requireFullScript"))
                $root.google.protobuf.BoolValue.encode(message.requireFullScript, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.requireScriptImageList != null && Object.hasOwnProperty.call(message, "requireScriptImageList"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireScriptImageList, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.requireScriptAudioList != null && Object.hasOwnProperty.call(message, "requireScriptAudioList"))
                $root.query_recursive_types.AudioRequireField.encode(message.requireScriptAudioList, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.requireLikeCount != null && Object.hasOwnProperty.call(message, "requireLikeCount"))
                $root.google.protobuf.BoolValue.encode(message.requireLikeCount, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.requireCommentPermission != null && Object.hasOwnProperty.call(message, "requireCommentPermission"))
                $root.query_recursive_types.CommentPermissionRequireField.encode(message.requireCommentPermission, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.requireCommentSection != null && Object.hasOwnProperty.call(message, "requireCommentSection"))
                $root.query_recursive_types.CommentSectionRequireField.encode(message.requireCommentSection, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.requireAdvertisementPage != null && Object.hasOwnProperty.call(message, "requireAdvertisementPage"))
                $root.query_recursive_types.StoryAdvertisementCursorRequest.encode(message.requireAdvertisementPage, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.requireVideoList != null && Object.hasOwnProperty.call(message, "requireVideoList"))
                $root.story_video.StoryVideoRequest.encode(message.requireVideoList, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.requireRelatedStoryPage != null && Object.hasOwnProperty.call(message, "requireRelatedStoryPage"))
                $root.query_recursive_types.StoryCursorRequest.encode(message.requireRelatedStoryPage, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.requireUserBookmarked != null && Object.hasOwnProperty.call(message, "requireUserBookmarked"))
                $root.google.protobuf.BoolValue.encode(message.requireUserBookmarked, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.requireUserReadingProgress != null && Object.hasOwnProperty.call(message, "requireUserReadingProgress"))
                $root.query_recursive_types.StoryReadingProgressRequireField.encode(message.requireUserReadingProgress, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.requireUserAvailability != null && Object.hasOwnProperty.call(message, "requireUserAvailability"))
                $root.google.protobuf.BoolValue.encode(message.requireUserAvailability, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.requireNextStory != null && Object.hasOwnProperty.call(message, "requireNextStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireNextStory, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.requireNextSibling != null && Object.hasOwnProperty.call(message, "requireNextSibling"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireNextSibling, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.requirePreviousSibling != null && Object.hasOwnProperty.call(message, "requirePreviousSibling"))
                $root.query_recursive_types.StoryRequireField.encode(message.requirePreviousSibling, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.requireSearchRelatedStory != null && Object.hasOwnProperty.call(message, "requireSearchRelatedStory"))
                $root.query_recursive_types.SearchStoryRequest.encode(message.requireSearchRelatedStory, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.requireReviewComment != null && Object.hasOwnProperty.call(message, "requireReviewComment"))
                $root.query_recursive_types.StoryReviewCommentRequireField.encode(message.requireReviewComment, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.requireScriptType != null && Object.hasOwnProperty.call(message, "requireScriptType"))
                $root.google.protobuf.BoolValue.encode(message.requireScriptType, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.requireChatNovelScript != null && Object.hasOwnProperty.call(message, "requireChatNovelScript"))
                $root.query_recursive_types.StoryChatNovelScriptRequireField.encode(message.requireChatNovelScript, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.requireNovelScript != null && Object.hasOwnProperty.call(message, "requireNovelScript"))
                $root.query_recursive_types.StoryNovelScriptRequireField.encode(message.requireNovelScript, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
            if (message.requireVideo != null && Object.hasOwnProperty.call(message, "requireVideo"))
                $root.story_video.StoryVideoRequest.encode(message.requireVideo, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
            if (message.requireViewCount != null && Object.hasOwnProperty.call(message, "requireViewCount"))
                $root.google.protobuf.BoolValue.encode(message.requireViewCount, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.requireSensitiveFlag != null && Object.hasOwnProperty.call(message, "requireSensitiveFlag"))
                $root.google.protobuf.BoolValue.encode(message.requireSensitiveFlag, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.requireCanShowGoogleAd != null && Object.hasOwnProperty.call(message, "requireCanShowGoogleAd"))
                $root.google.protobuf.BoolValue.encode(message.requireCanShowGoogleAd, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.requireOriginalFlag != null && Object.hasOwnProperty.call(message, "requireOriginalFlag"))
                $root.google.protobuf.BoolValue.encode(message.requireOriginalFlag, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
            if (message.requireIsAdminSensitive != null && Object.hasOwnProperty.call(message, "requireIsAdminSensitive"))
                $root.google.protobuf.BoolValue.encode(message.requireIsAdminSensitive, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.requireIsUserSensitive != null && Object.hasOwnProperty.call(message, "requireIsUserSensitive"))
                $root.google.protobuf.BoolValue.encode(message.requireIsUserSensitive, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryRequireField} StoryRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTags = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireSeries = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireSeriesIndex = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireTitle = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireOpening = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.requireThumbnail = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.requireStatus = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.requireIsOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.requirePublishedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.requireAvailability = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.requireLikeCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.requireCommentPermission = $root.query_recursive_types.CommentPermissionRequireField.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.requireCommentSection = $root.query_recursive_types.CommentSectionRequireField.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.requireAdvertisementPage = $root.query_recursive_types.StoryAdvertisementCursorRequest.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.requireUserBookmarked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.requireUserReadingProgress = $root.query_recursive_types.StoryReadingProgressRequireField.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.requireUserAvailability = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.requireNextStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.requireNextSibling = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.requirePreviousSibling = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.requireReviewComment = $root.query_recursive_types.StoryReviewCommentRequireField.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.requireScriptType = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 36:
                    message.requireChatNovelScript = $root.query_recursive_types.StoryChatNovelScriptRequireField.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.requireNovelScript = $root.query_recursive_types.StoryNovelScriptRequireField.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.requireVideo = $root.story_video.StoryVideoRequest.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.requireViewCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.requireCanShowGoogleAd = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 42:
                    message.requireOriginalFlag = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 43:
                    message.requireIsAdminSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.requireIsUserSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.requireVideoList = $root.story_video.StoryVideoRequest.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.requireLength = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.requireRelatedStoryPage = $root.query_recursive_types.StoryCursorRequest.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.requireTrialScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.requireFullScript = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.requireScriptImageList = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.requireScriptAudioList = $root.query_recursive_types.AudioRequireField.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.requireSearchRelatedStory = $root.query_recursive_types.SearchStoryRequest.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.requireSensitiveFlag = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryRequireField} StoryRequireField
         */
        StoryRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryRequireField)
                return object;
            var message = new $root.query_recursive_types.StoryRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireTags != null) {
                if (typeof object.requireTags !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireTags: object expected");
                message.requireTags = $root.google.protobuf.BoolValue.fromObject(object.requireTags);
            }
            if (object.requireSeries != null) {
                if (typeof object.requireSeries !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireSeries: object expected");
                message.requireSeries = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeries);
            }
            if (object.requireSeriesIndex != null) {
                if (typeof object.requireSeriesIndex !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireSeriesIndex: object expected");
                message.requireSeriesIndex = $root.google.protobuf.BoolValue.fromObject(object.requireSeriesIndex);
            }
            if (object.requireTitle != null) {
                if (typeof object.requireTitle !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireTitle: object expected");
                message.requireTitle = $root.google.protobuf.BoolValue.fromObject(object.requireTitle);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireOpening != null) {
                if (typeof object.requireOpening !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireOpening: object expected");
                message.requireOpening = $root.google.protobuf.BoolValue.fromObject(object.requireOpening);
            }
            if (object.requireThumbnail != null) {
                if (typeof object.requireThumbnail !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireThumbnail: object expected");
                message.requireThumbnail = $root.query_recursive_types.ImageRequireField.fromObject(object.requireThumbnail);
            }
            if (object.requireStatus != null) {
                if (typeof object.requireStatus !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireStatus: object expected");
                message.requireStatus = $root.google.protobuf.BoolValue.fromObject(object.requireStatus);
            }
            if (object.requireIsOfficial != null) {
                if (typeof object.requireIsOfficial !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireIsOfficial: object expected");
                message.requireIsOfficial = $root.google.protobuf.BoolValue.fromObject(object.requireIsOfficial);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requirePublishedAt != null) {
                if (typeof object.requirePublishedAt !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requirePublishedAt: object expected");
                message.requirePublishedAt = $root.google.protobuf.BoolValue.fromObject(object.requirePublishedAt);
            }
            if (object.requireAvailability != null) {
                if (typeof object.requireAvailability !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireAvailability: object expected");
                message.requireAvailability = $root.google.protobuf.BoolValue.fromObject(object.requireAvailability);
            }
            if (object.requireLikeCount != null) {
                if (typeof object.requireLikeCount !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireLikeCount: object expected");
                message.requireLikeCount = $root.google.protobuf.BoolValue.fromObject(object.requireLikeCount);
            }
            if (object.requireCommentPermission != null) {
                if (typeof object.requireCommentPermission !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireCommentPermission: object expected");
                message.requireCommentPermission = $root.query_recursive_types.CommentPermissionRequireField.fromObject(object.requireCommentPermission);
            }
            if (object.requireCommentSection != null) {
                if (typeof object.requireCommentSection !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireCommentSection: object expected");
                message.requireCommentSection = $root.query_recursive_types.CommentSectionRequireField.fromObject(object.requireCommentSection);
            }
            if (object.requireAdvertisementPage != null) {
                if (typeof object.requireAdvertisementPage !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireAdvertisementPage: object expected");
                message.requireAdvertisementPage = $root.query_recursive_types.StoryAdvertisementCursorRequest.fromObject(object.requireAdvertisementPage);
            }
            if (object.requireUserBookmarked != null) {
                if (typeof object.requireUserBookmarked !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireUserBookmarked: object expected");
                message.requireUserBookmarked = $root.google.protobuf.BoolValue.fromObject(object.requireUserBookmarked);
            }
            if (object.requireUserReadingProgress != null) {
                if (typeof object.requireUserReadingProgress !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireUserReadingProgress: object expected");
                message.requireUserReadingProgress = $root.query_recursive_types.StoryReadingProgressRequireField.fromObject(object.requireUserReadingProgress);
            }
            if (object.requireUserAvailability != null) {
                if (typeof object.requireUserAvailability !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireUserAvailability: object expected");
                message.requireUserAvailability = $root.google.protobuf.BoolValue.fromObject(object.requireUserAvailability);
            }
            if (object.requireNextStory != null) {
                if (typeof object.requireNextStory !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireNextStory: object expected");
                message.requireNextStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireNextStory);
            }
            if (object.requireNextSibling != null) {
                if (typeof object.requireNextSibling !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireNextSibling: object expected");
                message.requireNextSibling = $root.query_recursive_types.StoryRequireField.fromObject(object.requireNextSibling);
            }
            if (object.requirePreviousSibling != null) {
                if (typeof object.requirePreviousSibling !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requirePreviousSibling: object expected");
                message.requirePreviousSibling = $root.query_recursive_types.StoryRequireField.fromObject(object.requirePreviousSibling);
            }
            if (object.requireReviewComment != null) {
                if (typeof object.requireReviewComment !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireReviewComment: object expected");
                message.requireReviewComment = $root.query_recursive_types.StoryReviewCommentRequireField.fromObject(object.requireReviewComment);
            }
            if (object.requireScriptType != null) {
                if (typeof object.requireScriptType !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireScriptType: object expected");
                message.requireScriptType = $root.google.protobuf.BoolValue.fromObject(object.requireScriptType);
            }
            if (object.requireChatNovelScript != null) {
                if (typeof object.requireChatNovelScript !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireChatNovelScript: object expected");
                message.requireChatNovelScript = $root.query_recursive_types.StoryChatNovelScriptRequireField.fromObject(object.requireChatNovelScript);
            }
            if (object.requireNovelScript != null) {
                if (typeof object.requireNovelScript !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireNovelScript: object expected");
                message.requireNovelScript = $root.query_recursive_types.StoryNovelScriptRequireField.fromObject(object.requireNovelScript);
            }
            if (object.requireVideo != null) {
                if (typeof object.requireVideo !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireVideo: object expected");
                message.requireVideo = $root.story_video.StoryVideoRequest.fromObject(object.requireVideo);
            }
            if (object.requireViewCount != null) {
                if (typeof object.requireViewCount !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireViewCount: object expected");
                message.requireViewCount = $root.google.protobuf.BoolValue.fromObject(object.requireViewCount);
            }
            if (object.requireCanShowGoogleAd != null) {
                if (typeof object.requireCanShowGoogleAd !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireCanShowGoogleAd: object expected");
                message.requireCanShowGoogleAd = $root.google.protobuf.BoolValue.fromObject(object.requireCanShowGoogleAd);
            }
            if (object.requireOriginalFlag != null) {
                if (typeof object.requireOriginalFlag !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireOriginalFlag: object expected");
                message.requireOriginalFlag = $root.google.protobuf.BoolValue.fromObject(object.requireOriginalFlag);
            }
            if (object.requireIsAdminSensitive != null) {
                if (typeof object.requireIsAdminSensitive !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireIsAdminSensitive: object expected");
                message.requireIsAdminSensitive = $root.google.protobuf.BoolValue.fromObject(object.requireIsAdminSensitive);
            }
            if (object.requireIsUserSensitive != null) {
                if (typeof object.requireIsUserSensitive !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireIsUserSensitive: object expected");
                message.requireIsUserSensitive = $root.google.protobuf.BoolValue.fromObject(object.requireIsUserSensitive);
            }
            if (object.requireVideoList != null) {
                if (typeof object.requireVideoList !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireVideoList: object expected");
                message.requireVideoList = $root.story_video.StoryVideoRequest.fromObject(object.requireVideoList);
            }
            if (object.requireLength != null) {
                if (typeof object.requireLength !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireLength: object expected");
                message.requireLength = $root.google.protobuf.BoolValue.fromObject(object.requireLength);
            }
            if (object.requireRelatedStoryPage != null) {
                if (typeof object.requireRelatedStoryPage !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireRelatedStoryPage: object expected");
                message.requireRelatedStoryPage = $root.query_recursive_types.StoryCursorRequest.fromObject(object.requireRelatedStoryPage);
            }
            if (object.requireTrialScript != null) {
                if (typeof object.requireTrialScript !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireTrialScript: object expected");
                message.requireTrialScript = $root.google.protobuf.BoolValue.fromObject(object.requireTrialScript);
            }
            if (object.requireFullScript != null) {
                if (typeof object.requireFullScript !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireFullScript: object expected");
                message.requireFullScript = $root.google.protobuf.BoolValue.fromObject(object.requireFullScript);
            }
            if (object.requireScriptImageList != null) {
                if (typeof object.requireScriptImageList !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireScriptImageList: object expected");
                message.requireScriptImageList = $root.query_recursive_types.ImageRequireField.fromObject(object.requireScriptImageList);
            }
            if (object.requireScriptAudioList != null) {
                if (typeof object.requireScriptAudioList !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireScriptAudioList: object expected");
                message.requireScriptAudioList = $root.query_recursive_types.AudioRequireField.fromObject(object.requireScriptAudioList);
            }
            if (object.requireSearchRelatedStory != null) {
                if (typeof object.requireSearchRelatedStory !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireSearchRelatedStory: object expected");
                message.requireSearchRelatedStory = $root.query_recursive_types.SearchStoryRequest.fromObject(object.requireSearchRelatedStory);
            }
            if (object.requireSensitiveFlag != null) {
                if (typeof object.requireSensitiveFlag !== "object")
                    throw TypeError(".query_recursive_types.StoryRequireField.requireSensitiveFlag: object expected");
                message.requireSensitiveFlag = $root.google.protobuf.BoolValue.fromObject(object.requireSensitiveFlag);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryRequireField
         * @static
         * @param {query_recursive_types.StoryRequireField} message StoryRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireUser = null;
                object.requireTags = null;
                object.requireSeries = null;
                object.requireSeriesIndex = null;
                object.requireTitle = null;
                object.requireDescription = null;
                object.requireOpening = null;
                object.requireThumbnail = null;
                object.requireLength = null;
                object.requireStatus = null;
                object.requireIsOfficial = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
                object.requirePublishedAt = null;
                object.requireAvailability = null;
                object.requireTrialScript = null;
                object.requireFullScript = null;
                object.requireScriptImageList = null;
                object.requireScriptAudioList = null;
                object.requireLikeCount = null;
                object.requireCommentPermission = null;
                object.requireCommentSection = null;
                object.requireAdvertisementPage = null;
                object.requireVideoList = null;
                object.requireRelatedStoryPage = null;
                object.requireUserBookmarked = null;
                object.requireUserReadingProgress = null;
                object.requireUserAvailability = null;
                object.requireNextStory = null;
                object.requireNextSibling = null;
                object.requirePreviousSibling = null;
                object.requireSearchRelatedStory = null;
                object.requireReviewComment = null;
                object.requireScriptType = null;
                object.requireChatNovelScript = null;
                object.requireNovelScript = null;
                object.requireVideo = null;
                object.requireViewCount = null;
                object.requireSensitiveFlag = null;
                object.requireCanShowGoogleAd = null;
                object.requireOriginalFlag = null;
                object.requireIsAdminSensitive = null;
                object.requireIsUserSensitive = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireTags != null && message.hasOwnProperty("requireTags"))
                object.requireTags = $root.google.protobuf.BoolValue.toObject(message.requireTags, options);
            if (message.requireSeries != null && message.hasOwnProperty("requireSeries"))
                object.requireSeries = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeries, options);
            if (message.requireSeriesIndex != null && message.hasOwnProperty("requireSeriesIndex"))
                object.requireSeriesIndex = $root.google.protobuf.BoolValue.toObject(message.requireSeriesIndex, options);
            if (message.requireTitle != null && message.hasOwnProperty("requireTitle"))
                object.requireTitle = $root.google.protobuf.BoolValue.toObject(message.requireTitle, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireOpening != null && message.hasOwnProperty("requireOpening"))
                object.requireOpening = $root.google.protobuf.BoolValue.toObject(message.requireOpening, options);
            if (message.requireThumbnail != null && message.hasOwnProperty("requireThumbnail"))
                object.requireThumbnail = $root.query_recursive_types.ImageRequireField.toObject(message.requireThumbnail, options);
            if (message.requireLength != null && message.hasOwnProperty("requireLength"))
                object.requireLength = $root.google.protobuf.BoolValue.toObject(message.requireLength, options);
            if (message.requireStatus != null && message.hasOwnProperty("requireStatus"))
                object.requireStatus = $root.google.protobuf.BoolValue.toObject(message.requireStatus, options);
            if (message.requireIsOfficial != null && message.hasOwnProperty("requireIsOfficial"))
                object.requireIsOfficial = $root.google.protobuf.BoolValue.toObject(message.requireIsOfficial, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            if (message.requirePublishedAt != null && message.hasOwnProperty("requirePublishedAt"))
                object.requirePublishedAt = $root.google.protobuf.BoolValue.toObject(message.requirePublishedAt, options);
            if (message.requireAvailability != null && message.hasOwnProperty("requireAvailability"))
                object.requireAvailability = $root.google.protobuf.BoolValue.toObject(message.requireAvailability, options);
            if (message.requireTrialScript != null && message.hasOwnProperty("requireTrialScript"))
                object.requireTrialScript = $root.google.protobuf.BoolValue.toObject(message.requireTrialScript, options);
            if (message.requireFullScript != null && message.hasOwnProperty("requireFullScript"))
                object.requireFullScript = $root.google.protobuf.BoolValue.toObject(message.requireFullScript, options);
            if (message.requireScriptImageList != null && message.hasOwnProperty("requireScriptImageList"))
                object.requireScriptImageList = $root.query_recursive_types.ImageRequireField.toObject(message.requireScriptImageList, options);
            if (message.requireScriptAudioList != null && message.hasOwnProperty("requireScriptAudioList"))
                object.requireScriptAudioList = $root.query_recursive_types.AudioRequireField.toObject(message.requireScriptAudioList, options);
            if (message.requireLikeCount != null && message.hasOwnProperty("requireLikeCount"))
                object.requireLikeCount = $root.google.protobuf.BoolValue.toObject(message.requireLikeCount, options);
            if (message.requireCommentPermission != null && message.hasOwnProperty("requireCommentPermission"))
                object.requireCommentPermission = $root.query_recursive_types.CommentPermissionRequireField.toObject(message.requireCommentPermission, options);
            if (message.requireCommentSection != null && message.hasOwnProperty("requireCommentSection"))
                object.requireCommentSection = $root.query_recursive_types.CommentSectionRequireField.toObject(message.requireCommentSection, options);
            if (message.requireAdvertisementPage != null && message.hasOwnProperty("requireAdvertisementPage"))
                object.requireAdvertisementPage = $root.query_recursive_types.StoryAdvertisementCursorRequest.toObject(message.requireAdvertisementPage, options);
            if (message.requireVideoList != null && message.hasOwnProperty("requireVideoList"))
                object.requireVideoList = $root.story_video.StoryVideoRequest.toObject(message.requireVideoList, options);
            if (message.requireRelatedStoryPage != null && message.hasOwnProperty("requireRelatedStoryPage"))
                object.requireRelatedStoryPage = $root.query_recursive_types.StoryCursorRequest.toObject(message.requireRelatedStoryPage, options);
            if (message.requireUserBookmarked != null && message.hasOwnProperty("requireUserBookmarked"))
                object.requireUserBookmarked = $root.google.protobuf.BoolValue.toObject(message.requireUserBookmarked, options);
            if (message.requireUserReadingProgress != null && message.hasOwnProperty("requireUserReadingProgress"))
                object.requireUserReadingProgress = $root.query_recursive_types.StoryReadingProgressRequireField.toObject(message.requireUserReadingProgress, options);
            if (message.requireUserAvailability != null && message.hasOwnProperty("requireUserAvailability"))
                object.requireUserAvailability = $root.google.protobuf.BoolValue.toObject(message.requireUserAvailability, options);
            if (message.requireNextStory != null && message.hasOwnProperty("requireNextStory"))
                object.requireNextStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireNextStory, options);
            if (message.requireNextSibling != null && message.hasOwnProperty("requireNextSibling"))
                object.requireNextSibling = $root.query_recursive_types.StoryRequireField.toObject(message.requireNextSibling, options);
            if (message.requirePreviousSibling != null && message.hasOwnProperty("requirePreviousSibling"))
                object.requirePreviousSibling = $root.query_recursive_types.StoryRequireField.toObject(message.requirePreviousSibling, options);
            if (message.requireSearchRelatedStory != null && message.hasOwnProperty("requireSearchRelatedStory"))
                object.requireSearchRelatedStory = $root.query_recursive_types.SearchStoryRequest.toObject(message.requireSearchRelatedStory, options);
            if (message.requireReviewComment != null && message.hasOwnProperty("requireReviewComment"))
                object.requireReviewComment = $root.query_recursive_types.StoryReviewCommentRequireField.toObject(message.requireReviewComment, options);
            if (message.requireScriptType != null && message.hasOwnProperty("requireScriptType"))
                object.requireScriptType = $root.google.protobuf.BoolValue.toObject(message.requireScriptType, options);
            if (message.requireChatNovelScript != null && message.hasOwnProperty("requireChatNovelScript"))
                object.requireChatNovelScript = $root.query_recursive_types.StoryChatNovelScriptRequireField.toObject(message.requireChatNovelScript, options);
            if (message.requireNovelScript != null && message.hasOwnProperty("requireNovelScript"))
                object.requireNovelScript = $root.query_recursive_types.StoryNovelScriptRequireField.toObject(message.requireNovelScript, options);
            if (message.requireVideo != null && message.hasOwnProperty("requireVideo"))
                object.requireVideo = $root.story_video.StoryVideoRequest.toObject(message.requireVideo, options);
            if (message.requireViewCount != null && message.hasOwnProperty("requireViewCount"))
                object.requireViewCount = $root.google.protobuf.BoolValue.toObject(message.requireViewCount, options);
            if (message.requireSensitiveFlag != null && message.hasOwnProperty("requireSensitiveFlag"))
                object.requireSensitiveFlag = $root.google.protobuf.BoolValue.toObject(message.requireSensitiveFlag, options);
            if (message.requireCanShowGoogleAd != null && message.hasOwnProperty("requireCanShowGoogleAd"))
                object.requireCanShowGoogleAd = $root.google.protobuf.BoolValue.toObject(message.requireCanShowGoogleAd, options);
            if (message.requireOriginalFlag != null && message.hasOwnProperty("requireOriginalFlag"))
                object.requireOriginalFlag = $root.google.protobuf.BoolValue.toObject(message.requireOriginalFlag, options);
            if (message.requireIsAdminSensitive != null && message.hasOwnProperty("requireIsAdminSensitive"))
                object.requireIsAdminSensitive = $root.google.protobuf.BoolValue.toObject(message.requireIsAdminSensitive, options);
            if (message.requireIsUserSensitive != null && message.hasOwnProperty("requireIsUserSensitive"))
                object.requireIsUserSensitive = $root.google.protobuf.BoolValue.toObject(message.requireIsUserSensitive, options);
            return object;
        };

        /**
         * Converts this StoryRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryRequireField";
        };

        return StoryRequireField;
    })();

    query_recursive_types.StoryResponse = (function() {

        /**
         * Properties of a StoryResponse.
         * @memberof query_recursive_types
         * @interface IStoryResponse
         * @property {google.protobuf.IStringValue|null} [id] StoryResponse id
         * @property {query_recursive_types.IUserResponse|null} [user] StoryResponse user
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] StoryResponse tags
         * @property {query_recursive_types.ISeriesResponse|null} [series] StoryResponse series
         * @property {google.protobuf.IInt32Value|null} [seriesIndex] StoryResponse seriesIndex
         * @property {google.protobuf.IStringValue|null} [title] StoryResponse title
         * @property {google.protobuf.IStringValue|null} [description] StoryResponse description
         * @property {google.protobuf.IStringValue|null} [opening] StoryResponse opening
         * @property {query_recursive_types.IImageResponse|null} [thumbnail] StoryResponse thumbnail
         * @property {story.StoryStatus|null} [status] StoryResponse status
         * @property {google.protobuf.IBoolValue|null} [isOfficial] StoryResponse isOfficial
         * @property {google.protobuf.ITimestamp|null} [createdAt] StoryResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StoryResponse updatedAt
         * @property {google.protobuf.ITimestamp|null} [publishedAt] StoryResponse publishedAt
         * @property {query_types.StoryAvailability|null} [availability] StoryResponse availability
         * @property {google.protobuf.IInt32Value|null} [likeCount] StoryResponse likeCount
         * @property {query_recursive_types.ICommentPermissionResponse|null} [commentPermission] StoryResponse commentPermission
         * @property {query_recursive_types.ICommentSectionResponse|null} [commentSection] StoryResponse commentSection
         * @property {query_recursive_types.IStoryAdvertisementCursorResponse|null} [advertisementPage] StoryResponse advertisementPage
         * @property {google.protobuf.IBoolValue|null} [userBookmarked] StoryResponse userBookmarked
         * @property {query_recursive_types.IStoryReadingProgressResponse|null} [userReadingProgress] StoryResponse userReadingProgress
         * @property {query_types.StoryAvailability|null} [userAvailability] StoryResponse userAvailability
         * @property {query_recursive_types.IStoryResponse|null} [nextStory] StoryResponse nextStory
         * @property {query_recursive_types.IStoryResponse|null} [nextSibling] StoryResponse nextSibling
         * @property {query_recursive_types.IStoryResponse|null} [previousSibling] StoryResponse previousSibling
         * @property {query_recursive_types.IStoryReviewCommentResponse|null} [reviewComment] StoryResponse reviewComment
         * @property {query_types.StoryScriptType|null} [scriptType] StoryResponse scriptType
         * @property {query_recursive_types.IStoryChatNovelScriptResponse|null} [chatNovelScript] StoryResponse chatNovelScript
         * @property {query_recursive_types.IStoryNovelScriptResponse|null} [novelScript] StoryResponse novelScript
         * @property {story_video.IStoryVideoResponse|null} [video] StoryResponse video
         * @property {google.protobuf.IInt32Value|null} [viewCount] StoryResponse viewCount
         * @property {google.protobuf.IBoolValue|null} [canShowGoogleAd] StoryResponse canShowGoogleAd
         * @property {query_types.OriginalFlag|null} [originalFlag] StoryResponse originalFlag
         * @property {google.protobuf.IBoolValue|null} [isAdminSensitive] StoryResponse isAdminSensitive
         * @property {google.protobuf.IBoolValue|null} [isUserSensitive] StoryResponse isUserSensitive
         * @property {Array.<story_video.IStoryVideo>|null} [videoList] StoryResponse videoList
         * @property {google.protobuf.IInt32Value|null} [length] StoryResponse length
         * @property {query_recursive_types.IStoryCursorResponse|null} [relatedStoryPage] StoryResponse relatedStoryPage
         * @property {tellerscript.ITellerScript|null} [trialScript] StoryResponse trialScript
         * @property {tellerscript.ITellerScript|null} [fullScript] StoryResponse fullScript
         * @property {Array.<query_recursive_types.IImageResponse>|null} [scriptImageList] StoryResponse scriptImageList
         * @property {Array.<query_recursive_types.IAudioResponse>|null} [scriptAudioList] StoryResponse scriptAudioList
         * @property {query_recursive_types.ISearchStoryResponse|null} [searchRelatedStory] StoryResponse searchRelatedStory
         * @property {query_types.SensitiveFlag|null} [sensitiveFlag] StoryResponse sensitiveFlag
         */

        /**
         * Constructs a new StoryResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryResponse.
         * @implements IStoryResponse
         * @constructor
         * @param {query_recursive_types.IStoryResponse=} [properties] Properties to set
         */
        function StoryResponse(properties) {
            this.tags = [];
            this.videoList = [];
            this.scriptImageList = [];
            this.scriptAudioList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.id = null;

        /**
         * StoryResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.user = null;

        /**
         * StoryResponse tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.tags = $util.emptyArray;

        /**
         * StoryResponse series.
         * @member {query_recursive_types.ISeriesResponse|null|undefined} series
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.series = null;

        /**
         * StoryResponse seriesIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} seriesIndex
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.seriesIndex = null;

        /**
         * StoryResponse title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.title = null;

        /**
         * StoryResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.description = null;

        /**
         * StoryResponse opening.
         * @member {google.protobuf.IStringValue|null|undefined} opening
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.opening = null;

        /**
         * StoryResponse thumbnail.
         * @member {query_recursive_types.IImageResponse|null|undefined} thumbnail
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.thumbnail = null;

        /**
         * StoryResponse status.
         * @member {story.StoryStatus} status
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.status = 0;

        /**
         * StoryResponse isOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} isOfficial
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.isOfficial = null;

        /**
         * StoryResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.createdAt = null;

        /**
         * StoryResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.updatedAt = null;

        /**
         * StoryResponse publishedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} publishedAt
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.publishedAt = null;

        /**
         * StoryResponse availability.
         * @member {query_types.StoryAvailability} availability
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.availability = 0;

        /**
         * StoryResponse likeCount.
         * @member {google.protobuf.IInt32Value|null|undefined} likeCount
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.likeCount = null;

        /**
         * StoryResponse commentPermission.
         * @member {query_recursive_types.ICommentPermissionResponse|null|undefined} commentPermission
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.commentPermission = null;

        /**
         * StoryResponse commentSection.
         * @member {query_recursive_types.ICommentSectionResponse|null|undefined} commentSection
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.commentSection = null;

        /**
         * StoryResponse advertisementPage.
         * @member {query_recursive_types.IStoryAdvertisementCursorResponse|null|undefined} advertisementPage
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.advertisementPage = null;

        /**
         * StoryResponse userBookmarked.
         * @member {google.protobuf.IBoolValue|null|undefined} userBookmarked
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.userBookmarked = null;

        /**
         * StoryResponse userReadingProgress.
         * @member {query_recursive_types.IStoryReadingProgressResponse|null|undefined} userReadingProgress
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.userReadingProgress = null;

        /**
         * StoryResponse userAvailability.
         * @member {query_types.StoryAvailability} userAvailability
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.userAvailability = 0;

        /**
         * StoryResponse nextStory.
         * @member {query_recursive_types.IStoryResponse|null|undefined} nextStory
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.nextStory = null;

        /**
         * StoryResponse nextSibling.
         * @member {query_recursive_types.IStoryResponse|null|undefined} nextSibling
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.nextSibling = null;

        /**
         * StoryResponse previousSibling.
         * @member {query_recursive_types.IStoryResponse|null|undefined} previousSibling
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.previousSibling = null;

        /**
         * StoryResponse reviewComment.
         * @member {query_recursive_types.IStoryReviewCommentResponse|null|undefined} reviewComment
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.reviewComment = null;

        /**
         * StoryResponse scriptType.
         * @member {query_types.StoryScriptType} scriptType
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.scriptType = 0;

        /**
         * StoryResponse chatNovelScript.
         * @member {query_recursive_types.IStoryChatNovelScriptResponse|null|undefined} chatNovelScript
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.chatNovelScript = null;

        /**
         * StoryResponse novelScript.
         * @member {query_recursive_types.IStoryNovelScriptResponse|null|undefined} novelScript
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.novelScript = null;

        /**
         * StoryResponse video.
         * @member {story_video.IStoryVideoResponse|null|undefined} video
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.video = null;

        /**
         * StoryResponse viewCount.
         * @member {google.protobuf.IInt32Value|null|undefined} viewCount
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.viewCount = null;

        /**
         * StoryResponse canShowGoogleAd.
         * @member {google.protobuf.IBoolValue|null|undefined} canShowGoogleAd
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.canShowGoogleAd = null;

        /**
         * StoryResponse originalFlag.
         * @member {query_types.OriginalFlag} originalFlag
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.originalFlag = 0;

        /**
         * StoryResponse isAdminSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} isAdminSensitive
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.isAdminSensitive = null;

        /**
         * StoryResponse isUserSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} isUserSensitive
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.isUserSensitive = null;

        /**
         * StoryResponse videoList.
         * @member {Array.<story_video.IStoryVideo>} videoList
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.videoList = $util.emptyArray;

        /**
         * StoryResponse length.
         * @member {google.protobuf.IInt32Value|null|undefined} length
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.length = null;

        /**
         * StoryResponse relatedStoryPage.
         * @member {query_recursive_types.IStoryCursorResponse|null|undefined} relatedStoryPage
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.relatedStoryPage = null;

        /**
         * StoryResponse trialScript.
         * @member {tellerscript.ITellerScript|null|undefined} trialScript
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.trialScript = null;

        /**
         * StoryResponse fullScript.
         * @member {tellerscript.ITellerScript|null|undefined} fullScript
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.fullScript = null;

        /**
         * StoryResponse scriptImageList.
         * @member {Array.<query_recursive_types.IImageResponse>} scriptImageList
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.scriptImageList = $util.emptyArray;

        /**
         * StoryResponse scriptAudioList.
         * @member {Array.<query_recursive_types.IAudioResponse>} scriptAudioList
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.scriptAudioList = $util.emptyArray;

        /**
         * StoryResponse searchRelatedStory.
         * @member {query_recursive_types.ISearchStoryResponse|null|undefined} searchRelatedStory
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.searchRelatedStory = null;

        /**
         * StoryResponse sensitiveFlag.
         * @member {query_types.SensitiveFlag} sensitiveFlag
         * @memberof query_recursive_types.StoryResponse
         * @instance
         */
        StoryResponse.prototype.sensitiveFlag = 0;

        /**
         * Creates a new StoryResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryResponse
         * @static
         * @param {query_recursive_types.IStoryResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryResponse} StoryResponse instance
         */
        StoryResponse.create = function create(properties) {
            return new StoryResponse(properties);
        };

        /**
         * Encodes the specified StoryResponse message. Does not implicitly {@link query_recursive_types.StoryResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryResponse
         * @static
         * @param {query_recursive_types.IStoryResponse} message StoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.query_recursive_types.SeriesResponse.encode(message.series, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.seriesIndex != null && Object.hasOwnProperty.call(message, "seriesIndex"))
                $root.google.protobuf.Int32Value.encode(message.seriesIndex, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.opening != null && Object.hasOwnProperty.call(message, "opening"))
                $root.google.protobuf.StringValue.encode(message.opening, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.query_recursive_types.ImageResponse.encode(message.thumbnail, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                $root.google.protobuf.Int32Value.encode(message.length, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.status);
            if (message.isOfficial != null && Object.hasOwnProperty.call(message, "isOfficial"))
                $root.google.protobuf.BoolValue.encode(message.isOfficial, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.publishedAt != null && Object.hasOwnProperty.call(message, "publishedAt"))
                $root.google.protobuf.Timestamp.encode(message.publishedAt, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.availability != null && Object.hasOwnProperty.call(message, "availability"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.availability);
            if (message.trialScript != null && Object.hasOwnProperty.call(message, "trialScript"))
                $root.tellerscript.TellerScript.encode(message.trialScript, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.fullScript != null && Object.hasOwnProperty.call(message, "fullScript"))
                $root.tellerscript.TellerScript.encode(message.fullScript, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.scriptImageList != null && message.scriptImageList.length)
                for (var i = 0; i < message.scriptImageList.length; ++i)
                    $root.query_recursive_types.ImageResponse.encode(message.scriptImageList[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.scriptAudioList != null && message.scriptAudioList.length)
                for (var i = 0; i < message.scriptAudioList.length; ++i)
                    $root.query_recursive_types.AudioResponse.encode(message.scriptAudioList[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.likeCount != null && Object.hasOwnProperty.call(message, "likeCount"))
                $root.google.protobuf.Int32Value.encode(message.likeCount, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.commentPermission != null && Object.hasOwnProperty.call(message, "commentPermission"))
                $root.query_recursive_types.CommentPermissionResponse.encode(message.commentPermission, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.commentSection != null && Object.hasOwnProperty.call(message, "commentSection"))
                $root.query_recursive_types.CommentSectionResponse.encode(message.commentSection, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.advertisementPage != null && Object.hasOwnProperty.call(message, "advertisementPage"))
                $root.query_recursive_types.StoryAdvertisementCursorResponse.encode(message.advertisementPage, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.videoList != null && message.videoList.length)
                for (var i = 0; i < message.videoList.length; ++i)
                    $root.story_video.StoryVideo.encode(message.videoList[i], writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.relatedStoryPage != null && Object.hasOwnProperty.call(message, "relatedStoryPage"))
                $root.query_recursive_types.StoryCursorResponse.encode(message.relatedStoryPage, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.userBookmarked != null && Object.hasOwnProperty.call(message, "userBookmarked"))
                $root.google.protobuf.BoolValue.encode(message.userBookmarked, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.userReadingProgress != null && Object.hasOwnProperty.call(message, "userReadingProgress"))
                $root.query_recursive_types.StoryReadingProgressResponse.encode(message.userReadingProgress, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.userAvailability != null && Object.hasOwnProperty.call(message, "userAvailability"))
                writer.uint32(/* id 29, wireType 0 =*/232).int32(message.userAvailability);
            if (message.nextStory != null && Object.hasOwnProperty.call(message, "nextStory"))
                $root.query_recursive_types.StoryResponse.encode(message.nextStory, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.nextSibling != null && Object.hasOwnProperty.call(message, "nextSibling"))
                $root.query_recursive_types.StoryResponse.encode(message.nextSibling, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.previousSibling != null && Object.hasOwnProperty.call(message, "previousSibling"))
                $root.query_recursive_types.StoryResponse.encode(message.previousSibling, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.searchRelatedStory != null && Object.hasOwnProperty.call(message, "searchRelatedStory"))
                $root.query_recursive_types.SearchStoryResponse.encode(message.searchRelatedStory, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.reviewComment != null && Object.hasOwnProperty.call(message, "reviewComment"))
                $root.query_recursive_types.StoryReviewCommentResponse.encode(message.reviewComment, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.scriptType != null && Object.hasOwnProperty.call(message, "scriptType"))
                writer.uint32(/* id 35, wireType 0 =*/280).int32(message.scriptType);
            if (message.chatNovelScript != null && Object.hasOwnProperty.call(message, "chatNovelScript"))
                $root.query_recursive_types.StoryChatNovelScriptResponse.encode(message.chatNovelScript, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.novelScript != null && Object.hasOwnProperty.call(message, "novelScript"))
                $root.query_recursive_types.StoryNovelScriptResponse.encode(message.novelScript, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
            if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                $root.story_video.StoryVideoResponse.encode(message.video, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
            if (message.viewCount != null && Object.hasOwnProperty.call(message, "viewCount"))
                $root.google.protobuf.Int32Value.encode(message.viewCount, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.sensitiveFlag != null && Object.hasOwnProperty.call(message, "sensitiveFlag"))
                writer.uint32(/* id 40, wireType 0 =*/320).int32(message.sensitiveFlag);
            if (message.canShowGoogleAd != null && Object.hasOwnProperty.call(message, "canShowGoogleAd"))
                $root.google.protobuf.BoolValue.encode(message.canShowGoogleAd, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.originalFlag != null && Object.hasOwnProperty.call(message, "originalFlag"))
                writer.uint32(/* id 42, wireType 0 =*/336).int32(message.originalFlag);
            if (message.isAdminSensitive != null && Object.hasOwnProperty.call(message, "isAdminSensitive"))
                $root.google.protobuf.BoolValue.encode(message.isAdminSensitive, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.isUserSensitive != null && Object.hasOwnProperty.call(message, "isUserSensitive"))
                $root.google.protobuf.BoolValue.encode(message.isUserSensitive, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryResponse} StoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.series = $root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.seriesIndex = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.opening = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.thumbnail = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.status = reader.int32();
                    break;
                case 12:
                    message.isOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.publishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.availability = reader.int32();
                    break;
                case 21:
                    message.likeCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.commentPermission = $root.query_recursive_types.CommentPermissionResponse.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.commentSection = $root.query_recursive_types.CommentSectionResponse.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.advertisementPage = $root.query_recursive_types.StoryAdvertisementCursorResponse.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.userBookmarked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.userReadingProgress = $root.query_recursive_types.StoryReadingProgressResponse.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.userAvailability = reader.int32();
                    break;
                case 30:
                    message.nextStory = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.nextSibling = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.previousSibling = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.reviewComment = $root.query_recursive_types.StoryReviewCommentResponse.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.scriptType = reader.int32();
                    break;
                case 36:
                    message.chatNovelScript = $root.query_recursive_types.StoryChatNovelScriptResponse.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.novelScript = $root.query_recursive_types.StoryNovelScriptResponse.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.video = $root.story_video.StoryVideoResponse.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.viewCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.canShowGoogleAd = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 42:
                    message.originalFlag = reader.int32();
                    break;
                case 43:
                    message.isAdminSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.isUserSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 25:
                    if (!(message.videoList && message.videoList.length))
                        message.videoList = [];
                    message.videoList.push($root.story_video.StoryVideo.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.length = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.relatedStoryPage = $root.query_recursive_types.StoryCursorResponse.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.trialScript = $root.tellerscript.TellerScript.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.fullScript = $root.tellerscript.TellerScript.decode(reader, reader.uint32());
                    break;
                case 19:
                    if (!(message.scriptImageList && message.scriptImageList.length))
                        message.scriptImageList = [];
                    message.scriptImageList.push($root.query_recursive_types.ImageResponse.decode(reader, reader.uint32()));
                    break;
                case 20:
                    if (!(message.scriptAudioList && message.scriptAudioList.length))
                        message.scriptAudioList = [];
                    message.scriptAudioList.push($root.query_recursive_types.AudioResponse.decode(reader, reader.uint32()));
                    break;
                case 33:
                    message.searchRelatedStory = $root.query_recursive_types.SearchStoryResponse.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.sensitiveFlag = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryResponse} StoryResponse
         */
        StoryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryResponse)
                return object;
            var message = new $root.query_recursive_types.StoryResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".query_recursive_types.StoryResponse.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryResponse.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.series: object expected");
                message.series = $root.query_recursive_types.SeriesResponse.fromObject(object.series);
            }
            if (object.seriesIndex != null) {
                if (typeof object.seriesIndex !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.seriesIndex: object expected");
                message.seriesIndex = $root.google.protobuf.Int32Value.fromObject(object.seriesIndex);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.opening != null) {
                if (typeof object.opening !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.opening: object expected");
                message.opening = $root.google.protobuf.StringValue.fromObject(object.opening);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.thumbnail: object expected");
                message.thumbnail = $root.query_recursive_types.ImageResponse.fromObject(object.thumbnail);
            }
            switch (object.status) {
            case "STORY_STATUS_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "DRAFT":
            case 1:
                message.status = 1;
                break;
            case "RESERVE":
            case 2:
                message.status = 2;
                break;
            case "PUBLISH":
            case 3:
                message.status = 3;
                break;
            case "REJECT":
            case 4:
                message.status = 4;
                break;
            }
            if (object.isOfficial != null) {
                if (typeof object.isOfficial !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.isOfficial: object expected");
                message.isOfficial = $root.google.protobuf.BoolValue.fromObject(object.isOfficial);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.publishedAt != null) {
                if (typeof object.publishedAt !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.publishedAt: object expected");
                message.publishedAt = $root.google.protobuf.Timestamp.fromObject(object.publishedAt);
            }
            switch (object.availability) {
            case "STORY_AVAILABILITY_UNSPECIFIED":
            case 0:
                message.availability = 0;
                break;
            case "STORY_AVAILABILITY_REQUIRE_VIP":
            case 1:
                message.availability = 1;
                break;
            case "STORY_AVAILABILITY_REQUIRE_WAIT":
            case 2:
                message.availability = 2;
                break;
            case "STORY_AVAILABILITY_REQUIRE_NONE":
            case 3:
                message.availability = 3;
                break;
            case "STORY_AVAILABILITY_REQUIRE_FOLLOWING":
            case 4:
                message.availability = 4;
                break;
            case "STORY_AVAILABILITY_REQUIRE_FOLLOWED":
            case 5:
                message.availability = 5;
                break;
            case "STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING":
            case 6:
                message.availability = 6;
                break;
            }
            if (object.likeCount != null) {
                if (typeof object.likeCount !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.likeCount: object expected");
                message.likeCount = $root.google.protobuf.Int32Value.fromObject(object.likeCount);
            }
            if (object.commentPermission != null) {
                if (typeof object.commentPermission !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.commentPermission: object expected");
                message.commentPermission = $root.query_recursive_types.CommentPermissionResponse.fromObject(object.commentPermission);
            }
            if (object.commentSection != null) {
                if (typeof object.commentSection !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.commentSection: object expected");
                message.commentSection = $root.query_recursive_types.CommentSectionResponse.fromObject(object.commentSection);
            }
            if (object.advertisementPage != null) {
                if (typeof object.advertisementPage !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.advertisementPage: object expected");
                message.advertisementPage = $root.query_recursive_types.StoryAdvertisementCursorResponse.fromObject(object.advertisementPage);
            }
            if (object.userBookmarked != null) {
                if (typeof object.userBookmarked !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.userBookmarked: object expected");
                message.userBookmarked = $root.google.protobuf.BoolValue.fromObject(object.userBookmarked);
            }
            if (object.userReadingProgress != null) {
                if (typeof object.userReadingProgress !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.userReadingProgress: object expected");
                message.userReadingProgress = $root.query_recursive_types.StoryReadingProgressResponse.fromObject(object.userReadingProgress);
            }
            switch (object.userAvailability) {
            case "STORY_AVAILABILITY_UNSPECIFIED":
            case 0:
                message.userAvailability = 0;
                break;
            case "STORY_AVAILABILITY_REQUIRE_VIP":
            case 1:
                message.userAvailability = 1;
                break;
            case "STORY_AVAILABILITY_REQUIRE_WAIT":
            case 2:
                message.userAvailability = 2;
                break;
            case "STORY_AVAILABILITY_REQUIRE_NONE":
            case 3:
                message.userAvailability = 3;
                break;
            case "STORY_AVAILABILITY_REQUIRE_FOLLOWING":
            case 4:
                message.userAvailability = 4;
                break;
            case "STORY_AVAILABILITY_REQUIRE_FOLLOWED":
            case 5:
                message.userAvailability = 5;
                break;
            case "STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING":
            case 6:
                message.userAvailability = 6;
                break;
            }
            if (object.nextStory != null) {
                if (typeof object.nextStory !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.nextStory: object expected");
                message.nextStory = $root.query_recursive_types.StoryResponse.fromObject(object.nextStory);
            }
            if (object.nextSibling != null) {
                if (typeof object.nextSibling !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.nextSibling: object expected");
                message.nextSibling = $root.query_recursive_types.StoryResponse.fromObject(object.nextSibling);
            }
            if (object.previousSibling != null) {
                if (typeof object.previousSibling !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.previousSibling: object expected");
                message.previousSibling = $root.query_recursive_types.StoryResponse.fromObject(object.previousSibling);
            }
            if (object.reviewComment != null) {
                if (typeof object.reviewComment !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.reviewComment: object expected");
                message.reviewComment = $root.query_recursive_types.StoryReviewCommentResponse.fromObject(object.reviewComment);
            }
            switch (object.scriptType) {
            case "STORY_SCRIPT_TYPE_UNSPECIFIED":
            case 0:
                message.scriptType = 0;
                break;
            case "STORY_SCRIPT_TYPE_CHAT_NOVEL":
            case 1:
                message.scriptType = 1;
                break;
            case "STORY_SCRIPT_TYPE_NOVEL":
            case 2:
                message.scriptType = 2;
                break;
            case "STORY_SCRIPT_TYPE_VIDEO":
            case 3:
                message.scriptType = 3;
                break;
            case "STORY_SCRIPT_TYPE_AUDIO":
            case 4:
                message.scriptType = 4;
                break;
            }
            if (object.chatNovelScript != null) {
                if (typeof object.chatNovelScript !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.chatNovelScript: object expected");
                message.chatNovelScript = $root.query_recursive_types.StoryChatNovelScriptResponse.fromObject(object.chatNovelScript);
            }
            if (object.novelScript != null) {
                if (typeof object.novelScript !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.novelScript: object expected");
                message.novelScript = $root.query_recursive_types.StoryNovelScriptResponse.fromObject(object.novelScript);
            }
            if (object.video != null) {
                if (typeof object.video !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.video: object expected");
                message.video = $root.story_video.StoryVideoResponse.fromObject(object.video);
            }
            if (object.viewCount != null) {
                if (typeof object.viewCount !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.viewCount: object expected");
                message.viewCount = $root.google.protobuf.Int32Value.fromObject(object.viewCount);
            }
            if (object.canShowGoogleAd != null) {
                if (typeof object.canShowGoogleAd !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.canShowGoogleAd: object expected");
                message.canShowGoogleAd = $root.google.protobuf.BoolValue.fromObject(object.canShowGoogleAd);
            }
            switch (object.originalFlag) {
            case "ORIGINAL_FLAG_UNSPECIFIED":
            case 0:
                message.originalFlag = 0;
                break;
            case "ORIGINAL_FLAG_ORIGINAL":
            case 1:
                message.originalFlag = 1;
                break;
            case "ORIGINAL_FLAG_FANFIC":
            case 2:
                message.originalFlag = 2;
                break;
            case "ORIGINAL_FLAG_OTHER":
            case 3:
                message.originalFlag = 3;
                break;
            }
            if (object.isAdminSensitive != null) {
                if (typeof object.isAdminSensitive !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.isAdminSensitive: object expected");
                message.isAdminSensitive = $root.google.protobuf.BoolValue.fromObject(object.isAdminSensitive);
            }
            if (object.isUserSensitive != null) {
                if (typeof object.isUserSensitive !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.isUserSensitive: object expected");
                message.isUserSensitive = $root.google.protobuf.BoolValue.fromObject(object.isUserSensitive);
            }
            if (object.videoList) {
                if (!Array.isArray(object.videoList))
                    throw TypeError(".query_recursive_types.StoryResponse.videoList: array expected");
                message.videoList = [];
                for (var i = 0; i < object.videoList.length; ++i) {
                    if (typeof object.videoList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryResponse.videoList: object expected");
                    message.videoList[i] = $root.story_video.StoryVideo.fromObject(object.videoList[i]);
                }
            }
            if (object.length != null) {
                if (typeof object.length !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.length: object expected");
                message.length = $root.google.protobuf.Int32Value.fromObject(object.length);
            }
            if (object.relatedStoryPage != null) {
                if (typeof object.relatedStoryPage !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.relatedStoryPage: object expected");
                message.relatedStoryPage = $root.query_recursive_types.StoryCursorResponse.fromObject(object.relatedStoryPage);
            }
            if (object.trialScript != null) {
                if (typeof object.trialScript !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.trialScript: object expected");
                message.trialScript = $root.tellerscript.TellerScript.fromObject(object.trialScript);
            }
            if (object.fullScript != null) {
                if (typeof object.fullScript !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.fullScript: object expected");
                message.fullScript = $root.tellerscript.TellerScript.fromObject(object.fullScript);
            }
            if (object.scriptImageList) {
                if (!Array.isArray(object.scriptImageList))
                    throw TypeError(".query_recursive_types.StoryResponse.scriptImageList: array expected");
                message.scriptImageList = [];
                for (var i = 0; i < object.scriptImageList.length; ++i) {
                    if (typeof object.scriptImageList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryResponse.scriptImageList: object expected");
                    message.scriptImageList[i] = $root.query_recursive_types.ImageResponse.fromObject(object.scriptImageList[i]);
                }
            }
            if (object.scriptAudioList) {
                if (!Array.isArray(object.scriptAudioList))
                    throw TypeError(".query_recursive_types.StoryResponse.scriptAudioList: array expected");
                message.scriptAudioList = [];
                for (var i = 0; i < object.scriptAudioList.length; ++i) {
                    if (typeof object.scriptAudioList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryResponse.scriptAudioList: object expected");
                    message.scriptAudioList[i] = $root.query_recursive_types.AudioResponse.fromObject(object.scriptAudioList[i]);
                }
            }
            if (object.searchRelatedStory != null) {
                if (typeof object.searchRelatedStory !== "object")
                    throw TypeError(".query_recursive_types.StoryResponse.searchRelatedStory: object expected");
                message.searchRelatedStory = $root.query_recursive_types.SearchStoryResponse.fromObject(object.searchRelatedStory);
            }
            switch (object.sensitiveFlag) {
            case "SENSITIVE_FLAG_UNSPECIFIED":
            case 0:
                message.sensitiveFlag = 0;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_USER":
            case 1:
                message.sensitiveFlag = 1;
                break;
            case "SENSITIVE_FLAG_ENABLED_BY_ADMIN":
            case 2:
                message.sensitiveFlag = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryResponse
         * @static
         * @param {query_recursive_types.StoryResponse} message StoryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.tags = [];
                object.scriptImageList = [];
                object.scriptAudioList = [];
                object.videoList = [];
            }
            if (options.defaults) {
                object.id = null;
                object.user = null;
                object.series = null;
                object.seriesIndex = null;
                object.title = null;
                object.description = null;
                object.opening = null;
                object.thumbnail = null;
                object.length = null;
                object.status = options.enums === String ? "STORY_STATUS_UNKNOWN" : 0;
                object.isOfficial = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.publishedAt = null;
                object.availability = options.enums === String ? "STORY_AVAILABILITY_UNSPECIFIED" : 0;
                object.trialScript = null;
                object.fullScript = null;
                object.likeCount = null;
                object.commentPermission = null;
                object.commentSection = null;
                object.advertisementPage = null;
                object.relatedStoryPage = null;
                object.userBookmarked = null;
                object.userReadingProgress = null;
                object.userAvailability = options.enums === String ? "STORY_AVAILABILITY_UNSPECIFIED" : 0;
                object.nextStory = null;
                object.nextSibling = null;
                object.previousSibling = null;
                object.searchRelatedStory = null;
                object.reviewComment = null;
                object.scriptType = options.enums === String ? "STORY_SCRIPT_TYPE_UNSPECIFIED" : 0;
                object.chatNovelScript = null;
                object.novelScript = null;
                object.video = null;
                object.viewCount = null;
                object.sensitiveFlag = options.enums === String ? "SENSITIVE_FLAG_UNSPECIFIED" : 0;
                object.canShowGoogleAd = null;
                object.originalFlag = options.enums === String ? "ORIGINAL_FLAG_UNSPECIFIED" : 0;
                object.isAdminSensitive = null;
                object.isUserSensitive = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.series != null && message.hasOwnProperty("series"))
                object.series = $root.query_recursive_types.SeriesResponse.toObject(message.series, options);
            if (message.seriesIndex != null && message.hasOwnProperty("seriesIndex"))
                object.seriesIndex = $root.google.protobuf.Int32Value.toObject(message.seriesIndex, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.opening != null && message.hasOwnProperty("opening"))
                object.opening = $root.google.protobuf.StringValue.toObject(message.opening, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.query_recursive_types.ImageResponse.toObject(message.thumbnail, options);
            if (message.length != null && message.hasOwnProperty("length"))
                object.length = $root.google.protobuf.Int32Value.toObject(message.length, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.story.StoryStatus[message.status] : message.status;
            if (message.isOfficial != null && message.hasOwnProperty("isOfficial"))
                object.isOfficial = $root.google.protobuf.BoolValue.toObject(message.isOfficial, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.publishedAt != null && message.hasOwnProperty("publishedAt"))
                object.publishedAt = $root.google.protobuf.Timestamp.toObject(message.publishedAt, options);
            if (message.availability != null && message.hasOwnProperty("availability"))
                object.availability = options.enums === String ? $root.query_types.StoryAvailability[message.availability] : message.availability;
            if (message.trialScript != null && message.hasOwnProperty("trialScript"))
                object.trialScript = $root.tellerscript.TellerScript.toObject(message.trialScript, options);
            if (message.fullScript != null && message.hasOwnProperty("fullScript"))
                object.fullScript = $root.tellerscript.TellerScript.toObject(message.fullScript, options);
            if (message.scriptImageList && message.scriptImageList.length) {
                object.scriptImageList = [];
                for (var j = 0; j < message.scriptImageList.length; ++j)
                    object.scriptImageList[j] = $root.query_recursive_types.ImageResponse.toObject(message.scriptImageList[j], options);
            }
            if (message.scriptAudioList && message.scriptAudioList.length) {
                object.scriptAudioList = [];
                for (var j = 0; j < message.scriptAudioList.length; ++j)
                    object.scriptAudioList[j] = $root.query_recursive_types.AudioResponse.toObject(message.scriptAudioList[j], options);
            }
            if (message.likeCount != null && message.hasOwnProperty("likeCount"))
                object.likeCount = $root.google.protobuf.Int32Value.toObject(message.likeCount, options);
            if (message.commentPermission != null && message.hasOwnProperty("commentPermission"))
                object.commentPermission = $root.query_recursive_types.CommentPermissionResponse.toObject(message.commentPermission, options);
            if (message.commentSection != null && message.hasOwnProperty("commentSection"))
                object.commentSection = $root.query_recursive_types.CommentSectionResponse.toObject(message.commentSection, options);
            if (message.advertisementPage != null && message.hasOwnProperty("advertisementPage"))
                object.advertisementPage = $root.query_recursive_types.StoryAdvertisementCursorResponse.toObject(message.advertisementPage, options);
            if (message.videoList && message.videoList.length) {
                object.videoList = [];
                for (var j = 0; j < message.videoList.length; ++j)
                    object.videoList[j] = $root.story_video.StoryVideo.toObject(message.videoList[j], options);
            }
            if (message.relatedStoryPage != null && message.hasOwnProperty("relatedStoryPage"))
                object.relatedStoryPage = $root.query_recursive_types.StoryCursorResponse.toObject(message.relatedStoryPage, options);
            if (message.userBookmarked != null && message.hasOwnProperty("userBookmarked"))
                object.userBookmarked = $root.google.protobuf.BoolValue.toObject(message.userBookmarked, options);
            if (message.userReadingProgress != null && message.hasOwnProperty("userReadingProgress"))
                object.userReadingProgress = $root.query_recursive_types.StoryReadingProgressResponse.toObject(message.userReadingProgress, options);
            if (message.userAvailability != null && message.hasOwnProperty("userAvailability"))
                object.userAvailability = options.enums === String ? $root.query_types.StoryAvailability[message.userAvailability] : message.userAvailability;
            if (message.nextStory != null && message.hasOwnProperty("nextStory"))
                object.nextStory = $root.query_recursive_types.StoryResponse.toObject(message.nextStory, options);
            if (message.nextSibling != null && message.hasOwnProperty("nextSibling"))
                object.nextSibling = $root.query_recursive_types.StoryResponse.toObject(message.nextSibling, options);
            if (message.previousSibling != null && message.hasOwnProperty("previousSibling"))
                object.previousSibling = $root.query_recursive_types.StoryResponse.toObject(message.previousSibling, options);
            if (message.searchRelatedStory != null && message.hasOwnProperty("searchRelatedStory"))
                object.searchRelatedStory = $root.query_recursive_types.SearchStoryResponse.toObject(message.searchRelatedStory, options);
            if (message.reviewComment != null && message.hasOwnProperty("reviewComment"))
                object.reviewComment = $root.query_recursive_types.StoryReviewCommentResponse.toObject(message.reviewComment, options);
            if (message.scriptType != null && message.hasOwnProperty("scriptType"))
                object.scriptType = options.enums === String ? $root.query_types.StoryScriptType[message.scriptType] : message.scriptType;
            if (message.chatNovelScript != null && message.hasOwnProperty("chatNovelScript"))
                object.chatNovelScript = $root.query_recursive_types.StoryChatNovelScriptResponse.toObject(message.chatNovelScript, options);
            if (message.novelScript != null && message.hasOwnProperty("novelScript"))
                object.novelScript = $root.query_recursive_types.StoryNovelScriptResponse.toObject(message.novelScript, options);
            if (message.video != null && message.hasOwnProperty("video"))
                object.video = $root.story_video.StoryVideoResponse.toObject(message.video, options);
            if (message.viewCount != null && message.hasOwnProperty("viewCount"))
                object.viewCount = $root.google.protobuf.Int32Value.toObject(message.viewCount, options);
            if (message.sensitiveFlag != null && message.hasOwnProperty("sensitiveFlag"))
                object.sensitiveFlag = options.enums === String ? $root.query_types.SensitiveFlag[message.sensitiveFlag] : message.sensitiveFlag;
            if (message.canShowGoogleAd != null && message.hasOwnProperty("canShowGoogleAd"))
                object.canShowGoogleAd = $root.google.protobuf.BoolValue.toObject(message.canShowGoogleAd, options);
            if (message.originalFlag != null && message.hasOwnProperty("originalFlag"))
                object.originalFlag = options.enums === String ? $root.query_types.OriginalFlag[message.originalFlag] : message.originalFlag;
            if (message.isAdminSensitive != null && message.hasOwnProperty("isAdminSensitive"))
                object.isAdminSensitive = $root.google.protobuf.BoolValue.toObject(message.isAdminSensitive, options);
            if (message.isUserSensitive != null && message.hasOwnProperty("isUserSensitive"))
                object.isUserSensitive = $root.google.protobuf.BoolValue.toObject(message.isUserSensitive, options);
            return object;
        };

        /**
         * Converts this StoryResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryResponse";
        };

        return StoryResponse;
    })();

    query_recursive_types.CharacterRequireField = (function() {

        /**
         * Properties of a CharacterRequireField.
         * @memberof query_recursive_types
         * @interface ICharacterRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] CharacterRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireName] CharacterRequireField requireName
         * @property {query_recursive_types.IImageRequireField|null} [requireIcon] CharacterRequireField requireIcon
         */

        /**
         * Constructs a new CharacterRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a CharacterRequireField.
         * @implements ICharacterRequireField
         * @constructor
         * @param {query_recursive_types.ICharacterRequireField=} [properties] Properties to set
         */
        function CharacterRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CharacterRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.CharacterRequireField
         * @instance
         */
        CharacterRequireField.prototype.requireId = null;

        /**
         * CharacterRequireField requireName.
         * @member {google.protobuf.IBoolValue|null|undefined} requireName
         * @memberof query_recursive_types.CharacterRequireField
         * @instance
         */
        CharacterRequireField.prototype.requireName = null;

        /**
         * CharacterRequireField requireIcon.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireIcon
         * @memberof query_recursive_types.CharacterRequireField
         * @instance
         */
        CharacterRequireField.prototype.requireIcon = null;

        /**
         * Creates a new CharacterRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CharacterRequireField
         * @static
         * @param {query_recursive_types.ICharacterRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.CharacterRequireField} CharacterRequireField instance
         */
        CharacterRequireField.create = function create(properties) {
            return new CharacterRequireField(properties);
        };

        /**
         * Encodes the specified CharacterRequireField message. Does not implicitly {@link query_recursive_types.CharacterRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CharacterRequireField
         * @static
         * @param {query_recursive_types.ICharacterRequireField} message CharacterRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CharacterRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireName != null && Object.hasOwnProperty.call(message, "requireName"))
                $root.google.protobuf.BoolValue.encode(message.requireName, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireIcon != null && Object.hasOwnProperty.call(message, "requireIcon"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireIcon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CharacterRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CharacterRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CharacterRequireField} CharacterRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CharacterRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CharacterRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireName = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireIcon = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CharacterRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CharacterRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CharacterRequireField} CharacterRequireField
         */
        CharacterRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CharacterRequireField)
                return object;
            var message = new $root.query_recursive_types.CharacterRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.CharacterRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireName != null) {
                if (typeof object.requireName !== "object")
                    throw TypeError(".query_recursive_types.CharacterRequireField.requireName: object expected");
                message.requireName = $root.google.protobuf.BoolValue.fromObject(object.requireName);
            }
            if (object.requireIcon != null) {
                if (typeof object.requireIcon !== "object")
                    throw TypeError(".query_recursive_types.CharacterRequireField.requireIcon: object expected");
                message.requireIcon = $root.query_recursive_types.ImageRequireField.fromObject(object.requireIcon);
            }
            return message;
        };

        /**
         * Creates a plain object from a CharacterRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CharacterRequireField
         * @static
         * @param {query_recursive_types.CharacterRequireField} message CharacterRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CharacterRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireName = null;
                object.requireIcon = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireName != null && message.hasOwnProperty("requireName"))
                object.requireName = $root.google.protobuf.BoolValue.toObject(message.requireName, options);
            if (message.requireIcon != null && message.hasOwnProperty("requireIcon"))
                object.requireIcon = $root.query_recursive_types.ImageRequireField.toObject(message.requireIcon, options);
            return object;
        };

        /**
         * Converts this CharacterRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CharacterRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CharacterRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CharacterRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.CharacterRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CharacterRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CharacterRequireField";
        };

        return CharacterRequireField;
    })();

    query_recursive_types.CharacterResponse = (function() {

        /**
         * Properties of a CharacterResponse.
         * @memberof query_recursive_types
         * @interface ICharacterResponse
         * @property {google.protobuf.IStringValue|null} [id] CharacterResponse id
         * @property {google.protobuf.IStringValue|null} [name] CharacterResponse name
         * @property {query_recursive_types.IImageResponse|null} [icon] CharacterResponse icon
         */

        /**
         * Constructs a new CharacterResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a CharacterResponse.
         * @implements ICharacterResponse
         * @constructor
         * @param {query_recursive_types.ICharacterResponse=} [properties] Properties to set
         */
        function CharacterResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CharacterResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.CharacterResponse
         * @instance
         */
        CharacterResponse.prototype.id = null;

        /**
         * CharacterResponse name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof query_recursive_types.CharacterResponse
         * @instance
         */
        CharacterResponse.prototype.name = null;

        /**
         * CharacterResponse icon.
         * @member {query_recursive_types.IImageResponse|null|undefined} icon
         * @memberof query_recursive_types.CharacterResponse
         * @instance
         */
        CharacterResponse.prototype.icon = null;

        /**
         * Creates a new CharacterResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CharacterResponse
         * @static
         * @param {query_recursive_types.ICharacterResponse=} [properties] Properties to set
         * @returns {query_recursive_types.CharacterResponse} CharacterResponse instance
         */
        CharacterResponse.create = function create(properties) {
            return new CharacterResponse(properties);
        };

        /**
         * Encodes the specified CharacterResponse message. Does not implicitly {@link query_recursive_types.CharacterResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CharacterResponse
         * @static
         * @param {query_recursive_types.ICharacterResponse} message CharacterResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CharacterResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                $root.query_recursive_types.ImageResponse.encode(message.icon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CharacterResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CharacterResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CharacterResponse} CharacterResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CharacterResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CharacterResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.icon = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CharacterResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CharacterResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CharacterResponse} CharacterResponse
         */
        CharacterResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CharacterResponse)
                return object;
            var message = new $root.query_recursive_types.CharacterResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.CharacterResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".query_recursive_types.CharacterResponse.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.icon != null) {
                if (typeof object.icon !== "object")
                    throw TypeError(".query_recursive_types.CharacterResponse.icon: object expected");
                message.icon = $root.query_recursive_types.ImageResponse.fromObject(object.icon);
            }
            return message;
        };

        /**
         * Creates a plain object from a CharacterResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CharacterResponse
         * @static
         * @param {query_recursive_types.CharacterResponse} message CharacterResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CharacterResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.name = null;
                object.icon = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.icon != null && message.hasOwnProperty("icon"))
                object.icon = $root.query_recursive_types.ImageResponse.toObject(message.icon, options);
            return object;
        };

        /**
         * Converts this CharacterResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CharacterResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CharacterResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CharacterResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.CharacterResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CharacterResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CharacterResponse";
        };

        return CharacterResponse;
    })();

    query_recursive_types.SearchStoryRequest = (function() {

        /**
         * Properties of a SearchStoryRequest.
         * @memberof query_recursive_types
         * @interface ISearchStoryRequest
         * @property {query_search_types.ISearchStoryParams|null} [params] SearchStoryRequest params
         * @property {query_recursive_types.IStoryPageRequest|null} [requireStoryPage] SearchStoryRequest requireStoryPage
         * @property {query_search_types.ISearchStoryFacetRequest|null} [requireFacetList] SearchStoryRequest requireFacetList
         */

        /**
         * Constructs a new SearchStoryRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchStoryRequest.
         * @implements ISearchStoryRequest
         * @constructor
         * @param {query_recursive_types.ISearchStoryRequest=} [properties] Properties to set
         */
        function SearchStoryRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryRequest params.
         * @member {query_search_types.ISearchStoryParams|null|undefined} params
         * @memberof query_recursive_types.SearchStoryRequest
         * @instance
         */
        SearchStoryRequest.prototype.params = null;

        /**
         * SearchStoryRequest requireStoryPage.
         * @member {query_recursive_types.IStoryPageRequest|null|undefined} requireStoryPage
         * @memberof query_recursive_types.SearchStoryRequest
         * @instance
         */
        SearchStoryRequest.prototype.requireStoryPage = null;

        /**
         * SearchStoryRequest requireFacetList.
         * @member {query_search_types.ISearchStoryFacetRequest|null|undefined} requireFacetList
         * @memberof query_recursive_types.SearchStoryRequest
         * @instance
         */
        SearchStoryRequest.prototype.requireFacetList = null;

        /**
         * Creates a new SearchStoryRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchStoryRequest
         * @static
         * @param {query_recursive_types.ISearchStoryRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SearchStoryRequest} SearchStoryRequest instance
         */
        SearchStoryRequest.create = function create(properties) {
            return new SearchStoryRequest(properties);
        };

        /**
         * Encodes the specified SearchStoryRequest message. Does not implicitly {@link query_recursive_types.SearchStoryRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchStoryRequest
         * @static
         * @param {query_recursive_types.ISearchStoryRequest} message SearchStoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                $root.query_search_types.SearchStoryParams.encode(message.params, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireStoryPage != null && Object.hasOwnProperty.call(message, "requireStoryPage"))
                $root.query_recursive_types.StoryPageRequest.encode(message.requireStoryPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireFacetList != null && Object.hasOwnProperty.call(message, "requireFacetList"))
                $root.query_search_types.SearchStoryFacetRequest.encode(message.requireFacetList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchStoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchStoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchStoryRequest} SearchStoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchStoryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = $root.query_search_types.SearchStoryParams.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireStoryPage = $root.query_recursive_types.StoryPageRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireFacetList = $root.query_search_types.SearchStoryFacetRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchStoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchStoryRequest} SearchStoryRequest
         */
        SearchStoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchStoryRequest)
                return object;
            var message = new $root.query_recursive_types.SearchStoryRequest();
            if (object.params != null) {
                if (typeof object.params !== "object")
                    throw TypeError(".query_recursive_types.SearchStoryRequest.params: object expected");
                message.params = $root.query_search_types.SearchStoryParams.fromObject(object.params);
            }
            if (object.requireStoryPage != null) {
                if (typeof object.requireStoryPage !== "object")
                    throw TypeError(".query_recursive_types.SearchStoryRequest.requireStoryPage: object expected");
                message.requireStoryPage = $root.query_recursive_types.StoryPageRequest.fromObject(object.requireStoryPage);
            }
            if (object.requireFacetList != null) {
                if (typeof object.requireFacetList !== "object")
                    throw TypeError(".query_recursive_types.SearchStoryRequest.requireFacetList: object expected");
                message.requireFacetList = $root.query_search_types.SearchStoryFacetRequest.fromObject(object.requireFacetList);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchStoryRequest
         * @static
         * @param {query_recursive_types.SearchStoryRequest} message SearchStoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.params = null;
                object.requireStoryPage = null;
                object.requireFacetList = null;
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = $root.query_search_types.SearchStoryParams.toObject(message.params, options);
            if (message.requireStoryPage != null && message.hasOwnProperty("requireStoryPage"))
                object.requireStoryPage = $root.query_recursive_types.StoryPageRequest.toObject(message.requireStoryPage, options);
            if (message.requireFacetList != null && message.hasOwnProperty("requireFacetList"))
                object.requireFacetList = $root.query_search_types.SearchStoryFacetRequest.toObject(message.requireFacetList, options);
            return object;
        };

        /**
         * Converts this SearchStoryRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchStoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchStoryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchStoryRequest";
        };

        return SearchStoryRequest;
    })();

    query_recursive_types.SearchStoryResponse = (function() {

        /**
         * Properties of a SearchStoryResponse.
         * @memberof query_recursive_types
         * @interface ISearchStoryResponse
         * @property {query_recursive_types.IStoryPageResponse|null} [storyPage] SearchStoryResponse storyPage
         * @property {Array.<query_search_types.ISearchStoryFacetResponse>|null} [facetList] SearchStoryResponse facetList
         */

        /**
         * Constructs a new SearchStoryResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchStoryResponse.
         * @implements ISearchStoryResponse
         * @constructor
         * @param {query_recursive_types.ISearchStoryResponse=} [properties] Properties to set
         */
        function SearchStoryResponse(properties) {
            this.facetList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryResponse storyPage.
         * @member {query_recursive_types.IStoryPageResponse|null|undefined} storyPage
         * @memberof query_recursive_types.SearchStoryResponse
         * @instance
         */
        SearchStoryResponse.prototype.storyPage = null;

        /**
         * SearchStoryResponse facetList.
         * @member {Array.<query_search_types.ISearchStoryFacetResponse>} facetList
         * @memberof query_recursive_types.SearchStoryResponse
         * @instance
         */
        SearchStoryResponse.prototype.facetList = $util.emptyArray;

        /**
         * Creates a new SearchStoryResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchStoryResponse
         * @static
         * @param {query_recursive_types.ISearchStoryResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SearchStoryResponse} SearchStoryResponse instance
         */
        SearchStoryResponse.create = function create(properties) {
            return new SearchStoryResponse(properties);
        };

        /**
         * Encodes the specified SearchStoryResponse message. Does not implicitly {@link query_recursive_types.SearchStoryResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchStoryResponse
         * @static
         * @param {query_recursive_types.ISearchStoryResponse} message SearchStoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyPage != null && Object.hasOwnProperty.call(message, "storyPage"))
                $root.query_recursive_types.StoryPageResponse.encode(message.storyPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.facetList != null && message.facetList.length)
                for (var i = 0; i < message.facetList.length; ++i)
                    $root.query_search_types.SearchStoryFacetResponse.encode(message.facetList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchStoryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchStoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchStoryResponse} SearchStoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchStoryResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyPage = $root.query_recursive_types.StoryPageResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.facetList && message.facetList.length))
                        message.facetList = [];
                    message.facetList.push($root.query_search_types.SearchStoryFacetResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchStoryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchStoryResponse} SearchStoryResponse
         */
        SearchStoryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchStoryResponse)
                return object;
            var message = new $root.query_recursive_types.SearchStoryResponse();
            if (object.storyPage != null) {
                if (typeof object.storyPage !== "object")
                    throw TypeError(".query_recursive_types.SearchStoryResponse.storyPage: object expected");
                message.storyPage = $root.query_recursive_types.StoryPageResponse.fromObject(object.storyPage);
            }
            if (object.facetList) {
                if (!Array.isArray(object.facetList))
                    throw TypeError(".query_recursive_types.SearchStoryResponse.facetList: array expected");
                message.facetList = [];
                for (var i = 0; i < object.facetList.length; ++i) {
                    if (typeof object.facetList[i] !== "object")
                        throw TypeError(".query_recursive_types.SearchStoryResponse.facetList: object expected");
                    message.facetList[i] = $root.query_search_types.SearchStoryFacetResponse.fromObject(object.facetList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchStoryResponse
         * @static
         * @param {query_recursive_types.SearchStoryResponse} message SearchStoryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.facetList = [];
            if (options.defaults)
                object.storyPage = null;
            if (message.storyPage != null && message.hasOwnProperty("storyPage"))
                object.storyPage = $root.query_recursive_types.StoryPageResponse.toObject(message.storyPage, options);
            if (message.facetList && message.facetList.length) {
                object.facetList = [];
                for (var j = 0; j < message.facetList.length; ++j)
                    object.facetList[j] = $root.query_search_types.SearchStoryFacetResponse.toObject(message.facetList[j], options);
            }
            return object;
        };

        /**
         * Converts this SearchStoryResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchStoryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchStoryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchStoryResponse";
        };

        return SearchStoryResponse;
    })();

    query_recursive_types.StoryPageRequest = (function() {

        /**
         * Properties of a StoryPageRequest.
         * @memberof query_recursive_types
         * @interface IStoryPageRequest
         * @property {query_recursive_types.IStoryRequireField|null} [requireStoryList] StoryPageRequest requireStoryList
         * @property {query_pagination.IPageRequest|null} [page] StoryPageRequest page
         */

        /**
         * Constructs a new StoryPageRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryPageRequest.
         * @implements IStoryPageRequest
         * @constructor
         * @param {query_recursive_types.IStoryPageRequest=} [properties] Properties to set
         */
        function StoryPageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryPageRequest requireStoryList.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStoryList
         * @memberof query_recursive_types.StoryPageRequest
         * @instance
         */
        StoryPageRequest.prototype.requireStoryList = null;

        /**
         * StoryPageRequest page.
         * @member {query_pagination.IPageRequest|null|undefined} page
         * @memberof query_recursive_types.StoryPageRequest
         * @instance
         */
        StoryPageRequest.prototype.page = null;

        /**
         * Creates a new StoryPageRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryPageRequest
         * @static
         * @param {query_recursive_types.IStoryPageRequest=} [properties] Properties to set
         * @returns {query_recursive_types.StoryPageRequest} StoryPageRequest instance
         */
        StoryPageRequest.create = function create(properties) {
            return new StoryPageRequest(properties);
        };

        /**
         * Encodes the specified StoryPageRequest message. Does not implicitly {@link query_recursive_types.StoryPageRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryPageRequest
         * @static
         * @param {query_recursive_types.IStoryPageRequest} message StoryPageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryPageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireStoryList != null && Object.hasOwnProperty.call(message, "requireStoryList"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStoryList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageRequest.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryPageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryPageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryPageRequest} StoryPageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryPageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryPageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireStoryList = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.page = $root.query_pagination.PageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryPageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryPageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryPageRequest} StoryPageRequest
         */
        StoryPageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryPageRequest)
                return object;
            var message = new $root.query_recursive_types.StoryPageRequest();
            if (object.requireStoryList != null) {
                if (typeof object.requireStoryList !== "object")
                    throw TypeError(".query_recursive_types.StoryPageRequest.requireStoryList: object expected");
                message.requireStoryList = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStoryList);
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.StoryPageRequest.page: object expected");
                message.page = $root.query_pagination.PageRequest.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryPageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryPageRequest
         * @static
         * @param {query_recursive_types.StoryPageRequest} message StoryPageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryPageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireStoryList = null;
                object.page = null;
            }
            if (message.requireStoryList != null && message.hasOwnProperty("requireStoryList"))
                object.requireStoryList = $root.query_recursive_types.StoryRequireField.toObject(message.requireStoryList, options);
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageRequest.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this StoryPageRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryPageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryPageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryPageRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryPageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryPageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryPageRequest";
        };

        return StoryPageRequest;
    })();

    query_recursive_types.StoryPageResponse = (function() {

        /**
         * Properties of a StoryPageResponse.
         * @memberof query_recursive_types
         * @interface IStoryPageResponse
         * @property {Array.<query_recursive_types.IStoryResponse>|null} [storyList] StoryPageResponse storyList
         * @property {query_pagination.IPageInfo|null} [page] StoryPageResponse page
         */

        /**
         * Constructs a new StoryPageResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryPageResponse.
         * @implements IStoryPageResponse
         * @constructor
         * @param {query_recursive_types.IStoryPageResponse=} [properties] Properties to set
         */
        function StoryPageResponse(properties) {
            this.storyList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryPageResponse storyList.
         * @member {Array.<query_recursive_types.IStoryResponse>} storyList
         * @memberof query_recursive_types.StoryPageResponse
         * @instance
         */
        StoryPageResponse.prototype.storyList = $util.emptyArray;

        /**
         * StoryPageResponse page.
         * @member {query_pagination.IPageInfo|null|undefined} page
         * @memberof query_recursive_types.StoryPageResponse
         * @instance
         */
        StoryPageResponse.prototype.page = null;

        /**
         * Creates a new StoryPageResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryPageResponse
         * @static
         * @param {query_recursive_types.IStoryPageResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryPageResponse} StoryPageResponse instance
         */
        StoryPageResponse.create = function create(properties) {
            return new StoryPageResponse(properties);
        };

        /**
         * Encodes the specified StoryPageResponse message. Does not implicitly {@link query_recursive_types.StoryPageResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryPageResponse
         * @static
         * @param {query_recursive_types.IStoryPageResponse} message StoryPageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyList != null && message.storyList.length)
                for (var i = 0; i < message.storyList.length; ++i)
                    $root.query_recursive_types.StoryResponse.encode(message.storyList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageInfo.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryPageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryPageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryPageResponse} StoryPageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyList && message.storyList.length))
                        message.storyList = [];
                    message.storyList.push($root.query_recursive_types.StoryResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = $root.query_pagination.PageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryPageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryPageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryPageResponse} StoryPageResponse
         */
        StoryPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryPageResponse)
                return object;
            var message = new $root.query_recursive_types.StoryPageResponse();
            if (object.storyList) {
                if (!Array.isArray(object.storyList))
                    throw TypeError(".query_recursive_types.StoryPageResponse.storyList: array expected");
                message.storyList = [];
                for (var i = 0; i < object.storyList.length; ++i) {
                    if (typeof object.storyList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryPageResponse.storyList: object expected");
                    message.storyList[i] = $root.query_recursive_types.StoryResponse.fromObject(object.storyList[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.StoryPageResponse.page: object expected");
                message.page = $root.query_pagination.PageInfo.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryPageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryPageResponse
         * @static
         * @param {query_recursive_types.StoryPageResponse} message StoryPageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyList = [];
            if (options.defaults)
                object.page = null;
            if (message.storyList && message.storyList.length) {
                object.storyList = [];
                for (var j = 0; j < message.storyList.length; ++j)
                    object.storyList[j] = $root.query_recursive_types.StoryResponse.toObject(message.storyList[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageInfo.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this StoryPageResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryPageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryPageResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryPageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryPageResponse";
        };

        return StoryPageResponse;
    })();

    /**
     * SeriesStorySortBy enum.
     * @name query_recursive_types.SeriesStorySortBy
     * @enum {number}
     * @property {number} SERIES_STORY_SORT_BY_UNSPECIFIED=0 SERIES_STORY_SORT_BY_UNSPECIFIED value
     * @property {number} SERIES_STORY_SORT_BY_SERIES_INDEX_ASC=1 SERIES_STORY_SORT_BY_SERIES_INDEX_ASC value
     * @property {number} SERIES_STORY_SORT_BY_SERIES_INDEX_DESC=2 SERIES_STORY_SORT_BY_SERIES_INDEX_DESC value
     */
    query_recursive_types.SeriesStorySortBy = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERIES_STORY_SORT_BY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SERIES_STORY_SORT_BY_SERIES_INDEX_ASC"] = 1;
        values[valuesById[2] = "SERIES_STORY_SORT_BY_SERIES_INDEX_DESC"] = 2;
        return values;
    })();

    query_recursive_types.SeriesStoryPageRequest = (function() {

        /**
         * Properties of a SeriesStoryPageRequest.
         * @memberof query_recursive_types
         * @interface ISeriesStoryPageRequest
         * @property {query_recursive_types.IStoryRequireField|null} [requireStoryList] SeriesStoryPageRequest requireStoryList
         * @property {query_pagination.IPageRequest|null} [page] SeriesStoryPageRequest page
         * @property {query_recursive_types.SeriesStorySortBy|null} [sortBy] SeriesStoryPageRequest sortBy
         * @property {story.StoryStatus|null} [filterStatus] SeriesStoryPageRequest filterStatus
         */

        /**
         * Constructs a new SeriesStoryPageRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesStoryPageRequest.
         * @implements ISeriesStoryPageRequest
         * @constructor
         * @param {query_recursive_types.ISeriesStoryPageRequest=} [properties] Properties to set
         */
        function SeriesStoryPageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesStoryPageRequest requireStoryList.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStoryList
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @instance
         */
        SeriesStoryPageRequest.prototype.requireStoryList = null;

        /**
         * SeriesStoryPageRequest page.
         * @member {query_pagination.IPageRequest|null|undefined} page
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @instance
         */
        SeriesStoryPageRequest.prototype.page = null;

        /**
         * SeriesStoryPageRequest sortBy.
         * @member {query_recursive_types.SeriesStorySortBy} sortBy
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @instance
         */
        SeriesStoryPageRequest.prototype.sortBy = 0;

        /**
         * SeriesStoryPageRequest filterStatus.
         * @member {story.StoryStatus} filterStatus
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @instance
         */
        SeriesStoryPageRequest.prototype.filterStatus = 0;

        /**
         * Creates a new SeriesStoryPageRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @static
         * @param {query_recursive_types.ISeriesStoryPageRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesStoryPageRequest} SeriesStoryPageRequest instance
         */
        SeriesStoryPageRequest.create = function create(properties) {
            return new SeriesStoryPageRequest(properties);
        };

        /**
         * Encodes the specified SeriesStoryPageRequest message. Does not implicitly {@link query_recursive_types.SeriesStoryPageRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @static
         * @param {query_recursive_types.ISeriesStoryPageRequest} message SeriesStoryPageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesStoryPageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireStoryList != null && Object.hasOwnProperty.call(message, "requireStoryList"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStoryList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageRequest.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sortBy);
            if (message.filterStatus != null && Object.hasOwnProperty.call(message, "filterStatus"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.filterStatus);
            return writer;
        };

        /**
         * Decodes a SeriesStoryPageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesStoryPageRequest} SeriesStoryPageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesStoryPageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesStoryPageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireStoryList = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.page = $root.query_pagination.PageRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.sortBy = reader.int32();
                    break;
                case 4:
                    message.filterStatus = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesStoryPageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesStoryPageRequest} SeriesStoryPageRequest
         */
        SeriesStoryPageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesStoryPageRequest)
                return object;
            var message = new $root.query_recursive_types.SeriesStoryPageRequest();
            if (object.requireStoryList != null) {
                if (typeof object.requireStoryList !== "object")
                    throw TypeError(".query_recursive_types.SeriesStoryPageRequest.requireStoryList: object expected");
                message.requireStoryList = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStoryList);
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.SeriesStoryPageRequest.page: object expected");
                message.page = $root.query_pagination.PageRequest.fromObject(object.page);
            }
            switch (object.sortBy) {
            case "SERIES_STORY_SORT_BY_UNSPECIFIED":
            case 0:
                message.sortBy = 0;
                break;
            case "SERIES_STORY_SORT_BY_SERIES_INDEX_ASC":
            case 1:
                message.sortBy = 1;
                break;
            case "SERIES_STORY_SORT_BY_SERIES_INDEX_DESC":
            case 2:
                message.sortBy = 2;
                break;
            }
            switch (object.filterStatus) {
            case "STORY_STATUS_UNKNOWN":
            case 0:
                message.filterStatus = 0;
                break;
            case "DRAFT":
            case 1:
                message.filterStatus = 1;
                break;
            case "RESERVE":
            case 2:
                message.filterStatus = 2;
                break;
            case "PUBLISH":
            case 3:
                message.filterStatus = 3;
                break;
            case "REJECT":
            case 4:
                message.filterStatus = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesStoryPageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @static
         * @param {query_recursive_types.SeriesStoryPageRequest} message SeriesStoryPageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesStoryPageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireStoryList = null;
                object.page = null;
                object.sortBy = options.enums === String ? "SERIES_STORY_SORT_BY_UNSPECIFIED" : 0;
                object.filterStatus = options.enums === String ? "STORY_STATUS_UNKNOWN" : 0;
            }
            if (message.requireStoryList != null && message.hasOwnProperty("requireStoryList"))
                object.requireStoryList = $root.query_recursive_types.StoryRequireField.toObject(message.requireStoryList, options);
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageRequest.toObject(message.page, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.query_recursive_types.SeriesStorySortBy[message.sortBy] : message.sortBy;
            if (message.filterStatus != null && message.hasOwnProperty("filterStatus"))
                object.filterStatus = options.enums === String ? $root.story.StoryStatus[message.filterStatus] : message.filterStatus;
            return object;
        };

        /**
         * Converts this SeriesStoryPageRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesStoryPageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesStoryPageRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesStoryPageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesStoryPageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesStoryPageRequest";
        };

        return SeriesStoryPageRequest;
    })();

    query_recursive_types.SeriesStoryPageResponse = (function() {

        /**
         * Properties of a SeriesStoryPageResponse.
         * @memberof query_recursive_types
         * @interface ISeriesStoryPageResponse
         * @property {Array.<query_recursive_types.IStoryResponse>|null} [storyList] SeriesStoryPageResponse storyList
         * @property {query_pagination.IPageInfo|null} [page] SeriesStoryPageResponse page
         */

        /**
         * Constructs a new SeriesStoryPageResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesStoryPageResponse.
         * @implements ISeriesStoryPageResponse
         * @constructor
         * @param {query_recursive_types.ISeriesStoryPageResponse=} [properties] Properties to set
         */
        function SeriesStoryPageResponse(properties) {
            this.storyList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesStoryPageResponse storyList.
         * @member {Array.<query_recursive_types.IStoryResponse>} storyList
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @instance
         */
        SeriesStoryPageResponse.prototype.storyList = $util.emptyArray;

        /**
         * SeriesStoryPageResponse page.
         * @member {query_pagination.IPageInfo|null|undefined} page
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @instance
         */
        SeriesStoryPageResponse.prototype.page = null;

        /**
         * Creates a new SeriesStoryPageResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @static
         * @param {query_recursive_types.ISeriesStoryPageResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesStoryPageResponse} SeriesStoryPageResponse instance
         */
        SeriesStoryPageResponse.create = function create(properties) {
            return new SeriesStoryPageResponse(properties);
        };

        /**
         * Encodes the specified SeriesStoryPageResponse message. Does not implicitly {@link query_recursive_types.SeriesStoryPageResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @static
         * @param {query_recursive_types.ISeriesStoryPageResponse} message SeriesStoryPageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesStoryPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyList != null && message.storyList.length)
                for (var i = 0; i < message.storyList.length; ++i)
                    $root.query_recursive_types.StoryResponse.encode(message.storyList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageInfo.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesStoryPageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesStoryPageResponse} SeriesStoryPageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesStoryPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesStoryPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyList && message.storyList.length))
                        message.storyList = [];
                    message.storyList.push($root.query_recursive_types.StoryResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = $root.query_pagination.PageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesStoryPageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesStoryPageResponse} SeriesStoryPageResponse
         */
        SeriesStoryPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesStoryPageResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesStoryPageResponse();
            if (object.storyList) {
                if (!Array.isArray(object.storyList))
                    throw TypeError(".query_recursive_types.SeriesStoryPageResponse.storyList: array expected");
                message.storyList = [];
                for (var i = 0; i < object.storyList.length; ++i) {
                    if (typeof object.storyList[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesStoryPageResponse.storyList: object expected");
                    message.storyList[i] = $root.query_recursive_types.StoryResponse.fromObject(object.storyList[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.SeriesStoryPageResponse.page: object expected");
                message.page = $root.query_pagination.PageInfo.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesStoryPageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @static
         * @param {query_recursive_types.SeriesStoryPageResponse} message SeriesStoryPageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesStoryPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyList = [];
            if (options.defaults)
                object.page = null;
            if (message.storyList && message.storyList.length) {
                object.storyList = [];
                for (var j = 0; j < message.storyList.length; ++j)
                    object.storyList[j] = $root.query_recursive_types.StoryResponse.toObject(message.storyList[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageInfo.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this SeriesStoryPageResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesStoryPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesStoryPageResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesStoryPageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesStoryPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesStoryPageResponse";
        };

        return SeriesStoryPageResponse;
    })();

    query_recursive_types.StoryCursorRequest = (function() {

        /**
         * Properties of a StoryCursorRequest.
         * @memberof query_recursive_types
         * @interface IStoryCursorRequest
         * @property {query_recursive_types.IStoryRequireField|null} [requireStoryList] StoryCursorRequest requireStoryList
         * @property {query_types.IStoryFilter|null} [filter] StoryCursorRequest filter
         * @property {query_pagination.ICursorRequest|null} [cursor] StoryCursorRequest cursor
         */

        /**
         * Constructs a new StoryCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryCursorRequest.
         * @implements IStoryCursorRequest
         * @constructor
         * @param {query_recursive_types.IStoryCursorRequest=} [properties] Properties to set
         */
        function StoryCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryCursorRequest requireStoryList.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStoryList
         * @memberof query_recursive_types.StoryCursorRequest
         * @instance
         */
        StoryCursorRequest.prototype.requireStoryList = null;

        /**
         * StoryCursorRequest filter.
         * @member {query_types.IStoryFilter|null|undefined} filter
         * @memberof query_recursive_types.StoryCursorRequest
         * @instance
         */
        StoryCursorRequest.prototype.filter = null;

        /**
         * StoryCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.StoryCursorRequest
         * @instance
         */
        StoryCursorRequest.prototype.cursor = null;

        /**
         * Creates a new StoryCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryCursorRequest
         * @static
         * @param {query_recursive_types.IStoryCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.StoryCursorRequest} StoryCursorRequest instance
         */
        StoryCursorRequest.create = function create(properties) {
            return new StoryCursorRequest(properties);
        };

        /**
         * Encodes the specified StoryCursorRequest message. Does not implicitly {@link query_recursive_types.StoryCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryCursorRequest
         * @static
         * @param {query_recursive_types.IStoryCursorRequest} message StoryCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireStoryList != null && Object.hasOwnProperty.call(message, "requireStoryList"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStoryList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                $root.query_types.StoryFilter.encode(message.filter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryCursorRequest} StoryCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireStoryList = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.filter = $root.query_types.StoryFilter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryCursorRequest} StoryCursorRequest
         */
        StoryCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryCursorRequest)
                return object;
            var message = new $root.query_recursive_types.StoryCursorRequest();
            if (object.requireStoryList != null) {
                if (typeof object.requireStoryList !== "object")
                    throw TypeError(".query_recursive_types.StoryCursorRequest.requireStoryList: object expected");
                message.requireStoryList = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStoryList);
            }
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".query_recursive_types.StoryCursorRequest.filter: object expected");
                message.filter = $root.query_types.StoryFilter.fromObject(object.filter);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.StoryCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryCursorRequest
         * @static
         * @param {query_recursive_types.StoryCursorRequest} message StoryCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireStoryList = null;
                object.filter = null;
                object.cursor = null;
            }
            if (message.requireStoryList != null && message.hasOwnProperty("requireStoryList"))
                object.requireStoryList = $root.query_recursive_types.StoryRequireField.toObject(message.requireStoryList, options);
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.query_types.StoryFilter.toObject(message.filter, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StoryCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryCursorRequest";
        };

        return StoryCursorRequest;
    })();

    query_recursive_types.StoryCursorResponse = (function() {

        /**
         * Properties of a StoryCursorResponse.
         * @memberof query_recursive_types
         * @interface IStoryCursorResponse
         * @property {Array.<query_recursive_types.IStoryResponse>|null} [storyList] StoryCursorResponse storyList
         * @property {query_pagination.ICursorInfo|null} [cursor] StoryCursorResponse cursor
         */

        /**
         * Constructs a new StoryCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryCursorResponse.
         * @implements IStoryCursorResponse
         * @constructor
         * @param {query_recursive_types.IStoryCursorResponse=} [properties] Properties to set
         */
        function StoryCursorResponse(properties) {
            this.storyList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryCursorResponse storyList.
         * @member {Array.<query_recursive_types.IStoryResponse>} storyList
         * @memberof query_recursive_types.StoryCursorResponse
         * @instance
         */
        StoryCursorResponse.prototype.storyList = $util.emptyArray;

        /**
         * StoryCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.StoryCursorResponse
         * @instance
         */
        StoryCursorResponse.prototype.cursor = null;

        /**
         * Creates a new StoryCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryCursorResponse
         * @static
         * @param {query_recursive_types.IStoryCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryCursorResponse} StoryCursorResponse instance
         */
        StoryCursorResponse.create = function create(properties) {
            return new StoryCursorResponse(properties);
        };

        /**
         * Encodes the specified StoryCursorResponse message. Does not implicitly {@link query_recursive_types.StoryCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryCursorResponse
         * @static
         * @param {query_recursive_types.IStoryCursorResponse} message StoryCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyList != null && message.storyList.length)
                for (var i = 0; i < message.storyList.length; ++i)
                    $root.query_recursive_types.StoryResponse.encode(message.storyList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryCursorResponse} StoryCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyList && message.storyList.length))
                        message.storyList = [];
                    message.storyList.push($root.query_recursive_types.StoryResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryCursorResponse} StoryCursorResponse
         */
        StoryCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryCursorResponse)
                return object;
            var message = new $root.query_recursive_types.StoryCursorResponse();
            if (object.storyList) {
                if (!Array.isArray(object.storyList))
                    throw TypeError(".query_recursive_types.StoryCursorResponse.storyList: array expected");
                message.storyList = [];
                for (var i = 0; i < object.storyList.length; ++i) {
                    if (typeof object.storyList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryCursorResponse.storyList: object expected");
                    message.storyList[i] = $root.query_recursive_types.StoryResponse.fromObject(object.storyList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.StoryCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryCursorResponse
         * @static
         * @param {query_recursive_types.StoryCursorResponse} message StoryCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.storyList && message.storyList.length) {
                object.storyList = [];
                for (var j = 0; j < message.storyList.length; ++j)
                    object.storyList[j] = $root.query_recursive_types.StoryResponse.toObject(message.storyList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StoryCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryCursorResponse";
        };

        return StoryCursorResponse;
    })();

    query_recursive_types.StoryReadingProgressRequireField = (function() {

        /**
         * Properties of a StoryReadingProgressRequireField.
         * @memberof query_recursive_types
         * @interface IStoryReadingProgressRequireField
         * @property {query_recursive_types.IStoryRequireField|null} [requireStory] StoryReadingProgressRequireField requireStory
         * @property {google.protobuf.IBoolValue|null} [requireIndex] StoryReadingProgressRequireField requireIndex
         * @property {google.protobuf.IBoolValue|null} [requireIsFinished] StoryReadingProgressRequireField requireIsFinished
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] StoryReadingProgressRequireField requireUpdatedAt
         */

        /**
         * Constructs a new StoryReadingProgressRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryReadingProgressRequireField.
         * @implements IStoryReadingProgressRequireField
         * @constructor
         * @param {query_recursive_types.IStoryReadingProgressRequireField=} [properties] Properties to set
         */
        function StoryReadingProgressRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReadingProgressRequireField requireStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStory
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @instance
         */
        StoryReadingProgressRequireField.prototype.requireStory = null;

        /**
         * StoryReadingProgressRequireField requireIndex.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIndex
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @instance
         */
        StoryReadingProgressRequireField.prototype.requireIndex = null;

        /**
         * StoryReadingProgressRequireField requireIsFinished.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsFinished
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @instance
         */
        StoryReadingProgressRequireField.prototype.requireIsFinished = null;

        /**
         * StoryReadingProgressRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @instance
         */
        StoryReadingProgressRequireField.prototype.requireUpdatedAt = null;

        /**
         * Creates a new StoryReadingProgressRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @static
         * @param {query_recursive_types.IStoryReadingProgressRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.StoryReadingProgressRequireField} StoryReadingProgressRequireField instance
         */
        StoryReadingProgressRequireField.create = function create(properties) {
            return new StoryReadingProgressRequireField(properties);
        };

        /**
         * Encodes the specified StoryReadingProgressRequireField message. Does not implicitly {@link query_recursive_types.StoryReadingProgressRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @static
         * @param {query_recursive_types.IStoryReadingProgressRequireField} message StoryReadingProgressRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReadingProgressRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireStory != null && Object.hasOwnProperty.call(message, "requireStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStory, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireIndex != null && Object.hasOwnProperty.call(message, "requireIndex"))
                $root.google.protobuf.BoolValue.encode(message.requireIndex, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireIsFinished != null && Object.hasOwnProperty.call(message, "requireIsFinished"))
                $root.google.protobuf.BoolValue.encode(message.requireIsFinished, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReadingProgressRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryReadingProgressRequireField} StoryReadingProgressRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReadingProgressRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryReadingProgressRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireIndex = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireIsFinished = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReadingProgressRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryReadingProgressRequireField} StoryReadingProgressRequireField
         */
        StoryReadingProgressRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryReadingProgressRequireField)
                return object;
            var message = new $root.query_recursive_types.StoryReadingProgressRequireField();
            if (object.requireStory != null) {
                if (typeof object.requireStory !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressRequireField.requireStory: object expected");
                message.requireStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStory);
            }
            if (object.requireIndex != null) {
                if (typeof object.requireIndex !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressRequireField.requireIndex: object expected");
                message.requireIndex = $root.google.protobuf.BoolValue.fromObject(object.requireIndex);
            }
            if (object.requireIsFinished != null) {
                if (typeof object.requireIsFinished !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressRequireField.requireIsFinished: object expected");
                message.requireIsFinished = $root.google.protobuf.BoolValue.fromObject(object.requireIsFinished);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReadingProgressRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @static
         * @param {query_recursive_types.StoryReadingProgressRequireField} message StoryReadingProgressRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReadingProgressRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireStory = null;
                object.requireIndex = null;
                object.requireIsFinished = null;
                object.requireUpdatedAt = null;
            }
            if (message.requireStory != null && message.hasOwnProperty("requireStory"))
                object.requireStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireStory, options);
            if (message.requireIndex != null && message.hasOwnProperty("requireIndex"))
                object.requireIndex = $root.google.protobuf.BoolValue.toObject(message.requireIndex, options);
            if (message.requireIsFinished != null && message.hasOwnProperty("requireIsFinished"))
                object.requireIsFinished = $root.google.protobuf.BoolValue.toObject(message.requireIsFinished, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            return object;
        };

        /**
         * Converts this StoryReadingProgressRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReadingProgressRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReadingProgressRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryReadingProgressRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReadingProgressRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryReadingProgressRequireField";
        };

        return StoryReadingProgressRequireField;
    })();

    query_recursive_types.StoryReadingProgressResponse = (function() {

        /**
         * Properties of a StoryReadingProgressResponse.
         * @memberof query_recursive_types
         * @interface IStoryReadingProgressResponse
         * @property {query_recursive_types.IStoryResponse|null} [story] StoryReadingProgressResponse story
         * @property {google.protobuf.IInt32Value|null} [index] StoryReadingProgressResponse index
         * @property {google.protobuf.IBoolValue|null} [isFinished] StoryReadingProgressResponse isFinished
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StoryReadingProgressResponse updatedAt
         */

        /**
         * Constructs a new StoryReadingProgressResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryReadingProgressResponse.
         * @implements IStoryReadingProgressResponse
         * @constructor
         * @param {query_recursive_types.IStoryReadingProgressResponse=} [properties] Properties to set
         */
        function StoryReadingProgressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReadingProgressResponse story.
         * @member {query_recursive_types.IStoryResponse|null|undefined} story
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @instance
         */
        StoryReadingProgressResponse.prototype.story = null;

        /**
         * StoryReadingProgressResponse index.
         * @member {google.protobuf.IInt32Value|null|undefined} index
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @instance
         */
        StoryReadingProgressResponse.prototype.index = null;

        /**
         * StoryReadingProgressResponse isFinished.
         * @member {google.protobuf.IBoolValue|null|undefined} isFinished
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @instance
         */
        StoryReadingProgressResponse.prototype.isFinished = null;

        /**
         * StoryReadingProgressResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @instance
         */
        StoryReadingProgressResponse.prototype.updatedAt = null;

        /**
         * Creates a new StoryReadingProgressResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @static
         * @param {query_recursive_types.IStoryReadingProgressResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryReadingProgressResponse} StoryReadingProgressResponse instance
         */
        StoryReadingProgressResponse.create = function create(properties) {
            return new StoryReadingProgressResponse(properties);
        };

        /**
         * Encodes the specified StoryReadingProgressResponse message. Does not implicitly {@link query_recursive_types.StoryReadingProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @static
         * @param {query_recursive_types.IStoryReadingProgressResponse} message StoryReadingProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReadingProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.query_recursive_types.StoryResponse.encode(message.story, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                $root.google.protobuf.Int32Value.encode(message.index, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.isFinished != null && Object.hasOwnProperty.call(message, "isFinished"))
                $root.google.protobuf.BoolValue.encode(message.isFinished, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReadingProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryReadingProgressResponse} StoryReadingProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReadingProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryReadingProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.story = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.index = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.isFinished = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReadingProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryReadingProgressResponse} StoryReadingProgressResponse
         */
        StoryReadingProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryReadingProgressResponse)
                return object;
            var message = new $root.query_recursive_types.StoryReadingProgressResponse();
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressResponse.story: object expected");
                message.story = $root.query_recursive_types.StoryResponse.fromObject(object.story);
            }
            if (object.index != null) {
                if (typeof object.index !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressResponse.index: object expected");
                message.index = $root.google.protobuf.Int32Value.fromObject(object.index);
            }
            if (object.isFinished != null) {
                if (typeof object.isFinished !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressResponse.isFinished: object expected");
                message.isFinished = $root.google.protobuf.BoolValue.fromObject(object.isFinished);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReadingProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @static
         * @param {query_recursive_types.StoryReadingProgressResponse} message StoryReadingProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReadingProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.story = null;
                object.index = null;
                object.isFinished = null;
                object.updatedAt = null;
            }
            if (message.story != null && message.hasOwnProperty("story"))
                object.story = $root.query_recursive_types.StoryResponse.toObject(message.story, options);
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = $root.google.protobuf.Int32Value.toObject(message.index, options);
            if (message.isFinished != null && message.hasOwnProperty("isFinished"))
                object.isFinished = $root.google.protobuf.BoolValue.toObject(message.isFinished, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this StoryReadingProgressResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReadingProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReadingProgressResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryReadingProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReadingProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryReadingProgressResponse";
        };

        return StoryReadingProgressResponse;
    })();

    query_recursive_types.StoryReadingProgressCursorRequest = (function() {

        /**
         * Properties of a StoryReadingProgressCursorRequest.
         * @memberof query_recursive_types
         * @interface IStoryReadingProgressCursorRequest
         * @property {query_recursive_types.IStoryReadingProgressRequireField|null} [requireReadingProgressList] StoryReadingProgressCursorRequest requireReadingProgressList
         * @property {google.protobuf.IBoolValue|null} [excludeFinished] StoryReadingProgressCursorRequest excludeFinished
         * @property {google.protobuf.IBoolValue|null} [excludeNotFinished] StoryReadingProgressCursorRequest excludeNotFinished
         * @property {google.protobuf.IBoolValue|null} [excludeSeriesStory] StoryReadingProgressCursorRequest excludeSeriesStory
         * @property {query_pagination.ICursorRequest|null} [cursor] StoryReadingProgressCursorRequest cursor
         */

        /**
         * Constructs a new StoryReadingProgressCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryReadingProgressCursorRequest.
         * @implements IStoryReadingProgressCursorRequest
         * @constructor
         * @param {query_recursive_types.IStoryReadingProgressCursorRequest=} [properties] Properties to set
         */
        function StoryReadingProgressCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReadingProgressCursorRequest requireReadingProgressList.
         * @member {query_recursive_types.IStoryReadingProgressRequireField|null|undefined} requireReadingProgressList
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @instance
         */
        StoryReadingProgressCursorRequest.prototype.requireReadingProgressList = null;

        /**
         * StoryReadingProgressCursorRequest excludeFinished.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeFinished
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @instance
         */
        StoryReadingProgressCursorRequest.prototype.excludeFinished = null;

        /**
         * StoryReadingProgressCursorRequest excludeNotFinished.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotFinished
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @instance
         */
        StoryReadingProgressCursorRequest.prototype.excludeNotFinished = null;

        /**
         * StoryReadingProgressCursorRequest excludeSeriesStory.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeSeriesStory
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @instance
         */
        StoryReadingProgressCursorRequest.prototype.excludeSeriesStory = null;

        /**
         * StoryReadingProgressCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @instance
         */
        StoryReadingProgressCursorRequest.prototype.cursor = null;

        /**
         * Creates a new StoryReadingProgressCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @static
         * @param {query_recursive_types.IStoryReadingProgressCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.StoryReadingProgressCursorRequest} StoryReadingProgressCursorRequest instance
         */
        StoryReadingProgressCursorRequest.create = function create(properties) {
            return new StoryReadingProgressCursorRequest(properties);
        };

        /**
         * Encodes the specified StoryReadingProgressCursorRequest message. Does not implicitly {@link query_recursive_types.StoryReadingProgressCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @static
         * @param {query_recursive_types.IStoryReadingProgressCursorRequest} message StoryReadingProgressCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReadingProgressCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireReadingProgressList != null && Object.hasOwnProperty.call(message, "requireReadingProgressList"))
                $root.query_recursive_types.StoryReadingProgressRequireField.encode(message.requireReadingProgressList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.excludeFinished != null && Object.hasOwnProperty.call(message, "excludeFinished"))
                $root.google.protobuf.BoolValue.encode(message.excludeFinished, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.excludeNotFinished != null && Object.hasOwnProperty.call(message, "excludeNotFinished"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotFinished, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.excludeSeriesStory != null && Object.hasOwnProperty.call(message, "excludeSeriesStory"))
                $root.google.protobuf.BoolValue.encode(message.excludeSeriesStory, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReadingProgressCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryReadingProgressCursorRequest} StoryReadingProgressCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReadingProgressCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryReadingProgressCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireReadingProgressList = $root.query_recursive_types.StoryReadingProgressRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.excludeFinished = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.excludeNotFinished = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.excludeSeriesStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReadingProgressCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryReadingProgressCursorRequest} StoryReadingProgressCursorRequest
         */
        StoryReadingProgressCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryReadingProgressCursorRequest)
                return object;
            var message = new $root.query_recursive_types.StoryReadingProgressCursorRequest();
            if (object.requireReadingProgressList != null) {
                if (typeof object.requireReadingProgressList !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressCursorRequest.requireReadingProgressList: object expected");
                message.requireReadingProgressList = $root.query_recursive_types.StoryReadingProgressRequireField.fromObject(object.requireReadingProgressList);
            }
            if (object.excludeFinished != null) {
                if (typeof object.excludeFinished !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressCursorRequest.excludeFinished: object expected");
                message.excludeFinished = $root.google.protobuf.BoolValue.fromObject(object.excludeFinished);
            }
            if (object.excludeNotFinished != null) {
                if (typeof object.excludeNotFinished !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressCursorRequest.excludeNotFinished: object expected");
                message.excludeNotFinished = $root.google.protobuf.BoolValue.fromObject(object.excludeNotFinished);
            }
            if (object.excludeSeriesStory != null) {
                if (typeof object.excludeSeriesStory !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressCursorRequest.excludeSeriesStory: object expected");
                message.excludeSeriesStory = $root.google.protobuf.BoolValue.fromObject(object.excludeSeriesStory);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReadingProgressCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @static
         * @param {query_recursive_types.StoryReadingProgressCursorRequest} message StoryReadingProgressCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReadingProgressCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireReadingProgressList = null;
                object.excludeFinished = null;
                object.excludeNotFinished = null;
                object.excludeSeriesStory = null;
                object.cursor = null;
            }
            if (message.requireReadingProgressList != null && message.hasOwnProperty("requireReadingProgressList"))
                object.requireReadingProgressList = $root.query_recursive_types.StoryReadingProgressRequireField.toObject(message.requireReadingProgressList, options);
            if (message.excludeFinished != null && message.hasOwnProperty("excludeFinished"))
                object.excludeFinished = $root.google.protobuf.BoolValue.toObject(message.excludeFinished, options);
            if (message.excludeNotFinished != null && message.hasOwnProperty("excludeNotFinished"))
                object.excludeNotFinished = $root.google.protobuf.BoolValue.toObject(message.excludeNotFinished, options);
            if (message.excludeSeriesStory != null && message.hasOwnProperty("excludeSeriesStory"))
                object.excludeSeriesStory = $root.google.protobuf.BoolValue.toObject(message.excludeSeriesStory, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StoryReadingProgressCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReadingProgressCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReadingProgressCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryReadingProgressCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReadingProgressCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryReadingProgressCursorRequest";
        };

        return StoryReadingProgressCursorRequest;
    })();

    query_recursive_types.StoryReadingProgressCursorResponse = (function() {

        /**
         * Properties of a StoryReadingProgressCursorResponse.
         * @memberof query_recursive_types
         * @interface IStoryReadingProgressCursorResponse
         * @property {Array.<query_recursive_types.IStoryReadingProgressResponse>|null} [readingProgressList] StoryReadingProgressCursorResponse readingProgressList
         * @property {query_pagination.ICursorInfo|null} [cursor] StoryReadingProgressCursorResponse cursor
         */

        /**
         * Constructs a new StoryReadingProgressCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryReadingProgressCursorResponse.
         * @implements IStoryReadingProgressCursorResponse
         * @constructor
         * @param {query_recursive_types.IStoryReadingProgressCursorResponse=} [properties] Properties to set
         */
        function StoryReadingProgressCursorResponse(properties) {
            this.readingProgressList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReadingProgressCursorResponse readingProgressList.
         * @member {Array.<query_recursive_types.IStoryReadingProgressResponse>} readingProgressList
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @instance
         */
        StoryReadingProgressCursorResponse.prototype.readingProgressList = $util.emptyArray;

        /**
         * StoryReadingProgressCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @instance
         */
        StoryReadingProgressCursorResponse.prototype.cursor = null;

        /**
         * Creates a new StoryReadingProgressCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @static
         * @param {query_recursive_types.IStoryReadingProgressCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryReadingProgressCursorResponse} StoryReadingProgressCursorResponse instance
         */
        StoryReadingProgressCursorResponse.create = function create(properties) {
            return new StoryReadingProgressCursorResponse(properties);
        };

        /**
         * Encodes the specified StoryReadingProgressCursorResponse message. Does not implicitly {@link query_recursive_types.StoryReadingProgressCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @static
         * @param {query_recursive_types.IStoryReadingProgressCursorResponse} message StoryReadingProgressCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReadingProgressCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.readingProgressList != null && message.readingProgressList.length)
                for (var i = 0; i < message.readingProgressList.length; ++i)
                    $root.query_recursive_types.StoryReadingProgressResponse.encode(message.readingProgressList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReadingProgressCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryReadingProgressCursorResponse} StoryReadingProgressCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReadingProgressCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryReadingProgressCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.readingProgressList && message.readingProgressList.length))
                        message.readingProgressList = [];
                    message.readingProgressList.push($root.query_recursive_types.StoryReadingProgressResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReadingProgressCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryReadingProgressCursorResponse} StoryReadingProgressCursorResponse
         */
        StoryReadingProgressCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryReadingProgressCursorResponse)
                return object;
            var message = new $root.query_recursive_types.StoryReadingProgressCursorResponse();
            if (object.readingProgressList) {
                if (!Array.isArray(object.readingProgressList))
                    throw TypeError(".query_recursive_types.StoryReadingProgressCursorResponse.readingProgressList: array expected");
                message.readingProgressList = [];
                for (var i = 0; i < object.readingProgressList.length; ++i) {
                    if (typeof object.readingProgressList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryReadingProgressCursorResponse.readingProgressList: object expected");
                    message.readingProgressList[i] = $root.query_recursive_types.StoryReadingProgressResponse.fromObject(object.readingProgressList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.StoryReadingProgressCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReadingProgressCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @static
         * @param {query_recursive_types.StoryReadingProgressCursorResponse} message StoryReadingProgressCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReadingProgressCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.readingProgressList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.readingProgressList && message.readingProgressList.length) {
                object.readingProgressList = [];
                for (var j = 0; j < message.readingProgressList.length; ++j)
                    object.readingProgressList[j] = $root.query_recursive_types.StoryReadingProgressResponse.toObject(message.readingProgressList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StoryReadingProgressCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReadingProgressCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReadingProgressCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryReadingProgressCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReadingProgressCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryReadingProgressCursorResponse";
        };

        return StoryReadingProgressCursorResponse;
    })();

    query_recursive_types.SeriesReadingProgressRequireField = (function() {

        /**
         * Properties of a SeriesReadingProgressRequireField.
         * @memberof query_recursive_types
         * @interface ISeriesReadingProgressRequireField
         * @property {query_recursive_types.ISeriesRequireField|null} [requireSeries] SeriesReadingProgressRequireField requireSeries
         * @property {query_recursive_types.IStoryReadingProgressRequireField|null} [requireStoryReadingProgress] SeriesReadingProgressRequireField requireStoryReadingProgress
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] SeriesReadingProgressRequireField requireUpdatedAt
         * @property {query_recursive_types.IStoryRequireField|null} [requireNextStory] SeriesReadingProgressRequireField requireNextStory
         */

        /**
         * Constructs a new SeriesReadingProgressRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesReadingProgressRequireField.
         * @implements ISeriesReadingProgressRequireField
         * @constructor
         * @param {query_recursive_types.ISeriesReadingProgressRequireField=} [properties] Properties to set
         */
        function SeriesReadingProgressRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesReadingProgressRequireField requireSeries.
         * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeries
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @instance
         */
        SeriesReadingProgressRequireField.prototype.requireSeries = null;

        /**
         * SeriesReadingProgressRequireField requireStoryReadingProgress.
         * @member {query_recursive_types.IStoryReadingProgressRequireField|null|undefined} requireStoryReadingProgress
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @instance
         */
        SeriesReadingProgressRequireField.prototype.requireStoryReadingProgress = null;

        /**
         * SeriesReadingProgressRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @instance
         */
        SeriesReadingProgressRequireField.prototype.requireUpdatedAt = null;

        /**
         * SeriesReadingProgressRequireField requireNextStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireNextStory
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @instance
         */
        SeriesReadingProgressRequireField.prototype.requireNextStory = null;

        /**
         * Creates a new SeriesReadingProgressRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesReadingProgressRequireField} SeriesReadingProgressRequireField instance
         */
        SeriesReadingProgressRequireField.create = function create(properties) {
            return new SeriesReadingProgressRequireField(properties);
        };

        /**
         * Encodes the specified SeriesReadingProgressRequireField message. Does not implicitly {@link query_recursive_types.SeriesReadingProgressRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressRequireField} message SeriesReadingProgressRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesReadingProgressRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSeries != null && Object.hasOwnProperty.call(message, "requireSeries"))
                $root.query_recursive_types.SeriesRequireField.encode(message.requireSeries, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireStoryReadingProgress != null && Object.hasOwnProperty.call(message, "requireStoryReadingProgress"))
                $root.query_recursive_types.StoryReadingProgressRequireField.encode(message.requireStoryReadingProgress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireNextStory != null && Object.hasOwnProperty.call(message, "requireNextStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireNextStory, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesReadingProgressRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesReadingProgressRequireField} SeriesReadingProgressRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesReadingProgressRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesReadingProgressRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSeries = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireStoryReadingProgress = $root.query_recursive_types.StoryReadingProgressRequireField.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireNextStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesReadingProgressRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesReadingProgressRequireField} SeriesReadingProgressRequireField
         */
        SeriesReadingProgressRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesReadingProgressRequireField)
                return object;
            var message = new $root.query_recursive_types.SeriesReadingProgressRequireField();
            if (object.requireSeries != null) {
                if (typeof object.requireSeries !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressRequireField.requireSeries: object expected");
                message.requireSeries = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeries);
            }
            if (object.requireStoryReadingProgress != null) {
                if (typeof object.requireStoryReadingProgress !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressRequireField.requireStoryReadingProgress: object expected");
                message.requireStoryReadingProgress = $root.query_recursive_types.StoryReadingProgressRequireField.fromObject(object.requireStoryReadingProgress);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requireNextStory != null) {
                if (typeof object.requireNextStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressRequireField.requireNextStory: object expected");
                message.requireNextStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireNextStory);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesReadingProgressRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @static
         * @param {query_recursive_types.SeriesReadingProgressRequireField} message SeriesReadingProgressRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesReadingProgressRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireSeries = null;
                object.requireStoryReadingProgress = null;
                object.requireNextStory = null;
                object.requireUpdatedAt = null;
            }
            if (message.requireSeries != null && message.hasOwnProperty("requireSeries"))
                object.requireSeries = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeries, options);
            if (message.requireStoryReadingProgress != null && message.hasOwnProperty("requireStoryReadingProgress"))
                object.requireStoryReadingProgress = $root.query_recursive_types.StoryReadingProgressRequireField.toObject(message.requireStoryReadingProgress, options);
            if (message.requireNextStory != null && message.hasOwnProperty("requireNextStory"))
                object.requireNextStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireNextStory, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            return object;
        };

        /**
         * Converts this SeriesReadingProgressRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesReadingProgressRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesReadingProgressRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesReadingProgressRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesReadingProgressRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesReadingProgressRequireField";
        };

        return SeriesReadingProgressRequireField;
    })();

    query_recursive_types.SeriesReadingProgressResponse = (function() {

        /**
         * Properties of a SeriesReadingProgressResponse.
         * @memberof query_recursive_types
         * @interface ISeriesReadingProgressResponse
         * @property {query_recursive_types.ISeriesResponse|null} [series] SeriesReadingProgressResponse series
         * @property {query_recursive_types.IStoryReadingProgressResponse|null} [storyReadingProgress] SeriesReadingProgressResponse storyReadingProgress
         * @property {google.protobuf.ITimestamp|null} [updatedAt] SeriesReadingProgressResponse updatedAt
         * @property {query_recursive_types.IStoryResponse|null} [nextStory] SeriesReadingProgressResponse nextStory
         */

        /**
         * Constructs a new SeriesReadingProgressResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesReadingProgressResponse.
         * @implements ISeriesReadingProgressResponse
         * @constructor
         * @param {query_recursive_types.ISeriesReadingProgressResponse=} [properties] Properties to set
         */
        function SeriesReadingProgressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesReadingProgressResponse series.
         * @member {query_recursive_types.ISeriesResponse|null|undefined} series
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @instance
         */
        SeriesReadingProgressResponse.prototype.series = null;

        /**
         * SeriesReadingProgressResponse storyReadingProgress.
         * @member {query_recursive_types.IStoryReadingProgressResponse|null|undefined} storyReadingProgress
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @instance
         */
        SeriesReadingProgressResponse.prototype.storyReadingProgress = null;

        /**
         * SeriesReadingProgressResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @instance
         */
        SeriesReadingProgressResponse.prototype.updatedAt = null;

        /**
         * SeriesReadingProgressResponse nextStory.
         * @member {query_recursive_types.IStoryResponse|null|undefined} nextStory
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @instance
         */
        SeriesReadingProgressResponse.prototype.nextStory = null;

        /**
         * Creates a new SeriesReadingProgressResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesReadingProgressResponse} SeriesReadingProgressResponse instance
         */
        SeriesReadingProgressResponse.create = function create(properties) {
            return new SeriesReadingProgressResponse(properties);
        };

        /**
         * Encodes the specified SeriesReadingProgressResponse message. Does not implicitly {@link query_recursive_types.SeriesReadingProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressResponse} message SeriesReadingProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesReadingProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.query_recursive_types.SeriesResponse.encode(message.series, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyReadingProgress != null && Object.hasOwnProperty.call(message, "storyReadingProgress"))
                $root.query_recursive_types.StoryReadingProgressResponse.encode(message.storyReadingProgress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.nextStory != null && Object.hasOwnProperty.call(message, "nextStory"))
                $root.query_recursive_types.StoryResponse.encode(message.nextStory, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesReadingProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesReadingProgressResponse} SeriesReadingProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesReadingProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesReadingProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.series = $root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyReadingProgress = $root.query_recursive_types.StoryReadingProgressResponse.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.nextStory = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesReadingProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesReadingProgressResponse} SeriesReadingProgressResponse
         */
        SeriesReadingProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesReadingProgressResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesReadingProgressResponse();
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressResponse.series: object expected");
                message.series = $root.query_recursive_types.SeriesResponse.fromObject(object.series);
            }
            if (object.storyReadingProgress != null) {
                if (typeof object.storyReadingProgress !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressResponse.storyReadingProgress: object expected");
                message.storyReadingProgress = $root.query_recursive_types.StoryReadingProgressResponse.fromObject(object.storyReadingProgress);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.nextStory != null) {
                if (typeof object.nextStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressResponse.nextStory: object expected");
                message.nextStory = $root.query_recursive_types.StoryResponse.fromObject(object.nextStory);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesReadingProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @static
         * @param {query_recursive_types.SeriesReadingProgressResponse} message SeriesReadingProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesReadingProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.series = null;
                object.storyReadingProgress = null;
                object.nextStory = null;
                object.updatedAt = null;
            }
            if (message.series != null && message.hasOwnProperty("series"))
                object.series = $root.query_recursive_types.SeriesResponse.toObject(message.series, options);
            if (message.storyReadingProgress != null && message.hasOwnProperty("storyReadingProgress"))
                object.storyReadingProgress = $root.query_recursive_types.StoryReadingProgressResponse.toObject(message.storyReadingProgress, options);
            if (message.nextStory != null && message.hasOwnProperty("nextStory"))
                object.nextStory = $root.query_recursive_types.StoryResponse.toObject(message.nextStory, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this SeriesReadingProgressResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesReadingProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesReadingProgressResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesReadingProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesReadingProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesReadingProgressResponse";
        };

        return SeriesReadingProgressResponse;
    })();

    query_recursive_types.SeriesReadingProgressCursorRequest = (function() {

        /**
         * Properties of a SeriesReadingProgressCursorRequest.
         * @memberof query_recursive_types
         * @interface ISeriesReadingProgressCursorRequest
         * @property {query_recursive_types.ISeriesReadingProgressRequireField|null} [requireReadingProgressList] SeriesReadingProgressCursorRequest requireReadingProgressList
         * @property {google.protobuf.IBoolValue|null} [excludeFinishedLatestStory] SeriesReadingProgressCursorRequest excludeFinishedLatestStory
         * @property {google.protobuf.IBoolValue|null} [excludeNotFinishedLatestStory] SeriesReadingProgressCursorRequest excludeNotFinishedLatestStory
         * @property {google.protobuf.IBoolValue|null} [excludeFinishedCompleteSeries] SeriesReadingProgressCursorRequest excludeFinishedCompleteSeries
         * @property {google.protobuf.IBoolValue|null} [excludeNotFinishedCompleteSeries] SeriesReadingProgressCursorRequest excludeNotFinishedCompleteSeries
         * @property {query_pagination.ICursorRequest|null} [cursor] SeriesReadingProgressCursorRequest cursor
         */

        /**
         * Constructs a new SeriesReadingProgressCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesReadingProgressCursorRequest.
         * @implements ISeriesReadingProgressCursorRequest
         * @constructor
         * @param {query_recursive_types.ISeriesReadingProgressCursorRequest=} [properties] Properties to set
         */
        function SeriesReadingProgressCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesReadingProgressCursorRequest requireReadingProgressList.
         * @member {query_recursive_types.ISeriesReadingProgressRequireField|null|undefined} requireReadingProgressList
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @instance
         */
        SeriesReadingProgressCursorRequest.prototype.requireReadingProgressList = null;

        /**
         * SeriesReadingProgressCursorRequest excludeFinishedLatestStory.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeFinishedLatestStory
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @instance
         */
        SeriesReadingProgressCursorRequest.prototype.excludeFinishedLatestStory = null;

        /**
         * SeriesReadingProgressCursorRequest excludeNotFinishedLatestStory.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotFinishedLatestStory
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @instance
         */
        SeriesReadingProgressCursorRequest.prototype.excludeNotFinishedLatestStory = null;

        /**
         * SeriesReadingProgressCursorRequest excludeFinishedCompleteSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeFinishedCompleteSeries
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @instance
         */
        SeriesReadingProgressCursorRequest.prototype.excludeFinishedCompleteSeries = null;

        /**
         * SeriesReadingProgressCursorRequest excludeNotFinishedCompleteSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotFinishedCompleteSeries
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @instance
         */
        SeriesReadingProgressCursorRequest.prototype.excludeNotFinishedCompleteSeries = null;

        /**
         * SeriesReadingProgressCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @instance
         */
        SeriesReadingProgressCursorRequest.prototype.cursor = null;

        /**
         * Creates a new SeriesReadingProgressCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesReadingProgressCursorRequest} SeriesReadingProgressCursorRequest instance
         */
        SeriesReadingProgressCursorRequest.create = function create(properties) {
            return new SeriesReadingProgressCursorRequest(properties);
        };

        /**
         * Encodes the specified SeriesReadingProgressCursorRequest message. Does not implicitly {@link query_recursive_types.SeriesReadingProgressCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressCursorRequest} message SeriesReadingProgressCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesReadingProgressCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireReadingProgressList != null && Object.hasOwnProperty.call(message, "requireReadingProgressList"))
                $root.query_recursive_types.SeriesReadingProgressRequireField.encode(message.requireReadingProgressList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.excludeFinishedLatestStory != null && Object.hasOwnProperty.call(message, "excludeFinishedLatestStory"))
                $root.google.protobuf.BoolValue.encode(message.excludeFinishedLatestStory, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.excludeNotFinishedLatestStory != null && Object.hasOwnProperty.call(message, "excludeNotFinishedLatestStory"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotFinishedLatestStory, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.excludeFinishedCompleteSeries != null && Object.hasOwnProperty.call(message, "excludeFinishedCompleteSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeFinishedCompleteSeries, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.excludeNotFinishedCompleteSeries != null && Object.hasOwnProperty.call(message, "excludeNotFinishedCompleteSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotFinishedCompleteSeries, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesReadingProgressCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesReadingProgressCursorRequest} SeriesReadingProgressCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesReadingProgressCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesReadingProgressCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireReadingProgressList = $root.query_recursive_types.SeriesReadingProgressRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.excludeFinishedLatestStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.excludeNotFinishedLatestStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.excludeFinishedCompleteSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.excludeNotFinishedCompleteSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesReadingProgressCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesReadingProgressCursorRequest} SeriesReadingProgressCursorRequest
         */
        SeriesReadingProgressCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesReadingProgressCursorRequest)
                return object;
            var message = new $root.query_recursive_types.SeriesReadingProgressCursorRequest();
            if (object.requireReadingProgressList != null) {
                if (typeof object.requireReadingProgressList !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorRequest.requireReadingProgressList: object expected");
                message.requireReadingProgressList = $root.query_recursive_types.SeriesReadingProgressRequireField.fromObject(object.requireReadingProgressList);
            }
            if (object.excludeFinishedLatestStory != null) {
                if (typeof object.excludeFinishedLatestStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorRequest.excludeFinishedLatestStory: object expected");
                message.excludeFinishedLatestStory = $root.google.protobuf.BoolValue.fromObject(object.excludeFinishedLatestStory);
            }
            if (object.excludeNotFinishedLatestStory != null) {
                if (typeof object.excludeNotFinishedLatestStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorRequest.excludeNotFinishedLatestStory: object expected");
                message.excludeNotFinishedLatestStory = $root.google.protobuf.BoolValue.fromObject(object.excludeNotFinishedLatestStory);
            }
            if (object.excludeFinishedCompleteSeries != null) {
                if (typeof object.excludeFinishedCompleteSeries !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorRequest.excludeFinishedCompleteSeries: object expected");
                message.excludeFinishedCompleteSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeFinishedCompleteSeries);
            }
            if (object.excludeNotFinishedCompleteSeries != null) {
                if (typeof object.excludeNotFinishedCompleteSeries !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorRequest.excludeNotFinishedCompleteSeries: object expected");
                message.excludeNotFinishedCompleteSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeNotFinishedCompleteSeries);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesReadingProgressCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @static
         * @param {query_recursive_types.SeriesReadingProgressCursorRequest} message SeriesReadingProgressCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesReadingProgressCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireReadingProgressList = null;
                object.excludeFinishedLatestStory = null;
                object.excludeNotFinishedLatestStory = null;
                object.excludeFinishedCompleteSeries = null;
                object.excludeNotFinishedCompleteSeries = null;
                object.cursor = null;
            }
            if (message.requireReadingProgressList != null && message.hasOwnProperty("requireReadingProgressList"))
                object.requireReadingProgressList = $root.query_recursive_types.SeriesReadingProgressRequireField.toObject(message.requireReadingProgressList, options);
            if (message.excludeFinishedLatestStory != null && message.hasOwnProperty("excludeFinishedLatestStory"))
                object.excludeFinishedLatestStory = $root.google.protobuf.BoolValue.toObject(message.excludeFinishedLatestStory, options);
            if (message.excludeNotFinishedLatestStory != null && message.hasOwnProperty("excludeNotFinishedLatestStory"))
                object.excludeNotFinishedLatestStory = $root.google.protobuf.BoolValue.toObject(message.excludeNotFinishedLatestStory, options);
            if (message.excludeFinishedCompleteSeries != null && message.hasOwnProperty("excludeFinishedCompleteSeries"))
                object.excludeFinishedCompleteSeries = $root.google.protobuf.BoolValue.toObject(message.excludeFinishedCompleteSeries, options);
            if (message.excludeNotFinishedCompleteSeries != null && message.hasOwnProperty("excludeNotFinishedCompleteSeries"))
                object.excludeNotFinishedCompleteSeries = $root.google.protobuf.BoolValue.toObject(message.excludeNotFinishedCompleteSeries, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this SeriesReadingProgressCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesReadingProgressCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesReadingProgressCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesReadingProgressCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesReadingProgressCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesReadingProgressCursorRequest";
        };

        return SeriesReadingProgressCursorRequest;
    })();

    query_recursive_types.SeriesReadingProgressCursorResponse = (function() {

        /**
         * Properties of a SeriesReadingProgressCursorResponse.
         * @memberof query_recursive_types
         * @interface ISeriesReadingProgressCursorResponse
         * @property {Array.<query_recursive_types.ISeriesReadingProgressResponse>|null} [readingProgressList] SeriesReadingProgressCursorResponse readingProgressList
         * @property {query_pagination.ICursorInfo|null} [cursor] SeriesReadingProgressCursorResponse cursor
         */

        /**
         * Constructs a new SeriesReadingProgressCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesReadingProgressCursorResponse.
         * @implements ISeriesReadingProgressCursorResponse
         * @constructor
         * @param {query_recursive_types.ISeriesReadingProgressCursorResponse=} [properties] Properties to set
         */
        function SeriesReadingProgressCursorResponse(properties) {
            this.readingProgressList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesReadingProgressCursorResponse readingProgressList.
         * @member {Array.<query_recursive_types.ISeriesReadingProgressResponse>} readingProgressList
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @instance
         */
        SeriesReadingProgressCursorResponse.prototype.readingProgressList = $util.emptyArray;

        /**
         * SeriesReadingProgressCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @instance
         */
        SeriesReadingProgressCursorResponse.prototype.cursor = null;

        /**
         * Creates a new SeriesReadingProgressCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesReadingProgressCursorResponse} SeriesReadingProgressCursorResponse instance
         */
        SeriesReadingProgressCursorResponse.create = function create(properties) {
            return new SeriesReadingProgressCursorResponse(properties);
        };

        /**
         * Encodes the specified SeriesReadingProgressCursorResponse message. Does not implicitly {@link query_recursive_types.SeriesReadingProgressCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @static
         * @param {query_recursive_types.ISeriesReadingProgressCursorResponse} message SeriesReadingProgressCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesReadingProgressCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.readingProgressList != null && message.readingProgressList.length)
                for (var i = 0; i < message.readingProgressList.length; ++i)
                    $root.query_recursive_types.SeriesReadingProgressResponse.encode(message.readingProgressList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesReadingProgressCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesReadingProgressCursorResponse} SeriesReadingProgressCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesReadingProgressCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesReadingProgressCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.readingProgressList && message.readingProgressList.length))
                        message.readingProgressList = [];
                    message.readingProgressList.push($root.query_recursive_types.SeriesReadingProgressResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesReadingProgressCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesReadingProgressCursorResponse} SeriesReadingProgressCursorResponse
         */
        SeriesReadingProgressCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesReadingProgressCursorResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesReadingProgressCursorResponse();
            if (object.readingProgressList) {
                if (!Array.isArray(object.readingProgressList))
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorResponse.readingProgressList: array expected");
                message.readingProgressList = [];
                for (var i = 0; i < object.readingProgressList.length; ++i) {
                    if (typeof object.readingProgressList[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesReadingProgressCursorResponse.readingProgressList: object expected");
                    message.readingProgressList[i] = $root.query_recursive_types.SeriesReadingProgressResponse.fromObject(object.readingProgressList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.SeriesReadingProgressCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesReadingProgressCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @static
         * @param {query_recursive_types.SeriesReadingProgressCursorResponse} message SeriesReadingProgressCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesReadingProgressCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.readingProgressList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.readingProgressList && message.readingProgressList.length) {
                object.readingProgressList = [];
                for (var j = 0; j < message.readingProgressList.length; ++j)
                    object.readingProgressList[j] = $root.query_recursive_types.SeriesReadingProgressResponse.toObject(message.readingProgressList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this SeriesReadingProgressCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesReadingProgressCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesReadingProgressCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesReadingProgressCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesReadingProgressCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesReadingProgressCursorResponse";
        };

        return SeriesReadingProgressCursorResponse;
    })();

    query_recursive_types.UserRequest = (function() {

        /**
         * Properties of a UserRequest.
         * @memberof query_recursive_types
         * @interface IUserRequest
         * @property {google.protobuf.IStringValue|null} [id] UserRequest id
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] UserRequest requireUser
         */

        /**
         * Constructs a new UserRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a UserRequest.
         * @implements IUserRequest
         * @constructor
         * @param {query_recursive_types.IUserRequest=} [properties] Properties to set
         */
        function UserRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.UserRequest
         * @instance
         */
        UserRequest.prototype.id = null;

        /**
         * UserRequest requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.UserRequest
         * @instance
         */
        UserRequest.prototype.requireUser = null;

        /**
         * Creates a new UserRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UserRequest
         * @static
         * @param {query_recursive_types.IUserRequest=} [properties] Properties to set
         * @returns {query_recursive_types.UserRequest} UserRequest instance
         */
        UserRequest.create = function create(properties) {
            return new UserRequest(properties);
        };

        /**
         * Encodes the specified UserRequest message. Does not implicitly {@link query_recursive_types.UserRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UserRequest
         * @static
         * @param {query_recursive_types.IUserRequest} message UserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UserRequest} UserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UserRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UserRequest} UserRequest
         */
        UserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UserRequest)
                return object;
            var message = new $root.query_recursive_types.UserRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.UserRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.UserRequest.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UserRequest
         * @static
         * @param {query_recursive_types.UserRequest} message UserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireUser = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            return object;
        };

        /**
         * Converts this UserRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.UserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UserRequest";
        };

        return UserRequest;
    })();

    query_recursive_types.UserRequireField = (function() {

        /**
         * Properties of a UserRequireField.
         * @memberof query_recursive_types
         * @interface IUserRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] UserRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireName] UserRequireField requireName
         * @property {google.protobuf.IBoolValue|null} [requireProfile] UserRequireField requireProfile
         * @property {query_recursive_types.IImageRequireField|null} [requireThumbnail] UserRequireField requireThumbnail
         * @property {query_recursive_types.IImageRequireField|null} [requireCoverImage] UserRequireField requireCoverImage
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] UserRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] UserRequireField requireUpdatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUserRole] UserRequireField requireUserRole
         * @property {query_ticket.IUserTicketStatusRequireField|null} [requireTicketStatus] UserRequireField requireTicketStatus
         * @property {query_bonus_ticket.IBonusTicketAvailabilityRequireField|null} [requireBonusTicketAvailability] UserRequireField requireBonusTicketAvailability
         * @property {query_recursive_types.IUserCursorRequest|null} [requireFollowee] UserRequireField requireFollowee
         * @property {query_recursive_types.IUserCursorRequest|null} [requireFollower] UserRequireField requireFollower
         * @property {google.protobuf.IBoolValue|null} [requireSearchableStoryCount] UserRequireField requireSearchableStoryCount
         * @property {query_recursive_types.ISearchStoryRequest|null} [requireSearchStory] UserRequireField requireSearchStory
         * @property {query_recursive_types.ISearchSeriesRequest|null} [requireSearchSeries] UserRequireField requireSearchSeries
         * @property {query_recursive_types.ISearchSeriesGroupRequest|null} [requireSearchSeriesGroup] UserRequireField requireSearchSeriesGroup
         * @property {query_recursive_types.IStoryReadingProgressCursorRequest|null} [requireStoryReadingProgressPage] UserRequireField requireStoryReadingProgressPage
         * @property {query_recursive_types.ISeriesReadingProgressCursorRequest|null} [requireSeriesReadingProgressPage] UserRequireField requireSeriesReadingProgressPage
         * @property {query_recursive_types.IBookmarkItemCursorRequest|null} [requireBookmarkItemPage] UserRequireField requireBookmarkItemPage
         * @property {google.protobuf.IBoolValue|null} [requireIsFollowedByMe] UserRequireField requireIsFollowedByMe
         * @property {google.protobuf.IBoolValue|null} [requireIsFollowingMe] UserRequireField requireIsFollowingMe
         * @property {query_recursive_types.IProfilingRequest|null} [requireProfiling] UserRequireField requireProfiling
         * @property {google.protobuf.IBoolValue|null} [requireNotOfficialFollowingTagList] UserRequireField requireNotOfficialFollowingTagList
         * @property {google.protobuf.IBoolValue|null} [requireNotOfficialRecommendationTagList] UserRequireField requireNotOfficialRecommendationTagList
         * @property {query_recursive_types.IMedalCursorRequest|null} [requireMedalPage] UserRequireField requireMedalPage
         * @property {query_recursive_types.IImageCursorRequest|null} [requireImagePage] UserRequireField requireImagePage
         * @property {query_recursive_types.INotificationCursorRequest|null} [requireNotificationPage] UserRequireField requireNotificationPage
         * @property {query_inspection.IUserPenaltyRequest|null} [requirePenalty] UserRequireField requirePenalty
         * @property {google.protobuf.IBoolValue|null} [requireVipMembership] UserRequireField requireVipMembership
         * @property {google.protobuf.IBoolValue|null} [requireVipStatus] UserRequireField requireVipStatus
         * @property {query_recursive_types.IStoryCursorRequest|null} [requireNotOfficialRecommendedStoryPage] UserRequireField requireNotOfficialRecommendedStoryPage
         * @property {query_recursive_types.IUserCursorRequest|null} [requireOfficialRecommendedUserPage] UserRequireField requireOfficialRecommendedUserPage
         * @property {query_recursive_types.IUserCursorRequest|null} [requireNotOfficialRecommendedUserPage] UserRequireField requireNotOfficialRecommendedUserPage
         * @property {query_recursive_types.ISearchStoryRequest|null} [requireSearchRecommendedStory] UserRequireField requireSearchRecommendedStory
         * @property {query_recursive_types.IStoryCursorRequest|null} [requireOfficialRecommendedStoryPage] UserRequireField requireOfficialRecommendedStoryPage
         */

        /**
         * Constructs a new UserRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a UserRequireField.
         * @implements IUserRequireField
         * @constructor
         * @param {query_recursive_types.IUserRequireField=} [properties] Properties to set
         */
        function UserRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireId = null;

        /**
         * UserRequireField requireName.
         * @member {google.protobuf.IBoolValue|null|undefined} requireName
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireName = null;

        /**
         * UserRequireField requireProfile.
         * @member {google.protobuf.IBoolValue|null|undefined} requireProfile
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireProfile = null;

        /**
         * UserRequireField requireThumbnail.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireThumbnail
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireThumbnail = null;

        /**
         * UserRequireField requireCoverImage.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireCoverImage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireCoverImage = null;

        /**
         * UserRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireCreatedAt = null;

        /**
         * UserRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireUpdatedAt = null;

        /**
         * UserRequireField requireUserRole.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUserRole
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireUserRole = null;

        /**
         * UserRequireField requireTicketStatus.
         * @member {query_ticket.IUserTicketStatusRequireField|null|undefined} requireTicketStatus
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireTicketStatus = null;

        /**
         * UserRequireField requireBonusTicketAvailability.
         * @member {query_bonus_ticket.IBonusTicketAvailabilityRequireField|null|undefined} requireBonusTicketAvailability
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireBonusTicketAvailability = null;

        /**
         * UserRequireField requireFollowee.
         * @member {query_recursive_types.IUserCursorRequest|null|undefined} requireFollowee
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireFollowee = null;

        /**
         * UserRequireField requireFollower.
         * @member {query_recursive_types.IUserCursorRequest|null|undefined} requireFollower
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireFollower = null;

        /**
         * UserRequireField requireSearchableStoryCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSearchableStoryCount
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireSearchableStoryCount = null;

        /**
         * UserRequireField requireSearchStory.
         * @member {query_recursive_types.ISearchStoryRequest|null|undefined} requireSearchStory
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireSearchStory = null;

        /**
         * UserRequireField requireSearchSeries.
         * @member {query_recursive_types.ISearchSeriesRequest|null|undefined} requireSearchSeries
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireSearchSeries = null;

        /**
         * UserRequireField requireSearchSeriesGroup.
         * @member {query_recursive_types.ISearchSeriesGroupRequest|null|undefined} requireSearchSeriesGroup
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireSearchSeriesGroup = null;

        /**
         * UserRequireField requireStoryReadingProgressPage.
         * @member {query_recursive_types.IStoryReadingProgressCursorRequest|null|undefined} requireStoryReadingProgressPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireStoryReadingProgressPage = null;

        /**
         * UserRequireField requireSeriesReadingProgressPage.
         * @member {query_recursive_types.ISeriesReadingProgressCursorRequest|null|undefined} requireSeriesReadingProgressPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireSeriesReadingProgressPage = null;

        /**
         * UserRequireField requireBookmarkItemPage.
         * @member {query_recursive_types.IBookmarkItemCursorRequest|null|undefined} requireBookmarkItemPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireBookmarkItemPage = null;

        /**
         * UserRequireField requireIsFollowedByMe.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsFollowedByMe
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireIsFollowedByMe = null;

        /**
         * UserRequireField requireIsFollowingMe.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsFollowingMe
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireIsFollowingMe = null;

        /**
         * UserRequireField requireProfiling.
         * @member {query_recursive_types.IProfilingRequest|null|undefined} requireProfiling
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireProfiling = null;

        /**
         * UserRequireField requireNotOfficialFollowingTagList.
         * @member {google.protobuf.IBoolValue|null|undefined} requireNotOfficialFollowingTagList
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireNotOfficialFollowingTagList = null;

        /**
         * UserRequireField requireNotOfficialRecommendationTagList.
         * @member {google.protobuf.IBoolValue|null|undefined} requireNotOfficialRecommendationTagList
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireNotOfficialRecommendationTagList = null;

        /**
         * UserRequireField requireMedalPage.
         * @member {query_recursive_types.IMedalCursorRequest|null|undefined} requireMedalPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireMedalPage = null;

        /**
         * UserRequireField requireImagePage.
         * @member {query_recursive_types.IImageCursorRequest|null|undefined} requireImagePage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireImagePage = null;

        /**
         * UserRequireField requireNotificationPage.
         * @member {query_recursive_types.INotificationCursorRequest|null|undefined} requireNotificationPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireNotificationPage = null;

        /**
         * UserRequireField requirePenalty.
         * @member {query_inspection.IUserPenaltyRequest|null|undefined} requirePenalty
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requirePenalty = null;

        /**
         * UserRequireField requireVipMembership.
         * @member {google.protobuf.IBoolValue|null|undefined} requireVipMembership
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireVipMembership = null;

        /**
         * UserRequireField requireVipStatus.
         * @member {google.protobuf.IBoolValue|null|undefined} requireVipStatus
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireVipStatus = null;

        /**
         * UserRequireField requireNotOfficialRecommendedStoryPage.
         * @member {query_recursive_types.IStoryCursorRequest|null|undefined} requireNotOfficialRecommendedStoryPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireNotOfficialRecommendedStoryPage = null;

        /**
         * UserRequireField requireOfficialRecommendedUserPage.
         * @member {query_recursive_types.IUserCursorRequest|null|undefined} requireOfficialRecommendedUserPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireOfficialRecommendedUserPage = null;

        /**
         * UserRequireField requireNotOfficialRecommendedUserPage.
         * @member {query_recursive_types.IUserCursorRequest|null|undefined} requireNotOfficialRecommendedUserPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireNotOfficialRecommendedUserPage = null;

        /**
         * UserRequireField requireSearchRecommendedStory.
         * @member {query_recursive_types.ISearchStoryRequest|null|undefined} requireSearchRecommendedStory
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireSearchRecommendedStory = null;

        /**
         * UserRequireField requireOfficialRecommendedStoryPage.
         * @member {query_recursive_types.IStoryCursorRequest|null|undefined} requireOfficialRecommendedStoryPage
         * @memberof query_recursive_types.UserRequireField
         * @instance
         */
        UserRequireField.prototype.requireOfficialRecommendedStoryPage = null;

        /**
         * Creates a new UserRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UserRequireField
         * @static
         * @param {query_recursive_types.IUserRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.UserRequireField} UserRequireField instance
         */
        UserRequireField.create = function create(properties) {
            return new UserRequireField(properties);
        };

        /**
         * Encodes the specified UserRequireField message. Does not implicitly {@link query_recursive_types.UserRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UserRequireField
         * @static
         * @param {query_recursive_types.IUserRequireField} message UserRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireName != null && Object.hasOwnProperty.call(message, "requireName"))
                $root.google.protobuf.BoolValue.encode(message.requireName, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireProfile != null && Object.hasOwnProperty.call(message, "requireProfile"))
                $root.google.protobuf.BoolValue.encode(message.requireProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireThumbnail != null && Object.hasOwnProperty.call(message, "requireThumbnail"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireThumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireCoverImage != null && Object.hasOwnProperty.call(message, "requireCoverImage"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireCoverImage, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireUserRole != null && Object.hasOwnProperty.call(message, "requireUserRole"))
                $root.google.protobuf.BoolValue.encode(message.requireUserRole, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.requireVipStatus != null && Object.hasOwnProperty.call(message, "requireVipStatus"))
                $root.google.protobuf.BoolValue.encode(message.requireVipStatus, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.requireTicketStatus != null && Object.hasOwnProperty.call(message, "requireTicketStatus"))
                $root.query_ticket.UserTicketStatusRequireField.encode(message.requireTicketStatus, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.requireBonusTicketAvailability != null && Object.hasOwnProperty.call(message, "requireBonusTicketAvailability"))
                $root.query_bonus_ticket.BonusTicketAvailabilityRequireField.encode(message.requireBonusTicketAvailability, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.requireFollowee != null && Object.hasOwnProperty.call(message, "requireFollowee"))
                $root.query_recursive_types.UserCursorRequest.encode(message.requireFollowee, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.requireFollower != null && Object.hasOwnProperty.call(message, "requireFollower"))
                $root.query_recursive_types.UserCursorRequest.encode(message.requireFollower, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.requireSearchableStoryCount != null && Object.hasOwnProperty.call(message, "requireSearchableStoryCount"))
                $root.google.protobuf.BoolValue.encode(message.requireSearchableStoryCount, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.requireSearchStory != null && Object.hasOwnProperty.call(message, "requireSearchStory"))
                $root.query_recursive_types.SearchStoryRequest.encode(message.requireSearchStory, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.requireSearchSeries != null && Object.hasOwnProperty.call(message, "requireSearchSeries"))
                $root.query_recursive_types.SearchSeriesRequest.encode(message.requireSearchSeries, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.requireSearchSeriesGroup != null && Object.hasOwnProperty.call(message, "requireSearchSeriesGroup"))
                $root.query_recursive_types.SearchSeriesGroupRequest.encode(message.requireSearchSeriesGroup, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.requireStoryReadingProgressPage != null && Object.hasOwnProperty.call(message, "requireStoryReadingProgressPage"))
                $root.query_recursive_types.StoryReadingProgressCursorRequest.encode(message.requireStoryReadingProgressPage, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.requireSeriesReadingProgressPage != null && Object.hasOwnProperty.call(message, "requireSeriesReadingProgressPage"))
                $root.query_recursive_types.SeriesReadingProgressCursorRequest.encode(message.requireSeriesReadingProgressPage, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.requireBookmarkItemPage != null && Object.hasOwnProperty.call(message, "requireBookmarkItemPage"))
                $root.query_recursive_types.BookmarkItemCursorRequest.encode(message.requireBookmarkItemPage, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.requireOfficialRecommendedStoryPage != null && Object.hasOwnProperty.call(message, "requireOfficialRecommendedStoryPage"))
                $root.query_recursive_types.StoryCursorRequest.encode(message.requireOfficialRecommendedStoryPage, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.requireNotOfficialRecommendedStoryPage != null && Object.hasOwnProperty.call(message, "requireNotOfficialRecommendedStoryPage"))
                $root.query_recursive_types.StoryCursorRequest.encode(message.requireNotOfficialRecommendedStoryPage, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.requireOfficialRecommendedUserPage != null && Object.hasOwnProperty.call(message, "requireOfficialRecommendedUserPage"))
                $root.query_recursive_types.UserCursorRequest.encode(message.requireOfficialRecommendedUserPage, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.requireNotOfficialRecommendedUserPage != null && Object.hasOwnProperty.call(message, "requireNotOfficialRecommendedUserPage"))
                $root.query_recursive_types.UserCursorRequest.encode(message.requireNotOfficialRecommendedUserPage, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.requireIsFollowedByMe != null && Object.hasOwnProperty.call(message, "requireIsFollowedByMe"))
                $root.google.protobuf.BoolValue.encode(message.requireIsFollowedByMe, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.requireIsFollowingMe != null && Object.hasOwnProperty.call(message, "requireIsFollowingMe"))
                $root.google.protobuf.BoolValue.encode(message.requireIsFollowingMe, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.requireProfiling != null && Object.hasOwnProperty.call(message, "requireProfiling"))
                $root.query_recursive_types.ProfilingRequest.encode(message.requireProfiling, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.requireNotOfficialFollowingTagList != null && Object.hasOwnProperty.call(message, "requireNotOfficialFollowingTagList"))
                $root.google.protobuf.BoolValue.encode(message.requireNotOfficialFollowingTagList, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.requireNotOfficialRecommendationTagList != null && Object.hasOwnProperty.call(message, "requireNotOfficialRecommendationTagList"))
                $root.google.protobuf.BoolValue.encode(message.requireNotOfficialRecommendationTagList, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.requireMedalPage != null && Object.hasOwnProperty.call(message, "requireMedalPage"))
                $root.query_recursive_types.MedalCursorRequest.encode(message.requireMedalPage, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.requireSearchRecommendedStory != null && Object.hasOwnProperty.call(message, "requireSearchRecommendedStory"))
                $root.query_recursive_types.SearchStoryRequest.encode(message.requireSearchRecommendedStory, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.requireImagePage != null && Object.hasOwnProperty.call(message, "requireImagePage"))
                $root.query_recursive_types.ImageCursorRequest.encode(message.requireImagePage, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.requireNotificationPage != null && Object.hasOwnProperty.call(message, "requireNotificationPage"))
                $root.query_recursive_types.NotificationCursorRequest.encode(message.requireNotificationPage, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.requirePenalty != null && Object.hasOwnProperty.call(message, "requirePenalty"))
                $root.query_inspection.UserPenaltyRequest.encode(message.requirePenalty, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.requireVipMembership != null && Object.hasOwnProperty.call(message, "requireVipMembership"))
                $root.google.protobuf.BoolValue.encode(message.requireVipMembership, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UserRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UserRequireField} UserRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UserRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireName = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireProfile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireThumbnail = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireCoverImage = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireUserRole = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.requireTicketStatus = $root.query_ticket.UserTicketStatusRequireField.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.requireBonusTicketAvailability = $root.query_bonus_ticket.BonusTicketAvailabilityRequireField.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.requireFollowee = $root.query_recursive_types.UserCursorRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.requireFollower = $root.query_recursive_types.UserCursorRequest.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.requireSearchableStoryCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.requireSearchStory = $root.query_recursive_types.SearchStoryRequest.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.requireSearchSeries = $root.query_recursive_types.SearchSeriesRequest.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.requireSearchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupRequest.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.requireStoryReadingProgressPage = $root.query_recursive_types.StoryReadingProgressCursorRequest.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.requireSeriesReadingProgressPage = $root.query_recursive_types.SeriesReadingProgressCursorRequest.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.requireBookmarkItemPage = $root.query_recursive_types.BookmarkItemCursorRequest.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.requireIsFollowedByMe = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.requireIsFollowingMe = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.requireProfiling = $root.query_recursive_types.ProfilingRequest.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.requireNotOfficialFollowingTagList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.requireNotOfficialRecommendationTagList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.requireMedalPage = $root.query_recursive_types.MedalCursorRequest.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.requireImagePage = $root.query_recursive_types.ImageCursorRequest.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.requireNotificationPage = $root.query_recursive_types.NotificationCursorRequest.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.requirePenalty = $root.query_inspection.UserPenaltyRequest.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.requireVipMembership = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.requireVipStatus = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.requireNotOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorRequest.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.requireOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorRequest.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.requireNotOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorRequest.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.requireSearchRecommendedStory = $root.query_recursive_types.SearchStoryRequest.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.requireOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UserRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UserRequireField} UserRequireField
         */
        UserRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UserRequireField)
                return object;
            var message = new $root.query_recursive_types.UserRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireName != null) {
                if (typeof object.requireName !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireName: object expected");
                message.requireName = $root.google.protobuf.BoolValue.fromObject(object.requireName);
            }
            if (object.requireProfile != null) {
                if (typeof object.requireProfile !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireProfile: object expected");
                message.requireProfile = $root.google.protobuf.BoolValue.fromObject(object.requireProfile);
            }
            if (object.requireThumbnail != null) {
                if (typeof object.requireThumbnail !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireThumbnail: object expected");
                message.requireThumbnail = $root.query_recursive_types.ImageRequireField.fromObject(object.requireThumbnail);
            }
            if (object.requireCoverImage != null) {
                if (typeof object.requireCoverImage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireCoverImage: object expected");
                message.requireCoverImage = $root.query_recursive_types.ImageRequireField.fromObject(object.requireCoverImage);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requireUserRole != null) {
                if (typeof object.requireUserRole !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireUserRole: object expected");
                message.requireUserRole = $root.google.protobuf.BoolValue.fromObject(object.requireUserRole);
            }
            if (object.requireTicketStatus != null) {
                if (typeof object.requireTicketStatus !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireTicketStatus: object expected");
                message.requireTicketStatus = $root.query_ticket.UserTicketStatusRequireField.fromObject(object.requireTicketStatus);
            }
            if (object.requireBonusTicketAvailability != null) {
                if (typeof object.requireBonusTicketAvailability !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireBonusTicketAvailability: object expected");
                message.requireBonusTicketAvailability = $root.query_bonus_ticket.BonusTicketAvailabilityRequireField.fromObject(object.requireBonusTicketAvailability);
            }
            if (object.requireFollowee != null) {
                if (typeof object.requireFollowee !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireFollowee: object expected");
                message.requireFollowee = $root.query_recursive_types.UserCursorRequest.fromObject(object.requireFollowee);
            }
            if (object.requireFollower != null) {
                if (typeof object.requireFollower !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireFollower: object expected");
                message.requireFollower = $root.query_recursive_types.UserCursorRequest.fromObject(object.requireFollower);
            }
            if (object.requireSearchableStoryCount != null) {
                if (typeof object.requireSearchableStoryCount !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireSearchableStoryCount: object expected");
                message.requireSearchableStoryCount = $root.google.protobuf.BoolValue.fromObject(object.requireSearchableStoryCount);
            }
            if (object.requireSearchStory != null) {
                if (typeof object.requireSearchStory !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireSearchStory: object expected");
                message.requireSearchStory = $root.query_recursive_types.SearchStoryRequest.fromObject(object.requireSearchStory);
            }
            if (object.requireSearchSeries != null) {
                if (typeof object.requireSearchSeries !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireSearchSeries: object expected");
                message.requireSearchSeries = $root.query_recursive_types.SearchSeriesRequest.fromObject(object.requireSearchSeries);
            }
            if (object.requireSearchSeriesGroup != null) {
                if (typeof object.requireSearchSeriesGroup !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireSearchSeriesGroup: object expected");
                message.requireSearchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupRequest.fromObject(object.requireSearchSeriesGroup);
            }
            if (object.requireStoryReadingProgressPage != null) {
                if (typeof object.requireStoryReadingProgressPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireStoryReadingProgressPage: object expected");
                message.requireStoryReadingProgressPage = $root.query_recursive_types.StoryReadingProgressCursorRequest.fromObject(object.requireStoryReadingProgressPage);
            }
            if (object.requireSeriesReadingProgressPage != null) {
                if (typeof object.requireSeriesReadingProgressPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireSeriesReadingProgressPage: object expected");
                message.requireSeriesReadingProgressPage = $root.query_recursive_types.SeriesReadingProgressCursorRequest.fromObject(object.requireSeriesReadingProgressPage);
            }
            if (object.requireBookmarkItemPage != null) {
                if (typeof object.requireBookmarkItemPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireBookmarkItemPage: object expected");
                message.requireBookmarkItemPage = $root.query_recursive_types.BookmarkItemCursorRequest.fromObject(object.requireBookmarkItemPage);
            }
            if (object.requireIsFollowedByMe != null) {
                if (typeof object.requireIsFollowedByMe !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireIsFollowedByMe: object expected");
                message.requireIsFollowedByMe = $root.google.protobuf.BoolValue.fromObject(object.requireIsFollowedByMe);
            }
            if (object.requireIsFollowingMe != null) {
                if (typeof object.requireIsFollowingMe !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireIsFollowingMe: object expected");
                message.requireIsFollowingMe = $root.google.protobuf.BoolValue.fromObject(object.requireIsFollowingMe);
            }
            if (object.requireProfiling != null) {
                if (typeof object.requireProfiling !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireProfiling: object expected");
                message.requireProfiling = $root.query_recursive_types.ProfilingRequest.fromObject(object.requireProfiling);
            }
            if (object.requireNotOfficialFollowingTagList != null) {
                if (typeof object.requireNotOfficialFollowingTagList !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireNotOfficialFollowingTagList: object expected");
                message.requireNotOfficialFollowingTagList = $root.google.protobuf.BoolValue.fromObject(object.requireNotOfficialFollowingTagList);
            }
            if (object.requireNotOfficialRecommendationTagList != null) {
                if (typeof object.requireNotOfficialRecommendationTagList !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireNotOfficialRecommendationTagList: object expected");
                message.requireNotOfficialRecommendationTagList = $root.google.protobuf.BoolValue.fromObject(object.requireNotOfficialRecommendationTagList);
            }
            if (object.requireMedalPage != null) {
                if (typeof object.requireMedalPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireMedalPage: object expected");
                message.requireMedalPage = $root.query_recursive_types.MedalCursorRequest.fromObject(object.requireMedalPage);
            }
            if (object.requireImagePage != null) {
                if (typeof object.requireImagePage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireImagePage: object expected");
                message.requireImagePage = $root.query_recursive_types.ImageCursorRequest.fromObject(object.requireImagePage);
            }
            if (object.requireNotificationPage != null) {
                if (typeof object.requireNotificationPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireNotificationPage: object expected");
                message.requireNotificationPage = $root.query_recursive_types.NotificationCursorRequest.fromObject(object.requireNotificationPage);
            }
            if (object.requirePenalty != null) {
                if (typeof object.requirePenalty !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requirePenalty: object expected");
                message.requirePenalty = $root.query_inspection.UserPenaltyRequest.fromObject(object.requirePenalty);
            }
            if (object.requireVipMembership != null) {
                if (typeof object.requireVipMembership !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireVipMembership: object expected");
                message.requireVipMembership = $root.google.protobuf.BoolValue.fromObject(object.requireVipMembership);
            }
            if (object.requireVipStatus != null) {
                if (typeof object.requireVipStatus !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireVipStatus: object expected");
                message.requireVipStatus = $root.google.protobuf.BoolValue.fromObject(object.requireVipStatus);
            }
            if (object.requireNotOfficialRecommendedStoryPage != null) {
                if (typeof object.requireNotOfficialRecommendedStoryPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireNotOfficialRecommendedStoryPage: object expected");
                message.requireNotOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorRequest.fromObject(object.requireNotOfficialRecommendedStoryPage);
            }
            if (object.requireOfficialRecommendedUserPage != null) {
                if (typeof object.requireOfficialRecommendedUserPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireOfficialRecommendedUserPage: object expected");
                message.requireOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorRequest.fromObject(object.requireOfficialRecommendedUserPage);
            }
            if (object.requireNotOfficialRecommendedUserPage != null) {
                if (typeof object.requireNotOfficialRecommendedUserPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireNotOfficialRecommendedUserPage: object expected");
                message.requireNotOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorRequest.fromObject(object.requireNotOfficialRecommendedUserPage);
            }
            if (object.requireSearchRecommendedStory != null) {
                if (typeof object.requireSearchRecommendedStory !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireSearchRecommendedStory: object expected");
                message.requireSearchRecommendedStory = $root.query_recursive_types.SearchStoryRequest.fromObject(object.requireSearchRecommendedStory);
            }
            if (object.requireOfficialRecommendedStoryPage != null) {
                if (typeof object.requireOfficialRecommendedStoryPage !== "object")
                    throw TypeError(".query_recursive_types.UserRequireField.requireOfficialRecommendedStoryPage: object expected");
                message.requireOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorRequest.fromObject(object.requireOfficialRecommendedStoryPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UserRequireField
         * @static
         * @param {query_recursive_types.UserRequireField} message UserRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireName = null;
                object.requireProfile = null;
                object.requireThumbnail = null;
                object.requireCoverImage = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
                object.requireUserRole = null;
                object.requireVipStatus = null;
                object.requireTicketStatus = null;
                object.requireBonusTicketAvailability = null;
                object.requireFollowee = null;
                object.requireFollower = null;
                object.requireSearchableStoryCount = null;
                object.requireSearchStory = null;
                object.requireSearchSeries = null;
                object.requireSearchSeriesGroup = null;
                object.requireStoryReadingProgressPage = null;
                object.requireSeriesReadingProgressPage = null;
                object.requireBookmarkItemPage = null;
                object.requireOfficialRecommendedStoryPage = null;
                object.requireNotOfficialRecommendedStoryPage = null;
                object.requireOfficialRecommendedUserPage = null;
                object.requireNotOfficialRecommendedUserPage = null;
                object.requireIsFollowedByMe = null;
                object.requireIsFollowingMe = null;
                object.requireProfiling = null;
                object.requireNotOfficialFollowingTagList = null;
                object.requireNotOfficialRecommendationTagList = null;
                object.requireMedalPage = null;
                object.requireSearchRecommendedStory = null;
                object.requireImagePage = null;
                object.requireNotificationPage = null;
                object.requirePenalty = null;
                object.requireVipMembership = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireName != null && message.hasOwnProperty("requireName"))
                object.requireName = $root.google.protobuf.BoolValue.toObject(message.requireName, options);
            if (message.requireProfile != null && message.hasOwnProperty("requireProfile"))
                object.requireProfile = $root.google.protobuf.BoolValue.toObject(message.requireProfile, options);
            if (message.requireThumbnail != null && message.hasOwnProperty("requireThumbnail"))
                object.requireThumbnail = $root.query_recursive_types.ImageRequireField.toObject(message.requireThumbnail, options);
            if (message.requireCoverImage != null && message.hasOwnProperty("requireCoverImage"))
                object.requireCoverImage = $root.query_recursive_types.ImageRequireField.toObject(message.requireCoverImage, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            if (message.requireUserRole != null && message.hasOwnProperty("requireUserRole"))
                object.requireUserRole = $root.google.protobuf.BoolValue.toObject(message.requireUserRole, options);
            if (message.requireVipStatus != null && message.hasOwnProperty("requireVipStatus"))
                object.requireVipStatus = $root.google.protobuf.BoolValue.toObject(message.requireVipStatus, options);
            if (message.requireTicketStatus != null && message.hasOwnProperty("requireTicketStatus"))
                object.requireTicketStatus = $root.query_ticket.UserTicketStatusRequireField.toObject(message.requireTicketStatus, options);
            if (message.requireBonusTicketAvailability != null && message.hasOwnProperty("requireBonusTicketAvailability"))
                object.requireBonusTicketAvailability = $root.query_bonus_ticket.BonusTicketAvailabilityRequireField.toObject(message.requireBonusTicketAvailability, options);
            if (message.requireFollowee != null && message.hasOwnProperty("requireFollowee"))
                object.requireFollowee = $root.query_recursive_types.UserCursorRequest.toObject(message.requireFollowee, options);
            if (message.requireFollower != null && message.hasOwnProperty("requireFollower"))
                object.requireFollower = $root.query_recursive_types.UserCursorRequest.toObject(message.requireFollower, options);
            if (message.requireSearchableStoryCount != null && message.hasOwnProperty("requireSearchableStoryCount"))
                object.requireSearchableStoryCount = $root.google.protobuf.BoolValue.toObject(message.requireSearchableStoryCount, options);
            if (message.requireSearchStory != null && message.hasOwnProperty("requireSearchStory"))
                object.requireSearchStory = $root.query_recursive_types.SearchStoryRequest.toObject(message.requireSearchStory, options);
            if (message.requireSearchSeries != null && message.hasOwnProperty("requireSearchSeries"))
                object.requireSearchSeries = $root.query_recursive_types.SearchSeriesRequest.toObject(message.requireSearchSeries, options);
            if (message.requireSearchSeriesGroup != null && message.hasOwnProperty("requireSearchSeriesGroup"))
                object.requireSearchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupRequest.toObject(message.requireSearchSeriesGroup, options);
            if (message.requireStoryReadingProgressPage != null && message.hasOwnProperty("requireStoryReadingProgressPage"))
                object.requireStoryReadingProgressPage = $root.query_recursive_types.StoryReadingProgressCursorRequest.toObject(message.requireStoryReadingProgressPage, options);
            if (message.requireSeriesReadingProgressPage != null && message.hasOwnProperty("requireSeriesReadingProgressPage"))
                object.requireSeriesReadingProgressPage = $root.query_recursive_types.SeriesReadingProgressCursorRequest.toObject(message.requireSeriesReadingProgressPage, options);
            if (message.requireBookmarkItemPage != null && message.hasOwnProperty("requireBookmarkItemPage"))
                object.requireBookmarkItemPage = $root.query_recursive_types.BookmarkItemCursorRequest.toObject(message.requireBookmarkItemPage, options);
            if (message.requireOfficialRecommendedStoryPage != null && message.hasOwnProperty("requireOfficialRecommendedStoryPage"))
                object.requireOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorRequest.toObject(message.requireOfficialRecommendedStoryPage, options);
            if (message.requireNotOfficialRecommendedStoryPage != null && message.hasOwnProperty("requireNotOfficialRecommendedStoryPage"))
                object.requireNotOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorRequest.toObject(message.requireNotOfficialRecommendedStoryPage, options);
            if (message.requireOfficialRecommendedUserPage != null && message.hasOwnProperty("requireOfficialRecommendedUserPage"))
                object.requireOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorRequest.toObject(message.requireOfficialRecommendedUserPage, options);
            if (message.requireNotOfficialRecommendedUserPage != null && message.hasOwnProperty("requireNotOfficialRecommendedUserPage"))
                object.requireNotOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorRequest.toObject(message.requireNotOfficialRecommendedUserPage, options);
            if (message.requireIsFollowedByMe != null && message.hasOwnProperty("requireIsFollowedByMe"))
                object.requireIsFollowedByMe = $root.google.protobuf.BoolValue.toObject(message.requireIsFollowedByMe, options);
            if (message.requireIsFollowingMe != null && message.hasOwnProperty("requireIsFollowingMe"))
                object.requireIsFollowingMe = $root.google.protobuf.BoolValue.toObject(message.requireIsFollowingMe, options);
            if (message.requireProfiling != null && message.hasOwnProperty("requireProfiling"))
                object.requireProfiling = $root.query_recursive_types.ProfilingRequest.toObject(message.requireProfiling, options);
            if (message.requireNotOfficialFollowingTagList != null && message.hasOwnProperty("requireNotOfficialFollowingTagList"))
                object.requireNotOfficialFollowingTagList = $root.google.protobuf.BoolValue.toObject(message.requireNotOfficialFollowingTagList, options);
            if (message.requireNotOfficialRecommendationTagList != null && message.hasOwnProperty("requireNotOfficialRecommendationTagList"))
                object.requireNotOfficialRecommendationTagList = $root.google.protobuf.BoolValue.toObject(message.requireNotOfficialRecommendationTagList, options);
            if (message.requireMedalPage != null && message.hasOwnProperty("requireMedalPage"))
                object.requireMedalPage = $root.query_recursive_types.MedalCursorRequest.toObject(message.requireMedalPage, options);
            if (message.requireSearchRecommendedStory != null && message.hasOwnProperty("requireSearchRecommendedStory"))
                object.requireSearchRecommendedStory = $root.query_recursive_types.SearchStoryRequest.toObject(message.requireSearchRecommendedStory, options);
            if (message.requireImagePage != null && message.hasOwnProperty("requireImagePage"))
                object.requireImagePage = $root.query_recursive_types.ImageCursorRequest.toObject(message.requireImagePage, options);
            if (message.requireNotificationPage != null && message.hasOwnProperty("requireNotificationPage"))
                object.requireNotificationPage = $root.query_recursive_types.NotificationCursorRequest.toObject(message.requireNotificationPage, options);
            if (message.requirePenalty != null && message.hasOwnProperty("requirePenalty"))
                object.requirePenalty = $root.query_inspection.UserPenaltyRequest.toObject(message.requirePenalty, options);
            if (message.requireVipMembership != null && message.hasOwnProperty("requireVipMembership"))
                object.requireVipMembership = $root.google.protobuf.BoolValue.toObject(message.requireVipMembership, options);
            return object;
        };

        /**
         * Converts this UserRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UserRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.UserRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UserRequireField";
        };

        return UserRequireField;
    })();

    query_recursive_types.UserResponse = (function() {

        /**
         * Properties of a UserResponse.
         * @memberof query_recursive_types
         * @interface IUserResponse
         * @property {google.protobuf.IStringValue|null} [id] UserResponse id
         * @property {google.protobuf.IStringValue|null} [name] UserResponse name
         * @property {google.protobuf.IStringValue|null} [profile] UserResponse profile
         * @property {query_recursive_types.IImageResponse|null} [thumbnail] UserResponse thumbnail
         * @property {query_recursive_types.IImageResponse|null} [coverImage] UserResponse coverImage
         * @property {google.protobuf.ITimestamp|null} [createdAt] UserResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] UserResponse updatedAt
         * @property {query_types.UserRole|null} [userRole] UserResponse userRole
         * @property {query_ticket.IUserTicketStatusResponse|null} [ticketStatus] UserResponse ticketStatus
         * @property {query_bonus_ticket.IBonusTicketAvailabilityResponse|null} [bonusTicketAvailability] UserResponse bonusTicketAvailability
         * @property {query_recursive_types.IUserCursorResponse|null} [followeePage] UserResponse followeePage
         * @property {query_recursive_types.IUserCursorResponse|null} [followerPage] UserResponse followerPage
         * @property {google.protobuf.IInt32Value|null} [searchableStoryCount] UserResponse searchableStoryCount
         * @property {query_recursive_types.ISearchStoryResponse|null} [searchStory] UserResponse searchStory
         * @property {query_recursive_types.ISearchSeriesResponse|null} [searchSeries] UserResponse searchSeries
         * @property {query_recursive_types.ISearchSeriesGroupResponse|null} [searchSeriesGroup] UserResponse searchSeriesGroup
         * @property {query_recursive_types.IStoryReadingProgressCursorResponse|null} [storyReadingProgressPage] UserResponse storyReadingProgressPage
         * @property {query_recursive_types.ISeriesReadingProgressCursorResponse|null} [seriesReadingProgressPage] UserResponse seriesReadingProgressPage
         * @property {query_recursive_types.IBookmarkItemCursorResponse|null} [bookmarkItemPage] UserResponse bookmarkItemPage
         * @property {query_recursive_types.ISearchStoryResponse|null} [searchRecommendedStory] UserResponse searchRecommendedStory
         * @property {query_recursive_types.IUserCursorResponse|null} [officialRecommendedUserPage] UserResponse officialRecommendedUserPage
         * @property {query_recursive_types.IUserCursorResponse|null} [notOfficialRecommendedUserPage] UserResponse notOfficialRecommendedUserPage
         * @property {google.protobuf.IBoolValue|null} [isFollowedByMe] UserResponse isFollowedByMe
         * @property {google.protobuf.IBoolValue|null} [isFollowingMe] UserResponse isFollowingMe
         * @property {query_recursive_types.IProfilingResponse|null} [profiling] UserResponse profiling
         * @property {Array.<google.protobuf.IStringValue>|null} [notOfficialFollowingTagList] UserResponse notOfficialFollowingTagList
         * @property {Array.<google.protobuf.IStringValue>|null} [notOfficialRecommendationTagList] UserResponse notOfficialRecommendationTagList
         * @property {query_recursive_types.IMedalCursorResponse|null} [medalPage] UserResponse medalPage
         * @property {query_recursive_types.IImageCursorResponse|null} [imagePage] UserResponse imagePage
         * @property {query_recursive_types.INotificationCursorResponse|null} [notificationPage] UserResponse notificationPage
         * @property {query_inspection.IUserPenaltyResponse|null} [penalty] UserResponse penalty
         * @property {query_types.IVipMembership|null} [vipMembership] UserResponse vipMembership
         * @property {query_types.VipStatus|null} [vipStatus] UserResponse vipStatus
         * @property {query_recursive_types.IStoryCursorResponse|null} [notOfficialRecommendedStoryPage] UserResponse notOfficialRecommendedStoryPage
         * @property {query_recursive_types.IStoryCursorResponse|null} [officialRecommendedStoryPage] UserResponse officialRecommendedStoryPage
         */

        /**
         * Constructs a new UserResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a UserResponse.
         * @implements IUserResponse
         * @constructor
         * @param {query_recursive_types.IUserResponse=} [properties] Properties to set
         */
        function UserResponse(properties) {
            this.notOfficialFollowingTagList = [];
            this.notOfficialRecommendationTagList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.id = null;

        /**
         * UserResponse name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.name = null;

        /**
         * UserResponse profile.
         * @member {google.protobuf.IStringValue|null|undefined} profile
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.profile = null;

        /**
         * UserResponse thumbnail.
         * @member {query_recursive_types.IImageResponse|null|undefined} thumbnail
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.thumbnail = null;

        /**
         * UserResponse coverImage.
         * @member {query_recursive_types.IImageResponse|null|undefined} coverImage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.coverImage = null;

        /**
         * UserResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.createdAt = null;

        /**
         * UserResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.updatedAt = null;

        /**
         * UserResponse userRole.
         * @member {query_types.UserRole} userRole
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.userRole = 0;

        /**
         * UserResponse ticketStatus.
         * @member {query_ticket.IUserTicketStatusResponse|null|undefined} ticketStatus
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.ticketStatus = null;

        /**
         * UserResponse bonusTicketAvailability.
         * @member {query_bonus_ticket.IBonusTicketAvailabilityResponse|null|undefined} bonusTicketAvailability
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.bonusTicketAvailability = null;

        /**
         * UserResponse followeePage.
         * @member {query_recursive_types.IUserCursorResponse|null|undefined} followeePage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.followeePage = null;

        /**
         * UserResponse followerPage.
         * @member {query_recursive_types.IUserCursorResponse|null|undefined} followerPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.followerPage = null;

        /**
         * UserResponse searchableStoryCount.
         * @member {google.protobuf.IInt32Value|null|undefined} searchableStoryCount
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.searchableStoryCount = null;

        /**
         * UserResponse searchStory.
         * @member {query_recursive_types.ISearchStoryResponse|null|undefined} searchStory
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.searchStory = null;

        /**
         * UserResponse searchSeries.
         * @member {query_recursive_types.ISearchSeriesResponse|null|undefined} searchSeries
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.searchSeries = null;

        /**
         * UserResponse searchSeriesGroup.
         * @member {query_recursive_types.ISearchSeriesGroupResponse|null|undefined} searchSeriesGroup
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.searchSeriesGroup = null;

        /**
         * UserResponse storyReadingProgressPage.
         * @member {query_recursive_types.IStoryReadingProgressCursorResponse|null|undefined} storyReadingProgressPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.storyReadingProgressPage = null;

        /**
         * UserResponse seriesReadingProgressPage.
         * @member {query_recursive_types.ISeriesReadingProgressCursorResponse|null|undefined} seriesReadingProgressPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.seriesReadingProgressPage = null;

        /**
         * UserResponse bookmarkItemPage.
         * @member {query_recursive_types.IBookmarkItemCursorResponse|null|undefined} bookmarkItemPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.bookmarkItemPage = null;

        /**
         * UserResponse searchRecommendedStory.
         * @member {query_recursive_types.ISearchStoryResponse|null|undefined} searchRecommendedStory
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.searchRecommendedStory = null;

        /**
         * UserResponse officialRecommendedUserPage.
         * @member {query_recursive_types.IUserCursorResponse|null|undefined} officialRecommendedUserPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.officialRecommendedUserPage = null;

        /**
         * UserResponse notOfficialRecommendedUserPage.
         * @member {query_recursive_types.IUserCursorResponse|null|undefined} notOfficialRecommendedUserPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.notOfficialRecommendedUserPage = null;

        /**
         * UserResponse isFollowedByMe.
         * @member {google.protobuf.IBoolValue|null|undefined} isFollowedByMe
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.isFollowedByMe = null;

        /**
         * UserResponse isFollowingMe.
         * @member {google.protobuf.IBoolValue|null|undefined} isFollowingMe
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.isFollowingMe = null;

        /**
         * UserResponse profiling.
         * @member {query_recursive_types.IProfilingResponse|null|undefined} profiling
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.profiling = null;

        /**
         * UserResponse notOfficialFollowingTagList.
         * @member {Array.<google.protobuf.IStringValue>} notOfficialFollowingTagList
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.notOfficialFollowingTagList = $util.emptyArray;

        /**
         * UserResponse notOfficialRecommendationTagList.
         * @member {Array.<google.protobuf.IStringValue>} notOfficialRecommendationTagList
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.notOfficialRecommendationTagList = $util.emptyArray;

        /**
         * UserResponse medalPage.
         * @member {query_recursive_types.IMedalCursorResponse|null|undefined} medalPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.medalPage = null;

        /**
         * UserResponse imagePage.
         * @member {query_recursive_types.IImageCursorResponse|null|undefined} imagePage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.imagePage = null;

        /**
         * UserResponse notificationPage.
         * @member {query_recursive_types.INotificationCursorResponse|null|undefined} notificationPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.notificationPage = null;

        /**
         * UserResponse penalty.
         * @member {query_inspection.IUserPenaltyResponse|null|undefined} penalty
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.penalty = null;

        /**
         * UserResponse vipMembership.
         * @member {query_types.IVipMembership|null|undefined} vipMembership
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.vipMembership = null;

        /**
         * UserResponse vipStatus.
         * @member {query_types.VipStatus} vipStatus
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.vipStatus = 0;

        /**
         * UserResponse notOfficialRecommendedStoryPage.
         * @member {query_recursive_types.IStoryCursorResponse|null|undefined} notOfficialRecommendedStoryPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.notOfficialRecommendedStoryPage = null;

        /**
         * UserResponse officialRecommendedStoryPage.
         * @member {query_recursive_types.IStoryCursorResponse|null|undefined} officialRecommendedStoryPage
         * @memberof query_recursive_types.UserResponse
         * @instance
         */
        UserResponse.prototype.officialRecommendedStoryPage = null;

        /**
         * Creates a new UserResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UserResponse
         * @static
         * @param {query_recursive_types.IUserResponse=} [properties] Properties to set
         * @returns {query_recursive_types.UserResponse} UserResponse instance
         */
        UserResponse.create = function create(properties) {
            return new UserResponse(properties);
        };

        /**
         * Encodes the specified UserResponse message. Does not implicitly {@link query_recursive_types.UserResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UserResponse
         * @static
         * @param {query_recursive_types.IUserResponse} message UserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.google.protobuf.StringValue.encode(message.profile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.query_recursive_types.ImageResponse.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.coverImage != null && Object.hasOwnProperty.call(message, "coverImage"))
                $root.query_recursive_types.ImageResponse.encode(message.coverImage, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.userRole != null && Object.hasOwnProperty.call(message, "userRole"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.userRole);
            if (message.vipStatus != null && Object.hasOwnProperty.call(message, "vipStatus"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.vipStatus);
            if (message.ticketStatus != null && Object.hasOwnProperty.call(message, "ticketStatus"))
                $root.query_ticket.UserTicketStatusResponse.encode(message.ticketStatus, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.bonusTicketAvailability != null && Object.hasOwnProperty.call(message, "bonusTicketAvailability"))
                $root.query_bonus_ticket.BonusTicketAvailabilityResponse.encode(message.bonusTicketAvailability, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.followeePage != null && Object.hasOwnProperty.call(message, "followeePage"))
                $root.query_recursive_types.UserCursorResponse.encode(message.followeePage, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.followerPage != null && Object.hasOwnProperty.call(message, "followerPage"))
                $root.query_recursive_types.UserCursorResponse.encode(message.followerPage, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.searchableStoryCount != null && Object.hasOwnProperty.call(message, "searchableStoryCount"))
                $root.google.protobuf.Int32Value.encode(message.searchableStoryCount, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.searchStory != null && Object.hasOwnProperty.call(message, "searchStory"))
                $root.query_recursive_types.SearchStoryResponse.encode(message.searchStory, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.searchSeries != null && Object.hasOwnProperty.call(message, "searchSeries"))
                $root.query_recursive_types.SearchSeriesResponse.encode(message.searchSeries, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.searchSeriesGroup != null && Object.hasOwnProperty.call(message, "searchSeriesGroup"))
                $root.query_recursive_types.SearchSeriesGroupResponse.encode(message.searchSeriesGroup, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.storyReadingProgressPage != null && Object.hasOwnProperty.call(message, "storyReadingProgressPage"))
                $root.query_recursive_types.StoryReadingProgressCursorResponse.encode(message.storyReadingProgressPage, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.seriesReadingProgressPage != null && Object.hasOwnProperty.call(message, "seriesReadingProgressPage"))
                $root.query_recursive_types.SeriesReadingProgressCursorResponse.encode(message.seriesReadingProgressPage, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.bookmarkItemPage != null && Object.hasOwnProperty.call(message, "bookmarkItemPage"))
                $root.query_recursive_types.BookmarkItemCursorResponse.encode(message.bookmarkItemPage, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.officialRecommendedStoryPage != null && Object.hasOwnProperty.call(message, "officialRecommendedStoryPage"))
                $root.query_recursive_types.StoryCursorResponse.encode(message.officialRecommendedStoryPage, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.notOfficialRecommendedStoryPage != null && Object.hasOwnProperty.call(message, "notOfficialRecommendedStoryPage"))
                $root.query_recursive_types.StoryCursorResponse.encode(message.notOfficialRecommendedStoryPage, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.officialRecommendedUserPage != null && Object.hasOwnProperty.call(message, "officialRecommendedUserPage"))
                $root.query_recursive_types.UserCursorResponse.encode(message.officialRecommendedUserPage, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.notOfficialRecommendedUserPage != null && Object.hasOwnProperty.call(message, "notOfficialRecommendedUserPage"))
                $root.query_recursive_types.UserCursorResponse.encode(message.notOfficialRecommendedUserPage, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.isFollowedByMe != null && Object.hasOwnProperty.call(message, "isFollowedByMe"))
                $root.google.protobuf.BoolValue.encode(message.isFollowedByMe, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.isFollowingMe != null && Object.hasOwnProperty.call(message, "isFollowingMe"))
                $root.google.protobuf.BoolValue.encode(message.isFollowingMe, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.profiling != null && Object.hasOwnProperty.call(message, "profiling"))
                $root.query_recursive_types.ProfilingResponse.encode(message.profiling, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.notOfficialFollowingTagList != null && message.notOfficialFollowingTagList.length)
                for (var i = 0; i < message.notOfficialFollowingTagList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.notOfficialFollowingTagList[i], writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.notOfficialRecommendationTagList != null && message.notOfficialRecommendationTagList.length)
                for (var i = 0; i < message.notOfficialRecommendationTagList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.notOfficialRecommendationTagList[i], writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.medalPage != null && Object.hasOwnProperty.call(message, "medalPage"))
                $root.query_recursive_types.MedalCursorResponse.encode(message.medalPage, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.searchRecommendedStory != null && Object.hasOwnProperty.call(message, "searchRecommendedStory"))
                $root.query_recursive_types.SearchStoryResponse.encode(message.searchRecommendedStory, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.imagePage != null && Object.hasOwnProperty.call(message, "imagePage"))
                $root.query_recursive_types.ImageCursorResponse.encode(message.imagePage, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.notificationPage != null && Object.hasOwnProperty.call(message, "notificationPage"))
                $root.query_recursive_types.NotificationCursorResponse.encode(message.notificationPage, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.penalty != null && Object.hasOwnProperty.call(message, "penalty"))
                $root.query_inspection.UserPenaltyResponse.encode(message.penalty, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.vipMembership != null && Object.hasOwnProperty.call(message, "vipMembership"))
                $root.query_types.VipMembership.encode(message.vipMembership, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UserResponse} UserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UserResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.profile = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.coverImage = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.userRole = reader.int32();
                    break;
                case 10:
                    message.ticketStatus = $root.query_ticket.UserTicketStatusResponse.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.bonusTicketAvailability = $root.query_bonus_ticket.BonusTicketAvailabilityResponse.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.followeePage = $root.query_recursive_types.UserCursorResponse.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.followerPage = $root.query_recursive_types.UserCursorResponse.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.searchableStoryCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.searchStory = $root.query_recursive_types.SearchStoryResponse.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.searchSeries = $root.query_recursive_types.SearchSeriesResponse.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.searchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupResponse.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.storyReadingProgressPage = $root.query_recursive_types.StoryReadingProgressCursorResponse.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.seriesReadingProgressPage = $root.query_recursive_types.SeriesReadingProgressCursorResponse.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.bookmarkItemPage = $root.query_recursive_types.BookmarkItemCursorResponse.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.searchRecommendedStory = $root.query_recursive_types.SearchStoryResponse.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.officialRecommendedUserPage = $root.query_recursive_types.UserCursorResponse.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.notOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorResponse.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.isFollowedByMe = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.isFollowingMe = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.profiling = $root.query_recursive_types.ProfilingResponse.decode(reader, reader.uint32());
                    break;
                case 28:
                    if (!(message.notOfficialFollowingTagList && message.notOfficialFollowingTagList.length))
                        message.notOfficialFollowingTagList = [];
                    message.notOfficialFollowingTagList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 29:
                    if (!(message.notOfficialRecommendationTagList && message.notOfficialRecommendationTagList.length))
                        message.notOfficialRecommendationTagList = [];
                    message.notOfficialRecommendationTagList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 30:
                    message.medalPage = $root.query_recursive_types.MedalCursorResponse.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.imagePage = $root.query_recursive_types.ImageCursorResponse.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.notificationPage = $root.query_recursive_types.NotificationCursorResponse.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.penalty = $root.query_inspection.UserPenaltyResponse.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.vipMembership = $root.query_types.VipMembership.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.vipStatus = reader.int32();
                    break;
                case 22:
                    message.notOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorResponse.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.officialRecommendedStoryPage = $root.query_recursive_types.StoryCursorResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UserResponse} UserResponse
         */
        UserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UserResponse)
                return object;
            var message = new $root.query_recursive_types.UserResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.profile != null) {
                if (typeof object.profile !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.profile: object expected");
                message.profile = $root.google.protobuf.StringValue.fromObject(object.profile);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.thumbnail: object expected");
                message.thumbnail = $root.query_recursive_types.ImageResponse.fromObject(object.thumbnail);
            }
            if (object.coverImage != null) {
                if (typeof object.coverImage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.coverImage: object expected");
                message.coverImage = $root.query_recursive_types.ImageResponse.fromObject(object.coverImage);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            switch (object.userRole) {
            case "USER_ROLE_UNSPECIFIED":
            case 0:
                message.userRole = 0;
                break;
            case "USER_ROLE_PLAIN":
            case 1:
                message.userRole = 1;
                break;
            case "USER_ROLE_OFFICIAL_WRITER":
            case 2:
                message.userRole = 2;
                break;
            case "USER_ROLE_BANNED":
            case 3:
                message.userRole = 3;
                break;
            case "USER_ROLE_ADMIN":
            case 4:
                message.userRole = 4;
                break;
            }
            if (object.ticketStatus != null) {
                if (typeof object.ticketStatus !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.ticketStatus: object expected");
                message.ticketStatus = $root.query_ticket.UserTicketStatusResponse.fromObject(object.ticketStatus);
            }
            if (object.bonusTicketAvailability != null) {
                if (typeof object.bonusTicketAvailability !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.bonusTicketAvailability: object expected");
                message.bonusTicketAvailability = $root.query_bonus_ticket.BonusTicketAvailabilityResponse.fromObject(object.bonusTicketAvailability);
            }
            if (object.followeePage != null) {
                if (typeof object.followeePage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.followeePage: object expected");
                message.followeePage = $root.query_recursive_types.UserCursorResponse.fromObject(object.followeePage);
            }
            if (object.followerPage != null) {
                if (typeof object.followerPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.followerPage: object expected");
                message.followerPage = $root.query_recursive_types.UserCursorResponse.fromObject(object.followerPage);
            }
            if (object.searchableStoryCount != null) {
                if (typeof object.searchableStoryCount !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.searchableStoryCount: object expected");
                message.searchableStoryCount = $root.google.protobuf.Int32Value.fromObject(object.searchableStoryCount);
            }
            if (object.searchStory != null) {
                if (typeof object.searchStory !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.searchStory: object expected");
                message.searchStory = $root.query_recursive_types.SearchStoryResponse.fromObject(object.searchStory);
            }
            if (object.searchSeries != null) {
                if (typeof object.searchSeries !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.searchSeries: object expected");
                message.searchSeries = $root.query_recursive_types.SearchSeriesResponse.fromObject(object.searchSeries);
            }
            if (object.searchSeriesGroup != null) {
                if (typeof object.searchSeriesGroup !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.searchSeriesGroup: object expected");
                message.searchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupResponse.fromObject(object.searchSeriesGroup);
            }
            if (object.storyReadingProgressPage != null) {
                if (typeof object.storyReadingProgressPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.storyReadingProgressPage: object expected");
                message.storyReadingProgressPage = $root.query_recursive_types.StoryReadingProgressCursorResponse.fromObject(object.storyReadingProgressPage);
            }
            if (object.seriesReadingProgressPage != null) {
                if (typeof object.seriesReadingProgressPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.seriesReadingProgressPage: object expected");
                message.seriesReadingProgressPage = $root.query_recursive_types.SeriesReadingProgressCursorResponse.fromObject(object.seriesReadingProgressPage);
            }
            if (object.bookmarkItemPage != null) {
                if (typeof object.bookmarkItemPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.bookmarkItemPage: object expected");
                message.bookmarkItemPage = $root.query_recursive_types.BookmarkItemCursorResponse.fromObject(object.bookmarkItemPage);
            }
            if (object.searchRecommendedStory != null) {
                if (typeof object.searchRecommendedStory !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.searchRecommendedStory: object expected");
                message.searchRecommendedStory = $root.query_recursive_types.SearchStoryResponse.fromObject(object.searchRecommendedStory);
            }
            if (object.officialRecommendedUserPage != null) {
                if (typeof object.officialRecommendedUserPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.officialRecommendedUserPage: object expected");
                message.officialRecommendedUserPage = $root.query_recursive_types.UserCursorResponse.fromObject(object.officialRecommendedUserPage);
            }
            if (object.notOfficialRecommendedUserPage != null) {
                if (typeof object.notOfficialRecommendedUserPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.notOfficialRecommendedUserPage: object expected");
                message.notOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorResponse.fromObject(object.notOfficialRecommendedUserPage);
            }
            if (object.isFollowedByMe != null) {
                if (typeof object.isFollowedByMe !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.isFollowedByMe: object expected");
                message.isFollowedByMe = $root.google.protobuf.BoolValue.fromObject(object.isFollowedByMe);
            }
            if (object.isFollowingMe != null) {
                if (typeof object.isFollowingMe !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.isFollowingMe: object expected");
                message.isFollowingMe = $root.google.protobuf.BoolValue.fromObject(object.isFollowingMe);
            }
            if (object.profiling != null) {
                if (typeof object.profiling !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.profiling: object expected");
                message.profiling = $root.query_recursive_types.ProfilingResponse.fromObject(object.profiling);
            }
            if (object.notOfficialFollowingTagList) {
                if (!Array.isArray(object.notOfficialFollowingTagList))
                    throw TypeError(".query_recursive_types.UserResponse.notOfficialFollowingTagList: array expected");
                message.notOfficialFollowingTagList = [];
                for (var i = 0; i < object.notOfficialFollowingTagList.length; ++i) {
                    if (typeof object.notOfficialFollowingTagList[i] !== "object")
                        throw TypeError(".query_recursive_types.UserResponse.notOfficialFollowingTagList: object expected");
                    message.notOfficialFollowingTagList[i] = $root.google.protobuf.StringValue.fromObject(object.notOfficialFollowingTagList[i]);
                }
            }
            if (object.notOfficialRecommendationTagList) {
                if (!Array.isArray(object.notOfficialRecommendationTagList))
                    throw TypeError(".query_recursive_types.UserResponse.notOfficialRecommendationTagList: array expected");
                message.notOfficialRecommendationTagList = [];
                for (var i = 0; i < object.notOfficialRecommendationTagList.length; ++i) {
                    if (typeof object.notOfficialRecommendationTagList[i] !== "object")
                        throw TypeError(".query_recursive_types.UserResponse.notOfficialRecommendationTagList: object expected");
                    message.notOfficialRecommendationTagList[i] = $root.google.protobuf.StringValue.fromObject(object.notOfficialRecommendationTagList[i]);
                }
            }
            if (object.medalPage != null) {
                if (typeof object.medalPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.medalPage: object expected");
                message.medalPage = $root.query_recursive_types.MedalCursorResponse.fromObject(object.medalPage);
            }
            if (object.imagePage != null) {
                if (typeof object.imagePage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.imagePage: object expected");
                message.imagePage = $root.query_recursive_types.ImageCursorResponse.fromObject(object.imagePage);
            }
            if (object.notificationPage != null) {
                if (typeof object.notificationPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.notificationPage: object expected");
                message.notificationPage = $root.query_recursive_types.NotificationCursorResponse.fromObject(object.notificationPage);
            }
            if (object.penalty != null) {
                if (typeof object.penalty !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.penalty: object expected");
                message.penalty = $root.query_inspection.UserPenaltyResponse.fromObject(object.penalty);
            }
            if (object.vipMembership != null) {
                if (typeof object.vipMembership !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.vipMembership: object expected");
                message.vipMembership = $root.query_types.VipMembership.fromObject(object.vipMembership);
            }
            switch (object.vipStatus) {
            case "VIP_STATUS_UNSPECIFIED":
            case 0:
                message.vipStatus = 0;
                break;
            case "VIP_STATUS_NONE":
            case 1:
                message.vipStatus = 1;
                break;
            case "VIP_STATUS_IN_TRIAL":
            case 2:
                message.vipStatus = 2;
                break;
            case "VIP_STATUS_VIP":
            case 3:
                message.vipStatus = 3;
                break;
            }
            if (object.notOfficialRecommendedStoryPage != null) {
                if (typeof object.notOfficialRecommendedStoryPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.notOfficialRecommendedStoryPage: object expected");
                message.notOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorResponse.fromObject(object.notOfficialRecommendedStoryPage);
            }
            if (object.officialRecommendedStoryPage != null) {
                if (typeof object.officialRecommendedStoryPage !== "object")
                    throw TypeError(".query_recursive_types.UserResponse.officialRecommendedStoryPage: object expected");
                message.officialRecommendedStoryPage = $root.query_recursive_types.StoryCursorResponse.fromObject(object.officialRecommendedStoryPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UserResponse
         * @static
         * @param {query_recursive_types.UserResponse} message UserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.notOfficialFollowingTagList = [];
                object.notOfficialRecommendationTagList = [];
            }
            if (options.defaults) {
                object.id = null;
                object.name = null;
                object.profile = null;
                object.thumbnail = null;
                object.coverImage = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.userRole = options.enums === String ? "USER_ROLE_UNSPECIFIED" : 0;
                object.vipStatus = options.enums === String ? "VIP_STATUS_UNSPECIFIED" : 0;
                object.ticketStatus = null;
                object.bonusTicketAvailability = null;
                object.followeePage = null;
                object.followerPage = null;
                object.searchableStoryCount = null;
                object.searchStory = null;
                object.searchSeries = null;
                object.searchSeriesGroup = null;
                object.storyReadingProgressPage = null;
                object.seriesReadingProgressPage = null;
                object.bookmarkItemPage = null;
                object.officialRecommendedStoryPage = null;
                object.notOfficialRecommendedStoryPage = null;
                object.officialRecommendedUserPage = null;
                object.notOfficialRecommendedUserPage = null;
                object.isFollowedByMe = null;
                object.isFollowingMe = null;
                object.profiling = null;
                object.medalPage = null;
                object.searchRecommendedStory = null;
                object.imagePage = null;
                object.notificationPage = null;
                object.penalty = null;
                object.vipMembership = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.profile != null && message.hasOwnProperty("profile"))
                object.profile = $root.google.protobuf.StringValue.toObject(message.profile, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.query_recursive_types.ImageResponse.toObject(message.thumbnail, options);
            if (message.coverImage != null && message.hasOwnProperty("coverImage"))
                object.coverImage = $root.query_recursive_types.ImageResponse.toObject(message.coverImage, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.userRole != null && message.hasOwnProperty("userRole"))
                object.userRole = options.enums === String ? $root.query_types.UserRole[message.userRole] : message.userRole;
            if (message.vipStatus != null && message.hasOwnProperty("vipStatus"))
                object.vipStatus = options.enums === String ? $root.query_types.VipStatus[message.vipStatus] : message.vipStatus;
            if (message.ticketStatus != null && message.hasOwnProperty("ticketStatus"))
                object.ticketStatus = $root.query_ticket.UserTicketStatusResponse.toObject(message.ticketStatus, options);
            if (message.bonusTicketAvailability != null && message.hasOwnProperty("bonusTicketAvailability"))
                object.bonusTicketAvailability = $root.query_bonus_ticket.BonusTicketAvailabilityResponse.toObject(message.bonusTicketAvailability, options);
            if (message.followeePage != null && message.hasOwnProperty("followeePage"))
                object.followeePage = $root.query_recursive_types.UserCursorResponse.toObject(message.followeePage, options);
            if (message.followerPage != null && message.hasOwnProperty("followerPage"))
                object.followerPage = $root.query_recursive_types.UserCursorResponse.toObject(message.followerPage, options);
            if (message.searchableStoryCount != null && message.hasOwnProperty("searchableStoryCount"))
                object.searchableStoryCount = $root.google.protobuf.Int32Value.toObject(message.searchableStoryCount, options);
            if (message.searchStory != null && message.hasOwnProperty("searchStory"))
                object.searchStory = $root.query_recursive_types.SearchStoryResponse.toObject(message.searchStory, options);
            if (message.searchSeries != null && message.hasOwnProperty("searchSeries"))
                object.searchSeries = $root.query_recursive_types.SearchSeriesResponse.toObject(message.searchSeries, options);
            if (message.searchSeriesGroup != null && message.hasOwnProperty("searchSeriesGroup"))
                object.searchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupResponse.toObject(message.searchSeriesGroup, options);
            if (message.storyReadingProgressPage != null && message.hasOwnProperty("storyReadingProgressPage"))
                object.storyReadingProgressPage = $root.query_recursive_types.StoryReadingProgressCursorResponse.toObject(message.storyReadingProgressPage, options);
            if (message.seriesReadingProgressPage != null && message.hasOwnProperty("seriesReadingProgressPage"))
                object.seriesReadingProgressPage = $root.query_recursive_types.SeriesReadingProgressCursorResponse.toObject(message.seriesReadingProgressPage, options);
            if (message.bookmarkItemPage != null && message.hasOwnProperty("bookmarkItemPage"))
                object.bookmarkItemPage = $root.query_recursive_types.BookmarkItemCursorResponse.toObject(message.bookmarkItemPage, options);
            if (message.officialRecommendedStoryPage != null && message.hasOwnProperty("officialRecommendedStoryPage"))
                object.officialRecommendedStoryPage = $root.query_recursive_types.StoryCursorResponse.toObject(message.officialRecommendedStoryPage, options);
            if (message.notOfficialRecommendedStoryPage != null && message.hasOwnProperty("notOfficialRecommendedStoryPage"))
                object.notOfficialRecommendedStoryPage = $root.query_recursive_types.StoryCursorResponse.toObject(message.notOfficialRecommendedStoryPage, options);
            if (message.officialRecommendedUserPage != null && message.hasOwnProperty("officialRecommendedUserPage"))
                object.officialRecommendedUserPage = $root.query_recursive_types.UserCursorResponse.toObject(message.officialRecommendedUserPage, options);
            if (message.notOfficialRecommendedUserPage != null && message.hasOwnProperty("notOfficialRecommendedUserPage"))
                object.notOfficialRecommendedUserPage = $root.query_recursive_types.UserCursorResponse.toObject(message.notOfficialRecommendedUserPage, options);
            if (message.isFollowedByMe != null && message.hasOwnProperty("isFollowedByMe"))
                object.isFollowedByMe = $root.google.protobuf.BoolValue.toObject(message.isFollowedByMe, options);
            if (message.isFollowingMe != null && message.hasOwnProperty("isFollowingMe"))
                object.isFollowingMe = $root.google.protobuf.BoolValue.toObject(message.isFollowingMe, options);
            if (message.profiling != null && message.hasOwnProperty("profiling"))
                object.profiling = $root.query_recursive_types.ProfilingResponse.toObject(message.profiling, options);
            if (message.notOfficialFollowingTagList && message.notOfficialFollowingTagList.length) {
                object.notOfficialFollowingTagList = [];
                for (var j = 0; j < message.notOfficialFollowingTagList.length; ++j)
                    object.notOfficialFollowingTagList[j] = $root.google.protobuf.StringValue.toObject(message.notOfficialFollowingTagList[j], options);
            }
            if (message.notOfficialRecommendationTagList && message.notOfficialRecommendationTagList.length) {
                object.notOfficialRecommendationTagList = [];
                for (var j = 0; j < message.notOfficialRecommendationTagList.length; ++j)
                    object.notOfficialRecommendationTagList[j] = $root.google.protobuf.StringValue.toObject(message.notOfficialRecommendationTagList[j], options);
            }
            if (message.medalPage != null && message.hasOwnProperty("medalPage"))
                object.medalPage = $root.query_recursive_types.MedalCursorResponse.toObject(message.medalPage, options);
            if (message.searchRecommendedStory != null && message.hasOwnProperty("searchRecommendedStory"))
                object.searchRecommendedStory = $root.query_recursive_types.SearchStoryResponse.toObject(message.searchRecommendedStory, options);
            if (message.imagePage != null && message.hasOwnProperty("imagePage"))
                object.imagePage = $root.query_recursive_types.ImageCursorResponse.toObject(message.imagePage, options);
            if (message.notificationPage != null && message.hasOwnProperty("notificationPage"))
                object.notificationPage = $root.query_recursive_types.NotificationCursorResponse.toObject(message.notificationPage, options);
            if (message.penalty != null && message.hasOwnProperty("penalty"))
                object.penalty = $root.query_inspection.UserPenaltyResponse.toObject(message.penalty, options);
            if (message.vipMembership != null && message.hasOwnProperty("vipMembership"))
                object.vipMembership = $root.query_types.VipMembership.toObject(message.vipMembership, options);
            return object;
        };

        /**
         * Converts this UserResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.UserResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UserResponse";
        };

        return UserResponse;
    })();

    query_recursive_types.SearchUserRequest = (function() {

        /**
         * Properties of a SearchUserRequest.
         * @memberof query_recursive_types
         * @interface ISearchUserRequest
         * @property {query_search_types.ISearchUserParams|null} [params] SearchUserRequest params
         * @property {query_recursive_types.IUserPageRequest|null} [requireUserPage] SearchUserRequest requireUserPage
         */

        /**
         * Constructs a new SearchUserRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchUserRequest.
         * @implements ISearchUserRequest
         * @constructor
         * @param {query_recursive_types.ISearchUserRequest=} [properties] Properties to set
         */
        function SearchUserRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUserRequest params.
         * @member {query_search_types.ISearchUserParams|null|undefined} params
         * @memberof query_recursive_types.SearchUserRequest
         * @instance
         */
        SearchUserRequest.prototype.params = null;

        /**
         * SearchUserRequest requireUserPage.
         * @member {query_recursive_types.IUserPageRequest|null|undefined} requireUserPage
         * @memberof query_recursive_types.SearchUserRequest
         * @instance
         */
        SearchUserRequest.prototype.requireUserPage = null;

        /**
         * Creates a new SearchUserRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchUserRequest
         * @static
         * @param {query_recursive_types.ISearchUserRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SearchUserRequest} SearchUserRequest instance
         */
        SearchUserRequest.create = function create(properties) {
            return new SearchUserRequest(properties);
        };

        /**
         * Encodes the specified SearchUserRequest message. Does not implicitly {@link query_recursive_types.SearchUserRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchUserRequest
         * @static
         * @param {query_recursive_types.ISearchUserRequest} message SearchUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                $root.query_search_types.SearchUserParams.encode(message.params, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUserPage != null && Object.hasOwnProperty.call(message, "requireUserPage"))
                $root.query_recursive_types.UserPageRequest.encode(message.requireUserPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchUserRequest} SearchUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchUserRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = $root.query_search_types.SearchUserParams.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUserPage = $root.query_recursive_types.UserPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchUserRequest} SearchUserRequest
         */
        SearchUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchUserRequest)
                return object;
            var message = new $root.query_recursive_types.SearchUserRequest();
            if (object.params != null) {
                if (typeof object.params !== "object")
                    throw TypeError(".query_recursive_types.SearchUserRequest.params: object expected");
                message.params = $root.query_search_types.SearchUserParams.fromObject(object.params);
            }
            if (object.requireUserPage != null) {
                if (typeof object.requireUserPage !== "object")
                    throw TypeError(".query_recursive_types.SearchUserRequest.requireUserPage: object expected");
                message.requireUserPage = $root.query_recursive_types.UserPageRequest.fromObject(object.requireUserPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchUserRequest
         * @static
         * @param {query_recursive_types.SearchUserRequest} message SearchUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.params = null;
                object.requireUserPage = null;
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = $root.query_search_types.SearchUserParams.toObject(message.params, options);
            if (message.requireUserPage != null && message.hasOwnProperty("requireUserPage"))
                object.requireUserPage = $root.query_recursive_types.UserPageRequest.toObject(message.requireUserPage, options);
            return object;
        };

        /**
         * Converts this SearchUserRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUserRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchUserRequest";
        };

        return SearchUserRequest;
    })();

    query_recursive_types.SearchUserResponse = (function() {

        /**
         * Properties of a SearchUserResponse.
         * @memberof query_recursive_types
         * @interface ISearchUserResponse
         * @property {query_recursive_types.IUserPageResponse|null} [userPage] SearchUserResponse userPage
         */

        /**
         * Constructs a new SearchUserResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchUserResponse.
         * @implements ISearchUserResponse
         * @constructor
         * @param {query_recursive_types.ISearchUserResponse=} [properties] Properties to set
         */
        function SearchUserResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUserResponse userPage.
         * @member {query_recursive_types.IUserPageResponse|null|undefined} userPage
         * @memberof query_recursive_types.SearchUserResponse
         * @instance
         */
        SearchUserResponse.prototype.userPage = null;

        /**
         * Creates a new SearchUserResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchUserResponse
         * @static
         * @param {query_recursive_types.ISearchUserResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SearchUserResponse} SearchUserResponse instance
         */
        SearchUserResponse.create = function create(properties) {
            return new SearchUserResponse(properties);
        };

        /**
         * Encodes the specified SearchUserResponse message. Does not implicitly {@link query_recursive_types.SearchUserResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchUserResponse
         * @static
         * @param {query_recursive_types.ISearchUserResponse} message SearchUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userPage != null && Object.hasOwnProperty.call(message, "userPage"))
                $root.query_recursive_types.UserPageResponse.encode(message.userPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchUserResponse} SearchUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchUserResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userPage = $root.query_recursive_types.UserPageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchUserResponse} SearchUserResponse
         */
        SearchUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchUserResponse)
                return object;
            var message = new $root.query_recursive_types.SearchUserResponse();
            if (object.userPage != null) {
                if (typeof object.userPage !== "object")
                    throw TypeError(".query_recursive_types.SearchUserResponse.userPage: object expected");
                message.userPage = $root.query_recursive_types.UserPageResponse.fromObject(object.userPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchUserResponse
         * @static
         * @param {query_recursive_types.SearchUserResponse} message SearchUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userPage = null;
            if (message.userPage != null && message.hasOwnProperty("userPage"))
                object.userPage = $root.query_recursive_types.UserPageResponse.toObject(message.userPage, options);
            return object;
        };

        /**
         * Converts this SearchUserResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUserResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchUserResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchUserResponse";
        };

        return SearchUserResponse;
    })();

    query_recursive_types.UserPageRequest = (function() {

        /**
         * Properties of a UserPageRequest.
         * @memberof query_recursive_types
         * @interface IUserPageRequest
         * @property {query_recursive_types.IUserRequireField|null} [requireUserList] UserPageRequest requireUserList
         * @property {query_pagination.IPageRequest|null} [page] UserPageRequest page
         */

        /**
         * Constructs a new UserPageRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a UserPageRequest.
         * @implements IUserPageRequest
         * @constructor
         * @param {query_recursive_types.IUserPageRequest=} [properties] Properties to set
         */
        function UserPageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPageRequest requireUserList.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUserList
         * @memberof query_recursive_types.UserPageRequest
         * @instance
         */
        UserPageRequest.prototype.requireUserList = null;

        /**
         * UserPageRequest page.
         * @member {query_pagination.IPageRequest|null|undefined} page
         * @memberof query_recursive_types.UserPageRequest
         * @instance
         */
        UserPageRequest.prototype.page = null;

        /**
         * Creates a new UserPageRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UserPageRequest
         * @static
         * @param {query_recursive_types.IUserPageRequest=} [properties] Properties to set
         * @returns {query_recursive_types.UserPageRequest} UserPageRequest instance
         */
        UserPageRequest.create = function create(properties) {
            return new UserPageRequest(properties);
        };

        /**
         * Encodes the specified UserPageRequest message. Does not implicitly {@link query_recursive_types.UserPageRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UserPageRequest
         * @static
         * @param {query_recursive_types.IUserPageRequest} message UserPageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireUserList != null && Object.hasOwnProperty.call(message, "requireUserList"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUserList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageRequest.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserPageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UserPageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UserPageRequest} UserPageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UserPageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireUserList = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.page = $root.query_pagination.PageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserPageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UserPageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UserPageRequest} UserPageRequest
         */
        UserPageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UserPageRequest)
                return object;
            var message = new $root.query_recursive_types.UserPageRequest();
            if (object.requireUserList != null) {
                if (typeof object.requireUserList !== "object")
                    throw TypeError(".query_recursive_types.UserPageRequest.requireUserList: object expected");
                message.requireUserList = $root.query_recursive_types.UserRequireField.fromObject(object.requireUserList);
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.UserPageRequest.page: object expected");
                message.page = $root.query_pagination.PageRequest.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserPageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UserPageRequest
         * @static
         * @param {query_recursive_types.UserPageRequest} message UserPageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireUserList = null;
                object.page = null;
            }
            if (message.requireUserList != null && message.hasOwnProperty("requireUserList"))
                object.requireUserList = $root.query_recursive_types.UserRequireField.toObject(message.requireUserList, options);
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageRequest.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this UserPageRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UserPageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserPageRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.UserPageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UserPageRequest";
        };

        return UserPageRequest;
    })();

    query_recursive_types.UserPageResponse = (function() {

        /**
         * Properties of a UserPageResponse.
         * @memberof query_recursive_types
         * @interface IUserPageResponse
         * @property {Array.<query_recursive_types.IUserResponse>|null} [userList] UserPageResponse userList
         * @property {query_pagination.IPageInfo|null} [page] UserPageResponse page
         */

        /**
         * Constructs a new UserPageResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a UserPageResponse.
         * @implements IUserPageResponse
         * @constructor
         * @param {query_recursive_types.IUserPageResponse=} [properties] Properties to set
         */
        function UserPageResponse(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPageResponse userList.
         * @member {Array.<query_recursive_types.IUserResponse>} userList
         * @memberof query_recursive_types.UserPageResponse
         * @instance
         */
        UserPageResponse.prototype.userList = $util.emptyArray;

        /**
         * UserPageResponse page.
         * @member {query_pagination.IPageInfo|null|undefined} page
         * @memberof query_recursive_types.UserPageResponse
         * @instance
         */
        UserPageResponse.prototype.page = null;

        /**
         * Creates a new UserPageResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UserPageResponse
         * @static
         * @param {query_recursive_types.IUserPageResponse=} [properties] Properties to set
         * @returns {query_recursive_types.UserPageResponse} UserPageResponse instance
         */
        UserPageResponse.create = function create(properties) {
            return new UserPageResponse(properties);
        };

        /**
         * Encodes the specified UserPageResponse message. Does not implicitly {@link query_recursive_types.UserPageResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UserPageResponse
         * @static
         * @param {query_recursive_types.IUserPageResponse} message UserPageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.query_recursive_types.UserResponse.encode(message.userList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageInfo.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserPageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UserPageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UserPageResponse} UserPageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UserPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.query_recursive_types.UserResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = $root.query_pagination.PageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserPageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UserPageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UserPageResponse} UserPageResponse
         */
        UserPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UserPageResponse)
                return object;
            var message = new $root.query_recursive_types.UserPageResponse();
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".query_recursive_types.UserPageResponse.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".query_recursive_types.UserPageResponse.userList: object expected");
                    message.userList[i] = $root.query_recursive_types.UserResponse.fromObject(object.userList[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.UserPageResponse.page: object expected");
                message.page = $root.query_pagination.PageInfo.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserPageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UserPageResponse
         * @static
         * @param {query_recursive_types.UserPageResponse} message UserPageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (options.defaults)
                object.page = null;
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.query_recursive_types.UserResponse.toObject(message.userList[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageInfo.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this UserPageResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UserPageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserPageResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.UserPageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UserPageResponse";
        };

        return UserPageResponse;
    })();

    query_recursive_types.UserCursorRequest = (function() {

        /**
         * Properties of a UserCursorRequest.
         * @memberof query_recursive_types
         * @interface IUserCursorRequest
         * @property {query_recursive_types.IUserRequireField|null} [requireUserList] UserCursorRequest requireUserList
         * @property {query_pagination.ICursorRequest|null} [cursor] UserCursorRequest cursor
         */

        /**
         * Constructs a new UserCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a UserCursorRequest.
         * @implements IUserCursorRequest
         * @constructor
         * @param {query_recursive_types.IUserCursorRequest=} [properties] Properties to set
         */
        function UserCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserCursorRequest requireUserList.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUserList
         * @memberof query_recursive_types.UserCursorRequest
         * @instance
         */
        UserCursorRequest.prototype.requireUserList = null;

        /**
         * UserCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.UserCursorRequest
         * @instance
         */
        UserCursorRequest.prototype.cursor = null;

        /**
         * Creates a new UserCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UserCursorRequest
         * @static
         * @param {query_recursive_types.IUserCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.UserCursorRequest} UserCursorRequest instance
         */
        UserCursorRequest.create = function create(properties) {
            return new UserCursorRequest(properties);
        };

        /**
         * Encodes the specified UserCursorRequest message. Does not implicitly {@link query_recursive_types.UserCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UserCursorRequest
         * @static
         * @param {query_recursive_types.IUserCursorRequest} message UserCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireUserList != null && Object.hasOwnProperty.call(message, "requireUserList"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUserList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UserCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UserCursorRequest} UserCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UserCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireUserList = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UserCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UserCursorRequest} UserCursorRequest
         */
        UserCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UserCursorRequest)
                return object;
            var message = new $root.query_recursive_types.UserCursorRequest();
            if (object.requireUserList != null) {
                if (typeof object.requireUserList !== "object")
                    throw TypeError(".query_recursive_types.UserCursorRequest.requireUserList: object expected");
                message.requireUserList = $root.query_recursive_types.UserRequireField.fromObject(object.requireUserList);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.UserCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UserCursorRequest
         * @static
         * @param {query_recursive_types.UserCursorRequest} message UserCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireUserList = null;
                object.cursor = null;
            }
            if (message.requireUserList != null && message.hasOwnProperty("requireUserList"))
                object.requireUserList = $root.query_recursive_types.UserRequireField.toObject(message.requireUserList, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this UserCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UserCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.UserCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UserCursorRequest";
        };

        return UserCursorRequest;
    })();

    query_recursive_types.UserCursorResponse = (function() {

        /**
         * Properties of a UserCursorResponse.
         * @memberof query_recursive_types
         * @interface IUserCursorResponse
         * @property {Array.<query_recursive_types.IUserResponse>|null} [userList] UserCursorResponse userList
         * @property {query_pagination.ICursorInfo|null} [cursor] UserCursorResponse cursor
         */

        /**
         * Constructs a new UserCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a UserCursorResponse.
         * @implements IUserCursorResponse
         * @constructor
         * @param {query_recursive_types.IUserCursorResponse=} [properties] Properties to set
         */
        function UserCursorResponse(properties) {
            this.userList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserCursorResponse userList.
         * @member {Array.<query_recursive_types.IUserResponse>} userList
         * @memberof query_recursive_types.UserCursorResponse
         * @instance
         */
        UserCursorResponse.prototype.userList = $util.emptyArray;

        /**
         * UserCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.UserCursorResponse
         * @instance
         */
        UserCursorResponse.prototype.cursor = null;

        /**
         * Creates a new UserCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UserCursorResponse
         * @static
         * @param {query_recursive_types.IUserCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.UserCursorResponse} UserCursorResponse instance
         */
        UserCursorResponse.create = function create(properties) {
            return new UserCursorResponse(properties);
        };

        /**
         * Encodes the specified UserCursorResponse message. Does not implicitly {@link query_recursive_types.UserCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UserCursorResponse
         * @static
         * @param {query_recursive_types.IUserCursorResponse} message UserCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userList != null && message.userList.length)
                for (var i = 0; i < message.userList.length; ++i)
                    $root.query_recursive_types.UserResponse.encode(message.userList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UserCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UserCursorResponse} UserCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UserCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.userList && message.userList.length))
                        message.userList = [];
                    message.userList.push($root.query_recursive_types.UserResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UserCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UserCursorResponse} UserCursorResponse
         */
        UserCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UserCursorResponse)
                return object;
            var message = new $root.query_recursive_types.UserCursorResponse();
            if (object.userList) {
                if (!Array.isArray(object.userList))
                    throw TypeError(".query_recursive_types.UserCursorResponse.userList: array expected");
                message.userList = [];
                for (var i = 0; i < object.userList.length; ++i) {
                    if (typeof object.userList[i] !== "object")
                        throw TypeError(".query_recursive_types.UserCursorResponse.userList: object expected");
                    message.userList[i] = $root.query_recursive_types.UserResponse.fromObject(object.userList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.UserCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UserCursorResponse
         * @static
         * @param {query_recursive_types.UserCursorResponse} message UserCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.userList && message.userList.length) {
                object.userList = [];
                for (var j = 0; j < message.userList.length; ++j)
                    object.userList[j] = $root.query_recursive_types.UserResponse.toObject(message.userList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this UserCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UserCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.UserCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UserCursorResponse";
        };

        return UserCursorResponse;
    })();

    query_recursive_types.MeRequest = (function() {

        /**
         * Properties of a MeRequest.
         * @memberof query_recursive_types
         * @interface IMeRequest
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] MeRequest requireUser
         * @property {query_recursive_types.IMySeriesCursorRequest|null} [requireSeriesPage] MeRequest requireSeriesPage
         * @property {query_recursive_types.IStampMasterRequireField|null} [requireStampList] MeRequest requireStampList
         * @property {google.protobuf.IBoolValue|null} [requireShowSensitive] MeRequest requireShowSensitive
         * @property {query_recursive_types.IUsedTagListRequest|null} [requireUsedTagList] MeRequest requireUsedTagList
         * @property {query_recursive_types.IRecommendSeriesRequest|null} [requireRecommendedSeries] MeRequest requireRecommendedSeries
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireRecommendedSeriesPage] MeRequest requireRecommendedSeriesPage
         * @property {query_recursive_types.IMyStoryCursorRequest|null} [requireStoryPage] MeRequest requireStoryPage
         */

        /**
         * Constructs a new MeRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a MeRequest.
         * @implements IMeRequest
         * @constructor
         * @param {query_recursive_types.IMeRequest=} [properties] Properties to set
         */
        function MeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeRequest requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireUser = null;

        /**
         * MeRequest requireSeriesPage.
         * @member {query_recursive_types.IMySeriesCursorRequest|null|undefined} requireSeriesPage
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireSeriesPage = null;

        /**
         * MeRequest requireStampList.
         * @member {query_recursive_types.IStampMasterRequireField|null|undefined} requireStampList
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireStampList = null;

        /**
         * MeRequest requireShowSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} requireShowSensitive
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireShowSensitive = null;

        /**
         * MeRequest requireUsedTagList.
         * @member {query_recursive_types.IUsedTagListRequest|null|undefined} requireUsedTagList
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireUsedTagList = null;

        /**
         * MeRequest requireRecommendedSeries.
         * @member {query_recursive_types.IRecommendSeriesRequest|null|undefined} requireRecommendedSeries
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireRecommendedSeries = null;

        /**
         * MeRequest requireRecommendedSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireRecommendedSeriesPage
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireRecommendedSeriesPage = null;

        /**
         * MeRequest requireStoryPage.
         * @member {query_recursive_types.IMyStoryCursorRequest|null|undefined} requireStoryPage
         * @memberof query_recursive_types.MeRequest
         * @instance
         */
        MeRequest.prototype.requireStoryPage = null;

        /**
         * Creates a new MeRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MeRequest
         * @static
         * @param {query_recursive_types.IMeRequest=} [properties] Properties to set
         * @returns {query_recursive_types.MeRequest} MeRequest instance
         */
        MeRequest.create = function create(properties) {
            return new MeRequest(properties);
        };

        /**
         * Encodes the specified MeRequest message. Does not implicitly {@link query_recursive_types.MeRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MeRequest
         * @static
         * @param {query_recursive_types.IMeRequest} message MeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireStoryPage != null && Object.hasOwnProperty.call(message, "requireStoryPage"))
                $root.query_recursive_types.MyStoryCursorRequest.encode(message.requireStoryPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.MySeriesCursorRequest.encode(message.requireSeriesPage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireStampList != null && Object.hasOwnProperty.call(message, "requireStampList"))
                $root.query_recursive_types.StampMasterRequireField.encode(message.requireStampList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireShowSensitive != null && Object.hasOwnProperty.call(message, "requireShowSensitive"))
                $root.google.protobuf.BoolValue.encode(message.requireShowSensitive, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireRecommendedSeriesPage != null && Object.hasOwnProperty.call(message, "requireRecommendedSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireRecommendedSeriesPage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireUsedTagList != null && Object.hasOwnProperty.call(message, "requireUsedTagList"))
                $root.query_recursive_types.UsedTagListRequest.encode(message.requireUsedTagList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireRecommendedSeries != null && Object.hasOwnProperty.call(message, "requireRecommendedSeries"))
                $root.query_recursive_types.RecommendSeriesRequest.encode(message.requireRecommendedSeries, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MeRequest} MeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireSeriesPage = $root.query_recursive_types.MySeriesCursorRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireStampList = $root.query_recursive_types.StampMasterRequireField.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireShowSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireUsedTagList = $root.query_recursive_types.UsedTagListRequest.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireRecommendedSeries = $root.query_recursive_types.RecommendSeriesRequest.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireRecommendedSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireStoryPage = $root.query_recursive_types.MyStoryCursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MeRequest} MeRequest
         */
        MeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MeRequest)
                return object;
            var message = new $root.query_recursive_types.MeRequest();
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.MySeriesCursorRequest.fromObject(object.requireSeriesPage);
            }
            if (object.requireStampList != null) {
                if (typeof object.requireStampList !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireStampList: object expected");
                message.requireStampList = $root.query_recursive_types.StampMasterRequireField.fromObject(object.requireStampList);
            }
            if (object.requireShowSensitive != null) {
                if (typeof object.requireShowSensitive !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireShowSensitive: object expected");
                message.requireShowSensitive = $root.google.protobuf.BoolValue.fromObject(object.requireShowSensitive);
            }
            if (object.requireUsedTagList != null) {
                if (typeof object.requireUsedTagList !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireUsedTagList: object expected");
                message.requireUsedTagList = $root.query_recursive_types.UsedTagListRequest.fromObject(object.requireUsedTagList);
            }
            if (object.requireRecommendedSeries != null) {
                if (typeof object.requireRecommendedSeries !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireRecommendedSeries: object expected");
                message.requireRecommendedSeries = $root.query_recursive_types.RecommendSeriesRequest.fromObject(object.requireRecommendedSeries);
            }
            if (object.requireRecommendedSeriesPage != null) {
                if (typeof object.requireRecommendedSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireRecommendedSeriesPage: object expected");
                message.requireRecommendedSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireRecommendedSeriesPage);
            }
            if (object.requireStoryPage != null) {
                if (typeof object.requireStoryPage !== "object")
                    throw TypeError(".query_recursive_types.MeRequest.requireStoryPage: object expected");
                message.requireStoryPage = $root.query_recursive_types.MyStoryCursorRequest.fromObject(object.requireStoryPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MeRequest
         * @static
         * @param {query_recursive_types.MeRequest} message MeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireUser = null;
                object.requireStoryPage = null;
                object.requireSeriesPage = null;
                object.requireStampList = null;
                object.requireShowSensitive = null;
                object.requireRecommendedSeriesPage = null;
                object.requireUsedTagList = null;
                object.requireRecommendedSeries = null;
            }
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireStoryPage != null && message.hasOwnProperty("requireStoryPage"))
                object.requireStoryPage = $root.query_recursive_types.MyStoryCursorRequest.toObject(message.requireStoryPage, options);
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.MySeriesCursorRequest.toObject(message.requireSeriesPage, options);
            if (message.requireStampList != null && message.hasOwnProperty("requireStampList"))
                object.requireStampList = $root.query_recursive_types.StampMasterRequireField.toObject(message.requireStampList, options);
            if (message.requireShowSensitive != null && message.hasOwnProperty("requireShowSensitive"))
                object.requireShowSensitive = $root.google.protobuf.BoolValue.toObject(message.requireShowSensitive, options);
            if (message.requireRecommendedSeriesPage != null && message.hasOwnProperty("requireRecommendedSeriesPage"))
                object.requireRecommendedSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireRecommendedSeriesPage, options);
            if (message.requireUsedTagList != null && message.hasOwnProperty("requireUsedTagList"))
                object.requireUsedTagList = $root.query_recursive_types.UsedTagListRequest.toObject(message.requireUsedTagList, options);
            if (message.requireRecommendedSeries != null && message.hasOwnProperty("requireRecommendedSeries"))
                object.requireRecommendedSeries = $root.query_recursive_types.RecommendSeriesRequest.toObject(message.requireRecommendedSeries, options);
            return object;
        };

        /**
         * Converts this MeRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.MeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MeRequest";
        };

        return MeRequest;
    })();

    query_recursive_types.MeResponse = (function() {

        /**
         * Properties of a MeResponse.
         * @memberof query_recursive_types
         * @interface IMeResponse
         * @property {query_recursive_types.IUserResponse|null} [user] MeResponse user
         * @property {query_recursive_types.IMyStoryCursorResponse|null} [storyPage] MeResponse storyPage
         * @property {query_recursive_types.IMySeriesCursorResponse|null} [seriesPage] MeResponse seriesPage
         * @property {Array.<query_recursive_types.IStampMasterResponse>|null} [stampList] MeResponse stampList
         * @property {user_preference_sensitive.ShowSensitive|null} [showSensitive] MeResponse showSensitive
         * @property {query_recursive_types.ISeriesPageResponse|null} [recommendedSeriesPage] MeResponse recommendedSeriesPage
         * @property {query_recursive_types.IUsedTagListResponse|null} [usedTagListResponse] MeResponse usedTagListResponse
         */

        /**
         * Constructs a new MeResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a MeResponse.
         * @implements IMeResponse
         * @constructor
         * @param {query_recursive_types.IMeResponse=} [properties] Properties to set
         */
        function MeResponse(properties) {
            this.stampList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.MeResponse
         * @instance
         */
        MeResponse.prototype.user = null;

        /**
         * MeResponse storyPage.
         * @member {query_recursive_types.IMyStoryCursorResponse|null|undefined} storyPage
         * @memberof query_recursive_types.MeResponse
         * @instance
         */
        MeResponse.prototype.storyPage = null;

        /**
         * MeResponse seriesPage.
         * @member {query_recursive_types.IMySeriesCursorResponse|null|undefined} seriesPage
         * @memberof query_recursive_types.MeResponse
         * @instance
         */
        MeResponse.prototype.seriesPage = null;

        /**
         * MeResponse stampList.
         * @member {Array.<query_recursive_types.IStampMasterResponse>} stampList
         * @memberof query_recursive_types.MeResponse
         * @instance
         */
        MeResponse.prototype.stampList = $util.emptyArray;

        /**
         * MeResponse showSensitive.
         * @member {user_preference_sensitive.ShowSensitive} showSensitive
         * @memberof query_recursive_types.MeResponse
         * @instance
         */
        MeResponse.prototype.showSensitive = 0;

        /**
         * MeResponse recommendedSeriesPage.
         * @member {query_recursive_types.ISeriesPageResponse|null|undefined} recommendedSeriesPage
         * @memberof query_recursive_types.MeResponse
         * @instance
         */
        MeResponse.prototype.recommendedSeriesPage = null;

        /**
         * MeResponse usedTagListResponse.
         * @member {query_recursive_types.IUsedTagListResponse|null|undefined} usedTagListResponse
         * @memberof query_recursive_types.MeResponse
         * @instance
         */
        MeResponse.prototype.usedTagListResponse = null;

        /**
         * Creates a new MeResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MeResponse
         * @static
         * @param {query_recursive_types.IMeResponse=} [properties] Properties to set
         * @returns {query_recursive_types.MeResponse} MeResponse instance
         */
        MeResponse.create = function create(properties) {
            return new MeResponse(properties);
        };

        /**
         * Encodes the specified MeResponse message. Does not implicitly {@link query_recursive_types.MeResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MeResponse
         * @static
         * @param {query_recursive_types.IMeResponse} message MeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyPage != null && Object.hasOwnProperty.call(message, "storyPage"))
                $root.query_recursive_types.MyStoryCursorResponse.encode(message.storyPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.seriesPage != null && Object.hasOwnProperty.call(message, "seriesPage"))
                $root.query_recursive_types.MySeriesCursorResponse.encode(message.seriesPage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.stampList != null && message.stampList.length)
                for (var i = 0; i < message.stampList.length; ++i)
                    $root.query_recursive_types.StampMasterResponse.encode(message.stampList[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.showSensitive != null && Object.hasOwnProperty.call(message, "showSensitive"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.showSensitive);
            if (message.recommendedSeriesPage != null && Object.hasOwnProperty.call(message, "recommendedSeriesPage"))
                $root.query_recursive_types.SeriesPageResponse.encode(message.recommendedSeriesPage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.usedTagListResponse != null && Object.hasOwnProperty.call(message, "usedTagListResponse"))
                $root.query_recursive_types.UsedTagListResponse.encode(message.usedTagListResponse, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MeResponse} MeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyPage = $root.query_recursive_types.MyStoryCursorResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.seriesPage = $root.query_recursive_types.MySeriesCursorResponse.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.stampList && message.stampList.length))
                        message.stampList = [];
                    message.stampList.push($root.query_recursive_types.StampMasterResponse.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.showSensitive = reader.int32();
                    break;
                case 6:
                    message.recommendedSeriesPage = $root.query_recursive_types.SeriesPageResponse.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.usedTagListResponse = $root.query_recursive_types.UsedTagListResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MeResponse} MeResponse
         */
        MeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MeResponse)
                return object;
            var message = new $root.query_recursive_types.MeResponse();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.MeResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.storyPage != null) {
                if (typeof object.storyPage !== "object")
                    throw TypeError(".query_recursive_types.MeResponse.storyPage: object expected");
                message.storyPage = $root.query_recursive_types.MyStoryCursorResponse.fromObject(object.storyPage);
            }
            if (object.seriesPage != null) {
                if (typeof object.seriesPage !== "object")
                    throw TypeError(".query_recursive_types.MeResponse.seriesPage: object expected");
                message.seriesPage = $root.query_recursive_types.MySeriesCursorResponse.fromObject(object.seriesPage);
            }
            if (object.stampList) {
                if (!Array.isArray(object.stampList))
                    throw TypeError(".query_recursive_types.MeResponse.stampList: array expected");
                message.stampList = [];
                for (var i = 0; i < object.stampList.length; ++i) {
                    if (typeof object.stampList[i] !== "object")
                        throw TypeError(".query_recursive_types.MeResponse.stampList: object expected");
                    message.stampList[i] = $root.query_recursive_types.StampMasterResponse.fromObject(object.stampList[i]);
                }
            }
            switch (object.showSensitive) {
            case "SHOW_SENSITIVE_UNSPECIFIED":
            case 0:
                message.showSensitive = 0;
                break;
            case "SHOW_SENSITIVE_NOT_BLOCK_AND_LIST":
            case 3:
                message.showSensitive = 3;
                break;
            case "SHOW_SENSITIVE_BLOCK_AND_NOT_LIST":
            case 4:
                message.showSensitive = 4;
                break;
            case "SHOW_SENSITIVE_BLOCK_AND_LIST":
            case 5:
                message.showSensitive = 5;
                break;
            }
            if (object.recommendedSeriesPage != null) {
                if (typeof object.recommendedSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.MeResponse.recommendedSeriesPage: object expected");
                message.recommendedSeriesPage = $root.query_recursive_types.SeriesPageResponse.fromObject(object.recommendedSeriesPage);
            }
            if (object.usedTagListResponse != null) {
                if (typeof object.usedTagListResponse !== "object")
                    throw TypeError(".query_recursive_types.MeResponse.usedTagListResponse: object expected");
                message.usedTagListResponse = $root.query_recursive_types.UsedTagListResponse.fromObject(object.usedTagListResponse);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MeResponse
         * @static
         * @param {query_recursive_types.MeResponse} message MeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.stampList = [];
            if (options.defaults) {
                object.user = null;
                object.storyPage = null;
                object.seriesPage = null;
                object.showSensitive = options.enums === String ? "SHOW_SENSITIVE_UNSPECIFIED" : 0;
                object.recommendedSeriesPage = null;
                object.usedTagListResponse = null;
            }
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.storyPage != null && message.hasOwnProperty("storyPage"))
                object.storyPage = $root.query_recursive_types.MyStoryCursorResponse.toObject(message.storyPage, options);
            if (message.seriesPage != null && message.hasOwnProperty("seriesPage"))
                object.seriesPage = $root.query_recursive_types.MySeriesCursorResponse.toObject(message.seriesPage, options);
            if (message.stampList && message.stampList.length) {
                object.stampList = [];
                for (var j = 0; j < message.stampList.length; ++j)
                    object.stampList[j] = $root.query_recursive_types.StampMasterResponse.toObject(message.stampList[j], options);
            }
            if (message.showSensitive != null && message.hasOwnProperty("showSensitive"))
                object.showSensitive = options.enums === String ? $root.user_preference_sensitive.ShowSensitive[message.showSensitive] : message.showSensitive;
            if (message.recommendedSeriesPage != null && message.hasOwnProperty("recommendedSeriesPage"))
                object.recommendedSeriesPage = $root.query_recursive_types.SeriesPageResponse.toObject(message.recommendedSeriesPage, options);
            if (message.usedTagListResponse != null && message.hasOwnProperty("usedTagListResponse"))
                object.usedTagListResponse = $root.query_recursive_types.UsedTagListResponse.toObject(message.usedTagListResponse, options);
            return object;
        };

        /**
         * Converts this MeResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.MeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MeResponse";
        };

        return MeResponse;
    })();

    query_recursive_types.SeriesRequest = (function() {

        /**
         * Properties of a SeriesRequest.
         * @memberof query_recursive_types
         * @interface ISeriesRequest
         * @property {google.protobuf.IStringValue|null} [id] SeriesRequest id
         * @property {query_recursive_types.ISeriesRequireField|null} [requireSeries] SeriesRequest requireSeries
         */

        /**
         * Constructs a new SeriesRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesRequest.
         * @implements ISeriesRequest
         * @constructor
         * @param {query_recursive_types.ISeriesRequest=} [properties] Properties to set
         */
        function SeriesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.SeriesRequest
         * @instance
         */
        SeriesRequest.prototype.id = null;

        /**
         * SeriesRequest requireSeries.
         * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeries
         * @memberof query_recursive_types.SeriesRequest
         * @instance
         */
        SeriesRequest.prototype.requireSeries = null;

        /**
         * Creates a new SeriesRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesRequest
         * @static
         * @param {query_recursive_types.ISeriesRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesRequest} SeriesRequest instance
         */
        SeriesRequest.create = function create(properties) {
            return new SeriesRequest(properties);
        };

        /**
         * Encodes the specified SeriesRequest message. Does not implicitly {@link query_recursive_types.SeriesRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesRequest
         * @static
         * @param {query_recursive_types.ISeriesRequest} message SeriesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireSeries != null && Object.hasOwnProperty.call(message, "requireSeries"))
                $root.query_recursive_types.SeriesRequireField.encode(message.requireSeries, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesRequest} SeriesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeries = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesRequest} SeriesRequest
         */
        SeriesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesRequest)
                return object;
            var message = new $root.query_recursive_types.SeriesRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireSeries != null) {
                if (typeof object.requireSeries !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequest.requireSeries: object expected");
                message.requireSeries = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeries);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesRequest
         * @static
         * @param {query_recursive_types.SeriesRequest} message SeriesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireSeries = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireSeries != null && message.hasOwnProperty("requireSeries"))
                object.requireSeries = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeries, options);
            return object;
        };

        /**
         * Converts this SeriesRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesRequest";
        };

        return SeriesRequest;
    })();

    query_recursive_types.TagRequest = (function() {

        /**
         * Properties of a TagRequest.
         * @memberof query_recursive_types
         * @interface ITagRequest
         * @property {google.protobuf.IStringValue|null} [tag] TagRequest tag
         * @property {query_recursive_types.ITagRequireField|null} [requireTag] TagRequest requireTag
         */

        /**
         * Constructs a new TagRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a TagRequest.
         * @implements ITagRequest
         * @constructor
         * @param {query_recursive_types.ITagRequest=} [properties] Properties to set
         */
        function TagRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagRequest tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_recursive_types.TagRequest
         * @instance
         */
        TagRequest.prototype.tag = null;

        /**
         * TagRequest requireTag.
         * @member {query_recursive_types.ITagRequireField|null|undefined} requireTag
         * @memberof query_recursive_types.TagRequest
         * @instance
         */
        TagRequest.prototype.requireTag = null;

        /**
         * Creates a new TagRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.TagRequest
         * @static
         * @param {query_recursive_types.ITagRequest=} [properties] Properties to set
         * @returns {query_recursive_types.TagRequest} TagRequest instance
         */
        TagRequest.create = function create(properties) {
            return new TagRequest(properties);
        };

        /**
         * Encodes the specified TagRequest message. Does not implicitly {@link query_recursive_types.TagRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.TagRequest
         * @static
         * @param {query_recursive_types.ITagRequest} message TagRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTag != null && Object.hasOwnProperty.call(message, "requireTag"))
                $root.query_recursive_types.TagRequireField.encode(message.requireTag, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.TagRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.TagRequest} TagRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.TagRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTag = $root.query_recursive_types.TagRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.TagRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.TagRequest} TagRequest
         */
        TagRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.TagRequest)
                return object;
            var message = new $root.query_recursive_types.TagRequest();
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_recursive_types.TagRequest.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            if (object.requireTag != null) {
                if (typeof object.requireTag !== "object")
                    throw TypeError(".query_recursive_types.TagRequest.requireTag: object expected");
                message.requireTag = $root.query_recursive_types.TagRequireField.fromObject(object.requireTag);
            }
            return message;
        };

        /**
         * Creates a plain object from a TagRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.TagRequest
         * @static
         * @param {query_recursive_types.TagRequest} message TagRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.tag = null;
                object.requireTag = null;
            }
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.requireTag != null && message.hasOwnProperty("requireTag"))
                object.requireTag = $root.query_recursive_types.TagRequireField.toObject(message.requireTag, options);
            return object;
        };

        /**
         * Converts this TagRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.TagRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.TagRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.TagRequest";
        };

        return TagRequest;
    })();

    query_recursive_types.SeriesRequireField = (function() {

        /**
         * Properties of a SeriesRequireField.
         * @memberof query_recursive_types
         * @interface ISeriesRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] SeriesRequireField requireId
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] SeriesRequireField requireUser
         * @property {google.protobuf.IBoolValue|null} [requireTags] SeriesRequireField requireTags
         * @property {google.protobuf.IBoolValue|null} [requireTitle] SeriesRequireField requireTitle
         * @property {google.protobuf.IBoolValue|null} [requireDescription] SeriesRequireField requireDescription
         * @property {query_recursive_types.IImageRequireField|null} [requireThumbnail] SeriesRequireField requireThumbnail
         * @property {google.protobuf.IBoolValue|null} [requireIsOfficial] SeriesRequireField requireIsOfficial
         * @property {google.protobuf.IBoolValue|null} [requireIsCompleted] SeriesRequireField requireIsCompleted
         * @property {google.protobuf.IBoolValue|null} [requireNextPublishAt] SeriesRequireField requireNextPublishAt
         * @property {google.protobuf.IBoolValue|null} [requireSharedWithStatus] SeriesRequireField requireSharedWithStatus
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] SeriesRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] SeriesRequireField requireUpdatedAt
         * @property {google.protobuf.IBoolValue|null} [requireHasVipOnlyStory] SeriesRequireField requireHasVipOnlyStory
         * @property {google.protobuf.IBoolValue|null} [requireHasFreeStory] SeriesRequireField requireHasFreeStory
         * @property {query_series_charge.ISeriesChargeRequireField|null} [requireSeriesCharge] SeriesRequireField requireSeriesCharge
         * @property {query_recursive_types.ISeriesGroupRequireField|null} [requireSeriesGroupList] SeriesRequireField requireSeriesGroupList
         * @property {google.protobuf.IBoolValue|null} [requireUserBookmarked] SeriesRequireField requireUserBookmarked
         * @property {google.protobuf.IBoolValue|null} [requirePublishSchedule] SeriesRequireField requirePublishSchedule
         * @property {google.protobuf.IBoolValue|null} [requireLatestPublishedAt] SeriesRequireField requireLatestPublishedAt
         * @property {google.protobuf.IBoolValue|null} [requireHasNovelScriptStory] SeriesRequireField requireHasNovelScriptStory
         * @property {google.protobuf.IBoolValue|null} [requireHasChatNovelScriptStory] SeriesRequireField requireHasChatNovelScriptStory
         * @property {google.protobuf.IBoolValue|null} [requireSearchableStoryCount] SeriesRequireField requireSearchableStoryCount
         * @property {google.protobuf.IBoolValue|null} [requireTotalLikeCount] SeriesRequireField requireTotalLikeCount
         * @property {query_recursive_types.ISeriesReadingProgressRequireField|null} [requireUserReadingProgress] SeriesRequireField requireUserReadingProgress
         * @property {query_recursive_types.ICharacterRequireField|null} [requireCharacterList] SeriesRequireField requireCharacterList
         * @property {google.protobuf.IBoolValue|null} [requireUserAvailability] SeriesRequireField requireUserAvailability
         * @property {google.protobuf.IBoolValue|null} [requireIsOneshot] SeriesRequireField requireIsOneshot
         * @property {google.protobuf.IBoolValue|null} [requireCanChangeToOneshot] SeriesRequireField requireCanChangeToOneshot
         * @property {query_recursive_types.IStoryRequireField|null} [requireFirstPublishedStory] SeriesRequireField requireFirstPublishedStory
         * @property {google.protobuf.IBoolValue|null} [requireRecommenderModelId] SeriesRequireField requireRecommenderModelId
         * @property {google.protobuf.IBoolValue|null} [requireIsUploadingImageLocked] SeriesRequireField requireIsUploadingImageLocked
         * @property {google.protobuf.IBoolValue|null} [requireShowGuidelineDialog] SeriesRequireField requireShowGuidelineDialog
         * @property {google.protobuf.IBoolValue|null} [requireCanShowGoogleAd] SeriesRequireField requireCanShowGoogleAd
         * @property {google.protobuf.IBoolValue|null} [requireOriginalFlag] SeriesRequireField requireOriginalFlag
         * @property {google.protobuf.IBoolValue|null} [requireGenre] SeriesRequireField requireGenre
         * @property {google.protobuf.IBoolValue|null} [requireIsSensitive] SeriesRequireField requireIsSensitive
         * @property {query_recursive_types.IRecommendSeriesRequest|null} [requireRecommendedSeries] SeriesRequireField requireRecommendedSeries
         * @property {query_recursive_types.ISeriesStoryPageRequest|null} [requireStoryPage] SeriesRequireField requireStoryPage
         * @property {query_recursive_types.ISearchStoryRequest|null} [requireSearchStory] SeriesRequireField requireSearchStory
         * @property {google.protobuf.IBoolValue|null} [requireSensitiveStoryCount] SeriesRequireField requireSensitiveStoryCount
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireRecommendedSeriesPage] SeriesRequireField requireRecommendedSeriesPage
         */

        /**
         * Constructs a new SeriesRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesRequireField.
         * @implements ISeriesRequireField
         * @constructor
         * @param {query_recursive_types.ISeriesRequireField=} [properties] Properties to set
         */
        function SeriesRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireId = null;

        /**
         * SeriesRequireField requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireUser = null;

        /**
         * SeriesRequireField requireTags.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTags
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireTags = null;

        /**
         * SeriesRequireField requireTitle.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTitle
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireTitle = null;

        /**
         * SeriesRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireDescription = null;

        /**
         * SeriesRequireField requireThumbnail.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireThumbnail
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireThumbnail = null;

        /**
         * SeriesRequireField requireIsOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsOfficial
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireIsOfficial = null;

        /**
         * SeriesRequireField requireIsCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsCompleted
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireIsCompleted = null;

        /**
         * SeriesRequireField requireNextPublishAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireNextPublishAt
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireNextPublishAt = null;

        /**
         * SeriesRequireField requireSharedWithStatus.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSharedWithStatus
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireSharedWithStatus = null;

        /**
         * SeriesRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireCreatedAt = null;

        /**
         * SeriesRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireUpdatedAt = null;

        /**
         * SeriesRequireField requireHasVipOnlyStory.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHasVipOnlyStory
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireHasVipOnlyStory = null;

        /**
         * SeriesRequireField requireHasFreeStory.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHasFreeStory
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireHasFreeStory = null;

        /**
         * SeriesRequireField requireSeriesCharge.
         * @member {query_series_charge.ISeriesChargeRequireField|null|undefined} requireSeriesCharge
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireSeriesCharge = null;

        /**
         * SeriesRequireField requireSeriesGroupList.
         * @member {query_recursive_types.ISeriesGroupRequireField|null|undefined} requireSeriesGroupList
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireSeriesGroupList = null;

        /**
         * SeriesRequireField requireUserBookmarked.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUserBookmarked
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireUserBookmarked = null;

        /**
         * SeriesRequireField requirePublishSchedule.
         * @member {google.protobuf.IBoolValue|null|undefined} requirePublishSchedule
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requirePublishSchedule = null;

        /**
         * SeriesRequireField requireLatestPublishedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLatestPublishedAt
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireLatestPublishedAt = null;

        /**
         * SeriesRequireField requireHasNovelScriptStory.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHasNovelScriptStory
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireHasNovelScriptStory = null;

        /**
         * SeriesRequireField requireHasChatNovelScriptStory.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHasChatNovelScriptStory
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireHasChatNovelScriptStory = null;

        /**
         * SeriesRequireField requireSearchableStoryCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSearchableStoryCount
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireSearchableStoryCount = null;

        /**
         * SeriesRequireField requireTotalLikeCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTotalLikeCount
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireTotalLikeCount = null;

        /**
         * SeriesRequireField requireUserReadingProgress.
         * @member {query_recursive_types.ISeriesReadingProgressRequireField|null|undefined} requireUserReadingProgress
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireUserReadingProgress = null;

        /**
         * SeriesRequireField requireCharacterList.
         * @member {query_recursive_types.ICharacterRequireField|null|undefined} requireCharacterList
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireCharacterList = null;

        /**
         * SeriesRequireField requireUserAvailability.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUserAvailability
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireUserAvailability = null;

        /**
         * SeriesRequireField requireIsOneshot.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsOneshot
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireIsOneshot = null;

        /**
         * SeriesRequireField requireCanChangeToOneshot.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCanChangeToOneshot
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireCanChangeToOneshot = null;

        /**
         * SeriesRequireField requireFirstPublishedStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireFirstPublishedStory
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireFirstPublishedStory = null;

        /**
         * SeriesRequireField requireRecommenderModelId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireRecommenderModelId
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireRecommenderModelId = null;

        /**
         * SeriesRequireField requireIsUploadingImageLocked.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsUploadingImageLocked
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireIsUploadingImageLocked = null;

        /**
         * SeriesRequireField requireShowGuidelineDialog.
         * @member {google.protobuf.IBoolValue|null|undefined} requireShowGuidelineDialog
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireShowGuidelineDialog = null;

        /**
         * SeriesRequireField requireCanShowGoogleAd.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCanShowGoogleAd
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireCanShowGoogleAd = null;

        /**
         * SeriesRequireField requireOriginalFlag.
         * @member {google.protobuf.IBoolValue|null|undefined} requireOriginalFlag
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireOriginalFlag = null;

        /**
         * SeriesRequireField requireGenre.
         * @member {google.protobuf.IBoolValue|null|undefined} requireGenre
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireGenre = null;

        /**
         * SeriesRequireField requireIsSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsSensitive
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireIsSensitive = null;

        /**
         * SeriesRequireField requireRecommendedSeries.
         * @member {query_recursive_types.IRecommendSeriesRequest|null|undefined} requireRecommendedSeries
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireRecommendedSeries = null;

        /**
         * SeriesRequireField requireStoryPage.
         * @member {query_recursive_types.ISeriesStoryPageRequest|null|undefined} requireStoryPage
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireStoryPage = null;

        /**
         * SeriesRequireField requireSearchStory.
         * @member {query_recursive_types.ISearchStoryRequest|null|undefined} requireSearchStory
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireSearchStory = null;

        /**
         * SeriesRequireField requireSensitiveStoryCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSensitiveStoryCount
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireSensitiveStoryCount = null;

        /**
         * SeriesRequireField requireRecommendedSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireRecommendedSeriesPage
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         */
        SeriesRequireField.prototype.requireRecommendedSeriesPage = null;

        /**
         * Creates a new SeriesRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesRequireField
         * @static
         * @param {query_recursive_types.ISeriesRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesRequireField} SeriesRequireField instance
         */
        SeriesRequireField.create = function create(properties) {
            return new SeriesRequireField(properties);
        };

        /**
         * Encodes the specified SeriesRequireField message. Does not implicitly {@link query_recursive_types.SeriesRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesRequireField
         * @static
         * @param {query_recursive_types.ISeriesRequireField} message SeriesRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTags != null && Object.hasOwnProperty.call(message, "requireTags"))
                $root.google.protobuf.BoolValue.encode(message.requireTags, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireTitle != null && Object.hasOwnProperty.call(message, "requireTitle"))
                $root.google.protobuf.BoolValue.encode(message.requireTitle, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireThumbnail != null && Object.hasOwnProperty.call(message, "requireThumbnail"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireThumbnail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireIsOfficial != null && Object.hasOwnProperty.call(message, "requireIsOfficial"))
                $root.google.protobuf.BoolValue.encode(message.requireIsOfficial, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireIsCompleted != null && Object.hasOwnProperty.call(message, "requireIsCompleted"))
                $root.google.protobuf.BoolValue.encode(message.requireIsCompleted, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.requireNextPublishAt != null && Object.hasOwnProperty.call(message, "requireNextPublishAt"))
                $root.google.protobuf.BoolValue.encode(message.requireNextPublishAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.requireSharedWithStatus != null && Object.hasOwnProperty.call(message, "requireSharedWithStatus"))
                $root.google.protobuf.BoolValue.encode(message.requireSharedWithStatus, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.requireHasVipOnlyStory != null && Object.hasOwnProperty.call(message, "requireHasVipOnlyStory"))
                $root.google.protobuf.BoolValue.encode(message.requireHasVipOnlyStory, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.requireHasFreeStory != null && Object.hasOwnProperty.call(message, "requireHasFreeStory"))
                $root.google.protobuf.BoolValue.encode(message.requireHasFreeStory, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.requireSearchStory != null && Object.hasOwnProperty.call(message, "requireSearchStory"))
                $root.query_recursive_types.SearchStoryRequest.encode(message.requireSearchStory, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.requireSeriesCharge != null && Object.hasOwnProperty.call(message, "requireSeriesCharge"))
                $root.query_series_charge.SeriesChargeRequireField.encode(message.requireSeriesCharge, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.requireSeriesGroupList != null && Object.hasOwnProperty.call(message, "requireSeriesGroupList"))
                $root.query_recursive_types.SeriesGroupRequireField.encode(message.requireSeriesGroupList, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.requireUserBookmarked != null && Object.hasOwnProperty.call(message, "requireUserBookmarked"))
                $root.google.protobuf.BoolValue.encode(message.requireUserBookmarked, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.requirePublishSchedule != null && Object.hasOwnProperty.call(message, "requirePublishSchedule"))
                $root.google.protobuf.BoolValue.encode(message.requirePublishSchedule, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.requireLatestPublishedAt != null && Object.hasOwnProperty.call(message, "requireLatestPublishedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireLatestPublishedAt, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.requireHasNovelScriptStory != null && Object.hasOwnProperty.call(message, "requireHasNovelScriptStory"))
                $root.google.protobuf.BoolValue.encode(message.requireHasNovelScriptStory, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.requireHasChatNovelScriptStory != null && Object.hasOwnProperty.call(message, "requireHasChatNovelScriptStory"))
                $root.google.protobuf.BoolValue.encode(message.requireHasChatNovelScriptStory, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.requireSearchableStoryCount != null && Object.hasOwnProperty.call(message, "requireSearchableStoryCount"))
                $root.google.protobuf.BoolValue.encode(message.requireSearchableStoryCount, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.requireTotalLikeCount != null && Object.hasOwnProperty.call(message, "requireTotalLikeCount"))
                $root.google.protobuf.BoolValue.encode(message.requireTotalLikeCount, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.requireUserReadingProgress != null && Object.hasOwnProperty.call(message, "requireUserReadingProgress"))
                $root.query_recursive_types.SeriesReadingProgressRequireField.encode(message.requireUserReadingProgress, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.requireCharacterList != null && Object.hasOwnProperty.call(message, "requireCharacterList"))
                $root.query_recursive_types.CharacterRequireField.encode(message.requireCharacterList, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.requireUserAvailability != null && Object.hasOwnProperty.call(message, "requireUserAvailability"))
                $root.google.protobuf.BoolValue.encode(message.requireUserAvailability, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.requireSensitiveStoryCount != null && Object.hasOwnProperty.call(message, "requireSensitiveStoryCount"))
                $root.google.protobuf.BoolValue.encode(message.requireSensitiveStoryCount, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.requireIsOneshot != null && Object.hasOwnProperty.call(message, "requireIsOneshot"))
                $root.google.protobuf.BoolValue.encode(message.requireIsOneshot, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.requireCanChangeToOneshot != null && Object.hasOwnProperty.call(message, "requireCanChangeToOneshot"))
                $root.google.protobuf.BoolValue.encode(message.requireCanChangeToOneshot, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.requireFirstPublishedStory != null && Object.hasOwnProperty.call(message, "requireFirstPublishedStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireFirstPublishedStory, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.requireRecommenderModelId != null && Object.hasOwnProperty.call(message, "requireRecommenderModelId"))
                $root.google.protobuf.BoolValue.encode(message.requireRecommenderModelId, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.requireRecommendedSeriesPage != null && Object.hasOwnProperty.call(message, "requireRecommendedSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireRecommendedSeriesPage, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.requireIsUploadingImageLocked != null && Object.hasOwnProperty.call(message, "requireIsUploadingImageLocked"))
                $root.google.protobuf.BoolValue.encode(message.requireIsUploadingImageLocked, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.requireShowGuidelineDialog != null && Object.hasOwnProperty.call(message, "requireShowGuidelineDialog"))
                $root.google.protobuf.BoolValue.encode(message.requireShowGuidelineDialog, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.requireCanShowGoogleAd != null && Object.hasOwnProperty.call(message, "requireCanShowGoogleAd"))
                $root.google.protobuf.BoolValue.encode(message.requireCanShowGoogleAd, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.requireOriginalFlag != null && Object.hasOwnProperty.call(message, "requireOriginalFlag"))
                $root.google.protobuf.BoolValue.encode(message.requireOriginalFlag, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
            if (message.requireGenre != null && Object.hasOwnProperty.call(message, "requireGenre"))
                $root.google.protobuf.BoolValue.encode(message.requireGenre, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
            if (message.requireIsSensitive != null && Object.hasOwnProperty.call(message, "requireIsSensitive"))
                $root.google.protobuf.BoolValue.encode(message.requireIsSensitive, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.requireRecommendedSeries != null && Object.hasOwnProperty.call(message, "requireRecommendedSeries"))
                $root.query_recursive_types.RecommendSeriesRequest.encode(message.requireRecommendedSeries, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.requireStoryPage != null && Object.hasOwnProperty.call(message, "requireStoryPage"))
                $root.query_recursive_types.SeriesStoryPageRequest.encode(message.requireStoryPage, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesRequireField} SeriesRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTags = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireTitle = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireThumbnail = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireIsOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireIsCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.requireNextPublishAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.requireSharedWithStatus = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.requireHasVipOnlyStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.requireHasFreeStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.requireSeriesGroupList = $root.query_recursive_types.SeriesGroupRequireField.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.requireUserBookmarked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.requirePublishSchedule = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.requireLatestPublishedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.requireHasNovelScriptStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.requireHasChatNovelScriptStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.requireSearchableStoryCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.requireTotalLikeCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.requireUserReadingProgress = $root.query_recursive_types.SeriesReadingProgressRequireField.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.requireCharacterList = $root.query_recursive_types.CharacterRequireField.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.requireUserAvailability = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.requireIsOneshot = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.requireCanChangeToOneshot = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.requireFirstPublishedStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.requireRecommenderModelId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.requireIsUploadingImageLocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.requireShowGuidelineDialog = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 36:
                    message.requireCanShowGoogleAd = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.requireOriginalFlag = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.requireGenre = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.requireIsSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.requireRecommendedSeries = $root.query_recursive_types.RecommendSeriesRequest.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.requireStoryPage = $root.query_recursive_types.SeriesStoryPageRequest.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.requireSearchStory = $root.query_recursive_types.SearchStoryRequest.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.requireSensitiveStoryCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.requireRecommendedSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesRequireField} SeriesRequireField
         */
        SeriesRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesRequireField)
                return object;
            var message = new $root.query_recursive_types.SeriesRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireTags != null) {
                if (typeof object.requireTags !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireTags: object expected");
                message.requireTags = $root.google.protobuf.BoolValue.fromObject(object.requireTags);
            }
            if (object.requireTitle != null) {
                if (typeof object.requireTitle !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireTitle: object expected");
                message.requireTitle = $root.google.protobuf.BoolValue.fromObject(object.requireTitle);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireThumbnail != null) {
                if (typeof object.requireThumbnail !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireThumbnail: object expected");
                message.requireThumbnail = $root.query_recursive_types.ImageRequireField.fromObject(object.requireThumbnail);
            }
            if (object.requireIsOfficial != null) {
                if (typeof object.requireIsOfficial !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireIsOfficial: object expected");
                message.requireIsOfficial = $root.google.protobuf.BoolValue.fromObject(object.requireIsOfficial);
            }
            if (object.requireIsCompleted != null) {
                if (typeof object.requireIsCompleted !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireIsCompleted: object expected");
                message.requireIsCompleted = $root.google.protobuf.BoolValue.fromObject(object.requireIsCompleted);
            }
            if (object.requireNextPublishAt != null) {
                if (typeof object.requireNextPublishAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireNextPublishAt: object expected");
                message.requireNextPublishAt = $root.google.protobuf.BoolValue.fromObject(object.requireNextPublishAt);
            }
            if (object.requireSharedWithStatus != null) {
                if (typeof object.requireSharedWithStatus !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireSharedWithStatus: object expected");
                message.requireSharedWithStatus = $root.google.protobuf.BoolValue.fromObject(object.requireSharedWithStatus);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requireHasVipOnlyStory != null) {
                if (typeof object.requireHasVipOnlyStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireHasVipOnlyStory: object expected");
                message.requireHasVipOnlyStory = $root.google.protobuf.BoolValue.fromObject(object.requireHasVipOnlyStory);
            }
            if (object.requireHasFreeStory != null) {
                if (typeof object.requireHasFreeStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireHasFreeStory: object expected");
                message.requireHasFreeStory = $root.google.protobuf.BoolValue.fromObject(object.requireHasFreeStory);
            }
            if (object.requireSeriesCharge != null) {
                if (typeof object.requireSeriesCharge !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireSeriesCharge: object expected");
                message.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.fromObject(object.requireSeriesCharge);
            }
            if (object.requireSeriesGroupList != null) {
                if (typeof object.requireSeriesGroupList !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireSeriesGroupList: object expected");
                message.requireSeriesGroupList = $root.query_recursive_types.SeriesGroupRequireField.fromObject(object.requireSeriesGroupList);
            }
            if (object.requireUserBookmarked != null) {
                if (typeof object.requireUserBookmarked !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireUserBookmarked: object expected");
                message.requireUserBookmarked = $root.google.protobuf.BoolValue.fromObject(object.requireUserBookmarked);
            }
            if (object.requirePublishSchedule != null) {
                if (typeof object.requirePublishSchedule !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requirePublishSchedule: object expected");
                message.requirePublishSchedule = $root.google.protobuf.BoolValue.fromObject(object.requirePublishSchedule);
            }
            if (object.requireLatestPublishedAt != null) {
                if (typeof object.requireLatestPublishedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireLatestPublishedAt: object expected");
                message.requireLatestPublishedAt = $root.google.protobuf.BoolValue.fromObject(object.requireLatestPublishedAt);
            }
            if (object.requireHasNovelScriptStory != null) {
                if (typeof object.requireHasNovelScriptStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireHasNovelScriptStory: object expected");
                message.requireHasNovelScriptStory = $root.google.protobuf.BoolValue.fromObject(object.requireHasNovelScriptStory);
            }
            if (object.requireHasChatNovelScriptStory != null) {
                if (typeof object.requireHasChatNovelScriptStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireHasChatNovelScriptStory: object expected");
                message.requireHasChatNovelScriptStory = $root.google.protobuf.BoolValue.fromObject(object.requireHasChatNovelScriptStory);
            }
            if (object.requireSearchableStoryCount != null) {
                if (typeof object.requireSearchableStoryCount !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireSearchableStoryCount: object expected");
                message.requireSearchableStoryCount = $root.google.protobuf.BoolValue.fromObject(object.requireSearchableStoryCount);
            }
            if (object.requireTotalLikeCount != null) {
                if (typeof object.requireTotalLikeCount !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireTotalLikeCount: object expected");
                message.requireTotalLikeCount = $root.google.protobuf.BoolValue.fromObject(object.requireTotalLikeCount);
            }
            if (object.requireUserReadingProgress != null) {
                if (typeof object.requireUserReadingProgress !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireUserReadingProgress: object expected");
                message.requireUserReadingProgress = $root.query_recursive_types.SeriesReadingProgressRequireField.fromObject(object.requireUserReadingProgress);
            }
            if (object.requireCharacterList != null) {
                if (typeof object.requireCharacterList !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireCharacterList: object expected");
                message.requireCharacterList = $root.query_recursive_types.CharacterRequireField.fromObject(object.requireCharacterList);
            }
            if (object.requireUserAvailability != null) {
                if (typeof object.requireUserAvailability !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireUserAvailability: object expected");
                message.requireUserAvailability = $root.google.protobuf.BoolValue.fromObject(object.requireUserAvailability);
            }
            if (object.requireIsOneshot != null) {
                if (typeof object.requireIsOneshot !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireIsOneshot: object expected");
                message.requireIsOneshot = $root.google.protobuf.BoolValue.fromObject(object.requireIsOneshot);
            }
            if (object.requireCanChangeToOneshot != null) {
                if (typeof object.requireCanChangeToOneshot !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireCanChangeToOneshot: object expected");
                message.requireCanChangeToOneshot = $root.google.protobuf.BoolValue.fromObject(object.requireCanChangeToOneshot);
            }
            if (object.requireFirstPublishedStory != null) {
                if (typeof object.requireFirstPublishedStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireFirstPublishedStory: object expected");
                message.requireFirstPublishedStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireFirstPublishedStory);
            }
            if (object.requireRecommenderModelId != null) {
                if (typeof object.requireRecommenderModelId !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireRecommenderModelId: object expected");
                message.requireRecommenderModelId = $root.google.protobuf.BoolValue.fromObject(object.requireRecommenderModelId);
            }
            if (object.requireIsUploadingImageLocked != null) {
                if (typeof object.requireIsUploadingImageLocked !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireIsUploadingImageLocked: object expected");
                message.requireIsUploadingImageLocked = $root.google.protobuf.BoolValue.fromObject(object.requireIsUploadingImageLocked);
            }
            if (object.requireShowGuidelineDialog != null) {
                if (typeof object.requireShowGuidelineDialog !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireShowGuidelineDialog: object expected");
                message.requireShowGuidelineDialog = $root.google.protobuf.BoolValue.fromObject(object.requireShowGuidelineDialog);
            }
            if (object.requireCanShowGoogleAd != null) {
                if (typeof object.requireCanShowGoogleAd !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireCanShowGoogleAd: object expected");
                message.requireCanShowGoogleAd = $root.google.protobuf.BoolValue.fromObject(object.requireCanShowGoogleAd);
            }
            if (object.requireOriginalFlag != null) {
                if (typeof object.requireOriginalFlag !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireOriginalFlag: object expected");
                message.requireOriginalFlag = $root.google.protobuf.BoolValue.fromObject(object.requireOriginalFlag);
            }
            if (object.requireGenre != null) {
                if (typeof object.requireGenre !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireGenre: object expected");
                message.requireGenre = $root.google.protobuf.BoolValue.fromObject(object.requireGenre);
            }
            if (object.requireIsSensitive != null) {
                if (typeof object.requireIsSensitive !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireIsSensitive: object expected");
                message.requireIsSensitive = $root.google.protobuf.BoolValue.fromObject(object.requireIsSensitive);
            }
            if (object.requireRecommendedSeries != null) {
                if (typeof object.requireRecommendedSeries !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireRecommendedSeries: object expected");
                message.requireRecommendedSeries = $root.query_recursive_types.RecommendSeriesRequest.fromObject(object.requireRecommendedSeries);
            }
            if (object.requireStoryPage != null) {
                if (typeof object.requireStoryPage !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireStoryPage: object expected");
                message.requireStoryPage = $root.query_recursive_types.SeriesStoryPageRequest.fromObject(object.requireStoryPage);
            }
            if (object.requireSearchStory != null) {
                if (typeof object.requireSearchStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireSearchStory: object expected");
                message.requireSearchStory = $root.query_recursive_types.SearchStoryRequest.fromObject(object.requireSearchStory);
            }
            if (object.requireSensitiveStoryCount != null) {
                if (typeof object.requireSensitiveStoryCount !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireSensitiveStoryCount: object expected");
                message.requireSensitiveStoryCount = $root.google.protobuf.BoolValue.fromObject(object.requireSensitiveStoryCount);
            }
            if (object.requireRecommendedSeriesPage != null) {
                if (typeof object.requireRecommendedSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.SeriesRequireField.requireRecommendedSeriesPage: object expected");
                message.requireRecommendedSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireRecommendedSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesRequireField
         * @static
         * @param {query_recursive_types.SeriesRequireField} message SeriesRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireUser = null;
                object.requireTags = null;
                object.requireTitle = null;
                object.requireDescription = null;
                object.requireThumbnail = null;
                object.requireIsOfficial = null;
                object.requireIsCompleted = null;
                object.requireNextPublishAt = null;
                object.requireSharedWithStatus = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
                object.requireHasVipOnlyStory = null;
                object.requireHasFreeStory = null;
                object.requireSearchStory = null;
                object.requireSeriesCharge = null;
                object.requireSeriesGroupList = null;
                object.requireUserBookmarked = null;
                object.requirePublishSchedule = null;
                object.requireLatestPublishedAt = null;
                object.requireHasNovelScriptStory = null;
                object.requireHasChatNovelScriptStory = null;
                object.requireSearchableStoryCount = null;
                object.requireTotalLikeCount = null;
                object.requireUserReadingProgress = null;
                object.requireCharacterList = null;
                object.requireUserAvailability = null;
                object.requireSensitiveStoryCount = null;
                object.requireIsOneshot = null;
                object.requireCanChangeToOneshot = null;
                object.requireFirstPublishedStory = null;
                object.requireRecommenderModelId = null;
                object.requireRecommendedSeriesPage = null;
                object.requireIsUploadingImageLocked = null;
                object.requireShowGuidelineDialog = null;
                object.requireCanShowGoogleAd = null;
                object.requireOriginalFlag = null;
                object.requireGenre = null;
                object.requireIsSensitive = null;
                object.requireRecommendedSeries = null;
                object.requireStoryPage = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireTags != null && message.hasOwnProperty("requireTags"))
                object.requireTags = $root.google.protobuf.BoolValue.toObject(message.requireTags, options);
            if (message.requireTitle != null && message.hasOwnProperty("requireTitle"))
                object.requireTitle = $root.google.protobuf.BoolValue.toObject(message.requireTitle, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireThumbnail != null && message.hasOwnProperty("requireThumbnail"))
                object.requireThumbnail = $root.query_recursive_types.ImageRequireField.toObject(message.requireThumbnail, options);
            if (message.requireIsOfficial != null && message.hasOwnProperty("requireIsOfficial"))
                object.requireIsOfficial = $root.google.protobuf.BoolValue.toObject(message.requireIsOfficial, options);
            if (message.requireIsCompleted != null && message.hasOwnProperty("requireIsCompleted"))
                object.requireIsCompleted = $root.google.protobuf.BoolValue.toObject(message.requireIsCompleted, options);
            if (message.requireNextPublishAt != null && message.hasOwnProperty("requireNextPublishAt"))
                object.requireNextPublishAt = $root.google.protobuf.BoolValue.toObject(message.requireNextPublishAt, options);
            if (message.requireSharedWithStatus != null && message.hasOwnProperty("requireSharedWithStatus"))
                object.requireSharedWithStatus = $root.google.protobuf.BoolValue.toObject(message.requireSharedWithStatus, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            if (message.requireHasVipOnlyStory != null && message.hasOwnProperty("requireHasVipOnlyStory"))
                object.requireHasVipOnlyStory = $root.google.protobuf.BoolValue.toObject(message.requireHasVipOnlyStory, options);
            if (message.requireHasFreeStory != null && message.hasOwnProperty("requireHasFreeStory"))
                object.requireHasFreeStory = $root.google.protobuf.BoolValue.toObject(message.requireHasFreeStory, options);
            if (message.requireSearchStory != null && message.hasOwnProperty("requireSearchStory"))
                object.requireSearchStory = $root.query_recursive_types.SearchStoryRequest.toObject(message.requireSearchStory, options);
            if (message.requireSeriesCharge != null && message.hasOwnProperty("requireSeriesCharge"))
                object.requireSeriesCharge = $root.query_series_charge.SeriesChargeRequireField.toObject(message.requireSeriesCharge, options);
            if (message.requireSeriesGroupList != null && message.hasOwnProperty("requireSeriesGroupList"))
                object.requireSeriesGroupList = $root.query_recursive_types.SeriesGroupRequireField.toObject(message.requireSeriesGroupList, options);
            if (message.requireUserBookmarked != null && message.hasOwnProperty("requireUserBookmarked"))
                object.requireUserBookmarked = $root.google.protobuf.BoolValue.toObject(message.requireUserBookmarked, options);
            if (message.requirePublishSchedule != null && message.hasOwnProperty("requirePublishSchedule"))
                object.requirePublishSchedule = $root.google.protobuf.BoolValue.toObject(message.requirePublishSchedule, options);
            if (message.requireLatestPublishedAt != null && message.hasOwnProperty("requireLatestPublishedAt"))
                object.requireLatestPublishedAt = $root.google.protobuf.BoolValue.toObject(message.requireLatestPublishedAt, options);
            if (message.requireHasNovelScriptStory != null && message.hasOwnProperty("requireHasNovelScriptStory"))
                object.requireHasNovelScriptStory = $root.google.protobuf.BoolValue.toObject(message.requireHasNovelScriptStory, options);
            if (message.requireHasChatNovelScriptStory != null && message.hasOwnProperty("requireHasChatNovelScriptStory"))
                object.requireHasChatNovelScriptStory = $root.google.protobuf.BoolValue.toObject(message.requireHasChatNovelScriptStory, options);
            if (message.requireSearchableStoryCount != null && message.hasOwnProperty("requireSearchableStoryCount"))
                object.requireSearchableStoryCount = $root.google.protobuf.BoolValue.toObject(message.requireSearchableStoryCount, options);
            if (message.requireTotalLikeCount != null && message.hasOwnProperty("requireTotalLikeCount"))
                object.requireTotalLikeCount = $root.google.protobuf.BoolValue.toObject(message.requireTotalLikeCount, options);
            if (message.requireUserReadingProgress != null && message.hasOwnProperty("requireUserReadingProgress"))
                object.requireUserReadingProgress = $root.query_recursive_types.SeriesReadingProgressRequireField.toObject(message.requireUserReadingProgress, options);
            if (message.requireCharacterList != null && message.hasOwnProperty("requireCharacterList"))
                object.requireCharacterList = $root.query_recursive_types.CharacterRequireField.toObject(message.requireCharacterList, options);
            if (message.requireUserAvailability != null && message.hasOwnProperty("requireUserAvailability"))
                object.requireUserAvailability = $root.google.protobuf.BoolValue.toObject(message.requireUserAvailability, options);
            if (message.requireSensitiveStoryCount != null && message.hasOwnProperty("requireSensitiveStoryCount"))
                object.requireSensitiveStoryCount = $root.google.protobuf.BoolValue.toObject(message.requireSensitiveStoryCount, options);
            if (message.requireIsOneshot != null && message.hasOwnProperty("requireIsOneshot"))
                object.requireIsOneshot = $root.google.protobuf.BoolValue.toObject(message.requireIsOneshot, options);
            if (message.requireCanChangeToOneshot != null && message.hasOwnProperty("requireCanChangeToOneshot"))
                object.requireCanChangeToOneshot = $root.google.protobuf.BoolValue.toObject(message.requireCanChangeToOneshot, options);
            if (message.requireFirstPublishedStory != null && message.hasOwnProperty("requireFirstPublishedStory"))
                object.requireFirstPublishedStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireFirstPublishedStory, options);
            if (message.requireRecommenderModelId != null && message.hasOwnProperty("requireRecommenderModelId"))
                object.requireRecommenderModelId = $root.google.protobuf.BoolValue.toObject(message.requireRecommenderModelId, options);
            if (message.requireRecommendedSeriesPage != null && message.hasOwnProperty("requireRecommendedSeriesPage"))
                object.requireRecommendedSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireRecommendedSeriesPage, options);
            if (message.requireIsUploadingImageLocked != null && message.hasOwnProperty("requireIsUploadingImageLocked"))
                object.requireIsUploadingImageLocked = $root.google.protobuf.BoolValue.toObject(message.requireIsUploadingImageLocked, options);
            if (message.requireShowGuidelineDialog != null && message.hasOwnProperty("requireShowGuidelineDialog"))
                object.requireShowGuidelineDialog = $root.google.protobuf.BoolValue.toObject(message.requireShowGuidelineDialog, options);
            if (message.requireCanShowGoogleAd != null && message.hasOwnProperty("requireCanShowGoogleAd"))
                object.requireCanShowGoogleAd = $root.google.protobuf.BoolValue.toObject(message.requireCanShowGoogleAd, options);
            if (message.requireOriginalFlag != null && message.hasOwnProperty("requireOriginalFlag"))
                object.requireOriginalFlag = $root.google.protobuf.BoolValue.toObject(message.requireOriginalFlag, options);
            if (message.requireGenre != null && message.hasOwnProperty("requireGenre"))
                object.requireGenre = $root.google.protobuf.BoolValue.toObject(message.requireGenre, options);
            if (message.requireIsSensitive != null && message.hasOwnProperty("requireIsSensitive"))
                object.requireIsSensitive = $root.google.protobuf.BoolValue.toObject(message.requireIsSensitive, options);
            if (message.requireRecommendedSeries != null && message.hasOwnProperty("requireRecommendedSeries"))
                object.requireRecommendedSeries = $root.query_recursive_types.RecommendSeriesRequest.toObject(message.requireRecommendedSeries, options);
            if (message.requireStoryPage != null && message.hasOwnProperty("requireStoryPage"))
                object.requireStoryPage = $root.query_recursive_types.SeriesStoryPageRequest.toObject(message.requireStoryPage, options);
            return object;
        };

        /**
         * Converts this SeriesRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesRequireField";
        };

        return SeriesRequireField;
    })();

    query_recursive_types.SeriesResponse = (function() {

        /**
         * Properties of a SeriesResponse.
         * @memberof query_recursive_types
         * @interface ISeriesResponse
         * @property {google.protobuf.IStringValue|null} [id] SeriesResponse id
         * @property {query_recursive_types.IUserResponse|null} [user] SeriesResponse user
         * @property {Array.<google.protobuf.IStringValue>|null} [tags] SeriesResponse tags
         * @property {google.protobuf.IStringValue|null} [title] SeriesResponse title
         * @property {google.protobuf.IStringValue|null} [description] SeriesResponse description
         * @property {query_recursive_types.IImageResponse|null} [thumbnail] SeriesResponse thumbnail
         * @property {google.protobuf.IBoolValue|null} [isOfficial] SeriesResponse isOfficial
         * @property {google.protobuf.IBoolValue|null} [isCompleted] SeriesResponse isCompleted
         * @property {google.protobuf.ITimestamp|null} [nextPublishAt] SeriesResponse nextPublishAt
         * @property {types.SharedWithStatus|null} [sharedWithStatus] SeriesResponse sharedWithStatus
         * @property {google.protobuf.ITimestamp|null} [createdAt] SeriesResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] SeriesResponse updatedAt
         * @property {google.protobuf.IBoolValue|null} [hasVipOnlyStory] SeriesResponse hasVipOnlyStory
         * @property {google.protobuf.IBoolValue|null} [hasFreeStory] SeriesResponse hasFreeStory
         * @property {query_series_charge.ISeriesChargeResponse|null} [seriesCharge] SeriesResponse seriesCharge
         * @property {Array.<query_recursive_types.ISeriesGroupResponse>|null} [seriesGroupList] SeriesResponse seriesGroupList
         * @property {google.protobuf.IBoolValue|null} [userBookmarked] SeriesResponse userBookmarked
         * @property {query_series.IPublishScheduleResponse|null} [publishSchedule] SeriesResponse publishSchedule
         * @property {google.protobuf.ITimestamp|null} [latestPublishedAt] SeriesResponse latestPublishedAt
         * @property {google.protobuf.IBoolValue|null} [hasNovelScriptStory] SeriesResponse hasNovelScriptStory
         * @property {google.protobuf.IBoolValue|null} [hasChatNovelScriptStory] SeriesResponse hasChatNovelScriptStory
         * @property {google.protobuf.IInt32Value|null} [searchableStoryCount] SeriesResponse searchableStoryCount
         * @property {google.protobuf.IInt32Value|null} [totalLikeCount] SeriesResponse totalLikeCount
         * @property {query_recursive_types.ISeriesReadingProgressResponse|null} [userReadingProgress] SeriesResponse userReadingProgress
         * @property {Array.<query_recursive_types.ICharacterResponse>|null} [characterList] SeriesResponse characterList
         * @property {query_types.SeriesAvailability|null} [userAvailability] SeriesResponse userAvailability
         * @property {google.protobuf.IBoolValue|null} [isOneshot] SeriesResponse isOneshot
         * @property {google.protobuf.IBoolValue|null} [canChangeToOneshot] SeriesResponse canChangeToOneshot
         * @property {query_recursive_types.IStoryResponse|null} [firstPublishedStory] SeriesResponse firstPublishedStory
         * @property {google.protobuf.IStringValue|null} [recommenderModelId] SeriesResponse recommenderModelId
         * @property {query_recursive_types.ISeriesPageResponse|null} [recommendedSeriesPage] SeriesResponse recommendedSeriesPage
         * @property {google.protobuf.IBoolValue|null} [isUploadingImageLocked] SeriesResponse isUploadingImageLocked
         * @property {google.protobuf.IBoolValue|null} [showGuidelineDialog] SeriesResponse showGuidelineDialog
         * @property {google.protobuf.IBoolValue|null} [canShowGoogleAd] SeriesResponse canShowGoogleAd
         * @property {query_types.OriginalFlag|null} [originalFlag] SeriesResponse originalFlag
         * @property {query_types.Genre|null} [genre] SeriesResponse genre
         * @property {google.protobuf.IBoolValue|null} [isSensitive] SeriesResponse isSensitive
         * @property {query_recursive_types.ISeriesStoryPageResponse|null} [storyPage] SeriesResponse storyPage
         * @property {query_recursive_types.ISearchStoryResponse|null} [searchStory] SeriesResponse searchStory
         * @property {google.protobuf.IInt32Value|null} [sensitiveStoryCount] SeriesResponse sensitiveStoryCount
         */

        /**
         * Constructs a new SeriesResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesResponse.
         * @implements ISeriesResponse
         * @constructor
         * @param {query_recursive_types.ISeriesResponse=} [properties] Properties to set
         */
        function SeriesResponse(properties) {
            this.tags = [];
            this.seriesGroupList = [];
            this.characterList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.id = null;

        /**
         * SeriesResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.user = null;

        /**
         * SeriesResponse tags.
         * @member {Array.<google.protobuf.IStringValue>} tags
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.tags = $util.emptyArray;

        /**
         * SeriesResponse title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.title = null;

        /**
         * SeriesResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.description = null;

        /**
         * SeriesResponse thumbnail.
         * @member {query_recursive_types.IImageResponse|null|undefined} thumbnail
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.thumbnail = null;

        /**
         * SeriesResponse isOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} isOfficial
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.isOfficial = null;

        /**
         * SeriesResponse isCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} isCompleted
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.isCompleted = null;

        /**
         * SeriesResponse nextPublishAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextPublishAt
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.nextPublishAt = null;

        /**
         * SeriesResponse sharedWithStatus.
         * @member {types.SharedWithStatus} sharedWithStatus
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.sharedWithStatus = 0;

        /**
         * SeriesResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.createdAt = null;

        /**
         * SeriesResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.updatedAt = null;

        /**
         * SeriesResponse hasVipOnlyStory.
         * @member {google.protobuf.IBoolValue|null|undefined} hasVipOnlyStory
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.hasVipOnlyStory = null;

        /**
         * SeriesResponse hasFreeStory.
         * @member {google.protobuf.IBoolValue|null|undefined} hasFreeStory
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.hasFreeStory = null;

        /**
         * SeriesResponse seriesCharge.
         * @member {query_series_charge.ISeriesChargeResponse|null|undefined} seriesCharge
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.seriesCharge = null;

        /**
         * SeriesResponse seriesGroupList.
         * @member {Array.<query_recursive_types.ISeriesGroupResponse>} seriesGroupList
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.seriesGroupList = $util.emptyArray;

        /**
         * SeriesResponse userBookmarked.
         * @member {google.protobuf.IBoolValue|null|undefined} userBookmarked
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.userBookmarked = null;

        /**
         * SeriesResponse publishSchedule.
         * @member {query_series.IPublishScheduleResponse|null|undefined} publishSchedule
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.publishSchedule = null;

        /**
         * SeriesResponse latestPublishedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} latestPublishedAt
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.latestPublishedAt = null;

        /**
         * SeriesResponse hasNovelScriptStory.
         * @member {google.protobuf.IBoolValue|null|undefined} hasNovelScriptStory
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.hasNovelScriptStory = null;

        /**
         * SeriesResponse hasChatNovelScriptStory.
         * @member {google.protobuf.IBoolValue|null|undefined} hasChatNovelScriptStory
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.hasChatNovelScriptStory = null;

        /**
         * SeriesResponse searchableStoryCount.
         * @member {google.protobuf.IInt32Value|null|undefined} searchableStoryCount
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.searchableStoryCount = null;

        /**
         * SeriesResponse totalLikeCount.
         * @member {google.protobuf.IInt32Value|null|undefined} totalLikeCount
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.totalLikeCount = null;

        /**
         * SeriesResponse userReadingProgress.
         * @member {query_recursive_types.ISeriesReadingProgressResponse|null|undefined} userReadingProgress
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.userReadingProgress = null;

        /**
         * SeriesResponse characterList.
         * @member {Array.<query_recursive_types.ICharacterResponse>} characterList
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.characterList = $util.emptyArray;

        /**
         * SeriesResponse userAvailability.
         * @member {query_types.SeriesAvailability} userAvailability
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.userAvailability = 0;

        /**
         * SeriesResponse isOneshot.
         * @member {google.protobuf.IBoolValue|null|undefined} isOneshot
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.isOneshot = null;

        /**
         * SeriesResponse canChangeToOneshot.
         * @member {google.protobuf.IBoolValue|null|undefined} canChangeToOneshot
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.canChangeToOneshot = null;

        /**
         * SeriesResponse firstPublishedStory.
         * @member {query_recursive_types.IStoryResponse|null|undefined} firstPublishedStory
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.firstPublishedStory = null;

        /**
         * SeriesResponse recommenderModelId.
         * @member {google.protobuf.IStringValue|null|undefined} recommenderModelId
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.recommenderModelId = null;

        /**
         * SeriesResponse recommendedSeriesPage.
         * @member {query_recursive_types.ISeriesPageResponse|null|undefined} recommendedSeriesPage
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.recommendedSeriesPage = null;

        /**
         * SeriesResponse isUploadingImageLocked.
         * @member {google.protobuf.IBoolValue|null|undefined} isUploadingImageLocked
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.isUploadingImageLocked = null;

        /**
         * SeriesResponse showGuidelineDialog.
         * @member {google.protobuf.IBoolValue|null|undefined} showGuidelineDialog
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.showGuidelineDialog = null;

        /**
         * SeriesResponse canShowGoogleAd.
         * @member {google.protobuf.IBoolValue|null|undefined} canShowGoogleAd
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.canShowGoogleAd = null;

        /**
         * SeriesResponse originalFlag.
         * @member {query_types.OriginalFlag} originalFlag
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.originalFlag = 0;

        /**
         * SeriesResponse genre.
         * @member {query_types.Genre} genre
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.genre = 0;

        /**
         * SeriesResponse isSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} isSensitive
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.isSensitive = null;

        /**
         * SeriesResponse storyPage.
         * @member {query_recursive_types.ISeriesStoryPageResponse|null|undefined} storyPage
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.storyPage = null;

        /**
         * SeriesResponse searchStory.
         * @member {query_recursive_types.ISearchStoryResponse|null|undefined} searchStory
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.searchStory = null;

        /**
         * SeriesResponse sensitiveStoryCount.
         * @member {google.protobuf.IInt32Value|null|undefined} sensitiveStoryCount
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         */
        SeriesResponse.prototype.sensitiveStoryCount = null;

        /**
         * Creates a new SeriesResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesResponse
         * @static
         * @param {query_recursive_types.ISeriesResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesResponse} SeriesResponse instance
         */
        SeriesResponse.create = function create(properties) {
            return new SeriesResponse(properties);
        };

        /**
         * Encodes the specified SeriesResponse message. Does not implicitly {@link query_recursive_types.SeriesResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesResponse
         * @static
         * @param {query_recursive_types.ISeriesResponse} message SeriesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tags[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.query_recursive_types.ImageResponse.encode(message.thumbnail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isOfficial != null && Object.hasOwnProperty.call(message, "isOfficial"))
                $root.google.protobuf.BoolValue.encode(message.isOfficial, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.isCompleted != null && Object.hasOwnProperty.call(message, "isCompleted"))
                $root.google.protobuf.BoolValue.encode(message.isCompleted, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.nextPublishAt != null && Object.hasOwnProperty.call(message, "nextPublishAt"))
                $root.google.protobuf.Timestamp.encode(message.nextPublishAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.sharedWithStatus != null && Object.hasOwnProperty.call(message, "sharedWithStatus"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.sharedWithStatus);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.hasVipOnlyStory != null && Object.hasOwnProperty.call(message, "hasVipOnlyStory"))
                $root.google.protobuf.BoolValue.encode(message.hasVipOnlyStory, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.hasFreeStory != null && Object.hasOwnProperty.call(message, "hasFreeStory"))
                $root.google.protobuf.BoolValue.encode(message.hasFreeStory, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.searchStory != null && Object.hasOwnProperty.call(message, "searchStory"))
                $root.query_recursive_types.SearchStoryResponse.encode(message.searchStory, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.seriesCharge != null && Object.hasOwnProperty.call(message, "seriesCharge"))
                $root.query_series_charge.SeriesChargeResponse.encode(message.seriesCharge, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.seriesGroupList != null && message.seriesGroupList.length)
                for (var i = 0; i < message.seriesGroupList.length; ++i)
                    $root.query_recursive_types.SeriesGroupResponse.encode(message.seriesGroupList[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.userBookmarked != null && Object.hasOwnProperty.call(message, "userBookmarked"))
                $root.google.protobuf.BoolValue.encode(message.userBookmarked, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.publishSchedule != null && Object.hasOwnProperty.call(message, "publishSchedule"))
                $root.query_series.PublishScheduleResponse.encode(message.publishSchedule, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.latestPublishedAt != null && Object.hasOwnProperty.call(message, "latestPublishedAt"))
                $root.google.protobuf.Timestamp.encode(message.latestPublishedAt, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.hasNovelScriptStory != null && Object.hasOwnProperty.call(message, "hasNovelScriptStory"))
                $root.google.protobuf.BoolValue.encode(message.hasNovelScriptStory, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.hasChatNovelScriptStory != null && Object.hasOwnProperty.call(message, "hasChatNovelScriptStory"))
                $root.google.protobuf.BoolValue.encode(message.hasChatNovelScriptStory, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.searchableStoryCount != null && Object.hasOwnProperty.call(message, "searchableStoryCount"))
                $root.google.protobuf.Int32Value.encode(message.searchableStoryCount, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.totalLikeCount != null && Object.hasOwnProperty.call(message, "totalLikeCount"))
                $root.google.protobuf.Int32Value.encode(message.totalLikeCount, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.userReadingProgress != null && Object.hasOwnProperty.call(message, "userReadingProgress"))
                $root.query_recursive_types.SeriesReadingProgressResponse.encode(message.userReadingProgress, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.characterList != null && message.characterList.length)
                for (var i = 0; i < message.characterList.length; ++i)
                    $root.query_recursive_types.CharacterResponse.encode(message.characterList[i], writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.userAvailability != null && Object.hasOwnProperty.call(message, "userAvailability"))
                writer.uint32(/* id 27, wireType 0 =*/216).int32(message.userAvailability);
            if (message.sensitiveStoryCount != null && Object.hasOwnProperty.call(message, "sensitiveStoryCount"))
                $root.google.protobuf.Int32Value.encode(message.sensitiveStoryCount, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.isOneshot != null && Object.hasOwnProperty.call(message, "isOneshot"))
                $root.google.protobuf.BoolValue.encode(message.isOneshot, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.canChangeToOneshot != null && Object.hasOwnProperty.call(message, "canChangeToOneshot"))
                $root.google.protobuf.BoolValue.encode(message.canChangeToOneshot, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.firstPublishedStory != null && Object.hasOwnProperty.call(message, "firstPublishedStory"))
                $root.query_recursive_types.StoryResponse.encode(message.firstPublishedStory, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.recommenderModelId != null && Object.hasOwnProperty.call(message, "recommenderModelId"))
                $root.google.protobuf.StringValue.encode(message.recommenderModelId, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.recommendedSeriesPage != null && Object.hasOwnProperty.call(message, "recommendedSeriesPage"))
                $root.query_recursive_types.SeriesPageResponse.encode(message.recommendedSeriesPage, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.isUploadingImageLocked != null && Object.hasOwnProperty.call(message, "isUploadingImageLocked"))
                $root.google.protobuf.BoolValue.encode(message.isUploadingImageLocked, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.showGuidelineDialog != null && Object.hasOwnProperty.call(message, "showGuidelineDialog"))
                $root.google.protobuf.BoolValue.encode(message.showGuidelineDialog, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.canShowGoogleAd != null && Object.hasOwnProperty.call(message, "canShowGoogleAd"))
                $root.google.protobuf.BoolValue.encode(message.canShowGoogleAd, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.originalFlag != null && Object.hasOwnProperty.call(message, "originalFlag"))
                writer.uint32(/* id 37, wireType 0 =*/296).int32(message.originalFlag);
            if (message.genre != null && Object.hasOwnProperty.call(message, "genre"))
                writer.uint32(/* id 38, wireType 0 =*/304).int32(message.genre);
            if (message.isSensitive != null && Object.hasOwnProperty.call(message, "isSensitive"))
                $root.google.protobuf.BoolValue.encode(message.isSensitive, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.storyPage != null && Object.hasOwnProperty.call(message, "storyPage"))
                $root.query_recursive_types.SeriesStoryPageResponse.encode(message.storyPage, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesResponse} SeriesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.thumbnail = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.isCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.nextPublishAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.sharedWithStatus = reader.int32();
                    break;
                case 11:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.hasVipOnlyStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.hasFreeStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.seriesCharge = $root.query_series_charge.SeriesChargeResponse.decode(reader, reader.uint32());
                    break;
                case 17:
                    if (!(message.seriesGroupList && message.seriesGroupList.length))
                        message.seriesGroupList = [];
                    message.seriesGroupList.push($root.query_recursive_types.SeriesGroupResponse.decode(reader, reader.uint32()));
                    break;
                case 18:
                    message.userBookmarked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.publishSchedule = $root.query_series.PublishScheduleResponse.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.latestPublishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.hasNovelScriptStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.hasChatNovelScriptStory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.searchableStoryCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.totalLikeCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.userReadingProgress = $root.query_recursive_types.SeriesReadingProgressResponse.decode(reader, reader.uint32());
                    break;
                case 26:
                    if (!(message.characterList && message.characterList.length))
                        message.characterList = [];
                    message.characterList.push($root.query_recursive_types.CharacterResponse.decode(reader, reader.uint32()));
                    break;
                case 27:
                    message.userAvailability = reader.int32();
                    break;
                case 29:
                    message.isOneshot = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.canChangeToOneshot = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.firstPublishedStory = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.recommenderModelId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.recommendedSeriesPage = $root.query_recursive_types.SeriesPageResponse.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.isUploadingImageLocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.showGuidelineDialog = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 36:
                    message.canShowGoogleAd = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.originalFlag = reader.int32();
                    break;
                case 38:
                    message.genre = reader.int32();
                    break;
                case 39:
                    message.isSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.storyPage = $root.query_recursive_types.SeriesStoryPageResponse.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.searchStory = $root.query_recursive_types.SearchStoryResponse.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.sensitiveStoryCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesResponse} SeriesResponse
         */
        SeriesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".query_recursive_types.SeriesResponse.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesResponse.tags: object expected");
                    message.tags[i] = $root.google.protobuf.StringValue.fromObject(object.tags[i]);
                }
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.thumbnail: object expected");
                message.thumbnail = $root.query_recursive_types.ImageResponse.fromObject(object.thumbnail);
            }
            if (object.isOfficial != null) {
                if (typeof object.isOfficial !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.isOfficial: object expected");
                message.isOfficial = $root.google.protobuf.BoolValue.fromObject(object.isOfficial);
            }
            if (object.isCompleted != null) {
                if (typeof object.isCompleted !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.isCompleted: object expected");
                message.isCompleted = $root.google.protobuf.BoolValue.fromObject(object.isCompleted);
            }
            if (object.nextPublishAt != null) {
                if (typeof object.nextPublishAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.nextPublishAt: object expected");
                message.nextPublishAt = $root.google.protobuf.Timestamp.fromObject(object.nextPublishAt);
            }
            switch (object.sharedWithStatus) {
            case "SHARED_WITH_STATUS_UNKNOWN":
            case 0:
                message.sharedWithStatus = 0;
                break;
            case "SHARED_WITH_NOBODY":
            case 1:
                message.sharedWithStatus = 1;
                break;
            case "SHARED_WITH_DIRECT_LINK":
            case 2:
                message.sharedWithStatus = 2;
                break;
            case "SHARED_WITH_PUBLIC":
            case 3:
                message.sharedWithStatus = 3;
                break;
            case "SHARED_WITH_FOLLOWER":
            case 4:
                message.sharedWithStatus = 4;
                break;
            case "SHARED_WITH_MUTUAL_FOLLOWER":
            case 5:
                message.sharedWithStatus = 5;
                break;
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.hasVipOnlyStory != null) {
                if (typeof object.hasVipOnlyStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.hasVipOnlyStory: object expected");
                message.hasVipOnlyStory = $root.google.protobuf.BoolValue.fromObject(object.hasVipOnlyStory);
            }
            if (object.hasFreeStory != null) {
                if (typeof object.hasFreeStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.hasFreeStory: object expected");
                message.hasFreeStory = $root.google.protobuf.BoolValue.fromObject(object.hasFreeStory);
            }
            if (object.seriesCharge != null) {
                if (typeof object.seriesCharge !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.seriesCharge: object expected");
                message.seriesCharge = $root.query_series_charge.SeriesChargeResponse.fromObject(object.seriesCharge);
            }
            if (object.seriesGroupList) {
                if (!Array.isArray(object.seriesGroupList))
                    throw TypeError(".query_recursive_types.SeriesResponse.seriesGroupList: array expected");
                message.seriesGroupList = [];
                for (var i = 0; i < object.seriesGroupList.length; ++i) {
                    if (typeof object.seriesGroupList[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesResponse.seriesGroupList: object expected");
                    message.seriesGroupList[i] = $root.query_recursive_types.SeriesGroupResponse.fromObject(object.seriesGroupList[i]);
                }
            }
            if (object.userBookmarked != null) {
                if (typeof object.userBookmarked !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.userBookmarked: object expected");
                message.userBookmarked = $root.google.protobuf.BoolValue.fromObject(object.userBookmarked);
            }
            if (object.publishSchedule != null) {
                if (typeof object.publishSchedule !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.publishSchedule: object expected");
                message.publishSchedule = $root.query_series.PublishScheduleResponse.fromObject(object.publishSchedule);
            }
            if (object.latestPublishedAt != null) {
                if (typeof object.latestPublishedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.latestPublishedAt: object expected");
                message.latestPublishedAt = $root.google.protobuf.Timestamp.fromObject(object.latestPublishedAt);
            }
            if (object.hasNovelScriptStory != null) {
                if (typeof object.hasNovelScriptStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.hasNovelScriptStory: object expected");
                message.hasNovelScriptStory = $root.google.protobuf.BoolValue.fromObject(object.hasNovelScriptStory);
            }
            if (object.hasChatNovelScriptStory != null) {
                if (typeof object.hasChatNovelScriptStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.hasChatNovelScriptStory: object expected");
                message.hasChatNovelScriptStory = $root.google.protobuf.BoolValue.fromObject(object.hasChatNovelScriptStory);
            }
            if (object.searchableStoryCount != null) {
                if (typeof object.searchableStoryCount !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.searchableStoryCount: object expected");
                message.searchableStoryCount = $root.google.protobuf.Int32Value.fromObject(object.searchableStoryCount);
            }
            if (object.totalLikeCount != null) {
                if (typeof object.totalLikeCount !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.totalLikeCount: object expected");
                message.totalLikeCount = $root.google.protobuf.Int32Value.fromObject(object.totalLikeCount);
            }
            if (object.userReadingProgress != null) {
                if (typeof object.userReadingProgress !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.userReadingProgress: object expected");
                message.userReadingProgress = $root.query_recursive_types.SeriesReadingProgressResponse.fromObject(object.userReadingProgress);
            }
            if (object.characterList) {
                if (!Array.isArray(object.characterList))
                    throw TypeError(".query_recursive_types.SeriesResponse.characterList: array expected");
                message.characterList = [];
                for (var i = 0; i < object.characterList.length; ++i) {
                    if (typeof object.characterList[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesResponse.characterList: object expected");
                    message.characterList[i] = $root.query_recursive_types.CharacterResponse.fromObject(object.characterList[i]);
                }
            }
            switch (object.userAvailability) {
            case "SERIES_AVAILABILITY_UNSPECIFIED":
            case 0:
                message.userAvailability = 0;
                break;
            case "SERIES_AVAILABILITY_REQUIRE_FOLLOWING":
            case 1:
                message.userAvailability = 1;
                break;
            case "SERIES_AVAILABILITY_REQUIRE_FOLLOWED":
            case 2:
                message.userAvailability = 2;
                break;
            case "SERIES_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING":
            case 3:
                message.userAvailability = 3;
                break;
            case "SERIES_AVAILABILITY_REQUIRE_NONE":
            case 4:
                message.userAvailability = 4;
                break;
            }
            if (object.isOneshot != null) {
                if (typeof object.isOneshot !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.isOneshot: object expected");
                message.isOneshot = $root.google.protobuf.BoolValue.fromObject(object.isOneshot);
            }
            if (object.canChangeToOneshot != null) {
                if (typeof object.canChangeToOneshot !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.canChangeToOneshot: object expected");
                message.canChangeToOneshot = $root.google.protobuf.BoolValue.fromObject(object.canChangeToOneshot);
            }
            if (object.firstPublishedStory != null) {
                if (typeof object.firstPublishedStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.firstPublishedStory: object expected");
                message.firstPublishedStory = $root.query_recursive_types.StoryResponse.fromObject(object.firstPublishedStory);
            }
            if (object.recommenderModelId != null) {
                if (typeof object.recommenderModelId !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.recommenderModelId: object expected");
                message.recommenderModelId = $root.google.protobuf.StringValue.fromObject(object.recommenderModelId);
            }
            if (object.recommendedSeriesPage != null) {
                if (typeof object.recommendedSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.recommendedSeriesPage: object expected");
                message.recommendedSeriesPage = $root.query_recursive_types.SeriesPageResponse.fromObject(object.recommendedSeriesPage);
            }
            if (object.isUploadingImageLocked != null) {
                if (typeof object.isUploadingImageLocked !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.isUploadingImageLocked: object expected");
                message.isUploadingImageLocked = $root.google.protobuf.BoolValue.fromObject(object.isUploadingImageLocked);
            }
            if (object.showGuidelineDialog != null) {
                if (typeof object.showGuidelineDialog !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.showGuidelineDialog: object expected");
                message.showGuidelineDialog = $root.google.protobuf.BoolValue.fromObject(object.showGuidelineDialog);
            }
            if (object.canShowGoogleAd != null) {
                if (typeof object.canShowGoogleAd !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.canShowGoogleAd: object expected");
                message.canShowGoogleAd = $root.google.protobuf.BoolValue.fromObject(object.canShowGoogleAd);
            }
            switch (object.originalFlag) {
            case "ORIGINAL_FLAG_UNSPECIFIED":
            case 0:
                message.originalFlag = 0;
                break;
            case "ORIGINAL_FLAG_ORIGINAL":
            case 1:
                message.originalFlag = 1;
                break;
            case "ORIGINAL_FLAG_FANFIC":
            case 2:
                message.originalFlag = 2;
                break;
            case "ORIGINAL_FLAG_OTHER":
            case 3:
                message.originalFlag = 3;
                break;
            }
            switch (object.genre) {
            case "GENRE_UNSPECIFIED":
            case 0:
                message.genre = 0;
                break;
            case "GENRE_FANFIC":
            case 1:
                message.genre = 1;
                break;
            case "GENRE_OTHER":
            case 2:
                message.genre = 2;
                break;
            case "GENRE_HORROR_THRILLER":
            case 3:
                message.genre = 3;
                break;
            case "GENRE_ROMANCE_FANTASY":
            case 4:
                message.genre = 4;
                break;
            case "GENRE_BL":
            case 5:
                message.genre = 5;
                break;
            case "GENRE_DRAMA":
            case 6:
                message.genre = 6;
                break;
            case "GENRE_ROMANCE":
            case 7:
                message.genre = 7;
                break;
            case "GENRE_FANTASY":
            case 8:
                message.genre = 8;
                break;
            case "GENRE_COMEDY":
            case 9:
                message.genre = 9;
                break;
            }
            if (object.isSensitive != null) {
                if (typeof object.isSensitive !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.isSensitive: object expected");
                message.isSensitive = $root.google.protobuf.BoolValue.fromObject(object.isSensitive);
            }
            if (object.storyPage != null) {
                if (typeof object.storyPage !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.storyPage: object expected");
                message.storyPage = $root.query_recursive_types.SeriesStoryPageResponse.fromObject(object.storyPage);
            }
            if (object.searchStory != null) {
                if (typeof object.searchStory !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.searchStory: object expected");
                message.searchStory = $root.query_recursive_types.SearchStoryResponse.fromObject(object.searchStory);
            }
            if (object.sensitiveStoryCount != null) {
                if (typeof object.sensitiveStoryCount !== "object")
                    throw TypeError(".query_recursive_types.SeriesResponse.sensitiveStoryCount: object expected");
                message.sensitiveStoryCount = $root.google.protobuf.Int32Value.fromObject(object.sensitiveStoryCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesResponse
         * @static
         * @param {query_recursive_types.SeriesResponse} message SeriesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.tags = [];
                object.seriesGroupList = [];
                object.characterList = [];
            }
            if (options.defaults) {
                object.id = null;
                object.user = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.isOfficial = null;
                object.isCompleted = null;
                object.nextPublishAt = null;
                object.sharedWithStatus = options.enums === String ? "SHARED_WITH_STATUS_UNKNOWN" : 0;
                object.createdAt = null;
                object.updatedAt = null;
                object.hasVipOnlyStory = null;
                object.hasFreeStory = null;
                object.searchStory = null;
                object.seriesCharge = null;
                object.userBookmarked = null;
                object.publishSchedule = null;
                object.latestPublishedAt = null;
                object.hasNovelScriptStory = null;
                object.hasChatNovelScriptStory = null;
                object.searchableStoryCount = null;
                object.totalLikeCount = null;
                object.userReadingProgress = null;
                object.userAvailability = options.enums === String ? "SERIES_AVAILABILITY_UNSPECIFIED" : 0;
                object.sensitiveStoryCount = null;
                object.isOneshot = null;
                object.canChangeToOneshot = null;
                object.firstPublishedStory = null;
                object.recommenderModelId = null;
                object.recommendedSeriesPage = null;
                object.isUploadingImageLocked = null;
                object.showGuidelineDialog = null;
                object.canShowGoogleAd = null;
                object.originalFlag = options.enums === String ? "ORIGINAL_FLAG_UNSPECIFIED" : 0;
                object.genre = options.enums === String ? "GENRE_UNSPECIFIED" : 0;
                object.isSensitive = null;
                object.storyPage = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.google.protobuf.StringValue.toObject(message.tags[j], options);
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.query_recursive_types.ImageResponse.toObject(message.thumbnail, options);
            if (message.isOfficial != null && message.hasOwnProperty("isOfficial"))
                object.isOfficial = $root.google.protobuf.BoolValue.toObject(message.isOfficial, options);
            if (message.isCompleted != null && message.hasOwnProperty("isCompleted"))
                object.isCompleted = $root.google.protobuf.BoolValue.toObject(message.isCompleted, options);
            if (message.nextPublishAt != null && message.hasOwnProperty("nextPublishAt"))
                object.nextPublishAt = $root.google.protobuf.Timestamp.toObject(message.nextPublishAt, options);
            if (message.sharedWithStatus != null && message.hasOwnProperty("sharedWithStatus"))
                object.sharedWithStatus = options.enums === String ? $root.types.SharedWithStatus[message.sharedWithStatus] : message.sharedWithStatus;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.hasVipOnlyStory != null && message.hasOwnProperty("hasVipOnlyStory"))
                object.hasVipOnlyStory = $root.google.protobuf.BoolValue.toObject(message.hasVipOnlyStory, options);
            if (message.hasFreeStory != null && message.hasOwnProperty("hasFreeStory"))
                object.hasFreeStory = $root.google.protobuf.BoolValue.toObject(message.hasFreeStory, options);
            if (message.searchStory != null && message.hasOwnProperty("searchStory"))
                object.searchStory = $root.query_recursive_types.SearchStoryResponse.toObject(message.searchStory, options);
            if (message.seriesCharge != null && message.hasOwnProperty("seriesCharge"))
                object.seriesCharge = $root.query_series_charge.SeriesChargeResponse.toObject(message.seriesCharge, options);
            if (message.seriesGroupList && message.seriesGroupList.length) {
                object.seriesGroupList = [];
                for (var j = 0; j < message.seriesGroupList.length; ++j)
                    object.seriesGroupList[j] = $root.query_recursive_types.SeriesGroupResponse.toObject(message.seriesGroupList[j], options);
            }
            if (message.userBookmarked != null && message.hasOwnProperty("userBookmarked"))
                object.userBookmarked = $root.google.protobuf.BoolValue.toObject(message.userBookmarked, options);
            if (message.publishSchedule != null && message.hasOwnProperty("publishSchedule"))
                object.publishSchedule = $root.query_series.PublishScheduleResponse.toObject(message.publishSchedule, options);
            if (message.latestPublishedAt != null && message.hasOwnProperty("latestPublishedAt"))
                object.latestPublishedAt = $root.google.protobuf.Timestamp.toObject(message.latestPublishedAt, options);
            if (message.hasNovelScriptStory != null && message.hasOwnProperty("hasNovelScriptStory"))
                object.hasNovelScriptStory = $root.google.protobuf.BoolValue.toObject(message.hasNovelScriptStory, options);
            if (message.hasChatNovelScriptStory != null && message.hasOwnProperty("hasChatNovelScriptStory"))
                object.hasChatNovelScriptStory = $root.google.protobuf.BoolValue.toObject(message.hasChatNovelScriptStory, options);
            if (message.searchableStoryCount != null && message.hasOwnProperty("searchableStoryCount"))
                object.searchableStoryCount = $root.google.protobuf.Int32Value.toObject(message.searchableStoryCount, options);
            if (message.totalLikeCount != null && message.hasOwnProperty("totalLikeCount"))
                object.totalLikeCount = $root.google.protobuf.Int32Value.toObject(message.totalLikeCount, options);
            if (message.userReadingProgress != null && message.hasOwnProperty("userReadingProgress"))
                object.userReadingProgress = $root.query_recursive_types.SeriesReadingProgressResponse.toObject(message.userReadingProgress, options);
            if (message.characterList && message.characterList.length) {
                object.characterList = [];
                for (var j = 0; j < message.characterList.length; ++j)
                    object.characterList[j] = $root.query_recursive_types.CharacterResponse.toObject(message.characterList[j], options);
            }
            if (message.userAvailability != null && message.hasOwnProperty("userAvailability"))
                object.userAvailability = options.enums === String ? $root.query_types.SeriesAvailability[message.userAvailability] : message.userAvailability;
            if (message.sensitiveStoryCount != null && message.hasOwnProperty("sensitiveStoryCount"))
                object.sensitiveStoryCount = $root.google.protobuf.Int32Value.toObject(message.sensitiveStoryCount, options);
            if (message.isOneshot != null && message.hasOwnProperty("isOneshot"))
                object.isOneshot = $root.google.protobuf.BoolValue.toObject(message.isOneshot, options);
            if (message.canChangeToOneshot != null && message.hasOwnProperty("canChangeToOneshot"))
                object.canChangeToOneshot = $root.google.protobuf.BoolValue.toObject(message.canChangeToOneshot, options);
            if (message.firstPublishedStory != null && message.hasOwnProperty("firstPublishedStory"))
                object.firstPublishedStory = $root.query_recursive_types.StoryResponse.toObject(message.firstPublishedStory, options);
            if (message.recommenderModelId != null && message.hasOwnProperty("recommenderModelId"))
                object.recommenderModelId = $root.google.protobuf.StringValue.toObject(message.recommenderModelId, options);
            if (message.recommendedSeriesPage != null && message.hasOwnProperty("recommendedSeriesPage"))
                object.recommendedSeriesPage = $root.query_recursive_types.SeriesPageResponse.toObject(message.recommendedSeriesPage, options);
            if (message.isUploadingImageLocked != null && message.hasOwnProperty("isUploadingImageLocked"))
                object.isUploadingImageLocked = $root.google.protobuf.BoolValue.toObject(message.isUploadingImageLocked, options);
            if (message.showGuidelineDialog != null && message.hasOwnProperty("showGuidelineDialog"))
                object.showGuidelineDialog = $root.google.protobuf.BoolValue.toObject(message.showGuidelineDialog, options);
            if (message.canShowGoogleAd != null && message.hasOwnProperty("canShowGoogleAd"))
                object.canShowGoogleAd = $root.google.protobuf.BoolValue.toObject(message.canShowGoogleAd, options);
            if (message.originalFlag != null && message.hasOwnProperty("originalFlag"))
                object.originalFlag = options.enums === String ? $root.query_types.OriginalFlag[message.originalFlag] : message.originalFlag;
            if (message.genre != null && message.hasOwnProperty("genre"))
                object.genre = options.enums === String ? $root.query_types.Genre[message.genre] : message.genre;
            if (message.isSensitive != null && message.hasOwnProperty("isSensitive"))
                object.isSensitive = $root.google.protobuf.BoolValue.toObject(message.isSensitive, options);
            if (message.storyPage != null && message.hasOwnProperty("storyPage"))
                object.storyPage = $root.query_recursive_types.SeriesStoryPageResponse.toObject(message.storyPage, options);
            return object;
        };

        /**
         * Converts this SeriesResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesResponse";
        };

        return SeriesResponse;
    })();

    query_recursive_types.ScriptTypeFilter = (function() {

        /**
         * Properties of a ScriptTypeFilter.
         * @memberof query_recursive_types
         * @interface IScriptTypeFilter
         * @property {boolean|null} [excludeNovel] ScriptTypeFilter excludeNovel
         * @property {boolean|null} [excludeChatNovel] ScriptTypeFilter excludeChatNovel
         * @property {boolean|null} [excludeVideo] ScriptTypeFilter excludeVideo
         * @property {boolean|null} [excludeAudio] ScriptTypeFilter excludeAudio
         */

        /**
         * Constructs a new ScriptTypeFilter.
         * @memberof query_recursive_types
         * @classdesc Represents a ScriptTypeFilter.
         * @implements IScriptTypeFilter
         * @constructor
         * @param {query_recursive_types.IScriptTypeFilter=} [properties] Properties to set
         */
        function ScriptTypeFilter(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScriptTypeFilter excludeNovel.
         * @member {boolean} excludeNovel
         * @memberof query_recursive_types.ScriptTypeFilter
         * @instance
         */
        ScriptTypeFilter.prototype.excludeNovel = false;

        /**
         * ScriptTypeFilter excludeChatNovel.
         * @member {boolean} excludeChatNovel
         * @memberof query_recursive_types.ScriptTypeFilter
         * @instance
         */
        ScriptTypeFilter.prototype.excludeChatNovel = false;

        /**
         * ScriptTypeFilter excludeVideo.
         * @member {boolean} excludeVideo
         * @memberof query_recursive_types.ScriptTypeFilter
         * @instance
         */
        ScriptTypeFilter.prototype.excludeVideo = false;

        /**
         * ScriptTypeFilter excludeAudio.
         * @member {boolean} excludeAudio
         * @memberof query_recursive_types.ScriptTypeFilter
         * @instance
         */
        ScriptTypeFilter.prototype.excludeAudio = false;

        /**
         * Creates a new ScriptTypeFilter instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ScriptTypeFilter
         * @static
         * @param {query_recursive_types.IScriptTypeFilter=} [properties] Properties to set
         * @returns {query_recursive_types.ScriptTypeFilter} ScriptTypeFilter instance
         */
        ScriptTypeFilter.create = function create(properties) {
            return new ScriptTypeFilter(properties);
        };

        /**
         * Encodes the specified ScriptTypeFilter message. Does not implicitly {@link query_recursive_types.ScriptTypeFilter.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ScriptTypeFilter
         * @static
         * @param {query_recursive_types.IScriptTypeFilter} message ScriptTypeFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScriptTypeFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.excludeNovel != null && Object.hasOwnProperty.call(message, "excludeNovel"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.excludeNovel);
            if (message.excludeChatNovel != null && Object.hasOwnProperty.call(message, "excludeChatNovel"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.excludeChatNovel);
            if (message.excludeVideo != null && Object.hasOwnProperty.call(message, "excludeVideo"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.excludeVideo);
            if (message.excludeAudio != null && Object.hasOwnProperty.call(message, "excludeAudio"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.excludeAudio);
            return writer;
        };

        /**
         * Decodes a ScriptTypeFilter message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ScriptTypeFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ScriptTypeFilter} ScriptTypeFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScriptTypeFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ScriptTypeFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.excludeNovel = reader.bool();
                    break;
                case 2:
                    message.excludeChatNovel = reader.bool();
                    break;
                case 3:
                    message.excludeVideo = reader.bool();
                    break;
                case 4:
                    message.excludeAudio = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ScriptTypeFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ScriptTypeFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ScriptTypeFilter} ScriptTypeFilter
         */
        ScriptTypeFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ScriptTypeFilter)
                return object;
            var message = new $root.query_recursive_types.ScriptTypeFilter();
            if (object.excludeNovel != null)
                message.excludeNovel = Boolean(object.excludeNovel);
            if (object.excludeChatNovel != null)
                message.excludeChatNovel = Boolean(object.excludeChatNovel);
            if (object.excludeVideo != null)
                message.excludeVideo = Boolean(object.excludeVideo);
            if (object.excludeAudio != null)
                message.excludeAudio = Boolean(object.excludeAudio);
            return message;
        };

        /**
         * Creates a plain object from a ScriptTypeFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ScriptTypeFilter
         * @static
         * @param {query_recursive_types.ScriptTypeFilter} message ScriptTypeFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScriptTypeFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.excludeNovel = false;
                object.excludeChatNovel = false;
                object.excludeVideo = false;
                object.excludeAudio = false;
            }
            if (message.excludeNovel != null && message.hasOwnProperty("excludeNovel"))
                object.excludeNovel = message.excludeNovel;
            if (message.excludeChatNovel != null && message.hasOwnProperty("excludeChatNovel"))
                object.excludeChatNovel = message.excludeChatNovel;
            if (message.excludeVideo != null && message.hasOwnProperty("excludeVideo"))
                object.excludeVideo = message.excludeVideo;
            if (message.excludeAudio != null && message.hasOwnProperty("excludeAudio"))
                object.excludeAudio = message.excludeAudio;
            return object;
        };

        /**
         * Converts this ScriptTypeFilter to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ScriptTypeFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScriptTypeFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ScriptTypeFilter
         * @function getTypeUrl
         * @memberof query_recursive_types.ScriptTypeFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ScriptTypeFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ScriptTypeFilter";
        };

        return ScriptTypeFilter;
    })();

    query_recursive_types.SearchSeriesRequest = (function() {

        /**
         * Properties of a SearchSeriesRequest.
         * @memberof query_recursive_types
         * @interface ISearchSeriesRequest
         * @property {query_search_types.ISearchSeriesParams|null} [params] SearchSeriesRequest params
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SearchSeriesRequest requireSeriesPage
         */

        /**
         * Constructs a new SearchSeriesRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchSeriesRequest.
         * @implements ISearchSeriesRequest
         * @constructor
         * @param {query_recursive_types.ISearchSeriesRequest=} [properties] Properties to set
         */
        function SearchSeriesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesRequest params.
         * @member {query_search_types.ISearchSeriesParams|null|undefined} params
         * @memberof query_recursive_types.SearchSeriesRequest
         * @instance
         */
        SearchSeriesRequest.prototype.params = null;

        /**
         * SearchSeriesRequest requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_recursive_types.SearchSeriesRequest
         * @instance
         */
        SearchSeriesRequest.prototype.requireSeriesPage = null;

        /**
         * Creates a new SearchSeriesRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchSeriesRequest
         * @static
         * @param {query_recursive_types.ISearchSeriesRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SearchSeriesRequest} SearchSeriesRequest instance
         */
        SearchSeriesRequest.create = function create(properties) {
            return new SearchSeriesRequest(properties);
        };

        /**
         * Encodes the specified SearchSeriesRequest message. Does not implicitly {@link query_recursive_types.SearchSeriesRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchSeriesRequest
         * @static
         * @param {query_recursive_types.ISearchSeriesRequest} message SearchSeriesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                $root.query_search_types.SearchSeriesParams.encode(message.params, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchSeriesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchSeriesRequest} SearchSeriesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchSeriesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = $root.query_search_types.SearchSeriesParams.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchSeriesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchSeriesRequest} SearchSeriesRequest
         */
        SearchSeriesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchSeriesRequest)
                return object;
            var message = new $root.query_recursive_types.SearchSeriesRequest();
            if (object.params != null) {
                if (typeof object.params !== "object")
                    throw TypeError(".query_recursive_types.SearchSeriesRequest.params: object expected");
                message.params = $root.query_search_types.SearchSeriesParams.fromObject(object.params);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.SearchSeriesRequest.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchSeriesRequest
         * @static
         * @param {query_recursive_types.SearchSeriesRequest} message SearchSeriesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.params = null;
                object.requireSeriesPage = null;
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = $root.query_search_types.SearchSeriesParams.toObject(message.params, options);
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            return object;
        };

        /**
         * Converts this SearchSeriesRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchSeriesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchSeriesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchSeriesRequest";
        };

        return SearchSeriesRequest;
    })();

    query_recursive_types.SearchSeriesResponse = (function() {

        /**
         * Properties of a SearchSeriesResponse.
         * @memberof query_recursive_types
         * @interface ISearchSeriesResponse
         * @property {query_recursive_types.ISeriesPageResponse|null} [seriesPage] SearchSeriesResponse seriesPage
         */

        /**
         * Constructs a new SearchSeriesResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchSeriesResponse.
         * @implements ISearchSeriesResponse
         * @constructor
         * @param {query_recursive_types.ISearchSeriesResponse=} [properties] Properties to set
         */
        function SearchSeriesResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesResponse seriesPage.
         * @member {query_recursive_types.ISeriesPageResponse|null|undefined} seriesPage
         * @memberof query_recursive_types.SearchSeriesResponse
         * @instance
         */
        SearchSeriesResponse.prototype.seriesPage = null;

        /**
         * Creates a new SearchSeriesResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchSeriesResponse
         * @static
         * @param {query_recursive_types.ISearchSeriesResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SearchSeriesResponse} SearchSeriesResponse instance
         */
        SearchSeriesResponse.create = function create(properties) {
            return new SearchSeriesResponse(properties);
        };

        /**
         * Encodes the specified SearchSeriesResponse message. Does not implicitly {@link query_recursive_types.SearchSeriesResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchSeriesResponse
         * @static
         * @param {query_recursive_types.ISearchSeriesResponse} message SearchSeriesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesPage != null && Object.hasOwnProperty.call(message, "seriesPage"))
                $root.query_recursive_types.SeriesPageResponse.encode(message.seriesPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchSeriesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchSeriesResponse} SearchSeriesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchSeriesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesPage = $root.query_recursive_types.SeriesPageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchSeriesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchSeriesResponse} SearchSeriesResponse
         */
        SearchSeriesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchSeriesResponse)
                return object;
            var message = new $root.query_recursive_types.SearchSeriesResponse();
            if (object.seriesPage != null) {
                if (typeof object.seriesPage !== "object")
                    throw TypeError(".query_recursive_types.SearchSeriesResponse.seriesPage: object expected");
                message.seriesPage = $root.query_recursive_types.SeriesPageResponse.fromObject(object.seriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchSeriesResponse
         * @static
         * @param {query_recursive_types.SearchSeriesResponse} message SearchSeriesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seriesPage = null;
            if (message.seriesPage != null && message.hasOwnProperty("seriesPage"))
                object.seriesPage = $root.query_recursive_types.SeriesPageResponse.toObject(message.seriesPage, options);
            return object;
        };

        /**
         * Converts this SearchSeriesResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchSeriesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchSeriesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchSeriesResponse";
        };

        return SearchSeriesResponse;
    })();

    query_recursive_types.RecommendSeriesRequest = (function() {

        /**
         * Properties of a RecommendSeriesRequest.
         * @memberof query_recursive_types
         * @interface IRecommendSeriesRequest
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] RecommendSeriesRequest requireSeriesPage
         * @property {query_recursive_types.IScriptTypeFilter|null} [scriptTypeFilter] RecommendSeriesRequest scriptTypeFilter
         */

        /**
         * Constructs a new RecommendSeriesRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a RecommendSeriesRequest.
         * @implements IRecommendSeriesRequest
         * @constructor
         * @param {query_recursive_types.IRecommendSeriesRequest=} [properties] Properties to set
         */
        function RecommendSeriesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecommendSeriesRequest requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @instance
         */
        RecommendSeriesRequest.prototype.requireSeriesPage = null;

        /**
         * RecommendSeriesRequest scriptTypeFilter.
         * @member {query_recursive_types.IScriptTypeFilter|null|undefined} scriptTypeFilter
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @instance
         */
        RecommendSeriesRequest.prototype.scriptTypeFilter = null;

        /**
         * Creates a new RecommendSeriesRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @static
         * @param {query_recursive_types.IRecommendSeriesRequest=} [properties] Properties to set
         * @returns {query_recursive_types.RecommendSeriesRequest} RecommendSeriesRequest instance
         */
        RecommendSeriesRequest.create = function create(properties) {
            return new RecommendSeriesRequest(properties);
        };

        /**
         * Encodes the specified RecommendSeriesRequest message. Does not implicitly {@link query_recursive_types.RecommendSeriesRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @static
         * @param {query_recursive_types.IRecommendSeriesRequest} message RecommendSeriesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecommendSeriesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.scriptTypeFilter != null && Object.hasOwnProperty.call(message, "scriptTypeFilter"))
                $root.query_recursive_types.ScriptTypeFilter.encode(message.scriptTypeFilter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RecommendSeriesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.RecommendSeriesRequest} RecommendSeriesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecommendSeriesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.RecommendSeriesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a RecommendSeriesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.RecommendSeriesRequest} RecommendSeriesRequest
         */
        RecommendSeriesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.RecommendSeriesRequest)
                return object;
            var message = new $root.query_recursive_types.RecommendSeriesRequest();
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.RecommendSeriesRequest.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            if (object.scriptTypeFilter != null) {
                if (typeof object.scriptTypeFilter !== "object")
                    throw TypeError(".query_recursive_types.RecommendSeriesRequest.scriptTypeFilter: object expected");
                message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.fromObject(object.scriptTypeFilter);
            }
            return message;
        };

        /**
         * Creates a plain object from a RecommendSeriesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @static
         * @param {query_recursive_types.RecommendSeriesRequest} message RecommendSeriesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecommendSeriesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireSeriesPage = null;
                object.scriptTypeFilter = null;
            }
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            if (message.scriptTypeFilter != null && message.hasOwnProperty("scriptTypeFilter"))
                object.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.toObject(message.scriptTypeFilter, options);
            return object;
        };

        /**
         * Converts this RecommendSeriesRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecommendSeriesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecommendSeriesRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.RecommendSeriesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecommendSeriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.RecommendSeriesRequest";
        };

        return RecommendSeriesRequest;
    })();

    query_recursive_types.SeriesPageRequest = (function() {

        /**
         * Properties of a SeriesPageRequest.
         * @memberof query_recursive_types
         * @interface ISeriesPageRequest
         * @property {query_recursive_types.ISeriesRequireField|null} [requireSeriesList] SeriesPageRequest requireSeriesList
         * @property {query_pagination.IPageRequest|null} [page] SeriesPageRequest page
         */

        /**
         * Constructs a new SeriesPageRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesPageRequest.
         * @implements ISeriesPageRequest
         * @constructor
         * @param {query_recursive_types.ISeriesPageRequest=} [properties] Properties to set
         */
        function SeriesPageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesPageRequest requireSeriesList.
         * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeriesList
         * @memberof query_recursive_types.SeriesPageRequest
         * @instance
         */
        SeriesPageRequest.prototype.requireSeriesList = null;

        /**
         * SeriesPageRequest page.
         * @member {query_pagination.IPageRequest|null|undefined} page
         * @memberof query_recursive_types.SeriesPageRequest
         * @instance
         */
        SeriesPageRequest.prototype.page = null;

        /**
         * Creates a new SeriesPageRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesPageRequest
         * @static
         * @param {query_recursive_types.ISeriesPageRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesPageRequest} SeriesPageRequest instance
         */
        SeriesPageRequest.create = function create(properties) {
            return new SeriesPageRequest(properties);
        };

        /**
         * Encodes the specified SeriesPageRequest message. Does not implicitly {@link query_recursive_types.SeriesPageRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesPageRequest
         * @static
         * @param {query_recursive_types.ISeriesPageRequest} message SeriesPageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesPageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSeriesList != null && Object.hasOwnProperty.call(message, "requireSeriesList"))
                $root.query_recursive_types.SeriesRequireField.encode(message.requireSeriesList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageRequest.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesPageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesPageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesPageRequest} SeriesPageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesPageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesPageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSeriesList = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.page = $root.query_pagination.PageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesPageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesPageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesPageRequest} SeriesPageRequest
         */
        SeriesPageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesPageRequest)
                return object;
            var message = new $root.query_recursive_types.SeriesPageRequest();
            if (object.requireSeriesList != null) {
                if (typeof object.requireSeriesList !== "object")
                    throw TypeError(".query_recursive_types.SeriesPageRequest.requireSeriesList: object expected");
                message.requireSeriesList = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeriesList);
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.SeriesPageRequest.page: object expected");
                message.page = $root.query_pagination.PageRequest.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesPageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesPageRequest
         * @static
         * @param {query_recursive_types.SeriesPageRequest} message SeriesPageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesPageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireSeriesList = null;
                object.page = null;
            }
            if (message.requireSeriesList != null && message.hasOwnProperty("requireSeriesList"))
                object.requireSeriesList = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeriesList, options);
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageRequest.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this SeriesPageRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesPageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesPageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesPageRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesPageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesPageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesPageRequest";
        };

        return SeriesPageRequest;
    })();

    query_recursive_types.SeriesPageResponse = (function() {

        /**
         * Properties of a SeriesPageResponse.
         * @memberof query_recursive_types
         * @interface ISeriesPageResponse
         * @property {Array.<query_recursive_types.ISeriesResponse>|null} [seriesList] SeriesPageResponse seriesList
         * @property {query_pagination.IPageInfo|null} [page] SeriesPageResponse page
         */

        /**
         * Constructs a new SeriesPageResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesPageResponse.
         * @implements ISeriesPageResponse
         * @constructor
         * @param {query_recursive_types.ISeriesPageResponse=} [properties] Properties to set
         */
        function SeriesPageResponse(properties) {
            this.seriesList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesPageResponse seriesList.
         * @member {Array.<query_recursive_types.ISeriesResponse>} seriesList
         * @memberof query_recursive_types.SeriesPageResponse
         * @instance
         */
        SeriesPageResponse.prototype.seriesList = $util.emptyArray;

        /**
         * SeriesPageResponse page.
         * @member {query_pagination.IPageInfo|null|undefined} page
         * @memberof query_recursive_types.SeriesPageResponse
         * @instance
         */
        SeriesPageResponse.prototype.page = null;

        /**
         * Creates a new SeriesPageResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesPageResponse
         * @static
         * @param {query_recursive_types.ISeriesPageResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesPageResponse} SeriesPageResponse instance
         */
        SeriesPageResponse.create = function create(properties) {
            return new SeriesPageResponse(properties);
        };

        /**
         * Encodes the specified SeriesPageResponse message. Does not implicitly {@link query_recursive_types.SeriesPageResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesPageResponse
         * @static
         * @param {query_recursive_types.ISeriesPageResponse} message SeriesPageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesList != null && message.seriesList.length)
                for (var i = 0; i < message.seriesList.length; ++i)
                    $root.query_recursive_types.SeriesResponse.encode(message.seriesList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageInfo.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesPageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesPageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesPageResponse} SeriesPageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.seriesList && message.seriesList.length))
                        message.seriesList = [];
                    message.seriesList.push($root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = $root.query_pagination.PageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesPageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesPageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesPageResponse} SeriesPageResponse
         */
        SeriesPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesPageResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesPageResponse();
            if (object.seriesList) {
                if (!Array.isArray(object.seriesList))
                    throw TypeError(".query_recursive_types.SeriesPageResponse.seriesList: array expected");
                message.seriesList = [];
                for (var i = 0; i < object.seriesList.length; ++i) {
                    if (typeof object.seriesList[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesPageResponse.seriesList: object expected");
                    message.seriesList[i] = $root.query_recursive_types.SeriesResponse.fromObject(object.seriesList[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.SeriesPageResponse.page: object expected");
                message.page = $root.query_pagination.PageInfo.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesPageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesPageResponse
         * @static
         * @param {query_recursive_types.SeriesPageResponse} message SeriesPageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.seriesList = [];
            if (options.defaults)
                object.page = null;
            if (message.seriesList && message.seriesList.length) {
                object.seriesList = [];
                for (var j = 0; j < message.seriesList.length; ++j)
                    object.seriesList[j] = $root.query_recursive_types.SeriesResponse.toObject(message.seriesList[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageInfo.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this SeriesPageResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesPageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesPageResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesPageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesPageResponse";
        };

        return SeriesPageResponse;
    })();

    query_recursive_types.SeriesGroupRequest = (function() {

        /**
         * Properties of a SeriesGroupRequest.
         * @memberof query_recursive_types
         * @interface ISeriesGroupRequest
         * @property {google.protobuf.IStringValue|null} [id] SeriesGroupRequest id
         * @property {query_recursive_types.ISeriesGroupRequireField|null} [requireSeriesGroup] SeriesGroupRequest requireSeriesGroup
         */

        /**
         * Constructs a new SeriesGroupRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesGroupRequest.
         * @implements ISeriesGroupRequest
         * @constructor
         * @param {query_recursive_types.ISeriesGroupRequest=} [properties] Properties to set
         */
        function SeriesGroupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGroupRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.SeriesGroupRequest
         * @instance
         */
        SeriesGroupRequest.prototype.id = null;

        /**
         * SeriesGroupRequest requireSeriesGroup.
         * @member {query_recursive_types.ISeriesGroupRequireField|null|undefined} requireSeriesGroup
         * @memberof query_recursive_types.SeriesGroupRequest
         * @instance
         */
        SeriesGroupRequest.prototype.requireSeriesGroup = null;

        /**
         * Creates a new SeriesGroupRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesGroupRequest
         * @static
         * @param {query_recursive_types.ISeriesGroupRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesGroupRequest} SeriesGroupRequest instance
         */
        SeriesGroupRequest.create = function create(properties) {
            return new SeriesGroupRequest(properties);
        };

        /**
         * Encodes the specified SeriesGroupRequest message. Does not implicitly {@link query_recursive_types.SeriesGroupRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesGroupRequest
         * @static
         * @param {query_recursive_types.ISeriesGroupRequest} message SeriesGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGroupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireSeriesGroup != null && Object.hasOwnProperty.call(message, "requireSeriesGroup"))
                $root.query_recursive_types.SeriesGroupRequireField.encode(message.requireSeriesGroup, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGroupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesGroupRequest} SeriesGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGroupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesGroupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeriesGroup = $root.query_recursive_types.SeriesGroupRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGroupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesGroupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesGroupRequest} SeriesGroupRequest
         */
        SeriesGroupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesGroupRequest)
                return object;
            var message = new $root.query_recursive_types.SeriesGroupRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireSeriesGroup != null) {
                if (typeof object.requireSeriesGroup !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequest.requireSeriesGroup: object expected");
                message.requireSeriesGroup = $root.query_recursive_types.SeriesGroupRequireField.fromObject(object.requireSeriesGroup);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGroupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesGroupRequest
         * @static
         * @param {query_recursive_types.SeriesGroupRequest} message SeriesGroupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGroupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireSeriesGroup = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireSeriesGroup != null && message.hasOwnProperty("requireSeriesGroup"))
                object.requireSeriesGroup = $root.query_recursive_types.SeriesGroupRequireField.toObject(message.requireSeriesGroup, options);
            return object;
        };

        /**
         * Converts this SeriesGroupRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesGroupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGroupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGroupRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesGroupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGroupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesGroupRequest";
        };

        return SeriesGroupRequest;
    })();

    query_recursive_types.SeriesGroupRequireField = (function() {

        /**
         * Properties of a SeriesGroupRequireField.
         * @memberof query_recursive_types
         * @interface ISeriesGroupRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] SeriesGroupRequireField requireId
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] SeriesGroupRequireField requireUser
         * @property {google.protobuf.IBoolValue|null} [requireTitle] SeriesGroupRequireField requireTitle
         * @property {google.protobuf.IBoolValue|null} [requireDescription] SeriesGroupRequireField requireDescription
         * @property {google.protobuf.IBoolValue|null} [requireSharedWithStatus] SeriesGroupRequireField requireSharedWithStatus
         * @property {query_recursive_types.ISeriesRequireField|null} [requireSeriesList] SeriesGroupRequireField requireSeriesList
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] SeriesGroupRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] SeriesGroupRequireField requireUpdatedAt
         */

        /**
         * Constructs a new SeriesGroupRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesGroupRequireField.
         * @implements ISeriesGroupRequireField
         * @constructor
         * @param {query_recursive_types.ISeriesGroupRequireField=} [properties] Properties to set
         */
        function SeriesGroupRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGroupRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireId = null;

        /**
         * SeriesGroupRequireField requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireUser = null;

        /**
         * SeriesGroupRequireField requireTitle.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTitle
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireTitle = null;

        /**
         * SeriesGroupRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireDescription = null;

        /**
         * SeriesGroupRequireField requireSharedWithStatus.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSharedWithStatus
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireSharedWithStatus = null;

        /**
         * SeriesGroupRequireField requireSeriesList.
         * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeriesList
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireSeriesList = null;

        /**
         * SeriesGroupRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireCreatedAt = null;

        /**
         * SeriesGroupRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         */
        SeriesGroupRequireField.prototype.requireUpdatedAt = null;

        /**
         * Creates a new SeriesGroupRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @static
         * @param {query_recursive_types.ISeriesGroupRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesGroupRequireField} SeriesGroupRequireField instance
         */
        SeriesGroupRequireField.create = function create(properties) {
            return new SeriesGroupRequireField(properties);
        };

        /**
         * Encodes the specified SeriesGroupRequireField message. Does not implicitly {@link query_recursive_types.SeriesGroupRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @static
         * @param {query_recursive_types.ISeriesGroupRequireField} message SeriesGroupRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGroupRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTitle != null && Object.hasOwnProperty.call(message, "requireTitle"))
                $root.google.protobuf.BoolValue.encode(message.requireTitle, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireSharedWithStatus != null && Object.hasOwnProperty.call(message, "requireSharedWithStatus"))
                $root.google.protobuf.BoolValue.encode(message.requireSharedWithStatus, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireSeriesList != null && Object.hasOwnProperty.call(message, "requireSeriesList"))
                $root.query_recursive_types.SeriesRequireField.encode(message.requireSeriesList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGroupRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesGroupRequireField} SeriesGroupRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGroupRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesGroupRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTitle = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireSharedWithStatus = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireSeriesList = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGroupRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesGroupRequireField} SeriesGroupRequireField
         */
        SeriesGroupRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesGroupRequireField)
                return object;
            var message = new $root.query_recursive_types.SeriesGroupRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireTitle != null) {
                if (typeof object.requireTitle !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireTitle: object expected");
                message.requireTitle = $root.google.protobuf.BoolValue.fromObject(object.requireTitle);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireSharedWithStatus != null) {
                if (typeof object.requireSharedWithStatus !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireSharedWithStatus: object expected");
                message.requireSharedWithStatus = $root.google.protobuf.BoolValue.fromObject(object.requireSharedWithStatus);
            }
            if (object.requireSeriesList != null) {
                if (typeof object.requireSeriesList !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireSeriesList: object expected");
                message.requireSeriesList = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeriesList);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGroupRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @static
         * @param {query_recursive_types.SeriesGroupRequireField} message SeriesGroupRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGroupRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireUser = null;
                object.requireTitle = null;
                object.requireDescription = null;
                object.requireSharedWithStatus = null;
                object.requireSeriesList = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireTitle != null && message.hasOwnProperty("requireTitle"))
                object.requireTitle = $root.google.protobuf.BoolValue.toObject(message.requireTitle, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireSharedWithStatus != null && message.hasOwnProperty("requireSharedWithStatus"))
                object.requireSharedWithStatus = $root.google.protobuf.BoolValue.toObject(message.requireSharedWithStatus, options);
            if (message.requireSeriesList != null && message.hasOwnProperty("requireSeriesList"))
                object.requireSeriesList = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeriesList, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            return object;
        };

        /**
         * Converts this SeriesGroupRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGroupRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGroupRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesGroupRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGroupRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesGroupRequireField";
        };

        return SeriesGroupRequireField;
    })();

    query_recursive_types.SeriesGroupResponse = (function() {

        /**
         * Properties of a SeriesGroupResponse.
         * @memberof query_recursive_types
         * @interface ISeriesGroupResponse
         * @property {google.protobuf.IStringValue|null} [id] SeriesGroupResponse id
         * @property {query_recursive_types.IUserResponse|null} [user] SeriesGroupResponse user
         * @property {google.protobuf.IStringValue|null} [title] SeriesGroupResponse title
         * @property {google.protobuf.IStringValue|null} [description] SeriesGroupResponse description
         * @property {types.SharedWithStatus|null} [sharedWithStatus] SeriesGroupResponse sharedWithStatus
         * @property {Array.<query_recursive_types.ISeriesResponse>|null} [seriesList] SeriesGroupResponse seriesList
         * @property {google.protobuf.ITimestamp|null} [createdAt] SeriesGroupResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] SeriesGroupResponse updatedAt
         */

        /**
         * Constructs a new SeriesGroupResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesGroupResponse.
         * @implements ISeriesGroupResponse
         * @constructor
         * @param {query_recursive_types.ISeriesGroupResponse=} [properties] Properties to set
         */
        function SeriesGroupResponse(properties) {
            this.seriesList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGroupResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.id = null;

        /**
         * SeriesGroupResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.user = null;

        /**
         * SeriesGroupResponse title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.title = null;

        /**
         * SeriesGroupResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.description = null;

        /**
         * SeriesGroupResponse sharedWithStatus.
         * @member {types.SharedWithStatus} sharedWithStatus
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.sharedWithStatus = 0;

        /**
         * SeriesGroupResponse seriesList.
         * @member {Array.<query_recursive_types.ISeriesResponse>} seriesList
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.seriesList = $util.emptyArray;

        /**
         * SeriesGroupResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.createdAt = null;

        /**
         * SeriesGroupResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         */
        SeriesGroupResponse.prototype.updatedAt = null;

        /**
         * Creates a new SeriesGroupResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesGroupResponse
         * @static
         * @param {query_recursive_types.ISeriesGroupResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesGroupResponse} SeriesGroupResponse instance
         */
        SeriesGroupResponse.create = function create(properties) {
            return new SeriesGroupResponse(properties);
        };

        /**
         * Encodes the specified SeriesGroupResponse message. Does not implicitly {@link query_recursive_types.SeriesGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesGroupResponse
         * @static
         * @param {query_recursive_types.ISeriesGroupResponse} message SeriesGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGroupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.sharedWithStatus != null && Object.hasOwnProperty.call(message, "sharedWithStatus"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sharedWithStatus);
            if (message.seriesList != null && message.seriesList.length)
                for (var i = 0; i < message.seriesList.length; ++i)
                    $root.query_recursive_types.SeriesResponse.encode(message.seriesList[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesGroupResponse} SeriesGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGroupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesGroupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.sharedWithStatus = reader.int32();
                    break;
                case 6:
                    if (!(message.seriesList && message.seriesList.length))
                        message.seriesList = [];
                    message.seriesList.push($root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGroupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesGroupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesGroupResponse} SeriesGroupResponse
         */
        SeriesGroupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesGroupResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesGroupResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupResponse.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            switch (object.sharedWithStatus) {
            case "SHARED_WITH_STATUS_UNKNOWN":
            case 0:
                message.sharedWithStatus = 0;
                break;
            case "SHARED_WITH_NOBODY":
            case 1:
                message.sharedWithStatus = 1;
                break;
            case "SHARED_WITH_DIRECT_LINK":
            case 2:
                message.sharedWithStatus = 2;
                break;
            case "SHARED_WITH_PUBLIC":
            case 3:
                message.sharedWithStatus = 3;
                break;
            case "SHARED_WITH_FOLLOWER":
            case 4:
                message.sharedWithStatus = 4;
                break;
            case "SHARED_WITH_MUTUAL_FOLLOWER":
            case 5:
                message.sharedWithStatus = 5;
                break;
            }
            if (object.seriesList) {
                if (!Array.isArray(object.seriesList))
                    throw TypeError(".query_recursive_types.SeriesGroupResponse.seriesList: array expected");
                message.seriesList = [];
                for (var i = 0; i < object.seriesList.length; ++i) {
                    if (typeof object.seriesList[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesGroupResponse.seriesList: object expected");
                    message.seriesList[i] = $root.query_recursive_types.SeriesResponse.fromObject(object.seriesList[i]);
                }
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGroupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesGroupResponse
         * @static
         * @param {query_recursive_types.SeriesGroupResponse} message SeriesGroupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGroupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.seriesList = [];
            if (options.defaults) {
                object.id = null;
                object.user = null;
                object.title = null;
                object.description = null;
                object.sharedWithStatus = options.enums === String ? "SHARED_WITH_STATUS_UNKNOWN" : 0;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.sharedWithStatus != null && message.hasOwnProperty("sharedWithStatus"))
                object.sharedWithStatus = options.enums === String ? $root.types.SharedWithStatus[message.sharedWithStatus] : message.sharedWithStatus;
            if (message.seriesList && message.seriesList.length) {
                object.seriesList = [];
                for (var j = 0; j < message.seriesList.length; ++j)
                    object.seriesList[j] = $root.query_recursive_types.SeriesResponse.toObject(message.seriesList[j], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this SeriesGroupResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesGroupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGroupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGroupResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesGroupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGroupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesGroupResponse";
        };

        return SeriesGroupResponse;
    })();

    query_recursive_types.SearchSeriesGroupRequest = (function() {

        /**
         * Properties of a SearchSeriesGroupRequest.
         * @memberof query_recursive_types
         * @interface ISearchSeriesGroupRequest
         * @property {query_search_types.ISearchSeriesGroupParams|null} [params] SearchSeriesGroupRequest params
         * @property {query_recursive_types.ISeriesGroupPageRequest|null} [requireSeriesGroupPage] SearchSeriesGroupRequest requireSeriesGroupPage
         */

        /**
         * Constructs a new SearchSeriesGroupRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchSeriesGroupRequest.
         * @implements ISearchSeriesGroupRequest
         * @constructor
         * @param {query_recursive_types.ISearchSeriesGroupRequest=} [properties] Properties to set
         */
        function SearchSeriesGroupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesGroupRequest params.
         * @member {query_search_types.ISearchSeriesGroupParams|null|undefined} params
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @instance
         */
        SearchSeriesGroupRequest.prototype.params = null;

        /**
         * SearchSeriesGroupRequest requireSeriesGroupPage.
         * @member {query_recursive_types.ISeriesGroupPageRequest|null|undefined} requireSeriesGroupPage
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @instance
         */
        SearchSeriesGroupRequest.prototype.requireSeriesGroupPage = null;

        /**
         * Creates a new SearchSeriesGroupRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @static
         * @param {query_recursive_types.ISearchSeriesGroupRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SearchSeriesGroupRequest} SearchSeriesGroupRequest instance
         */
        SearchSeriesGroupRequest.create = function create(properties) {
            return new SearchSeriesGroupRequest(properties);
        };

        /**
         * Encodes the specified SearchSeriesGroupRequest message. Does not implicitly {@link query_recursive_types.SearchSeriesGroupRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @static
         * @param {query_recursive_types.ISearchSeriesGroupRequest} message SearchSeriesGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesGroupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                $root.query_search_types.SearchSeriesGroupParams.encode(message.params, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireSeriesGroupPage != null && Object.hasOwnProperty.call(message, "requireSeriesGroupPage"))
                $root.query_recursive_types.SeriesGroupPageRequest.encode(message.requireSeriesGroupPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesGroupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchSeriesGroupRequest} SearchSeriesGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesGroupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchSeriesGroupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = $root.query_search_types.SearchSeriesGroupParams.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeriesGroupPage = $root.query_recursive_types.SeriesGroupPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesGroupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchSeriesGroupRequest} SearchSeriesGroupRequest
         */
        SearchSeriesGroupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchSeriesGroupRequest)
                return object;
            var message = new $root.query_recursive_types.SearchSeriesGroupRequest();
            if (object.params != null) {
                if (typeof object.params !== "object")
                    throw TypeError(".query_recursive_types.SearchSeriesGroupRequest.params: object expected");
                message.params = $root.query_search_types.SearchSeriesGroupParams.fromObject(object.params);
            }
            if (object.requireSeriesGroupPage != null) {
                if (typeof object.requireSeriesGroupPage !== "object")
                    throw TypeError(".query_recursive_types.SearchSeriesGroupRequest.requireSeriesGroupPage: object expected");
                message.requireSeriesGroupPage = $root.query_recursive_types.SeriesGroupPageRequest.fromObject(object.requireSeriesGroupPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesGroupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @static
         * @param {query_recursive_types.SearchSeriesGroupRequest} message SearchSeriesGroupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesGroupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.params = null;
                object.requireSeriesGroupPage = null;
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = $root.query_search_types.SearchSeriesGroupParams.toObject(message.params, options);
            if (message.requireSeriesGroupPage != null && message.hasOwnProperty("requireSeriesGroupPage"))
                object.requireSeriesGroupPage = $root.query_recursive_types.SeriesGroupPageRequest.toObject(message.requireSeriesGroupPage, options);
            return object;
        };

        /**
         * Converts this SearchSeriesGroupRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesGroupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesGroupRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchSeriesGroupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesGroupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchSeriesGroupRequest";
        };

        return SearchSeriesGroupRequest;
    })();

    query_recursive_types.SearchSeriesGroupResponse = (function() {

        /**
         * Properties of a SearchSeriesGroupResponse.
         * @memberof query_recursive_types
         * @interface ISearchSeriesGroupResponse
         * @property {query_recursive_types.ISeriesGroupPageResponse|null} [seriesGroupPage] SearchSeriesGroupResponse seriesGroupPage
         */

        /**
         * Constructs a new SearchSeriesGroupResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SearchSeriesGroupResponse.
         * @implements ISearchSeriesGroupResponse
         * @constructor
         * @param {query_recursive_types.ISearchSeriesGroupResponse=} [properties] Properties to set
         */
        function SearchSeriesGroupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesGroupResponse seriesGroupPage.
         * @member {query_recursive_types.ISeriesGroupPageResponse|null|undefined} seriesGroupPage
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @instance
         */
        SearchSeriesGroupResponse.prototype.seriesGroupPage = null;

        /**
         * Creates a new SearchSeriesGroupResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @static
         * @param {query_recursive_types.ISearchSeriesGroupResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SearchSeriesGroupResponse} SearchSeriesGroupResponse instance
         */
        SearchSeriesGroupResponse.create = function create(properties) {
            return new SearchSeriesGroupResponse(properties);
        };

        /**
         * Encodes the specified SearchSeriesGroupResponse message. Does not implicitly {@link query_recursive_types.SearchSeriesGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @static
         * @param {query_recursive_types.ISearchSeriesGroupResponse} message SearchSeriesGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesGroupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesGroupPage != null && Object.hasOwnProperty.call(message, "seriesGroupPage"))
                $root.query_recursive_types.SeriesGroupPageResponse.encode(message.seriesGroupPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SearchSeriesGroupResponse} SearchSeriesGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesGroupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SearchSeriesGroupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesGroupPage = $root.query_recursive_types.SeriesGroupPageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesGroupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SearchSeriesGroupResponse} SearchSeriesGroupResponse
         */
        SearchSeriesGroupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SearchSeriesGroupResponse)
                return object;
            var message = new $root.query_recursive_types.SearchSeriesGroupResponse();
            if (object.seriesGroupPage != null) {
                if (typeof object.seriesGroupPage !== "object")
                    throw TypeError(".query_recursive_types.SearchSeriesGroupResponse.seriesGroupPage: object expected");
                message.seriesGroupPage = $root.query_recursive_types.SeriesGroupPageResponse.fromObject(object.seriesGroupPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesGroupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @static
         * @param {query_recursive_types.SearchSeriesGroupResponse} message SearchSeriesGroupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesGroupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seriesGroupPage = null;
            if (message.seriesGroupPage != null && message.hasOwnProperty("seriesGroupPage"))
                object.seriesGroupPage = $root.query_recursive_types.SeriesGroupPageResponse.toObject(message.seriesGroupPage, options);
            return object;
        };

        /**
         * Converts this SearchSeriesGroupResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesGroupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesGroupResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SearchSeriesGroupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesGroupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SearchSeriesGroupResponse";
        };

        return SearchSeriesGroupResponse;
    })();

    query_recursive_types.SeriesGroupPageRequest = (function() {

        /**
         * Properties of a SeriesGroupPageRequest.
         * @memberof query_recursive_types
         * @interface ISeriesGroupPageRequest
         * @property {query_recursive_types.ISeriesGroupRequireField|null} [requireSeriesGroupList] SeriesGroupPageRequest requireSeriesGroupList
         * @property {query_pagination.IPageRequest|null} [page] SeriesGroupPageRequest page
         */

        /**
         * Constructs a new SeriesGroupPageRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesGroupPageRequest.
         * @implements ISeriesGroupPageRequest
         * @constructor
         * @param {query_recursive_types.ISeriesGroupPageRequest=} [properties] Properties to set
         */
        function SeriesGroupPageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGroupPageRequest requireSeriesGroupList.
         * @member {query_recursive_types.ISeriesGroupRequireField|null|undefined} requireSeriesGroupList
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @instance
         */
        SeriesGroupPageRequest.prototype.requireSeriesGroupList = null;

        /**
         * SeriesGroupPageRequest page.
         * @member {query_pagination.IPageRequest|null|undefined} page
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @instance
         */
        SeriesGroupPageRequest.prototype.page = null;

        /**
         * Creates a new SeriesGroupPageRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @static
         * @param {query_recursive_types.ISeriesGroupPageRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesGroupPageRequest} SeriesGroupPageRequest instance
         */
        SeriesGroupPageRequest.create = function create(properties) {
            return new SeriesGroupPageRequest(properties);
        };

        /**
         * Encodes the specified SeriesGroupPageRequest message. Does not implicitly {@link query_recursive_types.SeriesGroupPageRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @static
         * @param {query_recursive_types.ISeriesGroupPageRequest} message SeriesGroupPageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGroupPageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSeriesGroupList != null && Object.hasOwnProperty.call(message, "requireSeriesGroupList"))
                $root.query_recursive_types.SeriesGroupRequireField.encode(message.requireSeriesGroupList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageRequest.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGroupPageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesGroupPageRequest} SeriesGroupPageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGroupPageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesGroupPageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSeriesGroupList = $root.query_recursive_types.SeriesGroupRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.page = $root.query_pagination.PageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGroupPageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesGroupPageRequest} SeriesGroupPageRequest
         */
        SeriesGroupPageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesGroupPageRequest)
                return object;
            var message = new $root.query_recursive_types.SeriesGroupPageRequest();
            if (object.requireSeriesGroupList != null) {
                if (typeof object.requireSeriesGroupList !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupPageRequest.requireSeriesGroupList: object expected");
                message.requireSeriesGroupList = $root.query_recursive_types.SeriesGroupRequireField.fromObject(object.requireSeriesGroupList);
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupPageRequest.page: object expected");
                message.page = $root.query_pagination.PageRequest.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGroupPageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @static
         * @param {query_recursive_types.SeriesGroupPageRequest} message SeriesGroupPageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGroupPageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireSeriesGroupList = null;
                object.page = null;
            }
            if (message.requireSeriesGroupList != null && message.hasOwnProperty("requireSeriesGroupList"))
                object.requireSeriesGroupList = $root.query_recursive_types.SeriesGroupRequireField.toObject(message.requireSeriesGroupList, options);
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageRequest.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this SeriesGroupPageRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGroupPageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGroupPageRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesGroupPageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGroupPageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesGroupPageRequest";
        };

        return SeriesGroupPageRequest;
    })();

    query_recursive_types.SeriesGroupPageResponse = (function() {

        /**
         * Properties of a SeriesGroupPageResponse.
         * @memberof query_recursive_types
         * @interface ISeriesGroupPageResponse
         * @property {Array.<query_recursive_types.ISeriesGroupResponse>|null} [seriesGroupList] SeriesGroupPageResponse seriesGroupList
         * @property {query_pagination.IPageInfo|null} [page] SeriesGroupPageResponse page
         */

        /**
         * Constructs a new SeriesGroupPageResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesGroupPageResponse.
         * @implements ISeriesGroupPageResponse
         * @constructor
         * @param {query_recursive_types.ISeriesGroupPageResponse=} [properties] Properties to set
         */
        function SeriesGroupPageResponse(properties) {
            this.seriesGroupList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGroupPageResponse seriesGroupList.
         * @member {Array.<query_recursive_types.ISeriesGroupResponse>} seriesGroupList
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @instance
         */
        SeriesGroupPageResponse.prototype.seriesGroupList = $util.emptyArray;

        /**
         * SeriesGroupPageResponse page.
         * @member {query_pagination.IPageInfo|null|undefined} page
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @instance
         */
        SeriesGroupPageResponse.prototype.page = null;

        /**
         * Creates a new SeriesGroupPageResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @static
         * @param {query_recursive_types.ISeriesGroupPageResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesGroupPageResponse} SeriesGroupPageResponse instance
         */
        SeriesGroupPageResponse.create = function create(properties) {
            return new SeriesGroupPageResponse(properties);
        };

        /**
         * Encodes the specified SeriesGroupPageResponse message. Does not implicitly {@link query_recursive_types.SeriesGroupPageResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @static
         * @param {query_recursive_types.ISeriesGroupPageResponse} message SeriesGroupPageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGroupPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesGroupList != null && message.seriesGroupList.length)
                for (var i = 0; i < message.seriesGroupList.length; ++i)
                    $root.query_recursive_types.SeriesGroupResponse.encode(message.seriesGroupList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageInfo.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGroupPageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesGroupPageResponse} SeriesGroupPageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGroupPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesGroupPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.seriesGroupList && message.seriesGroupList.length))
                        message.seriesGroupList = [];
                    message.seriesGroupList.push($root.query_recursive_types.SeriesGroupResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = $root.query_pagination.PageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGroupPageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesGroupPageResponse} SeriesGroupPageResponse
         */
        SeriesGroupPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesGroupPageResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesGroupPageResponse();
            if (object.seriesGroupList) {
                if (!Array.isArray(object.seriesGroupList))
                    throw TypeError(".query_recursive_types.SeriesGroupPageResponse.seriesGroupList: array expected");
                message.seriesGroupList = [];
                for (var i = 0; i < object.seriesGroupList.length; ++i) {
                    if (typeof object.seriesGroupList[i] !== "object")
                        throw TypeError(".query_recursive_types.SeriesGroupPageResponse.seriesGroupList: object expected");
                    message.seriesGroupList[i] = $root.query_recursive_types.SeriesGroupResponse.fromObject(object.seriesGroupList[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_recursive_types.SeriesGroupPageResponse.page: object expected");
                message.page = $root.query_pagination.PageInfo.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGroupPageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @static
         * @param {query_recursive_types.SeriesGroupPageResponse} message SeriesGroupPageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGroupPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.seriesGroupList = [];
            if (options.defaults)
                object.page = null;
            if (message.seriesGroupList && message.seriesGroupList.length) {
                object.seriesGroupList = [];
                for (var j = 0; j < message.seriesGroupList.length; ++j)
                    object.seriesGroupList[j] = $root.query_recursive_types.SeriesGroupResponse.toObject(message.seriesGroupList[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageInfo.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this SeriesGroupPageResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGroupPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGroupPageResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesGroupPageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGroupPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesGroupPageResponse";
        };

        return SeriesGroupPageResponse;
    })();

    query_recursive_types.ImageRequireField = (function() {

        /**
         * Properties of an ImageRequireField.
         * @memberof query_recursive_types
         * @interface IImageRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] ImageRequireField requireId
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] ImageRequireField requireUser
         * @property {google.protobuf.IBoolValue|null} [requireServingUrl] ImageRequireField requireServingUrl
         * @property {google.protobuf.IBoolValue|null} [requireFormat] ImageRequireField requireFormat
         * @property {google.protobuf.IBoolValue|null} [requireWidth] ImageRequireField requireWidth
         * @property {google.protobuf.IBoolValue|null} [requireHeight] ImageRequireField requireHeight
         * @property {google.protobuf.IBoolValue|null} [requireCategory] ImageRequireField requireCategory
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] ImageRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireIsAvailable] ImageRequireField requireIsAvailable
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] ImageRequireField requireUpdatedAt
         */

        /**
         * Constructs a new ImageRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents an ImageRequireField.
         * @implements IImageRequireField
         * @constructor
         * @param {query_recursive_types.IImageRequireField=} [properties] Properties to set
         */
        function ImageRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireId = null;

        /**
         * ImageRequireField requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireUser = null;

        /**
         * ImageRequireField requireServingUrl.
         * @member {google.protobuf.IBoolValue|null|undefined} requireServingUrl
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireServingUrl = null;

        /**
         * ImageRequireField requireFormat.
         * @member {google.protobuf.IBoolValue|null|undefined} requireFormat
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireFormat = null;

        /**
         * ImageRequireField requireWidth.
         * @member {google.protobuf.IBoolValue|null|undefined} requireWidth
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireWidth = null;

        /**
         * ImageRequireField requireHeight.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHeight
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireHeight = null;

        /**
         * ImageRequireField requireCategory.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCategory
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireCategory = null;

        /**
         * ImageRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireCreatedAt = null;

        /**
         * ImageRequireField requireIsAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIsAvailable
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireIsAvailable = null;

        /**
         * ImageRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         */
        ImageRequireField.prototype.requireUpdatedAt = null;

        /**
         * Creates a new ImageRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ImageRequireField
         * @static
         * @param {query_recursive_types.IImageRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.ImageRequireField} ImageRequireField instance
         */
        ImageRequireField.create = function create(properties) {
            return new ImageRequireField(properties);
        };

        /**
         * Encodes the specified ImageRequireField message. Does not implicitly {@link query_recursive_types.ImageRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ImageRequireField
         * @static
         * @param {query_recursive_types.IImageRequireField} message ImageRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireServingUrl != null && Object.hasOwnProperty.call(message, "requireServingUrl"))
                $root.google.protobuf.BoolValue.encode(message.requireServingUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireFormat != null && Object.hasOwnProperty.call(message, "requireFormat"))
                $root.google.protobuf.BoolValue.encode(message.requireFormat, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireWidth != null && Object.hasOwnProperty.call(message, "requireWidth"))
                $root.google.protobuf.BoolValue.encode(message.requireWidth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireHeight != null && Object.hasOwnProperty.call(message, "requireHeight"))
                $root.google.protobuf.BoolValue.encode(message.requireHeight, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireCategory != null && Object.hasOwnProperty.call(message, "requireCategory"))
                $root.google.protobuf.BoolValue.encode(message.requireCategory, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.requireIsAvailable != null && Object.hasOwnProperty.call(message, "requireIsAvailable"))
                $root.google.protobuf.BoolValue.encode(message.requireIsAvailable, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ImageRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ImageRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ImageRequireField} ImageRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ImageRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireServingUrl = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireFormat = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireWidth = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireHeight = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireCategory = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.requireIsAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an ImageRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ImageRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ImageRequireField} ImageRequireField
         */
        ImageRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ImageRequireField)
                return object;
            var message = new $root.query_recursive_types.ImageRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireServingUrl != null) {
                if (typeof object.requireServingUrl !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireServingUrl: object expected");
                message.requireServingUrl = $root.google.protobuf.BoolValue.fromObject(object.requireServingUrl);
            }
            if (object.requireFormat != null) {
                if (typeof object.requireFormat !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireFormat: object expected");
                message.requireFormat = $root.google.protobuf.BoolValue.fromObject(object.requireFormat);
            }
            if (object.requireWidth != null) {
                if (typeof object.requireWidth !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireWidth: object expected");
                message.requireWidth = $root.google.protobuf.BoolValue.fromObject(object.requireWidth);
            }
            if (object.requireHeight != null) {
                if (typeof object.requireHeight !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireHeight: object expected");
                message.requireHeight = $root.google.protobuf.BoolValue.fromObject(object.requireHeight);
            }
            if (object.requireCategory != null) {
                if (typeof object.requireCategory !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireCategory: object expected");
                message.requireCategory = $root.google.protobuf.BoolValue.fromObject(object.requireCategory);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireIsAvailable != null) {
                if (typeof object.requireIsAvailable !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireIsAvailable: object expected");
                message.requireIsAvailable = $root.google.protobuf.BoolValue.fromObject(object.requireIsAvailable);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.ImageRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ImageRequireField
         * @static
         * @param {query_recursive_types.ImageRequireField} message ImageRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireUser = null;
                object.requireServingUrl = null;
                object.requireFormat = null;
                object.requireWidth = null;
                object.requireHeight = null;
                object.requireCategory = null;
                object.requireCreatedAt = null;
                object.requireIsAvailable = null;
                object.requireUpdatedAt = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireServingUrl != null && message.hasOwnProperty("requireServingUrl"))
                object.requireServingUrl = $root.google.protobuf.BoolValue.toObject(message.requireServingUrl, options);
            if (message.requireFormat != null && message.hasOwnProperty("requireFormat"))
                object.requireFormat = $root.google.protobuf.BoolValue.toObject(message.requireFormat, options);
            if (message.requireWidth != null && message.hasOwnProperty("requireWidth"))
                object.requireWidth = $root.google.protobuf.BoolValue.toObject(message.requireWidth, options);
            if (message.requireHeight != null && message.hasOwnProperty("requireHeight"))
                object.requireHeight = $root.google.protobuf.BoolValue.toObject(message.requireHeight, options);
            if (message.requireCategory != null && message.hasOwnProperty("requireCategory"))
                object.requireCategory = $root.google.protobuf.BoolValue.toObject(message.requireCategory, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireIsAvailable != null && message.hasOwnProperty("requireIsAvailable"))
                object.requireIsAvailable = $root.google.protobuf.BoolValue.toObject(message.requireIsAvailable, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            return object;
        };

        /**
         * Converts this ImageRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ImageRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImageRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.ImageRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImageRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ImageRequireField";
        };

        return ImageRequireField;
    })();

    query_recursive_types.ImageResponse = (function() {

        /**
         * Properties of an ImageResponse.
         * @memberof query_recursive_types
         * @interface IImageResponse
         * @property {google.protobuf.IStringValue|null} [id] ImageResponse id
         * @property {query_recursive_types.IUserResponse|null} [user] ImageResponse user
         * @property {google.protobuf.IStringValue|null} [servingUrl] ImageResponse servingUrl
         * @property {resource.ImageFormat|null} [format] ImageResponse format
         * @property {google.protobuf.IInt32Value|null} [width] ImageResponse width
         * @property {google.protobuf.IInt32Value|null} [height] ImageResponse height
         * @property {resource.ImageCategory|null} [category] ImageResponse category
         * @property {google.protobuf.ITimestamp|null} [createdAt] ImageResponse createdAt
         * @property {google.protobuf.IBoolValue|null} [isAvailable] ImageResponse isAvailable
         * @property {google.protobuf.ITimestamp|null} [updatedAt] ImageResponse updatedAt
         */

        /**
         * Constructs a new ImageResponse.
         * @memberof query_recursive_types
         * @classdesc Represents an ImageResponse.
         * @implements IImageResponse
         * @constructor
         * @param {query_recursive_types.IImageResponse=} [properties] Properties to set
         */
        function ImageResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.id = null;

        /**
         * ImageResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.user = null;

        /**
         * ImageResponse servingUrl.
         * @member {google.protobuf.IStringValue|null|undefined} servingUrl
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.servingUrl = null;

        /**
         * ImageResponse format.
         * @member {resource.ImageFormat} format
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.format = 0;

        /**
         * ImageResponse width.
         * @member {google.protobuf.IInt32Value|null|undefined} width
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.width = null;

        /**
         * ImageResponse height.
         * @member {google.protobuf.IInt32Value|null|undefined} height
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.height = null;

        /**
         * ImageResponse category.
         * @member {resource.ImageCategory} category
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.category = 0;

        /**
         * ImageResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.createdAt = null;

        /**
         * ImageResponse isAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} isAvailable
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.isAvailable = null;

        /**
         * ImageResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.ImageResponse
         * @instance
         */
        ImageResponse.prototype.updatedAt = null;

        /**
         * Creates a new ImageResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ImageResponse
         * @static
         * @param {query_recursive_types.IImageResponse=} [properties] Properties to set
         * @returns {query_recursive_types.ImageResponse} ImageResponse instance
         */
        ImageResponse.create = function create(properties) {
            return new ImageResponse(properties);
        };

        /**
         * Encodes the specified ImageResponse message. Does not implicitly {@link query_recursive_types.ImageResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ImageResponse
         * @static
         * @param {query_recursive_types.IImageResponse} message ImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.servingUrl != null && Object.hasOwnProperty.call(message, "servingUrl"))
                $root.google.protobuf.StringValue.encode(message.servingUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.format);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                $root.google.protobuf.Int32Value.encode(message.width, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                $root.google.protobuf.Int32Value.encode(message.height, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.category);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isAvailable != null && Object.hasOwnProperty.call(message, "isAvailable"))
                $root.google.protobuf.BoolValue.encode(message.isAvailable, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ImageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ImageResponse} ImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ImageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.servingUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.format = reader.int32();
                    break;
                case 5:
                    message.width = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.height = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.category = reader.int32();
                    break;
                case 8:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.isAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an ImageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ImageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ImageResponse} ImageResponse
         */
        ImageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ImageResponse)
                return object;
            var message = new $root.query_recursive_types.ImageResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.servingUrl != null) {
                if (typeof object.servingUrl !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.servingUrl: object expected");
                message.servingUrl = $root.google.protobuf.StringValue.fromObject(object.servingUrl);
            }
            switch (object.format) {
            case "IMAGE_FORMAT_UNSPECIFIED":
            case 0:
                message.format = 0;
                break;
            case "IMAGE_FORMAT_PNG":
            case 1:
                message.format = 1;
                break;
            case "IMAGE_FORMAT_JPEG":
            case 2:
                message.format = 2;
                break;
            case "IMAGE_FORMAT_GIF":
            case 3:
                message.format = 3;
                break;
            case "IMAGE_FORMAT_WEBP":
            case 4:
                message.format = 4;
                break;
            }
            if (object.width != null) {
                if (typeof object.width !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.width: object expected");
                message.width = $root.google.protobuf.Int32Value.fromObject(object.width);
            }
            if (object.height != null) {
                if (typeof object.height !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.height: object expected");
                message.height = $root.google.protobuf.Int32Value.fromObject(object.height);
            }
            switch (object.category) {
            case "IMAGE_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "IMAGE_CATEGORY_USER_PROFILE":
            case 1:
                message.category = 1;
                break;
            case "IMAGE_CATEGORY_CHARACTER_ICON":
            case 2:
                message.category = 2;
                break;
            case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
            case 3:
                message.category = 3;
                break;
            case "IMAGE_CATEGORY_CHAT_BACKGROUND":
            case 4:
                message.category = 4;
                break;
            case "IMAGE_CATEGORY_TALK_BACKGROUND":
            case 5:
                message.category = 5;
                break;
            case "IMAGE_CATEGORY_CONTENT":
            case 6:
                message.category = 6;
                break;
            case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
            case 7:
                message.category = 7;
                break;
            case "IMAGE_CATEGORY_STAMP":
            case 8:
                message.category = 8;
                break;
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.isAvailable != null) {
                if (typeof object.isAvailable !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.isAvailable: object expected");
                message.isAvailable = $root.google.protobuf.BoolValue.fromObject(object.isAvailable);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.ImageResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ImageResponse
         * @static
         * @param {query_recursive_types.ImageResponse} message ImageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.user = null;
                object.servingUrl = null;
                object.format = options.enums === String ? "IMAGE_FORMAT_UNSPECIFIED" : 0;
                object.width = null;
                object.height = null;
                object.category = options.enums === String ? "IMAGE_CATEGORY_UNSPECIFIED" : 0;
                object.createdAt = null;
                object.isAvailable = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.servingUrl != null && message.hasOwnProperty("servingUrl"))
                object.servingUrl = $root.google.protobuf.StringValue.toObject(message.servingUrl, options);
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = options.enums === String ? $root.resource.ImageFormat[message.format] : message.format;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = $root.google.protobuf.Int32Value.toObject(message.width, options);
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = $root.google.protobuf.Int32Value.toObject(message.height, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.resource.ImageCategory[message.category] : message.category;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.isAvailable != null && message.hasOwnProperty("isAvailable"))
                object.isAvailable = $root.google.protobuf.BoolValue.toObject(message.isAvailable, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this ImageResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ImageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImageResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.ImageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ImageResponse";
        };

        return ImageResponse;
    })();

    query_recursive_types.AudioRequireField = (function() {

        /**
         * Properties of an AudioRequireField.
         * @memberof query_recursive_types
         * @interface IAudioRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] AudioRequireField requireId
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] AudioRequireField requireUser
         * @property {google.protobuf.IBoolValue|null} [requireServingUrl] AudioRequireField requireServingUrl
         * @property {google.protobuf.IBoolValue|null} [requireDuration] AudioRequireField requireDuration
         * @property {google.protobuf.IBoolValue|null} [requireCodec] AudioRequireField requireCodec
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] AudioRequireField requireCreatedAt
         */

        /**
         * Constructs a new AudioRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents an AudioRequireField.
         * @implements IAudioRequireField
         * @constructor
         * @param {query_recursive_types.IAudioRequireField=} [properties] Properties to set
         */
        function AudioRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AudioRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.AudioRequireField
         * @instance
         */
        AudioRequireField.prototype.requireId = null;

        /**
         * AudioRequireField requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.AudioRequireField
         * @instance
         */
        AudioRequireField.prototype.requireUser = null;

        /**
         * AudioRequireField requireServingUrl.
         * @member {google.protobuf.IBoolValue|null|undefined} requireServingUrl
         * @memberof query_recursive_types.AudioRequireField
         * @instance
         */
        AudioRequireField.prototype.requireServingUrl = null;

        /**
         * AudioRequireField requireDuration.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDuration
         * @memberof query_recursive_types.AudioRequireField
         * @instance
         */
        AudioRequireField.prototype.requireDuration = null;

        /**
         * AudioRequireField requireCodec.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCodec
         * @memberof query_recursive_types.AudioRequireField
         * @instance
         */
        AudioRequireField.prototype.requireCodec = null;

        /**
         * AudioRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.AudioRequireField
         * @instance
         */
        AudioRequireField.prototype.requireCreatedAt = null;

        /**
         * Creates a new AudioRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.AudioRequireField
         * @static
         * @param {query_recursive_types.IAudioRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.AudioRequireField} AudioRequireField instance
         */
        AudioRequireField.create = function create(properties) {
            return new AudioRequireField(properties);
        };

        /**
         * Encodes the specified AudioRequireField message. Does not implicitly {@link query_recursive_types.AudioRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.AudioRequireField
         * @static
         * @param {query_recursive_types.IAudioRequireField} message AudioRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireServingUrl != null && Object.hasOwnProperty.call(message, "requireServingUrl"))
                $root.google.protobuf.BoolValue.encode(message.requireServingUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireDuration != null && Object.hasOwnProperty.call(message, "requireDuration"))
                $root.google.protobuf.BoolValue.encode(message.requireDuration, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireCodec != null && Object.hasOwnProperty.call(message, "requireCodec"))
                $root.google.protobuf.BoolValue.encode(message.requireCodec, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AudioRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.AudioRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.AudioRequireField} AudioRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.AudioRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireServingUrl = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireDuration = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireCodec = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AudioRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.AudioRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.AudioRequireField} AudioRequireField
         */
        AudioRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.AudioRequireField)
                return object;
            var message = new $root.query_recursive_types.AudioRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.AudioRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.AudioRequireField.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireServingUrl != null) {
                if (typeof object.requireServingUrl !== "object")
                    throw TypeError(".query_recursive_types.AudioRequireField.requireServingUrl: object expected");
                message.requireServingUrl = $root.google.protobuf.BoolValue.fromObject(object.requireServingUrl);
            }
            if (object.requireDuration != null) {
                if (typeof object.requireDuration !== "object")
                    throw TypeError(".query_recursive_types.AudioRequireField.requireDuration: object expected");
                message.requireDuration = $root.google.protobuf.BoolValue.fromObject(object.requireDuration);
            }
            if (object.requireCodec != null) {
                if (typeof object.requireCodec !== "object")
                    throw TypeError(".query_recursive_types.AudioRequireField.requireCodec: object expected");
                message.requireCodec = $root.google.protobuf.BoolValue.fromObject(object.requireCodec);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.AudioRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an AudioRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.AudioRequireField
         * @static
         * @param {query_recursive_types.AudioRequireField} message AudioRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AudioRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireUser = null;
                object.requireServingUrl = null;
                object.requireDuration = null;
                object.requireCodec = null;
                object.requireCreatedAt = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireServingUrl != null && message.hasOwnProperty("requireServingUrl"))
                object.requireServingUrl = $root.google.protobuf.BoolValue.toObject(message.requireServingUrl, options);
            if (message.requireDuration != null && message.hasOwnProperty("requireDuration"))
                object.requireDuration = $root.google.protobuf.BoolValue.toObject(message.requireDuration, options);
            if (message.requireCodec != null && message.hasOwnProperty("requireCodec"))
                object.requireCodec = $root.google.protobuf.BoolValue.toObject(message.requireCodec, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            return object;
        };

        /**
         * Converts this AudioRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.AudioRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AudioRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AudioRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.AudioRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AudioRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.AudioRequireField";
        };

        return AudioRequireField;
    })();

    query_recursive_types.TagRequireField = (function() {

        /**
         * Properties of a TagRequireField.
         * @memberof query_recursive_types
         * @interface ITagRequireField
         * @property {google.protobuf.IBoolValue|null} [requireTag] TagRequireField requireTag
         * @property {google.protobuf.IBoolValue|null} [requireDescription] TagRequireField requireDescription
         * @property {query_recursive_types.ITagRequireField|null} [requireChildren] TagRequireField requireChildren
         * @property {query_recursive_types.ITagRequireField|null} [requireParent] TagRequireField requireParent
         */

        /**
         * Constructs a new TagRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a TagRequireField.
         * @implements ITagRequireField
         * @constructor
         * @param {query_recursive_types.ITagRequireField=} [properties] Properties to set
         */
        function TagRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagRequireField requireTag.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTag
         * @memberof query_recursive_types.TagRequireField
         * @instance
         */
        TagRequireField.prototype.requireTag = null;

        /**
         * TagRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_recursive_types.TagRequireField
         * @instance
         */
        TagRequireField.prototype.requireDescription = null;

        /**
         * TagRequireField requireChildren.
         * @member {query_recursive_types.ITagRequireField|null|undefined} requireChildren
         * @memberof query_recursive_types.TagRequireField
         * @instance
         */
        TagRequireField.prototype.requireChildren = null;

        /**
         * TagRequireField requireParent.
         * @member {query_recursive_types.ITagRequireField|null|undefined} requireParent
         * @memberof query_recursive_types.TagRequireField
         * @instance
         */
        TagRequireField.prototype.requireParent = null;

        /**
         * Creates a new TagRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.TagRequireField
         * @static
         * @param {query_recursive_types.ITagRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.TagRequireField} TagRequireField instance
         */
        TagRequireField.create = function create(properties) {
            return new TagRequireField(properties);
        };

        /**
         * Encodes the specified TagRequireField message. Does not implicitly {@link query_recursive_types.TagRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.TagRequireField
         * @static
         * @param {query_recursive_types.ITagRequireField} message TagRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireTag != null && Object.hasOwnProperty.call(message, "requireTag"))
                $root.google.protobuf.BoolValue.encode(message.requireTag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireChildren != null && Object.hasOwnProperty.call(message, "requireChildren"))
                $root.query_recursive_types.TagRequireField.encode(message.requireChildren, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireParent != null && Object.hasOwnProperty.call(message, "requireParent"))
                $root.query_recursive_types.TagRequireField.encode(message.requireParent, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.TagRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.TagRequireField} TagRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.TagRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireTag = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireChildren = $root.query_recursive_types.TagRequireField.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireParent = $root.query_recursive_types.TagRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.TagRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.TagRequireField} TagRequireField
         */
        TagRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.TagRequireField)
                return object;
            var message = new $root.query_recursive_types.TagRequireField();
            if (object.requireTag != null) {
                if (typeof object.requireTag !== "object")
                    throw TypeError(".query_recursive_types.TagRequireField.requireTag: object expected");
                message.requireTag = $root.google.protobuf.BoolValue.fromObject(object.requireTag);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_recursive_types.TagRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireChildren != null) {
                if (typeof object.requireChildren !== "object")
                    throw TypeError(".query_recursive_types.TagRequireField.requireChildren: object expected");
                message.requireChildren = $root.query_recursive_types.TagRequireField.fromObject(object.requireChildren);
            }
            if (object.requireParent != null) {
                if (typeof object.requireParent !== "object")
                    throw TypeError(".query_recursive_types.TagRequireField.requireParent: object expected");
                message.requireParent = $root.query_recursive_types.TagRequireField.fromObject(object.requireParent);
            }
            return message;
        };

        /**
         * Creates a plain object from a TagRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.TagRequireField
         * @static
         * @param {query_recursive_types.TagRequireField} message TagRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireTag = null;
                object.requireDescription = null;
                object.requireChildren = null;
                object.requireParent = null;
            }
            if (message.requireTag != null && message.hasOwnProperty("requireTag"))
                object.requireTag = $root.google.protobuf.BoolValue.toObject(message.requireTag, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireChildren != null && message.hasOwnProperty("requireChildren"))
                object.requireChildren = $root.query_recursive_types.TagRequireField.toObject(message.requireChildren, options);
            if (message.requireParent != null && message.hasOwnProperty("requireParent"))
                object.requireParent = $root.query_recursive_types.TagRequireField.toObject(message.requireParent, options);
            return object;
        };

        /**
         * Converts this TagRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.TagRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.TagRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.TagRequireField";
        };

        return TagRequireField;
    })();

    query_recursive_types.TagResponse = (function() {

        /**
         * Properties of a TagResponse.
         * @memberof query_recursive_types
         * @interface ITagResponse
         * @property {google.protobuf.IStringValue|null} [tag] TagResponse tag
         * @property {google.protobuf.IStringValue|null} [description] TagResponse description
         * @property {Array.<query_recursive_types.ITagResponse>|null} [children] TagResponse children
         * @property {query_recursive_types.ITagResponse|null} [parent] TagResponse parent
         */

        /**
         * Constructs a new TagResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a TagResponse.
         * @implements ITagResponse
         * @constructor
         * @param {query_recursive_types.ITagResponse=} [properties] Properties to set
         */
        function TagResponse(properties) {
            this.children = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagResponse tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_recursive_types.TagResponse
         * @instance
         */
        TagResponse.prototype.tag = null;

        /**
         * TagResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_recursive_types.TagResponse
         * @instance
         */
        TagResponse.prototype.description = null;

        /**
         * TagResponse children.
         * @member {Array.<query_recursive_types.ITagResponse>} children
         * @memberof query_recursive_types.TagResponse
         * @instance
         */
        TagResponse.prototype.children = $util.emptyArray;

        /**
         * TagResponse parent.
         * @member {query_recursive_types.ITagResponse|null|undefined} parent
         * @memberof query_recursive_types.TagResponse
         * @instance
         */
        TagResponse.prototype.parent = null;

        /**
         * Creates a new TagResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.TagResponse
         * @static
         * @param {query_recursive_types.ITagResponse=} [properties] Properties to set
         * @returns {query_recursive_types.TagResponse} TagResponse instance
         */
        TagResponse.create = function create(properties) {
            return new TagResponse(properties);
        };

        /**
         * Encodes the specified TagResponse message. Does not implicitly {@link query_recursive_types.TagResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.TagResponse
         * @static
         * @param {query_recursive_types.ITagResponse} message TagResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.children != null && message.children.length)
                for (var i = 0; i < message.children.length; ++i)
                    $root.query_recursive_types.TagResponse.encode(message.children[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                $root.query_recursive_types.TagResponse.encode(message.parent, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.TagResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.TagResponse} TagResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.TagResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.children && message.children.length))
                        message.children = [];
                    message.children.push($root.query_recursive_types.TagResponse.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.parent = $root.query_recursive_types.TagResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.TagResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.TagResponse} TagResponse
         */
        TagResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.TagResponse)
                return object;
            var message = new $root.query_recursive_types.TagResponse();
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_recursive_types.TagResponse.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_recursive_types.TagResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.children) {
                if (!Array.isArray(object.children))
                    throw TypeError(".query_recursive_types.TagResponse.children: array expected");
                message.children = [];
                for (var i = 0; i < object.children.length; ++i) {
                    if (typeof object.children[i] !== "object")
                        throw TypeError(".query_recursive_types.TagResponse.children: object expected");
                    message.children[i] = $root.query_recursive_types.TagResponse.fromObject(object.children[i]);
                }
            }
            if (object.parent != null) {
                if (typeof object.parent !== "object")
                    throw TypeError(".query_recursive_types.TagResponse.parent: object expected");
                message.parent = $root.query_recursive_types.TagResponse.fromObject(object.parent);
            }
            return message;
        };

        /**
         * Creates a plain object from a TagResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.TagResponse
         * @static
         * @param {query_recursive_types.TagResponse} message TagResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.children = [];
            if (options.defaults) {
                object.tag = null;
                object.description = null;
                object.parent = null;
            }
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.children && message.children.length) {
                object.children = [];
                for (var j = 0; j < message.children.length; ++j)
                    object.children[j] = $root.query_recursive_types.TagResponse.toObject(message.children[j], options);
            }
            if (message.parent != null && message.hasOwnProperty("parent"))
                object.parent = $root.query_recursive_types.TagResponse.toObject(message.parent, options);
            return object;
        };

        /**
         * Converts this TagResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.TagResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.TagResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.TagResponse";
        };

        return TagResponse;
    })();

    query_recursive_types.AudioResponse = (function() {

        /**
         * Properties of an AudioResponse.
         * @memberof query_recursive_types
         * @interface IAudioResponse
         * @property {google.protobuf.IStringValue|null} [id] AudioResponse id
         * @property {query_recursive_types.IUserResponse|null} [user] AudioResponse user
         * @property {google.protobuf.IStringValue|null} [servingUrl] AudioResponse servingUrl
         * @property {google.protobuf.IDuration|null} [duration] AudioResponse duration
         * @property {resource.AudioCodec|null} [codec] AudioResponse codec
         * @property {google.protobuf.ITimestamp|null} [createdAt] AudioResponse createdAt
         */

        /**
         * Constructs a new AudioResponse.
         * @memberof query_recursive_types
         * @classdesc Represents an AudioResponse.
         * @implements IAudioResponse
         * @constructor
         * @param {query_recursive_types.IAudioResponse=} [properties] Properties to set
         */
        function AudioResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AudioResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.AudioResponse
         * @instance
         */
        AudioResponse.prototype.id = null;

        /**
         * AudioResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.AudioResponse
         * @instance
         */
        AudioResponse.prototype.user = null;

        /**
         * AudioResponse servingUrl.
         * @member {google.protobuf.IStringValue|null|undefined} servingUrl
         * @memberof query_recursive_types.AudioResponse
         * @instance
         */
        AudioResponse.prototype.servingUrl = null;

        /**
         * AudioResponse duration.
         * @member {google.protobuf.IDuration|null|undefined} duration
         * @memberof query_recursive_types.AudioResponse
         * @instance
         */
        AudioResponse.prototype.duration = null;

        /**
         * AudioResponse codec.
         * @member {resource.AudioCodec} codec
         * @memberof query_recursive_types.AudioResponse
         * @instance
         */
        AudioResponse.prototype.codec = 0;

        /**
         * AudioResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.AudioResponse
         * @instance
         */
        AudioResponse.prototype.createdAt = null;

        /**
         * Creates a new AudioResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.AudioResponse
         * @static
         * @param {query_recursive_types.IAudioResponse=} [properties] Properties to set
         * @returns {query_recursive_types.AudioResponse} AudioResponse instance
         */
        AudioResponse.create = function create(properties) {
            return new AudioResponse(properties);
        };

        /**
         * Encodes the specified AudioResponse message. Does not implicitly {@link query_recursive_types.AudioResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.AudioResponse
         * @static
         * @param {query_recursive_types.IAudioResponse} message AudioResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.servingUrl != null && Object.hasOwnProperty.call(message, "servingUrl"))
                $root.google.protobuf.StringValue.encode(message.servingUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.codec != null && Object.hasOwnProperty.call(message, "codec"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.codec);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AudioResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.AudioResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.AudioResponse} AudioResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.AudioResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.servingUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.codec = reader.int32();
                    break;
                case 6:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an AudioResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.AudioResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.AudioResponse} AudioResponse
         */
        AudioResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.AudioResponse)
                return object;
            var message = new $root.query_recursive_types.AudioResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.AudioResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.AudioResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.servingUrl != null) {
                if (typeof object.servingUrl !== "object")
                    throw TypeError(".query_recursive_types.AudioResponse.servingUrl: object expected");
                message.servingUrl = $root.google.protobuf.StringValue.fromObject(object.servingUrl);
            }
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".query_recursive_types.AudioResponse.duration: object expected");
                message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
            }
            switch (object.codec) {
            case "AUDIO_CODEC_UNSPECIFIED":
            case 0:
                message.codec = 0;
                break;
            case "AUDIO_CODEC_AAC":
            case 1:
                message.codec = 1;
                break;
            case "AUDIO_CODEC_MP3":
            case 2:
                message.codec = 2;
                break;
            case "AUDIO_CODEC_VORBIS":
            case 3:
                message.codec = 3;
                break;
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.AudioResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            return message;
        };

        /**
         * Creates a plain object from an AudioResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.AudioResponse
         * @static
         * @param {query_recursive_types.AudioResponse} message AudioResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AudioResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.user = null;
                object.servingUrl = null;
                object.duration = null;
                object.codec = options.enums === String ? "AUDIO_CODEC_UNSPECIFIED" : 0;
                object.createdAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.servingUrl != null && message.hasOwnProperty("servingUrl"))
                object.servingUrl = $root.google.protobuf.StringValue.toObject(message.servingUrl, options);
            if (message.duration != null && message.hasOwnProperty("duration"))
                object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
            if (message.codec != null && message.hasOwnProperty("codec"))
                object.codec = options.enums === String ? $root.resource.AudioCodec[message.codec] : message.codec;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            return object;
        };

        /**
         * Converts this AudioResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.AudioResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AudioResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AudioResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.AudioResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AudioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.AudioResponse";
        };

        return AudioResponse;
    })();

    query_recursive_types.StoryAdvertisementRequest = (function() {

        /**
         * Properties of a StoryAdvertisementRequest.
         * @memberof query_recursive_types
         * @interface IStoryAdvertisementRequest
         * @property {google.protobuf.IStringValue|null} [id] StoryAdvertisementRequest id
         * @property {query_recursive_types.IStoryAdvertisementRequireField|null} [requireAdvertisement] StoryAdvertisementRequest requireAdvertisement
         */

        /**
         * Constructs a new StoryAdvertisementRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryAdvertisementRequest.
         * @implements IStoryAdvertisementRequest
         * @constructor
         * @param {query_recursive_types.IStoryAdvertisementRequest=} [properties] Properties to set
         */
        function StoryAdvertisementRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @instance
         */
        StoryAdvertisementRequest.prototype.id = null;

        /**
         * StoryAdvertisementRequest requireAdvertisement.
         * @member {query_recursive_types.IStoryAdvertisementRequireField|null|undefined} requireAdvertisement
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @instance
         */
        StoryAdvertisementRequest.prototype.requireAdvertisement = null;

        /**
         * Creates a new StoryAdvertisementRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @static
         * @param {query_recursive_types.IStoryAdvertisementRequest=} [properties] Properties to set
         * @returns {query_recursive_types.StoryAdvertisementRequest} StoryAdvertisementRequest instance
         */
        StoryAdvertisementRequest.create = function create(properties) {
            return new StoryAdvertisementRequest(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementRequest message. Does not implicitly {@link query_recursive_types.StoryAdvertisementRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @static
         * @param {query_recursive_types.IStoryAdvertisementRequest} message StoryAdvertisementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireAdvertisement != null && Object.hasOwnProperty.call(message, "requireAdvertisement"))
                $root.query_recursive_types.StoryAdvertisementRequireField.encode(message.requireAdvertisement, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryAdvertisementRequest} StoryAdvertisementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryAdvertisementRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireAdvertisement = $root.query_recursive_types.StoryAdvertisementRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryAdvertisementRequest} StoryAdvertisementRequest
         */
        StoryAdvertisementRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryAdvertisementRequest)
                return object;
            var message = new $root.query_recursive_types.StoryAdvertisementRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireAdvertisement != null) {
                if (typeof object.requireAdvertisement !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementRequest.requireAdvertisement: object expected");
                message.requireAdvertisement = $root.query_recursive_types.StoryAdvertisementRequireField.fromObject(object.requireAdvertisement);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @static
         * @param {query_recursive_types.StoryAdvertisementRequest} message StoryAdvertisementRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireAdvertisement = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireAdvertisement != null && message.hasOwnProperty("requireAdvertisement"))
                object.requireAdvertisement = $root.query_recursive_types.StoryAdvertisementRequireField.toObject(message.requireAdvertisement, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisementRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryAdvertisementRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryAdvertisementRequest";
        };

        return StoryAdvertisementRequest;
    })();

    query_recursive_types.StoryAdvertisementRequireField = (function() {

        /**
         * Properties of a StoryAdvertisementRequireField.
         * @memberof query_recursive_types
         * @interface IStoryAdvertisementRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] StoryAdvertisementRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireTitle] StoryAdvertisementRequireField requireTitle
         * @property {google.protobuf.IBoolValue|null} [requireDescription] StoryAdvertisementRequireField requireDescription
         * @property {query_recursive_types.IImageRequireField|null} [requireThumbnail] StoryAdvertisementRequireField requireThumbnail
         * @property {google.protobuf.IBoolValue|null} [requireLinkUrl] StoryAdvertisementRequireField requireLinkUrl
         */

        /**
         * Constructs a new StoryAdvertisementRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryAdvertisementRequireField.
         * @implements IStoryAdvertisementRequireField
         * @constructor
         * @param {query_recursive_types.IStoryAdvertisementRequireField=} [properties] Properties to set
         */
        function StoryAdvertisementRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @instance
         */
        StoryAdvertisementRequireField.prototype.requireId = null;

        /**
         * StoryAdvertisementRequireField requireTitle.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTitle
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @instance
         */
        StoryAdvertisementRequireField.prototype.requireTitle = null;

        /**
         * StoryAdvertisementRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @instance
         */
        StoryAdvertisementRequireField.prototype.requireDescription = null;

        /**
         * StoryAdvertisementRequireField requireThumbnail.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireThumbnail
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @instance
         */
        StoryAdvertisementRequireField.prototype.requireThumbnail = null;

        /**
         * StoryAdvertisementRequireField requireLinkUrl.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLinkUrl
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @instance
         */
        StoryAdvertisementRequireField.prototype.requireLinkUrl = null;

        /**
         * Creates a new StoryAdvertisementRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @static
         * @param {query_recursive_types.IStoryAdvertisementRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.StoryAdvertisementRequireField} StoryAdvertisementRequireField instance
         */
        StoryAdvertisementRequireField.create = function create(properties) {
            return new StoryAdvertisementRequireField(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementRequireField message. Does not implicitly {@link query_recursive_types.StoryAdvertisementRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @static
         * @param {query_recursive_types.IStoryAdvertisementRequireField} message StoryAdvertisementRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTitle != null && Object.hasOwnProperty.call(message, "requireTitle"))
                $root.google.protobuf.BoolValue.encode(message.requireTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireThumbnail != null && Object.hasOwnProperty.call(message, "requireThumbnail"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireThumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireLinkUrl != null && Object.hasOwnProperty.call(message, "requireLinkUrl"))
                $root.google.protobuf.BoolValue.encode(message.requireLinkUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryAdvertisementRequireField} StoryAdvertisementRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryAdvertisementRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTitle = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireThumbnail = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireLinkUrl = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryAdvertisementRequireField} StoryAdvertisementRequireField
         */
        StoryAdvertisementRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryAdvertisementRequireField)
                return object;
            var message = new $root.query_recursive_types.StoryAdvertisementRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireTitle != null) {
                if (typeof object.requireTitle !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementRequireField.requireTitle: object expected");
                message.requireTitle = $root.google.protobuf.BoolValue.fromObject(object.requireTitle);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireThumbnail != null) {
                if (typeof object.requireThumbnail !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementRequireField.requireThumbnail: object expected");
                message.requireThumbnail = $root.query_recursive_types.ImageRequireField.fromObject(object.requireThumbnail);
            }
            if (object.requireLinkUrl != null) {
                if (typeof object.requireLinkUrl !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementRequireField.requireLinkUrl: object expected");
                message.requireLinkUrl = $root.google.protobuf.BoolValue.fromObject(object.requireLinkUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @static
         * @param {query_recursive_types.StoryAdvertisementRequireField} message StoryAdvertisementRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireTitle = null;
                object.requireDescription = null;
                object.requireThumbnail = null;
                object.requireLinkUrl = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireTitle != null && message.hasOwnProperty("requireTitle"))
                object.requireTitle = $root.google.protobuf.BoolValue.toObject(message.requireTitle, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireThumbnail != null && message.hasOwnProperty("requireThumbnail"))
                object.requireThumbnail = $root.query_recursive_types.ImageRequireField.toObject(message.requireThumbnail, options);
            if (message.requireLinkUrl != null && message.hasOwnProperty("requireLinkUrl"))
                object.requireLinkUrl = $root.google.protobuf.BoolValue.toObject(message.requireLinkUrl, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisementRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryAdvertisementRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryAdvertisementRequireField";
        };

        return StoryAdvertisementRequireField;
    })();

    query_recursive_types.StoryAdvertisementResponse = (function() {

        /**
         * Properties of a StoryAdvertisementResponse.
         * @memberof query_recursive_types
         * @interface IStoryAdvertisementResponse
         * @property {google.protobuf.IStringValue|null} [id] StoryAdvertisementResponse id
         * @property {google.protobuf.IStringValue|null} [title] StoryAdvertisementResponse title
         * @property {google.protobuf.IStringValue|null} [description] StoryAdvertisementResponse description
         * @property {query_recursive_types.IImageResponse|null} [thumbnail] StoryAdvertisementResponse thumbnail
         * @property {google.protobuf.IStringValue|null} [linkUrl] StoryAdvertisementResponse linkUrl
         */

        /**
         * Constructs a new StoryAdvertisementResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryAdvertisementResponse.
         * @implements IStoryAdvertisementResponse
         * @constructor
         * @param {query_recursive_types.IStoryAdvertisementResponse=} [properties] Properties to set
         */
        function StoryAdvertisementResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @instance
         */
        StoryAdvertisementResponse.prototype.id = null;

        /**
         * StoryAdvertisementResponse title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @instance
         */
        StoryAdvertisementResponse.prototype.title = null;

        /**
         * StoryAdvertisementResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @instance
         */
        StoryAdvertisementResponse.prototype.description = null;

        /**
         * StoryAdvertisementResponse thumbnail.
         * @member {query_recursive_types.IImageResponse|null|undefined} thumbnail
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @instance
         */
        StoryAdvertisementResponse.prototype.thumbnail = null;

        /**
         * StoryAdvertisementResponse linkUrl.
         * @member {google.protobuf.IStringValue|null|undefined} linkUrl
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @instance
         */
        StoryAdvertisementResponse.prototype.linkUrl = null;

        /**
         * Creates a new StoryAdvertisementResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @static
         * @param {query_recursive_types.IStoryAdvertisementResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryAdvertisementResponse} StoryAdvertisementResponse instance
         */
        StoryAdvertisementResponse.create = function create(properties) {
            return new StoryAdvertisementResponse(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementResponse message. Does not implicitly {@link query_recursive_types.StoryAdvertisementResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @static
         * @param {query_recursive_types.IStoryAdvertisementResponse} message StoryAdvertisementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.query_recursive_types.ImageResponse.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.linkUrl != null && Object.hasOwnProperty.call(message, "linkUrl"))
                $root.google.protobuf.StringValue.encode(message.linkUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryAdvertisementResponse} StoryAdvertisementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryAdvertisementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.linkUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryAdvertisementResponse} StoryAdvertisementResponse
         */
        StoryAdvertisementResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryAdvertisementResponse)
                return object;
            var message = new $root.query_recursive_types.StoryAdvertisementResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementResponse.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementResponse.thumbnail: object expected");
                message.thumbnail = $root.query_recursive_types.ImageResponse.fromObject(object.thumbnail);
            }
            if (object.linkUrl != null) {
                if (typeof object.linkUrl !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementResponse.linkUrl: object expected");
                message.linkUrl = $root.google.protobuf.StringValue.fromObject(object.linkUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @static
         * @param {query_recursive_types.StoryAdvertisementResponse} message StoryAdvertisementResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.linkUrl = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.query_recursive_types.ImageResponse.toObject(message.thumbnail, options);
            if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                object.linkUrl = $root.google.protobuf.StringValue.toObject(message.linkUrl, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisementResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryAdvertisementResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryAdvertisementResponse";
        };

        return StoryAdvertisementResponse;
    })();

    query_recursive_types.StoryAdvertisementCursorRequest = (function() {

        /**
         * Properties of a StoryAdvertisementCursorRequest.
         * @memberof query_recursive_types
         * @interface IStoryAdvertisementCursorRequest
         * @property {google.protobuf.IStringValue|null} [filterStoryId] StoryAdvertisementCursorRequest filterStoryId
         * @property {query_pagination.ICursorRequest|null} [cursor] StoryAdvertisementCursorRequest cursor
         * @property {query_recursive_types.IStoryAdvertisementRequireField|null} [requireAdvertisementList] StoryAdvertisementCursorRequest requireAdvertisementList
         * @property {google.protobuf.IBoolValue|null} [includeInactive] StoryAdvertisementCursorRequest includeInactive
         */

        /**
         * Constructs a new StoryAdvertisementCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryAdvertisementCursorRequest.
         * @implements IStoryAdvertisementCursorRequest
         * @constructor
         * @param {query_recursive_types.IStoryAdvertisementCursorRequest=} [properties] Properties to set
         */
        function StoryAdvertisementCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementCursorRequest filterStoryId.
         * @member {google.protobuf.IStringValue|null|undefined} filterStoryId
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @instance
         */
        StoryAdvertisementCursorRequest.prototype.filterStoryId = null;

        /**
         * StoryAdvertisementCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @instance
         */
        StoryAdvertisementCursorRequest.prototype.cursor = null;

        /**
         * StoryAdvertisementCursorRequest requireAdvertisementList.
         * @member {query_recursive_types.IStoryAdvertisementRequireField|null|undefined} requireAdvertisementList
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @instance
         */
        StoryAdvertisementCursorRequest.prototype.requireAdvertisementList = null;

        /**
         * StoryAdvertisementCursorRequest includeInactive.
         * @member {google.protobuf.IBoolValue|null|undefined} includeInactive
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @instance
         */
        StoryAdvertisementCursorRequest.prototype.includeInactive = null;

        /**
         * Creates a new StoryAdvertisementCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @static
         * @param {query_recursive_types.IStoryAdvertisementCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.StoryAdvertisementCursorRequest} StoryAdvertisementCursorRequest instance
         */
        StoryAdvertisementCursorRequest.create = function create(properties) {
            return new StoryAdvertisementCursorRequest(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementCursorRequest message. Does not implicitly {@link query_recursive_types.StoryAdvertisementCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @static
         * @param {query_recursive_types.IStoryAdvertisementCursorRequest} message StoryAdvertisementCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterStoryId != null && Object.hasOwnProperty.call(message, "filterStoryId"))
                $root.google.protobuf.StringValue.encode(message.filterStoryId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireAdvertisementList != null && Object.hasOwnProperty.call(message, "requireAdvertisementList"))
                $root.query_recursive_types.StoryAdvertisementRequireField.encode(message.requireAdvertisementList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.includeInactive != null && Object.hasOwnProperty.call(message, "includeInactive"))
                $root.google.protobuf.BoolValue.encode(message.includeInactive, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryAdvertisementCursorRequest} StoryAdvertisementCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryAdvertisementCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.filterStoryId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireAdvertisementList = $root.query_recursive_types.StoryAdvertisementRequireField.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.includeInactive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryAdvertisementCursorRequest} StoryAdvertisementCursorRequest
         */
        StoryAdvertisementCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryAdvertisementCursorRequest)
                return object;
            var message = new $root.query_recursive_types.StoryAdvertisementCursorRequest();
            if (object.filterStoryId != null) {
                if (typeof object.filterStoryId !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementCursorRequest.filterStoryId: object expected");
                message.filterStoryId = $root.google.protobuf.StringValue.fromObject(object.filterStoryId);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            if (object.requireAdvertisementList != null) {
                if (typeof object.requireAdvertisementList !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementCursorRequest.requireAdvertisementList: object expected");
                message.requireAdvertisementList = $root.query_recursive_types.StoryAdvertisementRequireField.fromObject(object.requireAdvertisementList);
            }
            if (object.includeInactive != null) {
                if (typeof object.includeInactive !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementCursorRequest.includeInactive: object expected");
                message.includeInactive = $root.google.protobuf.BoolValue.fromObject(object.includeInactive);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @static
         * @param {query_recursive_types.StoryAdvertisementCursorRequest} message StoryAdvertisementCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.filterStoryId = null;
                object.cursor = null;
                object.requireAdvertisementList = null;
                object.includeInactive = null;
            }
            if (message.filterStoryId != null && message.hasOwnProperty("filterStoryId"))
                object.filterStoryId = $root.google.protobuf.StringValue.toObject(message.filterStoryId, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            if (message.requireAdvertisementList != null && message.hasOwnProperty("requireAdvertisementList"))
                object.requireAdvertisementList = $root.query_recursive_types.StoryAdvertisementRequireField.toObject(message.requireAdvertisementList, options);
            if (message.includeInactive != null && message.hasOwnProperty("includeInactive"))
                object.includeInactive = $root.google.protobuf.BoolValue.toObject(message.includeInactive, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisementCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryAdvertisementCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryAdvertisementCursorRequest";
        };

        return StoryAdvertisementCursorRequest;
    })();

    query_recursive_types.StoryAdvertisementCursorResponse = (function() {

        /**
         * Properties of a StoryAdvertisementCursorResponse.
         * @memberof query_recursive_types
         * @interface IStoryAdvertisementCursorResponse
         * @property {Array.<query_recursive_types.IStoryAdvertisementResponse>|null} [advertisementList] StoryAdvertisementCursorResponse advertisementList
         * @property {query_pagination.ICursorInfo|null} [cursor] StoryAdvertisementCursorResponse cursor
         */

        /**
         * Constructs a new StoryAdvertisementCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryAdvertisementCursorResponse.
         * @implements IStoryAdvertisementCursorResponse
         * @constructor
         * @param {query_recursive_types.IStoryAdvertisementCursorResponse=} [properties] Properties to set
         */
        function StoryAdvertisementCursorResponse(properties) {
            this.advertisementList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementCursorResponse advertisementList.
         * @member {Array.<query_recursive_types.IStoryAdvertisementResponse>} advertisementList
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @instance
         */
        StoryAdvertisementCursorResponse.prototype.advertisementList = $util.emptyArray;

        /**
         * StoryAdvertisementCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @instance
         */
        StoryAdvertisementCursorResponse.prototype.cursor = null;

        /**
         * Creates a new StoryAdvertisementCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @static
         * @param {query_recursive_types.IStoryAdvertisementCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryAdvertisementCursorResponse} StoryAdvertisementCursorResponse instance
         */
        StoryAdvertisementCursorResponse.create = function create(properties) {
            return new StoryAdvertisementCursorResponse(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementCursorResponse message. Does not implicitly {@link query_recursive_types.StoryAdvertisementCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @static
         * @param {query_recursive_types.IStoryAdvertisementCursorResponse} message StoryAdvertisementCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.advertisementList != null && message.advertisementList.length)
                for (var i = 0; i < message.advertisementList.length; ++i)
                    $root.query_recursive_types.StoryAdvertisementResponse.encode(message.advertisementList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryAdvertisementCursorResponse} StoryAdvertisementCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryAdvertisementCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.advertisementList && message.advertisementList.length))
                        message.advertisementList = [];
                    message.advertisementList.push($root.query_recursive_types.StoryAdvertisementResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryAdvertisementCursorResponse} StoryAdvertisementCursorResponse
         */
        StoryAdvertisementCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryAdvertisementCursorResponse)
                return object;
            var message = new $root.query_recursive_types.StoryAdvertisementCursorResponse();
            if (object.advertisementList) {
                if (!Array.isArray(object.advertisementList))
                    throw TypeError(".query_recursive_types.StoryAdvertisementCursorResponse.advertisementList: array expected");
                message.advertisementList = [];
                for (var i = 0; i < object.advertisementList.length; ++i) {
                    if (typeof object.advertisementList[i] !== "object")
                        throw TypeError(".query_recursive_types.StoryAdvertisementCursorResponse.advertisementList: object expected");
                    message.advertisementList[i] = $root.query_recursive_types.StoryAdvertisementResponse.fromObject(object.advertisementList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.StoryAdvertisementCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @static
         * @param {query_recursive_types.StoryAdvertisementCursorResponse} message StoryAdvertisementCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.advertisementList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.advertisementList && message.advertisementList.length) {
                object.advertisementList = [];
                for (var j = 0; j < message.advertisementList.length; ++j)
                    object.advertisementList[j] = $root.query_recursive_types.StoryAdvertisementResponse.toObject(message.advertisementList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisementCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryAdvertisementCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryAdvertisementCursorResponse";
        };

        return StoryAdvertisementCursorResponse;
    })();

    query_recursive_types.CommentPermissionRequireField = (function() {

        /**
         * Properties of a CommentPermissionRequireField.
         * @memberof query_recursive_types
         * @interface ICommentPermissionRequireField
         * @property {google.protobuf.IBoolValue|null} [requireCanAdd] CommentPermissionRequireField requireCanAdd
         * @property {google.protobuf.IBoolValue|null} [requireCanSetPin] CommentPermissionRequireField requireCanSetPin
         */

        /**
         * Constructs a new CommentPermissionRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentPermissionRequireField.
         * @implements ICommentPermissionRequireField
         * @constructor
         * @param {query_recursive_types.ICommentPermissionRequireField=} [properties] Properties to set
         */
        function CommentPermissionRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentPermissionRequireField requireCanAdd.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCanAdd
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @instance
         */
        CommentPermissionRequireField.prototype.requireCanAdd = null;

        /**
         * CommentPermissionRequireField requireCanSetPin.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCanSetPin
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @instance
         */
        CommentPermissionRequireField.prototype.requireCanSetPin = null;

        /**
         * Creates a new CommentPermissionRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @static
         * @param {query_recursive_types.ICommentPermissionRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.CommentPermissionRequireField} CommentPermissionRequireField instance
         */
        CommentPermissionRequireField.create = function create(properties) {
            return new CommentPermissionRequireField(properties);
        };

        /**
         * Encodes the specified CommentPermissionRequireField message. Does not implicitly {@link query_recursive_types.CommentPermissionRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @static
         * @param {query_recursive_types.ICommentPermissionRequireField} message CommentPermissionRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentPermissionRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireCanAdd != null && Object.hasOwnProperty.call(message, "requireCanAdd"))
                $root.google.protobuf.BoolValue.encode(message.requireCanAdd, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireCanSetPin != null && Object.hasOwnProperty.call(message, "requireCanSetPin"))
                $root.google.protobuf.BoolValue.encode(message.requireCanSetPin, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentPermissionRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentPermissionRequireField} CommentPermissionRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentPermissionRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentPermissionRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireCanAdd = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireCanSetPin = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentPermissionRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentPermissionRequireField} CommentPermissionRequireField
         */
        CommentPermissionRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentPermissionRequireField)
                return object;
            var message = new $root.query_recursive_types.CommentPermissionRequireField();
            if (object.requireCanAdd != null) {
                if (typeof object.requireCanAdd !== "object")
                    throw TypeError(".query_recursive_types.CommentPermissionRequireField.requireCanAdd: object expected");
                message.requireCanAdd = $root.google.protobuf.BoolValue.fromObject(object.requireCanAdd);
            }
            if (object.requireCanSetPin != null) {
                if (typeof object.requireCanSetPin !== "object")
                    throw TypeError(".query_recursive_types.CommentPermissionRequireField.requireCanSetPin: object expected");
                message.requireCanSetPin = $root.google.protobuf.BoolValue.fromObject(object.requireCanSetPin);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentPermissionRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @static
         * @param {query_recursive_types.CommentPermissionRequireField} message CommentPermissionRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentPermissionRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireCanAdd = null;
                object.requireCanSetPin = null;
            }
            if (message.requireCanAdd != null && message.hasOwnProperty("requireCanAdd"))
                object.requireCanAdd = $root.google.protobuf.BoolValue.toObject(message.requireCanAdd, options);
            if (message.requireCanSetPin != null && message.hasOwnProperty("requireCanSetPin"))
                object.requireCanSetPin = $root.google.protobuf.BoolValue.toObject(message.requireCanSetPin, options);
            return object;
        };

        /**
         * Converts this CommentPermissionRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentPermissionRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentPermissionRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentPermissionRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentPermissionRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentPermissionRequireField";
        };

        return CommentPermissionRequireField;
    })();

    query_recursive_types.CommentPermissionResponse = (function() {

        /**
         * Properties of a CommentPermissionResponse.
         * @memberof query_recursive_types
         * @interface ICommentPermissionResponse
         * @property {google.protobuf.IBoolValue|null} [canAdd] CommentPermissionResponse canAdd
         * @property {google.protobuf.IBoolValue|null} [canSetPin] CommentPermissionResponse canSetPin
         */

        /**
         * Constructs a new CommentPermissionResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentPermissionResponse.
         * @implements ICommentPermissionResponse
         * @constructor
         * @param {query_recursive_types.ICommentPermissionResponse=} [properties] Properties to set
         */
        function CommentPermissionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentPermissionResponse canAdd.
         * @member {google.protobuf.IBoolValue|null|undefined} canAdd
         * @memberof query_recursive_types.CommentPermissionResponse
         * @instance
         */
        CommentPermissionResponse.prototype.canAdd = null;

        /**
         * CommentPermissionResponse canSetPin.
         * @member {google.protobuf.IBoolValue|null|undefined} canSetPin
         * @memberof query_recursive_types.CommentPermissionResponse
         * @instance
         */
        CommentPermissionResponse.prototype.canSetPin = null;

        /**
         * Creates a new CommentPermissionResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentPermissionResponse
         * @static
         * @param {query_recursive_types.ICommentPermissionResponse=} [properties] Properties to set
         * @returns {query_recursive_types.CommentPermissionResponse} CommentPermissionResponse instance
         */
        CommentPermissionResponse.create = function create(properties) {
            return new CommentPermissionResponse(properties);
        };

        /**
         * Encodes the specified CommentPermissionResponse message. Does not implicitly {@link query_recursive_types.CommentPermissionResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentPermissionResponse
         * @static
         * @param {query_recursive_types.ICommentPermissionResponse} message CommentPermissionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentPermissionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canAdd != null && Object.hasOwnProperty.call(message, "canAdd"))
                $root.google.protobuf.BoolValue.encode(message.canAdd, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.canSetPin != null && Object.hasOwnProperty.call(message, "canSetPin"))
                $root.google.protobuf.BoolValue.encode(message.canSetPin, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentPermissionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentPermissionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentPermissionResponse} CommentPermissionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentPermissionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentPermissionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.canAdd = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.canSetPin = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentPermissionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentPermissionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentPermissionResponse} CommentPermissionResponse
         */
        CommentPermissionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentPermissionResponse)
                return object;
            var message = new $root.query_recursive_types.CommentPermissionResponse();
            if (object.canAdd != null) {
                if (typeof object.canAdd !== "object")
                    throw TypeError(".query_recursive_types.CommentPermissionResponse.canAdd: object expected");
                message.canAdd = $root.google.protobuf.BoolValue.fromObject(object.canAdd);
            }
            if (object.canSetPin != null) {
                if (typeof object.canSetPin !== "object")
                    throw TypeError(".query_recursive_types.CommentPermissionResponse.canSetPin: object expected");
                message.canSetPin = $root.google.protobuf.BoolValue.fromObject(object.canSetPin);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentPermissionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentPermissionResponse
         * @static
         * @param {query_recursive_types.CommentPermissionResponse} message CommentPermissionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentPermissionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.canAdd = null;
                object.canSetPin = null;
            }
            if (message.canAdd != null && message.hasOwnProperty("canAdd"))
                object.canAdd = $root.google.protobuf.BoolValue.toObject(message.canAdd, options);
            if (message.canSetPin != null && message.hasOwnProperty("canSetPin"))
                object.canSetPin = $root.google.protobuf.BoolValue.toObject(message.canSetPin, options);
            return object;
        };

        /**
         * Converts this CommentPermissionResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentPermissionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentPermissionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentPermissionResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentPermissionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentPermissionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentPermissionResponse";
        };

        return CommentPermissionResponse;
    })();

    query_recursive_types.CommentSectionRequireField = (function() {

        /**
         * Properties of a CommentSectionRequireField.
         * @memberof query_recursive_types
         * @interface ICommentSectionRequireField
         * @property {query_recursive_types.ICommentRequireField|null} [requirePinnedList] CommentSectionRequireField requirePinnedList
         * @property {query_recursive_types.ICommentRequireField|null} [requirePopularList] CommentSectionRequireField requirePopularList
         * @property {query_recursive_types.ICommentCursorRequest|null} [requireAllList] CommentSectionRequireField requireAllList
         */

        /**
         * Constructs a new CommentSectionRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentSectionRequireField.
         * @implements ICommentSectionRequireField
         * @constructor
         * @param {query_recursive_types.ICommentSectionRequireField=} [properties] Properties to set
         */
        function CommentSectionRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentSectionRequireField requirePinnedList.
         * @member {query_recursive_types.ICommentRequireField|null|undefined} requirePinnedList
         * @memberof query_recursive_types.CommentSectionRequireField
         * @instance
         */
        CommentSectionRequireField.prototype.requirePinnedList = null;

        /**
         * CommentSectionRequireField requirePopularList.
         * @member {query_recursive_types.ICommentRequireField|null|undefined} requirePopularList
         * @memberof query_recursive_types.CommentSectionRequireField
         * @instance
         */
        CommentSectionRequireField.prototype.requirePopularList = null;

        /**
         * CommentSectionRequireField requireAllList.
         * @member {query_recursive_types.ICommentCursorRequest|null|undefined} requireAllList
         * @memberof query_recursive_types.CommentSectionRequireField
         * @instance
         */
        CommentSectionRequireField.prototype.requireAllList = null;

        /**
         * Creates a new CommentSectionRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentSectionRequireField
         * @static
         * @param {query_recursive_types.ICommentSectionRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.CommentSectionRequireField} CommentSectionRequireField instance
         */
        CommentSectionRequireField.create = function create(properties) {
            return new CommentSectionRequireField(properties);
        };

        /**
         * Encodes the specified CommentSectionRequireField message. Does not implicitly {@link query_recursive_types.CommentSectionRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentSectionRequireField
         * @static
         * @param {query_recursive_types.ICommentSectionRequireField} message CommentSectionRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentSectionRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requirePinnedList != null && Object.hasOwnProperty.call(message, "requirePinnedList"))
                $root.query_recursive_types.CommentRequireField.encode(message.requirePinnedList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requirePopularList != null && Object.hasOwnProperty.call(message, "requirePopularList"))
                $root.query_recursive_types.CommentRequireField.encode(message.requirePopularList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireAllList != null && Object.hasOwnProperty.call(message, "requireAllList"))
                $root.query_recursive_types.CommentCursorRequest.encode(message.requireAllList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentSectionRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentSectionRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentSectionRequireField} CommentSectionRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentSectionRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentSectionRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requirePinnedList = $root.query_recursive_types.CommentRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requirePopularList = $root.query_recursive_types.CommentRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireAllList = $root.query_recursive_types.CommentCursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentSectionRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentSectionRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentSectionRequireField} CommentSectionRequireField
         */
        CommentSectionRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentSectionRequireField)
                return object;
            var message = new $root.query_recursive_types.CommentSectionRequireField();
            if (object.requirePinnedList != null) {
                if (typeof object.requirePinnedList !== "object")
                    throw TypeError(".query_recursive_types.CommentSectionRequireField.requirePinnedList: object expected");
                message.requirePinnedList = $root.query_recursive_types.CommentRequireField.fromObject(object.requirePinnedList);
            }
            if (object.requirePopularList != null) {
                if (typeof object.requirePopularList !== "object")
                    throw TypeError(".query_recursive_types.CommentSectionRequireField.requirePopularList: object expected");
                message.requirePopularList = $root.query_recursive_types.CommentRequireField.fromObject(object.requirePopularList);
            }
            if (object.requireAllList != null) {
                if (typeof object.requireAllList !== "object")
                    throw TypeError(".query_recursive_types.CommentSectionRequireField.requireAllList: object expected");
                message.requireAllList = $root.query_recursive_types.CommentCursorRequest.fromObject(object.requireAllList);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentSectionRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentSectionRequireField
         * @static
         * @param {query_recursive_types.CommentSectionRequireField} message CommentSectionRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentSectionRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requirePinnedList = null;
                object.requirePopularList = null;
                object.requireAllList = null;
            }
            if (message.requirePinnedList != null && message.hasOwnProperty("requirePinnedList"))
                object.requirePinnedList = $root.query_recursive_types.CommentRequireField.toObject(message.requirePinnedList, options);
            if (message.requirePopularList != null && message.hasOwnProperty("requirePopularList"))
                object.requirePopularList = $root.query_recursive_types.CommentRequireField.toObject(message.requirePopularList, options);
            if (message.requireAllList != null && message.hasOwnProperty("requireAllList"))
                object.requireAllList = $root.query_recursive_types.CommentCursorRequest.toObject(message.requireAllList, options);
            return object;
        };

        /**
         * Converts this CommentSectionRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentSectionRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentSectionRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentSectionRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentSectionRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentSectionRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentSectionRequireField";
        };

        return CommentSectionRequireField;
    })();

    query_recursive_types.CommentSectionResponse = (function() {

        /**
         * Properties of a CommentSectionResponse.
         * @memberof query_recursive_types
         * @interface ICommentSectionResponse
         * @property {Array.<query_recursive_types.ICommentResponse>|null} [pinnedList] CommentSectionResponse pinnedList
         * @property {Array.<query_recursive_types.ICommentResponse>|null} [popularList] CommentSectionResponse popularList
         * @property {query_recursive_types.ICommentCursorResponse|null} [allList] CommentSectionResponse allList
         */

        /**
         * Constructs a new CommentSectionResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentSectionResponse.
         * @implements ICommentSectionResponse
         * @constructor
         * @param {query_recursive_types.ICommentSectionResponse=} [properties] Properties to set
         */
        function CommentSectionResponse(properties) {
            this.pinnedList = [];
            this.popularList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentSectionResponse pinnedList.
         * @member {Array.<query_recursive_types.ICommentResponse>} pinnedList
         * @memberof query_recursive_types.CommentSectionResponse
         * @instance
         */
        CommentSectionResponse.prototype.pinnedList = $util.emptyArray;

        /**
         * CommentSectionResponse popularList.
         * @member {Array.<query_recursive_types.ICommentResponse>} popularList
         * @memberof query_recursive_types.CommentSectionResponse
         * @instance
         */
        CommentSectionResponse.prototype.popularList = $util.emptyArray;

        /**
         * CommentSectionResponse allList.
         * @member {query_recursive_types.ICommentCursorResponse|null|undefined} allList
         * @memberof query_recursive_types.CommentSectionResponse
         * @instance
         */
        CommentSectionResponse.prototype.allList = null;

        /**
         * Creates a new CommentSectionResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentSectionResponse
         * @static
         * @param {query_recursive_types.ICommentSectionResponse=} [properties] Properties to set
         * @returns {query_recursive_types.CommentSectionResponse} CommentSectionResponse instance
         */
        CommentSectionResponse.create = function create(properties) {
            return new CommentSectionResponse(properties);
        };

        /**
         * Encodes the specified CommentSectionResponse message. Does not implicitly {@link query_recursive_types.CommentSectionResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentSectionResponse
         * @static
         * @param {query_recursive_types.ICommentSectionResponse} message CommentSectionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentSectionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pinnedList != null && message.pinnedList.length)
                for (var i = 0; i < message.pinnedList.length; ++i)
                    $root.query_recursive_types.CommentResponse.encode(message.pinnedList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.popularList != null && message.popularList.length)
                for (var i = 0; i < message.popularList.length; ++i)
                    $root.query_recursive_types.CommentResponse.encode(message.popularList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.allList != null && Object.hasOwnProperty.call(message, "allList"))
                $root.query_recursive_types.CommentCursorResponse.encode(message.allList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentSectionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentSectionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentSectionResponse} CommentSectionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentSectionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentSectionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.pinnedList && message.pinnedList.length))
                        message.pinnedList = [];
                    message.pinnedList.push($root.query_recursive_types.CommentResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.popularList && message.popularList.length))
                        message.popularList = [];
                    message.popularList.push($root.query_recursive_types.CommentResponse.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.allList = $root.query_recursive_types.CommentCursorResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentSectionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentSectionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentSectionResponse} CommentSectionResponse
         */
        CommentSectionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentSectionResponse)
                return object;
            var message = new $root.query_recursive_types.CommentSectionResponse();
            if (object.pinnedList) {
                if (!Array.isArray(object.pinnedList))
                    throw TypeError(".query_recursive_types.CommentSectionResponse.pinnedList: array expected");
                message.pinnedList = [];
                for (var i = 0; i < object.pinnedList.length; ++i) {
                    if (typeof object.pinnedList[i] !== "object")
                        throw TypeError(".query_recursive_types.CommentSectionResponse.pinnedList: object expected");
                    message.pinnedList[i] = $root.query_recursive_types.CommentResponse.fromObject(object.pinnedList[i]);
                }
            }
            if (object.popularList) {
                if (!Array.isArray(object.popularList))
                    throw TypeError(".query_recursive_types.CommentSectionResponse.popularList: array expected");
                message.popularList = [];
                for (var i = 0; i < object.popularList.length; ++i) {
                    if (typeof object.popularList[i] !== "object")
                        throw TypeError(".query_recursive_types.CommentSectionResponse.popularList: object expected");
                    message.popularList[i] = $root.query_recursive_types.CommentResponse.fromObject(object.popularList[i]);
                }
            }
            if (object.allList != null) {
                if (typeof object.allList !== "object")
                    throw TypeError(".query_recursive_types.CommentSectionResponse.allList: object expected");
                message.allList = $root.query_recursive_types.CommentCursorResponse.fromObject(object.allList);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentSectionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentSectionResponse
         * @static
         * @param {query_recursive_types.CommentSectionResponse} message CommentSectionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentSectionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.pinnedList = [];
                object.popularList = [];
            }
            if (options.defaults)
                object.allList = null;
            if (message.pinnedList && message.pinnedList.length) {
                object.pinnedList = [];
                for (var j = 0; j < message.pinnedList.length; ++j)
                    object.pinnedList[j] = $root.query_recursive_types.CommentResponse.toObject(message.pinnedList[j], options);
            }
            if (message.popularList && message.popularList.length) {
                object.popularList = [];
                for (var j = 0; j < message.popularList.length; ++j)
                    object.popularList[j] = $root.query_recursive_types.CommentResponse.toObject(message.popularList[j], options);
            }
            if (message.allList != null && message.hasOwnProperty("allList"))
                object.allList = $root.query_recursive_types.CommentCursorResponse.toObject(message.allList, options);
            return object;
        };

        /**
         * Converts this CommentSectionResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentSectionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentSectionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentSectionResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentSectionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentSectionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentSectionResponse";
        };

        return CommentSectionResponse;
    })();

    query_recursive_types.CommentRequest = (function() {

        /**
         * Properties of a CommentRequest.
         * @memberof query_recursive_types
         * @interface ICommentRequest
         * @property {google.protobuf.IStringValue|null} [id] CommentRequest id
         * @property {query_recursive_types.ICommentRequireField|null} [requireComment] CommentRequest requireComment
         */

        /**
         * Constructs a new CommentRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentRequest.
         * @implements ICommentRequest
         * @constructor
         * @param {query_recursive_types.ICommentRequest=} [properties] Properties to set
         */
        function CommentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.CommentRequest
         * @instance
         */
        CommentRequest.prototype.id = null;

        /**
         * CommentRequest requireComment.
         * @member {query_recursive_types.ICommentRequireField|null|undefined} requireComment
         * @memberof query_recursive_types.CommentRequest
         * @instance
         */
        CommentRequest.prototype.requireComment = null;

        /**
         * Creates a new CommentRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentRequest
         * @static
         * @param {query_recursive_types.ICommentRequest=} [properties] Properties to set
         * @returns {query_recursive_types.CommentRequest} CommentRequest instance
         */
        CommentRequest.create = function create(properties) {
            return new CommentRequest(properties);
        };

        /**
         * Encodes the specified CommentRequest message. Does not implicitly {@link query_recursive_types.CommentRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentRequest
         * @static
         * @param {query_recursive_types.ICommentRequest} message CommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireComment != null && Object.hasOwnProperty.call(message, "requireComment"))
                $root.query_recursive_types.CommentRequireField.encode(message.requireComment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentRequest} CommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireComment = $root.query_recursive_types.CommentRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentRequest} CommentRequest
         */
        CommentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentRequest)
                return object;
            var message = new $root.query_recursive_types.CommentRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.CommentRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireComment != null) {
                if (typeof object.requireComment !== "object")
                    throw TypeError(".query_recursive_types.CommentRequest.requireComment: object expected");
                message.requireComment = $root.query_recursive_types.CommentRequireField.fromObject(object.requireComment);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentRequest
         * @static
         * @param {query_recursive_types.CommentRequest} message CommentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireComment = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireComment != null && message.hasOwnProperty("requireComment"))
                object.requireComment = $root.query_recursive_types.CommentRequireField.toObject(message.requireComment, options);
            return object;
        };

        /**
         * Converts this CommentRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentRequest";
        };

        return CommentRequest;
    })();

    query_recursive_types.CommentRequireField = (function() {

        /**
         * Properties of a CommentRequireField.
         * @memberof query_recursive_types
         * @interface ICommentRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] CommentRequireField requireId
         * @property {query_recursive_types.ICommentRequireField|null} [requireParentComment] CommentRequireField requireParentComment
         * @property {query_recursive_types.ICommentCursorRequest|null} [requireReplyCommentPage] CommentRequireField requireReplyCommentPage
         * @property {query_recursive_types.IStoryRequireField|null} [requireStory] CommentRequireField requireStory
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] CommentRequireField requireUser
         * @property {google.protobuf.IBoolValue|null} [requireTextBody] CommentRequireField requireTextBody
         * @property {query_recursive_types.IStampMasterRequireField|null} [requireStampBody] CommentRequireField requireStampBody
         * @property {google.protobuf.IBoolValue|null} [requireLikeCount] CommentRequireField requireLikeCount
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] CommentRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireDeletedAt] CommentRequireField requireDeletedAt
         * @property {google.protobuf.IBoolValue|null} [requireDeletedBy] CommentRequireField requireDeletedBy
         * @property {google.protobuf.IBoolValue|null} [requireLikedByAuthor] CommentRequireField requireLikedByAuthor
         * @property {google.protobuf.IBoolValue|null} [requireLikedByMe] CommentRequireField requireLikedByMe
         */

        /**
         * Constructs a new CommentRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentRequireField.
         * @implements ICommentRequireField
         * @constructor
         * @param {query_recursive_types.ICommentRequireField=} [properties] Properties to set
         */
        function CommentRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireId = null;

        /**
         * CommentRequireField requireParentComment.
         * @member {query_recursive_types.ICommentRequireField|null|undefined} requireParentComment
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireParentComment = null;

        /**
         * CommentRequireField requireReplyCommentPage.
         * @member {query_recursive_types.ICommentCursorRequest|null|undefined} requireReplyCommentPage
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireReplyCommentPage = null;

        /**
         * CommentRequireField requireStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStory
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireStory = null;

        /**
         * CommentRequireField requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireUser = null;

        /**
         * CommentRequireField requireTextBody.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTextBody
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireTextBody = null;

        /**
         * CommentRequireField requireStampBody.
         * @member {query_recursive_types.IStampMasterRequireField|null|undefined} requireStampBody
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireStampBody = null;

        /**
         * CommentRequireField requireLikeCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLikeCount
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireLikeCount = null;

        /**
         * CommentRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireCreatedAt = null;

        /**
         * CommentRequireField requireDeletedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDeletedAt
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireDeletedAt = null;

        /**
         * CommentRequireField requireDeletedBy.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDeletedBy
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireDeletedBy = null;

        /**
         * CommentRequireField requireLikedByAuthor.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLikedByAuthor
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireLikedByAuthor = null;

        /**
         * CommentRequireField requireLikedByMe.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLikedByMe
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         */
        CommentRequireField.prototype.requireLikedByMe = null;

        /**
         * Creates a new CommentRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentRequireField
         * @static
         * @param {query_recursive_types.ICommentRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.CommentRequireField} CommentRequireField instance
         */
        CommentRequireField.create = function create(properties) {
            return new CommentRequireField(properties);
        };

        /**
         * Encodes the specified CommentRequireField message. Does not implicitly {@link query_recursive_types.CommentRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentRequireField
         * @static
         * @param {query_recursive_types.ICommentRequireField} message CommentRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireParentComment != null && Object.hasOwnProperty.call(message, "requireParentComment"))
                $root.query_recursive_types.CommentRequireField.encode(message.requireParentComment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireReplyCommentPage != null && Object.hasOwnProperty.call(message, "requireReplyCommentPage"))
                $root.query_recursive_types.CommentCursorRequest.encode(message.requireReplyCommentPage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireStory != null && Object.hasOwnProperty.call(message, "requireStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStory, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireTextBody != null && Object.hasOwnProperty.call(message, "requireTextBody"))
                $root.google.protobuf.BoolValue.encode(message.requireTextBody, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireStampBody != null && Object.hasOwnProperty.call(message, "requireStampBody"))
                $root.query_recursive_types.StampMasterRequireField.encode(message.requireStampBody, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireLikeCount != null && Object.hasOwnProperty.call(message, "requireLikeCount"))
                $root.google.protobuf.BoolValue.encode(message.requireLikeCount, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.requireDeletedAt != null && Object.hasOwnProperty.call(message, "requireDeletedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireDeletedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.requireDeletedBy != null && Object.hasOwnProperty.call(message, "requireDeletedBy"))
                $root.google.protobuf.BoolValue.encode(message.requireDeletedBy, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.requireLikedByAuthor != null && Object.hasOwnProperty.call(message, "requireLikedByAuthor"))
                $root.google.protobuf.BoolValue.encode(message.requireLikedByAuthor, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.requireLikedByMe != null && Object.hasOwnProperty.call(message, "requireLikedByMe"))
                $root.google.protobuf.BoolValue.encode(message.requireLikedByMe, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentRequireField} CommentRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireParentComment = $root.query_recursive_types.CommentRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireReplyCommentPage = $root.query_recursive_types.CommentCursorRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireTextBody = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireStampBody = $root.query_recursive_types.StampMasterRequireField.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireLikeCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.requireDeletedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.requireDeletedBy = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.requireLikedByAuthor = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.requireLikedByMe = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentRequireField} CommentRequireField
         */
        CommentRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentRequireField)
                return object;
            var message = new $root.query_recursive_types.CommentRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireParentComment != null) {
                if (typeof object.requireParentComment !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireParentComment: object expected");
                message.requireParentComment = $root.query_recursive_types.CommentRequireField.fromObject(object.requireParentComment);
            }
            if (object.requireReplyCommentPage != null) {
                if (typeof object.requireReplyCommentPage !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireReplyCommentPage: object expected");
                message.requireReplyCommentPage = $root.query_recursive_types.CommentCursorRequest.fromObject(object.requireReplyCommentPage);
            }
            if (object.requireStory != null) {
                if (typeof object.requireStory !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireStory: object expected");
                message.requireStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStory);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireTextBody != null) {
                if (typeof object.requireTextBody !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireTextBody: object expected");
                message.requireTextBody = $root.google.protobuf.BoolValue.fromObject(object.requireTextBody);
            }
            if (object.requireStampBody != null) {
                if (typeof object.requireStampBody !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireStampBody: object expected");
                message.requireStampBody = $root.query_recursive_types.StampMasterRequireField.fromObject(object.requireStampBody);
            }
            if (object.requireLikeCount != null) {
                if (typeof object.requireLikeCount !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireLikeCount: object expected");
                message.requireLikeCount = $root.google.protobuf.BoolValue.fromObject(object.requireLikeCount);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireDeletedAt != null) {
                if (typeof object.requireDeletedAt !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireDeletedAt: object expected");
                message.requireDeletedAt = $root.google.protobuf.BoolValue.fromObject(object.requireDeletedAt);
            }
            if (object.requireDeletedBy != null) {
                if (typeof object.requireDeletedBy !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireDeletedBy: object expected");
                message.requireDeletedBy = $root.google.protobuf.BoolValue.fromObject(object.requireDeletedBy);
            }
            if (object.requireLikedByAuthor != null) {
                if (typeof object.requireLikedByAuthor !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireLikedByAuthor: object expected");
                message.requireLikedByAuthor = $root.google.protobuf.BoolValue.fromObject(object.requireLikedByAuthor);
            }
            if (object.requireLikedByMe != null) {
                if (typeof object.requireLikedByMe !== "object")
                    throw TypeError(".query_recursive_types.CommentRequireField.requireLikedByMe: object expected");
                message.requireLikedByMe = $root.google.protobuf.BoolValue.fromObject(object.requireLikedByMe);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentRequireField
         * @static
         * @param {query_recursive_types.CommentRequireField} message CommentRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireParentComment = null;
                object.requireReplyCommentPage = null;
                object.requireStory = null;
                object.requireUser = null;
                object.requireTextBody = null;
                object.requireStampBody = null;
                object.requireLikeCount = null;
                object.requireCreatedAt = null;
                object.requireDeletedAt = null;
                object.requireDeletedBy = null;
                object.requireLikedByAuthor = null;
                object.requireLikedByMe = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireParentComment != null && message.hasOwnProperty("requireParentComment"))
                object.requireParentComment = $root.query_recursive_types.CommentRequireField.toObject(message.requireParentComment, options);
            if (message.requireReplyCommentPage != null && message.hasOwnProperty("requireReplyCommentPage"))
                object.requireReplyCommentPage = $root.query_recursive_types.CommentCursorRequest.toObject(message.requireReplyCommentPage, options);
            if (message.requireStory != null && message.hasOwnProperty("requireStory"))
                object.requireStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireStory, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireTextBody != null && message.hasOwnProperty("requireTextBody"))
                object.requireTextBody = $root.google.protobuf.BoolValue.toObject(message.requireTextBody, options);
            if (message.requireStampBody != null && message.hasOwnProperty("requireStampBody"))
                object.requireStampBody = $root.query_recursive_types.StampMasterRequireField.toObject(message.requireStampBody, options);
            if (message.requireLikeCount != null && message.hasOwnProperty("requireLikeCount"))
                object.requireLikeCount = $root.google.protobuf.BoolValue.toObject(message.requireLikeCount, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireDeletedAt != null && message.hasOwnProperty("requireDeletedAt"))
                object.requireDeletedAt = $root.google.protobuf.BoolValue.toObject(message.requireDeletedAt, options);
            if (message.requireDeletedBy != null && message.hasOwnProperty("requireDeletedBy"))
                object.requireDeletedBy = $root.google.protobuf.BoolValue.toObject(message.requireDeletedBy, options);
            if (message.requireLikedByAuthor != null && message.hasOwnProperty("requireLikedByAuthor"))
                object.requireLikedByAuthor = $root.google.protobuf.BoolValue.toObject(message.requireLikedByAuthor, options);
            if (message.requireLikedByMe != null && message.hasOwnProperty("requireLikedByMe"))
                object.requireLikedByMe = $root.google.protobuf.BoolValue.toObject(message.requireLikedByMe, options);
            return object;
        };

        /**
         * Converts this CommentRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentRequireField";
        };

        return CommentRequireField;
    })();

    query_recursive_types.CommentResponse = (function() {

        /**
         * Properties of a CommentResponse.
         * @memberof query_recursive_types
         * @interface ICommentResponse
         * @property {google.protobuf.IStringValue|null} [id] CommentResponse id
         * @property {query_recursive_types.ICommentResponse|null} [parentComment] CommentResponse parentComment
         * @property {query_recursive_types.ICommentCursorResponse|null} [replyCommentPage] CommentResponse replyCommentPage
         * @property {query_recursive_types.IStoryResponse|null} [story] CommentResponse story
         * @property {query_recursive_types.IUserResponse|null} [user] CommentResponse user
         * @property {google.protobuf.IStringValue|null} [textBody] CommentResponse textBody
         * @property {query_recursive_types.IStampMasterResponse|null} [stampBody] CommentResponse stampBody
         * @property {google.protobuf.IInt32Value|null} [likeCount] CommentResponse likeCount
         * @property {google.protobuf.ITimestamp|null} [createdAt] CommentResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [deletedAt] CommentResponse deletedAt
         * @property {comment.CommentDeletedBy|null} [deletedBy] CommentResponse deletedBy
         * @property {google.protobuf.IBoolValue|null} [likedByAuthor] CommentResponse likedByAuthor
         * @property {google.protobuf.IBoolValue|null} [likedByMe] CommentResponse likedByMe
         */

        /**
         * Constructs a new CommentResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentResponse.
         * @implements ICommentResponse
         * @constructor
         * @param {query_recursive_types.ICommentResponse=} [properties] Properties to set
         */
        function CommentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.id = null;

        /**
         * CommentResponse parentComment.
         * @member {query_recursive_types.ICommentResponse|null|undefined} parentComment
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.parentComment = null;

        /**
         * CommentResponse replyCommentPage.
         * @member {query_recursive_types.ICommentCursorResponse|null|undefined} replyCommentPage
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.replyCommentPage = null;

        /**
         * CommentResponse story.
         * @member {query_recursive_types.IStoryResponse|null|undefined} story
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.story = null;

        /**
         * CommentResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.user = null;

        /**
         * CommentResponse textBody.
         * @member {google.protobuf.IStringValue|null|undefined} textBody
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.textBody = null;

        /**
         * CommentResponse stampBody.
         * @member {query_recursive_types.IStampMasterResponse|null|undefined} stampBody
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.stampBody = null;

        /**
         * CommentResponse likeCount.
         * @member {google.protobuf.IInt32Value|null|undefined} likeCount
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.likeCount = null;

        /**
         * CommentResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.createdAt = null;

        /**
         * CommentResponse deletedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} deletedAt
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.deletedAt = null;

        /**
         * CommentResponse deletedBy.
         * @member {comment.CommentDeletedBy} deletedBy
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.deletedBy = 0;

        /**
         * CommentResponse likedByAuthor.
         * @member {google.protobuf.IBoolValue|null|undefined} likedByAuthor
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.likedByAuthor = null;

        /**
         * CommentResponse likedByMe.
         * @member {google.protobuf.IBoolValue|null|undefined} likedByMe
         * @memberof query_recursive_types.CommentResponse
         * @instance
         */
        CommentResponse.prototype.likedByMe = null;

        /**
         * Creates a new CommentResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentResponse
         * @static
         * @param {query_recursive_types.ICommentResponse=} [properties] Properties to set
         * @returns {query_recursive_types.CommentResponse} CommentResponse instance
         */
        CommentResponse.create = function create(properties) {
            return new CommentResponse(properties);
        };

        /**
         * Encodes the specified CommentResponse message. Does not implicitly {@link query_recursive_types.CommentResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentResponse
         * @static
         * @param {query_recursive_types.ICommentResponse} message CommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.parentComment != null && Object.hasOwnProperty.call(message, "parentComment"))
                $root.query_recursive_types.CommentResponse.encode(message.parentComment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.replyCommentPage != null && Object.hasOwnProperty.call(message, "replyCommentPage"))
                $root.query_recursive_types.CommentCursorResponse.encode(message.replyCommentPage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.query_recursive_types.StoryResponse.encode(message.story, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.textBody != null && Object.hasOwnProperty.call(message, "textBody"))
                $root.google.protobuf.StringValue.encode(message.textBody, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.stampBody != null && Object.hasOwnProperty.call(message, "stampBody"))
                $root.query_recursive_types.StampMasterResponse.encode(message.stampBody, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.likeCount != null && Object.hasOwnProperty.call(message, "likeCount"))
                $root.google.protobuf.Int32Value.encode(message.likeCount, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                $root.google.protobuf.Timestamp.encode(message.deletedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.deletedBy != null && Object.hasOwnProperty.call(message, "deletedBy"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.deletedBy);
            if (message.likedByAuthor != null && Object.hasOwnProperty.call(message, "likedByAuthor"))
                $root.google.protobuf.BoolValue.encode(message.likedByAuthor, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.likedByMe != null && Object.hasOwnProperty.call(message, "likedByMe"))
                $root.google.protobuf.BoolValue.encode(message.likedByMe, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentResponse} CommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.parentComment = $root.query_recursive_types.CommentResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.replyCommentPage = $root.query_recursive_types.CommentCursorResponse.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.story = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.textBody = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.stampBody = $root.query_recursive_types.StampMasterResponse.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.likeCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.deletedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.deletedBy = reader.int32();
                    break;
                case 12:
                    message.likedByAuthor = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.likedByMe = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentResponse} CommentResponse
         */
        CommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentResponse)
                return object;
            var message = new $root.query_recursive_types.CommentResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.parentComment != null) {
                if (typeof object.parentComment !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.parentComment: object expected");
                message.parentComment = $root.query_recursive_types.CommentResponse.fromObject(object.parentComment);
            }
            if (object.replyCommentPage != null) {
                if (typeof object.replyCommentPage !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.replyCommentPage: object expected");
                message.replyCommentPage = $root.query_recursive_types.CommentCursorResponse.fromObject(object.replyCommentPage);
            }
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.story: object expected");
                message.story = $root.query_recursive_types.StoryResponse.fromObject(object.story);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.textBody != null) {
                if (typeof object.textBody !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.textBody: object expected");
                message.textBody = $root.google.protobuf.StringValue.fromObject(object.textBody);
            }
            if (object.stampBody != null) {
                if (typeof object.stampBody !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.stampBody: object expected");
                message.stampBody = $root.query_recursive_types.StampMasterResponse.fromObject(object.stampBody);
            }
            if (object.likeCount != null) {
                if (typeof object.likeCount !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.likeCount: object expected");
                message.likeCount = $root.google.protobuf.Int32Value.fromObject(object.likeCount);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.deletedAt != null) {
                if (typeof object.deletedAt !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.deletedAt: object expected");
                message.deletedAt = $root.google.protobuf.Timestamp.fromObject(object.deletedAt);
            }
            switch (object.deletedBy) {
            case "COMMENT_DELETED_BY_UNSPECIFIED":
            case 0:
                message.deletedBy = 0;
                break;
            case "COMMENT_DELETED_BY_COMMENT_OWNER":
            case 1:
                message.deletedBy = 1;
                break;
            case "COMMENT_DELETED_BY_STORY_OWNER":
            case 2:
                message.deletedBy = 2;
                break;
            case "COMMENT_DELETED_BY_ADMIN":
            case 3:
                message.deletedBy = 3;
                break;
            }
            if (object.likedByAuthor != null) {
                if (typeof object.likedByAuthor !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.likedByAuthor: object expected");
                message.likedByAuthor = $root.google.protobuf.BoolValue.fromObject(object.likedByAuthor);
            }
            if (object.likedByMe != null) {
                if (typeof object.likedByMe !== "object")
                    throw TypeError(".query_recursive_types.CommentResponse.likedByMe: object expected");
                message.likedByMe = $root.google.protobuf.BoolValue.fromObject(object.likedByMe);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentResponse
         * @static
         * @param {query_recursive_types.CommentResponse} message CommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.parentComment = null;
                object.replyCommentPage = null;
                object.story = null;
                object.user = null;
                object.textBody = null;
                object.stampBody = null;
                object.likeCount = null;
                object.createdAt = null;
                object.deletedAt = null;
                object.deletedBy = options.enums === String ? "COMMENT_DELETED_BY_UNSPECIFIED" : 0;
                object.likedByAuthor = null;
                object.likedByMe = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.parentComment != null && message.hasOwnProperty("parentComment"))
                object.parentComment = $root.query_recursive_types.CommentResponse.toObject(message.parentComment, options);
            if (message.replyCommentPage != null && message.hasOwnProperty("replyCommentPage"))
                object.replyCommentPage = $root.query_recursive_types.CommentCursorResponse.toObject(message.replyCommentPage, options);
            if (message.story != null && message.hasOwnProperty("story"))
                object.story = $root.query_recursive_types.StoryResponse.toObject(message.story, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.textBody != null && message.hasOwnProperty("textBody"))
                object.textBody = $root.google.protobuf.StringValue.toObject(message.textBody, options);
            if (message.stampBody != null && message.hasOwnProperty("stampBody"))
                object.stampBody = $root.query_recursive_types.StampMasterResponse.toObject(message.stampBody, options);
            if (message.likeCount != null && message.hasOwnProperty("likeCount"))
                object.likeCount = $root.google.protobuf.Int32Value.toObject(message.likeCount, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                object.deletedAt = $root.google.protobuf.Timestamp.toObject(message.deletedAt, options);
            if (message.deletedBy != null && message.hasOwnProperty("deletedBy"))
                object.deletedBy = options.enums === String ? $root.comment.CommentDeletedBy[message.deletedBy] : message.deletedBy;
            if (message.likedByAuthor != null && message.hasOwnProperty("likedByAuthor"))
                object.likedByAuthor = $root.google.protobuf.BoolValue.toObject(message.likedByAuthor, options);
            if (message.likedByMe != null && message.hasOwnProperty("likedByMe"))
                object.likedByMe = $root.google.protobuf.BoolValue.toObject(message.likedByMe, options);
            return object;
        };

        /**
         * Converts this CommentResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentResponse";
        };

        return CommentResponse;
    })();

    query_recursive_types.CommentCursorRequest = (function() {

        /**
         * Properties of a CommentCursorRequest.
         * @memberof query_recursive_types
         * @interface ICommentCursorRequest
         * @property {query_recursive_types.ICommentRequireField|null} [requireCommentList] CommentCursorRequest requireCommentList
         * @property {query_pagination.ICursorRequest|null} [cursor] CommentCursorRequest cursor
         * @property {google.protobuf.IBoolValue|null} [includeDeleted] CommentCursorRequest includeDeleted
         * @property {google.protobuf.IBoolValue|null} [includePinned] CommentCursorRequest includePinned
         * @property {google.protobuf.IBoolValue|null} [includeHidden] CommentCursorRequest includeHidden
         */

        /**
         * Constructs a new CommentCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentCursorRequest.
         * @implements ICommentCursorRequest
         * @constructor
         * @param {query_recursive_types.ICommentCursorRequest=} [properties] Properties to set
         */
        function CommentCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentCursorRequest requireCommentList.
         * @member {query_recursive_types.ICommentRequireField|null|undefined} requireCommentList
         * @memberof query_recursive_types.CommentCursorRequest
         * @instance
         */
        CommentCursorRequest.prototype.requireCommentList = null;

        /**
         * CommentCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.CommentCursorRequest
         * @instance
         */
        CommentCursorRequest.prototype.cursor = null;

        /**
         * CommentCursorRequest includeDeleted.
         * @member {google.protobuf.IBoolValue|null|undefined} includeDeleted
         * @memberof query_recursive_types.CommentCursorRequest
         * @instance
         */
        CommentCursorRequest.prototype.includeDeleted = null;

        /**
         * CommentCursorRequest includePinned.
         * @member {google.protobuf.IBoolValue|null|undefined} includePinned
         * @memberof query_recursive_types.CommentCursorRequest
         * @instance
         */
        CommentCursorRequest.prototype.includePinned = null;

        /**
         * CommentCursorRequest includeHidden.
         * @member {google.protobuf.IBoolValue|null|undefined} includeHidden
         * @memberof query_recursive_types.CommentCursorRequest
         * @instance
         */
        CommentCursorRequest.prototype.includeHidden = null;

        /**
         * Creates a new CommentCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentCursorRequest
         * @static
         * @param {query_recursive_types.ICommentCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.CommentCursorRequest} CommentCursorRequest instance
         */
        CommentCursorRequest.create = function create(properties) {
            return new CommentCursorRequest(properties);
        };

        /**
         * Encodes the specified CommentCursorRequest message. Does not implicitly {@link query_recursive_types.CommentCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentCursorRequest
         * @static
         * @param {query_recursive_types.ICommentCursorRequest} message CommentCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireCommentList != null && Object.hasOwnProperty.call(message, "requireCommentList"))
                $root.query_recursive_types.CommentRequireField.encode(message.requireCommentList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.includeDeleted != null && Object.hasOwnProperty.call(message, "includeDeleted"))
                $root.google.protobuf.BoolValue.encode(message.includeDeleted, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.includePinned != null && Object.hasOwnProperty.call(message, "includePinned"))
                $root.google.protobuf.BoolValue.encode(message.includePinned, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.includeHidden != null && Object.hasOwnProperty.call(message, "includeHidden"))
                $root.google.protobuf.BoolValue.encode(message.includeHidden, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentCursorRequest} CommentCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireCommentList = $root.query_recursive_types.CommentRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.includeDeleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.includePinned = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.includeHidden = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentCursorRequest} CommentCursorRequest
         */
        CommentCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentCursorRequest)
                return object;
            var message = new $root.query_recursive_types.CommentCursorRequest();
            if (object.requireCommentList != null) {
                if (typeof object.requireCommentList !== "object")
                    throw TypeError(".query_recursive_types.CommentCursorRequest.requireCommentList: object expected");
                message.requireCommentList = $root.query_recursive_types.CommentRequireField.fromObject(object.requireCommentList);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.CommentCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            if (object.includeDeleted != null) {
                if (typeof object.includeDeleted !== "object")
                    throw TypeError(".query_recursive_types.CommentCursorRequest.includeDeleted: object expected");
                message.includeDeleted = $root.google.protobuf.BoolValue.fromObject(object.includeDeleted);
            }
            if (object.includePinned != null) {
                if (typeof object.includePinned !== "object")
                    throw TypeError(".query_recursive_types.CommentCursorRequest.includePinned: object expected");
                message.includePinned = $root.google.protobuf.BoolValue.fromObject(object.includePinned);
            }
            if (object.includeHidden != null) {
                if (typeof object.includeHidden !== "object")
                    throw TypeError(".query_recursive_types.CommentCursorRequest.includeHidden: object expected");
                message.includeHidden = $root.google.protobuf.BoolValue.fromObject(object.includeHidden);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentCursorRequest
         * @static
         * @param {query_recursive_types.CommentCursorRequest} message CommentCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireCommentList = null;
                object.cursor = null;
                object.includeDeleted = null;
                object.includePinned = null;
                object.includeHidden = null;
            }
            if (message.requireCommentList != null && message.hasOwnProperty("requireCommentList"))
                object.requireCommentList = $root.query_recursive_types.CommentRequireField.toObject(message.requireCommentList, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            if (message.includeDeleted != null && message.hasOwnProperty("includeDeleted"))
                object.includeDeleted = $root.google.protobuf.BoolValue.toObject(message.includeDeleted, options);
            if (message.includePinned != null && message.hasOwnProperty("includePinned"))
                object.includePinned = $root.google.protobuf.BoolValue.toObject(message.includePinned, options);
            if (message.includeHidden != null && message.hasOwnProperty("includeHidden"))
                object.includeHidden = $root.google.protobuf.BoolValue.toObject(message.includeHidden, options);
            return object;
        };

        /**
         * Converts this CommentCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentCursorRequest";
        };

        return CommentCursorRequest;
    })();

    query_recursive_types.CommentCursorResponse = (function() {

        /**
         * Properties of a CommentCursorResponse.
         * @memberof query_recursive_types
         * @interface ICommentCursorResponse
         * @property {Array.<query_recursive_types.ICommentResponse>|null} [commentList] CommentCursorResponse commentList
         * @property {query_pagination.ICursorInfo|null} [cursor] CommentCursorResponse cursor
         */

        /**
         * Constructs a new CommentCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a CommentCursorResponse.
         * @implements ICommentCursorResponse
         * @constructor
         * @param {query_recursive_types.ICommentCursorResponse=} [properties] Properties to set
         */
        function CommentCursorResponse(properties) {
            this.commentList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentCursorResponse commentList.
         * @member {Array.<query_recursive_types.ICommentResponse>} commentList
         * @memberof query_recursive_types.CommentCursorResponse
         * @instance
         */
        CommentCursorResponse.prototype.commentList = $util.emptyArray;

        /**
         * CommentCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.CommentCursorResponse
         * @instance
         */
        CommentCursorResponse.prototype.cursor = null;

        /**
         * Creates a new CommentCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.CommentCursorResponse
         * @static
         * @param {query_recursive_types.ICommentCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.CommentCursorResponse} CommentCursorResponse instance
         */
        CommentCursorResponse.create = function create(properties) {
            return new CommentCursorResponse(properties);
        };

        /**
         * Encodes the specified CommentCursorResponse message. Does not implicitly {@link query_recursive_types.CommentCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.CommentCursorResponse
         * @static
         * @param {query_recursive_types.ICommentCursorResponse} message CommentCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentList != null && message.commentList.length)
                for (var i = 0; i < message.commentList.length; ++i)
                    $root.query_recursive_types.CommentResponse.encode(message.commentList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.CommentCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.CommentCursorResponse} CommentCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.CommentCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.commentList && message.commentList.length))
                        message.commentList = [];
                    message.commentList.push($root.query_recursive_types.CommentResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.CommentCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.CommentCursorResponse} CommentCursorResponse
         */
        CommentCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.CommentCursorResponse)
                return object;
            var message = new $root.query_recursive_types.CommentCursorResponse();
            if (object.commentList) {
                if (!Array.isArray(object.commentList))
                    throw TypeError(".query_recursive_types.CommentCursorResponse.commentList: array expected");
                message.commentList = [];
                for (var i = 0; i < object.commentList.length; ++i) {
                    if (typeof object.commentList[i] !== "object")
                        throw TypeError(".query_recursive_types.CommentCursorResponse.commentList: object expected");
                    message.commentList[i] = $root.query_recursive_types.CommentResponse.fromObject(object.commentList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.CommentCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.CommentCursorResponse
         * @static
         * @param {query_recursive_types.CommentCursorResponse} message CommentCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.commentList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.commentList && message.commentList.length) {
                object.commentList = [];
                for (var j = 0; j < message.commentList.length; ++j)
                    object.commentList[j] = $root.query_recursive_types.CommentResponse.toObject(message.commentList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this CommentCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.CommentCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.CommentCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.CommentCursorResponse";
        };

        return CommentCursorResponse;
    })();

    query_recursive_types.ChallengeRequireField = (function() {

        /**
         * Properties of a ChallengeRequireField.
         * @memberof query_recursive_types
         * @interface IChallengeRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] ChallengeRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireName] ChallengeRequireField requireName
         * @property {query_recursive_types.ChallengeRequireField.ILevelRequireField|null} [requireLevelList] ChallengeRequireField requireLevelList
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] ChallengeRequireField requireUpdatedAt
         */

        /**
         * Constructs a new ChallengeRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a ChallengeRequireField.
         * @implements IChallengeRequireField
         * @constructor
         * @param {query_recursive_types.IChallengeRequireField=} [properties] Properties to set
         */
        function ChallengeRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChallengeRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.ChallengeRequireField
         * @instance
         */
        ChallengeRequireField.prototype.requireId = null;

        /**
         * ChallengeRequireField requireName.
         * @member {google.protobuf.IBoolValue|null|undefined} requireName
         * @memberof query_recursive_types.ChallengeRequireField
         * @instance
         */
        ChallengeRequireField.prototype.requireName = null;

        /**
         * ChallengeRequireField requireLevelList.
         * @member {query_recursive_types.ChallengeRequireField.ILevelRequireField|null|undefined} requireLevelList
         * @memberof query_recursive_types.ChallengeRequireField
         * @instance
         */
        ChallengeRequireField.prototype.requireLevelList = null;

        /**
         * ChallengeRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.ChallengeRequireField
         * @instance
         */
        ChallengeRequireField.prototype.requireUpdatedAt = null;

        /**
         * Creates a new ChallengeRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ChallengeRequireField
         * @static
         * @param {query_recursive_types.IChallengeRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.ChallengeRequireField} ChallengeRequireField instance
         */
        ChallengeRequireField.create = function create(properties) {
            return new ChallengeRequireField(properties);
        };

        /**
         * Encodes the specified ChallengeRequireField message. Does not implicitly {@link query_recursive_types.ChallengeRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ChallengeRequireField
         * @static
         * @param {query_recursive_types.IChallengeRequireField} message ChallengeRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChallengeRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireName != null && Object.hasOwnProperty.call(message, "requireName"))
                $root.google.protobuf.BoolValue.encode(message.requireName, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireLevelList != null && Object.hasOwnProperty.call(message, "requireLevelList"))
                $root.query_recursive_types.ChallengeRequireField.LevelRequireField.encode(message.requireLevelList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ChallengeRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ChallengeRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ChallengeRequireField} ChallengeRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChallengeRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ChallengeRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireName = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireLevelList = $root.query_recursive_types.ChallengeRequireField.LevelRequireField.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ChallengeRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ChallengeRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ChallengeRequireField} ChallengeRequireField
         */
        ChallengeRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ChallengeRequireField)
                return object;
            var message = new $root.query_recursive_types.ChallengeRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.ChallengeRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireName != null) {
                if (typeof object.requireName !== "object")
                    throw TypeError(".query_recursive_types.ChallengeRequireField.requireName: object expected");
                message.requireName = $root.google.protobuf.BoolValue.fromObject(object.requireName);
            }
            if (object.requireLevelList != null) {
                if (typeof object.requireLevelList !== "object")
                    throw TypeError(".query_recursive_types.ChallengeRequireField.requireLevelList: object expected");
                message.requireLevelList = $root.query_recursive_types.ChallengeRequireField.LevelRequireField.fromObject(object.requireLevelList);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.ChallengeRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChallengeRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ChallengeRequireField
         * @static
         * @param {query_recursive_types.ChallengeRequireField} message ChallengeRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChallengeRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireName = null;
                object.requireLevelList = null;
                object.requireUpdatedAt = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireName != null && message.hasOwnProperty("requireName"))
                object.requireName = $root.google.protobuf.BoolValue.toObject(message.requireName, options);
            if (message.requireLevelList != null && message.hasOwnProperty("requireLevelList"))
                object.requireLevelList = $root.query_recursive_types.ChallengeRequireField.LevelRequireField.toObject(message.requireLevelList, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            return object;
        };

        /**
         * Converts this ChallengeRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ChallengeRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChallengeRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChallengeRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.ChallengeRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChallengeRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ChallengeRequireField";
        };

        ChallengeRequireField.LevelRequireField = (function() {

            /**
             * Properties of a LevelRequireField.
             * @memberof query_recursive_types.ChallengeRequireField
             * @interface ILevelRequireField
             * @property {google.protobuf.IBoolValue|null} [requireLevel] LevelRequireField requireLevel
             * @property {google.protobuf.IBoolValue|null} [requireStep] LevelRequireField requireStep
             * @property {google.protobuf.IBoolValue|null} [requireConditionText] LevelRequireField requireConditionText
             * @property {query_recursive_types.IImageRequireField|null} [requireIcon] LevelRequireField requireIcon
             */

            /**
             * Constructs a new LevelRequireField.
             * @memberof query_recursive_types.ChallengeRequireField
             * @classdesc Represents a LevelRequireField.
             * @implements ILevelRequireField
             * @constructor
             * @param {query_recursive_types.ChallengeRequireField.ILevelRequireField=} [properties] Properties to set
             */
            function LevelRequireField(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LevelRequireField requireLevel.
             * @member {google.protobuf.IBoolValue|null|undefined} requireLevel
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @instance
             */
            LevelRequireField.prototype.requireLevel = null;

            /**
             * LevelRequireField requireStep.
             * @member {google.protobuf.IBoolValue|null|undefined} requireStep
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @instance
             */
            LevelRequireField.prototype.requireStep = null;

            /**
             * LevelRequireField requireConditionText.
             * @member {google.protobuf.IBoolValue|null|undefined} requireConditionText
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @instance
             */
            LevelRequireField.prototype.requireConditionText = null;

            /**
             * LevelRequireField requireIcon.
             * @member {query_recursive_types.IImageRequireField|null|undefined} requireIcon
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @instance
             */
            LevelRequireField.prototype.requireIcon = null;

            /**
             * Creates a new LevelRequireField instance using the specified properties.
             * @function create
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @static
             * @param {query_recursive_types.ChallengeRequireField.ILevelRequireField=} [properties] Properties to set
             * @returns {query_recursive_types.ChallengeRequireField.LevelRequireField} LevelRequireField instance
             */
            LevelRequireField.create = function create(properties) {
                return new LevelRequireField(properties);
            };

            /**
             * Encodes the specified LevelRequireField message. Does not implicitly {@link query_recursive_types.ChallengeRequireField.LevelRequireField.verify|verify} messages.
             * @function encode
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @static
             * @param {query_recursive_types.ChallengeRequireField.ILevelRequireField} message LevelRequireField message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LevelRequireField.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requireLevel != null && Object.hasOwnProperty.call(message, "requireLevel"))
                    $root.google.protobuf.BoolValue.encode(message.requireLevel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.requireStep != null && Object.hasOwnProperty.call(message, "requireStep"))
                    $root.google.protobuf.BoolValue.encode(message.requireStep, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.requireConditionText != null && Object.hasOwnProperty.call(message, "requireConditionText"))
                    $root.google.protobuf.BoolValue.encode(message.requireConditionText, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.requireIcon != null && Object.hasOwnProperty.call(message, "requireIcon"))
                    $root.query_recursive_types.ImageRequireField.encode(message.requireIcon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a LevelRequireField message from the specified reader or buffer.
             * @function decode
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {query_recursive_types.ChallengeRequireField.LevelRequireField} LevelRequireField
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LevelRequireField.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ChallengeRequireField.LevelRequireField();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requireLevel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.requireStep = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.requireConditionText = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.requireIcon = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a LevelRequireField message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {query_recursive_types.ChallengeRequireField.LevelRequireField} LevelRequireField
             */
            LevelRequireField.fromObject = function fromObject(object) {
                if (object instanceof $root.query_recursive_types.ChallengeRequireField.LevelRequireField)
                    return object;
                var message = new $root.query_recursive_types.ChallengeRequireField.LevelRequireField();
                if (object.requireLevel != null) {
                    if (typeof object.requireLevel !== "object")
                        throw TypeError(".query_recursive_types.ChallengeRequireField.LevelRequireField.requireLevel: object expected");
                    message.requireLevel = $root.google.protobuf.BoolValue.fromObject(object.requireLevel);
                }
                if (object.requireStep != null) {
                    if (typeof object.requireStep !== "object")
                        throw TypeError(".query_recursive_types.ChallengeRequireField.LevelRequireField.requireStep: object expected");
                    message.requireStep = $root.google.protobuf.BoolValue.fromObject(object.requireStep);
                }
                if (object.requireConditionText != null) {
                    if (typeof object.requireConditionText !== "object")
                        throw TypeError(".query_recursive_types.ChallengeRequireField.LevelRequireField.requireConditionText: object expected");
                    message.requireConditionText = $root.google.protobuf.BoolValue.fromObject(object.requireConditionText);
                }
                if (object.requireIcon != null) {
                    if (typeof object.requireIcon !== "object")
                        throw TypeError(".query_recursive_types.ChallengeRequireField.LevelRequireField.requireIcon: object expected");
                    message.requireIcon = $root.query_recursive_types.ImageRequireField.fromObject(object.requireIcon);
                }
                return message;
            };

            /**
             * Creates a plain object from a LevelRequireField message. Also converts values to other types if specified.
             * @function toObject
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @static
             * @param {query_recursive_types.ChallengeRequireField.LevelRequireField} message LevelRequireField
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LevelRequireField.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.requireLevel = null;
                    object.requireStep = null;
                    object.requireConditionText = null;
                    object.requireIcon = null;
                }
                if (message.requireLevel != null && message.hasOwnProperty("requireLevel"))
                    object.requireLevel = $root.google.protobuf.BoolValue.toObject(message.requireLevel, options);
                if (message.requireStep != null && message.hasOwnProperty("requireStep"))
                    object.requireStep = $root.google.protobuf.BoolValue.toObject(message.requireStep, options);
                if (message.requireConditionText != null && message.hasOwnProperty("requireConditionText"))
                    object.requireConditionText = $root.google.protobuf.BoolValue.toObject(message.requireConditionText, options);
                if (message.requireIcon != null && message.hasOwnProperty("requireIcon"))
                    object.requireIcon = $root.query_recursive_types.ImageRequireField.toObject(message.requireIcon, options);
                return object;
            };

            /**
             * Converts this LevelRequireField to JSON.
             * @function toJSON
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LevelRequireField.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LevelRequireField
             * @function getTypeUrl
             * @memberof query_recursive_types.ChallengeRequireField.LevelRequireField
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LevelRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/query_recursive_types.ChallengeRequireField.LevelRequireField";
            };

            return LevelRequireField;
        })();

        return ChallengeRequireField;
    })();

    query_recursive_types.ChallengeResponse = (function() {

        /**
         * Properties of a ChallengeResponse.
         * @memberof query_recursive_types
         * @interface IChallengeResponse
         * @property {google.protobuf.IStringValue|null} [id] ChallengeResponse id
         * @property {google.protobuf.IStringValue|null} [name] ChallengeResponse name
         * @property {Array.<query_recursive_types.ChallengeResponse.ILevel>|null} [levelList] ChallengeResponse levelList
         * @property {google.protobuf.ITimestamp|null} [updatedAt] ChallengeResponse updatedAt
         */

        /**
         * Constructs a new ChallengeResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a ChallengeResponse.
         * @implements IChallengeResponse
         * @constructor
         * @param {query_recursive_types.IChallengeResponse=} [properties] Properties to set
         */
        function ChallengeResponse(properties) {
            this.levelList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChallengeResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.ChallengeResponse
         * @instance
         */
        ChallengeResponse.prototype.id = null;

        /**
         * ChallengeResponse name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof query_recursive_types.ChallengeResponse
         * @instance
         */
        ChallengeResponse.prototype.name = null;

        /**
         * ChallengeResponse levelList.
         * @member {Array.<query_recursive_types.ChallengeResponse.ILevel>} levelList
         * @memberof query_recursive_types.ChallengeResponse
         * @instance
         */
        ChallengeResponse.prototype.levelList = $util.emptyArray;

        /**
         * ChallengeResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.ChallengeResponse
         * @instance
         */
        ChallengeResponse.prototype.updatedAt = null;

        /**
         * Creates a new ChallengeResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ChallengeResponse
         * @static
         * @param {query_recursive_types.IChallengeResponse=} [properties] Properties to set
         * @returns {query_recursive_types.ChallengeResponse} ChallengeResponse instance
         */
        ChallengeResponse.create = function create(properties) {
            return new ChallengeResponse(properties);
        };

        /**
         * Encodes the specified ChallengeResponse message. Does not implicitly {@link query_recursive_types.ChallengeResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ChallengeResponse
         * @static
         * @param {query_recursive_types.IChallengeResponse} message ChallengeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChallengeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.levelList != null && message.levelList.length)
                for (var i = 0; i < message.levelList.length; ++i)
                    $root.query_recursive_types.ChallengeResponse.Level.encode(message.levelList[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ChallengeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ChallengeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ChallengeResponse} ChallengeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChallengeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ChallengeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.levelList && message.levelList.length))
                        message.levelList = [];
                    message.levelList.push($root.query_recursive_types.ChallengeResponse.Level.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ChallengeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ChallengeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ChallengeResponse} ChallengeResponse
         */
        ChallengeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ChallengeResponse)
                return object;
            var message = new $root.query_recursive_types.ChallengeResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.ChallengeResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".query_recursive_types.ChallengeResponse.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            if (object.levelList) {
                if (!Array.isArray(object.levelList))
                    throw TypeError(".query_recursive_types.ChallengeResponse.levelList: array expected");
                message.levelList = [];
                for (var i = 0; i < object.levelList.length; ++i) {
                    if (typeof object.levelList[i] !== "object")
                        throw TypeError(".query_recursive_types.ChallengeResponse.levelList: object expected");
                    message.levelList[i] = $root.query_recursive_types.ChallengeResponse.Level.fromObject(object.levelList[i]);
                }
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.ChallengeResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChallengeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ChallengeResponse
         * @static
         * @param {query_recursive_types.ChallengeResponse} message ChallengeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChallengeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.levelList = [];
            if (options.defaults) {
                object.id = null;
                object.name = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            if (message.levelList && message.levelList.length) {
                object.levelList = [];
                for (var j = 0; j < message.levelList.length; ++j)
                    object.levelList[j] = $root.query_recursive_types.ChallengeResponse.Level.toObject(message.levelList[j], options);
            }
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this ChallengeResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ChallengeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChallengeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChallengeResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.ChallengeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChallengeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ChallengeResponse";
        };

        ChallengeResponse.Level = (function() {

            /**
             * Properties of a Level.
             * @memberof query_recursive_types.ChallengeResponse
             * @interface ILevel
             * @property {google.protobuf.IInt32Value|null} [level] Level level
             * @property {google.protobuf.IInt32Value|null} [step] Level step
             * @property {google.protobuf.IStringValue|null} [conditionText] Level conditionText
             * @property {query_recursive_types.IImageResponse|null} [icon] Level icon
             */

            /**
             * Constructs a new Level.
             * @memberof query_recursive_types.ChallengeResponse
             * @classdesc Represents a Level.
             * @implements ILevel
             * @constructor
             * @param {query_recursive_types.ChallengeResponse.ILevel=} [properties] Properties to set
             */
            function Level(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Level level.
             * @member {google.protobuf.IInt32Value|null|undefined} level
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @instance
             */
            Level.prototype.level = null;

            /**
             * Level step.
             * @member {google.protobuf.IInt32Value|null|undefined} step
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @instance
             */
            Level.prototype.step = null;

            /**
             * Level conditionText.
             * @member {google.protobuf.IStringValue|null|undefined} conditionText
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @instance
             */
            Level.prototype.conditionText = null;

            /**
             * Level icon.
             * @member {query_recursive_types.IImageResponse|null|undefined} icon
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @instance
             */
            Level.prototype.icon = null;

            /**
             * Creates a new Level instance using the specified properties.
             * @function create
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @static
             * @param {query_recursive_types.ChallengeResponse.ILevel=} [properties] Properties to set
             * @returns {query_recursive_types.ChallengeResponse.Level} Level instance
             */
            Level.create = function create(properties) {
                return new Level(properties);
            };

            /**
             * Encodes the specified Level message. Does not implicitly {@link query_recursive_types.ChallengeResponse.Level.verify|verify} messages.
             * @function encode
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @static
             * @param {query_recursive_types.ChallengeResponse.ILevel} message Level message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Level.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                    $root.google.protobuf.Int32Value.encode(message.level, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.step != null && Object.hasOwnProperty.call(message, "step"))
                    $root.google.protobuf.Int32Value.encode(message.step, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.conditionText != null && Object.hasOwnProperty.call(message, "conditionText"))
                    $root.google.protobuf.StringValue.encode(message.conditionText, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                    $root.query_recursive_types.ImageResponse.encode(message.icon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Level message from the specified reader or buffer.
             * @function decode
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {query_recursive_types.ChallengeResponse.Level} Level
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Level.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ChallengeResponse.Level();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.level = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.step = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.conditionText = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.icon = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a Level message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {query_recursive_types.ChallengeResponse.Level} Level
             */
            Level.fromObject = function fromObject(object) {
                if (object instanceof $root.query_recursive_types.ChallengeResponse.Level)
                    return object;
                var message = new $root.query_recursive_types.ChallengeResponse.Level();
                if (object.level != null) {
                    if (typeof object.level !== "object")
                        throw TypeError(".query_recursive_types.ChallengeResponse.Level.level: object expected");
                    message.level = $root.google.protobuf.Int32Value.fromObject(object.level);
                }
                if (object.step != null) {
                    if (typeof object.step !== "object")
                        throw TypeError(".query_recursive_types.ChallengeResponse.Level.step: object expected");
                    message.step = $root.google.protobuf.Int32Value.fromObject(object.step);
                }
                if (object.conditionText != null) {
                    if (typeof object.conditionText !== "object")
                        throw TypeError(".query_recursive_types.ChallengeResponse.Level.conditionText: object expected");
                    message.conditionText = $root.google.protobuf.StringValue.fromObject(object.conditionText);
                }
                if (object.icon != null) {
                    if (typeof object.icon !== "object")
                        throw TypeError(".query_recursive_types.ChallengeResponse.Level.icon: object expected");
                    message.icon = $root.query_recursive_types.ImageResponse.fromObject(object.icon);
                }
                return message;
            };

            /**
             * Creates a plain object from a Level message. Also converts values to other types if specified.
             * @function toObject
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @static
             * @param {query_recursive_types.ChallengeResponse.Level} message Level
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Level.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.level = null;
                    object.step = null;
                    object.conditionText = null;
                    object.icon = null;
                }
                if (message.level != null && message.hasOwnProperty("level"))
                    object.level = $root.google.protobuf.Int32Value.toObject(message.level, options);
                if (message.step != null && message.hasOwnProperty("step"))
                    object.step = $root.google.protobuf.Int32Value.toObject(message.step, options);
                if (message.conditionText != null && message.hasOwnProperty("conditionText"))
                    object.conditionText = $root.google.protobuf.StringValue.toObject(message.conditionText, options);
                if (message.icon != null && message.hasOwnProperty("icon"))
                    object.icon = $root.query_recursive_types.ImageResponse.toObject(message.icon, options);
                return object;
            };

            /**
             * Converts this Level to JSON.
             * @function toJSON
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Level.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Level
             * @function getTypeUrl
             * @memberof query_recursive_types.ChallengeResponse.Level
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Level.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/query_recursive_types.ChallengeResponse.Level";
            };

            return Level;
        })();

        return ChallengeResponse;
    })();

    query_recursive_types.ChallengeConditionRequireField = (function() {

        /**
         * Properties of a ChallengeConditionRequireField.
         * @memberof query_recursive_types
         * @interface IChallengeConditionRequireField
         * @property {query_recursive_types.IChallengeRequireField|null} [requireChallenge] ChallengeConditionRequireField requireChallenge
         * @property {google.protobuf.IBoolValue|null} [requireLevel] ChallengeConditionRequireField requireLevel
         */

        /**
         * Constructs a new ChallengeConditionRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a ChallengeConditionRequireField.
         * @implements IChallengeConditionRequireField
         * @constructor
         * @param {query_recursive_types.IChallengeConditionRequireField=} [properties] Properties to set
         */
        function ChallengeConditionRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChallengeConditionRequireField requireChallenge.
         * @member {query_recursive_types.IChallengeRequireField|null|undefined} requireChallenge
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @instance
         */
        ChallengeConditionRequireField.prototype.requireChallenge = null;

        /**
         * ChallengeConditionRequireField requireLevel.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLevel
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @instance
         */
        ChallengeConditionRequireField.prototype.requireLevel = null;

        /**
         * Creates a new ChallengeConditionRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @static
         * @param {query_recursive_types.IChallengeConditionRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.ChallengeConditionRequireField} ChallengeConditionRequireField instance
         */
        ChallengeConditionRequireField.create = function create(properties) {
            return new ChallengeConditionRequireField(properties);
        };

        /**
         * Encodes the specified ChallengeConditionRequireField message. Does not implicitly {@link query_recursive_types.ChallengeConditionRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @static
         * @param {query_recursive_types.IChallengeConditionRequireField} message ChallengeConditionRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChallengeConditionRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireChallenge != null && Object.hasOwnProperty.call(message, "requireChallenge"))
                $root.query_recursive_types.ChallengeRequireField.encode(message.requireChallenge, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireLevel != null && Object.hasOwnProperty.call(message, "requireLevel"))
                $root.google.protobuf.BoolValue.encode(message.requireLevel, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ChallengeConditionRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ChallengeConditionRequireField} ChallengeConditionRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChallengeConditionRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ChallengeConditionRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireChallenge = $root.query_recursive_types.ChallengeRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireLevel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ChallengeConditionRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ChallengeConditionRequireField} ChallengeConditionRequireField
         */
        ChallengeConditionRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ChallengeConditionRequireField)
                return object;
            var message = new $root.query_recursive_types.ChallengeConditionRequireField();
            if (object.requireChallenge != null) {
                if (typeof object.requireChallenge !== "object")
                    throw TypeError(".query_recursive_types.ChallengeConditionRequireField.requireChallenge: object expected");
                message.requireChallenge = $root.query_recursive_types.ChallengeRequireField.fromObject(object.requireChallenge);
            }
            if (object.requireLevel != null) {
                if (typeof object.requireLevel !== "object")
                    throw TypeError(".query_recursive_types.ChallengeConditionRequireField.requireLevel: object expected");
                message.requireLevel = $root.google.protobuf.BoolValue.fromObject(object.requireLevel);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChallengeConditionRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @static
         * @param {query_recursive_types.ChallengeConditionRequireField} message ChallengeConditionRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChallengeConditionRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireChallenge = null;
                object.requireLevel = null;
            }
            if (message.requireChallenge != null && message.hasOwnProperty("requireChallenge"))
                object.requireChallenge = $root.query_recursive_types.ChallengeRequireField.toObject(message.requireChallenge, options);
            if (message.requireLevel != null && message.hasOwnProperty("requireLevel"))
                object.requireLevel = $root.google.protobuf.BoolValue.toObject(message.requireLevel, options);
            return object;
        };

        /**
         * Converts this ChallengeConditionRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChallengeConditionRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChallengeConditionRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.ChallengeConditionRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChallengeConditionRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ChallengeConditionRequireField";
        };

        return ChallengeConditionRequireField;
    })();

    query_recursive_types.ChallengeConditionResponse = (function() {

        /**
         * Properties of a ChallengeConditionResponse.
         * @memberof query_recursive_types
         * @interface IChallengeConditionResponse
         * @property {query_recursive_types.IChallengeResponse|null} [challenge] ChallengeConditionResponse challenge
         * @property {google.protobuf.IInt32Value|null} [level] ChallengeConditionResponse level
         */

        /**
         * Constructs a new ChallengeConditionResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a ChallengeConditionResponse.
         * @implements IChallengeConditionResponse
         * @constructor
         * @param {query_recursive_types.IChallengeConditionResponse=} [properties] Properties to set
         */
        function ChallengeConditionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChallengeConditionResponse challenge.
         * @member {query_recursive_types.IChallengeResponse|null|undefined} challenge
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @instance
         */
        ChallengeConditionResponse.prototype.challenge = null;

        /**
         * ChallengeConditionResponse level.
         * @member {google.protobuf.IInt32Value|null|undefined} level
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @instance
         */
        ChallengeConditionResponse.prototype.level = null;

        /**
         * Creates a new ChallengeConditionResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @static
         * @param {query_recursive_types.IChallengeConditionResponse=} [properties] Properties to set
         * @returns {query_recursive_types.ChallengeConditionResponse} ChallengeConditionResponse instance
         */
        ChallengeConditionResponse.create = function create(properties) {
            return new ChallengeConditionResponse(properties);
        };

        /**
         * Encodes the specified ChallengeConditionResponse message. Does not implicitly {@link query_recursive_types.ChallengeConditionResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @static
         * @param {query_recursive_types.IChallengeConditionResponse} message ChallengeConditionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChallengeConditionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.challenge != null && Object.hasOwnProperty.call(message, "challenge"))
                $root.query_recursive_types.ChallengeResponse.encode(message.challenge, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                $root.google.protobuf.Int32Value.encode(message.level, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ChallengeConditionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ChallengeConditionResponse} ChallengeConditionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChallengeConditionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ChallengeConditionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.challenge = $root.query_recursive_types.ChallengeResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.level = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ChallengeConditionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ChallengeConditionResponse} ChallengeConditionResponse
         */
        ChallengeConditionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ChallengeConditionResponse)
                return object;
            var message = new $root.query_recursive_types.ChallengeConditionResponse();
            if (object.challenge != null) {
                if (typeof object.challenge !== "object")
                    throw TypeError(".query_recursive_types.ChallengeConditionResponse.challenge: object expected");
                message.challenge = $root.query_recursive_types.ChallengeResponse.fromObject(object.challenge);
            }
            if (object.level != null) {
                if (typeof object.level !== "object")
                    throw TypeError(".query_recursive_types.ChallengeConditionResponse.level: object expected");
                message.level = $root.google.protobuf.Int32Value.fromObject(object.level);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChallengeConditionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @static
         * @param {query_recursive_types.ChallengeConditionResponse} message ChallengeConditionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChallengeConditionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.challenge = null;
                object.level = null;
            }
            if (message.challenge != null && message.hasOwnProperty("challenge"))
                object.challenge = $root.query_recursive_types.ChallengeResponse.toObject(message.challenge, options);
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = $root.google.protobuf.Int32Value.toObject(message.level, options);
            return object;
        };

        /**
         * Converts this ChallengeConditionResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChallengeConditionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChallengeConditionResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.ChallengeConditionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChallengeConditionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ChallengeConditionResponse";
        };

        return ChallengeConditionResponse;
    })();

    query_recursive_types.StampMasterRequireField = (function() {

        /**
         * Properties of a StampMasterRequireField.
         * @memberof query_recursive_types
         * @interface IStampMasterRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] StampMasterRequireField requireId
         * @property {query_recursive_types.IImageRequireField|null} [requireImage] StampMasterRequireField requireImage
         * @property {google.protobuf.IBoolValue|null} [requireAltText] StampMasterRequireField requireAltText
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] StampMasterRequireField requireUpdatedAt
         * @property {query_recursive_types.IChallengeConditionRequireField|null} [requireChallengeCondition] StampMasterRequireField requireChallengeCondition
         */

        /**
         * Constructs a new StampMasterRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a StampMasterRequireField.
         * @implements IStampMasterRequireField
         * @constructor
         * @param {query_recursive_types.IStampMasterRequireField=} [properties] Properties to set
         */
        function StampMasterRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StampMasterRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.StampMasterRequireField
         * @instance
         */
        StampMasterRequireField.prototype.requireId = null;

        /**
         * StampMasterRequireField requireImage.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireImage
         * @memberof query_recursive_types.StampMasterRequireField
         * @instance
         */
        StampMasterRequireField.prototype.requireImage = null;

        /**
         * StampMasterRequireField requireAltText.
         * @member {google.protobuf.IBoolValue|null|undefined} requireAltText
         * @memberof query_recursive_types.StampMasterRequireField
         * @instance
         */
        StampMasterRequireField.prototype.requireAltText = null;

        /**
         * StampMasterRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.StampMasterRequireField
         * @instance
         */
        StampMasterRequireField.prototype.requireUpdatedAt = null;

        /**
         * StampMasterRequireField requireChallengeCondition.
         * @member {query_recursive_types.IChallengeConditionRequireField|null|undefined} requireChallengeCondition
         * @memberof query_recursive_types.StampMasterRequireField
         * @instance
         */
        StampMasterRequireField.prototype.requireChallengeCondition = null;

        /**
         * Creates a new StampMasterRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StampMasterRequireField
         * @static
         * @param {query_recursive_types.IStampMasterRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.StampMasterRequireField} StampMasterRequireField instance
         */
        StampMasterRequireField.create = function create(properties) {
            return new StampMasterRequireField(properties);
        };

        /**
         * Encodes the specified StampMasterRequireField message. Does not implicitly {@link query_recursive_types.StampMasterRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StampMasterRequireField
         * @static
         * @param {query_recursive_types.IStampMasterRequireField} message StampMasterRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StampMasterRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireImage != null && Object.hasOwnProperty.call(message, "requireImage"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireImage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireAltText != null && Object.hasOwnProperty.call(message, "requireAltText"))
                $root.google.protobuf.BoolValue.encode(message.requireAltText, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireChallengeCondition != null && Object.hasOwnProperty.call(message, "requireChallengeCondition"))
                $root.query_recursive_types.ChallengeConditionRequireField.encode(message.requireChallengeCondition, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StampMasterRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StampMasterRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StampMasterRequireField} StampMasterRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StampMasterRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StampMasterRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireImage = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireAltText = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireChallengeCondition = $root.query_recursive_types.ChallengeConditionRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StampMasterRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StampMasterRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StampMasterRequireField} StampMasterRequireField
         */
        StampMasterRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StampMasterRequireField)
                return object;
            var message = new $root.query_recursive_types.StampMasterRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.StampMasterRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireImage != null) {
                if (typeof object.requireImage !== "object")
                    throw TypeError(".query_recursive_types.StampMasterRequireField.requireImage: object expected");
                message.requireImage = $root.query_recursive_types.ImageRequireField.fromObject(object.requireImage);
            }
            if (object.requireAltText != null) {
                if (typeof object.requireAltText !== "object")
                    throw TypeError(".query_recursive_types.StampMasterRequireField.requireAltText: object expected");
                message.requireAltText = $root.google.protobuf.BoolValue.fromObject(object.requireAltText);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.StampMasterRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requireChallengeCondition != null) {
                if (typeof object.requireChallengeCondition !== "object")
                    throw TypeError(".query_recursive_types.StampMasterRequireField.requireChallengeCondition: object expected");
                message.requireChallengeCondition = $root.query_recursive_types.ChallengeConditionRequireField.fromObject(object.requireChallengeCondition);
            }
            return message;
        };

        /**
         * Creates a plain object from a StampMasterRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StampMasterRequireField
         * @static
         * @param {query_recursive_types.StampMasterRequireField} message StampMasterRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StampMasterRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireImage = null;
                object.requireAltText = null;
                object.requireUpdatedAt = null;
                object.requireChallengeCondition = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireImage != null && message.hasOwnProperty("requireImage"))
                object.requireImage = $root.query_recursive_types.ImageRequireField.toObject(message.requireImage, options);
            if (message.requireAltText != null && message.hasOwnProperty("requireAltText"))
                object.requireAltText = $root.google.protobuf.BoolValue.toObject(message.requireAltText, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            if (message.requireChallengeCondition != null && message.hasOwnProperty("requireChallengeCondition"))
                object.requireChallengeCondition = $root.query_recursive_types.ChallengeConditionRequireField.toObject(message.requireChallengeCondition, options);
            return object;
        };

        /**
         * Converts this StampMasterRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StampMasterRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StampMasterRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StampMasterRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.StampMasterRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StampMasterRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StampMasterRequireField";
        };

        return StampMasterRequireField;
    })();

    query_recursive_types.StampMasterResponse = (function() {

        /**
         * Properties of a StampMasterResponse.
         * @memberof query_recursive_types
         * @interface IStampMasterResponse
         * @property {google.protobuf.IStringValue|null} [id] StampMasterResponse id
         * @property {query_recursive_types.IImageResponse|null} [image] StampMasterResponse image
         * @property {google.protobuf.IStringValue|null} [altText] StampMasterResponse altText
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StampMasterResponse updatedAt
         * @property {query_recursive_types.IChallengeConditionResponse|null} [challengeCondition] StampMasterResponse challengeCondition
         */

        /**
         * Constructs a new StampMasterResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StampMasterResponse.
         * @implements IStampMasterResponse
         * @constructor
         * @param {query_recursive_types.IStampMasterResponse=} [properties] Properties to set
         */
        function StampMasterResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StampMasterResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.StampMasterResponse
         * @instance
         */
        StampMasterResponse.prototype.id = null;

        /**
         * StampMasterResponse image.
         * @member {query_recursive_types.IImageResponse|null|undefined} image
         * @memberof query_recursive_types.StampMasterResponse
         * @instance
         */
        StampMasterResponse.prototype.image = null;

        /**
         * StampMasterResponse altText.
         * @member {google.protobuf.IStringValue|null|undefined} altText
         * @memberof query_recursive_types.StampMasterResponse
         * @instance
         */
        StampMasterResponse.prototype.altText = null;

        /**
         * StampMasterResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.StampMasterResponse
         * @instance
         */
        StampMasterResponse.prototype.updatedAt = null;

        /**
         * StampMasterResponse challengeCondition.
         * @member {query_recursive_types.IChallengeConditionResponse|null|undefined} challengeCondition
         * @memberof query_recursive_types.StampMasterResponse
         * @instance
         */
        StampMasterResponse.prototype.challengeCondition = null;

        /**
         * Creates a new StampMasterResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StampMasterResponse
         * @static
         * @param {query_recursive_types.IStampMasterResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StampMasterResponse} StampMasterResponse instance
         */
        StampMasterResponse.create = function create(properties) {
            return new StampMasterResponse(properties);
        };

        /**
         * Encodes the specified StampMasterResponse message. Does not implicitly {@link query_recursive_types.StampMasterResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StampMasterResponse
         * @static
         * @param {query_recursive_types.IStampMasterResponse} message StampMasterResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StampMasterResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                $root.query_recursive_types.ImageResponse.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.altText != null && Object.hasOwnProperty.call(message, "altText"))
                $root.google.protobuf.StringValue.encode(message.altText, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.challengeCondition != null && Object.hasOwnProperty.call(message, "challengeCondition"))
                $root.query_recursive_types.ChallengeConditionResponse.encode(message.challengeCondition, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StampMasterResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StampMasterResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StampMasterResponse} StampMasterResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StampMasterResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StampMasterResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.image = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.altText = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.challengeCondition = $root.query_recursive_types.ChallengeConditionResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StampMasterResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StampMasterResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StampMasterResponse} StampMasterResponse
         */
        StampMasterResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StampMasterResponse)
                return object;
            var message = new $root.query_recursive_types.StampMasterResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.StampMasterResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.image != null) {
                if (typeof object.image !== "object")
                    throw TypeError(".query_recursive_types.StampMasterResponse.image: object expected");
                message.image = $root.query_recursive_types.ImageResponse.fromObject(object.image);
            }
            if (object.altText != null) {
                if (typeof object.altText !== "object")
                    throw TypeError(".query_recursive_types.StampMasterResponse.altText: object expected");
                message.altText = $root.google.protobuf.StringValue.fromObject(object.altText);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.StampMasterResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.challengeCondition != null) {
                if (typeof object.challengeCondition !== "object")
                    throw TypeError(".query_recursive_types.StampMasterResponse.challengeCondition: object expected");
                message.challengeCondition = $root.query_recursive_types.ChallengeConditionResponse.fromObject(object.challengeCondition);
            }
            return message;
        };

        /**
         * Creates a plain object from a StampMasterResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StampMasterResponse
         * @static
         * @param {query_recursive_types.StampMasterResponse} message StampMasterResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StampMasterResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.image = null;
                object.altText = null;
                object.updatedAt = null;
                object.challengeCondition = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.image != null && message.hasOwnProperty("image"))
                object.image = $root.query_recursive_types.ImageResponse.toObject(message.image, options);
            if (message.altText != null && message.hasOwnProperty("altText"))
                object.altText = $root.google.protobuf.StringValue.toObject(message.altText, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.challengeCondition != null && message.hasOwnProperty("challengeCondition"))
                object.challengeCondition = $root.query_recursive_types.ChallengeConditionResponse.toObject(message.challengeCondition, options);
            return object;
        };

        /**
         * Converts this StampMasterResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StampMasterResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StampMasterResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StampMasterResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StampMasterResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StampMasterResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StampMasterResponse";
        };

        return StampMasterResponse;
    })();

    query_recursive_types.BookmarkItemRequireField = (function() {

        /**
         * Properties of a BookmarkItemRequireField.
         * @memberof query_recursive_types
         * @interface IBookmarkItemRequireField
         * @property {google.protobuf.IBoolValue|null} [requireItemType] BookmarkItemRequireField requireItemType
         * @property {query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField|null} [requireItem] BookmarkItemRequireField requireItem
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] BookmarkItemRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] BookmarkItemRequireField requireUpdatedAt
         */

        /**
         * Constructs a new BookmarkItemRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a BookmarkItemRequireField.
         * @implements IBookmarkItemRequireField
         * @constructor
         * @param {query_recursive_types.IBookmarkItemRequireField=} [properties] Properties to set
         */
        function BookmarkItemRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BookmarkItemRequireField requireItemType.
         * @member {google.protobuf.IBoolValue|null|undefined} requireItemType
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @instance
         */
        BookmarkItemRequireField.prototype.requireItemType = null;

        /**
         * BookmarkItemRequireField requireItem.
         * @member {query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField|null|undefined} requireItem
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @instance
         */
        BookmarkItemRequireField.prototype.requireItem = null;

        /**
         * BookmarkItemRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @instance
         */
        BookmarkItemRequireField.prototype.requireCreatedAt = null;

        /**
         * BookmarkItemRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @instance
         */
        BookmarkItemRequireField.prototype.requireUpdatedAt = null;

        /**
         * Creates a new BookmarkItemRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @static
         * @param {query_recursive_types.IBookmarkItemRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.BookmarkItemRequireField} BookmarkItemRequireField instance
         */
        BookmarkItemRequireField.create = function create(properties) {
            return new BookmarkItemRequireField(properties);
        };

        /**
         * Encodes the specified BookmarkItemRequireField message. Does not implicitly {@link query_recursive_types.BookmarkItemRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @static
         * @param {query_recursive_types.IBookmarkItemRequireField} message BookmarkItemRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BookmarkItemRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireItemType != null && Object.hasOwnProperty.call(message, "requireItemType"))
                $root.google.protobuf.BoolValue.encode(message.requireItemType, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireItem != null && Object.hasOwnProperty.call(message, "requireItem"))
                $root.query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField.encode(message.requireItem, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BookmarkItemRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.BookmarkItemRequireField} BookmarkItemRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BookmarkItemRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.BookmarkItemRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireItemType = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireItem = $root.query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a BookmarkItemRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.BookmarkItemRequireField} BookmarkItemRequireField
         */
        BookmarkItemRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.BookmarkItemRequireField)
                return object;
            var message = new $root.query_recursive_types.BookmarkItemRequireField();
            if (object.requireItemType != null) {
                if (typeof object.requireItemType !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemRequireField.requireItemType: object expected");
                message.requireItemType = $root.google.protobuf.BoolValue.fromObject(object.requireItemType);
            }
            if (object.requireItem != null) {
                if (typeof object.requireItem !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemRequireField.requireItem: object expected");
                message.requireItem = $root.query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField.fromObject(object.requireItem);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a BookmarkItemRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @static
         * @param {query_recursive_types.BookmarkItemRequireField} message BookmarkItemRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BookmarkItemRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireItemType = null;
                object.requireItem = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
            }
            if (message.requireItemType != null && message.hasOwnProperty("requireItemType"))
                object.requireItemType = $root.google.protobuf.BoolValue.toObject(message.requireItemType, options);
            if (message.requireItem != null && message.hasOwnProperty("requireItem"))
                object.requireItem = $root.query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField.toObject(message.requireItem, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            return object;
        };

        /**
         * Converts this BookmarkItemRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BookmarkItemRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BookmarkItemRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.BookmarkItemRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BookmarkItemRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.BookmarkItemRequireField";
        };

        BookmarkItemRequireField.BookmarkItemRequireField = (function() {

            /**
             * Properties of a BookmarkItemRequireField.
             * @memberof query_recursive_types.BookmarkItemRequireField
             * @interface IBookmarkItemRequireField
             * @property {query_recursive_types.IStoryRequireField|null} [requireStory] BookmarkItemRequireField requireStory
             * @property {query_recursive_types.ISeriesRequireField|null} [requireSeries] BookmarkItemRequireField requireSeries
             */

            /**
             * Constructs a new BookmarkItemRequireField.
             * @memberof query_recursive_types.BookmarkItemRequireField
             * @classdesc Represents a BookmarkItemRequireField.
             * @implements IBookmarkItemRequireField
             * @constructor
             * @param {query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField=} [properties] Properties to set
             */
            function BookmarkItemRequireField(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BookmarkItemRequireField requireStory.
             * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStory
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @instance
             */
            BookmarkItemRequireField.prototype.requireStory = null;

            /**
             * BookmarkItemRequireField requireSeries.
             * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeries
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @instance
             */
            BookmarkItemRequireField.prototype.requireSeries = null;

            /**
             * Creates a new BookmarkItemRequireField instance using the specified properties.
             * @function create
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @static
             * @param {query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField=} [properties] Properties to set
             * @returns {query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField} BookmarkItemRequireField instance
             */
            BookmarkItemRequireField.create = function create(properties) {
                return new BookmarkItemRequireField(properties);
            };

            /**
             * Encodes the specified BookmarkItemRequireField message. Does not implicitly {@link query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField.verify|verify} messages.
             * @function encode
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @static
             * @param {query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField} message BookmarkItemRequireField message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BookmarkItemRequireField.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requireStory != null && Object.hasOwnProperty.call(message, "requireStory"))
                    $root.query_recursive_types.StoryRequireField.encode(message.requireStory, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.requireSeries != null && Object.hasOwnProperty.call(message, "requireSeries"))
                    $root.query_recursive_types.SeriesRequireField.encode(message.requireSeries, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a BookmarkItemRequireField message from the specified reader or buffer.
             * @function decode
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField} BookmarkItemRequireField
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BookmarkItemRequireField.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requireStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.requireSeries = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a BookmarkItemRequireField message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField} BookmarkItemRequireField
             */
            BookmarkItemRequireField.fromObject = function fromObject(object) {
                if (object instanceof $root.query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField)
                    return object;
                var message = new $root.query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField();
                if (object.requireStory != null) {
                    if (typeof object.requireStory !== "object")
                        throw TypeError(".query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField.requireStory: object expected");
                    message.requireStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStory);
                }
                if (object.requireSeries != null) {
                    if (typeof object.requireSeries !== "object")
                        throw TypeError(".query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField.requireSeries: object expected");
                    message.requireSeries = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeries);
                }
                return message;
            };

            /**
             * Creates a plain object from a BookmarkItemRequireField message. Also converts values to other types if specified.
             * @function toObject
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @static
             * @param {query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField} message BookmarkItemRequireField
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BookmarkItemRequireField.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.requireStory = null;
                    object.requireSeries = null;
                }
                if (message.requireStory != null && message.hasOwnProperty("requireStory"))
                    object.requireStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireStory, options);
                if (message.requireSeries != null && message.hasOwnProperty("requireSeries"))
                    object.requireSeries = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeries, options);
                return object;
            };

            /**
             * Converts this BookmarkItemRequireField to JSON.
             * @function toJSON
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BookmarkItemRequireField.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BookmarkItemRequireField
             * @function getTypeUrl
             * @memberof query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BookmarkItemRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/query_recursive_types.BookmarkItemRequireField.BookmarkItemRequireField";
            };

            return BookmarkItemRequireField;
        })();

        return BookmarkItemRequireField;
    })();

    query_recursive_types.BookmarkItemResponse = (function() {

        /**
         * Properties of a BookmarkItemResponse.
         * @memberof query_recursive_types
         * @interface IBookmarkItemResponse
         * @property {query_recursive_types.BookmarkItemResponse.ItemType|null} [itemType] BookmarkItemResponse itemType
         * @property {query_recursive_types.BookmarkItemResponse.IItem|null} [item] BookmarkItemResponse item
         * @property {google.protobuf.ITimestamp|null} [createdAt] BookmarkItemResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] BookmarkItemResponse updatedAt
         */

        /**
         * Constructs a new BookmarkItemResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a BookmarkItemResponse.
         * @implements IBookmarkItemResponse
         * @constructor
         * @param {query_recursive_types.IBookmarkItemResponse=} [properties] Properties to set
         */
        function BookmarkItemResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BookmarkItemResponse itemType.
         * @member {query_recursive_types.BookmarkItemResponse.ItemType} itemType
         * @memberof query_recursive_types.BookmarkItemResponse
         * @instance
         */
        BookmarkItemResponse.prototype.itemType = 0;

        /**
         * BookmarkItemResponse item.
         * @member {query_recursive_types.BookmarkItemResponse.IItem|null|undefined} item
         * @memberof query_recursive_types.BookmarkItemResponse
         * @instance
         */
        BookmarkItemResponse.prototype.item = null;

        /**
         * BookmarkItemResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.BookmarkItemResponse
         * @instance
         */
        BookmarkItemResponse.prototype.createdAt = null;

        /**
         * BookmarkItemResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.BookmarkItemResponse
         * @instance
         */
        BookmarkItemResponse.prototype.updatedAt = null;

        /**
         * Creates a new BookmarkItemResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.BookmarkItemResponse
         * @static
         * @param {query_recursive_types.IBookmarkItemResponse=} [properties] Properties to set
         * @returns {query_recursive_types.BookmarkItemResponse} BookmarkItemResponse instance
         */
        BookmarkItemResponse.create = function create(properties) {
            return new BookmarkItemResponse(properties);
        };

        /**
         * Encodes the specified BookmarkItemResponse message. Does not implicitly {@link query_recursive_types.BookmarkItemResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.BookmarkItemResponse
         * @static
         * @param {query_recursive_types.IBookmarkItemResponse} message BookmarkItemResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BookmarkItemResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemType != null && Object.hasOwnProperty.call(message, "itemType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.itemType);
            if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                $root.query_recursive_types.BookmarkItemResponse.Item.encode(message.item, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BookmarkItemResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.BookmarkItemResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.BookmarkItemResponse} BookmarkItemResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BookmarkItemResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.BookmarkItemResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.itemType = reader.int32();
                    break;
                case 2:
                    message.item = $root.query_recursive_types.BookmarkItemResponse.Item.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a BookmarkItemResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.BookmarkItemResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.BookmarkItemResponse} BookmarkItemResponse
         */
        BookmarkItemResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.BookmarkItemResponse)
                return object;
            var message = new $root.query_recursive_types.BookmarkItemResponse();
            switch (object.itemType) {
            case "ITEM_TYPE_UNSPECIFIED":
            case 0:
                message.itemType = 0;
                break;
            case "ITEM_TYPE_STORY":
            case 1:
                message.itemType = 1;
                break;
            case "ITEM_TYPE_SERIES":
            case 2:
                message.itemType = 2;
                break;
            }
            if (object.item != null) {
                if (typeof object.item !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemResponse.item: object expected");
                message.item = $root.query_recursive_types.BookmarkItemResponse.Item.fromObject(object.item);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a BookmarkItemResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.BookmarkItemResponse
         * @static
         * @param {query_recursive_types.BookmarkItemResponse} message BookmarkItemResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BookmarkItemResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.itemType = options.enums === String ? "ITEM_TYPE_UNSPECIFIED" : 0;
                object.item = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.itemType != null && message.hasOwnProperty("itemType"))
                object.itemType = options.enums === String ? $root.query_recursive_types.BookmarkItemResponse.ItemType[message.itemType] : message.itemType;
            if (message.item != null && message.hasOwnProperty("item"))
                object.item = $root.query_recursive_types.BookmarkItemResponse.Item.toObject(message.item, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            return object;
        };

        /**
         * Converts this BookmarkItemResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.BookmarkItemResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BookmarkItemResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BookmarkItemResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.BookmarkItemResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BookmarkItemResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.BookmarkItemResponse";
        };

        /**
         * ItemType enum.
         * @name query_recursive_types.BookmarkItemResponse.ItemType
         * @enum {number}
         * @property {number} ITEM_TYPE_UNSPECIFIED=0 ITEM_TYPE_UNSPECIFIED value
         * @property {number} ITEM_TYPE_STORY=1 ITEM_TYPE_STORY value
         * @property {number} ITEM_TYPE_SERIES=2 ITEM_TYPE_SERIES value
         */
        BookmarkItemResponse.ItemType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ITEM_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "ITEM_TYPE_STORY"] = 1;
            values[valuesById[2] = "ITEM_TYPE_SERIES"] = 2;
            return values;
        })();

        BookmarkItemResponse.Item = (function() {

            /**
             * Properties of an Item.
             * @memberof query_recursive_types.BookmarkItemResponse
             * @interface IItem
             * @property {query_recursive_types.IStoryResponse|null} [story] Item story
             * @property {query_recursive_types.ISeriesResponse|null} [series] Item series
             */

            /**
             * Constructs a new Item.
             * @memberof query_recursive_types.BookmarkItemResponse
             * @classdesc Represents an Item.
             * @implements IItem
             * @constructor
             * @param {query_recursive_types.BookmarkItemResponse.IItem=} [properties] Properties to set
             */
            function Item(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Item story.
             * @member {query_recursive_types.IStoryResponse|null|undefined} story
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @instance
             */
            Item.prototype.story = null;

            /**
             * Item series.
             * @member {query_recursive_types.ISeriesResponse|null|undefined} series
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @instance
             */
            Item.prototype.series = null;

            /**
             * Creates a new Item instance using the specified properties.
             * @function create
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @static
             * @param {query_recursive_types.BookmarkItemResponse.IItem=} [properties] Properties to set
             * @returns {query_recursive_types.BookmarkItemResponse.Item} Item instance
             */
            Item.create = function create(properties) {
                return new Item(properties);
            };

            /**
             * Encodes the specified Item message. Does not implicitly {@link query_recursive_types.BookmarkItemResponse.Item.verify|verify} messages.
             * @function encode
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @static
             * @param {query_recursive_types.BookmarkItemResponse.IItem} message Item message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Item.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                    $root.query_recursive_types.StoryResponse.encode(message.story, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                    $root.query_recursive_types.SeriesResponse.encode(message.series, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an Item message from the specified reader or buffer.
             * @function decode
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {query_recursive_types.BookmarkItemResponse.Item} Item
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Item.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.BookmarkItemResponse.Item();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.story = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.series = $root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates an Item message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {query_recursive_types.BookmarkItemResponse.Item} Item
             */
            Item.fromObject = function fromObject(object) {
                if (object instanceof $root.query_recursive_types.BookmarkItemResponse.Item)
                    return object;
                var message = new $root.query_recursive_types.BookmarkItemResponse.Item();
                if (object.story != null) {
                    if (typeof object.story !== "object")
                        throw TypeError(".query_recursive_types.BookmarkItemResponse.Item.story: object expected");
                    message.story = $root.query_recursive_types.StoryResponse.fromObject(object.story);
                }
                if (object.series != null) {
                    if (typeof object.series !== "object")
                        throw TypeError(".query_recursive_types.BookmarkItemResponse.Item.series: object expected");
                    message.series = $root.query_recursive_types.SeriesResponse.fromObject(object.series);
                }
                return message;
            };

            /**
             * Creates a plain object from an Item message. Also converts values to other types if specified.
             * @function toObject
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @static
             * @param {query_recursive_types.BookmarkItemResponse.Item} message Item
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Item.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.story = null;
                    object.series = null;
                }
                if (message.story != null && message.hasOwnProperty("story"))
                    object.story = $root.query_recursive_types.StoryResponse.toObject(message.story, options);
                if (message.series != null && message.hasOwnProperty("series"))
                    object.series = $root.query_recursive_types.SeriesResponse.toObject(message.series, options);
                return object;
            };

            /**
             * Converts this Item to JSON.
             * @function toJSON
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Item.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Item
             * @function getTypeUrl
             * @memberof query_recursive_types.BookmarkItemResponse.Item
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/query_recursive_types.BookmarkItemResponse.Item";
            };

            return Item;
        })();

        return BookmarkItemResponse;
    })();

    query_recursive_types.BookmarkItemCursorRequest = (function() {

        /**
         * Properties of a BookmarkItemCursorRequest.
         * @memberof query_recursive_types
         * @interface IBookmarkItemCursorRequest
         * @property {query_recursive_types.IBookmarkItemRequireField|null} [requireBookmarkItemList] BookmarkItemCursorRequest requireBookmarkItemList
         * @property {query_pagination.ICursorRequest|null} [cursor] BookmarkItemCursorRequest cursor
         */

        /**
         * Constructs a new BookmarkItemCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a BookmarkItemCursorRequest.
         * @implements IBookmarkItemCursorRequest
         * @constructor
         * @param {query_recursive_types.IBookmarkItemCursorRequest=} [properties] Properties to set
         */
        function BookmarkItemCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BookmarkItemCursorRequest requireBookmarkItemList.
         * @member {query_recursive_types.IBookmarkItemRequireField|null|undefined} requireBookmarkItemList
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @instance
         */
        BookmarkItemCursorRequest.prototype.requireBookmarkItemList = null;

        /**
         * BookmarkItemCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @instance
         */
        BookmarkItemCursorRequest.prototype.cursor = null;

        /**
         * Creates a new BookmarkItemCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @static
         * @param {query_recursive_types.IBookmarkItemCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.BookmarkItemCursorRequest} BookmarkItemCursorRequest instance
         */
        BookmarkItemCursorRequest.create = function create(properties) {
            return new BookmarkItemCursorRequest(properties);
        };

        /**
         * Encodes the specified BookmarkItemCursorRequest message. Does not implicitly {@link query_recursive_types.BookmarkItemCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @static
         * @param {query_recursive_types.IBookmarkItemCursorRequest} message BookmarkItemCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BookmarkItemCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireBookmarkItemList != null && Object.hasOwnProperty.call(message, "requireBookmarkItemList"))
                $root.query_recursive_types.BookmarkItemRequireField.encode(message.requireBookmarkItemList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BookmarkItemCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.BookmarkItemCursorRequest} BookmarkItemCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BookmarkItemCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.BookmarkItemCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireBookmarkItemList = $root.query_recursive_types.BookmarkItemRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a BookmarkItemCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.BookmarkItemCursorRequest} BookmarkItemCursorRequest
         */
        BookmarkItemCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.BookmarkItemCursorRequest)
                return object;
            var message = new $root.query_recursive_types.BookmarkItemCursorRequest();
            if (object.requireBookmarkItemList != null) {
                if (typeof object.requireBookmarkItemList !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemCursorRequest.requireBookmarkItemList: object expected");
                message.requireBookmarkItemList = $root.query_recursive_types.BookmarkItemRequireField.fromObject(object.requireBookmarkItemList);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a BookmarkItemCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @static
         * @param {query_recursive_types.BookmarkItemCursorRequest} message BookmarkItemCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BookmarkItemCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireBookmarkItemList = null;
                object.cursor = null;
            }
            if (message.requireBookmarkItemList != null && message.hasOwnProperty("requireBookmarkItemList"))
                object.requireBookmarkItemList = $root.query_recursive_types.BookmarkItemRequireField.toObject(message.requireBookmarkItemList, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this BookmarkItemCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BookmarkItemCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BookmarkItemCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.BookmarkItemCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BookmarkItemCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.BookmarkItemCursorRequest";
        };

        return BookmarkItemCursorRequest;
    })();

    query_recursive_types.BookmarkItemCursorResponse = (function() {

        /**
         * Properties of a BookmarkItemCursorResponse.
         * @memberof query_recursive_types
         * @interface IBookmarkItemCursorResponse
         * @property {Array.<query_recursive_types.IBookmarkItemResponse>|null} [bookmarkItemList] BookmarkItemCursorResponse bookmarkItemList
         * @property {query_pagination.ICursorInfo|null} [cursor] BookmarkItemCursorResponse cursor
         */

        /**
         * Constructs a new BookmarkItemCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a BookmarkItemCursorResponse.
         * @implements IBookmarkItemCursorResponse
         * @constructor
         * @param {query_recursive_types.IBookmarkItemCursorResponse=} [properties] Properties to set
         */
        function BookmarkItemCursorResponse(properties) {
            this.bookmarkItemList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BookmarkItemCursorResponse bookmarkItemList.
         * @member {Array.<query_recursive_types.IBookmarkItemResponse>} bookmarkItemList
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @instance
         */
        BookmarkItemCursorResponse.prototype.bookmarkItemList = $util.emptyArray;

        /**
         * BookmarkItemCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @instance
         */
        BookmarkItemCursorResponse.prototype.cursor = null;

        /**
         * Creates a new BookmarkItemCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @static
         * @param {query_recursive_types.IBookmarkItemCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.BookmarkItemCursorResponse} BookmarkItemCursorResponse instance
         */
        BookmarkItemCursorResponse.create = function create(properties) {
            return new BookmarkItemCursorResponse(properties);
        };

        /**
         * Encodes the specified BookmarkItemCursorResponse message. Does not implicitly {@link query_recursive_types.BookmarkItemCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @static
         * @param {query_recursive_types.IBookmarkItemCursorResponse} message BookmarkItemCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BookmarkItemCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bookmarkItemList != null && message.bookmarkItemList.length)
                for (var i = 0; i < message.bookmarkItemList.length; ++i)
                    $root.query_recursive_types.BookmarkItemResponse.encode(message.bookmarkItemList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BookmarkItemCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.BookmarkItemCursorResponse} BookmarkItemCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BookmarkItemCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.BookmarkItemCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.bookmarkItemList && message.bookmarkItemList.length))
                        message.bookmarkItemList = [];
                    message.bookmarkItemList.push($root.query_recursive_types.BookmarkItemResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a BookmarkItemCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.BookmarkItemCursorResponse} BookmarkItemCursorResponse
         */
        BookmarkItemCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.BookmarkItemCursorResponse)
                return object;
            var message = new $root.query_recursive_types.BookmarkItemCursorResponse();
            if (object.bookmarkItemList) {
                if (!Array.isArray(object.bookmarkItemList))
                    throw TypeError(".query_recursive_types.BookmarkItemCursorResponse.bookmarkItemList: array expected");
                message.bookmarkItemList = [];
                for (var i = 0; i < object.bookmarkItemList.length; ++i) {
                    if (typeof object.bookmarkItemList[i] !== "object")
                        throw TypeError(".query_recursive_types.BookmarkItemCursorResponse.bookmarkItemList: object expected");
                    message.bookmarkItemList[i] = $root.query_recursive_types.BookmarkItemResponse.fromObject(object.bookmarkItemList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.BookmarkItemCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a BookmarkItemCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @static
         * @param {query_recursive_types.BookmarkItemCursorResponse} message BookmarkItemCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BookmarkItemCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.bookmarkItemList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.bookmarkItemList && message.bookmarkItemList.length) {
                object.bookmarkItemList = [];
                for (var j = 0; j < message.bookmarkItemList.length; ++j)
                    object.bookmarkItemList[j] = $root.query_recursive_types.BookmarkItemResponse.toObject(message.bookmarkItemList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this BookmarkItemCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BookmarkItemCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BookmarkItemCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.BookmarkItemCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BookmarkItemCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.BookmarkItemCursorResponse";
        };

        return BookmarkItemCursorResponse;
    })();

    query_recursive_types.ProfilingRequest = (function() {

        /**
         * Properties of a ProfilingRequest.
         * @memberof query_recursive_types
         * @interface IProfilingRequest
         * @property {google.protobuf.IBoolValue|null} [requireGender] ProfilingRequest requireGender
         * @property {google.protobuf.IBoolValue|null} [requireBirthday] ProfilingRequest requireBirthday
         * @property {google.protobuf.IBoolValue|null} [requireAnsweredAt] ProfilingRequest requireAnsweredAt
         * @property {query_recursive_types.ProfilingRequest.ITagPreferenceRequest|null} [requireTagPreference] ProfilingRequest requireTagPreference
         * @property {query_recursive_types.ProfilingRequest.IStoryPreferenceRequest|null} [requireStoryPreference] ProfilingRequest requireStoryPreference
         */

        /**
         * Constructs a new ProfilingRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a ProfilingRequest.
         * @implements IProfilingRequest
         * @constructor
         * @param {query_recursive_types.IProfilingRequest=} [properties] Properties to set
         */
        function ProfilingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilingRequest requireGender.
         * @member {google.protobuf.IBoolValue|null|undefined} requireGender
         * @memberof query_recursive_types.ProfilingRequest
         * @instance
         */
        ProfilingRequest.prototype.requireGender = null;

        /**
         * ProfilingRequest requireBirthday.
         * @member {google.protobuf.IBoolValue|null|undefined} requireBirthday
         * @memberof query_recursive_types.ProfilingRequest
         * @instance
         */
        ProfilingRequest.prototype.requireBirthday = null;

        /**
         * ProfilingRequest requireAnsweredAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireAnsweredAt
         * @memberof query_recursive_types.ProfilingRequest
         * @instance
         */
        ProfilingRequest.prototype.requireAnsweredAt = null;

        /**
         * ProfilingRequest requireTagPreference.
         * @member {query_recursive_types.ProfilingRequest.ITagPreferenceRequest|null|undefined} requireTagPreference
         * @memberof query_recursive_types.ProfilingRequest
         * @instance
         */
        ProfilingRequest.prototype.requireTagPreference = null;

        /**
         * ProfilingRequest requireStoryPreference.
         * @member {query_recursive_types.ProfilingRequest.IStoryPreferenceRequest|null|undefined} requireStoryPreference
         * @memberof query_recursive_types.ProfilingRequest
         * @instance
         */
        ProfilingRequest.prototype.requireStoryPreference = null;

        /**
         * Creates a new ProfilingRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ProfilingRequest
         * @static
         * @param {query_recursive_types.IProfilingRequest=} [properties] Properties to set
         * @returns {query_recursive_types.ProfilingRequest} ProfilingRequest instance
         */
        ProfilingRequest.create = function create(properties) {
            return new ProfilingRequest(properties);
        };

        /**
         * Encodes the specified ProfilingRequest message. Does not implicitly {@link query_recursive_types.ProfilingRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ProfilingRequest
         * @static
         * @param {query_recursive_types.IProfilingRequest} message ProfilingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireGender != null && Object.hasOwnProperty.call(message, "requireGender"))
                $root.google.protobuf.BoolValue.encode(message.requireGender, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireBirthday != null && Object.hasOwnProperty.call(message, "requireBirthday"))
                $root.google.protobuf.BoolValue.encode(message.requireBirthday, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTagPreference != null && Object.hasOwnProperty.call(message, "requireTagPreference"))
                $root.query_recursive_types.ProfilingRequest.TagPreferenceRequest.encode(message.requireTagPreference, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireStoryPreference != null && Object.hasOwnProperty.call(message, "requireStoryPreference"))
                $root.query_recursive_types.ProfilingRequest.StoryPreferenceRequest.encode(message.requireStoryPreference, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireAnsweredAt != null && Object.hasOwnProperty.call(message, "requireAnsweredAt"))
                $root.google.protobuf.BoolValue.encode(message.requireAnsweredAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfilingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ProfilingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ProfilingRequest} ProfilingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ProfilingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireGender = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireBirthday = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireAnsweredAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTagPreference = $root.query_recursive_types.ProfilingRequest.TagPreferenceRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireStoryPreference = $root.query_recursive_types.ProfilingRequest.StoryPreferenceRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ProfilingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ProfilingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ProfilingRequest} ProfilingRequest
         */
        ProfilingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ProfilingRequest)
                return object;
            var message = new $root.query_recursive_types.ProfilingRequest();
            if (object.requireGender != null) {
                if (typeof object.requireGender !== "object")
                    throw TypeError(".query_recursive_types.ProfilingRequest.requireGender: object expected");
                message.requireGender = $root.google.protobuf.BoolValue.fromObject(object.requireGender);
            }
            if (object.requireBirthday != null) {
                if (typeof object.requireBirthday !== "object")
                    throw TypeError(".query_recursive_types.ProfilingRequest.requireBirthday: object expected");
                message.requireBirthday = $root.google.protobuf.BoolValue.fromObject(object.requireBirthday);
            }
            if (object.requireAnsweredAt != null) {
                if (typeof object.requireAnsweredAt !== "object")
                    throw TypeError(".query_recursive_types.ProfilingRequest.requireAnsweredAt: object expected");
                message.requireAnsweredAt = $root.google.protobuf.BoolValue.fromObject(object.requireAnsweredAt);
            }
            if (object.requireTagPreference != null) {
                if (typeof object.requireTagPreference !== "object")
                    throw TypeError(".query_recursive_types.ProfilingRequest.requireTagPreference: object expected");
                message.requireTagPreference = $root.query_recursive_types.ProfilingRequest.TagPreferenceRequest.fromObject(object.requireTagPreference);
            }
            if (object.requireStoryPreference != null) {
                if (typeof object.requireStoryPreference !== "object")
                    throw TypeError(".query_recursive_types.ProfilingRequest.requireStoryPreference: object expected");
                message.requireStoryPreference = $root.query_recursive_types.ProfilingRequest.StoryPreferenceRequest.fromObject(object.requireStoryPreference);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ProfilingRequest
         * @static
         * @param {query_recursive_types.ProfilingRequest} message ProfilingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireGender = null;
                object.requireBirthday = null;
                object.requireTagPreference = null;
                object.requireStoryPreference = null;
                object.requireAnsweredAt = null;
            }
            if (message.requireGender != null && message.hasOwnProperty("requireGender"))
                object.requireGender = $root.google.protobuf.BoolValue.toObject(message.requireGender, options);
            if (message.requireBirthday != null && message.hasOwnProperty("requireBirthday"))
                object.requireBirthday = $root.google.protobuf.BoolValue.toObject(message.requireBirthday, options);
            if (message.requireTagPreference != null && message.hasOwnProperty("requireTagPreference"))
                object.requireTagPreference = $root.query_recursive_types.ProfilingRequest.TagPreferenceRequest.toObject(message.requireTagPreference, options);
            if (message.requireStoryPreference != null && message.hasOwnProperty("requireStoryPreference"))
                object.requireStoryPreference = $root.query_recursive_types.ProfilingRequest.StoryPreferenceRequest.toObject(message.requireStoryPreference, options);
            if (message.requireAnsweredAt != null && message.hasOwnProperty("requireAnsweredAt"))
                object.requireAnsweredAt = $root.google.protobuf.BoolValue.toObject(message.requireAnsweredAt, options);
            return object;
        };

        /**
         * Converts this ProfilingRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ProfilingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProfilingRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.ProfilingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfilingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ProfilingRequest";
        };

        ProfilingRequest.TagPreferenceRequest = (function() {

            /**
             * Properties of a TagPreferenceRequest.
             * @memberof query_recursive_types.ProfilingRequest
             * @interface ITagPreferenceRequest
             * @property {google.protobuf.IBoolValue|null} [requireQuestionList] TagPreferenceRequest requireQuestionList
             * @property {google.protobuf.IBoolValue|null} [requireAnswerPreferredList] TagPreferenceRequest requireAnswerPreferredList
             */

            /**
             * Constructs a new TagPreferenceRequest.
             * @memberof query_recursive_types.ProfilingRequest
             * @classdesc Represents a TagPreferenceRequest.
             * @implements ITagPreferenceRequest
             * @constructor
             * @param {query_recursive_types.ProfilingRequest.ITagPreferenceRequest=} [properties] Properties to set
             */
            function TagPreferenceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TagPreferenceRequest requireQuestionList.
             * @member {google.protobuf.IBoolValue|null|undefined} requireQuestionList
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @instance
             */
            TagPreferenceRequest.prototype.requireQuestionList = null;

            /**
             * TagPreferenceRequest requireAnswerPreferredList.
             * @member {google.protobuf.IBoolValue|null|undefined} requireAnswerPreferredList
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @instance
             */
            TagPreferenceRequest.prototype.requireAnswerPreferredList = null;

            /**
             * Creates a new TagPreferenceRequest instance using the specified properties.
             * @function create
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @static
             * @param {query_recursive_types.ProfilingRequest.ITagPreferenceRequest=} [properties] Properties to set
             * @returns {query_recursive_types.ProfilingRequest.TagPreferenceRequest} TagPreferenceRequest instance
             */
            TagPreferenceRequest.create = function create(properties) {
                return new TagPreferenceRequest(properties);
            };

            /**
             * Encodes the specified TagPreferenceRequest message. Does not implicitly {@link query_recursive_types.ProfilingRequest.TagPreferenceRequest.verify|verify} messages.
             * @function encode
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @static
             * @param {query_recursive_types.ProfilingRequest.ITagPreferenceRequest} message TagPreferenceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TagPreferenceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requireQuestionList != null && Object.hasOwnProperty.call(message, "requireQuestionList"))
                    $root.google.protobuf.BoolValue.encode(message.requireQuestionList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.requireAnswerPreferredList != null && Object.hasOwnProperty.call(message, "requireAnswerPreferredList"))
                    $root.google.protobuf.BoolValue.encode(message.requireAnswerPreferredList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a TagPreferenceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {query_recursive_types.ProfilingRequest.TagPreferenceRequest} TagPreferenceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TagPreferenceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ProfilingRequest.TagPreferenceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requireQuestionList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.requireAnswerPreferredList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a TagPreferenceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {query_recursive_types.ProfilingRequest.TagPreferenceRequest} TagPreferenceRequest
             */
            TagPreferenceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.query_recursive_types.ProfilingRequest.TagPreferenceRequest)
                    return object;
                var message = new $root.query_recursive_types.ProfilingRequest.TagPreferenceRequest();
                if (object.requireQuestionList != null) {
                    if (typeof object.requireQuestionList !== "object")
                        throw TypeError(".query_recursive_types.ProfilingRequest.TagPreferenceRequest.requireQuestionList: object expected");
                    message.requireQuestionList = $root.google.protobuf.BoolValue.fromObject(object.requireQuestionList);
                }
                if (object.requireAnswerPreferredList != null) {
                    if (typeof object.requireAnswerPreferredList !== "object")
                        throw TypeError(".query_recursive_types.ProfilingRequest.TagPreferenceRequest.requireAnswerPreferredList: object expected");
                    message.requireAnswerPreferredList = $root.google.protobuf.BoolValue.fromObject(object.requireAnswerPreferredList);
                }
                return message;
            };

            /**
             * Creates a plain object from a TagPreferenceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @static
             * @param {query_recursive_types.ProfilingRequest.TagPreferenceRequest} message TagPreferenceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TagPreferenceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.requireQuestionList = null;
                    object.requireAnswerPreferredList = null;
                }
                if (message.requireQuestionList != null && message.hasOwnProperty("requireQuestionList"))
                    object.requireQuestionList = $root.google.protobuf.BoolValue.toObject(message.requireQuestionList, options);
                if (message.requireAnswerPreferredList != null && message.hasOwnProperty("requireAnswerPreferredList"))
                    object.requireAnswerPreferredList = $root.google.protobuf.BoolValue.toObject(message.requireAnswerPreferredList, options);
                return object;
            };

            /**
             * Converts this TagPreferenceRequest to JSON.
             * @function toJSON
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TagPreferenceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TagPreferenceRequest
             * @function getTypeUrl
             * @memberof query_recursive_types.ProfilingRequest.TagPreferenceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TagPreferenceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/query_recursive_types.ProfilingRequest.TagPreferenceRequest";
            };

            return TagPreferenceRequest;
        })();

        ProfilingRequest.StoryPreferenceRequest = (function() {

            /**
             * Properties of a StoryPreferenceRequest.
             * @memberof query_recursive_types.ProfilingRequest
             * @interface IStoryPreferenceRequest
             * @property {query_recursive_types.IStoryRequireField|null} [requireQuestionList] StoryPreferenceRequest requireQuestionList
             * @property {query_recursive_types.IStoryRequireField|null} [requireAnswerPreferredList] StoryPreferenceRequest requireAnswerPreferredList
             */

            /**
             * Constructs a new StoryPreferenceRequest.
             * @memberof query_recursive_types.ProfilingRequest
             * @classdesc Represents a StoryPreferenceRequest.
             * @implements IStoryPreferenceRequest
             * @constructor
             * @param {query_recursive_types.ProfilingRequest.IStoryPreferenceRequest=} [properties] Properties to set
             */
            function StoryPreferenceRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StoryPreferenceRequest requireQuestionList.
             * @member {query_recursive_types.IStoryRequireField|null|undefined} requireQuestionList
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @instance
             */
            StoryPreferenceRequest.prototype.requireQuestionList = null;

            /**
             * StoryPreferenceRequest requireAnswerPreferredList.
             * @member {query_recursive_types.IStoryRequireField|null|undefined} requireAnswerPreferredList
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @instance
             */
            StoryPreferenceRequest.prototype.requireAnswerPreferredList = null;

            /**
             * Creates a new StoryPreferenceRequest instance using the specified properties.
             * @function create
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @static
             * @param {query_recursive_types.ProfilingRequest.IStoryPreferenceRequest=} [properties] Properties to set
             * @returns {query_recursive_types.ProfilingRequest.StoryPreferenceRequest} StoryPreferenceRequest instance
             */
            StoryPreferenceRequest.create = function create(properties) {
                return new StoryPreferenceRequest(properties);
            };

            /**
             * Encodes the specified StoryPreferenceRequest message. Does not implicitly {@link query_recursive_types.ProfilingRequest.StoryPreferenceRequest.verify|verify} messages.
             * @function encode
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @static
             * @param {query_recursive_types.ProfilingRequest.IStoryPreferenceRequest} message StoryPreferenceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StoryPreferenceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requireQuestionList != null && Object.hasOwnProperty.call(message, "requireQuestionList"))
                    $root.query_recursive_types.StoryRequireField.encode(message.requireQuestionList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.requireAnswerPreferredList != null && Object.hasOwnProperty.call(message, "requireAnswerPreferredList"))
                    $root.query_recursive_types.StoryRequireField.encode(message.requireAnswerPreferredList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a StoryPreferenceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {query_recursive_types.ProfilingRequest.StoryPreferenceRequest} StoryPreferenceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StoryPreferenceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ProfilingRequest.StoryPreferenceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requireQuestionList = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.requireAnswerPreferredList = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a StoryPreferenceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {query_recursive_types.ProfilingRequest.StoryPreferenceRequest} StoryPreferenceRequest
             */
            StoryPreferenceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.query_recursive_types.ProfilingRequest.StoryPreferenceRequest)
                    return object;
                var message = new $root.query_recursive_types.ProfilingRequest.StoryPreferenceRequest();
                if (object.requireQuestionList != null) {
                    if (typeof object.requireQuestionList !== "object")
                        throw TypeError(".query_recursive_types.ProfilingRequest.StoryPreferenceRequest.requireQuestionList: object expected");
                    message.requireQuestionList = $root.query_recursive_types.StoryRequireField.fromObject(object.requireQuestionList);
                }
                if (object.requireAnswerPreferredList != null) {
                    if (typeof object.requireAnswerPreferredList !== "object")
                        throw TypeError(".query_recursive_types.ProfilingRequest.StoryPreferenceRequest.requireAnswerPreferredList: object expected");
                    message.requireAnswerPreferredList = $root.query_recursive_types.StoryRequireField.fromObject(object.requireAnswerPreferredList);
                }
                return message;
            };

            /**
             * Creates a plain object from a StoryPreferenceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @static
             * @param {query_recursive_types.ProfilingRequest.StoryPreferenceRequest} message StoryPreferenceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StoryPreferenceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.requireQuestionList = null;
                    object.requireAnswerPreferredList = null;
                }
                if (message.requireQuestionList != null && message.hasOwnProperty("requireQuestionList"))
                    object.requireQuestionList = $root.query_recursive_types.StoryRequireField.toObject(message.requireQuestionList, options);
                if (message.requireAnswerPreferredList != null && message.hasOwnProperty("requireAnswerPreferredList"))
                    object.requireAnswerPreferredList = $root.query_recursive_types.StoryRequireField.toObject(message.requireAnswerPreferredList, options);
                return object;
            };

            /**
             * Converts this StoryPreferenceRequest to JSON.
             * @function toJSON
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StoryPreferenceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StoryPreferenceRequest
             * @function getTypeUrl
             * @memberof query_recursive_types.ProfilingRequest.StoryPreferenceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StoryPreferenceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/query_recursive_types.ProfilingRequest.StoryPreferenceRequest";
            };

            return StoryPreferenceRequest;
        })();

        return ProfilingRequest;
    })();

    query_recursive_types.ProfilingResponse = (function() {

        /**
         * Properties of a ProfilingResponse.
         * @memberof query_recursive_types
         * @interface IProfilingResponse
         * @property {query_profiling.Gender|null} [gender] ProfilingResponse gender
         * @property {google.protobuf.ITimestamp|null} [birthday] ProfilingResponse birthday
         * @property {google.protobuf.ITimestamp|null} [answeredAt] ProfilingResponse answeredAt
         * @property {query_recursive_types.IProfilingTagPreference|null} [tagPreference] ProfilingResponse tagPreference
         * @property {query_recursive_types.IProfilingStoryPreference|null} [storyPreference] ProfilingResponse storyPreference
         */

        /**
         * Constructs a new ProfilingResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a ProfilingResponse.
         * @implements IProfilingResponse
         * @constructor
         * @param {query_recursive_types.IProfilingResponse=} [properties] Properties to set
         */
        function ProfilingResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilingResponse gender.
         * @member {query_profiling.Gender} gender
         * @memberof query_recursive_types.ProfilingResponse
         * @instance
         */
        ProfilingResponse.prototype.gender = 0;

        /**
         * ProfilingResponse birthday.
         * @member {google.protobuf.ITimestamp|null|undefined} birthday
         * @memberof query_recursive_types.ProfilingResponse
         * @instance
         */
        ProfilingResponse.prototype.birthday = null;

        /**
         * ProfilingResponse answeredAt.
         * @member {google.protobuf.ITimestamp|null|undefined} answeredAt
         * @memberof query_recursive_types.ProfilingResponse
         * @instance
         */
        ProfilingResponse.prototype.answeredAt = null;

        /**
         * ProfilingResponse tagPreference.
         * @member {query_recursive_types.IProfilingTagPreference|null|undefined} tagPreference
         * @memberof query_recursive_types.ProfilingResponse
         * @instance
         */
        ProfilingResponse.prototype.tagPreference = null;

        /**
         * ProfilingResponse storyPreference.
         * @member {query_recursive_types.IProfilingStoryPreference|null|undefined} storyPreference
         * @memberof query_recursive_types.ProfilingResponse
         * @instance
         */
        ProfilingResponse.prototype.storyPreference = null;

        /**
         * Creates a new ProfilingResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ProfilingResponse
         * @static
         * @param {query_recursive_types.IProfilingResponse=} [properties] Properties to set
         * @returns {query_recursive_types.ProfilingResponse} ProfilingResponse instance
         */
        ProfilingResponse.create = function create(properties) {
            return new ProfilingResponse(properties);
        };

        /**
         * Encodes the specified ProfilingResponse message. Does not implicitly {@link query_recursive_types.ProfilingResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ProfilingResponse
         * @static
         * @param {query_recursive_types.IProfilingResponse} message ProfilingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilingResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                $root.google.protobuf.Timestamp.encode(message.birthday, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tagPreference != null && Object.hasOwnProperty.call(message, "tagPreference"))
                $root.query_recursive_types.ProfilingTagPreference.encode(message.tagPreference, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.storyPreference != null && Object.hasOwnProperty.call(message, "storyPreference"))
                $root.query_recursive_types.ProfilingStoryPreference.encode(message.storyPreference, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.answeredAt != null && Object.hasOwnProperty.call(message, "answeredAt"))
                $root.google.protobuf.Timestamp.encode(message.answeredAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfilingResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ProfilingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ProfilingResponse} ProfilingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilingResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ProfilingResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gender = reader.int32();
                    break;
                case 2:
                    message.birthday = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.answeredAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.tagPreference = $root.query_recursive_types.ProfilingTagPreference.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.storyPreference = $root.query_recursive_types.ProfilingStoryPreference.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ProfilingResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ProfilingResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ProfilingResponse} ProfilingResponse
         */
        ProfilingResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ProfilingResponse)
                return object;
            var message = new $root.query_recursive_types.ProfilingResponse();
            switch (object.gender) {
            case "GENDER_UNSPECIFIED":
            case 0:
                message.gender = 0;
                break;
            case "GENDER_MALE":
            case 1:
                message.gender = 1;
                break;
            case "GENDER_FEMALE":
            case 2:
                message.gender = 2;
                break;
            case "GENDER_OTHER":
            case 3:
                message.gender = 3;
                break;
            }
            if (object.birthday != null) {
                if (typeof object.birthday !== "object")
                    throw TypeError(".query_recursive_types.ProfilingResponse.birthday: object expected");
                message.birthday = $root.google.protobuf.Timestamp.fromObject(object.birthday);
            }
            if (object.answeredAt != null) {
                if (typeof object.answeredAt !== "object")
                    throw TypeError(".query_recursive_types.ProfilingResponse.answeredAt: object expected");
                message.answeredAt = $root.google.protobuf.Timestamp.fromObject(object.answeredAt);
            }
            if (object.tagPreference != null) {
                if (typeof object.tagPreference !== "object")
                    throw TypeError(".query_recursive_types.ProfilingResponse.tagPreference: object expected");
                message.tagPreference = $root.query_recursive_types.ProfilingTagPreference.fromObject(object.tagPreference);
            }
            if (object.storyPreference != null) {
                if (typeof object.storyPreference !== "object")
                    throw TypeError(".query_recursive_types.ProfilingResponse.storyPreference: object expected");
                message.storyPreference = $root.query_recursive_types.ProfilingStoryPreference.fromObject(object.storyPreference);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilingResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ProfilingResponse
         * @static
         * @param {query_recursive_types.ProfilingResponse} message ProfilingResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilingResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gender = options.enums === String ? "GENDER_UNSPECIFIED" : 0;
                object.birthday = null;
                object.tagPreference = null;
                object.storyPreference = null;
                object.answeredAt = null;
            }
            if (message.gender != null && message.hasOwnProperty("gender"))
                object.gender = options.enums === String ? $root.query_profiling.Gender[message.gender] : message.gender;
            if (message.birthday != null && message.hasOwnProperty("birthday"))
                object.birthday = $root.google.protobuf.Timestamp.toObject(message.birthday, options);
            if (message.tagPreference != null && message.hasOwnProperty("tagPreference"))
                object.tagPreference = $root.query_recursive_types.ProfilingTagPreference.toObject(message.tagPreference, options);
            if (message.storyPreference != null && message.hasOwnProperty("storyPreference"))
                object.storyPreference = $root.query_recursive_types.ProfilingStoryPreference.toObject(message.storyPreference, options);
            if (message.answeredAt != null && message.hasOwnProperty("answeredAt"))
                object.answeredAt = $root.google.protobuf.Timestamp.toObject(message.answeredAt, options);
            return object;
        };

        /**
         * Converts this ProfilingResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ProfilingResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilingResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProfilingResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.ProfilingResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfilingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ProfilingResponse";
        };

        return ProfilingResponse;
    })();

    query_recursive_types.ProfilingBirthday = (function() {

        /**
         * Properties of a ProfilingBirthday.
         * @memberof query_recursive_types
         * @interface IProfilingBirthday
         * @property {google.protobuf.ITimestamp|null} [answer] ProfilingBirthday answer
         */

        /**
         * Constructs a new ProfilingBirthday.
         * @memberof query_recursive_types
         * @classdesc Represents a ProfilingBirthday.
         * @implements IProfilingBirthday
         * @constructor
         * @param {query_recursive_types.IProfilingBirthday=} [properties] Properties to set
         */
        function ProfilingBirthday(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilingBirthday answer.
         * @member {google.protobuf.ITimestamp|null|undefined} answer
         * @memberof query_recursive_types.ProfilingBirthday
         * @instance
         */
        ProfilingBirthday.prototype.answer = null;

        /**
         * Creates a new ProfilingBirthday instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ProfilingBirthday
         * @static
         * @param {query_recursive_types.IProfilingBirthday=} [properties] Properties to set
         * @returns {query_recursive_types.ProfilingBirthday} ProfilingBirthday instance
         */
        ProfilingBirthday.create = function create(properties) {
            return new ProfilingBirthday(properties);
        };

        /**
         * Encodes the specified ProfilingBirthday message. Does not implicitly {@link query_recursive_types.ProfilingBirthday.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ProfilingBirthday
         * @static
         * @param {query_recursive_types.IProfilingBirthday} message ProfilingBirthday message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilingBirthday.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.answer != null && Object.hasOwnProperty.call(message, "answer"))
                $root.google.protobuf.Timestamp.encode(message.answer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfilingBirthday message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ProfilingBirthday
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ProfilingBirthday} ProfilingBirthday
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilingBirthday.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ProfilingBirthday();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.answer = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ProfilingBirthday message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ProfilingBirthday
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ProfilingBirthday} ProfilingBirthday
         */
        ProfilingBirthday.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ProfilingBirthday)
                return object;
            var message = new $root.query_recursive_types.ProfilingBirthday();
            if (object.answer != null) {
                if (typeof object.answer !== "object")
                    throw TypeError(".query_recursive_types.ProfilingBirthday.answer: object expected");
                message.answer = $root.google.protobuf.Timestamp.fromObject(object.answer);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilingBirthday message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ProfilingBirthday
         * @static
         * @param {query_recursive_types.ProfilingBirthday} message ProfilingBirthday
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilingBirthday.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.answer = null;
            if (message.answer != null && message.hasOwnProperty("answer"))
                object.answer = $root.google.protobuf.Timestamp.toObject(message.answer, options);
            return object;
        };

        /**
         * Converts this ProfilingBirthday to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ProfilingBirthday
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilingBirthday.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProfilingBirthday
         * @function getTypeUrl
         * @memberof query_recursive_types.ProfilingBirthday
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfilingBirthday.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ProfilingBirthday";
        };

        return ProfilingBirthday;
    })();

    query_recursive_types.ProfilingStoryPreference = (function() {

        /**
         * Properties of a ProfilingStoryPreference.
         * @memberof query_recursive_types
         * @interface IProfilingStoryPreference
         * @property {Array.<query_recursive_types.IStoryResponse>|null} [questionList] ProfilingStoryPreference questionList
         * @property {Array.<query_recursive_types.IStoryResponse>|null} [answerPreferredList] ProfilingStoryPreference answerPreferredList
         */

        /**
         * Constructs a new ProfilingStoryPreference.
         * @memberof query_recursive_types
         * @classdesc Represents a ProfilingStoryPreference.
         * @implements IProfilingStoryPreference
         * @constructor
         * @param {query_recursive_types.IProfilingStoryPreference=} [properties] Properties to set
         */
        function ProfilingStoryPreference(properties) {
            this.questionList = [];
            this.answerPreferredList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilingStoryPreference questionList.
         * @member {Array.<query_recursive_types.IStoryResponse>} questionList
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @instance
         */
        ProfilingStoryPreference.prototype.questionList = $util.emptyArray;

        /**
         * ProfilingStoryPreference answerPreferredList.
         * @member {Array.<query_recursive_types.IStoryResponse>} answerPreferredList
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @instance
         */
        ProfilingStoryPreference.prototype.answerPreferredList = $util.emptyArray;

        /**
         * Creates a new ProfilingStoryPreference instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @static
         * @param {query_recursive_types.IProfilingStoryPreference=} [properties] Properties to set
         * @returns {query_recursive_types.ProfilingStoryPreference} ProfilingStoryPreference instance
         */
        ProfilingStoryPreference.create = function create(properties) {
            return new ProfilingStoryPreference(properties);
        };

        /**
         * Encodes the specified ProfilingStoryPreference message. Does not implicitly {@link query_recursive_types.ProfilingStoryPreference.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @static
         * @param {query_recursive_types.IProfilingStoryPreference} message ProfilingStoryPreference message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilingStoryPreference.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.questionList != null && message.questionList.length)
                for (var i = 0; i < message.questionList.length; ++i)
                    $root.query_recursive_types.StoryResponse.encode(message.questionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.answerPreferredList != null && message.answerPreferredList.length)
                for (var i = 0; i < message.answerPreferredList.length; ++i)
                    $root.query_recursive_types.StoryResponse.encode(message.answerPreferredList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfilingStoryPreference message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ProfilingStoryPreference} ProfilingStoryPreference
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilingStoryPreference.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ProfilingStoryPreference();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.questionList && message.questionList.length))
                        message.questionList = [];
                    message.questionList.push($root.query_recursive_types.StoryResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.answerPreferredList && message.answerPreferredList.length))
                        message.answerPreferredList = [];
                    message.answerPreferredList.push($root.query_recursive_types.StoryResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ProfilingStoryPreference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ProfilingStoryPreference} ProfilingStoryPreference
         */
        ProfilingStoryPreference.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ProfilingStoryPreference)
                return object;
            var message = new $root.query_recursive_types.ProfilingStoryPreference();
            if (object.questionList) {
                if (!Array.isArray(object.questionList))
                    throw TypeError(".query_recursive_types.ProfilingStoryPreference.questionList: array expected");
                message.questionList = [];
                for (var i = 0; i < object.questionList.length; ++i) {
                    if (typeof object.questionList[i] !== "object")
                        throw TypeError(".query_recursive_types.ProfilingStoryPreference.questionList: object expected");
                    message.questionList[i] = $root.query_recursive_types.StoryResponse.fromObject(object.questionList[i]);
                }
            }
            if (object.answerPreferredList) {
                if (!Array.isArray(object.answerPreferredList))
                    throw TypeError(".query_recursive_types.ProfilingStoryPreference.answerPreferredList: array expected");
                message.answerPreferredList = [];
                for (var i = 0; i < object.answerPreferredList.length; ++i) {
                    if (typeof object.answerPreferredList[i] !== "object")
                        throw TypeError(".query_recursive_types.ProfilingStoryPreference.answerPreferredList: object expected");
                    message.answerPreferredList[i] = $root.query_recursive_types.StoryResponse.fromObject(object.answerPreferredList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilingStoryPreference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @static
         * @param {query_recursive_types.ProfilingStoryPreference} message ProfilingStoryPreference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilingStoryPreference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.questionList = [];
                object.answerPreferredList = [];
            }
            if (message.questionList && message.questionList.length) {
                object.questionList = [];
                for (var j = 0; j < message.questionList.length; ++j)
                    object.questionList[j] = $root.query_recursive_types.StoryResponse.toObject(message.questionList[j], options);
            }
            if (message.answerPreferredList && message.answerPreferredList.length) {
                object.answerPreferredList = [];
                for (var j = 0; j < message.answerPreferredList.length; ++j)
                    object.answerPreferredList[j] = $root.query_recursive_types.StoryResponse.toObject(message.answerPreferredList[j], options);
            }
            return object;
        };

        /**
         * Converts this ProfilingStoryPreference to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilingStoryPreference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProfilingStoryPreference
         * @function getTypeUrl
         * @memberof query_recursive_types.ProfilingStoryPreference
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfilingStoryPreference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ProfilingStoryPreference";
        };

        return ProfilingStoryPreference;
    })();

    query_recursive_types.ProfilingTagPreference = (function() {

        /**
         * Properties of a ProfilingTagPreference.
         * @memberof query_recursive_types
         * @interface IProfilingTagPreference
         * @property {Array.<query_profiling.IProfilingTagPair>|null} [questionList] ProfilingTagPreference questionList
         * @property {Array.<query_profiling.IProfilingTagPair>|null} [answerPreferredList] ProfilingTagPreference answerPreferredList
         */

        /**
         * Constructs a new ProfilingTagPreference.
         * @memberof query_recursive_types
         * @classdesc Represents a ProfilingTagPreference.
         * @implements IProfilingTagPreference
         * @constructor
         * @param {query_recursive_types.IProfilingTagPreference=} [properties] Properties to set
         */
        function ProfilingTagPreference(properties) {
            this.questionList = [];
            this.answerPreferredList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilingTagPreference questionList.
         * @member {Array.<query_profiling.IProfilingTagPair>} questionList
         * @memberof query_recursive_types.ProfilingTagPreference
         * @instance
         */
        ProfilingTagPreference.prototype.questionList = $util.emptyArray;

        /**
         * ProfilingTagPreference answerPreferredList.
         * @member {Array.<query_profiling.IProfilingTagPair>} answerPreferredList
         * @memberof query_recursive_types.ProfilingTagPreference
         * @instance
         */
        ProfilingTagPreference.prototype.answerPreferredList = $util.emptyArray;

        /**
         * Creates a new ProfilingTagPreference instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ProfilingTagPreference
         * @static
         * @param {query_recursive_types.IProfilingTagPreference=} [properties] Properties to set
         * @returns {query_recursive_types.ProfilingTagPreference} ProfilingTagPreference instance
         */
        ProfilingTagPreference.create = function create(properties) {
            return new ProfilingTagPreference(properties);
        };

        /**
         * Encodes the specified ProfilingTagPreference message. Does not implicitly {@link query_recursive_types.ProfilingTagPreference.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ProfilingTagPreference
         * @static
         * @param {query_recursive_types.IProfilingTagPreference} message ProfilingTagPreference message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilingTagPreference.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.questionList != null && message.questionList.length)
                for (var i = 0; i < message.questionList.length; ++i)
                    $root.query_profiling.ProfilingTagPair.encode(message.questionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.answerPreferredList != null && message.answerPreferredList.length)
                for (var i = 0; i < message.answerPreferredList.length; ++i)
                    $root.query_profiling.ProfilingTagPair.encode(message.answerPreferredList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfilingTagPreference message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ProfilingTagPreference
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ProfilingTagPreference} ProfilingTagPreference
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilingTagPreference.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ProfilingTagPreference();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.questionList && message.questionList.length))
                        message.questionList = [];
                    message.questionList.push($root.query_profiling.ProfilingTagPair.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.answerPreferredList && message.answerPreferredList.length))
                        message.answerPreferredList = [];
                    message.answerPreferredList.push($root.query_profiling.ProfilingTagPair.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a ProfilingTagPreference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ProfilingTagPreference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ProfilingTagPreference} ProfilingTagPreference
         */
        ProfilingTagPreference.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ProfilingTagPreference)
                return object;
            var message = new $root.query_recursive_types.ProfilingTagPreference();
            if (object.questionList) {
                if (!Array.isArray(object.questionList))
                    throw TypeError(".query_recursive_types.ProfilingTagPreference.questionList: array expected");
                message.questionList = [];
                for (var i = 0; i < object.questionList.length; ++i) {
                    if (typeof object.questionList[i] !== "object")
                        throw TypeError(".query_recursive_types.ProfilingTagPreference.questionList: object expected");
                    message.questionList[i] = $root.query_profiling.ProfilingTagPair.fromObject(object.questionList[i]);
                }
            }
            if (object.answerPreferredList) {
                if (!Array.isArray(object.answerPreferredList))
                    throw TypeError(".query_recursive_types.ProfilingTagPreference.answerPreferredList: array expected");
                message.answerPreferredList = [];
                for (var i = 0; i < object.answerPreferredList.length; ++i) {
                    if (typeof object.answerPreferredList[i] !== "object")
                        throw TypeError(".query_recursive_types.ProfilingTagPreference.answerPreferredList: object expected");
                    message.answerPreferredList[i] = $root.query_profiling.ProfilingTagPair.fromObject(object.answerPreferredList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilingTagPreference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ProfilingTagPreference
         * @static
         * @param {query_recursive_types.ProfilingTagPreference} message ProfilingTagPreference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilingTagPreference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.questionList = [];
                object.answerPreferredList = [];
            }
            if (message.questionList && message.questionList.length) {
                object.questionList = [];
                for (var j = 0; j < message.questionList.length; ++j)
                    object.questionList[j] = $root.query_profiling.ProfilingTagPair.toObject(message.questionList[j], options);
            }
            if (message.answerPreferredList && message.answerPreferredList.length) {
                object.answerPreferredList = [];
                for (var j = 0; j < message.answerPreferredList.length; ++j)
                    object.answerPreferredList[j] = $root.query_profiling.ProfilingTagPair.toObject(message.answerPreferredList[j], options);
            }
            return object;
        };

        /**
         * Converts this ProfilingTagPreference to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ProfilingTagPreference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilingTagPreference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProfilingTagPreference
         * @function getTypeUrl
         * @memberof query_recursive_types.ProfilingTagPreference
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfilingTagPreference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ProfilingTagPreference";
        };

        return ProfilingTagPreference;
    })();

    query_recursive_types.StoryReviewCommentRequireField = (function() {

        /**
         * Properties of a StoryReviewCommentRequireField.
         * @memberof query_recursive_types
         * @interface IStoryReviewCommentRequireField
         * @property {google.protobuf.IBoolValue|null} [requireTextBody] StoryReviewCommentRequireField requireTextBody
         */

        /**
         * Constructs a new StoryReviewCommentRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryReviewCommentRequireField.
         * @implements IStoryReviewCommentRequireField
         * @constructor
         * @param {query_recursive_types.IStoryReviewCommentRequireField=} [properties] Properties to set
         */
        function StoryReviewCommentRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReviewCommentRequireField requireTextBody.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTextBody
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @instance
         */
        StoryReviewCommentRequireField.prototype.requireTextBody = null;

        /**
         * Creates a new StoryReviewCommentRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @static
         * @param {query_recursive_types.IStoryReviewCommentRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.StoryReviewCommentRequireField} StoryReviewCommentRequireField instance
         */
        StoryReviewCommentRequireField.create = function create(properties) {
            return new StoryReviewCommentRequireField(properties);
        };

        /**
         * Encodes the specified StoryReviewCommentRequireField message. Does not implicitly {@link query_recursive_types.StoryReviewCommentRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @static
         * @param {query_recursive_types.IStoryReviewCommentRequireField} message StoryReviewCommentRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReviewCommentRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireTextBody != null && Object.hasOwnProperty.call(message, "requireTextBody"))
                $root.google.protobuf.BoolValue.encode(message.requireTextBody, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReviewCommentRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryReviewCommentRequireField} StoryReviewCommentRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReviewCommentRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryReviewCommentRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireTextBody = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReviewCommentRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryReviewCommentRequireField} StoryReviewCommentRequireField
         */
        StoryReviewCommentRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryReviewCommentRequireField)
                return object;
            var message = new $root.query_recursive_types.StoryReviewCommentRequireField();
            if (object.requireTextBody != null) {
                if (typeof object.requireTextBody !== "object")
                    throw TypeError(".query_recursive_types.StoryReviewCommentRequireField.requireTextBody: object expected");
                message.requireTextBody = $root.google.protobuf.BoolValue.fromObject(object.requireTextBody);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReviewCommentRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @static
         * @param {query_recursive_types.StoryReviewCommentRequireField} message StoryReviewCommentRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReviewCommentRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requireTextBody = null;
            if (message.requireTextBody != null && message.hasOwnProperty("requireTextBody"))
                object.requireTextBody = $root.google.protobuf.BoolValue.toObject(message.requireTextBody, options);
            return object;
        };

        /**
         * Converts this StoryReviewCommentRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReviewCommentRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReviewCommentRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryReviewCommentRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReviewCommentRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryReviewCommentRequireField";
        };

        return StoryReviewCommentRequireField;
    })();

    query_recursive_types.StoryReviewCommentResponse = (function() {

        /**
         * Properties of a StoryReviewCommentResponse.
         * @memberof query_recursive_types
         * @interface IStoryReviewCommentResponse
         * @property {google.protobuf.IStringValue|null} [textBody] StoryReviewCommentResponse textBody
         */

        /**
         * Constructs a new StoryReviewCommentResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a StoryReviewCommentResponse.
         * @implements IStoryReviewCommentResponse
         * @constructor
         * @param {query_recursive_types.IStoryReviewCommentResponse=} [properties] Properties to set
         */
        function StoryReviewCommentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryReviewCommentResponse textBody.
         * @member {google.protobuf.IStringValue|null|undefined} textBody
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @instance
         */
        StoryReviewCommentResponse.prototype.textBody = null;

        /**
         * Creates a new StoryReviewCommentResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @static
         * @param {query_recursive_types.IStoryReviewCommentResponse=} [properties] Properties to set
         * @returns {query_recursive_types.StoryReviewCommentResponse} StoryReviewCommentResponse instance
         */
        StoryReviewCommentResponse.create = function create(properties) {
            return new StoryReviewCommentResponse(properties);
        };

        /**
         * Encodes the specified StoryReviewCommentResponse message. Does not implicitly {@link query_recursive_types.StoryReviewCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @static
         * @param {query_recursive_types.IStoryReviewCommentResponse} message StoryReviewCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryReviewCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.textBody != null && Object.hasOwnProperty.call(message, "textBody"))
                $root.google.protobuf.StringValue.encode(message.textBody, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryReviewCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.StoryReviewCommentResponse} StoryReviewCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryReviewCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.StoryReviewCommentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.textBody = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryReviewCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.StoryReviewCommentResponse} StoryReviewCommentResponse
         */
        StoryReviewCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.StoryReviewCommentResponse)
                return object;
            var message = new $root.query_recursive_types.StoryReviewCommentResponse();
            if (object.textBody != null) {
                if (typeof object.textBody !== "object")
                    throw TypeError(".query_recursive_types.StoryReviewCommentResponse.textBody: object expected");
                message.textBody = $root.google.protobuf.StringValue.fromObject(object.textBody);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryReviewCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @static
         * @param {query_recursive_types.StoryReviewCommentResponse} message StoryReviewCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryReviewCommentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.textBody = null;
            if (message.textBody != null && message.hasOwnProperty("textBody"))
                object.textBody = $root.google.protobuf.StringValue.toObject(message.textBody, options);
            return object;
        };

        /**
         * Converts this StoryReviewCommentResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryReviewCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryReviewCommentResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.StoryReviewCommentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryReviewCommentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.StoryReviewCommentResponse";
        };

        return StoryReviewCommentResponse;
    })();

    query_recursive_types.EventListRequest = (function() {

        /**
         * Properties of an EventListRequest.
         * @memberof query_recursive_types
         * @interface IEventListRequest
         * @property {query_recursive_types.IEventRequireField|null} [requireEventList] EventListRequest requireEventList
         * @property {google.protobuf.IBoolValue|null} [includeInactive] EventListRequest includeInactive
         * @property {Array.<event.EventLabel>|null} [filterAnyEventLabel] EventListRequest filterAnyEventLabel
         */

        /**
         * Constructs a new EventListRequest.
         * @memberof query_recursive_types
         * @classdesc Represents an EventListRequest.
         * @implements IEventListRequest
         * @constructor
         * @param {query_recursive_types.IEventListRequest=} [properties] Properties to set
         */
        function EventListRequest(properties) {
            this.filterAnyEventLabel = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventListRequest requireEventList.
         * @member {query_recursive_types.IEventRequireField|null|undefined} requireEventList
         * @memberof query_recursive_types.EventListRequest
         * @instance
         */
        EventListRequest.prototype.requireEventList = null;

        /**
         * EventListRequest includeInactive.
         * @member {google.protobuf.IBoolValue|null|undefined} includeInactive
         * @memberof query_recursive_types.EventListRequest
         * @instance
         */
        EventListRequest.prototype.includeInactive = null;

        /**
         * EventListRequest filterAnyEventLabel.
         * @member {Array.<event.EventLabel>} filterAnyEventLabel
         * @memberof query_recursive_types.EventListRequest
         * @instance
         */
        EventListRequest.prototype.filterAnyEventLabel = $util.emptyArray;

        /**
         * Creates a new EventListRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.EventListRequest
         * @static
         * @param {query_recursive_types.IEventListRequest=} [properties] Properties to set
         * @returns {query_recursive_types.EventListRequest} EventListRequest instance
         */
        EventListRequest.create = function create(properties) {
            return new EventListRequest(properties);
        };

        /**
         * Encodes the specified EventListRequest message. Does not implicitly {@link query_recursive_types.EventListRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.EventListRequest
         * @static
         * @param {query_recursive_types.IEventListRequest} message EventListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireEventList != null && Object.hasOwnProperty.call(message, "requireEventList"))
                $root.query_recursive_types.EventRequireField.encode(message.requireEventList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.includeInactive != null && Object.hasOwnProperty.call(message, "includeInactive"))
                $root.google.protobuf.BoolValue.encode(message.includeInactive, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.filterAnyEventLabel != null && message.filterAnyEventLabel.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.filterAnyEventLabel.length; ++i)
                    writer.int32(message.filterAnyEventLabel[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes an EventListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.EventListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.EventListRequest} EventListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.EventListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireEventList = $root.query_recursive_types.EventRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.includeInactive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.filterAnyEventLabel && message.filterAnyEventLabel.length))
                        message.filterAnyEventLabel = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyEventLabel.push(reader.int32());
                    } else
                        message.filterAnyEventLabel.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an EventListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.EventListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.EventListRequest} EventListRequest
         */
        EventListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.EventListRequest)
                return object;
            var message = new $root.query_recursive_types.EventListRequest();
            if (object.requireEventList != null) {
                if (typeof object.requireEventList !== "object")
                    throw TypeError(".query_recursive_types.EventListRequest.requireEventList: object expected");
                message.requireEventList = $root.query_recursive_types.EventRequireField.fromObject(object.requireEventList);
            }
            if (object.includeInactive != null) {
                if (typeof object.includeInactive !== "object")
                    throw TypeError(".query_recursive_types.EventListRequest.includeInactive: object expected");
                message.includeInactive = $root.google.protobuf.BoolValue.fromObject(object.includeInactive);
            }
            if (object.filterAnyEventLabel) {
                if (!Array.isArray(object.filterAnyEventLabel))
                    throw TypeError(".query_recursive_types.EventListRequest.filterAnyEventLabel: array expected");
                message.filterAnyEventLabel = [];
                for (var i = 0; i < object.filterAnyEventLabel.length; ++i)
                    switch (object.filterAnyEventLabel[i]) {
                    default:
                    case "EVENT_LABEL_UNSPECIFIED":
                    case 0:
                        message.filterAnyEventLabel[i] = 0;
                        break;
                    case "EVENT_LABEL_THEME":
                    case 1:
                        message.filterAnyEventLabel[i] = 1;
                        break;
                    case "EVENT_LABEL_CONTEST":
                    case 2:
                        message.filterAnyEventLabel[i] = 2;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from an EventListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.EventListRequest
         * @static
         * @param {query_recursive_types.EventListRequest} message EventListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.filterAnyEventLabel = [];
            if (options.defaults) {
                object.requireEventList = null;
                object.includeInactive = null;
            }
            if (message.requireEventList != null && message.hasOwnProperty("requireEventList"))
                object.requireEventList = $root.query_recursive_types.EventRequireField.toObject(message.requireEventList, options);
            if (message.includeInactive != null && message.hasOwnProperty("includeInactive"))
                object.includeInactive = $root.google.protobuf.BoolValue.toObject(message.includeInactive, options);
            if (message.filterAnyEventLabel && message.filterAnyEventLabel.length) {
                object.filterAnyEventLabel = [];
                for (var j = 0; j < message.filterAnyEventLabel.length; ++j)
                    object.filterAnyEventLabel[j] = options.enums === String ? $root.event.EventLabel[message.filterAnyEventLabel[j]] : message.filterAnyEventLabel[j];
            }
            return object;
        };

        /**
         * Converts this EventListRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.EventListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventListRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.EventListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.EventListRequest";
        };

        return EventListRequest;
    })();

    query_recursive_types.EventListResponse = (function() {

        /**
         * Properties of an EventListResponse.
         * @memberof query_recursive_types
         * @interface IEventListResponse
         * @property {Array.<query_recursive_types.IEventResponse>|null} [eventList] EventListResponse eventList
         */

        /**
         * Constructs a new EventListResponse.
         * @memberof query_recursive_types
         * @classdesc Represents an EventListResponse.
         * @implements IEventListResponse
         * @constructor
         * @param {query_recursive_types.IEventListResponse=} [properties] Properties to set
         */
        function EventListResponse(properties) {
            this.eventList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventListResponse eventList.
         * @member {Array.<query_recursive_types.IEventResponse>} eventList
         * @memberof query_recursive_types.EventListResponse
         * @instance
         */
        EventListResponse.prototype.eventList = $util.emptyArray;

        /**
         * Creates a new EventListResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.EventListResponse
         * @static
         * @param {query_recursive_types.IEventListResponse=} [properties] Properties to set
         * @returns {query_recursive_types.EventListResponse} EventListResponse instance
         */
        EventListResponse.create = function create(properties) {
            return new EventListResponse(properties);
        };

        /**
         * Encodes the specified EventListResponse message. Does not implicitly {@link query_recursive_types.EventListResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.EventListResponse
         * @static
         * @param {query_recursive_types.IEventListResponse} message EventListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eventList != null && message.eventList.length)
                for (var i = 0; i < message.eventList.length; ++i)
                    $root.query_recursive_types.EventResponse.encode(message.eventList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an EventListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.EventListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.EventListResponse} EventListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.EventListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.eventList && message.eventList.length))
                        message.eventList = [];
                    message.eventList.push($root.query_recursive_types.EventResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an EventListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.EventListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.EventListResponse} EventListResponse
         */
        EventListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.EventListResponse)
                return object;
            var message = new $root.query_recursive_types.EventListResponse();
            if (object.eventList) {
                if (!Array.isArray(object.eventList))
                    throw TypeError(".query_recursive_types.EventListResponse.eventList: array expected");
                message.eventList = [];
                for (var i = 0; i < object.eventList.length; ++i) {
                    if (typeof object.eventList[i] !== "object")
                        throw TypeError(".query_recursive_types.EventListResponse.eventList: object expected");
                    message.eventList[i] = $root.query_recursive_types.EventResponse.fromObject(object.eventList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an EventListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.EventListResponse
         * @static
         * @param {query_recursive_types.EventListResponse} message EventListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.eventList = [];
            if (message.eventList && message.eventList.length) {
                object.eventList = [];
                for (var j = 0; j < message.eventList.length; ++j)
                    object.eventList[j] = $root.query_recursive_types.EventResponse.toObject(message.eventList[j], options);
            }
            return object;
        };

        /**
         * Converts this EventListResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.EventListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventListResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.EventListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.EventListResponse";
        };

        return EventListResponse;
    })();

    query_recursive_types.EventRequireField = (function() {

        /**
         * Properties of an EventRequireField.
         * @memberof query_recursive_types
         * @interface IEventRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] EventRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireTag] EventRequireField requireTag
         * @property {google.protobuf.IBoolValue|null} [requireDescription] EventRequireField requireDescription
         * @property {google.protobuf.IBoolValue|null} [requireActionUrl] EventRequireField requireActionUrl
         * @property {query_recursive_types.IImageRequireField|null} [requireCoverImage] EventRequireField requireCoverImage
         * @property {google.protobuf.IBoolValue|null} [requireSince] EventRequireField requireSince
         * @property {google.protobuf.IBoolValue|null} [requireUntil] EventRequireField requireUntil
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] EventRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] EventRequireField requireUpdatedAt
         * @property {google.protobuf.IBoolValue|null} [requireLabelList] EventRequireField requireLabelList
         */

        /**
         * Constructs a new EventRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents an EventRequireField.
         * @implements IEventRequireField
         * @constructor
         * @param {query_recursive_types.IEventRequireField=} [properties] Properties to set
         */
        function EventRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireId = null;

        /**
         * EventRequireField requireTag.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTag
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireTag = null;

        /**
         * EventRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireDescription = null;

        /**
         * EventRequireField requireActionUrl.
         * @member {google.protobuf.IBoolValue|null|undefined} requireActionUrl
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireActionUrl = null;

        /**
         * EventRequireField requireCoverImage.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireCoverImage
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireCoverImage = null;

        /**
         * EventRequireField requireSince.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSince
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireSince = null;

        /**
         * EventRequireField requireUntil.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUntil
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireUntil = null;

        /**
         * EventRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireCreatedAt = null;

        /**
         * EventRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireUpdatedAt = null;

        /**
         * EventRequireField requireLabelList.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLabelList
         * @memberof query_recursive_types.EventRequireField
         * @instance
         */
        EventRequireField.prototype.requireLabelList = null;

        /**
         * Creates a new EventRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.EventRequireField
         * @static
         * @param {query_recursive_types.IEventRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.EventRequireField} EventRequireField instance
         */
        EventRequireField.create = function create(properties) {
            return new EventRequireField(properties);
        };

        /**
         * Encodes the specified EventRequireField message. Does not implicitly {@link query_recursive_types.EventRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.EventRequireField
         * @static
         * @param {query_recursive_types.IEventRequireField} message EventRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTag != null && Object.hasOwnProperty.call(message, "requireTag"))
                $root.google.protobuf.BoolValue.encode(message.requireTag, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireActionUrl != null && Object.hasOwnProperty.call(message, "requireActionUrl"))
                $root.google.protobuf.BoolValue.encode(message.requireActionUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireCoverImage != null && Object.hasOwnProperty.call(message, "requireCoverImage"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireCoverImage, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireSince != null && Object.hasOwnProperty.call(message, "requireSince"))
                $root.google.protobuf.BoolValue.encode(message.requireSince, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireUntil != null && Object.hasOwnProperty.call(message, "requireUntil"))
                $root.google.protobuf.BoolValue.encode(message.requireUntil, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.requireLabelList != null && Object.hasOwnProperty.call(message, "requireLabelList"))
                $root.google.protobuf.BoolValue.encode(message.requireLabelList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an EventRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.EventRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.EventRequireField} EventRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.EventRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTag = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireActionUrl = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireCoverImage = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireSince = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireUntil = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.requireLabelList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an EventRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.EventRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.EventRequireField} EventRequireField
         */
        EventRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.EventRequireField)
                return object;
            var message = new $root.query_recursive_types.EventRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireTag != null) {
                if (typeof object.requireTag !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireTag: object expected");
                message.requireTag = $root.google.protobuf.BoolValue.fromObject(object.requireTag);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireActionUrl != null) {
                if (typeof object.requireActionUrl !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireActionUrl: object expected");
                message.requireActionUrl = $root.google.protobuf.BoolValue.fromObject(object.requireActionUrl);
            }
            if (object.requireCoverImage != null) {
                if (typeof object.requireCoverImage !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireCoverImage: object expected");
                message.requireCoverImage = $root.query_recursive_types.ImageRequireField.fromObject(object.requireCoverImage);
            }
            if (object.requireSince != null) {
                if (typeof object.requireSince !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireSince: object expected");
                message.requireSince = $root.google.protobuf.BoolValue.fromObject(object.requireSince);
            }
            if (object.requireUntil != null) {
                if (typeof object.requireUntil !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireUntil: object expected");
                message.requireUntil = $root.google.protobuf.BoolValue.fromObject(object.requireUntil);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requireLabelList != null) {
                if (typeof object.requireLabelList !== "object")
                    throw TypeError(".query_recursive_types.EventRequireField.requireLabelList: object expected");
                message.requireLabelList = $root.google.protobuf.BoolValue.fromObject(object.requireLabelList);
            }
            return message;
        };

        /**
         * Creates a plain object from an EventRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.EventRequireField
         * @static
         * @param {query_recursive_types.EventRequireField} message EventRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireTag = null;
                object.requireDescription = null;
                object.requireActionUrl = null;
                object.requireCoverImage = null;
                object.requireSince = null;
                object.requireUntil = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
                object.requireLabelList = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireTag != null && message.hasOwnProperty("requireTag"))
                object.requireTag = $root.google.protobuf.BoolValue.toObject(message.requireTag, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireActionUrl != null && message.hasOwnProperty("requireActionUrl"))
                object.requireActionUrl = $root.google.protobuf.BoolValue.toObject(message.requireActionUrl, options);
            if (message.requireCoverImage != null && message.hasOwnProperty("requireCoverImage"))
                object.requireCoverImage = $root.query_recursive_types.ImageRequireField.toObject(message.requireCoverImage, options);
            if (message.requireSince != null && message.hasOwnProperty("requireSince"))
                object.requireSince = $root.google.protobuf.BoolValue.toObject(message.requireSince, options);
            if (message.requireUntil != null && message.hasOwnProperty("requireUntil"))
                object.requireUntil = $root.google.protobuf.BoolValue.toObject(message.requireUntil, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            if (message.requireLabelList != null && message.hasOwnProperty("requireLabelList"))
                object.requireLabelList = $root.google.protobuf.BoolValue.toObject(message.requireLabelList, options);
            return object;
        };

        /**
         * Converts this EventRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.EventRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.EventRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.EventRequireField";
        };

        return EventRequireField;
    })();

    query_recursive_types.EventResponse = (function() {

        /**
         * Properties of an EventResponse.
         * @memberof query_recursive_types
         * @interface IEventResponse
         * @property {google.protobuf.IStringValue|null} [id] EventResponse id
         * @property {google.protobuf.IStringValue|null} [tag] EventResponse tag
         * @property {google.protobuf.IStringValue|null} [description] EventResponse description
         * @property {google.protobuf.IStringValue|null} [actionUrl] EventResponse actionUrl
         * @property {query_recursive_types.IImageResponse|null} [coverImage] EventResponse coverImage
         * @property {google.protobuf.ITimestamp|null} [since] EventResponse since
         * @property {google.protobuf.ITimestamp|null} [until] EventResponse until
         * @property {google.protobuf.ITimestamp|null} [createdAt] EventResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] EventResponse updatedAt
         * @property {Array.<event.EventLabel>|null} [labelList] EventResponse labelList
         */

        /**
         * Constructs a new EventResponse.
         * @memberof query_recursive_types
         * @classdesc Represents an EventResponse.
         * @implements IEventResponse
         * @constructor
         * @param {query_recursive_types.IEventResponse=} [properties] Properties to set
         */
        function EventResponse(properties) {
            this.labelList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.id = null;

        /**
         * EventResponse tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.tag = null;

        /**
         * EventResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.description = null;

        /**
         * EventResponse actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.actionUrl = null;

        /**
         * EventResponse coverImage.
         * @member {query_recursive_types.IImageResponse|null|undefined} coverImage
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.coverImage = null;

        /**
         * EventResponse since.
         * @member {google.protobuf.ITimestamp|null|undefined} since
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.since = null;

        /**
         * EventResponse until.
         * @member {google.protobuf.ITimestamp|null|undefined} until
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.until = null;

        /**
         * EventResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.createdAt = null;

        /**
         * EventResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.updatedAt = null;

        /**
         * EventResponse labelList.
         * @member {Array.<event.EventLabel>} labelList
         * @memberof query_recursive_types.EventResponse
         * @instance
         */
        EventResponse.prototype.labelList = $util.emptyArray;

        /**
         * Creates a new EventResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.EventResponse
         * @static
         * @param {query_recursive_types.IEventResponse=} [properties] Properties to set
         * @returns {query_recursive_types.EventResponse} EventResponse instance
         */
        EventResponse.create = function create(properties) {
            return new EventResponse(properties);
        };

        /**
         * Encodes the specified EventResponse message. Does not implicitly {@link query_recursive_types.EventResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.EventResponse
         * @static
         * @param {query_recursive_types.IEventResponse} message EventResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.coverImage != null && Object.hasOwnProperty.call(message, "coverImage"))
                $root.query_recursive_types.ImageResponse.encode(message.coverImage, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.since != null && Object.hasOwnProperty.call(message, "since"))
                $root.google.protobuf.Timestamp.encode(message.since, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.until != null && Object.hasOwnProperty.call(message, "until"))
                $root.google.protobuf.Timestamp.encode(message.until, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.labelList != null && message.labelList.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.labelList.length; ++i)
                    writer.int32(message.labelList[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes an EventResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.EventResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.EventResponse} EventResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.EventResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.coverImage = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.since = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.until = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    if (!(message.labelList && message.labelList.length))
                        message.labelList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.labelList.push(reader.int32());
                    } else
                        message.labelList.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an EventResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.EventResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.EventResponse} EventResponse
         */
        EventResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.EventResponse)
                return object;
            var message = new $root.query_recursive_types.EventResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.coverImage != null) {
                if (typeof object.coverImage !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.coverImage: object expected");
                message.coverImage = $root.query_recursive_types.ImageResponse.fromObject(object.coverImage);
            }
            if (object.since != null) {
                if (typeof object.since !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.since: object expected");
                message.since = $root.google.protobuf.Timestamp.fromObject(object.since);
            }
            if (object.until != null) {
                if (typeof object.until !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.until: object expected");
                message.until = $root.google.protobuf.Timestamp.fromObject(object.until);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.EventResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.labelList) {
                if (!Array.isArray(object.labelList))
                    throw TypeError(".query_recursive_types.EventResponse.labelList: array expected");
                message.labelList = [];
                for (var i = 0; i < object.labelList.length; ++i)
                    switch (object.labelList[i]) {
                    default:
                    case "EVENT_LABEL_UNSPECIFIED":
                    case 0:
                        message.labelList[i] = 0;
                        break;
                    case "EVENT_LABEL_THEME":
                    case 1:
                        message.labelList[i] = 1;
                        break;
                    case "EVENT_LABEL_CONTEST":
                    case 2:
                        message.labelList[i] = 2;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from an EventResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.EventResponse
         * @static
         * @param {query_recursive_types.EventResponse} message EventResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.labelList = [];
            if (options.defaults) {
                object.id = null;
                object.tag = null;
                object.description = null;
                object.actionUrl = null;
                object.coverImage = null;
                object.since = null;
                object.until = null;
                object.createdAt = null;
                object.updatedAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.coverImage != null && message.hasOwnProperty("coverImage"))
                object.coverImage = $root.query_recursive_types.ImageResponse.toObject(message.coverImage, options);
            if (message.since != null && message.hasOwnProperty("since"))
                object.since = $root.google.protobuf.Timestamp.toObject(message.since, options);
            if (message.until != null && message.hasOwnProperty("until"))
                object.until = $root.google.protobuf.Timestamp.toObject(message.until, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.labelList && message.labelList.length) {
                object.labelList = [];
                for (var j = 0; j < message.labelList.length; ++j)
                    object.labelList[j] = options.enums === String ? $root.event.EventLabel[message.labelList[j]] : message.labelList[j];
            }
            return object;
        };

        /**
         * Converts this EventResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.EventResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.EventResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.EventResponse";
        };

        return EventResponse;
    })();

    query_recursive_types.TagMedalCursorRequest = (function() {

        /**
         * Properties of a TagMedalCursorRequest.
         * @memberof query_recursive_types
         * @interface ITagMedalCursorRequest
         * @property {google.protobuf.IStringValue|null} [tag] TagMedalCursorRequest tag
         * @property {query_recursive_types.IMedalCursorRequest|null} [requireMedalPage] TagMedalCursorRequest requireMedalPage
         */

        /**
         * Constructs a new TagMedalCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a TagMedalCursorRequest.
         * @implements ITagMedalCursorRequest
         * @constructor
         * @param {query_recursive_types.ITagMedalCursorRequest=} [properties] Properties to set
         */
        function TagMedalCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagMedalCursorRequest tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @instance
         */
        TagMedalCursorRequest.prototype.tag = null;

        /**
         * TagMedalCursorRequest requireMedalPage.
         * @member {query_recursive_types.IMedalCursorRequest|null|undefined} requireMedalPage
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @instance
         */
        TagMedalCursorRequest.prototype.requireMedalPage = null;

        /**
         * Creates a new TagMedalCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @static
         * @param {query_recursive_types.ITagMedalCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.TagMedalCursorRequest} TagMedalCursorRequest instance
         */
        TagMedalCursorRequest.create = function create(properties) {
            return new TagMedalCursorRequest(properties);
        };

        /**
         * Encodes the specified TagMedalCursorRequest message. Does not implicitly {@link query_recursive_types.TagMedalCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @static
         * @param {query_recursive_types.ITagMedalCursorRequest} message TagMedalCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagMedalCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireMedalPage != null && Object.hasOwnProperty.call(message, "requireMedalPage"))
                $root.query_recursive_types.MedalCursorRequest.encode(message.requireMedalPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagMedalCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.TagMedalCursorRequest} TagMedalCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagMedalCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.TagMedalCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireMedalPage = $root.query_recursive_types.MedalCursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagMedalCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.TagMedalCursorRequest} TagMedalCursorRequest
         */
        TagMedalCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.TagMedalCursorRequest)
                return object;
            var message = new $root.query_recursive_types.TagMedalCursorRequest();
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_recursive_types.TagMedalCursorRequest.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            if (object.requireMedalPage != null) {
                if (typeof object.requireMedalPage !== "object")
                    throw TypeError(".query_recursive_types.TagMedalCursorRequest.requireMedalPage: object expected");
                message.requireMedalPage = $root.query_recursive_types.MedalCursorRequest.fromObject(object.requireMedalPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a TagMedalCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @static
         * @param {query_recursive_types.TagMedalCursorRequest} message TagMedalCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagMedalCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.tag = null;
                object.requireMedalPage = null;
            }
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.requireMedalPage != null && message.hasOwnProperty("requireMedalPage"))
                object.requireMedalPage = $root.query_recursive_types.MedalCursorRequest.toObject(message.requireMedalPage, options);
            return object;
        };

        /**
         * Converts this TagMedalCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagMedalCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagMedalCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.TagMedalCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagMedalCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.TagMedalCursorRequest";
        };

        return TagMedalCursorRequest;
    })();

    query_recursive_types.TagMedalCursorResponse = (function() {

        /**
         * Properties of a TagMedalCursorResponse.
         * @memberof query_recursive_types
         * @interface ITagMedalCursorResponse
         * @property {query_recursive_types.IMedalCursorResponse|null} [medalPage] TagMedalCursorResponse medalPage
         */

        /**
         * Constructs a new TagMedalCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a TagMedalCursorResponse.
         * @implements ITagMedalCursorResponse
         * @constructor
         * @param {query_recursive_types.ITagMedalCursorResponse=} [properties] Properties to set
         */
        function TagMedalCursorResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagMedalCursorResponse medalPage.
         * @member {query_recursive_types.IMedalCursorResponse|null|undefined} medalPage
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @instance
         */
        TagMedalCursorResponse.prototype.medalPage = null;

        /**
         * Creates a new TagMedalCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @static
         * @param {query_recursive_types.ITagMedalCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.TagMedalCursorResponse} TagMedalCursorResponse instance
         */
        TagMedalCursorResponse.create = function create(properties) {
            return new TagMedalCursorResponse(properties);
        };

        /**
         * Encodes the specified TagMedalCursorResponse message. Does not implicitly {@link query_recursive_types.TagMedalCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @static
         * @param {query_recursive_types.ITagMedalCursorResponse} message TagMedalCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagMedalCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.medalPage != null && Object.hasOwnProperty.call(message, "medalPage"))
                $root.query_recursive_types.MedalCursorResponse.encode(message.medalPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagMedalCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.TagMedalCursorResponse} TagMedalCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagMedalCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.TagMedalCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.medalPage = $root.query_recursive_types.MedalCursorResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagMedalCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.TagMedalCursorResponse} TagMedalCursorResponse
         */
        TagMedalCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.TagMedalCursorResponse)
                return object;
            var message = new $root.query_recursive_types.TagMedalCursorResponse();
            if (object.medalPage != null) {
                if (typeof object.medalPage !== "object")
                    throw TypeError(".query_recursive_types.TagMedalCursorResponse.medalPage: object expected");
                message.medalPage = $root.query_recursive_types.MedalCursorResponse.fromObject(object.medalPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a TagMedalCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @static
         * @param {query_recursive_types.TagMedalCursorResponse} message TagMedalCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagMedalCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.medalPage = null;
            if (message.medalPage != null && message.hasOwnProperty("medalPage"))
                object.medalPage = $root.query_recursive_types.MedalCursorResponse.toObject(message.medalPage, options);
            return object;
        };

        /**
         * Converts this TagMedalCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagMedalCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagMedalCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.TagMedalCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagMedalCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.TagMedalCursorResponse";
        };

        return TagMedalCursorResponse;
    })();

    query_recursive_types.MedalCursorRequest = (function() {

        /**
         * Properties of a MedalCursorRequest.
         * @memberof query_recursive_types
         * @interface IMedalCursorRequest
         * @property {query_recursive_types.IMedalRequireField|null} [requireMedalList] MedalCursorRequest requireMedalList
         * @property {query_pagination.ICursorRequest|null} [cursor] MedalCursorRequest cursor
         */

        /**
         * Constructs a new MedalCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a MedalCursorRequest.
         * @implements IMedalCursorRequest
         * @constructor
         * @param {query_recursive_types.IMedalCursorRequest=} [properties] Properties to set
         */
        function MedalCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MedalCursorRequest requireMedalList.
         * @member {query_recursive_types.IMedalRequireField|null|undefined} requireMedalList
         * @memberof query_recursive_types.MedalCursorRequest
         * @instance
         */
        MedalCursorRequest.prototype.requireMedalList = null;

        /**
         * MedalCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.MedalCursorRequest
         * @instance
         */
        MedalCursorRequest.prototype.cursor = null;

        /**
         * Creates a new MedalCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MedalCursorRequest
         * @static
         * @param {query_recursive_types.IMedalCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.MedalCursorRequest} MedalCursorRequest instance
         */
        MedalCursorRequest.create = function create(properties) {
            return new MedalCursorRequest(properties);
        };

        /**
         * Encodes the specified MedalCursorRequest message. Does not implicitly {@link query_recursive_types.MedalCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MedalCursorRequest
         * @static
         * @param {query_recursive_types.IMedalCursorRequest} message MedalCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MedalCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireMedalList != null && Object.hasOwnProperty.call(message, "requireMedalList"))
                $root.query_recursive_types.MedalRequireField.encode(message.requireMedalList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MedalCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MedalCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MedalCursorRequest} MedalCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MedalCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MedalCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireMedalList = $root.query_recursive_types.MedalRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MedalCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MedalCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MedalCursorRequest} MedalCursorRequest
         */
        MedalCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MedalCursorRequest)
                return object;
            var message = new $root.query_recursive_types.MedalCursorRequest();
            if (object.requireMedalList != null) {
                if (typeof object.requireMedalList !== "object")
                    throw TypeError(".query_recursive_types.MedalCursorRequest.requireMedalList: object expected");
                message.requireMedalList = $root.query_recursive_types.MedalRequireField.fromObject(object.requireMedalList);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.MedalCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MedalCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MedalCursorRequest
         * @static
         * @param {query_recursive_types.MedalCursorRequest} message MedalCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MedalCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireMedalList = null;
                object.cursor = null;
            }
            if (message.requireMedalList != null && message.hasOwnProperty("requireMedalList"))
                object.requireMedalList = $root.query_recursive_types.MedalRequireField.toObject(message.requireMedalList, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MedalCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MedalCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MedalCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MedalCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.MedalCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MedalCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MedalCursorRequest";
        };

        return MedalCursorRequest;
    })();

    query_recursive_types.MedalCursorResponse = (function() {

        /**
         * Properties of a MedalCursorResponse.
         * @memberof query_recursive_types
         * @interface IMedalCursorResponse
         * @property {Array.<query_recursive_types.IMedalResponse>|null} [medalList] MedalCursorResponse medalList
         * @property {query_pagination.ICursorInfo|null} [cursor] MedalCursorResponse cursor
         */

        /**
         * Constructs a new MedalCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a MedalCursorResponse.
         * @implements IMedalCursorResponse
         * @constructor
         * @param {query_recursive_types.IMedalCursorResponse=} [properties] Properties to set
         */
        function MedalCursorResponse(properties) {
            this.medalList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MedalCursorResponse medalList.
         * @member {Array.<query_recursive_types.IMedalResponse>} medalList
         * @memberof query_recursive_types.MedalCursorResponse
         * @instance
         */
        MedalCursorResponse.prototype.medalList = $util.emptyArray;

        /**
         * MedalCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.MedalCursorResponse
         * @instance
         */
        MedalCursorResponse.prototype.cursor = null;

        /**
         * Creates a new MedalCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MedalCursorResponse
         * @static
         * @param {query_recursive_types.IMedalCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.MedalCursorResponse} MedalCursorResponse instance
         */
        MedalCursorResponse.create = function create(properties) {
            return new MedalCursorResponse(properties);
        };

        /**
         * Encodes the specified MedalCursorResponse message. Does not implicitly {@link query_recursive_types.MedalCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MedalCursorResponse
         * @static
         * @param {query_recursive_types.IMedalCursorResponse} message MedalCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MedalCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.medalList != null && message.medalList.length)
                for (var i = 0; i < message.medalList.length; ++i)
                    $root.query_recursive_types.MedalResponse.encode(message.medalList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MedalCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MedalCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MedalCursorResponse} MedalCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MedalCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MedalCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.medalList && message.medalList.length))
                        message.medalList = [];
                    message.medalList.push($root.query_recursive_types.MedalResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MedalCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MedalCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MedalCursorResponse} MedalCursorResponse
         */
        MedalCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MedalCursorResponse)
                return object;
            var message = new $root.query_recursive_types.MedalCursorResponse();
            if (object.medalList) {
                if (!Array.isArray(object.medalList))
                    throw TypeError(".query_recursive_types.MedalCursorResponse.medalList: array expected");
                message.medalList = [];
                for (var i = 0; i < object.medalList.length; ++i) {
                    if (typeof object.medalList[i] !== "object")
                        throw TypeError(".query_recursive_types.MedalCursorResponse.medalList: object expected");
                    message.medalList[i] = $root.query_recursive_types.MedalResponse.fromObject(object.medalList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.MedalCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MedalCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MedalCursorResponse
         * @static
         * @param {query_recursive_types.MedalCursorResponse} message MedalCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MedalCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.medalList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.medalList && message.medalList.length) {
                object.medalList = [];
                for (var j = 0; j < message.medalList.length; ++j)
                    object.medalList[j] = $root.query_recursive_types.MedalResponse.toObject(message.medalList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MedalCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MedalCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MedalCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MedalCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.MedalCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MedalCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MedalCursorResponse";
        };

        return MedalCursorResponse;
    })();

    query_recursive_types.MedalRequireField = (function() {

        /**
         * Properties of a MedalRequireField.
         * @memberof query_recursive_types
         * @interface IMedalRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] MedalRequireField requireId
         * @property {query_recursive_types.IUserRequireField|null} [requireUser] MedalRequireField requireUser
         * @property {google.protobuf.IBoolValue|null} [requireGrade] MedalRequireField requireGrade
         * @property {google.protobuf.IBoolValue|null} [requireTag] MedalRequireField requireTag
         * @property {query_recursive_types.IStoryRequireField|null} [requireStory] MedalRequireField requireStory
         * @property {google.protobuf.IBoolValue|null} [requireIconUrl] MedalRequireField requireIconUrl
         * @property {google.protobuf.IBoolValue|null} [requireGainedAt] MedalRequireField requireGainedAt
         * @property {google.protobuf.IBoolValue|null} [requireName] MedalRequireField requireName
         */

        /**
         * Constructs a new MedalRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a MedalRequireField.
         * @implements IMedalRequireField
         * @constructor
         * @param {query_recursive_types.IMedalRequireField=} [properties] Properties to set
         */
        function MedalRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MedalRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireId = null;

        /**
         * MedalRequireField requireUser.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireUser
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireUser = null;

        /**
         * MedalRequireField requireGrade.
         * @member {google.protobuf.IBoolValue|null|undefined} requireGrade
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireGrade = null;

        /**
         * MedalRequireField requireTag.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTag
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireTag = null;

        /**
         * MedalRequireField requireStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStory
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireStory = null;

        /**
         * MedalRequireField requireIconUrl.
         * @member {google.protobuf.IBoolValue|null|undefined} requireIconUrl
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireIconUrl = null;

        /**
         * MedalRequireField requireGainedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireGainedAt
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireGainedAt = null;

        /**
         * MedalRequireField requireName.
         * @member {google.protobuf.IBoolValue|null|undefined} requireName
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         */
        MedalRequireField.prototype.requireName = null;

        /**
         * Creates a new MedalRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MedalRequireField
         * @static
         * @param {query_recursive_types.IMedalRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.MedalRequireField} MedalRequireField instance
         */
        MedalRequireField.create = function create(properties) {
            return new MedalRequireField(properties);
        };

        /**
         * Encodes the specified MedalRequireField message. Does not implicitly {@link query_recursive_types.MedalRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MedalRequireField
         * @static
         * @param {query_recursive_types.IMedalRequireField} message MedalRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MedalRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUser != null && Object.hasOwnProperty.call(message, "requireUser"))
                $root.query_recursive_types.UserRequireField.encode(message.requireUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireGrade != null && Object.hasOwnProperty.call(message, "requireGrade"))
                $root.google.protobuf.BoolValue.encode(message.requireGrade, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireTag != null && Object.hasOwnProperty.call(message, "requireTag"))
                $root.google.protobuf.BoolValue.encode(message.requireTag, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireStory != null && Object.hasOwnProperty.call(message, "requireStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStory, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireIconUrl != null && Object.hasOwnProperty.call(message, "requireIconUrl"))
                $root.google.protobuf.BoolValue.encode(message.requireIconUrl, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireGainedAt != null && Object.hasOwnProperty.call(message, "requireGainedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireGainedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireName != null && Object.hasOwnProperty.call(message, "requireName"))
                $root.google.protobuf.BoolValue.encode(message.requireName, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MedalRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MedalRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MedalRequireField} MedalRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MedalRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MedalRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUser = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireGrade = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireTag = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireIconUrl = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireGainedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireName = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MedalRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MedalRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MedalRequireField} MedalRequireField
         */
        MedalRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MedalRequireField)
                return object;
            var message = new $root.query_recursive_types.MedalRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireUser != null) {
                if (typeof object.requireUser !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireUser: object expected");
                message.requireUser = $root.query_recursive_types.UserRequireField.fromObject(object.requireUser);
            }
            if (object.requireGrade != null) {
                if (typeof object.requireGrade !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireGrade: object expected");
                message.requireGrade = $root.google.protobuf.BoolValue.fromObject(object.requireGrade);
            }
            if (object.requireTag != null) {
                if (typeof object.requireTag !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireTag: object expected");
                message.requireTag = $root.google.protobuf.BoolValue.fromObject(object.requireTag);
            }
            if (object.requireStory != null) {
                if (typeof object.requireStory !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireStory: object expected");
                message.requireStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStory);
            }
            if (object.requireIconUrl != null) {
                if (typeof object.requireIconUrl !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireIconUrl: object expected");
                message.requireIconUrl = $root.google.protobuf.BoolValue.fromObject(object.requireIconUrl);
            }
            if (object.requireGainedAt != null) {
                if (typeof object.requireGainedAt !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireGainedAt: object expected");
                message.requireGainedAt = $root.google.protobuf.BoolValue.fromObject(object.requireGainedAt);
            }
            if (object.requireName != null) {
                if (typeof object.requireName !== "object")
                    throw TypeError(".query_recursive_types.MedalRequireField.requireName: object expected");
                message.requireName = $root.google.protobuf.BoolValue.fromObject(object.requireName);
            }
            return message;
        };

        /**
         * Creates a plain object from a MedalRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MedalRequireField
         * @static
         * @param {query_recursive_types.MedalRequireField} message MedalRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MedalRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireUser = null;
                object.requireGrade = null;
                object.requireTag = null;
                object.requireStory = null;
                object.requireIconUrl = null;
                object.requireGainedAt = null;
                object.requireName = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireUser != null && message.hasOwnProperty("requireUser"))
                object.requireUser = $root.query_recursive_types.UserRequireField.toObject(message.requireUser, options);
            if (message.requireGrade != null && message.hasOwnProperty("requireGrade"))
                object.requireGrade = $root.google.protobuf.BoolValue.toObject(message.requireGrade, options);
            if (message.requireTag != null && message.hasOwnProperty("requireTag"))
                object.requireTag = $root.google.protobuf.BoolValue.toObject(message.requireTag, options);
            if (message.requireStory != null && message.hasOwnProperty("requireStory"))
                object.requireStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireStory, options);
            if (message.requireIconUrl != null && message.hasOwnProperty("requireIconUrl"))
                object.requireIconUrl = $root.google.protobuf.BoolValue.toObject(message.requireIconUrl, options);
            if (message.requireGainedAt != null && message.hasOwnProperty("requireGainedAt"))
                object.requireGainedAt = $root.google.protobuf.BoolValue.toObject(message.requireGainedAt, options);
            if (message.requireName != null && message.hasOwnProperty("requireName"))
                object.requireName = $root.google.protobuf.BoolValue.toObject(message.requireName, options);
            return object;
        };

        /**
         * Converts this MedalRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MedalRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MedalRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MedalRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.MedalRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MedalRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MedalRequireField";
        };

        return MedalRequireField;
    })();

    query_recursive_types.MedalResponse = (function() {

        /**
         * Properties of a MedalResponse.
         * @memberof query_recursive_types
         * @interface IMedalResponse
         * @property {google.protobuf.IStringValue|null} [id] MedalResponse id
         * @property {query_recursive_types.IUserResponse|null} [user] MedalResponse user
         * @property {query_recursive_types.MedalResponse.Grade|null} [grade] MedalResponse grade
         * @property {google.protobuf.IStringValue|null} [tag] MedalResponse tag
         * @property {query_recursive_types.IStoryResponse|null} [story] MedalResponse story
         * @property {google.protobuf.IStringValue|null} [iconUrl] MedalResponse iconUrl
         * @property {google.protobuf.ITimestamp|null} [gainedAt] MedalResponse gainedAt
         * @property {google.protobuf.IStringValue|null} [name] MedalResponse name
         */

        /**
         * Constructs a new MedalResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a MedalResponse.
         * @implements IMedalResponse
         * @constructor
         * @param {query_recursive_types.IMedalResponse=} [properties] Properties to set
         */
        function MedalResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MedalResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.id = null;

        /**
         * MedalResponse user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.user = null;

        /**
         * MedalResponse grade.
         * @member {query_recursive_types.MedalResponse.Grade} grade
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.grade = 0;

        /**
         * MedalResponse tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.tag = null;

        /**
         * MedalResponse story.
         * @member {query_recursive_types.IStoryResponse|null|undefined} story
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.story = null;

        /**
         * MedalResponse iconUrl.
         * @member {google.protobuf.IStringValue|null|undefined} iconUrl
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.iconUrl = null;

        /**
         * MedalResponse gainedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} gainedAt
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.gainedAt = null;

        /**
         * MedalResponse name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof query_recursive_types.MedalResponse
         * @instance
         */
        MedalResponse.prototype.name = null;

        /**
         * Creates a new MedalResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MedalResponse
         * @static
         * @param {query_recursive_types.IMedalResponse=} [properties] Properties to set
         * @returns {query_recursive_types.MedalResponse} MedalResponse instance
         */
        MedalResponse.create = function create(properties) {
            return new MedalResponse(properties);
        };

        /**
         * Encodes the specified MedalResponse message. Does not implicitly {@link query_recursive_types.MedalResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MedalResponse
         * @static
         * @param {query_recursive_types.IMedalResponse} message MedalResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MedalResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.grade);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.query_recursive_types.StoryResponse.encode(message.story, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                $root.google.protobuf.StringValue.encode(message.iconUrl, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.gainedAt != null && Object.hasOwnProperty.call(message, "gainedAt"))
                $root.google.protobuf.Timestamp.encode(message.gainedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                $root.google.protobuf.StringValue.encode(message.name, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MedalResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MedalResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MedalResponse} MedalResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MedalResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MedalResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.grade = reader.int32();
                    break;
                case 4:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.story = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.iconUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.gainedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.name = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MedalResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MedalResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MedalResponse} MedalResponse
         */
        MedalResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MedalResponse)
                return object;
            var message = new $root.query_recursive_types.MedalResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.MedalResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query_recursive_types.MedalResponse.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            switch (object.grade) {
            case "GRADE_UNSPECIFIED":
            case 0:
                message.grade = 0;
                break;
            case "GRADE_BRONZE":
            case 1:
                message.grade = 1;
                break;
            case "GRADE_SILVER":
            case 2:
                message.grade = 2;
                break;
            case "GRADE_GOLD":
            case 3:
                message.grade = 3;
                break;
            }
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_recursive_types.MedalResponse.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".query_recursive_types.MedalResponse.story: object expected");
                message.story = $root.query_recursive_types.StoryResponse.fromObject(object.story);
            }
            if (object.iconUrl != null) {
                if (typeof object.iconUrl !== "object")
                    throw TypeError(".query_recursive_types.MedalResponse.iconUrl: object expected");
                message.iconUrl = $root.google.protobuf.StringValue.fromObject(object.iconUrl);
            }
            if (object.gainedAt != null) {
                if (typeof object.gainedAt !== "object")
                    throw TypeError(".query_recursive_types.MedalResponse.gainedAt: object expected");
                message.gainedAt = $root.google.protobuf.Timestamp.fromObject(object.gainedAt);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".query_recursive_types.MedalResponse.name: object expected");
                message.name = $root.google.protobuf.StringValue.fromObject(object.name);
            }
            return message;
        };

        /**
         * Creates a plain object from a MedalResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MedalResponse
         * @static
         * @param {query_recursive_types.MedalResponse} message MedalResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MedalResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.user = null;
                object.grade = options.enums === String ? "GRADE_UNSPECIFIED" : 0;
                object.tag = null;
                object.story = null;
                object.iconUrl = null;
                object.gainedAt = null;
                object.name = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
            if (message.grade != null && message.hasOwnProperty("grade"))
                object.grade = options.enums === String ? $root.query_recursive_types.MedalResponse.Grade[message.grade] : message.grade;
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.story != null && message.hasOwnProperty("story"))
                object.story = $root.query_recursive_types.StoryResponse.toObject(message.story, options);
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = $root.google.protobuf.StringValue.toObject(message.iconUrl, options);
            if (message.gainedAt != null && message.hasOwnProperty("gainedAt"))
                object.gainedAt = $root.google.protobuf.Timestamp.toObject(message.gainedAt, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.google.protobuf.StringValue.toObject(message.name, options);
            return object;
        };

        /**
         * Converts this MedalResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MedalResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MedalResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MedalResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.MedalResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MedalResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MedalResponse";
        };

        /**
         * Grade enum.
         * @name query_recursive_types.MedalResponse.Grade
         * @enum {number}
         * @property {number} GRADE_UNSPECIFIED=0 GRADE_UNSPECIFIED value
         * @property {number} GRADE_BRONZE=1 GRADE_BRONZE value
         * @property {number} GRADE_SILVER=2 GRADE_SILVER value
         * @property {number} GRADE_GOLD=3 GRADE_GOLD value
         */
        MedalResponse.Grade = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GRADE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "GRADE_BRONZE"] = 1;
            values[valuesById[2] = "GRADE_SILVER"] = 2;
            values[valuesById[3] = "GRADE_GOLD"] = 3;
            return values;
        })();

        return MedalResponse;
    })();

    query_recursive_types.ImageCursorRequest = (function() {

        /**
         * Properties of an ImageCursorRequest.
         * @memberof query_recursive_types
         * @interface IImageCursorRequest
         * @property {query_recursive_types.IImageRequireField|null} [requireImageList] ImageCursorRequest requireImageList
         * @property {resource.ImageCategory|null} [category] ImageCursorRequest category
         * @property {query_pagination.ICursorRequest|null} [cursor] ImageCursorRequest cursor
         * @property {google.protobuf.IBoolValue|null} [includeUnavailable] ImageCursorRequest includeUnavailable
         */

        /**
         * Constructs a new ImageCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents an ImageCursorRequest.
         * @implements IImageCursorRequest
         * @constructor
         * @param {query_recursive_types.IImageCursorRequest=} [properties] Properties to set
         */
        function ImageCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageCursorRequest requireImageList.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireImageList
         * @memberof query_recursive_types.ImageCursorRequest
         * @instance
         */
        ImageCursorRequest.prototype.requireImageList = null;

        /**
         * ImageCursorRequest category.
         * @member {resource.ImageCategory} category
         * @memberof query_recursive_types.ImageCursorRequest
         * @instance
         */
        ImageCursorRequest.prototype.category = 0;

        /**
         * ImageCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.ImageCursorRequest
         * @instance
         */
        ImageCursorRequest.prototype.cursor = null;

        /**
         * ImageCursorRequest includeUnavailable.
         * @member {google.protobuf.IBoolValue|null|undefined} includeUnavailable
         * @memberof query_recursive_types.ImageCursorRequest
         * @instance
         */
        ImageCursorRequest.prototype.includeUnavailable = null;

        /**
         * Creates a new ImageCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ImageCursorRequest
         * @static
         * @param {query_recursive_types.IImageCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.ImageCursorRequest} ImageCursorRequest instance
         */
        ImageCursorRequest.create = function create(properties) {
            return new ImageCursorRequest(properties);
        };

        /**
         * Encodes the specified ImageCursorRequest message. Does not implicitly {@link query_recursive_types.ImageCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ImageCursorRequest
         * @static
         * @param {query_recursive_types.IImageCursorRequest} message ImageCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireImageList != null && Object.hasOwnProperty.call(message, "requireImageList"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireImageList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.category);
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.includeUnavailable != null && Object.hasOwnProperty.call(message, "includeUnavailable"))
                $root.google.protobuf.BoolValue.encode(message.includeUnavailable, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ImageCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ImageCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ImageCursorRequest} ImageCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ImageCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireImageList = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.category = reader.int32();
                    break;
                case 3:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.includeUnavailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an ImageCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ImageCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ImageCursorRequest} ImageCursorRequest
         */
        ImageCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ImageCursorRequest)
                return object;
            var message = new $root.query_recursive_types.ImageCursorRequest();
            if (object.requireImageList != null) {
                if (typeof object.requireImageList !== "object")
                    throw TypeError(".query_recursive_types.ImageCursorRequest.requireImageList: object expected");
                message.requireImageList = $root.query_recursive_types.ImageRequireField.fromObject(object.requireImageList);
            }
            switch (object.category) {
            case "IMAGE_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "IMAGE_CATEGORY_USER_PROFILE":
            case 1:
                message.category = 1;
                break;
            case "IMAGE_CATEGORY_CHARACTER_ICON":
            case 2:
                message.category = 2;
                break;
            case "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON":
            case 3:
                message.category = 3;
                break;
            case "IMAGE_CATEGORY_CHAT_BACKGROUND":
            case 4:
                message.category = 4;
                break;
            case "IMAGE_CATEGORY_TALK_BACKGROUND":
            case 5:
                message.category = 5;
                break;
            case "IMAGE_CATEGORY_CONTENT":
            case 6:
                message.category = 6;
                break;
            case "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL":
            case 7:
                message.category = 7;
                break;
            case "IMAGE_CATEGORY_STAMP":
            case 8:
                message.category = 8;
                break;
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.ImageCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            if (object.includeUnavailable != null) {
                if (typeof object.includeUnavailable !== "object")
                    throw TypeError(".query_recursive_types.ImageCursorRequest.includeUnavailable: object expected");
                message.includeUnavailable = $root.google.protobuf.BoolValue.fromObject(object.includeUnavailable);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ImageCursorRequest
         * @static
         * @param {query_recursive_types.ImageCursorRequest} message ImageCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireImageList = null;
                object.category = options.enums === String ? "IMAGE_CATEGORY_UNSPECIFIED" : 0;
                object.cursor = null;
                object.includeUnavailable = null;
            }
            if (message.requireImageList != null && message.hasOwnProperty("requireImageList"))
                object.requireImageList = $root.query_recursive_types.ImageRequireField.toObject(message.requireImageList, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.resource.ImageCategory[message.category] : message.category;
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            if (message.includeUnavailable != null && message.hasOwnProperty("includeUnavailable"))
                object.includeUnavailable = $root.google.protobuf.BoolValue.toObject(message.includeUnavailable, options);
            return object;
        };

        /**
         * Converts this ImageCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ImageCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImageCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.ImageCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImageCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ImageCursorRequest";
        };

        return ImageCursorRequest;
    })();

    query_recursive_types.ImageCursorResponse = (function() {

        /**
         * Properties of an ImageCursorResponse.
         * @memberof query_recursive_types
         * @interface IImageCursorResponse
         * @property {Array.<query_recursive_types.IImageResponse>|null} [imageList] ImageCursorResponse imageList
         * @property {query_pagination.ICursorInfo|null} [cursor] ImageCursorResponse cursor
         */

        /**
         * Constructs a new ImageCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents an ImageCursorResponse.
         * @implements IImageCursorResponse
         * @constructor
         * @param {query_recursive_types.IImageCursorResponse=} [properties] Properties to set
         */
        function ImageCursorResponse(properties) {
            this.imageList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageCursorResponse imageList.
         * @member {Array.<query_recursive_types.IImageResponse>} imageList
         * @memberof query_recursive_types.ImageCursorResponse
         * @instance
         */
        ImageCursorResponse.prototype.imageList = $util.emptyArray;

        /**
         * ImageCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.ImageCursorResponse
         * @instance
         */
        ImageCursorResponse.prototype.cursor = null;

        /**
         * Creates a new ImageCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.ImageCursorResponse
         * @static
         * @param {query_recursive_types.IImageCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.ImageCursorResponse} ImageCursorResponse instance
         */
        ImageCursorResponse.create = function create(properties) {
            return new ImageCursorResponse(properties);
        };

        /**
         * Encodes the specified ImageCursorResponse message. Does not implicitly {@link query_recursive_types.ImageCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.ImageCursorResponse
         * @static
         * @param {query_recursive_types.IImageCursorResponse} message ImageCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageList != null && message.imageList.length)
                for (var i = 0; i < message.imageList.length; ++i)
                    $root.query_recursive_types.ImageResponse.encode(message.imageList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ImageCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.ImageCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.ImageCursorResponse} ImageCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.ImageCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.imageList && message.imageList.length))
                        message.imageList = [];
                    message.imageList.push($root.query_recursive_types.ImageResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an ImageCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.ImageCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.ImageCursorResponse} ImageCursorResponse
         */
        ImageCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.ImageCursorResponse)
                return object;
            var message = new $root.query_recursive_types.ImageCursorResponse();
            if (object.imageList) {
                if (!Array.isArray(object.imageList))
                    throw TypeError(".query_recursive_types.ImageCursorResponse.imageList: array expected");
                message.imageList = [];
                for (var i = 0; i < object.imageList.length; ++i) {
                    if (typeof object.imageList[i] !== "object")
                        throw TypeError(".query_recursive_types.ImageCursorResponse.imageList: object expected");
                    message.imageList[i] = $root.query_recursive_types.ImageResponse.fromObject(object.imageList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.ImageCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.ImageCursorResponse
         * @static
         * @param {query_recursive_types.ImageCursorResponse} message ImageCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.imageList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.imageList && message.imageList.length) {
                object.imageList = [];
                for (var j = 0; j < message.imageList.length; ++j)
                    object.imageList[j] = $root.query_recursive_types.ImageResponse.toObject(message.imageList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this ImageCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.ImageCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImageCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.ImageCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImageCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.ImageCursorResponse";
        };

        return ImageCursorResponse;
    })();

    query_recursive_types.MyStoryCursorRequest = (function() {

        /**
         * Properties of a MyStoryCursorRequest.
         * @memberof query_recursive_types
         * @interface IMyStoryCursorRequest
         * @property {query_recursive_types.IStoryRequireField|null} [requireStoryList] MyStoryCursorRequest requireStoryList
         * @property {query_pagination.ICursorRequest|null} [cursor] MyStoryCursorRequest cursor
         * @property {google.protobuf.IBoolValue|null} [excludeContainingChatNovel] MyStoryCursorRequest excludeContainingChatNovel
         * @property {google.protobuf.IBoolValue|null} [excludeContainingNovel] MyStoryCursorRequest excludeContainingNovel
         * @property {google.protobuf.IStringValue|null} [seriesId] MyStoryCursorRequest seriesId
         */

        /**
         * Constructs a new MyStoryCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a MyStoryCursorRequest.
         * @implements IMyStoryCursorRequest
         * @constructor
         * @param {query_recursive_types.IMyStoryCursorRequest=} [properties] Properties to set
         */
        function MyStoryCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MyStoryCursorRequest requireStoryList.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStoryList
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @instance
         */
        MyStoryCursorRequest.prototype.requireStoryList = null;

        /**
         * MyStoryCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @instance
         */
        MyStoryCursorRequest.prototype.cursor = null;

        /**
         * MyStoryCursorRequest excludeContainingChatNovel.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeContainingChatNovel
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @instance
         */
        MyStoryCursorRequest.prototype.excludeContainingChatNovel = null;

        /**
         * MyStoryCursorRequest excludeContainingNovel.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeContainingNovel
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @instance
         */
        MyStoryCursorRequest.prototype.excludeContainingNovel = null;

        /**
         * MyStoryCursorRequest seriesId.
         * @member {google.protobuf.IStringValue|null|undefined} seriesId
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @instance
         */
        MyStoryCursorRequest.prototype.seriesId = null;

        /**
         * Creates a new MyStoryCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @static
         * @param {query_recursive_types.IMyStoryCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.MyStoryCursorRequest} MyStoryCursorRequest instance
         */
        MyStoryCursorRequest.create = function create(properties) {
            return new MyStoryCursorRequest(properties);
        };

        /**
         * Encodes the specified MyStoryCursorRequest message. Does not implicitly {@link query_recursive_types.MyStoryCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @static
         * @param {query_recursive_types.IMyStoryCursorRequest} message MyStoryCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MyStoryCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireStoryList != null && Object.hasOwnProperty.call(message, "requireStoryList"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStoryList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.excludeContainingChatNovel != null && Object.hasOwnProperty.call(message, "excludeContainingChatNovel"))
                $root.google.protobuf.BoolValue.encode(message.excludeContainingChatNovel, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.excludeContainingNovel != null && Object.hasOwnProperty.call(message, "excludeContainingNovel"))
                $root.google.protobuf.BoolValue.encode(message.excludeContainingNovel, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.seriesId != null && Object.hasOwnProperty.call(message, "seriesId"))
                $root.google.protobuf.StringValue.encode(message.seriesId, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MyStoryCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MyStoryCursorRequest} MyStoryCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MyStoryCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MyStoryCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireStoryList = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.excludeContainingChatNovel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.excludeContainingNovel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.seriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MyStoryCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MyStoryCursorRequest} MyStoryCursorRequest
         */
        MyStoryCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MyStoryCursorRequest)
                return object;
            var message = new $root.query_recursive_types.MyStoryCursorRequest();
            if (object.requireStoryList != null) {
                if (typeof object.requireStoryList !== "object")
                    throw TypeError(".query_recursive_types.MyStoryCursorRequest.requireStoryList: object expected");
                message.requireStoryList = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStoryList);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.MyStoryCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            if (object.excludeContainingChatNovel != null) {
                if (typeof object.excludeContainingChatNovel !== "object")
                    throw TypeError(".query_recursive_types.MyStoryCursorRequest.excludeContainingChatNovel: object expected");
                message.excludeContainingChatNovel = $root.google.protobuf.BoolValue.fromObject(object.excludeContainingChatNovel);
            }
            if (object.excludeContainingNovel != null) {
                if (typeof object.excludeContainingNovel !== "object")
                    throw TypeError(".query_recursive_types.MyStoryCursorRequest.excludeContainingNovel: object expected");
                message.excludeContainingNovel = $root.google.protobuf.BoolValue.fromObject(object.excludeContainingNovel);
            }
            if (object.seriesId != null) {
                if (typeof object.seriesId !== "object")
                    throw TypeError(".query_recursive_types.MyStoryCursorRequest.seriesId: object expected");
                message.seriesId = $root.google.protobuf.StringValue.fromObject(object.seriesId);
            }
            return message;
        };

        /**
         * Creates a plain object from a MyStoryCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @static
         * @param {query_recursive_types.MyStoryCursorRequest} message MyStoryCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MyStoryCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireStoryList = null;
                object.cursor = null;
                object.excludeContainingChatNovel = null;
                object.excludeContainingNovel = null;
                object.seriesId = null;
            }
            if (message.requireStoryList != null && message.hasOwnProperty("requireStoryList"))
                object.requireStoryList = $root.query_recursive_types.StoryRequireField.toObject(message.requireStoryList, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            if (message.excludeContainingChatNovel != null && message.hasOwnProperty("excludeContainingChatNovel"))
                object.excludeContainingChatNovel = $root.google.protobuf.BoolValue.toObject(message.excludeContainingChatNovel, options);
            if (message.excludeContainingNovel != null && message.hasOwnProperty("excludeContainingNovel"))
                object.excludeContainingNovel = $root.google.protobuf.BoolValue.toObject(message.excludeContainingNovel, options);
            if (message.seriesId != null && message.hasOwnProperty("seriesId"))
                object.seriesId = $root.google.protobuf.StringValue.toObject(message.seriesId, options);
            return object;
        };

        /**
         * Converts this MyStoryCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MyStoryCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MyStoryCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.MyStoryCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MyStoryCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MyStoryCursorRequest";
        };

        return MyStoryCursorRequest;
    })();

    query_recursive_types.MySeriesCursorRequest = (function() {

        /**
         * Properties of a MySeriesCursorRequest.
         * @memberof query_recursive_types
         * @interface IMySeriesCursorRequest
         * @property {query_recursive_types.ISeriesRequireField|null} [requireSeriesList] MySeriesCursorRequest requireSeriesList
         * @property {query_pagination.ICursorRequest|null} [cursor] MySeriesCursorRequest cursor
         * @property {google.protobuf.IBoolValue|null} [excludeContainingChatNovel] MySeriesCursorRequest excludeContainingChatNovel
         * @property {google.protobuf.IBoolValue|null} [excludeContainingNovel] MySeriesCursorRequest excludeContainingNovel
         */

        /**
         * Constructs a new MySeriesCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a MySeriesCursorRequest.
         * @implements IMySeriesCursorRequest
         * @constructor
         * @param {query_recursive_types.IMySeriesCursorRequest=} [properties] Properties to set
         */
        function MySeriesCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MySeriesCursorRequest requireSeriesList.
         * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeriesList
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @instance
         */
        MySeriesCursorRequest.prototype.requireSeriesList = null;

        /**
         * MySeriesCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @instance
         */
        MySeriesCursorRequest.prototype.cursor = null;

        /**
         * MySeriesCursorRequest excludeContainingChatNovel.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeContainingChatNovel
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @instance
         */
        MySeriesCursorRequest.prototype.excludeContainingChatNovel = null;

        /**
         * MySeriesCursorRequest excludeContainingNovel.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeContainingNovel
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @instance
         */
        MySeriesCursorRequest.prototype.excludeContainingNovel = null;

        /**
         * Creates a new MySeriesCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @static
         * @param {query_recursive_types.IMySeriesCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.MySeriesCursorRequest} MySeriesCursorRequest instance
         */
        MySeriesCursorRequest.create = function create(properties) {
            return new MySeriesCursorRequest(properties);
        };

        /**
         * Encodes the specified MySeriesCursorRequest message. Does not implicitly {@link query_recursive_types.MySeriesCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @static
         * @param {query_recursive_types.IMySeriesCursorRequest} message MySeriesCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MySeriesCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSeriesList != null && Object.hasOwnProperty.call(message, "requireSeriesList"))
                $root.query_recursive_types.SeriesRequireField.encode(message.requireSeriesList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.excludeContainingChatNovel != null && Object.hasOwnProperty.call(message, "excludeContainingChatNovel"))
                $root.google.protobuf.BoolValue.encode(message.excludeContainingChatNovel, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.excludeContainingNovel != null && Object.hasOwnProperty.call(message, "excludeContainingNovel"))
                $root.google.protobuf.BoolValue.encode(message.excludeContainingNovel, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MySeriesCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MySeriesCursorRequest} MySeriesCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MySeriesCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MySeriesCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSeriesList = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.excludeContainingChatNovel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.excludeContainingNovel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MySeriesCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MySeriesCursorRequest} MySeriesCursorRequest
         */
        MySeriesCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MySeriesCursorRequest)
                return object;
            var message = new $root.query_recursive_types.MySeriesCursorRequest();
            if (object.requireSeriesList != null) {
                if (typeof object.requireSeriesList !== "object")
                    throw TypeError(".query_recursive_types.MySeriesCursorRequest.requireSeriesList: object expected");
                message.requireSeriesList = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeriesList);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.MySeriesCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            if (object.excludeContainingChatNovel != null) {
                if (typeof object.excludeContainingChatNovel !== "object")
                    throw TypeError(".query_recursive_types.MySeriesCursorRequest.excludeContainingChatNovel: object expected");
                message.excludeContainingChatNovel = $root.google.protobuf.BoolValue.fromObject(object.excludeContainingChatNovel);
            }
            if (object.excludeContainingNovel != null) {
                if (typeof object.excludeContainingNovel !== "object")
                    throw TypeError(".query_recursive_types.MySeriesCursorRequest.excludeContainingNovel: object expected");
                message.excludeContainingNovel = $root.google.protobuf.BoolValue.fromObject(object.excludeContainingNovel);
            }
            return message;
        };

        /**
         * Creates a plain object from a MySeriesCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @static
         * @param {query_recursive_types.MySeriesCursorRequest} message MySeriesCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MySeriesCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireSeriesList = null;
                object.cursor = null;
                object.excludeContainingChatNovel = null;
                object.excludeContainingNovel = null;
            }
            if (message.requireSeriesList != null && message.hasOwnProperty("requireSeriesList"))
                object.requireSeriesList = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeriesList, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            if (message.excludeContainingChatNovel != null && message.hasOwnProperty("excludeContainingChatNovel"))
                object.excludeContainingChatNovel = $root.google.protobuf.BoolValue.toObject(message.excludeContainingChatNovel, options);
            if (message.excludeContainingNovel != null && message.hasOwnProperty("excludeContainingNovel"))
                object.excludeContainingNovel = $root.google.protobuf.BoolValue.toObject(message.excludeContainingNovel, options);
            return object;
        };

        /**
         * Converts this MySeriesCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MySeriesCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MySeriesCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.MySeriesCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MySeriesCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MySeriesCursorRequest";
        };

        return MySeriesCursorRequest;
    })();

    query_recursive_types.NotificationCursorRequest = (function() {

        /**
         * Properties of a NotificationCursorRequest.
         * @memberof query_recursive_types
         * @interface INotificationCursorRequest
         * @property {query_recursive_types.INotificationRequireField|null} [requireNotificationList] NotificationCursorRequest requireNotificationList
         * @property {types.OS|null} [os] NotificationCursorRequest os
         * @property {query_pagination.ICursorRequest|null} [cursor] NotificationCursorRequest cursor
         * @property {Array.<notification.NotificationCategory>|null} [categoryList] NotificationCursorRequest categoryList
         * @property {query_recursive_types.IUserRequireField|null} [requireRecommendedUserList] NotificationCursorRequest requireRecommendedUserList
         * @property {notification.NotificationCategory|null} [category] NotificationCursorRequest category
         */

        /**
         * Constructs a new NotificationCursorRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationCursorRequest.
         * @implements INotificationCursorRequest
         * @constructor
         * @param {query_recursive_types.INotificationCursorRequest=} [properties] Properties to set
         */
        function NotificationCursorRequest(properties) {
            this.categoryList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationCursorRequest requireNotificationList.
         * @member {query_recursive_types.INotificationRequireField|null|undefined} requireNotificationList
         * @memberof query_recursive_types.NotificationCursorRequest
         * @instance
         */
        NotificationCursorRequest.prototype.requireNotificationList = null;

        /**
         * NotificationCursorRequest os.
         * @member {types.OS} os
         * @memberof query_recursive_types.NotificationCursorRequest
         * @instance
         */
        NotificationCursorRequest.prototype.os = 0;

        /**
         * NotificationCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_recursive_types.NotificationCursorRequest
         * @instance
         */
        NotificationCursorRequest.prototype.cursor = null;

        /**
         * NotificationCursorRequest categoryList.
         * @member {Array.<notification.NotificationCategory>} categoryList
         * @memberof query_recursive_types.NotificationCursorRequest
         * @instance
         */
        NotificationCursorRequest.prototype.categoryList = $util.emptyArray;

        /**
         * NotificationCursorRequest requireRecommendedUserList.
         * @member {query_recursive_types.IUserRequireField|null|undefined} requireRecommendedUserList
         * @memberof query_recursive_types.NotificationCursorRequest
         * @instance
         */
        NotificationCursorRequest.prototype.requireRecommendedUserList = null;

        /**
         * NotificationCursorRequest category.
         * @member {notification.NotificationCategory} category
         * @memberof query_recursive_types.NotificationCursorRequest
         * @instance
         */
        NotificationCursorRequest.prototype.category = 0;

        /**
         * Creates a new NotificationCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationCursorRequest
         * @static
         * @param {query_recursive_types.INotificationCursorRequest=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationCursorRequest} NotificationCursorRequest instance
         */
        NotificationCursorRequest.create = function create(properties) {
            return new NotificationCursorRequest(properties);
        };

        /**
         * Encodes the specified NotificationCursorRequest message. Does not implicitly {@link query_recursive_types.NotificationCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationCursorRequest
         * @static
         * @param {query_recursive_types.INotificationCursorRequest} message NotificationCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireNotificationList != null && Object.hasOwnProperty.call(message, "requireNotificationList"))
                $root.query_recursive_types.NotificationRequireField.encode(message.requireNotificationList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.category);
            if (message.os != null && Object.hasOwnProperty.call(message, "os"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.os);
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.categoryList != null && message.categoryList.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.categoryList.length; ++i)
                    writer.int32(message.categoryList[i]);
                writer.ldelim();
            }
            if (message.requireRecommendedUserList != null && Object.hasOwnProperty.call(message, "requireRecommendedUserList"))
                $root.query_recursive_types.UserRequireField.encode(message.requireRecommendedUserList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationCursorRequest} NotificationCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireNotificationList = $root.query_recursive_types.NotificationRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.os = reader.int32();
                    break;
                case 4:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.categoryList && message.categoryList.length))
                        message.categoryList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.categoryList.push(reader.int32());
                    } else
                        message.categoryList.push(reader.int32());
                    break;
                case 6:
                    message.requireRecommendedUserList = $root.query_recursive_types.UserRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.category = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationCursorRequest} NotificationCursorRequest
         */
        NotificationCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationCursorRequest)
                return object;
            var message = new $root.query_recursive_types.NotificationCursorRequest();
            if (object.requireNotificationList != null) {
                if (typeof object.requireNotificationList !== "object")
                    throw TypeError(".query_recursive_types.NotificationCursorRequest.requireNotificationList: object expected");
                message.requireNotificationList = $root.query_recursive_types.NotificationRequireField.fromObject(object.requireNotificationList);
            }
            switch (object.os) {
            case "OS_UNSPECIFIED":
            case 0:
                message.os = 0;
                break;
            case "OS_IOS":
            case 1:
                message.os = 1;
                break;
            case "OS_ANDROID":
            case 2:
                message.os = 2;
                break;
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.NotificationCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            if (object.categoryList) {
                if (!Array.isArray(object.categoryList))
                    throw TypeError(".query_recursive_types.NotificationCursorRequest.categoryList: array expected");
                message.categoryList = [];
                for (var i = 0; i < object.categoryList.length; ++i)
                    switch (object.categoryList[i]) {
                    default:
                    case "NOTIFICATION_CATEGORY_UNSPECIFIED":
                    case 0:
                        message.categoryList[i] = 0;
                        break;
                    case "NOTIFICATION_CATEGORY_OPERATION":
                    case 1:
                        message.categoryList[i] = 1;
                        break;
                    case "NOTIFICATION_CATEGORY_USER_RELATED":
                    case 2:
                        message.categoryList[i] = 2;
                        break;
                    case "NOTIFICATION_CATEGORY_FOLLOWING_RELATED":
                    case 3:
                        message.categoryList[i] = 3;
                        break;
                    case "NOTIFICATION_CATEGORY_WAKEUP_DIALOG":
                    case 4:
                        message.categoryList[i] = 4;
                        break;
                    }
            }
            if (object.requireRecommendedUserList != null) {
                if (typeof object.requireRecommendedUserList !== "object")
                    throw TypeError(".query_recursive_types.NotificationCursorRequest.requireRecommendedUserList: object expected");
                message.requireRecommendedUserList = $root.query_recursive_types.UserRequireField.fromObject(object.requireRecommendedUserList);
            }
            switch (object.category) {
            case "NOTIFICATION_CATEGORY_UNSPECIFIED":
            case 0:
                message.category = 0;
                break;
            case "NOTIFICATION_CATEGORY_OPERATION":
            case 1:
                message.category = 1;
                break;
            case "NOTIFICATION_CATEGORY_USER_RELATED":
            case 2:
                message.category = 2;
                break;
            case "NOTIFICATION_CATEGORY_FOLLOWING_RELATED":
            case 3:
                message.category = 3;
                break;
            case "NOTIFICATION_CATEGORY_WAKEUP_DIALOG":
            case 4:
                message.category = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationCursorRequest
         * @static
         * @param {query_recursive_types.NotificationCursorRequest} message NotificationCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.categoryList = [];
            if (options.defaults) {
                object.requireNotificationList = null;
                object.category = options.enums === String ? "NOTIFICATION_CATEGORY_UNSPECIFIED" : 0;
                object.os = options.enums === String ? "OS_UNSPECIFIED" : 0;
                object.cursor = null;
                object.requireRecommendedUserList = null;
            }
            if (message.requireNotificationList != null && message.hasOwnProperty("requireNotificationList"))
                object.requireNotificationList = $root.query_recursive_types.NotificationRequireField.toObject(message.requireNotificationList, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.notification.NotificationCategory[message.category] : message.category;
            if (message.os != null && message.hasOwnProperty("os"))
                object.os = options.enums === String ? $root.types.OS[message.os] : message.os;
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            if (message.categoryList && message.categoryList.length) {
                object.categoryList = [];
                for (var j = 0; j < message.categoryList.length; ++j)
                    object.categoryList[j] = options.enums === String ? $root.notification.NotificationCategory[message.categoryList[j]] : message.categoryList[j];
            }
            if (message.requireRecommendedUserList != null && message.hasOwnProperty("requireRecommendedUserList"))
                object.requireRecommendedUserList = $root.query_recursive_types.UserRequireField.toObject(message.requireRecommendedUserList, options);
            return object;
        };

        /**
         * Converts this NotificationCursorRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationCursorRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationCursorRequest";
        };

        return NotificationCursorRequest;
    })();

    query_recursive_types.NotificationCursorResponse = (function() {

        /**
         * Properties of a NotificationCursorResponse.
         * @memberof query_recursive_types
         * @interface INotificationCursorResponse
         * @property {Array.<query_recursive_types.INotificationResponse>|null} [notificationList] NotificationCursorResponse notificationList
         * @property {query_pagination.ICursorInfo|null} [cursor] NotificationCursorResponse cursor
         * @property {Array.<query_recursive_types.IUserResponse>|null} [recommendedUserList] NotificationCursorResponse recommendedUserList
         */

        /**
         * Constructs a new NotificationCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationCursorResponse.
         * @implements INotificationCursorResponse
         * @constructor
         * @param {query_recursive_types.INotificationCursorResponse=} [properties] Properties to set
         */
        function NotificationCursorResponse(properties) {
            this.notificationList = [];
            this.recommendedUserList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationCursorResponse notificationList.
         * @member {Array.<query_recursive_types.INotificationResponse>} notificationList
         * @memberof query_recursive_types.NotificationCursorResponse
         * @instance
         */
        NotificationCursorResponse.prototype.notificationList = $util.emptyArray;

        /**
         * NotificationCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.NotificationCursorResponse
         * @instance
         */
        NotificationCursorResponse.prototype.cursor = null;

        /**
         * NotificationCursorResponse recommendedUserList.
         * @member {Array.<query_recursive_types.IUserResponse>} recommendedUserList
         * @memberof query_recursive_types.NotificationCursorResponse
         * @instance
         */
        NotificationCursorResponse.prototype.recommendedUserList = $util.emptyArray;

        /**
         * Creates a new NotificationCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationCursorResponse
         * @static
         * @param {query_recursive_types.INotificationCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationCursorResponse} NotificationCursorResponse instance
         */
        NotificationCursorResponse.create = function create(properties) {
            return new NotificationCursorResponse(properties);
        };

        /**
         * Encodes the specified NotificationCursorResponse message. Does not implicitly {@link query_recursive_types.NotificationCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationCursorResponse
         * @static
         * @param {query_recursive_types.INotificationCursorResponse} message NotificationCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.notificationList != null && message.notificationList.length)
                for (var i = 0; i < message.notificationList.length; ++i)
                    $root.query_recursive_types.NotificationResponse.encode(message.notificationList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.recommendedUserList != null && message.recommendedUserList.length)
                for (var i = 0; i < message.recommendedUserList.length; ++i)
                    $root.query_recursive_types.UserResponse.encode(message.recommendedUserList[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationCursorResponse} NotificationCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.notificationList && message.notificationList.length))
                        message.notificationList = [];
                    message.notificationList.push($root.query_recursive_types.NotificationResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.recommendedUserList && message.recommendedUserList.length))
                        message.recommendedUserList = [];
                    message.recommendedUserList.push($root.query_recursive_types.UserResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationCursorResponse} NotificationCursorResponse
         */
        NotificationCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationCursorResponse)
                return object;
            var message = new $root.query_recursive_types.NotificationCursorResponse();
            if (object.notificationList) {
                if (!Array.isArray(object.notificationList))
                    throw TypeError(".query_recursive_types.NotificationCursorResponse.notificationList: array expected");
                message.notificationList = [];
                for (var i = 0; i < object.notificationList.length; ++i) {
                    if (typeof object.notificationList[i] !== "object")
                        throw TypeError(".query_recursive_types.NotificationCursorResponse.notificationList: object expected");
                    message.notificationList[i] = $root.query_recursive_types.NotificationResponse.fromObject(object.notificationList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.NotificationCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            if (object.recommendedUserList) {
                if (!Array.isArray(object.recommendedUserList))
                    throw TypeError(".query_recursive_types.NotificationCursorResponse.recommendedUserList: array expected");
                message.recommendedUserList = [];
                for (var i = 0; i < object.recommendedUserList.length; ++i) {
                    if (typeof object.recommendedUserList[i] !== "object")
                        throw TypeError(".query_recursive_types.NotificationCursorResponse.recommendedUserList: object expected");
                    message.recommendedUserList[i] = $root.query_recursive_types.UserResponse.fromObject(object.recommendedUserList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationCursorResponse
         * @static
         * @param {query_recursive_types.NotificationCursorResponse} message NotificationCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.notificationList = [];
                object.recommendedUserList = [];
            }
            if (options.defaults)
                object.cursor = null;
            if (message.notificationList && message.notificationList.length) {
                object.notificationList = [];
                for (var j = 0; j < message.notificationList.length; ++j)
                    object.notificationList[j] = $root.query_recursive_types.NotificationResponse.toObject(message.notificationList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            if (message.recommendedUserList && message.recommendedUserList.length) {
                object.recommendedUserList = [];
                for (var j = 0; j < message.recommendedUserList.length; ++j)
                    object.recommendedUserList[j] = $root.query_recursive_types.UserResponse.toObject(message.recommendedUserList[j], options);
            }
            return object;
        };

        /**
         * Converts this NotificationCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationCursorResponse";
        };

        return NotificationCursorResponse;
    })();

    query_recursive_types.NotificationRequireField = (function() {

        /**
         * Properties of a NotificationRequireField.
         * @memberof query_recursive_types
         * @interface INotificationRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] NotificationRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireType] NotificationRequireField requireType
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] NotificationRequireField requireCreatedAt
         * @property {query_recursive_types.INotificationContentsV2RequireField|null} [requireContentsV2] NotificationRequireField requireContentsV2
         * @property {google.protobuf.IBoolValue|null} [requireContents] NotificationRequireField requireContents
         */

        /**
         * Constructs a new NotificationRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationRequireField.
         * @implements INotificationRequireField
         * @constructor
         * @param {query_recursive_types.INotificationRequireField=} [properties] Properties to set
         */
        function NotificationRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.NotificationRequireField
         * @instance
         */
        NotificationRequireField.prototype.requireId = null;

        /**
         * NotificationRequireField requireType.
         * @member {google.protobuf.IBoolValue|null|undefined} requireType
         * @memberof query_recursive_types.NotificationRequireField
         * @instance
         */
        NotificationRequireField.prototype.requireType = null;

        /**
         * NotificationRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.NotificationRequireField
         * @instance
         */
        NotificationRequireField.prototype.requireCreatedAt = null;

        /**
         * NotificationRequireField requireContentsV2.
         * @member {query_recursive_types.INotificationContentsV2RequireField|null|undefined} requireContentsV2
         * @memberof query_recursive_types.NotificationRequireField
         * @instance
         */
        NotificationRequireField.prototype.requireContentsV2 = null;

        /**
         * NotificationRequireField requireContents.
         * @member {google.protobuf.IBoolValue|null|undefined} requireContents
         * @memberof query_recursive_types.NotificationRequireField
         * @instance
         */
        NotificationRequireField.prototype.requireContents = null;

        /**
         * Creates a new NotificationRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationRequireField
         * @static
         * @param {query_recursive_types.INotificationRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationRequireField} NotificationRequireField instance
         */
        NotificationRequireField.create = function create(properties) {
            return new NotificationRequireField(properties);
        };

        /**
         * Encodes the specified NotificationRequireField message. Does not implicitly {@link query_recursive_types.NotificationRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationRequireField
         * @static
         * @param {query_recursive_types.INotificationRequireField} message NotificationRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireType != null && Object.hasOwnProperty.call(message, "requireType"))
                $root.google.protobuf.BoolValue.encode(message.requireType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireContents != null && Object.hasOwnProperty.call(message, "requireContents"))
                $root.google.protobuf.BoolValue.encode(message.requireContents, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireContentsV2 != null && Object.hasOwnProperty.call(message, "requireContentsV2"))
                $root.query_recursive_types.NotificationContentsV2RequireField.encode(message.requireContentsV2, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationRequireField} NotificationRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireType = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireContentsV2 = $root.query_recursive_types.NotificationContentsV2RequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireContents = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationRequireField} NotificationRequireField
         */
        NotificationRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationRequireField)
                return object;
            var message = new $root.query_recursive_types.NotificationRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.NotificationRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireType != null) {
                if (typeof object.requireType !== "object")
                    throw TypeError(".query_recursive_types.NotificationRequireField.requireType: object expected");
                message.requireType = $root.google.protobuf.BoolValue.fromObject(object.requireType);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.NotificationRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireContentsV2 != null) {
                if (typeof object.requireContentsV2 !== "object")
                    throw TypeError(".query_recursive_types.NotificationRequireField.requireContentsV2: object expected");
                message.requireContentsV2 = $root.query_recursive_types.NotificationContentsV2RequireField.fromObject(object.requireContentsV2);
            }
            if (object.requireContents != null) {
                if (typeof object.requireContents !== "object")
                    throw TypeError(".query_recursive_types.NotificationRequireField.requireContents: object expected");
                message.requireContents = $root.google.protobuf.BoolValue.fromObject(object.requireContents);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationRequireField
         * @static
         * @param {query_recursive_types.NotificationRequireField} message NotificationRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireType = null;
                object.requireContents = null;
                object.requireCreatedAt = null;
                object.requireContentsV2 = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireType != null && message.hasOwnProperty("requireType"))
                object.requireType = $root.google.protobuf.BoolValue.toObject(message.requireType, options);
            if (message.requireContents != null && message.hasOwnProperty("requireContents"))
                object.requireContents = $root.google.protobuf.BoolValue.toObject(message.requireContents, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireContentsV2 != null && message.hasOwnProperty("requireContentsV2"))
                object.requireContentsV2 = $root.query_recursive_types.NotificationContentsV2RequireField.toObject(message.requireContentsV2, options);
            return object;
        };

        /**
         * Converts this NotificationRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationRequireField";
        };

        return NotificationRequireField;
    })();

    query_recursive_types.NotificationContentsV2RequireField = (function() {

        /**
         * Properties of a NotificationContentsV2RequireField.
         * @memberof query_recursive_types
         * @interface INotificationContentsV2RequireField
         * @property {query_recursive_types.IStoryRequireField|null} [requireStory] NotificationContentsV2RequireField requireStory
         */

        /**
         * Constructs a new NotificationContentsV2RequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationContentsV2RequireField.
         * @implements INotificationContentsV2RequireField
         * @constructor
         * @param {query_recursive_types.INotificationContentsV2RequireField=} [properties] Properties to set
         */
        function NotificationContentsV2RequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsV2RequireField requireStory.
         * @member {query_recursive_types.IStoryRequireField|null|undefined} requireStory
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @instance
         */
        NotificationContentsV2RequireField.prototype.requireStory = null;

        /**
         * Creates a new NotificationContentsV2RequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @static
         * @param {query_recursive_types.INotificationContentsV2RequireField=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationContentsV2RequireField} NotificationContentsV2RequireField instance
         */
        NotificationContentsV2RequireField.create = function create(properties) {
            return new NotificationContentsV2RequireField(properties);
        };

        /**
         * Encodes the specified NotificationContentsV2RequireField message. Does not implicitly {@link query_recursive_types.NotificationContentsV2RequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @static
         * @param {query_recursive_types.INotificationContentsV2RequireField} message NotificationContentsV2RequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsV2RequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireStory != null && Object.hasOwnProperty.call(message, "requireStory"))
                $root.query_recursive_types.StoryRequireField.encode(message.requireStory, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsV2RequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationContentsV2RequireField} NotificationContentsV2RequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsV2RequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationContentsV2RequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireStory = $root.query_recursive_types.StoryRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsV2RequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationContentsV2RequireField} NotificationContentsV2RequireField
         */
        NotificationContentsV2RequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationContentsV2RequireField)
                return object;
            var message = new $root.query_recursive_types.NotificationContentsV2RequireField();
            if (object.requireStory != null) {
                if (typeof object.requireStory !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2RequireField.requireStory: object expected");
                message.requireStory = $root.query_recursive_types.StoryRequireField.fromObject(object.requireStory);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsV2RequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @static
         * @param {query_recursive_types.NotificationContentsV2RequireField} message NotificationContentsV2RequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsV2RequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requireStory = null;
            if (message.requireStory != null && message.hasOwnProperty("requireStory"))
                object.requireStory = $root.query_recursive_types.StoryRequireField.toObject(message.requireStory, options);
            return object;
        };

        /**
         * Converts this NotificationContentsV2RequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsV2RequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsV2RequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationContentsV2RequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsV2RequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationContentsV2RequireField";
        };

        return NotificationContentsV2RequireField;
    })();

    query_recursive_types.NotificationResponse = (function() {

        /**
         * Properties of a NotificationResponse.
         * @memberof query_recursive_types
         * @interface INotificationResponse
         * @property {google.protobuf.IStringValue|null} [id] NotificationResponse id
         * @property {notification.NotificationType|null} [type] NotificationResponse type
         * @property {google.protobuf.ITimestamp|null} [createdAt] NotificationResponse createdAt
         * @property {query_recursive_types.INotificationContentsV2|null} [contentsV2] NotificationResponse contentsV2
         * @property {notification.INotificationContents|null} [contents] NotificationResponse contents
         */

        /**
         * Constructs a new NotificationResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationResponse.
         * @implements INotificationResponse
         * @constructor
         * @param {query_recursive_types.INotificationResponse=} [properties] Properties to set
         */
        function NotificationResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.NotificationResponse
         * @instance
         */
        NotificationResponse.prototype.id = null;

        /**
         * NotificationResponse type.
         * @member {notification.NotificationType} type
         * @memberof query_recursive_types.NotificationResponse
         * @instance
         */
        NotificationResponse.prototype.type = 0;

        /**
         * NotificationResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.NotificationResponse
         * @instance
         */
        NotificationResponse.prototype.createdAt = null;

        /**
         * NotificationResponse contentsV2.
         * @member {query_recursive_types.INotificationContentsV2|null|undefined} contentsV2
         * @memberof query_recursive_types.NotificationResponse
         * @instance
         */
        NotificationResponse.prototype.contentsV2 = null;

        /**
         * NotificationResponse contents.
         * @member {notification.INotificationContents|null|undefined} contents
         * @memberof query_recursive_types.NotificationResponse
         * @instance
         */
        NotificationResponse.prototype.contents = null;

        /**
         * Creates a new NotificationResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationResponse
         * @static
         * @param {query_recursive_types.INotificationResponse=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationResponse} NotificationResponse instance
         */
        NotificationResponse.create = function create(properties) {
            return new NotificationResponse(properties);
        };

        /**
         * Encodes the specified NotificationResponse message. Does not implicitly {@link query_recursive_types.NotificationResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationResponse
         * @static
         * @param {query_recursive_types.INotificationResponse} message NotificationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.contents != null && Object.hasOwnProperty.call(message, "contents"))
                $root.notification.NotificationContents.encode(message.contents, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.contentsV2 != null && Object.hasOwnProperty.call(message, "contentsV2"))
                $root.query_recursive_types.NotificationContentsV2.encode(message.contentsV2, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationResponse} NotificationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.contentsV2 = $root.query_recursive_types.NotificationContentsV2.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.contents = $root.notification.NotificationContents.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationResponse} NotificationResponse
         */
        NotificationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationResponse)
                return object;
            var message = new $root.query_recursive_types.NotificationResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.NotificationResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            switch (object.type) {
            case "NOTIFICATION_TYPE_UNSPECIFIED":
            case 0:
                message.type = 0;
                break;
            case "NOTIFICATION_TYPE_OPERATION":
            case 1:
                message.type = 1;
                break;
            case "NOTIFICATION_TYPE_STORY_REJECTED":
            case 2:
                message.type = 2;
                break;
            case "NOTIFICATION_TYPE_USER_LIKE_BREAK":
            case 3:
                message.type = 3;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK":
            case 4:
                message.type = 4;
                break;
            case "NOTIFICATION_TYPE_USER_BANNED":
            case 5:
                message.type = 5;
                break;
            case "NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS":
            case 8:
                message.type = 8;
                break;
            case "NOTIFICATION_TYPE_NEW_FOLLOWER":
            case 10:
                message.type = 10;
                break;
            case "NOTIFICATION_TYPE_NEW_STORY_COMMENT":
            case 11:
                message.type = 11;
                break;
            case "NOTIFICATION_TYPE_NEW_COMMENT_LIKE":
            case 12:
                message.type = 12;
                break;
            case "NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE":
            case 13:
                message.type = 13;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY":
            case 15:
                message.type = 15;
                break;
            case "NOTIFICATION_TYPE_NEW_MEDAL":
            case 21:
                message.type = 21;
                break;
            case "NOTIFICATION_TYPE_PROMOTION_OFFER":
            case 22:
                message.type = 22;
                break;
            case "NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY":
            case 24:
                message.type = 24;
                break;
            case "NOTIFICATION_TYPE_STORY_REMINDER":
            case 26:
                message.type = 26;
                break;
            case "NOTIFICATION_TYPE_PAW_INTRODUCTION":
            case 27:
                message.type = 27;
                break;
            case "NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE":
            case 29:
                message.type = 29;
                break;
            case "NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED":
            case 30:
                message.type = 30;
                break;
            case "NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER":
            case 31:
                message.type = 31;
                break;
            case "NOTIFICATION_TYPE_NEW_SEASON":
            case 32:
                message.type = 32;
                break;
            case "NOTIFICATION_TYPE_NEXT_SEASON_REMINDER":
            case 33:
                message.type = 33;
                break;
            case "NOTIFICATION_TYPE_STORY_VIDEO_CREATED":
            case 34:
                message.type = 34;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_NEW_LIKES":
            case 35:
                message.type = 35;
                break;
            case "NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST":
            case 36:
                message.type = 36;
                break;
            case "NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES":
            case 6:
                message.type = 6;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS":
            case 7:
                message.type = 7;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES":
            case 9:
                message.type = 9;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS":
            case 14:
                message.type = 14;
                break;
            case "NOTIFICATION_TYPE_STORY_IN_TAG_RANKING":
            case 16:
                message.type = 16;
                break;
            case "NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK":
            case 17:
                message.type = 17;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES":
            case 18:
                message.type = 18;
                break;
            case "NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK":
            case 19:
                message.type = 19;
                break;
            case "NOTIFICATION_TYPE_PAW_RECOVERED":
            case 20:
                message.type = 20;
                break;
            case "NOTIFICATION_TYPE_NEW_BOOKMARK":
            case 23:
                message.type = 23;
                break;
            case "NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK":
            case 25:
                message.type = 25;
                break;
            case "NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER":
            case 28:
                message.type = 28;
                break;
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.NotificationResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.contentsV2 != null) {
                if (typeof object.contentsV2 !== "object")
                    throw TypeError(".query_recursive_types.NotificationResponse.contentsV2: object expected");
                message.contentsV2 = $root.query_recursive_types.NotificationContentsV2.fromObject(object.contentsV2);
            }
            if (object.contents != null) {
                if (typeof object.contents !== "object")
                    throw TypeError(".query_recursive_types.NotificationResponse.contents: object expected");
                message.contents = $root.notification.NotificationContents.fromObject(object.contents);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationResponse
         * @static
         * @param {query_recursive_types.NotificationResponse} message NotificationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.type = options.enums === String ? "NOTIFICATION_TYPE_UNSPECIFIED" : 0;
                object.contents = null;
                object.createdAt = null;
                object.contentsV2 = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.notification.NotificationType[message.type] : message.type;
            if (message.contents != null && message.hasOwnProperty("contents"))
                object.contents = $root.notification.NotificationContents.toObject(message.contents, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.contentsV2 != null && message.hasOwnProperty("contentsV2"))
                object.contentsV2 = $root.query_recursive_types.NotificationContentsV2.toObject(message.contentsV2, options);
            return object;
        };

        /**
         * Converts this NotificationResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationResponse";
        };

        return NotificationResponse;
    })();

    query_recursive_types.MyStoryCursorResponse = (function() {

        /**
         * Properties of a MyStoryCursorResponse.
         * @memberof query_recursive_types
         * @interface IMyStoryCursorResponse
         * @property {Array.<query_recursive_types.IStoryResponse>|null} [storyList] MyStoryCursorResponse storyList
         * @property {query_pagination.ICursorInfo|null} [cursor] MyStoryCursorResponse cursor
         */

        /**
         * Constructs a new MyStoryCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a MyStoryCursorResponse.
         * @implements IMyStoryCursorResponse
         * @constructor
         * @param {query_recursive_types.IMyStoryCursorResponse=} [properties] Properties to set
         */
        function MyStoryCursorResponse(properties) {
            this.storyList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MyStoryCursorResponse storyList.
         * @member {Array.<query_recursive_types.IStoryResponse>} storyList
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @instance
         */
        MyStoryCursorResponse.prototype.storyList = $util.emptyArray;

        /**
         * MyStoryCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @instance
         */
        MyStoryCursorResponse.prototype.cursor = null;

        /**
         * Creates a new MyStoryCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @static
         * @param {query_recursive_types.IMyStoryCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.MyStoryCursorResponse} MyStoryCursorResponse instance
         */
        MyStoryCursorResponse.create = function create(properties) {
            return new MyStoryCursorResponse(properties);
        };

        /**
         * Encodes the specified MyStoryCursorResponse message. Does not implicitly {@link query_recursive_types.MyStoryCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @static
         * @param {query_recursive_types.IMyStoryCursorResponse} message MyStoryCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MyStoryCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyList != null && message.storyList.length)
                for (var i = 0; i < message.storyList.length; ++i)
                    $root.query_recursive_types.StoryResponse.encode(message.storyList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MyStoryCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MyStoryCursorResponse} MyStoryCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MyStoryCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MyStoryCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyList && message.storyList.length))
                        message.storyList = [];
                    message.storyList.push($root.query_recursive_types.StoryResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MyStoryCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MyStoryCursorResponse} MyStoryCursorResponse
         */
        MyStoryCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MyStoryCursorResponse)
                return object;
            var message = new $root.query_recursive_types.MyStoryCursorResponse();
            if (object.storyList) {
                if (!Array.isArray(object.storyList))
                    throw TypeError(".query_recursive_types.MyStoryCursorResponse.storyList: array expected");
                message.storyList = [];
                for (var i = 0; i < object.storyList.length; ++i) {
                    if (typeof object.storyList[i] !== "object")
                        throw TypeError(".query_recursive_types.MyStoryCursorResponse.storyList: object expected");
                    message.storyList[i] = $root.query_recursive_types.StoryResponse.fromObject(object.storyList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.MyStoryCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MyStoryCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @static
         * @param {query_recursive_types.MyStoryCursorResponse} message MyStoryCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MyStoryCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.storyList && message.storyList.length) {
                object.storyList = [];
                for (var j = 0; j < message.storyList.length; ++j)
                    object.storyList[j] = $root.query_recursive_types.StoryResponse.toObject(message.storyList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MyStoryCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MyStoryCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MyStoryCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.MyStoryCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MyStoryCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MyStoryCursorResponse";
        };

        return MyStoryCursorResponse;
    })();

    query_recursive_types.MySeriesCursorResponse = (function() {

        /**
         * Properties of a MySeriesCursorResponse.
         * @memberof query_recursive_types
         * @interface IMySeriesCursorResponse
         * @property {Array.<query_recursive_types.ISeriesResponse>|null} [seriesList] MySeriesCursorResponse seriesList
         * @property {query_pagination.ICursorInfo|null} [cursor] MySeriesCursorResponse cursor
         */

        /**
         * Constructs a new MySeriesCursorResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a MySeriesCursorResponse.
         * @implements IMySeriesCursorResponse
         * @constructor
         * @param {query_recursive_types.IMySeriesCursorResponse=} [properties] Properties to set
         */
        function MySeriesCursorResponse(properties) {
            this.seriesList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MySeriesCursorResponse seriesList.
         * @member {Array.<query_recursive_types.ISeriesResponse>} seriesList
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @instance
         */
        MySeriesCursorResponse.prototype.seriesList = $util.emptyArray;

        /**
         * MySeriesCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @instance
         */
        MySeriesCursorResponse.prototype.cursor = null;

        /**
         * Creates a new MySeriesCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @static
         * @param {query_recursive_types.IMySeriesCursorResponse=} [properties] Properties to set
         * @returns {query_recursive_types.MySeriesCursorResponse} MySeriesCursorResponse instance
         */
        MySeriesCursorResponse.create = function create(properties) {
            return new MySeriesCursorResponse(properties);
        };

        /**
         * Encodes the specified MySeriesCursorResponse message. Does not implicitly {@link query_recursive_types.MySeriesCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @static
         * @param {query_recursive_types.IMySeriesCursorResponse} message MySeriesCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MySeriesCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesList != null && message.seriesList.length)
                for (var i = 0; i < message.seriesList.length; ++i)
                    $root.query_recursive_types.SeriesResponse.encode(message.seriesList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MySeriesCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.MySeriesCursorResponse} MySeriesCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MySeriesCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.MySeriesCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.seriesList && message.seriesList.length))
                        message.seriesList = [];
                    message.seriesList.push($root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a MySeriesCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.MySeriesCursorResponse} MySeriesCursorResponse
         */
        MySeriesCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.MySeriesCursorResponse)
                return object;
            var message = new $root.query_recursive_types.MySeriesCursorResponse();
            if (object.seriesList) {
                if (!Array.isArray(object.seriesList))
                    throw TypeError(".query_recursive_types.MySeriesCursorResponse.seriesList: array expected");
                message.seriesList = [];
                for (var i = 0; i < object.seriesList.length; ++i) {
                    if (typeof object.seriesList[i] !== "object")
                        throw TypeError(".query_recursive_types.MySeriesCursorResponse.seriesList: object expected");
                    message.seriesList[i] = $root.query_recursive_types.SeriesResponse.fromObject(object.seriesList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_recursive_types.MySeriesCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a MySeriesCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @static
         * @param {query_recursive_types.MySeriesCursorResponse} message MySeriesCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MySeriesCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.seriesList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.seriesList && message.seriesList.length) {
                object.seriesList = [];
                for (var j = 0; j < message.seriesList.length; ++j)
                    object.seriesList[j] = $root.query_recursive_types.SeriesResponse.toObject(message.seriesList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this MySeriesCursorResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MySeriesCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MySeriesCursorResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.MySeriesCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MySeriesCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.MySeriesCursorResponse";
        };

        return MySeriesCursorResponse;
    })();

    query_recursive_types.NotificationContentsV2 = (function() {

        /**
         * Properties of a NotificationContentsV2.
         * @memberof query_recursive_types
         * @interface INotificationContentsV2
         * @property {notification.INotificationContentsOperation|null} [operation] NotificationContentsV2 operation
         * @property {notification.INotificationContentsStoryRejected|null} [storyRejected] NotificationContentsV2 storyRejected
         * @property {notification.INotificationContentsUserBanned|null} [userBanned] NotificationContentsV2 userBanned
         * @property {notification.INotificationContentsPaymentFailure|null} [paymentFailure] NotificationContentsV2 paymentFailure
         * @property {notification.INotificationContentsVipGracePeriodExpired|null} [vipGracePeriodExpired] NotificationContentsV2 vipGracePeriodExpired
         * @property {notification.INotificationContentsNewFollower|null} [newFollower] NotificationContentsV2 newFollower
         * @property {notification.INotificationContentsNewStoryComment|null} [newStoryComment] NotificationContentsV2 newStoryComment
         * @property {notification.INotificationContentsNewCommentLike|null} [newCommentLike] NotificationContentsV2 newCommentLike
         * @property {notification.INotificationContentsNewCommentResponse|null} [newCommentResponse] NotificationContentsV2 newCommentResponse
         * @property {query_recursive_types.INotificationContentsFollowingUserNewStoryV2|null} [followingUserNewStory] NotificationContentsV2 followingUserNewStory
         * @property {notification.INotificationContentsUserLikeBreak|null} [userLikeBreak] NotificationContentsV2 userLikeBreak
         * @property {query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2|null} [bookmarkNewSeriesStory] NotificationContentsV2 bookmarkNewSeriesStory
         * @property {notification.INotificationContentsUserStoryLikeBreak|null} [userStoryLikeBreak] NotificationContentsV2 userStoryLikeBreak
         * @property {notification.INotificationContentsNewMedal|null} [newMedal] NotificationContentsV2 newMedal
         * @property {notification.INotificationContentsUserStoryNewLikes|null} [userStoryNewLikes] NotificationContentsV2 userStoryNewLikes
         * @property {notification.INotificationContentsStoryVideoCreated|null} [storyVideoCreated] NotificationContentsV2 storyVideoCreated
         * @property {notification.INotificationContentsMutualFollowReadRequest|null} [mutualFollowReadRequest] NotificationContentsV2 mutualFollowReadRequest
         */

        /**
         * Constructs a new NotificationContentsV2.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationContentsV2.
         * @implements INotificationContentsV2
         * @constructor
         * @param {query_recursive_types.INotificationContentsV2=} [properties] Properties to set
         */
        function NotificationContentsV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsV2 operation.
         * @member {notification.INotificationContentsOperation|null|undefined} operation
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.operation = null;

        /**
         * NotificationContentsV2 storyRejected.
         * @member {notification.INotificationContentsStoryRejected|null|undefined} storyRejected
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.storyRejected = null;

        /**
         * NotificationContentsV2 userBanned.
         * @member {notification.INotificationContentsUserBanned|null|undefined} userBanned
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.userBanned = null;

        /**
         * NotificationContentsV2 paymentFailure.
         * @member {notification.INotificationContentsPaymentFailure|null|undefined} paymentFailure
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.paymentFailure = null;

        /**
         * NotificationContentsV2 vipGracePeriodExpired.
         * @member {notification.INotificationContentsVipGracePeriodExpired|null|undefined} vipGracePeriodExpired
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.vipGracePeriodExpired = null;

        /**
         * NotificationContentsV2 newFollower.
         * @member {notification.INotificationContentsNewFollower|null|undefined} newFollower
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.newFollower = null;

        /**
         * NotificationContentsV2 newStoryComment.
         * @member {notification.INotificationContentsNewStoryComment|null|undefined} newStoryComment
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.newStoryComment = null;

        /**
         * NotificationContentsV2 newCommentLike.
         * @member {notification.INotificationContentsNewCommentLike|null|undefined} newCommentLike
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.newCommentLike = null;

        /**
         * NotificationContentsV2 newCommentResponse.
         * @member {notification.INotificationContentsNewCommentResponse|null|undefined} newCommentResponse
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.newCommentResponse = null;

        /**
         * NotificationContentsV2 followingUserNewStory.
         * @member {query_recursive_types.INotificationContentsFollowingUserNewStoryV2|null|undefined} followingUserNewStory
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.followingUserNewStory = null;

        /**
         * NotificationContentsV2 userLikeBreak.
         * @member {notification.INotificationContentsUserLikeBreak|null|undefined} userLikeBreak
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.userLikeBreak = null;

        /**
         * NotificationContentsV2 bookmarkNewSeriesStory.
         * @member {query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2|null|undefined} bookmarkNewSeriesStory
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.bookmarkNewSeriesStory = null;

        /**
         * NotificationContentsV2 userStoryLikeBreak.
         * @member {notification.INotificationContentsUserStoryLikeBreak|null|undefined} userStoryLikeBreak
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.userStoryLikeBreak = null;

        /**
         * NotificationContentsV2 newMedal.
         * @member {notification.INotificationContentsNewMedal|null|undefined} newMedal
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.newMedal = null;

        /**
         * NotificationContentsV2 userStoryNewLikes.
         * @member {notification.INotificationContentsUserStoryNewLikes|null|undefined} userStoryNewLikes
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.userStoryNewLikes = null;

        /**
         * NotificationContentsV2 storyVideoCreated.
         * @member {notification.INotificationContentsStoryVideoCreated|null|undefined} storyVideoCreated
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.storyVideoCreated = null;

        /**
         * NotificationContentsV2 mutualFollowReadRequest.
         * @member {notification.INotificationContentsMutualFollowReadRequest|null|undefined} mutualFollowReadRequest
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        NotificationContentsV2.prototype.mutualFollowReadRequest = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * NotificationContentsV2 contents.
         * @member {"operation"|"storyRejected"|"userBanned"|"paymentFailure"|"vipGracePeriodExpired"|"newFollower"|"newStoryComment"|"newCommentLike"|"newCommentResponse"|"followingUserNewStory"|"userLikeBreak"|"bookmarkNewSeriesStory"|"userStoryLikeBreak"|"newMedal"|"userStoryNewLikes"|"storyVideoCreated"|"mutualFollowReadRequest"|undefined} contents
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         */
        Object.defineProperty(NotificationContentsV2.prototype, "contents", {
            get: $util.oneOfGetter($oneOfFields = ["operation", "storyRejected", "userBanned", "paymentFailure", "vipGracePeriodExpired", "newFollower", "newStoryComment", "newCommentLike", "newCommentResponse", "followingUserNewStory", "userLikeBreak", "bookmarkNewSeriesStory", "userStoryLikeBreak", "newMedal", "userStoryNewLikes", "storyVideoCreated", "mutualFollowReadRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new NotificationContentsV2 instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationContentsV2
         * @static
         * @param {query_recursive_types.INotificationContentsV2=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationContentsV2} NotificationContentsV2 instance
         */
        NotificationContentsV2.create = function create(properties) {
            return new NotificationContentsV2(properties);
        };

        /**
         * Encodes the specified NotificationContentsV2 message. Does not implicitly {@link query_recursive_types.NotificationContentsV2.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationContentsV2
         * @static
         * @param {query_recursive_types.INotificationContentsV2} message NotificationContentsV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
                $root.notification.NotificationContentsOperation.encode(message.operation, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyRejected != null && Object.hasOwnProperty.call(message, "storyRejected"))
                $root.notification.NotificationContentsStoryRejected.encode(message.storyRejected, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.userBanned != null && Object.hasOwnProperty.call(message, "userBanned"))
                $root.notification.NotificationContentsUserBanned.encode(message.userBanned, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.paymentFailure != null && Object.hasOwnProperty.call(message, "paymentFailure"))
                $root.notification.NotificationContentsPaymentFailure.encode(message.paymentFailure, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.vipGracePeriodExpired != null && Object.hasOwnProperty.call(message, "vipGracePeriodExpired"))
                $root.notification.NotificationContentsVipGracePeriodExpired.encode(message.vipGracePeriodExpired, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.newFollower != null && Object.hasOwnProperty.call(message, "newFollower"))
                $root.notification.NotificationContentsNewFollower.encode(message.newFollower, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.newStoryComment != null && Object.hasOwnProperty.call(message, "newStoryComment"))
                $root.notification.NotificationContentsNewStoryComment.encode(message.newStoryComment, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.newCommentLike != null && Object.hasOwnProperty.call(message, "newCommentLike"))
                $root.notification.NotificationContentsNewCommentLike.encode(message.newCommentLike, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.newCommentResponse != null && Object.hasOwnProperty.call(message, "newCommentResponse"))
                $root.notification.NotificationContentsNewCommentResponse.encode(message.newCommentResponse, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.followingUserNewStory != null && Object.hasOwnProperty.call(message, "followingUserNewStory"))
                $root.query_recursive_types.NotificationContentsFollowingUserNewStoryV2.encode(message.followingUserNewStory, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.userLikeBreak != null && Object.hasOwnProperty.call(message, "userLikeBreak"))
                $root.notification.NotificationContentsUserLikeBreak.encode(message.userLikeBreak, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.bookmarkNewSeriesStory != null && Object.hasOwnProperty.call(message, "bookmarkNewSeriesStory"))
                $root.query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.encode(message.bookmarkNewSeriesStory, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.userStoryLikeBreak != null && Object.hasOwnProperty.call(message, "userStoryLikeBreak"))
                $root.notification.NotificationContentsUserStoryLikeBreak.encode(message.userStoryLikeBreak, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.newMedal != null && Object.hasOwnProperty.call(message, "newMedal"))
                $root.notification.NotificationContentsNewMedal.encode(message.newMedal, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.userStoryNewLikes != null && Object.hasOwnProperty.call(message, "userStoryNewLikes"))
                $root.notification.NotificationContentsUserStoryNewLikes.encode(message.userStoryNewLikes, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.storyVideoCreated != null && Object.hasOwnProperty.call(message, "storyVideoCreated"))
                $root.notification.NotificationContentsStoryVideoCreated.encode(message.storyVideoCreated, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.mutualFollowReadRequest != null && Object.hasOwnProperty.call(message, "mutualFollowReadRequest"))
                $root.notification.NotificationContentsMutualFollowReadRequest.encode(message.mutualFollowReadRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationContentsV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationContentsV2} NotificationContentsV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationContentsV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.operation = $root.notification.NotificationContentsOperation.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyRejected = $root.notification.NotificationContentsStoryRejected.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userBanned = $root.notification.NotificationContentsUserBanned.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.paymentFailure = $root.notification.NotificationContentsPaymentFailure.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.vipGracePeriodExpired = $root.notification.NotificationContentsVipGracePeriodExpired.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.newFollower = $root.notification.NotificationContentsNewFollower.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.newStoryComment = $root.notification.NotificationContentsNewStoryComment.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.newCommentLike = $root.notification.NotificationContentsNewCommentLike.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.newCommentResponse = $root.notification.NotificationContentsNewCommentResponse.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.followingUserNewStory = $root.query_recursive_types.NotificationContentsFollowingUserNewStoryV2.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.userLikeBreak = $root.notification.NotificationContentsUserLikeBreak.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.bookmarkNewSeriesStory = $root.query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.userStoryLikeBreak = $root.notification.NotificationContentsUserStoryLikeBreak.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.newMedal = $root.notification.NotificationContentsNewMedal.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.userStoryNewLikes = $root.notification.NotificationContentsUserStoryNewLikes.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.storyVideoCreated = $root.notification.NotificationContentsStoryVideoCreated.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.mutualFollowReadRequest = $root.notification.NotificationContentsMutualFollowReadRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationContentsV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationContentsV2} NotificationContentsV2
         */
        NotificationContentsV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationContentsV2)
                return object;
            var message = new $root.query_recursive_types.NotificationContentsV2();
            if (object.operation != null) {
                if (typeof object.operation !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.operation: object expected");
                message.operation = $root.notification.NotificationContentsOperation.fromObject(object.operation);
            }
            if (object.storyRejected != null) {
                if (typeof object.storyRejected !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.storyRejected: object expected");
                message.storyRejected = $root.notification.NotificationContentsStoryRejected.fromObject(object.storyRejected);
            }
            if (object.userBanned != null) {
                if (typeof object.userBanned !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.userBanned: object expected");
                message.userBanned = $root.notification.NotificationContentsUserBanned.fromObject(object.userBanned);
            }
            if (object.paymentFailure != null) {
                if (typeof object.paymentFailure !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.paymentFailure: object expected");
                message.paymentFailure = $root.notification.NotificationContentsPaymentFailure.fromObject(object.paymentFailure);
            }
            if (object.vipGracePeriodExpired != null) {
                if (typeof object.vipGracePeriodExpired !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.vipGracePeriodExpired: object expected");
                message.vipGracePeriodExpired = $root.notification.NotificationContentsVipGracePeriodExpired.fromObject(object.vipGracePeriodExpired);
            }
            if (object.newFollower != null) {
                if (typeof object.newFollower !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.newFollower: object expected");
                message.newFollower = $root.notification.NotificationContentsNewFollower.fromObject(object.newFollower);
            }
            if (object.newStoryComment != null) {
                if (typeof object.newStoryComment !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.newStoryComment: object expected");
                message.newStoryComment = $root.notification.NotificationContentsNewStoryComment.fromObject(object.newStoryComment);
            }
            if (object.newCommentLike != null) {
                if (typeof object.newCommentLike !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.newCommentLike: object expected");
                message.newCommentLike = $root.notification.NotificationContentsNewCommentLike.fromObject(object.newCommentLike);
            }
            if (object.newCommentResponse != null) {
                if (typeof object.newCommentResponse !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.newCommentResponse: object expected");
                message.newCommentResponse = $root.notification.NotificationContentsNewCommentResponse.fromObject(object.newCommentResponse);
            }
            if (object.followingUserNewStory != null) {
                if (typeof object.followingUserNewStory !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.followingUserNewStory: object expected");
                message.followingUserNewStory = $root.query_recursive_types.NotificationContentsFollowingUserNewStoryV2.fromObject(object.followingUserNewStory);
            }
            if (object.userLikeBreak != null) {
                if (typeof object.userLikeBreak !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.userLikeBreak: object expected");
                message.userLikeBreak = $root.notification.NotificationContentsUserLikeBreak.fromObject(object.userLikeBreak);
            }
            if (object.bookmarkNewSeriesStory != null) {
                if (typeof object.bookmarkNewSeriesStory !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.bookmarkNewSeriesStory: object expected");
                message.bookmarkNewSeriesStory = $root.query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.fromObject(object.bookmarkNewSeriesStory);
            }
            if (object.userStoryLikeBreak != null) {
                if (typeof object.userStoryLikeBreak !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.userStoryLikeBreak: object expected");
                message.userStoryLikeBreak = $root.notification.NotificationContentsUserStoryLikeBreak.fromObject(object.userStoryLikeBreak);
            }
            if (object.newMedal != null) {
                if (typeof object.newMedal !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.newMedal: object expected");
                message.newMedal = $root.notification.NotificationContentsNewMedal.fromObject(object.newMedal);
            }
            if (object.userStoryNewLikes != null) {
                if (typeof object.userStoryNewLikes !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.userStoryNewLikes: object expected");
                message.userStoryNewLikes = $root.notification.NotificationContentsUserStoryNewLikes.fromObject(object.userStoryNewLikes);
            }
            if (object.storyVideoCreated != null) {
                if (typeof object.storyVideoCreated !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.storyVideoCreated: object expected");
                message.storyVideoCreated = $root.notification.NotificationContentsStoryVideoCreated.fromObject(object.storyVideoCreated);
            }
            if (object.mutualFollowReadRequest != null) {
                if (typeof object.mutualFollowReadRequest !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsV2.mutualFollowReadRequest: object expected");
                message.mutualFollowReadRequest = $root.notification.NotificationContentsMutualFollowReadRequest.fromObject(object.mutualFollowReadRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationContentsV2
         * @static
         * @param {query_recursive_types.NotificationContentsV2} message NotificationContentsV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.operation != null && message.hasOwnProperty("operation")) {
                object.operation = $root.notification.NotificationContentsOperation.toObject(message.operation, options);
                if (options.oneofs)
                    object.contents = "operation";
            }
            if (message.storyRejected != null && message.hasOwnProperty("storyRejected")) {
                object.storyRejected = $root.notification.NotificationContentsStoryRejected.toObject(message.storyRejected, options);
                if (options.oneofs)
                    object.contents = "storyRejected";
            }
            if (message.userBanned != null && message.hasOwnProperty("userBanned")) {
                object.userBanned = $root.notification.NotificationContentsUserBanned.toObject(message.userBanned, options);
                if (options.oneofs)
                    object.contents = "userBanned";
            }
            if (message.paymentFailure != null && message.hasOwnProperty("paymentFailure")) {
                object.paymentFailure = $root.notification.NotificationContentsPaymentFailure.toObject(message.paymentFailure, options);
                if (options.oneofs)
                    object.contents = "paymentFailure";
            }
            if (message.vipGracePeriodExpired != null && message.hasOwnProperty("vipGracePeriodExpired")) {
                object.vipGracePeriodExpired = $root.notification.NotificationContentsVipGracePeriodExpired.toObject(message.vipGracePeriodExpired, options);
                if (options.oneofs)
                    object.contents = "vipGracePeriodExpired";
            }
            if (message.newFollower != null && message.hasOwnProperty("newFollower")) {
                object.newFollower = $root.notification.NotificationContentsNewFollower.toObject(message.newFollower, options);
                if (options.oneofs)
                    object.contents = "newFollower";
            }
            if (message.newStoryComment != null && message.hasOwnProperty("newStoryComment")) {
                object.newStoryComment = $root.notification.NotificationContentsNewStoryComment.toObject(message.newStoryComment, options);
                if (options.oneofs)
                    object.contents = "newStoryComment";
            }
            if (message.newCommentLike != null && message.hasOwnProperty("newCommentLike")) {
                object.newCommentLike = $root.notification.NotificationContentsNewCommentLike.toObject(message.newCommentLike, options);
                if (options.oneofs)
                    object.contents = "newCommentLike";
            }
            if (message.newCommentResponse != null && message.hasOwnProperty("newCommentResponse")) {
                object.newCommentResponse = $root.notification.NotificationContentsNewCommentResponse.toObject(message.newCommentResponse, options);
                if (options.oneofs)
                    object.contents = "newCommentResponse";
            }
            if (message.followingUserNewStory != null && message.hasOwnProperty("followingUserNewStory")) {
                object.followingUserNewStory = $root.query_recursive_types.NotificationContentsFollowingUserNewStoryV2.toObject(message.followingUserNewStory, options);
                if (options.oneofs)
                    object.contents = "followingUserNewStory";
            }
            if (message.userLikeBreak != null && message.hasOwnProperty("userLikeBreak")) {
                object.userLikeBreak = $root.notification.NotificationContentsUserLikeBreak.toObject(message.userLikeBreak, options);
                if (options.oneofs)
                    object.contents = "userLikeBreak";
            }
            if (message.bookmarkNewSeriesStory != null && message.hasOwnProperty("bookmarkNewSeriesStory")) {
                object.bookmarkNewSeriesStory = $root.query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.toObject(message.bookmarkNewSeriesStory, options);
                if (options.oneofs)
                    object.contents = "bookmarkNewSeriesStory";
            }
            if (message.userStoryLikeBreak != null && message.hasOwnProperty("userStoryLikeBreak")) {
                object.userStoryLikeBreak = $root.notification.NotificationContentsUserStoryLikeBreak.toObject(message.userStoryLikeBreak, options);
                if (options.oneofs)
                    object.contents = "userStoryLikeBreak";
            }
            if (message.newMedal != null && message.hasOwnProperty("newMedal")) {
                object.newMedal = $root.notification.NotificationContentsNewMedal.toObject(message.newMedal, options);
                if (options.oneofs)
                    object.contents = "newMedal";
            }
            if (message.userStoryNewLikes != null && message.hasOwnProperty("userStoryNewLikes")) {
                object.userStoryNewLikes = $root.notification.NotificationContentsUserStoryNewLikes.toObject(message.userStoryNewLikes, options);
                if (options.oneofs)
                    object.contents = "userStoryNewLikes";
            }
            if (message.storyVideoCreated != null && message.hasOwnProperty("storyVideoCreated")) {
                object.storyVideoCreated = $root.notification.NotificationContentsStoryVideoCreated.toObject(message.storyVideoCreated, options);
                if (options.oneofs)
                    object.contents = "storyVideoCreated";
            }
            if (message.mutualFollowReadRequest != null && message.hasOwnProperty("mutualFollowReadRequest")) {
                object.mutualFollowReadRequest = $root.notification.NotificationContentsMutualFollowReadRequest.toObject(message.mutualFollowReadRequest, options);
                if (options.oneofs)
                    object.contents = "mutualFollowReadRequest";
            }
            return object;
        };

        /**
         * Converts this NotificationContentsV2 to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationContentsV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsV2
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationContentsV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationContentsV2";
        };

        return NotificationContentsV2;
    })();

    query_recursive_types.NotificationContentsFollowingUserNewStoryV2 = (function() {

        /**
         * Properties of a NotificationContentsFollowingUserNewStoryV2.
         * @memberof query_recursive_types
         * @interface INotificationContentsFollowingUserNewStoryV2
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsFollowingUserNewStoryV2 message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsFollowingUserNewStoryV2 actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsFollowingUserNewStoryV2 thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsFollowingUserNewStoryV2 userIcon
         * @property {query_recursive_types.IStoryResponse|null} [story] NotificationContentsFollowingUserNewStoryV2 story
         */

        /**
         * Constructs a new NotificationContentsFollowingUserNewStoryV2.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationContentsFollowingUserNewStoryV2.
         * @implements INotificationContentsFollowingUserNewStoryV2
         * @constructor
         * @param {query_recursive_types.INotificationContentsFollowingUserNewStoryV2=} [properties] Properties to set
         */
        function NotificationContentsFollowingUserNewStoryV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsFollowingUserNewStoryV2 message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @instance
         */
        NotificationContentsFollowingUserNewStoryV2.prototype.message = null;

        /**
         * NotificationContentsFollowingUserNewStoryV2 actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @instance
         */
        NotificationContentsFollowingUserNewStoryV2.prototype.actionUrl = null;

        /**
         * NotificationContentsFollowingUserNewStoryV2 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @instance
         */
        NotificationContentsFollowingUserNewStoryV2.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsFollowingUserNewStoryV2 userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @instance
         */
        NotificationContentsFollowingUserNewStoryV2.prototype.userIcon = null;

        /**
         * NotificationContentsFollowingUserNewStoryV2 story.
         * @member {query_recursive_types.IStoryResponse|null|undefined} story
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @instance
         */
        NotificationContentsFollowingUserNewStoryV2.prototype.story = null;

        /**
         * Creates a new NotificationContentsFollowingUserNewStoryV2 instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @static
         * @param {query_recursive_types.INotificationContentsFollowingUserNewStoryV2=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationContentsFollowingUserNewStoryV2} NotificationContentsFollowingUserNewStoryV2 instance
         */
        NotificationContentsFollowingUserNewStoryV2.create = function create(properties) {
            return new NotificationContentsFollowingUserNewStoryV2(properties);
        };

        /**
         * Encodes the specified NotificationContentsFollowingUserNewStoryV2 message. Does not implicitly {@link query_recursive_types.NotificationContentsFollowingUserNewStoryV2.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @static
         * @param {query_recursive_types.INotificationContentsFollowingUserNewStoryV2} message NotificationContentsFollowingUserNewStoryV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsFollowingUserNewStoryV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.query_recursive_types.StoryResponse.encode(message.story, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsFollowingUserNewStoryV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationContentsFollowingUserNewStoryV2} NotificationContentsFollowingUserNewStoryV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsFollowingUserNewStoryV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationContentsFollowingUserNewStoryV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.story = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsFollowingUserNewStoryV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationContentsFollowingUserNewStoryV2} NotificationContentsFollowingUserNewStoryV2
         */
        NotificationContentsFollowingUserNewStoryV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationContentsFollowingUserNewStoryV2)
                return object;
            var message = new $root.query_recursive_types.NotificationContentsFollowingUserNewStoryV2();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsFollowingUserNewStoryV2.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsFollowingUserNewStoryV2.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsFollowingUserNewStoryV2.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsFollowingUserNewStoryV2.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsFollowingUserNewStoryV2.story: object expected");
                message.story = $root.query_recursive_types.StoryResponse.fromObject(object.story);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsFollowingUserNewStoryV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @static
         * @param {query_recursive_types.NotificationContentsFollowingUserNewStoryV2} message NotificationContentsFollowingUserNewStoryV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsFollowingUserNewStoryV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.userIcon = null;
                object.story = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            if (message.story != null && message.hasOwnProperty("story"))
                object.story = $root.query_recursive_types.StoryResponse.toObject(message.story, options);
            return object;
        };

        /**
         * Converts this NotificationContentsFollowingUserNewStoryV2 to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsFollowingUserNewStoryV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsFollowingUserNewStoryV2
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationContentsFollowingUserNewStoryV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsFollowingUserNewStoryV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationContentsFollowingUserNewStoryV2";
        };

        return NotificationContentsFollowingUserNewStoryV2;
    })();

    query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2 = (function() {

        /**
         * Properties of a NotificationContentsBookmarkNewSeriesStoryV2.
         * @memberof query_recursive_types
         * @interface INotificationContentsBookmarkNewSeriesStoryV2
         * @property {google.protobuf.IStringValue|null} [message] NotificationContentsBookmarkNewSeriesStoryV2 message
         * @property {google.protobuf.IStringValue|null} [actionUrl] NotificationContentsBookmarkNewSeriesStoryV2 actionUrl
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] NotificationContentsBookmarkNewSeriesStoryV2 thumbnailUrl
         * @property {notification.IImageWithLink|null} [userIcon] NotificationContentsBookmarkNewSeriesStoryV2 userIcon
         * @property {query_recursive_types.IStoryResponse|null} [story] NotificationContentsBookmarkNewSeriesStoryV2 story
         */

        /**
         * Constructs a new NotificationContentsBookmarkNewSeriesStoryV2.
         * @memberof query_recursive_types
         * @classdesc Represents a NotificationContentsBookmarkNewSeriesStoryV2.
         * @implements INotificationContentsBookmarkNewSeriesStoryV2
         * @constructor
         * @param {query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2=} [properties] Properties to set
         */
        function NotificationContentsBookmarkNewSeriesStoryV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationContentsBookmarkNewSeriesStoryV2 message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStoryV2.prototype.message = null;

        /**
         * NotificationContentsBookmarkNewSeriesStoryV2 actionUrl.
         * @member {google.protobuf.IStringValue|null|undefined} actionUrl
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStoryV2.prototype.actionUrl = null;

        /**
         * NotificationContentsBookmarkNewSeriesStoryV2 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStoryV2.prototype.thumbnailUrl = null;

        /**
         * NotificationContentsBookmarkNewSeriesStoryV2 userIcon.
         * @member {notification.IImageWithLink|null|undefined} userIcon
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStoryV2.prototype.userIcon = null;

        /**
         * NotificationContentsBookmarkNewSeriesStoryV2 story.
         * @member {query_recursive_types.IStoryResponse|null|undefined} story
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @instance
         */
        NotificationContentsBookmarkNewSeriesStoryV2.prototype.story = null;

        /**
         * Creates a new NotificationContentsBookmarkNewSeriesStoryV2 instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @static
         * @param {query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2=} [properties] Properties to set
         * @returns {query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2} NotificationContentsBookmarkNewSeriesStoryV2 instance
         */
        NotificationContentsBookmarkNewSeriesStoryV2.create = function create(properties) {
            return new NotificationContentsBookmarkNewSeriesStoryV2(properties);
        };

        /**
         * Encodes the specified NotificationContentsBookmarkNewSeriesStoryV2 message. Does not implicitly {@link query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @static
         * @param {query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2} message NotificationContentsBookmarkNewSeriesStoryV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationContentsBookmarkNewSeriesStoryV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
                $root.google.protobuf.StringValue.encode(message.actionUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.userIcon != null && Object.hasOwnProperty.call(message, "userIcon"))
                $root.notification.ImageWithLink.encode(message.userIcon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.query_recursive_types.StoryResponse.encode(message.story, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationContentsBookmarkNewSeriesStoryV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2} NotificationContentsBookmarkNewSeriesStoryV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationContentsBookmarkNewSeriesStoryV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.userIcon = $root.notification.ImageWithLink.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.story = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a NotificationContentsBookmarkNewSeriesStoryV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2} NotificationContentsBookmarkNewSeriesStoryV2
         */
        NotificationContentsBookmarkNewSeriesStoryV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2)
                return object;
            var message = new $root.query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            if (object.actionUrl != null) {
                if (typeof object.actionUrl !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.actionUrl: object expected");
                message.actionUrl = $root.google.protobuf.StringValue.fromObject(object.actionUrl);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.userIcon != null) {
                if (typeof object.userIcon !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.userIcon: object expected");
                message.userIcon = $root.notification.ImageWithLink.fromObject(object.userIcon);
            }
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2.story: object expected");
                message.story = $root.query_recursive_types.StoryResponse.fromObject(object.story);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationContentsBookmarkNewSeriesStoryV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @static
         * @param {query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2} message NotificationContentsBookmarkNewSeriesStoryV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationContentsBookmarkNewSeriesStoryV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.message = null;
                object.actionUrl = null;
                object.thumbnailUrl = null;
                object.userIcon = null;
                object.story = null;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
                object.actionUrl = $root.google.protobuf.StringValue.toObject(message.actionUrl, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.userIcon != null && message.hasOwnProperty("userIcon"))
                object.userIcon = $root.notification.ImageWithLink.toObject(message.userIcon, options);
            if (message.story != null && message.hasOwnProperty("story"))
                object.story = $root.query_recursive_types.StoryResponse.toObject(message.story, options);
            return object;
        };

        /**
         * Converts this NotificationContentsBookmarkNewSeriesStoryV2 to JSON.
         * @function toJSON
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationContentsBookmarkNewSeriesStoryV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationContentsBookmarkNewSeriesStoryV2
         * @function getTypeUrl
         * @memberof query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationContentsBookmarkNewSeriesStoryV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.NotificationContentsBookmarkNewSeriesStoryV2";
        };

        return NotificationContentsBookmarkNewSeriesStoryV2;
    })();

    query_recursive_types.SeriesSelectionRequest = (function() {

        /**
         * Properties of a SeriesSelectionRequest.
         * @memberof query_recursive_types
         * @interface ISeriesSelectionRequest
         * @property {google.protobuf.IStringValue|null} [id] SeriesSelectionRequest id
         * @property {query_recursive_types.ISeriesSelectionRequireField|null} [requireSeriesSelection] SeriesSelectionRequest requireSeriesSelection
         * @property {query_series_selection.SeriesSelectionShuffling|null} [applyShuffling] SeriesSelectionRequest applyShuffling
         */

        /**
         * Constructs a new SeriesSelectionRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesSelectionRequest.
         * @implements ISeriesSelectionRequest
         * @constructor
         * @param {query_recursive_types.ISeriesSelectionRequest=} [properties] Properties to set
         */
        function SeriesSelectionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesSelectionRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @instance
         */
        SeriesSelectionRequest.prototype.id = null;

        /**
         * SeriesSelectionRequest requireSeriesSelection.
         * @member {query_recursive_types.ISeriesSelectionRequireField|null|undefined} requireSeriesSelection
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @instance
         */
        SeriesSelectionRequest.prototype.requireSeriesSelection = null;

        /**
         * SeriesSelectionRequest applyShuffling.
         * @member {query_series_selection.SeriesSelectionShuffling} applyShuffling
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @instance
         */
        SeriesSelectionRequest.prototype.applyShuffling = 0;

        /**
         * Creates a new SeriesSelectionRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @static
         * @param {query_recursive_types.ISeriesSelectionRequest=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesSelectionRequest} SeriesSelectionRequest instance
         */
        SeriesSelectionRequest.create = function create(properties) {
            return new SeriesSelectionRequest(properties);
        };

        /**
         * Encodes the specified SeriesSelectionRequest message. Does not implicitly {@link query_recursive_types.SeriesSelectionRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @static
         * @param {query_recursive_types.ISeriesSelectionRequest} message SeriesSelectionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesSelectionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireSeriesSelection != null && Object.hasOwnProperty.call(message, "requireSeriesSelection"))
                $root.query_recursive_types.SeriesSelectionRequireField.encode(message.requireSeriesSelection, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.applyShuffling != null && Object.hasOwnProperty.call(message, "applyShuffling"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.applyShuffling);
            return writer;
        };

        /**
         * Decodes a SeriesSelectionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesSelectionRequest} SeriesSelectionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesSelectionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesSelectionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeriesSelection = $root.query_recursive_types.SeriesSelectionRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.applyShuffling = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesSelectionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesSelectionRequest} SeriesSelectionRequest
         */
        SeriesSelectionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesSelectionRequest)
                return object;
            var message = new $root.query_recursive_types.SeriesSelectionRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireSeriesSelection != null) {
                if (typeof object.requireSeriesSelection !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequest.requireSeriesSelection: object expected");
                message.requireSeriesSelection = $root.query_recursive_types.SeriesSelectionRequireField.fromObject(object.requireSeriesSelection);
            }
            switch (object.applyShuffling) {
            case "SERIES_SELECTION_SHUFFLING_UNSPECIFIED":
            case 0:
                message.applyShuffling = 0;
                break;
            case "SERIES_SELECTION_SHUFFLING_EVERYTIME":
            case 1:
                message.applyShuffling = 1;
                break;
            case "SERIES_SELECTION_SHUFFLING_DAILY":
            case 2:
                message.applyShuffling = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesSelectionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @static
         * @param {query_recursive_types.SeriesSelectionRequest} message SeriesSelectionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesSelectionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireSeriesSelection = null;
                object.applyShuffling = options.enums === String ? "SERIES_SELECTION_SHUFFLING_UNSPECIFIED" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireSeriesSelection != null && message.hasOwnProperty("requireSeriesSelection"))
                object.requireSeriesSelection = $root.query_recursive_types.SeriesSelectionRequireField.toObject(message.requireSeriesSelection, options);
            if (message.applyShuffling != null && message.hasOwnProperty("applyShuffling"))
                object.applyShuffling = options.enums === String ? $root.query_series_selection.SeriesSelectionShuffling[message.applyShuffling] : message.applyShuffling;
            return object;
        };

        /**
         * Converts this SeriesSelectionRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesSelectionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesSelectionRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesSelectionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesSelectionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesSelectionRequest";
        };

        return SeriesSelectionRequest;
    })();

    query_recursive_types.SeriesSelectionRequireField = (function() {

        /**
         * Properties of a SeriesSelectionRequireField.
         * @memberof query_recursive_types
         * @interface ISeriesSelectionRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] SeriesSelectionRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireTitle] SeriesSelectionRequireField requireTitle
         * @property {google.protobuf.IBoolValue|null} [requireDescription] SeriesSelectionRequireField requireDescription
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] SeriesSelectionRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] SeriesSelectionRequireField requireUpdatedAt
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SeriesSelectionRequireField requireSeriesPage
         */

        /**
         * Constructs a new SeriesSelectionRequireField.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesSelectionRequireField.
         * @implements ISeriesSelectionRequireField
         * @constructor
         * @param {query_recursive_types.ISeriesSelectionRequireField=} [properties] Properties to set
         */
        function SeriesSelectionRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesSelectionRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @instance
         */
        SeriesSelectionRequireField.prototype.requireId = null;

        /**
         * SeriesSelectionRequireField requireTitle.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTitle
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @instance
         */
        SeriesSelectionRequireField.prototype.requireTitle = null;

        /**
         * SeriesSelectionRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @instance
         */
        SeriesSelectionRequireField.prototype.requireDescription = null;

        /**
         * SeriesSelectionRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @instance
         */
        SeriesSelectionRequireField.prototype.requireCreatedAt = null;

        /**
         * SeriesSelectionRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @instance
         */
        SeriesSelectionRequireField.prototype.requireUpdatedAt = null;

        /**
         * SeriesSelectionRequireField requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @instance
         */
        SeriesSelectionRequireField.prototype.requireSeriesPage = null;

        /**
         * Creates a new SeriesSelectionRequireField instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @static
         * @param {query_recursive_types.ISeriesSelectionRequireField=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesSelectionRequireField} SeriesSelectionRequireField instance
         */
        SeriesSelectionRequireField.create = function create(properties) {
            return new SeriesSelectionRequireField(properties);
        };

        /**
         * Encodes the specified SeriesSelectionRequireField message. Does not implicitly {@link query_recursive_types.SeriesSelectionRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @static
         * @param {query_recursive_types.ISeriesSelectionRequireField} message SeriesSelectionRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesSelectionRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTitle != null && Object.hasOwnProperty.call(message, "requireTitle"))
                $root.google.protobuf.BoolValue.encode(message.requireTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesSelectionRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesSelectionRequireField} SeriesSelectionRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesSelectionRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesSelectionRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTitle = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesSelectionRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesSelectionRequireField} SeriesSelectionRequireField
         */
        SeriesSelectionRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesSelectionRequireField)
                return object;
            var message = new $root.query_recursive_types.SeriesSelectionRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireTitle != null) {
                if (typeof object.requireTitle !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequireField.requireTitle: object expected");
                message.requireTitle = $root.google.protobuf.BoolValue.fromObject(object.requireTitle);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionRequireField.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesSelectionRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @static
         * @param {query_recursive_types.SeriesSelectionRequireField} message SeriesSelectionRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesSelectionRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireTitle = null;
                object.requireDescription = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
                object.requireSeriesPage = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireTitle != null && message.hasOwnProperty("requireTitle"))
                object.requireTitle = $root.google.protobuf.BoolValue.toObject(message.requireTitle, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            return object;
        };

        /**
         * Converts this SeriesSelectionRequireField to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesSelectionRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesSelectionRequireField
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesSelectionRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesSelectionRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesSelectionRequireField";
        };

        return SeriesSelectionRequireField;
    })();

    query_recursive_types.SeriesSelectionResponse = (function() {

        /**
         * Properties of a SeriesSelectionResponse.
         * @memberof query_recursive_types
         * @interface ISeriesSelectionResponse
         * @property {google.protobuf.IStringValue|null} [id] SeriesSelectionResponse id
         * @property {google.protobuf.IStringValue|null} [title] SeriesSelectionResponse title
         * @property {google.protobuf.IStringValue|null} [description] SeriesSelectionResponse description
         * @property {google.protobuf.ITimestamp|null} [createdAt] SeriesSelectionResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] SeriesSelectionResponse updatedAt
         * @property {query_recursive_types.ISeriesPageResponse|null} [seriesPage] SeriesSelectionResponse seriesPage
         */

        /**
         * Constructs a new SeriesSelectionResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a SeriesSelectionResponse.
         * @implements ISeriesSelectionResponse
         * @constructor
         * @param {query_recursive_types.ISeriesSelectionResponse=} [properties] Properties to set
         */
        function SeriesSelectionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesSelectionResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @instance
         */
        SeriesSelectionResponse.prototype.id = null;

        /**
         * SeriesSelectionResponse title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @instance
         */
        SeriesSelectionResponse.prototype.title = null;

        /**
         * SeriesSelectionResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @instance
         */
        SeriesSelectionResponse.prototype.description = null;

        /**
         * SeriesSelectionResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @instance
         */
        SeriesSelectionResponse.prototype.createdAt = null;

        /**
         * SeriesSelectionResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @instance
         */
        SeriesSelectionResponse.prototype.updatedAt = null;

        /**
         * SeriesSelectionResponse seriesPage.
         * @member {query_recursive_types.ISeriesPageResponse|null|undefined} seriesPage
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @instance
         */
        SeriesSelectionResponse.prototype.seriesPage = null;

        /**
         * Creates a new SeriesSelectionResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @static
         * @param {query_recursive_types.ISeriesSelectionResponse=} [properties] Properties to set
         * @returns {query_recursive_types.SeriesSelectionResponse} SeriesSelectionResponse instance
         */
        SeriesSelectionResponse.create = function create(properties) {
            return new SeriesSelectionResponse(properties);
        };

        /**
         * Encodes the specified SeriesSelectionResponse message. Does not implicitly {@link query_recursive_types.SeriesSelectionResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @static
         * @param {query_recursive_types.ISeriesSelectionResponse} message SeriesSelectionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesSelectionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.seriesPage != null && Object.hasOwnProperty.call(message, "seriesPage"))
                $root.query_recursive_types.SeriesPageResponse.encode(message.seriesPage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesSelectionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.SeriesSelectionResponse} SeriesSelectionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesSelectionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.SeriesSelectionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.seriesPage = $root.query_recursive_types.SeriesPageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesSelectionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.SeriesSelectionResponse} SeriesSelectionResponse
         */
        SeriesSelectionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.SeriesSelectionResponse)
                return object;
            var message = new $root.query_recursive_types.SeriesSelectionResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionResponse.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.seriesPage != null) {
                if (typeof object.seriesPage !== "object")
                    throw TypeError(".query_recursive_types.SeriesSelectionResponse.seriesPage: object expected");
                message.seriesPage = $root.query_recursive_types.SeriesPageResponse.fromObject(object.seriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesSelectionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @static
         * @param {query_recursive_types.SeriesSelectionResponse} message SeriesSelectionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesSelectionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.seriesPage = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.seriesPage != null && message.hasOwnProperty("seriesPage"))
                object.seriesPage = $root.query_recursive_types.SeriesPageResponse.toObject(message.seriesPage, options);
            return object;
        };

        /**
         * Converts this SeriesSelectionResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesSelectionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesSelectionResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.SeriesSelectionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesSelectionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.SeriesSelectionResponse";
        };

        return SeriesSelectionResponse;
    })();

    query_recursive_types.UsedTagListRequest = (function() {

        /**
         * Properties of a UsedTagListRequest.
         * @memberof query_recursive_types
         * @interface IUsedTagListRequest
         * @property {google.protobuf.IInt64Value|null} [limit] UsedTagListRequest limit
         */

        /**
         * Constructs a new UsedTagListRequest.
         * @memberof query_recursive_types
         * @classdesc Represents a UsedTagListRequest.
         * @implements IUsedTagListRequest
         * @constructor
         * @param {query_recursive_types.IUsedTagListRequest=} [properties] Properties to set
         */
        function UsedTagListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UsedTagListRequest limit.
         * @member {google.protobuf.IInt64Value|null|undefined} limit
         * @memberof query_recursive_types.UsedTagListRequest
         * @instance
         */
        UsedTagListRequest.prototype.limit = null;

        /**
         * Creates a new UsedTagListRequest instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UsedTagListRequest
         * @static
         * @param {query_recursive_types.IUsedTagListRequest=} [properties] Properties to set
         * @returns {query_recursive_types.UsedTagListRequest} UsedTagListRequest instance
         */
        UsedTagListRequest.create = function create(properties) {
            return new UsedTagListRequest(properties);
        };

        /**
         * Encodes the specified UsedTagListRequest message. Does not implicitly {@link query_recursive_types.UsedTagListRequest.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UsedTagListRequest
         * @static
         * @param {query_recursive_types.IUsedTagListRequest} message UsedTagListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UsedTagListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int64Value.encode(message.limit, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UsedTagListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UsedTagListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UsedTagListRequest} UsedTagListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UsedTagListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UsedTagListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UsedTagListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UsedTagListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UsedTagListRequest} UsedTagListRequest
         */
        UsedTagListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UsedTagListRequest)
                return object;
            var message = new $root.query_recursive_types.UsedTagListRequest();
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".query_recursive_types.UsedTagListRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int64Value.fromObject(object.limit);
            }
            return message;
        };

        /**
         * Creates a plain object from a UsedTagListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UsedTagListRequest
         * @static
         * @param {query_recursive_types.UsedTagListRequest} message UsedTagListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UsedTagListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.limit = null;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int64Value.toObject(message.limit, options);
            return object;
        };

        /**
         * Converts this UsedTagListRequest to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UsedTagListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UsedTagListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UsedTagListRequest
         * @function getTypeUrl
         * @memberof query_recursive_types.UsedTagListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UsedTagListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UsedTagListRequest";
        };

        return UsedTagListRequest;
    })();

    query_recursive_types.UsedTagListResponse = (function() {

        /**
         * Properties of a UsedTagListResponse.
         * @memberof query_recursive_types
         * @interface IUsedTagListResponse
         * @property {Array.<google.protobuf.IStringValue>|null} [tagList] UsedTagListResponse tagList
         */

        /**
         * Constructs a new UsedTagListResponse.
         * @memberof query_recursive_types
         * @classdesc Represents a UsedTagListResponse.
         * @implements IUsedTagListResponse
         * @constructor
         * @param {query_recursive_types.IUsedTagListResponse=} [properties] Properties to set
         */
        function UsedTagListResponse(properties) {
            this.tagList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UsedTagListResponse tagList.
         * @member {Array.<google.protobuf.IStringValue>} tagList
         * @memberof query_recursive_types.UsedTagListResponse
         * @instance
         */
        UsedTagListResponse.prototype.tagList = $util.emptyArray;

        /**
         * Creates a new UsedTagListResponse instance using the specified properties.
         * @function create
         * @memberof query_recursive_types.UsedTagListResponse
         * @static
         * @param {query_recursive_types.IUsedTagListResponse=} [properties] Properties to set
         * @returns {query_recursive_types.UsedTagListResponse} UsedTagListResponse instance
         */
        UsedTagListResponse.create = function create(properties) {
            return new UsedTagListResponse(properties);
        };

        /**
         * Encodes the specified UsedTagListResponse message. Does not implicitly {@link query_recursive_types.UsedTagListResponse.verify|verify} messages.
         * @function encode
         * @memberof query_recursive_types.UsedTagListResponse
         * @static
         * @param {query_recursive_types.IUsedTagListResponse} message UsedTagListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UsedTagListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tagList != null && message.tagList.length)
                for (var i = 0; i < message.tagList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tagList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UsedTagListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_recursive_types.UsedTagListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_recursive_types.UsedTagListResponse} UsedTagListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UsedTagListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_recursive_types.UsedTagListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.tagList && message.tagList.length))
                        message.tagList = [];
                    message.tagList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UsedTagListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_recursive_types.UsedTagListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_recursive_types.UsedTagListResponse} UsedTagListResponse
         */
        UsedTagListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_recursive_types.UsedTagListResponse)
                return object;
            var message = new $root.query_recursive_types.UsedTagListResponse();
            if (object.tagList) {
                if (!Array.isArray(object.tagList))
                    throw TypeError(".query_recursive_types.UsedTagListResponse.tagList: array expected");
                message.tagList = [];
                for (var i = 0; i < object.tagList.length; ++i) {
                    if (typeof object.tagList[i] !== "object")
                        throw TypeError(".query_recursive_types.UsedTagListResponse.tagList: object expected");
                    message.tagList[i] = $root.google.protobuf.StringValue.fromObject(object.tagList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UsedTagListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_recursive_types.UsedTagListResponse
         * @static
         * @param {query_recursive_types.UsedTagListResponse} message UsedTagListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UsedTagListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tagList = [];
            if (message.tagList && message.tagList.length) {
                object.tagList = [];
                for (var j = 0; j < message.tagList.length; ++j)
                    object.tagList[j] = $root.google.protobuf.StringValue.toObject(message.tagList[j], options);
            }
            return object;
        };

        /**
         * Converts this UsedTagListResponse to JSON.
         * @function toJSON
         * @memberof query_recursive_types.UsedTagListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UsedTagListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UsedTagListResponse
         * @function getTypeUrl
         * @memberof query_recursive_types.UsedTagListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UsedTagListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_recursive_types.UsedTagListResponse";
        };

        return UsedTagListResponse;
    })();

    return query_recursive_types;
})();

$root.query_ticket = (function() {

    /**
     * Namespace query_ticket.
     * @exports query_ticket
     * @namespace
     */
    var query_ticket = {};

    query_ticket.UserTicketStatusRequireField = (function() {

        /**
         * Properties of a UserTicketStatusRequireField.
         * @memberof query_ticket
         * @interface IUserTicketStatusRequireField
         * @property {google.protobuf.IBoolValue|null} [requireAvailableCount] UserTicketStatusRequireField requireAvailableCount
         * @property {query_ticket.ITicketRequireField|null} [requireAvailableTicket] UserTicketStatusRequireField requireAvailableTicket
         */

        /**
         * Constructs a new UserTicketStatusRequireField.
         * @memberof query_ticket
         * @classdesc Represents a UserTicketStatusRequireField.
         * @implements IUserTicketStatusRequireField
         * @constructor
         * @param {query_ticket.IUserTicketStatusRequireField=} [properties] Properties to set
         */
        function UserTicketStatusRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserTicketStatusRequireField requireAvailableCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireAvailableCount
         * @memberof query_ticket.UserTicketStatusRequireField
         * @instance
         */
        UserTicketStatusRequireField.prototype.requireAvailableCount = null;

        /**
         * UserTicketStatusRequireField requireAvailableTicket.
         * @member {query_ticket.ITicketRequireField|null|undefined} requireAvailableTicket
         * @memberof query_ticket.UserTicketStatusRequireField
         * @instance
         */
        UserTicketStatusRequireField.prototype.requireAvailableTicket = null;

        /**
         * Creates a new UserTicketStatusRequireField instance using the specified properties.
         * @function create
         * @memberof query_ticket.UserTicketStatusRequireField
         * @static
         * @param {query_ticket.IUserTicketStatusRequireField=} [properties] Properties to set
         * @returns {query_ticket.UserTicketStatusRequireField} UserTicketStatusRequireField instance
         */
        UserTicketStatusRequireField.create = function create(properties) {
            return new UserTicketStatusRequireField(properties);
        };

        /**
         * Encodes the specified UserTicketStatusRequireField message. Does not implicitly {@link query_ticket.UserTicketStatusRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_ticket.UserTicketStatusRequireField
         * @static
         * @param {query_ticket.IUserTicketStatusRequireField} message UserTicketStatusRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserTicketStatusRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireAvailableCount != null && Object.hasOwnProperty.call(message, "requireAvailableCount"))
                $root.google.protobuf.BoolValue.encode(message.requireAvailableCount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireAvailableTicket != null && Object.hasOwnProperty.call(message, "requireAvailableTicket"))
                $root.query_ticket.TicketRequireField.encode(message.requireAvailableTicket, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserTicketStatusRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_ticket.UserTicketStatusRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_ticket.UserTicketStatusRequireField} UserTicketStatusRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserTicketStatusRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_ticket.UserTicketStatusRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireAvailableCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireAvailableTicket = $root.query_ticket.TicketRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserTicketStatusRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_ticket.UserTicketStatusRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_ticket.UserTicketStatusRequireField} UserTicketStatusRequireField
         */
        UserTicketStatusRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_ticket.UserTicketStatusRequireField)
                return object;
            var message = new $root.query_ticket.UserTicketStatusRequireField();
            if (object.requireAvailableCount != null) {
                if (typeof object.requireAvailableCount !== "object")
                    throw TypeError(".query_ticket.UserTicketStatusRequireField.requireAvailableCount: object expected");
                message.requireAvailableCount = $root.google.protobuf.BoolValue.fromObject(object.requireAvailableCount);
            }
            if (object.requireAvailableTicket != null) {
                if (typeof object.requireAvailableTicket !== "object")
                    throw TypeError(".query_ticket.UserTicketStatusRequireField.requireAvailableTicket: object expected");
                message.requireAvailableTicket = $root.query_ticket.TicketRequireField.fromObject(object.requireAvailableTicket);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserTicketStatusRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_ticket.UserTicketStatusRequireField
         * @static
         * @param {query_ticket.UserTicketStatusRequireField} message UserTicketStatusRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserTicketStatusRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireAvailableCount = null;
                object.requireAvailableTicket = null;
            }
            if (message.requireAvailableCount != null && message.hasOwnProperty("requireAvailableCount"))
                object.requireAvailableCount = $root.google.protobuf.BoolValue.toObject(message.requireAvailableCount, options);
            if (message.requireAvailableTicket != null && message.hasOwnProperty("requireAvailableTicket"))
                object.requireAvailableTicket = $root.query_ticket.TicketRequireField.toObject(message.requireAvailableTicket, options);
            return object;
        };

        /**
         * Converts this UserTicketStatusRequireField to JSON.
         * @function toJSON
         * @memberof query_ticket.UserTicketStatusRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserTicketStatusRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserTicketStatusRequireField
         * @function getTypeUrl
         * @memberof query_ticket.UserTicketStatusRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserTicketStatusRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_ticket.UserTicketStatusRequireField";
        };

        return UserTicketStatusRequireField;
    })();

    query_ticket.UserTicketStatusResponse = (function() {

        /**
         * Properties of a UserTicketStatusResponse.
         * @memberof query_ticket
         * @interface IUserTicketStatusResponse
         * @property {google.protobuf.IInt32Value|null} [availableCount] UserTicketStatusResponse availableCount
         * @property {query_ticket.ITicketResponse|null} [availableTicket] UserTicketStatusResponse availableTicket
         */

        /**
         * Constructs a new UserTicketStatusResponse.
         * @memberof query_ticket
         * @classdesc Represents a UserTicketStatusResponse.
         * @implements IUserTicketStatusResponse
         * @constructor
         * @param {query_ticket.IUserTicketStatusResponse=} [properties] Properties to set
         */
        function UserTicketStatusResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserTicketStatusResponse availableCount.
         * @member {google.protobuf.IInt32Value|null|undefined} availableCount
         * @memberof query_ticket.UserTicketStatusResponse
         * @instance
         */
        UserTicketStatusResponse.prototype.availableCount = null;

        /**
         * UserTicketStatusResponse availableTicket.
         * @member {query_ticket.ITicketResponse|null|undefined} availableTicket
         * @memberof query_ticket.UserTicketStatusResponse
         * @instance
         */
        UserTicketStatusResponse.prototype.availableTicket = null;

        /**
         * Creates a new UserTicketStatusResponse instance using the specified properties.
         * @function create
         * @memberof query_ticket.UserTicketStatusResponse
         * @static
         * @param {query_ticket.IUserTicketStatusResponse=} [properties] Properties to set
         * @returns {query_ticket.UserTicketStatusResponse} UserTicketStatusResponse instance
         */
        UserTicketStatusResponse.create = function create(properties) {
            return new UserTicketStatusResponse(properties);
        };

        /**
         * Encodes the specified UserTicketStatusResponse message. Does not implicitly {@link query_ticket.UserTicketStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof query_ticket.UserTicketStatusResponse
         * @static
         * @param {query_ticket.IUserTicketStatusResponse} message UserTicketStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserTicketStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.availableCount != null && Object.hasOwnProperty.call(message, "availableCount"))
                $root.google.protobuf.Int32Value.encode(message.availableCount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.availableTicket != null && Object.hasOwnProperty.call(message, "availableTicket"))
                $root.query_ticket.TicketResponse.encode(message.availableTicket, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserTicketStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_ticket.UserTicketStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_ticket.UserTicketStatusResponse} UserTicketStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserTicketStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_ticket.UserTicketStatusResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.availableCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.availableTicket = $root.query_ticket.TicketResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserTicketStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_ticket.UserTicketStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_ticket.UserTicketStatusResponse} UserTicketStatusResponse
         */
        UserTicketStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_ticket.UserTicketStatusResponse)
                return object;
            var message = new $root.query_ticket.UserTicketStatusResponse();
            if (object.availableCount != null) {
                if (typeof object.availableCount !== "object")
                    throw TypeError(".query_ticket.UserTicketStatusResponse.availableCount: object expected");
                message.availableCount = $root.google.protobuf.Int32Value.fromObject(object.availableCount);
            }
            if (object.availableTicket != null) {
                if (typeof object.availableTicket !== "object")
                    throw TypeError(".query_ticket.UserTicketStatusResponse.availableTicket: object expected");
                message.availableTicket = $root.query_ticket.TicketResponse.fromObject(object.availableTicket);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserTicketStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_ticket.UserTicketStatusResponse
         * @static
         * @param {query_ticket.UserTicketStatusResponse} message UserTicketStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserTicketStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.availableCount = null;
                object.availableTicket = null;
            }
            if (message.availableCount != null && message.hasOwnProperty("availableCount"))
                object.availableCount = $root.google.protobuf.Int32Value.toObject(message.availableCount, options);
            if (message.availableTicket != null && message.hasOwnProperty("availableTicket"))
                object.availableTicket = $root.query_ticket.TicketResponse.toObject(message.availableTicket, options);
            return object;
        };

        /**
         * Converts this UserTicketStatusResponse to JSON.
         * @function toJSON
         * @memberof query_ticket.UserTicketStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserTicketStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserTicketStatusResponse
         * @function getTypeUrl
         * @memberof query_ticket.UserTicketStatusResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserTicketStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_ticket.UserTicketStatusResponse";
        };

        return UserTicketStatusResponse;
    })();

    query_ticket.TicketRequireField = (function() {

        /**
         * Properties of a TicketRequireField.
         * @memberof query_ticket
         * @interface ITicketRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] TicketRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] TicketRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireExpiresAt] TicketRequireField requireExpiresAt
         */

        /**
         * Constructs a new TicketRequireField.
         * @memberof query_ticket
         * @classdesc Represents a TicketRequireField.
         * @implements ITicketRequireField
         * @constructor
         * @param {query_ticket.ITicketRequireField=} [properties] Properties to set
         */
        function TicketRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TicketRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_ticket.TicketRequireField
         * @instance
         */
        TicketRequireField.prototype.requireId = null;

        /**
         * TicketRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_ticket.TicketRequireField
         * @instance
         */
        TicketRequireField.prototype.requireCreatedAt = null;

        /**
         * TicketRequireField requireExpiresAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireExpiresAt
         * @memberof query_ticket.TicketRequireField
         * @instance
         */
        TicketRequireField.prototype.requireExpiresAt = null;

        /**
         * Creates a new TicketRequireField instance using the specified properties.
         * @function create
         * @memberof query_ticket.TicketRequireField
         * @static
         * @param {query_ticket.ITicketRequireField=} [properties] Properties to set
         * @returns {query_ticket.TicketRequireField} TicketRequireField instance
         */
        TicketRequireField.create = function create(properties) {
            return new TicketRequireField(properties);
        };

        /**
         * Encodes the specified TicketRequireField message. Does not implicitly {@link query_ticket.TicketRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_ticket.TicketRequireField
         * @static
         * @param {query_ticket.ITicketRequireField} message TicketRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireExpiresAt != null && Object.hasOwnProperty.call(message, "requireExpiresAt"))
                $root.google.protobuf.BoolValue.encode(message.requireExpiresAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TicketRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_ticket.TicketRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_ticket.TicketRequireField} TicketRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_ticket.TicketRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireExpiresAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TicketRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_ticket.TicketRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_ticket.TicketRequireField} TicketRequireField
         */
        TicketRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_ticket.TicketRequireField)
                return object;
            var message = new $root.query_ticket.TicketRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_ticket.TicketRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_ticket.TicketRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireExpiresAt != null) {
                if (typeof object.requireExpiresAt !== "object")
                    throw TypeError(".query_ticket.TicketRequireField.requireExpiresAt: object expected");
                message.requireExpiresAt = $root.google.protobuf.BoolValue.fromObject(object.requireExpiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a TicketRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_ticket.TicketRequireField
         * @static
         * @param {query_ticket.TicketRequireField} message TicketRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireCreatedAt = null;
                object.requireExpiresAt = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireExpiresAt != null && message.hasOwnProperty("requireExpiresAt"))
                object.requireExpiresAt = $root.google.protobuf.BoolValue.toObject(message.requireExpiresAt, options);
            return object;
        };

        /**
         * Converts this TicketRequireField to JSON.
         * @function toJSON
         * @memberof query_ticket.TicketRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TicketRequireField
         * @function getTypeUrl
         * @memberof query_ticket.TicketRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TicketRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_ticket.TicketRequireField";
        };

        return TicketRequireField;
    })();

    query_ticket.TicketResponse = (function() {

        /**
         * Properties of a TicketResponse.
         * @memberof query_ticket
         * @interface ITicketResponse
         * @property {google.protobuf.IStringValue|null} [id] TicketResponse id
         * @property {google.protobuf.ITimestamp|null} [createdAt] TicketResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [expiresAt] TicketResponse expiresAt
         */

        /**
         * Constructs a new TicketResponse.
         * @memberof query_ticket
         * @classdesc Represents a TicketResponse.
         * @implements ITicketResponse
         * @constructor
         * @param {query_ticket.ITicketResponse=} [properties] Properties to set
         */
        function TicketResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TicketResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_ticket.TicketResponse
         * @instance
         */
        TicketResponse.prototype.id = null;

        /**
         * TicketResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_ticket.TicketResponse
         * @instance
         */
        TicketResponse.prototype.createdAt = null;

        /**
         * TicketResponse expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof query_ticket.TicketResponse
         * @instance
         */
        TicketResponse.prototype.expiresAt = null;

        /**
         * Creates a new TicketResponse instance using the specified properties.
         * @function create
         * @memberof query_ticket.TicketResponse
         * @static
         * @param {query_ticket.ITicketResponse=} [properties] Properties to set
         * @returns {query_ticket.TicketResponse} TicketResponse instance
         */
        TicketResponse.create = function create(properties) {
            return new TicketResponse(properties);
        };

        /**
         * Encodes the specified TicketResponse message. Does not implicitly {@link query_ticket.TicketResponse.verify|verify} messages.
         * @function encode
         * @memberof query_ticket.TicketResponse
         * @static
         * @param {query_ticket.ITicketResponse} message TicketResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TicketResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_ticket.TicketResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_ticket.TicketResponse} TicketResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_ticket.TicketResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TicketResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_ticket.TicketResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_ticket.TicketResponse} TicketResponse
         */
        TicketResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_ticket.TicketResponse)
                return object;
            var message = new $root.query_ticket.TicketResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_ticket.TicketResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_ticket.TicketResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".query_ticket.TicketResponse.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a TicketResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_ticket.TicketResponse
         * @static
         * @param {query_ticket.TicketResponse} message TicketResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.createdAt = null;
                object.expiresAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            return object;
        };

        /**
         * Converts this TicketResponse to JSON.
         * @function toJSON
         * @memberof query_ticket.TicketResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TicketResponse
         * @function getTypeUrl
         * @memberof query_ticket.TicketResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TicketResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_ticket.TicketResponse";
        };

        return TicketResponse;
    })();

    return query_ticket;
})();

$root.query_bonus_ticket = (function() {

    /**
     * Namespace query_bonus_ticket.
     * @exports query_bonus_ticket
     * @namespace
     */
    var query_bonus_ticket = {};

    query_bonus_ticket.FixedBonusTicketStatus = (function() {

        /**
         * Properties of a FixedBonusTicketStatus.
         * @memberof query_bonus_ticket
         * @interface IFixedBonusTicketStatus
         * @property {google.protobuf.IInt32Value|null} [availableCount] FixedBonusTicketStatus availableCount
         * @property {google.protobuf.IInt32Value|null} [amount] FixedBonusTicketStatus amount
         * @property {google.protobuf.ITimestamp|null} [nextGainableAt] FixedBonusTicketStatus nextGainableAt
         */

        /**
         * Constructs a new FixedBonusTicketStatus.
         * @memberof query_bonus_ticket
         * @classdesc Represents a FixedBonusTicketStatus.
         * @implements IFixedBonusTicketStatus
         * @constructor
         * @param {query_bonus_ticket.IFixedBonusTicketStatus=} [properties] Properties to set
         */
        function FixedBonusTicketStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FixedBonusTicketStatus availableCount.
         * @member {google.protobuf.IInt32Value|null|undefined} availableCount
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @instance
         */
        FixedBonusTicketStatus.prototype.availableCount = null;

        /**
         * FixedBonusTicketStatus amount.
         * @member {google.protobuf.IInt32Value|null|undefined} amount
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @instance
         */
        FixedBonusTicketStatus.prototype.amount = null;

        /**
         * FixedBonusTicketStatus nextGainableAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextGainableAt
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @instance
         */
        FixedBonusTicketStatus.prototype.nextGainableAt = null;

        /**
         * Creates a new FixedBonusTicketStatus instance using the specified properties.
         * @function create
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.IFixedBonusTicketStatus=} [properties] Properties to set
         * @returns {query_bonus_ticket.FixedBonusTicketStatus} FixedBonusTicketStatus instance
         */
        FixedBonusTicketStatus.create = function create(properties) {
            return new FixedBonusTicketStatus(properties);
        };

        /**
         * Encodes the specified FixedBonusTicketStatus message. Does not implicitly {@link query_bonus_ticket.FixedBonusTicketStatus.verify|verify} messages.
         * @function encode
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.IFixedBonusTicketStatus} message FixedBonusTicketStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FixedBonusTicketStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.availableCount != null && Object.hasOwnProperty.call(message, "availableCount"))
                $root.google.protobuf.Int32Value.encode(message.availableCount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                $root.google.protobuf.Int32Value.encode(message.amount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.nextGainableAt != null && Object.hasOwnProperty.call(message, "nextGainableAt"))
                $root.google.protobuf.Timestamp.encode(message.nextGainableAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FixedBonusTicketStatus message from the specified reader or buffer.
         * @function decode
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_bonus_ticket.FixedBonusTicketStatus} FixedBonusTicketStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FixedBonusTicketStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_bonus_ticket.FixedBonusTicketStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.availableCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.amount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.nextGainableAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a FixedBonusTicketStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_bonus_ticket.FixedBonusTicketStatus} FixedBonusTicketStatus
         */
        FixedBonusTicketStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.query_bonus_ticket.FixedBonusTicketStatus)
                return object;
            var message = new $root.query_bonus_ticket.FixedBonusTicketStatus();
            if (object.availableCount != null) {
                if (typeof object.availableCount !== "object")
                    throw TypeError(".query_bonus_ticket.FixedBonusTicketStatus.availableCount: object expected");
                message.availableCount = $root.google.protobuf.Int32Value.fromObject(object.availableCount);
            }
            if (object.amount != null) {
                if (typeof object.amount !== "object")
                    throw TypeError(".query_bonus_ticket.FixedBonusTicketStatus.amount: object expected");
                message.amount = $root.google.protobuf.Int32Value.fromObject(object.amount);
            }
            if (object.nextGainableAt != null) {
                if (typeof object.nextGainableAt !== "object")
                    throw TypeError(".query_bonus_ticket.FixedBonusTicketStatus.nextGainableAt: object expected");
                message.nextGainableAt = $root.google.protobuf.Timestamp.fromObject(object.nextGainableAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a FixedBonusTicketStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.FixedBonusTicketStatus} message FixedBonusTicketStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FixedBonusTicketStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.availableCount = null;
                object.amount = null;
                object.nextGainableAt = null;
            }
            if (message.availableCount != null && message.hasOwnProperty("availableCount"))
                object.availableCount = $root.google.protobuf.Int32Value.toObject(message.availableCount, options);
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = $root.google.protobuf.Int32Value.toObject(message.amount, options);
            if (message.nextGainableAt != null && message.hasOwnProperty("nextGainableAt"))
                object.nextGainableAt = $root.google.protobuf.Timestamp.toObject(message.nextGainableAt, options);
            return object;
        };

        /**
         * Converts this FixedBonusTicketStatus to JSON.
         * @function toJSON
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FixedBonusTicketStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FixedBonusTicketStatus
         * @function getTypeUrl
         * @memberof query_bonus_ticket.FixedBonusTicketStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FixedBonusTicketStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_bonus_ticket.FixedBonusTicketStatus";
        };

        return FixedBonusTicketStatus;
    })();

    query_bonus_ticket.StepBonusTicketStatus = (function() {

        /**
         * Properties of a StepBonusTicketStatus.
         * @memberof query_bonus_ticket
         * @interface IStepBonusTicketStatus
         * @property {google.protobuf.IInt32Value|null} [availableCount] StepBonusTicketStatus availableCount
         * @property {google.protobuf.ITimestamp|null} [nextGainableAt] StepBonusTicketStatus nextGainableAt
         * @property {google.protobuf.IInt32Value|null} [currentIndex] StepBonusTicketStatus currentIndex
         * @property {Array.<query_bonus_ticket.IStepBonusTicketItem>|null} [stepItemList] StepBonusTicketStatus stepItemList
         */

        /**
         * Constructs a new StepBonusTicketStatus.
         * @memberof query_bonus_ticket
         * @classdesc Represents a StepBonusTicketStatus.
         * @implements IStepBonusTicketStatus
         * @constructor
         * @param {query_bonus_ticket.IStepBonusTicketStatus=} [properties] Properties to set
         */
        function StepBonusTicketStatus(properties) {
            this.stepItemList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StepBonusTicketStatus availableCount.
         * @member {google.protobuf.IInt32Value|null|undefined} availableCount
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @instance
         */
        StepBonusTicketStatus.prototype.availableCount = null;

        /**
         * StepBonusTicketStatus nextGainableAt.
         * @member {google.protobuf.ITimestamp|null|undefined} nextGainableAt
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @instance
         */
        StepBonusTicketStatus.prototype.nextGainableAt = null;

        /**
         * StepBonusTicketStatus currentIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} currentIndex
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @instance
         */
        StepBonusTicketStatus.prototype.currentIndex = null;

        /**
         * StepBonusTicketStatus stepItemList.
         * @member {Array.<query_bonus_ticket.IStepBonusTicketItem>} stepItemList
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @instance
         */
        StepBonusTicketStatus.prototype.stepItemList = $util.emptyArray;

        /**
         * Creates a new StepBonusTicketStatus instance using the specified properties.
         * @function create
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.IStepBonusTicketStatus=} [properties] Properties to set
         * @returns {query_bonus_ticket.StepBonusTicketStatus} StepBonusTicketStatus instance
         */
        StepBonusTicketStatus.create = function create(properties) {
            return new StepBonusTicketStatus(properties);
        };

        /**
         * Encodes the specified StepBonusTicketStatus message. Does not implicitly {@link query_bonus_ticket.StepBonusTicketStatus.verify|verify} messages.
         * @function encode
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.IStepBonusTicketStatus} message StepBonusTicketStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StepBonusTicketStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.availableCount != null && Object.hasOwnProperty.call(message, "availableCount"))
                $root.google.protobuf.Int32Value.encode(message.availableCount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.nextGainableAt != null && Object.hasOwnProperty.call(message, "nextGainableAt"))
                $root.google.protobuf.Timestamp.encode(message.nextGainableAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.currentIndex != null && Object.hasOwnProperty.call(message, "currentIndex"))
                $root.google.protobuf.Int32Value.encode(message.currentIndex, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.stepItemList != null && message.stepItemList.length)
                for (var i = 0; i < message.stepItemList.length; ++i)
                    $root.query_bonus_ticket.StepBonusTicketItem.encode(message.stepItemList[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StepBonusTicketStatus message from the specified reader or buffer.
         * @function decode
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_bonus_ticket.StepBonusTicketStatus} StepBonusTicketStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StepBonusTicketStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_bonus_ticket.StepBonusTicketStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.availableCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.nextGainableAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.currentIndex = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.stepItemList && message.stepItemList.length))
                        message.stepItemList = [];
                    message.stepItemList.push($root.query_bonus_ticket.StepBonusTicketItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StepBonusTicketStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_bonus_ticket.StepBonusTicketStatus} StepBonusTicketStatus
         */
        StepBonusTicketStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.query_bonus_ticket.StepBonusTicketStatus)
                return object;
            var message = new $root.query_bonus_ticket.StepBonusTicketStatus();
            if (object.availableCount != null) {
                if (typeof object.availableCount !== "object")
                    throw TypeError(".query_bonus_ticket.StepBonusTicketStatus.availableCount: object expected");
                message.availableCount = $root.google.protobuf.Int32Value.fromObject(object.availableCount);
            }
            if (object.nextGainableAt != null) {
                if (typeof object.nextGainableAt !== "object")
                    throw TypeError(".query_bonus_ticket.StepBonusTicketStatus.nextGainableAt: object expected");
                message.nextGainableAt = $root.google.protobuf.Timestamp.fromObject(object.nextGainableAt);
            }
            if (object.currentIndex != null) {
                if (typeof object.currentIndex !== "object")
                    throw TypeError(".query_bonus_ticket.StepBonusTicketStatus.currentIndex: object expected");
                message.currentIndex = $root.google.protobuf.Int32Value.fromObject(object.currentIndex);
            }
            if (object.stepItemList) {
                if (!Array.isArray(object.stepItemList))
                    throw TypeError(".query_bonus_ticket.StepBonusTicketStatus.stepItemList: array expected");
                message.stepItemList = [];
                for (var i = 0; i < object.stepItemList.length; ++i) {
                    if (typeof object.stepItemList[i] !== "object")
                        throw TypeError(".query_bonus_ticket.StepBonusTicketStatus.stepItemList: object expected");
                    message.stepItemList[i] = $root.query_bonus_ticket.StepBonusTicketItem.fromObject(object.stepItemList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StepBonusTicketStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.StepBonusTicketStatus} message StepBonusTicketStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StepBonusTicketStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.stepItemList = [];
            if (options.defaults) {
                object.availableCount = null;
                object.nextGainableAt = null;
                object.currentIndex = null;
            }
            if (message.availableCount != null && message.hasOwnProperty("availableCount"))
                object.availableCount = $root.google.protobuf.Int32Value.toObject(message.availableCount, options);
            if (message.nextGainableAt != null && message.hasOwnProperty("nextGainableAt"))
                object.nextGainableAt = $root.google.protobuf.Timestamp.toObject(message.nextGainableAt, options);
            if (message.currentIndex != null && message.hasOwnProperty("currentIndex"))
                object.currentIndex = $root.google.protobuf.Int32Value.toObject(message.currentIndex, options);
            if (message.stepItemList && message.stepItemList.length) {
                object.stepItemList = [];
                for (var j = 0; j < message.stepItemList.length; ++j)
                    object.stepItemList[j] = $root.query_bonus_ticket.StepBonusTicketItem.toObject(message.stepItemList[j], options);
            }
            return object;
        };

        /**
         * Converts this StepBonusTicketStatus to JSON.
         * @function toJSON
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StepBonusTicketStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StepBonusTicketStatus
         * @function getTypeUrl
         * @memberof query_bonus_ticket.StepBonusTicketStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StepBonusTicketStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_bonus_ticket.StepBonusTicketStatus";
        };

        return StepBonusTicketStatus;
    })();

    query_bonus_ticket.StepBonusTicketItem = (function() {

        /**
         * Properties of a StepBonusTicketItem.
         * @memberof query_bonus_ticket
         * @interface IStepBonusTicketItem
         * @property {google.protobuf.IInt32Value|null} [amount] StepBonusTicketItem amount
         * @property {google.protobuf.IStringValue|null} [imageUrl] StepBonusTicketItem imageUrl
         */

        /**
         * Constructs a new StepBonusTicketItem.
         * @memberof query_bonus_ticket
         * @classdesc Represents a StepBonusTicketItem.
         * @implements IStepBonusTicketItem
         * @constructor
         * @param {query_bonus_ticket.IStepBonusTicketItem=} [properties] Properties to set
         */
        function StepBonusTicketItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StepBonusTicketItem amount.
         * @member {google.protobuf.IInt32Value|null|undefined} amount
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @instance
         */
        StepBonusTicketItem.prototype.amount = null;

        /**
         * StepBonusTicketItem imageUrl.
         * @member {google.protobuf.IStringValue|null|undefined} imageUrl
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @instance
         */
        StepBonusTicketItem.prototype.imageUrl = null;

        /**
         * Creates a new StepBonusTicketItem instance using the specified properties.
         * @function create
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @static
         * @param {query_bonus_ticket.IStepBonusTicketItem=} [properties] Properties to set
         * @returns {query_bonus_ticket.StepBonusTicketItem} StepBonusTicketItem instance
         */
        StepBonusTicketItem.create = function create(properties) {
            return new StepBonusTicketItem(properties);
        };

        /**
         * Encodes the specified StepBonusTicketItem message. Does not implicitly {@link query_bonus_ticket.StepBonusTicketItem.verify|verify} messages.
         * @function encode
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @static
         * @param {query_bonus_ticket.IStepBonusTicketItem} message StepBonusTicketItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StepBonusTicketItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                $root.google.protobuf.Int32Value.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                $root.google.protobuf.StringValue.encode(message.imageUrl, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StepBonusTicketItem message from the specified reader or buffer.
         * @function decode
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_bonus_ticket.StepBonusTicketItem} StepBonusTicketItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StepBonusTicketItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_bonus_ticket.StepBonusTicketItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.amount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.imageUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StepBonusTicketItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_bonus_ticket.StepBonusTicketItem} StepBonusTicketItem
         */
        StepBonusTicketItem.fromObject = function fromObject(object) {
            if (object instanceof $root.query_bonus_ticket.StepBonusTicketItem)
                return object;
            var message = new $root.query_bonus_ticket.StepBonusTicketItem();
            if (object.amount != null) {
                if (typeof object.amount !== "object")
                    throw TypeError(".query_bonus_ticket.StepBonusTicketItem.amount: object expected");
                message.amount = $root.google.protobuf.Int32Value.fromObject(object.amount);
            }
            if (object.imageUrl != null) {
                if (typeof object.imageUrl !== "object")
                    throw TypeError(".query_bonus_ticket.StepBonusTicketItem.imageUrl: object expected");
                message.imageUrl = $root.google.protobuf.StringValue.fromObject(object.imageUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a StepBonusTicketItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @static
         * @param {query_bonus_ticket.StepBonusTicketItem} message StepBonusTicketItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StepBonusTicketItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.amount = null;
                object.imageUrl = null;
            }
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = $root.google.protobuf.Int32Value.toObject(message.amount, options);
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                object.imageUrl = $root.google.protobuf.StringValue.toObject(message.imageUrl, options);
            return object;
        };

        /**
         * Converts this StepBonusTicketItem to JSON.
         * @function toJSON
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StepBonusTicketItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StepBonusTicketItem
         * @function getTypeUrl
         * @memberof query_bonus_ticket.StepBonusTicketItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StepBonusTicketItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_bonus_ticket.StepBonusTicketItem";
        };

        return StepBonusTicketItem;
    })();

    query_bonus_ticket.TimeLimitedBonusTicketStatus = (function() {

        /**
         * Properties of a TimeLimitedBonusTicketStatus.
         * @memberof query_bonus_ticket
         * @interface ITimeLimitedBonusTicketStatus
         * @property {google.protobuf.IInt32Value|null} [availableCount] TimeLimitedBonusTicketStatus availableCount
         * @property {google.protobuf.ITimestamp|null} [expiresAt] TimeLimitedBonusTicketStatus expiresAt
         */

        /**
         * Constructs a new TimeLimitedBonusTicketStatus.
         * @memberof query_bonus_ticket
         * @classdesc Represents a TimeLimitedBonusTicketStatus.
         * @implements ITimeLimitedBonusTicketStatus
         * @constructor
         * @param {query_bonus_ticket.ITimeLimitedBonusTicketStatus=} [properties] Properties to set
         */
        function TimeLimitedBonusTicketStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeLimitedBonusTicketStatus availableCount.
         * @member {google.protobuf.IInt32Value|null|undefined} availableCount
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @instance
         */
        TimeLimitedBonusTicketStatus.prototype.availableCount = null;

        /**
         * TimeLimitedBonusTicketStatus expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @instance
         */
        TimeLimitedBonusTicketStatus.prototype.expiresAt = null;

        /**
         * Creates a new TimeLimitedBonusTicketStatus instance using the specified properties.
         * @function create
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.ITimeLimitedBonusTicketStatus=} [properties] Properties to set
         * @returns {query_bonus_ticket.TimeLimitedBonusTicketStatus} TimeLimitedBonusTicketStatus instance
         */
        TimeLimitedBonusTicketStatus.create = function create(properties) {
            return new TimeLimitedBonusTicketStatus(properties);
        };

        /**
         * Encodes the specified TimeLimitedBonusTicketStatus message. Does not implicitly {@link query_bonus_ticket.TimeLimitedBonusTicketStatus.verify|verify} messages.
         * @function encode
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.ITimeLimitedBonusTicketStatus} message TimeLimitedBonusTicketStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeLimitedBonusTicketStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.availableCount != null && Object.hasOwnProperty.call(message, "availableCount"))
                $root.google.protobuf.Int32Value.encode(message.availableCount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TimeLimitedBonusTicketStatus message from the specified reader or buffer.
         * @function decode
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_bonus_ticket.TimeLimitedBonusTicketStatus} TimeLimitedBonusTicketStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeLimitedBonusTicketStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_bonus_ticket.TimeLimitedBonusTicketStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.availableCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TimeLimitedBonusTicketStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_bonus_ticket.TimeLimitedBonusTicketStatus} TimeLimitedBonusTicketStatus
         */
        TimeLimitedBonusTicketStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.query_bonus_ticket.TimeLimitedBonusTicketStatus)
                return object;
            var message = new $root.query_bonus_ticket.TimeLimitedBonusTicketStatus();
            if (object.availableCount != null) {
                if (typeof object.availableCount !== "object")
                    throw TypeError(".query_bonus_ticket.TimeLimitedBonusTicketStatus.availableCount: object expected");
                message.availableCount = $root.google.protobuf.Int32Value.fromObject(object.availableCount);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".query_bonus_ticket.TimeLimitedBonusTicketStatus.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a TimeLimitedBonusTicketStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @static
         * @param {query_bonus_ticket.TimeLimitedBonusTicketStatus} message TimeLimitedBonusTicketStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeLimitedBonusTicketStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.availableCount = null;
                object.expiresAt = null;
            }
            if (message.availableCount != null && message.hasOwnProperty("availableCount"))
                object.availableCount = $root.google.protobuf.Int32Value.toObject(message.availableCount, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            return object;
        };

        /**
         * Converts this TimeLimitedBonusTicketStatus to JSON.
         * @function toJSON
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeLimitedBonusTicketStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TimeLimitedBonusTicketStatus
         * @function getTypeUrl
         * @memberof query_bonus_ticket.TimeLimitedBonusTicketStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimeLimitedBonusTicketStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_bonus_ticket.TimeLimitedBonusTicketStatus";
        };

        return TimeLimitedBonusTicketStatus;
    })();

    query_bonus_ticket.BonusTicketAvailabilityRequireField = (function() {

        /**
         * Properties of a BonusTicketAvailabilityRequireField.
         * @memberof query_bonus_ticket
         * @interface IBonusTicketAvailabilityRequireField
         * @property {google.protobuf.IBoolValue|null} [requireSharing] BonusTicketAvailabilityRequireField requireSharing
         * @property {google.protobuf.IBoolValue|null} [requireAdvertisement] BonusTicketAvailabilityRequireField requireAdvertisement
         * @property {google.protobuf.IBoolValue|null} [requireLogin] BonusTicketAvailabilityRequireField requireLogin
         * @property {google.protobuf.IBoolValue|null} [requireReview] BonusTicketAvailabilityRequireField requireReview
         * @property {google.protobuf.IBoolValue|null} [requireTimeLimited] BonusTicketAvailabilityRequireField requireTimeLimited
         */

        /**
         * Constructs a new BonusTicketAvailabilityRequireField.
         * @memberof query_bonus_ticket
         * @classdesc Represents a BonusTicketAvailabilityRequireField.
         * @implements IBonusTicketAvailabilityRequireField
         * @constructor
         * @param {query_bonus_ticket.IBonusTicketAvailabilityRequireField=} [properties] Properties to set
         */
        function BonusTicketAvailabilityRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BonusTicketAvailabilityRequireField requireSharing.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSharing
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @instance
         */
        BonusTicketAvailabilityRequireField.prototype.requireSharing = null;

        /**
         * BonusTicketAvailabilityRequireField requireAdvertisement.
         * @member {google.protobuf.IBoolValue|null|undefined} requireAdvertisement
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @instance
         */
        BonusTicketAvailabilityRequireField.prototype.requireAdvertisement = null;

        /**
         * BonusTicketAvailabilityRequireField requireLogin.
         * @member {google.protobuf.IBoolValue|null|undefined} requireLogin
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @instance
         */
        BonusTicketAvailabilityRequireField.prototype.requireLogin = null;

        /**
         * BonusTicketAvailabilityRequireField requireReview.
         * @member {google.protobuf.IBoolValue|null|undefined} requireReview
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @instance
         */
        BonusTicketAvailabilityRequireField.prototype.requireReview = null;

        /**
         * BonusTicketAvailabilityRequireField requireTimeLimited.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTimeLimited
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @instance
         */
        BonusTicketAvailabilityRequireField.prototype.requireTimeLimited = null;

        /**
         * Creates a new BonusTicketAvailabilityRequireField instance using the specified properties.
         * @function create
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @static
         * @param {query_bonus_ticket.IBonusTicketAvailabilityRequireField=} [properties] Properties to set
         * @returns {query_bonus_ticket.BonusTicketAvailabilityRequireField} BonusTicketAvailabilityRequireField instance
         */
        BonusTicketAvailabilityRequireField.create = function create(properties) {
            return new BonusTicketAvailabilityRequireField(properties);
        };

        /**
         * Encodes the specified BonusTicketAvailabilityRequireField message. Does not implicitly {@link query_bonus_ticket.BonusTicketAvailabilityRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @static
         * @param {query_bonus_ticket.IBonusTicketAvailabilityRequireField} message BonusTicketAvailabilityRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BonusTicketAvailabilityRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSharing != null && Object.hasOwnProperty.call(message, "requireSharing"))
                $root.google.protobuf.BoolValue.encode(message.requireSharing, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireAdvertisement != null && Object.hasOwnProperty.call(message, "requireAdvertisement"))
                $root.google.protobuf.BoolValue.encode(message.requireAdvertisement, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireLogin != null && Object.hasOwnProperty.call(message, "requireLogin"))
                $root.google.protobuf.BoolValue.encode(message.requireLogin, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireReview != null && Object.hasOwnProperty.call(message, "requireReview"))
                $root.google.protobuf.BoolValue.encode(message.requireReview, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireTimeLimited != null && Object.hasOwnProperty.call(message, "requireTimeLimited"))
                $root.google.protobuf.BoolValue.encode(message.requireTimeLimited, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BonusTicketAvailabilityRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_bonus_ticket.BonusTicketAvailabilityRequireField} BonusTicketAvailabilityRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BonusTicketAvailabilityRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_bonus_ticket.BonusTicketAvailabilityRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSharing = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireAdvertisement = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireLogin = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireReview = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireTimeLimited = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a BonusTicketAvailabilityRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_bonus_ticket.BonusTicketAvailabilityRequireField} BonusTicketAvailabilityRequireField
         */
        BonusTicketAvailabilityRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_bonus_ticket.BonusTicketAvailabilityRequireField)
                return object;
            var message = new $root.query_bonus_ticket.BonusTicketAvailabilityRequireField();
            if (object.requireSharing != null) {
                if (typeof object.requireSharing !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityRequireField.requireSharing: object expected");
                message.requireSharing = $root.google.protobuf.BoolValue.fromObject(object.requireSharing);
            }
            if (object.requireAdvertisement != null) {
                if (typeof object.requireAdvertisement !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityRequireField.requireAdvertisement: object expected");
                message.requireAdvertisement = $root.google.protobuf.BoolValue.fromObject(object.requireAdvertisement);
            }
            if (object.requireLogin != null) {
                if (typeof object.requireLogin !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityRequireField.requireLogin: object expected");
                message.requireLogin = $root.google.protobuf.BoolValue.fromObject(object.requireLogin);
            }
            if (object.requireReview != null) {
                if (typeof object.requireReview !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityRequireField.requireReview: object expected");
                message.requireReview = $root.google.protobuf.BoolValue.fromObject(object.requireReview);
            }
            if (object.requireTimeLimited != null) {
                if (typeof object.requireTimeLimited !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityRequireField.requireTimeLimited: object expected");
                message.requireTimeLimited = $root.google.protobuf.BoolValue.fromObject(object.requireTimeLimited);
            }
            return message;
        };

        /**
         * Creates a plain object from a BonusTicketAvailabilityRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @static
         * @param {query_bonus_ticket.BonusTicketAvailabilityRequireField} message BonusTicketAvailabilityRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BonusTicketAvailabilityRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireSharing = null;
                object.requireAdvertisement = null;
                object.requireLogin = null;
                object.requireReview = null;
                object.requireTimeLimited = null;
            }
            if (message.requireSharing != null && message.hasOwnProperty("requireSharing"))
                object.requireSharing = $root.google.protobuf.BoolValue.toObject(message.requireSharing, options);
            if (message.requireAdvertisement != null && message.hasOwnProperty("requireAdvertisement"))
                object.requireAdvertisement = $root.google.protobuf.BoolValue.toObject(message.requireAdvertisement, options);
            if (message.requireLogin != null && message.hasOwnProperty("requireLogin"))
                object.requireLogin = $root.google.protobuf.BoolValue.toObject(message.requireLogin, options);
            if (message.requireReview != null && message.hasOwnProperty("requireReview"))
                object.requireReview = $root.google.protobuf.BoolValue.toObject(message.requireReview, options);
            if (message.requireTimeLimited != null && message.hasOwnProperty("requireTimeLimited"))
                object.requireTimeLimited = $root.google.protobuf.BoolValue.toObject(message.requireTimeLimited, options);
            return object;
        };

        /**
         * Converts this BonusTicketAvailabilityRequireField to JSON.
         * @function toJSON
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BonusTicketAvailabilityRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BonusTicketAvailabilityRequireField
         * @function getTypeUrl
         * @memberof query_bonus_ticket.BonusTicketAvailabilityRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BonusTicketAvailabilityRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_bonus_ticket.BonusTicketAvailabilityRequireField";
        };

        return BonusTicketAvailabilityRequireField;
    })();

    query_bonus_ticket.BonusTicketAvailabilityResponse = (function() {

        /**
         * Properties of a BonusTicketAvailabilityResponse.
         * @memberof query_bonus_ticket
         * @interface IBonusTicketAvailabilityResponse
         * @property {query_bonus_ticket.IFixedBonusTicketStatus|null} [sharing] BonusTicketAvailabilityResponse sharing
         * @property {query_bonus_ticket.IFixedBonusTicketStatus|null} [advertisement] BonusTicketAvailabilityResponse advertisement
         * @property {query_bonus_ticket.IStepBonusTicketStatus|null} [login] BonusTicketAvailabilityResponse login
         * @property {query_bonus_ticket.IFixedBonusTicketStatus|null} [review] BonusTicketAvailabilityResponse review
         * @property {query_bonus_ticket.ITimeLimitedBonusTicketStatus|null} [timeLimited] BonusTicketAvailabilityResponse timeLimited
         */

        /**
         * Constructs a new BonusTicketAvailabilityResponse.
         * @memberof query_bonus_ticket
         * @classdesc Represents a BonusTicketAvailabilityResponse.
         * @implements IBonusTicketAvailabilityResponse
         * @constructor
         * @param {query_bonus_ticket.IBonusTicketAvailabilityResponse=} [properties] Properties to set
         */
        function BonusTicketAvailabilityResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BonusTicketAvailabilityResponse sharing.
         * @member {query_bonus_ticket.IFixedBonusTicketStatus|null|undefined} sharing
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @instance
         */
        BonusTicketAvailabilityResponse.prototype.sharing = null;

        /**
         * BonusTicketAvailabilityResponse advertisement.
         * @member {query_bonus_ticket.IFixedBonusTicketStatus|null|undefined} advertisement
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @instance
         */
        BonusTicketAvailabilityResponse.prototype.advertisement = null;

        /**
         * BonusTicketAvailabilityResponse login.
         * @member {query_bonus_ticket.IStepBonusTicketStatus|null|undefined} login
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @instance
         */
        BonusTicketAvailabilityResponse.prototype.login = null;

        /**
         * BonusTicketAvailabilityResponse review.
         * @member {query_bonus_ticket.IFixedBonusTicketStatus|null|undefined} review
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @instance
         */
        BonusTicketAvailabilityResponse.prototype.review = null;

        /**
         * BonusTicketAvailabilityResponse timeLimited.
         * @member {query_bonus_ticket.ITimeLimitedBonusTicketStatus|null|undefined} timeLimited
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @instance
         */
        BonusTicketAvailabilityResponse.prototype.timeLimited = null;

        /**
         * Creates a new BonusTicketAvailabilityResponse instance using the specified properties.
         * @function create
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @static
         * @param {query_bonus_ticket.IBonusTicketAvailabilityResponse=} [properties] Properties to set
         * @returns {query_bonus_ticket.BonusTicketAvailabilityResponse} BonusTicketAvailabilityResponse instance
         */
        BonusTicketAvailabilityResponse.create = function create(properties) {
            return new BonusTicketAvailabilityResponse(properties);
        };

        /**
         * Encodes the specified BonusTicketAvailabilityResponse message. Does not implicitly {@link query_bonus_ticket.BonusTicketAvailabilityResponse.verify|verify} messages.
         * @function encode
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @static
         * @param {query_bonus_ticket.IBonusTicketAvailabilityResponse} message BonusTicketAvailabilityResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BonusTicketAvailabilityResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sharing != null && Object.hasOwnProperty.call(message, "sharing"))
                $root.query_bonus_ticket.FixedBonusTicketStatus.encode(message.sharing, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.advertisement != null && Object.hasOwnProperty.call(message, "advertisement"))
                $root.query_bonus_ticket.FixedBonusTicketStatus.encode(message.advertisement, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                $root.query_bonus_ticket.StepBonusTicketStatus.encode(message.login, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.review != null && Object.hasOwnProperty.call(message, "review"))
                $root.query_bonus_ticket.FixedBonusTicketStatus.encode(message.review, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.timeLimited != null && Object.hasOwnProperty.call(message, "timeLimited"))
                $root.query_bonus_ticket.TimeLimitedBonusTicketStatus.encode(message.timeLimited, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BonusTicketAvailabilityResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_bonus_ticket.BonusTicketAvailabilityResponse} BonusTicketAvailabilityResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BonusTicketAvailabilityResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_bonus_ticket.BonusTicketAvailabilityResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sharing = $root.query_bonus_ticket.FixedBonusTicketStatus.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.advertisement = $root.query_bonus_ticket.FixedBonusTicketStatus.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.login = $root.query_bonus_ticket.StepBonusTicketStatus.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.review = $root.query_bonus_ticket.FixedBonusTicketStatus.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.timeLimited = $root.query_bonus_ticket.TimeLimitedBonusTicketStatus.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a BonusTicketAvailabilityResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_bonus_ticket.BonusTicketAvailabilityResponse} BonusTicketAvailabilityResponse
         */
        BonusTicketAvailabilityResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_bonus_ticket.BonusTicketAvailabilityResponse)
                return object;
            var message = new $root.query_bonus_ticket.BonusTicketAvailabilityResponse();
            if (object.sharing != null) {
                if (typeof object.sharing !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityResponse.sharing: object expected");
                message.sharing = $root.query_bonus_ticket.FixedBonusTicketStatus.fromObject(object.sharing);
            }
            if (object.advertisement != null) {
                if (typeof object.advertisement !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityResponse.advertisement: object expected");
                message.advertisement = $root.query_bonus_ticket.FixedBonusTicketStatus.fromObject(object.advertisement);
            }
            if (object.login != null) {
                if (typeof object.login !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityResponse.login: object expected");
                message.login = $root.query_bonus_ticket.StepBonusTicketStatus.fromObject(object.login);
            }
            if (object.review != null) {
                if (typeof object.review !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityResponse.review: object expected");
                message.review = $root.query_bonus_ticket.FixedBonusTicketStatus.fromObject(object.review);
            }
            if (object.timeLimited != null) {
                if (typeof object.timeLimited !== "object")
                    throw TypeError(".query_bonus_ticket.BonusTicketAvailabilityResponse.timeLimited: object expected");
                message.timeLimited = $root.query_bonus_ticket.TimeLimitedBonusTicketStatus.fromObject(object.timeLimited);
            }
            return message;
        };

        /**
         * Creates a plain object from a BonusTicketAvailabilityResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @static
         * @param {query_bonus_ticket.BonusTicketAvailabilityResponse} message BonusTicketAvailabilityResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BonusTicketAvailabilityResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.sharing = null;
                object.advertisement = null;
                object.login = null;
                object.review = null;
                object.timeLimited = null;
            }
            if (message.sharing != null && message.hasOwnProperty("sharing"))
                object.sharing = $root.query_bonus_ticket.FixedBonusTicketStatus.toObject(message.sharing, options);
            if (message.advertisement != null && message.hasOwnProperty("advertisement"))
                object.advertisement = $root.query_bonus_ticket.FixedBonusTicketStatus.toObject(message.advertisement, options);
            if (message.login != null && message.hasOwnProperty("login"))
                object.login = $root.query_bonus_ticket.StepBonusTicketStatus.toObject(message.login, options);
            if (message.review != null && message.hasOwnProperty("review"))
                object.review = $root.query_bonus_ticket.FixedBonusTicketStatus.toObject(message.review, options);
            if (message.timeLimited != null && message.hasOwnProperty("timeLimited"))
                object.timeLimited = $root.query_bonus_ticket.TimeLimitedBonusTicketStatus.toObject(message.timeLimited, options);
            return object;
        };

        /**
         * Converts this BonusTicketAvailabilityResponse to JSON.
         * @function toJSON
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BonusTicketAvailabilityResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BonusTicketAvailabilityResponse
         * @function getTypeUrl
         * @memberof query_bonus_ticket.BonusTicketAvailabilityResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BonusTicketAvailabilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_bonus_ticket.BonusTicketAvailabilityResponse";
        };

        return BonusTicketAvailabilityResponse;
    })();

    return query_bonus_ticket;
})();

$root.query_search_types = (function() {

    /**
     * Namespace query_search_types.
     * @exports query_search_types
     * @namespace
     */
    var query_search_types = {};

    query_search_types.SearchStoryFilter = (function() {

        /**
         * Properties of a SearchStoryFilter.
         * @memberof query_search_types
         * @interface ISearchStoryFilter
         * @property {Array.<google.protobuf.IStringValue>|null} [filterTag] SearchStoryFilter filterTag
         * @property {Array.<google.protobuf.IStringValue>|null} [filterAnyUserId] SearchStoryFilter filterAnyUserId
         * @property {google.protobuf.IBoolValue|null} [excludeAlreadyRead] SearchStoryFilter excludeAlreadyRead
         * @property {google.protobuf.IStringValue|null} [filterSeriesId] SearchStoryFilter filterSeriesId
         * @property {google.protobuf.IInt32Value|null} [filterSeriesIndex] SearchStoryFilter filterSeriesIndex
         * @property {google.protobuf.IBoolValue|null} [includeBlocked] SearchStoryFilter includeBlocked
         * @property {google.protobuf.IBoolValue|null} [excludeOfficial] SearchStoryFilter excludeOfficial
         * @property {google.protobuf.IBoolValue|null} [excludeNotOfficial] SearchStoryFilter excludeNotOfficial
         * @property {google.protobuf.IBoolValue|null} [excludeCompletedSeries] SearchStoryFilter excludeCompletedSeries
         * @property {google.protobuf.IBoolValue|null} [excludeNotCompletedSeries] SearchStoryFilter excludeNotCompletedSeries
         * @property {google.protobuf.IBoolValue|null} [excludeSeries] SearchStoryFilter excludeSeries
         * @property {google.protobuf.IBoolValue|null} [excludeNotSeries] SearchStoryFilter excludeNotSeries
         * @property {Array.<query_types.StoryAvailability>|null} [filterAnyAvailability] SearchStoryFilter filterAnyAvailability
         * @property {types.ITimestampRange|null} [filterPublishedAt] SearchStoryFilter filterPublishedAt
         * @property {Array.<query_types.StoryScriptType>|null} [filterAnyScriptType] SearchStoryFilter filterAnyScriptType
         * @property {google.protobuf.IBoolValue|null} [excludeFirstPublished] SearchStoryFilter excludeFirstPublished
         * @property {google.protobuf.IBoolValue|null} [excludeNotFirstPublished] SearchStoryFilter excludeNotFirstPublished
         * @property {google.protobuf.IBoolValue|null} [includeSensitive] SearchStoryFilter includeSensitive
         * @property {Array.<story.StoryStatus>|null} [filterAnyStatus] SearchStoryFilter filterAnyStatus
         * @property {Array.<query_types.UserRole>|null} [filterAnyUserRole] SearchStoryFilter filterAnyUserRole
         * @property {google.protobuf.IBoolValue|null} [includeHidden] SearchStoryFilter includeHidden
         */

        /**
         * Constructs a new SearchStoryFilter.
         * @memberof query_search_types
         * @classdesc Represents a SearchStoryFilter.
         * @implements ISearchStoryFilter
         * @constructor
         * @param {query_search_types.ISearchStoryFilter=} [properties] Properties to set
         */
        function SearchStoryFilter(properties) {
            this.filterTag = [];
            this.filterAnyUserId = [];
            this.filterAnyAvailability = [];
            this.filterAnyScriptType = [];
            this.filterAnyStatus = [];
            this.filterAnyUserRole = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryFilter filterTag.
         * @member {Array.<google.protobuf.IStringValue>} filterTag
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterTag = $util.emptyArray;

        /**
         * SearchStoryFilter filterAnyUserId.
         * @member {Array.<google.protobuf.IStringValue>} filterAnyUserId
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterAnyUserId = $util.emptyArray;

        /**
         * SearchStoryFilter excludeAlreadyRead.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeAlreadyRead
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeAlreadyRead = null;

        /**
         * SearchStoryFilter filterSeriesId.
         * @member {google.protobuf.IStringValue|null|undefined} filterSeriesId
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterSeriesId = null;

        /**
         * SearchStoryFilter filterSeriesIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} filterSeriesIndex
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterSeriesIndex = null;

        /**
         * SearchStoryFilter includeBlocked.
         * @member {google.protobuf.IBoolValue|null|undefined} includeBlocked
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.includeBlocked = null;

        /**
         * SearchStoryFilter excludeOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeOfficial
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeOfficial = null;

        /**
         * SearchStoryFilter excludeNotOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotOfficial
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeNotOfficial = null;

        /**
         * SearchStoryFilter excludeCompletedSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeCompletedSeries
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeCompletedSeries = null;

        /**
         * SearchStoryFilter excludeNotCompletedSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotCompletedSeries
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeNotCompletedSeries = null;

        /**
         * SearchStoryFilter excludeSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeSeries
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeSeries = null;

        /**
         * SearchStoryFilter excludeNotSeries.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotSeries
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeNotSeries = null;

        /**
         * SearchStoryFilter filterAnyAvailability.
         * @member {Array.<query_types.StoryAvailability>} filterAnyAvailability
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterAnyAvailability = $util.emptyArray;

        /**
         * SearchStoryFilter filterPublishedAt.
         * @member {types.ITimestampRange|null|undefined} filterPublishedAt
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterPublishedAt = null;

        /**
         * SearchStoryFilter filterAnyScriptType.
         * @member {Array.<query_types.StoryScriptType>} filterAnyScriptType
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterAnyScriptType = $util.emptyArray;

        /**
         * SearchStoryFilter excludeFirstPublished.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeFirstPublished
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeFirstPublished = null;

        /**
         * SearchStoryFilter excludeNotFirstPublished.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotFirstPublished
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.excludeNotFirstPublished = null;

        /**
         * SearchStoryFilter includeSensitive.
         * @member {google.protobuf.IBoolValue|null|undefined} includeSensitive
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.includeSensitive = null;

        /**
         * SearchStoryFilter filterAnyStatus.
         * @member {Array.<story.StoryStatus>} filterAnyStatus
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterAnyStatus = $util.emptyArray;

        /**
         * SearchStoryFilter filterAnyUserRole.
         * @member {Array.<query_types.UserRole>} filterAnyUserRole
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.filterAnyUserRole = $util.emptyArray;

        /**
         * SearchStoryFilter includeHidden.
         * @member {google.protobuf.IBoolValue|null|undefined} includeHidden
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         */
        SearchStoryFilter.prototype.includeHidden = null;

        /**
         * Creates a new SearchStoryFilter instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchStoryFilter
         * @static
         * @param {query_search_types.ISearchStoryFilter=} [properties] Properties to set
         * @returns {query_search_types.SearchStoryFilter} SearchStoryFilter instance
         */
        SearchStoryFilter.create = function create(properties) {
            return new SearchStoryFilter(properties);
        };

        /**
         * Encodes the specified SearchStoryFilter message. Does not implicitly {@link query_search_types.SearchStoryFilter.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchStoryFilter
         * @static
         * @param {query_search_types.ISearchStoryFilter} message SearchStoryFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterTag != null && message.filterTag.length)
                for (var i = 0; i < message.filterTag.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.filterTag[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.filterAnyUserId != null && message.filterAnyUserId.length)
                for (var i = 0; i < message.filterAnyUserId.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.filterAnyUserId[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.excludeAlreadyRead != null && Object.hasOwnProperty.call(message, "excludeAlreadyRead"))
                $root.google.protobuf.BoolValue.encode(message.excludeAlreadyRead, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.filterSeriesId != null && Object.hasOwnProperty.call(message, "filterSeriesId"))
                $root.google.protobuf.StringValue.encode(message.filterSeriesId, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.filterSeriesIndex != null && Object.hasOwnProperty.call(message, "filterSeriesIndex"))
                $root.google.protobuf.Int32Value.encode(message.filterSeriesIndex, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.includeBlocked != null && Object.hasOwnProperty.call(message, "includeBlocked"))
                $root.google.protobuf.BoolValue.encode(message.includeBlocked, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.excludeOfficial != null && Object.hasOwnProperty.call(message, "excludeOfficial"))
                $root.google.protobuf.BoolValue.encode(message.excludeOfficial, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.excludeNotOfficial != null && Object.hasOwnProperty.call(message, "excludeNotOfficial"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotOfficial, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.excludeCompletedSeries != null && Object.hasOwnProperty.call(message, "excludeCompletedSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeCompletedSeries, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.excludeNotCompletedSeries != null && Object.hasOwnProperty.call(message, "excludeNotCompletedSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotCompletedSeries, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.excludeSeries != null && Object.hasOwnProperty.call(message, "excludeSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeSeries, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.excludeNotSeries != null && Object.hasOwnProperty.call(message, "excludeNotSeries"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotSeries, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.filterAnyAvailability != null && message.filterAnyAvailability.length) {
                writer.uint32(/* id 13, wireType 2 =*/106).fork();
                for (var i = 0; i < message.filterAnyAvailability.length; ++i)
                    writer.int32(message.filterAnyAvailability[i]);
                writer.ldelim();
            }
            if (message.filterAnyStatus != null && message.filterAnyStatus.length) {
                writer.uint32(/* id 14, wireType 2 =*/114).fork();
                for (var i = 0; i < message.filterAnyStatus.length; ++i)
                    writer.int32(message.filterAnyStatus[i]);
                writer.ldelim();
            }
            if (message.filterAnyUserRole != null && message.filterAnyUserRole.length) {
                writer.uint32(/* id 15, wireType 2 =*/122).fork();
                for (var i = 0; i < message.filterAnyUserRole.length; ++i)
                    writer.int32(message.filterAnyUserRole[i]);
                writer.ldelim();
            }
            if (message.includeHidden != null && Object.hasOwnProperty.call(message, "includeHidden"))
                $root.google.protobuf.BoolValue.encode(message.includeHidden, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.filterPublishedAt != null && Object.hasOwnProperty.call(message, "filterPublishedAt"))
                $root.types.TimestampRange.encode(message.filterPublishedAt, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.filterAnyScriptType != null && message.filterAnyScriptType.length) {
                writer.uint32(/* id 18, wireType 2 =*/146).fork();
                for (var i = 0; i < message.filterAnyScriptType.length; ++i)
                    writer.int32(message.filterAnyScriptType[i]);
                writer.ldelim();
            }
            if (message.excludeFirstPublished != null && Object.hasOwnProperty.call(message, "excludeFirstPublished"))
                $root.google.protobuf.BoolValue.encode(message.excludeFirstPublished, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.excludeNotFirstPublished != null && Object.hasOwnProperty.call(message, "excludeNotFirstPublished"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotFirstPublished, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.includeSensitive != null && Object.hasOwnProperty.call(message, "includeSensitive"))
                $root.google.protobuf.BoolValue.encode(message.includeSensitive, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchStoryFilter message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchStoryFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchStoryFilter} SearchStoryFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchStoryFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.filterTag && message.filterTag.length))
                        message.filterTag = [];
                    message.filterTag.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.filterAnyUserId && message.filterAnyUserId.length))
                        message.filterAnyUserId = [];
                    message.filterAnyUserId.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.excludeAlreadyRead = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.filterSeriesId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.filterSeriesIndex = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.includeBlocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.excludeOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.excludeNotOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.excludeCompletedSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.excludeNotCompletedSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.excludeSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.excludeNotSeries = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    if (!(message.filterAnyAvailability && message.filterAnyAvailability.length))
                        message.filterAnyAvailability = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyAvailability.push(reader.int32());
                    } else
                        message.filterAnyAvailability.push(reader.int32());
                    break;
                case 17:
                    message.filterPublishedAt = $root.types.TimestampRange.decode(reader, reader.uint32());
                    break;
                case 18:
                    if (!(message.filterAnyScriptType && message.filterAnyScriptType.length))
                        message.filterAnyScriptType = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyScriptType.push(reader.int32());
                    } else
                        message.filterAnyScriptType.push(reader.int32());
                    break;
                case 19:
                    message.excludeFirstPublished = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.excludeNotFirstPublished = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.includeSensitive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 14:
                    if (!(message.filterAnyStatus && message.filterAnyStatus.length))
                        message.filterAnyStatus = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyStatus.push(reader.int32());
                    } else
                        message.filterAnyStatus.push(reader.int32());
                    break;
                case 15:
                    if (!(message.filterAnyUserRole && message.filterAnyUserRole.length))
                        message.filterAnyUserRole = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyUserRole.push(reader.int32());
                    } else
                        message.filterAnyUserRole.push(reader.int32());
                    break;
                case 16:
                    message.includeHidden = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchStoryFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchStoryFilter} SearchStoryFilter
         */
        SearchStoryFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchStoryFilter)
                return object;
            var message = new $root.query_search_types.SearchStoryFilter();
            if (object.filterTag) {
                if (!Array.isArray(object.filterTag))
                    throw TypeError(".query_search_types.SearchStoryFilter.filterTag: array expected");
                message.filterTag = [];
                for (var i = 0; i < object.filterTag.length; ++i) {
                    if (typeof object.filterTag[i] !== "object")
                        throw TypeError(".query_search_types.SearchStoryFilter.filterTag: object expected");
                    message.filterTag[i] = $root.google.protobuf.StringValue.fromObject(object.filterTag[i]);
                }
            }
            if (object.filterAnyUserId) {
                if (!Array.isArray(object.filterAnyUserId))
                    throw TypeError(".query_search_types.SearchStoryFilter.filterAnyUserId: array expected");
                message.filterAnyUserId = [];
                for (var i = 0; i < object.filterAnyUserId.length; ++i) {
                    if (typeof object.filterAnyUserId[i] !== "object")
                        throw TypeError(".query_search_types.SearchStoryFilter.filterAnyUserId: object expected");
                    message.filterAnyUserId[i] = $root.google.protobuf.StringValue.fromObject(object.filterAnyUserId[i]);
                }
            }
            if (object.excludeAlreadyRead != null) {
                if (typeof object.excludeAlreadyRead !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeAlreadyRead: object expected");
                message.excludeAlreadyRead = $root.google.protobuf.BoolValue.fromObject(object.excludeAlreadyRead);
            }
            if (object.filterSeriesId != null) {
                if (typeof object.filterSeriesId !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.filterSeriesId: object expected");
                message.filterSeriesId = $root.google.protobuf.StringValue.fromObject(object.filterSeriesId);
            }
            if (object.filterSeriesIndex != null) {
                if (typeof object.filterSeriesIndex !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.filterSeriesIndex: object expected");
                message.filterSeriesIndex = $root.google.protobuf.Int32Value.fromObject(object.filterSeriesIndex);
            }
            if (object.includeBlocked != null) {
                if (typeof object.includeBlocked !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.includeBlocked: object expected");
                message.includeBlocked = $root.google.protobuf.BoolValue.fromObject(object.includeBlocked);
            }
            if (object.excludeOfficial != null) {
                if (typeof object.excludeOfficial !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeOfficial: object expected");
                message.excludeOfficial = $root.google.protobuf.BoolValue.fromObject(object.excludeOfficial);
            }
            if (object.excludeNotOfficial != null) {
                if (typeof object.excludeNotOfficial !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeNotOfficial: object expected");
                message.excludeNotOfficial = $root.google.protobuf.BoolValue.fromObject(object.excludeNotOfficial);
            }
            if (object.excludeCompletedSeries != null) {
                if (typeof object.excludeCompletedSeries !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeCompletedSeries: object expected");
                message.excludeCompletedSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeCompletedSeries);
            }
            if (object.excludeNotCompletedSeries != null) {
                if (typeof object.excludeNotCompletedSeries !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeNotCompletedSeries: object expected");
                message.excludeNotCompletedSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeNotCompletedSeries);
            }
            if (object.excludeSeries != null) {
                if (typeof object.excludeSeries !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeSeries: object expected");
                message.excludeSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeSeries);
            }
            if (object.excludeNotSeries != null) {
                if (typeof object.excludeNotSeries !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeNotSeries: object expected");
                message.excludeNotSeries = $root.google.protobuf.BoolValue.fromObject(object.excludeNotSeries);
            }
            if (object.filterAnyAvailability) {
                if (!Array.isArray(object.filterAnyAvailability))
                    throw TypeError(".query_search_types.SearchStoryFilter.filterAnyAvailability: array expected");
                message.filterAnyAvailability = [];
                for (var i = 0; i < object.filterAnyAvailability.length; ++i)
                    switch (object.filterAnyAvailability[i]) {
                    default:
                    case "STORY_AVAILABILITY_UNSPECIFIED":
                    case 0:
                        message.filterAnyAvailability[i] = 0;
                        break;
                    case "STORY_AVAILABILITY_REQUIRE_VIP":
                    case 1:
                        message.filterAnyAvailability[i] = 1;
                        break;
                    case "STORY_AVAILABILITY_REQUIRE_WAIT":
                    case 2:
                        message.filterAnyAvailability[i] = 2;
                        break;
                    case "STORY_AVAILABILITY_REQUIRE_NONE":
                    case 3:
                        message.filterAnyAvailability[i] = 3;
                        break;
                    case "STORY_AVAILABILITY_REQUIRE_FOLLOWING":
                    case 4:
                        message.filterAnyAvailability[i] = 4;
                        break;
                    case "STORY_AVAILABILITY_REQUIRE_FOLLOWED":
                    case 5:
                        message.filterAnyAvailability[i] = 5;
                        break;
                    case "STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING":
                    case 6:
                        message.filterAnyAvailability[i] = 6;
                        break;
                    }
            }
            if (object.filterPublishedAt != null) {
                if (typeof object.filterPublishedAt !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.filterPublishedAt: object expected");
                message.filterPublishedAt = $root.types.TimestampRange.fromObject(object.filterPublishedAt);
            }
            if (object.filterAnyScriptType) {
                if (!Array.isArray(object.filterAnyScriptType))
                    throw TypeError(".query_search_types.SearchStoryFilter.filterAnyScriptType: array expected");
                message.filterAnyScriptType = [];
                for (var i = 0; i < object.filterAnyScriptType.length; ++i)
                    switch (object.filterAnyScriptType[i]) {
                    default:
                    case "STORY_SCRIPT_TYPE_UNSPECIFIED":
                    case 0:
                        message.filterAnyScriptType[i] = 0;
                        break;
                    case "STORY_SCRIPT_TYPE_CHAT_NOVEL":
                    case 1:
                        message.filterAnyScriptType[i] = 1;
                        break;
                    case "STORY_SCRIPT_TYPE_NOVEL":
                    case 2:
                        message.filterAnyScriptType[i] = 2;
                        break;
                    case "STORY_SCRIPT_TYPE_VIDEO":
                    case 3:
                        message.filterAnyScriptType[i] = 3;
                        break;
                    case "STORY_SCRIPT_TYPE_AUDIO":
                    case 4:
                        message.filterAnyScriptType[i] = 4;
                        break;
                    }
            }
            if (object.excludeFirstPublished != null) {
                if (typeof object.excludeFirstPublished !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeFirstPublished: object expected");
                message.excludeFirstPublished = $root.google.protobuf.BoolValue.fromObject(object.excludeFirstPublished);
            }
            if (object.excludeNotFirstPublished != null) {
                if (typeof object.excludeNotFirstPublished !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.excludeNotFirstPublished: object expected");
                message.excludeNotFirstPublished = $root.google.protobuf.BoolValue.fromObject(object.excludeNotFirstPublished);
            }
            if (object.includeSensitive != null) {
                if (typeof object.includeSensitive !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.includeSensitive: object expected");
                message.includeSensitive = $root.google.protobuf.BoolValue.fromObject(object.includeSensitive);
            }
            if (object.filterAnyStatus) {
                if (!Array.isArray(object.filterAnyStatus))
                    throw TypeError(".query_search_types.SearchStoryFilter.filterAnyStatus: array expected");
                message.filterAnyStatus = [];
                for (var i = 0; i < object.filterAnyStatus.length; ++i)
                    switch (object.filterAnyStatus[i]) {
                    default:
                    case "STORY_STATUS_UNKNOWN":
                    case 0:
                        message.filterAnyStatus[i] = 0;
                        break;
                    case "DRAFT":
                    case 1:
                        message.filterAnyStatus[i] = 1;
                        break;
                    case "RESERVE":
                    case 2:
                        message.filterAnyStatus[i] = 2;
                        break;
                    case "PUBLISH":
                    case 3:
                        message.filterAnyStatus[i] = 3;
                        break;
                    case "REJECT":
                    case 4:
                        message.filterAnyStatus[i] = 4;
                        break;
                    }
            }
            if (object.filterAnyUserRole) {
                if (!Array.isArray(object.filterAnyUserRole))
                    throw TypeError(".query_search_types.SearchStoryFilter.filterAnyUserRole: array expected");
                message.filterAnyUserRole = [];
                for (var i = 0; i < object.filterAnyUserRole.length; ++i)
                    switch (object.filterAnyUserRole[i]) {
                    default:
                    case "USER_ROLE_UNSPECIFIED":
                    case 0:
                        message.filterAnyUserRole[i] = 0;
                        break;
                    case "USER_ROLE_PLAIN":
                    case 1:
                        message.filterAnyUserRole[i] = 1;
                        break;
                    case "USER_ROLE_OFFICIAL_WRITER":
                    case 2:
                        message.filterAnyUserRole[i] = 2;
                        break;
                    case "USER_ROLE_BANNED":
                    case 3:
                        message.filterAnyUserRole[i] = 3;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 4:
                        message.filterAnyUserRole[i] = 4;
                        break;
                    }
            }
            if (object.includeHidden != null) {
                if (typeof object.includeHidden !== "object")
                    throw TypeError(".query_search_types.SearchStoryFilter.includeHidden: object expected");
                message.includeHidden = $root.google.protobuf.BoolValue.fromObject(object.includeHidden);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchStoryFilter
         * @static
         * @param {query_search_types.SearchStoryFilter} message SearchStoryFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.filterTag = [];
                object.filterAnyUserId = [];
                object.filterAnyAvailability = [];
                object.filterAnyStatus = [];
                object.filterAnyUserRole = [];
                object.filterAnyScriptType = [];
            }
            if (options.defaults) {
                object.excludeAlreadyRead = null;
                object.filterSeriesId = null;
                object.filterSeriesIndex = null;
                object.includeBlocked = null;
                object.excludeOfficial = null;
                object.excludeNotOfficial = null;
                object.excludeCompletedSeries = null;
                object.excludeNotCompletedSeries = null;
                object.excludeSeries = null;
                object.excludeNotSeries = null;
                object.includeHidden = null;
                object.filterPublishedAt = null;
                object.excludeFirstPublished = null;
                object.excludeNotFirstPublished = null;
                object.includeSensitive = null;
            }
            if (message.filterTag && message.filterTag.length) {
                object.filterTag = [];
                for (var j = 0; j < message.filterTag.length; ++j)
                    object.filterTag[j] = $root.google.protobuf.StringValue.toObject(message.filterTag[j], options);
            }
            if (message.filterAnyUserId && message.filterAnyUserId.length) {
                object.filterAnyUserId = [];
                for (var j = 0; j < message.filterAnyUserId.length; ++j)
                    object.filterAnyUserId[j] = $root.google.protobuf.StringValue.toObject(message.filterAnyUserId[j], options);
            }
            if (message.excludeAlreadyRead != null && message.hasOwnProperty("excludeAlreadyRead"))
                object.excludeAlreadyRead = $root.google.protobuf.BoolValue.toObject(message.excludeAlreadyRead, options);
            if (message.filterSeriesId != null && message.hasOwnProperty("filterSeriesId"))
                object.filterSeriesId = $root.google.protobuf.StringValue.toObject(message.filterSeriesId, options);
            if (message.filterSeriesIndex != null && message.hasOwnProperty("filterSeriesIndex"))
                object.filterSeriesIndex = $root.google.protobuf.Int32Value.toObject(message.filterSeriesIndex, options);
            if (message.includeBlocked != null && message.hasOwnProperty("includeBlocked"))
                object.includeBlocked = $root.google.protobuf.BoolValue.toObject(message.includeBlocked, options);
            if (message.excludeOfficial != null && message.hasOwnProperty("excludeOfficial"))
                object.excludeOfficial = $root.google.protobuf.BoolValue.toObject(message.excludeOfficial, options);
            if (message.excludeNotOfficial != null && message.hasOwnProperty("excludeNotOfficial"))
                object.excludeNotOfficial = $root.google.protobuf.BoolValue.toObject(message.excludeNotOfficial, options);
            if (message.excludeCompletedSeries != null && message.hasOwnProperty("excludeCompletedSeries"))
                object.excludeCompletedSeries = $root.google.protobuf.BoolValue.toObject(message.excludeCompletedSeries, options);
            if (message.excludeNotCompletedSeries != null && message.hasOwnProperty("excludeNotCompletedSeries"))
                object.excludeNotCompletedSeries = $root.google.protobuf.BoolValue.toObject(message.excludeNotCompletedSeries, options);
            if (message.excludeSeries != null && message.hasOwnProperty("excludeSeries"))
                object.excludeSeries = $root.google.protobuf.BoolValue.toObject(message.excludeSeries, options);
            if (message.excludeNotSeries != null && message.hasOwnProperty("excludeNotSeries"))
                object.excludeNotSeries = $root.google.protobuf.BoolValue.toObject(message.excludeNotSeries, options);
            if (message.filterAnyAvailability && message.filterAnyAvailability.length) {
                object.filterAnyAvailability = [];
                for (var j = 0; j < message.filterAnyAvailability.length; ++j)
                    object.filterAnyAvailability[j] = options.enums === String ? $root.query_types.StoryAvailability[message.filterAnyAvailability[j]] : message.filterAnyAvailability[j];
            }
            if (message.filterAnyStatus && message.filterAnyStatus.length) {
                object.filterAnyStatus = [];
                for (var j = 0; j < message.filterAnyStatus.length; ++j)
                    object.filterAnyStatus[j] = options.enums === String ? $root.story.StoryStatus[message.filterAnyStatus[j]] : message.filterAnyStatus[j];
            }
            if (message.filterAnyUserRole && message.filterAnyUserRole.length) {
                object.filterAnyUserRole = [];
                for (var j = 0; j < message.filterAnyUserRole.length; ++j)
                    object.filterAnyUserRole[j] = options.enums === String ? $root.query_types.UserRole[message.filterAnyUserRole[j]] : message.filterAnyUserRole[j];
            }
            if (message.includeHidden != null && message.hasOwnProperty("includeHidden"))
                object.includeHidden = $root.google.protobuf.BoolValue.toObject(message.includeHidden, options);
            if (message.filterPublishedAt != null && message.hasOwnProperty("filterPublishedAt"))
                object.filterPublishedAt = $root.types.TimestampRange.toObject(message.filterPublishedAt, options);
            if (message.filterAnyScriptType && message.filterAnyScriptType.length) {
                object.filterAnyScriptType = [];
                for (var j = 0; j < message.filterAnyScriptType.length; ++j)
                    object.filterAnyScriptType[j] = options.enums === String ? $root.query_types.StoryScriptType[message.filterAnyScriptType[j]] : message.filterAnyScriptType[j];
            }
            if (message.excludeFirstPublished != null && message.hasOwnProperty("excludeFirstPublished"))
                object.excludeFirstPublished = $root.google.protobuf.BoolValue.toObject(message.excludeFirstPublished, options);
            if (message.excludeNotFirstPublished != null && message.hasOwnProperty("excludeNotFirstPublished"))
                object.excludeNotFirstPublished = $root.google.protobuf.BoolValue.toObject(message.excludeNotFirstPublished, options);
            if (message.includeSensitive != null && message.hasOwnProperty("includeSensitive"))
                object.includeSensitive = $root.google.protobuf.BoolValue.toObject(message.includeSensitive, options);
            return object;
        };

        /**
         * Converts this SearchStoryFilter to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchStoryFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryFilter
         * @function getTypeUrl
         * @memberof query_search_types.SearchStoryFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchStoryFilter";
        };

        return SearchStoryFilter;
    })();

    query_search_types.SearchStoryOptionalFilter = (function() {

        /**
         * Properties of a SearchStoryOptionalFilter.
         * @memberof query_search_types
         * @interface ISearchStoryOptionalFilter
         * @property {google.protobuf.IUInt32Value|null} [prioritizeOfficial] SearchStoryOptionalFilter prioritizeOfficial
         * @property {google.protobuf.IUInt32Value|null} [prioritizeNotOfficial] SearchStoryOptionalFilter prioritizeNotOfficial
         */

        /**
         * Constructs a new SearchStoryOptionalFilter.
         * @memberof query_search_types
         * @classdesc Represents a SearchStoryOptionalFilter.
         * @implements ISearchStoryOptionalFilter
         * @constructor
         * @param {query_search_types.ISearchStoryOptionalFilter=} [properties] Properties to set
         */
        function SearchStoryOptionalFilter(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryOptionalFilter prioritizeOfficial.
         * @member {google.protobuf.IUInt32Value|null|undefined} prioritizeOfficial
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @instance
         */
        SearchStoryOptionalFilter.prototype.prioritizeOfficial = null;

        /**
         * SearchStoryOptionalFilter prioritizeNotOfficial.
         * @member {google.protobuf.IUInt32Value|null|undefined} prioritizeNotOfficial
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @instance
         */
        SearchStoryOptionalFilter.prototype.prioritizeNotOfficial = null;

        /**
         * Creates a new SearchStoryOptionalFilter instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @static
         * @param {query_search_types.ISearchStoryOptionalFilter=} [properties] Properties to set
         * @returns {query_search_types.SearchStoryOptionalFilter} SearchStoryOptionalFilter instance
         */
        SearchStoryOptionalFilter.create = function create(properties) {
            return new SearchStoryOptionalFilter(properties);
        };

        /**
         * Encodes the specified SearchStoryOptionalFilter message. Does not implicitly {@link query_search_types.SearchStoryOptionalFilter.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @static
         * @param {query_search_types.ISearchStoryOptionalFilter} message SearchStoryOptionalFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryOptionalFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.prioritizeOfficial != null && Object.hasOwnProperty.call(message, "prioritizeOfficial"))
                $root.google.protobuf.UInt32Value.encode(message.prioritizeOfficial, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.prioritizeNotOfficial != null && Object.hasOwnProperty.call(message, "prioritizeNotOfficial"))
                $root.google.protobuf.UInt32Value.encode(message.prioritizeNotOfficial, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchStoryOptionalFilter message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchStoryOptionalFilter} SearchStoryOptionalFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryOptionalFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchStoryOptionalFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.prioritizeOfficial = $root.google.protobuf.UInt32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.prioritizeNotOfficial = $root.google.protobuf.UInt32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryOptionalFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchStoryOptionalFilter} SearchStoryOptionalFilter
         */
        SearchStoryOptionalFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchStoryOptionalFilter)
                return object;
            var message = new $root.query_search_types.SearchStoryOptionalFilter();
            if (object.prioritizeOfficial != null) {
                if (typeof object.prioritizeOfficial !== "object")
                    throw TypeError(".query_search_types.SearchStoryOptionalFilter.prioritizeOfficial: object expected");
                message.prioritizeOfficial = $root.google.protobuf.UInt32Value.fromObject(object.prioritizeOfficial);
            }
            if (object.prioritizeNotOfficial != null) {
                if (typeof object.prioritizeNotOfficial !== "object")
                    throw TypeError(".query_search_types.SearchStoryOptionalFilter.prioritizeNotOfficial: object expected");
                message.prioritizeNotOfficial = $root.google.protobuf.UInt32Value.fromObject(object.prioritizeNotOfficial);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryOptionalFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @static
         * @param {query_search_types.SearchStoryOptionalFilter} message SearchStoryOptionalFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryOptionalFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.prioritizeOfficial = null;
                object.prioritizeNotOfficial = null;
            }
            if (message.prioritizeOfficial != null && message.hasOwnProperty("prioritizeOfficial"))
                object.prioritizeOfficial = $root.google.protobuf.UInt32Value.toObject(message.prioritizeOfficial, options);
            if (message.prioritizeNotOfficial != null && message.hasOwnProperty("prioritizeNotOfficial"))
                object.prioritizeNotOfficial = $root.google.protobuf.UInt32Value.toObject(message.prioritizeNotOfficial, options);
            return object;
        };

        /**
         * Converts this SearchStoryOptionalFilter to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryOptionalFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryOptionalFilter
         * @function getTypeUrl
         * @memberof query_search_types.SearchStoryOptionalFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryOptionalFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchStoryOptionalFilter";
        };

        return SearchStoryOptionalFilter;
    })();

    /**
     * StorySearchableAttribute enum.
     * @name query_search_types.StorySearchableAttribute
     * @enum {number}
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED=0 STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_TITLE=1 STORY_SEARCHABLE_ATTRIBUTE_TITLE value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE=2 STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION=3 STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION=4 STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_TAGS=5 STORY_SEARCHABLE_ATTRIBUTE_TAGS value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS=6 STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE=7 STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE=8 STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_USER_NAME=9 STORY_SEARCHABLE_ATTRIBUTE_USER_NAME value
     * @property {number} STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME=10 STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME value
     */
    query_search_types.StorySearchableAttribute = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STORY_SEARCHABLE_ATTRIBUTE_TITLE"] = 1;
        values[valuesById[2] = "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE"] = 2;
        values[valuesById[3] = "STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION"] = 3;
        values[valuesById[4] = "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION"] = 4;
        values[valuesById[5] = "STORY_SEARCHABLE_ATTRIBUTE_TAGS"] = 5;
        values[valuesById[6] = "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS"] = 6;
        values[valuesById[7] = "STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE"] = 7;
        values[valuesById[8] = "STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE"] = 8;
        values[valuesById[9] = "STORY_SEARCHABLE_ATTRIBUTE_USER_NAME"] = 9;
        values[valuesById[10] = "STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME"] = 10;
        return values;
    })();

    query_search_types.SearchStoryParams = (function() {

        /**
         * Properties of a SearchStoryParams.
         * @memberof query_search_types
         * @interface ISearchStoryParams
         * @property {google.protobuf.IStringValue|null} [query] SearchStoryParams query
         * @property {Array.<query_search_types.StorySearchableAttribute>|null} [searchableAttributes] SearchStoryParams searchableAttributes
         * @property {query_search_types.ISearchStoryFilter|null} [filter] SearchStoryParams filter
         * @property {query_search_types.ISearchStoryOptionalFilter|null} [optionalFilter] SearchStoryParams optionalFilter
         * @property {query_search_types.SearchStorySortBy|null} [sortBy] SearchStoryParams sortBy
         * @property {query_search_types.SearchStoryDistinct|null} [distinct] SearchStoryParams distinct
         */

        /**
         * Constructs a new SearchStoryParams.
         * @memberof query_search_types
         * @classdesc Represents a SearchStoryParams.
         * @implements ISearchStoryParams
         * @constructor
         * @param {query_search_types.ISearchStoryParams=} [properties] Properties to set
         */
        function SearchStoryParams(properties) {
            this.searchableAttributes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryParams query.
         * @member {google.protobuf.IStringValue|null|undefined} query
         * @memberof query_search_types.SearchStoryParams
         * @instance
         */
        SearchStoryParams.prototype.query = null;

        /**
         * SearchStoryParams searchableAttributes.
         * @member {Array.<query_search_types.StorySearchableAttribute>} searchableAttributes
         * @memberof query_search_types.SearchStoryParams
         * @instance
         */
        SearchStoryParams.prototype.searchableAttributes = $util.emptyArray;

        /**
         * SearchStoryParams filter.
         * @member {query_search_types.ISearchStoryFilter|null|undefined} filter
         * @memberof query_search_types.SearchStoryParams
         * @instance
         */
        SearchStoryParams.prototype.filter = null;

        /**
         * SearchStoryParams optionalFilter.
         * @member {query_search_types.ISearchStoryOptionalFilter|null|undefined} optionalFilter
         * @memberof query_search_types.SearchStoryParams
         * @instance
         */
        SearchStoryParams.prototype.optionalFilter = null;

        /**
         * SearchStoryParams sortBy.
         * @member {query_search_types.SearchStorySortBy} sortBy
         * @memberof query_search_types.SearchStoryParams
         * @instance
         */
        SearchStoryParams.prototype.sortBy = 0;

        /**
         * SearchStoryParams distinct.
         * @member {query_search_types.SearchStoryDistinct} distinct
         * @memberof query_search_types.SearchStoryParams
         * @instance
         */
        SearchStoryParams.prototype.distinct = 0;

        /**
         * Creates a new SearchStoryParams instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchStoryParams
         * @static
         * @param {query_search_types.ISearchStoryParams=} [properties] Properties to set
         * @returns {query_search_types.SearchStoryParams} SearchStoryParams instance
         */
        SearchStoryParams.create = function create(properties) {
            return new SearchStoryParams(properties);
        };

        /**
         * Encodes the specified SearchStoryParams message. Does not implicitly {@link query_search_types.SearchStoryParams.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchStoryParams
         * @static
         * @param {query_search_types.ISearchStoryParams} message SearchStoryParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryParams.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.google.protobuf.StringValue.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.searchableAttributes != null && message.searchableAttributes.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.searchableAttributes.length; ++i)
                    writer.int32(message.searchableAttributes[i]);
                writer.ldelim();
            }
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                $root.query_search_types.SearchStoryFilter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.optionalFilter != null && Object.hasOwnProperty.call(message, "optionalFilter"))
                $root.query_search_types.SearchStoryOptionalFilter.encode(message.optionalFilter, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sortBy);
            if (message.distinct != null && Object.hasOwnProperty.call(message, "distinct"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.distinct);
            return writer;
        };

        /**
         * Decodes a SearchStoryParams message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchStoryParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchStoryParams} SearchStoryParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryParams.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchStoryParams();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.searchableAttributes && message.searchableAttributes.length))
                        message.searchableAttributes = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.searchableAttributes.push(reader.int32());
                    } else
                        message.searchableAttributes.push(reader.int32());
                    break;
                case 3:
                    message.filter = $root.query_search_types.SearchStoryFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.optionalFilter = $root.query_search_types.SearchStoryOptionalFilter.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.sortBy = reader.int32();
                    break;
                case 6:
                    message.distinct = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryParams message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchStoryParams
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchStoryParams} SearchStoryParams
         */
        SearchStoryParams.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchStoryParams)
                return object;
            var message = new $root.query_search_types.SearchStoryParams();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".query_search_types.SearchStoryParams.query: object expected");
                message.query = $root.google.protobuf.StringValue.fromObject(object.query);
            }
            if (object.searchableAttributes) {
                if (!Array.isArray(object.searchableAttributes))
                    throw TypeError(".query_search_types.SearchStoryParams.searchableAttributes: array expected");
                message.searchableAttributes = [];
                for (var i = 0; i < object.searchableAttributes.length; ++i)
                    switch (object.searchableAttributes[i]) {
                    default:
                    case "STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED":
                    case 0:
                        message.searchableAttributes[i] = 0;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_TITLE":
                    case 1:
                        message.searchableAttributes[i] = 1;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE":
                    case 2:
                        message.searchableAttributes[i] = 2;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION":
                    case 3:
                        message.searchableAttributes[i] = 3;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION":
                    case 4:
                        message.searchableAttributes[i] = 4;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_TAGS":
                    case 5:
                        message.searchableAttributes[i] = 5;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS":
                    case 6:
                        message.searchableAttributes[i] = 6;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE":
                    case 7:
                        message.searchableAttributes[i] = 7;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE":
                    case 8:
                        message.searchableAttributes[i] = 8;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_USER_NAME":
                    case 9:
                        message.searchableAttributes[i] = 9;
                        break;
                    case "STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME":
                    case 10:
                        message.searchableAttributes[i] = 10;
                        break;
                    }
            }
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".query_search_types.SearchStoryParams.filter: object expected");
                message.filter = $root.query_search_types.SearchStoryFilter.fromObject(object.filter);
            }
            if (object.optionalFilter != null) {
                if (typeof object.optionalFilter !== "object")
                    throw TypeError(".query_search_types.SearchStoryParams.optionalFilter: object expected");
                message.optionalFilter = $root.query_search_types.SearchStoryOptionalFilter.fromObject(object.optionalFilter);
            }
            switch (object.sortBy) {
            case "SEARCH_STORY_SORT_BY_UNSPECIFIED":
            case 0:
                message.sortBy = 0;
                break;
            case "SEARCH_STORY_SORT_BY_DEFAULT":
            case 1:
                message.sortBy = 1;
                break;
            case "SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC":
            case 2:
                message.sortBy = 2;
                break;
            case "SEARCH_STORY_SORT_BY_UPDATED_AT_DESC":
            case 3:
                message.sortBy = 3;
                break;
            case "SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC":
            case 4:
                message.sortBy = 4;
                break;
            }
            switch (object.distinct) {
            case "SEARCH_STORY_DISTINCT_UNSPECIFIED":
            case 0:
                message.distinct = 0;
                break;
            case "SEARCH_STORY_DISTINCT_SERIES_OR_STORY":
            case 1:
                message.distinct = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryParams message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchStoryParams
         * @static
         * @param {query_search_types.SearchStoryParams} message SearchStoryParams
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryParams.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.searchableAttributes = [];
            if (options.defaults) {
                object.query = null;
                object.filter = null;
                object.optionalFilter = null;
                object.sortBy = options.enums === String ? "SEARCH_STORY_SORT_BY_UNSPECIFIED" : 0;
                object.distinct = options.enums === String ? "SEARCH_STORY_DISTINCT_UNSPECIFIED" : 0;
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.google.protobuf.StringValue.toObject(message.query, options);
            if (message.searchableAttributes && message.searchableAttributes.length) {
                object.searchableAttributes = [];
                for (var j = 0; j < message.searchableAttributes.length; ++j)
                    object.searchableAttributes[j] = options.enums === String ? $root.query_search_types.StorySearchableAttribute[message.searchableAttributes[j]] : message.searchableAttributes[j];
            }
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.query_search_types.SearchStoryFilter.toObject(message.filter, options);
            if (message.optionalFilter != null && message.hasOwnProperty("optionalFilter"))
                object.optionalFilter = $root.query_search_types.SearchStoryOptionalFilter.toObject(message.optionalFilter, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.query_search_types.SearchStorySortBy[message.sortBy] : message.sortBy;
            if (message.distinct != null && message.hasOwnProperty("distinct"))
                object.distinct = options.enums === String ? $root.query_search_types.SearchStoryDistinct[message.distinct] : message.distinct;
            return object;
        };

        /**
         * Converts this SearchStoryParams to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchStoryParams
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryParams.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryParams
         * @function getTypeUrl
         * @memberof query_search_types.SearchStoryParams
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchStoryParams";
        };

        return SearchStoryParams;
    })();

    /**
     * SearchStoryDistinct enum.
     * @name query_search_types.SearchStoryDistinct
     * @enum {number}
     * @property {number} SEARCH_STORY_DISTINCT_UNSPECIFIED=0 SEARCH_STORY_DISTINCT_UNSPECIFIED value
     * @property {number} SEARCH_STORY_DISTINCT_SERIES_OR_STORY=1 SEARCH_STORY_DISTINCT_SERIES_OR_STORY value
     */
    query_search_types.SearchStoryDistinct = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEARCH_STORY_DISTINCT_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SEARCH_STORY_DISTINCT_SERIES_OR_STORY"] = 1;
        return values;
    })();

    /**
     * SearchStorySortBy enum.
     * @name query_search_types.SearchStorySortBy
     * @enum {number}
     * @property {number} SEARCH_STORY_SORT_BY_UNSPECIFIED=0 SEARCH_STORY_SORT_BY_UNSPECIFIED value
     * @property {number} SEARCH_STORY_SORT_BY_DEFAULT=1 SEARCH_STORY_SORT_BY_DEFAULT value
     * @property {number} SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC=2 SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC value
     * @property {number} SEARCH_STORY_SORT_BY_UPDATED_AT_DESC=3 SEARCH_STORY_SORT_BY_UPDATED_AT_DESC value
     * @property {number} SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC=4 SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC value
     */
    query_search_types.SearchStorySortBy = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEARCH_STORY_SORT_BY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SEARCH_STORY_SORT_BY_DEFAULT"] = 1;
        values[valuesById[2] = "SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC"] = 2;
        values[valuesById[3] = "SEARCH_STORY_SORT_BY_UPDATED_AT_DESC"] = 3;
        values[valuesById[4] = "SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC"] = 4;
        return values;
    })();

    /**
     * SearchStoryFacetType enum.
     * @name query_search_types.SearchStoryFacetType
     * @enum {number}
     * @property {number} SEARCH_STORY_FACET_TYPE_UNSPECIFIED=0 SEARCH_STORY_FACET_TYPE_UNSPECIFIED value
     * @property {number} SEARCH_STORY_FACET_TYPE_TAGS=1 SEARCH_STORY_FACET_TYPE_TAGS value
     */
    query_search_types.SearchStoryFacetType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEARCH_STORY_FACET_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SEARCH_STORY_FACET_TYPE_TAGS"] = 1;
        return values;
    })();

    query_search_types.SearchStoryFacetRequest = (function() {

        /**
         * Properties of a SearchStoryFacetRequest.
         * @memberof query_search_types
         * @interface ISearchStoryFacetRequest
         * @property {Array.<query_search_types.SearchStoryFacetType>|null} [facetType] SearchStoryFacetRequest facetType
         * @property {google.protobuf.IInt32Value|null} [limitPerFacet] SearchStoryFacetRequest limitPerFacet
         */

        /**
         * Constructs a new SearchStoryFacetRequest.
         * @memberof query_search_types
         * @classdesc Represents a SearchStoryFacetRequest.
         * @implements ISearchStoryFacetRequest
         * @constructor
         * @param {query_search_types.ISearchStoryFacetRequest=} [properties] Properties to set
         */
        function SearchStoryFacetRequest(properties) {
            this.facetType = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryFacetRequest facetType.
         * @member {Array.<query_search_types.SearchStoryFacetType>} facetType
         * @memberof query_search_types.SearchStoryFacetRequest
         * @instance
         */
        SearchStoryFacetRequest.prototype.facetType = $util.emptyArray;

        /**
         * SearchStoryFacetRequest limitPerFacet.
         * @member {google.protobuf.IInt32Value|null|undefined} limitPerFacet
         * @memberof query_search_types.SearchStoryFacetRequest
         * @instance
         */
        SearchStoryFacetRequest.prototype.limitPerFacet = null;

        /**
         * Creates a new SearchStoryFacetRequest instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchStoryFacetRequest
         * @static
         * @param {query_search_types.ISearchStoryFacetRequest=} [properties] Properties to set
         * @returns {query_search_types.SearchStoryFacetRequest} SearchStoryFacetRequest instance
         */
        SearchStoryFacetRequest.create = function create(properties) {
            return new SearchStoryFacetRequest(properties);
        };

        /**
         * Encodes the specified SearchStoryFacetRequest message. Does not implicitly {@link query_search_types.SearchStoryFacetRequest.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchStoryFacetRequest
         * @static
         * @param {query_search_types.ISearchStoryFacetRequest} message SearchStoryFacetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryFacetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.facetType != null && message.facetType.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.facetType.length; ++i)
                    writer.int32(message.facetType[i]);
                writer.ldelim();
            }
            if (message.limitPerFacet != null && Object.hasOwnProperty.call(message, "limitPerFacet"))
                $root.google.protobuf.Int32Value.encode(message.limitPerFacet, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchStoryFacetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchStoryFacetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchStoryFacetRequest} SearchStoryFacetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryFacetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchStoryFacetRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.facetType && message.facetType.length))
                        message.facetType = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.facetType.push(reader.int32());
                    } else
                        message.facetType.push(reader.int32());
                    break;
                case 2:
                    message.limitPerFacet = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryFacetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchStoryFacetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchStoryFacetRequest} SearchStoryFacetRequest
         */
        SearchStoryFacetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchStoryFacetRequest)
                return object;
            var message = new $root.query_search_types.SearchStoryFacetRequest();
            if (object.facetType) {
                if (!Array.isArray(object.facetType))
                    throw TypeError(".query_search_types.SearchStoryFacetRequest.facetType: array expected");
                message.facetType = [];
                for (var i = 0; i < object.facetType.length; ++i)
                    switch (object.facetType[i]) {
                    default:
                    case "SEARCH_STORY_FACET_TYPE_UNSPECIFIED":
                    case 0:
                        message.facetType[i] = 0;
                        break;
                    case "SEARCH_STORY_FACET_TYPE_TAGS":
                    case 1:
                        message.facetType[i] = 1;
                        break;
                    }
            }
            if (object.limitPerFacet != null) {
                if (typeof object.limitPerFacet !== "object")
                    throw TypeError(".query_search_types.SearchStoryFacetRequest.limitPerFacet: object expected");
                message.limitPerFacet = $root.google.protobuf.Int32Value.fromObject(object.limitPerFacet);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryFacetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchStoryFacetRequest
         * @static
         * @param {query_search_types.SearchStoryFacetRequest} message SearchStoryFacetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryFacetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.facetType = [];
            if (options.defaults)
                object.limitPerFacet = null;
            if (message.facetType && message.facetType.length) {
                object.facetType = [];
                for (var j = 0; j < message.facetType.length; ++j)
                    object.facetType[j] = options.enums === String ? $root.query_search_types.SearchStoryFacetType[message.facetType[j]] : message.facetType[j];
            }
            if (message.limitPerFacet != null && message.hasOwnProperty("limitPerFacet"))
                object.limitPerFacet = $root.google.protobuf.Int32Value.toObject(message.limitPerFacet, options);
            return object;
        };

        /**
         * Converts this SearchStoryFacetRequest to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchStoryFacetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryFacetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryFacetRequest
         * @function getTypeUrl
         * @memberof query_search_types.SearchStoryFacetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryFacetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchStoryFacetRequest";
        };

        return SearchStoryFacetRequest;
    })();

    query_search_types.SearchStoryFacetTagRequest = (function() {

        /**
         * Properties of a SearchStoryFacetTagRequest.
         * @memberof query_search_types
         * @interface ISearchStoryFacetTagRequest
         * @property {google.protobuf.IStringValue|null} [query] SearchStoryFacetTagRequest query
         * @property {query_search_types.ISearchStoryFilter|null} [filter] SearchStoryFacetTagRequest filter
         * @property {google.protobuf.IInt32Value|null} [limit] SearchStoryFacetTagRequest limit
         * @property {query_search_types.SearchStoryDistinct|null} [distinct] SearchStoryFacetTagRequest distinct
         */

        /**
         * Constructs a new SearchStoryFacetTagRequest.
         * @memberof query_search_types
         * @classdesc Represents a SearchStoryFacetTagRequest.
         * @implements ISearchStoryFacetTagRequest
         * @constructor
         * @param {query_search_types.ISearchStoryFacetTagRequest=} [properties] Properties to set
         */
        function SearchStoryFacetTagRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryFacetTagRequest query.
         * @member {google.protobuf.IStringValue|null|undefined} query
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @instance
         */
        SearchStoryFacetTagRequest.prototype.query = null;

        /**
         * SearchStoryFacetTagRequest filter.
         * @member {query_search_types.ISearchStoryFilter|null|undefined} filter
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @instance
         */
        SearchStoryFacetTagRequest.prototype.filter = null;

        /**
         * SearchStoryFacetTagRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @instance
         */
        SearchStoryFacetTagRequest.prototype.limit = null;

        /**
         * SearchStoryFacetTagRequest distinct.
         * @member {query_search_types.SearchStoryDistinct} distinct
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @instance
         */
        SearchStoryFacetTagRequest.prototype.distinct = 0;

        /**
         * Creates a new SearchStoryFacetTagRequest instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @static
         * @param {query_search_types.ISearchStoryFacetTagRequest=} [properties] Properties to set
         * @returns {query_search_types.SearchStoryFacetTagRequest} SearchStoryFacetTagRequest instance
         */
        SearchStoryFacetTagRequest.create = function create(properties) {
            return new SearchStoryFacetTagRequest(properties);
        };

        /**
         * Encodes the specified SearchStoryFacetTagRequest message. Does not implicitly {@link query_search_types.SearchStoryFacetTagRequest.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @static
         * @param {query_search_types.ISearchStoryFacetTagRequest} message SearchStoryFacetTagRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryFacetTagRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.google.protobuf.StringValue.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                $root.query_search_types.SearchStoryFilter.encode(message.filter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.distinct != null && Object.hasOwnProperty.call(message, "distinct"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.distinct);
            return writer;
        };

        /**
         * Decodes a SearchStoryFacetTagRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchStoryFacetTagRequest} SearchStoryFacetTagRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryFacetTagRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchStoryFacetTagRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.filter = $root.query_search_types.SearchStoryFilter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.distinct = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryFacetTagRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchStoryFacetTagRequest} SearchStoryFacetTagRequest
         */
        SearchStoryFacetTagRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchStoryFacetTagRequest)
                return object;
            var message = new $root.query_search_types.SearchStoryFacetTagRequest();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".query_search_types.SearchStoryFacetTagRequest.query: object expected");
                message.query = $root.google.protobuf.StringValue.fromObject(object.query);
            }
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".query_search_types.SearchStoryFacetTagRequest.filter: object expected");
                message.filter = $root.query_search_types.SearchStoryFilter.fromObject(object.filter);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".query_search_types.SearchStoryFacetTagRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            switch (object.distinct) {
            case "SEARCH_STORY_DISTINCT_UNSPECIFIED":
            case 0:
                message.distinct = 0;
                break;
            case "SEARCH_STORY_DISTINCT_SERIES_OR_STORY":
            case 1:
                message.distinct = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryFacetTagRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @static
         * @param {query_search_types.SearchStoryFacetTagRequest} message SearchStoryFacetTagRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryFacetTagRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.query = null;
                object.filter = null;
                object.limit = null;
                object.distinct = options.enums === String ? "SEARCH_STORY_DISTINCT_UNSPECIFIED" : 0;
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.google.protobuf.StringValue.toObject(message.query, options);
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.query_search_types.SearchStoryFilter.toObject(message.filter, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.distinct != null && message.hasOwnProperty("distinct"))
                object.distinct = options.enums === String ? $root.query_search_types.SearchStoryDistinct[message.distinct] : message.distinct;
            return object;
        };

        /**
         * Converts this SearchStoryFacetTagRequest to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryFacetTagRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryFacetTagRequest
         * @function getTypeUrl
         * @memberof query_search_types.SearchStoryFacetTagRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryFacetTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchStoryFacetTagRequest";
        };

        return SearchStoryFacetTagRequest;
    })();

    query_search_types.SearchFacetItem = (function() {

        /**
         * Properties of a SearchFacetItem.
         * @memberof query_search_types
         * @interface ISearchFacetItem
         * @property {google.protobuf.IStringValue|null} [item] SearchFacetItem item
         * @property {google.protobuf.IInt32Value|null} [count] SearchFacetItem count
         */

        /**
         * Constructs a new SearchFacetItem.
         * @memberof query_search_types
         * @classdesc Represents a SearchFacetItem.
         * @implements ISearchFacetItem
         * @constructor
         * @param {query_search_types.ISearchFacetItem=} [properties] Properties to set
         */
        function SearchFacetItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchFacetItem item.
         * @member {google.protobuf.IStringValue|null|undefined} item
         * @memberof query_search_types.SearchFacetItem
         * @instance
         */
        SearchFacetItem.prototype.item = null;

        /**
         * SearchFacetItem count.
         * @member {google.protobuf.IInt32Value|null|undefined} count
         * @memberof query_search_types.SearchFacetItem
         * @instance
         */
        SearchFacetItem.prototype.count = null;

        /**
         * Creates a new SearchFacetItem instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchFacetItem
         * @static
         * @param {query_search_types.ISearchFacetItem=} [properties] Properties to set
         * @returns {query_search_types.SearchFacetItem} SearchFacetItem instance
         */
        SearchFacetItem.create = function create(properties) {
            return new SearchFacetItem(properties);
        };

        /**
         * Encodes the specified SearchFacetItem message. Does not implicitly {@link query_search_types.SearchFacetItem.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchFacetItem
         * @static
         * @param {query_search_types.ISearchFacetItem} message SearchFacetItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchFacetItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                $root.google.protobuf.StringValue.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                $root.google.protobuf.Int32Value.encode(message.count, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchFacetItem message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchFacetItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchFacetItem} SearchFacetItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchFacetItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchFacetItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.item = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.count = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchFacetItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchFacetItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchFacetItem} SearchFacetItem
         */
        SearchFacetItem.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchFacetItem)
                return object;
            var message = new $root.query_search_types.SearchFacetItem();
            if (object.item != null) {
                if (typeof object.item !== "object")
                    throw TypeError(".query_search_types.SearchFacetItem.item: object expected");
                message.item = $root.google.protobuf.StringValue.fromObject(object.item);
            }
            if (object.count != null) {
                if (typeof object.count !== "object")
                    throw TypeError(".query_search_types.SearchFacetItem.count: object expected");
                message.count = $root.google.protobuf.Int32Value.fromObject(object.count);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchFacetItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchFacetItem
         * @static
         * @param {query_search_types.SearchFacetItem} message SearchFacetItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchFacetItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.item = null;
                object.count = null;
            }
            if (message.item != null && message.hasOwnProperty("item"))
                object.item = $root.google.protobuf.StringValue.toObject(message.item, options);
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = $root.google.protobuf.Int32Value.toObject(message.count, options);
            return object;
        };

        /**
         * Converts this SearchFacetItem to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchFacetItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchFacetItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchFacetItem
         * @function getTypeUrl
         * @memberof query_search_types.SearchFacetItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchFacetItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchFacetItem";
        };

        return SearchFacetItem;
    })();

    query_search_types.SearchStoryFacetResponse = (function() {

        /**
         * Properties of a SearchStoryFacetResponse.
         * @memberof query_search_types
         * @interface ISearchStoryFacetResponse
         * @property {query_search_types.SearchStoryFacetType|null} [facetType] SearchStoryFacetResponse facetType
         * @property {Array.<query_search_types.ISearchFacetItem>|null} [itemList] SearchStoryFacetResponse itemList
         */

        /**
         * Constructs a new SearchStoryFacetResponse.
         * @memberof query_search_types
         * @classdesc Represents a SearchStoryFacetResponse.
         * @implements ISearchStoryFacetResponse
         * @constructor
         * @param {query_search_types.ISearchStoryFacetResponse=} [properties] Properties to set
         */
        function SearchStoryFacetResponse(properties) {
            this.itemList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchStoryFacetResponse facetType.
         * @member {query_search_types.SearchStoryFacetType} facetType
         * @memberof query_search_types.SearchStoryFacetResponse
         * @instance
         */
        SearchStoryFacetResponse.prototype.facetType = 0;

        /**
         * SearchStoryFacetResponse itemList.
         * @member {Array.<query_search_types.ISearchFacetItem>} itemList
         * @memberof query_search_types.SearchStoryFacetResponse
         * @instance
         */
        SearchStoryFacetResponse.prototype.itemList = $util.emptyArray;

        /**
         * Creates a new SearchStoryFacetResponse instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchStoryFacetResponse
         * @static
         * @param {query_search_types.ISearchStoryFacetResponse=} [properties] Properties to set
         * @returns {query_search_types.SearchStoryFacetResponse} SearchStoryFacetResponse instance
         */
        SearchStoryFacetResponse.create = function create(properties) {
            return new SearchStoryFacetResponse(properties);
        };

        /**
         * Encodes the specified SearchStoryFacetResponse message. Does not implicitly {@link query_search_types.SearchStoryFacetResponse.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchStoryFacetResponse
         * @static
         * @param {query_search_types.ISearchStoryFacetResponse} message SearchStoryFacetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchStoryFacetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.facetType != null && Object.hasOwnProperty.call(message, "facetType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.facetType);
            if (message.itemList != null && message.itemList.length)
                for (var i = 0; i < message.itemList.length; ++i)
                    $root.query_search_types.SearchFacetItem.encode(message.itemList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchStoryFacetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchStoryFacetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchStoryFacetResponse} SearchStoryFacetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchStoryFacetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchStoryFacetResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.facetType = reader.int32();
                    break;
                case 2:
                    if (!(message.itemList && message.itemList.length))
                        message.itemList = [];
                    message.itemList.push($root.query_search_types.SearchFacetItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchStoryFacetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchStoryFacetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchStoryFacetResponse} SearchStoryFacetResponse
         */
        SearchStoryFacetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchStoryFacetResponse)
                return object;
            var message = new $root.query_search_types.SearchStoryFacetResponse();
            switch (object.facetType) {
            case "SEARCH_STORY_FACET_TYPE_UNSPECIFIED":
            case 0:
                message.facetType = 0;
                break;
            case "SEARCH_STORY_FACET_TYPE_TAGS":
            case 1:
                message.facetType = 1;
                break;
            }
            if (object.itemList) {
                if (!Array.isArray(object.itemList))
                    throw TypeError(".query_search_types.SearchStoryFacetResponse.itemList: array expected");
                message.itemList = [];
                for (var i = 0; i < object.itemList.length; ++i) {
                    if (typeof object.itemList[i] !== "object")
                        throw TypeError(".query_search_types.SearchStoryFacetResponse.itemList: object expected");
                    message.itemList[i] = $root.query_search_types.SearchFacetItem.fromObject(object.itemList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchStoryFacetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchStoryFacetResponse
         * @static
         * @param {query_search_types.SearchStoryFacetResponse} message SearchStoryFacetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchStoryFacetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.itemList = [];
            if (options.defaults)
                object.facetType = options.enums === String ? "SEARCH_STORY_FACET_TYPE_UNSPECIFIED" : 0;
            if (message.facetType != null && message.hasOwnProperty("facetType"))
                object.facetType = options.enums === String ? $root.query_search_types.SearchStoryFacetType[message.facetType] : message.facetType;
            if (message.itemList && message.itemList.length) {
                object.itemList = [];
                for (var j = 0; j < message.itemList.length; ++j)
                    object.itemList[j] = $root.query_search_types.SearchFacetItem.toObject(message.itemList[j], options);
            }
            return object;
        };

        /**
         * Converts this SearchStoryFacetResponse to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchStoryFacetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchStoryFacetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchStoryFacetResponse
         * @function getTypeUrl
         * @memberof query_search_types.SearchStoryFacetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchStoryFacetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchStoryFacetResponse";
        };

        return SearchStoryFacetResponse;
    })();

    query_search_types.SearchUserParams = (function() {

        /**
         * Properties of a SearchUserParams.
         * @memberof query_search_types
         * @interface ISearchUserParams
         * @property {google.protobuf.IStringValue|null} [query] SearchUserParams query
         * @property {Array.<query_search_types.UserSearchableAttribute>|null} [searchableAttributes] SearchUserParams searchableAttributes
         * @property {query_search_types.ISearchUserFilter|null} [filter] SearchUserParams filter
         * @property {query_search_types.SearchUserSortBy|null} [sortBy] SearchUserParams sortBy
         */

        /**
         * Constructs a new SearchUserParams.
         * @memberof query_search_types
         * @classdesc Represents a SearchUserParams.
         * @implements ISearchUserParams
         * @constructor
         * @param {query_search_types.ISearchUserParams=} [properties] Properties to set
         */
        function SearchUserParams(properties) {
            this.searchableAttributes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUserParams query.
         * @member {google.protobuf.IStringValue|null|undefined} query
         * @memberof query_search_types.SearchUserParams
         * @instance
         */
        SearchUserParams.prototype.query = null;

        /**
         * SearchUserParams searchableAttributes.
         * @member {Array.<query_search_types.UserSearchableAttribute>} searchableAttributes
         * @memberof query_search_types.SearchUserParams
         * @instance
         */
        SearchUserParams.prototype.searchableAttributes = $util.emptyArray;

        /**
         * SearchUserParams filter.
         * @member {query_search_types.ISearchUserFilter|null|undefined} filter
         * @memberof query_search_types.SearchUserParams
         * @instance
         */
        SearchUserParams.prototype.filter = null;

        /**
         * SearchUserParams sortBy.
         * @member {query_search_types.SearchUserSortBy} sortBy
         * @memberof query_search_types.SearchUserParams
         * @instance
         */
        SearchUserParams.prototype.sortBy = 0;

        /**
         * Creates a new SearchUserParams instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchUserParams
         * @static
         * @param {query_search_types.ISearchUserParams=} [properties] Properties to set
         * @returns {query_search_types.SearchUserParams} SearchUserParams instance
         */
        SearchUserParams.create = function create(properties) {
            return new SearchUserParams(properties);
        };

        /**
         * Encodes the specified SearchUserParams message. Does not implicitly {@link query_search_types.SearchUserParams.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchUserParams
         * @static
         * @param {query_search_types.ISearchUserParams} message SearchUserParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUserParams.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.google.protobuf.StringValue.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.searchableAttributes != null && message.searchableAttributes.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.searchableAttributes.length; ++i)
                    writer.int32(message.searchableAttributes[i]);
                writer.ldelim();
            }
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                $root.query_search_types.SearchUserFilter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortBy);
            return writer;
        };

        /**
         * Decodes a SearchUserParams message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchUserParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchUserParams} SearchUserParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUserParams.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchUserParams();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.searchableAttributes && message.searchableAttributes.length))
                        message.searchableAttributes = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.searchableAttributes.push(reader.int32());
                    } else
                        message.searchableAttributes.push(reader.int32());
                    break;
                case 3:
                    message.filter = $root.query_search_types.SearchUserFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.sortBy = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUserParams message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchUserParams
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchUserParams} SearchUserParams
         */
        SearchUserParams.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchUserParams)
                return object;
            var message = new $root.query_search_types.SearchUserParams();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".query_search_types.SearchUserParams.query: object expected");
                message.query = $root.google.protobuf.StringValue.fromObject(object.query);
            }
            if (object.searchableAttributes) {
                if (!Array.isArray(object.searchableAttributes))
                    throw TypeError(".query_search_types.SearchUserParams.searchableAttributes: array expected");
                message.searchableAttributes = [];
                for (var i = 0; i < object.searchableAttributes.length; ++i)
                    switch (object.searchableAttributes[i]) {
                    default:
                    case "USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED":
                    case 0:
                        message.searchableAttributes[i] = 0;
                        break;
                    case "USER_SEARCHABLE_ATTRIBUTE_NAME":
                    case 1:
                        message.searchableAttributes[i] = 1;
                        break;
                    case "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME":
                    case 2:
                        message.searchableAttributes[i] = 2;
                        break;
                    case "USER_SEARCHABLE_ATTRIBUTE_PROFILE":
                    case 3:
                        message.searchableAttributes[i] = 3;
                        break;
                    case "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE":
                    case 4:
                        message.searchableAttributes[i] = 4;
                        break;
                    case "USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS":
                    case 5:
                        message.searchableAttributes[i] = 5;
                        break;
                    case "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS":
                    case 6:
                        message.searchableAttributes[i] = 6;
                        break;
                    }
            }
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".query_search_types.SearchUserParams.filter: object expected");
                message.filter = $root.query_search_types.SearchUserFilter.fromObject(object.filter);
            }
            switch (object.sortBy) {
            case "SEARCH_USER_SORT_BY_UNSPECIFIED":
            case 0:
                message.sortBy = 0;
                break;
            case "SEARCH_USER_SORT_BY_DEFAULT":
            case 1:
                message.sortBy = 1;
                break;
            case "SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC":
            case 2:
                message.sortBy = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUserParams message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchUserParams
         * @static
         * @param {query_search_types.SearchUserParams} message SearchUserParams
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUserParams.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.searchableAttributes = [];
            if (options.defaults) {
                object.query = null;
                object.filter = null;
                object.sortBy = options.enums === String ? "SEARCH_USER_SORT_BY_UNSPECIFIED" : 0;
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.google.protobuf.StringValue.toObject(message.query, options);
            if (message.searchableAttributes && message.searchableAttributes.length) {
                object.searchableAttributes = [];
                for (var j = 0; j < message.searchableAttributes.length; ++j)
                    object.searchableAttributes[j] = options.enums === String ? $root.query_search_types.UserSearchableAttribute[message.searchableAttributes[j]] : message.searchableAttributes[j];
            }
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.query_search_types.SearchUserFilter.toObject(message.filter, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.query_search_types.SearchUserSortBy[message.sortBy] : message.sortBy;
            return object;
        };

        /**
         * Converts this SearchUserParams to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchUserParams
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUserParams.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUserParams
         * @function getTypeUrl
         * @memberof query_search_types.SearchUserParams
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUserParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchUserParams";
        };

        return SearchUserParams;
    })();

    /**
     * UserSearchableAttribute enum.
     * @name query_search_types.UserSearchableAttribute
     * @enum {number}
     * @property {number} USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED=0 USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED value
     * @property {number} USER_SEARCHABLE_ATTRIBUTE_NAME=1 USER_SEARCHABLE_ATTRIBUTE_NAME value
     * @property {number} USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME=2 USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME value
     * @property {number} USER_SEARCHABLE_ATTRIBUTE_PROFILE=3 USER_SEARCHABLE_ATTRIBUTE_PROFILE value
     * @property {number} USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE=4 USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE value
     * @property {number} USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS=5 USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS value
     * @property {number} USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS=6 USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS value
     */
    query_search_types.UserSearchableAttribute = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "USER_SEARCHABLE_ATTRIBUTE_NAME"] = 1;
        values[valuesById[2] = "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME"] = 2;
        values[valuesById[3] = "USER_SEARCHABLE_ATTRIBUTE_PROFILE"] = 3;
        values[valuesById[4] = "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE"] = 4;
        values[valuesById[5] = "USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS"] = 5;
        values[valuesById[6] = "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS"] = 6;
        return values;
    })();

    /**
     * SearchUserSortBy enum.
     * @name query_search_types.SearchUserSortBy
     * @enum {number}
     * @property {number} SEARCH_USER_SORT_BY_UNSPECIFIED=0 SEARCH_USER_SORT_BY_UNSPECIFIED value
     * @property {number} SEARCH_USER_SORT_BY_DEFAULT=1 SEARCH_USER_SORT_BY_DEFAULT value
     * @property {number} SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC=2 SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC value
     */
    query_search_types.SearchUserSortBy = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEARCH_USER_SORT_BY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SEARCH_USER_SORT_BY_DEFAULT"] = 1;
        values[valuesById[2] = "SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC"] = 2;
        return values;
    })();

    query_search_types.SearchUserFilter = (function() {

        /**
         * Properties of a SearchUserFilter.
         * @memberof query_search_types
         * @interface ISearchUserFilter
         * @property {Array.<google.protobuf.IStringValue>|null} [filterPublishedStoryTag] SearchUserFilter filterPublishedStoryTag
         * @property {Array.<google.protobuf.IStringValue>|null} [filterPublishedAnyStoryTag] SearchUserFilter filterPublishedAnyStoryTag
         * @property {google.protobuf.IBoolValue|null} [includeBlocked] SearchUserFilter includeBlocked
         * @property {google.protobuf.IBoolValue|null} [excludeVip] SearchUserFilter excludeVip
         * @property {google.protobuf.IBoolValue|null} [excludeNotVip] SearchUserFilter excludeNotVip
         * @property {types.IUInt32Range|null} [filterSearchableStoryCount] SearchUserFilter filterSearchableStoryCount
         * @property {Array.<query_types.UserRole>|null} [filterAnyUserRole] SearchUserFilter filterAnyUserRole
         */

        /**
         * Constructs a new SearchUserFilter.
         * @memberof query_search_types
         * @classdesc Represents a SearchUserFilter.
         * @implements ISearchUserFilter
         * @constructor
         * @param {query_search_types.ISearchUserFilter=} [properties] Properties to set
         */
        function SearchUserFilter(properties) {
            this.filterPublishedStoryTag = [];
            this.filterPublishedAnyStoryTag = [];
            this.filterAnyUserRole = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUserFilter filterPublishedStoryTag.
         * @member {Array.<google.protobuf.IStringValue>} filterPublishedStoryTag
         * @memberof query_search_types.SearchUserFilter
         * @instance
         */
        SearchUserFilter.prototype.filterPublishedStoryTag = $util.emptyArray;

        /**
         * SearchUserFilter filterPublishedAnyStoryTag.
         * @member {Array.<google.protobuf.IStringValue>} filterPublishedAnyStoryTag
         * @memberof query_search_types.SearchUserFilter
         * @instance
         */
        SearchUserFilter.prototype.filterPublishedAnyStoryTag = $util.emptyArray;

        /**
         * SearchUserFilter includeBlocked.
         * @member {google.protobuf.IBoolValue|null|undefined} includeBlocked
         * @memberof query_search_types.SearchUserFilter
         * @instance
         */
        SearchUserFilter.prototype.includeBlocked = null;

        /**
         * SearchUserFilter excludeVip.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeVip
         * @memberof query_search_types.SearchUserFilter
         * @instance
         */
        SearchUserFilter.prototype.excludeVip = null;

        /**
         * SearchUserFilter excludeNotVip.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotVip
         * @memberof query_search_types.SearchUserFilter
         * @instance
         */
        SearchUserFilter.prototype.excludeNotVip = null;

        /**
         * SearchUserFilter filterSearchableStoryCount.
         * @member {types.IUInt32Range|null|undefined} filterSearchableStoryCount
         * @memberof query_search_types.SearchUserFilter
         * @instance
         */
        SearchUserFilter.prototype.filterSearchableStoryCount = null;

        /**
         * SearchUserFilter filterAnyUserRole.
         * @member {Array.<query_types.UserRole>} filterAnyUserRole
         * @memberof query_search_types.SearchUserFilter
         * @instance
         */
        SearchUserFilter.prototype.filterAnyUserRole = $util.emptyArray;

        /**
         * Creates a new SearchUserFilter instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchUserFilter
         * @static
         * @param {query_search_types.ISearchUserFilter=} [properties] Properties to set
         * @returns {query_search_types.SearchUserFilter} SearchUserFilter instance
         */
        SearchUserFilter.create = function create(properties) {
            return new SearchUserFilter(properties);
        };

        /**
         * Encodes the specified SearchUserFilter message. Does not implicitly {@link query_search_types.SearchUserFilter.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchUserFilter
         * @static
         * @param {query_search_types.ISearchUserFilter} message SearchUserFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUserFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterPublishedStoryTag != null && message.filterPublishedStoryTag.length)
                for (var i = 0; i < message.filterPublishedStoryTag.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.filterPublishedStoryTag[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.includeBlocked != null && Object.hasOwnProperty.call(message, "includeBlocked"))
                $root.google.protobuf.BoolValue.encode(message.includeBlocked, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.excludeVip != null && Object.hasOwnProperty.call(message, "excludeVip"))
                $root.google.protobuf.BoolValue.encode(message.excludeVip, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.excludeNotVip != null && Object.hasOwnProperty.call(message, "excludeNotVip"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotVip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.filterSearchableStoryCount != null && Object.hasOwnProperty.call(message, "filterSearchableStoryCount"))
                $root.types.UInt32Range.encode(message.filterSearchableStoryCount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.filterAnyUserRole != null && message.filterAnyUserRole.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.filterAnyUserRole.length; ++i)
                    writer.int32(message.filterAnyUserRole[i]);
                writer.ldelim();
            }
            if (message.filterPublishedAnyStoryTag != null && message.filterPublishedAnyStoryTag.length)
                for (var i = 0; i < message.filterPublishedAnyStoryTag.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.filterPublishedAnyStoryTag[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUserFilter message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchUserFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchUserFilter} SearchUserFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUserFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchUserFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.filterPublishedStoryTag && message.filterPublishedStoryTag.length))
                        message.filterPublishedStoryTag = [];
                    message.filterPublishedStoryTag.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.filterPublishedAnyStoryTag && message.filterPublishedAnyStoryTag.length))
                        message.filterPublishedAnyStoryTag = [];
                    message.filterPublishedAnyStoryTag.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.includeBlocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.excludeVip = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.excludeNotVip = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.filterSearchableStoryCount = $root.types.UInt32Range.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.filterAnyUserRole && message.filterAnyUserRole.length))
                        message.filterAnyUserRole = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyUserRole.push(reader.int32());
                    } else
                        message.filterAnyUserRole.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUserFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchUserFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchUserFilter} SearchUserFilter
         */
        SearchUserFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchUserFilter)
                return object;
            var message = new $root.query_search_types.SearchUserFilter();
            if (object.filterPublishedStoryTag) {
                if (!Array.isArray(object.filterPublishedStoryTag))
                    throw TypeError(".query_search_types.SearchUserFilter.filterPublishedStoryTag: array expected");
                message.filterPublishedStoryTag = [];
                for (var i = 0; i < object.filterPublishedStoryTag.length; ++i) {
                    if (typeof object.filterPublishedStoryTag[i] !== "object")
                        throw TypeError(".query_search_types.SearchUserFilter.filterPublishedStoryTag: object expected");
                    message.filterPublishedStoryTag[i] = $root.google.protobuf.StringValue.fromObject(object.filterPublishedStoryTag[i]);
                }
            }
            if (object.filterPublishedAnyStoryTag) {
                if (!Array.isArray(object.filterPublishedAnyStoryTag))
                    throw TypeError(".query_search_types.SearchUserFilter.filterPublishedAnyStoryTag: array expected");
                message.filterPublishedAnyStoryTag = [];
                for (var i = 0; i < object.filterPublishedAnyStoryTag.length; ++i) {
                    if (typeof object.filterPublishedAnyStoryTag[i] !== "object")
                        throw TypeError(".query_search_types.SearchUserFilter.filterPublishedAnyStoryTag: object expected");
                    message.filterPublishedAnyStoryTag[i] = $root.google.protobuf.StringValue.fromObject(object.filterPublishedAnyStoryTag[i]);
                }
            }
            if (object.includeBlocked != null) {
                if (typeof object.includeBlocked !== "object")
                    throw TypeError(".query_search_types.SearchUserFilter.includeBlocked: object expected");
                message.includeBlocked = $root.google.protobuf.BoolValue.fromObject(object.includeBlocked);
            }
            if (object.excludeVip != null) {
                if (typeof object.excludeVip !== "object")
                    throw TypeError(".query_search_types.SearchUserFilter.excludeVip: object expected");
                message.excludeVip = $root.google.protobuf.BoolValue.fromObject(object.excludeVip);
            }
            if (object.excludeNotVip != null) {
                if (typeof object.excludeNotVip !== "object")
                    throw TypeError(".query_search_types.SearchUserFilter.excludeNotVip: object expected");
                message.excludeNotVip = $root.google.protobuf.BoolValue.fromObject(object.excludeNotVip);
            }
            if (object.filterSearchableStoryCount != null) {
                if (typeof object.filterSearchableStoryCount !== "object")
                    throw TypeError(".query_search_types.SearchUserFilter.filterSearchableStoryCount: object expected");
                message.filterSearchableStoryCount = $root.types.UInt32Range.fromObject(object.filterSearchableStoryCount);
            }
            if (object.filterAnyUserRole) {
                if (!Array.isArray(object.filterAnyUserRole))
                    throw TypeError(".query_search_types.SearchUserFilter.filterAnyUserRole: array expected");
                message.filterAnyUserRole = [];
                for (var i = 0; i < object.filterAnyUserRole.length; ++i)
                    switch (object.filterAnyUserRole[i]) {
                    default:
                    case "USER_ROLE_UNSPECIFIED":
                    case 0:
                        message.filterAnyUserRole[i] = 0;
                        break;
                    case "USER_ROLE_PLAIN":
                    case 1:
                        message.filterAnyUserRole[i] = 1;
                        break;
                    case "USER_ROLE_OFFICIAL_WRITER":
                    case 2:
                        message.filterAnyUserRole[i] = 2;
                        break;
                    case "USER_ROLE_BANNED":
                    case 3:
                        message.filterAnyUserRole[i] = 3;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 4:
                        message.filterAnyUserRole[i] = 4;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUserFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchUserFilter
         * @static
         * @param {query_search_types.SearchUserFilter} message SearchUserFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUserFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.filterPublishedStoryTag = [];
                object.filterAnyUserRole = [];
                object.filterPublishedAnyStoryTag = [];
            }
            if (options.defaults) {
                object.includeBlocked = null;
                object.excludeVip = null;
                object.excludeNotVip = null;
                object.filterSearchableStoryCount = null;
            }
            if (message.filterPublishedStoryTag && message.filterPublishedStoryTag.length) {
                object.filterPublishedStoryTag = [];
                for (var j = 0; j < message.filterPublishedStoryTag.length; ++j)
                    object.filterPublishedStoryTag[j] = $root.google.protobuf.StringValue.toObject(message.filterPublishedStoryTag[j], options);
            }
            if (message.includeBlocked != null && message.hasOwnProperty("includeBlocked"))
                object.includeBlocked = $root.google.protobuf.BoolValue.toObject(message.includeBlocked, options);
            if (message.excludeVip != null && message.hasOwnProperty("excludeVip"))
                object.excludeVip = $root.google.protobuf.BoolValue.toObject(message.excludeVip, options);
            if (message.excludeNotVip != null && message.hasOwnProperty("excludeNotVip"))
                object.excludeNotVip = $root.google.protobuf.BoolValue.toObject(message.excludeNotVip, options);
            if (message.filterSearchableStoryCount != null && message.hasOwnProperty("filterSearchableStoryCount"))
                object.filterSearchableStoryCount = $root.types.UInt32Range.toObject(message.filterSearchableStoryCount, options);
            if (message.filterAnyUserRole && message.filterAnyUserRole.length) {
                object.filterAnyUserRole = [];
                for (var j = 0; j < message.filterAnyUserRole.length; ++j)
                    object.filterAnyUserRole[j] = options.enums === String ? $root.query_types.UserRole[message.filterAnyUserRole[j]] : message.filterAnyUserRole[j];
            }
            if (message.filterPublishedAnyStoryTag && message.filterPublishedAnyStoryTag.length) {
                object.filterPublishedAnyStoryTag = [];
                for (var j = 0; j < message.filterPublishedAnyStoryTag.length; ++j)
                    object.filterPublishedAnyStoryTag[j] = $root.google.protobuf.StringValue.toObject(message.filterPublishedAnyStoryTag[j], options);
            }
            return object;
        };

        /**
         * Converts this SearchUserFilter to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchUserFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUserFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUserFilter
         * @function getTypeUrl
         * @memberof query_search_types.SearchUserFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUserFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchUserFilter";
        };

        return SearchUserFilter;
    })();

    query_search_types.SearchSeriesParams = (function() {

        /**
         * Properties of a SearchSeriesParams.
         * @memberof query_search_types
         * @interface ISearchSeriesParams
         * @property {google.protobuf.IStringValue|null} [query] SearchSeriesParams query
         * @property {Array.<query_search_types.SeriesSearchableAttribute>|null} [searchableAttributes] SearchSeriesParams searchableAttributes
         * @property {query_search_types.ISearchSeriesFilter|null} [filter] SearchSeriesParams filter
         * @property {query_search_types.SearchSeriesSortBy|null} [sortBy] SearchSeriesParams sortBy
         */

        /**
         * Constructs a new SearchSeriesParams.
         * @memberof query_search_types
         * @classdesc Represents a SearchSeriesParams.
         * @implements ISearchSeriesParams
         * @constructor
         * @param {query_search_types.ISearchSeriesParams=} [properties] Properties to set
         */
        function SearchSeriesParams(properties) {
            this.searchableAttributes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesParams query.
         * @member {google.protobuf.IStringValue|null|undefined} query
         * @memberof query_search_types.SearchSeriesParams
         * @instance
         */
        SearchSeriesParams.prototype.query = null;

        /**
         * SearchSeriesParams searchableAttributes.
         * @member {Array.<query_search_types.SeriesSearchableAttribute>} searchableAttributes
         * @memberof query_search_types.SearchSeriesParams
         * @instance
         */
        SearchSeriesParams.prototype.searchableAttributes = $util.emptyArray;

        /**
         * SearchSeriesParams filter.
         * @member {query_search_types.ISearchSeriesFilter|null|undefined} filter
         * @memberof query_search_types.SearchSeriesParams
         * @instance
         */
        SearchSeriesParams.prototype.filter = null;

        /**
         * SearchSeriesParams sortBy.
         * @member {query_search_types.SearchSeriesSortBy} sortBy
         * @memberof query_search_types.SearchSeriesParams
         * @instance
         */
        SearchSeriesParams.prototype.sortBy = 0;

        /**
         * Creates a new SearchSeriesParams instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchSeriesParams
         * @static
         * @param {query_search_types.ISearchSeriesParams=} [properties] Properties to set
         * @returns {query_search_types.SearchSeriesParams} SearchSeriesParams instance
         */
        SearchSeriesParams.create = function create(properties) {
            return new SearchSeriesParams(properties);
        };

        /**
         * Encodes the specified SearchSeriesParams message. Does not implicitly {@link query_search_types.SearchSeriesParams.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchSeriesParams
         * @static
         * @param {query_search_types.ISearchSeriesParams} message SearchSeriesParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesParams.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.google.protobuf.StringValue.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.searchableAttributes != null && message.searchableAttributes.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.searchableAttributes.length; ++i)
                    writer.int32(message.searchableAttributes[i]);
                writer.ldelim();
            }
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                $root.query_search_types.SearchSeriesFilter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortBy);
            return writer;
        };

        /**
         * Decodes a SearchSeriesParams message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchSeriesParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchSeriesParams} SearchSeriesParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesParams.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchSeriesParams();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.searchableAttributes && message.searchableAttributes.length))
                        message.searchableAttributes = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.searchableAttributes.push(reader.int32());
                    } else
                        message.searchableAttributes.push(reader.int32());
                    break;
                case 3:
                    message.filter = $root.query_search_types.SearchSeriesFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.sortBy = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesParams message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchSeriesParams
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchSeriesParams} SearchSeriesParams
         */
        SearchSeriesParams.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchSeriesParams)
                return object;
            var message = new $root.query_search_types.SearchSeriesParams();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".query_search_types.SearchSeriesParams.query: object expected");
                message.query = $root.google.protobuf.StringValue.fromObject(object.query);
            }
            if (object.searchableAttributes) {
                if (!Array.isArray(object.searchableAttributes))
                    throw TypeError(".query_search_types.SearchSeriesParams.searchableAttributes: array expected");
                message.searchableAttributes = [];
                for (var i = 0; i < object.searchableAttributes.length; ++i)
                    switch (object.searchableAttributes[i]) {
                    default:
                    case "SERIES_SEARCHABLE_ATTRIBUTE_UNSPECIFIED":
                    case 0:
                        message.searchableAttributes[i] = 0;
                        break;
                    case "SERIES_SEARCHABLE_ATTRIBUTE_TITLE":
                    case 1:
                        message.searchableAttributes[i] = 1;
                        break;
                    case "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE":
                    case 2:
                        message.searchableAttributes[i] = 2;
                        break;
                    case "SERIES_SEARCHABLE_ATTRIBUTE_DESCRIPTION":
                    case 3:
                        message.searchableAttributes[i] = 3;
                        break;
                    case "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION":
                    case 4:
                        message.searchableAttributes[i] = 4;
                        break;
                    case "SERIES_SEARCHABLE_ATTRIBUTE_TAGS":
                    case 5:
                        message.searchableAttributes[i] = 5;
                        break;
                    case "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS":
                    case 6:
                        message.searchableAttributes[i] = 6;
                        break;
                    }
            }
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".query_search_types.SearchSeriesParams.filter: object expected");
                message.filter = $root.query_search_types.SearchSeriesFilter.fromObject(object.filter);
            }
            switch (object.sortBy) {
            case "SEARCH_SERIES_SORT_BY_UNSPECIFIED":
            case 0:
                message.sortBy = 0;
                break;
            case "SEARCH_SERIES_SORT_BY_DEFAULT":
            case 1:
                message.sortBy = 1;
                break;
            case "SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC":
            case 2:
                message.sortBy = 2;
                break;
            case "SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC":
            case 3:
                message.sortBy = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesParams message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchSeriesParams
         * @static
         * @param {query_search_types.SearchSeriesParams} message SearchSeriesParams
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesParams.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.searchableAttributes = [];
            if (options.defaults) {
                object.query = null;
                object.filter = null;
                object.sortBy = options.enums === String ? "SEARCH_SERIES_SORT_BY_UNSPECIFIED" : 0;
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.google.protobuf.StringValue.toObject(message.query, options);
            if (message.searchableAttributes && message.searchableAttributes.length) {
                object.searchableAttributes = [];
                for (var j = 0; j < message.searchableAttributes.length; ++j)
                    object.searchableAttributes[j] = options.enums === String ? $root.query_search_types.SeriesSearchableAttribute[message.searchableAttributes[j]] : message.searchableAttributes[j];
            }
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.query_search_types.SearchSeriesFilter.toObject(message.filter, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.query_search_types.SearchSeriesSortBy[message.sortBy] : message.sortBy;
            return object;
        };

        /**
         * Converts this SearchSeriesParams to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchSeriesParams
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesParams.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesParams
         * @function getTypeUrl
         * @memberof query_search_types.SearchSeriesParams
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchSeriesParams";
        };

        return SearchSeriesParams;
    })();

    /**
     * SeriesSearchableAttribute enum.
     * @name query_search_types.SeriesSearchableAttribute
     * @enum {number}
     * @property {number} SERIES_SEARCHABLE_ATTRIBUTE_UNSPECIFIED=0 SERIES_SEARCHABLE_ATTRIBUTE_UNSPECIFIED value
     * @property {number} SERIES_SEARCHABLE_ATTRIBUTE_TITLE=1 SERIES_SEARCHABLE_ATTRIBUTE_TITLE value
     * @property {number} SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE=2 SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE value
     * @property {number} SERIES_SEARCHABLE_ATTRIBUTE_DESCRIPTION=3 SERIES_SEARCHABLE_ATTRIBUTE_DESCRIPTION value
     * @property {number} SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION=4 SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION value
     * @property {number} SERIES_SEARCHABLE_ATTRIBUTE_TAGS=5 SERIES_SEARCHABLE_ATTRIBUTE_TAGS value
     * @property {number} SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS=6 SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS value
     */
    query_search_types.SeriesSearchableAttribute = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERIES_SEARCHABLE_ATTRIBUTE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SERIES_SEARCHABLE_ATTRIBUTE_TITLE"] = 1;
        values[valuesById[2] = "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE"] = 2;
        values[valuesById[3] = "SERIES_SEARCHABLE_ATTRIBUTE_DESCRIPTION"] = 3;
        values[valuesById[4] = "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION"] = 4;
        values[valuesById[5] = "SERIES_SEARCHABLE_ATTRIBUTE_TAGS"] = 5;
        values[valuesById[6] = "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS"] = 6;
        return values;
    })();

    /**
     * SearchSeriesSortBy enum.
     * @name query_search_types.SearchSeriesSortBy
     * @enum {number}
     * @property {number} SEARCH_SERIES_SORT_BY_UNSPECIFIED=0 SEARCH_SERIES_SORT_BY_UNSPECIFIED value
     * @property {number} SEARCH_SERIES_SORT_BY_DEFAULT=1 SEARCH_SERIES_SORT_BY_DEFAULT value
     * @property {number} SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC=2 SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC value
     * @property {number} SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC=3 SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC value
     */
    query_search_types.SearchSeriesSortBy = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEARCH_SERIES_SORT_BY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SEARCH_SERIES_SORT_BY_DEFAULT"] = 1;
        values[valuesById[2] = "SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC"] = 2;
        values[valuesById[3] = "SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC"] = 3;
        return values;
    })();

    query_search_types.SearchSeriesFilter = (function() {

        /**
         * Properties of a SearchSeriesFilter.
         * @memberof query_search_types
         * @interface ISearchSeriesFilter
         * @property {Array.<google.protobuf.IStringValue>|null} [filterTag] SearchSeriesFilter filterTag
         * @property {Array.<google.protobuf.IStringValue>|null} [filterAnyUserId] SearchSeriesFilter filterAnyUserId
         * @property {google.protobuf.IStringValue|null} [filterSeriesGroupId] SearchSeriesFilter filterSeriesGroupId
         * @property {google.protobuf.IBoolValue|null} [includeBlocked] SearchSeriesFilter includeBlocked
         * @property {google.protobuf.IBoolValue|null} [excludeOfficial] SearchSeriesFilter excludeOfficial
         * @property {google.protobuf.IBoolValue|null} [excludeNotOfficial] SearchSeriesFilter excludeNotOfficial
         * @property {google.protobuf.IBoolValue|null} [excludeCompleted] SearchSeriesFilter excludeCompleted
         * @property {google.protobuf.IBoolValue|null} [excludeNotCompleted] SearchSeriesFilter excludeNotCompleted
         * @property {types.IUInt32Range|null} [filterSearchableStoryCount] SearchSeriesFilter filterSearchableStoryCount
         * @property {google.protobuf.IBoolValue|null} [excludeContainingChatNovel] SearchSeriesFilter excludeContainingChatNovel
         * @property {google.protobuf.IBoolValue|null} [excludeContainingNovel] SearchSeriesFilter excludeContainingNovel
         * @property {Array.<types.SharedWithStatus>|null} [filterAnyStatus] SearchSeriesFilter filterAnyStatus
         * @property {Array.<query_types.UserRole>|null} [filterAnyUserRole] SearchSeriesFilter filterAnyUserRole
         */

        /**
         * Constructs a new SearchSeriesFilter.
         * @memberof query_search_types
         * @classdesc Represents a SearchSeriesFilter.
         * @implements ISearchSeriesFilter
         * @constructor
         * @param {query_search_types.ISearchSeriesFilter=} [properties] Properties to set
         */
        function SearchSeriesFilter(properties) {
            this.filterTag = [];
            this.filterAnyUserId = [];
            this.filterAnyStatus = [];
            this.filterAnyUserRole = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesFilter filterTag.
         * @member {Array.<google.protobuf.IStringValue>} filterTag
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.filterTag = $util.emptyArray;

        /**
         * SearchSeriesFilter filterAnyUserId.
         * @member {Array.<google.protobuf.IStringValue>} filterAnyUserId
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.filterAnyUserId = $util.emptyArray;

        /**
         * SearchSeriesFilter filterSeriesGroupId.
         * @member {google.protobuf.IStringValue|null|undefined} filterSeriesGroupId
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.filterSeriesGroupId = null;

        /**
         * SearchSeriesFilter includeBlocked.
         * @member {google.protobuf.IBoolValue|null|undefined} includeBlocked
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.includeBlocked = null;

        /**
         * SearchSeriesFilter excludeOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeOfficial
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.excludeOfficial = null;

        /**
         * SearchSeriesFilter excludeNotOfficial.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotOfficial
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.excludeNotOfficial = null;

        /**
         * SearchSeriesFilter excludeCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeCompleted
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.excludeCompleted = null;

        /**
         * SearchSeriesFilter excludeNotCompleted.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeNotCompleted
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.excludeNotCompleted = null;

        /**
         * SearchSeriesFilter filterSearchableStoryCount.
         * @member {types.IUInt32Range|null|undefined} filterSearchableStoryCount
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.filterSearchableStoryCount = null;

        /**
         * SearchSeriesFilter excludeContainingChatNovel.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeContainingChatNovel
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.excludeContainingChatNovel = null;

        /**
         * SearchSeriesFilter excludeContainingNovel.
         * @member {google.protobuf.IBoolValue|null|undefined} excludeContainingNovel
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.excludeContainingNovel = null;

        /**
         * SearchSeriesFilter filterAnyStatus.
         * @member {Array.<types.SharedWithStatus>} filterAnyStatus
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.filterAnyStatus = $util.emptyArray;

        /**
         * SearchSeriesFilter filterAnyUserRole.
         * @member {Array.<query_types.UserRole>} filterAnyUserRole
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         */
        SearchSeriesFilter.prototype.filterAnyUserRole = $util.emptyArray;

        /**
         * Creates a new SearchSeriesFilter instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchSeriesFilter
         * @static
         * @param {query_search_types.ISearchSeriesFilter=} [properties] Properties to set
         * @returns {query_search_types.SearchSeriesFilter} SearchSeriesFilter instance
         */
        SearchSeriesFilter.create = function create(properties) {
            return new SearchSeriesFilter(properties);
        };

        /**
         * Encodes the specified SearchSeriesFilter message. Does not implicitly {@link query_search_types.SearchSeriesFilter.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchSeriesFilter
         * @static
         * @param {query_search_types.ISearchSeriesFilter} message SearchSeriesFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterTag != null && message.filterTag.length)
                for (var i = 0; i < message.filterTag.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.filterTag[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.filterAnyUserId != null && message.filterAnyUserId.length)
                for (var i = 0; i < message.filterAnyUserId.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.filterAnyUserId[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.filterSeriesGroupId != null && Object.hasOwnProperty.call(message, "filterSeriesGroupId"))
                $root.google.protobuf.StringValue.encode(message.filterSeriesGroupId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.includeBlocked != null && Object.hasOwnProperty.call(message, "includeBlocked"))
                $root.google.protobuf.BoolValue.encode(message.includeBlocked, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.excludeOfficial != null && Object.hasOwnProperty.call(message, "excludeOfficial"))
                $root.google.protobuf.BoolValue.encode(message.excludeOfficial, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.excludeNotOfficial != null && Object.hasOwnProperty.call(message, "excludeNotOfficial"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotOfficial, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.excludeCompleted != null && Object.hasOwnProperty.call(message, "excludeCompleted"))
                $root.google.protobuf.BoolValue.encode(message.excludeCompleted, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.excludeNotCompleted != null && Object.hasOwnProperty.call(message, "excludeNotCompleted"))
                $root.google.protobuf.BoolValue.encode(message.excludeNotCompleted, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.filterSearchableStoryCount != null && Object.hasOwnProperty.call(message, "filterSearchableStoryCount"))
                $root.types.UInt32Range.encode(message.filterSearchableStoryCount, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.filterAnyStatus != null && message.filterAnyStatus.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.filterAnyStatus.length; ++i)
                    writer.int32(message.filterAnyStatus[i]);
                writer.ldelim();
            }
            if (message.filterAnyUserRole != null && message.filterAnyUserRole.length) {
                writer.uint32(/* id 11, wireType 2 =*/90).fork();
                for (var i = 0; i < message.filterAnyUserRole.length; ++i)
                    writer.int32(message.filterAnyUserRole[i]);
                writer.ldelim();
            }
            if (message.excludeContainingChatNovel != null && Object.hasOwnProperty.call(message, "excludeContainingChatNovel"))
                $root.google.protobuf.BoolValue.encode(message.excludeContainingChatNovel, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.excludeContainingNovel != null && Object.hasOwnProperty.call(message, "excludeContainingNovel"))
                $root.google.protobuf.BoolValue.encode(message.excludeContainingNovel, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesFilter message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchSeriesFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchSeriesFilter} SearchSeriesFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchSeriesFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.filterTag && message.filterTag.length))
                        message.filterTag = [];
                    message.filterTag.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.filterAnyUserId && message.filterAnyUserId.length))
                        message.filterAnyUserId = [];
                    message.filterAnyUserId.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.filterSeriesGroupId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.includeBlocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.excludeOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.excludeNotOfficial = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.excludeCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.excludeNotCompleted = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.filterSearchableStoryCount = $root.types.UInt32Range.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.excludeContainingChatNovel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.excludeContainingNovel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    if (!(message.filterAnyStatus && message.filterAnyStatus.length))
                        message.filterAnyStatus = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyStatus.push(reader.int32());
                    } else
                        message.filterAnyStatus.push(reader.int32());
                    break;
                case 11:
                    if (!(message.filterAnyUserRole && message.filterAnyUserRole.length))
                        message.filterAnyUserRole = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyUserRole.push(reader.int32());
                    } else
                        message.filterAnyUserRole.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchSeriesFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchSeriesFilter} SearchSeriesFilter
         */
        SearchSeriesFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchSeriesFilter)
                return object;
            var message = new $root.query_search_types.SearchSeriesFilter();
            if (object.filterTag) {
                if (!Array.isArray(object.filterTag))
                    throw TypeError(".query_search_types.SearchSeriesFilter.filterTag: array expected");
                message.filterTag = [];
                for (var i = 0; i < object.filterTag.length; ++i) {
                    if (typeof object.filterTag[i] !== "object")
                        throw TypeError(".query_search_types.SearchSeriesFilter.filterTag: object expected");
                    message.filterTag[i] = $root.google.protobuf.StringValue.fromObject(object.filterTag[i]);
                }
            }
            if (object.filterAnyUserId) {
                if (!Array.isArray(object.filterAnyUserId))
                    throw TypeError(".query_search_types.SearchSeriesFilter.filterAnyUserId: array expected");
                message.filterAnyUserId = [];
                for (var i = 0; i < object.filterAnyUserId.length; ++i) {
                    if (typeof object.filterAnyUserId[i] !== "object")
                        throw TypeError(".query_search_types.SearchSeriesFilter.filterAnyUserId: object expected");
                    message.filterAnyUserId[i] = $root.google.protobuf.StringValue.fromObject(object.filterAnyUserId[i]);
                }
            }
            if (object.filterSeriesGroupId != null) {
                if (typeof object.filterSeriesGroupId !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.filterSeriesGroupId: object expected");
                message.filterSeriesGroupId = $root.google.protobuf.StringValue.fromObject(object.filterSeriesGroupId);
            }
            if (object.includeBlocked != null) {
                if (typeof object.includeBlocked !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.includeBlocked: object expected");
                message.includeBlocked = $root.google.protobuf.BoolValue.fromObject(object.includeBlocked);
            }
            if (object.excludeOfficial != null) {
                if (typeof object.excludeOfficial !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.excludeOfficial: object expected");
                message.excludeOfficial = $root.google.protobuf.BoolValue.fromObject(object.excludeOfficial);
            }
            if (object.excludeNotOfficial != null) {
                if (typeof object.excludeNotOfficial !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.excludeNotOfficial: object expected");
                message.excludeNotOfficial = $root.google.protobuf.BoolValue.fromObject(object.excludeNotOfficial);
            }
            if (object.excludeCompleted != null) {
                if (typeof object.excludeCompleted !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.excludeCompleted: object expected");
                message.excludeCompleted = $root.google.protobuf.BoolValue.fromObject(object.excludeCompleted);
            }
            if (object.excludeNotCompleted != null) {
                if (typeof object.excludeNotCompleted !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.excludeNotCompleted: object expected");
                message.excludeNotCompleted = $root.google.protobuf.BoolValue.fromObject(object.excludeNotCompleted);
            }
            if (object.filterSearchableStoryCount != null) {
                if (typeof object.filterSearchableStoryCount !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.filterSearchableStoryCount: object expected");
                message.filterSearchableStoryCount = $root.types.UInt32Range.fromObject(object.filterSearchableStoryCount);
            }
            if (object.excludeContainingChatNovel != null) {
                if (typeof object.excludeContainingChatNovel !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.excludeContainingChatNovel: object expected");
                message.excludeContainingChatNovel = $root.google.protobuf.BoolValue.fromObject(object.excludeContainingChatNovel);
            }
            if (object.excludeContainingNovel != null) {
                if (typeof object.excludeContainingNovel !== "object")
                    throw TypeError(".query_search_types.SearchSeriesFilter.excludeContainingNovel: object expected");
                message.excludeContainingNovel = $root.google.protobuf.BoolValue.fromObject(object.excludeContainingNovel);
            }
            if (object.filterAnyStatus) {
                if (!Array.isArray(object.filterAnyStatus))
                    throw TypeError(".query_search_types.SearchSeriesFilter.filterAnyStatus: array expected");
                message.filterAnyStatus = [];
                for (var i = 0; i < object.filterAnyStatus.length; ++i)
                    switch (object.filterAnyStatus[i]) {
                    default:
                    case "SHARED_WITH_STATUS_UNKNOWN":
                    case 0:
                        message.filterAnyStatus[i] = 0;
                        break;
                    case "SHARED_WITH_NOBODY":
                    case 1:
                        message.filterAnyStatus[i] = 1;
                        break;
                    case "SHARED_WITH_DIRECT_LINK":
                    case 2:
                        message.filterAnyStatus[i] = 2;
                        break;
                    case "SHARED_WITH_PUBLIC":
                    case 3:
                        message.filterAnyStatus[i] = 3;
                        break;
                    case "SHARED_WITH_FOLLOWER":
                    case 4:
                        message.filterAnyStatus[i] = 4;
                        break;
                    case "SHARED_WITH_MUTUAL_FOLLOWER":
                    case 5:
                        message.filterAnyStatus[i] = 5;
                        break;
                    }
            }
            if (object.filterAnyUserRole) {
                if (!Array.isArray(object.filterAnyUserRole))
                    throw TypeError(".query_search_types.SearchSeriesFilter.filterAnyUserRole: array expected");
                message.filterAnyUserRole = [];
                for (var i = 0; i < object.filterAnyUserRole.length; ++i)
                    switch (object.filterAnyUserRole[i]) {
                    default:
                    case "USER_ROLE_UNSPECIFIED":
                    case 0:
                        message.filterAnyUserRole[i] = 0;
                        break;
                    case "USER_ROLE_PLAIN":
                    case 1:
                        message.filterAnyUserRole[i] = 1;
                        break;
                    case "USER_ROLE_OFFICIAL_WRITER":
                    case 2:
                        message.filterAnyUserRole[i] = 2;
                        break;
                    case "USER_ROLE_BANNED":
                    case 3:
                        message.filterAnyUserRole[i] = 3;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 4:
                        message.filterAnyUserRole[i] = 4;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchSeriesFilter
         * @static
         * @param {query_search_types.SearchSeriesFilter} message SearchSeriesFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.filterTag = [];
                object.filterAnyUserId = [];
                object.filterAnyStatus = [];
                object.filterAnyUserRole = [];
            }
            if (options.defaults) {
                object.filterSeriesGroupId = null;
                object.includeBlocked = null;
                object.excludeOfficial = null;
                object.excludeNotOfficial = null;
                object.excludeCompleted = null;
                object.excludeNotCompleted = null;
                object.filterSearchableStoryCount = null;
                object.excludeContainingChatNovel = null;
                object.excludeContainingNovel = null;
            }
            if (message.filterTag && message.filterTag.length) {
                object.filterTag = [];
                for (var j = 0; j < message.filterTag.length; ++j)
                    object.filterTag[j] = $root.google.protobuf.StringValue.toObject(message.filterTag[j], options);
            }
            if (message.filterAnyUserId && message.filterAnyUserId.length) {
                object.filterAnyUserId = [];
                for (var j = 0; j < message.filterAnyUserId.length; ++j)
                    object.filterAnyUserId[j] = $root.google.protobuf.StringValue.toObject(message.filterAnyUserId[j], options);
            }
            if (message.filterSeriesGroupId != null && message.hasOwnProperty("filterSeriesGroupId"))
                object.filterSeriesGroupId = $root.google.protobuf.StringValue.toObject(message.filterSeriesGroupId, options);
            if (message.includeBlocked != null && message.hasOwnProperty("includeBlocked"))
                object.includeBlocked = $root.google.protobuf.BoolValue.toObject(message.includeBlocked, options);
            if (message.excludeOfficial != null && message.hasOwnProperty("excludeOfficial"))
                object.excludeOfficial = $root.google.protobuf.BoolValue.toObject(message.excludeOfficial, options);
            if (message.excludeNotOfficial != null && message.hasOwnProperty("excludeNotOfficial"))
                object.excludeNotOfficial = $root.google.protobuf.BoolValue.toObject(message.excludeNotOfficial, options);
            if (message.excludeCompleted != null && message.hasOwnProperty("excludeCompleted"))
                object.excludeCompleted = $root.google.protobuf.BoolValue.toObject(message.excludeCompleted, options);
            if (message.excludeNotCompleted != null && message.hasOwnProperty("excludeNotCompleted"))
                object.excludeNotCompleted = $root.google.protobuf.BoolValue.toObject(message.excludeNotCompleted, options);
            if (message.filterSearchableStoryCount != null && message.hasOwnProperty("filterSearchableStoryCount"))
                object.filterSearchableStoryCount = $root.types.UInt32Range.toObject(message.filterSearchableStoryCount, options);
            if (message.filterAnyStatus && message.filterAnyStatus.length) {
                object.filterAnyStatus = [];
                for (var j = 0; j < message.filterAnyStatus.length; ++j)
                    object.filterAnyStatus[j] = options.enums === String ? $root.types.SharedWithStatus[message.filterAnyStatus[j]] : message.filterAnyStatus[j];
            }
            if (message.filterAnyUserRole && message.filterAnyUserRole.length) {
                object.filterAnyUserRole = [];
                for (var j = 0; j < message.filterAnyUserRole.length; ++j)
                    object.filterAnyUserRole[j] = options.enums === String ? $root.query_types.UserRole[message.filterAnyUserRole[j]] : message.filterAnyUserRole[j];
            }
            if (message.excludeContainingChatNovel != null && message.hasOwnProperty("excludeContainingChatNovel"))
                object.excludeContainingChatNovel = $root.google.protobuf.BoolValue.toObject(message.excludeContainingChatNovel, options);
            if (message.excludeContainingNovel != null && message.hasOwnProperty("excludeContainingNovel"))
                object.excludeContainingNovel = $root.google.protobuf.BoolValue.toObject(message.excludeContainingNovel, options);
            return object;
        };

        /**
         * Converts this SearchSeriesFilter to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchSeriesFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesFilter
         * @function getTypeUrl
         * @memberof query_search_types.SearchSeriesFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchSeriesFilter";
        };

        return SearchSeriesFilter;
    })();

    query_search_types.SearchSeriesGroupParams = (function() {

        /**
         * Properties of a SearchSeriesGroupParams.
         * @memberof query_search_types
         * @interface ISearchSeriesGroupParams
         * @property {google.protobuf.IStringValue|null} [query] SearchSeriesGroupParams query
         * @property {Array.<query_search_types.SeriesGroupSearchableAttribute>|null} [searchableAttributes] SearchSeriesGroupParams searchableAttributes
         * @property {query_search_types.ISearchSeriesGroupFilter|null} [filter] SearchSeriesGroupParams filter
         * @property {query_search_types.SearchSeriesGroupSortBy|null} [sortBy] SearchSeriesGroupParams sortBy
         */

        /**
         * Constructs a new SearchSeriesGroupParams.
         * @memberof query_search_types
         * @classdesc Represents a SearchSeriesGroupParams.
         * @implements ISearchSeriesGroupParams
         * @constructor
         * @param {query_search_types.ISearchSeriesGroupParams=} [properties] Properties to set
         */
        function SearchSeriesGroupParams(properties) {
            this.searchableAttributes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesGroupParams query.
         * @member {google.protobuf.IStringValue|null|undefined} query
         * @memberof query_search_types.SearchSeriesGroupParams
         * @instance
         */
        SearchSeriesGroupParams.prototype.query = null;

        /**
         * SearchSeriesGroupParams searchableAttributes.
         * @member {Array.<query_search_types.SeriesGroupSearchableAttribute>} searchableAttributes
         * @memberof query_search_types.SearchSeriesGroupParams
         * @instance
         */
        SearchSeriesGroupParams.prototype.searchableAttributes = $util.emptyArray;

        /**
         * SearchSeriesGroupParams filter.
         * @member {query_search_types.ISearchSeriesGroupFilter|null|undefined} filter
         * @memberof query_search_types.SearchSeriesGroupParams
         * @instance
         */
        SearchSeriesGroupParams.prototype.filter = null;

        /**
         * SearchSeriesGroupParams sortBy.
         * @member {query_search_types.SearchSeriesGroupSortBy} sortBy
         * @memberof query_search_types.SearchSeriesGroupParams
         * @instance
         */
        SearchSeriesGroupParams.prototype.sortBy = 0;

        /**
         * Creates a new SearchSeriesGroupParams instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchSeriesGroupParams
         * @static
         * @param {query_search_types.ISearchSeriesGroupParams=} [properties] Properties to set
         * @returns {query_search_types.SearchSeriesGroupParams} SearchSeriesGroupParams instance
         */
        SearchSeriesGroupParams.create = function create(properties) {
            return new SearchSeriesGroupParams(properties);
        };

        /**
         * Encodes the specified SearchSeriesGroupParams message. Does not implicitly {@link query_search_types.SearchSeriesGroupParams.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchSeriesGroupParams
         * @static
         * @param {query_search_types.ISearchSeriesGroupParams} message SearchSeriesGroupParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesGroupParams.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.google.protobuf.StringValue.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.searchableAttributes != null && message.searchableAttributes.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.searchableAttributes.length; ++i)
                    writer.int32(message.searchableAttributes[i]);
                writer.ldelim();
            }
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                $root.query_search_types.SearchSeriesGroupFilter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortBy);
            return writer;
        };

        /**
         * Decodes a SearchSeriesGroupParams message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchSeriesGroupParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchSeriesGroupParams} SearchSeriesGroupParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesGroupParams.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchSeriesGroupParams();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.searchableAttributes && message.searchableAttributes.length))
                        message.searchableAttributes = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.searchableAttributes.push(reader.int32());
                    } else
                        message.searchableAttributes.push(reader.int32());
                    break;
                case 3:
                    message.filter = $root.query_search_types.SearchSeriesGroupFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.sortBy = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesGroupParams message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchSeriesGroupParams
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchSeriesGroupParams} SearchSeriesGroupParams
         */
        SearchSeriesGroupParams.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchSeriesGroupParams)
                return object;
            var message = new $root.query_search_types.SearchSeriesGroupParams();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".query_search_types.SearchSeriesGroupParams.query: object expected");
                message.query = $root.google.protobuf.StringValue.fromObject(object.query);
            }
            if (object.searchableAttributes) {
                if (!Array.isArray(object.searchableAttributes))
                    throw TypeError(".query_search_types.SearchSeriesGroupParams.searchableAttributes: array expected");
                message.searchableAttributes = [];
                for (var i = 0; i < object.searchableAttributes.length; ++i)
                    switch (object.searchableAttributes[i]) {
                    default:
                    case "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_UNSPECIFIED":
                    case 0:
                        message.searchableAttributes[i] = 0;
                        break;
                    case "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_TITLE":
                    case 1:
                        message.searchableAttributes[i] = 1;
                        break;
                    case "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE":
                    case 2:
                        message.searchableAttributes[i] = 2;
                        break;
                    }
            }
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".query_search_types.SearchSeriesGroupParams.filter: object expected");
                message.filter = $root.query_search_types.SearchSeriesGroupFilter.fromObject(object.filter);
            }
            switch (object.sortBy) {
            case "SEARCH_SERIES_GROUP_SORT_BY_UNSPECIFIED":
            case 0:
                message.sortBy = 0;
                break;
            case "SEARCH_SERIES_GROUP_SORT_BY_DEFAULT":
            case 1:
                message.sortBy = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesGroupParams message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchSeriesGroupParams
         * @static
         * @param {query_search_types.SearchSeriesGroupParams} message SearchSeriesGroupParams
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesGroupParams.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.searchableAttributes = [];
            if (options.defaults) {
                object.query = null;
                object.filter = null;
                object.sortBy = options.enums === String ? "SEARCH_SERIES_GROUP_SORT_BY_UNSPECIFIED" : 0;
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.google.protobuf.StringValue.toObject(message.query, options);
            if (message.searchableAttributes && message.searchableAttributes.length) {
                object.searchableAttributes = [];
                for (var j = 0; j < message.searchableAttributes.length; ++j)
                    object.searchableAttributes[j] = options.enums === String ? $root.query_search_types.SeriesGroupSearchableAttribute[message.searchableAttributes[j]] : message.searchableAttributes[j];
            }
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.query_search_types.SearchSeriesGroupFilter.toObject(message.filter, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.query_search_types.SearchSeriesGroupSortBy[message.sortBy] : message.sortBy;
            return object;
        };

        /**
         * Converts this SearchSeriesGroupParams to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchSeriesGroupParams
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesGroupParams.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesGroupParams
         * @function getTypeUrl
         * @memberof query_search_types.SearchSeriesGroupParams
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesGroupParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchSeriesGroupParams";
        };

        return SearchSeriesGroupParams;
    })();

    /**
     * SeriesGroupSearchableAttribute enum.
     * @name query_search_types.SeriesGroupSearchableAttribute
     * @enum {number}
     * @property {number} SERIES_GROUP_SEARCHABLE_ATTRIBUTE_UNSPECIFIED=0 SERIES_GROUP_SEARCHABLE_ATTRIBUTE_UNSPECIFIED value
     * @property {number} SERIES_GROUP_SEARCHABLE_ATTRIBUTE_TITLE=1 SERIES_GROUP_SEARCHABLE_ATTRIBUTE_TITLE value
     * @property {number} SERIES_GROUP_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE=2 SERIES_GROUP_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE value
     */
    query_search_types.SeriesGroupSearchableAttribute = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_TITLE"] = 1;
        values[valuesById[2] = "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE"] = 2;
        return values;
    })();

    /**
     * SearchSeriesGroupSortBy enum.
     * @name query_search_types.SearchSeriesGroupSortBy
     * @enum {number}
     * @property {number} SEARCH_SERIES_GROUP_SORT_BY_UNSPECIFIED=0 SEARCH_SERIES_GROUP_SORT_BY_UNSPECIFIED value
     * @property {number} SEARCH_SERIES_GROUP_SORT_BY_DEFAULT=1 SEARCH_SERIES_GROUP_SORT_BY_DEFAULT value
     */
    query_search_types.SearchSeriesGroupSortBy = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEARCH_SERIES_GROUP_SORT_BY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SEARCH_SERIES_GROUP_SORT_BY_DEFAULT"] = 1;
        return values;
    })();

    query_search_types.SearchSeriesGroupFilter = (function() {

        /**
         * Properties of a SearchSeriesGroupFilter.
         * @memberof query_search_types
         * @interface ISearchSeriesGroupFilter
         * @property {Array.<google.protobuf.IStringValue>|null} [filterAnyUserId] SearchSeriesGroupFilter filterAnyUserId
         * @property {google.protobuf.IBoolValue|null} [includeBlocked] SearchSeriesGroupFilter includeBlocked
         * @property {Array.<types.SharedWithStatus>|null} [filterAnyStatus] SearchSeriesGroupFilter filterAnyStatus
         * @property {Array.<query_types.UserRole>|null} [filterAnyUserRole] SearchSeriesGroupFilter filterAnyUserRole
         */

        /**
         * Constructs a new SearchSeriesGroupFilter.
         * @memberof query_search_types
         * @classdesc Represents a SearchSeriesGroupFilter.
         * @implements ISearchSeriesGroupFilter
         * @constructor
         * @param {query_search_types.ISearchSeriesGroupFilter=} [properties] Properties to set
         */
        function SearchSeriesGroupFilter(properties) {
            this.filterAnyUserId = [];
            this.filterAnyStatus = [];
            this.filterAnyUserRole = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesGroupFilter filterAnyUserId.
         * @member {Array.<google.protobuf.IStringValue>} filterAnyUserId
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @instance
         */
        SearchSeriesGroupFilter.prototype.filterAnyUserId = $util.emptyArray;

        /**
         * SearchSeriesGroupFilter includeBlocked.
         * @member {google.protobuf.IBoolValue|null|undefined} includeBlocked
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @instance
         */
        SearchSeriesGroupFilter.prototype.includeBlocked = null;

        /**
         * SearchSeriesGroupFilter filterAnyStatus.
         * @member {Array.<types.SharedWithStatus>} filterAnyStatus
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @instance
         */
        SearchSeriesGroupFilter.prototype.filterAnyStatus = $util.emptyArray;

        /**
         * SearchSeriesGroupFilter filterAnyUserRole.
         * @member {Array.<query_types.UserRole>} filterAnyUserRole
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @instance
         */
        SearchSeriesGroupFilter.prototype.filterAnyUserRole = $util.emptyArray;

        /**
         * Creates a new SearchSeriesGroupFilter instance using the specified properties.
         * @function create
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @static
         * @param {query_search_types.ISearchSeriesGroupFilter=} [properties] Properties to set
         * @returns {query_search_types.SearchSeriesGroupFilter} SearchSeriesGroupFilter instance
         */
        SearchSeriesGroupFilter.create = function create(properties) {
            return new SearchSeriesGroupFilter(properties);
        };

        /**
         * Encodes the specified SearchSeriesGroupFilter message. Does not implicitly {@link query_search_types.SearchSeriesGroupFilter.verify|verify} messages.
         * @function encode
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @static
         * @param {query_search_types.ISearchSeriesGroupFilter} message SearchSeriesGroupFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesGroupFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterAnyUserId != null && message.filterAnyUserId.length)
                for (var i = 0; i < message.filterAnyUserId.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.filterAnyUserId[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.includeBlocked != null && Object.hasOwnProperty.call(message, "includeBlocked"))
                $root.google.protobuf.BoolValue.encode(message.includeBlocked, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.filterAnyStatus != null && message.filterAnyStatus.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.filterAnyStatus.length; ++i)
                    writer.int32(message.filterAnyStatus[i]);
                writer.ldelim();
            }
            if (message.filterAnyUserRole != null && message.filterAnyUserRole.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.filterAnyUserRole.length; ++i)
                    writer.int32(message.filterAnyUserRole[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a SearchSeriesGroupFilter message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_types.SearchSeriesGroupFilter} SearchSeriesGroupFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesGroupFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_types.SearchSeriesGroupFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.filterAnyUserId && message.filterAnyUserId.length))
                        message.filterAnyUserId = [];
                    message.filterAnyUserId.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.includeBlocked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.filterAnyStatus && message.filterAnyStatus.length))
                        message.filterAnyStatus = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyStatus.push(reader.int32());
                    } else
                        message.filterAnyStatus.push(reader.int32());
                    break;
                case 4:
                    if (!(message.filterAnyUserRole && message.filterAnyUserRole.length))
                        message.filterAnyUserRole = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.filterAnyUserRole.push(reader.int32());
                    } else
                        message.filterAnyUserRole.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesGroupFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_types.SearchSeriesGroupFilter} SearchSeriesGroupFilter
         */
        SearchSeriesGroupFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_types.SearchSeriesGroupFilter)
                return object;
            var message = new $root.query_search_types.SearchSeriesGroupFilter();
            if (object.filterAnyUserId) {
                if (!Array.isArray(object.filterAnyUserId))
                    throw TypeError(".query_search_types.SearchSeriesGroupFilter.filterAnyUserId: array expected");
                message.filterAnyUserId = [];
                for (var i = 0; i < object.filterAnyUserId.length; ++i) {
                    if (typeof object.filterAnyUserId[i] !== "object")
                        throw TypeError(".query_search_types.SearchSeriesGroupFilter.filterAnyUserId: object expected");
                    message.filterAnyUserId[i] = $root.google.protobuf.StringValue.fromObject(object.filterAnyUserId[i]);
                }
            }
            if (object.includeBlocked != null) {
                if (typeof object.includeBlocked !== "object")
                    throw TypeError(".query_search_types.SearchSeriesGroupFilter.includeBlocked: object expected");
                message.includeBlocked = $root.google.protobuf.BoolValue.fromObject(object.includeBlocked);
            }
            if (object.filterAnyStatus) {
                if (!Array.isArray(object.filterAnyStatus))
                    throw TypeError(".query_search_types.SearchSeriesGroupFilter.filterAnyStatus: array expected");
                message.filterAnyStatus = [];
                for (var i = 0; i < object.filterAnyStatus.length; ++i)
                    switch (object.filterAnyStatus[i]) {
                    default:
                    case "SHARED_WITH_STATUS_UNKNOWN":
                    case 0:
                        message.filterAnyStatus[i] = 0;
                        break;
                    case "SHARED_WITH_NOBODY":
                    case 1:
                        message.filterAnyStatus[i] = 1;
                        break;
                    case "SHARED_WITH_DIRECT_LINK":
                    case 2:
                        message.filterAnyStatus[i] = 2;
                        break;
                    case "SHARED_WITH_PUBLIC":
                    case 3:
                        message.filterAnyStatus[i] = 3;
                        break;
                    case "SHARED_WITH_FOLLOWER":
                    case 4:
                        message.filterAnyStatus[i] = 4;
                        break;
                    case "SHARED_WITH_MUTUAL_FOLLOWER":
                    case 5:
                        message.filterAnyStatus[i] = 5;
                        break;
                    }
            }
            if (object.filterAnyUserRole) {
                if (!Array.isArray(object.filterAnyUserRole))
                    throw TypeError(".query_search_types.SearchSeriesGroupFilter.filterAnyUserRole: array expected");
                message.filterAnyUserRole = [];
                for (var i = 0; i < object.filterAnyUserRole.length; ++i)
                    switch (object.filterAnyUserRole[i]) {
                    default:
                    case "USER_ROLE_UNSPECIFIED":
                    case 0:
                        message.filterAnyUserRole[i] = 0;
                        break;
                    case "USER_ROLE_PLAIN":
                    case 1:
                        message.filterAnyUserRole[i] = 1;
                        break;
                    case "USER_ROLE_OFFICIAL_WRITER":
                    case 2:
                        message.filterAnyUserRole[i] = 2;
                        break;
                    case "USER_ROLE_BANNED":
                    case 3:
                        message.filterAnyUserRole[i] = 3;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 4:
                        message.filterAnyUserRole[i] = 4;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesGroupFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @static
         * @param {query_search_types.SearchSeriesGroupFilter} message SearchSeriesGroupFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesGroupFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.filterAnyUserId = [];
                object.filterAnyStatus = [];
                object.filterAnyUserRole = [];
            }
            if (options.defaults)
                object.includeBlocked = null;
            if (message.filterAnyUserId && message.filterAnyUserId.length) {
                object.filterAnyUserId = [];
                for (var j = 0; j < message.filterAnyUserId.length; ++j)
                    object.filterAnyUserId[j] = $root.google.protobuf.StringValue.toObject(message.filterAnyUserId[j], options);
            }
            if (message.includeBlocked != null && message.hasOwnProperty("includeBlocked"))
                object.includeBlocked = $root.google.protobuf.BoolValue.toObject(message.includeBlocked, options);
            if (message.filterAnyStatus && message.filterAnyStatus.length) {
                object.filterAnyStatus = [];
                for (var j = 0; j < message.filterAnyStatus.length; ++j)
                    object.filterAnyStatus[j] = options.enums === String ? $root.types.SharedWithStatus[message.filterAnyStatus[j]] : message.filterAnyStatus[j];
            }
            if (message.filterAnyUserRole && message.filterAnyUserRole.length) {
                object.filterAnyUserRole = [];
                for (var j = 0; j < message.filterAnyUserRole.length; ++j)
                    object.filterAnyUserRole[j] = options.enums === String ? $root.query_types.UserRole[message.filterAnyUserRole[j]] : message.filterAnyUserRole[j];
            }
            return object;
        };

        /**
         * Converts this SearchSeriesGroupFilter to JSON.
         * @function toJSON
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesGroupFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesGroupFilter
         * @function getTypeUrl
         * @memberof query_search_types.SearchSeriesGroupFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesGroupFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_types.SearchSeriesGroupFilter";
        };

        return SearchSeriesGroupFilter;
    })();

    return query_search_types;
})();

$root.query_series = (function() {

    /**
     * Namespace query_series.
     * @exports query_series
     * @namespace
     */
    var query_series = {};

    query_series.PublishScheduleResponse = (function() {

        /**
         * Properties of a PublishScheduleResponse.
         * @memberof query_series
         * @interface IPublishScheduleResponse
         * @property {query_types.PublishSchedule|null} [schedule] PublishScheduleResponse schedule
         * @property {google.protobuf.IStringValue|null} [text] PublishScheduleResponse text
         */

        /**
         * Constructs a new PublishScheduleResponse.
         * @memberof query_series
         * @classdesc Represents a PublishScheduleResponse.
         * @implements IPublishScheduleResponse
         * @constructor
         * @param {query_series.IPublishScheduleResponse=} [properties] Properties to set
         */
        function PublishScheduleResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublishScheduleResponse schedule.
         * @member {query_types.PublishSchedule} schedule
         * @memberof query_series.PublishScheduleResponse
         * @instance
         */
        PublishScheduleResponse.prototype.schedule = 0;

        /**
         * PublishScheduleResponse text.
         * @member {google.protobuf.IStringValue|null|undefined} text
         * @memberof query_series.PublishScheduleResponse
         * @instance
         */
        PublishScheduleResponse.prototype.text = null;

        /**
         * Creates a new PublishScheduleResponse instance using the specified properties.
         * @function create
         * @memberof query_series.PublishScheduleResponse
         * @static
         * @param {query_series.IPublishScheduleResponse=} [properties] Properties to set
         * @returns {query_series.PublishScheduleResponse} PublishScheduleResponse instance
         */
        PublishScheduleResponse.create = function create(properties) {
            return new PublishScheduleResponse(properties);
        };

        /**
         * Encodes the specified PublishScheduleResponse message. Does not implicitly {@link query_series.PublishScheduleResponse.verify|verify} messages.
         * @function encode
         * @memberof query_series.PublishScheduleResponse
         * @static
         * @param {query_series.IPublishScheduleResponse} message PublishScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishScheduleResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.schedule);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                $root.google.protobuf.StringValue.encode(message.text, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PublishScheduleResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_series.PublishScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_series.PublishScheduleResponse} PublishScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishScheduleResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_series.PublishScheduleResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.schedule = reader.int32();
                    break;
                case 2:
                    message.text = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a PublishScheduleResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_series.PublishScheduleResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_series.PublishScheduleResponse} PublishScheduleResponse
         */
        PublishScheduleResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_series.PublishScheduleResponse)
                return object;
            var message = new $root.query_series.PublishScheduleResponse();
            switch (object.schedule) {
            case "PUBLISH_SCHEDULE_UNSPECIFIED":
            case 0:
                message.schedule = 0;
                break;
            case "PUBLISH_SCHEDULE_EVARY_DAY":
            case 1:
                message.schedule = 1;
                break;
            case "PUBLISH_SCHEDULE_EVERY_SUNDAY":
            case 2:
                message.schedule = 2;
                break;
            case "PUBLISH_SCHEDULE_EVERY_MONDAY":
            case 3:
                message.schedule = 3;
                break;
            case "PUBLISH_SCHEDULE_EVERY_TUESDAY":
            case 4:
                message.schedule = 4;
                break;
            case "PUBLISH_SCHEDULE_EVERY_WEDNESDAY":
            case 5:
                message.schedule = 5;
                break;
            case "PUBLISH_SCHEDULE_EVERY_THURSDAY":
            case 6:
                message.schedule = 6;
                break;
            case "PUBLISH_SCHEDULE_EVERY_FRIDAY":
            case 7:
                message.schedule = 7;
                break;
            case "PUBLISH_SCHEDULE_EVERY_SATURDAY":
            case 8:
                message.schedule = 8;
                break;
            }
            if (object.text != null) {
                if (typeof object.text !== "object")
                    throw TypeError(".query_series.PublishScheduleResponse.text: object expected");
                message.text = $root.google.protobuf.StringValue.fromObject(object.text);
            }
            return message;
        };

        /**
         * Creates a plain object from a PublishScheduleResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_series.PublishScheduleResponse
         * @static
         * @param {query_series.PublishScheduleResponse} message PublishScheduleResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishScheduleResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.schedule = options.enums === String ? "PUBLISH_SCHEDULE_UNSPECIFIED" : 0;
                object.text = null;
            }
            if (message.schedule != null && message.hasOwnProperty("schedule"))
                object.schedule = options.enums === String ? $root.query_types.PublishSchedule[message.schedule] : message.schedule;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = $root.google.protobuf.StringValue.toObject(message.text, options);
            return object;
        };

        /**
         * Converts this PublishScheduleResponse to JSON.
         * @function toJSON
         * @memberof query_series.PublishScheduleResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishScheduleResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PublishScheduleResponse
         * @function getTypeUrl
         * @memberof query_series.PublishScheduleResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PublishScheduleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_series.PublishScheduleResponse";
        };

        return PublishScheduleResponse;
    })();

    return query_series;
})();

$root.comment = (function() {

    /**
     * Namespace comment.
     * @exports comment
     * @namespace
     */
    var comment = {};

    comment.CommentPermissionByStory = (function() {

        /**
         * Properties of a CommentPermissionByStory.
         * @memberof comment
         * @interface ICommentPermissionByStory
         * @property {google.protobuf.IBoolValue|null} [canAdd] CommentPermissionByStory canAdd
         * @property {google.protobuf.IBoolValue|null} [canSetPin] CommentPermissionByStory canSetPin
         */

        /**
         * Constructs a new CommentPermissionByStory.
         * @memberof comment
         * @classdesc Represents a CommentPermissionByStory.
         * @implements ICommentPermissionByStory
         * @constructor
         * @param {comment.ICommentPermissionByStory=} [properties] Properties to set
         */
        function CommentPermissionByStory(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentPermissionByStory canAdd.
         * @member {google.protobuf.IBoolValue|null|undefined} canAdd
         * @memberof comment.CommentPermissionByStory
         * @instance
         */
        CommentPermissionByStory.prototype.canAdd = null;

        /**
         * CommentPermissionByStory canSetPin.
         * @member {google.protobuf.IBoolValue|null|undefined} canSetPin
         * @memberof comment.CommentPermissionByStory
         * @instance
         */
        CommentPermissionByStory.prototype.canSetPin = null;

        /**
         * Creates a new CommentPermissionByStory instance using the specified properties.
         * @function create
         * @memberof comment.CommentPermissionByStory
         * @static
         * @param {comment.ICommentPermissionByStory=} [properties] Properties to set
         * @returns {comment.CommentPermissionByStory} CommentPermissionByStory instance
         */
        CommentPermissionByStory.create = function create(properties) {
            return new CommentPermissionByStory(properties);
        };

        /**
         * Encodes the specified CommentPermissionByStory message. Does not implicitly {@link comment.CommentPermissionByStory.verify|verify} messages.
         * @function encode
         * @memberof comment.CommentPermissionByStory
         * @static
         * @param {comment.ICommentPermissionByStory} message CommentPermissionByStory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentPermissionByStory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canAdd != null && Object.hasOwnProperty.call(message, "canAdd"))
                $root.google.protobuf.BoolValue.encode(message.canAdd, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.canSetPin != null && Object.hasOwnProperty.call(message, "canSetPin"))
                $root.google.protobuf.BoolValue.encode(message.canSetPin, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentPermissionByStory message from the specified reader or buffer.
         * @function decode
         * @memberof comment.CommentPermissionByStory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {comment.CommentPermissionByStory} CommentPermissionByStory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentPermissionByStory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.comment.CommentPermissionByStory();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.canAdd = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.canSetPin = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentPermissionByStory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof comment.CommentPermissionByStory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {comment.CommentPermissionByStory} CommentPermissionByStory
         */
        CommentPermissionByStory.fromObject = function fromObject(object) {
            if (object instanceof $root.comment.CommentPermissionByStory)
                return object;
            var message = new $root.comment.CommentPermissionByStory();
            if (object.canAdd != null) {
                if (typeof object.canAdd !== "object")
                    throw TypeError(".comment.CommentPermissionByStory.canAdd: object expected");
                message.canAdd = $root.google.protobuf.BoolValue.fromObject(object.canAdd);
            }
            if (object.canSetPin != null) {
                if (typeof object.canSetPin !== "object")
                    throw TypeError(".comment.CommentPermissionByStory.canSetPin: object expected");
                message.canSetPin = $root.google.protobuf.BoolValue.fromObject(object.canSetPin);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentPermissionByStory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof comment.CommentPermissionByStory
         * @static
         * @param {comment.CommentPermissionByStory} message CommentPermissionByStory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentPermissionByStory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.canAdd = null;
                object.canSetPin = null;
            }
            if (message.canAdd != null && message.hasOwnProperty("canAdd"))
                object.canAdd = $root.google.protobuf.BoolValue.toObject(message.canAdd, options);
            if (message.canSetPin != null && message.hasOwnProperty("canSetPin"))
                object.canSetPin = $root.google.protobuf.BoolValue.toObject(message.canSetPin, options);
            return object;
        };

        /**
         * Converts this CommentPermissionByStory to JSON.
         * @function toJSON
         * @memberof comment.CommentPermissionByStory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentPermissionByStory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentPermissionByStory
         * @function getTypeUrl
         * @memberof comment.CommentPermissionByStory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentPermissionByStory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/comment.CommentPermissionByStory";
        };

        return CommentPermissionByStory;
    })();

    comment.CommentListWithCursor = (function() {

        /**
         * Properties of a CommentListWithCursor.
         * @memberof comment
         * @interface ICommentListWithCursor
         * @property {Array.<comment.IComment>|null} [commentList] CommentListWithCursor commentList
         * @property {google.protobuf.IStringValue|null} [cursor] CommentListWithCursor cursor
         */

        /**
         * Constructs a new CommentListWithCursor.
         * @memberof comment
         * @classdesc Represents a CommentListWithCursor.
         * @implements ICommentListWithCursor
         * @constructor
         * @param {comment.ICommentListWithCursor=} [properties] Properties to set
         */
        function CommentListWithCursor(properties) {
            this.commentList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentListWithCursor commentList.
         * @member {Array.<comment.IComment>} commentList
         * @memberof comment.CommentListWithCursor
         * @instance
         */
        CommentListWithCursor.prototype.commentList = $util.emptyArray;

        /**
         * CommentListWithCursor cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof comment.CommentListWithCursor
         * @instance
         */
        CommentListWithCursor.prototype.cursor = null;

        /**
         * Creates a new CommentListWithCursor instance using the specified properties.
         * @function create
         * @memberof comment.CommentListWithCursor
         * @static
         * @param {comment.ICommentListWithCursor=} [properties] Properties to set
         * @returns {comment.CommentListWithCursor} CommentListWithCursor instance
         */
        CommentListWithCursor.create = function create(properties) {
            return new CommentListWithCursor(properties);
        };

        /**
         * Encodes the specified CommentListWithCursor message. Does not implicitly {@link comment.CommentListWithCursor.verify|verify} messages.
         * @function encode
         * @memberof comment.CommentListWithCursor
         * @static
         * @param {comment.ICommentListWithCursor} message CommentListWithCursor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentListWithCursor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentList != null && message.commentList.length)
                for (var i = 0; i < message.commentList.length; ++i)
                    $root.comment.Comment.encode(message.commentList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentListWithCursor message from the specified reader or buffer.
         * @function decode
         * @memberof comment.CommentListWithCursor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {comment.CommentListWithCursor} CommentListWithCursor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentListWithCursor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.comment.CommentListWithCursor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.commentList && message.commentList.length))
                        message.commentList = [];
                    message.commentList.push($root.comment.Comment.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentListWithCursor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof comment.CommentListWithCursor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {comment.CommentListWithCursor} CommentListWithCursor
         */
        CommentListWithCursor.fromObject = function fromObject(object) {
            if (object instanceof $root.comment.CommentListWithCursor)
                return object;
            var message = new $root.comment.CommentListWithCursor();
            if (object.commentList) {
                if (!Array.isArray(object.commentList))
                    throw TypeError(".comment.CommentListWithCursor.commentList: array expected");
                message.commentList = [];
                for (var i = 0; i < object.commentList.length; ++i) {
                    if (typeof object.commentList[i] !== "object")
                        throw TypeError(".comment.CommentListWithCursor.commentList: object expected");
                    message.commentList[i] = $root.comment.Comment.fromObject(object.commentList[i]);
                }
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".comment.CommentListWithCursor.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentListWithCursor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof comment.CommentListWithCursor
         * @static
         * @param {comment.CommentListWithCursor} message CommentListWithCursor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentListWithCursor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.commentList = [];
            if (options.defaults)
                object.cursor = null;
            if (message.commentList && message.commentList.length) {
                object.commentList = [];
                for (var j = 0; j < message.commentList.length; ++j)
                    object.commentList[j] = $root.comment.Comment.toObject(message.commentList[j], options);
            }
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this CommentListWithCursor to JSON.
         * @function toJSON
         * @memberof comment.CommentListWithCursor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentListWithCursor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentListWithCursor
         * @function getTypeUrl
         * @memberof comment.CommentListWithCursor
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentListWithCursor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/comment.CommentListWithCursor";
        };

        return CommentListWithCursor;
    })();

    comment.CommentStats = (function() {

        /**
         * Properties of a CommentStats.
         * @memberof comment
         * @interface ICommentStats
         * @property {google.protobuf.IStringValue|null} [storyId] CommentStats storyId
         * @property {google.protobuf.IInt32Value|null} [commentCount] CommentStats commentCount
         */

        /**
         * Constructs a new CommentStats.
         * @memberof comment
         * @classdesc Represents a CommentStats.
         * @implements ICommentStats
         * @constructor
         * @param {comment.ICommentStats=} [properties] Properties to set
         */
        function CommentStats(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentStats storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof comment.CommentStats
         * @instance
         */
        CommentStats.prototype.storyId = null;

        /**
         * CommentStats commentCount.
         * @member {google.protobuf.IInt32Value|null|undefined} commentCount
         * @memberof comment.CommentStats
         * @instance
         */
        CommentStats.prototype.commentCount = null;

        /**
         * Creates a new CommentStats instance using the specified properties.
         * @function create
         * @memberof comment.CommentStats
         * @static
         * @param {comment.ICommentStats=} [properties] Properties to set
         * @returns {comment.CommentStats} CommentStats instance
         */
        CommentStats.create = function create(properties) {
            return new CommentStats(properties);
        };

        /**
         * Encodes the specified CommentStats message. Does not implicitly {@link comment.CommentStats.verify|verify} messages.
         * @function encode
         * @memberof comment.CommentStats
         * @static
         * @param {comment.ICommentStats} message CommentStats message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentStats.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.commentCount != null && Object.hasOwnProperty.call(message, "commentCount"))
                $root.google.protobuf.Int32Value.encode(message.commentCount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentStats message from the specified reader or buffer.
         * @function decode
         * @memberof comment.CommentStats
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {comment.CommentStats} CommentStats
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentStats.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.comment.CommentStats();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.commentCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentStats message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof comment.CommentStats
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {comment.CommentStats} CommentStats
         */
        CommentStats.fromObject = function fromObject(object) {
            if (object instanceof $root.comment.CommentStats)
                return object;
            var message = new $root.comment.CommentStats();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".comment.CommentStats.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.commentCount != null) {
                if (typeof object.commentCount !== "object")
                    throw TypeError(".comment.CommentStats.commentCount: object expected");
                message.commentCount = $root.google.protobuf.Int32Value.fromObject(object.commentCount);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentStats message. Also converts values to other types if specified.
         * @function toObject
         * @memberof comment.CommentStats
         * @static
         * @param {comment.CommentStats} message CommentStats
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentStats.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.commentCount = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.commentCount != null && message.hasOwnProperty("commentCount"))
                object.commentCount = $root.google.protobuf.Int32Value.toObject(message.commentCount, options);
            return object;
        };

        /**
         * Converts this CommentStats to JSON.
         * @function toJSON
         * @memberof comment.CommentStats
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentStats.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentStats
         * @function getTypeUrl
         * @memberof comment.CommentStats
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentStats.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/comment.CommentStats";
        };

        return CommentStats;
    })();

    comment.CommentStatsList = (function() {

        /**
         * Properties of a CommentStatsList.
         * @memberof comment
         * @interface ICommentStatsList
         * @property {Array.<comment.ICommentStats>|null} [statsList] CommentStatsList statsList
         */

        /**
         * Constructs a new CommentStatsList.
         * @memberof comment
         * @classdesc Represents a CommentStatsList.
         * @implements ICommentStatsList
         * @constructor
         * @param {comment.ICommentStatsList=} [properties] Properties to set
         */
        function CommentStatsList(properties) {
            this.statsList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentStatsList statsList.
         * @member {Array.<comment.ICommentStats>} statsList
         * @memberof comment.CommentStatsList
         * @instance
         */
        CommentStatsList.prototype.statsList = $util.emptyArray;

        /**
         * Creates a new CommentStatsList instance using the specified properties.
         * @function create
         * @memberof comment.CommentStatsList
         * @static
         * @param {comment.ICommentStatsList=} [properties] Properties to set
         * @returns {comment.CommentStatsList} CommentStatsList instance
         */
        CommentStatsList.create = function create(properties) {
            return new CommentStatsList(properties);
        };

        /**
         * Encodes the specified CommentStatsList message. Does not implicitly {@link comment.CommentStatsList.verify|verify} messages.
         * @function encode
         * @memberof comment.CommentStatsList
         * @static
         * @param {comment.ICommentStatsList} message CommentStatsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentStatsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statsList != null && message.statsList.length)
                for (var i = 0; i < message.statsList.length; ++i)
                    $root.comment.CommentStats.encode(message.statsList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CommentStatsList message from the specified reader or buffer.
         * @function decode
         * @memberof comment.CommentStatsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {comment.CommentStatsList} CommentStatsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentStatsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.comment.CommentStatsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.statsList && message.statsList.length))
                        message.statsList = [];
                    message.statsList.push($root.comment.CommentStats.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a CommentStatsList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof comment.CommentStatsList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {comment.CommentStatsList} CommentStatsList
         */
        CommentStatsList.fromObject = function fromObject(object) {
            if (object instanceof $root.comment.CommentStatsList)
                return object;
            var message = new $root.comment.CommentStatsList();
            if (object.statsList) {
                if (!Array.isArray(object.statsList))
                    throw TypeError(".comment.CommentStatsList.statsList: array expected");
                message.statsList = [];
                for (var i = 0; i < object.statsList.length; ++i) {
                    if (typeof object.statsList[i] !== "object")
                        throw TypeError(".comment.CommentStatsList.statsList: object expected");
                    message.statsList[i] = $root.comment.CommentStats.fromObject(object.statsList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CommentStatsList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof comment.CommentStatsList
         * @static
         * @param {comment.CommentStatsList} message CommentStatsList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentStatsList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.statsList = [];
            if (message.statsList && message.statsList.length) {
                object.statsList = [];
                for (var j = 0; j < message.statsList.length; ++j)
                    object.statsList[j] = $root.comment.CommentStats.toObject(message.statsList[j], options);
            }
            return object;
        };

        /**
         * Converts this CommentStatsList to JSON.
         * @function toJSON
         * @memberof comment.CommentStatsList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentStatsList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentStatsList
         * @function getTypeUrl
         * @memberof comment.CommentStatsList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentStatsList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/comment.CommentStatsList";
        };

        return CommentStatsList;
    })();

    comment.Comment = (function() {

        /**
         * Properties of a Comment.
         * @memberof comment
         * @interface IComment
         * @property {google.protobuf.IStringValue|null} [id] Comment id
         * @property {google.protobuf.IStringValue|null} [storyId] Comment storyId
         * @property {google.protobuf.IStringValue|null} [userId] Comment userId
         * @property {google.protobuf.IStringValue|null} [body] Comment body
         * @property {google.protobuf.IInt32Value|null} [likeCount] Comment likeCount
         * @property {google.protobuf.IBoolValue|null} [liked] Comment liked
         * @property {google.protobuf.ITimestamp|null} [createdAt] Comment createdAt
         * @property {google.protobuf.ITimestamp|null} [deletedAt] Comment deletedAt
         * @property {comment.CommentDeletedBy|null} [deletedBy] Comment deletedBy
         * @property {google.protobuf.IBoolValue|null} [likedByAuthor] Comment likedByAuthor
         * @property {google.protobuf.IInt32Value|null} [replyCount] Comment replyCount
         * @property {google.protobuf.IStringValue|null} [stampId] Comment stampId
         */

        /**
         * Constructs a new Comment.
         * @memberof comment
         * @classdesc Represents a Comment.
         * @implements IComment
         * @constructor
         * @param {comment.IComment=} [properties] Properties to set
         */
        function Comment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Comment id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.id = null;

        /**
         * Comment storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.storyId = null;

        /**
         * Comment userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.userId = null;

        /**
         * Comment body.
         * @member {google.protobuf.IStringValue|null|undefined} body
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.body = null;

        /**
         * Comment likeCount.
         * @member {google.protobuf.IInt32Value|null|undefined} likeCount
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.likeCount = null;

        /**
         * Comment liked.
         * @member {google.protobuf.IBoolValue|null|undefined} liked
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.liked = null;

        /**
         * Comment createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.createdAt = null;

        /**
         * Comment deletedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} deletedAt
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.deletedAt = null;

        /**
         * Comment deletedBy.
         * @member {comment.CommentDeletedBy} deletedBy
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.deletedBy = 0;

        /**
         * Comment likedByAuthor.
         * @member {google.protobuf.IBoolValue|null|undefined} likedByAuthor
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.likedByAuthor = null;

        /**
         * Comment replyCount.
         * @member {google.protobuf.IInt32Value|null|undefined} replyCount
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.replyCount = null;

        /**
         * Comment stampId.
         * @member {google.protobuf.IStringValue|null|undefined} stampId
         * @memberof comment.Comment
         * @instance
         */
        Comment.prototype.stampId = null;

        /**
         * Creates a new Comment instance using the specified properties.
         * @function create
         * @memberof comment.Comment
         * @static
         * @param {comment.IComment=} [properties] Properties to set
         * @returns {comment.Comment} Comment instance
         */
        Comment.create = function create(properties) {
            return new Comment(properties);
        };

        /**
         * Encodes the specified Comment message. Does not implicitly {@link comment.Comment.verify|verify} messages.
         * @function encode
         * @memberof comment.Comment
         * @static
         * @param {comment.IComment} message Comment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Comment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                $root.google.protobuf.StringValue.encode(message.body, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.likeCount != null && Object.hasOwnProperty.call(message, "likeCount"))
                $root.google.protobuf.Int32Value.encode(message.likeCount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.liked != null && Object.hasOwnProperty.call(message, "liked"))
                $root.google.protobuf.BoolValue.encode(message.liked, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                $root.google.protobuf.Timestamp.encode(message.deletedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.deletedBy != null && Object.hasOwnProperty.call(message, "deletedBy"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.deletedBy);
            if (message.likedByAuthor != null && Object.hasOwnProperty.call(message, "likedByAuthor"))
                $root.google.protobuf.BoolValue.encode(message.likedByAuthor, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.replyCount != null && Object.hasOwnProperty.call(message, "replyCount"))
                $root.google.protobuf.Int32Value.encode(message.replyCount, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.stampId != null && Object.hasOwnProperty.call(message, "stampId"))
                $root.google.protobuf.StringValue.encode(message.stampId, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Comment message from the specified reader or buffer.
         * @function decode
         * @memberof comment.Comment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {comment.Comment} Comment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Comment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.comment.Comment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.body = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.likeCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.liked = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.deletedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.deletedBy = reader.int32();
                    break;
                case 10:
                    message.likedByAuthor = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.replyCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.stampId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Comment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof comment.Comment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {comment.Comment} Comment
         */
        Comment.fromObject = function fromObject(object) {
            if (object instanceof $root.comment.Comment)
                return object;
            var message = new $root.comment.Comment();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".comment.Comment.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".comment.Comment.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".comment.Comment.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            if (object.body != null) {
                if (typeof object.body !== "object")
                    throw TypeError(".comment.Comment.body: object expected");
                message.body = $root.google.protobuf.StringValue.fromObject(object.body);
            }
            if (object.likeCount != null) {
                if (typeof object.likeCount !== "object")
                    throw TypeError(".comment.Comment.likeCount: object expected");
                message.likeCount = $root.google.protobuf.Int32Value.fromObject(object.likeCount);
            }
            if (object.liked != null) {
                if (typeof object.liked !== "object")
                    throw TypeError(".comment.Comment.liked: object expected");
                message.liked = $root.google.protobuf.BoolValue.fromObject(object.liked);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".comment.Comment.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.deletedAt != null) {
                if (typeof object.deletedAt !== "object")
                    throw TypeError(".comment.Comment.deletedAt: object expected");
                message.deletedAt = $root.google.protobuf.Timestamp.fromObject(object.deletedAt);
            }
            switch (object.deletedBy) {
            case "COMMENT_DELETED_BY_UNSPECIFIED":
            case 0:
                message.deletedBy = 0;
                break;
            case "COMMENT_DELETED_BY_COMMENT_OWNER":
            case 1:
                message.deletedBy = 1;
                break;
            case "COMMENT_DELETED_BY_STORY_OWNER":
            case 2:
                message.deletedBy = 2;
                break;
            case "COMMENT_DELETED_BY_ADMIN":
            case 3:
                message.deletedBy = 3;
                break;
            }
            if (object.likedByAuthor != null) {
                if (typeof object.likedByAuthor !== "object")
                    throw TypeError(".comment.Comment.likedByAuthor: object expected");
                message.likedByAuthor = $root.google.protobuf.BoolValue.fromObject(object.likedByAuthor);
            }
            if (object.replyCount != null) {
                if (typeof object.replyCount !== "object")
                    throw TypeError(".comment.Comment.replyCount: object expected");
                message.replyCount = $root.google.protobuf.Int32Value.fromObject(object.replyCount);
            }
            if (object.stampId != null) {
                if (typeof object.stampId !== "object")
                    throw TypeError(".comment.Comment.stampId: object expected");
                message.stampId = $root.google.protobuf.StringValue.fromObject(object.stampId);
            }
            return message;
        };

        /**
         * Creates a plain object from a Comment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof comment.Comment
         * @static
         * @param {comment.Comment} message Comment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Comment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.storyId = null;
                object.userId = null;
                object.body = null;
                object.likeCount = null;
                object.liked = null;
                object.createdAt = null;
                object.deletedAt = null;
                object.deletedBy = options.enums === String ? "COMMENT_DELETED_BY_UNSPECIFIED" : 0;
                object.likedByAuthor = null;
                object.replyCount = null;
                object.stampId = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = $root.google.protobuf.StringValue.toObject(message.body, options);
            if (message.likeCount != null && message.hasOwnProperty("likeCount"))
                object.likeCount = $root.google.protobuf.Int32Value.toObject(message.likeCount, options);
            if (message.liked != null && message.hasOwnProperty("liked"))
                object.liked = $root.google.protobuf.BoolValue.toObject(message.liked, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                object.deletedAt = $root.google.protobuf.Timestamp.toObject(message.deletedAt, options);
            if (message.deletedBy != null && message.hasOwnProperty("deletedBy"))
                object.deletedBy = options.enums === String ? $root.comment.CommentDeletedBy[message.deletedBy] : message.deletedBy;
            if (message.likedByAuthor != null && message.hasOwnProperty("likedByAuthor"))
                object.likedByAuthor = $root.google.protobuf.BoolValue.toObject(message.likedByAuthor, options);
            if (message.replyCount != null && message.hasOwnProperty("replyCount"))
                object.replyCount = $root.google.protobuf.Int32Value.toObject(message.replyCount, options);
            if (message.stampId != null && message.hasOwnProperty("stampId"))
                object.stampId = $root.google.protobuf.StringValue.toObject(message.stampId, options);
            return object;
        };

        /**
         * Converts this Comment to JSON.
         * @function toJSON
         * @memberof comment.Comment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Comment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Comment
         * @function getTypeUrl
         * @memberof comment.Comment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Comment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/comment.Comment";
        };

        return Comment;
    })();

    /**
     * CommentDeletedBy enum.
     * @name comment.CommentDeletedBy
     * @enum {number}
     * @property {number} COMMENT_DELETED_BY_UNSPECIFIED=0 COMMENT_DELETED_BY_UNSPECIFIED value
     * @property {number} COMMENT_DELETED_BY_COMMENT_OWNER=1 COMMENT_DELETED_BY_COMMENT_OWNER value
     * @property {number} COMMENT_DELETED_BY_STORY_OWNER=2 COMMENT_DELETED_BY_STORY_OWNER value
     * @property {number} COMMENT_DELETED_BY_ADMIN=3 COMMENT_DELETED_BY_ADMIN value
     */
    comment.CommentDeletedBy = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COMMENT_DELETED_BY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "COMMENT_DELETED_BY_COMMENT_OWNER"] = 1;
        values[valuesById[2] = "COMMENT_DELETED_BY_STORY_OWNER"] = 2;
        values[valuesById[3] = "COMMENT_DELETED_BY_ADMIN"] = 3;
        return values;
    })();

    /**
     * CommentViolationType enum.
     * @name comment.CommentViolationType
     * @enum {number}
     * @property {number} COMMENT_VIOLATION_TYPE_UNSPECIFIED=0 COMMENT_VIOLATION_TYPE_UNSPECIFIED value
     * @property {number} COMMENT_VIOLATION_TYPE_VIOLENCE=1 COMMENT_VIOLATION_TYPE_VIOLENCE value
     * @property {number} COMMENT_VIOLATION_TYPE_HARASSMENT=2 COMMENT_VIOLATION_TYPE_HARASSMENT value
     * @property {number} COMMENT_VIOLATION_TYPE_PRIVACY=3 COMMENT_VIOLATION_TYPE_PRIVACY value
     * @property {number} COMMENT_VIOLATION_TYPE_SPAM=4 COMMENT_VIOLATION_TYPE_SPAM value
     * @property {number} COMMENT_VIOLATION_TYPE_ADULT=5 COMMENT_VIOLATION_TYPE_ADULT value
     * @property {number} COMMENT_VIOLATION_TYPE_OTHER=6 COMMENT_VIOLATION_TYPE_OTHER value
     */
    comment.CommentViolationType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COMMENT_VIOLATION_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "COMMENT_VIOLATION_TYPE_VIOLENCE"] = 1;
        values[valuesById[2] = "COMMENT_VIOLATION_TYPE_HARASSMENT"] = 2;
        values[valuesById[3] = "COMMENT_VIOLATION_TYPE_PRIVACY"] = 3;
        values[valuesById[4] = "COMMENT_VIOLATION_TYPE_SPAM"] = 4;
        values[valuesById[5] = "COMMENT_VIOLATION_TYPE_ADULT"] = 5;
        values[valuesById[6] = "COMMENT_VIOLATION_TYPE_OTHER"] = 6;
        return values;
    })();

    return comment;
})();

$root.story_video = (function() {

    /**
     * Namespace story_video.
     * @exports story_video
     * @namespace
     */
    var story_video = {};

    story_video.StoryVideoRequest = (function() {

        /**
         * Properties of a StoryVideoRequest.
         * @memberof story_video
         * @interface IStoryVideoRequest
         * @property {story_video.IStoryVideoRequireField|null} [requireForShare] StoryVideoRequest requireForShare
         * @property {story_video.IStoryVideoRequireField|null} [requireForInAppTimeline] StoryVideoRequest requireForInAppTimeline
         * @property {story_video.IStoryVideoRequireField|null} [requireForTiktok] StoryVideoRequest requireForTiktok
         * @property {Array.<story_video.StoryVideoType>|null} [typeList] StoryVideoRequest typeList
         * @property {story_video.IStoryVideoRequireField|null} [requireStoryVideo] StoryVideoRequest requireStoryVideo
         */

        /**
         * Constructs a new StoryVideoRequest.
         * @memberof story_video
         * @classdesc Represents a StoryVideoRequest.
         * @implements IStoryVideoRequest
         * @constructor
         * @param {story_video.IStoryVideoRequest=} [properties] Properties to set
         */
        function StoryVideoRequest(properties) {
            this.typeList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryVideoRequest requireForShare.
         * @member {story_video.IStoryVideoRequireField|null|undefined} requireForShare
         * @memberof story_video.StoryVideoRequest
         * @instance
         */
        StoryVideoRequest.prototype.requireForShare = null;

        /**
         * StoryVideoRequest requireForInAppTimeline.
         * @member {story_video.IStoryVideoRequireField|null|undefined} requireForInAppTimeline
         * @memberof story_video.StoryVideoRequest
         * @instance
         */
        StoryVideoRequest.prototype.requireForInAppTimeline = null;

        /**
         * StoryVideoRequest requireForTiktok.
         * @member {story_video.IStoryVideoRequireField|null|undefined} requireForTiktok
         * @memberof story_video.StoryVideoRequest
         * @instance
         */
        StoryVideoRequest.prototype.requireForTiktok = null;

        /**
         * StoryVideoRequest typeList.
         * @member {Array.<story_video.StoryVideoType>} typeList
         * @memberof story_video.StoryVideoRequest
         * @instance
         */
        StoryVideoRequest.prototype.typeList = $util.emptyArray;

        /**
         * StoryVideoRequest requireStoryVideo.
         * @member {story_video.IStoryVideoRequireField|null|undefined} requireStoryVideo
         * @memberof story_video.StoryVideoRequest
         * @instance
         */
        StoryVideoRequest.prototype.requireStoryVideo = null;

        /**
         * Creates a new StoryVideoRequest instance using the specified properties.
         * @function create
         * @memberof story_video.StoryVideoRequest
         * @static
         * @param {story_video.IStoryVideoRequest=} [properties] Properties to set
         * @returns {story_video.StoryVideoRequest} StoryVideoRequest instance
         */
        StoryVideoRequest.create = function create(properties) {
            return new StoryVideoRequest(properties);
        };

        /**
         * Encodes the specified StoryVideoRequest message. Does not implicitly {@link story_video.StoryVideoRequest.verify|verify} messages.
         * @function encode
         * @memberof story_video.StoryVideoRequest
         * @static
         * @param {story_video.IStoryVideoRequest} message StoryVideoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryVideoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.typeList != null && message.typeList.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.typeList.length; ++i)
                    writer.int32(message.typeList[i]);
                writer.ldelim();
            }
            if (message.requireStoryVideo != null && Object.hasOwnProperty.call(message, "requireStoryVideo"))
                $root.story_video.StoryVideoRequireField.encode(message.requireStoryVideo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireForShare != null && Object.hasOwnProperty.call(message, "requireForShare"))
                $root.story_video.StoryVideoRequireField.encode(message.requireForShare, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireForInAppTimeline != null && Object.hasOwnProperty.call(message, "requireForInAppTimeline"))
                $root.story_video.StoryVideoRequireField.encode(message.requireForInAppTimeline, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireForTiktok != null && Object.hasOwnProperty.call(message, "requireForTiktok"))
                $root.story_video.StoryVideoRequireField.encode(message.requireForTiktok, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryVideoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof story_video.StoryVideoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_video.StoryVideoRequest} StoryVideoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryVideoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_video.StoryVideoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.requireForShare = $root.story_video.StoryVideoRequireField.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireForInAppTimeline = $root.story_video.StoryVideoRequireField.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireForTiktok = $root.story_video.StoryVideoRequireField.decode(reader, reader.uint32());
                    break;
                case 1:
                    if (!(message.typeList && message.typeList.length))
                        message.typeList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.typeList.push(reader.int32());
                    } else
                        message.typeList.push(reader.int32());
                    break;
                case 2:
                    message.requireStoryVideo = $root.story_video.StoryVideoRequireField.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryVideoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_video.StoryVideoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_video.StoryVideoRequest} StoryVideoRequest
         */
        StoryVideoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.story_video.StoryVideoRequest)
                return object;
            var message = new $root.story_video.StoryVideoRequest();
            if (object.requireForShare != null) {
                if (typeof object.requireForShare !== "object")
                    throw TypeError(".story_video.StoryVideoRequest.requireForShare: object expected");
                message.requireForShare = $root.story_video.StoryVideoRequireField.fromObject(object.requireForShare);
            }
            if (object.requireForInAppTimeline != null) {
                if (typeof object.requireForInAppTimeline !== "object")
                    throw TypeError(".story_video.StoryVideoRequest.requireForInAppTimeline: object expected");
                message.requireForInAppTimeline = $root.story_video.StoryVideoRequireField.fromObject(object.requireForInAppTimeline);
            }
            if (object.requireForTiktok != null) {
                if (typeof object.requireForTiktok !== "object")
                    throw TypeError(".story_video.StoryVideoRequest.requireForTiktok: object expected");
                message.requireForTiktok = $root.story_video.StoryVideoRequireField.fromObject(object.requireForTiktok);
            }
            if (object.typeList) {
                if (!Array.isArray(object.typeList))
                    throw TypeError(".story_video.StoryVideoRequest.typeList: array expected");
                message.typeList = [];
                for (var i = 0; i < object.typeList.length; ++i)
                    switch (object.typeList[i]) {
                    default:
                    case "STORY_VIDEO_TYPE_UNSPECIFIED":
                    case 0:
                        message.typeList[i] = 0;
                        break;
                    case "STORY_VIDEO_TYPE_FOR_SHARE":
                    case 1:
                        message.typeList[i] = 1;
                        break;
                    case "STORY_VIDEO_TYPE_FOR_IN_APP_TIMELINE":
                    case 2:
                        message.typeList[i] = 2;
                        break;
                    case "STORY_VIDEO_TYPE_FOR_TIKTOK":
                    case 3:
                        message.typeList[i] = 3;
                        break;
                    }
            }
            if (object.requireStoryVideo != null) {
                if (typeof object.requireStoryVideo !== "object")
                    throw TypeError(".story_video.StoryVideoRequest.requireStoryVideo: object expected");
                message.requireStoryVideo = $root.story_video.StoryVideoRequireField.fromObject(object.requireStoryVideo);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryVideoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_video.StoryVideoRequest
         * @static
         * @param {story_video.StoryVideoRequest} message StoryVideoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryVideoRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.typeList = [];
            if (options.defaults) {
                object.requireStoryVideo = null;
                object.requireForShare = null;
                object.requireForInAppTimeline = null;
                object.requireForTiktok = null;
            }
            if (message.typeList && message.typeList.length) {
                object.typeList = [];
                for (var j = 0; j < message.typeList.length; ++j)
                    object.typeList[j] = options.enums === String ? $root.story_video.StoryVideoType[message.typeList[j]] : message.typeList[j];
            }
            if (message.requireStoryVideo != null && message.hasOwnProperty("requireStoryVideo"))
                object.requireStoryVideo = $root.story_video.StoryVideoRequireField.toObject(message.requireStoryVideo, options);
            if (message.requireForShare != null && message.hasOwnProperty("requireForShare"))
                object.requireForShare = $root.story_video.StoryVideoRequireField.toObject(message.requireForShare, options);
            if (message.requireForInAppTimeline != null && message.hasOwnProperty("requireForInAppTimeline"))
                object.requireForInAppTimeline = $root.story_video.StoryVideoRequireField.toObject(message.requireForInAppTimeline, options);
            if (message.requireForTiktok != null && message.hasOwnProperty("requireForTiktok"))
                object.requireForTiktok = $root.story_video.StoryVideoRequireField.toObject(message.requireForTiktok, options);
            return object;
        };

        /**
         * Converts this StoryVideoRequest to JSON.
         * @function toJSON
         * @memberof story_video.StoryVideoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryVideoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryVideoRequest
         * @function getTypeUrl
         * @memberof story_video.StoryVideoRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryVideoRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_video.StoryVideoRequest";
        };

        return StoryVideoRequest;
    })();

    story_video.StoryVideoResponse = (function() {

        /**
         * Properties of a StoryVideoResponse.
         * @memberof story_video
         * @interface IStoryVideoResponse
         * @property {story_video.IStoryVideo|null} [forShare] StoryVideoResponse forShare
         * @property {story_video.IStoryVideo|null} [forInAppTimeline] StoryVideoResponse forInAppTimeline
         * @property {story_video.IStoryVideo|null} [forTiktok] StoryVideoResponse forTiktok
         */

        /**
         * Constructs a new StoryVideoResponse.
         * @memberof story_video
         * @classdesc Represents a StoryVideoResponse.
         * @implements IStoryVideoResponse
         * @constructor
         * @param {story_video.IStoryVideoResponse=} [properties] Properties to set
         */
        function StoryVideoResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryVideoResponse forShare.
         * @member {story_video.IStoryVideo|null|undefined} forShare
         * @memberof story_video.StoryVideoResponse
         * @instance
         */
        StoryVideoResponse.prototype.forShare = null;

        /**
         * StoryVideoResponse forInAppTimeline.
         * @member {story_video.IStoryVideo|null|undefined} forInAppTimeline
         * @memberof story_video.StoryVideoResponse
         * @instance
         */
        StoryVideoResponse.prototype.forInAppTimeline = null;

        /**
         * StoryVideoResponse forTiktok.
         * @member {story_video.IStoryVideo|null|undefined} forTiktok
         * @memberof story_video.StoryVideoResponse
         * @instance
         */
        StoryVideoResponse.prototype.forTiktok = null;

        /**
         * Creates a new StoryVideoResponse instance using the specified properties.
         * @function create
         * @memberof story_video.StoryVideoResponse
         * @static
         * @param {story_video.IStoryVideoResponse=} [properties] Properties to set
         * @returns {story_video.StoryVideoResponse} StoryVideoResponse instance
         */
        StoryVideoResponse.create = function create(properties) {
            return new StoryVideoResponse(properties);
        };

        /**
         * Encodes the specified StoryVideoResponse message. Does not implicitly {@link story_video.StoryVideoResponse.verify|verify} messages.
         * @function encode
         * @memberof story_video.StoryVideoResponse
         * @static
         * @param {story_video.IStoryVideoResponse} message StoryVideoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryVideoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.forShare != null && Object.hasOwnProperty.call(message, "forShare"))
                $root.story_video.StoryVideo.encode(message.forShare, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.forInAppTimeline != null && Object.hasOwnProperty.call(message, "forInAppTimeline"))
                $root.story_video.StoryVideo.encode(message.forInAppTimeline, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.forTiktok != null && Object.hasOwnProperty.call(message, "forTiktok"))
                $root.story_video.StoryVideo.encode(message.forTiktok, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryVideoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof story_video.StoryVideoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_video.StoryVideoResponse} StoryVideoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryVideoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_video.StoryVideoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.forShare = $root.story_video.StoryVideo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.forInAppTimeline = $root.story_video.StoryVideo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.forTiktok = $root.story_video.StoryVideo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryVideoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_video.StoryVideoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_video.StoryVideoResponse} StoryVideoResponse
         */
        StoryVideoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.story_video.StoryVideoResponse)
                return object;
            var message = new $root.story_video.StoryVideoResponse();
            if (object.forShare != null) {
                if (typeof object.forShare !== "object")
                    throw TypeError(".story_video.StoryVideoResponse.forShare: object expected");
                message.forShare = $root.story_video.StoryVideo.fromObject(object.forShare);
            }
            if (object.forInAppTimeline != null) {
                if (typeof object.forInAppTimeline !== "object")
                    throw TypeError(".story_video.StoryVideoResponse.forInAppTimeline: object expected");
                message.forInAppTimeline = $root.story_video.StoryVideo.fromObject(object.forInAppTimeline);
            }
            if (object.forTiktok != null) {
                if (typeof object.forTiktok !== "object")
                    throw TypeError(".story_video.StoryVideoResponse.forTiktok: object expected");
                message.forTiktok = $root.story_video.StoryVideo.fromObject(object.forTiktok);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryVideoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_video.StoryVideoResponse
         * @static
         * @param {story_video.StoryVideoResponse} message StoryVideoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryVideoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.forShare = null;
                object.forInAppTimeline = null;
                object.forTiktok = null;
            }
            if (message.forShare != null && message.hasOwnProperty("forShare"))
                object.forShare = $root.story_video.StoryVideo.toObject(message.forShare, options);
            if (message.forInAppTimeline != null && message.hasOwnProperty("forInAppTimeline"))
                object.forInAppTimeline = $root.story_video.StoryVideo.toObject(message.forInAppTimeline, options);
            if (message.forTiktok != null && message.hasOwnProperty("forTiktok"))
                object.forTiktok = $root.story_video.StoryVideo.toObject(message.forTiktok, options);
            return object;
        };

        /**
         * Converts this StoryVideoResponse to JSON.
         * @function toJSON
         * @memberof story_video.StoryVideoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryVideoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryVideoResponse
         * @function getTypeUrl
         * @memberof story_video.StoryVideoResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryVideoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_video.StoryVideoResponse";
        };

        return StoryVideoResponse;
    })();

    /**
     * StoryVideoStatus enum.
     * @name story_video.StoryVideoStatus
     * @enum {number}
     * @property {number} STORY_VIDEO_STATUS_UNSPECIFIED=0 STORY_VIDEO_STATUS_UNSPECIFIED value
     * @property {number} STORY_VIDEO_STATUS_NO_VIDEO=1 STORY_VIDEO_STATUS_NO_VIDEO value
     * @property {number} STORY_VIDEO_STATUS_CREATING=2 STORY_VIDEO_STATUS_CREATING value
     * @property {number} STORY_VIDEO_STATUS_UP_TO_DATE=3 STORY_VIDEO_STATUS_UP_TO_DATE value
     * @property {number} STORY_VIDEO_STATUS_STALE=4 STORY_VIDEO_STATUS_STALE value
     */
    story_video.StoryVideoStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_VIDEO_STATUS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STORY_VIDEO_STATUS_NO_VIDEO"] = 1;
        values[valuesById[2] = "STORY_VIDEO_STATUS_CREATING"] = 2;
        values[valuesById[3] = "STORY_VIDEO_STATUS_UP_TO_DATE"] = 3;
        values[valuesById[4] = "STORY_VIDEO_STATUS_STALE"] = 4;
        return values;
    })();

    story_video.StoryVideoRequireField = (function() {

        /**
         * Properties of a StoryVideoRequireField.
         * @memberof story_video
         * @interface IStoryVideoRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] StoryVideoRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireType] StoryVideoRequireField requireType
         * @property {google.protobuf.IBoolValue|null} [requireDownloadUrl] StoryVideoRequireField requireDownloadUrl
         * @property {google.protobuf.IBoolValue|null} [requireSize] StoryVideoRequireField requireSize
         * @property {story_video.IStoryVideoOptionRequireField|null} [requireGenerateOption] StoryVideoRequireField requireGenerateOption
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] StoryVideoRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireStatus] StoryVideoRequireField requireStatus
         */

        /**
         * Constructs a new StoryVideoRequireField.
         * @memberof story_video
         * @classdesc Represents a StoryVideoRequireField.
         * @implements IStoryVideoRequireField
         * @constructor
         * @param {story_video.IStoryVideoRequireField=} [properties] Properties to set
         */
        function StoryVideoRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryVideoRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof story_video.StoryVideoRequireField
         * @instance
         */
        StoryVideoRequireField.prototype.requireId = null;

        /**
         * StoryVideoRequireField requireType.
         * @member {google.protobuf.IBoolValue|null|undefined} requireType
         * @memberof story_video.StoryVideoRequireField
         * @instance
         */
        StoryVideoRequireField.prototype.requireType = null;

        /**
         * StoryVideoRequireField requireDownloadUrl.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDownloadUrl
         * @memberof story_video.StoryVideoRequireField
         * @instance
         */
        StoryVideoRequireField.prototype.requireDownloadUrl = null;

        /**
         * StoryVideoRequireField requireSize.
         * @member {google.protobuf.IBoolValue|null|undefined} requireSize
         * @memberof story_video.StoryVideoRequireField
         * @instance
         */
        StoryVideoRequireField.prototype.requireSize = null;

        /**
         * StoryVideoRequireField requireGenerateOption.
         * @member {story_video.IStoryVideoOptionRequireField|null|undefined} requireGenerateOption
         * @memberof story_video.StoryVideoRequireField
         * @instance
         */
        StoryVideoRequireField.prototype.requireGenerateOption = null;

        /**
         * StoryVideoRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof story_video.StoryVideoRequireField
         * @instance
         */
        StoryVideoRequireField.prototype.requireCreatedAt = null;

        /**
         * StoryVideoRequireField requireStatus.
         * @member {google.protobuf.IBoolValue|null|undefined} requireStatus
         * @memberof story_video.StoryVideoRequireField
         * @instance
         */
        StoryVideoRequireField.prototype.requireStatus = null;

        /**
         * Creates a new StoryVideoRequireField instance using the specified properties.
         * @function create
         * @memberof story_video.StoryVideoRequireField
         * @static
         * @param {story_video.IStoryVideoRequireField=} [properties] Properties to set
         * @returns {story_video.StoryVideoRequireField} StoryVideoRequireField instance
         */
        StoryVideoRequireField.create = function create(properties) {
            return new StoryVideoRequireField(properties);
        };

        /**
         * Encodes the specified StoryVideoRequireField message. Does not implicitly {@link story_video.StoryVideoRequireField.verify|verify} messages.
         * @function encode
         * @memberof story_video.StoryVideoRequireField
         * @static
         * @param {story_video.IStoryVideoRequireField} message StoryVideoRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryVideoRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireType != null && Object.hasOwnProperty.call(message, "requireType"))
                $root.google.protobuf.BoolValue.encode(message.requireType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireDownloadUrl != null && Object.hasOwnProperty.call(message, "requireDownloadUrl"))
                $root.google.protobuf.BoolValue.encode(message.requireDownloadUrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireSize != null && Object.hasOwnProperty.call(message, "requireSize"))
                $root.google.protobuf.BoolValue.encode(message.requireSize, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireGenerateOption != null && Object.hasOwnProperty.call(message, "requireGenerateOption"))
                $root.story_video.StoryVideoOptionRequireField.encode(message.requireGenerateOption, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireStatus != null && Object.hasOwnProperty.call(message, "requireStatus"))
                $root.google.protobuf.BoolValue.encode(message.requireStatus, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryVideoRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof story_video.StoryVideoRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_video.StoryVideoRequireField} StoryVideoRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryVideoRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_video.StoryVideoRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireType = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireDownloadUrl = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireSize = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireGenerateOption = $root.story_video.StoryVideoOptionRequireField.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireStatus = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryVideoRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_video.StoryVideoRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_video.StoryVideoRequireField} StoryVideoRequireField
         */
        StoryVideoRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.story_video.StoryVideoRequireField)
                return object;
            var message = new $root.story_video.StoryVideoRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".story_video.StoryVideoRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireType != null) {
                if (typeof object.requireType !== "object")
                    throw TypeError(".story_video.StoryVideoRequireField.requireType: object expected");
                message.requireType = $root.google.protobuf.BoolValue.fromObject(object.requireType);
            }
            if (object.requireDownloadUrl != null) {
                if (typeof object.requireDownloadUrl !== "object")
                    throw TypeError(".story_video.StoryVideoRequireField.requireDownloadUrl: object expected");
                message.requireDownloadUrl = $root.google.protobuf.BoolValue.fromObject(object.requireDownloadUrl);
            }
            if (object.requireSize != null) {
                if (typeof object.requireSize !== "object")
                    throw TypeError(".story_video.StoryVideoRequireField.requireSize: object expected");
                message.requireSize = $root.google.protobuf.BoolValue.fromObject(object.requireSize);
            }
            if (object.requireGenerateOption != null) {
                if (typeof object.requireGenerateOption !== "object")
                    throw TypeError(".story_video.StoryVideoRequireField.requireGenerateOption: object expected");
                message.requireGenerateOption = $root.story_video.StoryVideoOptionRequireField.fromObject(object.requireGenerateOption);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".story_video.StoryVideoRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireStatus != null) {
                if (typeof object.requireStatus !== "object")
                    throw TypeError(".story_video.StoryVideoRequireField.requireStatus: object expected");
                message.requireStatus = $root.google.protobuf.BoolValue.fromObject(object.requireStatus);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryVideoRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_video.StoryVideoRequireField
         * @static
         * @param {story_video.StoryVideoRequireField} message StoryVideoRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryVideoRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireType = null;
                object.requireDownloadUrl = null;
                object.requireSize = null;
                object.requireGenerateOption = null;
                object.requireCreatedAt = null;
                object.requireStatus = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireType != null && message.hasOwnProperty("requireType"))
                object.requireType = $root.google.protobuf.BoolValue.toObject(message.requireType, options);
            if (message.requireDownloadUrl != null && message.hasOwnProperty("requireDownloadUrl"))
                object.requireDownloadUrl = $root.google.protobuf.BoolValue.toObject(message.requireDownloadUrl, options);
            if (message.requireSize != null && message.hasOwnProperty("requireSize"))
                object.requireSize = $root.google.protobuf.BoolValue.toObject(message.requireSize, options);
            if (message.requireGenerateOption != null && message.hasOwnProperty("requireGenerateOption"))
                object.requireGenerateOption = $root.story_video.StoryVideoOptionRequireField.toObject(message.requireGenerateOption, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireStatus != null && message.hasOwnProperty("requireStatus"))
                object.requireStatus = $root.google.protobuf.BoolValue.toObject(message.requireStatus, options);
            return object;
        };

        /**
         * Converts this StoryVideoRequireField to JSON.
         * @function toJSON
         * @memberof story_video.StoryVideoRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryVideoRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryVideoRequireField
         * @function getTypeUrl
         * @memberof story_video.StoryVideoRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryVideoRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_video.StoryVideoRequireField";
        };

        return StoryVideoRequireField;
    })();

    story_video.StoryVideoOptionRequireField = (function() {

        /**
         * Properties of a StoryVideoOptionRequireField.
         * @memberof story_video
         * @interface IStoryVideoOptionRequireField
         * @property {google.protobuf.IBoolValue|null} [requireWidth] StoryVideoOptionRequireField requireWidth
         * @property {google.protobuf.IBoolValue|null} [requireHeight] StoryVideoOptionRequireField requireHeight
         * @property {google.protobuf.IBoolValue|null} [requireScale] StoryVideoOptionRequireField requireScale
         * @property {google.protobuf.IBoolValue|null} [requireWait] StoryVideoOptionRequireField requireWait
         * @property {google.protobuf.IBoolValue|null} [requireMaxTapCount] StoryVideoOptionRequireField requireMaxTapCount
         * @property {google.protobuf.IBoolValue|null} [requireShowHeader] StoryVideoOptionRequireField requireShowHeader
         * @property {google.protobuf.IBoolValue|null} [requireShowAppPromotion] StoryVideoOptionRequireField requireShowAppPromotion
         * @property {google.protobuf.IBoolValue|null} [requireBitrate] StoryVideoOptionRequireField requireBitrate
         * @property {google.protobuf.IBoolValue|null} [requireCrf] StoryVideoOptionRequireField requireCrf
         * @property {google.protobuf.IBoolValue|null} [requireShowWatermark] StoryVideoOptionRequireField requireShowWatermark
         */

        /**
         * Constructs a new StoryVideoOptionRequireField.
         * @memberof story_video
         * @classdesc Represents a StoryVideoOptionRequireField.
         * @implements IStoryVideoOptionRequireField
         * @constructor
         * @param {story_video.IStoryVideoOptionRequireField=} [properties] Properties to set
         */
        function StoryVideoOptionRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryVideoOptionRequireField requireWidth.
         * @member {google.protobuf.IBoolValue|null|undefined} requireWidth
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireWidth = null;

        /**
         * StoryVideoOptionRequireField requireHeight.
         * @member {google.protobuf.IBoolValue|null|undefined} requireHeight
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireHeight = null;

        /**
         * StoryVideoOptionRequireField requireScale.
         * @member {google.protobuf.IBoolValue|null|undefined} requireScale
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireScale = null;

        /**
         * StoryVideoOptionRequireField requireWait.
         * @member {google.protobuf.IBoolValue|null|undefined} requireWait
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireWait = null;

        /**
         * StoryVideoOptionRequireField requireMaxTapCount.
         * @member {google.protobuf.IBoolValue|null|undefined} requireMaxTapCount
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireMaxTapCount = null;

        /**
         * StoryVideoOptionRequireField requireShowHeader.
         * @member {google.protobuf.IBoolValue|null|undefined} requireShowHeader
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireShowHeader = null;

        /**
         * StoryVideoOptionRequireField requireShowAppPromotion.
         * @member {google.protobuf.IBoolValue|null|undefined} requireShowAppPromotion
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireShowAppPromotion = null;

        /**
         * StoryVideoOptionRequireField requireBitrate.
         * @member {google.protobuf.IBoolValue|null|undefined} requireBitrate
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireBitrate = null;

        /**
         * StoryVideoOptionRequireField requireCrf.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCrf
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireCrf = null;

        /**
         * StoryVideoOptionRequireField requireShowWatermark.
         * @member {google.protobuf.IBoolValue|null|undefined} requireShowWatermark
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         */
        StoryVideoOptionRequireField.prototype.requireShowWatermark = null;

        /**
         * Creates a new StoryVideoOptionRequireField instance using the specified properties.
         * @function create
         * @memberof story_video.StoryVideoOptionRequireField
         * @static
         * @param {story_video.IStoryVideoOptionRequireField=} [properties] Properties to set
         * @returns {story_video.StoryVideoOptionRequireField} StoryVideoOptionRequireField instance
         */
        StoryVideoOptionRequireField.create = function create(properties) {
            return new StoryVideoOptionRequireField(properties);
        };

        /**
         * Encodes the specified StoryVideoOptionRequireField message. Does not implicitly {@link story_video.StoryVideoOptionRequireField.verify|verify} messages.
         * @function encode
         * @memberof story_video.StoryVideoOptionRequireField
         * @static
         * @param {story_video.IStoryVideoOptionRequireField} message StoryVideoOptionRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryVideoOptionRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireWidth != null && Object.hasOwnProperty.call(message, "requireWidth"))
                $root.google.protobuf.BoolValue.encode(message.requireWidth, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireHeight != null && Object.hasOwnProperty.call(message, "requireHeight"))
                $root.google.protobuf.BoolValue.encode(message.requireHeight, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireScale != null && Object.hasOwnProperty.call(message, "requireScale"))
                $root.google.protobuf.BoolValue.encode(message.requireScale, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireWait != null && Object.hasOwnProperty.call(message, "requireWait"))
                $root.google.protobuf.BoolValue.encode(message.requireWait, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireMaxTapCount != null && Object.hasOwnProperty.call(message, "requireMaxTapCount"))
                $root.google.protobuf.BoolValue.encode(message.requireMaxTapCount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireShowHeader != null && Object.hasOwnProperty.call(message, "requireShowHeader"))
                $root.google.protobuf.BoolValue.encode(message.requireShowHeader, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireShowAppPromotion != null && Object.hasOwnProperty.call(message, "requireShowAppPromotion"))
                $root.google.protobuf.BoolValue.encode(message.requireShowAppPromotion, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireBitrate != null && Object.hasOwnProperty.call(message, "requireBitrate"))
                $root.google.protobuf.BoolValue.encode(message.requireBitrate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.requireCrf != null && Object.hasOwnProperty.call(message, "requireCrf"))
                $root.google.protobuf.BoolValue.encode(message.requireCrf, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.requireShowWatermark != null && Object.hasOwnProperty.call(message, "requireShowWatermark"))
                $root.google.protobuf.BoolValue.encode(message.requireShowWatermark, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryVideoOptionRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof story_video.StoryVideoOptionRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_video.StoryVideoOptionRequireField} StoryVideoOptionRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryVideoOptionRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_video.StoryVideoOptionRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireWidth = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireHeight = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireScale = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireWait = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireMaxTapCount = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireShowHeader = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireShowAppPromotion = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireBitrate = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.requireCrf = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.requireShowWatermark = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryVideoOptionRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_video.StoryVideoOptionRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_video.StoryVideoOptionRequireField} StoryVideoOptionRequireField
         */
        StoryVideoOptionRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.story_video.StoryVideoOptionRequireField)
                return object;
            var message = new $root.story_video.StoryVideoOptionRequireField();
            if (object.requireWidth != null) {
                if (typeof object.requireWidth !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireWidth: object expected");
                message.requireWidth = $root.google.protobuf.BoolValue.fromObject(object.requireWidth);
            }
            if (object.requireHeight != null) {
                if (typeof object.requireHeight !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireHeight: object expected");
                message.requireHeight = $root.google.protobuf.BoolValue.fromObject(object.requireHeight);
            }
            if (object.requireScale != null) {
                if (typeof object.requireScale !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireScale: object expected");
                message.requireScale = $root.google.protobuf.BoolValue.fromObject(object.requireScale);
            }
            if (object.requireWait != null) {
                if (typeof object.requireWait !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireWait: object expected");
                message.requireWait = $root.google.protobuf.BoolValue.fromObject(object.requireWait);
            }
            if (object.requireMaxTapCount != null) {
                if (typeof object.requireMaxTapCount !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireMaxTapCount: object expected");
                message.requireMaxTapCount = $root.google.protobuf.BoolValue.fromObject(object.requireMaxTapCount);
            }
            if (object.requireShowHeader != null) {
                if (typeof object.requireShowHeader !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireShowHeader: object expected");
                message.requireShowHeader = $root.google.protobuf.BoolValue.fromObject(object.requireShowHeader);
            }
            if (object.requireShowAppPromotion != null) {
                if (typeof object.requireShowAppPromotion !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireShowAppPromotion: object expected");
                message.requireShowAppPromotion = $root.google.protobuf.BoolValue.fromObject(object.requireShowAppPromotion);
            }
            if (object.requireBitrate != null) {
                if (typeof object.requireBitrate !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireBitrate: object expected");
                message.requireBitrate = $root.google.protobuf.BoolValue.fromObject(object.requireBitrate);
            }
            if (object.requireCrf != null) {
                if (typeof object.requireCrf !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireCrf: object expected");
                message.requireCrf = $root.google.protobuf.BoolValue.fromObject(object.requireCrf);
            }
            if (object.requireShowWatermark != null) {
                if (typeof object.requireShowWatermark !== "object")
                    throw TypeError(".story_video.StoryVideoOptionRequireField.requireShowWatermark: object expected");
                message.requireShowWatermark = $root.google.protobuf.BoolValue.fromObject(object.requireShowWatermark);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryVideoOptionRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_video.StoryVideoOptionRequireField
         * @static
         * @param {story_video.StoryVideoOptionRequireField} message StoryVideoOptionRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryVideoOptionRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireWidth = null;
                object.requireHeight = null;
                object.requireScale = null;
                object.requireWait = null;
                object.requireMaxTapCount = null;
                object.requireShowHeader = null;
                object.requireShowAppPromotion = null;
                object.requireBitrate = null;
                object.requireCrf = null;
                object.requireShowWatermark = null;
            }
            if (message.requireWidth != null && message.hasOwnProperty("requireWidth"))
                object.requireWidth = $root.google.protobuf.BoolValue.toObject(message.requireWidth, options);
            if (message.requireHeight != null && message.hasOwnProperty("requireHeight"))
                object.requireHeight = $root.google.protobuf.BoolValue.toObject(message.requireHeight, options);
            if (message.requireScale != null && message.hasOwnProperty("requireScale"))
                object.requireScale = $root.google.protobuf.BoolValue.toObject(message.requireScale, options);
            if (message.requireWait != null && message.hasOwnProperty("requireWait"))
                object.requireWait = $root.google.protobuf.BoolValue.toObject(message.requireWait, options);
            if (message.requireMaxTapCount != null && message.hasOwnProperty("requireMaxTapCount"))
                object.requireMaxTapCount = $root.google.protobuf.BoolValue.toObject(message.requireMaxTapCount, options);
            if (message.requireShowHeader != null && message.hasOwnProperty("requireShowHeader"))
                object.requireShowHeader = $root.google.protobuf.BoolValue.toObject(message.requireShowHeader, options);
            if (message.requireShowAppPromotion != null && message.hasOwnProperty("requireShowAppPromotion"))
                object.requireShowAppPromotion = $root.google.protobuf.BoolValue.toObject(message.requireShowAppPromotion, options);
            if (message.requireBitrate != null && message.hasOwnProperty("requireBitrate"))
                object.requireBitrate = $root.google.protobuf.BoolValue.toObject(message.requireBitrate, options);
            if (message.requireCrf != null && message.hasOwnProperty("requireCrf"))
                object.requireCrf = $root.google.protobuf.BoolValue.toObject(message.requireCrf, options);
            if (message.requireShowWatermark != null && message.hasOwnProperty("requireShowWatermark"))
                object.requireShowWatermark = $root.google.protobuf.BoolValue.toObject(message.requireShowWatermark, options);
            return object;
        };

        /**
         * Converts this StoryVideoOptionRequireField to JSON.
         * @function toJSON
         * @memberof story_video.StoryVideoOptionRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryVideoOptionRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryVideoOptionRequireField
         * @function getTypeUrl
         * @memberof story_video.StoryVideoOptionRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryVideoOptionRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_video.StoryVideoOptionRequireField";
        };

        return StoryVideoOptionRequireField;
    })();

    story_video.StoryVideo = (function() {

        /**
         * Properties of a StoryVideo.
         * @memberof story_video
         * @interface IStoryVideo
         * @property {google.protobuf.IStringValue|null} [id] StoryVideo id
         * @property {google.protobuf.IStringValue|null} [storyId] StoryVideo storyId
         * @property {story_video.StoryVideoType|null} [type] StoryVideo type
         * @property {google.protobuf.IStringValue|null} [downloadUrl] StoryVideo downloadUrl
         * @property {google.protobuf.IInt32Value|null} [size] StoryVideo size
         * @property {story_video.IStoryVideoOption|null} [generateOption] StoryVideo generateOption
         * @property {google.protobuf.ITimestamp|null} [createdAt] StoryVideo createdAt
         * @property {story_video.StoryVideoStatus|null} [status] StoryVideo status
         */

        /**
         * Constructs a new StoryVideo.
         * @memberof story_video
         * @classdesc Represents a StoryVideo.
         * @implements IStoryVideo
         * @constructor
         * @param {story_video.IStoryVideo=} [properties] Properties to set
         */
        function StoryVideo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryVideo id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.id = null;

        /**
         * StoryVideo storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.storyId = null;

        /**
         * StoryVideo type.
         * @member {story_video.StoryVideoType} type
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.type = 0;

        /**
         * StoryVideo downloadUrl.
         * @member {google.protobuf.IStringValue|null|undefined} downloadUrl
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.downloadUrl = null;

        /**
         * StoryVideo size.
         * @member {google.protobuf.IInt32Value|null|undefined} size
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.size = null;

        /**
         * StoryVideo generateOption.
         * @member {story_video.IStoryVideoOption|null|undefined} generateOption
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.generateOption = null;

        /**
         * StoryVideo createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.createdAt = null;

        /**
         * StoryVideo status.
         * @member {story_video.StoryVideoStatus} status
         * @memberof story_video.StoryVideo
         * @instance
         */
        StoryVideo.prototype.status = 0;

        /**
         * Creates a new StoryVideo instance using the specified properties.
         * @function create
         * @memberof story_video.StoryVideo
         * @static
         * @param {story_video.IStoryVideo=} [properties] Properties to set
         * @returns {story_video.StoryVideo} StoryVideo instance
         */
        StoryVideo.create = function create(properties) {
            return new StoryVideo(properties);
        };

        /**
         * Encodes the specified StoryVideo message. Does not implicitly {@link story_video.StoryVideo.verify|verify} messages.
         * @function encode
         * @memberof story_video.StoryVideo
         * @static
         * @param {story_video.IStoryVideo} message StoryVideo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryVideo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.downloadUrl != null && Object.hasOwnProperty.call(message, "downloadUrl"))
                $root.google.protobuf.StringValue.encode(message.downloadUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                $root.google.protobuf.Int32Value.encode(message.size, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.generateOption != null && Object.hasOwnProperty.call(message, "generateOption"))
                $root.story_video.StoryVideoOption.encode(message.generateOption, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.status);
            return writer;
        };

        /**
         * Decodes a StoryVideo message from the specified reader or buffer.
         * @function decode
         * @memberof story_video.StoryVideo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_video.StoryVideo} StoryVideo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryVideo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_video.StoryVideo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.downloadUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.size = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.generateOption = $root.story_video.StoryVideoOption.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryVideo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_video.StoryVideo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_video.StoryVideo} StoryVideo
         */
        StoryVideo.fromObject = function fromObject(object) {
            if (object instanceof $root.story_video.StoryVideo)
                return object;
            var message = new $root.story_video.StoryVideo();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_video.StoryVideo.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".story_video.StoryVideo.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            switch (object.type) {
            case "STORY_VIDEO_TYPE_UNSPECIFIED":
            case 0:
                message.type = 0;
                break;
            case "STORY_VIDEO_TYPE_FOR_SHARE":
            case 1:
                message.type = 1;
                break;
            case "STORY_VIDEO_TYPE_FOR_IN_APP_TIMELINE":
            case 2:
                message.type = 2;
                break;
            case "STORY_VIDEO_TYPE_FOR_TIKTOK":
            case 3:
                message.type = 3;
                break;
            }
            if (object.downloadUrl != null) {
                if (typeof object.downloadUrl !== "object")
                    throw TypeError(".story_video.StoryVideo.downloadUrl: object expected");
                message.downloadUrl = $root.google.protobuf.StringValue.fromObject(object.downloadUrl);
            }
            if (object.size != null) {
                if (typeof object.size !== "object")
                    throw TypeError(".story_video.StoryVideo.size: object expected");
                message.size = $root.google.protobuf.Int32Value.fromObject(object.size);
            }
            if (object.generateOption != null) {
                if (typeof object.generateOption !== "object")
                    throw TypeError(".story_video.StoryVideo.generateOption: object expected");
                message.generateOption = $root.story_video.StoryVideoOption.fromObject(object.generateOption);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".story_video.StoryVideo.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            switch (object.status) {
            case "STORY_VIDEO_STATUS_UNSPECIFIED":
            case 0:
                message.status = 0;
                break;
            case "STORY_VIDEO_STATUS_NO_VIDEO":
            case 1:
                message.status = 1;
                break;
            case "STORY_VIDEO_STATUS_CREATING":
            case 2:
                message.status = 2;
                break;
            case "STORY_VIDEO_STATUS_UP_TO_DATE":
            case 3:
                message.status = 3;
                break;
            case "STORY_VIDEO_STATUS_STALE":
            case 4:
                message.status = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryVideo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_video.StoryVideo
         * @static
         * @param {story_video.StoryVideo} message StoryVideo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryVideo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.storyId = null;
                object.type = options.enums === String ? "STORY_VIDEO_TYPE_UNSPECIFIED" : 0;
                object.downloadUrl = null;
                object.size = null;
                object.generateOption = null;
                object.createdAt = null;
                object.status = options.enums === String ? "STORY_VIDEO_STATUS_UNSPECIFIED" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.story_video.StoryVideoType[message.type] : message.type;
            if (message.downloadUrl != null && message.hasOwnProperty("downloadUrl"))
                object.downloadUrl = $root.google.protobuf.StringValue.toObject(message.downloadUrl, options);
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = $root.google.protobuf.Int32Value.toObject(message.size, options);
            if (message.generateOption != null && message.hasOwnProperty("generateOption"))
                object.generateOption = $root.story_video.StoryVideoOption.toObject(message.generateOption, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.story_video.StoryVideoStatus[message.status] : message.status;
            return object;
        };

        /**
         * Converts this StoryVideo to JSON.
         * @function toJSON
         * @memberof story_video.StoryVideo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryVideo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryVideo
         * @function getTypeUrl
         * @memberof story_video.StoryVideo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryVideo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_video.StoryVideo";
        };

        return StoryVideo;
    })();

    story_video.StoryVideoList = (function() {

        /**
         * Properties of a StoryVideoList.
         * @memberof story_video
         * @interface IStoryVideoList
         * @property {Array.<story_video.IStoryVideo>|null} [storyVideoList] StoryVideoList storyVideoList
         */

        /**
         * Constructs a new StoryVideoList.
         * @memberof story_video
         * @classdesc Represents a StoryVideoList.
         * @implements IStoryVideoList
         * @constructor
         * @param {story_video.IStoryVideoList=} [properties] Properties to set
         */
        function StoryVideoList(properties) {
            this.storyVideoList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryVideoList storyVideoList.
         * @member {Array.<story_video.IStoryVideo>} storyVideoList
         * @memberof story_video.StoryVideoList
         * @instance
         */
        StoryVideoList.prototype.storyVideoList = $util.emptyArray;

        /**
         * Creates a new StoryVideoList instance using the specified properties.
         * @function create
         * @memberof story_video.StoryVideoList
         * @static
         * @param {story_video.IStoryVideoList=} [properties] Properties to set
         * @returns {story_video.StoryVideoList} StoryVideoList instance
         */
        StoryVideoList.create = function create(properties) {
            return new StoryVideoList(properties);
        };

        /**
         * Encodes the specified StoryVideoList message. Does not implicitly {@link story_video.StoryVideoList.verify|verify} messages.
         * @function encode
         * @memberof story_video.StoryVideoList
         * @static
         * @param {story_video.IStoryVideoList} message StoryVideoList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryVideoList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyVideoList != null && message.storyVideoList.length)
                for (var i = 0; i < message.storyVideoList.length; ++i)
                    $root.story_video.StoryVideo.encode(message.storyVideoList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryVideoList message from the specified reader or buffer.
         * @function decode
         * @memberof story_video.StoryVideoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_video.StoryVideoList} StoryVideoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryVideoList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_video.StoryVideoList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyVideoList && message.storyVideoList.length))
                        message.storyVideoList = [];
                    message.storyVideoList.push($root.story_video.StoryVideo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryVideoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_video.StoryVideoList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_video.StoryVideoList} StoryVideoList
         */
        StoryVideoList.fromObject = function fromObject(object) {
            if (object instanceof $root.story_video.StoryVideoList)
                return object;
            var message = new $root.story_video.StoryVideoList();
            if (object.storyVideoList) {
                if (!Array.isArray(object.storyVideoList))
                    throw TypeError(".story_video.StoryVideoList.storyVideoList: array expected");
                message.storyVideoList = [];
                for (var i = 0; i < object.storyVideoList.length; ++i) {
                    if (typeof object.storyVideoList[i] !== "object")
                        throw TypeError(".story_video.StoryVideoList.storyVideoList: object expected");
                    message.storyVideoList[i] = $root.story_video.StoryVideo.fromObject(object.storyVideoList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryVideoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_video.StoryVideoList
         * @static
         * @param {story_video.StoryVideoList} message StoryVideoList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryVideoList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyVideoList = [];
            if (message.storyVideoList && message.storyVideoList.length) {
                object.storyVideoList = [];
                for (var j = 0; j < message.storyVideoList.length; ++j)
                    object.storyVideoList[j] = $root.story_video.StoryVideo.toObject(message.storyVideoList[j], options);
            }
            return object;
        };

        /**
         * Converts this StoryVideoList to JSON.
         * @function toJSON
         * @memberof story_video.StoryVideoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryVideoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryVideoList
         * @function getTypeUrl
         * @memberof story_video.StoryVideoList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryVideoList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_video.StoryVideoList";
        };

        return StoryVideoList;
    })();

    story_video.StoryVideoOption = (function() {

        /**
         * Properties of a StoryVideoOption.
         * @memberof story_video
         * @interface IStoryVideoOption
         * @property {google.protobuf.IInt32Value|null} [width] StoryVideoOption width
         * @property {google.protobuf.IInt32Value|null} [height] StoryVideoOption height
         * @property {google.protobuf.IFloatValue|null} [scale] StoryVideoOption scale
         * @property {google.protobuf.IDuration|null} [wait] StoryVideoOption wait
         * @property {google.protobuf.IInt32Value|null} [maxTapCount] StoryVideoOption maxTapCount
         * @property {google.protobuf.IBoolValue|null} [showHeader] StoryVideoOption showHeader
         * @property {google.protobuf.IBoolValue|null} [showAppPromotion] StoryVideoOption showAppPromotion
         * @property {google.protobuf.IInt32Value|null} [bitrate] StoryVideoOption bitrate
         * @property {google.protobuf.IInt32Value|null} [crf] StoryVideoOption crf
         * @property {google.protobuf.IStringValue|null} [showWatermark] StoryVideoOption showWatermark
         */

        /**
         * Constructs a new StoryVideoOption.
         * @memberof story_video
         * @classdesc Represents a StoryVideoOption.
         * @implements IStoryVideoOption
         * @constructor
         * @param {story_video.IStoryVideoOption=} [properties] Properties to set
         */
        function StoryVideoOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryVideoOption width.
         * @member {google.protobuf.IInt32Value|null|undefined} width
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.width = null;

        /**
         * StoryVideoOption height.
         * @member {google.protobuf.IInt32Value|null|undefined} height
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.height = null;

        /**
         * StoryVideoOption scale.
         * @member {google.protobuf.IFloatValue|null|undefined} scale
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.scale = null;

        /**
         * StoryVideoOption wait.
         * @member {google.protobuf.IDuration|null|undefined} wait
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.wait = null;

        /**
         * StoryVideoOption maxTapCount.
         * @member {google.protobuf.IInt32Value|null|undefined} maxTapCount
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.maxTapCount = null;

        /**
         * StoryVideoOption showHeader.
         * @member {google.protobuf.IBoolValue|null|undefined} showHeader
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.showHeader = null;

        /**
         * StoryVideoOption showAppPromotion.
         * @member {google.protobuf.IBoolValue|null|undefined} showAppPromotion
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.showAppPromotion = null;

        /**
         * StoryVideoOption bitrate.
         * @member {google.protobuf.IInt32Value|null|undefined} bitrate
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.bitrate = null;

        /**
         * StoryVideoOption crf.
         * @member {google.protobuf.IInt32Value|null|undefined} crf
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.crf = null;

        /**
         * StoryVideoOption showWatermark.
         * @member {google.protobuf.IStringValue|null|undefined} showWatermark
         * @memberof story_video.StoryVideoOption
         * @instance
         */
        StoryVideoOption.prototype.showWatermark = null;

        /**
         * Creates a new StoryVideoOption instance using the specified properties.
         * @function create
         * @memberof story_video.StoryVideoOption
         * @static
         * @param {story_video.IStoryVideoOption=} [properties] Properties to set
         * @returns {story_video.StoryVideoOption} StoryVideoOption instance
         */
        StoryVideoOption.create = function create(properties) {
            return new StoryVideoOption(properties);
        };

        /**
         * Encodes the specified StoryVideoOption message. Does not implicitly {@link story_video.StoryVideoOption.verify|verify} messages.
         * @function encode
         * @memberof story_video.StoryVideoOption
         * @static
         * @param {story_video.IStoryVideoOption} message StoryVideoOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryVideoOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                $root.google.protobuf.Int32Value.encode(message.width, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                $root.google.protobuf.Int32Value.encode(message.height, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.scale != null && Object.hasOwnProperty.call(message, "scale"))
                $root.google.protobuf.FloatValue.encode(message.scale, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.wait != null && Object.hasOwnProperty.call(message, "wait"))
                $root.google.protobuf.Duration.encode(message.wait, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.maxTapCount != null && Object.hasOwnProperty.call(message, "maxTapCount"))
                $root.google.protobuf.Int32Value.encode(message.maxTapCount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.showHeader != null && Object.hasOwnProperty.call(message, "showHeader"))
                $root.google.protobuf.BoolValue.encode(message.showHeader, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.showAppPromotion != null && Object.hasOwnProperty.call(message, "showAppPromotion"))
                $root.google.protobuf.BoolValue.encode(message.showAppPromotion, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.bitrate != null && Object.hasOwnProperty.call(message, "bitrate"))
                $root.google.protobuf.Int32Value.encode(message.bitrate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.crf != null && Object.hasOwnProperty.call(message, "crf"))
                $root.google.protobuf.Int32Value.encode(message.crf, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.showWatermark != null && Object.hasOwnProperty.call(message, "showWatermark"))
                $root.google.protobuf.StringValue.encode(message.showWatermark, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryVideoOption message from the specified reader or buffer.
         * @function decode
         * @memberof story_video.StoryVideoOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_video.StoryVideoOption} StoryVideoOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryVideoOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_video.StoryVideoOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.width = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.height = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.scale = $root.google.protobuf.FloatValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.wait = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.maxTapCount = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.showHeader = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.showAppPromotion = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.bitrate = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.crf = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.showWatermark = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryVideoOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_video.StoryVideoOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_video.StoryVideoOption} StoryVideoOption
         */
        StoryVideoOption.fromObject = function fromObject(object) {
            if (object instanceof $root.story_video.StoryVideoOption)
                return object;
            var message = new $root.story_video.StoryVideoOption();
            if (object.width != null) {
                if (typeof object.width !== "object")
                    throw TypeError(".story_video.StoryVideoOption.width: object expected");
                message.width = $root.google.protobuf.Int32Value.fromObject(object.width);
            }
            if (object.height != null) {
                if (typeof object.height !== "object")
                    throw TypeError(".story_video.StoryVideoOption.height: object expected");
                message.height = $root.google.protobuf.Int32Value.fromObject(object.height);
            }
            if (object.scale != null) {
                if (typeof object.scale !== "object")
                    throw TypeError(".story_video.StoryVideoOption.scale: object expected");
                message.scale = $root.google.protobuf.FloatValue.fromObject(object.scale);
            }
            if (object.wait != null) {
                if (typeof object.wait !== "object")
                    throw TypeError(".story_video.StoryVideoOption.wait: object expected");
                message.wait = $root.google.protobuf.Duration.fromObject(object.wait);
            }
            if (object.maxTapCount != null) {
                if (typeof object.maxTapCount !== "object")
                    throw TypeError(".story_video.StoryVideoOption.maxTapCount: object expected");
                message.maxTapCount = $root.google.protobuf.Int32Value.fromObject(object.maxTapCount);
            }
            if (object.showHeader != null) {
                if (typeof object.showHeader !== "object")
                    throw TypeError(".story_video.StoryVideoOption.showHeader: object expected");
                message.showHeader = $root.google.protobuf.BoolValue.fromObject(object.showHeader);
            }
            if (object.showAppPromotion != null) {
                if (typeof object.showAppPromotion !== "object")
                    throw TypeError(".story_video.StoryVideoOption.showAppPromotion: object expected");
                message.showAppPromotion = $root.google.protobuf.BoolValue.fromObject(object.showAppPromotion);
            }
            if (object.bitrate != null) {
                if (typeof object.bitrate !== "object")
                    throw TypeError(".story_video.StoryVideoOption.bitrate: object expected");
                message.bitrate = $root.google.protobuf.Int32Value.fromObject(object.bitrate);
            }
            if (object.crf != null) {
                if (typeof object.crf !== "object")
                    throw TypeError(".story_video.StoryVideoOption.crf: object expected");
                message.crf = $root.google.protobuf.Int32Value.fromObject(object.crf);
            }
            if (object.showWatermark != null) {
                if (typeof object.showWatermark !== "object")
                    throw TypeError(".story_video.StoryVideoOption.showWatermark: object expected");
                message.showWatermark = $root.google.protobuf.StringValue.fromObject(object.showWatermark);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryVideoOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_video.StoryVideoOption
         * @static
         * @param {story_video.StoryVideoOption} message StoryVideoOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryVideoOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.width = null;
                object.height = null;
                object.scale = null;
                object.wait = null;
                object.maxTapCount = null;
                object.showHeader = null;
                object.showAppPromotion = null;
                object.bitrate = null;
                object.crf = null;
                object.showWatermark = null;
            }
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = $root.google.protobuf.Int32Value.toObject(message.width, options);
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = $root.google.protobuf.Int32Value.toObject(message.height, options);
            if (message.scale != null && message.hasOwnProperty("scale"))
                object.scale = $root.google.protobuf.FloatValue.toObject(message.scale, options);
            if (message.wait != null && message.hasOwnProperty("wait"))
                object.wait = $root.google.protobuf.Duration.toObject(message.wait, options);
            if (message.maxTapCount != null && message.hasOwnProperty("maxTapCount"))
                object.maxTapCount = $root.google.protobuf.Int32Value.toObject(message.maxTapCount, options);
            if (message.showHeader != null && message.hasOwnProperty("showHeader"))
                object.showHeader = $root.google.protobuf.BoolValue.toObject(message.showHeader, options);
            if (message.showAppPromotion != null && message.hasOwnProperty("showAppPromotion"))
                object.showAppPromotion = $root.google.protobuf.BoolValue.toObject(message.showAppPromotion, options);
            if (message.bitrate != null && message.hasOwnProperty("bitrate"))
                object.bitrate = $root.google.protobuf.Int32Value.toObject(message.bitrate, options);
            if (message.crf != null && message.hasOwnProperty("crf"))
                object.crf = $root.google.protobuf.Int32Value.toObject(message.crf, options);
            if (message.showWatermark != null && message.hasOwnProperty("showWatermark"))
                object.showWatermark = $root.google.protobuf.StringValue.toObject(message.showWatermark, options);
            return object;
        };

        /**
         * Converts this StoryVideoOption to JSON.
         * @function toJSON
         * @memberof story_video.StoryVideoOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryVideoOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryVideoOption
         * @function getTypeUrl
         * @memberof story_video.StoryVideoOption
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryVideoOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_video.StoryVideoOption";
        };

        return StoryVideoOption;
    })();

    /**
     * StoryVideoType enum.
     * @name story_video.StoryVideoType
     * @enum {number}
     * @property {number} STORY_VIDEO_TYPE_UNSPECIFIED=0 STORY_VIDEO_TYPE_UNSPECIFIED value
     * @property {number} STORY_VIDEO_TYPE_FOR_SHARE=1 STORY_VIDEO_TYPE_FOR_SHARE value
     * @property {number} STORY_VIDEO_TYPE_FOR_IN_APP_TIMELINE=2 STORY_VIDEO_TYPE_FOR_IN_APP_TIMELINE value
     * @property {number} STORY_VIDEO_TYPE_FOR_TIKTOK=3 STORY_VIDEO_TYPE_FOR_TIKTOK value
     */
    story_video.StoryVideoType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_VIDEO_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STORY_VIDEO_TYPE_FOR_SHARE"] = 1;
        values[valuesById[2] = "STORY_VIDEO_TYPE_FOR_IN_APP_TIMELINE"] = 2;
        values[valuesById[3] = "STORY_VIDEO_TYPE_FOR_TIKTOK"] = 3;
        return values;
    })();

    return story_video;
})();

$root.query_series_selection = (function() {

    /**
     * Namespace query_series_selection.
     * @exports query_series_selection
     * @namespace
     */
    var query_series_selection = {};

    /**
     * SeriesSelectionShuffling enum.
     * @name query_series_selection.SeriesSelectionShuffling
     * @enum {number}
     * @property {number} SERIES_SELECTION_SHUFFLING_UNSPECIFIED=0 SERIES_SELECTION_SHUFFLING_UNSPECIFIED value
     * @property {number} SERIES_SELECTION_SHUFFLING_EVERYTIME=1 SERIES_SELECTION_SHUFFLING_EVERYTIME value
     * @property {number} SERIES_SELECTION_SHUFFLING_DAILY=2 SERIES_SELECTION_SHUFFLING_DAILY value
     */
    query_series_selection.SeriesSelectionShuffling = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERIES_SELECTION_SHUFFLING_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SERIES_SELECTION_SHUFFLING_EVERYTIME"] = 1;
        values[valuesById[2] = "SERIES_SELECTION_SHUFFLING_DAILY"] = 2;
        return values;
    })();

    return query_series_selection;
})();

$root.query_search_query = (function() {

    /**
     * Namespace query_search_query.
     * @exports query_search_query
     * @namespace
     */
    var query_search_query = {};

    query_search_query.SearchQueryRequest = (function() {

        /**
         * Properties of a SearchQueryRequest.
         * @memberof query_search_query
         * @interface ISearchQueryRequest
         * @property {query_search_query.ISearchQueryPageRequest|null} [requirePopularSearchQuery] SearchQueryRequest requirePopularSearchQuery
         */

        /**
         * Constructs a new SearchQueryRequest.
         * @memberof query_search_query
         * @classdesc Represents a SearchQueryRequest.
         * @implements ISearchQueryRequest
         * @constructor
         * @param {query_search_query.ISearchQueryRequest=} [properties] Properties to set
         */
        function SearchQueryRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchQueryRequest requirePopularSearchQuery.
         * @member {query_search_query.ISearchQueryPageRequest|null|undefined} requirePopularSearchQuery
         * @memberof query_search_query.SearchQueryRequest
         * @instance
         */
        SearchQueryRequest.prototype.requirePopularSearchQuery = null;

        /**
         * Creates a new SearchQueryRequest instance using the specified properties.
         * @function create
         * @memberof query_search_query.SearchQueryRequest
         * @static
         * @param {query_search_query.ISearchQueryRequest=} [properties] Properties to set
         * @returns {query_search_query.SearchQueryRequest} SearchQueryRequest instance
         */
        SearchQueryRequest.create = function create(properties) {
            return new SearchQueryRequest(properties);
        };

        /**
         * Encodes the specified SearchQueryRequest message. Does not implicitly {@link query_search_query.SearchQueryRequest.verify|verify} messages.
         * @function encode
         * @memberof query_search_query.SearchQueryRequest
         * @static
         * @param {query_search_query.ISearchQueryRequest} message SearchQueryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchQueryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requirePopularSearchQuery != null && Object.hasOwnProperty.call(message, "requirePopularSearchQuery"))
                $root.query_search_query.SearchQueryPageRequest.encode(message.requirePopularSearchQuery, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchQueryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_query.SearchQueryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_query.SearchQueryRequest} SearchQueryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchQueryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_query.SearchQueryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requirePopularSearchQuery = $root.query_search_query.SearchQueryPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchQueryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_query.SearchQueryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_query.SearchQueryRequest} SearchQueryRequest
         */
        SearchQueryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_query.SearchQueryRequest)
                return object;
            var message = new $root.query_search_query.SearchQueryRequest();
            if (object.requirePopularSearchQuery != null) {
                if (typeof object.requirePopularSearchQuery !== "object")
                    throw TypeError(".query_search_query.SearchQueryRequest.requirePopularSearchQuery: object expected");
                message.requirePopularSearchQuery = $root.query_search_query.SearchQueryPageRequest.fromObject(object.requirePopularSearchQuery);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchQueryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_query.SearchQueryRequest
         * @static
         * @param {query_search_query.SearchQueryRequest} message SearchQueryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchQueryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requirePopularSearchQuery = null;
            if (message.requirePopularSearchQuery != null && message.hasOwnProperty("requirePopularSearchQuery"))
                object.requirePopularSearchQuery = $root.query_search_query.SearchQueryPageRequest.toObject(message.requirePopularSearchQuery, options);
            return object;
        };

        /**
         * Converts this SearchQueryRequest to JSON.
         * @function toJSON
         * @memberof query_search_query.SearchQueryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchQueryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchQueryRequest
         * @function getTypeUrl
         * @memberof query_search_query.SearchQueryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchQueryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_query.SearchQueryRequest";
        };

        return SearchQueryRequest;
    })();

    query_search_query.SearchQueryPageRequest = (function() {

        /**
         * Properties of a SearchQueryPageRequest.
         * @memberof query_search_query
         * @interface ISearchQueryPageRequest
         * @property {google.protobuf.IBoolValue|null} [requireQueryList] SearchQueryPageRequest requireQueryList
         * @property {query_pagination.IPageRequest|null} [page] SearchQueryPageRequest page
         */

        /**
         * Constructs a new SearchQueryPageRequest.
         * @memberof query_search_query
         * @classdesc Represents a SearchQueryPageRequest.
         * @implements ISearchQueryPageRequest
         * @constructor
         * @param {query_search_query.ISearchQueryPageRequest=} [properties] Properties to set
         */
        function SearchQueryPageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchQueryPageRequest requireQueryList.
         * @member {google.protobuf.IBoolValue|null|undefined} requireQueryList
         * @memberof query_search_query.SearchQueryPageRequest
         * @instance
         */
        SearchQueryPageRequest.prototype.requireQueryList = null;

        /**
         * SearchQueryPageRequest page.
         * @member {query_pagination.IPageRequest|null|undefined} page
         * @memberof query_search_query.SearchQueryPageRequest
         * @instance
         */
        SearchQueryPageRequest.prototype.page = null;

        /**
         * Creates a new SearchQueryPageRequest instance using the specified properties.
         * @function create
         * @memberof query_search_query.SearchQueryPageRequest
         * @static
         * @param {query_search_query.ISearchQueryPageRequest=} [properties] Properties to set
         * @returns {query_search_query.SearchQueryPageRequest} SearchQueryPageRequest instance
         */
        SearchQueryPageRequest.create = function create(properties) {
            return new SearchQueryPageRequest(properties);
        };

        /**
         * Encodes the specified SearchQueryPageRequest message. Does not implicitly {@link query_search_query.SearchQueryPageRequest.verify|verify} messages.
         * @function encode
         * @memberof query_search_query.SearchQueryPageRequest
         * @static
         * @param {query_search_query.ISearchQueryPageRequest} message SearchQueryPageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchQueryPageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireQueryList != null && Object.hasOwnProperty.call(message, "requireQueryList"))
                $root.google.protobuf.BoolValue.encode(message.requireQueryList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageRequest.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchQueryPageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_query.SearchQueryPageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_query.SearchQueryPageRequest} SearchQueryPageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchQueryPageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_query.SearchQueryPageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireQueryList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.page = $root.query_pagination.PageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchQueryPageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_query.SearchQueryPageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_query.SearchQueryPageRequest} SearchQueryPageRequest
         */
        SearchQueryPageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_query.SearchQueryPageRequest)
                return object;
            var message = new $root.query_search_query.SearchQueryPageRequest();
            if (object.requireQueryList != null) {
                if (typeof object.requireQueryList !== "object")
                    throw TypeError(".query_search_query.SearchQueryPageRequest.requireQueryList: object expected");
                message.requireQueryList = $root.google.protobuf.BoolValue.fromObject(object.requireQueryList);
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_search_query.SearchQueryPageRequest.page: object expected");
                message.page = $root.query_pagination.PageRequest.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchQueryPageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_query.SearchQueryPageRequest
         * @static
         * @param {query_search_query.SearchQueryPageRequest} message SearchQueryPageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchQueryPageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireQueryList = null;
                object.page = null;
            }
            if (message.requireQueryList != null && message.hasOwnProperty("requireQueryList"))
                object.requireQueryList = $root.google.protobuf.BoolValue.toObject(message.requireQueryList, options);
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageRequest.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this SearchQueryPageRequest to JSON.
         * @function toJSON
         * @memberof query_search_query.SearchQueryPageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchQueryPageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchQueryPageRequest
         * @function getTypeUrl
         * @memberof query_search_query.SearchQueryPageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchQueryPageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_query.SearchQueryPageRequest";
        };

        return SearchQueryPageRequest;
    })();

    query_search_query.SearchQueryResponse = (function() {

        /**
         * Properties of a SearchQueryResponse.
         * @memberof query_search_query
         * @interface ISearchQueryResponse
         * @property {query_search_query.ISearchQueryPageResponse|null} [popularSearchQueryList] SearchQueryResponse popularSearchQueryList
         */

        /**
         * Constructs a new SearchQueryResponse.
         * @memberof query_search_query
         * @classdesc Represents a SearchQueryResponse.
         * @implements ISearchQueryResponse
         * @constructor
         * @param {query_search_query.ISearchQueryResponse=} [properties] Properties to set
         */
        function SearchQueryResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchQueryResponse popularSearchQueryList.
         * @member {query_search_query.ISearchQueryPageResponse|null|undefined} popularSearchQueryList
         * @memberof query_search_query.SearchQueryResponse
         * @instance
         */
        SearchQueryResponse.prototype.popularSearchQueryList = null;

        /**
         * Creates a new SearchQueryResponse instance using the specified properties.
         * @function create
         * @memberof query_search_query.SearchQueryResponse
         * @static
         * @param {query_search_query.ISearchQueryResponse=} [properties] Properties to set
         * @returns {query_search_query.SearchQueryResponse} SearchQueryResponse instance
         */
        SearchQueryResponse.create = function create(properties) {
            return new SearchQueryResponse(properties);
        };

        /**
         * Encodes the specified SearchQueryResponse message. Does not implicitly {@link query_search_query.SearchQueryResponse.verify|verify} messages.
         * @function encode
         * @memberof query_search_query.SearchQueryResponse
         * @static
         * @param {query_search_query.ISearchQueryResponse} message SearchQueryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchQueryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.popularSearchQueryList != null && Object.hasOwnProperty.call(message, "popularSearchQueryList"))
                $root.query_search_query.SearchQueryPageResponse.encode(message.popularSearchQueryList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchQueryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_query.SearchQueryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_query.SearchQueryResponse} SearchQueryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchQueryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_query.SearchQueryResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.popularSearchQueryList = $root.query_search_query.SearchQueryPageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchQueryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_query.SearchQueryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_query.SearchQueryResponse} SearchQueryResponse
         */
        SearchQueryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_query.SearchQueryResponse)
                return object;
            var message = new $root.query_search_query.SearchQueryResponse();
            if (object.popularSearchQueryList != null) {
                if (typeof object.popularSearchQueryList !== "object")
                    throw TypeError(".query_search_query.SearchQueryResponse.popularSearchQueryList: object expected");
                message.popularSearchQueryList = $root.query_search_query.SearchQueryPageResponse.fromObject(object.popularSearchQueryList);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchQueryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_query.SearchQueryResponse
         * @static
         * @param {query_search_query.SearchQueryResponse} message SearchQueryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchQueryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.popularSearchQueryList = null;
            if (message.popularSearchQueryList != null && message.hasOwnProperty("popularSearchQueryList"))
                object.popularSearchQueryList = $root.query_search_query.SearchQueryPageResponse.toObject(message.popularSearchQueryList, options);
            return object;
        };

        /**
         * Converts this SearchQueryResponse to JSON.
         * @function toJSON
         * @memberof query_search_query.SearchQueryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchQueryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchQueryResponse
         * @function getTypeUrl
         * @memberof query_search_query.SearchQueryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_query.SearchQueryResponse";
        };

        return SearchQueryResponse;
    })();

    query_search_query.SearchQueryPageResponse = (function() {

        /**
         * Properties of a SearchQueryPageResponse.
         * @memberof query_search_query
         * @interface ISearchQueryPageResponse
         * @property {Array.<google.protobuf.IStringValue>|null} [queryList] SearchQueryPageResponse queryList
         * @property {query_pagination.IPageInfo|null} [page] SearchQueryPageResponse page
         */

        /**
         * Constructs a new SearchQueryPageResponse.
         * @memberof query_search_query
         * @classdesc Represents a SearchQueryPageResponse.
         * @implements ISearchQueryPageResponse
         * @constructor
         * @param {query_search_query.ISearchQueryPageResponse=} [properties] Properties to set
         */
        function SearchQueryPageResponse(properties) {
            this.queryList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchQueryPageResponse queryList.
         * @member {Array.<google.protobuf.IStringValue>} queryList
         * @memberof query_search_query.SearchQueryPageResponse
         * @instance
         */
        SearchQueryPageResponse.prototype.queryList = $util.emptyArray;

        /**
         * SearchQueryPageResponse page.
         * @member {query_pagination.IPageInfo|null|undefined} page
         * @memberof query_search_query.SearchQueryPageResponse
         * @instance
         */
        SearchQueryPageResponse.prototype.page = null;

        /**
         * Creates a new SearchQueryPageResponse instance using the specified properties.
         * @function create
         * @memberof query_search_query.SearchQueryPageResponse
         * @static
         * @param {query_search_query.ISearchQueryPageResponse=} [properties] Properties to set
         * @returns {query_search_query.SearchQueryPageResponse} SearchQueryPageResponse instance
         */
        SearchQueryPageResponse.create = function create(properties) {
            return new SearchQueryPageResponse(properties);
        };

        /**
         * Encodes the specified SearchQueryPageResponse message. Does not implicitly {@link query_search_query.SearchQueryPageResponse.verify|verify} messages.
         * @function encode
         * @memberof query_search_query.SearchQueryPageResponse
         * @static
         * @param {query_search_query.ISearchQueryPageResponse} message SearchQueryPageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchQueryPageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.queryList != null && message.queryList.length)
                for (var i = 0; i < message.queryList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.queryList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                $root.query_pagination.PageInfo.encode(message.page, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchQueryPageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_query.SearchQueryPageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_query.SearchQueryPageResponse} SearchQueryPageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchQueryPageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_query.SearchQueryPageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.queryList && message.queryList.length))
                        message.queryList = [];
                    message.queryList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = $root.query_pagination.PageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchQueryPageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_query.SearchQueryPageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_query.SearchQueryPageResponse} SearchQueryPageResponse
         */
        SearchQueryPageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_query.SearchQueryPageResponse)
                return object;
            var message = new $root.query_search_query.SearchQueryPageResponse();
            if (object.queryList) {
                if (!Array.isArray(object.queryList))
                    throw TypeError(".query_search_query.SearchQueryPageResponse.queryList: array expected");
                message.queryList = [];
                for (var i = 0; i < object.queryList.length; ++i) {
                    if (typeof object.queryList[i] !== "object")
                        throw TypeError(".query_search_query.SearchQueryPageResponse.queryList: object expected");
                    message.queryList[i] = $root.google.protobuf.StringValue.fromObject(object.queryList[i]);
                }
            }
            if (object.page != null) {
                if (typeof object.page !== "object")
                    throw TypeError(".query_search_query.SearchQueryPageResponse.page: object expected");
                message.page = $root.query_pagination.PageInfo.fromObject(object.page);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchQueryPageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_query.SearchQueryPageResponse
         * @static
         * @param {query_search_query.SearchQueryPageResponse} message SearchQueryPageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchQueryPageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.queryList = [];
            if (options.defaults)
                object.page = null;
            if (message.queryList && message.queryList.length) {
                object.queryList = [];
                for (var j = 0; j < message.queryList.length; ++j)
                    object.queryList[j] = $root.google.protobuf.StringValue.toObject(message.queryList[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = $root.query_pagination.PageInfo.toObject(message.page, options);
            return object;
        };

        /**
         * Converts this SearchQueryPageResponse to JSON.
         * @function toJSON
         * @memberof query_search_query.SearchQueryPageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchQueryPageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchQueryPageResponse
         * @function getTypeUrl
         * @memberof query_search_query.SearchQueryPageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchQueryPageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_query.SearchQueryPageResponse";
        };

        return SearchQueryPageResponse;
    })();

    return query_search_query;
})();

$root.query_search_v2 = (function() {

    /**
     * Namespace query_search_v2.
     * @exports query_search_v2
     * @namespace
     */
    var query_search_v2 = {};

    query_search_v2.SearchSeriesRequestV2 = (function() {

        /**
         * Properties of a SearchSeriesRequestV2.
         * @memberof query_search_v2
         * @interface ISearchSeriesRequestV2
         * @property {google.protobuf.IStringValue|null} [q] SearchSeriesRequestV2 q
         * @property {query_search_v2.OfficialFilter|null} [officialFilter] SearchSeriesRequestV2 officialFilter
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchSeriesRequestV2 sensitiveFilter
         * @property {query_recursive_types.IScriptTypeFilter|null} [scriptTypeFilter] SearchSeriesRequestV2 scriptTypeFilter
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SearchSeriesRequestV2 requireSeriesPage
         */

        /**
         * Constructs a new SearchSeriesRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchSeriesRequestV2.
         * @implements ISearchSeriesRequestV2
         * @constructor
         * @param {query_search_v2.ISearchSeriesRequestV2=} [properties] Properties to set
         */
        function SearchSeriesRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesRequestV2 q.
         * @member {google.protobuf.IStringValue|null|undefined} q
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @instance
         */
        SearchSeriesRequestV2.prototype.q = null;

        /**
         * SearchSeriesRequestV2 officialFilter.
         * @member {query_search_v2.OfficialFilter} officialFilter
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @instance
         */
        SearchSeriesRequestV2.prototype.officialFilter = 0;

        /**
         * SearchSeriesRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @instance
         */
        SearchSeriesRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchSeriesRequestV2 scriptTypeFilter.
         * @member {query_recursive_types.IScriptTypeFilter|null|undefined} scriptTypeFilter
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @instance
         */
        SearchSeriesRequestV2.prototype.scriptTypeFilter = null;

        /**
         * SearchSeriesRequestV2 requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @instance
         */
        SearchSeriesRequestV2.prototype.requireSeriesPage = null;

        /**
         * Creates a new SearchSeriesRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchSeriesRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchSeriesRequestV2} SearchSeriesRequestV2 instance
         */
        SearchSeriesRequestV2.create = function create(properties) {
            return new SearchSeriesRequestV2(properties);
        };

        /**
         * Encodes the specified SearchSeriesRequestV2 message. Does not implicitly {@link query_search_v2.SearchSeriesRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchSeriesRequestV2} message SearchSeriesRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.q != null && Object.hasOwnProperty.call(message, "q"))
                $root.google.protobuf.StringValue.encode(message.q, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.officialFilter != null && Object.hasOwnProperty.call(message, "officialFilter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.officialFilter);
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sensitiveFilter);
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.scriptTypeFilter != null && Object.hasOwnProperty.call(message, "scriptTypeFilter"))
                $root.query_recursive_types.ScriptTypeFilter.encode(message.scriptTypeFilter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchSeriesRequestV2} SearchSeriesRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchSeriesRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.q = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.officialFilter = reader.int32();
                    break;
                case 3:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 5:
                    message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchSeriesRequestV2} SearchSeriesRequestV2
         */
        SearchSeriesRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchSeriesRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchSeriesRequestV2();
            if (object.q != null) {
                if (typeof object.q !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesRequestV2.q: object expected");
                message.q = $root.google.protobuf.StringValue.fromObject(object.q);
            }
            switch (object.officialFilter) {
            case "OFFICIAL_FILTER_UNSPECIFIED":
            case 0:
                message.officialFilter = 0;
                break;
            case "OFFICIAL_FILTER_OFFICIAL":
            case 1:
                message.officialFilter = 1;
                break;
            case "OFFICIAL_FILTER_NOT_OFFICIAL":
            case 2:
                message.officialFilter = 2;
                break;
            }
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            if (object.scriptTypeFilter != null) {
                if (typeof object.scriptTypeFilter !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesRequestV2.scriptTypeFilter: object expected");
                message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.fromObject(object.scriptTypeFilter);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesRequestV2.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @static
         * @param {query_search_v2.SearchSeriesRequestV2} message SearchSeriesRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.q = null;
                object.officialFilter = options.enums === String ? "OFFICIAL_FILTER_UNSPECIFIED" : 0;
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
                object.requireSeriesPage = null;
                object.scriptTypeFilter = null;
            }
            if (message.q != null && message.hasOwnProperty("q"))
                object.q = $root.google.protobuf.StringValue.toObject(message.q, options);
            if (message.officialFilter != null && message.hasOwnProperty("officialFilter"))
                object.officialFilter = options.enums === String ? $root.query_search_v2.OfficialFilter[message.officialFilter] : message.officialFilter;
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            if (message.scriptTypeFilter != null && message.hasOwnProperty("scriptTypeFilter"))
                object.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.toObject(message.scriptTypeFilter, options);
            return object;
        };

        /**
         * Converts this SearchSeriesRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchSeriesRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchSeriesRequestV2";
        };

        return SearchSeriesRequestV2;
    })();

    query_search_v2.SearchSeriesByTitleRequestV2 = (function() {

        /**
         * Properties of a SearchSeriesByTitleRequestV2.
         * @memberof query_search_v2
         * @interface ISearchSeriesByTitleRequestV2
         * @property {google.protobuf.IStringValue|null} [q] SearchSeriesByTitleRequestV2 q
         * @property {query_search_v2.OfficialFilter|null} [officialFilter] SearchSeriesByTitleRequestV2 officialFilter
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchSeriesByTitleRequestV2 sensitiveFilter
         * @property {query_recursive_types.IScriptTypeFilter|null} [scriptTypeFilter] SearchSeriesByTitleRequestV2 scriptTypeFilter
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SearchSeriesByTitleRequestV2 requireSeriesPage
         */

        /**
         * Constructs a new SearchSeriesByTitleRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchSeriesByTitleRequestV2.
         * @implements ISearchSeriesByTitleRequestV2
         * @constructor
         * @param {query_search_v2.ISearchSeriesByTitleRequestV2=} [properties] Properties to set
         */
        function SearchSeriesByTitleRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesByTitleRequestV2 q.
         * @member {google.protobuf.IStringValue|null|undefined} q
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @instance
         */
        SearchSeriesByTitleRequestV2.prototype.q = null;

        /**
         * SearchSeriesByTitleRequestV2 officialFilter.
         * @member {query_search_v2.OfficialFilter} officialFilter
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @instance
         */
        SearchSeriesByTitleRequestV2.prototype.officialFilter = 0;

        /**
         * SearchSeriesByTitleRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @instance
         */
        SearchSeriesByTitleRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchSeriesByTitleRequestV2 scriptTypeFilter.
         * @member {query_recursive_types.IScriptTypeFilter|null|undefined} scriptTypeFilter
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @instance
         */
        SearchSeriesByTitleRequestV2.prototype.scriptTypeFilter = null;

        /**
         * SearchSeriesByTitleRequestV2 requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @instance
         */
        SearchSeriesByTitleRequestV2.prototype.requireSeriesPage = null;

        /**
         * Creates a new SearchSeriesByTitleRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @static
         * @param {query_search_v2.ISearchSeriesByTitleRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchSeriesByTitleRequestV2} SearchSeriesByTitleRequestV2 instance
         */
        SearchSeriesByTitleRequestV2.create = function create(properties) {
            return new SearchSeriesByTitleRequestV2(properties);
        };

        /**
         * Encodes the specified SearchSeriesByTitleRequestV2 message. Does not implicitly {@link query_search_v2.SearchSeriesByTitleRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @static
         * @param {query_search_v2.ISearchSeriesByTitleRequestV2} message SearchSeriesByTitleRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesByTitleRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.q != null && Object.hasOwnProperty.call(message, "q"))
                $root.google.protobuf.StringValue.encode(message.q, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.officialFilter != null && Object.hasOwnProperty.call(message, "officialFilter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.officialFilter);
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sensitiveFilter);
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.scriptTypeFilter != null && Object.hasOwnProperty.call(message, "scriptTypeFilter"))
                $root.query_recursive_types.ScriptTypeFilter.encode(message.scriptTypeFilter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesByTitleRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchSeriesByTitleRequestV2} SearchSeriesByTitleRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesByTitleRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchSeriesByTitleRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.q = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.officialFilter = reader.int32();
                    break;
                case 3:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 5:
                    message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesByTitleRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchSeriesByTitleRequestV2} SearchSeriesByTitleRequestV2
         */
        SearchSeriesByTitleRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchSeriesByTitleRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchSeriesByTitleRequestV2();
            if (object.q != null) {
                if (typeof object.q !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesByTitleRequestV2.q: object expected");
                message.q = $root.google.protobuf.StringValue.fromObject(object.q);
            }
            switch (object.officialFilter) {
            case "OFFICIAL_FILTER_UNSPECIFIED":
            case 0:
                message.officialFilter = 0;
                break;
            case "OFFICIAL_FILTER_OFFICIAL":
            case 1:
                message.officialFilter = 1;
                break;
            case "OFFICIAL_FILTER_NOT_OFFICIAL":
            case 2:
                message.officialFilter = 2;
                break;
            }
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            if (object.scriptTypeFilter != null) {
                if (typeof object.scriptTypeFilter !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesByTitleRequestV2.scriptTypeFilter: object expected");
                message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.fromObject(object.scriptTypeFilter);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesByTitleRequestV2.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesByTitleRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @static
         * @param {query_search_v2.SearchSeriesByTitleRequestV2} message SearchSeriesByTitleRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesByTitleRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.q = null;
                object.officialFilter = options.enums === String ? "OFFICIAL_FILTER_UNSPECIFIED" : 0;
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
                object.requireSeriesPage = null;
                object.scriptTypeFilter = null;
            }
            if (message.q != null && message.hasOwnProperty("q"))
                object.q = $root.google.protobuf.StringValue.toObject(message.q, options);
            if (message.officialFilter != null && message.hasOwnProperty("officialFilter"))
                object.officialFilter = options.enums === String ? $root.query_search_v2.OfficialFilter[message.officialFilter] : message.officialFilter;
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            if (message.scriptTypeFilter != null && message.hasOwnProperty("scriptTypeFilter"))
                object.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.toObject(message.scriptTypeFilter, options);
            return object;
        };

        /**
         * Converts this SearchSeriesByTitleRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesByTitleRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesByTitleRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchSeriesByTitleRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesByTitleRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchSeriesByTitleRequestV2";
        };

        return SearchSeriesByTitleRequestV2;
    })();

    query_search_v2.SearchSeriesByTagRequestV2 = (function() {

        /**
         * Properties of a SearchSeriesByTagRequestV2.
         * @memberof query_search_v2
         * @interface ISearchSeriesByTagRequestV2
         * @property {google.protobuf.IStringValue|null} [tag] SearchSeriesByTagRequestV2 tag
         * @property {query_search_v2.OfficialFilter|null} [officialFilter] SearchSeriesByTagRequestV2 officialFilter
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchSeriesByTagRequestV2 sensitiveFilter
         * @property {query_recursive_types.IScriptTypeFilter|null} [scriptTypeFilter] SearchSeriesByTagRequestV2 scriptTypeFilter
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SearchSeriesByTagRequestV2 requireSeriesPage
         */

        /**
         * Constructs a new SearchSeriesByTagRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchSeriesByTagRequestV2.
         * @implements ISearchSeriesByTagRequestV2
         * @constructor
         * @param {query_search_v2.ISearchSeriesByTagRequestV2=} [properties] Properties to set
         */
        function SearchSeriesByTagRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesByTagRequestV2 tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @instance
         */
        SearchSeriesByTagRequestV2.prototype.tag = null;

        /**
         * SearchSeriesByTagRequestV2 officialFilter.
         * @member {query_search_v2.OfficialFilter} officialFilter
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @instance
         */
        SearchSeriesByTagRequestV2.prototype.officialFilter = 0;

        /**
         * SearchSeriesByTagRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @instance
         */
        SearchSeriesByTagRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchSeriesByTagRequestV2 scriptTypeFilter.
         * @member {query_recursive_types.IScriptTypeFilter|null|undefined} scriptTypeFilter
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @instance
         */
        SearchSeriesByTagRequestV2.prototype.scriptTypeFilter = null;

        /**
         * SearchSeriesByTagRequestV2 requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @instance
         */
        SearchSeriesByTagRequestV2.prototype.requireSeriesPage = null;

        /**
         * Creates a new SearchSeriesByTagRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @static
         * @param {query_search_v2.ISearchSeriesByTagRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchSeriesByTagRequestV2} SearchSeriesByTagRequestV2 instance
         */
        SearchSeriesByTagRequestV2.create = function create(properties) {
            return new SearchSeriesByTagRequestV2(properties);
        };

        /**
         * Encodes the specified SearchSeriesByTagRequestV2 message. Does not implicitly {@link query_search_v2.SearchSeriesByTagRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @static
         * @param {query_search_v2.ISearchSeriesByTagRequestV2} message SearchSeriesByTagRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesByTagRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.officialFilter != null && Object.hasOwnProperty.call(message, "officialFilter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.officialFilter);
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sensitiveFilter);
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.scriptTypeFilter != null && Object.hasOwnProperty.call(message, "scriptTypeFilter"))
                $root.query_recursive_types.ScriptTypeFilter.encode(message.scriptTypeFilter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesByTagRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchSeriesByTagRequestV2} SearchSeriesByTagRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesByTagRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchSeriesByTagRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.officialFilter = reader.int32();
                    break;
                case 3:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 5:
                    message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesByTagRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchSeriesByTagRequestV2} SearchSeriesByTagRequestV2
         */
        SearchSeriesByTagRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchSeriesByTagRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchSeriesByTagRequestV2();
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesByTagRequestV2.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            switch (object.officialFilter) {
            case "OFFICIAL_FILTER_UNSPECIFIED":
            case 0:
                message.officialFilter = 0;
                break;
            case "OFFICIAL_FILTER_OFFICIAL":
            case 1:
                message.officialFilter = 1;
                break;
            case "OFFICIAL_FILTER_NOT_OFFICIAL":
            case 2:
                message.officialFilter = 2;
                break;
            }
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            if (object.scriptTypeFilter != null) {
                if (typeof object.scriptTypeFilter !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesByTagRequestV2.scriptTypeFilter: object expected");
                message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.fromObject(object.scriptTypeFilter);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesByTagRequestV2.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesByTagRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @static
         * @param {query_search_v2.SearchSeriesByTagRequestV2} message SearchSeriesByTagRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesByTagRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.tag = null;
                object.officialFilter = options.enums === String ? "OFFICIAL_FILTER_UNSPECIFIED" : 0;
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
                object.requireSeriesPage = null;
                object.scriptTypeFilter = null;
            }
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.officialFilter != null && message.hasOwnProperty("officialFilter"))
                object.officialFilter = options.enums === String ? $root.query_search_v2.OfficialFilter[message.officialFilter] : message.officialFilter;
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            if (message.scriptTypeFilter != null && message.hasOwnProperty("scriptTypeFilter"))
                object.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.toObject(message.scriptTypeFilter, options);
            return object;
        };

        /**
         * Converts this SearchSeriesByTagRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesByTagRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesByTagRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchSeriesByTagRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesByTagRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchSeriesByTagRequestV2";
        };

        return SearchSeriesByTagRequestV2;
    })();

    query_search_v2.SearchLatestSeriesRequestV2 = (function() {

        /**
         * Properties of a SearchLatestSeriesRequestV2.
         * @memberof query_search_v2
         * @interface ISearchLatestSeriesRequestV2
         * @property {query_search_v2.OfficialFilter|null} [officialFilter] SearchLatestSeriesRequestV2 officialFilter
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchLatestSeriesRequestV2 sensitiveFilter
         * @property {query_recursive_types.IScriptTypeFilter|null} [scriptTypeFilter] SearchLatestSeriesRequestV2 scriptTypeFilter
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SearchLatestSeriesRequestV2 requireSeriesPage
         */

        /**
         * Constructs a new SearchLatestSeriesRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchLatestSeriesRequestV2.
         * @implements ISearchLatestSeriesRequestV2
         * @constructor
         * @param {query_search_v2.ISearchLatestSeriesRequestV2=} [properties] Properties to set
         */
        function SearchLatestSeriesRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchLatestSeriesRequestV2 officialFilter.
         * @member {query_search_v2.OfficialFilter} officialFilter
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @instance
         */
        SearchLatestSeriesRequestV2.prototype.officialFilter = 0;

        /**
         * SearchLatestSeriesRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @instance
         */
        SearchLatestSeriesRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchLatestSeriesRequestV2 scriptTypeFilter.
         * @member {query_recursive_types.IScriptTypeFilter|null|undefined} scriptTypeFilter
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @instance
         */
        SearchLatestSeriesRequestV2.prototype.scriptTypeFilter = null;

        /**
         * SearchLatestSeriesRequestV2 requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @instance
         */
        SearchLatestSeriesRequestV2.prototype.requireSeriesPage = null;

        /**
         * Creates a new SearchLatestSeriesRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchLatestSeriesRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchLatestSeriesRequestV2} SearchLatestSeriesRequestV2 instance
         */
        SearchLatestSeriesRequestV2.create = function create(properties) {
            return new SearchLatestSeriesRequestV2(properties);
        };

        /**
         * Encodes the specified SearchLatestSeriesRequestV2 message. Does not implicitly {@link query_search_v2.SearchLatestSeriesRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchLatestSeriesRequestV2} message SearchLatestSeriesRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchLatestSeriesRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.officialFilter != null && Object.hasOwnProperty.call(message, "officialFilter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.officialFilter);
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sensitiveFilter);
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.scriptTypeFilter != null && Object.hasOwnProperty.call(message, "scriptTypeFilter"))
                $root.query_recursive_types.ScriptTypeFilter.encode(message.scriptTypeFilter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchLatestSeriesRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchLatestSeriesRequestV2} SearchLatestSeriesRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchLatestSeriesRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchLatestSeriesRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.officialFilter = reader.int32();
                    break;
                case 3:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 5:
                    message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchLatestSeriesRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchLatestSeriesRequestV2} SearchLatestSeriesRequestV2
         */
        SearchLatestSeriesRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchLatestSeriesRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchLatestSeriesRequestV2();
            switch (object.officialFilter) {
            case "OFFICIAL_FILTER_UNSPECIFIED":
            case 0:
                message.officialFilter = 0;
                break;
            case "OFFICIAL_FILTER_OFFICIAL":
            case 1:
                message.officialFilter = 1;
                break;
            case "OFFICIAL_FILTER_NOT_OFFICIAL":
            case 2:
                message.officialFilter = 2;
                break;
            }
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            if (object.scriptTypeFilter != null) {
                if (typeof object.scriptTypeFilter !== "object")
                    throw TypeError(".query_search_v2.SearchLatestSeriesRequestV2.scriptTypeFilter: object expected");
                message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.fromObject(object.scriptTypeFilter);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_search_v2.SearchLatestSeriesRequestV2.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchLatestSeriesRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @static
         * @param {query_search_v2.SearchLatestSeriesRequestV2} message SearchLatestSeriesRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchLatestSeriesRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.officialFilter = options.enums === String ? "OFFICIAL_FILTER_UNSPECIFIED" : 0;
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
                object.requireSeriesPage = null;
                object.scriptTypeFilter = null;
            }
            if (message.officialFilter != null && message.hasOwnProperty("officialFilter"))
                object.officialFilter = options.enums === String ? $root.query_search_v2.OfficialFilter[message.officialFilter] : message.officialFilter;
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            if (message.scriptTypeFilter != null && message.hasOwnProperty("scriptTypeFilter"))
                object.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.toObject(message.scriptTypeFilter, options);
            return object;
        };

        /**
         * Converts this SearchLatestSeriesRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchLatestSeriesRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchLatestSeriesRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchLatestSeriesRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchLatestSeriesRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchLatestSeriesRequestV2";
        };

        return SearchLatestSeriesRequestV2;
    })();

    query_search_v2.SearchCompletedOfficialSeriesRequestV2 = (function() {

        /**
         * Properties of a SearchCompletedOfficialSeriesRequestV2.
         * @memberof query_search_v2
         * @interface ISearchCompletedOfficialSeriesRequestV2
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchCompletedOfficialSeriesRequestV2 sensitiveFilter
         * @property {query_recursive_types.IScriptTypeFilter|null} [scriptTypeFilter] SearchCompletedOfficialSeriesRequestV2 scriptTypeFilter
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SearchCompletedOfficialSeriesRequestV2 requireSeriesPage
         */

        /**
         * Constructs a new SearchCompletedOfficialSeriesRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchCompletedOfficialSeriesRequestV2.
         * @implements ISearchCompletedOfficialSeriesRequestV2
         * @constructor
         * @param {query_search_v2.ISearchCompletedOfficialSeriesRequestV2=} [properties] Properties to set
         */
        function SearchCompletedOfficialSeriesRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchCompletedOfficialSeriesRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @instance
         */
        SearchCompletedOfficialSeriesRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchCompletedOfficialSeriesRequestV2 scriptTypeFilter.
         * @member {query_recursive_types.IScriptTypeFilter|null|undefined} scriptTypeFilter
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @instance
         */
        SearchCompletedOfficialSeriesRequestV2.prototype.scriptTypeFilter = null;

        /**
         * SearchCompletedOfficialSeriesRequestV2 requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @instance
         */
        SearchCompletedOfficialSeriesRequestV2.prototype.requireSeriesPage = null;

        /**
         * Creates a new SearchCompletedOfficialSeriesRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchCompletedOfficialSeriesRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchCompletedOfficialSeriesRequestV2} SearchCompletedOfficialSeriesRequestV2 instance
         */
        SearchCompletedOfficialSeriesRequestV2.create = function create(properties) {
            return new SearchCompletedOfficialSeriesRequestV2(properties);
        };

        /**
         * Encodes the specified SearchCompletedOfficialSeriesRequestV2 message. Does not implicitly {@link query_search_v2.SearchCompletedOfficialSeriesRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchCompletedOfficialSeriesRequestV2} message SearchCompletedOfficialSeriesRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchCompletedOfficialSeriesRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.sensitiveFilter);
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.scriptTypeFilter != null && Object.hasOwnProperty.call(message, "scriptTypeFilter"))
                $root.query_recursive_types.ScriptTypeFilter.encode(message.scriptTypeFilter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchCompletedOfficialSeriesRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchCompletedOfficialSeriesRequestV2} SearchCompletedOfficialSeriesRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchCompletedOfficialSeriesRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchCompletedOfficialSeriesRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 3:
                    message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchCompletedOfficialSeriesRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchCompletedOfficialSeriesRequestV2} SearchCompletedOfficialSeriesRequestV2
         */
        SearchCompletedOfficialSeriesRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchCompletedOfficialSeriesRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchCompletedOfficialSeriesRequestV2();
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            if (object.scriptTypeFilter != null) {
                if (typeof object.scriptTypeFilter !== "object")
                    throw TypeError(".query_search_v2.SearchCompletedOfficialSeriesRequestV2.scriptTypeFilter: object expected");
                message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.fromObject(object.scriptTypeFilter);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_search_v2.SearchCompletedOfficialSeriesRequestV2.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchCompletedOfficialSeriesRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @static
         * @param {query_search_v2.SearchCompletedOfficialSeriesRequestV2} message SearchCompletedOfficialSeriesRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchCompletedOfficialSeriesRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
                object.requireSeriesPage = null;
                object.scriptTypeFilter = null;
            }
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            if (message.scriptTypeFilter != null && message.hasOwnProperty("scriptTypeFilter"))
                object.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.toObject(message.scriptTypeFilter, options);
            return object;
        };

        /**
         * Converts this SearchCompletedOfficialSeriesRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchCompletedOfficialSeriesRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchCompletedOfficialSeriesRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchCompletedOfficialSeriesRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchCompletedOfficialSeriesRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchCompletedOfficialSeriesRequestV2";
        };

        return SearchCompletedOfficialSeriesRequestV2;
    })();

    query_search_v2.SearchUncompletedOfficialSeriesRequestV2 = (function() {

        /**
         * Properties of a SearchUncompletedOfficialSeriesRequestV2.
         * @memberof query_search_v2
         * @interface ISearchUncompletedOfficialSeriesRequestV2
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchUncompletedOfficialSeriesRequestV2 sensitiveFilter
         * @property {query_recursive_types.IScriptTypeFilter|null} [scriptTypeFilter] SearchUncompletedOfficialSeriesRequestV2 scriptTypeFilter
         * @property {query_recursive_types.ISeriesPageRequest|null} [requireSeriesPage] SearchUncompletedOfficialSeriesRequestV2 requireSeriesPage
         */

        /**
         * Constructs a new SearchUncompletedOfficialSeriesRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchUncompletedOfficialSeriesRequestV2.
         * @implements ISearchUncompletedOfficialSeriesRequestV2
         * @constructor
         * @param {query_search_v2.ISearchUncompletedOfficialSeriesRequestV2=} [properties] Properties to set
         */
        function SearchUncompletedOfficialSeriesRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUncompletedOfficialSeriesRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @instance
         */
        SearchUncompletedOfficialSeriesRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchUncompletedOfficialSeriesRequestV2 scriptTypeFilter.
         * @member {query_recursive_types.IScriptTypeFilter|null|undefined} scriptTypeFilter
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @instance
         */
        SearchUncompletedOfficialSeriesRequestV2.prototype.scriptTypeFilter = null;

        /**
         * SearchUncompletedOfficialSeriesRequestV2 requireSeriesPage.
         * @member {query_recursive_types.ISeriesPageRequest|null|undefined} requireSeriesPage
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @instance
         */
        SearchUncompletedOfficialSeriesRequestV2.prototype.requireSeriesPage = null;

        /**
         * Creates a new SearchUncompletedOfficialSeriesRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchUncompletedOfficialSeriesRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchUncompletedOfficialSeriesRequestV2} SearchUncompletedOfficialSeriesRequestV2 instance
         */
        SearchUncompletedOfficialSeriesRequestV2.create = function create(properties) {
            return new SearchUncompletedOfficialSeriesRequestV2(properties);
        };

        /**
         * Encodes the specified SearchUncompletedOfficialSeriesRequestV2 message. Does not implicitly {@link query_search_v2.SearchUncompletedOfficialSeriesRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @static
         * @param {query_search_v2.ISearchUncompletedOfficialSeriesRequestV2} message SearchUncompletedOfficialSeriesRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUncompletedOfficialSeriesRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.sensitiveFilter);
            if (message.requireSeriesPage != null && Object.hasOwnProperty.call(message, "requireSeriesPage"))
                $root.query_recursive_types.SeriesPageRequest.encode(message.requireSeriesPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.scriptTypeFilter != null && Object.hasOwnProperty.call(message, "scriptTypeFilter"))
                $root.query_recursive_types.ScriptTypeFilter.encode(message.scriptTypeFilter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUncompletedOfficialSeriesRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchUncompletedOfficialSeriesRequestV2} SearchUncompletedOfficialSeriesRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUncompletedOfficialSeriesRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchUncompletedOfficialSeriesRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 3:
                    message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUncompletedOfficialSeriesRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchUncompletedOfficialSeriesRequestV2} SearchUncompletedOfficialSeriesRequestV2
         */
        SearchUncompletedOfficialSeriesRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchUncompletedOfficialSeriesRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchUncompletedOfficialSeriesRequestV2();
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            if (object.scriptTypeFilter != null) {
                if (typeof object.scriptTypeFilter !== "object")
                    throw TypeError(".query_search_v2.SearchUncompletedOfficialSeriesRequestV2.scriptTypeFilter: object expected");
                message.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.fromObject(object.scriptTypeFilter);
            }
            if (object.requireSeriesPage != null) {
                if (typeof object.requireSeriesPage !== "object")
                    throw TypeError(".query_search_v2.SearchUncompletedOfficialSeriesRequestV2.requireSeriesPage: object expected");
                message.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.fromObject(object.requireSeriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUncompletedOfficialSeriesRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @static
         * @param {query_search_v2.SearchUncompletedOfficialSeriesRequestV2} message SearchUncompletedOfficialSeriesRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUncompletedOfficialSeriesRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
                object.requireSeriesPage = null;
                object.scriptTypeFilter = null;
            }
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.requireSeriesPage != null && message.hasOwnProperty("requireSeriesPage"))
                object.requireSeriesPage = $root.query_recursive_types.SeriesPageRequest.toObject(message.requireSeriesPage, options);
            if (message.scriptTypeFilter != null && message.hasOwnProperty("scriptTypeFilter"))
                object.scriptTypeFilter = $root.query_recursive_types.ScriptTypeFilter.toObject(message.scriptTypeFilter, options);
            return object;
        };

        /**
         * Converts this SearchUncompletedOfficialSeriesRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUncompletedOfficialSeriesRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUncompletedOfficialSeriesRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchUncompletedOfficialSeriesRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUncompletedOfficialSeriesRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchUncompletedOfficialSeriesRequestV2";
        };

        return SearchUncompletedOfficialSeriesRequestV2;
    })();

    query_search_v2.SearchSeriesResponseV2 = (function() {

        /**
         * Properties of a SearchSeriesResponseV2.
         * @memberof query_search_v2
         * @interface ISearchSeriesResponseV2
         * @property {query_recursive_types.ISeriesPageResponse|null} [seriesPage] SearchSeriesResponseV2 seriesPage
         */

        /**
         * Constructs a new SearchSeriesResponseV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchSeriesResponseV2.
         * @implements ISearchSeriesResponseV2
         * @constructor
         * @param {query_search_v2.ISearchSeriesResponseV2=} [properties] Properties to set
         */
        function SearchSeriesResponseV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSeriesResponseV2 seriesPage.
         * @member {query_recursive_types.ISeriesPageResponse|null|undefined} seriesPage
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @instance
         */
        SearchSeriesResponseV2.prototype.seriesPage = null;

        /**
         * Creates a new SearchSeriesResponseV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @static
         * @param {query_search_v2.ISearchSeriesResponseV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchSeriesResponseV2} SearchSeriesResponseV2 instance
         */
        SearchSeriesResponseV2.create = function create(properties) {
            return new SearchSeriesResponseV2(properties);
        };

        /**
         * Encodes the specified SearchSeriesResponseV2 message. Does not implicitly {@link query_search_v2.SearchSeriesResponseV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @static
         * @param {query_search_v2.ISearchSeriesResponseV2} message SearchSeriesResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSeriesResponseV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesPage != null && Object.hasOwnProperty.call(message, "seriesPage"))
                $root.query_recursive_types.SeriesPageResponse.encode(message.seriesPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchSeriesResponseV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchSeriesResponseV2} SearchSeriesResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSeriesResponseV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchSeriesResponseV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seriesPage = $root.query_recursive_types.SeriesPageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchSeriesResponseV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchSeriesResponseV2} SearchSeriesResponseV2
         */
        SearchSeriesResponseV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchSeriesResponseV2)
                return object;
            var message = new $root.query_search_v2.SearchSeriesResponseV2();
            if (object.seriesPage != null) {
                if (typeof object.seriesPage !== "object")
                    throw TypeError(".query_search_v2.SearchSeriesResponseV2.seriesPage: object expected");
                message.seriesPage = $root.query_recursive_types.SeriesPageResponse.fromObject(object.seriesPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchSeriesResponseV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @static
         * @param {query_search_v2.SearchSeriesResponseV2} message SearchSeriesResponseV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchSeriesResponseV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seriesPage = null;
            if (message.seriesPage != null && message.hasOwnProperty("seriesPage"))
                object.seriesPage = $root.query_recursive_types.SeriesPageResponse.toObject(message.seriesPage, options);
            return object;
        };

        /**
         * Converts this SearchSeriesResponseV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchSeriesResponseV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchSeriesResponseV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchSeriesResponseV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchSeriesResponseV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchSeriesResponseV2";
        };

        return SearchSeriesResponseV2;
    })();

    query_search_v2.SearchFacetRelatedTagRequestV2 = (function() {

        /**
         * Properties of a SearchFacetRelatedTagRequestV2.
         * @memberof query_search_v2
         * @interface ISearchFacetRelatedTagRequestV2
         * @property {Array.<google.protobuf.IStringValue>|null} [anyTags] SearchFacetRelatedTagRequestV2 anyTags
         * @property {google.protobuf.IInt64Value|null} [limit] SearchFacetRelatedTagRequestV2 limit
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchFacetRelatedTagRequestV2 sensitiveFilter
         * @property {google.protobuf.IStringValue|null} [tag] SearchFacetRelatedTagRequestV2 tag
         */

        /**
         * Constructs a new SearchFacetRelatedTagRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchFacetRelatedTagRequestV2.
         * @implements ISearchFacetRelatedTagRequestV2
         * @constructor
         * @param {query_search_v2.ISearchFacetRelatedTagRequestV2=} [properties] Properties to set
         */
        function SearchFacetRelatedTagRequestV2(properties) {
            this.anyTags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchFacetRelatedTagRequestV2 anyTags.
         * @member {Array.<google.protobuf.IStringValue>} anyTags
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @instance
         */
        SearchFacetRelatedTagRequestV2.prototype.anyTags = $util.emptyArray;

        /**
         * SearchFacetRelatedTagRequestV2 limit.
         * @member {google.protobuf.IInt64Value|null|undefined} limit
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @instance
         */
        SearchFacetRelatedTagRequestV2.prototype.limit = null;

        /**
         * SearchFacetRelatedTagRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @instance
         */
        SearchFacetRelatedTagRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchFacetRelatedTagRequestV2 tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @instance
         */
        SearchFacetRelatedTagRequestV2.prototype.tag = null;

        /**
         * Creates a new SearchFacetRelatedTagRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @static
         * @param {query_search_v2.ISearchFacetRelatedTagRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchFacetRelatedTagRequestV2} SearchFacetRelatedTagRequestV2 instance
         */
        SearchFacetRelatedTagRequestV2.create = function create(properties) {
            return new SearchFacetRelatedTagRequestV2(properties);
        };

        /**
         * Encodes the specified SearchFacetRelatedTagRequestV2 message. Does not implicitly {@link query_search_v2.SearchFacetRelatedTagRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @static
         * @param {query_search_v2.ISearchFacetRelatedTagRequestV2} message SearchFacetRelatedTagRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchFacetRelatedTagRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int64Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sensitiveFilter);
            if (message.anyTags != null && message.anyTags.length)
                for (var i = 0; i < message.anyTags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.anyTags[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchFacetRelatedTagRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchFacetRelatedTagRequestV2} SearchFacetRelatedTagRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchFacetRelatedTagRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchFacetRelatedTagRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 4:
                    if (!(message.anyTags && message.anyTags.length))
                        message.anyTags = [];
                    message.anyTags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 1:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchFacetRelatedTagRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchFacetRelatedTagRequestV2} SearchFacetRelatedTagRequestV2
         */
        SearchFacetRelatedTagRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchFacetRelatedTagRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchFacetRelatedTagRequestV2();
            if (object.anyTags) {
                if (!Array.isArray(object.anyTags))
                    throw TypeError(".query_search_v2.SearchFacetRelatedTagRequestV2.anyTags: array expected");
                message.anyTags = [];
                for (var i = 0; i < object.anyTags.length; ++i) {
                    if (typeof object.anyTags[i] !== "object")
                        throw TypeError(".query_search_v2.SearchFacetRelatedTagRequestV2.anyTags: object expected");
                    message.anyTags[i] = $root.google.protobuf.StringValue.fromObject(object.anyTags[i]);
                }
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".query_search_v2.SearchFacetRelatedTagRequestV2.limit: object expected");
                message.limit = $root.google.protobuf.Int64Value.fromObject(object.limit);
            }
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_search_v2.SearchFacetRelatedTagRequestV2.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchFacetRelatedTagRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @static
         * @param {query_search_v2.SearchFacetRelatedTagRequestV2} message SearchFacetRelatedTagRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchFacetRelatedTagRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.anyTags = [];
            if (options.defaults) {
                object.tag = null;
                object.limit = null;
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
            }
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int64Value.toObject(message.limit, options);
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.anyTags && message.anyTags.length) {
                object.anyTags = [];
                for (var j = 0; j < message.anyTags.length; ++j)
                    object.anyTags[j] = $root.google.protobuf.StringValue.toObject(message.anyTags[j], options);
            }
            return object;
        };

        /**
         * Converts this SearchFacetRelatedTagRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchFacetRelatedTagRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchFacetRelatedTagRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchFacetRelatedTagRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchFacetRelatedTagRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchFacetRelatedTagRequestV2";
        };

        return SearchFacetRelatedTagRequestV2;
    })();

    query_search_v2.SearchFacetTagRequestV2 = (function() {

        /**
         * Properties of a SearchFacetTagRequestV2.
         * @memberof query_search_v2
         * @interface ISearchFacetTagRequestV2
         * @property {google.protobuf.IStringValue|null} [q] SearchFacetTagRequestV2 q
         * @property {google.protobuf.IInt64Value|null} [limit] SearchFacetTagRequestV2 limit
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchFacetTagRequestV2 sensitiveFilter
         * @property {query_search_v2.MatchType|null} [matchType] SearchFacetTagRequestV2 matchType
         */

        /**
         * Constructs a new SearchFacetTagRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchFacetTagRequestV2.
         * @implements ISearchFacetTagRequestV2
         * @constructor
         * @param {query_search_v2.ISearchFacetTagRequestV2=} [properties] Properties to set
         */
        function SearchFacetTagRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchFacetTagRequestV2 q.
         * @member {google.protobuf.IStringValue|null|undefined} q
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @instance
         */
        SearchFacetTagRequestV2.prototype.q = null;

        /**
         * SearchFacetTagRequestV2 limit.
         * @member {google.protobuf.IInt64Value|null|undefined} limit
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @instance
         */
        SearchFacetTagRequestV2.prototype.limit = null;

        /**
         * SearchFacetTagRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @instance
         */
        SearchFacetTagRequestV2.prototype.sensitiveFilter = 0;

        /**
         * SearchFacetTagRequestV2 matchType.
         * @member {query_search_v2.MatchType} matchType
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @instance
         */
        SearchFacetTagRequestV2.prototype.matchType = 0;

        /**
         * Creates a new SearchFacetTagRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @static
         * @param {query_search_v2.ISearchFacetTagRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchFacetTagRequestV2} SearchFacetTagRequestV2 instance
         */
        SearchFacetTagRequestV2.create = function create(properties) {
            return new SearchFacetTagRequestV2(properties);
        };

        /**
         * Encodes the specified SearchFacetTagRequestV2 message. Does not implicitly {@link query_search_v2.SearchFacetTagRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @static
         * @param {query_search_v2.ISearchFacetTagRequestV2} message SearchFacetTagRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchFacetTagRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.q != null && Object.hasOwnProperty.call(message, "q"))
                $root.google.protobuf.StringValue.encode(message.q, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int64Value.encode(message.limit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sensitiveFilter);
            if (message.matchType != null && Object.hasOwnProperty.call(message, "matchType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.matchType);
            return writer;
        };

        /**
         * Decodes a SearchFacetTagRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchFacetTagRequestV2} SearchFacetTagRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchFacetTagRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchFacetTagRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.q = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.limit = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.sensitiveFilter = reader.int32();
                    break;
                case 4:
                    message.matchType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchFacetTagRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchFacetTagRequestV2} SearchFacetTagRequestV2
         */
        SearchFacetTagRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchFacetTagRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchFacetTagRequestV2();
            if (object.q != null) {
                if (typeof object.q !== "object")
                    throw TypeError(".query_search_v2.SearchFacetTagRequestV2.q: object expected");
                message.q = $root.google.protobuf.StringValue.fromObject(object.q);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".query_search_v2.SearchFacetTagRequestV2.limit: object expected");
                message.limit = $root.google.protobuf.Int64Value.fromObject(object.limit);
            }
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            switch (object.matchType) {
            case "MATCH_TYPE_UNSPECIFIED":
            case 0:
                message.matchType = 0;
                break;
            case "MATCH_TYPE_PARTIAL":
            case 1:
                message.matchType = 1;
                break;
            case "MATCH_TYPE_EXACT":
            case 2:
                message.matchType = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchFacetTagRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @static
         * @param {query_search_v2.SearchFacetTagRequestV2} message SearchFacetTagRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchFacetTagRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.q = null;
                object.limit = null;
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
                object.matchType = options.enums === String ? "MATCH_TYPE_UNSPECIFIED" : 0;
            }
            if (message.q != null && message.hasOwnProperty("q"))
                object.q = $root.google.protobuf.StringValue.toObject(message.q, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int64Value.toObject(message.limit, options);
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            if (message.matchType != null && message.hasOwnProperty("matchType"))
                object.matchType = options.enums === String ? $root.query_search_v2.MatchType[message.matchType] : message.matchType;
            return object;
        };

        /**
         * Converts this SearchFacetTagRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchFacetTagRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchFacetTagRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchFacetTagRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchFacetTagRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchFacetTagRequestV2";
        };

        return SearchFacetTagRequestV2;
    })();

    query_search_v2.SearchLatestFacetTagRequestV2 = (function() {

        /**
         * Properties of a SearchLatestFacetTagRequestV2.
         * @memberof query_search_v2
         * @interface ISearchLatestFacetTagRequestV2
         * @property {google.protobuf.IInt64Value|null} [limit] SearchLatestFacetTagRequestV2 limit
         * @property {query_search_v2.SensitiveFilter|null} [sensitiveFilter] SearchLatestFacetTagRequestV2 sensitiveFilter
         */

        /**
         * Constructs a new SearchLatestFacetTagRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchLatestFacetTagRequestV2.
         * @implements ISearchLatestFacetTagRequestV2
         * @constructor
         * @param {query_search_v2.ISearchLatestFacetTagRequestV2=} [properties] Properties to set
         */
        function SearchLatestFacetTagRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchLatestFacetTagRequestV2 limit.
         * @member {google.protobuf.IInt64Value|null|undefined} limit
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @instance
         */
        SearchLatestFacetTagRequestV2.prototype.limit = null;

        /**
         * SearchLatestFacetTagRequestV2 sensitiveFilter.
         * @member {query_search_v2.SensitiveFilter} sensitiveFilter
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @instance
         */
        SearchLatestFacetTagRequestV2.prototype.sensitiveFilter = 0;

        /**
         * Creates a new SearchLatestFacetTagRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @static
         * @param {query_search_v2.ISearchLatestFacetTagRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchLatestFacetTagRequestV2} SearchLatestFacetTagRequestV2 instance
         */
        SearchLatestFacetTagRequestV2.create = function create(properties) {
            return new SearchLatestFacetTagRequestV2(properties);
        };

        /**
         * Encodes the specified SearchLatestFacetTagRequestV2 message. Does not implicitly {@link query_search_v2.SearchLatestFacetTagRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @static
         * @param {query_search_v2.ISearchLatestFacetTagRequestV2} message SearchLatestFacetTagRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchLatestFacetTagRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int64Value.encode(message.limit, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sensitiveFilter != null && Object.hasOwnProperty.call(message, "sensitiveFilter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.sensitiveFilter);
            return writer;
        };

        /**
         * Decodes a SearchLatestFacetTagRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchLatestFacetTagRequestV2} SearchLatestFacetTagRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchLatestFacetTagRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchLatestFacetTagRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.sensitiveFilter = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchLatestFacetTagRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchLatestFacetTagRequestV2} SearchLatestFacetTagRequestV2
         */
        SearchLatestFacetTagRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchLatestFacetTagRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchLatestFacetTagRequestV2();
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".query_search_v2.SearchLatestFacetTagRequestV2.limit: object expected");
                message.limit = $root.google.protobuf.Int64Value.fromObject(object.limit);
            }
            switch (object.sensitiveFilter) {
            case "SENSITIVE_FILTER_UNSPECIFIED":
            case 0:
                message.sensitiveFilter = 0;
                break;
            case "SENSITIVE_FILTER_NON_SENSITIVE":
            case 1:
                message.sensitiveFilter = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchLatestFacetTagRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @static
         * @param {query_search_v2.SearchLatestFacetTagRequestV2} message SearchLatestFacetTagRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchLatestFacetTagRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.limit = null;
                object.sensitiveFilter = options.enums === String ? "SENSITIVE_FILTER_UNSPECIFIED" : 0;
            }
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int64Value.toObject(message.limit, options);
            if (message.sensitiveFilter != null && message.hasOwnProperty("sensitiveFilter"))
                object.sensitiveFilter = options.enums === String ? $root.query_search_v2.SensitiveFilter[message.sensitiveFilter] : message.sensitiveFilter;
            return object;
        };

        /**
         * Converts this SearchLatestFacetTagRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchLatestFacetTagRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchLatestFacetTagRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchLatestFacetTagRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchLatestFacetTagRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchLatestFacetTagRequestV2";
        };

        return SearchLatestFacetTagRequestV2;
    })();

    query_search_v2.SearchFacetTagResponseV2 = (function() {

        /**
         * Properties of a SearchFacetTagResponseV2.
         * @memberof query_search_v2
         * @interface ISearchFacetTagResponseV2
         * @property {Array.<query_search_v2.IFacetTag>|null} [facetTags] SearchFacetTagResponseV2 facetTags
         */

        /**
         * Constructs a new SearchFacetTagResponseV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchFacetTagResponseV2.
         * @implements ISearchFacetTagResponseV2
         * @constructor
         * @param {query_search_v2.ISearchFacetTagResponseV2=} [properties] Properties to set
         */
        function SearchFacetTagResponseV2(properties) {
            this.facetTags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchFacetTagResponseV2 facetTags.
         * @member {Array.<query_search_v2.IFacetTag>} facetTags
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @instance
         */
        SearchFacetTagResponseV2.prototype.facetTags = $util.emptyArray;

        /**
         * Creates a new SearchFacetTagResponseV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @static
         * @param {query_search_v2.ISearchFacetTagResponseV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchFacetTagResponseV2} SearchFacetTagResponseV2 instance
         */
        SearchFacetTagResponseV2.create = function create(properties) {
            return new SearchFacetTagResponseV2(properties);
        };

        /**
         * Encodes the specified SearchFacetTagResponseV2 message. Does not implicitly {@link query_search_v2.SearchFacetTagResponseV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @static
         * @param {query_search_v2.ISearchFacetTagResponseV2} message SearchFacetTagResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchFacetTagResponseV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.facetTags != null && message.facetTags.length)
                for (var i = 0; i < message.facetTags.length; ++i)
                    $root.query_search_v2.FacetTag.encode(message.facetTags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchFacetTagResponseV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchFacetTagResponseV2} SearchFacetTagResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchFacetTagResponseV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchFacetTagResponseV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.facetTags && message.facetTags.length))
                        message.facetTags = [];
                    message.facetTags.push($root.query_search_v2.FacetTag.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchFacetTagResponseV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchFacetTagResponseV2} SearchFacetTagResponseV2
         */
        SearchFacetTagResponseV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchFacetTagResponseV2)
                return object;
            var message = new $root.query_search_v2.SearchFacetTagResponseV2();
            if (object.facetTags) {
                if (!Array.isArray(object.facetTags))
                    throw TypeError(".query_search_v2.SearchFacetTagResponseV2.facetTags: array expected");
                message.facetTags = [];
                for (var i = 0; i < object.facetTags.length; ++i) {
                    if (typeof object.facetTags[i] !== "object")
                        throw TypeError(".query_search_v2.SearchFacetTagResponseV2.facetTags: object expected");
                    message.facetTags[i] = $root.query_search_v2.FacetTag.fromObject(object.facetTags[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchFacetTagResponseV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @static
         * @param {query_search_v2.SearchFacetTagResponseV2} message SearchFacetTagResponseV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchFacetTagResponseV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.facetTags = [];
            if (message.facetTags && message.facetTags.length) {
                object.facetTags = [];
                for (var j = 0; j < message.facetTags.length; ++j)
                    object.facetTags[j] = $root.query_search_v2.FacetTag.toObject(message.facetTags[j], options);
            }
            return object;
        };

        /**
         * Converts this SearchFacetTagResponseV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchFacetTagResponseV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchFacetTagResponseV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchFacetTagResponseV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchFacetTagResponseV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchFacetTagResponseV2";
        };

        return SearchFacetTagResponseV2;
    })();

    query_search_v2.FacetTag = (function() {

        /**
         * Properties of a FacetTag.
         * @memberof query_search_v2
         * @interface IFacetTag
         * @property {google.protobuf.IStringValue|null} [tag] FacetTag tag
         * @property {google.protobuf.IInt64Value|null} [count] FacetTag count
         */

        /**
         * Constructs a new FacetTag.
         * @memberof query_search_v2
         * @classdesc Represents a FacetTag.
         * @implements IFacetTag
         * @constructor
         * @param {query_search_v2.IFacetTag=} [properties] Properties to set
         */
        function FacetTag(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FacetTag tag.
         * @member {google.protobuf.IStringValue|null|undefined} tag
         * @memberof query_search_v2.FacetTag
         * @instance
         */
        FacetTag.prototype.tag = null;

        /**
         * FacetTag count.
         * @member {google.protobuf.IInt64Value|null|undefined} count
         * @memberof query_search_v2.FacetTag
         * @instance
         */
        FacetTag.prototype.count = null;

        /**
         * Creates a new FacetTag instance using the specified properties.
         * @function create
         * @memberof query_search_v2.FacetTag
         * @static
         * @param {query_search_v2.IFacetTag=} [properties] Properties to set
         * @returns {query_search_v2.FacetTag} FacetTag instance
         */
        FacetTag.create = function create(properties) {
            return new FacetTag(properties);
        };

        /**
         * Encodes the specified FacetTag message. Does not implicitly {@link query_search_v2.FacetTag.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.FacetTag
         * @static
         * @param {query_search_v2.IFacetTag} message FacetTag message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FacetTag.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.google.protobuf.StringValue.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                $root.google.protobuf.Int64Value.encode(message.count, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FacetTag message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.FacetTag
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.FacetTag} FacetTag
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FacetTag.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.FacetTag();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tag = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.count = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a FacetTag message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.FacetTag
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.FacetTag} FacetTag
         */
        FacetTag.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.FacetTag)
                return object;
            var message = new $root.query_search_v2.FacetTag();
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query_search_v2.FacetTag.tag: object expected");
                message.tag = $root.google.protobuf.StringValue.fromObject(object.tag);
            }
            if (object.count != null) {
                if (typeof object.count !== "object")
                    throw TypeError(".query_search_v2.FacetTag.count: object expected");
                message.count = $root.google.protobuf.Int64Value.fromObject(object.count);
            }
            return message;
        };

        /**
         * Creates a plain object from a FacetTag message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.FacetTag
         * @static
         * @param {query_search_v2.FacetTag} message FacetTag
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FacetTag.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.tag = null;
                object.count = null;
            }
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = $root.google.protobuf.StringValue.toObject(message.tag, options);
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = $root.google.protobuf.Int64Value.toObject(message.count, options);
            return object;
        };

        /**
         * Converts this FacetTag to JSON.
         * @function toJSON
         * @memberof query_search_v2.FacetTag
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FacetTag.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FacetTag
         * @function getTypeUrl
         * @memberof query_search_v2.FacetTag
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FacetTag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.FacetTag";
        };

        return FacetTag;
    })();

    /**
     * OfficialFilter enum.
     * @name query_search_v2.OfficialFilter
     * @enum {number}
     * @property {number} OFFICIAL_FILTER_UNSPECIFIED=0 OFFICIAL_FILTER_UNSPECIFIED value
     * @property {number} OFFICIAL_FILTER_OFFICIAL=1 OFFICIAL_FILTER_OFFICIAL value
     * @property {number} OFFICIAL_FILTER_NOT_OFFICIAL=2 OFFICIAL_FILTER_NOT_OFFICIAL value
     */
    query_search_v2.OfficialFilter = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OFFICIAL_FILTER_UNSPECIFIED"] = 0;
        values[valuesById[1] = "OFFICIAL_FILTER_OFFICIAL"] = 1;
        values[valuesById[2] = "OFFICIAL_FILTER_NOT_OFFICIAL"] = 2;
        return values;
    })();

    /**
     * SensitiveFilter enum.
     * @name query_search_v2.SensitiveFilter
     * @enum {number}
     * @property {number} SENSITIVE_FILTER_UNSPECIFIED=0 SENSITIVE_FILTER_UNSPECIFIED value
     * @property {number} SENSITIVE_FILTER_NON_SENSITIVE=1 SENSITIVE_FILTER_NON_SENSITIVE value
     */
    query_search_v2.SensitiveFilter = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SENSITIVE_FILTER_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SENSITIVE_FILTER_NON_SENSITIVE"] = 1;
        return values;
    })();

    /**
     * MatchType enum.
     * @name query_search_v2.MatchType
     * @enum {number}
     * @property {number} MATCH_TYPE_UNSPECIFIED=0 MATCH_TYPE_UNSPECIFIED value
     * @property {number} MATCH_TYPE_PARTIAL=1 MATCH_TYPE_PARTIAL value
     * @property {number} MATCH_TYPE_EXACT=2 MATCH_TYPE_EXACT value
     */
    query_search_v2.MatchType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MATCH_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "MATCH_TYPE_PARTIAL"] = 1;
        values[valuesById[2] = "MATCH_TYPE_EXACT"] = 2;
        return values;
    })();

    query_search_v2.SearchUserRequestV2 = (function() {

        /**
         * Properties of a SearchUserRequestV2.
         * @memberof query_search_v2
         * @interface ISearchUserRequestV2
         * @property {google.protobuf.IStringValue|null} [q] SearchUserRequestV2 q
         * @property {query_recursive_types.IUserPageRequest|null} [requireUserPage] SearchUserRequestV2 requireUserPage
         */

        /**
         * Constructs a new SearchUserRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchUserRequestV2.
         * @implements ISearchUserRequestV2
         * @constructor
         * @param {query_search_v2.ISearchUserRequestV2=} [properties] Properties to set
         */
        function SearchUserRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUserRequestV2 q.
         * @member {google.protobuf.IStringValue|null|undefined} q
         * @memberof query_search_v2.SearchUserRequestV2
         * @instance
         */
        SearchUserRequestV2.prototype.q = null;

        /**
         * SearchUserRequestV2 requireUserPage.
         * @member {query_recursive_types.IUserPageRequest|null|undefined} requireUserPage
         * @memberof query_search_v2.SearchUserRequestV2
         * @instance
         */
        SearchUserRequestV2.prototype.requireUserPage = null;

        /**
         * Creates a new SearchUserRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchUserRequestV2
         * @static
         * @param {query_search_v2.ISearchUserRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchUserRequestV2} SearchUserRequestV2 instance
         */
        SearchUserRequestV2.create = function create(properties) {
            return new SearchUserRequestV2(properties);
        };

        /**
         * Encodes the specified SearchUserRequestV2 message. Does not implicitly {@link query_search_v2.SearchUserRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchUserRequestV2
         * @static
         * @param {query_search_v2.ISearchUserRequestV2} message SearchUserRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUserRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.q != null && Object.hasOwnProperty.call(message, "q"))
                $root.google.protobuf.StringValue.encode(message.q, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireUserPage != null && Object.hasOwnProperty.call(message, "requireUserPage"))
                $root.query_recursive_types.UserPageRequest.encode(message.requireUserPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUserRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchUserRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchUserRequestV2} SearchUserRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUserRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchUserRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.q = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireUserPage = $root.query_recursive_types.UserPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUserRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchUserRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchUserRequestV2} SearchUserRequestV2
         */
        SearchUserRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchUserRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchUserRequestV2();
            if (object.q != null) {
                if (typeof object.q !== "object")
                    throw TypeError(".query_search_v2.SearchUserRequestV2.q: object expected");
                message.q = $root.google.protobuf.StringValue.fromObject(object.q);
            }
            if (object.requireUserPage != null) {
                if (typeof object.requireUserPage !== "object")
                    throw TypeError(".query_search_v2.SearchUserRequestV2.requireUserPage: object expected");
                message.requireUserPage = $root.query_recursive_types.UserPageRequest.fromObject(object.requireUserPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUserRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchUserRequestV2
         * @static
         * @param {query_search_v2.SearchUserRequestV2} message SearchUserRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUserRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.q = null;
                object.requireUserPage = null;
            }
            if (message.q != null && message.hasOwnProperty("q"))
                object.q = $root.google.protobuf.StringValue.toObject(message.q, options);
            if (message.requireUserPage != null && message.hasOwnProperty("requireUserPage"))
                object.requireUserPage = $root.query_recursive_types.UserPageRequest.toObject(message.requireUserPage, options);
            return object;
        };

        /**
         * Converts this SearchUserRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchUserRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUserRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUserRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchUserRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUserRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchUserRequestV2";
        };

        return SearchUserRequestV2;
    })();

    query_search_v2.SearchUserByTagsRequestV2 = (function() {

        /**
         * Properties of a SearchUserByTagsRequestV2.
         * @memberof query_search_v2
         * @interface ISearchUserByTagsRequestV2
         * @property {Array.<google.protobuf.IStringValue>|null} [anyTags] SearchUserByTagsRequestV2 anyTags
         * @property {query_search_v2.OfficialFilter|null} [officialFilter] SearchUserByTagsRequestV2 officialFilter
         * @property {query_recursive_types.IUserPageRequest|null} [requireUserPage] SearchUserByTagsRequestV2 requireUserPage
         */

        /**
         * Constructs a new SearchUserByTagsRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchUserByTagsRequestV2.
         * @implements ISearchUserByTagsRequestV2
         * @constructor
         * @param {query_search_v2.ISearchUserByTagsRequestV2=} [properties] Properties to set
         */
        function SearchUserByTagsRequestV2(properties) {
            this.anyTags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUserByTagsRequestV2 anyTags.
         * @member {Array.<google.protobuf.IStringValue>} anyTags
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @instance
         */
        SearchUserByTagsRequestV2.prototype.anyTags = $util.emptyArray;

        /**
         * SearchUserByTagsRequestV2 officialFilter.
         * @member {query_search_v2.OfficialFilter} officialFilter
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @instance
         */
        SearchUserByTagsRequestV2.prototype.officialFilter = 0;

        /**
         * SearchUserByTagsRequestV2 requireUserPage.
         * @member {query_recursive_types.IUserPageRequest|null|undefined} requireUserPage
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @instance
         */
        SearchUserByTagsRequestV2.prototype.requireUserPage = null;

        /**
         * Creates a new SearchUserByTagsRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @static
         * @param {query_search_v2.ISearchUserByTagsRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchUserByTagsRequestV2} SearchUserByTagsRequestV2 instance
         */
        SearchUserByTagsRequestV2.create = function create(properties) {
            return new SearchUserByTagsRequestV2(properties);
        };

        /**
         * Encodes the specified SearchUserByTagsRequestV2 message. Does not implicitly {@link query_search_v2.SearchUserByTagsRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @static
         * @param {query_search_v2.ISearchUserByTagsRequestV2} message SearchUserByTagsRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUserByTagsRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.anyTags != null && message.anyTags.length)
                for (var i = 0; i < message.anyTags.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.anyTags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.officialFilter != null && Object.hasOwnProperty.call(message, "officialFilter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.officialFilter);
            if (message.requireUserPage != null && Object.hasOwnProperty.call(message, "requireUserPage"))
                $root.query_recursive_types.UserPageRequest.encode(message.requireUserPage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUserByTagsRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchUserByTagsRequestV2} SearchUserByTagsRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUserByTagsRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchUserByTagsRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.anyTags && message.anyTags.length))
                        message.anyTags = [];
                    message.anyTags.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.officialFilter = reader.int32();
                    break;
                case 3:
                    message.requireUserPage = $root.query_recursive_types.UserPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUserByTagsRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchUserByTagsRequestV2} SearchUserByTagsRequestV2
         */
        SearchUserByTagsRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchUserByTagsRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchUserByTagsRequestV2();
            if (object.anyTags) {
                if (!Array.isArray(object.anyTags))
                    throw TypeError(".query_search_v2.SearchUserByTagsRequestV2.anyTags: array expected");
                message.anyTags = [];
                for (var i = 0; i < object.anyTags.length; ++i) {
                    if (typeof object.anyTags[i] !== "object")
                        throw TypeError(".query_search_v2.SearchUserByTagsRequestV2.anyTags: object expected");
                    message.anyTags[i] = $root.google.protobuf.StringValue.fromObject(object.anyTags[i]);
                }
            }
            switch (object.officialFilter) {
            case "OFFICIAL_FILTER_UNSPECIFIED":
            case 0:
                message.officialFilter = 0;
                break;
            case "OFFICIAL_FILTER_OFFICIAL":
            case 1:
                message.officialFilter = 1;
                break;
            case "OFFICIAL_FILTER_NOT_OFFICIAL":
            case 2:
                message.officialFilter = 2;
                break;
            }
            if (object.requireUserPage != null) {
                if (typeof object.requireUserPage !== "object")
                    throw TypeError(".query_search_v2.SearchUserByTagsRequestV2.requireUserPage: object expected");
                message.requireUserPage = $root.query_recursive_types.UserPageRequest.fromObject(object.requireUserPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUserByTagsRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @static
         * @param {query_search_v2.SearchUserByTagsRequestV2} message SearchUserByTagsRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUserByTagsRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.anyTags = [];
            if (options.defaults) {
                object.officialFilter = options.enums === String ? "OFFICIAL_FILTER_UNSPECIFIED" : 0;
                object.requireUserPage = null;
            }
            if (message.anyTags && message.anyTags.length) {
                object.anyTags = [];
                for (var j = 0; j < message.anyTags.length; ++j)
                    object.anyTags[j] = $root.google.protobuf.StringValue.toObject(message.anyTags[j], options);
            }
            if (message.officialFilter != null && message.hasOwnProperty("officialFilter"))
                object.officialFilter = options.enums === String ? $root.query_search_v2.OfficialFilter[message.officialFilter] : message.officialFilter;
            if (message.requireUserPage != null && message.hasOwnProperty("requireUserPage"))
                object.requireUserPage = $root.query_recursive_types.UserPageRequest.toObject(message.requireUserPage, options);
            return object;
        };

        /**
         * Converts this SearchUserByTagsRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUserByTagsRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUserByTagsRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchUserByTagsRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUserByTagsRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchUserByTagsRequestV2";
        };

        return SearchUserByTagsRequestV2;
    })();

    query_search_v2.SearchLatestWriterRequestV2 = (function() {

        /**
         * Properties of a SearchLatestWriterRequestV2.
         * @memberof query_search_v2
         * @interface ISearchLatestWriterRequestV2
         * @property {query_recursive_types.IUserPageRequest|null} [requireUserPage] SearchLatestWriterRequestV2 requireUserPage
         */

        /**
         * Constructs a new SearchLatestWriterRequestV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchLatestWriterRequestV2.
         * @implements ISearchLatestWriterRequestV2
         * @constructor
         * @param {query_search_v2.ISearchLatestWriterRequestV2=} [properties] Properties to set
         */
        function SearchLatestWriterRequestV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchLatestWriterRequestV2 requireUserPage.
         * @member {query_recursive_types.IUserPageRequest|null|undefined} requireUserPage
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @instance
         */
        SearchLatestWriterRequestV2.prototype.requireUserPage = null;

        /**
         * Creates a new SearchLatestWriterRequestV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @static
         * @param {query_search_v2.ISearchLatestWriterRequestV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchLatestWriterRequestV2} SearchLatestWriterRequestV2 instance
         */
        SearchLatestWriterRequestV2.create = function create(properties) {
            return new SearchLatestWriterRequestV2(properties);
        };

        /**
         * Encodes the specified SearchLatestWriterRequestV2 message. Does not implicitly {@link query_search_v2.SearchLatestWriterRequestV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @static
         * @param {query_search_v2.ISearchLatestWriterRequestV2} message SearchLatestWriterRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchLatestWriterRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireUserPage != null && Object.hasOwnProperty.call(message, "requireUserPage"))
                $root.query_recursive_types.UserPageRequest.encode(message.requireUserPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchLatestWriterRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchLatestWriterRequestV2} SearchLatestWriterRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchLatestWriterRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchLatestWriterRequestV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireUserPage = $root.query_recursive_types.UserPageRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchLatestWriterRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchLatestWriterRequestV2} SearchLatestWriterRequestV2
         */
        SearchLatestWriterRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchLatestWriterRequestV2)
                return object;
            var message = new $root.query_search_v2.SearchLatestWriterRequestV2();
            if (object.requireUserPage != null) {
                if (typeof object.requireUserPage !== "object")
                    throw TypeError(".query_search_v2.SearchLatestWriterRequestV2.requireUserPage: object expected");
                message.requireUserPage = $root.query_recursive_types.UserPageRequest.fromObject(object.requireUserPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchLatestWriterRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @static
         * @param {query_search_v2.SearchLatestWriterRequestV2} message SearchLatestWriterRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchLatestWriterRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requireUserPage = null;
            if (message.requireUserPage != null && message.hasOwnProperty("requireUserPage"))
                object.requireUserPage = $root.query_recursive_types.UserPageRequest.toObject(message.requireUserPage, options);
            return object;
        };

        /**
         * Converts this SearchLatestWriterRequestV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchLatestWriterRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchLatestWriterRequestV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchLatestWriterRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchLatestWriterRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchLatestWriterRequestV2";
        };

        return SearchLatestWriterRequestV2;
    })();

    query_search_v2.SearchUserResponseV2 = (function() {

        /**
         * Properties of a SearchUserResponseV2.
         * @memberof query_search_v2
         * @interface ISearchUserResponseV2
         * @property {query_recursive_types.IUserPageResponse|null} [userPage] SearchUserResponseV2 userPage
         */

        /**
         * Constructs a new SearchUserResponseV2.
         * @memberof query_search_v2
         * @classdesc Represents a SearchUserResponseV2.
         * @implements ISearchUserResponseV2
         * @constructor
         * @param {query_search_v2.ISearchUserResponseV2=} [properties] Properties to set
         */
        function SearchUserResponseV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUserResponseV2 userPage.
         * @member {query_recursive_types.IUserPageResponse|null|undefined} userPage
         * @memberof query_search_v2.SearchUserResponseV2
         * @instance
         */
        SearchUserResponseV2.prototype.userPage = null;

        /**
         * Creates a new SearchUserResponseV2 instance using the specified properties.
         * @function create
         * @memberof query_search_v2.SearchUserResponseV2
         * @static
         * @param {query_search_v2.ISearchUserResponseV2=} [properties] Properties to set
         * @returns {query_search_v2.SearchUserResponseV2} SearchUserResponseV2 instance
         */
        SearchUserResponseV2.create = function create(properties) {
            return new SearchUserResponseV2(properties);
        };

        /**
         * Encodes the specified SearchUserResponseV2 message. Does not implicitly {@link query_search_v2.SearchUserResponseV2.verify|verify} messages.
         * @function encode
         * @memberof query_search_v2.SearchUserResponseV2
         * @static
         * @param {query_search_v2.ISearchUserResponseV2} message SearchUserResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUserResponseV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userPage != null && Object.hasOwnProperty.call(message, "userPage"))
                $root.query_recursive_types.UserPageResponse.encode(message.userPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUserResponseV2 message from the specified reader or buffer.
         * @function decode
         * @memberof query_search_v2.SearchUserResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_search_v2.SearchUserResponseV2} SearchUserResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUserResponseV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_search_v2.SearchUserResponseV2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userPage = $root.query_recursive_types.UserPageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchUserResponseV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_search_v2.SearchUserResponseV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_search_v2.SearchUserResponseV2} SearchUserResponseV2
         */
        SearchUserResponseV2.fromObject = function fromObject(object) {
            if (object instanceof $root.query_search_v2.SearchUserResponseV2)
                return object;
            var message = new $root.query_search_v2.SearchUserResponseV2();
            if (object.userPage != null) {
                if (typeof object.userPage !== "object")
                    throw TypeError(".query_search_v2.SearchUserResponseV2.userPage: object expected");
                message.userPage = $root.query_recursive_types.UserPageResponse.fromObject(object.userPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchUserResponseV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_search_v2.SearchUserResponseV2
         * @static
         * @param {query_search_v2.SearchUserResponseV2} message SearchUserResponseV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchUserResponseV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userPage = null;
            if (message.userPage != null && message.hasOwnProperty("userPage"))
                object.userPage = $root.query_recursive_types.UserPageResponse.toObject(message.userPage, options);
            return object;
        };

        /**
         * Converts this SearchUserResponseV2 to JSON.
         * @function toJSON
         * @memberof query_search_v2.SearchUserResponseV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchUserResponseV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchUserResponseV2
         * @function getTypeUrl
         * @memberof query_search_v2.SearchUserResponseV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchUserResponseV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_search_v2.SearchUserResponseV2";
        };

        return SearchUserResponseV2;
    })();

    return query_search_v2;
})();

$root.query_series_genre_ranking = (function() {

    /**
     * Namespace query_series_genre_ranking.
     * @exports query_series_genre_ranking
     * @namespace
     */
    var query_series_genre_ranking = {};

    query_series_genre_ranking.SeriesGenreRankingCursorRequest = (function() {

        /**
         * Properties of a SeriesGenreRankingCursorRequest.
         * @memberof query_series_genre_ranking
         * @interface ISeriesGenreRankingCursorRequest
         * @property {query_types.Genre|null} [genre] SeriesGenreRankingCursorRequest genre
         * @property {query_series_genre_ranking.ISeriesRankingRequest|null} [requireSeriesRanking] SeriesGenreRankingCursorRequest requireSeriesRanking
         * @property {google.protobuf.IBoolValue|null} [requireTargetDate] SeriesGenreRankingCursorRequest requireTargetDate
         * @property {query_pagination.ICursorRequest|null} [cursor] SeriesGenreRankingCursorRequest cursor
         */

        /**
         * Constructs a new SeriesGenreRankingCursorRequest.
         * @memberof query_series_genre_ranking
         * @classdesc Represents a SeriesGenreRankingCursorRequest.
         * @implements ISeriesGenreRankingCursorRequest
         * @constructor
         * @param {query_series_genre_ranking.ISeriesGenreRankingCursorRequest=} [properties] Properties to set
         */
        function SeriesGenreRankingCursorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGenreRankingCursorRequest genre.
         * @member {query_types.Genre} genre
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @instance
         */
        SeriesGenreRankingCursorRequest.prototype.genre = 0;

        /**
         * SeriesGenreRankingCursorRequest requireSeriesRanking.
         * @member {query_series_genre_ranking.ISeriesRankingRequest|null|undefined} requireSeriesRanking
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @instance
         */
        SeriesGenreRankingCursorRequest.prototype.requireSeriesRanking = null;

        /**
         * SeriesGenreRankingCursorRequest requireTargetDate.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTargetDate
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @instance
         */
        SeriesGenreRankingCursorRequest.prototype.requireTargetDate = null;

        /**
         * SeriesGenreRankingCursorRequest cursor.
         * @member {query_pagination.ICursorRequest|null|undefined} cursor
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @instance
         */
        SeriesGenreRankingCursorRequest.prototype.cursor = null;

        /**
         * Creates a new SeriesGenreRankingCursorRequest instance using the specified properties.
         * @function create
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @static
         * @param {query_series_genre_ranking.ISeriesGenreRankingCursorRequest=} [properties] Properties to set
         * @returns {query_series_genre_ranking.SeriesGenreRankingCursorRequest} SeriesGenreRankingCursorRequest instance
         */
        SeriesGenreRankingCursorRequest.create = function create(properties) {
            return new SeriesGenreRankingCursorRequest(properties);
        };

        /**
         * Encodes the specified SeriesGenreRankingCursorRequest message. Does not implicitly {@link query_series_genre_ranking.SeriesGenreRankingCursorRequest.verify|verify} messages.
         * @function encode
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @static
         * @param {query_series_genre_ranking.ISeriesGenreRankingCursorRequest} message SeriesGenreRankingCursorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGenreRankingCursorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.genre != null && Object.hasOwnProperty.call(message, "genre"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.genre);
            if (message.requireSeriesRanking != null && Object.hasOwnProperty.call(message, "requireSeriesRanking"))
                $root.query_series_genre_ranking.SeriesRankingRequest.encode(message.requireSeriesRanking, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireTargetDate != null && Object.hasOwnProperty.call(message, "requireTargetDate"))
                $root.google.protobuf.BoolValue.encode(message.requireTargetDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorRequest.encode(message.cursor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGenreRankingCursorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_series_genre_ranking.SeriesGenreRankingCursorRequest} SeriesGenreRankingCursorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGenreRankingCursorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_series_genre_ranking.SeriesGenreRankingCursorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.genre = reader.int32();
                    break;
                case 2:
                    message.requireSeriesRanking = $root.query_series_genre_ranking.SeriesRankingRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireTargetDate = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.cursor = $root.query_pagination.CursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGenreRankingCursorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_series_genre_ranking.SeriesGenreRankingCursorRequest} SeriesGenreRankingCursorRequest
         */
        SeriesGenreRankingCursorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_series_genre_ranking.SeriesGenreRankingCursorRequest)
                return object;
            var message = new $root.query_series_genre_ranking.SeriesGenreRankingCursorRequest();
            switch (object.genre) {
            case "GENRE_UNSPECIFIED":
            case 0:
                message.genre = 0;
                break;
            case "GENRE_FANFIC":
            case 1:
                message.genre = 1;
                break;
            case "GENRE_OTHER":
            case 2:
                message.genre = 2;
                break;
            case "GENRE_HORROR_THRILLER":
            case 3:
                message.genre = 3;
                break;
            case "GENRE_ROMANCE_FANTASY":
            case 4:
                message.genre = 4;
                break;
            case "GENRE_BL":
            case 5:
                message.genre = 5;
                break;
            case "GENRE_DRAMA":
            case 6:
                message.genre = 6;
                break;
            case "GENRE_ROMANCE":
            case 7:
                message.genre = 7;
                break;
            case "GENRE_FANTASY":
            case 8:
                message.genre = 8;
                break;
            case "GENRE_COMEDY":
            case 9:
                message.genre = 9;
                break;
            }
            if (object.requireSeriesRanking != null) {
                if (typeof object.requireSeriesRanking !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesGenreRankingCursorRequest.requireSeriesRanking: object expected");
                message.requireSeriesRanking = $root.query_series_genre_ranking.SeriesRankingRequest.fromObject(object.requireSeriesRanking);
            }
            if (object.requireTargetDate != null) {
                if (typeof object.requireTargetDate !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesGenreRankingCursorRequest.requireTargetDate: object expected");
                message.requireTargetDate = $root.google.protobuf.BoolValue.fromObject(object.requireTargetDate);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesGenreRankingCursorRequest.cursor: object expected");
                message.cursor = $root.query_pagination.CursorRequest.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGenreRankingCursorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @static
         * @param {query_series_genre_ranking.SeriesGenreRankingCursorRequest} message SeriesGenreRankingCursorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGenreRankingCursorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.genre = options.enums === String ? "GENRE_UNSPECIFIED" : 0;
                object.requireSeriesRanking = null;
                object.requireTargetDate = null;
                object.cursor = null;
            }
            if (message.genre != null && message.hasOwnProperty("genre"))
                object.genre = options.enums === String ? $root.query_types.Genre[message.genre] : message.genre;
            if (message.requireSeriesRanking != null && message.hasOwnProperty("requireSeriesRanking"))
                object.requireSeriesRanking = $root.query_series_genre_ranking.SeriesRankingRequest.toObject(message.requireSeriesRanking, options);
            if (message.requireTargetDate != null && message.hasOwnProperty("requireTargetDate"))
                object.requireTargetDate = $root.google.protobuf.BoolValue.toObject(message.requireTargetDate, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorRequest.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this SeriesGenreRankingCursorRequest to JSON.
         * @function toJSON
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGenreRankingCursorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGenreRankingCursorRequest
         * @function getTypeUrl
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGenreRankingCursorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_series_genre_ranking.SeriesGenreRankingCursorRequest";
        };

        return SeriesGenreRankingCursorRequest;
    })();

    query_series_genre_ranking.SeriesGenreRankingCursorResponse = (function() {

        /**
         * Properties of a SeriesGenreRankingCursorResponse.
         * @memberof query_series_genre_ranking
         * @interface ISeriesGenreRankingCursorResponse
         * @property {Array.<query_series_genre_ranking.ISeriesRankingResponse>|null} [seriesList] SeriesGenreRankingCursorResponse seriesList
         * @property {google.protobuf.IStringValue|null} [targetDate] SeriesGenreRankingCursorResponse targetDate
         * @property {query_pagination.ICursorInfo|null} [cursor] SeriesGenreRankingCursorResponse cursor
         */

        /**
         * Constructs a new SeriesGenreRankingCursorResponse.
         * @memberof query_series_genre_ranking
         * @classdesc Represents a SeriesGenreRankingCursorResponse.
         * @implements ISeriesGenreRankingCursorResponse
         * @constructor
         * @param {query_series_genre_ranking.ISeriesGenreRankingCursorResponse=} [properties] Properties to set
         */
        function SeriesGenreRankingCursorResponse(properties) {
            this.seriesList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGenreRankingCursorResponse seriesList.
         * @member {Array.<query_series_genre_ranking.ISeriesRankingResponse>} seriesList
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @instance
         */
        SeriesGenreRankingCursorResponse.prototype.seriesList = $util.emptyArray;

        /**
         * SeriesGenreRankingCursorResponse targetDate.
         * @member {google.protobuf.IStringValue|null|undefined} targetDate
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @instance
         */
        SeriesGenreRankingCursorResponse.prototype.targetDate = null;

        /**
         * SeriesGenreRankingCursorResponse cursor.
         * @member {query_pagination.ICursorInfo|null|undefined} cursor
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @instance
         */
        SeriesGenreRankingCursorResponse.prototype.cursor = null;

        /**
         * Creates a new SeriesGenreRankingCursorResponse instance using the specified properties.
         * @function create
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @static
         * @param {query_series_genre_ranking.ISeriesGenreRankingCursorResponse=} [properties] Properties to set
         * @returns {query_series_genre_ranking.SeriesGenreRankingCursorResponse} SeriesGenreRankingCursorResponse instance
         */
        SeriesGenreRankingCursorResponse.create = function create(properties) {
            return new SeriesGenreRankingCursorResponse(properties);
        };

        /**
         * Encodes the specified SeriesGenreRankingCursorResponse message. Does not implicitly {@link query_series_genre_ranking.SeriesGenreRankingCursorResponse.verify|verify} messages.
         * @function encode
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @static
         * @param {query_series_genre_ranking.ISeriesGenreRankingCursorResponse} message SeriesGenreRankingCursorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGenreRankingCursorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seriesList != null && message.seriesList.length)
                for (var i = 0; i < message.seriesList.length; ++i)
                    $root.query_series_genre_ranking.SeriesRankingResponse.encode(message.seriesList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.targetDate != null && Object.hasOwnProperty.call(message, "targetDate"))
                $root.google.protobuf.StringValue.encode(message.targetDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.query_pagination.CursorInfo.encode(message.cursor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGenreRankingCursorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_series_genre_ranking.SeriesGenreRankingCursorResponse} SeriesGenreRankingCursorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGenreRankingCursorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_series_genre_ranking.SeriesGenreRankingCursorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.seriesList && message.seriesList.length))
                        message.seriesList = [];
                    message.seriesList.push($root.query_series_genre_ranking.SeriesRankingResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.targetDate = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.cursor = $root.query_pagination.CursorInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGenreRankingCursorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_series_genre_ranking.SeriesGenreRankingCursorResponse} SeriesGenreRankingCursorResponse
         */
        SeriesGenreRankingCursorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_series_genre_ranking.SeriesGenreRankingCursorResponse)
                return object;
            var message = new $root.query_series_genre_ranking.SeriesGenreRankingCursorResponse();
            if (object.seriesList) {
                if (!Array.isArray(object.seriesList))
                    throw TypeError(".query_series_genre_ranking.SeriesGenreRankingCursorResponse.seriesList: array expected");
                message.seriesList = [];
                for (var i = 0; i < object.seriesList.length; ++i) {
                    if (typeof object.seriesList[i] !== "object")
                        throw TypeError(".query_series_genre_ranking.SeriesGenreRankingCursorResponse.seriesList: object expected");
                    message.seriesList[i] = $root.query_series_genre_ranking.SeriesRankingResponse.fromObject(object.seriesList[i]);
                }
            }
            if (object.targetDate != null) {
                if (typeof object.targetDate !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesGenreRankingCursorResponse.targetDate: object expected");
                message.targetDate = $root.google.protobuf.StringValue.fromObject(object.targetDate);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesGenreRankingCursorResponse.cursor: object expected");
                message.cursor = $root.query_pagination.CursorInfo.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGenreRankingCursorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @static
         * @param {query_series_genre_ranking.SeriesGenreRankingCursorResponse} message SeriesGenreRankingCursorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGenreRankingCursorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.seriesList = [];
            if (options.defaults) {
                object.targetDate = null;
                object.cursor = null;
            }
            if (message.seriesList && message.seriesList.length) {
                object.seriesList = [];
                for (var j = 0; j < message.seriesList.length; ++j)
                    object.seriesList[j] = $root.query_series_genre_ranking.SeriesRankingResponse.toObject(message.seriesList[j], options);
            }
            if (message.targetDate != null && message.hasOwnProperty("targetDate"))
                object.targetDate = $root.google.protobuf.StringValue.toObject(message.targetDate, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.query_pagination.CursorInfo.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this SeriesGenreRankingCursorResponse to JSON.
         * @function toJSON
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGenreRankingCursorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGenreRankingCursorResponse
         * @function getTypeUrl
         * @memberof query_series_genre_ranking.SeriesGenreRankingCursorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGenreRankingCursorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_series_genre_ranking.SeriesGenreRankingCursorResponse";
        };

        return SeriesGenreRankingCursorResponse;
    })();

    query_series_genre_ranking.SeriesRankingRequest = (function() {

        /**
         * Properties of a SeriesRankingRequest.
         * @memberof query_series_genre_ranking
         * @interface ISeriesRankingRequest
         * @property {query_recursive_types.ISeriesRequireField|null} [requireSeries] SeriesRankingRequest requireSeries
         * @property {google.protobuf.IBoolValue|null} [requireTrend] SeriesRankingRequest requireTrend
         * @property {google.protobuf.IBoolValue|null} [requireRank] SeriesRankingRequest requireRank
         */

        /**
         * Constructs a new SeriesRankingRequest.
         * @memberof query_series_genre_ranking
         * @classdesc Represents a SeriesRankingRequest.
         * @implements ISeriesRankingRequest
         * @constructor
         * @param {query_series_genre_ranking.ISeriesRankingRequest=} [properties] Properties to set
         */
        function SeriesRankingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesRankingRequest requireSeries.
         * @member {query_recursive_types.ISeriesRequireField|null|undefined} requireSeries
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @instance
         */
        SeriesRankingRequest.prototype.requireSeries = null;

        /**
         * SeriesRankingRequest requireTrend.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTrend
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @instance
         */
        SeriesRankingRequest.prototype.requireTrend = null;

        /**
         * SeriesRankingRequest requireRank.
         * @member {google.protobuf.IBoolValue|null|undefined} requireRank
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @instance
         */
        SeriesRankingRequest.prototype.requireRank = null;

        /**
         * Creates a new SeriesRankingRequest instance using the specified properties.
         * @function create
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @static
         * @param {query_series_genre_ranking.ISeriesRankingRequest=} [properties] Properties to set
         * @returns {query_series_genre_ranking.SeriesRankingRequest} SeriesRankingRequest instance
         */
        SeriesRankingRequest.create = function create(properties) {
            return new SeriesRankingRequest(properties);
        };

        /**
         * Encodes the specified SeriesRankingRequest message. Does not implicitly {@link query_series_genre_ranking.SeriesRankingRequest.verify|verify} messages.
         * @function encode
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @static
         * @param {query_series_genre_ranking.ISeriesRankingRequest} message SeriesRankingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesRankingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSeries != null && Object.hasOwnProperty.call(message, "requireSeries"))
                $root.query_recursive_types.SeriesRequireField.encode(message.requireSeries, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTrend != null && Object.hasOwnProperty.call(message, "requireTrend"))
                $root.google.protobuf.BoolValue.encode(message.requireTrend, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireRank != null && Object.hasOwnProperty.call(message, "requireRank"))
                $root.google.protobuf.BoolValue.encode(message.requireRank, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesRankingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_series_genre_ranking.SeriesRankingRequest} SeriesRankingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesRankingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_series_genre_ranking.SeriesRankingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSeries = $root.query_recursive_types.SeriesRequireField.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTrend = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireRank = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesRankingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_series_genre_ranking.SeriesRankingRequest} SeriesRankingRequest
         */
        SeriesRankingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_series_genre_ranking.SeriesRankingRequest)
                return object;
            var message = new $root.query_series_genre_ranking.SeriesRankingRequest();
            if (object.requireSeries != null) {
                if (typeof object.requireSeries !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesRankingRequest.requireSeries: object expected");
                message.requireSeries = $root.query_recursive_types.SeriesRequireField.fromObject(object.requireSeries);
            }
            if (object.requireTrend != null) {
                if (typeof object.requireTrend !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesRankingRequest.requireTrend: object expected");
                message.requireTrend = $root.google.protobuf.BoolValue.fromObject(object.requireTrend);
            }
            if (object.requireRank != null) {
                if (typeof object.requireRank !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesRankingRequest.requireRank: object expected");
                message.requireRank = $root.google.protobuf.BoolValue.fromObject(object.requireRank);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesRankingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @static
         * @param {query_series_genre_ranking.SeriesRankingRequest} message SeriesRankingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesRankingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireSeries = null;
                object.requireTrend = null;
                object.requireRank = null;
            }
            if (message.requireSeries != null && message.hasOwnProperty("requireSeries"))
                object.requireSeries = $root.query_recursive_types.SeriesRequireField.toObject(message.requireSeries, options);
            if (message.requireTrend != null && message.hasOwnProperty("requireTrend"))
                object.requireTrend = $root.google.protobuf.BoolValue.toObject(message.requireTrend, options);
            if (message.requireRank != null && message.hasOwnProperty("requireRank"))
                object.requireRank = $root.google.protobuf.BoolValue.toObject(message.requireRank, options);
            return object;
        };

        /**
         * Converts this SeriesRankingRequest to JSON.
         * @function toJSON
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesRankingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesRankingRequest
         * @function getTypeUrl
         * @memberof query_series_genre_ranking.SeriesRankingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesRankingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_series_genre_ranking.SeriesRankingRequest";
        };

        return SeriesRankingRequest;
    })();

    query_series_genre_ranking.SeriesRankingResponse = (function() {

        /**
         * Properties of a SeriesRankingResponse.
         * @memberof query_series_genre_ranking
         * @interface ISeriesRankingResponse
         * @property {query_recursive_types.ISeriesResponse|null} [series] SeriesRankingResponse series
         * @property {query_types.Trend|null} [trend] SeriesRankingResponse trend
         * @property {google.protobuf.IInt64Value|null} [rank] SeriesRankingResponse rank
         */

        /**
         * Constructs a new SeriesRankingResponse.
         * @memberof query_series_genre_ranking
         * @classdesc Represents a SeriesRankingResponse.
         * @implements ISeriesRankingResponse
         * @constructor
         * @param {query_series_genre_ranking.ISeriesRankingResponse=} [properties] Properties to set
         */
        function SeriesRankingResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesRankingResponse series.
         * @member {query_recursive_types.ISeriesResponse|null|undefined} series
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @instance
         */
        SeriesRankingResponse.prototype.series = null;

        /**
         * SeriesRankingResponse trend.
         * @member {query_types.Trend} trend
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @instance
         */
        SeriesRankingResponse.prototype.trend = 0;

        /**
         * SeriesRankingResponse rank.
         * @member {google.protobuf.IInt64Value|null|undefined} rank
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @instance
         */
        SeriesRankingResponse.prototype.rank = null;

        /**
         * Creates a new SeriesRankingResponse instance using the specified properties.
         * @function create
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @static
         * @param {query_series_genre_ranking.ISeriesRankingResponse=} [properties] Properties to set
         * @returns {query_series_genre_ranking.SeriesRankingResponse} SeriesRankingResponse instance
         */
        SeriesRankingResponse.create = function create(properties) {
            return new SeriesRankingResponse(properties);
        };

        /**
         * Encodes the specified SeriesRankingResponse message. Does not implicitly {@link query_series_genre_ranking.SeriesRankingResponse.verify|verify} messages.
         * @function encode
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @static
         * @param {query_series_genre_ranking.ISeriesRankingResponse} message SeriesRankingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesRankingResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.query_recursive_types.SeriesResponse.encode(message.series, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.trend != null && Object.hasOwnProperty.call(message, "trend"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.trend);
            if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
                $root.google.protobuf.Int64Value.encode(message.rank, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesRankingResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_series_genre_ranking.SeriesRankingResponse} SeriesRankingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesRankingResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_series_genre_ranking.SeriesRankingResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.series = $root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.trend = reader.int32();
                    break;
                case 3:
                    message.rank = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesRankingResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_series_genre_ranking.SeriesRankingResponse} SeriesRankingResponse
         */
        SeriesRankingResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_series_genre_ranking.SeriesRankingResponse)
                return object;
            var message = new $root.query_series_genre_ranking.SeriesRankingResponse();
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesRankingResponse.series: object expected");
                message.series = $root.query_recursive_types.SeriesResponse.fromObject(object.series);
            }
            switch (object.trend) {
            case "TREND_UNSPECIFIED":
            case 0:
                message.trend = 0;
                break;
            case "TREND_UP":
            case 1:
                message.trend = 1;
                break;
            case "TREND_DOWN":
            case 2:
                message.trend = 2;
                break;
            case "TREND_STAY":
            case 3:
                message.trend = 3;
                break;
            }
            if (object.rank != null) {
                if (typeof object.rank !== "object")
                    throw TypeError(".query_series_genre_ranking.SeriesRankingResponse.rank: object expected");
                message.rank = $root.google.protobuf.Int64Value.fromObject(object.rank);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesRankingResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @static
         * @param {query_series_genre_ranking.SeriesRankingResponse} message SeriesRankingResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesRankingResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.series = null;
                object.trend = options.enums === String ? "TREND_UNSPECIFIED" : 0;
                object.rank = null;
            }
            if (message.series != null && message.hasOwnProperty("series"))
                object.series = $root.query_recursive_types.SeriesResponse.toObject(message.series, options);
            if (message.trend != null && message.hasOwnProperty("trend"))
                object.trend = options.enums === String ? $root.query_types.Trend[message.trend] : message.trend;
            if (message.rank != null && message.hasOwnProperty("rank"))
                object.rank = $root.google.protobuf.Int64Value.toObject(message.rank, options);
            return object;
        };

        /**
         * Converts this SeriesRankingResponse to JSON.
         * @function toJSON
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesRankingResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesRankingResponse
         * @function getTypeUrl
         * @memberof query_series_genre_ranking.SeriesRankingResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesRankingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_series_genre_ranking.SeriesRankingResponse";
        };

        return SeriesRankingResponse;
    })();

    return query_series_genre_ranking;
})();

$root.query = (function() {

    /**
     * Namespace query.
     * @exports query
     * @namespace
     */
    var query = {};

    query.QueryService = (function() {

        /**
         * Constructs a new QueryService service.
         * @memberof query
         * @classdesc Represents a QueryService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function QueryService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (QueryService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = QueryService;

        /**
         * Creates new QueryService service using the specified rpc implementation.
         * @function create
         * @memberof query.QueryService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {QueryService} RPC service. Useful where requests and/or responses are streamed.
         */
        QueryService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link query.QueryService#query}.
         * @memberof query.QueryService
         * @typedef QueryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {query.QueryResponse} [response] QueryResponse
         */

        /**
         * Calls Query.
         * @function query
         * @memberof query.QueryService
         * @instance
         * @param {query.IQueryRequest} request QueryRequest message or plain object
         * @param {query.QueryService.QueryCallback} callback Node-style callback called with the error, if any, and QueryResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(QueryService.prototype.query = function query(request, callback) {
            return this.rpcCall(query, $root.query.QueryRequest, $root.query.QueryResponse, request, callback);
        }, "name", { value: "Query" });

        /**
         * Calls Query.
         * @function query
         * @memberof query.QueryService
         * @instance
         * @param {query.IQueryRequest} request QueryRequest message or plain object
         * @returns {Promise<query.QueryResponse>} Promise
         * @variation 2
         */

        return QueryService;
    })();

    query.QueryRequest = (function() {

        /**
         * Properties of a QueryRequest.
         * @memberof query
         * @interface IQueryRequest
         * @property {Array.<query.IQueryRequestItem>|null} [requestList] QueryRequest requestList
         */

        /**
         * Constructs a new QueryRequest.
         * @memberof query
         * @classdesc Represents a QueryRequest.
         * @implements IQueryRequest
         * @constructor
         * @param {query.IQueryRequest=} [properties] Properties to set
         */
        function QueryRequest(properties) {
            this.requestList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryRequest requestList.
         * @member {Array.<query.IQueryRequestItem>} requestList
         * @memberof query.QueryRequest
         * @instance
         */
        QueryRequest.prototype.requestList = $util.emptyArray;

        /**
         * Creates a new QueryRequest instance using the specified properties.
         * @function create
         * @memberof query.QueryRequest
         * @static
         * @param {query.IQueryRequest=} [properties] Properties to set
         * @returns {query.QueryRequest} QueryRequest instance
         */
        QueryRequest.create = function create(properties) {
            return new QueryRequest(properties);
        };

        /**
         * Encodes the specified QueryRequest message. Does not implicitly {@link query.QueryRequest.verify|verify} messages.
         * @function encode
         * @memberof query.QueryRequest
         * @static
         * @param {query.IQueryRequest} message QueryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestList != null && message.requestList.length)
                for (var i = 0; i < message.requestList.length; ++i)
                    $root.query.QueryRequestItem.encode(message.requestList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a QueryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query.QueryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query.QueryRequest} QueryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query.QueryRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.requestList && message.requestList.length))
                        message.requestList = [];
                    message.requestList.push($root.query.QueryRequestItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a QueryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query.QueryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query.QueryRequest} QueryRequest
         */
        QueryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query.QueryRequest)
                return object;
            var message = new $root.query.QueryRequest();
            if (object.requestList) {
                if (!Array.isArray(object.requestList))
                    throw TypeError(".query.QueryRequest.requestList: array expected");
                message.requestList = [];
                for (var i = 0; i < object.requestList.length; ++i) {
                    if (typeof object.requestList[i] !== "object")
                        throw TypeError(".query.QueryRequest.requestList: object expected");
                    message.requestList[i] = $root.query.QueryRequestItem.fromObject(object.requestList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a QueryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query.QueryRequest
         * @static
         * @param {query.QueryRequest} message QueryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.requestList = [];
            if (message.requestList && message.requestList.length) {
                object.requestList = [];
                for (var j = 0; j < message.requestList.length; ++j)
                    object.requestList[j] = $root.query.QueryRequestItem.toObject(message.requestList[j], options);
            }
            return object;
        };

        /**
         * Converts this QueryRequest to JSON.
         * @function toJSON
         * @memberof query.QueryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueryRequest
         * @function getTypeUrl
         * @memberof query.QueryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.QueryRequest";
        };

        return QueryRequest;
    })();

    query.QueryRequestItem = (function() {

        /**
         * Properties of a QueryRequestItem.
         * @memberof query
         * @interface IQueryRequestItem
         * @property {google.protobuf.IStringValue|null} [requestId] QueryRequestItem requestId
         * @property {query_recursive_types.IMeRequest|null} [meRequest] QueryRequestItem meRequest
         * @property {query_recursive_types.IUserRequest|null} [userRequest] QueryRequestItem userRequest
         * @property {query_recursive_types.IStoryRequest|null} [storyRequest] QueryRequestItem storyRequest
         * @property {query_recursive_types.ISeriesRequest|null} [seriesRequest] QueryRequestItem seriesRequest
         * @property {query_recursive_types.ISeriesGroupRequest|null} [seriesGroupRequest] QueryRequestItem seriesGroupRequest
         * @property {query_recursive_types.IStoryAdvertisementRequest|null} [storyAdvertisementRequest] QueryRequestItem storyAdvertisementRequest
         * @property {query_recursive_types.ICommentRequest|null} [commentRequest] QueryRequestItem commentRequest
         * @property {query_recursive_types.IEventListRequest|null} [eventListRequest] QueryRequestItem eventListRequest
         * @property {query_tag_list.ITagRankingRequest|null} [tagRankingRequest] QueryRequestItem tagRankingRequest
         * @property {query_recursive_types.ITagMedalCursorRequest|null} [tagMedalRequest] QueryRequestItem tagMedalRequest
         * @property {query_search_query.ISearchQueryRequest|null} [searchQueryRequest] QueryRequestItem searchQueryRequest
         * @property {query_recursive_types.ITagRequest|null} [tagRequest] QueryRequestItem tagRequest
         * @property {query_tag_list.ITagHotListRequest|null} [tagHotListRequest] QueryRequestItem tagHotListRequest
         * @property {query_search_v2.ISearchSeriesRequestV2|null} [searchSeriesRequestV2] QueryRequestItem searchSeriesRequestV2
         * @property {query_search_v2.ISearchSeriesByTitleRequestV2|null} [searchSeriesByTitleRequestV2] QueryRequestItem searchSeriesByTitleRequestV2
         * @property {query_search_v2.ISearchSeriesByTagRequestV2|null} [searchSeriesByTagRequestV2] QueryRequestItem searchSeriesByTagRequestV2
         * @property {query_search_v2.ISearchLatestSeriesRequestV2|null} [searchLatestSeriesRequestV2] QueryRequestItem searchLatestSeriesRequestV2
         * @property {query_search_v2.ISearchCompletedOfficialSeriesRequestV2|null} [searchCompletedOfficialSeriesRequestV2] QueryRequestItem searchCompletedOfficialSeriesRequestV2
         * @property {query_search_v2.ISearchUncompletedOfficialSeriesRequestV2|null} [searchUncompletedOfficialSeriesRequestV2] QueryRequestItem searchUncompletedOfficialSeriesRequestV2
         * @property {query_search_v2.ISearchFacetRelatedTagRequestV2|null} [searchFacetRelatedTagRequestV2] QueryRequestItem searchFacetRelatedTagRequestV2
         * @property {query_search_v2.ISearchFacetTagRequestV2|null} [searchFacetTagRequestV2] QueryRequestItem searchFacetTagRequestV2
         * @property {query_search_v2.ISearchLatestFacetTagRequestV2|null} [searchLatestFacetTagRequestV2] QueryRequestItem searchLatestFacetTagRequestV2
         * @property {query_search_v2.ISearchUserRequestV2|null} [searchUserRequestV2] QueryRequestItem searchUserRequestV2
         * @property {query_search_v2.ISearchUserByTagsRequestV2|null} [searchUserByTagsRequestV2] QueryRequestItem searchUserByTagsRequestV2
         * @property {query_search_v2.ISearchLatestWriterRequestV2|null} [searchLatestWriterRequestV2] QueryRequestItem searchLatestWriterRequestV2
         * @property {query_recursive_types.ISeriesSelectionRequest|null} [seriesSelectionRequest] QueryRequestItem seriesSelectionRequest
         * @property {query_series_genre_ranking.ISeriesGenreRankingCursorRequest|null} [seriesGenreRankingRequest] QueryRequestItem seriesGenreRankingRequest
         * @property {query_story_selection.IStorySelectionRequest|null} [storySelectionRequest] QueryRequestItem storySelectionRequest
         * @property {query_recursive_types.ISearchStoryRequest|null} [searchStoryRequest] QueryRequestItem searchStoryRequest
         * @property {query_search_types.ISearchStoryFacetTagRequest|null} [searchStoryFacetTagRequest] QueryRequestItem searchStoryFacetTagRequest
         * @property {query_recursive_types.ISearchUserRequest|null} [searchUserRequest] QueryRequestItem searchUserRequest
         * @property {query_recursive_types.ISearchSeriesRequest|null} [searchSeriesRequest] QueryRequestItem searchSeriesRequest
         * @property {query_recursive_types.ISearchSeriesGroupRequest|null} [searchSeriesGroupRequest] QueryRequestItem searchSeriesGroupRequest
         * @property {query_story_ranking.IStoryRankingRequest|null} [storyRankingRequest] QueryRequestItem storyRankingRequest
         */

        /**
         * Constructs a new QueryRequestItem.
         * @memberof query
         * @classdesc Represents a QueryRequestItem.
         * @implements IQueryRequestItem
         * @constructor
         * @param {query.IQueryRequestItem=} [properties] Properties to set
         */
        function QueryRequestItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryRequestItem requestId.
         * @member {google.protobuf.IStringValue|null|undefined} requestId
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.requestId = null;

        /**
         * QueryRequestItem meRequest.
         * @member {query_recursive_types.IMeRequest|null|undefined} meRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.meRequest = null;

        /**
         * QueryRequestItem userRequest.
         * @member {query_recursive_types.IUserRequest|null|undefined} userRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.userRequest = null;

        /**
         * QueryRequestItem storyRequest.
         * @member {query_recursive_types.IStoryRequest|null|undefined} storyRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.storyRequest = null;

        /**
         * QueryRequestItem seriesRequest.
         * @member {query_recursive_types.ISeriesRequest|null|undefined} seriesRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.seriesRequest = null;

        /**
         * QueryRequestItem seriesGroupRequest.
         * @member {query_recursive_types.ISeriesGroupRequest|null|undefined} seriesGroupRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.seriesGroupRequest = null;

        /**
         * QueryRequestItem storyAdvertisementRequest.
         * @member {query_recursive_types.IStoryAdvertisementRequest|null|undefined} storyAdvertisementRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.storyAdvertisementRequest = null;

        /**
         * QueryRequestItem commentRequest.
         * @member {query_recursive_types.ICommentRequest|null|undefined} commentRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.commentRequest = null;

        /**
         * QueryRequestItem eventListRequest.
         * @member {query_recursive_types.IEventListRequest|null|undefined} eventListRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.eventListRequest = null;

        /**
         * QueryRequestItem tagRankingRequest.
         * @member {query_tag_list.ITagRankingRequest|null|undefined} tagRankingRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.tagRankingRequest = null;

        /**
         * QueryRequestItem tagMedalRequest.
         * @member {query_recursive_types.ITagMedalCursorRequest|null|undefined} tagMedalRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.tagMedalRequest = null;

        /**
         * QueryRequestItem searchQueryRequest.
         * @member {query_search_query.ISearchQueryRequest|null|undefined} searchQueryRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchQueryRequest = null;

        /**
         * QueryRequestItem tagRequest.
         * @member {query_recursive_types.ITagRequest|null|undefined} tagRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.tagRequest = null;

        /**
         * QueryRequestItem tagHotListRequest.
         * @member {query_tag_list.ITagHotListRequest|null|undefined} tagHotListRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.tagHotListRequest = null;

        /**
         * QueryRequestItem searchSeriesRequestV2.
         * @member {query_search_v2.ISearchSeriesRequestV2|null|undefined} searchSeriesRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchSeriesRequestV2 = null;

        /**
         * QueryRequestItem searchSeriesByTitleRequestV2.
         * @member {query_search_v2.ISearchSeriesByTitleRequestV2|null|undefined} searchSeriesByTitleRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchSeriesByTitleRequestV2 = null;

        /**
         * QueryRequestItem searchSeriesByTagRequestV2.
         * @member {query_search_v2.ISearchSeriesByTagRequestV2|null|undefined} searchSeriesByTagRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchSeriesByTagRequestV2 = null;

        /**
         * QueryRequestItem searchLatestSeriesRequestV2.
         * @member {query_search_v2.ISearchLatestSeriesRequestV2|null|undefined} searchLatestSeriesRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchLatestSeriesRequestV2 = null;

        /**
         * QueryRequestItem searchCompletedOfficialSeriesRequestV2.
         * @member {query_search_v2.ISearchCompletedOfficialSeriesRequestV2|null|undefined} searchCompletedOfficialSeriesRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchCompletedOfficialSeriesRequestV2 = null;

        /**
         * QueryRequestItem searchUncompletedOfficialSeriesRequestV2.
         * @member {query_search_v2.ISearchUncompletedOfficialSeriesRequestV2|null|undefined} searchUncompletedOfficialSeriesRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchUncompletedOfficialSeriesRequestV2 = null;

        /**
         * QueryRequestItem searchFacetRelatedTagRequestV2.
         * @member {query_search_v2.ISearchFacetRelatedTagRequestV2|null|undefined} searchFacetRelatedTagRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchFacetRelatedTagRequestV2 = null;

        /**
         * QueryRequestItem searchFacetTagRequestV2.
         * @member {query_search_v2.ISearchFacetTagRequestV2|null|undefined} searchFacetTagRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchFacetTagRequestV2 = null;

        /**
         * QueryRequestItem searchLatestFacetTagRequestV2.
         * @member {query_search_v2.ISearchLatestFacetTagRequestV2|null|undefined} searchLatestFacetTagRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchLatestFacetTagRequestV2 = null;

        /**
         * QueryRequestItem searchUserRequestV2.
         * @member {query_search_v2.ISearchUserRequestV2|null|undefined} searchUserRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchUserRequestV2 = null;

        /**
         * QueryRequestItem searchUserByTagsRequestV2.
         * @member {query_search_v2.ISearchUserByTagsRequestV2|null|undefined} searchUserByTagsRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchUserByTagsRequestV2 = null;

        /**
         * QueryRequestItem searchLatestWriterRequestV2.
         * @member {query_search_v2.ISearchLatestWriterRequestV2|null|undefined} searchLatestWriterRequestV2
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchLatestWriterRequestV2 = null;

        /**
         * QueryRequestItem seriesSelectionRequest.
         * @member {query_recursive_types.ISeriesSelectionRequest|null|undefined} seriesSelectionRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.seriesSelectionRequest = null;

        /**
         * QueryRequestItem seriesGenreRankingRequest.
         * @member {query_series_genre_ranking.ISeriesGenreRankingCursorRequest|null|undefined} seriesGenreRankingRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.seriesGenreRankingRequest = null;

        /**
         * QueryRequestItem storySelectionRequest.
         * @member {query_story_selection.IStorySelectionRequest|null|undefined} storySelectionRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.storySelectionRequest = null;

        /**
         * QueryRequestItem searchStoryRequest.
         * @member {query_recursive_types.ISearchStoryRequest|null|undefined} searchStoryRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchStoryRequest = null;

        /**
         * QueryRequestItem searchStoryFacetTagRequest.
         * @member {query_search_types.ISearchStoryFacetTagRequest|null|undefined} searchStoryFacetTagRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchStoryFacetTagRequest = null;

        /**
         * QueryRequestItem searchUserRequest.
         * @member {query_recursive_types.ISearchUserRequest|null|undefined} searchUserRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchUserRequest = null;

        /**
         * QueryRequestItem searchSeriesRequest.
         * @member {query_recursive_types.ISearchSeriesRequest|null|undefined} searchSeriesRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchSeriesRequest = null;

        /**
         * QueryRequestItem searchSeriesGroupRequest.
         * @member {query_recursive_types.ISearchSeriesGroupRequest|null|undefined} searchSeriesGroupRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.searchSeriesGroupRequest = null;

        /**
         * QueryRequestItem storyRankingRequest.
         * @member {query_story_ranking.IStoryRankingRequest|null|undefined} storyRankingRequest
         * @memberof query.QueryRequestItem
         * @instance
         */
        QueryRequestItem.prototype.storyRankingRequest = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QueryRequestItem request.
         * @member {"meRequest"|"userRequest"|"storyRequest"|"seriesRequest"|"seriesGroupRequest"|"storyAdvertisementRequest"|"commentRequest"|"eventListRequest"|"tagRankingRequest"|"tagMedalRequest"|"searchQueryRequest"|"tagRequest"|"tagHotListRequest"|"searchSeriesRequestV2"|"searchSeriesByTitleRequestV2"|"searchSeriesByTagRequestV2"|"searchLatestSeriesRequestV2"|"searchCompletedOfficialSeriesRequestV2"|"searchUncompletedOfficialSeriesRequestV2"|"searchFacetRelatedTagRequestV2"|"searchFacetTagRequestV2"|"searchLatestFacetTagRequestV2"|"searchUserRequestV2"|"searchUserByTagsRequestV2"|"searchLatestWriterRequestV2"|"seriesSelectionRequest"|"seriesGenreRankingRequest"|"storySelectionRequest"|"searchStoryRequest"|"searchStoryFacetTagRequest"|"searchUserRequest"|"searchSeriesRequest"|"searchSeriesGroupRequest"|"storyRankingRequest"|undefined} request
         * @memberof query.QueryRequestItem
         * @instance
         */
        Object.defineProperty(QueryRequestItem.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["meRequest", "userRequest", "storyRequest", "seriesRequest", "seriesGroupRequest", "storyAdvertisementRequest", "commentRequest", "eventListRequest", "tagRankingRequest", "tagMedalRequest", "searchQueryRequest", "tagRequest", "tagHotListRequest", "searchSeriesRequestV2", "searchSeriesByTitleRequestV2", "searchSeriesByTagRequestV2", "searchLatestSeriesRequestV2", "searchCompletedOfficialSeriesRequestV2", "searchUncompletedOfficialSeriesRequestV2", "searchFacetRelatedTagRequestV2", "searchFacetTagRequestV2", "searchLatestFacetTagRequestV2", "searchUserRequestV2", "searchUserByTagsRequestV2", "searchLatestWriterRequestV2", "seriesSelectionRequest", "seriesGenreRankingRequest", "storySelectionRequest", "searchStoryRequest", "searchStoryFacetTagRequest", "searchUserRequest", "searchSeriesRequest", "searchSeriesGroupRequest", "storyRankingRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new QueryRequestItem instance using the specified properties.
         * @function create
         * @memberof query.QueryRequestItem
         * @static
         * @param {query.IQueryRequestItem=} [properties] Properties to set
         * @returns {query.QueryRequestItem} QueryRequestItem instance
         */
        QueryRequestItem.create = function create(properties) {
            return new QueryRequestItem(properties);
        };

        /**
         * Encodes the specified QueryRequestItem message. Does not implicitly {@link query.QueryRequestItem.verify|verify} messages.
         * @function encode
         * @memberof query.QueryRequestItem
         * @static
         * @param {query.IQueryRequestItem} message QueryRequestItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRequestItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                $root.google.protobuf.StringValue.encode(message.requestId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.meRequest != null && Object.hasOwnProperty.call(message, "meRequest"))
                $root.query_recursive_types.MeRequest.encode(message.meRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.userRequest != null && Object.hasOwnProperty.call(message, "userRequest"))
                $root.query_recursive_types.UserRequest.encode(message.userRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.storyRequest != null && Object.hasOwnProperty.call(message, "storyRequest"))
                $root.query_recursive_types.StoryRequest.encode(message.storyRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.seriesRequest != null && Object.hasOwnProperty.call(message, "seriesRequest"))
                $root.query_recursive_types.SeriesRequest.encode(message.seriesRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.seriesGroupRequest != null && Object.hasOwnProperty.call(message, "seriesGroupRequest"))
                $root.query_recursive_types.SeriesGroupRequest.encode(message.seriesGroupRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.storySelectionRequest != null && Object.hasOwnProperty.call(message, "storySelectionRequest"))
                $root.query_story_selection.StorySelectionRequest.encode(message.storySelectionRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.storyAdvertisementRequest != null && Object.hasOwnProperty.call(message, "storyAdvertisementRequest"))
                $root.query_recursive_types.StoryAdvertisementRequest.encode(message.storyAdvertisementRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.commentRequest != null && Object.hasOwnProperty.call(message, "commentRequest"))
                $root.query_recursive_types.CommentRequest.encode(message.commentRequest, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.searchStoryRequest != null && Object.hasOwnProperty.call(message, "searchStoryRequest"))
                $root.query_recursive_types.SearchStoryRequest.encode(message.searchStoryRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.searchStoryFacetTagRequest != null && Object.hasOwnProperty.call(message, "searchStoryFacetTagRequest"))
                $root.query_search_types.SearchStoryFacetTagRequest.encode(message.searchStoryFacetTagRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.searchUserRequest != null && Object.hasOwnProperty.call(message, "searchUserRequest"))
                $root.query_recursive_types.SearchUserRequest.encode(message.searchUserRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.searchSeriesRequest != null && Object.hasOwnProperty.call(message, "searchSeriesRequest"))
                $root.query_recursive_types.SearchSeriesRequest.encode(message.searchSeriesRequest, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.searchSeriesGroupRequest != null && Object.hasOwnProperty.call(message, "searchSeriesGroupRequest"))
                $root.query_recursive_types.SearchSeriesGroupRequest.encode(message.searchSeriesGroupRequest, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.eventListRequest != null && Object.hasOwnProperty.call(message, "eventListRequest"))
                $root.query_recursive_types.EventListRequest.encode(message.eventListRequest, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.tagRankingRequest != null && Object.hasOwnProperty.call(message, "tagRankingRequest"))
                $root.query_tag_list.TagRankingRequest.encode(message.tagRankingRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.tagMedalRequest != null && Object.hasOwnProperty.call(message, "tagMedalRequest"))
                $root.query_recursive_types.TagMedalCursorRequest.encode(message.tagMedalRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.storyRankingRequest != null && Object.hasOwnProperty.call(message, "storyRankingRequest"))
                $root.query_story_ranking.StoryRankingRequest.encode(message.storyRankingRequest, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.searchQueryRequest != null && Object.hasOwnProperty.call(message, "searchQueryRequest"))
                $root.query_search_query.SearchQueryRequest.encode(message.searchQueryRequest, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.tagRequest != null && Object.hasOwnProperty.call(message, "tagRequest"))
                $root.query_recursive_types.TagRequest.encode(message.tagRequest, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.tagHotListRequest != null && Object.hasOwnProperty.call(message, "tagHotListRequest"))
                $root.query_tag_list.TagHotListRequest.encode(message.tagHotListRequest, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.searchSeriesRequestV2 != null && Object.hasOwnProperty.call(message, "searchSeriesRequestV2"))
                $root.query_search_v2.SearchSeriesRequestV2.encode(message.searchSeriesRequestV2, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.searchSeriesByTitleRequestV2 != null && Object.hasOwnProperty.call(message, "searchSeriesByTitleRequestV2"))
                $root.query_search_v2.SearchSeriesByTitleRequestV2.encode(message.searchSeriesByTitleRequestV2, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.searchSeriesByTagRequestV2 != null && Object.hasOwnProperty.call(message, "searchSeriesByTagRequestV2"))
                $root.query_search_v2.SearchSeriesByTagRequestV2.encode(message.searchSeriesByTagRequestV2, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.searchLatestSeriesRequestV2 != null && Object.hasOwnProperty.call(message, "searchLatestSeriesRequestV2"))
                $root.query_search_v2.SearchLatestSeriesRequestV2.encode(message.searchLatestSeriesRequestV2, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.searchCompletedOfficialSeriesRequestV2 != null && Object.hasOwnProperty.call(message, "searchCompletedOfficialSeriesRequestV2"))
                $root.query_search_v2.SearchCompletedOfficialSeriesRequestV2.encode(message.searchCompletedOfficialSeriesRequestV2, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.searchUncompletedOfficialSeriesRequestV2 != null && Object.hasOwnProperty.call(message, "searchUncompletedOfficialSeriesRequestV2"))
                $root.query_search_v2.SearchUncompletedOfficialSeriesRequestV2.encode(message.searchUncompletedOfficialSeriesRequestV2, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.searchFacetRelatedTagRequestV2 != null && Object.hasOwnProperty.call(message, "searchFacetRelatedTagRequestV2"))
                $root.query_search_v2.SearchFacetRelatedTagRequestV2.encode(message.searchFacetRelatedTagRequestV2, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.searchFacetTagRequestV2 != null && Object.hasOwnProperty.call(message, "searchFacetTagRequestV2"))
                $root.query_search_v2.SearchFacetTagRequestV2.encode(message.searchFacetTagRequestV2, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.searchLatestFacetTagRequestV2 != null && Object.hasOwnProperty.call(message, "searchLatestFacetTagRequestV2"))
                $root.query_search_v2.SearchLatestFacetTagRequestV2.encode(message.searchLatestFacetTagRequestV2, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.searchUserRequestV2 != null && Object.hasOwnProperty.call(message, "searchUserRequestV2"))
                $root.query_search_v2.SearchUserRequestV2.encode(message.searchUserRequestV2, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.searchUserByTagsRequestV2 != null && Object.hasOwnProperty.call(message, "searchUserByTagsRequestV2"))
                $root.query_search_v2.SearchUserByTagsRequestV2.encode(message.searchUserByTagsRequestV2, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.searchLatestWriterRequestV2 != null && Object.hasOwnProperty.call(message, "searchLatestWriterRequestV2"))
                $root.query_search_v2.SearchLatestWriterRequestV2.encode(message.searchLatestWriterRequestV2, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.seriesSelectionRequest != null && Object.hasOwnProperty.call(message, "seriesSelectionRequest"))
                $root.query_recursive_types.SeriesSelectionRequest.encode(message.seriesSelectionRequest, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.seriesGenreRankingRequest != null && Object.hasOwnProperty.call(message, "seriesGenreRankingRequest"))
                $root.query_series_genre_ranking.SeriesGenreRankingCursorRequest.encode(message.seriesGenreRankingRequest, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a QueryRequestItem message from the specified reader or buffer.
         * @function decode
         * @memberof query.QueryRequestItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query.QueryRequestItem} QueryRequestItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRequestItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query.QueryRequestItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.meRequest = $root.query_recursive_types.MeRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userRequest = $root.query_recursive_types.UserRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.storyRequest = $root.query_recursive_types.StoryRequest.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.seriesRequest = $root.query_recursive_types.SeriesRequest.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.seriesGroupRequest = $root.query_recursive_types.SeriesGroupRequest.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.storyAdvertisementRequest = $root.query_recursive_types.StoryAdvertisementRequest.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.commentRequest = $root.query_recursive_types.CommentRequest.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.eventListRequest = $root.query_recursive_types.EventListRequest.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.tagRankingRequest = $root.query_tag_list.TagRankingRequest.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.tagMedalRequest = $root.query_recursive_types.TagMedalCursorRequest.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.searchQueryRequest = $root.query_search_query.SearchQueryRequest.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.tagRequest = $root.query_recursive_types.TagRequest.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.tagHotListRequest = $root.query_tag_list.TagHotListRequest.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.searchSeriesRequestV2 = $root.query_search_v2.SearchSeriesRequestV2.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.searchSeriesByTitleRequestV2 = $root.query_search_v2.SearchSeriesByTitleRequestV2.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.searchSeriesByTagRequestV2 = $root.query_search_v2.SearchSeriesByTagRequestV2.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.searchLatestSeriesRequestV2 = $root.query_search_v2.SearchLatestSeriesRequestV2.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.searchCompletedOfficialSeriesRequestV2 = $root.query_search_v2.SearchCompletedOfficialSeriesRequestV2.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.searchUncompletedOfficialSeriesRequestV2 = $root.query_search_v2.SearchUncompletedOfficialSeriesRequestV2.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.searchFacetRelatedTagRequestV2 = $root.query_search_v2.SearchFacetRelatedTagRequestV2.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.searchFacetTagRequestV2 = $root.query_search_v2.SearchFacetTagRequestV2.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.searchLatestFacetTagRequestV2 = $root.query_search_v2.SearchLatestFacetTagRequestV2.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.searchUserRequestV2 = $root.query_search_v2.SearchUserRequestV2.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.searchUserByTagsRequestV2 = $root.query_search_v2.SearchUserByTagsRequestV2.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.searchLatestWriterRequestV2 = $root.query_search_v2.SearchLatestWriterRequestV2.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.seriesSelectionRequest = $root.query_recursive_types.SeriesSelectionRequest.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.seriesGenreRankingRequest = $root.query_series_genre_ranking.SeriesGenreRankingCursorRequest.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.storySelectionRequest = $root.query_story_selection.StorySelectionRequest.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.searchStoryRequest = $root.query_recursive_types.SearchStoryRequest.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.searchStoryFacetTagRequest = $root.query_search_types.SearchStoryFacetTagRequest.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.searchUserRequest = $root.query_recursive_types.SearchUserRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.searchSeriesRequest = $root.query_recursive_types.SearchSeriesRequest.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.searchSeriesGroupRequest = $root.query_recursive_types.SearchSeriesGroupRequest.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.storyRankingRequest = $root.query_story_ranking.StoryRankingRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a QueryRequestItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query.QueryRequestItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query.QueryRequestItem} QueryRequestItem
         */
        QueryRequestItem.fromObject = function fromObject(object) {
            if (object instanceof $root.query.QueryRequestItem)
                return object;
            var message = new $root.query.QueryRequestItem();
            if (object.requestId != null) {
                if (typeof object.requestId !== "object")
                    throw TypeError(".query.QueryRequestItem.requestId: object expected");
                message.requestId = $root.google.protobuf.StringValue.fromObject(object.requestId);
            }
            if (object.meRequest != null) {
                if (typeof object.meRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.meRequest: object expected");
                message.meRequest = $root.query_recursive_types.MeRequest.fromObject(object.meRequest);
            }
            if (object.userRequest != null) {
                if (typeof object.userRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.userRequest: object expected");
                message.userRequest = $root.query_recursive_types.UserRequest.fromObject(object.userRequest);
            }
            if (object.storyRequest != null) {
                if (typeof object.storyRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.storyRequest: object expected");
                message.storyRequest = $root.query_recursive_types.StoryRequest.fromObject(object.storyRequest);
            }
            if (object.seriesRequest != null) {
                if (typeof object.seriesRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.seriesRequest: object expected");
                message.seriesRequest = $root.query_recursive_types.SeriesRequest.fromObject(object.seriesRequest);
            }
            if (object.seriesGroupRequest != null) {
                if (typeof object.seriesGroupRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.seriesGroupRequest: object expected");
                message.seriesGroupRequest = $root.query_recursive_types.SeriesGroupRequest.fromObject(object.seriesGroupRequest);
            }
            if (object.storyAdvertisementRequest != null) {
                if (typeof object.storyAdvertisementRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.storyAdvertisementRequest: object expected");
                message.storyAdvertisementRequest = $root.query_recursive_types.StoryAdvertisementRequest.fromObject(object.storyAdvertisementRequest);
            }
            if (object.commentRequest != null) {
                if (typeof object.commentRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.commentRequest: object expected");
                message.commentRequest = $root.query_recursive_types.CommentRequest.fromObject(object.commentRequest);
            }
            if (object.eventListRequest != null) {
                if (typeof object.eventListRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.eventListRequest: object expected");
                message.eventListRequest = $root.query_recursive_types.EventListRequest.fromObject(object.eventListRequest);
            }
            if (object.tagRankingRequest != null) {
                if (typeof object.tagRankingRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.tagRankingRequest: object expected");
                message.tagRankingRequest = $root.query_tag_list.TagRankingRequest.fromObject(object.tagRankingRequest);
            }
            if (object.tagMedalRequest != null) {
                if (typeof object.tagMedalRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.tagMedalRequest: object expected");
                message.tagMedalRequest = $root.query_recursive_types.TagMedalCursorRequest.fromObject(object.tagMedalRequest);
            }
            if (object.searchQueryRequest != null) {
                if (typeof object.searchQueryRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.searchQueryRequest: object expected");
                message.searchQueryRequest = $root.query_search_query.SearchQueryRequest.fromObject(object.searchQueryRequest);
            }
            if (object.tagRequest != null) {
                if (typeof object.tagRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.tagRequest: object expected");
                message.tagRequest = $root.query_recursive_types.TagRequest.fromObject(object.tagRequest);
            }
            if (object.tagHotListRequest != null) {
                if (typeof object.tagHotListRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.tagHotListRequest: object expected");
                message.tagHotListRequest = $root.query_tag_list.TagHotListRequest.fromObject(object.tagHotListRequest);
            }
            if (object.searchSeriesRequestV2 != null) {
                if (typeof object.searchSeriesRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchSeriesRequestV2: object expected");
                message.searchSeriesRequestV2 = $root.query_search_v2.SearchSeriesRequestV2.fromObject(object.searchSeriesRequestV2);
            }
            if (object.searchSeriesByTitleRequestV2 != null) {
                if (typeof object.searchSeriesByTitleRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchSeriesByTitleRequestV2: object expected");
                message.searchSeriesByTitleRequestV2 = $root.query_search_v2.SearchSeriesByTitleRequestV2.fromObject(object.searchSeriesByTitleRequestV2);
            }
            if (object.searchSeriesByTagRequestV2 != null) {
                if (typeof object.searchSeriesByTagRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchSeriesByTagRequestV2: object expected");
                message.searchSeriesByTagRequestV2 = $root.query_search_v2.SearchSeriesByTagRequestV2.fromObject(object.searchSeriesByTagRequestV2);
            }
            if (object.searchLatestSeriesRequestV2 != null) {
                if (typeof object.searchLatestSeriesRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchLatestSeriesRequestV2: object expected");
                message.searchLatestSeriesRequestV2 = $root.query_search_v2.SearchLatestSeriesRequestV2.fromObject(object.searchLatestSeriesRequestV2);
            }
            if (object.searchCompletedOfficialSeriesRequestV2 != null) {
                if (typeof object.searchCompletedOfficialSeriesRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchCompletedOfficialSeriesRequestV2: object expected");
                message.searchCompletedOfficialSeriesRequestV2 = $root.query_search_v2.SearchCompletedOfficialSeriesRequestV2.fromObject(object.searchCompletedOfficialSeriesRequestV2);
            }
            if (object.searchUncompletedOfficialSeriesRequestV2 != null) {
                if (typeof object.searchUncompletedOfficialSeriesRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchUncompletedOfficialSeriesRequestV2: object expected");
                message.searchUncompletedOfficialSeriesRequestV2 = $root.query_search_v2.SearchUncompletedOfficialSeriesRequestV2.fromObject(object.searchUncompletedOfficialSeriesRequestV2);
            }
            if (object.searchFacetRelatedTagRequestV2 != null) {
                if (typeof object.searchFacetRelatedTagRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchFacetRelatedTagRequestV2: object expected");
                message.searchFacetRelatedTagRequestV2 = $root.query_search_v2.SearchFacetRelatedTagRequestV2.fromObject(object.searchFacetRelatedTagRequestV2);
            }
            if (object.searchFacetTagRequestV2 != null) {
                if (typeof object.searchFacetTagRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchFacetTagRequestV2: object expected");
                message.searchFacetTagRequestV2 = $root.query_search_v2.SearchFacetTagRequestV2.fromObject(object.searchFacetTagRequestV2);
            }
            if (object.searchLatestFacetTagRequestV2 != null) {
                if (typeof object.searchLatestFacetTagRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchLatestFacetTagRequestV2: object expected");
                message.searchLatestFacetTagRequestV2 = $root.query_search_v2.SearchLatestFacetTagRequestV2.fromObject(object.searchLatestFacetTagRequestV2);
            }
            if (object.searchUserRequestV2 != null) {
                if (typeof object.searchUserRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchUserRequestV2: object expected");
                message.searchUserRequestV2 = $root.query_search_v2.SearchUserRequestV2.fromObject(object.searchUserRequestV2);
            }
            if (object.searchUserByTagsRequestV2 != null) {
                if (typeof object.searchUserByTagsRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchUserByTagsRequestV2: object expected");
                message.searchUserByTagsRequestV2 = $root.query_search_v2.SearchUserByTagsRequestV2.fromObject(object.searchUserByTagsRequestV2);
            }
            if (object.searchLatestWriterRequestV2 != null) {
                if (typeof object.searchLatestWriterRequestV2 !== "object")
                    throw TypeError(".query.QueryRequestItem.searchLatestWriterRequestV2: object expected");
                message.searchLatestWriterRequestV2 = $root.query_search_v2.SearchLatestWriterRequestV2.fromObject(object.searchLatestWriterRequestV2);
            }
            if (object.seriesSelectionRequest != null) {
                if (typeof object.seriesSelectionRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.seriesSelectionRequest: object expected");
                message.seriesSelectionRequest = $root.query_recursive_types.SeriesSelectionRequest.fromObject(object.seriesSelectionRequest);
            }
            if (object.seriesGenreRankingRequest != null) {
                if (typeof object.seriesGenreRankingRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.seriesGenreRankingRequest: object expected");
                message.seriesGenreRankingRequest = $root.query_series_genre_ranking.SeriesGenreRankingCursorRequest.fromObject(object.seriesGenreRankingRequest);
            }
            if (object.storySelectionRequest != null) {
                if (typeof object.storySelectionRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.storySelectionRequest: object expected");
                message.storySelectionRequest = $root.query_story_selection.StorySelectionRequest.fromObject(object.storySelectionRequest);
            }
            if (object.searchStoryRequest != null) {
                if (typeof object.searchStoryRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.searchStoryRequest: object expected");
                message.searchStoryRequest = $root.query_recursive_types.SearchStoryRequest.fromObject(object.searchStoryRequest);
            }
            if (object.searchStoryFacetTagRequest != null) {
                if (typeof object.searchStoryFacetTagRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.searchStoryFacetTagRequest: object expected");
                message.searchStoryFacetTagRequest = $root.query_search_types.SearchStoryFacetTagRequest.fromObject(object.searchStoryFacetTagRequest);
            }
            if (object.searchUserRequest != null) {
                if (typeof object.searchUserRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.searchUserRequest: object expected");
                message.searchUserRequest = $root.query_recursive_types.SearchUserRequest.fromObject(object.searchUserRequest);
            }
            if (object.searchSeriesRequest != null) {
                if (typeof object.searchSeriesRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.searchSeriesRequest: object expected");
                message.searchSeriesRequest = $root.query_recursive_types.SearchSeriesRequest.fromObject(object.searchSeriesRequest);
            }
            if (object.searchSeriesGroupRequest != null) {
                if (typeof object.searchSeriesGroupRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.searchSeriesGroupRequest: object expected");
                message.searchSeriesGroupRequest = $root.query_recursive_types.SearchSeriesGroupRequest.fromObject(object.searchSeriesGroupRequest);
            }
            if (object.storyRankingRequest != null) {
                if (typeof object.storyRankingRequest !== "object")
                    throw TypeError(".query.QueryRequestItem.storyRankingRequest: object expected");
                message.storyRankingRequest = $root.query_story_ranking.StoryRankingRequest.fromObject(object.storyRankingRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a QueryRequestItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query.QueryRequestItem
         * @static
         * @param {query.QueryRequestItem} message QueryRequestItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryRequestItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requestId = null;
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                object.requestId = $root.google.protobuf.StringValue.toObject(message.requestId, options);
            if (message.meRequest != null && message.hasOwnProperty("meRequest")) {
                object.meRequest = $root.query_recursive_types.MeRequest.toObject(message.meRequest, options);
                if (options.oneofs)
                    object.request = "meRequest";
            }
            if (message.userRequest != null && message.hasOwnProperty("userRequest")) {
                object.userRequest = $root.query_recursive_types.UserRequest.toObject(message.userRequest, options);
                if (options.oneofs)
                    object.request = "userRequest";
            }
            if (message.storyRequest != null && message.hasOwnProperty("storyRequest")) {
                object.storyRequest = $root.query_recursive_types.StoryRequest.toObject(message.storyRequest, options);
                if (options.oneofs)
                    object.request = "storyRequest";
            }
            if (message.seriesRequest != null && message.hasOwnProperty("seriesRequest")) {
                object.seriesRequest = $root.query_recursive_types.SeriesRequest.toObject(message.seriesRequest, options);
                if (options.oneofs)
                    object.request = "seriesRequest";
            }
            if (message.seriesGroupRequest != null && message.hasOwnProperty("seriesGroupRequest")) {
                object.seriesGroupRequest = $root.query_recursive_types.SeriesGroupRequest.toObject(message.seriesGroupRequest, options);
                if (options.oneofs)
                    object.request = "seriesGroupRequest";
            }
            if (message.storySelectionRequest != null && message.hasOwnProperty("storySelectionRequest")) {
                object.storySelectionRequest = $root.query_story_selection.StorySelectionRequest.toObject(message.storySelectionRequest, options);
                if (options.oneofs)
                    object.request = "storySelectionRequest";
            }
            if (message.storyAdvertisementRequest != null && message.hasOwnProperty("storyAdvertisementRequest")) {
                object.storyAdvertisementRequest = $root.query_recursive_types.StoryAdvertisementRequest.toObject(message.storyAdvertisementRequest, options);
                if (options.oneofs)
                    object.request = "storyAdvertisementRequest";
            }
            if (message.commentRequest != null && message.hasOwnProperty("commentRequest")) {
                object.commentRequest = $root.query_recursive_types.CommentRequest.toObject(message.commentRequest, options);
                if (options.oneofs)
                    object.request = "commentRequest";
            }
            if (message.searchStoryRequest != null && message.hasOwnProperty("searchStoryRequest")) {
                object.searchStoryRequest = $root.query_recursive_types.SearchStoryRequest.toObject(message.searchStoryRequest, options);
                if (options.oneofs)
                    object.request = "searchStoryRequest";
            }
            if (message.searchStoryFacetTagRequest != null && message.hasOwnProperty("searchStoryFacetTagRequest")) {
                object.searchStoryFacetTagRequest = $root.query_search_types.SearchStoryFacetTagRequest.toObject(message.searchStoryFacetTagRequest, options);
                if (options.oneofs)
                    object.request = "searchStoryFacetTagRequest";
            }
            if (message.searchUserRequest != null && message.hasOwnProperty("searchUserRequest")) {
                object.searchUserRequest = $root.query_recursive_types.SearchUserRequest.toObject(message.searchUserRequest, options);
                if (options.oneofs)
                    object.request = "searchUserRequest";
            }
            if (message.searchSeriesRequest != null && message.hasOwnProperty("searchSeriesRequest")) {
                object.searchSeriesRequest = $root.query_recursive_types.SearchSeriesRequest.toObject(message.searchSeriesRequest, options);
                if (options.oneofs)
                    object.request = "searchSeriesRequest";
            }
            if (message.searchSeriesGroupRequest != null && message.hasOwnProperty("searchSeriesGroupRequest")) {
                object.searchSeriesGroupRequest = $root.query_recursive_types.SearchSeriesGroupRequest.toObject(message.searchSeriesGroupRequest, options);
                if (options.oneofs)
                    object.request = "searchSeriesGroupRequest";
            }
            if (message.eventListRequest != null && message.hasOwnProperty("eventListRequest")) {
                object.eventListRequest = $root.query_recursive_types.EventListRequest.toObject(message.eventListRequest, options);
                if (options.oneofs)
                    object.request = "eventListRequest";
            }
            if (message.tagRankingRequest != null && message.hasOwnProperty("tagRankingRequest")) {
                object.tagRankingRequest = $root.query_tag_list.TagRankingRequest.toObject(message.tagRankingRequest, options);
                if (options.oneofs)
                    object.request = "tagRankingRequest";
            }
            if (message.tagMedalRequest != null && message.hasOwnProperty("tagMedalRequest")) {
                object.tagMedalRequest = $root.query_recursive_types.TagMedalCursorRequest.toObject(message.tagMedalRequest, options);
                if (options.oneofs)
                    object.request = "tagMedalRequest";
            }
            if (message.storyRankingRequest != null && message.hasOwnProperty("storyRankingRequest")) {
                object.storyRankingRequest = $root.query_story_ranking.StoryRankingRequest.toObject(message.storyRankingRequest, options);
                if (options.oneofs)
                    object.request = "storyRankingRequest";
            }
            if (message.searchQueryRequest != null && message.hasOwnProperty("searchQueryRequest")) {
                object.searchQueryRequest = $root.query_search_query.SearchQueryRequest.toObject(message.searchQueryRequest, options);
                if (options.oneofs)
                    object.request = "searchQueryRequest";
            }
            if (message.tagRequest != null && message.hasOwnProperty("tagRequest")) {
                object.tagRequest = $root.query_recursive_types.TagRequest.toObject(message.tagRequest, options);
                if (options.oneofs)
                    object.request = "tagRequest";
            }
            if (message.tagHotListRequest != null && message.hasOwnProperty("tagHotListRequest")) {
                object.tagHotListRequest = $root.query_tag_list.TagHotListRequest.toObject(message.tagHotListRequest, options);
                if (options.oneofs)
                    object.request = "tagHotListRequest";
            }
            if (message.searchSeriesRequestV2 != null && message.hasOwnProperty("searchSeriesRequestV2")) {
                object.searchSeriesRequestV2 = $root.query_search_v2.SearchSeriesRequestV2.toObject(message.searchSeriesRequestV2, options);
                if (options.oneofs)
                    object.request = "searchSeriesRequestV2";
            }
            if (message.searchSeriesByTitleRequestV2 != null && message.hasOwnProperty("searchSeriesByTitleRequestV2")) {
                object.searchSeriesByTitleRequestV2 = $root.query_search_v2.SearchSeriesByTitleRequestV2.toObject(message.searchSeriesByTitleRequestV2, options);
                if (options.oneofs)
                    object.request = "searchSeriesByTitleRequestV2";
            }
            if (message.searchSeriesByTagRequestV2 != null && message.hasOwnProperty("searchSeriesByTagRequestV2")) {
                object.searchSeriesByTagRequestV2 = $root.query_search_v2.SearchSeriesByTagRequestV2.toObject(message.searchSeriesByTagRequestV2, options);
                if (options.oneofs)
                    object.request = "searchSeriesByTagRequestV2";
            }
            if (message.searchLatestSeriesRequestV2 != null && message.hasOwnProperty("searchLatestSeriesRequestV2")) {
                object.searchLatestSeriesRequestV2 = $root.query_search_v2.SearchLatestSeriesRequestV2.toObject(message.searchLatestSeriesRequestV2, options);
                if (options.oneofs)
                    object.request = "searchLatestSeriesRequestV2";
            }
            if (message.searchCompletedOfficialSeriesRequestV2 != null && message.hasOwnProperty("searchCompletedOfficialSeriesRequestV2")) {
                object.searchCompletedOfficialSeriesRequestV2 = $root.query_search_v2.SearchCompletedOfficialSeriesRequestV2.toObject(message.searchCompletedOfficialSeriesRequestV2, options);
                if (options.oneofs)
                    object.request = "searchCompletedOfficialSeriesRequestV2";
            }
            if (message.searchUncompletedOfficialSeriesRequestV2 != null && message.hasOwnProperty("searchUncompletedOfficialSeriesRequestV2")) {
                object.searchUncompletedOfficialSeriesRequestV2 = $root.query_search_v2.SearchUncompletedOfficialSeriesRequestV2.toObject(message.searchUncompletedOfficialSeriesRequestV2, options);
                if (options.oneofs)
                    object.request = "searchUncompletedOfficialSeriesRequestV2";
            }
            if (message.searchFacetRelatedTagRequestV2 != null && message.hasOwnProperty("searchFacetRelatedTagRequestV2")) {
                object.searchFacetRelatedTagRequestV2 = $root.query_search_v2.SearchFacetRelatedTagRequestV2.toObject(message.searchFacetRelatedTagRequestV2, options);
                if (options.oneofs)
                    object.request = "searchFacetRelatedTagRequestV2";
            }
            if (message.searchFacetTagRequestV2 != null && message.hasOwnProperty("searchFacetTagRequestV2")) {
                object.searchFacetTagRequestV2 = $root.query_search_v2.SearchFacetTagRequestV2.toObject(message.searchFacetTagRequestV2, options);
                if (options.oneofs)
                    object.request = "searchFacetTagRequestV2";
            }
            if (message.searchLatestFacetTagRequestV2 != null && message.hasOwnProperty("searchLatestFacetTagRequestV2")) {
                object.searchLatestFacetTagRequestV2 = $root.query_search_v2.SearchLatestFacetTagRequestV2.toObject(message.searchLatestFacetTagRequestV2, options);
                if (options.oneofs)
                    object.request = "searchLatestFacetTagRequestV2";
            }
            if (message.searchUserRequestV2 != null && message.hasOwnProperty("searchUserRequestV2")) {
                object.searchUserRequestV2 = $root.query_search_v2.SearchUserRequestV2.toObject(message.searchUserRequestV2, options);
                if (options.oneofs)
                    object.request = "searchUserRequestV2";
            }
            if (message.searchUserByTagsRequestV2 != null && message.hasOwnProperty("searchUserByTagsRequestV2")) {
                object.searchUserByTagsRequestV2 = $root.query_search_v2.SearchUserByTagsRequestV2.toObject(message.searchUserByTagsRequestV2, options);
                if (options.oneofs)
                    object.request = "searchUserByTagsRequestV2";
            }
            if (message.searchLatestWriterRequestV2 != null && message.hasOwnProperty("searchLatestWriterRequestV2")) {
                object.searchLatestWriterRequestV2 = $root.query_search_v2.SearchLatestWriterRequestV2.toObject(message.searchLatestWriterRequestV2, options);
                if (options.oneofs)
                    object.request = "searchLatestWriterRequestV2";
            }
            if (message.seriesSelectionRequest != null && message.hasOwnProperty("seriesSelectionRequest")) {
                object.seriesSelectionRequest = $root.query_recursive_types.SeriesSelectionRequest.toObject(message.seriesSelectionRequest, options);
                if (options.oneofs)
                    object.request = "seriesSelectionRequest";
            }
            if (message.seriesGenreRankingRequest != null && message.hasOwnProperty("seriesGenreRankingRequest")) {
                object.seriesGenreRankingRequest = $root.query_series_genre_ranking.SeriesGenreRankingCursorRequest.toObject(message.seriesGenreRankingRequest, options);
                if (options.oneofs)
                    object.request = "seriesGenreRankingRequest";
            }
            return object;
        };

        /**
         * Converts this QueryRequestItem to JSON.
         * @function toJSON
         * @memberof query.QueryRequestItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryRequestItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueryRequestItem
         * @function getTypeUrl
         * @memberof query.QueryRequestItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueryRequestItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.QueryRequestItem";
        };

        return QueryRequestItem;
    })();

    query.QueryResponse = (function() {

        /**
         * Properties of a QueryResponse.
         * @memberof query
         * @interface IQueryResponse
         * @property {Array.<query.IQueryResponseItem>|null} [responseList] QueryResponse responseList
         */

        /**
         * Constructs a new QueryResponse.
         * @memberof query
         * @classdesc Represents a QueryResponse.
         * @implements IQueryResponse
         * @constructor
         * @param {query.IQueryResponse=} [properties] Properties to set
         */
        function QueryResponse(properties) {
            this.responseList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryResponse responseList.
         * @member {Array.<query.IQueryResponseItem>} responseList
         * @memberof query.QueryResponse
         * @instance
         */
        QueryResponse.prototype.responseList = $util.emptyArray;

        /**
         * Creates a new QueryResponse instance using the specified properties.
         * @function create
         * @memberof query.QueryResponse
         * @static
         * @param {query.IQueryResponse=} [properties] Properties to set
         * @returns {query.QueryResponse} QueryResponse instance
         */
        QueryResponse.create = function create(properties) {
            return new QueryResponse(properties);
        };

        /**
         * Encodes the specified QueryResponse message. Does not implicitly {@link query.QueryResponse.verify|verify} messages.
         * @function encode
         * @memberof query.QueryResponse
         * @static
         * @param {query.IQueryResponse} message QueryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.responseList != null && message.responseList.length)
                for (var i = 0; i < message.responseList.length; ++i)
                    $root.query.QueryResponseItem.encode(message.responseList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a QueryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query.QueryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query.QueryResponse} QueryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query.QueryResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.responseList && message.responseList.length))
                        message.responseList = [];
                    message.responseList.push($root.query.QueryResponseItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a QueryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query.QueryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query.QueryResponse} QueryResponse
         */
        QueryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query.QueryResponse)
                return object;
            var message = new $root.query.QueryResponse();
            if (object.responseList) {
                if (!Array.isArray(object.responseList))
                    throw TypeError(".query.QueryResponse.responseList: array expected");
                message.responseList = [];
                for (var i = 0; i < object.responseList.length; ++i) {
                    if (typeof object.responseList[i] !== "object")
                        throw TypeError(".query.QueryResponse.responseList: object expected");
                    message.responseList[i] = $root.query.QueryResponseItem.fromObject(object.responseList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a QueryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query.QueryResponse
         * @static
         * @param {query.QueryResponse} message QueryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.responseList = [];
            if (message.responseList && message.responseList.length) {
                object.responseList = [];
                for (var j = 0; j < message.responseList.length; ++j)
                    object.responseList[j] = $root.query.QueryResponseItem.toObject(message.responseList[j], options);
            }
            return object;
        };

        /**
         * Converts this QueryResponse to JSON.
         * @function toJSON
         * @memberof query.QueryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueryResponse
         * @function getTypeUrl
         * @memberof query.QueryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.QueryResponse";
        };

        return QueryResponse;
    })();

    query.QueryResponseItem = (function() {

        /**
         * Properties of a QueryResponseItem.
         * @memberof query
         * @interface IQueryResponseItem
         * @property {google.protobuf.IStringValue|null} [requestId] QueryResponseItem requestId
         * @property {query.IErrorResponse|null} [error] QueryResponseItem error
         * @property {query_recursive_types.IMeResponse|null} [me] QueryResponseItem me
         * @property {query_recursive_types.IUserResponse|null} [user] QueryResponseItem user
         * @property {query_recursive_types.IStoryResponse|null} [story] QueryResponseItem story
         * @property {query_recursive_types.ISeriesResponse|null} [series] QueryResponseItem series
         * @property {query_recursive_types.ISeriesGroupResponse|null} [seriesGroup] QueryResponseItem seriesGroup
         * @property {query_story_selection.IStorySelectionResponse|null} [storySelection] QueryResponseItem storySelection
         * @property {query_recursive_types.IStoryAdvertisementResponse|null} [storyAdvertisement] QueryResponseItem storyAdvertisement
         * @property {query_recursive_types.ICommentResponse|null} [comment] QueryResponseItem comment
         * @property {query_recursive_types.ISearchStoryResponse|null} [searchStory] QueryResponseItem searchStory
         * @property {query_search_types.ISearchStoryFacetResponse|null} [searchStoryFacetTag] QueryResponseItem searchStoryFacetTag
         * @property {query_recursive_types.ISearchUserResponse|null} [searchUser] QueryResponseItem searchUser
         * @property {query_recursive_types.ISearchSeriesResponse|null} [searchSeries] QueryResponseItem searchSeries
         * @property {query_recursive_types.ISearchSeriesGroupResponse|null} [searchSeriesGroup] QueryResponseItem searchSeriesGroup
         * @property {query_recursive_types.IEventListResponse|null} [eventList] QueryResponseItem eventList
         * @property {query_tag_list.ITagRankingResponse|null} [tagRanking] QueryResponseItem tagRanking
         * @property {query_recursive_types.ITagMedalCursorResponse|null} [tagMedal] QueryResponseItem tagMedal
         * @property {query_story_ranking.IStoryRankingResponse|null} [storyRanking] QueryResponseItem storyRanking
         * @property {query_search_query.ISearchQueryResponse|null} [searchQuery] QueryResponseItem searchQuery
         * @property {query_recursive_types.ITagResponse|null} [tag] QueryResponseItem tag
         * @property {query_tag_list.ITagHotListResponse|null} [tagHotList] QueryResponseItem tagHotList
         * @property {query_search_v2.ISearchSeriesResponseV2|null} [searchSeriesV2] QueryResponseItem searchSeriesV2
         * @property {query_search_v2.ISearchFacetTagResponseV2|null} [searchFacetTagV2] QueryResponseItem searchFacetTagV2
         * @property {query_search_v2.ISearchUserResponseV2|null} [searchUserV2] QueryResponseItem searchUserV2
         * @property {query_recursive_types.ISeriesSelectionResponse|null} [seriesSelection] QueryResponseItem seriesSelection
         * @property {query_series_genre_ranking.ISeriesGenreRankingCursorResponse|null} [seriesGenreRanking] QueryResponseItem seriesGenreRanking
         */

        /**
         * Constructs a new QueryResponseItem.
         * @memberof query
         * @classdesc Represents a QueryResponseItem.
         * @implements IQueryResponseItem
         * @constructor
         * @param {query.IQueryResponseItem=} [properties] Properties to set
         */
        function QueryResponseItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryResponseItem requestId.
         * @member {google.protobuf.IStringValue|null|undefined} requestId
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.requestId = null;

        /**
         * QueryResponseItem error.
         * @member {query.IErrorResponse|null|undefined} error
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.error = null;

        /**
         * QueryResponseItem me.
         * @member {query_recursive_types.IMeResponse|null|undefined} me
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.me = null;

        /**
         * QueryResponseItem user.
         * @member {query_recursive_types.IUserResponse|null|undefined} user
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.user = null;

        /**
         * QueryResponseItem story.
         * @member {query_recursive_types.IStoryResponse|null|undefined} story
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.story = null;

        /**
         * QueryResponseItem series.
         * @member {query_recursive_types.ISeriesResponse|null|undefined} series
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.series = null;

        /**
         * QueryResponseItem seriesGroup.
         * @member {query_recursive_types.ISeriesGroupResponse|null|undefined} seriesGroup
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.seriesGroup = null;

        /**
         * QueryResponseItem storySelection.
         * @member {query_story_selection.IStorySelectionResponse|null|undefined} storySelection
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.storySelection = null;

        /**
         * QueryResponseItem storyAdvertisement.
         * @member {query_recursive_types.IStoryAdvertisementResponse|null|undefined} storyAdvertisement
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.storyAdvertisement = null;

        /**
         * QueryResponseItem comment.
         * @member {query_recursive_types.ICommentResponse|null|undefined} comment
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.comment = null;

        /**
         * QueryResponseItem searchStory.
         * @member {query_recursive_types.ISearchStoryResponse|null|undefined} searchStory
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchStory = null;

        /**
         * QueryResponseItem searchStoryFacetTag.
         * @member {query_search_types.ISearchStoryFacetResponse|null|undefined} searchStoryFacetTag
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchStoryFacetTag = null;

        /**
         * QueryResponseItem searchUser.
         * @member {query_recursive_types.ISearchUserResponse|null|undefined} searchUser
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchUser = null;

        /**
         * QueryResponseItem searchSeries.
         * @member {query_recursive_types.ISearchSeriesResponse|null|undefined} searchSeries
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchSeries = null;

        /**
         * QueryResponseItem searchSeriesGroup.
         * @member {query_recursive_types.ISearchSeriesGroupResponse|null|undefined} searchSeriesGroup
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchSeriesGroup = null;

        /**
         * QueryResponseItem eventList.
         * @member {query_recursive_types.IEventListResponse|null|undefined} eventList
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.eventList = null;

        /**
         * QueryResponseItem tagRanking.
         * @member {query_tag_list.ITagRankingResponse|null|undefined} tagRanking
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.tagRanking = null;

        /**
         * QueryResponseItem tagMedal.
         * @member {query_recursive_types.ITagMedalCursorResponse|null|undefined} tagMedal
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.tagMedal = null;

        /**
         * QueryResponseItem storyRanking.
         * @member {query_story_ranking.IStoryRankingResponse|null|undefined} storyRanking
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.storyRanking = null;

        /**
         * QueryResponseItem searchQuery.
         * @member {query_search_query.ISearchQueryResponse|null|undefined} searchQuery
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchQuery = null;

        /**
         * QueryResponseItem tag.
         * @member {query_recursive_types.ITagResponse|null|undefined} tag
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.tag = null;

        /**
         * QueryResponseItem tagHotList.
         * @member {query_tag_list.ITagHotListResponse|null|undefined} tagHotList
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.tagHotList = null;

        /**
         * QueryResponseItem searchSeriesV2.
         * @member {query_search_v2.ISearchSeriesResponseV2|null|undefined} searchSeriesV2
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchSeriesV2 = null;

        /**
         * QueryResponseItem searchFacetTagV2.
         * @member {query_search_v2.ISearchFacetTagResponseV2|null|undefined} searchFacetTagV2
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchFacetTagV2 = null;

        /**
         * QueryResponseItem searchUserV2.
         * @member {query_search_v2.ISearchUserResponseV2|null|undefined} searchUserV2
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.searchUserV2 = null;

        /**
         * QueryResponseItem seriesSelection.
         * @member {query_recursive_types.ISeriesSelectionResponse|null|undefined} seriesSelection
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.seriesSelection = null;

        /**
         * QueryResponseItem seriesGenreRanking.
         * @member {query_series_genre_ranking.ISeriesGenreRankingCursorResponse|null|undefined} seriesGenreRanking
         * @memberof query.QueryResponseItem
         * @instance
         */
        QueryResponseItem.prototype.seriesGenreRanking = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QueryResponseItem response.
         * @member {"error"|"me"|"user"|"story"|"series"|"seriesGroup"|"storySelection"|"storyAdvertisement"|"comment"|"searchStory"|"searchStoryFacetTag"|"searchUser"|"searchSeries"|"searchSeriesGroup"|"eventList"|"tagRanking"|"tagMedal"|"storyRanking"|"searchQuery"|"tag"|"tagHotList"|"searchSeriesV2"|"searchFacetTagV2"|"searchUserV2"|"seriesSelection"|"seriesGenreRanking"|undefined} response
         * @memberof query.QueryResponseItem
         * @instance
         */
        Object.defineProperty(QueryResponseItem.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["error", "me", "user", "story", "series", "seriesGroup", "storySelection", "storyAdvertisement", "comment", "searchStory", "searchStoryFacetTag", "searchUser", "searchSeries", "searchSeriesGroup", "eventList", "tagRanking", "tagMedal", "storyRanking", "searchQuery", "tag", "tagHotList", "searchSeriesV2", "searchFacetTagV2", "searchUserV2", "seriesSelection", "seriesGenreRanking"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new QueryResponseItem instance using the specified properties.
         * @function create
         * @memberof query.QueryResponseItem
         * @static
         * @param {query.IQueryResponseItem=} [properties] Properties to set
         * @returns {query.QueryResponseItem} QueryResponseItem instance
         */
        QueryResponseItem.create = function create(properties) {
            return new QueryResponseItem(properties);
        };

        /**
         * Encodes the specified QueryResponseItem message. Does not implicitly {@link query.QueryResponseItem.verify|verify} messages.
         * @function encode
         * @memberof query.QueryResponseItem
         * @static
         * @param {query.IQueryResponseItem} message QueryResponseItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryResponseItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                $root.google.protobuf.StringValue.encode(message.requestId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.query.ErrorResponse.encode(message.error, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.me != null && Object.hasOwnProperty.call(message, "me"))
                $root.query_recursive_types.MeResponse.encode(message.me, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.query_recursive_types.UserResponse.encode(message.user, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.query_recursive_types.StoryResponse.encode(message.story, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.query_recursive_types.SeriesResponse.encode(message.series, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.seriesGroup != null && Object.hasOwnProperty.call(message, "seriesGroup"))
                $root.query_recursive_types.SeriesGroupResponse.encode(message.seriesGroup, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.storySelection != null && Object.hasOwnProperty.call(message, "storySelection"))
                $root.query_story_selection.StorySelectionResponse.encode(message.storySelection, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.storyAdvertisement != null && Object.hasOwnProperty.call(message, "storyAdvertisement"))
                $root.query_recursive_types.StoryAdvertisementResponse.encode(message.storyAdvertisement, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                $root.query_recursive_types.CommentResponse.encode(message.comment, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.searchStory != null && Object.hasOwnProperty.call(message, "searchStory"))
                $root.query_recursive_types.SearchStoryResponse.encode(message.searchStory, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.searchStoryFacetTag != null && Object.hasOwnProperty.call(message, "searchStoryFacetTag"))
                $root.query_search_types.SearchStoryFacetResponse.encode(message.searchStoryFacetTag, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.searchUser != null && Object.hasOwnProperty.call(message, "searchUser"))
                $root.query_recursive_types.SearchUserResponse.encode(message.searchUser, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.searchSeries != null && Object.hasOwnProperty.call(message, "searchSeries"))
                $root.query_recursive_types.SearchSeriesResponse.encode(message.searchSeries, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.searchSeriesGroup != null && Object.hasOwnProperty.call(message, "searchSeriesGroup"))
                $root.query_recursive_types.SearchSeriesGroupResponse.encode(message.searchSeriesGroup, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.eventList != null && Object.hasOwnProperty.call(message, "eventList"))
                $root.query_recursive_types.EventListResponse.encode(message.eventList, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.tagRanking != null && Object.hasOwnProperty.call(message, "tagRanking"))
                $root.query_tag_list.TagRankingResponse.encode(message.tagRanking, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.tagMedal != null && Object.hasOwnProperty.call(message, "tagMedal"))
                $root.query_recursive_types.TagMedalCursorResponse.encode(message.tagMedal, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.storyRanking != null && Object.hasOwnProperty.call(message, "storyRanking"))
                $root.query_story_ranking.StoryRankingResponse.encode(message.storyRanking, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.searchQuery != null && Object.hasOwnProperty.call(message, "searchQuery"))
                $root.query_search_query.SearchQueryResponse.encode(message.searchQuery, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.query_recursive_types.TagResponse.encode(message.tag, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.tagHotList != null && Object.hasOwnProperty.call(message, "tagHotList"))
                $root.query_tag_list.TagHotListResponse.encode(message.tagHotList, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.searchSeriesV2 != null && Object.hasOwnProperty.call(message, "searchSeriesV2"))
                $root.query_search_v2.SearchSeriesResponseV2.encode(message.searchSeriesV2, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.searchFacetTagV2 != null && Object.hasOwnProperty.call(message, "searchFacetTagV2"))
                $root.query_search_v2.SearchFacetTagResponseV2.encode(message.searchFacetTagV2, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.searchUserV2 != null && Object.hasOwnProperty.call(message, "searchUserV2"))
                $root.query_search_v2.SearchUserResponseV2.encode(message.searchUserV2, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.seriesSelection != null && Object.hasOwnProperty.call(message, "seriesSelection"))
                $root.query_recursive_types.SeriesSelectionResponse.encode(message.seriesSelection, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.seriesGenreRanking != null && Object.hasOwnProperty.call(message, "seriesGenreRanking"))
                $root.query_series_genre_ranking.SeriesGenreRankingCursorResponse.encode(message.seriesGenreRanking, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a QueryResponseItem message from the specified reader or buffer.
         * @function decode
         * @memberof query.QueryResponseItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query.QueryResponseItem} QueryResponseItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryResponseItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query.QueryResponseItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.error = $root.query.ErrorResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.me = $root.query_recursive_types.MeResponse.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.user = $root.query_recursive_types.UserResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.story = $root.query_recursive_types.StoryResponse.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.series = $root.query_recursive_types.SeriesResponse.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.seriesGroup = $root.query_recursive_types.SeriesGroupResponse.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.storySelection = $root.query_story_selection.StorySelectionResponse.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.storyAdvertisement = $root.query_recursive_types.StoryAdvertisementResponse.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.comment = $root.query_recursive_types.CommentResponse.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.searchStory = $root.query_recursive_types.SearchStoryResponse.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.searchStoryFacetTag = $root.query_search_types.SearchStoryFacetResponse.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.searchUser = $root.query_recursive_types.SearchUserResponse.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.searchSeries = $root.query_recursive_types.SearchSeriesResponse.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.searchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupResponse.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.eventList = $root.query_recursive_types.EventListResponse.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.tagRanking = $root.query_tag_list.TagRankingResponse.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.tagMedal = $root.query_recursive_types.TagMedalCursorResponse.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.storyRanking = $root.query_story_ranking.StoryRankingResponse.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.searchQuery = $root.query_search_query.SearchQueryResponse.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.tag = $root.query_recursive_types.TagResponse.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.tagHotList = $root.query_tag_list.TagHotListResponse.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.searchSeriesV2 = $root.query_search_v2.SearchSeriesResponseV2.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.searchFacetTagV2 = $root.query_search_v2.SearchFacetTagResponseV2.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.searchUserV2 = $root.query_search_v2.SearchUserResponseV2.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.seriesSelection = $root.query_recursive_types.SeriesSelectionResponse.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.seriesGenreRanking = $root.query_series_genre_ranking.SeriesGenreRankingCursorResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a QueryResponseItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query.QueryResponseItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query.QueryResponseItem} QueryResponseItem
         */
        QueryResponseItem.fromObject = function fromObject(object) {
            if (object instanceof $root.query.QueryResponseItem)
                return object;
            var message = new $root.query.QueryResponseItem();
            if (object.requestId != null) {
                if (typeof object.requestId !== "object")
                    throw TypeError(".query.QueryResponseItem.requestId: object expected");
                message.requestId = $root.google.protobuf.StringValue.fromObject(object.requestId);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".query.QueryResponseItem.error: object expected");
                message.error = $root.query.ErrorResponse.fromObject(object.error);
            }
            if (object.me != null) {
                if (typeof object.me !== "object")
                    throw TypeError(".query.QueryResponseItem.me: object expected");
                message.me = $root.query_recursive_types.MeResponse.fromObject(object.me);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".query.QueryResponseItem.user: object expected");
                message.user = $root.query_recursive_types.UserResponse.fromObject(object.user);
            }
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".query.QueryResponseItem.story: object expected");
                message.story = $root.query_recursive_types.StoryResponse.fromObject(object.story);
            }
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".query.QueryResponseItem.series: object expected");
                message.series = $root.query_recursive_types.SeriesResponse.fromObject(object.series);
            }
            if (object.seriesGroup != null) {
                if (typeof object.seriesGroup !== "object")
                    throw TypeError(".query.QueryResponseItem.seriesGroup: object expected");
                message.seriesGroup = $root.query_recursive_types.SeriesGroupResponse.fromObject(object.seriesGroup);
            }
            if (object.storySelection != null) {
                if (typeof object.storySelection !== "object")
                    throw TypeError(".query.QueryResponseItem.storySelection: object expected");
                message.storySelection = $root.query_story_selection.StorySelectionResponse.fromObject(object.storySelection);
            }
            if (object.storyAdvertisement != null) {
                if (typeof object.storyAdvertisement !== "object")
                    throw TypeError(".query.QueryResponseItem.storyAdvertisement: object expected");
                message.storyAdvertisement = $root.query_recursive_types.StoryAdvertisementResponse.fromObject(object.storyAdvertisement);
            }
            if (object.comment != null) {
                if (typeof object.comment !== "object")
                    throw TypeError(".query.QueryResponseItem.comment: object expected");
                message.comment = $root.query_recursive_types.CommentResponse.fromObject(object.comment);
            }
            if (object.searchStory != null) {
                if (typeof object.searchStory !== "object")
                    throw TypeError(".query.QueryResponseItem.searchStory: object expected");
                message.searchStory = $root.query_recursive_types.SearchStoryResponse.fromObject(object.searchStory);
            }
            if (object.searchStoryFacetTag != null) {
                if (typeof object.searchStoryFacetTag !== "object")
                    throw TypeError(".query.QueryResponseItem.searchStoryFacetTag: object expected");
                message.searchStoryFacetTag = $root.query_search_types.SearchStoryFacetResponse.fromObject(object.searchStoryFacetTag);
            }
            if (object.searchUser != null) {
                if (typeof object.searchUser !== "object")
                    throw TypeError(".query.QueryResponseItem.searchUser: object expected");
                message.searchUser = $root.query_recursive_types.SearchUserResponse.fromObject(object.searchUser);
            }
            if (object.searchSeries != null) {
                if (typeof object.searchSeries !== "object")
                    throw TypeError(".query.QueryResponseItem.searchSeries: object expected");
                message.searchSeries = $root.query_recursive_types.SearchSeriesResponse.fromObject(object.searchSeries);
            }
            if (object.searchSeriesGroup != null) {
                if (typeof object.searchSeriesGroup !== "object")
                    throw TypeError(".query.QueryResponseItem.searchSeriesGroup: object expected");
                message.searchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupResponse.fromObject(object.searchSeriesGroup);
            }
            if (object.eventList != null) {
                if (typeof object.eventList !== "object")
                    throw TypeError(".query.QueryResponseItem.eventList: object expected");
                message.eventList = $root.query_recursive_types.EventListResponse.fromObject(object.eventList);
            }
            if (object.tagRanking != null) {
                if (typeof object.tagRanking !== "object")
                    throw TypeError(".query.QueryResponseItem.tagRanking: object expected");
                message.tagRanking = $root.query_tag_list.TagRankingResponse.fromObject(object.tagRanking);
            }
            if (object.tagMedal != null) {
                if (typeof object.tagMedal !== "object")
                    throw TypeError(".query.QueryResponseItem.tagMedal: object expected");
                message.tagMedal = $root.query_recursive_types.TagMedalCursorResponse.fromObject(object.tagMedal);
            }
            if (object.storyRanking != null) {
                if (typeof object.storyRanking !== "object")
                    throw TypeError(".query.QueryResponseItem.storyRanking: object expected");
                message.storyRanking = $root.query_story_ranking.StoryRankingResponse.fromObject(object.storyRanking);
            }
            if (object.searchQuery != null) {
                if (typeof object.searchQuery !== "object")
                    throw TypeError(".query.QueryResponseItem.searchQuery: object expected");
                message.searchQuery = $root.query_search_query.SearchQueryResponse.fromObject(object.searchQuery);
            }
            if (object.tag != null) {
                if (typeof object.tag !== "object")
                    throw TypeError(".query.QueryResponseItem.tag: object expected");
                message.tag = $root.query_recursive_types.TagResponse.fromObject(object.tag);
            }
            if (object.tagHotList != null) {
                if (typeof object.tagHotList !== "object")
                    throw TypeError(".query.QueryResponseItem.tagHotList: object expected");
                message.tagHotList = $root.query_tag_list.TagHotListResponse.fromObject(object.tagHotList);
            }
            if (object.searchSeriesV2 != null) {
                if (typeof object.searchSeriesV2 !== "object")
                    throw TypeError(".query.QueryResponseItem.searchSeriesV2: object expected");
                message.searchSeriesV2 = $root.query_search_v2.SearchSeriesResponseV2.fromObject(object.searchSeriesV2);
            }
            if (object.searchFacetTagV2 != null) {
                if (typeof object.searchFacetTagV2 !== "object")
                    throw TypeError(".query.QueryResponseItem.searchFacetTagV2: object expected");
                message.searchFacetTagV2 = $root.query_search_v2.SearchFacetTagResponseV2.fromObject(object.searchFacetTagV2);
            }
            if (object.searchUserV2 != null) {
                if (typeof object.searchUserV2 !== "object")
                    throw TypeError(".query.QueryResponseItem.searchUserV2: object expected");
                message.searchUserV2 = $root.query_search_v2.SearchUserResponseV2.fromObject(object.searchUserV2);
            }
            if (object.seriesSelection != null) {
                if (typeof object.seriesSelection !== "object")
                    throw TypeError(".query.QueryResponseItem.seriesSelection: object expected");
                message.seriesSelection = $root.query_recursive_types.SeriesSelectionResponse.fromObject(object.seriesSelection);
            }
            if (object.seriesGenreRanking != null) {
                if (typeof object.seriesGenreRanking !== "object")
                    throw TypeError(".query.QueryResponseItem.seriesGenreRanking: object expected");
                message.seriesGenreRanking = $root.query_series_genre_ranking.SeriesGenreRankingCursorResponse.fromObject(object.seriesGenreRanking);
            }
            return message;
        };

        /**
         * Creates a plain object from a QueryResponseItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query.QueryResponseItem
         * @static
         * @param {query.QueryResponseItem} message QueryResponseItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryResponseItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requestId = null;
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                object.requestId = $root.google.protobuf.StringValue.toObject(message.requestId, options);
            if (message.error != null && message.hasOwnProperty("error")) {
                object.error = $root.query.ErrorResponse.toObject(message.error, options);
                if (options.oneofs)
                    object.response = "error";
            }
            if (message.me != null && message.hasOwnProperty("me")) {
                object.me = $root.query_recursive_types.MeResponse.toObject(message.me, options);
                if (options.oneofs)
                    object.response = "me";
            }
            if (message.user != null && message.hasOwnProperty("user")) {
                object.user = $root.query_recursive_types.UserResponse.toObject(message.user, options);
                if (options.oneofs)
                    object.response = "user";
            }
            if (message.story != null && message.hasOwnProperty("story")) {
                object.story = $root.query_recursive_types.StoryResponse.toObject(message.story, options);
                if (options.oneofs)
                    object.response = "story";
            }
            if (message.series != null && message.hasOwnProperty("series")) {
                object.series = $root.query_recursive_types.SeriesResponse.toObject(message.series, options);
                if (options.oneofs)
                    object.response = "series";
            }
            if (message.seriesGroup != null && message.hasOwnProperty("seriesGroup")) {
                object.seriesGroup = $root.query_recursive_types.SeriesGroupResponse.toObject(message.seriesGroup, options);
                if (options.oneofs)
                    object.response = "seriesGroup";
            }
            if (message.storySelection != null && message.hasOwnProperty("storySelection")) {
                object.storySelection = $root.query_story_selection.StorySelectionResponse.toObject(message.storySelection, options);
                if (options.oneofs)
                    object.response = "storySelection";
            }
            if (message.storyAdvertisement != null && message.hasOwnProperty("storyAdvertisement")) {
                object.storyAdvertisement = $root.query_recursive_types.StoryAdvertisementResponse.toObject(message.storyAdvertisement, options);
                if (options.oneofs)
                    object.response = "storyAdvertisement";
            }
            if (message.comment != null && message.hasOwnProperty("comment")) {
                object.comment = $root.query_recursive_types.CommentResponse.toObject(message.comment, options);
                if (options.oneofs)
                    object.response = "comment";
            }
            if (message.searchStory != null && message.hasOwnProperty("searchStory")) {
                object.searchStory = $root.query_recursive_types.SearchStoryResponse.toObject(message.searchStory, options);
                if (options.oneofs)
                    object.response = "searchStory";
            }
            if (message.searchStoryFacetTag != null && message.hasOwnProperty("searchStoryFacetTag")) {
                object.searchStoryFacetTag = $root.query_search_types.SearchStoryFacetResponse.toObject(message.searchStoryFacetTag, options);
                if (options.oneofs)
                    object.response = "searchStoryFacetTag";
            }
            if (message.searchUser != null && message.hasOwnProperty("searchUser")) {
                object.searchUser = $root.query_recursive_types.SearchUserResponse.toObject(message.searchUser, options);
                if (options.oneofs)
                    object.response = "searchUser";
            }
            if (message.searchSeries != null && message.hasOwnProperty("searchSeries")) {
                object.searchSeries = $root.query_recursive_types.SearchSeriesResponse.toObject(message.searchSeries, options);
                if (options.oneofs)
                    object.response = "searchSeries";
            }
            if (message.searchSeriesGroup != null && message.hasOwnProperty("searchSeriesGroup")) {
                object.searchSeriesGroup = $root.query_recursive_types.SearchSeriesGroupResponse.toObject(message.searchSeriesGroup, options);
                if (options.oneofs)
                    object.response = "searchSeriesGroup";
            }
            if (message.eventList != null && message.hasOwnProperty("eventList")) {
                object.eventList = $root.query_recursive_types.EventListResponse.toObject(message.eventList, options);
                if (options.oneofs)
                    object.response = "eventList";
            }
            if (message.tagRanking != null && message.hasOwnProperty("tagRanking")) {
                object.tagRanking = $root.query_tag_list.TagRankingResponse.toObject(message.tagRanking, options);
                if (options.oneofs)
                    object.response = "tagRanking";
            }
            if (message.tagMedal != null && message.hasOwnProperty("tagMedal")) {
                object.tagMedal = $root.query_recursive_types.TagMedalCursorResponse.toObject(message.tagMedal, options);
                if (options.oneofs)
                    object.response = "tagMedal";
            }
            if (message.storyRanking != null && message.hasOwnProperty("storyRanking")) {
                object.storyRanking = $root.query_story_ranking.StoryRankingResponse.toObject(message.storyRanking, options);
                if (options.oneofs)
                    object.response = "storyRanking";
            }
            if (message.searchQuery != null && message.hasOwnProperty("searchQuery")) {
                object.searchQuery = $root.query_search_query.SearchQueryResponse.toObject(message.searchQuery, options);
                if (options.oneofs)
                    object.response = "searchQuery";
            }
            if (message.tag != null && message.hasOwnProperty("tag")) {
                object.tag = $root.query_recursive_types.TagResponse.toObject(message.tag, options);
                if (options.oneofs)
                    object.response = "tag";
            }
            if (message.tagHotList != null && message.hasOwnProperty("tagHotList")) {
                object.tagHotList = $root.query_tag_list.TagHotListResponse.toObject(message.tagHotList, options);
                if (options.oneofs)
                    object.response = "tagHotList";
            }
            if (message.searchSeriesV2 != null && message.hasOwnProperty("searchSeriesV2")) {
                object.searchSeriesV2 = $root.query_search_v2.SearchSeriesResponseV2.toObject(message.searchSeriesV2, options);
                if (options.oneofs)
                    object.response = "searchSeriesV2";
            }
            if (message.searchFacetTagV2 != null && message.hasOwnProperty("searchFacetTagV2")) {
                object.searchFacetTagV2 = $root.query_search_v2.SearchFacetTagResponseV2.toObject(message.searchFacetTagV2, options);
                if (options.oneofs)
                    object.response = "searchFacetTagV2";
            }
            if (message.searchUserV2 != null && message.hasOwnProperty("searchUserV2")) {
                object.searchUserV2 = $root.query_search_v2.SearchUserResponseV2.toObject(message.searchUserV2, options);
                if (options.oneofs)
                    object.response = "searchUserV2";
            }
            if (message.seriesSelection != null && message.hasOwnProperty("seriesSelection")) {
                object.seriesSelection = $root.query_recursive_types.SeriesSelectionResponse.toObject(message.seriesSelection, options);
                if (options.oneofs)
                    object.response = "seriesSelection";
            }
            if (message.seriesGenreRanking != null && message.hasOwnProperty("seriesGenreRanking")) {
                object.seriesGenreRanking = $root.query_series_genre_ranking.SeriesGenreRankingCursorResponse.toObject(message.seriesGenreRanking, options);
                if (options.oneofs)
                    object.response = "seriesGenreRanking";
            }
            return object;
        };

        /**
         * Converts this QueryResponseItem to JSON.
         * @function toJSON
         * @memberof query.QueryResponseItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryResponseItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueryResponseItem
         * @function getTypeUrl
         * @memberof query.QueryResponseItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueryResponseItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.QueryResponseItem";
        };

        return QueryResponseItem;
    })();

    query.ErrorResponse = (function() {

        /**
         * Properties of an ErrorResponse.
         * @memberof query
         * @interface IErrorResponse
         * @property {google.protobuf.IInt32Value|null} [status] ErrorResponse status
         * @property {google.protobuf.IStringValue|null} [message] ErrorResponse message
         */

        /**
         * Constructs a new ErrorResponse.
         * @memberof query
         * @classdesc Represents an ErrorResponse.
         * @implements IErrorResponse
         * @constructor
         * @param {query.IErrorResponse=} [properties] Properties to set
         */
        function ErrorResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ErrorResponse status.
         * @member {google.protobuf.IInt32Value|null|undefined} status
         * @memberof query.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.status = null;

        /**
         * ErrorResponse message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof query.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.message = null;

        /**
         * Creates a new ErrorResponse instance using the specified properties.
         * @function create
         * @memberof query.ErrorResponse
         * @static
         * @param {query.IErrorResponse=} [properties] Properties to set
         * @returns {query.ErrorResponse} ErrorResponse instance
         */
        ErrorResponse.create = function create(properties) {
            return new ErrorResponse(properties);
        };

        /**
         * Encodes the specified ErrorResponse message. Does not implicitly {@link query.ErrorResponse.verify|verify} messages.
         * @function encode
         * @memberof query.ErrorResponse
         * @static
         * @param {query.IErrorResponse} message ErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                $root.google.protobuf.Int32Value.encode(message.status, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.google.protobuf.StringValue.encode(message.message, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ErrorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query.ErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query.ErrorResponse} ErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query.ErrorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.message = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates an ErrorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query.ErrorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query.ErrorResponse} ErrorResponse
         */
        ErrorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query.ErrorResponse)
                return object;
            var message = new $root.query.ErrorResponse();
            if (object.status != null) {
                if (typeof object.status !== "object")
                    throw TypeError(".query.ErrorResponse.status: object expected");
                message.status = $root.google.protobuf.Int32Value.fromObject(object.status);
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".query.ErrorResponse.message: object expected");
                message.message = $root.google.protobuf.StringValue.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from an ErrorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query.ErrorResponse
         * @static
         * @param {query.ErrorResponse} message ErrorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ErrorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.status = null;
                object.message = null;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = $root.google.protobuf.Int32Value.toObject(message.status, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.google.protobuf.StringValue.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this ErrorResponse to JSON.
         * @function toJSON
         * @memberof query.ErrorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ErrorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ErrorResponse
         * @function getTypeUrl
         * @memberof query.ErrorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ErrorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.ErrorResponse";
        };

        return ErrorResponse;
    })();

    return query;
})();

$root.query_story_selection = (function() {

    /**
     * Namespace query_story_selection.
     * @exports query_story_selection
     * @namespace
     */
    var query_story_selection = {};

    /**
     * StorySelectionShuffling enum.
     * @name query_story_selection.StorySelectionShuffling
     * @enum {number}
     * @property {number} STORY_SELECTION_SHUFFLING_UNSPECIFIED=0 STORY_SELECTION_SHUFFLING_UNSPECIFIED value
     * @property {number} STORY_SELECTION_SHUFFLING_EVERYTIME=1 STORY_SELECTION_SHUFFLING_EVERYTIME value
     * @property {number} STORY_SELECTION_SHUFFLING_DAILY=2 STORY_SELECTION_SHUFFLING_DAILY value
     */
    query_story_selection.StorySelectionShuffling = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STORY_SELECTION_SHUFFLING_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STORY_SELECTION_SHUFFLING_EVERYTIME"] = 1;
        values[valuesById[2] = "STORY_SELECTION_SHUFFLING_DAILY"] = 2;
        return values;
    })();

    query_story_selection.StorySelectionRequest = (function() {

        /**
         * Properties of a StorySelectionRequest.
         * @memberof query_story_selection
         * @interface IStorySelectionRequest
         * @property {google.protobuf.IStringValue|null} [id] StorySelectionRequest id
         * @property {query_story_selection.IStorySelectionRequireField|null} [requireStorySelection] StorySelectionRequest requireStorySelection
         * @property {query_story_selection.StorySelectionShuffling|null} [applyShuffling] StorySelectionRequest applyShuffling
         */

        /**
         * Constructs a new StorySelectionRequest.
         * @memberof query_story_selection
         * @classdesc Represents a StorySelectionRequest.
         * @implements IStorySelectionRequest
         * @constructor
         * @param {query_story_selection.IStorySelectionRequest=} [properties] Properties to set
         */
        function StorySelectionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionRequest id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_story_selection.StorySelectionRequest
         * @instance
         */
        StorySelectionRequest.prototype.id = null;

        /**
         * StorySelectionRequest requireStorySelection.
         * @member {query_story_selection.IStorySelectionRequireField|null|undefined} requireStorySelection
         * @memberof query_story_selection.StorySelectionRequest
         * @instance
         */
        StorySelectionRequest.prototype.requireStorySelection = null;

        /**
         * StorySelectionRequest applyShuffling.
         * @member {query_story_selection.StorySelectionShuffling} applyShuffling
         * @memberof query_story_selection.StorySelectionRequest
         * @instance
         */
        StorySelectionRequest.prototype.applyShuffling = 0;

        /**
         * Creates a new StorySelectionRequest instance using the specified properties.
         * @function create
         * @memberof query_story_selection.StorySelectionRequest
         * @static
         * @param {query_story_selection.IStorySelectionRequest=} [properties] Properties to set
         * @returns {query_story_selection.StorySelectionRequest} StorySelectionRequest instance
         */
        StorySelectionRequest.create = function create(properties) {
            return new StorySelectionRequest(properties);
        };

        /**
         * Encodes the specified StorySelectionRequest message. Does not implicitly {@link query_story_selection.StorySelectionRequest.verify|verify} messages.
         * @function encode
         * @memberof query_story_selection.StorySelectionRequest
         * @static
         * @param {query_story_selection.IStorySelectionRequest} message StorySelectionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireStorySelection != null && Object.hasOwnProperty.call(message, "requireStorySelection"))
                $root.query_story_selection.StorySelectionRequireField.encode(message.requireStorySelection, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.applyShuffling != null && Object.hasOwnProperty.call(message, "applyShuffling"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.applyShuffling);
            return writer;
        };

        /**
         * Decodes a StorySelectionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_story_selection.StorySelectionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_story_selection.StorySelectionRequest} StorySelectionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_story_selection.StorySelectionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireStorySelection = $root.query_story_selection.StorySelectionRequireField.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.applyShuffling = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_story_selection.StorySelectionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_story_selection.StorySelectionRequest} StorySelectionRequest
         */
        StorySelectionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_story_selection.StorySelectionRequest)
                return object;
            var message = new $root.query_story_selection.StorySelectionRequest();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequest.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.requireStorySelection != null) {
                if (typeof object.requireStorySelection !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequest.requireStorySelection: object expected");
                message.requireStorySelection = $root.query_story_selection.StorySelectionRequireField.fromObject(object.requireStorySelection);
            }
            switch (object.applyShuffling) {
            case "STORY_SELECTION_SHUFFLING_UNSPECIFIED":
            case 0:
                message.applyShuffling = 0;
                break;
            case "STORY_SELECTION_SHUFFLING_EVERYTIME":
            case 1:
                message.applyShuffling = 1;
                break;
            case "STORY_SELECTION_SHUFFLING_DAILY":
            case 2:
                message.applyShuffling = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_story_selection.StorySelectionRequest
         * @static
         * @param {query_story_selection.StorySelectionRequest} message StorySelectionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.requireStorySelection = null;
                object.applyShuffling = options.enums === String ? "STORY_SELECTION_SHUFFLING_UNSPECIFIED" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.requireStorySelection != null && message.hasOwnProperty("requireStorySelection"))
                object.requireStorySelection = $root.query_story_selection.StorySelectionRequireField.toObject(message.requireStorySelection, options);
            if (message.applyShuffling != null && message.hasOwnProperty("applyShuffling"))
                object.applyShuffling = options.enums === String ? $root.query_story_selection.StorySelectionShuffling[message.applyShuffling] : message.applyShuffling;
            return object;
        };

        /**
         * Converts this StorySelectionRequest to JSON.
         * @function toJSON
         * @memberof query_story_selection.StorySelectionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionRequest
         * @function getTypeUrl
         * @memberof query_story_selection.StorySelectionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_story_selection.StorySelectionRequest";
        };

        return StorySelectionRequest;
    })();

    query_story_selection.StorySelectionRequireField = (function() {

        /**
         * Properties of a StorySelectionRequireField.
         * @memberof query_story_selection
         * @interface IStorySelectionRequireField
         * @property {google.protobuf.IBoolValue|null} [requireId] StorySelectionRequireField requireId
         * @property {google.protobuf.IBoolValue|null} [requireTitle] StorySelectionRequireField requireTitle
         * @property {google.protobuf.IBoolValue|null} [requireDescription] StorySelectionRequireField requireDescription
         * @property {query_recursive_types.IImageRequireField|null} [requireThumbnail] StorySelectionRequireField requireThumbnail
         * @property {google.protobuf.IBoolValue|null} [requireCreatedAt] StorySelectionRequireField requireCreatedAt
         * @property {google.protobuf.IBoolValue|null} [requireUpdatedAt] StorySelectionRequireField requireUpdatedAt
         * @property {query_recursive_types.ISearchStoryRequest|null} [requireSearchStoryPage] StorySelectionRequireField requireSearchStoryPage
         * @property {query_recursive_types.IStoryCursorRequest|null} [requireStoryPage] StorySelectionRequireField requireStoryPage
         */

        /**
         * Constructs a new StorySelectionRequireField.
         * @memberof query_story_selection
         * @classdesc Represents a StorySelectionRequireField.
         * @implements IStorySelectionRequireField
         * @constructor
         * @param {query_story_selection.IStorySelectionRequireField=} [properties] Properties to set
         */
        function StorySelectionRequireField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionRequireField requireId.
         * @member {google.protobuf.IBoolValue|null|undefined} requireId
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireId = null;

        /**
         * StorySelectionRequireField requireTitle.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTitle
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireTitle = null;

        /**
         * StorySelectionRequireField requireDescription.
         * @member {google.protobuf.IBoolValue|null|undefined} requireDescription
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireDescription = null;

        /**
         * StorySelectionRequireField requireThumbnail.
         * @member {query_recursive_types.IImageRequireField|null|undefined} requireThumbnail
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireThumbnail = null;

        /**
         * StorySelectionRequireField requireCreatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireCreatedAt
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireCreatedAt = null;

        /**
         * StorySelectionRequireField requireUpdatedAt.
         * @member {google.protobuf.IBoolValue|null|undefined} requireUpdatedAt
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireUpdatedAt = null;

        /**
         * StorySelectionRequireField requireSearchStoryPage.
         * @member {query_recursive_types.ISearchStoryRequest|null|undefined} requireSearchStoryPage
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireSearchStoryPage = null;

        /**
         * StorySelectionRequireField requireStoryPage.
         * @member {query_recursive_types.IStoryCursorRequest|null|undefined} requireStoryPage
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         */
        StorySelectionRequireField.prototype.requireStoryPage = null;

        /**
         * Creates a new StorySelectionRequireField instance using the specified properties.
         * @function create
         * @memberof query_story_selection.StorySelectionRequireField
         * @static
         * @param {query_story_selection.IStorySelectionRequireField=} [properties] Properties to set
         * @returns {query_story_selection.StorySelectionRequireField} StorySelectionRequireField instance
         */
        StorySelectionRequireField.create = function create(properties) {
            return new StorySelectionRequireField(properties);
        };

        /**
         * Encodes the specified StorySelectionRequireField message. Does not implicitly {@link query_story_selection.StorySelectionRequireField.verify|verify} messages.
         * @function encode
         * @memberof query_story_selection.StorySelectionRequireField
         * @static
         * @param {query_story_selection.IStorySelectionRequireField} message StorySelectionRequireField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionRequireField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireId != null && Object.hasOwnProperty.call(message, "requireId"))
                $root.google.protobuf.BoolValue.encode(message.requireId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTitle != null && Object.hasOwnProperty.call(message, "requireTitle"))
                $root.google.protobuf.BoolValue.encode(message.requireTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.requireDescription != null && Object.hasOwnProperty.call(message, "requireDescription"))
                $root.google.protobuf.BoolValue.encode(message.requireDescription, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.requireThumbnail != null && Object.hasOwnProperty.call(message, "requireThumbnail"))
                $root.query_recursive_types.ImageRequireField.encode(message.requireThumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.requireCreatedAt != null && Object.hasOwnProperty.call(message, "requireCreatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireCreatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.requireUpdatedAt != null && Object.hasOwnProperty.call(message, "requireUpdatedAt"))
                $root.google.protobuf.BoolValue.encode(message.requireUpdatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.requireStoryPage != null && Object.hasOwnProperty.call(message, "requireStoryPage"))
                $root.query_recursive_types.StoryCursorRequest.encode(message.requireStoryPage, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.requireSearchStoryPage != null && Object.hasOwnProperty.call(message, "requireSearchStoryPage"))
                $root.query_recursive_types.SearchStoryRequest.encode(message.requireSearchStoryPage, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionRequireField message from the specified reader or buffer.
         * @function decode
         * @memberof query_story_selection.StorySelectionRequireField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_story_selection.StorySelectionRequireField} StorySelectionRequireField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionRequireField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_story_selection.StorySelectionRequireField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireId = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTitle = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.requireDescription = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.requireThumbnail = $root.query_recursive_types.ImageRequireField.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.requireCreatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.requireUpdatedAt = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.requireSearchStoryPage = $root.query_recursive_types.SearchStoryRequest.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requireStoryPage = $root.query_recursive_types.StoryCursorRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionRequireField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_story_selection.StorySelectionRequireField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_story_selection.StorySelectionRequireField} StorySelectionRequireField
         */
        StorySelectionRequireField.fromObject = function fromObject(object) {
            if (object instanceof $root.query_story_selection.StorySelectionRequireField)
                return object;
            var message = new $root.query_story_selection.StorySelectionRequireField();
            if (object.requireId != null) {
                if (typeof object.requireId !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireId: object expected");
                message.requireId = $root.google.protobuf.BoolValue.fromObject(object.requireId);
            }
            if (object.requireTitle != null) {
                if (typeof object.requireTitle !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireTitle: object expected");
                message.requireTitle = $root.google.protobuf.BoolValue.fromObject(object.requireTitle);
            }
            if (object.requireDescription != null) {
                if (typeof object.requireDescription !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireDescription: object expected");
                message.requireDescription = $root.google.protobuf.BoolValue.fromObject(object.requireDescription);
            }
            if (object.requireThumbnail != null) {
                if (typeof object.requireThumbnail !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireThumbnail: object expected");
                message.requireThumbnail = $root.query_recursive_types.ImageRequireField.fromObject(object.requireThumbnail);
            }
            if (object.requireCreatedAt != null) {
                if (typeof object.requireCreatedAt !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireCreatedAt: object expected");
                message.requireCreatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireCreatedAt);
            }
            if (object.requireUpdatedAt != null) {
                if (typeof object.requireUpdatedAt !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireUpdatedAt: object expected");
                message.requireUpdatedAt = $root.google.protobuf.BoolValue.fromObject(object.requireUpdatedAt);
            }
            if (object.requireSearchStoryPage != null) {
                if (typeof object.requireSearchStoryPage !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireSearchStoryPage: object expected");
                message.requireSearchStoryPage = $root.query_recursive_types.SearchStoryRequest.fromObject(object.requireSearchStoryPage);
            }
            if (object.requireStoryPage != null) {
                if (typeof object.requireStoryPage !== "object")
                    throw TypeError(".query_story_selection.StorySelectionRequireField.requireStoryPage: object expected");
                message.requireStoryPage = $root.query_recursive_types.StoryCursorRequest.fromObject(object.requireStoryPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionRequireField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_story_selection.StorySelectionRequireField
         * @static
         * @param {query_story_selection.StorySelectionRequireField} message StorySelectionRequireField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionRequireField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.requireId = null;
                object.requireTitle = null;
                object.requireDescription = null;
                object.requireThumbnail = null;
                object.requireCreatedAt = null;
                object.requireUpdatedAt = null;
                object.requireStoryPage = null;
                object.requireSearchStoryPage = null;
            }
            if (message.requireId != null && message.hasOwnProperty("requireId"))
                object.requireId = $root.google.protobuf.BoolValue.toObject(message.requireId, options);
            if (message.requireTitle != null && message.hasOwnProperty("requireTitle"))
                object.requireTitle = $root.google.protobuf.BoolValue.toObject(message.requireTitle, options);
            if (message.requireDescription != null && message.hasOwnProperty("requireDescription"))
                object.requireDescription = $root.google.protobuf.BoolValue.toObject(message.requireDescription, options);
            if (message.requireThumbnail != null && message.hasOwnProperty("requireThumbnail"))
                object.requireThumbnail = $root.query_recursive_types.ImageRequireField.toObject(message.requireThumbnail, options);
            if (message.requireCreatedAt != null && message.hasOwnProperty("requireCreatedAt"))
                object.requireCreatedAt = $root.google.protobuf.BoolValue.toObject(message.requireCreatedAt, options);
            if (message.requireUpdatedAt != null && message.hasOwnProperty("requireUpdatedAt"))
                object.requireUpdatedAt = $root.google.protobuf.BoolValue.toObject(message.requireUpdatedAt, options);
            if (message.requireStoryPage != null && message.hasOwnProperty("requireStoryPage"))
                object.requireStoryPage = $root.query_recursive_types.StoryCursorRequest.toObject(message.requireStoryPage, options);
            if (message.requireSearchStoryPage != null && message.hasOwnProperty("requireSearchStoryPage"))
                object.requireSearchStoryPage = $root.query_recursive_types.SearchStoryRequest.toObject(message.requireSearchStoryPage, options);
            return object;
        };

        /**
         * Converts this StorySelectionRequireField to JSON.
         * @function toJSON
         * @memberof query_story_selection.StorySelectionRequireField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionRequireField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionRequireField
         * @function getTypeUrl
         * @memberof query_story_selection.StorySelectionRequireField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionRequireField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_story_selection.StorySelectionRequireField";
        };

        return StorySelectionRequireField;
    })();

    query_story_selection.StorySelectionResponse = (function() {

        /**
         * Properties of a StorySelectionResponse.
         * @memberof query_story_selection
         * @interface IStorySelectionResponse
         * @property {google.protobuf.IStringValue|null} [id] StorySelectionResponse id
         * @property {google.protobuf.IStringValue|null} [title] StorySelectionResponse title
         * @property {google.protobuf.IStringValue|null} [description] StorySelectionResponse description
         * @property {query_recursive_types.IImageResponse|null} [thumbnail] StorySelectionResponse thumbnail
         * @property {google.protobuf.ITimestamp|null} [createdAt] StorySelectionResponse createdAt
         * @property {google.protobuf.ITimestamp|null} [updatedAt] StorySelectionResponse updatedAt
         * @property {query_recursive_types.ISearchStoryResponse|null} [searchStoryPage] StorySelectionResponse searchStoryPage
         * @property {query_recursive_types.IStoryCursorResponse|null} [storyPage] StorySelectionResponse storyPage
         */

        /**
         * Constructs a new StorySelectionResponse.
         * @memberof query_story_selection
         * @classdesc Represents a StorySelectionResponse.
         * @implements IStorySelectionResponse
         * @constructor
         * @param {query_story_selection.IStorySelectionResponse=} [properties] Properties to set
         */
        function StorySelectionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StorySelectionResponse id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.id = null;

        /**
         * StorySelectionResponse title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.title = null;

        /**
         * StorySelectionResponse description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.description = null;

        /**
         * StorySelectionResponse thumbnail.
         * @member {query_recursive_types.IImageResponse|null|undefined} thumbnail
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.thumbnail = null;

        /**
         * StorySelectionResponse createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.createdAt = null;

        /**
         * StorySelectionResponse updatedAt.
         * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.updatedAt = null;

        /**
         * StorySelectionResponse searchStoryPage.
         * @member {query_recursive_types.ISearchStoryResponse|null|undefined} searchStoryPage
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.searchStoryPage = null;

        /**
         * StorySelectionResponse storyPage.
         * @member {query_recursive_types.IStoryCursorResponse|null|undefined} storyPage
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         */
        StorySelectionResponse.prototype.storyPage = null;

        /**
         * Creates a new StorySelectionResponse instance using the specified properties.
         * @function create
         * @memberof query_story_selection.StorySelectionResponse
         * @static
         * @param {query_story_selection.IStorySelectionResponse=} [properties] Properties to set
         * @returns {query_story_selection.StorySelectionResponse} StorySelectionResponse instance
         */
        StorySelectionResponse.create = function create(properties) {
            return new StorySelectionResponse(properties);
        };

        /**
         * Encodes the specified StorySelectionResponse message. Does not implicitly {@link query_story_selection.StorySelectionResponse.verify|verify} messages.
         * @function encode
         * @memberof query_story_selection.StorySelectionResponse
         * @static
         * @param {query_story_selection.IStorySelectionResponse} message StorySelectionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StorySelectionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.query_recursive_types.ImageResponse.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.storyPage != null && Object.hasOwnProperty.call(message, "storyPage"))
                $root.query_recursive_types.StoryCursorResponse.encode(message.storyPage, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.searchStoryPage != null && Object.hasOwnProperty.call(message, "searchStoryPage"))
                $root.query_recursive_types.SearchStoryResponse.encode(message.searchStoryPage, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StorySelectionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_story_selection.StorySelectionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_story_selection.StorySelectionResponse} StorySelectionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StorySelectionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_story_selection.StorySelectionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.query_recursive_types.ImageResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.searchStoryPage = $root.query_recursive_types.SearchStoryResponse.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.storyPage = $root.query_recursive_types.StoryCursorResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StorySelectionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_story_selection.StorySelectionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_story_selection.StorySelectionResponse} StorySelectionResponse
         */
        StorySelectionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_story_selection.StorySelectionResponse)
                return object;
            var message = new $root.query_story_selection.StorySelectionResponse();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.thumbnail: object expected");
                message.thumbnail = $root.query_recursive_types.ImageResponse.fromObject(object.thumbnail);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.updatedAt != null) {
                if (typeof object.updatedAt !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.updatedAt: object expected");
                message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
            }
            if (object.searchStoryPage != null) {
                if (typeof object.searchStoryPage !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.searchStoryPage: object expected");
                message.searchStoryPage = $root.query_recursive_types.SearchStoryResponse.fromObject(object.searchStoryPage);
            }
            if (object.storyPage != null) {
                if (typeof object.storyPage !== "object")
                    throw TypeError(".query_story_selection.StorySelectionResponse.storyPage: object expected");
                message.storyPage = $root.query_recursive_types.StoryCursorResponse.fromObject(object.storyPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a StorySelectionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_story_selection.StorySelectionResponse
         * @static
         * @param {query_story_selection.StorySelectionResponse} message StorySelectionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StorySelectionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.createdAt = null;
                object.updatedAt = null;
                object.storyPage = null;
                object.searchStoryPage = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.query_recursive_types.ImageResponse.toObject(message.thumbnail, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
            if (message.storyPage != null && message.hasOwnProperty("storyPage"))
                object.storyPage = $root.query_recursive_types.StoryCursorResponse.toObject(message.storyPage, options);
            if (message.searchStoryPage != null && message.hasOwnProperty("searchStoryPage"))
                object.searchStoryPage = $root.query_recursive_types.SearchStoryResponse.toObject(message.searchStoryPage, options);
            return object;
        };

        /**
         * Converts this StorySelectionResponse to JSON.
         * @function toJSON
         * @memberof query_story_selection.StorySelectionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StorySelectionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StorySelectionResponse
         * @function getTypeUrl
         * @memberof query_story_selection.StorySelectionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StorySelectionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_story_selection.StorySelectionResponse";
        };

        return StorySelectionResponse;
    })();

    return query_story_selection;
})();

$root.query_tag_list = (function() {

    /**
     * Namespace query_tag_list.
     * @exports query_tag_list
     * @namespace
     */
    var query_tag_list = {};

    query_tag_list.TagRankingRequest = (function() {

        /**
         * Properties of a TagRankingRequest.
         * @memberof query_tag_list
         * @interface ITagRankingRequest
         * @property {google.protobuf.IBoolValue|null} [requireNotOfficialPopularList] TagRankingRequest requireNotOfficialPopularList
         */

        /**
         * Constructs a new TagRankingRequest.
         * @memberof query_tag_list
         * @classdesc Represents a TagRankingRequest.
         * @implements ITagRankingRequest
         * @constructor
         * @param {query_tag_list.ITagRankingRequest=} [properties] Properties to set
         */
        function TagRankingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagRankingRequest requireNotOfficialPopularList.
         * @member {google.protobuf.IBoolValue|null|undefined} requireNotOfficialPopularList
         * @memberof query_tag_list.TagRankingRequest
         * @instance
         */
        TagRankingRequest.prototype.requireNotOfficialPopularList = null;

        /**
         * Creates a new TagRankingRequest instance using the specified properties.
         * @function create
         * @memberof query_tag_list.TagRankingRequest
         * @static
         * @param {query_tag_list.ITagRankingRequest=} [properties] Properties to set
         * @returns {query_tag_list.TagRankingRequest} TagRankingRequest instance
         */
        TagRankingRequest.create = function create(properties) {
            return new TagRankingRequest(properties);
        };

        /**
         * Encodes the specified TagRankingRequest message. Does not implicitly {@link query_tag_list.TagRankingRequest.verify|verify} messages.
         * @function encode
         * @memberof query_tag_list.TagRankingRequest
         * @static
         * @param {query_tag_list.ITagRankingRequest} message TagRankingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagRankingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireNotOfficialPopularList != null && Object.hasOwnProperty.call(message, "requireNotOfficialPopularList"))
                $root.google.protobuf.BoolValue.encode(message.requireNotOfficialPopularList, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagRankingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_tag_list.TagRankingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_tag_list.TagRankingRequest} TagRankingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagRankingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_tag_list.TagRankingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireNotOfficialPopularList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagRankingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_tag_list.TagRankingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_tag_list.TagRankingRequest} TagRankingRequest
         */
        TagRankingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_tag_list.TagRankingRequest)
                return object;
            var message = new $root.query_tag_list.TagRankingRequest();
            if (object.requireNotOfficialPopularList != null) {
                if (typeof object.requireNotOfficialPopularList !== "object")
                    throw TypeError(".query_tag_list.TagRankingRequest.requireNotOfficialPopularList: object expected");
                message.requireNotOfficialPopularList = $root.google.protobuf.BoolValue.fromObject(object.requireNotOfficialPopularList);
            }
            return message;
        };

        /**
         * Creates a plain object from a TagRankingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_tag_list.TagRankingRequest
         * @static
         * @param {query_tag_list.TagRankingRequest} message TagRankingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagRankingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requireNotOfficialPopularList = null;
            if (message.requireNotOfficialPopularList != null && message.hasOwnProperty("requireNotOfficialPopularList"))
                object.requireNotOfficialPopularList = $root.google.protobuf.BoolValue.toObject(message.requireNotOfficialPopularList, options);
            return object;
        };

        /**
         * Converts this TagRankingRequest to JSON.
         * @function toJSON
         * @memberof query_tag_list.TagRankingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagRankingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagRankingRequest
         * @function getTypeUrl
         * @memberof query_tag_list.TagRankingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagRankingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_tag_list.TagRankingRequest";
        };

        return TagRankingRequest;
    })();

    query_tag_list.TagRankingResponse = (function() {

        /**
         * Properties of a TagRankingResponse.
         * @memberof query_tag_list
         * @interface ITagRankingResponse
         * @property {Array.<google.protobuf.IStringValue>|null} [notOfficialPopularList] TagRankingResponse notOfficialPopularList
         */

        /**
         * Constructs a new TagRankingResponse.
         * @memberof query_tag_list
         * @classdesc Represents a TagRankingResponse.
         * @implements ITagRankingResponse
         * @constructor
         * @param {query_tag_list.ITagRankingResponse=} [properties] Properties to set
         */
        function TagRankingResponse(properties) {
            this.notOfficialPopularList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagRankingResponse notOfficialPopularList.
         * @member {Array.<google.protobuf.IStringValue>} notOfficialPopularList
         * @memberof query_tag_list.TagRankingResponse
         * @instance
         */
        TagRankingResponse.prototype.notOfficialPopularList = $util.emptyArray;

        /**
         * Creates a new TagRankingResponse instance using the specified properties.
         * @function create
         * @memberof query_tag_list.TagRankingResponse
         * @static
         * @param {query_tag_list.ITagRankingResponse=} [properties] Properties to set
         * @returns {query_tag_list.TagRankingResponse} TagRankingResponse instance
         */
        TagRankingResponse.create = function create(properties) {
            return new TagRankingResponse(properties);
        };

        /**
         * Encodes the specified TagRankingResponse message. Does not implicitly {@link query_tag_list.TagRankingResponse.verify|verify} messages.
         * @function encode
         * @memberof query_tag_list.TagRankingResponse
         * @static
         * @param {query_tag_list.ITagRankingResponse} message TagRankingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagRankingResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.notOfficialPopularList != null && message.notOfficialPopularList.length)
                for (var i = 0; i < message.notOfficialPopularList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.notOfficialPopularList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagRankingResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_tag_list.TagRankingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_tag_list.TagRankingResponse} TagRankingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagRankingResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_tag_list.TagRankingResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.notOfficialPopularList && message.notOfficialPopularList.length))
                        message.notOfficialPopularList = [];
                    message.notOfficialPopularList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagRankingResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_tag_list.TagRankingResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_tag_list.TagRankingResponse} TagRankingResponse
         */
        TagRankingResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_tag_list.TagRankingResponse)
                return object;
            var message = new $root.query_tag_list.TagRankingResponse();
            if (object.notOfficialPopularList) {
                if (!Array.isArray(object.notOfficialPopularList))
                    throw TypeError(".query_tag_list.TagRankingResponse.notOfficialPopularList: array expected");
                message.notOfficialPopularList = [];
                for (var i = 0; i < object.notOfficialPopularList.length; ++i) {
                    if (typeof object.notOfficialPopularList[i] !== "object")
                        throw TypeError(".query_tag_list.TagRankingResponse.notOfficialPopularList: object expected");
                    message.notOfficialPopularList[i] = $root.google.protobuf.StringValue.fromObject(object.notOfficialPopularList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TagRankingResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_tag_list.TagRankingResponse
         * @static
         * @param {query_tag_list.TagRankingResponse} message TagRankingResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagRankingResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.notOfficialPopularList = [];
            if (message.notOfficialPopularList && message.notOfficialPopularList.length) {
                object.notOfficialPopularList = [];
                for (var j = 0; j < message.notOfficialPopularList.length; ++j)
                    object.notOfficialPopularList[j] = $root.google.protobuf.StringValue.toObject(message.notOfficialPopularList[j], options);
            }
            return object;
        };

        /**
         * Converts this TagRankingResponse to JSON.
         * @function toJSON
         * @memberof query_tag_list.TagRankingResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagRankingResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagRankingResponse
         * @function getTypeUrl
         * @memberof query_tag_list.TagRankingResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagRankingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_tag_list.TagRankingResponse";
        };

        return TagRankingResponse;
    })();

    query_tag_list.TagHotListRequest = (function() {

        /**
         * Properties of a TagHotListRequest.
         * @memberof query_tag_list
         * @interface ITagHotListRequest
         * @property {google.protobuf.IBoolValue|null} [includeEvent] TagHotListRequest includeEvent
         * @property {google.protobuf.IBoolValue|null} [requireTagHotList] TagHotListRequest requireTagHotList
         */

        /**
         * Constructs a new TagHotListRequest.
         * @memberof query_tag_list
         * @classdesc Represents a TagHotListRequest.
         * @implements ITagHotListRequest
         * @constructor
         * @param {query_tag_list.ITagHotListRequest=} [properties] Properties to set
         */
        function TagHotListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagHotListRequest includeEvent.
         * @member {google.protobuf.IBoolValue|null|undefined} includeEvent
         * @memberof query_tag_list.TagHotListRequest
         * @instance
         */
        TagHotListRequest.prototype.includeEvent = null;

        /**
         * TagHotListRequest requireTagHotList.
         * @member {google.protobuf.IBoolValue|null|undefined} requireTagHotList
         * @memberof query_tag_list.TagHotListRequest
         * @instance
         */
        TagHotListRequest.prototype.requireTagHotList = null;

        /**
         * Creates a new TagHotListRequest instance using the specified properties.
         * @function create
         * @memberof query_tag_list.TagHotListRequest
         * @static
         * @param {query_tag_list.ITagHotListRequest=} [properties] Properties to set
         * @returns {query_tag_list.TagHotListRequest} TagHotListRequest instance
         */
        TagHotListRequest.create = function create(properties) {
            return new TagHotListRequest(properties);
        };

        /**
         * Encodes the specified TagHotListRequest message. Does not implicitly {@link query_tag_list.TagHotListRequest.verify|verify} messages.
         * @function encode
         * @memberof query_tag_list.TagHotListRequest
         * @static
         * @param {query_tag_list.ITagHotListRequest} message TagHotListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagHotListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeEvent != null && Object.hasOwnProperty.call(message, "includeEvent"))
                $root.google.protobuf.BoolValue.encode(message.includeEvent, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.requireTagHotList != null && Object.hasOwnProperty.call(message, "requireTagHotList"))
                $root.google.protobuf.BoolValue.encode(message.requireTagHotList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagHotListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_tag_list.TagHotListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_tag_list.TagHotListRequest} TagHotListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagHotListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_tag_list.TagHotListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.includeEvent = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.requireTagHotList = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagHotListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_tag_list.TagHotListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_tag_list.TagHotListRequest} TagHotListRequest
         */
        TagHotListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_tag_list.TagHotListRequest)
                return object;
            var message = new $root.query_tag_list.TagHotListRequest();
            if (object.includeEvent != null) {
                if (typeof object.includeEvent !== "object")
                    throw TypeError(".query_tag_list.TagHotListRequest.includeEvent: object expected");
                message.includeEvent = $root.google.protobuf.BoolValue.fromObject(object.includeEvent);
            }
            if (object.requireTagHotList != null) {
                if (typeof object.requireTagHotList !== "object")
                    throw TypeError(".query_tag_list.TagHotListRequest.requireTagHotList: object expected");
                message.requireTagHotList = $root.google.protobuf.BoolValue.fromObject(object.requireTagHotList);
            }
            return message;
        };

        /**
         * Creates a plain object from a TagHotListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_tag_list.TagHotListRequest
         * @static
         * @param {query_tag_list.TagHotListRequest} message TagHotListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagHotListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.includeEvent = null;
                object.requireTagHotList = null;
            }
            if (message.includeEvent != null && message.hasOwnProperty("includeEvent"))
                object.includeEvent = $root.google.protobuf.BoolValue.toObject(message.includeEvent, options);
            if (message.requireTagHotList != null && message.hasOwnProperty("requireTagHotList"))
                object.requireTagHotList = $root.google.protobuf.BoolValue.toObject(message.requireTagHotList, options);
            return object;
        };

        /**
         * Converts this TagHotListRequest to JSON.
         * @function toJSON
         * @memberof query_tag_list.TagHotListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagHotListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagHotListRequest
         * @function getTypeUrl
         * @memberof query_tag_list.TagHotListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagHotListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_tag_list.TagHotListRequest";
        };

        return TagHotListRequest;
    })();

    query_tag_list.TagHotListResponse = (function() {

        /**
         * Properties of a TagHotListResponse.
         * @memberof query_tag_list
         * @interface ITagHotListResponse
         * @property {Array.<google.protobuf.IStringValue>|null} [tagHotList] TagHotListResponse tagHotList
         */

        /**
         * Constructs a new TagHotListResponse.
         * @memberof query_tag_list
         * @classdesc Represents a TagHotListResponse.
         * @implements ITagHotListResponse
         * @constructor
         * @param {query_tag_list.ITagHotListResponse=} [properties] Properties to set
         */
        function TagHotListResponse(properties) {
            this.tagHotList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagHotListResponse tagHotList.
         * @member {Array.<google.protobuf.IStringValue>} tagHotList
         * @memberof query_tag_list.TagHotListResponse
         * @instance
         */
        TagHotListResponse.prototype.tagHotList = $util.emptyArray;

        /**
         * Creates a new TagHotListResponse instance using the specified properties.
         * @function create
         * @memberof query_tag_list.TagHotListResponse
         * @static
         * @param {query_tag_list.ITagHotListResponse=} [properties] Properties to set
         * @returns {query_tag_list.TagHotListResponse} TagHotListResponse instance
         */
        TagHotListResponse.create = function create(properties) {
            return new TagHotListResponse(properties);
        };

        /**
         * Encodes the specified TagHotListResponse message. Does not implicitly {@link query_tag_list.TagHotListResponse.verify|verify} messages.
         * @function encode
         * @memberof query_tag_list.TagHotListResponse
         * @static
         * @param {query_tag_list.ITagHotListResponse} message TagHotListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagHotListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tagHotList != null && message.tagHotList.length)
                for (var i = 0; i < message.tagHotList.length; ++i)
                    $root.google.protobuf.StringValue.encode(message.tagHotList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagHotListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_tag_list.TagHotListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_tag_list.TagHotListResponse} TagHotListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagHotListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_tag_list.TagHotListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.tagHotList && message.tagHotList.length))
                        message.tagHotList = [];
                    message.tagHotList.push($root.google.protobuf.StringValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a TagHotListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_tag_list.TagHotListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_tag_list.TagHotListResponse} TagHotListResponse
         */
        TagHotListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_tag_list.TagHotListResponse)
                return object;
            var message = new $root.query_tag_list.TagHotListResponse();
            if (object.tagHotList) {
                if (!Array.isArray(object.tagHotList))
                    throw TypeError(".query_tag_list.TagHotListResponse.tagHotList: array expected");
                message.tagHotList = [];
                for (var i = 0; i < object.tagHotList.length; ++i) {
                    if (typeof object.tagHotList[i] !== "object")
                        throw TypeError(".query_tag_list.TagHotListResponse.tagHotList: object expected");
                    message.tagHotList[i] = $root.google.protobuf.StringValue.fromObject(object.tagHotList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TagHotListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_tag_list.TagHotListResponse
         * @static
         * @param {query_tag_list.TagHotListResponse} message TagHotListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagHotListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tagHotList = [];
            if (message.tagHotList && message.tagHotList.length) {
                object.tagHotList = [];
                for (var j = 0; j < message.tagHotList.length; ++j)
                    object.tagHotList[j] = $root.google.protobuf.StringValue.toObject(message.tagHotList[j], options);
            }
            return object;
        };

        /**
         * Converts this TagHotListResponse to JSON.
         * @function toJSON
         * @memberof query_tag_list.TagHotListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagHotListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagHotListResponse
         * @function getTypeUrl
         * @memberof query_tag_list.TagHotListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagHotListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_tag_list.TagHotListResponse";
        };

        return TagHotListResponse;
    })();

    return query_tag_list;
})();

$root.query_story_ranking = (function() {

    /**
     * Namespace query_story_ranking.
     * @exports query_story_ranking
     * @namespace
     */
    var query_story_ranking = {};

    query_story_ranking.StoryRankingRequest = (function() {

        /**
         * Properties of a StoryRankingRequest.
         * @memberof query_story_ranking
         * @interface IStoryRankingRequest
         * @property {query_recursive_types.ISearchStoryRequest|null} [requireSearchStoryPage] StoryRankingRequest requireSearchStoryPage
         */

        /**
         * Constructs a new StoryRankingRequest.
         * @memberof query_story_ranking
         * @classdesc Represents a StoryRankingRequest.
         * @implements IStoryRankingRequest
         * @constructor
         * @param {query_story_ranking.IStoryRankingRequest=} [properties] Properties to set
         */
        function StoryRankingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryRankingRequest requireSearchStoryPage.
         * @member {query_recursive_types.ISearchStoryRequest|null|undefined} requireSearchStoryPage
         * @memberof query_story_ranking.StoryRankingRequest
         * @instance
         */
        StoryRankingRequest.prototype.requireSearchStoryPage = null;

        /**
         * Creates a new StoryRankingRequest instance using the specified properties.
         * @function create
         * @memberof query_story_ranking.StoryRankingRequest
         * @static
         * @param {query_story_ranking.IStoryRankingRequest=} [properties] Properties to set
         * @returns {query_story_ranking.StoryRankingRequest} StoryRankingRequest instance
         */
        StoryRankingRequest.create = function create(properties) {
            return new StoryRankingRequest(properties);
        };

        /**
         * Encodes the specified StoryRankingRequest message. Does not implicitly {@link query_story_ranking.StoryRankingRequest.verify|verify} messages.
         * @function encode
         * @memberof query_story_ranking.StoryRankingRequest
         * @static
         * @param {query_story_ranking.IStoryRankingRequest} message StoryRankingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryRankingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requireSearchStoryPage != null && Object.hasOwnProperty.call(message, "requireSearchStoryPage"))
                $root.query_recursive_types.SearchStoryRequest.encode(message.requireSearchStoryPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryRankingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof query_story_ranking.StoryRankingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_story_ranking.StoryRankingRequest} StoryRankingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryRankingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_story_ranking.StoryRankingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requireSearchStoryPage = $root.query_recursive_types.SearchStoryRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryRankingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_story_ranking.StoryRankingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_story_ranking.StoryRankingRequest} StoryRankingRequest
         */
        StoryRankingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.query_story_ranking.StoryRankingRequest)
                return object;
            var message = new $root.query_story_ranking.StoryRankingRequest();
            if (object.requireSearchStoryPage != null) {
                if (typeof object.requireSearchStoryPage !== "object")
                    throw TypeError(".query_story_ranking.StoryRankingRequest.requireSearchStoryPage: object expected");
                message.requireSearchStoryPage = $root.query_recursive_types.SearchStoryRequest.fromObject(object.requireSearchStoryPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryRankingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_story_ranking.StoryRankingRequest
         * @static
         * @param {query_story_ranking.StoryRankingRequest} message StoryRankingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryRankingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.requireSearchStoryPage = null;
            if (message.requireSearchStoryPage != null && message.hasOwnProperty("requireSearchStoryPage"))
                object.requireSearchStoryPage = $root.query_recursive_types.SearchStoryRequest.toObject(message.requireSearchStoryPage, options);
            return object;
        };

        /**
         * Converts this StoryRankingRequest to JSON.
         * @function toJSON
         * @memberof query_story_ranking.StoryRankingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryRankingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryRankingRequest
         * @function getTypeUrl
         * @memberof query_story_ranking.StoryRankingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryRankingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_story_ranking.StoryRankingRequest";
        };

        return StoryRankingRequest;
    })();

    query_story_ranking.StoryRankingResponse = (function() {

        /**
         * Properties of a StoryRankingResponse.
         * @memberof query_story_ranking
         * @interface IStoryRankingResponse
         * @property {query_recursive_types.ISearchStoryResponse|null} [searchStoryPage] StoryRankingResponse searchStoryPage
         */

        /**
         * Constructs a new StoryRankingResponse.
         * @memberof query_story_ranking
         * @classdesc Represents a StoryRankingResponse.
         * @implements IStoryRankingResponse
         * @constructor
         * @param {query_story_ranking.IStoryRankingResponse=} [properties] Properties to set
         */
        function StoryRankingResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryRankingResponse searchStoryPage.
         * @member {query_recursive_types.ISearchStoryResponse|null|undefined} searchStoryPage
         * @memberof query_story_ranking.StoryRankingResponse
         * @instance
         */
        StoryRankingResponse.prototype.searchStoryPage = null;

        /**
         * Creates a new StoryRankingResponse instance using the specified properties.
         * @function create
         * @memberof query_story_ranking.StoryRankingResponse
         * @static
         * @param {query_story_ranking.IStoryRankingResponse=} [properties] Properties to set
         * @returns {query_story_ranking.StoryRankingResponse} StoryRankingResponse instance
         */
        StoryRankingResponse.create = function create(properties) {
            return new StoryRankingResponse(properties);
        };

        /**
         * Encodes the specified StoryRankingResponse message. Does not implicitly {@link query_story_ranking.StoryRankingResponse.verify|verify} messages.
         * @function encode
         * @memberof query_story_ranking.StoryRankingResponse
         * @static
         * @param {query_story_ranking.IStoryRankingResponse} message StoryRankingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryRankingResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchStoryPage != null && Object.hasOwnProperty.call(message, "searchStoryPage"))
                $root.query_recursive_types.SearchStoryResponse.encode(message.searchStoryPage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryRankingResponse message from the specified reader or buffer.
         * @function decode
         * @memberof query_story_ranking.StoryRankingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {query_story_ranking.StoryRankingResponse} StoryRankingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryRankingResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.query_story_ranking.StoryRankingResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchStoryPage = $root.query_recursive_types.SearchStoryResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryRankingResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof query_story_ranking.StoryRankingResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {query_story_ranking.StoryRankingResponse} StoryRankingResponse
         */
        StoryRankingResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.query_story_ranking.StoryRankingResponse)
                return object;
            var message = new $root.query_story_ranking.StoryRankingResponse();
            if (object.searchStoryPage != null) {
                if (typeof object.searchStoryPage !== "object")
                    throw TypeError(".query_story_ranking.StoryRankingResponse.searchStoryPage: object expected");
                message.searchStoryPage = $root.query_recursive_types.SearchStoryResponse.fromObject(object.searchStoryPage);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryRankingResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof query_story_ranking.StoryRankingResponse
         * @static
         * @param {query_story_ranking.StoryRankingResponse} message StoryRankingResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryRankingResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.searchStoryPage = null;
            if (message.searchStoryPage != null && message.hasOwnProperty("searchStoryPage"))
                object.searchStoryPage = $root.query_recursive_types.SearchStoryResponse.toObject(message.searchStoryPage, options);
            return object;
        };

        /**
         * Converts this StoryRankingResponse to JSON.
         * @function toJSON
         * @memberof query_story_ranking.StoryRankingResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryRankingResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryRankingResponse
         * @function getTypeUrl
         * @memberof query_story_ranking.StoryRankingResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryRankingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query_story_ranking.StoryRankingResponse";
        };

        return StoryRankingResponse;
    })();

    return query_story_ranking;
})();

$root.ranking = (function() {

    /**
     * Namespace ranking.
     * @exports ranking
     * @namespace
     */
    var ranking = {};

    ranking.RankingService = (function() {

        /**
         * Constructs a new RankingService service.
         * @memberof ranking
         * @classdesc Represents a RankingService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function RankingService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (RankingService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RankingService;

        /**
         * Creates new RankingService service using the specified rpc implementation.
         * @function create
         * @memberof ranking.RankingService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {RankingService} RPC service. Useful where requests and/or responses are streamed.
         */
        RankingService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link ranking.RankingService#get}.
         * @memberof ranking.RankingService
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {ranking.RankingGetResponse} [response] RankingGetResponse
         */

        /**
         * Calls Get.
         * @function get
         * @memberof ranking.RankingService
         * @instance
         * @param {ranking.IRankingGetRequest} request RankingGetRequest message or plain object
         * @param {ranking.RankingService.GetCallback} callback Node-style callback called with the error, if any, and RankingGetResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RankingService.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.ranking.RankingGetRequest, $root.ranking.RankingGetResponse, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof ranking.RankingService
         * @instance
         * @param {ranking.IRankingGetRequest} request RankingGetRequest message or plain object
         * @returns {Promise<ranking.RankingGetResponse>} Promise
         * @variation 2
         */

        return RankingService;
    })();

    /**
     * RankingType enum.
     * @name ranking.RankingType
     * @enum {number}
     * @property {number} RANKING_TYPE_UNKNOWN=0 RANKING_TYPE_UNKNOWN value
     * @property {number} OFFICIAL=1 OFFICIAL value
     * @property {number} NON_OFFICIAL=2 NON_OFFICIAL value
     * @property {number} BOTH=3 BOTH value
     */
    ranking.RankingType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RANKING_TYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "OFFICIAL"] = 1;
        values[valuesById[2] = "NON_OFFICIAL"] = 2;
        values[valuesById[3] = "BOTH"] = 3;
        return values;
    })();

    ranking.RankingGetRequest = (function() {

        /**
         * Properties of a RankingGetRequest.
         * @memberof ranking
         * @interface IRankingGetRequest
         * @property {ranking.RankingType|null} [rankingType] RankingGetRequest rankingType
         */

        /**
         * Constructs a new RankingGetRequest.
         * @memberof ranking
         * @classdesc Represents a RankingGetRequest.
         * @implements IRankingGetRequest
         * @constructor
         * @param {ranking.IRankingGetRequest=} [properties] Properties to set
         */
        function RankingGetRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RankingGetRequest rankingType.
         * @member {ranking.RankingType} rankingType
         * @memberof ranking.RankingGetRequest
         * @instance
         */
        RankingGetRequest.prototype.rankingType = 0;

        /**
         * Creates a new RankingGetRequest instance using the specified properties.
         * @function create
         * @memberof ranking.RankingGetRequest
         * @static
         * @param {ranking.IRankingGetRequest=} [properties] Properties to set
         * @returns {ranking.RankingGetRequest} RankingGetRequest instance
         */
        RankingGetRequest.create = function create(properties) {
            return new RankingGetRequest(properties);
        };

        /**
         * Encodes the specified RankingGetRequest message. Does not implicitly {@link ranking.RankingGetRequest.verify|verify} messages.
         * @function encode
         * @memberof ranking.RankingGetRequest
         * @static
         * @param {ranking.IRankingGetRequest} message RankingGetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RankingGetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rankingType != null && Object.hasOwnProperty.call(message, "rankingType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rankingType);
            return writer;
        };

        /**
         * Decodes a RankingGetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof ranking.RankingGetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ranking.RankingGetRequest} RankingGetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RankingGetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ranking.RankingGetRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rankingType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a RankingGetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ranking.RankingGetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ranking.RankingGetRequest} RankingGetRequest
         */
        RankingGetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.ranking.RankingGetRequest)
                return object;
            var message = new $root.ranking.RankingGetRequest();
            switch (object.rankingType) {
            case "RANKING_TYPE_UNKNOWN":
            case 0:
                message.rankingType = 0;
                break;
            case "OFFICIAL":
            case 1:
                message.rankingType = 1;
                break;
            case "NON_OFFICIAL":
            case 2:
                message.rankingType = 2;
                break;
            case "BOTH":
            case 3:
                message.rankingType = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RankingGetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ranking.RankingGetRequest
         * @static
         * @param {ranking.RankingGetRequest} message RankingGetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RankingGetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.rankingType = options.enums === String ? "RANKING_TYPE_UNKNOWN" : 0;
            if (message.rankingType != null && message.hasOwnProperty("rankingType"))
                object.rankingType = options.enums === String ? $root.ranking.RankingType[message.rankingType] : message.rankingType;
            return object;
        };

        /**
         * Converts this RankingGetRequest to JSON.
         * @function toJSON
         * @memberof ranking.RankingGetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RankingGetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RankingGetRequest
         * @function getTypeUrl
         * @memberof ranking.RankingGetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RankingGetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ranking.RankingGetRequest";
        };

        return RankingGetRequest;
    })();

    ranking.Ranking = (function() {

        /**
         * Properties of a Ranking.
         * @memberof ranking
         * @interface IRanking
         * @property {story.IStoryHeaderV1|null} [header] Ranking header
         * @property {google.protobuf.IInt32Value|null} [rank] Ranking rank
         */

        /**
         * Constructs a new Ranking.
         * @memberof ranking
         * @classdesc Represents a Ranking.
         * @implements IRanking
         * @constructor
         * @param {ranking.IRanking=} [properties] Properties to set
         */
        function Ranking(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ranking header.
         * @member {story.IStoryHeaderV1|null|undefined} header
         * @memberof ranking.Ranking
         * @instance
         */
        Ranking.prototype.header = null;

        /**
         * Ranking rank.
         * @member {google.protobuf.IInt32Value|null|undefined} rank
         * @memberof ranking.Ranking
         * @instance
         */
        Ranking.prototype.rank = null;

        /**
         * Creates a new Ranking instance using the specified properties.
         * @function create
         * @memberof ranking.Ranking
         * @static
         * @param {ranking.IRanking=} [properties] Properties to set
         * @returns {ranking.Ranking} Ranking instance
         */
        Ranking.create = function create(properties) {
            return new Ranking(properties);
        };

        /**
         * Encodes the specified Ranking message. Does not implicitly {@link ranking.Ranking.verify|verify} messages.
         * @function encode
         * @memberof ranking.Ranking
         * @static
         * @param {ranking.IRanking} message Ranking message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ranking.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.story.StoryHeaderV1.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
                $root.google.protobuf.Int32Value.encode(message.rank, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Ranking message from the specified reader or buffer.
         * @function decode
         * @memberof ranking.Ranking
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ranking.Ranking} Ranking
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ranking.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ranking.Ranking();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.header = $root.story.StoryHeaderV1.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.rank = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a Ranking message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ranking.Ranking
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ranking.Ranking} Ranking
         */
        Ranking.fromObject = function fromObject(object) {
            if (object instanceof $root.ranking.Ranking)
                return object;
            var message = new $root.ranking.Ranking();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".ranking.Ranking.header: object expected");
                message.header = $root.story.StoryHeaderV1.fromObject(object.header);
            }
            if (object.rank != null) {
                if (typeof object.rank !== "object")
                    throw TypeError(".ranking.Ranking.rank: object expected");
                message.rank = $root.google.protobuf.Int32Value.fromObject(object.rank);
            }
            return message;
        };

        /**
         * Creates a plain object from a Ranking message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ranking.Ranking
         * @static
         * @param {ranking.Ranking} message Ranking
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ranking.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.header = null;
                object.rank = null;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.story.StoryHeaderV1.toObject(message.header, options);
            if (message.rank != null && message.hasOwnProperty("rank"))
                object.rank = $root.google.protobuf.Int32Value.toObject(message.rank, options);
            return object;
        };

        /**
         * Converts this Ranking to JSON.
         * @function toJSON
         * @memberof ranking.Ranking
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ranking.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Ranking
         * @function getTypeUrl
         * @memberof ranking.Ranking
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Ranking.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ranking.Ranking";
        };

        return Ranking;
    })();

    ranking.RankingGetResponse = (function() {

        /**
         * Properties of a RankingGetResponse.
         * @memberof ranking
         * @interface IRankingGetResponse
         * @property {Array.<ranking.IRanking>|null} [rankingList] RankingGetResponse rankingList
         */

        /**
         * Constructs a new RankingGetResponse.
         * @memberof ranking
         * @classdesc Represents a RankingGetResponse.
         * @implements IRankingGetResponse
         * @constructor
         * @param {ranking.IRankingGetResponse=} [properties] Properties to set
         */
        function RankingGetResponse(properties) {
            this.rankingList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RankingGetResponse rankingList.
         * @member {Array.<ranking.IRanking>} rankingList
         * @memberof ranking.RankingGetResponse
         * @instance
         */
        RankingGetResponse.prototype.rankingList = $util.emptyArray;

        /**
         * Creates a new RankingGetResponse instance using the specified properties.
         * @function create
         * @memberof ranking.RankingGetResponse
         * @static
         * @param {ranking.IRankingGetResponse=} [properties] Properties to set
         * @returns {ranking.RankingGetResponse} RankingGetResponse instance
         */
        RankingGetResponse.create = function create(properties) {
            return new RankingGetResponse(properties);
        };

        /**
         * Encodes the specified RankingGetResponse message. Does not implicitly {@link ranking.RankingGetResponse.verify|verify} messages.
         * @function encode
         * @memberof ranking.RankingGetResponse
         * @static
         * @param {ranking.IRankingGetResponse} message RankingGetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RankingGetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rankingList != null && message.rankingList.length)
                for (var i = 0; i < message.rankingList.length; ++i)
                    $root.ranking.Ranking.encode(message.rankingList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RankingGetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof ranking.RankingGetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ranking.RankingGetResponse} RankingGetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RankingGetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ranking.RankingGetResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.rankingList && message.rankingList.length))
                        message.rankingList = [];
                    message.rankingList.push($root.ranking.Ranking.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a RankingGetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ranking.RankingGetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ranking.RankingGetResponse} RankingGetResponse
         */
        RankingGetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.ranking.RankingGetResponse)
                return object;
            var message = new $root.ranking.RankingGetResponse();
            if (object.rankingList) {
                if (!Array.isArray(object.rankingList))
                    throw TypeError(".ranking.RankingGetResponse.rankingList: array expected");
                message.rankingList = [];
                for (var i = 0; i < object.rankingList.length; ++i) {
                    if (typeof object.rankingList[i] !== "object")
                        throw TypeError(".ranking.RankingGetResponse.rankingList: object expected");
                    message.rankingList[i] = $root.ranking.Ranking.fromObject(object.rankingList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RankingGetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ranking.RankingGetResponse
         * @static
         * @param {ranking.RankingGetResponse} message RankingGetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RankingGetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rankingList = [];
            if (message.rankingList && message.rankingList.length) {
                object.rankingList = [];
                for (var j = 0; j < message.rankingList.length; ++j)
                    object.rankingList[j] = $root.ranking.Ranking.toObject(message.rankingList[j], options);
            }
            return object;
        };

        /**
         * Converts this RankingGetResponse to JSON.
         * @function toJSON
         * @memberof ranking.RankingGetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RankingGetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RankingGetResponse
         * @function getTypeUrl
         * @memberof ranking.RankingGetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RankingGetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ranking.RankingGetResponse";
        };

        return RankingGetResponse;
    })();

    return ranking;
})();

$root.recommendation = (function() {

    /**
     * Namespace recommendation.
     * @exports recommendation
     * @namespace
     */
    var recommendation = {};

    recommendation.RecommendationService = (function() {

        /**
         * Constructs a new RecommendationService service.
         * @memberof recommendation
         * @classdesc Represents a RecommendationService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function RecommendationService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (RecommendationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RecommendationService;

        /**
         * Creates new RecommendationService service using the specified rpc implementation.
         * @function create
         * @memberof recommendation.RecommendationService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {RecommendationService} RPC service. Useful where requests and/or responses are streamed.
         */
        RecommendationService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link recommendation.RecommendationService#getStorySelectionByStoryV2}.
         * @memberof recommendation.RecommendationService
         * @typedef GetStorySelectionByStoryV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithCursor} [response] StorySelectionWithCursor
         */

        /**
         * Calls GetStorySelectionByStoryV2.
         * @function getStorySelectionByStoryV2
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationStoryIdPagingRequest} request RecommendationStoryIdPagingRequest message or plain object
         * @param {recommendation.RecommendationService.GetStorySelectionByStoryV2Callback} callback Node-style callback called with the error, if any, and StorySelectionWithCursor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getStorySelectionByStoryV2 = function getStorySelectionByStoryV2(request, callback) {
            return this.rpcCall(getStorySelectionByStoryV2, $root.recommendation.RecommendationStoryIdPagingRequest, $root.story_selection.StorySelectionWithCursor, request, callback);
        }, "name", { value: "GetStorySelectionByStoryV2" });

        /**
         * Calls GetStorySelectionByStoryV2.
         * @function getStorySelectionByStoryV2
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationStoryIdPagingRequest} request RecommendationStoryIdPagingRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionWithCursor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link recommendation.RecommendationService#getStorySelectionByUserV2}.
         * @memberof recommendation.RecommendationService
         * @typedef GetStorySelectionByUserV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithCursorV3} [response] StorySelectionWithCursorV3
         */

        /**
         * Calls GetStorySelectionByUserV2.
         * @function getStorySelectionByUserV2
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserIdPagingRequest} request RecommendationUserIdPagingRequest message or plain object
         * @param {recommendation.RecommendationService.GetStorySelectionByUserV2Callback} callback Node-style callback called with the error, if any, and StorySelectionWithCursorV3
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getStorySelectionByUserV2 = function getStorySelectionByUserV2(request, callback) {
            return this.rpcCall(getStorySelectionByUserV2, $root.recommendation.RecommendationUserIdPagingRequest, $root.story_selection.StorySelectionWithCursorV3, request, callback);
        }, "name", { value: "GetStorySelectionByUserV2" });

        /**
         * Calls GetStorySelectionByUserV2.
         * @function getStorySelectionByUserV2
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserIdPagingRequest} request RecommendationUserIdPagingRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionWithCursorV3>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link recommendation.RecommendationService#getPickupV2}.
         * @memberof recommendation.RecommendationService
         * @typedef GetPickupV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionV3} [response] StorySelectionV3
         */

        /**
         * Calls GetPickupV2.
         * @function getPickupV2
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {recommendation.RecommendationService.GetPickupV2Callback} callback Node-style callback called with the error, if any, and StorySelectionV3
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getPickupV2 = function getPickupV2(request, callback) {
            return this.rpcCall(getPickupV2, $root.types.Empty, $root.story_selection.StorySelectionV3, request, callback);
        }, "name", { value: "GetPickupV2" });

        /**
         * Calls GetPickupV2.
         * @function getPickupV2
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<story_selection.StorySelectionV3>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link recommendation.RecommendationService#getUserSelectionToFollow}.
         * @memberof recommendation.RecommendationService
         * @typedef GetUserSelectionToFollowCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_selection.UserSelection} [response] UserSelection
         */

        /**
         * Calls GetUserSelectionToFollow.
         * @function getUserSelectionToFollow
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserTypeRequest} request RecommendationUserTypeRequest message or plain object
         * @param {recommendation.RecommendationService.GetUserSelectionToFollowCallback} callback Node-style callback called with the error, if any, and UserSelection
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getUserSelectionToFollow = function getUserSelectionToFollow(request, callback) {
            return this.rpcCall(getUserSelectionToFollow, $root.recommendation.RecommendationUserTypeRequest, $root.user_selection.UserSelection, request, callback);
        }, "name", { value: "GetUserSelectionToFollow" });

        /**
         * Calls GetUserSelectionToFollow.
         * @function getUserSelectionToFollow
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserTypeRequest} request RecommendationUserTypeRequest message or plain object
         * @returns {Promise<user_selection.UserSelection>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link recommendation.RecommendationService#getBestAuthorSelection}.
         * @memberof recommendation.RecommendationService
         * @typedef GetBestAuthorSelectionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_selection.UserSelectionV3} [response] UserSelectionV3
         */

        /**
         * Calls GetBestAuthorSelection.
         * @function getBestAuthorSelection
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserTypeRequest} request RecommendationUserTypeRequest message or plain object
         * @param {recommendation.RecommendationService.GetBestAuthorSelectionCallback} callback Node-style callback called with the error, if any, and UserSelectionV3
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getBestAuthorSelection = function getBestAuthorSelection(request, callback) {
            return this.rpcCall(getBestAuthorSelection, $root.recommendation.RecommendationUserTypeRequest, $root.user_selection.UserSelectionV3, request, callback);
        }, "name", { value: "GetBestAuthorSelection" });

        /**
         * Calls GetBestAuthorSelection.
         * @function getBestAuthorSelection
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserTypeRequest} request RecommendationUserTypeRequest message or plain object
         * @returns {Promise<user_selection.UserSelectionV3>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link recommendation.RecommendationService#getStorySelectionByUser}.
         * @memberof recommendation.RecommendationService
         * @typedef GetStorySelectionByUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithCursor} [response] StorySelectionWithCursor
         */

        /**
         * Calls GetStorySelectionByUser.
         * @function getStorySelectionByUser
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserIdPagingRequest} request RecommendationUserIdPagingRequest message or plain object
         * @param {recommendation.RecommendationService.GetStorySelectionByUserCallback} callback Node-style callback called with the error, if any, and StorySelectionWithCursor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getStorySelectionByUser = function getStorySelectionByUser(request, callback) {
            return this.rpcCall(getStorySelectionByUser, $root.recommendation.RecommendationUserIdPagingRequest, $root.story_selection.StorySelectionWithCursor, request, callback);
        }, "name", { value: "GetStorySelectionByUser" });

        /**
         * Calls GetStorySelectionByUser.
         * @function getStorySelectionByUser
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationUserIdPagingRequest} request RecommendationUserIdPagingRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionWithCursor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link recommendation.RecommendationService#getPickup}.
         * @memberof recommendation.RecommendationService
         * @typedef GetPickupCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionWithMatchScore} [response] StorySelectionWithMatchScore
         */

        /**
         * Calls GetPickup.
         * @function getPickup
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {recommendation.RecommendationService.GetPickupCallback} callback Node-style callback called with the error, if any, and StorySelectionWithMatchScore
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getPickup = function getPickup(request, callback) {
            return this.rpcCall(getPickup, $root.types.Empty, $root.story_selection.StorySelectionWithMatchScore, request, callback);
        }, "name", { value: "GetPickup" });

        /**
         * Calls GetPickup.
         * @function getPickup
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<story_selection.StorySelectionWithMatchScore>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link recommendation.RecommendationService#getStorySelectionByStory}.
         * @memberof recommendation.RecommendationService
         * @typedef GetStorySelectionByStoryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_selection.StorySelectionGetResponse} [response] StorySelectionGetResponse
         */

        /**
         * Calls GetStorySelectionByStory.
         * @function getStorySelectionByStory
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationStoryIdPagingRequest} request RecommendationStoryIdPagingRequest message or plain object
         * @param {recommendation.RecommendationService.GetStorySelectionByStoryCallback} callback Node-style callback called with the error, if any, and StorySelectionGetResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RecommendationService.prototype.getStorySelectionByStory = function getStorySelectionByStory(request, callback) {
            return this.rpcCall(getStorySelectionByStory, $root.recommendation.RecommendationStoryIdPagingRequest, $root.story_selection.StorySelectionGetResponse, request, callback);
        }, "name", { value: "GetStorySelectionByStory" });

        /**
         * Calls GetStorySelectionByStory.
         * @function getStorySelectionByStory
         * @memberof recommendation.RecommendationService
         * @instance
         * @param {recommendation.IRecommendationStoryIdPagingRequest} request RecommendationStoryIdPagingRequest message or plain object
         * @returns {Promise<story_selection.StorySelectionGetResponse>} Promise
         * @variation 2
         */

        return RecommendationService;
    })();

    /**
     * RecommendationStoryFilter enum.
     * @name recommendation.RecommendationStoryFilter
     * @enum {number}
     * @property {number} RECOMMEND_STORY_FILTER_UNKNOWN=0 RECOMMEND_STORY_FILTER_UNKNOWN value
     * @property {number} RECOMMEND_STORY_FILTER_OFFICIAL=1 RECOMMEND_STORY_FILTER_OFFICIAL value
     * @property {number} RECOMMEND_STORY_FILTER_OFFICIAL_SERIES=2 RECOMMEND_STORY_FILTER_OFFICIAL_SERIES value
     * @property {number} RECOMMEND_STORY_FILTER_OFFICIAL_ONESHOT=3 RECOMMEND_STORY_FILTER_OFFICIAL_ONESHOT value
     * @property {number} RECOMMEND_STORY_FILTER_NON_OFFICIAL=4 RECOMMEND_STORY_FILTER_NON_OFFICIAL value
     * @property {number} RECOMMEND_STORY_FILTER_PROFILING=5 RECOMMEND_STORY_FILTER_PROFILING value
     */
    recommendation.RecommendationStoryFilter = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RECOMMEND_STORY_FILTER_UNKNOWN"] = 0;
        values[valuesById[1] = "RECOMMEND_STORY_FILTER_OFFICIAL"] = 1;
        values[valuesById[2] = "RECOMMEND_STORY_FILTER_OFFICIAL_SERIES"] = 2;
        values[valuesById[3] = "RECOMMEND_STORY_FILTER_OFFICIAL_ONESHOT"] = 3;
        values[valuesById[4] = "RECOMMEND_STORY_FILTER_NON_OFFICIAL"] = 4;
        values[valuesById[5] = "RECOMMEND_STORY_FILTER_PROFILING"] = 5;
        return values;
    })();

    /**
     * RecommendationUserType enum.
     * @name recommendation.RecommendationUserType
     * @enum {number}
     * @property {number} RECOMMEND_USER_TYPE_UNSPECIFIED=0 RECOMMEND_USER_TYPE_UNSPECIFIED value
     * @property {number} RECOMMEND_USER_TYPE_OFFICIAL=1 RECOMMEND_USER_TYPE_OFFICIAL value
     * @property {number} RECOMMEND_USER_TYPE_NON_OFFICIAL=2 RECOMMEND_USER_TYPE_NON_OFFICIAL value
     * @property {number} RECOMMEND_USER_TYPE_BOTH=3 RECOMMEND_USER_TYPE_BOTH value
     */
    recommendation.RecommendationUserType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RECOMMEND_USER_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "RECOMMEND_USER_TYPE_OFFICIAL"] = 1;
        values[valuesById[2] = "RECOMMEND_USER_TYPE_NON_OFFICIAL"] = 2;
        values[valuesById[3] = "RECOMMEND_USER_TYPE_BOTH"] = 3;
        return values;
    })();

    recommendation.RecommendationStoryIdPagingRequest = (function() {

        /**
         * Properties of a RecommendationStoryIdPagingRequest.
         * @memberof recommendation
         * @interface IRecommendationStoryIdPagingRequest
         * @property {google.protobuf.IStringValue|null} [storyId] RecommendationStoryIdPagingRequest storyId
         * @property {recommendation.RecommendationStoryFilter|null} [filter] RecommendationStoryIdPagingRequest filter
         * @property {google.protobuf.IInt32Value|null} [limit] RecommendationStoryIdPagingRequest limit
         * @property {google.protobuf.IStringValue|null} [cursor] RecommendationStoryIdPagingRequest cursor
         */

        /**
         * Constructs a new RecommendationStoryIdPagingRequest.
         * @memberof recommendation
         * @classdesc Represents a RecommendationStoryIdPagingRequest.
         * @implements IRecommendationStoryIdPagingRequest
         * @constructor
         * @param {recommendation.IRecommendationStoryIdPagingRequest=} [properties] Properties to set
         */
        function RecommendationStoryIdPagingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecommendationStoryIdPagingRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @instance
         */
        RecommendationStoryIdPagingRequest.prototype.storyId = null;

        /**
         * RecommendationStoryIdPagingRequest filter.
         * @member {recommendation.RecommendationStoryFilter} filter
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @instance
         */
        RecommendationStoryIdPagingRequest.prototype.filter = 0;

        /**
         * RecommendationStoryIdPagingRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @instance
         */
        RecommendationStoryIdPagingRequest.prototype.limit = null;

        /**
         * RecommendationStoryIdPagingRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @instance
         */
        RecommendationStoryIdPagingRequest.prototype.cursor = null;

        /**
         * Creates a new RecommendationStoryIdPagingRequest instance using the specified properties.
         * @function create
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @static
         * @param {recommendation.IRecommendationStoryIdPagingRequest=} [properties] Properties to set
         * @returns {recommendation.RecommendationStoryIdPagingRequest} RecommendationStoryIdPagingRequest instance
         */
        RecommendationStoryIdPagingRequest.create = function create(properties) {
            return new RecommendationStoryIdPagingRequest(properties);
        };

        /**
         * Encodes the specified RecommendationStoryIdPagingRequest message. Does not implicitly {@link recommendation.RecommendationStoryIdPagingRequest.verify|verify} messages.
         * @function encode
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @static
         * @param {recommendation.IRecommendationStoryIdPagingRequest} message RecommendationStoryIdPagingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecommendationStoryIdPagingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.filter);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RecommendationStoryIdPagingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recommendation.RecommendationStoryIdPagingRequest} RecommendationStoryIdPagingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecommendationStoryIdPagingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.recommendation.RecommendationStoryIdPagingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.filter = reader.int32();
                    break;
                case 3:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a RecommendationStoryIdPagingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recommendation.RecommendationStoryIdPagingRequest} RecommendationStoryIdPagingRequest
         */
        RecommendationStoryIdPagingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.recommendation.RecommendationStoryIdPagingRequest)
                return object;
            var message = new $root.recommendation.RecommendationStoryIdPagingRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".recommendation.RecommendationStoryIdPagingRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            switch (object.filter) {
            case "RECOMMEND_STORY_FILTER_UNKNOWN":
            case 0:
                message.filter = 0;
                break;
            case "RECOMMEND_STORY_FILTER_OFFICIAL":
            case 1:
                message.filter = 1;
                break;
            case "RECOMMEND_STORY_FILTER_OFFICIAL_SERIES":
            case 2:
                message.filter = 2;
                break;
            case "RECOMMEND_STORY_FILTER_OFFICIAL_ONESHOT":
            case 3:
                message.filter = 3;
                break;
            case "RECOMMEND_STORY_FILTER_NON_OFFICIAL":
            case 4:
                message.filter = 4;
                break;
            case "RECOMMEND_STORY_FILTER_PROFILING":
            case 5:
                message.filter = 5;
                break;
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".recommendation.RecommendationStoryIdPagingRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".recommendation.RecommendationStoryIdPagingRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a RecommendationStoryIdPagingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @static
         * @param {recommendation.RecommendationStoryIdPagingRequest} message RecommendationStoryIdPagingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecommendationStoryIdPagingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyId = null;
                object.filter = options.enums === String ? "RECOMMEND_STORY_FILTER_UNKNOWN" : 0;
                object.limit = null;
                object.cursor = null;
            }
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = options.enums === String ? $root.recommendation.RecommendationStoryFilter[message.filter] : message.filter;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this RecommendationStoryIdPagingRequest to JSON.
         * @function toJSON
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecommendationStoryIdPagingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecommendationStoryIdPagingRequest
         * @function getTypeUrl
         * @memberof recommendation.RecommendationStoryIdPagingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecommendationStoryIdPagingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recommendation.RecommendationStoryIdPagingRequest";
        };

        return RecommendationStoryIdPagingRequest;
    })();

    recommendation.RecommendationUserIdPagingRequest = (function() {

        /**
         * Properties of a RecommendationUserIdPagingRequest.
         * @memberof recommendation
         * @interface IRecommendationUserIdPagingRequest
         * @property {google.protobuf.IStringValue|null} [userId] RecommendationUserIdPagingRequest userId
         * @property {recommendation.RecommendationStoryFilter|null} [filter] RecommendationUserIdPagingRequest filter
         * @property {google.protobuf.IInt32Value|null} [limit] RecommendationUserIdPagingRequest limit
         * @property {google.protobuf.IStringValue|null} [cursor] RecommendationUserIdPagingRequest cursor
         */

        /**
         * Constructs a new RecommendationUserIdPagingRequest.
         * @memberof recommendation
         * @classdesc Represents a RecommendationUserIdPagingRequest.
         * @implements IRecommendationUserIdPagingRequest
         * @constructor
         * @param {recommendation.IRecommendationUserIdPagingRequest=} [properties] Properties to set
         */
        function RecommendationUserIdPagingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecommendationUserIdPagingRequest userId.
         * @member {google.protobuf.IStringValue|null|undefined} userId
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @instance
         */
        RecommendationUserIdPagingRequest.prototype.userId = null;

        /**
         * RecommendationUserIdPagingRequest filter.
         * @member {recommendation.RecommendationStoryFilter} filter
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @instance
         */
        RecommendationUserIdPagingRequest.prototype.filter = 0;

        /**
         * RecommendationUserIdPagingRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @instance
         */
        RecommendationUserIdPagingRequest.prototype.limit = null;

        /**
         * RecommendationUserIdPagingRequest cursor.
         * @member {google.protobuf.IStringValue|null|undefined} cursor
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @instance
         */
        RecommendationUserIdPagingRequest.prototype.cursor = null;

        /**
         * Creates a new RecommendationUserIdPagingRequest instance using the specified properties.
         * @function create
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @static
         * @param {recommendation.IRecommendationUserIdPagingRequest=} [properties] Properties to set
         * @returns {recommendation.RecommendationUserIdPagingRequest} RecommendationUserIdPagingRequest instance
         */
        RecommendationUserIdPagingRequest.create = function create(properties) {
            return new RecommendationUserIdPagingRequest(properties);
        };

        /**
         * Encodes the specified RecommendationUserIdPagingRequest message. Does not implicitly {@link recommendation.RecommendationUserIdPagingRequest.verify|verify} messages.
         * @function encode
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @static
         * @param {recommendation.IRecommendationUserIdPagingRequest} message RecommendationUserIdPagingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecommendationUserIdPagingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                $root.google.protobuf.StringValue.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.filter);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.cursor != null && Object.hasOwnProperty.call(message, "cursor"))
                $root.google.protobuf.StringValue.encode(message.cursor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RecommendationUserIdPagingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recommendation.RecommendationUserIdPagingRequest} RecommendationUserIdPagingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecommendationUserIdPagingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.recommendation.RecommendationUserIdPagingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.filter = reader.int32();
                    break;
                case 3:
                    message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.cursor = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a RecommendationUserIdPagingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recommendation.RecommendationUserIdPagingRequest} RecommendationUserIdPagingRequest
         */
        RecommendationUserIdPagingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.recommendation.RecommendationUserIdPagingRequest)
                return object;
            var message = new $root.recommendation.RecommendationUserIdPagingRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".recommendation.RecommendationUserIdPagingRequest.userId: object expected");
                message.userId = $root.google.protobuf.StringValue.fromObject(object.userId);
            }
            switch (object.filter) {
            case "RECOMMEND_STORY_FILTER_UNKNOWN":
            case 0:
                message.filter = 0;
                break;
            case "RECOMMEND_STORY_FILTER_OFFICIAL":
            case 1:
                message.filter = 1;
                break;
            case "RECOMMEND_STORY_FILTER_OFFICIAL_SERIES":
            case 2:
                message.filter = 2;
                break;
            case "RECOMMEND_STORY_FILTER_OFFICIAL_ONESHOT":
            case 3:
                message.filter = 3;
                break;
            case "RECOMMEND_STORY_FILTER_NON_OFFICIAL":
            case 4:
                message.filter = 4;
                break;
            case "RECOMMEND_STORY_FILTER_PROFILING":
            case 5:
                message.filter = 5;
                break;
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".recommendation.RecommendationUserIdPagingRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.cursor != null) {
                if (typeof object.cursor !== "object")
                    throw TypeError(".recommendation.RecommendationUserIdPagingRequest.cursor: object expected");
                message.cursor = $root.google.protobuf.StringValue.fromObject(object.cursor);
            }
            return message;
        };

        /**
         * Creates a plain object from a RecommendationUserIdPagingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @static
         * @param {recommendation.RecommendationUserIdPagingRequest} message RecommendationUserIdPagingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecommendationUserIdPagingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = null;
                object.filter = options.enums === String ? "RECOMMEND_STORY_FILTER_UNKNOWN" : 0;
                object.limit = null;
                object.cursor = null;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.google.protobuf.StringValue.toObject(message.userId, options);
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = options.enums === String ? $root.recommendation.RecommendationStoryFilter[message.filter] : message.filter;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.cursor != null && message.hasOwnProperty("cursor"))
                object.cursor = $root.google.protobuf.StringValue.toObject(message.cursor, options);
            return object;
        };

        /**
         * Converts this RecommendationUserIdPagingRequest to JSON.
         * @function toJSON
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecommendationUserIdPagingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecommendationUserIdPagingRequest
         * @function getTypeUrl
         * @memberof recommendation.RecommendationUserIdPagingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecommendationUserIdPagingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recommendation.RecommendationUserIdPagingRequest";
        };

        return RecommendationUserIdPagingRequest;
    })();

    recommendation.RecommendationUserTypeRequest = (function() {

        /**
         * Properties of a RecommendationUserTypeRequest.
         * @memberof recommendation
         * @interface IRecommendationUserTypeRequest
         * @property {recommendation.RecommendationUserType|null} [userType] RecommendationUserTypeRequest userType
         */

        /**
         * Constructs a new RecommendationUserTypeRequest.
         * @memberof recommendation
         * @classdesc Represents a RecommendationUserTypeRequest.
         * @implements IRecommendationUserTypeRequest
         * @constructor
         * @param {recommendation.IRecommendationUserTypeRequest=} [properties] Properties to set
         */
        function RecommendationUserTypeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecommendationUserTypeRequest userType.
         * @member {recommendation.RecommendationUserType} userType
         * @memberof recommendation.RecommendationUserTypeRequest
         * @instance
         */
        RecommendationUserTypeRequest.prototype.userType = 0;

        /**
         * Creates a new RecommendationUserTypeRequest instance using the specified properties.
         * @function create
         * @memberof recommendation.RecommendationUserTypeRequest
         * @static
         * @param {recommendation.IRecommendationUserTypeRequest=} [properties] Properties to set
         * @returns {recommendation.RecommendationUserTypeRequest} RecommendationUserTypeRequest instance
         */
        RecommendationUserTypeRequest.create = function create(properties) {
            return new RecommendationUserTypeRequest(properties);
        };

        /**
         * Encodes the specified RecommendationUserTypeRequest message. Does not implicitly {@link recommendation.RecommendationUserTypeRequest.verify|verify} messages.
         * @function encode
         * @memberof recommendation.RecommendationUserTypeRequest
         * @static
         * @param {recommendation.IRecommendationUserTypeRequest} message RecommendationUserTypeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecommendationUserTypeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.userType);
            return writer;
        };

        /**
         * Decodes a RecommendationUserTypeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof recommendation.RecommendationUserTypeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recommendation.RecommendationUserTypeRequest} RecommendationUserTypeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecommendationUserTypeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.recommendation.RecommendationUserTypeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a RecommendationUserTypeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recommendation.RecommendationUserTypeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recommendation.RecommendationUserTypeRequest} RecommendationUserTypeRequest
         */
        RecommendationUserTypeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.recommendation.RecommendationUserTypeRequest)
                return object;
            var message = new $root.recommendation.RecommendationUserTypeRequest();
            switch (object.userType) {
            case "RECOMMEND_USER_TYPE_UNSPECIFIED":
            case 0:
                message.userType = 0;
                break;
            case "RECOMMEND_USER_TYPE_OFFICIAL":
            case 1:
                message.userType = 1;
                break;
            case "RECOMMEND_USER_TYPE_NON_OFFICIAL":
            case 2:
                message.userType = 2;
                break;
            case "RECOMMEND_USER_TYPE_BOTH":
            case 3:
                message.userType = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RecommendationUserTypeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recommendation.RecommendationUserTypeRequest
         * @static
         * @param {recommendation.RecommendationUserTypeRequest} message RecommendationUserTypeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecommendationUserTypeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.userType = options.enums === String ? "RECOMMEND_USER_TYPE_UNSPECIFIED" : 0;
            if (message.userType != null && message.hasOwnProperty("userType"))
                object.userType = options.enums === String ? $root.recommendation.RecommendationUserType[message.userType] : message.userType;
            return object;
        };

        /**
         * Converts this RecommendationUserTypeRequest to JSON.
         * @function toJSON
         * @memberof recommendation.RecommendationUserTypeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecommendationUserTypeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecommendationUserTypeRequest
         * @function getTypeUrl
         * @memberof recommendation.RecommendationUserTypeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecommendationUserTypeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recommendation.RecommendationUserTypeRequest";
        };

        return RecommendationUserTypeRequest;
    })();

    return recommendation;
})();

$root.search = (function() {

    /**
     * Namespace search.
     * @exports search
     * @namespace
     */
    var search = {};

    search.SearchService = (function() {

        /**
         * Constructs a new SearchService service.
         * @memberof search
         * @classdesc Represents a SearchService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SearchService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SearchService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SearchService;

        /**
         * Creates new SearchService service using the specified rpc implementation.
         * @function create
         * @memberof search.SearchService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {SearchService} RPC service. Useful where requests and/or responses are streamed.
         */
        SearchService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link search.SearchService#issueAPIKeys}.
         * @memberof search.SearchService
         * @typedef IssueAPIKeysCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {search.SearchIssueAPIKeysResponse} [response] SearchIssueAPIKeysResponse
         */

        /**
         * Calls IssueAPIKeys.
         * @function issueAPIKeys
         * @memberof search.SearchService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @param {search.SearchService.IssueAPIKeysCallback} callback Node-style callback called with the error, if any, and SearchIssueAPIKeysResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SearchService.prototype.issueAPIKeys = function issueAPIKeys(request, callback) {
            return this.rpcCall(issueAPIKeys, $root.types.Empty, $root.search.SearchIssueAPIKeysResponse, request, callback);
        }, "name", { value: "IssueAPIKeys" });

        /**
         * Calls IssueAPIKeys.
         * @function issueAPIKeys
         * @memberof search.SearchService
         * @instance
         * @param {types.IEmpty} request Empty message or plain object
         * @returns {Promise<search.SearchIssueAPIKeysResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link search.SearchService#issueAPIKey}.
         * @memberof search.SearchService
         * @typedef IssueAPIKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {search.SearchIssueAPIKeyResponse} [response] SearchIssueAPIKeyResponse
         */

        /**
         * Calls IssueAPIKey.
         * @function issueAPIKey
         * @memberof search.SearchService
         * @instance
         * @param {search.ISearchIssueAPIKeyRequest} request SearchIssueAPIKeyRequest message or plain object
         * @param {search.SearchService.IssueAPIKeyCallback} callback Node-style callback called with the error, if any, and SearchIssueAPIKeyResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SearchService.prototype.issueAPIKey = function issueAPIKey(request, callback) {
            return this.rpcCall(issueAPIKey, $root.search.SearchIssueAPIKeyRequest, $root.search.SearchIssueAPIKeyResponse, request, callback);
        }, "name", { value: "IssueAPIKey" });

        /**
         * Calls IssueAPIKey.
         * @function issueAPIKey
         * @memberof search.SearchService
         * @instance
         * @param {search.ISearchIssueAPIKeyRequest} request SearchIssueAPIKeyRequest message or plain object
         * @returns {Promise<search.SearchIssueAPIKeyResponse>} Promise
         * @variation 2
         */

        return SearchService;
    })();

    search.SearchIssueAPIKeyRequest = (function() {

        /**
         * Properties of a SearchIssueAPIKeyRequest.
         * @memberof search
         * @interface ISearchIssueAPIKeyRequest
         */

        /**
         * Constructs a new SearchIssueAPIKeyRequest.
         * @memberof search
         * @classdesc Represents a SearchIssueAPIKeyRequest.
         * @implements ISearchIssueAPIKeyRequest
         * @constructor
         * @param {search.ISearchIssueAPIKeyRequest=} [properties] Properties to set
         */
        function SearchIssueAPIKeyRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SearchIssueAPIKeyRequest instance using the specified properties.
         * @function create
         * @memberof search.SearchIssueAPIKeyRequest
         * @static
         * @param {search.ISearchIssueAPIKeyRequest=} [properties] Properties to set
         * @returns {search.SearchIssueAPIKeyRequest} SearchIssueAPIKeyRequest instance
         */
        SearchIssueAPIKeyRequest.create = function create(properties) {
            return new SearchIssueAPIKeyRequest(properties);
        };

        /**
         * Encodes the specified SearchIssueAPIKeyRequest message. Does not implicitly {@link search.SearchIssueAPIKeyRequest.verify|verify} messages.
         * @function encode
         * @memberof search.SearchIssueAPIKeyRequest
         * @static
         * @param {search.ISearchIssueAPIKeyRequest} message SearchIssueAPIKeyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchIssueAPIKeyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a SearchIssueAPIKeyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof search.SearchIssueAPIKeyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.SearchIssueAPIKeyRequest} SearchIssueAPIKeyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchIssueAPIKeyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SearchIssueAPIKeyRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchIssueAPIKeyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.SearchIssueAPIKeyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.SearchIssueAPIKeyRequest} SearchIssueAPIKeyRequest
         */
        SearchIssueAPIKeyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.search.SearchIssueAPIKeyRequest)
                return object;
            return new $root.search.SearchIssueAPIKeyRequest();
        };

        /**
         * Creates a plain object from a SearchIssueAPIKeyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.SearchIssueAPIKeyRequest
         * @static
         * @param {search.SearchIssueAPIKeyRequest} message SearchIssueAPIKeyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchIssueAPIKeyRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SearchIssueAPIKeyRequest to JSON.
         * @function toJSON
         * @memberof search.SearchIssueAPIKeyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchIssueAPIKeyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchIssueAPIKeyRequest
         * @function getTypeUrl
         * @memberof search.SearchIssueAPIKeyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchIssueAPIKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.SearchIssueAPIKeyRequest";
        };

        return SearchIssueAPIKeyRequest;
    })();

    search.SearchIssueAPIKeyResponse = (function() {

        /**
         * Properties of a SearchIssueAPIKeyResponse.
         * @memberof search
         * @interface ISearchIssueAPIKeyResponse
         * @property {google.protobuf.IStringValue|null} [appId] SearchIssueAPIKeyResponse appId
         * @property {search.ISearchIndicesName|null} [indices] SearchIssueAPIKeyResponse indices
         * @property {google.protobuf.IStringValue|null} [publicApiKey] SearchIssueAPIKeyResponse publicApiKey
         * @property {google.protobuf.IStringValue|null} [privateApiKey] SearchIssueAPIKeyResponse privateApiKey
         * @property {google.protobuf.ITimestamp|null} [expiresAt] SearchIssueAPIKeyResponse expiresAt
         */

        /**
         * Constructs a new SearchIssueAPIKeyResponse.
         * @memberof search
         * @classdesc Represents a SearchIssueAPIKeyResponse.
         * @implements ISearchIssueAPIKeyResponse
         * @constructor
         * @param {search.ISearchIssueAPIKeyResponse=} [properties] Properties to set
         */
        function SearchIssueAPIKeyResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchIssueAPIKeyResponse appId.
         * @member {google.protobuf.IStringValue|null|undefined} appId
         * @memberof search.SearchIssueAPIKeyResponse
         * @instance
         */
        SearchIssueAPIKeyResponse.prototype.appId = null;

        /**
         * SearchIssueAPIKeyResponse indices.
         * @member {search.ISearchIndicesName|null|undefined} indices
         * @memberof search.SearchIssueAPIKeyResponse
         * @instance
         */
        SearchIssueAPIKeyResponse.prototype.indices = null;

        /**
         * SearchIssueAPIKeyResponse publicApiKey.
         * @member {google.protobuf.IStringValue|null|undefined} publicApiKey
         * @memberof search.SearchIssueAPIKeyResponse
         * @instance
         */
        SearchIssueAPIKeyResponse.prototype.publicApiKey = null;

        /**
         * SearchIssueAPIKeyResponse privateApiKey.
         * @member {google.protobuf.IStringValue|null|undefined} privateApiKey
         * @memberof search.SearchIssueAPIKeyResponse
         * @instance
         */
        SearchIssueAPIKeyResponse.prototype.privateApiKey = null;

        /**
         * SearchIssueAPIKeyResponse expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof search.SearchIssueAPIKeyResponse
         * @instance
         */
        SearchIssueAPIKeyResponse.prototype.expiresAt = null;

        /**
         * Creates a new SearchIssueAPIKeyResponse instance using the specified properties.
         * @function create
         * @memberof search.SearchIssueAPIKeyResponse
         * @static
         * @param {search.ISearchIssueAPIKeyResponse=} [properties] Properties to set
         * @returns {search.SearchIssueAPIKeyResponse} SearchIssueAPIKeyResponse instance
         */
        SearchIssueAPIKeyResponse.create = function create(properties) {
            return new SearchIssueAPIKeyResponse(properties);
        };

        /**
         * Encodes the specified SearchIssueAPIKeyResponse message. Does not implicitly {@link search.SearchIssueAPIKeyResponse.verify|verify} messages.
         * @function encode
         * @memberof search.SearchIssueAPIKeyResponse
         * @static
         * @param {search.ISearchIssueAPIKeyResponse} message SearchIssueAPIKeyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchIssueAPIKeyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                $root.google.protobuf.StringValue.encode(message.appId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.indices != null && Object.hasOwnProperty.call(message, "indices"))
                $root.search.SearchIndicesName.encode(message.indices, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.publicApiKey != null && Object.hasOwnProperty.call(message, "publicApiKey"))
                $root.google.protobuf.StringValue.encode(message.publicApiKey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.privateApiKey != null && Object.hasOwnProperty.call(message, "privateApiKey"))
                $root.google.protobuf.StringValue.encode(message.privateApiKey, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchIssueAPIKeyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof search.SearchIssueAPIKeyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.SearchIssueAPIKeyResponse} SearchIssueAPIKeyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchIssueAPIKeyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SearchIssueAPIKeyResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.appId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.indices = $root.search.SearchIndicesName.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.publicApiKey = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.privateApiKey = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchIssueAPIKeyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.SearchIssueAPIKeyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.SearchIssueAPIKeyResponse} SearchIssueAPIKeyResponse
         */
        SearchIssueAPIKeyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.search.SearchIssueAPIKeyResponse)
                return object;
            var message = new $root.search.SearchIssueAPIKeyResponse();
            if (object.appId != null) {
                if (typeof object.appId !== "object")
                    throw TypeError(".search.SearchIssueAPIKeyResponse.appId: object expected");
                message.appId = $root.google.protobuf.StringValue.fromObject(object.appId);
            }
            if (object.indices != null) {
                if (typeof object.indices !== "object")
                    throw TypeError(".search.SearchIssueAPIKeyResponse.indices: object expected");
                message.indices = $root.search.SearchIndicesName.fromObject(object.indices);
            }
            if (object.publicApiKey != null) {
                if (typeof object.publicApiKey !== "object")
                    throw TypeError(".search.SearchIssueAPIKeyResponse.publicApiKey: object expected");
                message.publicApiKey = $root.google.protobuf.StringValue.fromObject(object.publicApiKey);
            }
            if (object.privateApiKey != null) {
                if (typeof object.privateApiKey !== "object")
                    throw TypeError(".search.SearchIssueAPIKeyResponse.privateApiKey: object expected");
                message.privateApiKey = $root.google.protobuf.StringValue.fromObject(object.privateApiKey);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".search.SearchIssueAPIKeyResponse.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchIssueAPIKeyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.SearchIssueAPIKeyResponse
         * @static
         * @param {search.SearchIssueAPIKeyResponse} message SearchIssueAPIKeyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchIssueAPIKeyResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = null;
                object.indices = null;
                object.publicApiKey = null;
                object.privateApiKey = null;
                object.expiresAt = null;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = $root.google.protobuf.StringValue.toObject(message.appId, options);
            if (message.indices != null && message.hasOwnProperty("indices"))
                object.indices = $root.search.SearchIndicesName.toObject(message.indices, options);
            if (message.publicApiKey != null && message.hasOwnProperty("publicApiKey"))
                object.publicApiKey = $root.google.protobuf.StringValue.toObject(message.publicApiKey, options);
            if (message.privateApiKey != null && message.hasOwnProperty("privateApiKey"))
                object.privateApiKey = $root.google.protobuf.StringValue.toObject(message.privateApiKey, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            return object;
        };

        /**
         * Converts this SearchIssueAPIKeyResponse to JSON.
         * @function toJSON
         * @memberof search.SearchIssueAPIKeyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchIssueAPIKeyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchIssueAPIKeyResponse
         * @function getTypeUrl
         * @memberof search.SearchIssueAPIKeyResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchIssueAPIKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.SearchIssueAPIKeyResponse";
        };

        return SearchIssueAPIKeyResponse;
    })();

    search.SearchIndicesName = (function() {

        /**
         * Properties of a SearchIndicesName.
         * @memberof search
         * @interface ISearchIndicesName
         * @property {google.protobuf.IStringValue|null} [storyPublishedAtAsc] SearchIndicesName storyPublishedAtAsc
         * @property {google.protobuf.IStringValue|null} [storyPublishedAtDesc] SearchIndicesName storyPublishedAtDesc
         */

        /**
         * Constructs a new SearchIndicesName.
         * @memberof search
         * @classdesc Represents a SearchIndicesName.
         * @implements ISearchIndicesName
         * @constructor
         * @param {search.ISearchIndicesName=} [properties] Properties to set
         */
        function SearchIndicesName(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchIndicesName storyPublishedAtAsc.
         * @member {google.protobuf.IStringValue|null|undefined} storyPublishedAtAsc
         * @memberof search.SearchIndicesName
         * @instance
         */
        SearchIndicesName.prototype.storyPublishedAtAsc = null;

        /**
         * SearchIndicesName storyPublishedAtDesc.
         * @member {google.protobuf.IStringValue|null|undefined} storyPublishedAtDesc
         * @memberof search.SearchIndicesName
         * @instance
         */
        SearchIndicesName.prototype.storyPublishedAtDesc = null;

        /**
         * Creates a new SearchIndicesName instance using the specified properties.
         * @function create
         * @memberof search.SearchIndicesName
         * @static
         * @param {search.ISearchIndicesName=} [properties] Properties to set
         * @returns {search.SearchIndicesName} SearchIndicesName instance
         */
        SearchIndicesName.create = function create(properties) {
            return new SearchIndicesName(properties);
        };

        /**
         * Encodes the specified SearchIndicesName message. Does not implicitly {@link search.SearchIndicesName.verify|verify} messages.
         * @function encode
         * @memberof search.SearchIndicesName
         * @static
         * @param {search.ISearchIndicesName} message SearchIndicesName message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchIndicesName.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyPublishedAtAsc != null && Object.hasOwnProperty.call(message, "storyPublishedAtAsc"))
                $root.google.protobuf.StringValue.encode(message.storyPublishedAtAsc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.storyPublishedAtDesc != null && Object.hasOwnProperty.call(message, "storyPublishedAtDesc"))
                $root.google.protobuf.StringValue.encode(message.storyPublishedAtDesc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchIndicesName message from the specified reader or buffer.
         * @function decode
         * @memberof search.SearchIndicesName
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.SearchIndicesName} SearchIndicesName
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchIndicesName.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SearchIndicesName();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyPublishedAtAsc = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.storyPublishedAtDesc = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchIndicesName message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.SearchIndicesName
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.SearchIndicesName} SearchIndicesName
         */
        SearchIndicesName.fromObject = function fromObject(object) {
            if (object instanceof $root.search.SearchIndicesName)
                return object;
            var message = new $root.search.SearchIndicesName();
            if (object.storyPublishedAtAsc != null) {
                if (typeof object.storyPublishedAtAsc !== "object")
                    throw TypeError(".search.SearchIndicesName.storyPublishedAtAsc: object expected");
                message.storyPublishedAtAsc = $root.google.protobuf.StringValue.fromObject(object.storyPublishedAtAsc);
            }
            if (object.storyPublishedAtDesc != null) {
                if (typeof object.storyPublishedAtDesc !== "object")
                    throw TypeError(".search.SearchIndicesName.storyPublishedAtDesc: object expected");
                message.storyPublishedAtDesc = $root.google.protobuf.StringValue.fromObject(object.storyPublishedAtDesc);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchIndicesName message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.SearchIndicesName
         * @static
         * @param {search.SearchIndicesName} message SearchIndicesName
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchIndicesName.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.storyPublishedAtAsc = null;
                object.storyPublishedAtDesc = null;
            }
            if (message.storyPublishedAtAsc != null && message.hasOwnProperty("storyPublishedAtAsc"))
                object.storyPublishedAtAsc = $root.google.protobuf.StringValue.toObject(message.storyPublishedAtAsc, options);
            if (message.storyPublishedAtDesc != null && message.hasOwnProperty("storyPublishedAtDesc"))
                object.storyPublishedAtDesc = $root.google.protobuf.StringValue.toObject(message.storyPublishedAtDesc, options);
            return object;
        };

        /**
         * Converts this SearchIndicesName to JSON.
         * @function toJSON
         * @memberof search.SearchIndicesName
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchIndicesName.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchIndicesName
         * @function getTypeUrl
         * @memberof search.SearchIndicesName
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchIndicesName.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.SearchIndicesName";
        };

        return SearchIndicesName;
    })();

    search.SearchAPIKey = (function() {

        /**
         * Properties of a SearchAPIKey.
         * @memberof search
         * @interface ISearchAPIKey
         * @property {google.protobuf.IStringValue|null} [publicApiKey] SearchAPIKey publicApiKey
         * @property {google.protobuf.IStringValue|null} [privateApiKey] SearchAPIKey privateApiKey
         * @property {google.protobuf.ITimestamp|null} [expiresAt] SearchAPIKey expiresAt
         */

        /**
         * Constructs a new SearchAPIKey.
         * @memberof search
         * @classdesc Represents a SearchAPIKey.
         * @implements ISearchAPIKey
         * @constructor
         * @param {search.ISearchAPIKey=} [properties] Properties to set
         */
        function SearchAPIKey(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchAPIKey publicApiKey.
         * @member {google.protobuf.IStringValue|null|undefined} publicApiKey
         * @memberof search.SearchAPIKey
         * @instance
         */
        SearchAPIKey.prototype.publicApiKey = null;

        /**
         * SearchAPIKey privateApiKey.
         * @member {google.protobuf.IStringValue|null|undefined} privateApiKey
         * @memberof search.SearchAPIKey
         * @instance
         */
        SearchAPIKey.prototype.privateApiKey = null;

        /**
         * SearchAPIKey expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof search.SearchAPIKey
         * @instance
         */
        SearchAPIKey.prototype.expiresAt = null;

        /**
         * Creates a new SearchAPIKey instance using the specified properties.
         * @function create
         * @memberof search.SearchAPIKey
         * @static
         * @param {search.ISearchAPIKey=} [properties] Properties to set
         * @returns {search.SearchAPIKey} SearchAPIKey instance
         */
        SearchAPIKey.create = function create(properties) {
            return new SearchAPIKey(properties);
        };

        /**
         * Encodes the specified SearchAPIKey message. Does not implicitly {@link search.SearchAPIKey.verify|verify} messages.
         * @function encode
         * @memberof search.SearchAPIKey
         * @static
         * @param {search.ISearchAPIKey} message SearchAPIKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchAPIKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.publicApiKey != null && Object.hasOwnProperty.call(message, "publicApiKey"))
                $root.google.protobuf.StringValue.encode(message.publicApiKey, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.privateApiKey != null && Object.hasOwnProperty.call(message, "privateApiKey"))
                $root.google.protobuf.StringValue.encode(message.privateApiKey, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchAPIKey message from the specified reader or buffer.
         * @function decode
         * @memberof search.SearchAPIKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.SearchAPIKey} SearchAPIKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchAPIKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SearchAPIKey();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.publicApiKey = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.privateApiKey = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchAPIKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.SearchAPIKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.SearchAPIKey} SearchAPIKey
         */
        SearchAPIKey.fromObject = function fromObject(object) {
            if (object instanceof $root.search.SearchAPIKey)
                return object;
            var message = new $root.search.SearchAPIKey();
            if (object.publicApiKey != null) {
                if (typeof object.publicApiKey !== "object")
                    throw TypeError(".search.SearchAPIKey.publicApiKey: object expected");
                message.publicApiKey = $root.google.protobuf.StringValue.fromObject(object.publicApiKey);
            }
            if (object.privateApiKey != null) {
                if (typeof object.privateApiKey !== "object")
                    throw TypeError(".search.SearchAPIKey.privateApiKey: object expected");
                message.privateApiKey = $root.google.protobuf.StringValue.fromObject(object.privateApiKey);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".search.SearchAPIKey.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchAPIKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.SearchAPIKey
         * @static
         * @param {search.SearchAPIKey} message SearchAPIKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchAPIKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.publicApiKey = null;
                object.privateApiKey = null;
                object.expiresAt = null;
            }
            if (message.publicApiKey != null && message.hasOwnProperty("publicApiKey"))
                object.publicApiKey = $root.google.protobuf.StringValue.toObject(message.publicApiKey, options);
            if (message.privateApiKey != null && message.hasOwnProperty("privateApiKey"))
                object.privateApiKey = $root.google.protobuf.StringValue.toObject(message.privateApiKey, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            return object;
        };

        /**
         * Converts this SearchAPIKey to JSON.
         * @function toJSON
         * @memberof search.SearchAPIKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchAPIKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchAPIKey
         * @function getTypeUrl
         * @memberof search.SearchAPIKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchAPIKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.SearchAPIKey";
        };

        return SearchAPIKey;
    })();

    search.StoryAPIKey = (function() {

        /**
         * Properties of a StoryAPIKey.
         * @memberof search
         * @interface IStoryAPIKey
         * @property {search.ISearchAPIKey|null} [key] StoryAPIKey key
         * @property {search.StoryAPIKey.IStoryIndices|null} [indices] StoryAPIKey indices
         */

        /**
         * Constructs a new StoryAPIKey.
         * @memberof search
         * @classdesc Represents a StoryAPIKey.
         * @implements IStoryAPIKey
         * @constructor
         * @param {search.IStoryAPIKey=} [properties] Properties to set
         */
        function StoryAPIKey(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAPIKey key.
         * @member {search.ISearchAPIKey|null|undefined} key
         * @memberof search.StoryAPIKey
         * @instance
         */
        StoryAPIKey.prototype.key = null;

        /**
         * StoryAPIKey indices.
         * @member {search.StoryAPIKey.IStoryIndices|null|undefined} indices
         * @memberof search.StoryAPIKey
         * @instance
         */
        StoryAPIKey.prototype.indices = null;

        /**
         * Creates a new StoryAPIKey instance using the specified properties.
         * @function create
         * @memberof search.StoryAPIKey
         * @static
         * @param {search.IStoryAPIKey=} [properties] Properties to set
         * @returns {search.StoryAPIKey} StoryAPIKey instance
         */
        StoryAPIKey.create = function create(properties) {
            return new StoryAPIKey(properties);
        };

        /**
         * Encodes the specified StoryAPIKey message. Does not implicitly {@link search.StoryAPIKey.verify|verify} messages.
         * @function encode
         * @memberof search.StoryAPIKey
         * @static
         * @param {search.IStoryAPIKey} message StoryAPIKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAPIKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                $root.search.SearchAPIKey.encode(message.key, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.indices != null && Object.hasOwnProperty.call(message, "indices"))
                $root.search.StoryAPIKey.StoryIndices.encode(message.indices, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAPIKey message from the specified reader or buffer.
         * @function decode
         * @memberof search.StoryAPIKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.StoryAPIKey} StoryAPIKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAPIKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.StoryAPIKey();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = $root.search.SearchAPIKey.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.indices = $root.search.StoryAPIKey.StoryIndices.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAPIKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.StoryAPIKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.StoryAPIKey} StoryAPIKey
         */
        StoryAPIKey.fromObject = function fromObject(object) {
            if (object instanceof $root.search.StoryAPIKey)
                return object;
            var message = new $root.search.StoryAPIKey();
            if (object.key != null) {
                if (typeof object.key !== "object")
                    throw TypeError(".search.StoryAPIKey.key: object expected");
                message.key = $root.search.SearchAPIKey.fromObject(object.key);
            }
            if (object.indices != null) {
                if (typeof object.indices !== "object")
                    throw TypeError(".search.StoryAPIKey.indices: object expected");
                message.indices = $root.search.StoryAPIKey.StoryIndices.fromObject(object.indices);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAPIKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.StoryAPIKey
         * @static
         * @param {search.StoryAPIKey} message StoryAPIKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAPIKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.key = null;
                object.indices = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = $root.search.SearchAPIKey.toObject(message.key, options);
            if (message.indices != null && message.hasOwnProperty("indices"))
                object.indices = $root.search.StoryAPIKey.StoryIndices.toObject(message.indices, options);
            return object;
        };

        /**
         * Converts this StoryAPIKey to JSON.
         * @function toJSON
         * @memberof search.StoryAPIKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAPIKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAPIKey
         * @function getTypeUrl
         * @memberof search.StoryAPIKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAPIKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.StoryAPIKey";
        };

        StoryAPIKey.StoryIndices = (function() {

            /**
             * Properties of a StoryIndices.
             * @memberof search.StoryAPIKey
             * @interface IStoryIndices
             * @property {google.protobuf.IStringValue|null} [storyMaster] StoryIndices storyMaster
             * @property {google.protobuf.IStringValue|null} [storyPublishedAtAsc] StoryIndices storyPublishedAtAsc
             * @property {google.protobuf.IStringValue|null} [storyPublishedAtDesc] StoryIndices storyPublishedAtDesc
             * @property {google.protobuf.IStringValue|null} [storyUpdatedAtDesc] StoryIndices storyUpdatedAtDesc
             */

            /**
             * Constructs a new StoryIndices.
             * @memberof search.StoryAPIKey
             * @classdesc Represents a StoryIndices.
             * @implements IStoryIndices
             * @constructor
             * @param {search.StoryAPIKey.IStoryIndices=} [properties] Properties to set
             */
            function StoryIndices(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StoryIndices storyMaster.
             * @member {google.protobuf.IStringValue|null|undefined} storyMaster
             * @memberof search.StoryAPIKey.StoryIndices
             * @instance
             */
            StoryIndices.prototype.storyMaster = null;

            /**
             * StoryIndices storyPublishedAtAsc.
             * @member {google.protobuf.IStringValue|null|undefined} storyPublishedAtAsc
             * @memberof search.StoryAPIKey.StoryIndices
             * @instance
             */
            StoryIndices.prototype.storyPublishedAtAsc = null;

            /**
             * StoryIndices storyPublishedAtDesc.
             * @member {google.protobuf.IStringValue|null|undefined} storyPublishedAtDesc
             * @memberof search.StoryAPIKey.StoryIndices
             * @instance
             */
            StoryIndices.prototype.storyPublishedAtDesc = null;

            /**
             * StoryIndices storyUpdatedAtDesc.
             * @member {google.protobuf.IStringValue|null|undefined} storyUpdatedAtDesc
             * @memberof search.StoryAPIKey.StoryIndices
             * @instance
             */
            StoryIndices.prototype.storyUpdatedAtDesc = null;

            /**
             * Creates a new StoryIndices instance using the specified properties.
             * @function create
             * @memberof search.StoryAPIKey.StoryIndices
             * @static
             * @param {search.StoryAPIKey.IStoryIndices=} [properties] Properties to set
             * @returns {search.StoryAPIKey.StoryIndices} StoryIndices instance
             */
            StoryIndices.create = function create(properties) {
                return new StoryIndices(properties);
            };

            /**
             * Encodes the specified StoryIndices message. Does not implicitly {@link search.StoryAPIKey.StoryIndices.verify|verify} messages.
             * @function encode
             * @memberof search.StoryAPIKey.StoryIndices
             * @static
             * @param {search.StoryAPIKey.IStoryIndices} message StoryIndices message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StoryIndices.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.storyMaster != null && Object.hasOwnProperty.call(message, "storyMaster"))
                    $root.google.protobuf.StringValue.encode(message.storyMaster, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.storyPublishedAtAsc != null && Object.hasOwnProperty.call(message, "storyPublishedAtAsc"))
                    $root.google.protobuf.StringValue.encode(message.storyPublishedAtAsc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.storyPublishedAtDesc != null && Object.hasOwnProperty.call(message, "storyPublishedAtDesc"))
                    $root.google.protobuf.StringValue.encode(message.storyPublishedAtDesc, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.storyUpdatedAtDesc != null && Object.hasOwnProperty.call(message, "storyUpdatedAtDesc"))
                    $root.google.protobuf.StringValue.encode(message.storyUpdatedAtDesc, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a StoryIndices message from the specified reader or buffer.
             * @function decode
             * @memberof search.StoryAPIKey.StoryIndices
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {search.StoryAPIKey.StoryIndices} StoryIndices
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StoryIndices.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.StoryAPIKey.StoryIndices();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.storyMaster = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.storyPublishedAtAsc = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.storyPublishedAtDesc = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.storyUpdatedAtDesc = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a StoryIndices message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof search.StoryAPIKey.StoryIndices
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {search.StoryAPIKey.StoryIndices} StoryIndices
             */
            StoryIndices.fromObject = function fromObject(object) {
                if (object instanceof $root.search.StoryAPIKey.StoryIndices)
                    return object;
                var message = new $root.search.StoryAPIKey.StoryIndices();
                if (object.storyMaster != null) {
                    if (typeof object.storyMaster !== "object")
                        throw TypeError(".search.StoryAPIKey.StoryIndices.storyMaster: object expected");
                    message.storyMaster = $root.google.protobuf.StringValue.fromObject(object.storyMaster);
                }
                if (object.storyPublishedAtAsc != null) {
                    if (typeof object.storyPublishedAtAsc !== "object")
                        throw TypeError(".search.StoryAPIKey.StoryIndices.storyPublishedAtAsc: object expected");
                    message.storyPublishedAtAsc = $root.google.protobuf.StringValue.fromObject(object.storyPublishedAtAsc);
                }
                if (object.storyPublishedAtDesc != null) {
                    if (typeof object.storyPublishedAtDesc !== "object")
                        throw TypeError(".search.StoryAPIKey.StoryIndices.storyPublishedAtDesc: object expected");
                    message.storyPublishedAtDesc = $root.google.protobuf.StringValue.fromObject(object.storyPublishedAtDesc);
                }
                if (object.storyUpdatedAtDesc != null) {
                    if (typeof object.storyUpdatedAtDesc !== "object")
                        throw TypeError(".search.StoryAPIKey.StoryIndices.storyUpdatedAtDesc: object expected");
                    message.storyUpdatedAtDesc = $root.google.protobuf.StringValue.fromObject(object.storyUpdatedAtDesc);
                }
                return message;
            };

            /**
             * Creates a plain object from a StoryIndices message. Also converts values to other types if specified.
             * @function toObject
             * @memberof search.StoryAPIKey.StoryIndices
             * @static
             * @param {search.StoryAPIKey.StoryIndices} message StoryIndices
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StoryIndices.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.storyMaster = null;
                    object.storyPublishedAtAsc = null;
                    object.storyPublishedAtDesc = null;
                    object.storyUpdatedAtDesc = null;
                }
                if (message.storyMaster != null && message.hasOwnProperty("storyMaster"))
                    object.storyMaster = $root.google.protobuf.StringValue.toObject(message.storyMaster, options);
                if (message.storyPublishedAtAsc != null && message.hasOwnProperty("storyPublishedAtAsc"))
                    object.storyPublishedAtAsc = $root.google.protobuf.StringValue.toObject(message.storyPublishedAtAsc, options);
                if (message.storyPublishedAtDesc != null && message.hasOwnProperty("storyPublishedAtDesc"))
                    object.storyPublishedAtDesc = $root.google.protobuf.StringValue.toObject(message.storyPublishedAtDesc, options);
                if (message.storyUpdatedAtDesc != null && message.hasOwnProperty("storyUpdatedAtDesc"))
                    object.storyUpdatedAtDesc = $root.google.protobuf.StringValue.toObject(message.storyUpdatedAtDesc, options);
                return object;
            };

            /**
             * Converts this StoryIndices to JSON.
             * @function toJSON
             * @memberof search.StoryAPIKey.StoryIndices
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StoryIndices.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StoryIndices
             * @function getTypeUrl
             * @memberof search.StoryAPIKey.StoryIndices
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StoryIndices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/search.StoryAPIKey.StoryIndices";
            };

            return StoryIndices;
        })();

        return StoryAPIKey;
    })();

    search.SeriesAPIKey = (function() {

        /**
         * Properties of a SeriesAPIKey.
         * @memberof search
         * @interface ISeriesAPIKey
         * @property {search.ISearchAPIKey|null} [key] SeriesAPIKey key
         * @property {search.SeriesAPIKey.ISeriesIndices|null} [indices] SeriesAPIKey indices
         */

        /**
         * Constructs a new SeriesAPIKey.
         * @memberof search
         * @classdesc Represents a SeriesAPIKey.
         * @implements ISeriesAPIKey
         * @constructor
         * @param {search.ISeriesAPIKey=} [properties] Properties to set
         */
        function SeriesAPIKey(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesAPIKey key.
         * @member {search.ISearchAPIKey|null|undefined} key
         * @memberof search.SeriesAPIKey
         * @instance
         */
        SeriesAPIKey.prototype.key = null;

        /**
         * SeriesAPIKey indices.
         * @member {search.SeriesAPIKey.ISeriesIndices|null|undefined} indices
         * @memberof search.SeriesAPIKey
         * @instance
         */
        SeriesAPIKey.prototype.indices = null;

        /**
         * Creates a new SeriesAPIKey instance using the specified properties.
         * @function create
         * @memberof search.SeriesAPIKey
         * @static
         * @param {search.ISeriesAPIKey=} [properties] Properties to set
         * @returns {search.SeriesAPIKey} SeriesAPIKey instance
         */
        SeriesAPIKey.create = function create(properties) {
            return new SeriesAPIKey(properties);
        };

        /**
         * Encodes the specified SeriesAPIKey message. Does not implicitly {@link search.SeriesAPIKey.verify|verify} messages.
         * @function encode
         * @memberof search.SeriesAPIKey
         * @static
         * @param {search.ISeriesAPIKey} message SeriesAPIKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesAPIKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                $root.search.SearchAPIKey.encode(message.key, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.indices != null && Object.hasOwnProperty.call(message, "indices"))
                $root.search.SeriesAPIKey.SeriesIndices.encode(message.indices, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesAPIKey message from the specified reader or buffer.
         * @function decode
         * @memberof search.SeriesAPIKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.SeriesAPIKey} SeriesAPIKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesAPIKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SeriesAPIKey();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = $root.search.SearchAPIKey.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.indices = $root.search.SeriesAPIKey.SeriesIndices.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesAPIKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.SeriesAPIKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.SeriesAPIKey} SeriesAPIKey
         */
        SeriesAPIKey.fromObject = function fromObject(object) {
            if (object instanceof $root.search.SeriesAPIKey)
                return object;
            var message = new $root.search.SeriesAPIKey();
            if (object.key != null) {
                if (typeof object.key !== "object")
                    throw TypeError(".search.SeriesAPIKey.key: object expected");
                message.key = $root.search.SearchAPIKey.fromObject(object.key);
            }
            if (object.indices != null) {
                if (typeof object.indices !== "object")
                    throw TypeError(".search.SeriesAPIKey.indices: object expected");
                message.indices = $root.search.SeriesAPIKey.SeriesIndices.fromObject(object.indices);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesAPIKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.SeriesAPIKey
         * @static
         * @param {search.SeriesAPIKey} message SeriesAPIKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesAPIKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.key = null;
                object.indices = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = $root.search.SearchAPIKey.toObject(message.key, options);
            if (message.indices != null && message.hasOwnProperty("indices"))
                object.indices = $root.search.SeriesAPIKey.SeriesIndices.toObject(message.indices, options);
            return object;
        };

        /**
         * Converts this SeriesAPIKey to JSON.
         * @function toJSON
         * @memberof search.SeriesAPIKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesAPIKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesAPIKey
         * @function getTypeUrl
         * @memberof search.SeriesAPIKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesAPIKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.SeriesAPIKey";
        };

        SeriesAPIKey.SeriesIndices = (function() {

            /**
             * Properties of a SeriesIndices.
             * @memberof search.SeriesAPIKey
             * @interface ISeriesIndices
             * @property {google.protobuf.IStringValue|null} [seriesMaster] SeriesIndices seriesMaster
             * @property {google.protobuf.IStringValue|null} [seriesLatestPublishedAtAsc] SeriesIndices seriesLatestPublishedAtAsc
             * @property {google.protobuf.IStringValue|null} [seriesLatestPublishedAtDesc] SeriesIndices seriesLatestPublishedAtDesc
             */

            /**
             * Constructs a new SeriesIndices.
             * @memberof search.SeriesAPIKey
             * @classdesc Represents a SeriesIndices.
             * @implements ISeriesIndices
             * @constructor
             * @param {search.SeriesAPIKey.ISeriesIndices=} [properties] Properties to set
             */
            function SeriesIndices(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SeriesIndices seriesMaster.
             * @member {google.protobuf.IStringValue|null|undefined} seriesMaster
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @instance
             */
            SeriesIndices.prototype.seriesMaster = null;

            /**
             * SeriesIndices seriesLatestPublishedAtAsc.
             * @member {google.protobuf.IStringValue|null|undefined} seriesLatestPublishedAtAsc
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @instance
             */
            SeriesIndices.prototype.seriesLatestPublishedAtAsc = null;

            /**
             * SeriesIndices seriesLatestPublishedAtDesc.
             * @member {google.protobuf.IStringValue|null|undefined} seriesLatestPublishedAtDesc
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @instance
             */
            SeriesIndices.prototype.seriesLatestPublishedAtDesc = null;

            /**
             * Creates a new SeriesIndices instance using the specified properties.
             * @function create
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @static
             * @param {search.SeriesAPIKey.ISeriesIndices=} [properties] Properties to set
             * @returns {search.SeriesAPIKey.SeriesIndices} SeriesIndices instance
             */
            SeriesIndices.create = function create(properties) {
                return new SeriesIndices(properties);
            };

            /**
             * Encodes the specified SeriesIndices message. Does not implicitly {@link search.SeriesAPIKey.SeriesIndices.verify|verify} messages.
             * @function encode
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @static
             * @param {search.SeriesAPIKey.ISeriesIndices} message SeriesIndices message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SeriesIndices.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seriesMaster != null && Object.hasOwnProperty.call(message, "seriesMaster"))
                    $root.google.protobuf.StringValue.encode(message.seriesMaster, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.seriesLatestPublishedAtAsc != null && Object.hasOwnProperty.call(message, "seriesLatestPublishedAtAsc"))
                    $root.google.protobuf.StringValue.encode(message.seriesLatestPublishedAtAsc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.seriesLatestPublishedAtDesc != null && Object.hasOwnProperty.call(message, "seriesLatestPublishedAtDesc"))
                    $root.google.protobuf.StringValue.encode(message.seriesLatestPublishedAtDesc, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SeriesIndices message from the specified reader or buffer.
             * @function decode
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {search.SeriesAPIKey.SeriesIndices} SeriesIndices
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SeriesIndices.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SeriesAPIKey.SeriesIndices();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seriesMaster = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.seriesLatestPublishedAtAsc = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.seriesLatestPublishedAtDesc = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a SeriesIndices message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {search.SeriesAPIKey.SeriesIndices} SeriesIndices
             */
            SeriesIndices.fromObject = function fromObject(object) {
                if (object instanceof $root.search.SeriesAPIKey.SeriesIndices)
                    return object;
                var message = new $root.search.SeriesAPIKey.SeriesIndices();
                if (object.seriesMaster != null) {
                    if (typeof object.seriesMaster !== "object")
                        throw TypeError(".search.SeriesAPIKey.SeriesIndices.seriesMaster: object expected");
                    message.seriesMaster = $root.google.protobuf.StringValue.fromObject(object.seriesMaster);
                }
                if (object.seriesLatestPublishedAtAsc != null) {
                    if (typeof object.seriesLatestPublishedAtAsc !== "object")
                        throw TypeError(".search.SeriesAPIKey.SeriesIndices.seriesLatestPublishedAtAsc: object expected");
                    message.seriesLatestPublishedAtAsc = $root.google.protobuf.StringValue.fromObject(object.seriesLatestPublishedAtAsc);
                }
                if (object.seriesLatestPublishedAtDesc != null) {
                    if (typeof object.seriesLatestPublishedAtDesc !== "object")
                        throw TypeError(".search.SeriesAPIKey.SeriesIndices.seriesLatestPublishedAtDesc: object expected");
                    message.seriesLatestPublishedAtDesc = $root.google.protobuf.StringValue.fromObject(object.seriesLatestPublishedAtDesc);
                }
                return message;
            };

            /**
             * Creates a plain object from a SeriesIndices message. Also converts values to other types if specified.
             * @function toObject
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @static
             * @param {search.SeriesAPIKey.SeriesIndices} message SeriesIndices
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SeriesIndices.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.seriesMaster = null;
                    object.seriesLatestPublishedAtAsc = null;
                    object.seriesLatestPublishedAtDesc = null;
                }
                if (message.seriesMaster != null && message.hasOwnProperty("seriesMaster"))
                    object.seriesMaster = $root.google.protobuf.StringValue.toObject(message.seriesMaster, options);
                if (message.seriesLatestPublishedAtAsc != null && message.hasOwnProperty("seriesLatestPublishedAtAsc"))
                    object.seriesLatestPublishedAtAsc = $root.google.protobuf.StringValue.toObject(message.seriesLatestPublishedAtAsc, options);
                if (message.seriesLatestPublishedAtDesc != null && message.hasOwnProperty("seriesLatestPublishedAtDesc"))
                    object.seriesLatestPublishedAtDesc = $root.google.protobuf.StringValue.toObject(message.seriesLatestPublishedAtDesc, options);
                return object;
            };

            /**
             * Converts this SeriesIndices to JSON.
             * @function toJSON
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SeriesIndices.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SeriesIndices
             * @function getTypeUrl
             * @memberof search.SeriesAPIKey.SeriesIndices
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SeriesIndices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/search.SeriesAPIKey.SeriesIndices";
            };

            return SeriesIndices;
        })();

        return SeriesAPIKey;
    })();

    search.SeriesGroupAPIKey = (function() {

        /**
         * Properties of a SeriesGroupAPIKey.
         * @memberof search
         * @interface ISeriesGroupAPIKey
         * @property {search.ISearchAPIKey|null} [key] SeriesGroupAPIKey key
         * @property {search.SeriesGroupAPIKey.ISeriesGroupIndices|null} [indices] SeriesGroupAPIKey indices
         */

        /**
         * Constructs a new SeriesGroupAPIKey.
         * @memberof search
         * @classdesc Represents a SeriesGroupAPIKey.
         * @implements ISeriesGroupAPIKey
         * @constructor
         * @param {search.ISeriesGroupAPIKey=} [properties] Properties to set
         */
        function SeriesGroupAPIKey(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeriesGroupAPIKey key.
         * @member {search.ISearchAPIKey|null|undefined} key
         * @memberof search.SeriesGroupAPIKey
         * @instance
         */
        SeriesGroupAPIKey.prototype.key = null;

        /**
         * SeriesGroupAPIKey indices.
         * @member {search.SeriesGroupAPIKey.ISeriesGroupIndices|null|undefined} indices
         * @memberof search.SeriesGroupAPIKey
         * @instance
         */
        SeriesGroupAPIKey.prototype.indices = null;

        /**
         * Creates a new SeriesGroupAPIKey instance using the specified properties.
         * @function create
         * @memberof search.SeriesGroupAPIKey
         * @static
         * @param {search.ISeriesGroupAPIKey=} [properties] Properties to set
         * @returns {search.SeriesGroupAPIKey} SeriesGroupAPIKey instance
         */
        SeriesGroupAPIKey.create = function create(properties) {
            return new SeriesGroupAPIKey(properties);
        };

        /**
         * Encodes the specified SeriesGroupAPIKey message. Does not implicitly {@link search.SeriesGroupAPIKey.verify|verify} messages.
         * @function encode
         * @memberof search.SeriesGroupAPIKey
         * @static
         * @param {search.ISeriesGroupAPIKey} message SeriesGroupAPIKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesGroupAPIKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                $root.search.SearchAPIKey.encode(message.key, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.indices != null && Object.hasOwnProperty.call(message, "indices"))
                $root.search.SeriesGroupAPIKey.SeriesGroupIndices.encode(message.indices, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SeriesGroupAPIKey message from the specified reader or buffer.
         * @function decode
         * @memberof search.SeriesGroupAPIKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.SeriesGroupAPIKey} SeriesGroupAPIKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesGroupAPIKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SeriesGroupAPIKey();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = $root.search.SearchAPIKey.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.indices = $root.search.SeriesGroupAPIKey.SeriesGroupIndices.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SeriesGroupAPIKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.SeriesGroupAPIKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.SeriesGroupAPIKey} SeriesGroupAPIKey
         */
        SeriesGroupAPIKey.fromObject = function fromObject(object) {
            if (object instanceof $root.search.SeriesGroupAPIKey)
                return object;
            var message = new $root.search.SeriesGroupAPIKey();
            if (object.key != null) {
                if (typeof object.key !== "object")
                    throw TypeError(".search.SeriesGroupAPIKey.key: object expected");
                message.key = $root.search.SearchAPIKey.fromObject(object.key);
            }
            if (object.indices != null) {
                if (typeof object.indices !== "object")
                    throw TypeError(".search.SeriesGroupAPIKey.indices: object expected");
                message.indices = $root.search.SeriesGroupAPIKey.SeriesGroupIndices.fromObject(object.indices);
            }
            return message;
        };

        /**
         * Creates a plain object from a SeriesGroupAPIKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.SeriesGroupAPIKey
         * @static
         * @param {search.SeriesGroupAPIKey} message SeriesGroupAPIKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesGroupAPIKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.key = null;
                object.indices = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = $root.search.SearchAPIKey.toObject(message.key, options);
            if (message.indices != null && message.hasOwnProperty("indices"))
                object.indices = $root.search.SeriesGroupAPIKey.SeriesGroupIndices.toObject(message.indices, options);
            return object;
        };

        /**
         * Converts this SeriesGroupAPIKey to JSON.
         * @function toJSON
         * @memberof search.SeriesGroupAPIKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesGroupAPIKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesGroupAPIKey
         * @function getTypeUrl
         * @memberof search.SeriesGroupAPIKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesGroupAPIKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.SeriesGroupAPIKey";
        };

        SeriesGroupAPIKey.SeriesGroupIndices = (function() {

            /**
             * Properties of a SeriesGroupIndices.
             * @memberof search.SeriesGroupAPIKey
             * @interface ISeriesGroupIndices
             * @property {google.protobuf.IStringValue|null} [seriesGroupMaster] SeriesGroupIndices seriesGroupMaster
             */

            /**
             * Constructs a new SeriesGroupIndices.
             * @memberof search.SeriesGroupAPIKey
             * @classdesc Represents a SeriesGroupIndices.
             * @implements ISeriesGroupIndices
             * @constructor
             * @param {search.SeriesGroupAPIKey.ISeriesGroupIndices=} [properties] Properties to set
             */
            function SeriesGroupIndices(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SeriesGroupIndices seriesGroupMaster.
             * @member {google.protobuf.IStringValue|null|undefined} seriesGroupMaster
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @instance
             */
            SeriesGroupIndices.prototype.seriesGroupMaster = null;

            /**
             * Creates a new SeriesGroupIndices instance using the specified properties.
             * @function create
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @static
             * @param {search.SeriesGroupAPIKey.ISeriesGroupIndices=} [properties] Properties to set
             * @returns {search.SeriesGroupAPIKey.SeriesGroupIndices} SeriesGroupIndices instance
             */
            SeriesGroupIndices.create = function create(properties) {
                return new SeriesGroupIndices(properties);
            };

            /**
             * Encodes the specified SeriesGroupIndices message. Does not implicitly {@link search.SeriesGroupAPIKey.SeriesGroupIndices.verify|verify} messages.
             * @function encode
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @static
             * @param {search.SeriesGroupAPIKey.ISeriesGroupIndices} message SeriesGroupIndices message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SeriesGroupIndices.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seriesGroupMaster != null && Object.hasOwnProperty.call(message, "seriesGroupMaster"))
                    $root.google.protobuf.StringValue.encode(message.seriesGroupMaster, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SeriesGroupIndices message from the specified reader or buffer.
             * @function decode
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {search.SeriesGroupAPIKey.SeriesGroupIndices} SeriesGroupIndices
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SeriesGroupIndices.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SeriesGroupAPIKey.SeriesGroupIndices();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seriesGroupMaster = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a SeriesGroupIndices message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {search.SeriesGroupAPIKey.SeriesGroupIndices} SeriesGroupIndices
             */
            SeriesGroupIndices.fromObject = function fromObject(object) {
                if (object instanceof $root.search.SeriesGroupAPIKey.SeriesGroupIndices)
                    return object;
                var message = new $root.search.SeriesGroupAPIKey.SeriesGroupIndices();
                if (object.seriesGroupMaster != null) {
                    if (typeof object.seriesGroupMaster !== "object")
                        throw TypeError(".search.SeriesGroupAPIKey.SeriesGroupIndices.seriesGroupMaster: object expected");
                    message.seriesGroupMaster = $root.google.protobuf.StringValue.fromObject(object.seriesGroupMaster);
                }
                return message;
            };

            /**
             * Creates a plain object from a SeriesGroupIndices message. Also converts values to other types if specified.
             * @function toObject
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @static
             * @param {search.SeriesGroupAPIKey.SeriesGroupIndices} message SeriesGroupIndices
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SeriesGroupIndices.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.seriesGroupMaster = null;
                if (message.seriesGroupMaster != null && message.hasOwnProperty("seriesGroupMaster"))
                    object.seriesGroupMaster = $root.google.protobuf.StringValue.toObject(message.seriesGroupMaster, options);
                return object;
            };

            /**
             * Converts this SeriesGroupIndices to JSON.
             * @function toJSON
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SeriesGroupIndices.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SeriesGroupIndices
             * @function getTypeUrl
             * @memberof search.SeriesGroupAPIKey.SeriesGroupIndices
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SeriesGroupIndices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/search.SeriesGroupAPIKey.SeriesGroupIndices";
            };

            return SeriesGroupIndices;
        })();

        return SeriesGroupAPIKey;
    })();

    search.UserAPIKey = (function() {

        /**
         * Properties of a UserAPIKey.
         * @memberof search
         * @interface IUserAPIKey
         * @property {search.ISearchAPIKey|null} [key] UserAPIKey key
         * @property {search.UserAPIKey.IUserIndices|null} [indices] UserAPIKey indices
         */

        /**
         * Constructs a new UserAPIKey.
         * @memberof search
         * @classdesc Represents a UserAPIKey.
         * @implements IUserAPIKey
         * @constructor
         * @param {search.IUserAPIKey=} [properties] Properties to set
         */
        function UserAPIKey(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserAPIKey key.
         * @member {search.ISearchAPIKey|null|undefined} key
         * @memberof search.UserAPIKey
         * @instance
         */
        UserAPIKey.prototype.key = null;

        /**
         * UserAPIKey indices.
         * @member {search.UserAPIKey.IUserIndices|null|undefined} indices
         * @memberof search.UserAPIKey
         * @instance
         */
        UserAPIKey.prototype.indices = null;

        /**
         * Creates a new UserAPIKey instance using the specified properties.
         * @function create
         * @memberof search.UserAPIKey
         * @static
         * @param {search.IUserAPIKey=} [properties] Properties to set
         * @returns {search.UserAPIKey} UserAPIKey instance
         */
        UserAPIKey.create = function create(properties) {
            return new UserAPIKey(properties);
        };

        /**
         * Encodes the specified UserAPIKey message. Does not implicitly {@link search.UserAPIKey.verify|verify} messages.
         * @function encode
         * @memberof search.UserAPIKey
         * @static
         * @param {search.IUserAPIKey} message UserAPIKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAPIKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                $root.search.SearchAPIKey.encode(message.key, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.indices != null && Object.hasOwnProperty.call(message, "indices"))
                $root.search.UserAPIKey.UserIndices.encode(message.indices, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a UserAPIKey message from the specified reader or buffer.
         * @function decode
         * @memberof search.UserAPIKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.UserAPIKey} UserAPIKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAPIKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.UserAPIKey();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = $root.search.SearchAPIKey.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.indices = $root.search.UserAPIKey.UserIndices.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a UserAPIKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.UserAPIKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.UserAPIKey} UserAPIKey
         */
        UserAPIKey.fromObject = function fromObject(object) {
            if (object instanceof $root.search.UserAPIKey)
                return object;
            var message = new $root.search.UserAPIKey();
            if (object.key != null) {
                if (typeof object.key !== "object")
                    throw TypeError(".search.UserAPIKey.key: object expected");
                message.key = $root.search.SearchAPIKey.fromObject(object.key);
            }
            if (object.indices != null) {
                if (typeof object.indices !== "object")
                    throw TypeError(".search.UserAPIKey.indices: object expected");
                message.indices = $root.search.UserAPIKey.UserIndices.fromObject(object.indices);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserAPIKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.UserAPIKey
         * @static
         * @param {search.UserAPIKey} message UserAPIKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserAPIKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.key = null;
                object.indices = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = $root.search.SearchAPIKey.toObject(message.key, options);
            if (message.indices != null && message.hasOwnProperty("indices"))
                object.indices = $root.search.UserAPIKey.UserIndices.toObject(message.indices, options);
            return object;
        };

        /**
         * Converts this UserAPIKey to JSON.
         * @function toJSON
         * @memberof search.UserAPIKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserAPIKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserAPIKey
         * @function getTypeUrl
         * @memberof search.UserAPIKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserAPIKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.UserAPIKey";
        };

        UserAPIKey.UserIndices = (function() {

            /**
             * Properties of a UserIndices.
             * @memberof search.UserAPIKey
             * @interface IUserIndices
             * @property {google.protobuf.IStringValue|null} [userMaster] UserIndices userMaster
             */

            /**
             * Constructs a new UserIndices.
             * @memberof search.UserAPIKey
             * @classdesc Represents a UserIndices.
             * @implements IUserIndices
             * @constructor
             * @param {search.UserAPIKey.IUserIndices=} [properties] Properties to set
             */
            function UserIndices(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserIndices userMaster.
             * @member {google.protobuf.IStringValue|null|undefined} userMaster
             * @memberof search.UserAPIKey.UserIndices
             * @instance
             */
            UserIndices.prototype.userMaster = null;

            /**
             * Creates a new UserIndices instance using the specified properties.
             * @function create
             * @memberof search.UserAPIKey.UserIndices
             * @static
             * @param {search.UserAPIKey.IUserIndices=} [properties] Properties to set
             * @returns {search.UserAPIKey.UserIndices} UserIndices instance
             */
            UserIndices.create = function create(properties) {
                return new UserIndices(properties);
            };

            /**
             * Encodes the specified UserIndices message. Does not implicitly {@link search.UserAPIKey.UserIndices.verify|verify} messages.
             * @function encode
             * @memberof search.UserAPIKey.UserIndices
             * @static
             * @param {search.UserAPIKey.IUserIndices} message UserIndices message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserIndices.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userMaster != null && Object.hasOwnProperty.call(message, "userMaster"))
                    $root.google.protobuf.StringValue.encode(message.userMaster, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a UserIndices message from the specified reader or buffer.
             * @function decode
             * @memberof search.UserAPIKey.UserIndices
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {search.UserAPIKey.UserIndices} UserIndices
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserIndices.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.UserAPIKey.UserIndices();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.userMaster = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Creates a UserIndices message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof search.UserAPIKey.UserIndices
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {search.UserAPIKey.UserIndices} UserIndices
             */
            UserIndices.fromObject = function fromObject(object) {
                if (object instanceof $root.search.UserAPIKey.UserIndices)
                    return object;
                var message = new $root.search.UserAPIKey.UserIndices();
                if (object.userMaster != null) {
                    if (typeof object.userMaster !== "object")
                        throw TypeError(".search.UserAPIKey.UserIndices.userMaster: object expected");
                    message.userMaster = $root.google.protobuf.StringValue.fromObject(object.userMaster);
                }
                return message;
            };

            /**
             * Creates a plain object from a UserIndices message. Also converts values to other types if specified.
             * @function toObject
             * @memberof search.UserAPIKey.UserIndices
             * @static
             * @param {search.UserAPIKey.UserIndices} message UserIndices
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserIndices.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.userMaster = null;
                if (message.userMaster != null && message.hasOwnProperty("userMaster"))
                    object.userMaster = $root.google.protobuf.StringValue.toObject(message.userMaster, options);
                return object;
            };

            /**
             * Converts this UserIndices to JSON.
             * @function toJSON
             * @memberof search.UserAPIKey.UserIndices
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserIndices.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserIndices
             * @function getTypeUrl
             * @memberof search.UserAPIKey.UserIndices
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserIndices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/search.UserAPIKey.UserIndices";
            };

            return UserIndices;
        })();

        return UserAPIKey;
    })();

    search.SearchIssueAPIKeysResponse = (function() {

        /**
         * Properties of a SearchIssueAPIKeysResponse.
         * @memberof search
         * @interface ISearchIssueAPIKeysResponse
         * @property {google.protobuf.IStringValue|null} [appId] SearchIssueAPIKeysResponse appId
         * @property {search.IStoryAPIKey|null} [story] SearchIssueAPIKeysResponse story
         * @property {search.ISeriesAPIKey|null} [series] SearchIssueAPIKeysResponse series
         * @property {search.IUserAPIKey|null} [user] SearchIssueAPIKeysResponse user
         * @property {search.ISeriesGroupAPIKey|null} [seriesGroup] SearchIssueAPIKeysResponse seriesGroup
         */

        /**
         * Constructs a new SearchIssueAPIKeysResponse.
         * @memberof search
         * @classdesc Represents a SearchIssueAPIKeysResponse.
         * @implements ISearchIssueAPIKeysResponse
         * @constructor
         * @param {search.ISearchIssueAPIKeysResponse=} [properties] Properties to set
         */
        function SearchIssueAPIKeysResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchIssueAPIKeysResponse appId.
         * @member {google.protobuf.IStringValue|null|undefined} appId
         * @memberof search.SearchIssueAPIKeysResponse
         * @instance
         */
        SearchIssueAPIKeysResponse.prototype.appId = null;

        /**
         * SearchIssueAPIKeysResponse story.
         * @member {search.IStoryAPIKey|null|undefined} story
         * @memberof search.SearchIssueAPIKeysResponse
         * @instance
         */
        SearchIssueAPIKeysResponse.prototype.story = null;

        /**
         * SearchIssueAPIKeysResponse series.
         * @member {search.ISeriesAPIKey|null|undefined} series
         * @memberof search.SearchIssueAPIKeysResponse
         * @instance
         */
        SearchIssueAPIKeysResponse.prototype.series = null;

        /**
         * SearchIssueAPIKeysResponse user.
         * @member {search.IUserAPIKey|null|undefined} user
         * @memberof search.SearchIssueAPIKeysResponse
         * @instance
         */
        SearchIssueAPIKeysResponse.prototype.user = null;

        /**
         * SearchIssueAPIKeysResponse seriesGroup.
         * @member {search.ISeriesGroupAPIKey|null|undefined} seriesGroup
         * @memberof search.SearchIssueAPIKeysResponse
         * @instance
         */
        SearchIssueAPIKeysResponse.prototype.seriesGroup = null;

        /**
         * Creates a new SearchIssueAPIKeysResponse instance using the specified properties.
         * @function create
         * @memberof search.SearchIssueAPIKeysResponse
         * @static
         * @param {search.ISearchIssueAPIKeysResponse=} [properties] Properties to set
         * @returns {search.SearchIssueAPIKeysResponse} SearchIssueAPIKeysResponse instance
         */
        SearchIssueAPIKeysResponse.create = function create(properties) {
            return new SearchIssueAPIKeysResponse(properties);
        };

        /**
         * Encodes the specified SearchIssueAPIKeysResponse message. Does not implicitly {@link search.SearchIssueAPIKeysResponse.verify|verify} messages.
         * @function encode
         * @memberof search.SearchIssueAPIKeysResponse
         * @static
         * @param {search.ISearchIssueAPIKeysResponse} message SearchIssueAPIKeysResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchIssueAPIKeysResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                $root.google.protobuf.StringValue.encode(message.appId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.story != null && Object.hasOwnProperty.call(message, "story"))
                $root.search.StoryAPIKey.encode(message.story, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                $root.search.SeriesAPIKey.encode(message.series, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.search.UserAPIKey.encode(message.user, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.seriesGroup != null && Object.hasOwnProperty.call(message, "seriesGroup"))
                $root.search.SeriesGroupAPIKey.encode(message.seriesGroup, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchIssueAPIKeysResponse message from the specified reader or buffer.
         * @function decode
         * @memberof search.SearchIssueAPIKeysResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {search.SearchIssueAPIKeysResponse} SearchIssueAPIKeysResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchIssueAPIKeysResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.search.SearchIssueAPIKeysResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.appId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.story = $root.search.StoryAPIKey.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.series = $root.search.SeriesAPIKey.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.user = $root.search.UserAPIKey.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.seriesGroup = $root.search.SeriesGroupAPIKey.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a SearchIssueAPIKeysResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof search.SearchIssueAPIKeysResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {search.SearchIssueAPIKeysResponse} SearchIssueAPIKeysResponse
         */
        SearchIssueAPIKeysResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.search.SearchIssueAPIKeysResponse)
                return object;
            var message = new $root.search.SearchIssueAPIKeysResponse();
            if (object.appId != null) {
                if (typeof object.appId !== "object")
                    throw TypeError(".search.SearchIssueAPIKeysResponse.appId: object expected");
                message.appId = $root.google.protobuf.StringValue.fromObject(object.appId);
            }
            if (object.story != null) {
                if (typeof object.story !== "object")
                    throw TypeError(".search.SearchIssueAPIKeysResponse.story: object expected");
                message.story = $root.search.StoryAPIKey.fromObject(object.story);
            }
            if (object.series != null) {
                if (typeof object.series !== "object")
                    throw TypeError(".search.SearchIssueAPIKeysResponse.series: object expected");
                message.series = $root.search.SeriesAPIKey.fromObject(object.series);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".search.SearchIssueAPIKeysResponse.user: object expected");
                message.user = $root.search.UserAPIKey.fromObject(object.user);
            }
            if (object.seriesGroup != null) {
                if (typeof object.seriesGroup !== "object")
                    throw TypeError(".search.SearchIssueAPIKeysResponse.seriesGroup: object expected");
                message.seriesGroup = $root.search.SeriesGroupAPIKey.fromObject(object.seriesGroup);
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchIssueAPIKeysResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof search.SearchIssueAPIKeysResponse
         * @static
         * @param {search.SearchIssueAPIKeysResponse} message SearchIssueAPIKeysResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchIssueAPIKeysResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.appId = null;
                object.story = null;
                object.series = null;
                object.user = null;
                object.seriesGroup = null;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = $root.google.protobuf.StringValue.toObject(message.appId, options);
            if (message.story != null && message.hasOwnProperty("story"))
                object.story = $root.search.StoryAPIKey.toObject(message.story, options);
            if (message.series != null && message.hasOwnProperty("series"))
                object.series = $root.search.SeriesAPIKey.toObject(message.series, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.search.UserAPIKey.toObject(message.user, options);
            if (message.seriesGroup != null && message.hasOwnProperty("seriesGroup"))
                object.seriesGroup = $root.search.SeriesGroupAPIKey.toObject(message.seriesGroup, options);
            return object;
        };

        /**
         * Converts this SearchIssueAPIKeysResponse to JSON.
         * @function toJSON
         * @memberof search.SearchIssueAPIKeysResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchIssueAPIKeysResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchIssueAPIKeysResponse
         * @function getTypeUrl
         * @memberof search.SearchIssueAPIKeysResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchIssueAPIKeysResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/search.SearchIssueAPIKeysResponse";
        };

        return SearchIssueAPIKeysResponse;
    })();

    return search;
})();

$root.story_advertisement = (function() {

    /**
     * Namespace story_advertisement.
     * @exports story_advertisement
     * @namespace
     */
    var story_advertisement = {};

    story_advertisement.StoryAdvertisementService = (function() {

        /**
         * Constructs a new StoryAdvertisementService service.
         * @memberof story_advertisement
         * @classdesc Represents a StoryAdvertisementService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function StoryAdvertisementService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (StoryAdvertisementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoryAdvertisementService;

        /**
         * Creates new StoryAdvertisementService service using the specified rpc implementation.
         * @function create
         * @memberof story_advertisement.StoryAdvertisementService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {StoryAdvertisementService} RPC service. Useful where requests and/or responses are streamed.
         */
        StoryAdvertisementService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link story_advertisement.StoryAdvertisementService#listV2}.
         * @memberof story_advertisement.StoryAdvertisementService
         * @typedef ListV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_advertisement.StoryAdvertisementList} [response] StoryAdvertisementList
         */

        /**
         * Calls ListV2.
         * @function listV2
         * @memberof story_advertisement.StoryAdvertisementService
         * @instance
         * @param {story_advertisement.IStoryAdvertisementListRequest} request StoryAdvertisementListRequest message or plain object
         * @param {story_advertisement.StoryAdvertisementService.ListV2Callback} callback Node-style callback called with the error, if any, and StoryAdvertisementList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryAdvertisementService.prototype.listV2 = function listV2(request, callback) {
            return this.rpcCall(listV2, $root.story_advertisement.StoryAdvertisementListRequest, $root.story_advertisement.StoryAdvertisementList, request, callback);
        }, "name", { value: "ListV2" });

        /**
         * Calls ListV2.
         * @function listV2
         * @memberof story_advertisement.StoryAdvertisementService
         * @instance
         * @param {story_advertisement.IStoryAdvertisementListRequest} request StoryAdvertisementListRequest message or plain object
         * @returns {Promise<story_advertisement.StoryAdvertisementList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link story_advertisement.StoryAdvertisementService#list}.
         * @memberof story_advertisement.StoryAdvertisementService
         * @typedef ListCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {story_advertisement.StoryAdvertisementListResponse} [response] StoryAdvertisementListResponse
         */

        /**
         * Calls List.
         * @function list
         * @memberof story_advertisement.StoryAdvertisementService
         * @instance
         * @param {story_advertisement.IStoryAdvertisementListRequest} request StoryAdvertisementListRequest message or plain object
         * @param {story_advertisement.StoryAdvertisementService.ListCallback} callback Node-style callback called with the error, if any, and StoryAdvertisementListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(StoryAdvertisementService.prototype.list = function list(request, callback) {
            return this.rpcCall(list, $root.story_advertisement.StoryAdvertisementListRequest, $root.story_advertisement.StoryAdvertisementListResponse, request, callback);
        }, "name", { value: "List" });

        /**
         * Calls List.
         * @function list
         * @memberof story_advertisement.StoryAdvertisementService
         * @instance
         * @param {story_advertisement.IStoryAdvertisementListRequest} request StoryAdvertisementListRequest message or plain object
         * @returns {Promise<story_advertisement.StoryAdvertisementListResponse>} Promise
         * @variation 2
         */

        return StoryAdvertisementService;
    })();

    story_advertisement.StoryAdvertisement = (function() {

        /**
         * Properties of a StoryAdvertisement.
         * @memberof story_advertisement
         * @interface IStoryAdvertisement
         * @property {google.protobuf.IStringValue|null} [id] StoryAdvertisement id
         * @property {google.protobuf.IStringValue|null} [title] StoryAdvertisement title
         * @property {google.protobuf.IStringValue|null} [description] StoryAdvertisement description
         * @property {resource.IImage|null} [thumbnail] StoryAdvertisement thumbnail
         * @property {google.protobuf.IStringValue|null} [linkUrl] StoryAdvertisement linkUrl
         */

        /**
         * Constructs a new StoryAdvertisement.
         * @memberof story_advertisement
         * @classdesc Represents a StoryAdvertisement.
         * @implements IStoryAdvertisement
         * @constructor
         * @param {story_advertisement.IStoryAdvertisement=} [properties] Properties to set
         */
        function StoryAdvertisement(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisement id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_advertisement.StoryAdvertisement
         * @instance
         */
        StoryAdvertisement.prototype.id = null;

        /**
         * StoryAdvertisement title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story_advertisement.StoryAdvertisement
         * @instance
         */
        StoryAdvertisement.prototype.title = null;

        /**
         * StoryAdvertisement description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story_advertisement.StoryAdvertisement
         * @instance
         */
        StoryAdvertisement.prototype.description = null;

        /**
         * StoryAdvertisement thumbnail.
         * @member {resource.IImage|null|undefined} thumbnail
         * @memberof story_advertisement.StoryAdvertisement
         * @instance
         */
        StoryAdvertisement.prototype.thumbnail = null;

        /**
         * StoryAdvertisement linkUrl.
         * @member {google.protobuf.IStringValue|null|undefined} linkUrl
         * @memberof story_advertisement.StoryAdvertisement
         * @instance
         */
        StoryAdvertisement.prototype.linkUrl = null;

        /**
         * Creates a new StoryAdvertisement instance using the specified properties.
         * @function create
         * @memberof story_advertisement.StoryAdvertisement
         * @static
         * @param {story_advertisement.IStoryAdvertisement=} [properties] Properties to set
         * @returns {story_advertisement.StoryAdvertisement} StoryAdvertisement instance
         */
        StoryAdvertisement.create = function create(properties) {
            return new StoryAdvertisement(properties);
        };

        /**
         * Encodes the specified StoryAdvertisement message. Does not implicitly {@link story_advertisement.StoryAdvertisement.verify|verify} messages.
         * @function encode
         * @memberof story_advertisement.StoryAdvertisement
         * @static
         * @param {story_advertisement.IStoryAdvertisement} message StoryAdvertisement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                $root.resource.Image.encode(message.thumbnail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.linkUrl != null && Object.hasOwnProperty.call(message, "linkUrl"))
                $root.google.protobuf.StringValue.encode(message.linkUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisement message from the specified reader or buffer.
         * @function decode
         * @memberof story_advertisement.StoryAdvertisement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_advertisement.StoryAdvertisement} StoryAdvertisement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_advertisement.StoryAdvertisement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnail = $root.resource.Image.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.linkUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_advertisement.StoryAdvertisement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_advertisement.StoryAdvertisement} StoryAdvertisement
         */
        StoryAdvertisement.fromObject = function fromObject(object) {
            if (object instanceof $root.story_advertisement.StoryAdvertisement)
                return object;
            var message = new $root.story_advertisement.StoryAdvertisement();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisement.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisement.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisement.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnail != null) {
                if (typeof object.thumbnail !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisement.thumbnail: object expected");
                message.thumbnail = $root.resource.Image.fromObject(object.thumbnail);
            }
            if (object.linkUrl != null) {
                if (typeof object.linkUrl !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisement.linkUrl: object expected");
                message.linkUrl = $root.google.protobuf.StringValue.fromObject(object.linkUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_advertisement.StoryAdvertisement
         * @static
         * @param {story_advertisement.StoryAdvertisement} message StoryAdvertisement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnail = null;
                object.linkUrl = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = $root.resource.Image.toObject(message.thumbnail, options);
            if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                object.linkUrl = $root.google.protobuf.StringValue.toObject(message.linkUrl, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisement to JSON.
         * @function toJSON
         * @memberof story_advertisement.StoryAdvertisement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisement
         * @function getTypeUrl
         * @memberof story_advertisement.StoryAdvertisement
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_advertisement.StoryAdvertisement";
        };

        return StoryAdvertisement;
    })();

    story_advertisement.StoryAdvertisementList = (function() {

        /**
         * Properties of a StoryAdvertisementList.
         * @memberof story_advertisement
         * @interface IStoryAdvertisementList
         * @property {Array.<story_advertisement.IStoryAdvertisement>|null} [storyAdvertisementList] StoryAdvertisementList storyAdvertisementList
         */

        /**
         * Constructs a new StoryAdvertisementList.
         * @memberof story_advertisement
         * @classdesc Represents a StoryAdvertisementList.
         * @implements IStoryAdvertisementList
         * @constructor
         * @param {story_advertisement.IStoryAdvertisementList=} [properties] Properties to set
         */
        function StoryAdvertisementList(properties) {
            this.storyAdvertisementList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementList storyAdvertisementList.
         * @member {Array.<story_advertisement.IStoryAdvertisement>} storyAdvertisementList
         * @memberof story_advertisement.StoryAdvertisementList
         * @instance
         */
        StoryAdvertisementList.prototype.storyAdvertisementList = $util.emptyArray;

        /**
         * Creates a new StoryAdvertisementList instance using the specified properties.
         * @function create
         * @memberof story_advertisement.StoryAdvertisementList
         * @static
         * @param {story_advertisement.IStoryAdvertisementList=} [properties] Properties to set
         * @returns {story_advertisement.StoryAdvertisementList} StoryAdvertisementList instance
         */
        StoryAdvertisementList.create = function create(properties) {
            return new StoryAdvertisementList(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementList message. Does not implicitly {@link story_advertisement.StoryAdvertisementList.verify|verify} messages.
         * @function encode
         * @memberof story_advertisement.StoryAdvertisementList
         * @static
         * @param {story_advertisement.IStoryAdvertisementList} message StoryAdvertisementList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyAdvertisementList != null && message.storyAdvertisementList.length)
                for (var i = 0; i < message.storyAdvertisementList.length; ++i)
                    $root.story_advertisement.StoryAdvertisement.encode(message.storyAdvertisementList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementList message from the specified reader or buffer.
         * @function decode
         * @memberof story_advertisement.StoryAdvertisementList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_advertisement.StoryAdvertisementList} StoryAdvertisementList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_advertisement.StoryAdvertisementList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyAdvertisementList && message.storyAdvertisementList.length))
                        message.storyAdvertisementList = [];
                    message.storyAdvertisementList.push($root.story_advertisement.StoryAdvertisement.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_advertisement.StoryAdvertisementList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_advertisement.StoryAdvertisementList} StoryAdvertisementList
         */
        StoryAdvertisementList.fromObject = function fromObject(object) {
            if (object instanceof $root.story_advertisement.StoryAdvertisementList)
                return object;
            var message = new $root.story_advertisement.StoryAdvertisementList();
            if (object.storyAdvertisementList) {
                if (!Array.isArray(object.storyAdvertisementList))
                    throw TypeError(".story_advertisement.StoryAdvertisementList.storyAdvertisementList: array expected");
                message.storyAdvertisementList = [];
                for (var i = 0; i < object.storyAdvertisementList.length; ++i) {
                    if (typeof object.storyAdvertisementList[i] !== "object")
                        throw TypeError(".story_advertisement.StoryAdvertisementList.storyAdvertisementList: object expected");
                    message.storyAdvertisementList[i] = $root.story_advertisement.StoryAdvertisement.fromObject(object.storyAdvertisementList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_advertisement.StoryAdvertisementList
         * @static
         * @param {story_advertisement.StoryAdvertisementList} message StoryAdvertisementList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyAdvertisementList = [];
            if (message.storyAdvertisementList && message.storyAdvertisementList.length) {
                object.storyAdvertisementList = [];
                for (var j = 0; j < message.storyAdvertisementList.length; ++j)
                    object.storyAdvertisementList[j] = $root.story_advertisement.StoryAdvertisement.toObject(message.storyAdvertisementList[j], options);
            }
            return object;
        };

        /**
         * Converts this StoryAdvertisementList to JSON.
         * @function toJSON
         * @memberof story_advertisement.StoryAdvertisementList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementList
         * @function getTypeUrl
         * @memberof story_advertisement.StoryAdvertisementList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_advertisement.StoryAdvertisementList";
        };

        return StoryAdvertisementList;
    })();

    story_advertisement.StoryAdvertisementV1 = (function() {

        /**
         * Properties of a StoryAdvertisementV1.
         * @memberof story_advertisement
         * @interface IStoryAdvertisementV1
         * @property {google.protobuf.IStringValue|null} [id] StoryAdvertisementV1 id
         * @property {google.protobuf.IStringValue|null} [title] StoryAdvertisementV1 title
         * @property {google.protobuf.IStringValue|null} [description] StoryAdvertisementV1 description
         * @property {google.protobuf.IStringValue|null} [thumbnailUrl] StoryAdvertisementV1 thumbnailUrl
         * @property {google.protobuf.IStringValue|null} [linkUrl] StoryAdvertisementV1 linkUrl
         */

        /**
         * Constructs a new StoryAdvertisementV1.
         * @memberof story_advertisement
         * @classdesc Represents a StoryAdvertisementV1.
         * @implements IStoryAdvertisementV1
         * @constructor
         * @param {story_advertisement.IStoryAdvertisementV1=} [properties] Properties to set
         */
        function StoryAdvertisementV1(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementV1 id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof story_advertisement.StoryAdvertisementV1
         * @instance
         */
        StoryAdvertisementV1.prototype.id = null;

        /**
         * StoryAdvertisementV1 title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof story_advertisement.StoryAdvertisementV1
         * @instance
         */
        StoryAdvertisementV1.prototype.title = null;

        /**
         * StoryAdvertisementV1 description.
         * @member {google.protobuf.IStringValue|null|undefined} description
         * @memberof story_advertisement.StoryAdvertisementV1
         * @instance
         */
        StoryAdvertisementV1.prototype.description = null;

        /**
         * StoryAdvertisementV1 thumbnailUrl.
         * @member {google.protobuf.IStringValue|null|undefined} thumbnailUrl
         * @memberof story_advertisement.StoryAdvertisementV1
         * @instance
         */
        StoryAdvertisementV1.prototype.thumbnailUrl = null;

        /**
         * StoryAdvertisementV1 linkUrl.
         * @member {google.protobuf.IStringValue|null|undefined} linkUrl
         * @memberof story_advertisement.StoryAdvertisementV1
         * @instance
         */
        StoryAdvertisementV1.prototype.linkUrl = null;

        /**
         * Creates a new StoryAdvertisementV1 instance using the specified properties.
         * @function create
         * @memberof story_advertisement.StoryAdvertisementV1
         * @static
         * @param {story_advertisement.IStoryAdvertisementV1=} [properties] Properties to set
         * @returns {story_advertisement.StoryAdvertisementV1} StoryAdvertisementV1 instance
         */
        StoryAdvertisementV1.create = function create(properties) {
            return new StoryAdvertisementV1(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementV1 message. Does not implicitly {@link story_advertisement.StoryAdvertisementV1.verify|verify} messages.
         * @function encode
         * @memberof story_advertisement.StoryAdvertisementV1
         * @static
         * @param {story_advertisement.IStoryAdvertisementV1} message StoryAdvertisementV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.google.protobuf.StringValue.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.google.protobuf.StringValue.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                $root.google.protobuf.StringValue.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                $root.google.protobuf.StringValue.encode(message.thumbnailUrl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.linkUrl != null && Object.hasOwnProperty.call(message, "linkUrl"))
                $root.google.protobuf.StringValue.encode(message.linkUrl, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementV1 message from the specified reader or buffer.
         * @function decode
         * @memberof story_advertisement.StoryAdvertisementV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_advertisement.StoryAdvertisementV1} StoryAdvertisementV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_advertisement.StoryAdvertisementV1();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.title = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.thumbnailUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.linkUrl = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_advertisement.StoryAdvertisementV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_advertisement.StoryAdvertisementV1} StoryAdvertisementV1
         */
        StoryAdvertisementV1.fromObject = function fromObject(object) {
            if (object instanceof $root.story_advertisement.StoryAdvertisementV1)
                return object;
            var message = new $root.story_advertisement.StoryAdvertisementV1();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisementV1.id: object expected");
                message.id = $root.google.protobuf.StringValue.fromObject(object.id);
            }
            if (object.title != null) {
                if (typeof object.title !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisementV1.title: object expected");
                message.title = $root.google.protobuf.StringValue.fromObject(object.title);
            }
            if (object.description != null) {
                if (typeof object.description !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisementV1.description: object expected");
                message.description = $root.google.protobuf.StringValue.fromObject(object.description);
            }
            if (object.thumbnailUrl != null) {
                if (typeof object.thumbnailUrl !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisementV1.thumbnailUrl: object expected");
                message.thumbnailUrl = $root.google.protobuf.StringValue.fromObject(object.thumbnailUrl);
            }
            if (object.linkUrl != null) {
                if (typeof object.linkUrl !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisementV1.linkUrl: object expected");
                message.linkUrl = $root.google.protobuf.StringValue.fromObject(object.linkUrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_advertisement.StoryAdvertisementV1
         * @static
         * @param {story_advertisement.StoryAdvertisementV1} message StoryAdvertisementV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = null;
                object.title = null;
                object.description = null;
                object.thumbnailUrl = null;
                object.linkUrl = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.google.protobuf.StringValue.toObject(message.id, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = $root.google.protobuf.StringValue.toObject(message.title, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = $root.google.protobuf.StringValue.toObject(message.description, options);
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = $root.google.protobuf.StringValue.toObject(message.thumbnailUrl, options);
            if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                object.linkUrl = $root.google.protobuf.StringValue.toObject(message.linkUrl, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisementV1 to JSON.
         * @function toJSON
         * @memberof story_advertisement.StoryAdvertisementV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementV1
         * @function getTypeUrl
         * @memberof story_advertisement.StoryAdvertisementV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_advertisement.StoryAdvertisementV1";
        };

        return StoryAdvertisementV1;
    })();

    story_advertisement.StoryAdvertisementListRequest = (function() {

        /**
         * Properties of a StoryAdvertisementListRequest.
         * @memberof story_advertisement
         * @interface IStoryAdvertisementListRequest
         * @property {google.protobuf.IStringValue|null} [storyId] StoryAdvertisementListRequest storyId
         */

        /**
         * Constructs a new StoryAdvertisementListRequest.
         * @memberof story_advertisement
         * @classdesc Represents a StoryAdvertisementListRequest.
         * @implements IStoryAdvertisementListRequest
         * @constructor
         * @param {story_advertisement.IStoryAdvertisementListRequest=} [properties] Properties to set
         */
        function StoryAdvertisementListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementListRequest storyId.
         * @member {google.protobuf.IStringValue|null|undefined} storyId
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @instance
         */
        StoryAdvertisementListRequest.prototype.storyId = null;

        /**
         * Creates a new StoryAdvertisementListRequest instance using the specified properties.
         * @function create
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @static
         * @param {story_advertisement.IStoryAdvertisementListRequest=} [properties] Properties to set
         * @returns {story_advertisement.StoryAdvertisementListRequest} StoryAdvertisementListRequest instance
         */
        StoryAdvertisementListRequest.create = function create(properties) {
            return new StoryAdvertisementListRequest(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementListRequest message. Does not implicitly {@link story_advertisement.StoryAdvertisementListRequest.verify|verify} messages.
         * @function encode
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @static
         * @param {story_advertisement.IStoryAdvertisementListRequest} message StoryAdvertisementListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
                $root.google.protobuf.StringValue.encode(message.storyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_advertisement.StoryAdvertisementListRequest} StoryAdvertisementListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_advertisement.StoryAdvertisementListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storyId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_advertisement.StoryAdvertisementListRequest} StoryAdvertisementListRequest
         */
        StoryAdvertisementListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.story_advertisement.StoryAdvertisementListRequest)
                return object;
            var message = new $root.story_advertisement.StoryAdvertisementListRequest();
            if (object.storyId != null) {
                if (typeof object.storyId !== "object")
                    throw TypeError(".story_advertisement.StoryAdvertisementListRequest.storyId: object expected");
                message.storyId = $root.google.protobuf.StringValue.fromObject(object.storyId);
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @static
         * @param {story_advertisement.StoryAdvertisementListRequest} message StoryAdvertisementListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.storyId = null;
            if (message.storyId != null && message.hasOwnProperty("storyId"))
                object.storyId = $root.google.protobuf.StringValue.toObject(message.storyId, options);
            return object;
        };

        /**
         * Converts this StoryAdvertisementListRequest to JSON.
         * @function toJSON
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementListRequest
         * @function getTypeUrl
         * @memberof story_advertisement.StoryAdvertisementListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_advertisement.StoryAdvertisementListRequest";
        };

        return StoryAdvertisementListRequest;
    })();

    story_advertisement.StoryAdvertisementListResponse = (function() {

        /**
         * Properties of a StoryAdvertisementListResponse.
         * @memberof story_advertisement
         * @interface IStoryAdvertisementListResponse
         * @property {Array.<story_advertisement.IStoryAdvertisementV1>|null} [storyAdvertisementList] StoryAdvertisementListResponse storyAdvertisementList
         */

        /**
         * Constructs a new StoryAdvertisementListResponse.
         * @memberof story_advertisement
         * @classdesc Represents a StoryAdvertisementListResponse.
         * @implements IStoryAdvertisementListResponse
         * @constructor
         * @param {story_advertisement.IStoryAdvertisementListResponse=} [properties] Properties to set
         */
        function StoryAdvertisementListResponse(properties) {
            this.storyAdvertisementList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StoryAdvertisementListResponse storyAdvertisementList.
         * @member {Array.<story_advertisement.IStoryAdvertisementV1>} storyAdvertisementList
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @instance
         */
        StoryAdvertisementListResponse.prototype.storyAdvertisementList = $util.emptyArray;

        /**
         * Creates a new StoryAdvertisementListResponse instance using the specified properties.
         * @function create
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @static
         * @param {story_advertisement.IStoryAdvertisementListResponse=} [properties] Properties to set
         * @returns {story_advertisement.StoryAdvertisementListResponse} StoryAdvertisementListResponse instance
         */
        StoryAdvertisementListResponse.create = function create(properties) {
            return new StoryAdvertisementListResponse(properties);
        };

        /**
         * Encodes the specified StoryAdvertisementListResponse message. Does not implicitly {@link story_advertisement.StoryAdvertisementListResponse.verify|verify} messages.
         * @function encode
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @static
         * @param {story_advertisement.IStoryAdvertisementListResponse} message StoryAdvertisementListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoryAdvertisementListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storyAdvertisementList != null && message.storyAdvertisementList.length)
                for (var i = 0; i < message.storyAdvertisementList.length; ++i)
                    $root.story_advertisement.StoryAdvertisementV1.encode(message.storyAdvertisementList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a StoryAdvertisementListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {story_advertisement.StoryAdvertisementListResponse} StoryAdvertisementListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoryAdvertisementListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.story_advertisement.StoryAdvertisementListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.storyAdvertisementList && message.storyAdvertisementList.length))
                        message.storyAdvertisementList = [];
                    message.storyAdvertisementList.push($root.story_advertisement.StoryAdvertisementV1.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Creates a StoryAdvertisementListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {story_advertisement.StoryAdvertisementListResponse} StoryAdvertisementListResponse
         */
        StoryAdvertisementListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.story_advertisement.StoryAdvertisementListResponse)
                return object;
            var message = new $root.story_advertisement.StoryAdvertisementListResponse();
            if (object.storyAdvertisementList) {
                if (!Array.isArray(object.storyAdvertisementList))
                    throw TypeError(".story_advertisement.StoryAdvertisementListResponse.storyAdvertisementList: array expected");
                message.storyAdvertisementList = [];
                for (var i = 0; i < object.storyAdvertisementList.length; ++i) {
                    if (typeof object.storyAdvertisementList[i] !== "object")
                        throw TypeError(".story_advertisement.StoryAdvertisementListResponse.storyAdvertisementList: object expected");
                    message.storyAdvertisementList[i] = $root.story_advertisement.StoryAdvertisementV1.fromObject(object.storyAdvertisementList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StoryAdvertisementListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @static
         * @param {story_advertisement.StoryAdvertisementListResponse} message StoryAdvertisementListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoryAdvertisementListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.storyAdvertisementList = [];
            if (message.storyAdvertisementList && message.storyAdvertisementList.length) {
                object.storyAdvertisementList = [];
                for (var j = 0; j < message.storyAdvertisementList.length; ++j)
                    object.storyAdvertisementList[j] = $root.story_advertisement.StoryAdvertisementV1.toObject(message.storyAdvertisementList[j], options);
            }
            return object;
        };

        /**
         * Converts this StoryAdvertisementListResponse to JSON.
         * @function toJSON
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoryAdvertisementListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoryAdvertisementListResponse
         * @function getTypeUrl
         * @memberof story_advertisement.StoryAdvertisementListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoryAdvertisementListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/story_advertisement.StoryAdvertisementListResponse";
        };

        return StoryAdvertisementListResponse;
    })();

    return story_advertisement;
})();

module.exports = $root;

import React from "react";
import styled from "@emotion/styled";

import { Image } from "../ui/image";

// Assets
import loadingIcon from "../../assets/spinner.svg";

const LoadingContainer = styled.div<{ noPadding?: boolean; noColor?: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 4px;
  padding: 20px;
  z-index: 9999;
  border-radius: 20px;
  background-color: white;

  > img {
    height: 60px;
    width: 60px;
  }
`;

export const ActivityModal: React.FC = () => (
  <>
    <LoadingContainer>
      <Image width="60" src={loadingIcon} />
    </LoadingContainer>
  </>
);

/*eslint-disable*/
/**
 *
 * pull-to-reload
 *
 * A pull-to-reload system that is compatible with both both web and mobile.
 * Configurable with loads of options.
 *
 * @author Erlend Ellingsen <erlend.ame@gmail.com>
 * @copyright MIT, Erlend Ellingsen
 * @version  1.1.5  08.03.2017
 */

/*
 *
 * This library has been quite modified for Teller Studio needs
 * Do not upgrade from original source as these features will be lost
 *
 */

export class PullToReload {
  constructor(optsUser) {
    const self = this;

    // --- OPTIONS ---
    this.opts = {
      "refresh-element": "ptr",
      "content-element": "content",
      "border-height": 1,
      height: 80,
      "font-size": "30px",
      threshold: 20,
      "default-text": "...",
      "default-inner": "...",
      "threshold-reached-text": "...",
      "loading-content": "Loading...",
      "callback-loading": function() {
        setTimeout(() => {
          self.loadingEnd();
        }, 1000);
      } // Required
    };

    // Overwrite options with user-options if present
    for (const prop in self.opts) {
      if (optsUser[prop] !== undefined) {
        self.opts[prop] = optsUser[prop];
      }
    }

    // --- INIT CODE ---
    this.ptr = self.opts["refresh-element"];
    this.content = self.opts["content-element"];

    // --- STYLING ---
    // Set style
    this.ptr.style.padding = "0px";
    this.ptr.style.margin = "0px";
    this.ptr.style.display = "block";
    this.ptr.style.height = `${self.opts.height}px`;
    this.ptr.style.border = `${self.opts["border-height"]}px solid #000`;
    this.ptr.style.borderTop = "0px";
    this.ptr.style.borderLeft = "0px";
    this.ptr.style.borderRight = "0px";
    this.ptr.style.textAlign = "center";
    this.ptr.style.lineHeight = `${self.opts.height}px`;
    this.ptr.style.fontSize = self.opts["font-size"];

    // Hide the margin
    this.ptr.style.marginTop = `-${self.opts["border-height"] +
      self.opts.height}px`;
    // --- CODE ---
    this.isLoading = false;

    // --- CODE: HANDLING  ---
    this.loadingStart = function() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      self.opts["callback-loading"]();
    };

    this.loadingEnd = function() {
      self.ptr.innerHTML = self.opts["default-inner"];

      this.ptr.style.marginTop = `-${self.opts["border-height"] +
        self.opts.height}px`;
      this.isLoading = false;
    };

    // --- CODE: COMMON FUNCS ---
    this.getPageY = function(event) {
      if (event.pageY === undefined && event.touches !== undefined) {
        if (event.touches.length <= 0) {
          return false;
        }
        event.pageY = event.touches[event.touches.length - 1].pageY;
      }
      return event.pageY;

      // end getPageY
    };

    // --- CODE: EVENTS  ---
    // EVENT: MOUSEUP
    this.isDragging = false;
    this.isThresholdReached = false;
    this.posStart = 0;

    self.content.addEventListener("touchstart", event => {
      self.mouseStart(event);
    });

    self.content.addEventListener("mousedown", event => {
      self.mouseStart(event);
    });

    this.mouseStart = function(event) {
      // Prevent PTR if position is beyond content start.
      if (self.content.scrollTop > 0) {
        return;
      }
      if (document.body.scrollTop >= self.content.getBoundingClientRect().top) {
        return;
      }

      self.isDragging = true;
      self.isThresholdReached = false;

      self.posStart = self.getPageY(event);

      // end mousedown touchstart
    };

    // EVENT: MOUSEUP
    document.addEventListener("touchmove", event => {
      self.mouseMove(event);
    });

    document.addEventListener("mousemove", event => {
      self.mouseMove(event);
    });

    this.mouseMove = function(event) {
      let pageY;
      // Prevent PTR if position is beyond content start.
      if (document.body.scrollTop >= self.content.getBoundingClientRect().top) {
        return;
      }

      if (!self.isDragging) {
        return;
      }

      // Calculate the drag distance
      // Android / Chrome compability. Sometimes consists of a list of touches.
      pageY = self.getPageY(event);
      if (pageY === false) {
        return;
      }

      const dragDistance = pageY - self.posStart;

      if (dragDistance <= 0) {
        return;
      } // Do not inverse the drag..

      // Prevent defaults after dragDistance-check. (We still want to keep site scrolling functionality.)
      const newMargin =
        self.opts["border-height"] + (self.opts.height - dragDistance);
      self.ptr.style.marginTop = `-${newMargin}px`;
      // Update
      if (newMargin <= self.opts.threshold) {
        this.ptr.classList.add("threshold");
        self.isThresholdReached = true;
        // self.ptr.style.marginTop = '-80px';
        self.loadingStart();
      } else {
        this.ptr.classList.remove("threshold");
        self.isThresholdReached = false;
      }
      event.preventDefault();
      event.stopImmediatePropagation();
    };

    // EVENT: MOUSEUP
    document.addEventListener("touchend", event => {
      self.mouseEnd(event);
    });
    document.addEventListener("mouseup", event => {
      self.mouseEnd(event);
    });

    this.mouseEnd = function(event) {
      // Prevent PTR if position is beyond content start.
      if (document.body.scrollTop >= self.content.getBoundingClientRect().top) {
        return;
      }

      if (!self.isDragging) {
        return;
      }

      // event.preventDefault();
      event.stopImmediatePropagation();

      if (self.isThresholdReached) {
        // self.ptr.style.marginTop = '-80px';
        self.isDragging = false;
        self.isThresholdReached = false;

        self.loadingStart();
        return;
      }

      // Reset margin
      self.ptr.style.marginTop = `-${self.opts["border-height"] +
        self.opts.height}px`;

      self.isDragging = false;
      self.isThresholdReached = false;

      // end mouseup touchend
    };

    // end PullToReload
  }
}

import { compare } from "compare-versions";
import { Platform } from "./constants";

type PlatformAndVersion = {
  platform: Platform;
  version: string;
};

/**
 * 指定のバージョン以上であるか
 * @param targetVersion 指定のプラットフォームとバージョン
 * @param currentVersion 現在のプラットフォームとバージョン
 * @returns
 */
export const isAvailable = (
  target: PlatformAndVersion,
  current: PlatformAndVersion
): boolean =>
  target.platform === current.platform &&
  compare(target.version, current.version, "<=");

/**
 * アプリでteller-studio://in-app-webviewを開くことができるか
 * @param target
 * @param current
 */
export const canOpenTellerWebURLInApp = (
  current: PlatformAndVersion
): boolean =>
  isAvailable(
    {
      version: "8.2.0",
      platform: Platform.ANDROID
    },
    current
  ) ||
  isAvailable(
    {
      version: "8.2.0",
      platform: Platform.IOS
    },
    current
  );

/**
 * アプリでReactNativeのスタジオを開くことができるか
 * @param target
 * @param current
 */
export const canUseReactNativeStudio = (current: PlatformAndVersion): boolean =>
  isAvailable(
    {
      version: "8.41.0",
      platform: Platform.IOS
    },
    current
  ) ||
  isAvailable(
    {
      version: "9.0.0",
      platform: Platform.ANDROID
    },
    current
  );

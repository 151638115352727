import { css, SerializedStyles } from "@emotion/react/macro";
import { px } from "../utils";

export interface MarginsProps {
  m?: number | string;
  mt?: number | string;
  mr?: number | string;
  mb?: number | string;
  ml?: number | string;
}

/**
 * Converts shorthand margin props to CSS declarations
 *
 * @example
 * // <Div m={12} />
 * const Div = styled.div<MarginsProps>`
 *  ${margins}
 * `;
 */
export const margins = ({
  m,
  mt,
  mr,
  mb,
  ml
}: MarginsProps): SerializedStyles => css`
  ${m && `margin: ${px(m)}`};
  ${mt && `margin-top: ${px(mt)}`};
  ${mr && `margin-right: ${px(mr)}`};
  ${mb && `margin-bottom: ${px(mb)}`};
  ${ml && `margin-left: ${px(ml)}`};
`;

import { useState, useCallback } from "react";

export interface ModalHandle {
  isOpen: boolean;
  open(): void;
  close(): void;
}

export const useModal = (): ModalHandle => {
  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    isOpen,
    open,
    close
  };
};

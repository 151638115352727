import { createContext, useContext } from "react";
import { ParsedUA } from "../infra/ua";

export const UAContext = createContext<ParsedUA | null>(null);

export const useUA = (): ParsedUA => {
  const ua = useContext(UAContext);

  if (!ua) {
    throw new Error(
      `Shouldn't use "useUA()" outside an "<UAContext.Provider>"`
    );
  }

  return ua;
};

import styled from "@emotion/styled";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useBeforeUnload } from "react-use";
import useSWR from "swr";
import nl2br from "react-nl2br";
import { story as RpcStory } from "../infra/api/rpc/api";
import { Episode, toMarkdown } from "../models/episode";
import { PublishRange } from "../models/novel";
import { formattedDateTime } from "../utils/utils";

// Usecase
import { NovelUseCase } from "../usecases/novelUseCase";

// Hooks
import { useAuth } from "../hooks/useAuth";
import { useLayoutUI } from "../hooks/useLayoutUI";
import { useSendEvents } from "../hooks/useSendEvents";
import { useAppParameters } from "../hooks/useAppParameters";
import { useContestAcknowledge } from "../hooks/useContestAcknowledge";

// Components
import { FeedbackPanel } from "../components/common/feedbackPanel";
import { ActivityModal } from "../components/common/activityModal";
import { Alert } from "../components/common/alert";
import { NovelInformationFormActionSheet } from "../components/novel/novelInformationFormActionSheet";
import { EpisodeTitleActionSheet } from "../components/episode/episodeTitleActionSheet";
import { EpisodeReadyModal } from "../components/episode/episodeReadyModal";
import { EpisodeHeader } from "../components/episode/episodeHeader";
import { EpisodeDiscardActionSheet } from "../components/episode/episodeDiscardActionSheet";
import { Editor } from "../components/episode/editor";
import { Color } from "../components/styles/enums";
import { useModal } from "../hooks/useModal";
import { AboutRatingModal } from "../components/common/aboutRatingModal";
import { EpisodeContestModal } from "../components/episode/episodeContestModal";

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  overflow-y: hidden;
`;

const Content = styled.div`
  max-width: 960px;
  margin: auto;
`;

type Props = {
  novelId: string;
  episodeId: string;
};

const novelUseCase = new NovelUseCase();

export const EpisodeWriteContainer: React.FC = () => {
  const { novelId, episodeId } = useParams<Props>();
  const {
    user: authUser,
    tellerUser,
    isOfficialWriter,
    requestReloadMe
  } = useAuth();
  const { isApp } = useAppParameters();
  const { sendStoryCreateDone, sendStoryCreateStart } = useSendEvents();
  const { setLayout } = useLayoutUI();
  const [layoutSet, setLayoutSet] = useState(false);
  const history = useHistory();
  const [content, setContent] = useState<string | null>(null);
  const [originalContent, setOriginalContent] = useState("");
  const [isEmptyOrAboveLimit, setIsEmptyOrAboveLimit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [toPublish, setToPublish] = useState(false);
  const [isNext, setNext] = useState(false);
  const [readyForPublish, setReadyForPublish] = useState(false);
  const [alertErrorMsg, setAlertErrorMsg] = useState<string | null>(null);
  const [isShowAlert, setShowAlert] = useState(false);
  const [isGoBackOnCloseAlert, setGoBackOnCloseAlert] = useState(false);
  const [feedbackMsg, setFeedbackMsg] = useState<string | null>(null);
  const [feedbackError, setFeedbackError] = useState(false);
  const isNewNovel = novelId === "new";
  const [createStorySent, setCreateStorySent] = useState(false);
  const [updateStorySent, setUpdateStorySent] = useState(false);

  const [
    episodeTitleActionSheetIsOpen,
    setEpisodeTitleActionSheetIsOpen
  ] = useState<boolean>(false);

  const [episodeReadyModalIsOpen, setEpisodeReadyModalIsOpen] = useState<
    boolean
  >(false);

  const [
    episodeDiscardActionSheetIsOpen,
    setEpisodeDiscardActionSheetIsOpen
  ] = useState(false);

  const openEpisodeDiscardActionSheet = useCallback(() => {
    setEpisodeDiscardActionSheetIsOpen(true);
  }, []);

  const closeEpisodeDiscardActionSheet = useCallback(() => {
    setEpisodeDiscardActionSheetIsOpen(false);
  }, []);

  const {
    checkContestTags,
    openContestModal,
    selectedContestTag,
    isOpen: contestModalIsOpen,
    close: closeContestModal
  } = useContestAcknowledge();

  // About Rating Modal
  const aboutRatingModal = useModal();

  const hasContent = content ? content.length > 1 : false;
  const urlParams = new URLSearchParams(window.location.search);
  const from = urlParams.get("from");
  const count = Number(urlParams.get("c")) || 0;
  const defaultTagsParam = urlParams.get("tags");
  const defaultTags = useMemo(
    () => (defaultTagsParam ? defaultTagsParam.split(",") : []),
    [defaultTagsParam]
  );

  const {
    data: episode,
    mutate: mutateEpisode,
    isValidating: isReloadingEpisode
  } = useSWR(
    tellerUser?.id?.value
      ? ["/api/editor/episode", tellerUser.id.value, novelId, episodeId]
      : null,
    (_, uId, nId, eId) =>
      novelUseCase.ensureEpisode(
        uId,
        isOfficialWriter,
        defaultTags,
        nId,
        eId,
        count
      ),
    {
      revalidateOnFocus: false
    }
  );

  useEffect(() => {
    if (episode) {
      setOriginalContent(episode.novelScript || "");
    }
  }, [episode]);

  const showAlert = useCallback((msg: string, goBackOnClose?: boolean) => {
    setAlertErrorMsg(msg);
    setShowAlert(true);
    if (goBackOnClose) {
      setGoBackOnCloseAlert(true);
    }
  }, []);

  const showFeedback = useCallback((msg: string, isError = false) => {
    setFeedbackMsg(msg);
    setFeedbackError(isError);
    setTimeout(() => {
      setFeedbackMsg(null);
    }, 3500);
  }, []);

  const redirectBack = useCallback(() => {
    if (from === "home") {
      history.push("/");
    } else if (novelId !== "new") {
      history.push(`/novel/${novelId}?refresh=1`);
    } else {
      history.push("/");
    }
  }, [from, history, novelId]);

  const contentHasChanged = useCallback(() => {
    const currentContent = toMarkdown(content?.trim() || "");
    return (
      !isReloadingEpisode &&
      content !== null &&
      originalContent.trim() !== currentContent.trim()
    );
  }, [content, isReloadingEpisode, originalContent]);

  const checkContentChange = useCallback(() => {
    if (contentHasChanged()) {
      openEpisodeDiscardActionSheet();
      return;
    }
    redirectBack();
  }, [contentHasChanged, openEpisodeDiscardActionSheet, redirectBack]);

  const showAlertBeforeClose = useCallback(
    () => !isApp && contentHasChanged(),
    [contentHasChanged, isApp]
  );

  // we can add a message, but is the alert content is really up to browser
  useBeforeUnload(
    showAlertBeforeClose,
    "このページから移動しますか？ 入力したデータは保存されません。"
  );

  const discardChanges = useCallback(() => {
    closeEpisodeDiscardActionSheet();
    redirectBack();
  }, [closeEpisodeDiscardActionSheet, redirectBack]);

  const redirectToCorrectPlace = useCallback(
    (goBack: boolean, novId: string, epId: string) => {
      setTimeout(() => {
        history.push(
          goBack
            ? `/novel/${novId}?refresh=1`
            : `/novel/${novId}/episodes/${epId}?notransition=1`
        );
      }, 2000);
    },
    [history]
  );

  const getUpdatedEpisode = useCallback(
    (): Episode =>
      ({
        ...episode,
        userId: episode?.userId || tellerUser?.id?.value || "",
        title: episode?.title || "第1話",
        isOfficial: isOfficialWriter,
        novelScript: toMarkdown(content || "")
      } as Episode),
    [content, episode, isOfficialWriter, tellerUser?.id?.value]
  );

  const createEpisodeAndNovel = useCallback(
    async (withRedirectBack: boolean) => {
      const updatedEpisode = getUpdatedEpisode();
      updatedEpisode.novelTitle = `一時保存:${formattedDateTime()}`;
      if (defaultTags) {
        updatedEpisode.tags = defaultTags;
      }
      try {
        setIsSaving(true);
        const createdIds = await novelUseCase.createEpisode(updatedEpisode);
        const newNovelId = createdIds.novelId;
        requestReloadMe();
        showFeedback("下書きを保存しました。");
        sendStoryCreateDone(
          createdIds.episodeId,
          episodeId,
          episode?.status === RpcStory.StoryStatus.PUBLISH, // is published ?
          episode?.novelPublishFor || null, // published for
          true, // is new
          false, // is new published
          updatedEpisode?.tags
        );
        redirectToCorrectPlace(
          withRedirectBack,
          newNovelId,
          createdIds.episodeId
        );
      } catch (err) {
        showAlert("不明なエラーが発生しました。");
      } finally {
        setIsSaving(false);
      }
    },
    [
      defaultTags,
      episode?.novelPublishFor,
      episode?.status,
      episodeId,
      getUpdatedEpisode,
      redirectToCorrectPlace,
      requestReloadMe,
      sendStoryCreateDone,
      showAlert,
      showFeedback
    ]
  );

  const createEpisode = useCallback(
    async (withRedirectBack: boolean) => {
      const updatedEpisode = getUpdatedEpisode();
      updatedEpisode.novelScript = toMarkdown(content || "");
      try {
        setIsSaving(true);
        const createdIds = await novelUseCase.createEpisode(updatedEpisode);
        const newNovelId = createdIds.novelId;
        showFeedback("下書きを保存しました。");
        sendStoryCreateDone(
          createdIds.episodeId,
          episodeId,
          episode?.status === RpcStory.StoryStatus.PUBLISH,
          updatedEpisode?.novelPublishFor || null,
          true, // is new
          false, // is new published
          updatedEpisode.tags
        );
        requestReloadMe();
        redirectToCorrectPlace(
          withRedirectBack,
          newNovelId,
          createdIds.episodeId
        );
      } catch (err) {
        showAlert("不明なエラーが発生しました。");
      } finally {
        setIsSaving(false);
      }
    },
    [
      content,
      episode?.status,
      episodeId,
      getUpdatedEpisode,
      redirectToCorrectPlace,
      requestReloadMe,
      sendStoryCreateDone,
      showAlert,
      showFeedback
    ]
  );

  const updateEpisode = useCallback(
    async (withRedirectBack: boolean) => {
      try {
        const updatedEpisode = getUpdatedEpisode();

        setIsSaving(true);
        await novelUseCase.updateEpisode(updatedEpisode);
        requestReloadMe();

        sendStoryCreateDone(
          episodeId,
          episodeId,
          episode?.status === RpcStory.StoryStatus.PUBLISH,
          episode?.novelPublishFor || null,
          false, // is new
          false, // is new published
          updatedEpisode.tags
        );
        showFeedback("更新が完了しました。");
        if (withRedirectBack) {
          setTimeout(() => {
            history.push(`/novel/${novelId}`);
          }, 2000);
        }
      } catch (err) {
        showAlert("不明なエラーが発生しました。");
      } finally {
        setIsSaving(false);
      }
    },
    [
      episode?.novelPublishFor,
      episode?.status,
      episodeId,
      getUpdatedEpisode,
      history,
      novelId,
      requestReloadMe,
      sendStoryCreateDone,
      showAlert,
      showFeedback
    ]
  );

  const saveChanges = useCallback(
    async (withRedirectBack: boolean) => {
      closeContestModal();
      closeEpisodeDiscardActionSheet();
      if (novelId === "new") {
        // create episode creating also a new novel with datetime as title (quick save draft case)
        await createEpisodeAndNovel(withRedirectBack);
      } else if (episodeId === "new") {
        // novel exists, create episode with predefined title
        await createEpisode(withRedirectBack);
      } else {
        // just save episode contents
        await updateEpisode(withRedirectBack);
      }
    },
    [
      closeContestModal,
      closeEpisodeDiscardActionSheet,
      createEpisode,
      createEpisodeAndNovel,
      episodeId,
      novelId,
      updateEpisode
    ]
  );

  const closeAlert = useCallback(() => {
    setAlertErrorMsg(null);
    setShowAlert(false);
    if (isGoBackOnCloseAlert) {
      redirectBack();
    }
  }, [isGoBackOnCloseAlert, redirectBack]);

  const [
    novelInformationFormActionSheetIsOpen,
    setNovelInformationFormActionSheetIsOpen
  ] = useState(false);

  const openNovelInformationFormActionSheet = useCallback(() => {
    setNovelInformationFormActionSheetIsOpen(true);
  }, []);

  const closeNovelInformationFormActionSheet = useCallback(() => {
    setNovelInformationFormActionSheetIsOpen(false);
  }, []);

  const handleContentChange = useCallback((changedContent: string) => {
    setContent(changedContent);
  }, []);

  const openEpisodeReadyModal = useCallback(() => {
    setEpisodeReadyModalIsOpen(true);
  }, []);

  const closeEpisodeReadyModal = useCallback(() => {
    setEpisodeReadyModalIsOpen(false);
    history.push("/");
  }, [history]);

  const openEpisodeTitleActionSheet = useCallback(() => {
    setEpisodeTitleActionSheetIsOpen(true);
  }, []);

  const closeEpisodeTitleActionSheet = useCallback(
    (
      saved: boolean,
      error: boolean,
      isNew: boolean,
      discardFromGuidelinesModal: boolean,
      novId?: string | null,
      epId?: string | null,
      epTitle?: string
    ) => {
      if (error) {
        showFeedback(
          "不明なエラーが発生しました。\nしばらくしてからもう一度お試しください",
          true
        );
      }
      setEpisodeTitleActionSheetIsOpen(false);
      if (saved) {
        if (episode && epTitle) {
          episode.title = epTitle;
        }
        if (readyForPublish) {
          openEpisodeReadyModal();
          return;
        }

        if (toPublish && !discardFromGuidelinesModal) {
          if (novId && epId && epTitle) {
            const isNewPublish = episode?.isPublished === false;
            sendStoryCreateDone(
              epId,
              episodeId,
              true, // is published
              episode?.novelPublishFor || null,
              isNew,
              isNewPublish,
              episode?.tags
            );
            history.push(
              `/novel/${novId}?published=1&title=${epTitle}&id=${epId}`
            );
          } else {
            history.push("/");
          }
        } else {
          if (episode?.id) {
            sendStoryCreateDone(
              episode.id,
              episodeId,
              episode?.status === RpcStory.StoryStatus.PUBLISH,
              episode?.novelPublishFor || null,
              isNew,
              false, // is new published ?
              episode.tags
            );
          }
          if (episode?.status === RpcStory.StoryStatus.DRAFT) {
            showFeedback("下書きを保存しました。");
          } else {
            showFeedback("更新が完了しました");
            if (isNext) {
              setTimeout(() => {
                history.push(`/novel/${episode?.novelId}`);
              }, 2000);
            }
          }
        }
      }
    },
    [
      episode,
      episodeId,
      history,
      isNext,
      openEpisodeReadyModal,
      readyForPublish,
      sendStoryCreateDone,
      showFeedback,
      toPublish
    ]
  );

  const onSave = useCallback(
    (
      saved: boolean,
      error: boolean,
      published: boolean,
      publishedFor: PublishRange | null,
      isNew: boolean,
      tags?: string[] | null,
      novId?: string | null,
      epId?: string | null,
      epTitle?: string
    ) => {
      if (error) {
        // TODO: parse error types from server and show good message
        showFeedback(
          "不明なエラーが発生しました。\nしばらくしてからもう一度お試しください",
          true
        );
        return;
      }

      if (published) {
        if (novId && epId && epTitle) {
          if (epId) {
            const isNewPublish = episode?.isPublished === false;
            sendStoryCreateDone(
              epId,
              episodeId,
              true, // published
              publishedFor,
              isNew,
              isNewPublish,
              tags
            );
          }
          history.push(
            `/novel/${novId}?published=1&title=${epTitle}&id=${epId}&refresh=1`
          );
        } else {
          history.push("/");
        }
        return;
      }

      if (readyForPublish) {
        openEpisodeReadyModal();
        return;
      }
      mutateEpisode();

      if (epId) {
        sendStoryCreateDone(
          epId,
          episodeId,
          episode?.status === RpcStory.StoryStatus.PUBLISH,
          publishedFor,
          isNew,
          false, // is new published
          tags
        );
      }
      showFeedback(
        episode?.status === RpcStory.StoryStatus.DRAFT
          ? "下書きを保存しました。"
          : "更新が完了しました"
      );
    },
    [
      episode?.isPublished,
      episode?.status,
      episodeId,
      history,
      mutateEpisode,
      openEpisodeReadyModal,
      readyForPublish,
      sendStoryCreateDone,
      showFeedback
    ]
  );

  const next = useCallback(() => {
    if (hasContent) {
      if (isOfficialWriter) {
        setReadyForPublish(true);
      } else {
        setNext(true);
        setToPublish(
          !episode?.status || episode?.status === RpcStory.StoryStatus.DRAFT
        );
      }
      if (isNewNovel || !episode?.thumbnailId) {
        openNovelInformationFormActionSheet();
        return;
      }
      openEpisodeTitleActionSheet();
    }
  }, [
    isOfficialWriter,
    episode,
    hasContent,
    isNewNovel,
    openEpisodeTitleActionSheet,
    openNovelInformationFormActionSheet
  ]);

  const edit = useCallback(() => {
    if (isNewNovel || !episode?.thumbnailId) {
      openNovelInformationFormActionSheet();
      return;
    }

    setToPublish(false);
    openEpisodeTitleActionSheet();
  }, [
    episode,
    isNewNovel,
    openEpisodeTitleActionSheet,
    openNovelInformationFormActionSheet
  ]);

  useEffect(() => {
    if (episode?.title && !layoutSet) {
      setLayout({
        title: episode?.title,
        noScroll: true
      });
      setLayoutSet(true);
    }
  }, [episode, layoutSet, setLayout]);

  useEffect(() => {
    if (episodeId !== "new" && episode === null) {
      // This is serious problem, so show big alert and go back on `close`
      showAlert("ストーリーが存在しません", true);
    }
  }, [episode, episodeId, showAlert]);

  useEffect(() => {
    // Was created and this page refreshed for reflecting new episode ID
    if (isApp !== undefined && episode !== undefined) {
      if (episodeId === "new" && !createStorySent) {
        sendStoryCreateStart(episodeId, episodeId, false, true, episode?.tags);
        setCreateStorySent(true);
      }
      if (episodeId !== "new" && !updateStorySent) {
        sendStoryCreateStart(
          episodeId,
          episodeId,
          episode?.status === RpcStory.StoryStatus.PUBLISH,
          false,
          episode?.tags
        );
        setUpdateStorySent(true);
      }
    }
  }, [
    authUser,
    createStorySent,
    episode,
    episodeId,
    from,
    isApp,
    sendStoryCreateStart,
    tellerUser,
    updateStorySent
  ]);

  const checkContestBeforeSave = useCallback(() => {
    if (!episode) return;
    if (
      episode.status === RpcStory.StoryStatus.PUBLISH &&
      episode.tags &&
      checkContestTags(episode.tags)
    ) {
      openContestModal(episode.tags);
      return;
    }
    saveChanges(true);
  }, [checkContestTags, episode, openContestModal, saveChanges]);

  return (
    <div>
      <PageWrapper>
        {isSaving ? <ActivityModal /> : null}
        {episodeId !== "new" && episode === undefined ? (
          <ActivityModal />
        ) : null}

        {isShowAlert && alertErrorMsg ? (
          <Alert msg={alertErrorMsg} close={closeAlert} />
        ) : null}
        <EpisodeHeader
          episode={episode}
          content={content || ""}
          isEmptyOrAboveLimit={isEmptyOrAboveLimit}
          onNewNovel={() => saveChanges(false)}
          onSave={onSave}
          onClose={checkContentChange}
          onNext={next}
          onEdit={edit}
        />
        <Content>
          {feedbackMsg ? (
            <FeedbackPanel isError={feedbackError}>
              {nl2br(feedbackMsg)}
            </FeedbackPanel>
          ) : null}
          {!isReloadingEpisode ? (
            <Editor
              isLoading={episodeId !== "new" && !episode}
              isNew={episodeId === "new"}
              onChange={handleContentChange}
              content={originalContent}
              onError={(msg: string) => {
                showAlert(msg);
              }}
              setIsEmptyOrAboveLimit={setIsEmptyOrAboveLimit}
            />
          ) : null}
        </Content>
      </PageWrapper>

      <NovelInformationFormActionSheet
        open={novelInformationFormActionSheetIsOpen}
        episode={episode}
        defaultTags={defaultTags}
        content={content || ""}
        openAboutRatingModal={aboutRatingModal.open}
        onClose={closeNovelInformationFormActionSheet}
        onSave={onSave}
        isForPublish={toPublish}
        isWritePage
      />

      <EpisodeDiscardActionSheet
        open={episodeDiscardActionSheetIsOpen}
        isNew={episodeId === "new"}
        onSave={checkContestBeforeSave}
        onDiscard={discardChanges}
        onClose={closeEpisodeDiscardActionSheet}
      />

      <AboutRatingModal
        open={aboutRatingModal.isOpen}
        onClose={aboutRatingModal.close}
      />

      {episode ? (
        <>
          <EpisodeTitleActionSheet
            open={episodeTitleActionSheetIsOpen}
            isForPublish={toPublish}
            episode={episode}
            content={content || ""}
            openAboutRatingModal={aboutRatingModal.open}
            onClose={closeEpisodeTitleActionSheet}
          />
          <EpisodeReadyModal
            open={episodeReadyModalIsOpen}
            novelTitle={episode.novelTitle || ""}
            episodeTitle={episode.title}
            onClose={closeEpisodeReadyModal}
          />
        </>
      ) : null}
      {selectedContestTag && (
        <EpisodeContestModal
          open={contestModalIsOpen}
          onClose={closeContestModal}
          onCloseWithAcknowledge={() => saveChanges(true)}
          contestTagName={selectedContestTag}
        />
      )}
    </div>
  );
};

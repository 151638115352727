import { query_types as RpcQueryTypes } from "../infra/api/rpc/api";

export const Genre = {
  GENRE_HORROR_THRILLER: "ホラー・ミステリー",
  GENRE_ROMANCE: "恋愛・ロマンス",
  GENRE_BL: "BL",
  GENRE_DRAMA: "ドラマ",
  GENRE_FANTASY: "ファンタジー・異世界・SF",
  GENRE_ROMANCE_FANTASY: "ロマンスファンタジー",
  GENRE_COMEDY: "コメディ",
  GENRE_FANFIC: "二次創作・夢小説",
  GENRE_OTHER: "雑談、その他"
} as const;
export type Genre = typeof Genre[keyof typeof Genre];

export const rpcGenreToGenre = (
  rpcGenre: RpcQueryTypes.Genre | null
): Genre | null => {
  if (!rpcGenre) return null;
  switch (rpcGenre) {
    case RpcQueryTypes.Genre.GENRE_BL:
      return Genre.GENRE_BL;
    case RpcQueryTypes.Genre.GENRE_COMEDY:
      return Genre.GENRE_COMEDY;
    case RpcQueryTypes.Genre.GENRE_DRAMA:
      return Genre.GENRE_DRAMA;
    case RpcQueryTypes.Genre.GENRE_FANFIC:
      return Genre.GENRE_FANFIC;
    case RpcQueryTypes.Genre.GENRE_FANTASY:
      return Genre.GENRE_FANTASY;
    case RpcQueryTypes.Genre.GENRE_HORROR_THRILLER:
      return Genre.GENRE_HORROR_THRILLER;
    case RpcQueryTypes.Genre.GENRE_OTHER:
      return Genre.GENRE_OTHER;
    case RpcQueryTypes.Genre.GENRE_ROMANCE:
      return Genre.GENRE_ROMANCE;
    case RpcQueryTypes.Genre.GENRE_ROMANCE_FANTASY:
      return Genre.GENRE_ROMANCE_FANTASY;
    default:
      return Genre.GENRE_OTHER;
  }
};

export const genreToRpcGenre = (genre: Genre | null): RpcQueryTypes.Genre => {
  switch (genre) {
    case Genre.GENRE_BL:
      return RpcQueryTypes.Genre.GENRE_BL;
    case Genre.GENRE_COMEDY:
      return RpcQueryTypes.Genre.GENRE_COMEDY;
    case Genre.GENRE_DRAMA:
      return RpcQueryTypes.Genre.GENRE_DRAMA;
    case Genre.GENRE_FANFIC:
      return RpcQueryTypes.Genre.GENRE_FANFIC;
    case Genre.GENRE_FANTASY:
      return RpcQueryTypes.Genre.GENRE_FANTASY;
    case Genre.GENRE_HORROR_THRILLER:
      return RpcQueryTypes.Genre.GENRE_HORROR_THRILLER;
    case Genre.GENRE_OTHER:
      return RpcQueryTypes.Genre.GENRE_OTHER;
    case Genre.GENRE_ROMANCE:
      return RpcQueryTypes.Genre.GENRE_ROMANCE;
    case Genre.GENRE_ROMANCE_FANTASY:
      return RpcQueryTypes.Genre.GENRE_ROMANCE_FANTASY;
    default:
      return RpcQueryTypes.Genre.GENRE_UNSPECIFIED;
  }
};

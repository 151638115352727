// Core
import styled from "@emotion/styled";
import { useCallback, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  query_recursive_types as RpcRecursiveTypes,
  story as RpcStory
} from "../../infra/api/rpc/api";
import { useChatStudioRedirect } from "../../hooks/useChatStudioRedirect";
import { useChatPreview } from "../../hooks/useChatPreview";
import viewsIcon from "../../assets/views_icon.svg";
import moreIcon from "../../assets/more_icon.png";
import { Color, FontSize, Media } from "../styles/enums";
import { Alert } from "../common/alert";
import { Image } from "../ui/image";
import { Text } from "../ui/text";

const EpisodeItem = styled.div<{ isSelected: boolean }>`
  position: relative;
  cursor: pointer;
  background: ${Color.PRIMARY};
  padding: 12px 16px;
  border-bottom: 1px solid ${Color.SEPARATOR};

  &:hover {
    background: ${Color.HIGHLIGHT};
  }

  @media ${Media.SMALL} {
    ${({ isSelected }) => isSelected && `background: ${Color.HIGHLIGHT};`};
  }
`;

const TitleAndMore = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 22px;
  grid-column-gap: 12px;
`;

const EpisodeIndex = styled(Text)`
  font-size: ${FontSize.SIZE_10};
  color: ${Color.ACCENT_500};
  font-weight: 700;
`;

const TitleWrapper = styled.div`
  max-width: 85vw;

  @media ${Media.SMALL} {
    max-width: 75vw;
  }
`;

const Title = styled.div`
  display: \-webkit-box;

  text-align: left;
  vertical-align: middle;
  color: ${Color.ACCENT_1000};
  font-size: ${FontSize.SIZE_14};
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  grid-column: 1 / -1;
`;

const MoreWrapper = styled.button`
  height: 36px;
  width: 36px;
  position: relative;
  top: -10px;
  right: 7px;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${Color.WHITE};
    opacity: 0.5;
  }
`;

const More = styled(Image)`
  width: 18px;
`;

const StatusAndViews = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${FontSize.SIZE_12};
`;

const Views = styled.div`
  font-weight: normal;
  display: inline-flex;
`;

const Status = styled.span<{ published: boolean }>`
  display: block;
  font-size: ${FontSize.SIZE_12};
  font-weight: 700;
  display: block;
  ${({ published }) => `color: ${published ? Color.GREEN : Color.RED};`}
`;

interface Props {
  episode: RpcRecursiveTypes.IStoryResponse;
  screenLarge: boolean;
  userUid?: string;
  novelId?: string;
  isOfficialWriter: boolean;
  isChat: boolean;
  onClickMore?(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
  onToChatStudio?(): void;
}

export const EpisodeCell: React.FC<Props> = ({
  episode,
  screenLarge,
  novelId,
  isOfficialWriter,
  isChat,
  onClickMore,
  onToChatStudio
}) => {
  const { goToChatStudio } = useChatStudioRedirect();
  const { previewInWeb } = useChatPreview();
  const [isSelected, setSelected] = useState(false);

  const link = useMemo(
    () =>
      `/novel/${novelId ||
        episode?.series?.id?.value ||
        episode?.id?.value}/episodes/${episode.id?.value}`,
    [episode.id?.value, episode?.series?.id?.value, novelId]
  );

  const history = useHistory();

  const [isShowRejectedAlert, setShowRejectedAlert] = useState(false);

  const showRejectedAlert = useCallback(() => {
    setShowRejectedAlert(true);
  }, []);

  const closeRejectedAlert = useCallback(() => {
    setShowRejectedAlert(false);
  }, []);

  const click = useCallback(async () => {
    if (onToChatStudio) {
      onToChatStudio();
    }
    const isPublish = episode.status === RpcStory.StoryStatus.PUBLISH;
    if (isChat && episode?.id?.value) {
      if (isOfficialWriter && isPublish) {
        previewInWeb(episode.id.value, screenLarge);
        return;
      }
      goToChatStudio(`/stories/${episode?.id.value}`);
      return;
    }
    if (episode.status === RpcStory.StoryStatus.REJECT) {
      showRejectedAlert();
      return;
    }

    if (isOfficialWriter && isPublish) {
      history.push(`/preview/${novelId}/episodes/${episode.id?.value}`);
    } else {
      setSelected(true);
      history.push(link);
    }
  }, [
    episode.id?.value,
    episode.status,
    goToChatStudio,
    history,
    isChat,
    isOfficialWriter,
    link,
    novelId,
    onToChatStudio,
    previewInWeb,
    screenLarge,
    showRejectedAlert
  ]);

  const statusString = useMemo(() => {
    if (episode.status === RpcStory.StoryStatus.REJECT) {
      return "非公開";
    }
    if (episode.status === RpcStory.StoryStatus.PUBLISH) {
      return "公開中";
    }
    return "下書き";
  }, [episode.status]);

  return (
    <>
      <EpisodeItem onClick={click} isSelected={isSelected}>
        <TitleAndMore>
          <TitleWrapper>
            <EpisodeIndex>第{episode.seriesIndex?.value || 1}話</EpisodeIndex>
            <Title>{episode.title?.value}</Title>
          </TitleWrapper>
          {episode.status !== RpcStory.StoryStatus.REJECT ? (
            <MoreWrapper onClick={onClickMore}>
              <More src={moreIcon} />
            </MoreWrapper>
          ) : null}
        </TitleAndMore>
        <StatusAndViews>
          <Status
            as="span"
            published={episode.status === RpcStory.StoryStatus.PUBLISH}
          >
            {statusString}
          </Status>
          {episode.viewCount?.value ? (
            <Views>
              <Image src={viewsIcon} />
              {episode.viewCount?.value}
            </Views>
          ) : null}
        </StatusAndViews>
      </EpisodeItem>
      {isShowRejectedAlert ? (
        <Alert
          msg="この作品はテラーノベルのコミュニティガイドラインに違反しているため、非公開に設定されました。"
          close={closeRejectedAlert}
        />
      ) : null}
    </>
  );
};

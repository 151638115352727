import styled from "@emotion/styled";
import { ComponentProps } from "react";
import officialUserBadgeImage from "../../assets/official_user_badge.svg";
import vipUserBadgeImage from "../../assets/vip_badge.png";
import vipUserBadgeImageWebp from "../../assets/vip_badge.webp";
import vipUserBadgeImageBig from "../../assets/vip_badge_big.png";
import vipUserBadgeImageBigWebp from "../../assets/vip_badge_big.webp";
import { Color, FontSize } from "../styles/enums";
import { ellipsis } from "../styles/mixins/ellipsis";
import { Image } from "../ui/image";
import { Text } from "../ui/text";

const Username = styled(Text)<{
  limitWidth?: number;
  big?: number;
  header?: number;
}>`
  display: inline;
  color: ${Color.ACCENT_700};
  line-height: 1;
  vertical-align: middle;
  ${({ limitWidth }) => limitWidth && ellipsis("80%")};
  ${({ big }) => big && `font-size: ${FontSize.SIZE_18}; font-weight: bold;`}
`;

const Wrapper = styled.div<{ medium?: number; big?: number; header?: number }>`
  font-size: ${FontSize.SIZE_12};
  ${({ medium }) =>
    medium && `font-size: ${FontSize.SIZE_14}; font-weight: bold;`}
  ${({ big }) => big && `font-size: ${FontSize.SIZE_18}; font-weight: bold;`}
  ${({ header }) => header && `font-size: ${FontSize.SIZE_16};`}
`;

interface Props extends ComponentProps<typeof Text> {
  username: string;
  isOfficialWriter?: boolean;
  isVip?: boolean;
  medium?: boolean;
  big?: boolean;
  header?: boolean;
  limitWidth?: boolean;
}

export const UsernameWithBadges: React.FC<Props> = ({
  username,
  isOfficialWriter,
  isVip,
  medium,
  big,
  header,
  limitWidth
}) => {
  const showVipUserBadge = !isOfficialWriter && isVip;
  return (
    <Wrapper
      medium={medium ? 1 : undefined}
      big={big ? 1 : undefined}
      header={header ? 1 : undefined}
    >
      <Username
        as={big ? "h1" : "p"}
        limitWidth={limitWidth ? 1 : undefined}
        big={big ? 1 : undefined}
        header={header ? 1 : undefined}
      >
        {username || "ゲスト"}
      </Username>
      {isOfficialWriter ? (
        <Image
          src={officialUserBadgeImage}
          width={big ? 24 : 12}
          height={big ? 24 : 12}
          alt="公式"
          lazy
          mt={big ? "-3px" : 0}
          ml={4}
        />
      ) : null}
      {showVipUserBadge ? (
        <picture>
          <source
            type="image/webp"
            srcSet={big ? vipUserBadgeImageBigWebp : vipUserBadgeImageWebp}
          />
          <Image
            src={big ? vipUserBadgeImageBig : vipUserBadgeImage}
            width={big ? 44 : 22}
            height={big ? 24 : 12}
            alt="VIP"
            lazy
            ml={4}
          />
        </picture>
      ) : null}
    </Wrapper>
  );
};

import React, { useCallback, useState, useRef, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Modal } from "./modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Image } from "../ui/image";
import { Button, ButtonVariant } from "../ui/button";
import { useMedia } from "../../hooks/useMedia";
import closeIcon from "../../assets/close_icon.svg";
import { useAppParameters } from "../../hooks/useAppParameters";

// step 2
const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  background: ${Color.WHITE};
  height: 44px;
  box-shadow: inset 0px -1px 0px ${Color.ACCENT_50};
  text-align: center;
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  line-height: 44px;
  color: ${Color.ACCENT_1000};

  @media ${Media.LARGE} {
    width: 50vw;

    > img {
      cursor: pointer;
    }
  }
`;

const Title = styled.div`
  flex: 1;
  text-align: center;
  margin: 0;
`;

const Content = styled.div<{ iframeLoaded: boolean }>`
  background-color: ${Color.ACCENT_50};
  flex: 1;

  iframe {
    width: 100%;
    height: 100%;
    transition: opacity 256ms ease-in-out;
    opacity: ${({ iframeLoaded }) => (iframeLoaded ? 1 : 0)};
  }
`;

const FixedFooter = styled.div`
  background-color: ${Color.WHITE};
  text-align: center;
  width: 100%;
  flex-shrink: 0;
  padding: 16px 16px 32px;
  > button {
    font-size: ${FontSize.SIZE_14};
  }

  @media ${Media.LARGE} {
    width: 50vw;
    > button {
      font-size: ${FontSize.SIZE_16};
    }
  }
`;

interface IMessageEventData {
  action: "scroll" | "link";
}

type IFrameScrollMessageEventData = IMessageEventData & {
  action: "scroll";
  scrollPercentage: number;
};

type IFrameLinkMessageEventData = IMessageEventData & {
  action: "link";
  url: string;
};

type IFrameMessageEventData =
  | IFrameScrollMessageEventData
  | IFrameLinkMessageEventData;

interface Props {
  open: boolean;
  onClose(acknowledged?: boolean): void;
}

export const MustAcknowledgeModal: React.FC<Props> = ({ open, onClose }) => {
  const { isApp } = useAppParameters();
  const screenLarge = useMedia(Media.LARGE);
  const [scrolledDown, setScrolledDown] = useState(false);
  const [isIframeLoaded, setIframeLoaded] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Detect iframe content and adjust parent height accordingly
  const handleIframeLoad = useCallback(() => {
    if (iframeRef.current) {
      setIframeLoaded(true);
    }
  }, []);

  const handleMessageEvent = useCallback(
    (event: MessageEvent<IFrameMessageEventData>): void => {
      // originが異なったメッセージはハンドリングしない
      if (
        process.env.NODE_ENV === "production" &&
        event.origin &&
        new URL(event.origin).host !== process.env.REACT_APP_WEB_DOMAIN
      )
        return;

      // scrollイベント以外はハンドリングしない
      switch (event.data.action) {
        case "scroll":
          // スクロールしたときに有効化
          if (scrolledDown === false && event.data.scrollPercentage >= 0.95) {
            setScrolledDown(true);
          }
          break;
        case "link": {
          if (!isApp) return;
          const isValidUrl =
            process.env.NODE_ENV === "production"
              ? new URL(event.data.url).host ===
                process.env.REACT_APP_WEB_DOMAIN
              : false;

          if (!isValidUrl) return;

          // アプリ内ブラウザで開く
          window.location.href = `teller-studio://in-app-webview?url=${event.data.url}`;

          break;
        }
        default:
      }
    },
    [isApp, scrolledDown]
  );

  // Detect then the user scrolled all the way down
  useEffect(() => {
    window?.addEventListener("message", handleMessageEvent);

    return () => {
      window?.removeEventListener("message", handleMessageEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = useCallback(() => {
    setScrolledDown(false);
    onClose();
  }, [onClose]);

  const acknowledge = useCallback(() => {
    onClose(true);
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width={screenLarge ? "50vw" : "100vw"}
      height="100vh"
      noPadding
      close={closeModal}
      hideClose
      wholeSmallScreen={!screenLarge}
      overlayClose={false}
      overflowScrolling={false}
      modalWrapperStyles={{
        overflow: "hidden"
      }}
    >
      <Wrapper>
        <Header>
          <Image
            src={closeIcon}
            height={20}
            width={20}
            ml={12}
            onClick={closeModal}
          />
          <Title>画像アップロード時の注意（著作権編）</Title>
        </Header>
        <Content iframeLoaded={isIframeLoaded}>
          <iframe
            ref={iframeRef}
            title="画像アップロード時の注意（著作権編）"
            src={`//${
              process.env.REACT_APP_WEB_DOMAIN
            }/community-guideline/copyright-protection/?nolayout=1${
              isApp ? "&isApp=1" : ""
            }`}
            onLoad={handleIframeLoad}
            style={{
              border: "0px"
            }}
          />
        </Content>
        <FixedFooter>
          <Button
            type="button"
            variant={ButtonVariant.PILL}
            color={Color.TINT}
            block
            big
            onClick={acknowledge}
            disabled={!scrolledDown}
          >
            記載された内容を十分に確認・理解しました
          </Button>
        </FixedFooter>
      </Wrapper>
    </Modal>
  );
};

import React from "react";
import styled from "@emotion/styled/macro";

// Components
import { Modal } from "../common/modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";
import { Button, ButtonVariant } from "../ui/button";

const ContentWrapper = styled.div`
  text-align: center;
  @media ${Media.LARGE} {
    padding: 8px 40px;
  }
`;

const Title = styled(Text)`
  font-size: ${FontSize.SIZE_20};
  font-weight: 700;
`;

interface Props {
  open: boolean;
  novelTitle: string;
  episodeTitle: string;
  onClose(): void;
}

export const EpisodeReadyModal: React.FC<Props> = ({
  open,
  novelTitle,
  episodeTitle,
  onClose
}) => {
  if (!open) {
    return null;
  }

  return (
    <Modal open={open} width="400px" showCloseIconInSmall close={onClose}>
      <ContentWrapper>
        <Title>保存完了しました</Title>
        <Text mt={30} fz={FontSize.SIZE_18} fw="bold">
          {novelTitle}
        </Text>
        <Text mt={5} fz={FontSize.SIZE_14} fw="bold">
          {episodeTitle}
        </Text>

        <Button
          type="button"
          variant={ButtonVariant.PILL}
          color={Color.TINT}
          block
          big
          mt={40}
          mb={38}
          onClick={onClose}
        >
          トップに戻る
        </Button>
      </ContentWrapper>
    </Modal>
  );
};

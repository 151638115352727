import { useCallback } from "react";
import { useAuthToken } from "./useAuthToken";
import { useAuth } from "./useAuth";
import { useAppParameters } from "./useAppParameters";

interface UseChatStudioRedirectHandle {
  goToChatStudio(path?: string, params?: string): void;
}

export const useChatStudioRedirect = (): UseChatStudioRedirectHandle => {
  const { ensureAuthToken } = useAuthToken();
  const { parametersFromApp, isApp } = useAppParameters();
  const { user } = useAuth();

  const goToChatStudio = useCallback(
    async (path?: string, params?: string) => {
      const webToken = isApp
        ? await ensureAuthToken().then(token => `&webToken=${token}`)
        : "";
      const studioPath = `//${process.env.REACT_APP_STUDIO_BASE_URL}`;
      const toPath = path || "";

      window.location.href = `${studioPath}${toPath}?webUid=${
        user?.uid
      }${webToken}${params || ""}${parametersFromApp()}`;
    },
    [parametersFromApp, user?.uid, isApp, ensureAuthToken]
  );

  return { goToChatStudio };
};

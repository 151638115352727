// Core
import styled from "@emotion/styled";
import { useCallback, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  query_recursive_types as RpcRecursiveTypes,
  query_types as RpcQueryTypes,
  types as RpcTypes
} from "../../infra/api/rpc/api";

import noThumbnailImage from "../../assets/no_thumbnail.png";
import rightArrow from "../../assets/right_arrow.svg";
import forFollowerIcon from "../../assets/for_followers_icon.png";
import forMutualFollowIcon from "../../assets/for_mutual_follow_icon.png";
import { Alert } from "../common/alert";
import { Color, FontSize, Media } from "../styles/enums";
import { Image } from "../ui/image";
import { Text } from "../ui/text";
import { NovelInformationFormActionSheet } from "./novelInformationFormActionSheet";
import { AboutRatingModal } from "../common/aboutRatingModal";
import { useModal } from "../../hooks/useModal";

const NovelItem = styled.div<{
  isEpisodeListing?: boolean;
  isSelected: boolean;
}>`
  position: relative;
  display: grid;
  cursor: pointer;
  ${({ isEpisodeListing }) =>
    `grid-template-columns: 116px 1fr ${isEpisodeListing ? 10 : 18}px;`}
  grid-column-gap: 12px;
  color: ${Color.ACCENT_700};
  text-align: left;
  align-items: flex-start;
  background: ${Color.PRIMARY};
  padding: 12px 16px;
  ${({ isEpisodeListing }) =>
    isEpisodeListing && `border-bottom: 1px solid ${Color.SEPARATOR};`};

  &::before {
    content: "";
    position: absolute;
    display: block;
    background: ${Color.ACCENT_50};
    left: 30px;
    right: 30px;
    height: 1px;
    bottom: 0;
  }

  &:last-of-type::before {
    height: 0;
  }

  @media ${Media.SMALL} {
    padding: 12px 16px;

    &::before {
      left: 8px;
      right: 8px;
    }

    ${({ isSelected }) => isSelected && `background: ${Color.HIGHLIGHT};`};
  }

  @media (hover) {
    &:hover {
      background: ${Color.HIGHLIGHT};

      .readMore {
        background-image: linear-gradient(
          to right,
          rgba(233, 240, 247, 0) 0,
          rgb(233, 240, 247) 30px
        );
      }
    }
  }
`;

const Information = styled.div`
  position: relative;
  overflow: hidden;
  font-weight: 700;
`;

const Legend = styled(Text)`
  font-size: ${FontSize.SIZE_10};
  color: ${Color.ACCENT_500};
`;

const Title = styled(Text)`
  display: \-webkit-box;
  text-align: left;
  vertical-align: middle;
  color: ${Color.ACCENT_1000};
  overflow: hidden;
  line-height: 21px;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  grid-column: 1 / -1;
`;

const Thumbnail = styled.div`
  position: relative;
  width: 116px;
  height: 78px;
`;

const ThumbnailImage = styled(Image)`
  object-fit: cover;
  min-height: 78px;
  max-height: 79px;
  width: 116px;
`;

const Next = styled(Image)`
  width: 8px;
  align-self: center;
`;

const Status = styled.span<{ published: boolean }>`
  display: block;
  font-size: ${FontSize.SIZE_12};
  display: block;
  ${({ published }) => `color: ${published ? Color.GREEN : Color.RED};`}
`;

const Tags = styled.div`
  display: block;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  margin-top: 2px;
  max-width: 95%;
`;

const Tag = styled(Text)`
  display: inline-block;
  flex: 0 0 auto;
  margin-right: 10px;
  font-size: ${FontSize.SIZE_12};
  color: ${Color.ACCENT_700};
  font-weight: normal;
`;

const Badge = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: ${FontSize.SIZE_10};
  font-weight: 700;
  color: ${Color.PRIMARY};
  padding: 2px 6px;
  line-height: 15px;
  background: rgba(102, 102, 102, 0.5);
  border: 0.5px solid #d7d8da;
  backdrop-filter: blur(1px);
  border-radius: 20px;
  height: 19px;
  width: 35px;
`;

const PublishedForRangeBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(102, 102, 102, 0.6);
  backdrop-filter: blur(1px);
  border-radius: 0px 4px 0px 0px;
  width: 20px;
  height: 20px;
`;

interface Props {
  novel: RpcRecursiveTypes.ISeriesResponse;
  isEpisodeListing?: boolean;
  novelStatusFromEpisodes?: boolean;
  seriesTypeFromEpisodes?: RpcQueryTypes.StoryScriptType | null;
  readOnly?: boolean;
  isOfficialWriter: boolean;
  onGuidelinesCheckNeeded?: (novelId: string) => void;
}

export const NovelCell: React.FC<Props> = ({
  novel,
  isEpisodeListing,
  novelStatusFromEpisodes,
  seriesTypeFromEpisodes,
  readOnly,
  isOfficialWriter,
  onGuidelinesCheckNeeded
}) => {
  const history = useHistory();
  const [isPublished, setIsPublished] = useState<boolean | undefined>(
    undefined
  );

  const [isSelected, setSelected] = useState(false);

  const [
    novelInformationFormActionSheetIsOpen,
    setNovelInformationFormActionSheetIsOpen
  ] = useState(false);

  const openNovelInformationFormActionSheet = useCallback(() => {
    setNovelInformationFormActionSheetIsOpen(true);
  }, []);

  const closeNovelInformationFormActionSheet = useCallback(() => {
    setNovelInformationFormActionSheetIsOpen(false);
  }, []);

  // About Rating Modal
  const aboutRatingModal = useModal();

  const onSave = useCallback(() => {
    // Refresh
    setTimeout(() => {
      history.go(0);
    }, 1000);
  }, [history]);

  // Alert handle
  const [alertMsg, setAlertMsg] = useState<string | null>(null);
  const [isShowAlert, setShowAlert] = useState(false);

  const showAlert = useCallback((msg: string) => {
    setAlertMsg(msg);
    setShowAlert(true);
  }, []);

  const closeAlert = useCallback(() => {
    setAlertMsg(null);
    setShowAlert(false);
  }, []);

  const click = useCallback(() => {
    if (readOnly) {
      return;
    }
    if (
      novel.id?.value &&
      novel.showGuidelineDialog?.value &&
      onGuidelinesCheckNeeded
    ) {
      onGuidelinesCheckNeeded(novel.id?.value);
      return;
    }

    if (isEpisodeListing) {
      if (isOfficialWriter && isPublished) {
        showAlert("全体公開されているので編集できません。お問い合わせください");
        return;
      }
      openNovelInformationFormActionSheet();
    } else {
      setSelected(true);
      history.push(`/novel/${novel.id?.value}`);
    }
  }, [
    history,
    isEpisodeListing,
    isOfficialWriter,
    isPublished,
    novel.id?.value,
    novel.showGuidelineDialog?.value,
    onGuidelinesCheckNeeded,
    openNovelInformationFormActionSheet,
    readOnly,
    showAlert
  ]);

  const title = novel.title?.value;
  const thumbnail = novel.thumbnail?.servingUrl?.value;

  useEffect(() => {
    if (isEpisodeListing) {
      setIsPublished(novelStatusFromEpisodes);
      return;
    }
    const novelPublishedStories = novel?.searchableStoryCount?.value || 0;

    setIsPublished(novelPublishedStories > 0);
  }, [isEpisodeListing, novel, novelStatusFromEpisodes]);

  const legend = useMemo(() => {
    if (seriesTypeFromEpisodes) {
      if (
        seriesTypeFromEpisodes ===
        RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_CHAT_NOVEL
      ) {
        return "チャットノベル";
      }
      if (
        seriesTypeFromEpisodes ===
        RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_NOVEL
      ) {
        return "ノベル";
      }
    }
    if (novel.hasChatNovelScriptStory?.value) {
      return "チャットノベル";
    }
    return "ノベル";
  }, [novel.hasChatNovelScriptStory?.value, seriesTypeFromEpisodes]);

  const publishForBadge = useMemo(() => {
    if (
      novel?.sharedWithStatus === RpcTypes.SharedWithStatus.SHARED_WITH_FOLLOWER
    ) {
      return forFollowerIcon;
    }
    if (
      novel?.sharedWithStatus ===
      RpcTypes.SharedWithStatus.SHARED_WITH_MUTUAL_FOLLOWER
    ) {
      return forMutualFollowIcon;
    }
    return null;
  }, [novel?.sharedWithStatus]);

  return (
    <>
      <NovelItem
        onClick={click}
        isEpisodeListing={isEpisodeListing}
        isSelected={isSelected}
      >
        <Thumbnail>
          <ThumbnailImage
            src={thumbnail ? `${thumbnail}=s264` : noThumbnailImage}
            alt={title || "ノベル画像"}
            width={116}
            height={77}
            rounded
            lazy
          />
          {novel.isOneshot?.value && <Badge>読切</Badge>}
          {!novel.isOneshot?.value && novel.isCompleted?.value && (
            <Badge>完結</Badge>
          )}
          {publishForBadge && (
            <PublishedForRangeBadge>
              <Image src={publishForBadge} width={16} />
            </PublishedForRangeBadge>
          )}
        </Thumbnail>
        <Information>
          <Legend>{legend}</Legend>

          <Text fz={FontSize.SIZE_14} fw="bold" as="span">
            <Title as="span">{title}</Title>
            {isEpisodeListing && novel.tags && novel.tags?.length > 0 ? (
              <Tags>
                {novel.tags.map(t => (
                  <Tag key={t.value}>#{t.value}</Tag>
                ))}
              </Tags>
            ) : null}
            {isPublished !== undefined && isPublished !== null ? (
              <Status as="span" published={isPublished}>
                {isPublished ? "公開中" : "下書き"}
              </Status>
            ) : null}
          </Text>
        </Information>
        {!readOnly && isEpisodeListing && <Next src={rightArrow} />}
      </NovelItem>
      <NovelInformationFormActionSheet
        open={novelInformationFormActionSheetIsOpen}
        novel={novel}
        openAboutRatingModal={aboutRatingModal.open}
        onClose={closeNovelInformationFormActionSheet}
        onSave={onSave}
        isForPublish={false}
      />
      <AboutRatingModal
        open={aboutRatingModal.isOpen}
        onClose={aboutRatingModal.close}
      />
      {isShowAlert && alertMsg ? (
        <Alert msg={alertMsg} close={closeAlert} />
      ) : null}
    </>
  );
};

import React from "react";
import styled from "@emotion/styled";
import { Color } from "../styles/enums";
import { Image } from "../ui/image";

// Assets
import loadingIcon from "../../assets/spinner.svg";

const LoadingContainer = styled.div<{ noPadding?: boolean; noColor?: boolean }>`
  ${({ noPadding }) => `padding: ${noPadding ? 0 : 100}px`};
  ${({ noColor }) =>
    `background-color: ${noColor ? "transparent" : Color.WHITE}`};
  text-align: center;
  margin: auto;
`;

export const Loading: React.FC<{
  noPadding?: boolean;
  noColor?: boolean;
  small?: boolean;
}> = ({ noPadding, noColor, small }) => (
  <LoadingContainer noPadding={noPadding} noColor={noColor}>
    <Image
      lazy
      src={loadingIcon}
      width={small ? 25 : 50}
      height={small ? 25 : 50}
    />
  </LoadingContainer>
);

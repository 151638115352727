import styled from "@emotion/styled/macro";
import { Color } from "../../styles/enums";
import { margins, MarginsProps } from "../../styles/mixins/margins";
import { typography, TypographyProps } from "../../styles/mixins/typography";
import { shouldForwardProp } from "../../styles/utils";

interface Props
  extends React.HTMLAttributes<HTMLElement>,
    TypographyProps,
    MarginsProps {
  color?: Color;
  as?: keyof JSX.IntrinsicElements;
}

export const Text = styled("p", {
  shouldForwardProp: shouldForwardProp<keyof Props>({
    ignore: ["color"]
  })
})<Props>`
  ${({ color }) => color && `color: ${color}`};
  ${typography};
  ${margins};
`;

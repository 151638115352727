import { useCallback, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import random from "lodash/random";

const BG_TYPES = [
  "bg-1",
  "bg-2",
  "bg-3",
  "bg-4",
  "bg-5",
  "bg-6",
  "bg-7",
  "bg-8"
];

export const DEFAULT_THUMBNAIL_WIDTH = 780;
export const DEFAULT_THUMBNAIL_HEIGHT = 520;
export const DEFAULT_THUMBNAIL_FORMAT = "png";

export const useDefaultThumbnail = (
  hasSelectedThumbnail: boolean,
  novelTitle: string | undefined
): {
  defaultThumbnailUrl: string | undefined;
  refreshBgType: () => void;
} => {
  const [defaultBgTypeIdx, setDefaultBgTypeIdx] = useState(
    random(0, BG_TYPES.length - 1)
  );
  const [debouncedNovelTitle, setDebouncedNovelTitle] = useState("");
  const refreshBgType = useCallback(() => {
    if (defaultBgTypeIdx + 1 < BG_TYPES.length) {
      setDefaultBgTypeIdx(i => i + 1);
    } else {
      setDefaultBgTypeIdx(0);
    }
  }, [defaultBgTypeIdx]);

  useDebounce(
    () => {
      setDebouncedNovelTitle(novelTitle ?? "");
    },
    200,
    [novelTitle]
  );

  const defaultThumbnailUrl = useMemo(() => {
    if (hasSelectedThumbnail) return undefined;
    return `//${
      process.env.REACT_APP_STUDIO_API_DOMAIN
    }/generate/text?title=${debouncedNovelTitle || "タイトル"}&bgType=${
      BG_TYPES[defaultBgTypeIdx]
    }&format=${DEFAULT_THUMBNAIL_FORMAT}&width=${DEFAULT_THUMBNAIL_WIDTH}&height=${DEFAULT_THUMBNAIL_HEIGHT}`;
  }, [hasSelectedThumbnail, debouncedNovelTitle, defaultBgTypeIdx]);

  return {
    defaultThumbnailUrl,
    refreshBgType
  };
};

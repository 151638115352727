import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { useCallback, useState, useRef } from "react";
import useSWR from "swr";

import { ActivityModal } from "../common/activityModal";
import { Alert } from "../common/alert";

import { NovelUseCase } from "../../usecases/novelUseCase";

import closeIcon from "../../assets/close_icon.svg";
import thumbnailUploadIcon from "../../assets/thumbnail_upload_icon.svg";
import { resource as RpcResource } from "../../infra/api/rpc/api";
import { fetchPresetThumbnails } from "../../usecases/resourceUseCase";
import { ActionSheet } from "../common/actionSheet";
import { Color, FontSize } from "../styles/enums";
import { Image } from "../ui/image";
import { Text } from "../ui/text";
import { loadWithOrientation, getFileInfo } from "../../utils/imageUtils";
import { useImageUploadWarningModal } from "../../hooks/useImageUploadWarningModal";
import { ImageUploadWarningModal } from "../common/imageUploadWarningModal";

interface Props {
  open: boolean;
  onChange(thumbnail: RpcResource.IImage): void;
  onClose(): void;
}

const Header = styled.div`
  position: fixed;
  width: 100%;
  border-bottom: 1px solid ${Color.ACCENT_50};
  padding: 12px 16px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Tabs = styled.ul`
  padding: 12px 16px;
  overflow: auto;
  white-space: nowrap;
  position: relative;
  top: 50px;
`;

const Body = styled.div`
  position: relative;
  top: 52px;
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 100px;
`;

const Tab = styled.li<{ selected: boolean }>`
  display: inline;

  + li {
    margin-left: 16px;
  }

  button {
    cursor: pointer;
    color: ${Color.ACCENT_700};

    :hover {
      opacity: 0.7;
    }
  }

  ${({ selected }) =>
    selected
      ? css`
          button {
            color: ${Color.ACCENT_1000};
            font-weight: bold;
            pointer-events: none;
          }
        `
      : null}
`;

const Thumbnails = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  li {
    aspect-ratio: 1;
  }

  button {
    cursor: pointer;
    width: 100%;
    height: 100%;

    :hover {
      opacity: 0.7;
    }
  }

  img {
    object-fit: cover;
  }
`;

const ThumbnailUploadButton = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0efff;
  cursor: pointer;
  width: 100%;
  height: 100%;

  :hover {
    opacity: 0.7;
  }

  input[type="file"] {
    display: none;
  }
`;

const novelUseCase = new NovelUseCase();

export const NovelThumbnailSelectionActionSheet: React.VFC<Props> = ({
  open,
  onChange,
  onClose
}) => {
  const { data: presetThumbnails } = useSWR(
    "/api/fetchPresetThumbnails",
    fetchPresetThumbnails
  );

  const [
    selectedSubcategory,
    setSelectedSubcategory
  ] = useState<RpcResource.IPresetImageSubcategory | null>(null);

  const [alertErrorMsg, setAlertErrorMsg] = useState<string | null>(null);
  const [isShowAlert, setShowAlert] = useState(false);
  const [isUploading, setUploading] = useState(false);

  const showAlert = useCallback((msg: string) => {
    setAlertErrorMsg(msg);
    setShowAlert(true);
  }, []);

  const closeAlert = useCallback(() => {
    setAlertErrorMsg(null);
    setShowAlert(false);
  }, []);

  const handleTabChange = useCallback(
    (subcategory: RpcResource.IPresetImageSubcategory | null) => {
      setSelectedSubcategory(subcategory);
    },
    []
  );

  const handleFileChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    async e => {
      if (!e.target.files) {
        return;
      }
      setUploading(true);
      const file = e.target.files[0];
      if (file) {
        const imageData = await loadWithOrientation(file);
        const canvas = imageData.image as HTMLCanvasElement;
        const base64 = canvas.toDataURL(file.type);
        const fileInfo = await getFileInfo(base64);
        if (fileInfo.isQR) {
          showAlert("この画像はアップできません。");
          setUploading(false);
          return;
        }

        const extension = file.name.split(".").pop() || "jpeg";
        const rpcImage = await novelUseCase.createImageOnServer(
          base64,
          extension,
          fileInfo
        );
        if (!rpcImage) {
          showAlert("画像のアップロードに失敗しました");
          setUploading(false);
          return;
        }
        setUploading(false);
        onChange(rpcImage);
        onClose();
      }
    },
    [onChange, onClose, showAlert]
  );

  const imageFileRef = useRef<HTMLInputElement | null>(null);

  const {
    isOpen: isImageWarningModalOpen,
    open: openImageWarningModal,
    close: closeImageWarningModal,
    shouldShowImageWarning
  } = useImageUploadWarningModal();

  const imageUploadClick = useCallback(
    (e?: React.MouseEvent) => {
      if (shouldShowImageWarning) {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        openImageWarningModal();
      }
    },
    [openImageWarningModal, shouldShowImageWarning]
  );

  const handleThumbnailClick = useCallback(
    (thumbnail: RpcResource.IImage) => {
      onChange(thumbnail);
      onClose();
    },
    [onChange, onClose]
  );

  const onCloseWarningModal = useCallback(() => {
    closeImageWarningModal();
    setTimeout(() => {
      imageFileRef.current?.click();
    }, 1000);
  }, [closeImageWarningModal]);

  return (
    <>
      {isUploading ? <ActivityModal /> : null}
      {isShowAlert && alertErrorMsg ? (
        <Alert msg={alertErrorMsg} close={closeAlert} />
      ) : null}
      <ActionSheet open={open} onClose={onClose}>
        <Header>
          <Text fz={FontSize.SIZE_16} fw="bold">
            作品カバー
          </Text>
          <CloseButton type="button" onClick={onClose}>
            <Image src={closeIcon} width={18} height={18} alt="閉じる" />
          </CloseButton>
        </Header>

        <Tabs>
          <Tab selected={selectedSubcategory === null}>
            <button type="button" onClick={() => handleTabChange(null)}>
              すべて
            </button>
          </Tab>
          {presetThumbnails?.subcategoryList
            ?.map(subcategory => {
              const name = subcategory.name?.value;

              return name && name !== "未設定" ? (
                <Tab key={name} selected={selectedSubcategory === subcategory}>
                  <button
                    type="button"
                    onClick={() => handleTabChange(subcategory)}
                  >
                    {name}
                  </button>
                </Tab>
              ) : null;
            })
            .filter(Boolean)}
        </Tabs>
        <Body>
          <Thumbnails>
            <li>
              <ThumbnailUploadButton>
                <input
                  ref={imageFileRef}
                  type="file"
                  accept="image/*"
                  onClick={imageUploadClick}
                  onChange={handleFileChange}
                />
                <Image
                  src={thumbnailUploadIcon}
                  width={40}
                  height={36}
                  mb={8}
                />
                <Text fz={FontSize.SIZE_14} fw="bold" color={Color.TINT}>
                  画像をアップロード
                </Text>
                <Text fz={FontSize.SIZE_12} color={Color.ACCENT_500}>
                  (おすすめサイズ600x400)
                </Text>
              </ThumbnailUploadButton>
            </li>
            {(selectedSubcategory
              ? selectedSubcategory.presetImageList
              : presetThumbnails?.subcategoryList
                  ?.map(subcategory => subcategory.presetImageList ?? [])
                  .flat()
            )
              ?.map(presetImage => {
                const { thumbnail } = presetImage;
                const id = thumbnail?.id?.value;
                const servingUrl = thumbnail?.servingUrl?.value;

                return thumbnail && id && servingUrl ? (
                  <li key={id}>
                    <button
                      type="button"
                      onClick={() => handleThumbnailClick(thumbnail)}
                    >
                      <Image src={servingUrl} width="100%" height="100%" />
                    </button>
                  </li>
                ) : null;
              })
              .filter(Boolean)}
          </Thumbnails>
        </Body>
      </ActionSheet>
      {shouldShowImageWarning ? (
        <ImageUploadWarningModal
          open={isImageWarningModalOpen}
          onClose={onCloseWarningModal}
        />
      ) : null}
    </>
  );
};

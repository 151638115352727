import { AppError, ErrorType } from "../../models/appError";

export function handleError(response: Response): Response {
  if (response.status === 409) {
    throw new AppError(
      ErrorType.API_REQUEST_409,
      "",
      response.status,
      response
    );
  }
  if (response.status >= 400 && response.status < 500) {
    throw new AppError(
      ErrorType.API_REQUEST_404,
      "",
      response.status,
      response
    );
  }
  if (response.status >= 500 && response.status < 600) {
    throw new AppError(
      ErrorType.API_REQUEST_500,
      "",
      response.status,
      response
    );
  }
  return response;
}

import React from "react";
import styled from "@emotion/styled/macro";

// Components
import { Modal } from "./modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Button, ButtonVariant } from "../ui/button";
import { Text } from "../ui/text";

const ContentWrapper = styled.div`
  text-align: center;
  padding: 48px 16px 40px;
`;

const Title = styled(Text)`
  font-size: ${FontSize.SIZE_18};
  font-weight: 600;
  line-height: 1.4;
`;

const Message = styled(Text)`
  font-size: ${FontSize.SIZE_14};
  margin-top: 24px;

  > span {
    font-weight: normal;
  }

  > a {
    color: ${Color.TINT};
    font-weight: normal;
  }
`;

const CloseButton = styled(Button)`
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  height: 40px;
  max-width: 300px;
  border: 1px solid ${Color.ACCENT_700};
  margin-right: 20px;

  @media ${Media.SMALL} {
    height: 46px;
  }
`;

const ChangeToSeriesButton = styled(Button)`
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  height: 40px;
  max-width: 300px;
  margin: auto;
`;

const Buttons = styled.div`
  margin: 40px auto 0;
  display: flex;
`;

interface Props {
  open: boolean;
  onClose: () => void;
  onChangeToSeries: () => void;
}

export const OneStoryOnlyWarningModal: React.FC<Props> = ({
  open,
  onClose,
  onChangeToSeries
}) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      width="400px"
      height="auto"
      noPadding
      hideClose
      close={onClose}
      overlayClose={false}
    >
      <ContentWrapper>
        <Title>この作品を読切から連載に変更しますか？</Title>
        <Message>
          1話以上の作品は自動的に読切から連載に変更されます。よろしいですか？
        </Message>
        <Buttons>
          <CloseButton
            block
            variant={ButtonVariant.OUTLINED}
            color={Color.ACCENT_700}
            onClick={onClose}
          >
            戻る
          </CloseButton>
          <ChangeToSeriesButton
            block
            variant={ButtonVariant.PILL}
            color={Color.TINT}
            onClick={onChangeToSeries}
          >
            連載に変更する
          </ChangeToSeriesButton>
        </Buttons>
      </ContentWrapper>
    </Modal>
  );
};

import styled from "@emotion/styled/macro";
import { margins, MarginsProps } from "../../styles/mixins/margins";

interface Props extends MarginsProps {
  as?: keyof JSX.IntrinsicElements;
}

export const Container = styled.div<Props>`
  max-width: 1280px;
  margin: 0 auto;
  ${margins};
`;

import { User } from "firebase/auth";
import { useEffect, useState, useMemo } from "react";
import useSWR, { mutate } from "swr";
import {
  query_recursive_types as RpcQueryRecursiveTypes,
  query_types as RpcQueryTypes
} from "../infra/api/rpc/api";
import { AuthUseCase } from "../usecases/authUseCase";

interface AuthState {
  user: User | null | undefined;
  tellerUser: RpcQueryRecursiveTypes.IUserResponse | null;
  isVipUser: boolean;
  isOfficialWriter: boolean;
  requestFetchMe: boolean;
}

export const AUTH_KEY = "AUTH_STATE";
export const AUTH_INITIAL_STATE: AuthState = {
  user: undefined,
  tellerUser: null,
  requestFetchMe: false,
  isVipUser: false,
  isOfficialWriter: false
};

const authUseCase = new AuthUseCase();

export const useAuthHandle = (): AuthState => {
  const { data = AUTH_INITIAL_STATE } = useSWR(AUTH_KEY, {
    initialData: AUTH_INITIAL_STATE
  });
  const [firebaseUser, setFirebaseUser] = useState<User | null | undefined>(
    undefined
  );

  const toReturn = data;

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const webToken = urlParams.get("webToken");
  const webUid = urlParams.get("webUid");

  useEffect(() => {
    if (firebaseUser === null) {
      mutate(
        AUTH_KEY,
        {
          ...data,
          user: null
        },
        false
      );
    }

    if (firebaseUser?.uid !== data?.user?.uid) {
      const mustLoadTellerUser = !data.tellerUser || firebaseUser !== data.user;
      mutate(
        AUTH_KEY,
        {
          ...data,
          user: firebaseUser,
          requestFetchMe: mustLoadTellerUser
        },
        false
      );
    }
  }, [data, firebaseUser]);

  useEffect(() => {
    const unsubscribe = authUseCase.detectAuthChange(user => {
      if (webUid && webToken && user && user.uid !== webUid) {
        // If coming back from Chat Studio but user doesn't match, login with chat studio user
        authUseCase.loginByToken(webToken).then(u => {
          setFirebaseUser(u.user);
        });
      } else {
        setFirebaseUser(user);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [webToken, webUid]);

  useEffect(() => {
    if (data.requestFetchMe && data.user) {
      authUseCase.fetchMe().then(result => {
        mutate(
          AUTH_KEY,
          {
            ...data,
            tellerUser: result,
            isVipUser:
              result.vipStatus === RpcQueryTypes.VipStatus.VIP_STATUS_VIP ||
              result.vipStatus === RpcQueryTypes.VipStatus.VIP_STATUS_IN_TRIAL,
            isOfficialWriter:
              result.userRole ===
              RpcQueryTypes.UserRole.USER_ROLE_OFFICIAL_WRITER,
            requestFetchMe: false
          },
          false
        );
      });
    }
  }, [data, data.requestFetchMe]);

  return {
    user: toReturn.user,
    tellerUser: toReturn.tellerUser,
    isVipUser: toReturn.isVipUser,
    isOfficialWriter: toReturn.isOfficialWriter,
    requestFetchMe: toReturn.requestFetchMe
  };
};

import { RPCImpl } from "protobufjs";
import {
  query as RpcQuery,
  query_pagination as RpcQueryPagination,
  query_recursive_types as RpcRecursiveTypes,
  query_search_types as RpcSearchTypes,
  event as RpcEvent,
  story as RpcStory,
  query_search_v2 as RpcQuerySearchV2
} from "../infra/api/rpc/api";
import { TellerApi } from "../infra/api/tellerApi";

const servicePath = "/query.QueryService/";

/*
 * Definition of common structures for wrapping Query Service requests
 * from here ↓
 */

type Pagination = {
  page?: number;
  cursor?: string;
  limit: number;
};

/*
 * Request types
 *
 */
type UserStoriesRequest = {
  seriesPagination: Pagination;
};

type NovelRequest = {
  novelId: string;
  userId: string;
};

type NovelEpisodesRequest = {
  novelId: string;
  episodePagination: Pagination;
};

type EpisodeRequest = {
  episodeId: string;
  withScript?: boolean;
};

type EpisodeForTikTokRequest = {
  episodeId: string;
};

type StoryFacetTagRequest = {
  query?: string;
  pagination?: Pagination;
};

type MeRequest = Record<string, never>;

type SearchQueryRequest = {
  pagination: Pagination;
};

type EventListRequest = {
  filterLabel?: RpcEvent.EventLabel;
};

export type QuickQueryParams = {
  requestId: string;
  userStoriesRequest?: UserStoriesRequest;
  meRequest?: MeRequest;
  novelRequest?: NovelRequest;
  novelEpisodesRequest?: NovelEpisodesRequest;
  episodeRequest?: EpisodeRequest;
  storyFacetTagRequest?: StoryFacetTagRequest;
  searchQueryRequest?: SearchQueryRequest;
  episodeForTikTokRequest?: EpisodeForTikTokRequest;
  eventListRequest?: EventListRequest;
  rawQueryRequest?: RpcQuery.IQueryRequestItem;
};

export class QueryRepository {
  private api: TellerApi;

  constructor() {
    this.api = new TellerApi();
  }

  /*
   * Methods for generating which fields we want to retrieve for each type
   * from here ↓
   */

  // Fields for user
  private buildUserFields = (
    withFollowNumbers = false,
    withCoverImage = false,
    withSearchableStoryCount = false,
    withProfile = false,
    onlyEssential = false
  ): RpcRecursiveTypes.IUserRequireField => {
    if (onlyEssential) {
      return {
        requireId: { value: true },
        requireName: { value: true }
      } as RpcRecursiveTypes.IUserRequireField;
    }

    const userRequireFields: RpcRecursiveTypes.IUserRequireField = {
      requireId: { value: true },
      requireName: { value: true },
      requireVipStatus: { value: true },
      requireThumbnail: {
        requireId: { value: true },
        requireServingUrl: { value: true }
      },
      requireUserRole: { value: true }
    };
    if (withFollowNumbers) {
      const requireCount: RpcRecursiveTypes.IUserCursorRequest = {
        cursor: {
          requireCursorInfo: {
            requireTotalCount: { value: true }
          }
        }
      };

      userRequireFields.requireFollowee = requireCount;
      userRequireFields.requireFollower = requireCount;
    }

    if (withCoverImage) {
      userRequireFields.requireCoverImage = {
        requireId: { value: true },
        requireServingUrl: { value: true }
      };
    }

    if (withSearchableStoryCount) {
      userRequireFields.requireSearchableStoryCount = { value: true };
    }
    if (withProfile) {
      userRequireFields.requireProfile = { value: true };
    }
    return userRequireFields;
  };

  // Common method for creating basic QueryRequestItem
  private buildRequestItem = (requestId: string): RpcQuery.QueryRequestItem =>
    new RpcQuery.QueryRequestItem({
      requestId: { value: requestId }
    });

  private buildPageRequest = (
    page = 0,
    limit: number,
    requireTotalCount = true,
    requireHasNextPage?: boolean
  ): RpcQueryPagination.IPageRequest => {
    const pageRequest: RpcQueryPagination.IPageRequest = {
      pageNumber: { value: page },
      limit: { value: limit }
    };

    if (requireTotalCount || requireHasNextPage) {
      pageRequest.requirePageInfo = {
        requireTotalCount: { value: requireTotalCount },
        requireHasNextPage: { value: requireHasNextPage }
      };
    }

    return pageRequest;
  };

  /*
   * Types of requests that can be send to query service API
   * from here ↓
   */

  private buildSearchQueryRequest = (
    searchQuery: SearchQueryRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.searchQueryRequest = {
      requirePopularSearchQuery: {
        requireQueryList: { value: true },
        page: {
          pageNumber: { value: searchQuery.pagination.page },
          limit: { value: searchQuery.pagination.limit }
        }
      }
    };
    return queryRequestItem;
  };

  // User
  private buildMeRequest = (requestId: string): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.meRequest = {
      requireUser: this.buildUserFields(
        true, // with follow numbers
        true, // with cover image
        true, // with searchable story count
        true // with profile,
      )
    };
    return queryRequestItem;
  };

  // User's novels
  private buildSearchSeriesRequest = (
    userStoriesRequest: UserStoriesRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.meRequest = {
      requireSeriesPage: {
        requireSeriesList: {
          requireId: { value: true },
          requireTitle: { value: true },
          requireDescription: { value: true },
          requireTags: { value: true },
          requireGenre: { value: true },
          requireThumbnail: {
            requireId: { value: true },
            requireServingUrl: { value: true }
          },
          requireUser: {
            requireId: { value: true }
          },
          requireSearchableStoryCount: { value: true },
          requireHasChatNovelScriptStory: { value: true },
          requireIsCompleted: { value: true },
          requireIsOneshot: { value: true },
          requireCanChangeToOneshot: { value: true },
          requireSharedWithStatus: { value: true },
          requireShowGuidelineDialog: { value: true }
        },
        cursor: {
          cursor: { value: userStoriesRequest.seriesPagination.cursor },
          limit: { value: userStoriesRequest.seriesPagination.limit },
          requireCursorInfo: {
            requireEndCursor: { value: true }
          }
        }
      }
    };
    return queryRequestItem;
  };

  // Novel
  private buildNovelRequest = (
    novelRequest: NovelRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    const seriesRequest: RpcRecursiveTypes.ISeriesRequest = {
      id: { value: novelRequest.novelId },
      requireSeries: {
        requireId: { value: true },
        requireTitle: { value: true },
        requireDescription: { value: true },
        requireThumbnail: {
          requireId: { value: true },
          requireServingUrl: { value: true }
        },
        requireUser: {
          requireId: { value: true }
        },
        requireTags: { value: true },
        requireSearchableStoryCount: { value: true },
        requireHasChatNovelScriptStory: { value: true },
        requireIsCompleted: { value: true },
        requireIsOneshot: { value: true },
        requireCanChangeToOneshot: { value: true },
        requireSharedWithStatus: { value: true },
        requireGenre: { value: true },
        requireSearchStory: {
          params: {
            sortBy:
              RpcSearchTypes.SearchStorySortBy
                .SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC,
            filter: {
              filterAnyUserId: [{ value: novelRequest.userId }]
            }
          },
          requireStoryPage: {
            page: {
              requirePageInfo: {
                requireTotalCount: { value: true }
              }
            }
          }
        }
      }
    };

    queryRequestItem.seriesRequest = seriesRequest;
    return queryRequestItem;
  };

  // Novel with episodes
  private buildNovelEpisodesRequest = (
    novelEpisodesRequest: NovelEpisodesRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.seriesRequest = {
      id: { value: novelEpisodesRequest.novelId },
      requireSeries: {
        requireId: { value: true },

        requireStoryPage: {
          requireStoryList: {
            requireId: { value: true },
            requireTitle: { value: true },
            requireThumbnail: {
              requireId: { value: true },
              requireServingUrl: { value: true }
            },
            requireStatus: { value: true },
            requireIsOfficial: { value: true },
            requireUpdatedAt: { value: true },
            requirePublishedAt: { value: true },
            requireViewCount: { value: true },
            requireTags: { value: true },
            requireSeriesIndex: { value: true },
            requireScriptType: { value: true }
          },
          page: this.buildPageRequest(
            novelEpisodesRequest.episodePagination.page,
            novelEpisodesRequest.episodePagination.limit,
            true,
            true
          ),
          filterStatus: RpcStory.StoryStatus.STORY_STATUS_UNKNOWN
        }
      }
    };

    return queryRequestItem;
  };

  // Episode
  private buildEpisodeRequest = (
    episodeRequest: EpisodeRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.storyRequest = {
      id: { value: episodeRequest.episodeId },
      requireStory: {
        requireId: { value: true },
        requireTitle: { value: true },
        requireThumbnail: {
          requireId: { value: true },
          requireServingUrl: { value: true }
        },
        requireTags: { value: true },
        requireSensitiveFlag: { value: true },
        requireStatus: { value: true },
        requireSeriesIndex: { value: true },
        requireScriptType: { value: true },
        requireSeries: {
          requireId: { value: true },
          requireTitle: { value: true },
          requireTags: { value: true },
          requireIsCompleted: { value: true },
          requireSharedWithStatus: { value: true },
          requireGenre: { value: true },
          requireThumbnail: {
            requireId: { value: true },
            requireServingUrl: { value: true }
          }
        },
        requirePublishedAt: { value: true }
      }
    };
    if (
      episodeRequest.withScript &&
      queryRequestItem.storyRequest.requireStory
    ) {
      queryRequestItem.storyRequest.requireStory.requireNovelScript = {
        requireFullScript: { value: true }
      };
      queryRequestItem.storyRequest.requireStory.requireChatNovelScript = {
        requireFullScript: { value: true }
      };
    }
    return queryRequestItem;
  };

  // Episode video for sharing in TikTok availability check
  private buildEpisodeForTikTokRequest = (
    episodeForTikTokRequest: EpisodeForTikTokRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.storyRequest = {
      id: { value: episodeForTikTokRequest.episodeId },
      requireStory: {
        requireId: { value: true },
        requireVideo: {
          requireForTiktok: {
            requireId: { value: true },
            requireDownloadUrl: { value: true },
            requireStatus: { value: true }
          }
        }
      }
    };
    return queryRequestItem;
  };

  private buildStoryFacetTagRequest = (
    storyFacetTagRequest: StoryFacetTagRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.searchFacetTagRequestV2 = {
      limit: { value: storyFacetTagRequest.pagination?.limit || 10 }
    };
    if (storyFacetTagRequest.query) {
      queryRequestItem.searchFacetTagRequestV2.q = {
        value: storyFacetTagRequest.query
      };
      queryRequestItem.searchFacetTagRequestV2.matchType =
        RpcQuerySearchV2.MatchType.MATCH_TYPE_PARTIAL;
    }

    return queryRequestItem;
  };

  private buildEventListRequest = (
    eventListRequest: EventListRequest,
    requestId: string
  ): RpcQuery.QueryRequestItem => {
    const queryRequestItem = this.buildRequestItem(requestId);
    queryRequestItem.eventListRequest = {
      requireEventList: {
        requireId: { value: true },
        requireTag: { value: true },
        requireDescription: { value: true }
      }
    };
    if (eventListRequest.filterLabel && queryRequestItem.eventListRequest) {
      queryRequestItem.eventListRequest.filterAnyEventLabel = [
        eventListRequest.filterLabel
      ];
    }
    return queryRequestItem;
  };

  /*
   * Pure call to QueryService.Query
   * ↓
   */
  public query = (
    query: RpcQuery.QueryRequest
  ): Promise<RpcQuery.QueryResponse> => {
    const queryService = this.getQueryService("Query");
    return queryService.query(query);
  };

  /*
   * Wrapper for generating and sending a combination of multiple queries to send at once
   * Based on types defined at the begining of this file
   * ↓
   */

  public quickQuery = (
    queries: QuickQueryParams[]
  ): Promise<RpcQuery.QueryResponse> => {
    const queryRequest = new RpcQuery.QueryRequest({
      requestList: []
    });
    queries.forEach(query => {
      if (query.meRequest) {
        queryRequest.requestList.push(this.buildMeRequest(query.requestId));
      }

      if (query.userStoriesRequest) {
        queryRequest.requestList.push(
          this.buildSearchSeriesRequest(
            query.userStoriesRequest,
            query.requestId
          )
        );
      }

      if (query.novelRequest) {
        queryRequest.requestList.push(
          this.buildNovelRequest(query.novelRequest, query.requestId)
        );
      }

      if (query.novelEpisodesRequest) {
        queryRequest.requestList.push(
          this.buildNovelEpisodesRequest(
            query.novelEpisodesRequest,
            query.requestId
          )
        );
      }

      if (query.episodeRequest) {
        queryRequest.requestList.push(
          this.buildEpisodeRequest(query.episodeRequest, query.requestId)
        );
      }

      if (query.episodeForTikTokRequest) {
        queryRequest.requestList.push(
          this.buildEpisodeForTikTokRequest(
            query.episodeForTikTokRequest,
            query.requestId
          )
        );
      }

      if (query.storyFacetTagRequest) {
        queryRequest.requestList.push(
          this.buildStoryFacetTagRequest(
            query.storyFacetTagRequest,
            query.requestId
          )
        );
      }

      if (query.searchQueryRequest) {
        queryRequest.requestList.push(
          this.buildSearchQueryRequest(
            query.searchQueryRequest,
            query.requestId
          )
        );
      }

      if (query.eventListRequest) {
        queryRequest.requestList.push(
          this.buildEventListRequest(query.eventListRequest, query.requestId)
        );
      }

      if (query.rawQueryRequest) {
        queryRequest.requestList.push({
          ...query.rawQueryRequest,
          requestId: { value: query.requestId }
        });
      }
    });
    return this.query(queryRequest);
  };

  private getQueryService = (method: string): RpcQuery.QueryService => {
    const rpcImpl: RPCImpl = this.api.post(`${servicePath}${method}`);
    return RpcQuery.QueryService.create(rpcImpl, false, false);
  };
}

import { useCallback, useRef, RefObject } from "react";
import { NovelUseCase } from "../usecases/novelUseCase";
import { logError } from "../utils/utils";
import {
  DEFAULT_THUMBNAIL_FORMAT,
  DEFAULT_THUMBNAIL_HEIGHT,
  DEFAULT_THUMBNAIL_WIDTH
} from "./useDefaultThumbnail";

export type ServerImage = {
  id: string;
  servingUrl: string;
};

const imageToBase64 = async (
  image: HTMLImageElement,
  mimeType: string
): Promise<string> => {
  const canvas = document.createElement("canvas");
  canvas.width = DEFAULT_THUMBNAIL_WIDTH;
  canvas.height = DEFAULT_THUMBNAIL_HEIGHT;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(image, 0, 0);
  return canvas.toDataURL(mimeType);
};

interface DefaultThumbnailHandle {
  thumbnailRef: RefObject<HTMLImageElement>;
  uploadDefaultThumbnail: () => Promise<ServerImage | undefined>;
}

export const useUploadDefaultThumbnail = (): DefaultThumbnailHandle => {
  const thumbnailRef = useRef<HTMLImageElement | null>(null);

  const uploadDefaultThumbnail = useCallback(async (): Promise<
    ServerImage | undefined
  > => {
    if (!thumbnailRef.current) {
      return undefined;
    }
    try {
      const base64 = await imageToBase64(
        thumbnailRef.current,
        `image/${DEFAULT_THUMBNAIL_FORMAT}`
      );
      const fileInfo = {
        isQR: false,
        width: DEFAULT_THUMBNAIL_WIDTH,
        height: DEFAULT_THUMBNAIL_HEIGHT
      };
      const novelUseCase = new NovelUseCase();
      const rpcImage = await novelUseCase.createImageOnServer(
        base64,
        "png",
        fileInfo
      );
      if (rpcImage.id?.value && rpcImage.servingUrl?.value) {
        return {
          id: rpcImage.id.value,
          servingUrl: rpcImage.servingUrl.value
        } as ServerImage;
      }
      return undefined;
    } catch (err) {
      logError(err);
      return undefined;
    }
  }, [thumbnailRef]);

  return {
    thumbnailRef,
    uploadDefaultThumbnail
  };
};

import React, { useCallback, useMemo } from "react";
import styled from "@emotion/styled/macro";

// Components
import { Modal } from "./modal";
import { Media, FontSize, Color } from "../styles/enums";
import { Text } from "../ui/text";
import { Button, ButtonVariant } from "../ui/button";
import { useModal } from "../../hooks/useModal";
import { CommunityGuidelineModal } from "./communityGuidelineModal";
import { useAppParameters } from "../../hooks/useAppParameters";
import { COMMUNITY_GUIDELINE_LINK } from "../../utils/constants";

const ContentWrapper = styled.div`
  text-align: center;
  padding: 48px 16px;
`;

const Title = styled(Text)`
  font-size: ${FontSize.SIZE_18};
  font-weight: 600;
  line-height: 1.4;
`;

const Message = styled(Text)`
  font-size: ${FontSize.SIZE_14};
  margin-top: 24px;

  > span {
    font-weight: normal;
  }

  > a {
    color: ${Color.TINT};
    font-weight: normal;
  }
`;

const Description = styled(Text)`
  font-size: ${FontSize.SIZE_12};
  color: ${Color.ACCENT_500};
  margin-top: 16px;
  text-align: center;
`;

const OkButton = styled(Button)`
  height: 40px;
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  max-width: 80%;
  margin: 16px auto 0;
  border: 1px solid ${Color.ACCENT_700};

  @media ${Media.SMALL} {
    height: 46px;
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AboutRatingModal: React.FC<Props> = ({ open, onClose }) => {
  const { canOpenInAppWeb } = useAppParameters();
  const communityGuidelineModal = useModal();
  const onClickOpenCommunityGuideline = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      communityGuidelineModal.open();
    },
    [communityGuidelineModal]
  );

  const guidelineURL = useMemo((): string => {
    if (canOpenInAppWeb) {
      return `teller-studio://in-app-webview?url=${COMMUNITY_GUIDELINE_LINK}`;
    }
    return COMMUNITY_GUIDELINE_LINK;
  }, [canOpenInAppWeb]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Modal
        open={open}
        width="326px"
        height="auto"
        hideClose
        noPadding
        close={onClose}
        overlayClose={false}
      >
        <ContentWrapper>
          <Title>レーティング設定にご協力ください</Title>
          <Message>
            性的表現・暴力的表現を含む投稿には「センシティブな内容を含む」の設定をONにしてください。詳しくは{" "}
            <a
              href={guidelineURL}
              onClick={
                canOpenInAppWeb ? undefined : onClickOpenCommunityGuideline
              }
            >
              コミュニティガイドライン
            </a>{" "}
            をご確認下さい。
          </Message>
          <Description>
            通報やサイトパトロールにより「センシティブな内容を含む」に変更される場合がございます。予めご了承ください。
          </Description>
          <OkButton
            block
            variant={ButtonVariant.OUTLINED}
            color={Color.ACCENT_700}
            onClick={onClose}
          >
            閉じる
          </OkButton>
        </ContentWrapper>
      </Modal>
      <CommunityGuidelineModal
        open={communityGuidelineModal.isOpen}
        onClose={communityGuidelineModal.close}
      />
    </>
  );
};

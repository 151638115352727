import { useMedia as reactUseMedia } from "react-use";
import { Media } from "../components/styles/enums";
import { useUA } from "./useUA";

const getFixedState = (media: Media, isMobile: boolean): boolean => {
  switch (media) {
    case Media.SMALLEST:
    case Media.SMALL:
      return isMobile;
    case Media.LARGE:
      return !isMobile;
    default:
      throw new Error(`Expected Media, got "${media}"`);
  }
};

export const useMedia = (media: Media): boolean => {
  const ua = useUA();

  // Only used as a fallback for Rendertron
  if (ua.isBot) {
    return getFixedState(media, ua.isMobile);
  }

  return reactUseMedia(media);
};

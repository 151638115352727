import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState, useEffect, useRef } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEpisodeReorder } from "../hooks/useEpisodeReorder";

// Models
import { query_recursive_types as RpcRecursiveTypes } from "../infra/api/rpc/api";

// Usecase
import { NovelUseCase } from "../usecases/novelUseCase";

// Hooks
import { useLayoutUI } from "../hooks/useLayoutUI";

// Components
import { Alert } from "../components/common/alert";
import { NovelStoryOrderSaveChangesActionSheet } from "../components/novel/novelStoryOrderSaveChangesActionSheet";
import { AppHeader } from "../components/common/header/appHeader";
import { ActivityModal } from "../components/common/activityModal";
import { NoResults } from "../components/common/noResults";
import { NovelCell } from "../components/novel/novelCell";
import { DraggableEpisodeCell } from "../components/episode/draggableEpisodeCell";
import { Text } from "../components/ui/text";
import { Color, FontSize } from "../components/styles/enums";

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  min-height: calc(100vh - 46px);
  max-width: 960px;
  margin: auto;
`;

const LegendWrapper = styled.div`
  display: flex;
  font-size: ${FontSize.SIZE_12};
  align-items: baseline;
  justify-content: space-between;
`;

const Legend = styled(Text)`
  color: ${Color.ACCENT_700};
  font-weight: 700;
  padding-left: 16px;
  margin-top: 16px;
`;

const ReorderLegend = styled(Text)`
  color: ${Color.ACCENT_500};
  font-weight: 400;
  margin-right: 15px;
`;

type Props = {
  novelId: string;
};

const novelUseCase = new NovelUseCase();

const Content = styled.div`
  min-height: 90vh;
  height: 90vh;
  padding-bottom: 150px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const NovelEpisodeReorderContainer: React.FC = () => {
  const { tellerUser, isOfficialWriter } = useAuth();
  const { novelId } = useParams<Props>();
  const { setLayout } = useLayoutUI();

  const [layoutSet, setLayoutSet] = useState(false);

  const { data: novel } = useSWR(
    tellerUser?.id?.value && novelId
      ? ["/api/novel", tellerUser.id.value, novelId]
      : null,
    (_, uId, nId) => novelUseCase.fetchNovel(uId, nId)
  );

  // Novel and episode data loading (with pagination on scroll)
  const paginationRef = useRef<HTMLDivElement>(null);
  const {
    isLoading,
    isValidating,
    isEmpty,
    items,
    publishedExistsNow,
    onDragEnd,
    getItemStyle,
    getListStyle,
    isDiscardChangesActionSheetOpen,
    closeDiscardChangesActionSheet,
    checkChangesOnBack,
    toNovelPage,
    checkAndSaveChanges,
    saveChanges,
    isShowActivityModal,
    isShowAlert,
    alertMsg,
    hideAlert
  } = useEpisodeReorder(novelId, novel, paginationRef);
  const novelPublishedStatus =
    publishedExistsNow || (novel?.searchableStoryCount?.value || 0) > 0;

  // Layout
  useEffect(() => {
    if (!layoutSet && novel?.title?.value) {
      setLayout({
        title: novel.title.value
      });
      setLayoutSet(true);
    }
  }, [layoutSet, novel, setLayout]);

  return (
    <div>
      <AppHeader
        title={novel?.title?.value || ""}
        noBackButton={false}
        backAction={checkChangesOnBack}
        moreButton
        moreButtonLabel="完了"
        onMoreButtonClick={checkAndSaveChanges}
      />
      <PageWrapper>
        {!isEmpty && (isValidating || isLoading || isShowActivityModal) ? (
          <ActivityModal />
        ) : null}
        {isEmpty ? <NoResults text="データは見つかりませんでした" /> : null}
        <Content>
          {isShowAlert && alertMsg && (
            <Alert msg={alertMsg} close={hideAlert} />
          )}
          {novel ? (
            <>
              <NovelCell
                novel={novel}
                isEpisodeListing
                readOnly
                novelStatusFromEpisodes={novelPublishedStatus}
                isOfficialWriter={isOfficialWriter}
              />
              {items && items.length > 0 ? (
                <>
                  <LegendWrapper>
                    <Legend>エピソード</Legend>
                    <ReorderLegend>並び替え</ReorderLegend>
                  </LegendWrapper>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot): JSX.Element => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {items.map(
                            (
                              ep: RpcRecursiveTypes.IStoryResponse,
                              index: number
                            ) => (
                              <Draggable
                                key={ep.id?.value}
                                draggableId={ep.id?.value || "0"}
                                index={index}
                              >
                                {(drgProvided, drgSnapshot): JSX.Element => (
                                  <div
                                    ref={drgProvided.innerRef}
                                    {...drgProvided.draggableProps}
                                    {...drgProvided.dragHandleProps}
                                    style={getItemStyle(
                                      drgSnapshot.isDragging,
                                      drgProvided.draggableProps.style
                                    )}
                                  >
                                    <DraggableEpisodeCell
                                      episode={ep}
                                      idx={index}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : null}
            </>
          ) : null}
          <div ref={paginationRef} />
        </Content>
        <NovelStoryOrderSaveChangesActionSheet
          open={isDiscardChangesActionSheetOpen}
          onClose={closeDiscardChangesActionSheet}
          onSave={saveChanges}
          onDiscard={toNovelPage}
        />
      </PageWrapper>
    </div>
  );
};

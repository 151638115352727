import { useCallback } from "react";
import { useUI } from "./useUI";
import { UIState } from "../contexts/uiContext";

interface UseLayoutUI {
  setLayout: (layoutSettings: UIState) => void;
}

const DEFAULT_SETTINGS = {
  noLayout: false,
  customHeader: false
};
export const useLayoutUI = (): UseLayoutUI => {
  const { setState } = useUI();

  const setLayout = useCallback(
    (layoutSettings: UIState): void => {
      setState({ ...DEFAULT_SETTINGS, ...layoutSettings });
    },
    [setState]
  );

  return { setLayout };
};

export class AppError extends Error {
  public type: ErrorType;

  public name: string;

  public message: string;

  // HTTP status code
  public status?: number;

  public response?: Response;

  constructor(
    type: ErrorType,
    message: string,
    status?: number,
    response?: Response
  ) {
    super(message);
    this.type = type;
    this.name = type;
    this.message = message;
    this.status = status;
    this.response = response;
  }

  public toString(): string {
    return `${this.name}: ${this.message}`;
  }
}

export enum ErrorType {
  INVALID_STORY_ID = "invalid story id",
  NETWORK_ERROR = "network error",
  API_REQUEST_404 = "api request 404",
  API_REQUEST_409 = "api request 409",
  API_REQUEST_500 = "api request 500",
  INVALID_STORY_DATA = "invalid story data",
  AUTHENTICATION_ERROR = "authentication error"
}

import {
  query_search_v2 as RpcQuerySearchV2,
  query as RpcQuery
} from "../infra/api/rpc/api";

import { QueryRepository } from "../repositories/queryRepository";
import { FirebaseRepository } from "../repositories/firebaseRepository";

const SELECTED_TAGS_KEY = "studio_selected_tags";
const RISING_TAGS_LIMIT = 24; // same as app
const PREVIOUS_USED_TAGS_LIMIT = 8;
export class TagUseCase {
  private queryRepository: QueryRepository;

  private firebaseRepository: FirebaseRepository;

  constructor() {
    this.queryRepository = new QueryRepository();
    this.firebaseRepository = new FirebaseRepository();
  }

  public searchStoryFacetTag = async (
    query: string
  ): Promise<RpcQuerySearchV2.ISearchFacetTagResponseV2 | null> => {
    const response = await this.queryRepository.quickQuery([
      {
        requestId: `storyFacetTag-${query}`,
        storyFacetTagRequest: { query }
      }
    ]);

    return response.responseList[0].searchFacetTagV2 ?? null;
  };

  public fetchPreviousAndRisingTags = async (
    userId: string
  ): Promise<RpcQuery.QueryResponse> =>
    this.queryRepository.quickQuery([
      {
        requestId: `storyFacetTag-previously-used-${userId}`,
        rawQueryRequest: {
          meRequest: {
            requireUsedTagList: {
              limit: { value: PREVIOUS_USED_TAGS_LIMIT }
            }
          }
        }
      },
      {
        requestId: "risingTags",
        searchQueryRequest: {
          pagination: {
            page: 1,
            limit: RISING_TAGS_LIMIT
          }
        }
      }
    ]);

  public fetchSelectedTags = (): Promise<string[]> =>
    new Promise(resolve => {
      this.firebaseRepository
        .getRemoteConfigValue(SELECTED_TAGS_KEY)
        .then(tagsString => {
          if (!tagsString) {
            resolve([]);
            return;
          }
          resolve(tagsString.split(","));
        });
    });
}

import { css } from "@emotion/react/macro";
import styled from "@emotion/styled";
import React, {
  RefObject,
  useRef,
  useEffect,
  useCallback,
  CSSProperties
} from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// Styles, assets, components
import { Color, Media } from "../styles/enums";
import { Image } from "../ui/image";
import closeModalIcon from "../../assets/close_modal_icon.svg";

const Wrapper = styled.div<{
  style?: CSSProperties;
}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
  ${({ style }) => ({ ...style })}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  position: relative;
`;

const Content = styled.div<{
  width?: string;
  height?: string;
  maxWidth?: string;
  noPadding?: boolean;
  borderRadius?: string;
  minSmallHeight?: string;
  wholeSmallScreen?: boolean;
  overflowScrolling?: boolean;
}>`
  position: relative;
  width: ${({ width = "320px" }) => width};
  height: ${({ height = "auto" }) => height};
  max-width: ${({ maxWidth = "calc(100vw - 20px)" }) => maxWidth};
  max-height: ${({ height = "calc(100vh - 20px)" }) => height};
  padding: ${({ noPadding }) => (noPadding ? "0" : "20px")};
  background-color: ${Color.PRIMARY};
  border-radius: ${({ borderRadius = "8px" }) => borderRadius};
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 4px;
  ${({ overflowScrolling }) =>
    overflowScrolling === true
      ? `
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      `
      : "overflow: hidden;"}

  @media ${Media.SMALL} {
    min-height: ${({ minSmallHeight }) =>
      minSmallHeight ? `${minSmallHeight}px` : "auto"};
    ${({ wholeSmallScreen }) =>
      wholeSmallScreen
        ? css`
            max-width: none;
            border-radius: 0;
          `
        : null}
  }
`;

const Close = styled(Image)<{ showCloseIconInSmall?: boolean }>`
  position: absolute;
  top: -30px;
  right: 0;
  cursor: pointer;

  @media ${Media.SMALL} {
    ${({ showCloseIconInSmall }) =>
      `display: ${showCloseIconInSmall ? "block" : "none"};`}
  }
`;

interface Props {
  open?: boolean;
  width?: string;
  height?: string;
  maxWidth?: string;
  minSmallHeight?: string;
  borderRadius?: string;
  noPadding?: boolean;
  showCloseIconInSmall?: boolean;
  overflowScrolling?: boolean;
  hideClose?: boolean;
  wholeSmallScreen?: boolean;
  close: () => void;
  overlayClose?: boolean;
  scrollableElement?: RefObject<HTMLDivElement>;
  modalWrapperStyles?: CSSProperties;
}

export const Modal: React.FC<Props> = ({
  open = false,
  width,
  height,
  maxWidth,
  minSmallHeight,
  borderRadius,
  noPadding,
  hideClose,
  showCloseIconInSmall,
  wholeSmallScreen,
  overflowScrolling,
  close,
  overlayClose = true,
  scrollableElement,
  modalWrapperStyles,
  children
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      const allowScrollOn = scrollableElement?.current || modalRef.current;
      if (allowScrollOn) {
        disableBodyScroll(allowScrollOn);
      }
    } else {
      clearAllBodyScrollLocks();
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open, modalRef, scrollableElement]);

  const closeFromOverlay = useCallback(() => {
    if (overlayClose) {
      close();
    }
  }, [close, overlayClose]);

  if (!open) {
    return null;
  }

  return (
    <Wrapper role="dialog" aria-modal style={modalWrapperStyles}>
      <Overlay aria-hidden onClick={closeFromOverlay} />
      <Container>
        <Content
          ref={modalRef}
          width={width}
          height={height}
          maxWidth={maxWidth}
          noPadding={noPadding}
          borderRadius={borderRadius}
          minSmallHeight={minSmallHeight}
          wholeSmallScreen={wholeSmallScreen}
          overflowScrolling={overflowScrolling}
        >
          {children}
        </Content>
        {hideClose ? null : (
          <Close
            role="button"
            src={closeModalIcon}
            onClick={close}
            showCloseIconInSmall={showCloseIconInSmall}
          />
        )}
      </Container>
    </Wrapper>
  );
};

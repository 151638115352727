import styled from "@emotion/styled/macro";
import { useCallback } from "react";

import { ActionSheet, OptionsList, Option } from "../common/actionSheet";
import { Image } from "../ui/image";
import playIcon from "../../assets/play_icon.svg";
import editIcon from "../../assets/edit_icon.svg";
import shareIcon from "../../assets/share_icon.svg";
import deleteIcon from "../../assets/delete_icon.svg";
// import publicIcon from "../../assets/public_icon.svg";

const Wrapper = styled.div`
  min-height: 230px;
`;

interface Props {
  open: boolean;
  isPublished: boolean;
  isOfficialWriter: boolean;
  isApp: boolean;
  isReadyForPublish: boolean;
  onClose(): void;
  onPreview(): void;
  onToDraft(): void;
  onShare(): void;
  onDelete(): void;
  onPublish(): void;
}

export const EpisodeActionSheet: React.VFC<Props> = ({
  open,
  isPublished,
  isReadyForPublish,
  isOfficialWriter,
  isApp,
  onClose,
  onPreview,
  onToDraft,
  onShare,
  onDelete
  // onPublish
}) => {
  const preview = useCallback(() => {
    onPreview();
    onClose();
  }, [onClose, onPreview]);

  const toDraft = useCallback(() => {
    onToDraft();
    onClose();
  }, [onToDraft, onClose]);

  const share = useCallback(() => {
    onShare();
    onClose();
  }, [onClose, onShare]);

  const deleteEpisode = useCallback(() => {
    onDelete();
    onClose();
  }, [onDelete, onClose]);

  // Disable publish episode from here for simplifying contest modal logic
  // https://github.com/filmapp/teller-novel-studio/issues/185
  // const publishEpisode = useCallback(() => {
  //   onPublish();
  //   onClose();
  // }, [onPublish, onClose]);

  return (
    <ActionSheet open={open || false} onClose={onClose} bckWhite>
      <Wrapper>
        <OptionsList>
          <Option>
            <button type="button" onClick={preview}>
              <Image src={playIcon} />
              プレビュー
            </button>
          </Option>
          {isOfficialWriter || !isReadyForPublish ? null : (
            <>
              {isPublished ? (
                <Option>
                  <button type="button" onClick={toDraft}>
                    <Image src={editIcon} />
                    エピソードを下書きに変更
                  </button>
                </Option>
              ) : null}
              {/* <button type="button" onClick={publishEpisode}>
                   <Image src={publicIcon} />
                   エピソードを全体公開に変更
                 </button> */}
            </>
          )}
          {isApp ? (
            <Option>
              <button type="button" onClick={share}>
                <Image src={shareIcon} />
                エピソードを共有
              </button>
            </Option>
          ) : null}
          {isOfficialWriter ? null : (
            <Option>
              <button type="button" onClick={deleteEpisode}>
                <Image src={deleteIcon} />
                エピソードを削除
              </button>
            </Option>
          )}
        </OptionsList>
      </Wrapper>
    </ActionSheet>
  );
};

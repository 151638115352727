import { css, SerializedStyles } from "@emotion/react/macro";
import { CSSProperties } from "react";
import { FontSize } from "../enums";

export interface TypographyProps {
  fz?: FontSize;
  fw?: CSSProperties["fontWeight"];
  ta?: CSSProperties["textAlign"];
  va?: CSSProperties["verticalAlign"];
}

/**
 * Converts shorthand typography props to CSS declarations
 *
 * @example
 * // <Div fz={FontSize.SIZE_12} />
 * const Div = styled.div<TypographyProps>`
 *  ${typography}
 * `;
 */
export const typography = ({
  fz,
  fw,
  ta,
  va
}: TypographyProps): SerializedStyles => css`
  ${fz && `font-size: ${fz}`};
  ${fw && `font-weight: ${fw}`};
  ${ta && `text-align: ${ta}`};
  ${va && `vertical-align: ${va}`};
`;

import { RPCImpl } from "protobufjs";
import {
  mutation as RpcMutation,
  mutation_story as RpcMutationStory,
  mutation_series as RpcMutationSeries,
  query_types as RpcQueryTypes,
  types as RpcTypes
} from "../infra/api/rpc/api";
import { Episode } from "../models/episode";
import { Novel, rpcSharedWithStatusFromPublishRange } from "../models/novel";
import { TellerApi } from "../infra/api/tellerApi";
import { genreToRpcGenre } from "../models/genre";

const servicePath = "/mutation.MutationService/";

export class MutationRepository {
  private api: TellerApi;

  constructor() {
    this.api = new TellerApi();
  }

  public createStory = (
    episode: Episode
  ): Promise<RpcMutationStory.CreateStoryResponse> => {
    const service = this.getService("CreateStory");
    const tags = episode?.tags?.map(t => ({ value: t })) || [];
    return service.createStory({
      userId: { value: episode.userId },
      tags,
      seriesId: { value: episode.novelId },
      seriesIndex: { value: episode.novelIndex },
      title: { value: episode.title },
      // NOTE: STU-140 Episode description not set
      description: { value: "" },
      thumbnailId: { value: episode.thumbnailId },
      sensitiveFlag: episode.sensitiveFlag,
      status: episode.status,
      scriptType: RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_NOVEL,
      novelScript: { value: episode.novelScript }
    });
  };

  public requestVideoCreation = (episodeId: string): Promise<void> =>
    new Promise<void>(resolve => {
      const service = this.getService("CreateStoryVideoAsync");
      service
        .createStoryVideoAsync({ storyId: { value: episodeId } })
        .then(() => {
          resolve();
        });
    });

  public updateStory = (
    episode: Episode
  ): Promise<RpcMutationStory.UpdateStoryResponse> => {
    const service = this.getService("UpdateStory");
    const tags = episode?.tags?.map(t => ({ value: t })) || [];
    const request: RpcMutationStory.IUpdateStoryRequest = {
      userId: { value: episode.userId },
      tags,
      id: { value: episode.id },
      title: { value: episode.title },
      seriesIndex: { value: episode.novelIndex },
      // NOTE: STU-140 Episode description not set
      description: { value: "" },
      thumbnailId: { value: episode.thumbnailId },
      sensitiveFlag: episode.sensitiveFlag,
      status: episode.status,
      scriptType: episode.scriptType
    };
    if (
      episode.scriptType ===
      RpcQueryTypes.StoryScriptType.STORY_SCRIPT_TYPE_NOVEL
    ) {
      request.novelScript = { value: episode.novelScript };
    } else {
      request.chatNovelScript = episode.chatScript?.fullScript;
    }
    if (episode.novelId && episode.novelId !== "new") {
      request.seriesId = { value: episode.novelId };
    }
    return service.updateStory(request);
  };

  public deleteStory = (id: string): Promise<RpcTypes.Empty> => {
    const service = this.getService("DeleteStory");
    return service.deleteStory({ id: { value: id } });
  };

  public createSeries = (
    novel: Novel
  ): Promise<RpcMutationSeries.CreateSeriesResponse> => {
    const service = this.getService("CreateSeries");
    const tags = novel?.tags?.map((t: string) => ({ value: t })) || [];
    return service.createSeries({
      userId: { value: novel.userId },
      tags,
      title: { value: novel.title },
      description: { value: novel.description },
      thumbnailId: { value: novel.thumbnailId },
      isCompleted: { value: novel.isCompleted },
      isOneshot: { value: novel.isOneShot },
      sharedWithStatus: rpcSharedWithStatusFromPublishRange(novel.publishRange),
      genre: genreToRpcGenre(novel.genre)
    });
  };

  public updateSeries = (
    novel: Novel
  ): Promise<RpcMutationSeries.UpdateSeriesResponse> => {
    const service = this.getService("UpdateSeries");
    const tags = novel?.tags?.map((t: string) => ({ value: t })) || [];
    return service.updateSeries({
      id: { value: novel.id },
      userId: { value: novel.userId },
      tags,
      title: { value: novel.title },
      description: { value: novel.description },
      thumbnailId: { value: novel.thumbnailId },
      isCompleted: { value: novel.isCompleted },
      isOneshot: { value: novel.isOneShot },
      sharedWithStatus: rpcSharedWithStatusFromPublishRange(novel.publishRange),
      genre: genreToRpcGenre(novel.genre)
    });
  };

  public deleteSeries = (id: string): Promise<RpcTypes.Empty> => {
    const service = this.getService("DeleteSeries");
    return service.deleteSeries({
      id: { value: id },
      deleteStory: { value: true }
    });
  };

  public updateSeriesStoryOrder = (
    seriesId: string,
    storyIds: string[]
  ): Promise<RpcTypes.Empty> => {
    const service = this.getService("UpdateSeriesStoryOrder");
    return service.updateSeriesStoryOrder({
      id: { value: seriesId },
      orderedStoryIdList: storyIds.map(id => ({ value: id }))
    });
  };

  public acceptGuidelines = (seriesId: string): Promise<RpcTypes.Empty> => {
    const service = this.getService("AcceptGuideline");
    return service.acceptGuideline({
      seriesId: { value: seriesId }
    });
  };

  private getService = (method: string): RpcMutation.MutationService => {
    const rpcImpl: RPCImpl = this.api.post(`${servicePath}${method}`);
    return RpcMutation.MutationService.create(rpcImpl, false, false);
  };
}

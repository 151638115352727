// Core
import styled from "@emotion/styled";
import { useMemo } from "react";
import {
  query_recursive_types as RpcRecursiveTypes,
  story as RpcStory
} from "../../infra/api/rpc/api";

import viewsIcon from "../../assets/views_icon.svg";
import dragIcon from "../../assets/drag_icon.svg";
import { Color, FontSize, Media } from "../styles/enums";
import { Image } from "../ui/image";
import { Text } from "../ui/text";

const EpisodeItem = styled.div`
  position: relative;
  cursor: grab;
  background: ${Color.PRIMARY};
  padding: 12px 16px;
  border-bottom: 1px solid ${Color.SEPARATOR};

  &:hover {
    background: ${Color.HIGHLIGHT};
  }
`;

const TitleAndMore = styled.div`
  display: flex;
  align-items: center;
`;

const DraggableIcon = styled(Image)`
  margin-right: 11px;
`;

const EpisodeIndex = styled(Text)`
  font-size: ${FontSize.SIZE_10};
  color: ${Color.ACCENT_500};
  font-weight: 700;
`;

const TitleWrapper = styled.div`
  max-width: 85vw;

  @media ${Media.SMALL} {
    max-width: 75vw;
  }
`;

const Title = styled.div`
  display: \-webkit-box;

  text-align: left;
  vertical-align: middle;
  color: ${Color.ACCENT_1000};
  font-size: ${FontSize.SIZE_14};
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  grid-column: 1 / -1;
`;

const StatusAndViews = styled.div`
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  font-size: ${FontSize.SIZE_12};
`;

const Views = styled.div`
  font-weight: normal;
  display: inline-flex;
`;

const Status = styled.span<{ published: boolean }>`
  display: block;
  font-size: ${FontSize.SIZE_12};
  font-weight: 700;
  display: block;
  ${({ published }) => `color: ${published ? Color.GREEN : Color.RED};`}
`;

interface Props {
  episode: RpcRecursiveTypes.IStoryResponse;
  idx: number;
}

export const DraggableEpisodeCell: React.FC<Props> = ({ episode, idx }) => {
  const statusString = useMemo(() => {
    if (episode.status === RpcStory.StoryStatus.REJECT) {
      return "非公開";
    }
    if (episode.status === RpcStory.StoryStatus.PUBLISH) {
      return "公開中";
    }
    return "下書き";
  }, [episode.status]);

  if (!episode) {
    return null;
  }

  return (
    <EpisodeItem>
      <TitleAndMore>
        <DraggableIcon src={dragIcon} />
        <TitleWrapper>
          <EpisodeIndex>第{idx + 1}話</EpisodeIndex>
          <Title>{episode.title?.value}</Title>
        </TitleWrapper>
      </TitleAndMore>
      <StatusAndViews>
        <Status
          as="span"
          published={episode.status === RpcStory.StoryStatus.PUBLISH}
        >
          {statusString}
        </Status>
        {episode.viewCount?.value ? (
          <Views>
            <Image src={viewsIcon} />
            {episode.viewCount?.value}
          </Views>
        ) : null}
      </StatusAndViews>
    </EpisodeItem>
  );
};

import styled from "@emotion/styled/macro";
import { button, ButtonProps } from "../../styles/mixins/button";
import { margins, MarginsProps } from "../../styles/mixins/margins";
import { shouldForwardProp } from "../../styles/utils";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonProps &
  MarginsProps;

export const Button = styled("button", {
  shouldForwardProp: shouldForwardProp<keyof Props>({
    ignore: ["color", "block"]
  })
})<Props>`
  ${button}
  ${margins};
`;

Button.defaultProps = {
  type: "button"
};

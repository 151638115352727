import { RPCImpl } from "protobufjs";
import { resource as RpcResource } from "../infra/api/rpc/api";
import { TellerApi } from "../infra/api/tellerApi";

const servicePath = "/resource.ResourceService/";

export class ResourceRepository {
  private api: TellerApi;

  constructor() {
    this.api = new TellerApi();
  }

  public createImage = (
    req: RpcResource.IResourceCreateImageRequest
  ): Promise<RpcResource.Image> => {
    const service = this.getService("CreateImage");
    return service.createImage(req);
  };

  public listPresetImage = (
    req: RpcResource.IResourceListPresetImageRequest
  ): Promise<RpcResource.IPresetImageSubcategoryList> => {
    const service = this.getService("ListPresetImageV2");
    return service.listPresetImageV2(req);
  };

  private getService = (method: string): RpcResource.ResourceService => {
    const rpcImpl: RPCImpl = this.api.post(`${servicePath}${method}`);
    return RpcResource.ResourceService.create(rpcImpl, false, false);
  };
}

import { useMemo, useCallback } from "react";
import { useLocalStorage } from "react-use";
import { Platform } from "../utils/constants";
import {
  canOpenTellerWebURLInApp,
  canUseReactNativeStudio
} from "../utils/appUtil";

export const APP_PARAMETER_FROM_KEY = "fromApp";
export const APP_PARAMETER_CAN_OPEN_TIKTOK = "canOpenTikTok";
export const APP_PARAMETER_PLATFORM = "platform";
export const APP_PARAMETER_APP_VERSION = "appVersion";
export const APP_PARAMETER_DEFAULT_TAGS = "defaultTags";
export const APP_PARAMETER_IS_AGREE_POST_RULE = "isAgreePostRule";

interface UseAppParametersHandle {
  storeAppParameters(): void;
  parametersFromApp(): string;
  clearDefaultTags(): void;
  isApp: boolean;
  appVersion: string | null;
  defaultTags: string[];
  platform: Platform;
  canOpenInAppWeb: boolean;
  canOpenTikTok: boolean;
  canUseReactNativeStudio: boolean;
  isAgreePostRule: boolean;
}

export const useAppParameters = (): UseAppParametersHandle => {
  const [fromApp, setFromApp] = useLocalStorage<boolean>(
    APP_PARAMETER_FROM_KEY
  );
  const [canOpenTikTok, setCanOpenTikTok] = useLocalStorage<boolean>(
    APP_PARAMETER_CAN_OPEN_TIKTOK
  );
  const [appPlatform, setAppPlatform] = useLocalStorage<string>(
    APP_PARAMETER_PLATFORM
  );
  const [appVersion, setAppVersion] = useLocalStorage<string | null>(
    APP_PARAMETER_APP_VERSION
  );
  const [defaultTags, setDefaultTags] = useLocalStorage<string[]>(
    APP_PARAMETER_DEFAULT_TAGS
  );
  const [isAgreePostRule, setIsAgreePostRule] = useLocalStorage<boolean>(
    APP_PARAMETER_IS_AGREE_POST_RULE,
    false
  );
  const clearDefaultTags = useCallback(() => {
    setDefaultTags([]);
  }, [setDefaultTags]);
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const defaultTagsParams = useMemo(
    () => (urlParams.get("tags") ? urlParams.get("tags")?.split(",") : []),
    [urlParams]
  );
  const platform: Platform = (() => {
    const platformParam =
      urlParams.get("platform")?.toLocaleLowerCase() || appPlatform;
    switch (platformParam) {
      case Platform.IOS:
        return Platform.IOS;
      case Platform.ANDROID:
        return Platform.ANDROID;
      default:
        return Platform.UNKNOWN;
    }
  })();

  const canOpenInAppWeb = useMemo(
    () =>
      (fromApp &&
        appVersion &&
        canOpenTellerWebURLInApp({
          platform,
          version: appVersion
        })) ||
      false,
    [appVersion, fromApp, platform]
  );

  // eslint-disable-next-line no-underscore-dangle
  const _canUseReactNativeStudio = useMemo(
    () =>
      (fromApp &&
        appVersion &&
        canUseReactNativeStudio({
          platform,
          version: appVersion
        })) ||
      false,
    [appVersion, fromApp, platform]
  );

  const storeAppParameters = useCallback((): void => {
    const from = urlParams.get("from");
    const appVersionParam = urlParams.get("app_version");
    const canOpenTikTokParam = urlParams.get("can_open_tiktok");
    const isAgreePostRuleParam = urlParams.get("is_agree_post_rule");
    setFromApp(from === "teller-app");
    setCanOpenTikTok(
      canOpenTikTokParam ? canOpenTikTokParam === "true" : undefined
    );
    setAppPlatform(platform);
    setAppVersion(appVersionParam || undefined);
    setDefaultTags(defaultTagsParams);

    // クエリパラメータでis_agree_post_ruleがtrueの場合、ローカルストレージにtrueを保存する
    if (isAgreePostRuleParam === "true") {
      setIsAgreePostRule(true);
    }
  }, [
    defaultTagsParams,
    platform,
    setAppPlatform,
    setAppVersion,
    setCanOpenTikTok,
    setDefaultTags,
    setFromApp,
    setIsAgreePostRule,
    urlParams
  ]);

  const parametersFromApp = useCallback((): string => {
    const fromAppParam = fromApp ? "&from=teller-app" : "";
    const tikTokParam = canOpenTikTok ? `&can_open_tiktok=true` : "";
    const platformParam = appPlatform ? `&platform=${appPlatform}` : "";
    const appVersionParam = appVersion ? `&app_version=${appVersion}` : "";
    const defaultTagsParam = defaultTags
      ? `&tags=${defaultTags.join(",")}`
      : "";
    const isAgreePostRuleParam = isAgreePostRule ? `&is_agree_rule=true` : "";

    return `${fromAppParam}${tikTokParam}${platformParam}${appVersionParam}${defaultTagsParam}${isAgreePostRuleParam}`;
  }, [
    appPlatform,
    appVersion,
    canOpenTikTok,
    defaultTags,
    fromApp,
    isAgreePostRule
  ]);

  return {
    storeAppParameters,
    parametersFromApp,
    clearDefaultTags,
    isApp: localStorage
      ? localStorage.getItem(APP_PARAMETER_FROM_KEY) === "true"
      : false,
    appVersion: appVersion || null,
    defaultTags: defaultTags || [],
    platform,
    canOpenInAppWeb,
    canOpenTikTok: canOpenTikTok || false,
    canUseReactNativeStudio: _canUseReactNativeStudio,
    isAgreePostRule: isAgreePostRule || false
  };
};

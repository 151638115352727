import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

// Hooks
import { useAuth } from "../../hooks/useAuth";
import { useAppParameters } from "../../hooks/useAppParameters";
import { useMedia } from "../../hooks/useMedia";
import { useUI } from "../../hooks/useUI";

// Components
import { Header } from "./header/header";
import { Sidebar } from "./sidebar";
import { Metadata } from "./metadata";

import { Media } from "../styles/enums";

const Wrapper = styled.div<{ noScroll?: boolean }>`
  min-height: calc(100vh - 46px);
  @media ${Media.SMALL} {
    ${({ noScroll }) =>
      noScroll
        ? css`
            height: 100%;
            max-height: 100%;
            overflow: hidden;
          `
        : null}
  }
`;

export const Layout: React.FC = ({ children }) => {
  const { user: authUser, tellerUser, isVipUser, isOfficialWriter } = useAuth();
  const { isApp } = useAppParameters();
  const screenLarge = useMedia(Media.LARGE);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  const logout = useCallback(() => {
    window.location.href = `//${process.env.REACT_APP_WEB_DOMAIN}/auth/logout?nolayout=1`;
  }, []);

  const { state } = useUI();

  const { title, description, noLayout, noScroll } = state;
  const location = useLocation();

  return (
    <Wrapper noScroll={noScroll}>
      <Metadata title={title} description={description} />
      {!noLayout ? (
        <>
          {isApp !== undefined && !isApp && location.pathname === "/" ? (
            <Header
              authUser={authUser}
              tellerUser={tellerUser}
              isVipUser={isVipUser}
              isOfficialWriter={isOfficialWriter}
              screenLarge={screenLarge}
              onSidebarOpen={handleSidebarOpen}
              onLogout={logout}
            />
          ) : null}

          {children}

          <Sidebar
            open={sidebarOpen}
            onClose={handleSidebarClose}
            tellerUser={tellerUser}
            authUser={authUser}
            onLogout={logout}
            isVipUser={isVipUser}
          />
        </>
      ) : (
        <>{children}</>
      )}
    </Wrapper>
  );
};
